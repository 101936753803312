import type { Article } from "../../../../../types";

export const Article503: Article = {
	id: 503,
	rsuiteId: "c71e9f83-99e3-4071-99c9-ab6c546ca0d0",
	type: "synopsium series",
	title:
		"The Role of Journals in Reporting Sex and Gender-Based Outcomes: Should We Adopt Guidelines, and What Else Can We Do to Improve Reporting?",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/503.png",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset">
<div class="article lens-article" style="position: unset" data-context="toc">
<div class="panel content document width100" style="position: unset">
<div class="surface resource-view content" style="position: unset">
  <div class="nodes" style="padding-left: 0px; position: unset">
    <div
      class="content-node cover"
      data-id="cover"
      style="padding-top: 30px; position: unset"
    >
      <div class="content" style="position: unset">
        <div class="text title" style="position: unset">
          The Role of Journals in Reporting Sex and Gender-Based Outcomes:
          Should We Adopt Guidelines, and What Else Can We Do to Improve
          Reporting?
        </div>
        <div class="text subtitle" style="position: unset"></div>
        <div class="authors" style="position: unset">
          <div
            class="content-node text"
            data-id="text_contributor_1_reference"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <a
                href=""
                data-id="contributor_reference_1"
                class="annotation contributor_reference resource-reference"
                style="position: unset"
                >Andrew J. Schoenfeld, MD, MSc</a
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node abstract"
      data-id="abstract"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="sections" style="position: unset">
          <div
            class="content-node heading level-1"
            data-id="heading_2"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset"
                >Abstract</span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node list"
            data-id="list_1"
            style="position: unset"
          >
            <ul class="simple content" style="position: unset">
              <li style="position: unset">
                <span class="label">➤</span>
                <div
                  class="content-node paragraph"
                  data-id="paragraph_1"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    <div
                      class="content-node text"
                      data-id="text_1"
                      style="position: unset"
                    >
                      <div class="content" style="position: unset">
                        Sex and gender are inconsistently reported in
                        musculoskeletal research. A panel of journal
                        editors and other interested parties who
                        participated in The Journal of Bone and Joint
                        Surgery Sex and Gender Reporting in
                        Musculoskeletal Research Symposium met in 2023 to
                        help define the roles of journals and other
                        stakeholders in addressing this issue.
                      </div>
                      <div
                        class="focus-handle"
                        style="position: unset"
                      ></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </li>
              <li style="position: unset">
                <span class="label">➤</span>
                <div
                  class="content-node paragraph"
                  data-id="paragraph_2"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    <div
                      class="content-node text"
                      data-id="text_2"
                      style="position: unset"
                    >
                      <div class="content" style="position: unset">
                        This report summarizes the resulting
                        recommendations and presents several different
                        ways in which orthopaedic journals may elect to
                        introduce to their readers, reviewers, and authors
                        the importance of considering sex and gender in
                        orthopaedic research.
                      </div>
                      <div
                        class="focus-handle"
                        style="position: unset"
                      ></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </li>
              <li style="position: unset">
                <span class="label">➤</span>
                <div
                  class="content-node paragraph"
                  data-id="paragraph_3"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    <div
                      class="content-node text"
                      data-id="text_3"
                      style="position: unset"
                    >
                      <div class="content" style="position: unset">
                        Many current sex and gender-related funding agency
                        mandates as well as the Sex and Gender Equity in
                        Research (SAGER) guidelines are most readily
                        applied to prospective research and/or randomized
                        controlled trials.
                      </div>
                      <div
                        class="focus-handle"
                        style="position: unset"
                      ></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </li>
              <li style="position: unset">
                <span class="label">➤</span>
                <div
                  class="content-node paragraph"
                  data-id="paragraph_4"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    <div
                      class="content-node text"
                      data-id="text_4"
                      style="position: unset"
                    >
                      <div class="content" style="position: unset">
                        The panel was supportive of endorsing SAGER or a
                        similar set of guidelines as a tool that can help
                        authors to consider the most effective means of
                        evaluating the influences of sex and gender within
                        the context of orthopaedic research.
                      </div>
                      <div
                        class="focus-handle"
                        style="position: unset"
                      ></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </li>
              <li style="position: unset">
                <span class="label">➤</span>
                <div
                  class="content-node paragraph"
                  data-id="paragraph_5"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    <div
                      class="content-node text"
                      data-id="text_5"
                      style="position: unset"
                    >
                      <div class="content" style="position: unset">
                        Engaging readers, reviewers, and authors on
                        multiple fronts, with purpose and intention, is
                        likely the most important approach to increase
                        awareness and to enhance the quality of evidence
                        utilized to support clinical decisions in
                        orthopaedic surgery.
                      </div>
                      <div
                        class="focus-handle"
                        style="position: unset"
                      ></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_9"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_9"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Over the last 3 decades (from 1994 to 2024), increased
            awareness has developed with respect to the lack of
            representation of women in many arenas within health care,
            from residency training to leadership and research<a
              href=""
              data-id="citation_reference_1"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_1"
                class="annotation superscript"
                style="position: unset"
                >1</span
              ></a
            ><span
              data-id="superscript_1"
              class="annotation superscript"
              style="position: unset"
              >-</span
            ><a
              href=""
              data-id="citation_reference_2"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_1"
                class="annotation superscript"
                style="position: unset"
                >3</span
              ></a
            >. On the research front, issues regarding representation
            apply to the individuals who conduct the research as well as
            to the conditions being studied and to the way in which
            investigations are constructed and reported to the public<a
              href=""
              data-id="citation_reference_3"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_2"
                class="annotation superscript"
                style="position: unset"
                >1</span
              ></a
            ><span
              data-id="superscript_2"
              class="annotation superscript"
              style="position: unset"
              >-</span
            ><a
              href=""
              data-id="citation_reference_4"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_2"
                class="annotation superscript"
                style="position: unset"
                >7</span
              ></a
            >.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_10"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_10"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Although by no means specific to orthopaedic surgery, these
            concerns are particularly salient for the field given the
            substantive history and present-day reality of the low
            representation of women and minorities in orthopaedic training
            programs, academic faculties, and private practices<a
              href=""
              data-id="citation_reference_5"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_3"
                class="annotation superscript"
                style="position: unset"
                >1</span
              ></a
            ><span
              data-id="superscript_3"
              class="annotation superscript"
              style="position: unset"
              >-</span
            ><a
              href=""
              data-id="citation_reference_6"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_3"
                class="annotation superscript"
                style="position: unset"
                >3</span
              ></a
            >. For example, the number of practicing women orthopaedic
            surgeons as a percentage of all practitioners increased by
            &lt;2% from 2008 to 2018<a
              href=""
              data-id="citation_reference_7"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_4"
                class="annotation superscript"
                style="position: unset"
                >1</span
              ></a
            >. Moreover, although women make up &gt;50% of U.S. medical
            students, they represented only 19% of orthopaedic surgery
            resident applicants in 2020<a
              href=""
              data-id="citation_reference_8"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_5"
                class="annotation superscript"
                style="position: unset"
                >1</span
              ></a
            >. At the same time, however, there are signs of improvement
            on the horizon, as indicated by the increase between 1970 and
            2016 in the proportion of orthopaedic residents who are women,
            from 0.6% to 13%<a
              href=""
              data-id="citation_reference_9"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_6"
                class="annotation superscript"
                style="position: unset"
                >2</span
              ></a
            >.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_11"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_11"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Hand in hand with these changes in surgeon development,
            awareness has been growing regarding the need for both
            adequate representation for women in scientific investigations
            and a greater focus on sex and gender within research work on
            the whole. This extends as far back as 1994, when the National
            Institutes of Health (NIH) mandated the inclusion of women and
            racial and ethnic minorities as participants in funded
            clinical research, with additional consideration of these
            demographic groups in analytical plans<a
              href=""
              data-id="citation_reference_10"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_7"
                class="annotation superscript"
                style="position: unset"
                >5</span
              ></a
            >. This mandate was followed in 2010 by the requirement by the
            Canadian Institutes of Health Research that grant applicants
            answer questions regarding the extent to which research
            projects addressed issues related to sex and gender<a
              href=""
              data-id="citation_reference_11"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_8"
                class="annotation superscript"
                style="position: unset"
                >6</span
              ></a
            >. In 2014, the International Committee of Medical Journal
            Editors (ICMJE) published a guideline on the reporting of sex
            and gender; this guideline suggested that investigators
            include representative populations in studies incorporating
            sex and gender, delineate descriptive statistics regarding the
            sex of participants and other relevant demographic
            characteristics in all work, and stratify reporting by sex<a
              href=""
              data-id="citation_reference_12"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_9"
                class="annotation superscript"
                style="position: unset"
                >6</span
              ></a
            >. The European Commission went even further in 2020 when it
            maintained that all grant recipients must include analytical
            plans that address both sex and gender in funded research
            designs<a
              href=""
              data-id="citation_reference_13"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_10"
                class="annotation superscript"
                style="position: unset"
                >4</span
              ></a
            >.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_12"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_12"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Given that a sizable portion of the orthopaedic literature is
            derived from retrospective research and/or clinical registries
            and is not funded by the NIH, the response to these calls and
            mandates from regulatory bodies and funders was somewhat
            muted, with awareness seemingly increasing over the last 10 to
            15 years. In 2014, an editorial by Leopold et al. entitled
            “Fairness to All: Gender and Sex in Scientific Reporting” was
            published in
            <span
              data-id="emphasis_14"
              class="annotation emphasis"
              style="position: unset"
              >Clinical Orthopaedics and Related Research</span
            >. In this editorial, the authors declared that “This is not
            just a semantic issue. It is a health issue…Women have been
            underrepresented in medical research, and therefore the
            evidence that drives their care is less robust.”<a
              href=""
              data-id="citation_reference_14"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_11"
                class="annotation superscript"
                style="position: unset"
                >7</span
              ></a
            >
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_13"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_13"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            There are numerous conditions in the orthopaedic sphere that
            present differently in males and females or that have a
            tendency to develop in one sex more than the other<a
              href=""
              data-id="citation_reference_15"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_12"
                class="annotation superscript"
                style="position: unset"
                >7</span
              ></a
            >. Such conditions include ankylosing spondylitis, diffuse
            idiopathic skeletal hyperostosis, scoliosis, osteoporosis,
            Legg-Calvé-Perthes disease, and anterior cruciate ligament
            injuries<a
              href=""
              data-id="citation_reference_16"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_13"
                class="annotation superscript"
                style="position: unset"
                >7</span
              ></a
            ><span
              data-id="superscript_13"
              class="annotation superscript"
              style="position: unset"
              >,</span
            ><a
              href=""
              data-id="citation_reference_17"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_13"
                class="annotation superscript"
                style="position: unset"
                >8</span
              ></a
            >. Male patients may be at a greater risk for mortality
            following surgical intervention in the spine<a
              href=""
              data-id="citation_reference_18"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_14"
                class="annotation superscript"
                style="position: unset"
                >9</span
              ></a
            >, whereas female patients are known to have a greater risk of
            failure following hip resurfacing<a
              href=""
              data-id="citation_reference_19"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_15"
                class="annotation superscript"
                style="position: unset"
                >7</span
              ></a
            >. Female patients may experience higher rates of
            posttraumatic arthritis, more severe posttraumatic stress, and
            lower rates of diagnosed pain disorders after sustaining a
            musculoskeletal injury<a
              href=""
              data-id="citation_reference_20"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_16"
                class="annotation superscript"
                style="position: unset"
                >10</span
              ></a
            >. At the same time, female patients have been found to have
            elevated rates of sustained prescription opioid use following
            surgical interventions, as compared with male patients<a
              href=""
              data-id="citation_reference_21"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_17"
                class="annotation superscript"
                style="position: unset"
                >11</span
              ></a
            >.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_14"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_14"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Leopold et al. claimed that female patients experience less
            pain than male patients after a total joint replacement but
            postulated that this phenomenon could result from female
            patients reporting pain differently or having their pain
            understood differently by their surgeons, who are also more
            likely to be men<a
              href=""
              data-id="citation_reference_22"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_18"
                class="annotation superscript"
                style="position: unset"
                >7</span
              ></a
            >. This claim underscores the complex interaction of sex and
            gender-related influences. Leopold et al. also decried the
            fact that, although women represent 85% of individuals
            prescribed cyclooxygenase-2 (COX-2)-specific nonsteroidal
            drugs for pain, the evidence supporting the use of these
            medications is largely derived from trials that overwhelmingly
            consist of male patients<a
              href=""
              data-id="citation_reference_23"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_19"
                class="annotation superscript"
                style="position: unset"
                >7</span
              ></a
            >. In addition, the side effect profiles and life-threatening
            complications for COX-2-specific nonsteroidal drugs differ
            between males and females.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_15"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_15"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Substantial improvements on these and other uncharted fronts
            are only possible following a conscious decision on the part
            of the discipline as a whole to make evaluating sex and
            gender-based differences in orthopaedic research a priority.
            These efforts must involve clinicians, investigators,
            institutions, funders, and the peer-reviewed journals that not
            only serve as the ultimate repositories for research but also
            contextualize and publicize the findings. It is widely
            believed that improvements in academia will follow from
            changes made at the level of funding agencies and scientific
            journals, as these institutions are viewed as the arbiters of
            content validity. With this in mind, it is important to note
            that a 2016 survey published by the Sex and Gender Equity in
            Research (SAGER) group reported that, at that time, only 7% of
            journals had policies related to the reporting of sex and
            gender<a
              href=""
              data-id="citation_reference_24"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_20"
                class="annotation superscript"
                style="position: unset"
                >6</span
              ></a
            >. Furthermore, 75% of editorial staff were unsure about or
            unwilling to introduce sex and gender-related requirements in
            their guidelines for authors, with respondents’ openness to
            doing so differing on the basis of their reported sex<a
              href=""
              data-id="citation_reference_25"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_21"
                class="annotation superscript"
                style="position: unset"
                >6</span
              ></a
            >.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_16"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_16"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            These realities prompted the creation of the SAGER guidelines,
            which have subsequently been adopted and/or endorsed by many
            journals and funding agencies and, most recently, by the World
            Health Organization<a
              href=""
              data-id="citation_reference_26"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_22"
                class="annotation superscript"
                style="position: unset"
                >12</span
              ></a
            >. Akin to other commonly encountered guidelines such as the
            PRISMA (Preferred Reporting Items for Systematic reviews and
            Meta-Analyses), STROBE (Strengthening the Reporting of
            Observational Studies in Epidemiology), and CONSORT
            (Consolidated Standards Of Reporting Trials) statements, the
            SAGER guidelines provide a checklist to potentiate the
            systematic evaluation of a research paper with respect to
            considerations of sex and gender<a
              href=""
              data-id="citation_reference_27"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_23"
                class="annotation superscript"
                style="position: unset"
                >6</span
              ></a
            >. In contrast to these other reporting tools, however, the
            SAGER tool also provides recommendations on
            operationalization<a
              href=""
              data-id="citation_reference_28"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_24"
                class="annotation superscript"
                style="position: unset"
                >6</span
              ></a
            >. For example, the SAGER group recommends that the
            Introduction of a manuscript include a statement on whether
            sex and/or gender differences are relevant and expected based
            on the research question. For the Methods section, the group
            recommends reporting on how sex and gender were accounted for
            and whether representation was adequate. They also advocate
            that the Results section should routinely present findings
            disaggregated by sex and gender or state why such reporting
            was not feasible. Finally, the group supports the inclusion of
            a section in the Discussion on the potential effects of sex
            and gender on the study results and analyses.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_17"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_17"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            The SAGER group encourages journals to include the SAGER
            guidelines in instructions to authors and reviewers as a means
            of standardizing expectations around the methodological
            handling and reporting of sex and gender in research work. As
            part of The Journal of Bone and Joint Surgery Sex and Gender
            Reporting in Musculoskeletal Research Symposium in 2023, a
            panel of journal editors and other interested parties were
            asked to consider whether guidelines should be adopted and how
            to handle enforcement if they are to be adopted. Additionally,
            participants were asked to consider what else can be done on
            the part of peer-reviewed journals to improve the reporting of
            sex and gender in orthopaedic research.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-1"
      data-id="heading_5"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset"
          >Challenges in the Consideration of Sex and Gender in
          Orthopaedic Research</span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_18"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_18"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            It is important to note that many of the current sex and
            gender-related funding agency mandates, as well as the SAGER
            guidelines, are most readily applicable to prospective
            research and/or randomized controlled trials, along with basic
            science investigations in which consideration of the sex of
            experimental animals or cell lines is warranted. For example,
            the SAGER guidelines and similarly focused mandates require
            the consideration of sex and gender in power analyses
            supporting the research design. The SAGER guidelines also
            emphasize the need to define how the sex and/or gender of the
            participants was determined (e.g., self-report, sex assigned
            at birth, internal characteristics, genetic tests, gender
            identity) and to report data—including enrollment, dropouts,
            and loss to follow-up—disaggregated by sex and/or gender in
            primary and secondary analyses.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_19"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_19"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            This reporting is not practical in a retrospective research
            design because the data may have been collected in time
            periods and environments in which such granularity was not
            considered or in which the means of identifying sex and
            gender, or the degree to which they have been conflated,
            cannot be ascertained (<a
              href=""
              data-id="figure_reference_1"
              class="annotation figure_reference resource-reference"
              style="position: unset"
              >Table I</a
            >). In addition, concerns regarding the accuracy of available
            data regarding sex and gender in electronic health records
            suggest that the quality of retrospective studies utilizing
            such data sources may be compromised. In large-scale datasets
            such as those generated from health-care claims, the federal
            government, the National Trauma Data Bank (American College of
            Surgeons [ACS]), or the ACS National Surgical Quality
            Improvement Program, the categories of “male” and “female” are
            generally assumed to reflect biological sex, or sex assigned
            at birth.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node html-table"
      data-id="html_table_1"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node caption"
          data-id="caption_1"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            <div
              class="content-node paragraph"
              data-id="paragraph_20"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div
                  class="content-node text"
                  data-id="text_20"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    <span
                      class="table-caption-label"
                      style="position: unset"
                      >TABLE I</span
                    >Selected Challenges in Investigating Sex and Gender
                    in Retrospective Orthopaedic Research, with Suggested
                    Resolutions
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
          </div>
        </div>
        <div
          class="table-wrapper"
          style="position: unset; overflow: auto; height: 1412px"
        >
          <table
            frame="hsides"
            rules="groups"
            style="position: unset; transform: scale(1)"
          >
            <thead style="position: unset">
              <tr style="position: unset">
                <th style="position: unset">
                  <span class="text" style="position: unset"
                    >Challenge</span
                  >
                </th>
                <th align="center" style="position: unset">
                  <span class="text" style="position: unset"
                    >Suggested Resolution</span
                  >
                </th>
              </tr>
            </thead>
            <tbody style="position: unset">
              <tr style="position: unset">
                <td style="position: unset">
                  <span class="text" style="position: unset"
                    >Large-scale datasets and clinical registries lack
                    granularity on how sex and gender were collected or
                    defined</span
                  >
                </td>
                <td style="position: unset">
                  <span class="text" style="position: unset"
                    >Researchers should define assumptions regarding the
                    application of terms such as sex and gender in their
                    study dataset and denote any ambiguities.</span
                  >
                </td>
              </tr>
              <tr style="position: unset">
                <td style="position: unset">
                  <span class="text" style="position: unset"
                    >No prospect of adjusting the sample size or
                    recruiting more individuals in order to support
                    questions specifically around sex or gender</span
                  >
                </td>
                <td style="position: unset">
                  <span class="text" style="position: unset"
                    >Researchers should evaluate their sample and assess
                    what stratifications by sex and gender are possible
                    based on total sample size and event rates. Simple
                    bivariate reporting of sex and gender may be plausible
                    in many instances and will at least provide a sense of
                    the diversity and representativeness of the population
                    under consideration.</span
                  >
                </td>
              </tr>
              <tr style="position: unset">
                <td style="position: unset">
                  <span class="text" style="position: unset"
                    >The stratified analyses advocated by SAGER and other
                    groups may be underpowered to detect meaningful
                    differences</span
                  >
                </td>
                <td style="position: unset">
                  <span class="text" style="position: unset"
                    >Researchers may elect to present stratified
                    subanalyses as secondary results in supplemental
                    appendices. If the sample size is too small to support
                    meaningful evaluation or conclusions, this can be
                    noted as a limitation.</span
                  >
                </td>
              </tr>
              <tr style="position: unset">
                <td style="position: unset">
                  <span class="text" style="position: unset"
                    >Advanced analytical techniques such as propensity
                    score matching, instrumental variable analyses, and
                    hierarchical modeling may not be able to effectively
                    address differences in outcomes based on sex or
                    gender</span
                  >
                </td>
                <td style="position: unset">
                  <span class="text" style="position: unset"
                    >Researchers could consider performing interaction
                    analyses of sex and/or gender with the primary
                    predictor, when practicable.</span
                  >
                </td>
              </tr>
              <tr style="position: unset">
                <td style="position: unset">
                  <span class="text" style="position: unset"
                    >Post-hoc analyses that consider sex and gender may be
                    exploratory or may increase the potential for multiple
                    comparisons to confound the results of the work</span
                  >
                </td>
                <td style="position: unset">
                  <span class="text" style="position: unset"
                    >- Researchers should specify when analyses of the
                    influences of sex and gender are secondary or
                    exploratory.<br
                      data-id="break_1"
                      style="position: unset"
                    />- In certain contexts, adjusting for multiple
                    comparisons may be appropriate and should be deployed
                    when indicated.<br
                      data-id="break_2"
                      style="position: unset"
                    />- If researchers feel that secondary analyses
                    detract from the primary focus of the manuscript,
                    these results can be placed in supplemental
                    appendices.</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="footers" style="position: unset"></div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_21"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_21"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Because retrospective data are preexisting by definition,
            there is no prospect of adjusting the sample size or
            recruiting more individuals in order to support questions
            specifically around sex or gender. Such questions may not even
            be possible prima facie unless the dataset under consideration
            was specifically constructed to address issues related to sex
            and gender. Depending on the research question and the source
            data, the stratified analyses advocated by SAGER and other
            groups may be underpowered to detect meaningful differences.
            Furthermore, advanced analytical techniques such as propensity
            score matching, instrumental variable analyses, and
            hierarchical modeling may not be able to effectively address
            sex or gender-based differences in outcomes, or they may
            further obfuscate these features, depending on the nature of
            the source data.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_22"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_22"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Nonetheless, several of the recommendations from the SAGER
            guidelines are both reasonable and actionable in any context
            and are discussed in this report in the setting of
            retrospective investigations. These recommendations are
            remarkably well aligned with those of Leopold et al. from
            2014<a
              href=""
              data-id="citation_reference_29"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_25"
                class="annotation superscript"
                style="position: unset"
                >7</span
              ></a
            >, which actually antedate the SAGER publication. These
            recommendations are broadly applicable across research designs
            and would seem to be actionable in most research contexts.
            They include the following 4 suggestions: (1) at minimum,
            provide bivariate data regarding sex, for transparency; (2)
            evaluate the relationship between sex and the primary or
            secondary outcomes, or indicate why such analyses were not
            feasible, and highlight in this context any issues regarding
            sample size and power, which might also be addressed as a
            limitation; (3) correct for multiple comparisons, where
            appropriate, or highlight when an analysis of sex is
            exploratory; and (4) present significant findings or
            clinically relevant results with an eye toward the drawbacks
            of the methodological approach and the limitations of the
            sample with respect to sex.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-1"
      data-id="heading_7"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset"
          >Recommendations of the Symposium Panel</span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_23"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_23"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            The panel was of the opinion that the main barrier to the
            adequate reporting of sex and gender in orthopaedic research
            is a lack of awareness regarding the importance of this issue.
            In this regard, intentionality was felt to be the key to
            improvements going forward. The panel was supportive of
            endorsing SAGER<a
              href=""
              data-id="citation_reference_30"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_26"
                class="annotation superscript"
                style="position: unset"
                >6</span
              ></a
            >
            or a similar set of guidelines, such as that espoused by
            Leopold et al.<a
              href=""
              data-id="citation_reference_31"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_27"
                class="annotation superscript"
                style="position: unset"
                >7</span
              ></a
            >, as a tool that can help authors to consider the most
            effective means of evaluating the influences of sex and gender
            within the context of their research questions. At the same
            time, the panel did not believe that it was feasible to
            recommend or endorse a specific guideline as being applicable
            to all orthopaedic journals.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_24"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_24"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Implementation could be effectuated in a number of ways (<a
              href=""
              data-id="figure_reference_2"
              class="annotation figure_reference resource-reference"
              style="position: unset"
              >Table II</a
            >). Possibly the easiest of these methods would be to ask
            authors to complete the SAGER checklist (or a similar
            checklist) in conjunction with the submission of their
            manuscript. Another route would be to ask authors, in the
            standard questionnaire associated with the pre-submission
            upload of a manuscript, whether sex and/or gender were
            considered in the analyses performed and in the resultant
            report of the research outcomes. If sex and/or gender were not
            considered, the authors would be asked to disclose why. Such
            automated questions are already commonplace at many journals
            with respect to the use of a statistician for research
            projects, the upload of data to preprint servers, and the
            reliance on artificial intelligence for assistance in the
            generation of research manuscripts. However, failure to
            include such analyses in the research design or resultant
            manuscript would not necessarily constitute a red line
            regarding further consideration for publication in and of
            itself. Instead, it may result in the journal recommending
            that this omission and the potential ramifications thereof be
            mentioned as a limitation in the Discussion.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node html-table"
      data-id="html_table_2"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node caption"
          data-id="caption_2"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            <div
              class="content-node paragraph"
              data-id="paragraph_25"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div
                  class="content-node text"
                  data-id="text_25"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    <span
                      class="table-caption-label"
                      style="position: unset"
                      >TABLE II</span
                    >Selected Approaches to Improve Considerations of Sex
                    and Gender in Orthopaedic Research, with the
                    Anticipated Benefits
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
          </div>
        </div>
        <div
          class="table-wrapper"
          style="position: unset; overflow: auto; height: 1201px"
        >
          <table
            frame="hsides"
            rules="groups"
            style="position: unset; transform: scale(1)"
          >
            <thead style="position: unset">
              <tr style="position: unset">
                <th style="position: unset">
                  <span class="text" style="position: unset"
                    >Approach Mechanism</span
                  >
                </th>
                <th align="center" style="position: unset">
                  <span class="text" style="position: unset"
                    >Anticipated Benefit</span
                  >
                </th>
              </tr>
            </thead>
            <tbody style="position: unset">
              <tr style="position: unset">
                <td style="position: unset">
                  <span class="text" style="position: unset"
                    >Adopt SAGER guidelines or a similar tool as part of
                    the required documentation by authors during
                    manuscript submission</span
                  >
                </td>
                <td style="position: unset">
                  <span class="text" style="position: unset"
                    >- Increases awareness of the importance of
                    considering sex and gender in orthopaedic research<br
                      data-id="break_3"
                      style="position: unset"
                    />- Informs authors of ways in which sex and gender
                    can be considered in orthopaedic research<br
                      data-id="break_4"
                      style="position: unset"
                    />- Aids reviewers and members of the editorial team
                    in assessing how sex and gender were considered in
                    research protocols</span
                  >
                </td>
              </tr>
              <tr style="position: unset">
                <td style="position: unset">
                  <span class="text" style="position: unset"
                    >Pre-submission questionnaires on the inclusion of sex
                    and gender in orthopaedic research</span
                  >
                </td>
                <td style="position: unset">
                  <span class="text" style="position: unset"
                    >- Increases awareness of the importance of
                    considering sex and gender in orthopaedic research<br
                      data-id="break_5"
                      style="position: unset"
                    />- May prompt authors to consider such analyses in
                    the work they plan to submit</span
                  >
                </td>
              </tr>
              <tr style="position: unset">
                <td style="position: unset">
                  <span class="text" style="position: unset"
                    >Editorials</span
                  >
                </td>
                <td style="position: unset">
                  <span class="text" style="position: unset"
                    >- Increases awareness of the importance of
                    considering sex and gender in orthopaedic research<br
                      data-id="break_6"
                      style="position: unset"
                    />- Educates authors, reviewers, and readers on the
                    importance of the topic and on editorial board
                    expectations going forward</span
                  >
                </td>
              </tr>
              <tr style="position: unset">
                <td style="position: unset">
                  <span class="text" style="position: unset"
                    >Journal or publisher-developed webinars and
                    symposia</span
                  >
                </td>
                <td style="position: unset">
                  <span class="text" style="position: unset"
                    >- Increases awareness of the importance of
                    considering sex and gender in orthopaedic research<br
                      data-id="break_7"
                      style="position: unset"
                    />- Informs authors of ways in which sex and gender
                    can be considered in orthopaedic research<br
                      data-id="break_8"
                      style="position: unset"
                    />- Allows for subspecialty-specific context</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="footers" style="position: unset"></div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_26"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_26"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            The main outcome data could be disaggregated by sex and/or
            gender, with intersectionality considered whenever
            practicable. This would help readers to understand how diverse
            or representative the study population is, even if the
            influences of sex and gender are not pertinent to the
            analytical approach. Secondary testing could be presented with
            use of bivariate or multivariable approaches. If the sample is
            robust enough, assessing for an interaction between sex and
            the primary outcome of interest may also provide useful
            insights. Authors could elect to include secondary analyses
            regarding sex and gender in supplemental data if such analyses
            were felt to detract from the main research focus. The panel
            maintained that reviewer and editor expectations should be
            adjusted, in a culturally sensitive manner, to account for
            differences in the method of data collection and reporting in
            manuscripts generated outside of the U.S.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_27"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_27"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Editorials on new journal policies with respect to
            investigating sex and gender are 1 blanket approach to
            increase awareness and to educate authors, reviewers, and
            readers on the importance of this topic and the expectations
            of the editorial board going forward. Examples of such
            policy-based announcements can be found in
            <span
              data-id="emphasis_15"
              class="annotation emphasis"
              style="position: unset"
              >Nature</span
            ><a
              href=""
              data-id="citation_reference_32"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_28"
                class="annotation superscript"
                style="position: unset"
                >4</span
              ></a
            >
            and
            <span
              data-id="emphasis_16"
              class="annotation emphasis"
              style="position: unset"
              >The Lancet Rheumatology</span
            ><a
              href=""
              data-id="citation_reference_33"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_29"
                class="annotation superscript"
                style="position: unset"
                >5</span
              ></a
            >. It was also suggested that journals or their publishers
            could develop webinars and symposia in support of regulatory
            guidance and policy changes regarding the reporting of sex and
            gender in research manuscripts. This would help not only to
            increase awareness around this issue but also to educate
            authors and reviewers on the importance of considering sex and
            gender in future research design and in manuscripts developed
            for publication. In addition to addressing best practices for
            reporting, these webinars and symposia could highlight sex and
            gender-related approaches to research design, study sample and
            power, data collection, and analytic techniques, which may
            enable greater visibility and enhanced contextualization of
            these issues for the subspecialty communities affiliated with
            each peer-reviewed publication.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_28"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_28"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Although diversity within journal editorial boards is
            inherently valuable for numerous reasons, the panel cautioned
            against assuming that editorial board diversity would
            immediately translate to increased awareness regarding issues
            specific to sex and gender in orthopaedic research. The panel
            felt that intentionality on the part of editorial teams is
            necessary when addressing the reporting of sex and gender in
            orthopaedic research. In this light, the panel anticipated
            that efforts at engagement with authors and reviewers as well
            as outreach through educational endeavors in the ways
            highlighted above would create synergy in the setting of
            editorial board diversity and would lead to improvements that
            are greater than those that would have been achieved through
            reliance on the diversity of an editorial team alone.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_29"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_29"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            In line with the messaging around intentionality, the panel
            also believed that it is important to survey for improvements
            as well as for any unanticipated effects of policy changes
            specific to the reporting of sex and gender in orthopaedic
            research. Author and reviewer surveys administered on an
            annual basis as well as evaluations of the awareness of, and
            adherence to, the guidelines could be useful in assessing
            changes year over year. Changes in the number of manuscripts
            asking specific questions regarding sex and gender as well as
            changes in the number of manuscripts considering sex and
            gender in secondary ways would serve as useful measures of
            efficacy. Finally, the panel suggested the possibility of
            holding a 5-year follow-up conference that would replicate the
            patterns of this first JBJS-sponsored symposium, which would
            serve as a further step in gauging progress related to this
            important issue of health equity in orthopaedics.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-2"
      data-id="heading_6"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset"
          >Conclusions</span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_30"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_30"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            There are a variety of different ways in which orthopaedic
            journals may elect to introduce to their readers, reviewers,
            and authors the importance of considering sex and gender in
            musculoskeletal research. How this is done is best left to
            individual editorial teams and should be based on the
            perceived needs of the journal’s authors and readership. A
            number of potential suggestions have been presented, and each
            of these suggestions, alone or in combination, is likely to
            advance the field from where we stand at present. Engaging on
            multiple fronts, with purpose and intention, is certainly the
            most important approach to increase awareness and to enhance
            the quality of evidence utilized to support clinical
            decisions, in service of the patients whom we treat.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-1"
      data-id="undefined_1"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset">References</span>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_1"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        1&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_1"
            class="annotation"
            style="position: unset"
          >
            Gerull KM</span
          >,
          <span
            data-id="annotation_2"
            class="annotation"
            style="position: unset"
          >
            Salles A</span
          >,
          <span
            data-id="annotation_3"
            class="annotation"
            style="position: unset"
          >
            Porter SE</span
          >,
          <span
            data-id="annotation_4"
            class="annotation"
            style="position: unset"
          >
            Braman JP</span
          >.
          <span
            data-id="strong_1"
            class="annotation strong"
            style="position: unset"
            >Strategies for Recruiting and Retaining Women and Minorities
            in Orthopaedics: AOA Critical Issues Symposium</span
          >.
          <span
            data-id="emphasis_1"
            class="annotation emphasis"
            style="position: unset"
            >J Bone Joint Surg Am.</span
          >
          2021 Dec 15;103(24):e98.</span
        ><span class="jbjs" style="position: unset"
          ><a
            href="?rsuite_id=2926178"
            target="_new"
            class=""
            style="position: unset"
            >J Bone Joint Surg Am</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_2"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        2&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_5"
            class="annotation"
            style="position: unset"
          >
            Clohisy DR</span
          >,
          <span
            data-id="annotation_6"
            class="annotation"
            style="position: unset"
          >
            Yaszemski MJ</span
          >,
          <span
            data-id="annotation_7"
            class="annotation"
            style="position: unset"
          >
            Lipman J</span
          >.
          <span
            data-id="strong_2"
            class="annotation strong"
            style="position: unset"
            >Leadership, Communication, and Negotiation Across a Diverse
            Workforce*: An AOA Critical Issues Symposium</span
          >.
          <span
            data-id="emphasis_2"
            class="annotation emphasis"
            style="position: unset"
            >J Bone Joint Surg Am.</span
          >
          2017 Jun 21;99(12):e60.</span
        ><span class="jbjs" style="position: unset"
          ><a
            href="?rsuite_id=1331052"
            target="_new"
            class=""
            style="position: unset"
            >J Bone Joint Surg Am</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_3"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        3&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_8"
            class="annotation"
            style="position: unset"
          >
            Koshinski JL</span
          >,
          <span
            data-id="annotation_9"
            class="annotation"
            style="position: unset"
          >
            Xiong GX</span
          >,
          <span
            data-id="annotation_10"
            class="annotation"
            style="position: unset"
          >
            Agaronnik N</span
          >,
          <span
            data-id="annotation_11"
            class="annotation"
            style="position: unset"
          >
            Byrd C</span
          >,
          <span
            data-id="annotation_12"
            class="annotation"
            style="position: unset"
          >
            Call CM</span
          >,
          <span
            data-id="annotation_13"
            class="annotation"
            style="position: unset"
          >
            Enchill Z</span
          >,
          <span
            data-id="annotation_14"
            class="annotation"
            style="position: unset"
          >
            Giberson-Chen C</span
          >,
          <span
            data-id="annotation_15"
            class="annotation"
            style="position: unset"
          >
            Hering K</span
          >,
          <span
            data-id="annotation_16"
            class="annotation"
            style="position: unset"
          >
            Hislop C</span
          >,
          <span
            data-id="annotation_17"
            class="annotation"
            style="position: unset"
          >
            McGovern MM</span
          >,
          <span
            data-id="annotation_18"
            class="annotation"
            style="position: unset"
          >
            Parker A</span
          >,
          <span
            data-id="annotation_19"
            class="annotation"
            style="position: unset"
          >
            Schoenfeld AL</span
          >,
          <span
            data-id="annotation_20"
            class="annotation"
            style="position: unset"
          >
            Yuen LC</span
          >,
          <span
            data-id="annotation_21"
            class="annotation"
            style="position: unset"
          >
            Ihejirika-Lomedico R</span
          >,
          <span
            data-id="annotation_22"
            class="annotation"
            style="position: unset"
          >
            Lipa SA</span
          >.
          <span
            data-id="strong_3"
            class="annotation strong"
            style="position: unset"
            >Report of the “Women in Academic Spine” Initiative</span
          >.
          <span
            data-id="emphasis_3"
            class="annotation emphasis"
            style="position: unset"
            >Spine (Phila Pa 1976).</span
          >
          2023 Apr 15;48(8):515-8.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Report%20of%20the%20%E2%80%9CWomen%20in%20Academic%20Spine%E2%80%9D%20Initiative&as_occt=title&as_sauthors=%20%22JL%20Koshinski%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_4"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        4&nbsp;<span class="text" style="position: unset"
          >Nature Editorial.
          <span
            data-id="strong_4"
            class="annotation strong"
            style="position: unset"
            >Nature journals raise the bar on sex and gender reporting in
            research</span
          >.
          <span
            data-id="emphasis_4"
            class="annotation emphasis"
            style="position: unset"
            >Nature.</span
          >
          2022 May;605(7910):396.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Nature%20journals%20raise%20the%20bar%20on%20sex%20and%20gender%20reporting%20in%20research&as_occt=title&as_sauthors=%20%22Nature%20Editorial%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_5"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        5&nbsp;<span class="text" style="position: unset"
          >The Lancet Rheumatology.
          <span
            data-id="strong_5"
            class="annotation strong"
            style="position: unset"
            >Getting serious about sex and gender</span
          >.
          <span
            data-id="emphasis_5"
            class="annotation emphasis"
            style="position: unset"
            >Lancet Rheumatol.</span
          >
          2021 May;3(5):e313.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Getting%20serious%20about%20sex%20and%20gender&as_occt=title&as_sauthors=%20%22The%20Lancet%20Rheumatology%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_6"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        6&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_23"
            class="annotation"
            style="position: unset"
          >
            Heidari S</span
          >,
          <span
            data-id="annotation_24"
            class="annotation"
            style="position: unset"
          >
            Babor TF</span
          >,
          <span
            data-id="annotation_25"
            class="annotation"
            style="position: unset"
          >
            De Castro P</span
          >,
          <span
            data-id="annotation_26"
            class="annotation"
            style="position: unset"
          >
            Tort S</span
          >,
          <span
            data-id="annotation_27"
            class="annotation"
            style="position: unset"
          >
            Curno M</span
          >.
          <span
            data-id="strong_6"
            class="annotation strong"
            style="position: unset"
            >Sex and Gender Equity in Research: rationale for the SAGER
            guidelines and recommended use</span
          >.
          <span
            data-id="emphasis_6"
            class="annotation emphasis"
            style="position: unset"
            >Res Integr Peer Rev.</span
          >
          2016 May 3;1:2.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%20and%20Gender%20Equity%20in%20Research%3A%20rationale%20for%20the%20SAGER%20guidelines%20and%20recommended%20use&as_occt=title&as_sauthors=%20%22S%20Heidari%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_7"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        7&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_28"
            class="annotation"
            style="position: unset"
          >
            Leopold SS</span
          >,
          <span
            data-id="annotation_29"
            class="annotation"
            style="position: unset"
          >
            Beadling L</span
          >,
          <span
            data-id="annotation_30"
            class="annotation"
            style="position: unset"
          >
            Dobbs MB</span
          >,
          <span
            data-id="annotation_31"
            class="annotation"
            style="position: unset"
          >
            Gebhardt MC</span
          >,
          <span
            data-id="annotation_32"
            class="annotation"
            style="position: unset"
          >
            Lotke PA</span
          >,
          <span
            data-id="annotation_33"
            class="annotation"
            style="position: unset"
          >
            Manner PA</span
          >,
          <span
            data-id="annotation_34"
            class="annotation"
            style="position: unset"
          >
            Rimnac CM</span
          >,
          <span
            data-id="annotation_35"
            class="annotation"
            style="position: unset"
          >
            Wongworawat MD</span
          >.
          <span
            data-id="strong_7"
            class="annotation strong"
            style="position: unset"
            >Fairness to all: gender and sex in scientific reporting</span
          >.
          <span
            data-id="emphasis_7"
            class="annotation emphasis"
            style="position: unset"
            >Clin Orthop Relat Res.</span
          >
          2014 Feb;472(2):391-2.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fairness%20to%20all%3A%20gender%20and%20sex%20in%20scientific%20reporting&as_occt=title&as_sauthors=%20%22SS%20Leopold%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_8"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        8&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_36"
            class="annotation"
            style="position: unset"
          >
            Schoenfeld AJ</span
          >,
          <span
            data-id="annotation_37"
            class="annotation"
            style="position: unset"
          >
            Harris MB</span
          >,
          <span
            data-id="annotation_38"
            class="annotation"
            style="position: unset"
          >
            McGuire KJ</span
          >,
          <span
            data-id="annotation_39"
            class="annotation"
            style="position: unset"
          >
            Warholic N</span
          >,
          <span
            data-id="annotation_40"
            class="annotation"
            style="position: unset"
          >
            Wood KB</span
          >,
          <span
            data-id="annotation_41"
            class="annotation"
            style="position: unset"
          >
            Bono CM</span
          >.
          <span
            data-id="strong_8"
            class="annotation strong"
            style="position: unset"
            >Mortality in elderly patients with hyperostotic disease of
            the cervical spine after fracture: an age- and sex-matched
            study</span
          >.
          <span
            data-id="emphasis_8"
            class="annotation emphasis"
            style="position: unset"
            >Spine J.</span
          >
          2011 Apr;11(4):257-64.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mortality%20in%20elderly%20patients%20with%20hyperostotic%20disease%20of%20the%20cervical%20spine%20after%20fracture%3A%20an%20age-%20and%20sex-matched%20study&as_occt=title&as_sauthors=%20%22AJ%20Schoenfeld%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_9"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        9&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_42"
            class="annotation"
            style="position: unset"
          >
            Schoenfeld AJ</span
          >,
          <span
            data-id="annotation_43"
            class="annotation"
            style="position: unset"
          >
            Reamer EN</span
          >,
          <span
            data-id="annotation_44"
            class="annotation"
            style="position: unset"
          >
            Wynkoop EI</span
          >,
          <span
            data-id="annotation_45"
            class="annotation"
            style="position: unset"
          >
            Choi H</span
          >,
          <span
            data-id="annotation_46"
            class="annotation"
            style="position: unset"
          >
            Bono CM</span
          >.
          <span
            data-id="strong_9"
            class="annotation strong"
            style="position: unset"
            >Does patient sex affect the rate of mortality and
            complications after spine surgery? A systematic review</span
          >.
          <span
            data-id="emphasis_9"
            class="annotation emphasis"
            style="position: unset"
            >Clin Orthop Relat Res.</span
          >
          2015 Aug;473(8):2479-86.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20patient%20sex%20affect%20the%20rate%20of%20mortality%20and%20complications%20after%20spine%20surgery%3F%20A%20systematic%20review&as_occt=title&as_sauthors=%20%22AJ%20Schoenfeld%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_10"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        10&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_47"
            class="annotation"
            style="position: unset"
          >
            Rivera JC</span
          >,
          <span
            data-id="annotation_48"
            class="annotation"
            style="position: unset"
          >
            Hylden CM</span
          >,
          <span
            data-id="annotation_49"
            class="annotation"
            style="position: unset"
          >
            Johnson AE</span
          >.
          <span
            data-id="strong_10"
            class="annotation strong"
            style="position: unset"
            >Disability After Deployment Injury: Are Women and Men Service
            Members Different?</span
          ><span
            data-id="emphasis_10"
            class="annotation emphasis"
            style="position: unset"
            >Clin Orthop Relat Res.</span
          >
          2015 Aug;473(8):2448-54.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Disability%20After%20Deployment%20Injury%3A%20Are%20Women%20and%20Men%20Service%20Members%20Different%3F&as_occt=title&as_sauthors=%20%22JC%20Rivera%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_11"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        11&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_50"
            class="annotation"
            style="position: unset"
          >
            Crawford AM</span
          >,
          <span
            data-id="annotation_51"
            class="annotation"
            style="position: unset"
          >
            Striano BM</span
          >,
          <span
            data-id="annotation_52"
            class="annotation"
            style="position: unset"
          >
            Gong J</span
          >,
          <span
            data-id="annotation_53"
            class="annotation"
            style="position: unset"
          >
            Koehlmoos TP</span
          >,
          <span
            data-id="annotation_54"
            class="annotation"
            style="position: unset"
          >
            Simpson AK</span
          >,
          <span
            data-id="annotation_55"
            class="annotation"
            style="position: unset"
          >
            Schoenfeld AJ</span
          >.
          <span
            data-id="strong_11"
            class="annotation strong"
            style="position: unset"
            >Validation of the Stopping Opioids after Surgery (SOS) Score
            for the sustained use of prescription opioids following
            orthopaedic surgery</span
          >.
          <span
            data-id="emphasis_11"
            class="annotation emphasis"
            style="position: unset"
            >J Bone Joint Surg Am.</span
          >
          2023 Sep 20;105(18):1403-9.</span
        ><span class="jbjs" style="position: unset"
          ><a
            href="?rsuite_id=018e2352-f324-4ec9-80bc-add9d7ea4556"
            target="_new"
            class=""
            style="position: unset"
            >J Bone Joint Surg Am</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_12"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        12&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_56"
            class="annotation"
            style="position: unset"
          >
            Heidari S</span
          >,
          <span
            data-id="annotation_57"
            class="annotation"
            style="position: unset"
          >
            Fernandez DGE</span
          >,
          <span
            data-id="annotation_58"
            class="annotation"
            style="position: unset"
          >
            Coates A</span
          >,
          <span
            data-id="annotation_59"
            class="annotation"
            style="position: unset"
          >
            Hosseinpoor AR</span
          >,
          <span
            data-id="annotation_60"
            class="annotation"
            style="position: unset"
          >
            Asma S</span
          >,
          <span
            data-id="annotation_61"
            class="annotation"
            style="position: unset"
          >
            Farrar J</span
          >,
          <span
            data-id="annotation_62"
            class="annotation"
            style="position: unset"
          >
            Kenney EM</span
          >.
          <span
            data-id="strong_12"
            class="annotation strong"
            style="position: unset"
            >WHO’s adoption of SAGER guidelines and GATHER: setting
            standards for better science with sex and gender in mind</span
          >.
          <span
            data-id="emphasis_12"
            class="annotation emphasis"
            style="position: unset"
            >Lancet.</span
          >
          2024 Jan 20;403(10423):226-8.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=WHO%E2%80%99s%20adoption%20of%20SAGER%20guidelines%20and%20GATHER%3A%20setting%20standards%20for%20better%20science%20with%20sex%20and%20gender%20in%20mind&as_occt=title&as_sauthors=%20%22S%20Heidari%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <a
      href=""
      data-id="eletter_reference"
      class="annotation eletter_reference resource-reference"
      style="position: unset"
    ></a>
    <div
      class="content-node eletter-submit"
      data-id="eletters"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="label">eLetters</div>
        <div class="link" style="position: unset">
          <a
            href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dc71e9f83-99e3-4071-99c9-ab6c546ca0d0%26native%3D1"
            target="_blank"
            class=""
            style="position: unset"
            ><img
              class="image"
              src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
              style="position: unset"
            /><span class="label">Submit an eLetter</span></a
          >
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-1"
      data-id="heading_8"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset"
          >Additional information</span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node publication-info"
      data-id="publication_info"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="meta-data" style="position: unset">
          <div class="journal" style="position: unset">
            <div class="label">Journal</div>
            <div class="value" style="position: unset">
              <span class="" style="position: unset"
                >The Journal of Bone and Joint Surgery</span
              >
            </div>
          </div>
          <div class="subject" style="position: unset">
            <div class="label">Section</div>
            <div class="value" style="position: unset">
              Symposium Series
            </div>
          </div>
          <div class="publishing" style="position: unset">
            <div class="label">Published</div>
            <div class="value" style="position: unset">
              <span class="" style="position: unset"
                >September 4, 2024; 106 (17): 1610</span
              >
            </div>
          </div>
          <div class="doi" style="position: unset">
            <div class="label">DOI</div>
            <div class="value" style="position: unset">
              <span class="" style="position: unset"
                >10.2106/JBJS.24.00035</span
              >
            </div>
          </div>
          <div class="dates" style="position: unset">
            The article was first published on
            <b class="" style="position: unset">July 29, 2024</b>.
          </div>
        </div>
        <div
          class="content-node paragraph"
          data-id="articleinfo"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            <div
              class="content-node paragraph"
              data-id="paragraph_6"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div
                  class="content-node text"
                  data-id="text_6"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    <span
                      data-id="emphasis_13"
                      class="annotation emphasis"
                      style="position: unset"
                      >Investigation performed at the Department of
                      Orthopaedic Surgery, Brigham and Women’s Hospital,
                      Harvard Medical School, Boston, Massachusetts</span
                    >
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node heading level-3"
              data-id="heading_3"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <span class="text title" style="position: unset"
                  >Copyright & License</span
                >
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node paragraph"
              data-id="paragraph_7"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div
                  class="content-node text"
                  data-id="text_7"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    Copyright © 2024 by The Journal of Bone and Joint
                    Surgery, Incorporated.
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node supplement"
              data-id="supplement_1"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div class="file" style="position: unset">
                  <span class="" style="position: unset"
                    >Open article PDF</span
                  ><a
                    class="jbjs_tracking gtm_tracking"
                    jbjs_tracking_type="download"
                    jbjs_tracking_data='{"id":"c71e9f83-99e3-4071-99c9-ab6c546ca0d0","type":"article","topics":[]}'
                    href="https://www.jbjs.org/reader.php?rsuite_id=c71e9f83-99e3-4071-99c9-ab6c546ca0d0&type=pdf&name=JBJS.24.00035.pdf"
                    target="_blank"
                    gtm_action="reader"
                    gtm_category="PDF_article_downloads"
                    gtm_label="https://www.jbjs.org/reader.php?rsuite_id=c71e9f83-99e3-4071-99c9-ab6c546ca0d0&type=pdf&name=JBJS.24.00035.pdf"
                    jbjs_tracking_source="reader"
                    style="position: unset"
                    ><img
                      src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                      style="position: unset"
                    />
                    Download</a
                  >
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node heading level-3"
              data-id="heading_4"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <span class="text title" style="position: unset"
                  >Disclosures of Potential Conflicts of Interest</span
                >
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node paragraph"
              data-id="paragraph_8"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div
                  class="content-node text"
                  data-id="text_8"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    <span
                      data-id="strong_13"
                      class="annotation strong"
                      style="position: unset"
                      >Disclosure:</span
                    >
                    Research reported in this publication was supported by
                    the National Institute of Arthritis and
                    Musculoskeletal and Skin Diseases of the National
                    Institutes of Health under Award Number R13AR082710.
                    The content is solely the responsibility of the
                    authors and does not necessarily represent the
                    official views of the National Institutes of Health.
                    The
                    <span
                      data-id="strong_14"
                      class="annotation strong"
                      style="position: unset"
                      >Disclosure of Potential Conflicts of Interest</span
                    >
                    form is provided with the online version of the
                    article (<a
                      href="http://links.lww.com/JBJS/I105"
                      target="_blank"
                      data-id="link_1"
                      class="link"
                      style="position: unset"
                      >http://links.lww.com/JBJS/I105</a
                    >).
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node supplement"
              data-id="supplement_2"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div class="file" style="position: unset">
                  <span class="" style="position: unset"
                    >Disclosures of Potential Conflicts of Interest</span
                  ><a
                    class="jbjs_tracking"
                    jbjs_tracking_type="download"
                    jbjs_tracking_data='{"id":"c71e9f83-99e3-4071-99c9-ab6c546ca0d0","type":"disclosure","topics":[]}'
                    href="/php/content/content_api.php?op=download&rsuite_id=c71e9f83-99e3-4071-99c9-ab6c546ca0d0&type=zip&name=JBJS.24.00035.disclosure.zip&subtype=disclosure"
                    target="_blank"
                    jbjs_tracking_source="reader"
                    style="position: unset"
                    ><i class="fa fa-download" style="position: unset">
                      Download</i
                    ></a
                  >
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-3"
      data-id="heading_1_author_list"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset">Authors</span>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node contributor"
      data-id="contributor_1"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="contributor-name" style="position: unset">
          Andrew J. Schoenfeld, MD, MSc<span
            data-id="affiliation_reference_1"
            class="label annotation cross_reference"
            >1</span
          >
        </div>
        <div class="affiliations" style="position: unset">
          <span class="" style="position: unset"></span>
        </div>
        <div class="emails contrib-data" style="position: unset">
          <span class="contrib-label" style="position: unset"
            >For correspondence: </span
          ><span class="" style="position: unset"
            ><a
              href="mailto:aschoenfeld@bwh.harvard.edu"
              class=""
              style="position: unset"
              >aschoenfeld@bwh.harvard.edu</a
            ></span
          >
        </div>
        <div class="contrib-data" style="position: unset">
          <img
            class="orcid"
            src="https://www.jbjs.org/images/orcid_logo.png"
            style="position: unset"
          /><a
            href="https://orcid.org/0000-0002-3691-1215"
            target="_blank"
            class="orcid"
            style="position: unset"
            >https://orcid.org/0000-0002-3691-1215</a
          >
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node affiliation"
      data-id="affiliation_1"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="label">1</span
        ><span class="text" style="position: unset"
          >Department of Orthopaedic Surgery, Brigham and Women’s
          Hospital, Harvard Medical School, Boston, Massachusetts</span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <a class="logo" href="home.php" style="position: unset"></a>
  </div>
</div>
<div
  class="surface-scrollbar content hidden"
  style="display: none; position: unset"
>
  <div
    class="visible-area"
    style="top: 0px; height: 20072.1px; position: unset"
  ></div>
</div>
</div>
</div>
</div>
`,
};
