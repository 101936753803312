/* -----------------Child components--------------- */
import { CustomAppbar } from "components/index";

/* -----------------Types--------------- */
import { ProfileContainer } from "@features/dashboard";
import { useDevice } from "@hooks/useDevice";
import { useAuthContext } from "@memorang/applets";
import DocumentTitle from "components/DocumentTitle";

const ProfileRoute = () => {
	const { newUserInfo } = useAuthContext();
	const userName = newUserInfo?.name;

	const { isMobile } = useDevice();

	return (
		<>
			<CustomAppbar
				showCommandBar={false}
				showHambugerMenu
				showRightAction={false}
				back
				options={{
					headerTitle: userName ?? "",
					subtitle: isMobile ? "" : "Your profile",
				}}
			/>
			<DocumentTitle title="Profile" />
			<ProfileContainer />
		</>
	);
};

export default ProfileRoute;
