import type { ProductType } from "@memorang/types";
import { DialogWrapperWeb } from "@memorang/ui";
/* -----------------UI--------------- */
import { Dialog as PaperDialog } from "react-native-paper";
import PurchaseContainer from "./PurchaseContainer";

type Props = {
	visible: boolean;
	handleClose: () => void;
	productType?: ProductType;
	fromPracticeTestPage?: boolean;
	fromHomePage?: boolean;
};
export const PurchaseDialog = ({
	visible,
	handleClose,
	productType,
	fromPracticeTestPage,
	fromHomePage,
}: Props) => {
	const getTitle = () => {
		switch (productType) {
			case "STUDY_PACK":
				return "Upgrade Your Study Plan";
			case "PRACTICE_TEST":
				return "Unlock Practice Tests";
			default:
				return "Upgrade";
		}
	};
	const title = getTitle();
	return (
		<DialogWrapperWeb visible={visible} handleClose={handleClose}>
			<PaperDialog.Title style={{ textAlign: "center" }}>
				{title}
			</PaperDialog.Title>
			<PurchaseContainer
				fromPracticeTestPage={fromPracticeTestPage}
				handleClose={handleClose}
				productType={productType}
				fromHomePage={fromHomePage}
			/>
		</DialogWrapperWeb>
	);
};
