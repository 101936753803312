/* ----------------- Globals --------------- */
import { SectionList } from "react-native";

/* ----------------- Components --------------- */
import { ChallengesFolderList } from "./ChallengesFolderList";

import { mockedChallengeViewStats } from "@constants/mocked-image-challenge-data";
import { Box, FeaturedChallengeSection } from "@memorang/ui";
import RowStats from "components/RowStats";
/* ----------------- Types --------------- */
import type {
	ChallengesSectionList,
	RecommendedChallenge,
} from "types/image-challenge";
import { Header } from "./Header";

type Props = {
	featuredChallenge?: RecommendedChallenge;
	challengesSectionList: ChallengesSectionList[];
	showViewAll?: boolean;
	handleNavigateToChallengesFolder: (id: string) => void;
	handleClickFeaturedChallenge?: () => void;
};
const ChallengesFolderView = ({
	featuredChallenge,
	challengesSectionList,
	showViewAll = true,
	handleNavigateToChallengesFolder,
	handleClickFeaturedChallenge,
}: Props) => {
	return (
		<SectionList
			sections={challengesSectionList}
			renderSectionHeader={({ section }) => {
				return <Header {...section} showViewAll={showViewAll} />;
			}}
			contentContainerStyle={{
				paddingBottom: 100,
			}}
			showsVerticalScrollIndicator={false}
			keyExtractor={(_item, index) => index.toString()}
			ListHeaderComponent={() => {
				return (
					<Box gap={8} paddingTop={8}>
						<RowStats stats={mockedChallengeViewStats} />
						{featuredChallenge && (
							<FeaturedChallengeSection
								handleClickFeaturedChallenge={handleClickFeaturedChallenge}
								featuredChallenge={featuredChallenge}
							/>
						)}
					</Box>
				);
			}}
			renderItem={({ item }) => {
				return (
					<ChallengesFolderList
						challenges={item}
						handleNavigateToChallengesFolder={handleNavigateToChallengesFolder}
					/>
				);
			}}
		/>
	);
};

export default ChallengesFolderView;
