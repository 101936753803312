import { List, type ListItemProps } from "react-native-paper";

const CustomListItem = (props: ListItemProps) => {
	return (
		<List.Item
			{...props}
			titleStyle={{
				fontFamily: "OpenSans-SemiBold",
			}}
			descriptionStyle={{
				fontSize: 12,
				fontFamily: "OpenSans-SemiBold",
			}}
		/>
	);
};

export default CustomListItem;
