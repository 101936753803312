import { ENDPOINT_AIRTABLE_FEEDBACK } from "../../qa-search/constants";
import { RUN_LANGSMITH_URL } from "./../constants/index";
import type { FeedbackParams } from "./../types/index";

export const sendFeedback = async (
	params: FeedbackParams,
	feedback: string,
) => {
	const {
		userName,
		email,
		appName,
		type = "Feature",
		bundleId,
		bundleName,
		tenantId,
		currentAppVersion,
		context,
		feature,
		sessionId,
		product,
		itemId,
		view,
		platform,
		runId,
	} = params;

	const body = JSON.stringify({
		companyName: tenantId,
		appName,
		appVersion: currentAppVersion,
		userName,
		email,
		feedback,
		type,
		context,
		view,
		platform,
		bundleId,
		bundleName,
		feature,
		sessionId,
		product,
		itemId,
		...(runId ? { runId: RUN_LANGSMITH_URL + runId } : {}),
	});

	await fetch(ENDPOINT_AIRTABLE_FEEDBACK, {
		method: "POST",
		body,
	});
};
