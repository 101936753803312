/* -----------------Globals--------------- */
import { useSafeAreaInsets } from "react-native-safe-area-context";

/* -----------------UI--------------- */
import { Button, List, Surface } from "react-native-paper";
import { useAppTheme } from "../../useAppTheme";
import useBreakpoints from "../../useBreakpoints";
import { CustomListSubheader } from "../CustomListSubheader";

type Props = {
	cta: string;
	handleAction: () => void;
	disabled: boolean;
	subheader: string;
	title: string;
};
export const StickyActionFooter = ({
	cta,
	handleAction,
	disabled,
	subheader,
	title,
}: Props) => {
	const insets = useSafeAreaInsets();
	const theme = useAppTheme();
	const { xsDown } = useBreakpoints();
	return (
		<Surface
			style={{
				position: "absolute",
				bottom: 0,
				left: 0,
				right: 0,
				gap: 16,
				paddingTop: 8,
				paddingBottom: insets.bottom,
			}}
		>
			{xsDown && (
				<>
					<CustomListSubheader
						style={{
							paddingBottom: 8,
						}}
					>
						{subheader}
					</CustomListSubheader>
					<List.Item
						title={title}
						style={{
							backgroundColor: theme.colors.surfaceDisabled,
						}}
						left={(p) => {
							return <List.Icon {...p} icon={"check"} />;
						}}
					/>
				</>
			)}
			<Button
				style={{
					marginHorizontal: 16,
				}}
				disabled={disabled}
				mode="contained"
				onPress={handleAction}
			>
				{cta}
			</Button>
		</Surface>
	);
};
