export const createRelativeURL = (
	path: string,
	params: Record<string, string | number | boolean>,
) => {
	let url = path;
	const queryParams = Object.entries(params)
		.filter(([, value]) => value !== undefined)
		.map(([key, value]) => `${encodeURIComponent(key)}=${value}`)
		.join("&");

	if (queryParams) {
		url += `?${queryParams}`;
	}

	return url;
};
