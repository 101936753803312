/* -----------------Types--------------- */
import type { ReportSessionType, SessionItem } from "@memorang/types";

import { useExamContext } from "@features/exam";
import { useAppTheme } from "@hooks/useAppTheme";
import { useDevice } from "@hooks/useDevice";
/* -----------------Components--------------- */
import { Box } from "@memorang/ui";
import { ScrollView } from "react-native";
import { getSessionStartDialogConfig } from "../constants/dialog-configs";
import StudySessionBottombarContainer from "../containers/StudySessionBottombarContainer";
/* -----------------Containers--------------- */
import StudySessionFeedbackContainer from "../containers/StudySessionFeedbackContainer";
import useSessionStore from "../hooks/useSessionStore";
import { useStartDialogStore } from "../hooks/useShowStartDialog";
import DialogSession from "./SessionDialog";
import SessionListDesktop from "./SessionList/SessionListDesktop";
import { StudySessionAppBarContainer } from "./StudySessionAppBarContainer";

type Props = {
	sessionItems: SessionItem[];
	sessionId: string;
	currentBlockIndex: number;
	totalTime: number;
	inReviewMode?: boolean;
	reportSessionType?: ReportSessionType;
};
const PracticeSessionView = ({
	sessionItems,
	sessionId,
	currentBlockIndex,
	totalTime,
	inReviewMode,
	reportSessionType,
}: Props) => {
	const {
		currentExamContext: { examId },
	} = useExamContext();

	const { isMobile } = useDevice();
	const { startDialogShownKeys, setStartDialogShown } = useStartDialogStore();
	const examName = useSessionStore((state) => state.examName);

	const theme = useAppTheme();

	const showStartDialog = !(startDialogShownKeys?.[examId] || inReviewMode);

	const finalSessionItems = sessionItems[currentBlockIndex].children;
	const numSections = sessionItems.length;

	const handleCloseStartDialog = () => {
		setStartDialogShown(examId);
	};

	const RenderStartDialog = () => {
		if (showStartDialog) {
			const numTotalQuestions = sessionItems.reduce(
				(acc, item) => acc + item.children.length,
				0,
			);

			const customConfig = getSessionStartDialogConfig({
				examName: examName!,
				totalTime,
				isQuiz: reportSessionType === "DIAGNOSTIC",
				numSections,
				numTotalQuestions,
			});
			return (
				<DialogSession
					customConfig={customConfig}
					open
					handleClose={handleCloseStartDialog}
					handleClickButton={handleCloseStartDialog}
				/>
			);
		}
		return null;
	};

	return (
		<Box
			// TODO: What is this gap?
			gap={isMobile ? 8 : 0}
			flex={1}
			backgroundColor={theme.colors.background}
		>
			<StudySessionAppBarContainer />
			<Box
				flex={1}
				backgroundColor={theme.colors.surface}
				paddingTop={isMobile ? 0 : 24}
			>
				<ScrollView
					showsVerticalScrollIndicator={false}
					contentContainerStyle={{
						gap: 16,
						flexGrow: 1,
						paddingBottom: 180,
					}}
				>
					<SessionListDesktop items={finalSessionItems as SessionItem[]} />
					<StudySessionFeedbackContainer />
				</ScrollView>
				<StudySessionBottombarContainer />
			</Box>
			<RenderStartDialog />
		</Box>
	);
};

export default PracticeSessionView;
