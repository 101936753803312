import type { Article } from "../../../../../types";

export const Article586: Article = {
	id: 586,
	rsuiteId: "4f1da912-6324-4e97-8651-e7d41b816a69",
	type: "commentary and perspective",
	title:
		"The Impact of Malnutrition on Healing After Arthroscopic Rotator Cuff Repair",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/586.jpeg",
	subSpecialties: ["shoulder_elbow"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">The Impact of Malnutrition on Healing After Arthroscopic
                Rotator Cuff Repair</div>
              <div class="text subtitle" style="position: unset;">Commentary on an article by Hitoshi Shitara, MD, PhD,
                et al.: “Preoperative Nutrition Impacts Retear Rate After Arthroscopic Rotator Cuff Repair”</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Eric T.
                      Ricchetti, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">Arthroscopic rotator cuff repair (ARCR) has been shown to
                  reliably reduce pain and improve shoulder function. However, the retear rate following ARCR can range
                  from 10% to 94%<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>
                  depending on tear characteristics such as size, degree of retraction, and presence of fatty
                  infiltration. While these and other risk factors for retears have been identified in the literature<a
                    href="" data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">1</span></a>, very few can be modified in an attempt to improve surgical
                  healing rates. One modifiable factor, preoperative malnutrition, has been shown to lead to worse
                  outcomes and increased complications after other types of surgery<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">5</span></a>, but
                  there are few reported studies on the impact of this factor on healing rates after ARCR. Shitara et
                  al. attempted to elucidate the potential role of nutritional status as a modifiable risk factor after
                  ARCR.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">The authors measured the preoperative Geriatric
                  Nutritional Risk Index (GNRI), a useful tool for evaluating nutritional status in geriatric patients
                  and in the surgical setting<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">5</span></a>, in a retrospective cohort of 143 patients aged 65 years and
                  older who underwent ARCR and had a minimum 2-year clinical and imaging follow-up. Magnetic resonance
                  imaging (MRI) was performed 2 years after surgery in all patients to assess rotator cuff repair
                  healing. The GNRI was assessed as a risk factor for retears using an extensive multivariable analysis
                  that adjusted for other confounding or independent risk factors for retears, including tear
                  characteristics (size, retraction, fatty infiltration, critical shoulder angle, repair technique),
                  age, serum albumin level, and presence of diabetes, osteoporosis, or hyperlipidemia. The GNRI was
                  found to be a significant independent risk factor for retears as seen on MRI at 2-year follow-up (odds
                  ratio [OR]: 3.39, 95% confidence interval [CI]: 1.22 to 9.41) when using established thresholds for
                  low nutritional risk (GNRI: 92 to ≤98) versus no nutritional risk (GNRI: &gt;98)<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">5</span></a>; this
                  result was not distorted by collinearity with other independent variables and was validated by
                  sensitivity analyses. Stratum-specific likelihood ratio (SSLR) analysis was then employed to establish
                  data-driven GNRI thresholds (GNRI &lt; 103, 103 ≤ GNRI &lt; 109, and GNR ≥ 109) in the study cohort.
                  GNRI &lt; 103 was found to be a significant independent risk factor for retears seen on MRI at 2-year
                  follow-up when compared with the other 2 cutoff ranges (103 ≤ GNRI &lt; 109 and GNR ≥ 109).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">Shitara et al. should be commended for this well-performed
                  study evaluating the effect of preoperative nutritional status on healing after ARCR. As stated, there
                  are few reported studies on this topic in the published literature, and this is the first study to
                  identify malnutrition as defined by the GNRI as an independent risk factor for retears after ARCR in
                  patients 65 years and older. Interestingly, the degree of malnutrition that affects rotator cuff
                  healing may be less than previously thought, as the authors found that the GNRI threshold that was a
                  significant risk factor for retears in their study cohort was higher than the previously established
                  threshold (GNRI &lt; 103 versus GNRI ≤ 98).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">If appropriately validated, a threshold such as the one
                  proposed by Shitara et al. has the potential to be used as target for optimizing a modifiable factor
                  preoperatively to improve surgical outcome. This is similar to the use of hemoglobin A1c thresholds in
                  diabetic patients to optimize glucose control prior to surgery. Despite advances in surgical
                  techniques for ARCR, reported retear rates have remained relatively stable in the literature.
                  Therefore, investigating modifiable factors that may influence the biology of rotator cuff healing,
                  such as nutritional status, may be more impactful in improving rotator cuff repair healing rates over
                  time than further improvements in the mechanical strength of the repair.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">Additional data are needed to build upon the findings of
                  the study by Shitara et al., which was retrospective and had a relatively small sample size. While it
                  is impressive that the authors brought back 80% of the patients with complete preoperative data for
                  the minimum 2-year clinical and MRI follow-up, 52 patients were excluded from the study because
                  incomplete preoperative data prevented calculation of the GNRI. Larger, prospective studies, including
                  those aiming to improve the GNRI preoperatively to directly assess its impact on rotator cuff repair
                  healing, are needed in the future to determine if the GNRI or other nutritional measures should be
                  routinely obtained to assess healing potential in patients undergoing ARCR.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Zhao J</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Luo M</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Pan J</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Liang G</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Feng W</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Zeng L</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Yang W</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Liu J</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Risk factors affecting rotator
                  cuff retear after arthroscopic repair: a meta-analysis and systematic review</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2021 Nov;30(11):2660-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20affecting%20rotator%20cuff%20retear%20after%20arthroscopic%20repair%3A%20a%20meta-analysis%20and%20systematic%20review&as_occt=title&as_sauthors=%20%22J%20Zhao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Puvanesarajah V</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Jain A</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Kebaish K</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Shaffrey CI</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Sciubba DM</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> De la Garza-Ramos R</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Khanna AJ</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Hassanzadeh H</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Poor Nutrition Status and Lumbar
                  Spine Fusion Surgery in the Elderly: Readmissions, Complications, and Mortality</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2017 Jul 1;42(13):979-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Poor%20Nutrition%20Status%20and%20Lumbar%20Spine%20Fusion%20Surgery%20in%20the%20Elderly%3A%20Readmissions%2C%20Complications%2C%20and%20Mortality&as_occt=title&as_sauthors=%20%22V%20Puvanesarajah%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Flamant EM</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Goltz DE</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Burnett RA</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Wickman JR</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Belay ES</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Saltzman EB</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Nicholson GP</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Garrigues GE</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Lassiter T</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Anakwenze OA</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Klifto CS</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Malnutrition in elective
                  shoulder arthroplasty: a multi-institutional retrospective study of preoperative albumin and adverse
                  outcomes</span>. <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2021 Nov;30(11):2491-1-2.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Malnutrition%20in%20elective%20shoulder%20arthroplasty%3A%20a%20multi-institutional%20retrospective%20study%20of%20preoperative%20albumin%20and%20adverse%20outcomes&as_occt=title&as_sauthors=%20%22EM%20Flamant%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Bouillanne O</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Morineau G</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Dupont C</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Coulombel I</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Vincent JP</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Nicolis I</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Benazeth S</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Cynober L</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Aussel C</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Geriatric Nutritional Risk
                  Index: a new index for evaluating at-risk elderly medical patients</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Am J Clin Nutr.</span> 2005
                Oct;82(4):777-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Geriatric%20Nutritional%20Risk%20Index%3A%20a%20new%20index%20for%20evaluating%20at-risk%20elderly%20medical%20patients&as_occt=title&as_sauthors=%20%22O%20Bouillanne%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Fang CJ</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Saadat GH</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Butler BA</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Bokhari F</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">The Geriatric Nutritional Risk
                  Index Is an Independent Predictor of Adverse Outcomes for Total Joint Arthroplasty Patients</span>.
                <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span>
                2022 Aug;37(8S):S836-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Geriatric%20Nutritional%20Risk%20Index%20Is%20an%20Independent%20Predictor%20of%20Adverse%20Outcomes%20for%20Total%20Joint%20Arthroplasty%20Patients&as_occt=title&as_sauthors=%20%22CJ%20Fang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D4f1da912-6324-4e97-8651-e7d41b816a69%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Commentary and Perspective</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): e47</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00716</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 20, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=dc9062df-67b7-434a-bc2e-09cc8339edd0&native=1"
                              class="article-title" style="position: unset;">Preoperative Nutrition Impacts Retear Rate
                              After Arthroscopic Rotator Cuff Repair</a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Shitara,
                              Hitoshi; Ichinose, Tsuyoshi; Sasaki, Tsuyoshi; Hamano, Noritaka; Kamiyama, Masataka;
                              Miyamoto, Ryosuke; Ino, Fukuhisa; Nakase, Kurumi; Honda, Akira; Yamamoto, Atsushi;
                              Takagishi, Kenji; Chikuda, Hirotaka</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(22):2111 | August 30, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;4f1da912-6324-4e97-8651-e7d41b816a69&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=4f1da912-6324-4e97-8651-e7d41b816a69&type=pdf&name=JBJS.24.00716.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=4f1da912-6324-4e97-8651-e7d41b816a69&type=pdf&name=JBJS.24.00716.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_6" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_7"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I172" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I172</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;4f1da912-6324-4e97-8651-e7d41b816a69&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=4f1da912-6324-4e97-8651-e7d41b816a69&type=zip&name=JBJS.24.00716.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Eric T. Ricchetti, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:ricchee@ccf.org" class="" style="position: unset;">ricchee@ccf.org</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4371-7998" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4371-7998</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Cleveland Clinic, Cleveland, Ohio</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 5666.45px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
