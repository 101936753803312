import { relayEnv } from "@memorang/configs";
import { fetchData } from "@memorang/helpers";
import type { MixedItemDistResponse } from "@memorang/types";
/* -----------------Globals--------------- */
import { graphql } from "react-relay";

const query = graphql`
  query FetchCumulativeContentsDistQuery(
    $bundleId: ID!
    $dashboardAction: DashboardAction
  ) {
    getMixedItemDistribution(
      bundleId: $bundleId
      dashboardAction: $dashboardAction
    ) {
      freeTagIds
      accuracy
      speed
      contents {
        id
        totalCount
        distribution {
          low
          medium
          high
        }
      }
      exams {
        id
        title
        legacyReport {
          numericId
          sessionDate
          numItems
          score
        }
        numItems
        numericId
        numCompleted
        status
        time
        timeElapsed
        totalTime
        attempted {
          low
          medium
          high
        }
        dateCompleted
        distribution {
          low
          medium
          high
        }
      }
      tags {
        id
        title
        displayType
        numCorrect
        numIncorrect
        weight
        type
        items {
          id
          distribution
          type
        }
      }
    }
  }
`;

const fetchCumulativeContentsDist = async (bundleId: string) => {
	const variables = {
		bundleId,
		dashboardAction: "COMMON_PROGRESS_QUERY",
	};

	const { getMixedItemDistribution } = (await fetchData(
		relayEnv,
		query,
		variables,
	)) as MixedItemDistResponse;

	return {
		getMixedItemDistribution,
	};
};

export default fetchCumulativeContentsDist;
