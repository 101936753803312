import { Platform } from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";

const isMobile = Platform.OS === "android" || Platform.OS === "ios";

if (isMobile) {
	require("react-native-gesture-handler");
}

type Props = {
	children: React.ReactNode;
};

export const GestureHandlerRootHOC = ({ children }: Props) => {
	if (isMobile) {
		return (
			<GestureHandlerRootView
				style={{
					flex: 1,
				}}
			>
				{children}
			</GestureHandlerRootView>
		);
	}

	return <>{children}</>;
};
