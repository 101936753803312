import { formatCount } from "@memorang/helpers";
import type { SessionItem } from "@memorang/types";
export type DialogType =
	| "pause"
	| "completeSection"
	| "completeTest"
	| "timerExpired";

const getAverageTimePerQuestion = (mins: number, numTotalQuestions: number) => {
	return ((mins * 60) / numTotalQuestions).toFixed(0);
};
export const helpDialog = ({
	sessionItems,
	totalTime,
	examName,
	isDiagnosticTest,
	numSections,
	numTotalQuestions,
}: {
	sessionItems: SessionItem[];
	totalTime: number;
	examName: string;
	isDiagnosticTest: boolean;
	numSections: number;
	numTotalQuestions: number;
}) => {
	const numQuestions = sessionItems.length;
	const mins = totalTime / 60;

	const averageTimePerQuestion = getAverageTimePerQuestion(
		mins,
		numTotalQuestions,
	);

	return {
		triggerButton: {
			label: "Help",
			icon: "help-circle-outline",
		},
		title: "Help",
		icon: "help-circle-outline",
		body: `
  #### Overview
  * You’re taking a${
		isDiagnosticTest
			? ` ${examName} Diagnostic`
			: ` full-length Practice ${examName}.`
	}
  ${
		numSections > 1
			? `* This test has ${numSections} sections of ${numQuestions} questions each.
  * There are a total of ${numTotalQuestions} questions.`
			: ""
	}
  * Once you have completed the test you will receive a detailed score report.
  #### Time
  * The total time allotted to complete the exam is ${Number(
		(mins / 60).toFixed(2),
	)} hours, or ${mins} minutes.
  * You have an average of ${averageTimePerQuestion} seconds per question.
  * The total time remaining is displayed in the top right.
  #### Answering Questions
  * Choose the best answer choice.
  * Right click on an answer choice to toggle crossing it out.
  #### Navigation
  * Click Next, Prev, or Skip to move between questions.
  * You can freely navigate between questions ${
		numSections > 1
			? "within a section, but once you move to the next section you cannot go back."
			: ""
	}
  #### Highlighter
  * In the top-left toolbar you have a highlighter. You may use this to highlight text in the stem of the question.
  #### Cross Out
  * Right click on an answer choice to cross it out and un-cross it out.
  `,
		confirmCta: "Close",
	};
};

export const dialogSessionConfigs: Record<
	DialogType,
	{
		title: string;
		icon: string;
		content: string;
		buttons?: {
			label: string;
			type: "confirm" | "cancel";
		}[];
	}
> = {
	pause: {
		title: "Pause Test",
		icon: "pause-circle-outline",
		content: "Are you sure you want to pause this test? You can resume later",
		buttons: [
			{
				label: "Cancel",
				type: "cancel",
			},
			{
				label: "Confirm",
				type: "confirm",
			},
		],
	},
	completeSection: {
		title: "Complete Section",
		icon: "check-outline",
		content:
			"You have completed all the items in this section. Once you move on to the **next section** you will not be able to go back to view or your answers.",
		buttons: [
			{
				label: "Cancel",
				type: "cancel",
			},
			{
				label: "Next Section",
				type: "confirm",
			},
		],
	},
	completeTest: {
		title: "Finish Test",
		icon: "flag-checkered",
		content:
			"You have attended all the items in this test. Click **finish** to end your test and see your results.",
		buttons: [
			{
				label: "Cancel",
				type: "cancel",
			},
			{
				label: "Finish",
				type: "confirm",
			},
		],
	},
	timerExpired: {
		title: "Time Expired",
		icon: "timer-sand-complete",
		content: "Your time has expired and your test will now be submitted.",
	},
};

export const getSessionStartDialogConfig = ({
	examName,
	totalTime,
	isQuiz,
	numSections,
	numTotalQuestions,
}: {
	examName: string;
	totalTime: number;
	isQuiz?: boolean;
	numSections: number;
	numTotalQuestions: number;
}) => {
	const mins = totalTime / 60;

	const averageTimePerQuestion = getAverageTimePerQuestion(
		mins,
		numTotalQuestions,
	);
	const topic = examName.split(": ") ? examName.split(": ")[1] : examName;

	const fullLengthText =
		numSections > 1 && !isQuiz
			? `In this full-length **${examName}** practice test, you will be tasked with complete **${formatCount(
					"section",
					numSections,
					true,
				)}** and a total of **${numTotalQuestions} questions**`
			: `In this${
					isQuiz ? "" : " full-length"
				} **${examName}** practice test, you will be tasked with complete **${numTotalQuestions} questions**`;
	const contentQuiz = `Welcome to your **diagnostic assessment** on the topic of **${topic.trim()}**.  
  The total time allotted to complete the exam is ${Number(
		(mins / 60).toFixed(2),
	)} hours, or ${mins.toFixed(0)} minutes.  
  You have an average of ${averageTimePerQuestion} seconds per question.  
  Feel free to navigate between questions as you work through them.  
  Once you have completed the test you will receive a detailed score report. Good luck!`;
	return {
		title: examName,
		icon: "clipboard-check-outline",
		content: isQuiz
			? contentQuiz
			: `${fullLengthText}.   
You can freely navigate between questions${
					numSections > 1
						? " within a section, but once you move to the next section you cannot go back"
						: ""
				}.  
        The total time allotted to complete the exam is **${Number(
					(mins / 60).toFixed(2),
				)} hours, or ${mins.toFixed(0)} minutes**.  
        You have an average of **${averageTimePerQuestion} seconds** per question.  
        Once you have completed the test you will receive a detailed score report${
					numSections > 1 ? " and have the ability to review your answers" : ""
				}. Good luck!`,
		buttons: [
			{
				label: "Continue",
				type: "confirm" as const,
			},
		],
	};
};
