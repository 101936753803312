/* ----------------- Globals --------------- */
import type { TagItem } from "@helpers/content/types";
import { create } from "zustand";

interface TagState {
	currentSelectedMasteryItems: TagItem[];
	setCurrentSelectedMasteryItems: (items: TagItem[]) => void;
}

/* ----------------- Store Creation --------------- */
export const useTagsStore = create<TagState>((set) => ({
	currentSelectedMasteryItems: [],
	setCurrentSelectedMasteryItems: (items) =>
		set(() => ({ currentSelectedMasteryItems: items })),
}));
