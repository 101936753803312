import { Platform } from "react-native";
/* ======== Components ========= */
import { FAB, type FABProps } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";

type Props = {
	bottom?: number;
} & FABProps;
const BottomFab = ({ onPress, icon, label, bottom }: Props) => {
	const theme = useAppTheme();

	const insets = useSafeAreaInsets();

	return (
		<Box
			style={{
				position: "absolute",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				right: 16,
				bottom: bottom || insets.bottom + (Platform.OS === "ios" ? 0 : 16),
			}}
		>
			<FAB
				onPress={onPress}
				style={{
					maxWidth: "100%",
					backgroundColor: theme.colors.primary,
				}}
				color={theme.colors.onPrimary}
				icon={icon}
				label={label!}
				variant="primary"
			/>
		</Box>
	);
};

export default BottomFab;
