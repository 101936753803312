import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";
/* ----------------- Local Imports --------------- */
import ProductCard from "./ProductCard";
import type { ProductListProps } from "./types";

/* ----------------- Third Party Imports --------------- */
import { Text } from "react-native-paper";

const ProductList = ({
	title,
	productCards,
	selectedProductId,
	purchasedProduct,
	handleSelectProduct,
}: ProductListProps) => {
	const theme = useAppTheme();

	return (
		<Box alignItems="center" gap={8} width="100%">
			<Text
				variant="labelLarge"
				style={{ color: theme.colors.onSurfaceVariant }}
			>
				{title}
			</Text>

			<Box
				flexDirection="row"
				gap={8}
				width="100%"
				height="100%"
				justifyContent="center"
				flex={1}
			>
				{productCards.map((card) => (
					<ProductCard
						key={card.id}
						{...card}
						disabled={purchasedProduct === card.id}
						selected={selectedProductId === card.id}
						handleSelectProduct={handleSelectProduct}
					/>
				))}
			</Box>
		</Box>
	);
};

export default ProductList;
