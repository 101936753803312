import { useCallback, useEffect, useRef, useState } from "react";

const padTime = (time: number): string => time.toString().padStart(2, "0");

const useCountDown = (
	initialTime = 60000,
	shouldStart?: boolean,
	interval = 1000,
) => {
	const [timeLeft, setTimeLeft] = useState(initialTime);
	const intervalRef = useRef<NodeJS.Timeout | null>(null);

	const start = useCallback(
		(newTime?: number) => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
			setTimeLeft(typeof newTime === "number" ? newTime : initialTime);
			intervalRef.current = setInterval(() => {
				setTimeLeft((prevTime) => prevTime - interval);
			}, interval) as unknown as NodeJS.Timeout;
		},
		[initialTime, interval],
	);

	const pause = useCallback(() => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}
	}, []);

	const resume = useCallback(() => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}
		intervalRef.current = setInterval(() => {
			setTimeLeft((prevTime) => prevTime - interval);
		}, interval) as unknown as NodeJS.Timeout;
	}, [interval]);

	const reset = useCallback(() => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}
		setTimeLeft(initialTime);
	}, [initialTime]);

	useEffect(() => {
		if (shouldStart) {
			start();
		}
		return pause;
	}, [start, pause, shouldStart]);

	const totalSeconds = Math.floor(timeLeft / 1000);
	const absTotalSeconds = Math.abs(totalSeconds);
	const seconds = absTotalSeconds % 60;
	const minutes = Math.floor(absTotalSeconds / 60) % 60;
	const hours = Math.floor(absTotalSeconds / 3600) % 24;
	const days = Math.floor(absTotalSeconds / 86400);

	const formattedTime = `${padTime(days)}:${padTime(hours)}:${padTime(minutes)}:${padTime(seconds)}`;

	return {
		days: padTime(days),
		hours: padTime(hours),
		minutes: padTime(minutes),
		seconds: padTime(seconds),
		isNegative: totalSeconds < 0,
		start,
		pause,
		resume,
		reset,
		formattedTime,
	};
};

export default useCountDown;
