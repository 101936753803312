import type { Article } from "../../../../../types";

export const Article658: Article = {
	id: 658,
	rsuiteId: "003776c2-daee-4ddd-8ddf-63fbfb25e784",
	type: "scientific article",
	title:
		"Effect of Preoperative Acupuncture and Epidural Steroid Injection on Early Postoperative Infection After Lumbar Spinal Fusion",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=003776c2-daee-4ddd-8ddf-63fbfb25e784&type=jpeg&name=JBJS.23.00721f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Effect of Preoperative Acupuncture and Epidural Steroid
                Injection on Early Postoperative Infection After Lumbar Spinal Fusion</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Sahyun Sung,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ji-Won Kwon,
                      MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 8 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_42" class="annotation strong"
                          style="position: unset;">Background:</span> Acupuncture and epidural steroid injection (ESI)
                        are frequently performed in patients with degenerative lumbar disease. The purpose of this study
                        was to explore preoperative acupuncture and ESI as risk factors for postoperative infection
                        after elective lumbar fusion.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_43" class="annotation strong"
                          style="position: unset;">Methods:</span> Patients &gt;50 years of age who underwent spinal
                        fusion due to degenerative lumbar disease from 2010 to 2019 were identified by diagnostic and
                        procedural codes using a nationwide database. The incidence of spinal infection within 90 days
                        after surgery was identified. Patients who underwent acupuncture and/or ESI within 90 days prior
                        to spinal surgery were identified using procedural codes. The infection rate was analyzed by
                        dividing patients into 4 groups as follows: patients who underwent neither acupuncture nor ESI
                        (unexposed group), patients who underwent acupuncture only (acupuncture group), patients who
                        underwent ESI only (ESI group), and patients who underwent both acupuncture and ESI (combined
                        group). Cox regression analysis was performed to identify risk factors for postoperative spinal
                        infection.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_44" class="annotation strong"
                          style="position: unset;">Results:</span> A total of 207,806 patients were included in this
                        study. The postoperative infection rate among all patients was 4.29%. The infection rates in the
                        unexposed, acupuncture, ESI, and combined groups were 4.17% (4,342 of 104,106 patients), 3.90%
                        (340 of 8,726 patients), 4.48% (3,761 of 83,882 patients), and 4.26% (473 of 11,092 patients),
                        respectively. Increasing age, male sex, and ESI were demonstrated to be risk factors for
                        postoperative spinal infection. ESI was no longer a risk factor when patients who received
                        acupuncture or ESI within 2 weeks of spinal surgery were excluded. Preoperative acupuncture was
                        not associated with postoperative spinal infection.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_45" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Acupuncture and ESI performed &gt;2 weeks prior
                        to spinal surgery did not increase the risk of postoperative infection.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_46" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Although the efficacy of acupuncture is controversial, it
                  is a widely used alternative treatment. Over 3 million people in the United States received
                  acupuncture in 2007, and this number has continued to increase with time<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. Back pain is experienced by &gt;70% of people at least once
                  in their lifetime<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">3</span></a>. Acupuncture is a frequently employed alternative medicine
                  for treating lower back pain. It is known to alleviate pain by regulating endogenous neuropeptides by
                  stimulating the trigger point of pain<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">7</span></a>. There have been reports on several complications of
                  acupuncture, and a few of those complications have been fatal<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">11</span></a>. MacPherson et al. reported a complication rate of 0.13%
                  among approximately 34,000 cases of acupuncture treatment, as assessed using a self-reported survey of
                  practitioners<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">8</span></a>.
                  Hematoma and infection are common complications<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">12</span></a>, and
                  there have been several reports of infection after acupuncture treatment for the lower back and
                  radiating pain<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">13</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">14</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Epidural steroid injection (ESI) is a widely used
                  nonoperative treatment method for pain control in patients with degenerative spinal disorders<a
                    href="" data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">19</span></a>.
                  Recently, several studies have reported that preoperative ESI is a risk factor for postoperative
                  infection after spinal surgery<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">20</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">22</span></a>. However, few studies have investigated whether
                  preoperative acupuncture increases the risk of postoperative infection after spinal surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The National Health Insurance system of our country covers
                  almost the entire national population<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">23</span></a> and
                  also has covered alternative medicine such as acupuncture and herbal medicine since 1987<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">24</span></a>. Therefore, many patients receive acupuncture to treat back
                  pain because the treatment is covered by the national insurance. Approximately 30% of all acupuncture
                  treatments are performed for the treatment of back pain. The claims data provide the dates that
                  acupuncture and ESIs were performed. Therefore, this study aimed to investigate preoperative
                  acupuncture and ESI as risk factors for postoperative infection after lumbar fusion using nationwide
                  claims data.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">This study was approved by the institutional review board
                  of the corresponding author’s hospital. The requirement for informed consent was waived because the
                  study involved the retrospective use of anonymized and publicly available data.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Selection and Definition of Postoperative Infection</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The Health Insurance Review and Assessment (HIRA) service
                  of our country is a public agency that evaluates the adequacy of insurance claims. The claims data
                  include prescribed medications, procedures, diagnoses, demographic data, and comorbidities<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">27</span></a>. Data of insurance claims were collected from January 2010
                  to December 2019. Patients &gt;50 years of age who underwent elective lumbar fusion surgery for
                  degenerative diseases were included. Patients who received treatment for specific diseases, such as
                  ankylosing spondylitis or spinal tumors, in the 3 months prior to spinal surgery and patients who had
                  a spinal infection within 3 months or on the day of spinal surgery were excluded.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Identification of ESI and Acupuncture</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">ESI and acupuncture were identified using procedural
                  codes. Patients who underwent epidural, root, and facet blocks in the thoracolumbar region and those
                  who underwent intervertebral acupuncture within 90 days prior to lumbar fusion surgery were
                  identified. Patients who underwent acupuncture and nerve blocks after spinal surgery were excluded
                  from this study. The patients were divided into 4 groups on the basis of the type(s) of preoperative
                  procedure they received: those who received neither acupuncture nor ESI (“unexposed group”), those who
                  underwent acupuncture only (“acupuncture group”), those who underwent ESI only (“ESI group”), and
                  those who underwent both procedures before spinal surgery (“combined group”).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Incidence of
                and Risk Factors for Postoperative Infection After Spinal Surgery</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The primary outcome of this study was the occurrence of
                  postoperative infection. Postoperative infection was considered present if diagnostic codes relating
                  to spinal infection were registered within 90 days of the date of spinal surgery (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). Risk factors associated with postoperative infection after
                  lumbar fusion surgery were evaluated. The Charlson Comorbidity Index (CCI) was used to identify
                  underlying diseases.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>International Classification of Diseases (ICD)
                          Diagnosis Codes for Postoperative Spinal Infection</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 465px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Diagnosis</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ICD
                          Diagnosis Code</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Osteomyelitis of
                          vertebra</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">M46.20-29</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Infection of
                          intervertebral disc (pyogenic)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">M46.30-39</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Other infective
                          spondylopathies</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">M46.50-59</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Disruption of operative
                          wound</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T81.3</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Infection following a
                          procedure</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T81.4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Complications of internal
                          orthopaedic prosthetic devices, implants, and grafts</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T84.2-9</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Infection and
                          inflammatory reaction due to other internal prosthetic devices, implants, and grafts</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">T85.7</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Number of
                Procedures and the Date of the Last Procedure Prior to Spinal Surgery</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">We investigated whether the number of preoperative
                  acupuncture and ESI sessions had an effect on the postoperative infection rate. We divided the number
                  of treatment sessions into 0, 1, 2, 3, and &gt;3 each for acupuncture and for ESI, and analyzed
                  whether the number of sessions was associated with an increase in the risk of infection. Moreover, the
                  duration between the last procedure and spinal surgery was calculated, and it was divided into ≤2
                  weeks, &gt;2 weeks to 4 weeks, and &gt;4 weeks to 12 weeks before surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">One-way analysis of variance (ANOVA) was performed for
                  continuous variables, and the chi-square test was performed for categorical variables, when comparing
                  characteristics between groups. Univariate and multivariable Cox regression analyses were performed to
                  identify the factors associated with the risk of postoperative infection. All statistical tests were
                  2-sided, and p values of &lt;0.05 were considered significant. SAS software (version 9.4; SAS
                  Institute) was used for the statistical analyses.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">A total of 237,178 patients &gt;50 years of age underwent
                  elective lumbar spinal surgery between 2010 and 2019. Of the 207,806 patients who met the inclusion
                  criteria, 104,106 were in the unexposed group, 8,726 were in the acupuncture group, 83,882 were in the
                  ESI group, and 11,092 were in the combined group (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00721f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=003776c2-daee-4ddd-8ddf-63fbfb25e784&type=jpeg&name=JBJS.23.00721f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Diagram of patient groups and corresponding
                          infection rates from our analysis of nationwide claims data from the Health Insurance Review
                          and Assessment (HIRA) service. ESI = epidural steroid injection.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Postoperative infection occurred in 8,916 patients,
                  resulting in a total infection rate of 4.29%, with a mean onset period (and standard deviation) of
                  30.60 ± 18.99 days. The infection rate in each group was as follows: 4.17% (4,342 of 104,106) in the
                  unexposed group, 3.90% (340 of 8,726) in the acupuncture group, 4.48% (3,761 of 83,882) in the ESI
                  group, and 4.26% (473 of 11,092) in the combined group (p = 0.0022). The pairwise comparisons showed
                  that there was a significant difference in the infection rate between the unexposed group and the ESI
                  group (p = 0.0027).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The mean age at surgery was the highest in the combined
                  group (67.89 ± 7.63 years), followed by the acupuncture (67.01 ± 7.83 years), ESI (65.96 ± 8.01
                  years), and unexposed (64.79 ± 8.13 years) groups (p &lt; 0.0001) (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). The proportion of female patients in the acupuncture group
                  was significantly higher than in the other groups (p &lt; 0.0001) (<a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Patient Characteristics, Comorbidities, and
                          Infection-Related Variables for Each Group<span data-id="table_footnote_reference_5"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1656.19px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.682573);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total (N =
                          207,806)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Unexposed
                          (N = 104,106)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Acupuncture (N = 8,726)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ESI (N =
                          83,882)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Combined
                          (N = 11,092)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at surgery<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_42" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65.52 ±
                          8.09</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64.79 ±
                          8.13</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67.01 ±
                          7.83</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65.96 ±
                          8.01</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67.89 ±
                          7.63</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_50" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;65 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">93,719
                          (45.10)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51,062
                          (49.05)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,994
                          (34.31)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36,271
                          (43.24)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,392
                          (30.58)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_51" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥65 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">114,087
                          (54.90)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53,044
                          (50.95)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,732
                          (65.69)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47,611
                          (56.76)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7,700
                          (69.42)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_52" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_53" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74,467
                          (35.83)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37,161
                          (35.70)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,656
                          (30.44)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31,076
                          (37.05)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,574
                          (32.22)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">133,339
                          (64.17)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66,945
                          (64.30)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,070
                          (69.56)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52,806
                          (62.95)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7,518
                          (67.78)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8,916
                          (4.29)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,342
                          (4.17)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">340
                          (3.90)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,761
                          (4.48)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">473
                          (4.26)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_54" class="annotation strong" style="position: unset;">0.0022</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Age at surgery</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_55" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  &lt;65 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,876
                          (43.47)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,087
                          (48.07)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">109
                          (32.06)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,559
                          (41.45)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">121
                          (25.58)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  ≥65 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,040
                          (56.53)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,255
                          (51.93)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">231
                          (67.94)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,202
                          (58.55)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">352
                          (74.42)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_56" class="annotation strong" style="position: unset;">0.0126</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,923
                          (44.00)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,899
                          (43.74)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">134
                          (39.41)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,706
                          (45.36)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">184
                          (38.90)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,993
                          (56.00)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,443
                          (56.26)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">206
                          (60.59)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,055
                          (54.64)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">289
                          (61.10)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Reoperation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">160
                          (0.08)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65
                          (0.06)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (0.14)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71
                          (0.08)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (0.11)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_57" class="annotation strong" style="position: unset;">0.0285</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CCI<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.00 ±
                          1.86</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.84 ±
                          1.82</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.00 ±
                          1.82</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.16 ±
                          1.89</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.32 ±
                          1.90</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_58" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Myocardial
                          infarction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,615
                          (1.26)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,277
                          (1.23)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">103
                          (1.18)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,080
                          (1.29)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">155
                          (1.40)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.3118</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Congestive heart
                          failure</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11,650
                          (5.61)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,379
                          (5.17)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">461
                          (5.28)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,056
                          (6.03)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">754
                          (6.80)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_59" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Peripheral vascular
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46,077
                          (22.17)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19,855
                          (19.07)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,941
                          (22.24)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21,032
                          (25.07)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,249
                          (29.29)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_60" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cerebrovascular
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24,836
                          (11.95)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11,917
                          (11.45)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,119
                          (12.82)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10,250
                          (12.22)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,550
                          (13.97)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_61" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dementia</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,847
                          (1.85)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,528
                          (1.47)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">188
                          (2.15)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,812
                          (2.16)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">319
                          (2.88)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_62" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Chronic pulmonary
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55,582
                          (26.75)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25,648
                          (24.64)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,397
                          (27.47)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24,186
                          (28.83)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,351
                          (30.21)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_63" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Rheumatic disease</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17,412
                          (8.38)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7,971
                          (7.66)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">680
                          (7.79)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7,679
                          (9.15)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,082
                          (9.75)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_64" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Peptic ulcer
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59,855
                          (28.80)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27,011
                          (25.95)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,542
                          (29.13)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26,509
                          (31.60)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,793
                          (34.20)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_65" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mild liver
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46,158
                          (22.21)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21,200
                          (20.36)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,855
                          (21.26)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20,382
                          (24.30)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,721
                          (24.53)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_66" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diabetes without chronic
                          complications</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60,707
                          (29.21)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28,024
                          (26.92)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,539
                          (29.10)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26,458
                          (31.54)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,686
                          (33.23)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_67" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diabetes with chronic
                          complications</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25,237
                          (12.14)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11,861
                          (11.39)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,067
                          (12.23)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10,817
                          (12.90)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,492
                          (13.45)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_68" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hemiplegia or
                          paraplegia</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,100
                          (1.01)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,047
                          (1.01)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">93
                          (1.07)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">850
                          (1.01)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">110
                          (0.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.9531</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Renal disease</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,893
                          (2.35)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,505
                          (2.41)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">202
                          (2.31)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,955
                          (2.33)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">231
                          (2.08)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.1686</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Any malignancy</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9,522
                          (4.58)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,697
                          (4.51)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">387
                          (4.44)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,873
                          (4.62)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">565
                          (5.09)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_69" class="annotation strong" style="position: unset;">0.0369</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Moderate or severe liver
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">553
                          (0.27)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">282
                          (0.27)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (0.24)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">220
                          (0.26)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (0.27)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.9491</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Metastatic solid
                          tumor</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">355
                          (0.17)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">175
                          (0.17)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (0.17)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">143
                          (0.17)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (0.20)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.9102</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> AIDS/HIV<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean onset period<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_43" class="annotation emphasis"
                            style="position: unset;">(days)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.60 ±
                          18.99</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.86 ±
                          18.58</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.16 ±
                          19.40</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.26 ±
                          19.27</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.42 ±
                          19.91</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_70" class="annotation strong" style="position: unset;">0.0013</span></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the number (percentage) of patients, except
                        where otherwise noted.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the mean and standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Because AIDS/HIV status is a sensitive issue, data are not
                        available.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">The rate of infection increased with the number of ESIs
                  received prior to surgery (p &lt; 0.0001) (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                  There was no association between the number of procedures and the rate of infection in the acupuncture
                  group and combined group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Comparison of Infection Rates According to the
                          Number of Treatments by ESI and Acupuncture Procedure in the 3 Months Before Surgery<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 264.288px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.498485);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">0 ESI</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">1 ESI</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">2 ESI</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">3 ESI</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">&gt;3 ESI</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total No.
                          (%)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. (%)
                          with Infection</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total No.
                          (%)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. (%)
                          with Infection</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total No.
                          (%)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. (%)
                          with Infection</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total No.
                          (%)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. (%)
                          with Infection</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total No.
                          (%)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. (%)
                          with Infection</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">0 acupuncture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">104,106
                          (50.10)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,342
                          (4.17)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31,969
                          (15.38)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,343
                          (4.20)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18,757
                          (9.03)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">849
                          (4.53)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12,442
                          (5.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">575
                          (4.63)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20,714
                          (9.97)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">994
                          (4.80)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_71" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">1 acupuncture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,845
                          (1.37)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">122
                          (4.29)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,246
                          (0.60)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56
                          (4.49)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">822
                          (0.40)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (2.92)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">598
                          (0.29)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (3.85)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,077
                          (0.52)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54
                          (5.01)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.3694</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2 acupuncture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,446
                          (0.70)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60
                          (4.15)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">654
                          (0.31)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33
                          (5.05)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">403
                          (0.19)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (4.71)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">296
                          (0.14)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (2.70)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">538
                          (0.26)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (3.72)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.2130</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3 acupuncture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">952
                          (0.46)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34
                          (3.57)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">428
                          (0.21)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (5.37)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">273
                          (0.13)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (5.86)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">204
                          (0.10)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (3.92)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">365
                          (0.18)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (3.84)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.3696</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">&gt;3 acupuncture</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,483
                          (1.68)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">124
                          (3.56)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,379
                          (0.66)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51
                          (3.70)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">885
                          (0.43)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (4.75)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">684
                          (0.33)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (3.95)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,240
                          (0.60)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55
                          (4.44)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0591</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">P value</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_72" class="annotation strong" style="position: unset;">0.0348</span></span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.4984</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.3679</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0776</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.1493</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Percentages in the “Total No. (%)” columns are of the total cohort (N =
                        207,806). Percentages in the “No. (%) with Infection” columns are of the total number for the
                        indicated treatments.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">In addition, we identified differences in the
                  postoperative infection rate on the basis of the duration between the last procedure and spinal
                  surgery. The incidence of postoperative infection increased as the duration between the procedure and
                  surgery decreased in the ESI group (p &lt; 0.0001) (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>). There
                  were no significant differences in the acupuncture and combined groups on the basis of the timing of
                  the last procedure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Comparison of Infection Rates According to the Time
                          of the Last Preoperative Procedure</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 697px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Duration Between Last Procedure and Surgery</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">≤2
                          Wk</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">&gt;2 to 4
                          Wk</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">&gt;4 to
                          12 Wk</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Acupuncture group</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.4250</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total no. (%) of
                          patients</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,327
                          (26.67)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,745
                          (20.00)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,654
                          (53.33)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No. (%) with
                          infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88
                          (3.78)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60
                          (3.44)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">192
                          (4.13)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ESI group</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_73" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total no. (%) of
                          patients</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24,490
                          (29.20)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20,009
                          (23.85)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39,383
                          (46.95)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No. (%) with
                          infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,226
                          (5.01)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">893
                          (4.46)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,642
                          (4.17)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Combined group</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.5510</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total no. (%) of
                          patients</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,161
                          (46.53)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,947
                          (26.57)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,984
                          (26.90)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No. (%) with
                          infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">223
                          (4.32)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">116
                          (3.94)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">134
                          (4.49)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Univariate
                Cox Regression Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">In the univariate analysis, the risk of infection
                  increased with age (crude hazard ratio [HR], 1.006; 95% confidence interval [CI], 1.003 to 1.008), and
                  it was shown that female patients were at a lower risk compared with male patients (HR, 0.705; 95% CI,
                  0.676 to 0.735). A 1-point increase in the CCI was demonstrated to increase the risk of postoperative
                  infection by 1.052 times (95% CI, 1.041 to 1.063). Congestive heart failure, peripheral vascular
                  disease, cerebrovascular disease, chronic pulmonary disease, rheumatic disease, peptic ulcer disease,
                  mild liver disease, diabetes without chronic complications, diabetes with chronic complications,
                  hemiplegia or paraplegia, renal disease, and moderate or severe liver disease were identified as the
                  risk factors (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a>). ESI
                  was associated with an increase in the postoperative infection rate (crude HR, 1.075; 95% CI, 1.032 to
                  1.121); however, acupuncture was not (crude HR, 0.951; 95% CI, 0.885 to 1.022).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Risk Factors for Postoperative Spinal Infection Using
                          Cox Regression Analysis</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 2276px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Crude HR
                          (95% CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Adjusted
                          HR (95% CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.006
                          (1.003-1.008)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_74" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.004
                          (1.001-1.007)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_75" class="annotation strong" style="position: unset;">0.0045</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (ref.)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (ref.)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.705
                          (0.676-0.735)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_76" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.701
                          (0.672-0.731)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_77" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CCI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.052
                          (1.041-1.063)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_78" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Myocardial
                          infarction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.149
                          (0.965-1.368)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.1186</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.989
                          (0.829-1.180)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.9051</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Congestive heart
                          failure</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.154
                          (1.061-1.257)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_79" class="annotation strong" style="position: unset;">0.0009</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.074
                          (0.985-1.172)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.1068</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Peripheral vascular
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.101
                          (1.049-1.156)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_80" class="annotation strong" style="position: unset;">0.0001</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.054
                          (1.002-1.108)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_81" class="annotation strong" style="position: unset;">0.0406</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cerebrovascular
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.079
                          (1.014-1.148)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_82" class="annotation strong" style="position: unset;">0.0165</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.013
                          (0.950-1.080)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.6951</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dementia</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.950
                          (0.811-1.112)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.5211</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.913
                          (0.778-1.070)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.2588</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Chronic pulmonary
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.061
                          (1.013-1.111)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_83" class="annotation strong" style="position: unset;">0.0118</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.021
                          (0.974-1.070)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.3883</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Rheumatic disease</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.226
                          (1.144-1.314)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_84" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.237
                          (1.154-1.326)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_85" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Peptic ulcer
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.126
                          (1.077-1.178)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_86" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.102
                          (1.053-1.153)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_87" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mild liver
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.122
                          (1.069-1.178)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_88" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.057
                          (1.006-1.111)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_89" class="annotation strong" style="position: unset;">0.0292</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diabetes without chronic
                          complications</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.121
                          (1.073-1.173)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_90" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.046
                          (0.996-1.098)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0721</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diabetes with chronic
                          complications</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.158
                          (1.091-1.230)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_91" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.071
                          (1.004-1.144)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_92" class="annotation strong" style="position: unset;">0.0388</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hemiplegia or
                          paraplegia</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.234
                          (1.023-1.488)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_93" class="annotation strong" style="position: unset;">0.0279</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.142
                          (0.946-1.378)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.1676</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Renal disease</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.280
                          (1.133-1.447)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_94" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.135
                          (1.003-1.286)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_95" class="annotation strong" style="position: unset;">0.0453</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Any malignancy</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.943
                          (0.852-1.045)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.2629</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.860
                          (0.775-0.955)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_96" class="annotation strong" style="position: unset;">0.0046</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Moderate or severe liver
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.411
                          (1.003-1.986)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_97" class="annotation strong" style="position: unset;">0.0479</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.275
                          (0.905-1.795)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.1642</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Metastatic solid
                          tumor</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.325
                          (0.855-2.054)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.2084</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.403
                          (0.898-2.191)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.1369</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> AIDS/HIV</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Acupuncture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.951
                          (0.885-1.022)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.1733</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.946
                          (0.880-1.017)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.1307</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ESI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.075
                          (1.032-1.121)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_98" class="annotation strong" style="position: unset;">0.0006</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.048
                          (1.005-1.093)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_99" class="annotation strong" style="position: unset;">0.0273</span></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Multivariable Cox Regression Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Multivariable analysis was performed after adjusting for
                  age, sex, comorbidities, acupuncture, and ESI procedures. As age increased by 1 year, the
                  postoperative infection rate increased by 1.004 times (95% CI, 1.001 to 1.007). Females were at lower
                  risk of infection compared to males (adjusted HR, 0.701; 95% CI, 0.672 to 0.731). Peripheral vascular
                  disease, rheumatic disease, peptic ulcer disease, mild liver disease, diabetes with chronic
                  complications, renal disease, and any malignancy were identified as risk factors (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table V</a>). ESI was a risk factor for postoperative infection among the
                  preoperative procedures but acupuncture was not (ESI: adjusted HR, 1.048; 95% CI, 1.005 to 1.093;
                  acupuncture: adjusted HR, 0.946; 95% CI, 0.880 to 1.017) (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a>). When
                  the patients who received procedures within 2 weeks prior to surgery were excluded, ESI was no longer
                  a risk factor for postoperative infection. (ESI: adjusted HR, 1.003; 95% CI, 0.956 to 1.052;
                  acupuncture: adjusted HR, 0.972; 95% CI, 0.887 to 1.064) (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VI</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VI</span>Risk Factors for Postoperative Spinal Infection
                          Using Cox Regression Analysis, Excluding Patients Who Received Acupuncture or ESI within 2
                          Weeks Prior to Spinal Surgery</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1592px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Adjusted
                          HR (95% CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.004
                          (1.001-1.007)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_100" class="annotation strong" style="position: unset;">0.0050</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (ref.)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.701
                          (0.669-0.734)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_101" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CCI</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Myocardial
                          infarction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.979
                          (0.806-1.190)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.8321</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Congestive heart
                          failure</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.089
                          (0.990-1.199)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0797</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Peripheral vascular
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.052
                          (0.995-1.112)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0749</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cerebrovascular
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.995
                          (0.928-1.068)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.8990</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dementia</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.914
                          (0.764-1.093)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.3231</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Chronic pulmonary
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.019
                          (0.967-1.073)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.4832</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Rheumatic disease</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.228
                          (1.137-1.326)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_102" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Peptic ulcer
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.093
                          (1.039-1.150)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_103" class="annotation strong" style="position: unset;">0.0005</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mild liver
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.058
                          (1.001-1.118)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_104" class="annotation strong" style="position: unset;">0.0467</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diabetes without chronic
                          complication</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.045
                          (0.990-1.103)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.1124</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diabetes with chronic
                          complication</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.073
                          (0.998-1.153)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0559</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hemiplegia or
                          paraplegia</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.189
                          (0.971-1.456)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0933</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Renal disease</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.132
                          (0.990-1.294)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0709</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Any malignancy</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.827
                          (0.736-0.929)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_105" class="annotation strong" style="position: unset;">0.0013</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Moderate or severe liver
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.514
                          (1.075-2.134)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_106" class="annotation strong" style="position: unset;">0.0177</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Metastatic solid
                          tumor</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.478
                          (0.911-2.399)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.1140</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> AIDS/HIV</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Acupuncture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.972
                          (0.887-1.064)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.5372</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ESI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.003
                          (0.956-1.052)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.9012</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, this is the first study to investigate
                  the effect of acupuncture on infection following lumbar spinal fusion using a nationwide database. We
                  compared early infection rates (within 90 days following spinal surgery) among patients who underwent
                  spinal fusion who had a history of ESI and/or acupuncture within 3 months before surgery, as
                  identified using insurance claims data. Acupuncture was not associated with an increased infection
                  rate. Although ESI was identified as a risk factor for postoperative infection, it was no longer a
                  risk factor when patients who received acupuncture or ESI within 2 weeks prior to surgery were
                  excluded. In addition to ESI, increasing age, male sex, and several comorbidities, including
                  peripheral vascular disease, rheumatic disease, peptic ulcer disease, mild liver disease, diabetes
                  with chronic complications, renal disease, and any malignancy, were identified as risk factors for
                  postoperative infection after spinal surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Several studies have demonstrated that preoperative ESI is
                  associated with an increased infection rate after fusion surgery, similar to the results of this
                  study<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">28</span></a>. The total infection rate in this study was 4.29% (8,916 of
                  207,806 patients), which is considered relatively high compared with the infection rate of other
                  studies (1.60% to 3.6%)<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">31</span></a>. It is difficult to make a direct comparison since there
                  are discrepancies among the studies in the methodology and the definition of postoperative infection.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Our study included cases of infection that occurred within
                  90 days after spinal surgery. Postoperative infection is considered infection that occurs up to 1 year
                  postoperatively in cases of spinal surgery with instrumentation<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">32</span></a>. However, the later the onset of postoperative infection,
                  the less likely it is to be affected by invasive procedures performed preoperatively and the more
                  likely it is to be influenced by patient-related risk factors. Previous studies on the association
                  between ESI and postoperative infection also included cases that occurred within 90 days
                  postoperatively<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">21</span></a>. Early postoperative infection is often defined as that
                  occurring within 90 days, and delayed onset is defined as infection occurring after 90 days
                  postoperatively. This 90-day threshold also serves as a standard for deciding whether to remove an
                  implant for the treatment of infection<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">34</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">We also evaluated the effect of the number and timing of
                  preoperative procedures on infection. The frequency and timing of preoperative acupuncture did not
                  affect the infection rate. However, the postoperative infection rate increased as the number of
                  preoperative ESIs increased, and as the duration between the last injection and surgery decreased. The
                  appropriate frequency and timing of ESI remains controversial<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">35</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">37</span></a>. It is recommended by the North American Spine Society that
                  ESIs should be performed &lt;6 times per year in the same site<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">38</span></a>. Even in our country, a medical institution usually
                  performs injection treatment 2 or 3 times at an interval of 1 to 2 weeks and advises the patient to
                  avoid additional injection for several months. However, it is common for many patients who report pain
                  to receive an ESI &gt;3 times in a short period of time by visiting other clinics for treatment. In
                  the current study, we found that 24.69% of the patients who underwent ESI alone received it &gt;3
                  times within 3 months. Acupuncture is often performed more frequently than ESI. We found that 39.92%
                  of the patients received acupuncture alone &gt;3 times within 90 days.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Surgeons tend to avoid invasive procedures before surgery
                  because they are concerned about the possibility of postoperative infection. However, 29.20% of the
                  patients in the ESI group and 26.67% in the acupuncture group underwent the procedure within 2 weeks
                  prior to surgery, and this shows that, in clinical practice, many patients receive invasive procedures
                  immediately before the operation. In the ESI group, patients who received the injection within 2 weeks
                  of surgery showed an infection rate of 5.01% (1,226 of 24,490), which was higher than the average rate
                  of in the ESI group across the time periods (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).
                  However, when we excluded patients who received acupuncture or ESI within 2 weeks of surgery, ESI was
                  not a risk factor for postoperative infection (<a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VI</a>).
                  Therefore, on the basis of our results, we recommend avoiding invasive procedures within 2 weeks prior
                  to surgery in order to reduce the risk of postoperative infection.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Acupuncture is widely practiced as an alternative
                  treatment in our country. More than 3 million acupuncture procedures are performed annually for back
                  pain<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">39</span></a>.
                  Alternative medicine, including acupuncture, has been covered by the National Health Insurance system
                  since 1987<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">24</span></a>. If
                  an alternative medicine practitioner submits a claim for the acupuncture treatment they have
                  performed, HIRA verifies the procedural code and reimburses the institution for the medical cost. Most
                  acupuncture procedures, except for noninsured procedures such as pharmacopuncture, which is a
                  combination of herbal medicine and acupuncture therapy, can be verified through a nationwide database
                  by the date, number, and treatment site. Because procedures such as acupuncture and ESI are performed
                  multiple times in various institutions, it is difficult to accurately identify them through medical
                  records or history taking. It is more difficult to verify the history in the case of acupuncture
                  because patients often attempt to hide their treatment history before undergoing surgery. Such
                  omissions can be avoided when the claims data are used, since all procedures with claims can be
                  identified in the national insurance system.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">We discovered that preoperative acupuncture was not
                  associated with an increased risk of postoperative infection. In contrast to ESI, in which the needle
                  is placed directly into the epidural space, most acupuncture needles are placed only subcutaneously in
                  the fascia or muscle layer<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">7</span></a>.
                  Additionally, patients with a high risk of postoperative infection, who have severe chronic underlying
                  diseases or who were scheduled for multilevel surgery, may have avoided receiving acupuncture. If it
                  was confirmed that acupuncture was performed immediately before surgery in a high-risk patient, it is
                  possible that the surgery was cancelled or postponed by the surgeon.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">We found that female sex was associated with a lower risk
                  of postoperative infection than male sex. There are conflicting results from previous studies. Some
                  have reported a lower incidence of infections in females, which is similar to the finding in our
                  study<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">40</span></a>. In
                  contrast, others have reported that there is no sex-based difference<a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">41</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">This study had several limitations. We used claims data
                  and were unable to review the medical records to confirm diagnoses and treatments. When a superficial
                  infection is suspected, incision and drainage are often performed at the bedside without registering a
                  diagnosis or procedure code. If no claim is reported, treatments cannot be identified in the national
                  insurance data. Such cases may have resulted in the underestimation of the incidence of postoperative
                  infection. In addition, the severity of the infection could not be identified because there are no
                  diagnostic codes to differentiate the severity, and it can only be confirmed through medical chart
                  review. Another limitation is that non-reimbursed procedures, such as acupuncture involving insertion
                  of foreign material inside the body, could not be identified. Most patients receive ESIs several
                  times, and they often involve different types of injections. Therefore, it was not possible to divide
                  them into subgroups according to the type of ESI injection. Also, the claims data do not provide
                  surgical details such as the number of fusion levels and the surgical approach.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">This study is the first to investigate the effect of
                  alternative medicine on lumbar spinal surgery using a nationwide insurance claims database.
                  Acupuncture and ESI performed &gt;2 weeks prior to spinal surgery did not increase the risk of
                  postoperative infection. However, ESI performed within 2 weeks before surgery was associated with
                  increased risk. Therefore, we recommend that invasive procedures immediately before surgery should be
                  avoided to prevent postoperative complications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Barnes PM</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Bloom B</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Nahin RL</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Complementary and alternative
                  medicine use among adults and children: United States, 2007</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Natl Health Stat Rep.</span> 2008 Dec
                10;(12):1-23.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Complementary%20and%20alternative%20medicine%20use%20among%20adults%20and%20children%3A%20United%20States%2C%202007&as_occt=title&as_sauthors=%20%22PM%20Barnes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Andersson GBJ</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Epidemiological features of
                  chronic low-back pain</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 1999 Aug 14;354(9178):581-5.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Epidemiological%20features%20of%20chronic%20low-back%20pain&as_occt=title&as_sauthors=%20%22GBJ%20Andersson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Hart LG</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Deyo RA</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Cherkin DC</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Physician office visits for low
                  back pain. Frequency, clinical evaluation, and treatment patterns from a U.S. national survey</span>.
                <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Spine.</span> 1995 Jan
                1;20(1):11-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Physician%20office%20visits%20for%20low%20back%20pain.%20Frequency%2C%20clinical%20evaluation%2C%20and%20treatment%20patterns%20from%20a%20U.S.%20national%20survey&as_occt=title&as_sauthors=%20%22LG%20Hart%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Zhao ZQ</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Neural mechanism underlying
                  acupuncture analgesia</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Prog Neurobiol.</span> 2008 Aug;85(4):355-75.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Neural%20mechanism%20underlying%20acupuncture%20analgesia&as_occt=title&as_sauthors=%20%22ZQ%20Zhao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Clement-Jones V</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> McLoughlin L</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Tomlin S</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Besser GM</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Rees LH</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Wen HL</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Increased beta-endorphin but not
                  met-enkephalin levels in human cerebrospinal fluid after acupuncture for recurrent pain</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Lancet.</span> 1980 Nov
                1;2(8201):946-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Increased%20beta-endorphin%20but%20not%20met-enkephalin%20levels%20in%20human%20cerebrospinal%20fluid%20after%20acupuncture%20for%20recurrent%20pain&as_occt=title&as_sauthors=%20%22V%20Clement-Jones%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Wang SM</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Kain ZN</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> White P</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Acupuncture analgesia: I. The
                  scientific basis</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Anesth Analg.</span> 2008 Feb;106(2):602-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acupuncture%20analgesia%3A%20I.%20The%20scientific%20basis&as_occt=title&as_sauthors=%20%22SM%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Berman BM</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Langevin HM</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Witt CM</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Dubner R</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Acupuncture for chronic low back
                  pain</span>. <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">N Engl J
                  Med.</span> 2010 Jul 29;363(5):454-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acupuncture%20for%20chronic%20low%20back%20pain&as_occt=title&as_sauthors=%20%22BM%20Berman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> MacPherson H</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Thomas K</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Walters S</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Fitter M</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">The York acupuncture safety
                  study: prospective survey of 34 000 treatments by traditional acupuncturists</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">BMJ.</span> 2001 Sep
                1;323(7311):486-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20York%20acupuncture%20safety%20study%3A%20prospective%20survey%20of%2034%20000%20treatments%20by%20traditional%20acupuncturists&as_occt=title&as_sauthors=%20%22H%20MacPherson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Melchart D</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Weidenhammer W</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Streng A</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Reitmayr S</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Hoppe A</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Ernst E</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Linde K</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Prospective investigation of
                  adverse effects of acupuncture in 97 733 patients</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Arch Intern Med.</span> 2004 Jan
                12;164(1):104-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prospective%20investigation%20of%20adverse%20effects%20of%20acupuncture%20in%2097%20733%20patients&as_occt=title&as_sauthors=%20%22D%20Melchart%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Witt CM</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Pach D</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Brinkhaus B</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Wruck K</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Tag B</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Mank S</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Willich SN</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Safety of acupuncture: results
                  of a prospective observational study with 229,230 patients and introduction of a medical information
                  and consent form</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Forsch Komplement Med.</span> 2009 Apr;16(2):91-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Safety%20of%20acupuncture%3A%20results%20of%20a%20prospective%20observational%20study%20with%20229%2C230%20patients%20and%20introduction%20of%20a%20medical%20information%20and%20consent%20form&as_occt=title&as_sauthors=%20%22CM%20Witt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> White A</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Hayhoe S</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Hart A</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Ernst E</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Adverse events following
                  acupuncture: prospective survey of 32 000 consultations with doctors and physiotherapists</span>.
                <span data-id="emphasis_11" class="annotation emphasis" style="position: unset;">BMJ.</span> 2001 Sep
                1;323(7311):485-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Adverse%20events%20following%20acupuncture%3A%20prospective%20survey%20of%2032%20000%20consultations%20with%20doctors%20and%20physiotherapists&as_occt=title&as_sauthors=%20%22A%20White%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Xu S</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Wang L</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Cooper E</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Zhang M</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Manheimer E</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Berman B</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Shen X</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Lao L</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Adverse events of acupuncture:
                  a systematic review of case reports</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Evid Based Complement Alternat Med.</span> 2013;2013:581203.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Adverse%20events%20of%20acupuncture%3A%20a%20systematic%20review%20of%20case%20reports&as_occt=title&as_sauthors=%20%22S%20Xu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Callan AK</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Bauer JM</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Martus JE</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Deep Spine Infection After
                  Acupuncture in the Setting of Spinal Instrumentation</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2016
                Mar;4(2):156-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Deep%20Spine%20Infection%20After%20Acupuncture%20in%20the%20Setting%20of%20Spinal%20Instrumentation&as_occt=title&as_sauthors=%20%22AK%20Callan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Hong WJ</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Lee SG</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Park CW</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Kim WK</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Yoo CJ</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Awareness for serious spinal
                  complications predicted after acupuncture procedures for pain control</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J Korean Neurosurg
                  Soc.</span> 2004;36:130-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Awareness%20for%20serious%20spinal%20complications%20predicted%20after%20acupuncture%20procedures%20for%20pain%20control&as_occt=title&as_sauthors=%20%22WJ%20Hong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Friedly J</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Standaert C</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Chan L</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Epidemiology of spine care: the
                  back pain dilemma</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Phys Med Rehabil Clin N Am.</span> 2010 Nov;21(4):659-77.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Epidemiology%20of%20spine%20care%3A%20the%20back%20pain%20dilemma&as_occt=title&as_sauthors=%20%22J%20Friedly%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Cluff R</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Mehio AK</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Cohen SP</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Chang Y</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Sang CN</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Stojanovic MP</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">The technical aspects of
                  epidural steroid injections: a national survey</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">Anesth Analg.</span> 2002
                Aug;95(2):403-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20technical%20aspects%20of%20epidural%20steroid%20injections%3A%20a%20national%20survey&as_occt=title&as_sauthors=%20%22R%20Cluff%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Pholsawatchai W</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Manakul P</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Lertcheewanan W</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Siribumrungwoung K</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Suntharapa T</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Arunakul R</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Comparison of Efficacy between
                  Transforaminal Epidural Steroid Injection Technique without Contrast versus with Contrast in Lumbar
                  Radiculopathy: A Prospective Longitudinal Cohort Study</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Asian Spine J.</span> 2023
                Dec;17(6):1108-16.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20Efficacy%20between%20Transforaminal%20Epidural%20Steroid%20Injection%20Technique%20without%20Contrast%20versus%20with%20Contrast%20in%20Lumbar%20Radiculopathy%3A%20A%20Prospective%20Longitudinal%20Cohort%20Study&as_occt=title&as_sauthors=%20%22W%20Pholsawatchai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Kwon JW</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Moon SH</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Park SY</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Park SJ</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Park SR</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Suk KS</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Kim HS</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Lee BH</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Lumbar Spinal Stenosis: Review
                  Update 2022</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Asian Spine J.</span> 2022 Oct;16(5):789-98.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lumbar%20Spinal%20Stenosis%3A%20Review%20Update%202022&as_occt=title&as_sauthors=%20%22JW%20Kwon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Gupta A</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Chhabra HS</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Singh V</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Nagarjuna D</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Lumbar Transforaminal Injection
                  of Steroids versus Platelet-Rich Plasma for Prolapse Lumbar Intervertebral Disc with Radiculopathy: A
                  Randomized Double-Blind Controlled Pilot Study</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Asian Spine J.</span> 2024
                Feb;18(1):58-65.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lumbar%20Transforaminal%20Injection%20of%20Steroids%20versus%20Platelet-Rich%20Plasma%20for%20Prolapse%20Lumbar%20Intervertebral%20Disc%20with%20Radiculopathy%3A%20A%20Randomized%20Double-Blind%20Controlled%20Pilot%20Study&as_occt=title&as_sauthors=%20%22A%20Gupta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Kreitz TM</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Mangan J</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Schroeder GD</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Kepler CK</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Kurd MF</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Radcliff KE</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Woods BI</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Rihn JA</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Anderson DG</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Vaccaro AR</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Hilibrand AS</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Do Preoperative Epidural
                  Steroid Injections Increase the Risk of Infection After Lumbar Spine Surgery?</span><span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Spine.</span> 2021 Feb
                1;46(3):E197-202.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Do%20Preoperative%20Epidural%20Steroid%20Injections%20Increase%20the%20Risk%20of%20Infection%20After%20Lumbar%20Spine%20Surgery%3F&as_occt=title&as_sauthors=%20%22TM%20Kreitz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Singla A</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Yang S</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Werner BC</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Cancienne JM</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Nourbakhsh A</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Shimer AL</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Hassanzadeh H</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Shen FH</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">The impact of preoperative
                  epidural injections on postoperative infection in lumbar fusion surgery</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">J Neurosurg Spine.</span>
                2017 May;26(5):645-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20impact%20of%20preoperative%20epidural%20injections%20on%20postoperative%20infection%20in%20lumbar%20fusion%20surgery&as_occt=title&as_sauthors=%20%22A%20Singla%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Yang S</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Werner BC</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Cancienne JM</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Hassanzadeh H</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Shimer AL</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Shen FH</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Singla A</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Preoperative epidural
                  injections are associated with increased risk of infection after single-level lumbar
                  decompression</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Spine J.</span> 2016 Feb;16(2):191-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preoperative%20epidural%20injections%20are%20associated%20with%20increased%20risk%20of%20infection%20after%20single-level%20lumbar%20decompression&as_occt=title&as_sauthors=%20%22S%20Yang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Kim L</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Kim JA</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Kim S</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">A guide for the utilization of
                  Health Insurance Review and Assessment Service National Patient Samples</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Epidemiol Health.</span>
                2014 Jul 30;36:e2014008.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20guide%20for%20the%20utilization%20of%20Health%20Insurance%20Review%20and%20Assessment%20Service%20National%20Patient%20Samples&as_occt=title&as_sauthors=%20%22L%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Lim B</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Korean medicine coverage in the
                  National Health Insurance in Korea: present situation and critical issues</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Integr Med Res.</span> 2013
                Sep;2(3):81-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Korean%20medicine%20coverage%20in%20the%20National%20Health%20Insurance%20in%20Korea%3A%20present%20situation%20and%20critical%20issues&as_occt=title&as_sauthors=%20%22B%20Lim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Park JG</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Han SB</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Park JH</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Moon SJ</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Jang WY</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">A Decline in Overutilization of
                  Transfusion after Total Knee Arthroplasty Using Pharmacological Agents for Patient Blood Management in
                  South Korea: An Analysis Based on the Korean National Health Insurance Claims Database from 2008 to
                  2019</span>. <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Surg.</span> 2023 Dec;15(6):942-52.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Decline%20in%20Overutilization%20of%20Transfusion%20after%20Total%20Knee%20Arthroplasty%20Using%20Pharmacological%20Agents%20for%20Patient%20Blood%20Management%20in%20South%20Korea%3A%20An%20Analysis%20Based%20on%20the%20Korean%20National%20Health%20Insurance%20Claims%20Database%20from%202008%20to%202019&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Jeong J</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Han H</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Ro DH</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Han HS</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Won S</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Development of Prediction Model
                  Using Machine-Learning Algorithms for Nonsteroidal Anti-inflammatory Drug-Induced Gastric Ulcer in
                  Osteoarthritis Patients: Retrospective Cohort Study of a Nationwide South Korean Cohort</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">Clin Orthop Surg.</span>
                2023 Aug;15(4):678-89.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20of%20Prediction%20Model%20Using%20Machine-Learning%20Algorithms%20for%20Nonsteroidal%20Anti-inflammatory%20Drug-Induced%20Gastric%20Ulcer%20in%20Osteoarthritis%20Patients%3A%20Retrospective%20Cohort%20Study%20of%20a%20Nationwide%20South%20Korean%20Cohort&as_occt=title&as_sauthors=%20%22J%20Jeong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Park HJ</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Chang MJ</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Kim TW</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Chang CB</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Kang SB</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Differences in Resumption of
                  Orthopedic Surgery According to Characteristics of Surgery during COVID-19 Pandemic: National Registry
                  Data</span>. <span data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Surg.</span> 2023 Apr;15(2):327-37.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Differences%20in%20Resumption%20of%20Orthopedic%20Surgery%20According%20to%20Characteristics%20of%20Surgery%20during%20COVID-19%20Pandemic%3A%20National%20Registry%20Data&as_occt=title&as_sauthors=%20%22HJ%20Park%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Zusman N</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Munch JL</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Ching A</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Hart R</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Yoo J</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Preoperative epidural spinal
                  injections increase the risk of surgical wound complications but do not affect overall complication
                  risk or patient-perceived outcomes</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">J Neurosurg Spine.</span> 2015 Nov;23(5):652-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preoperative%20epidural%20spinal%20injections%20increase%20the%20risk%20of%20surgical%20wound%20complications%20but%20do%20not%20affect%20overall%20complication%20risk%20or%20patient-perceived%20outcomes&as_occt=title&as_sauthors=%20%22N%20Zusman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Mok JM</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Guillaume TJ</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Talu U</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Berven SH</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Deviren V</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Kroeber M</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Bradford DS</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Hu SS</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Clinical outcome of deep wound
                  infection after instrumented posterior spinal fusion: a matched cohort analysis</span>. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Spine.</span> 2009 Mar
                15;34(6):578-83.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20outcome%20of%20deep%20wound%20infection%20after%20instrumented%20posterior%20spinal%20fusion%3A%20a%20matched%20cohort%20analysis&as_occt=title&as_sauthors=%20%22JM%20Mok%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Rao SB</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Vasquez G</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Harrop J</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Maltenfort M</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Stein N</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Kaliyadan G</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Klibert F</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Epstein R</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Sharan A</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Vaccaro A</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Flomenberg P</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Risk factors for surgical site
                  infections following spinal fusion procedures: a case-control study</span>. <span
                  data-id="emphasis_30" class="annotation emphasis" style="position: unset;">Clin Infect Dis.</span>
                2011 Oct;53(7):686-92.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20for%20surgical%20site%20infections%20following%20spinal%20fusion%20procedures%3A%20a%20case-control%20study&as_occt=title&as_sauthors=%20%22SB%20Rao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Smith JS</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Shaffrey CI</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Sansur CA</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Berven SH</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Fu KMG</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Broadstone PA</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Choma TJ</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Goytan MJ</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Noordeen HH</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Knapp DR Jr</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Hart RA</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Donaldson WF 3rd</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Polly DW Jr</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Perra JH</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Boachie-Adjei O</span>;
                Scoliosis Research Society Morbidity and Mortality Committee. <span data-id="strong_31"
                  class="annotation strong" style="position: unset;">Rates of infection after spine surgery based on
                  108,419 procedures: a report from the Scoliosis Research Society Morbidity and Mortality
                  Committee</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Spine.</span> 2011 Apr 1;36(7):556-63.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Rates%20of%20infection%20after%20spine%20surgery%20based%20on%20108%2C419%20procedures%3A%20a%20report%20from%20the%20Scoliosis%20Research%20Society%20Morbidity%20and%20Mortality%20Committee&as_occt=title&as_sauthors=%20%22JS%20Smith%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Lazennec JY</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Fourniols E</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Lenoir T</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Aubry A</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Pissonnier ML</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Issartel B</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Rousseau MA</span>; French Spine
                Surgery Society. <span data-id="strong_32" class="annotation strong" style="position: unset;">Infections
                  in the operated spine: update on risk management and therapeutic strategies</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Orthop Traumatol Surg
                  Res.</span> 2011 Oct;97(6)(Suppl):S107-16.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Infections%20in%20the%20operated%20spine%3A%20update%20on%20risk%20management%20and%20therapeutic%20strategies&as_occt=title&as_sauthors=%20%22JY%20Lazennec%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Schömig F</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Putzier M</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Clinical presentation and
                  diagnosis of delayed postoperative spinal implant infection</span>. <span data-id="emphasis_33"
                  class="annotation emphasis" style="position: unset;">J Spine Surg.</span> 2020
                Dec;6(4):772-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20presentation%20and%20diagnosis%20of%20delayed%20postoperative%20spinal%20implant%20infection&as_occt=title&as_sauthors=%20%22F%20Sch%C3%B6mig%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Hedequist D</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Haugen A</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Hresko T</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Emans J</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Failure of attempted implant
                  retention in spinal deformity delayed surgical site infections</span>. <span data-id="emphasis_34"
                  class="annotation emphasis" style="position: unset;">Spine.</span> 2009 Jan 1;34(1):60-4.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Failure%20of%20attempted%20implant%20retention%20in%20spinal%20deformity%20delayed%20surgical%20site%20infections&as_occt=title&as_sauthors=%20%22D%20Hedequist%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Vydra D</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> McCormick Z</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Clements N</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Nagpal A</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Julia J</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Cushman D</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Current trends in steroid dose
                  choice and frequency of administration of epidural steroid injections: a survey study</span>. <span
                  data-id="emphasis_35" class="annotation emphasis" style="position: unset;">PM R.</span> 2020
                Jan;12(1):49-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Current%20trends%20in%20steroid%20dose%20choice%20and%20frequency%20of%20administration%20of%20epidural%20steroid%20injections%3A%20a%20survey%20study&as_occt=title&as_sauthors=%20%22D%20Vydra%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Novak S</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Nemeth WC</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">The basis for recommending
                  repeating epidural steroid injections for radicular low back pain: a literature review</span>. <span
                  data-id="emphasis_36" class="annotation emphasis" style="position: unset;">Arch Phys Med
                  Rehabil.</span> 2008 Mar;89(3):543-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20basis%20for%20recommending%20repeating%20epidural%20steroid%20injections%20for%20radicular%20low%20back%20pain%3A%20a%20literature%20review&as_occt=title&as_sauthors=%20%22S%20Novak%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Seavey JG</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Balazs GC</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Steelman T</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Helgeson M</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Gwinn DE</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Wagner SC</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">The effect of preoperative
                  lumbar epidural corticosteroid injection on postoperative infection rate in patients undergoing
                  single-level lumbar decompression</span>. <span data-id="emphasis_37" class="annotation emphasis"
                  style="position: unset;">Spine J.</span> 2017 Sep;17(9):1209-14.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20preoperative%20lumbar%20epidural%20corticosteroid%20injection%20on%20postoperative%20infection%20rate%20in%20patients%20undergoing%20single-level%20lumbar%20decompression&as_occt=title&as_sauthors=%20%22JG%20Seavey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;">North
                American Spine Society. <span data-id="strong_38" class="annotation strong"
                  style="position: unset;">Lumbar Transforaminal Epidural Steroid Injections Review & Recommendation
                  Statement</span>. Jan 2013. Accessed 2024 Nov 20. <a
                  href="https://www.spine.org/Portals/0/assets/downloads/researchclinicalcare/ltfesireviewrecstatement.pdf"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.spine.org/Portals/0/assets/downloads/researchclinicalcare/ltfesireviewrecstatement.pdf</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lumbar%20Transforaminal%20Epidural%20Steroid%20Injections%20Review%20%26%20Recommendation%20Statement&as_occt=title&as_sauthors=%20%22North%20American%20Spine%20Society%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Kim JH</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Nam DW</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Kang JW</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Kim EJ</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Kim KS</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Kang SK</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Lee JD</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">A review of clinical trials for
                  development of assessment criteria for chronic low back pain</span>. <span data-id="emphasis_38"
                  class="annotation emphasis" style="position: unset;">Journal of Acupuncture Research.</span>
                2009;26(6):215-24.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20review%20of%20clinical%20trials%20for%20development%20of%20assessment%20criteria%20for%20chronic%20low%20back%20pain&as_occt=title&as_sauthors=%20%22JH%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_205" class="annotation" style="position: unset;"> White AJ</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Fiani B</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Jarrah R</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Momin AA</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Rasouli J</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Surgical site infection
                  prophylaxis and wound management in spine surgery</span>. <span data-id="emphasis_39"
                  class="annotation emphasis" style="position: unset;">Asian Spine J.</span> 2022
                Jun;16(3):451-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20site%20infection%20prophylaxis%20and%20wound%20management%20in%20spine%20surgery&as_occt=title&as_sauthors=%20%22AJ%20White%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Meng F</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Cao J</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Meng X</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Risk factors for surgical site
                  infections following spinal surgery</span>. <span data-id="emphasis_40" class="annotation emphasis"
                  style="position: unset;">J Clin Neurosci.</span> 2015 Dec;22(12):1862-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20for%20surgical%20site%20infections%20following%20spinal%20surgery&as_occt=title&as_sauthors=%20%22F%20Meng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="infographic_reference" class="annotation infographic_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node infographic" data-id="infographic" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Infographic</div><a class="link jbjs_tracking" jbjs_tracking_type="download"
                jbjs_tracking_data="{&quot;id&quot;:&quot;003776c2-daee-4ddd-8ddf-63fbfb25e784&quot;,&quot;type&quot;:&quot;infographic&quot;,&quot;topics&quot;:[]}"
                href="/php/content/content_api.php?op=download&rsuite_id=003776c2-daee-4ddd-8ddf-63fbfb25e784&type=infographic"
                jbjs_tracking_source="reader" style="position: unset;">Download</a>
              <div class="infographic-wrapper" style="position: unset;">
                <div class="viewer" style="position: unset;">
                  <div class="progress-bar" style="position: unset; width: 76.6484%;"></div>
                  <div class="pages" style="position: unset;"></div>
                  <div class="toolbar" style="position: unset;"><button id="zoom-out" class=""
                      style="position: unset;">-</button><button id="zoom-in" class=""
                      style="position: unset;">+</button><button id="full-screen" class="" style="position: unset;">Full
                      screen ON</button></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D003776c2-daee-4ddd-8ddf-63fbfb25e784%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">February 5, 2025;
                      107 (3): 229</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00721</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">December 10, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_41"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Yonsei
                            University College of Medicine, Seoul, South Korea</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_47"
                            class="annotation strong" style="position: unset;">A commentary by Patricia Lipson, BS, and
                            Philip K. Louie, MD,</span> is linked to the online version of this article.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=69592fed-0c68-417c-9f7f-4ac87da8ed87&native=1"
                              class="article-title" style="position: unset;">Balancing Act: Infection Risks of
                              Nonoperative Treatments Before Lumbar Fusion<span class="subtitle"
                                style="position: unset;"><br style="position: unset;">Commentary on an article by Sahyun
                                Sung, MD, et al.: “Effect of Preoperative Acupuncture and Epidural Steroid Injection on
                                Early Postoperative Infection After Lumbar Spinal Fusion”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Lipson,
                              Patricia; Louie, Philip K.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 107(3):e8 | February 5, 2025</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Author(s). Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the terms of the <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank"
                            data-id="link_2" class="link" style="position: unset;">Creative Commons Attribution-Non
                            Commercial-No Derivatives License 4.0</a> (CCBY-NC-ND), where it is permissible to download
                          and share the work provided it is properly cited. The work cannot be changed in any way or
                          used commercially without permission from the journal.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;003776c2-daee-4ddd-8ddf-63fbfb25e784&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=003776c2-daee-4ddd-8ddf-63fbfb25e784&type=pdf&name=JBJS.23.00721.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=003776c2-daee-4ddd-8ddf-63fbfb25e784&type=pdf&name=JBJS.23.00721.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_48"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_49" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I315"
                            target="_blank" data-id="link_3" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I315</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;003776c2-daee-4ddd-8ddf-63fbfb25e784&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=003776c2-daee-4ddd-8ddf-63fbfb25e784&type=zip&name=JBJS.23.00721.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sahyun Sung, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7483-7565" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7483-7565</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ji-Won Kwon, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4880-5310" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4880-5310</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Soo-Bin Lee, MD, PhD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4934-5942" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4934-5942</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Eun Hwa Kim, MS<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1692-6126" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1692-6126</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Inkyung Jung, PhD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3780-3213" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3780-3213</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Seong-Hwan Moon, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5165-1159" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5165-1159</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kyung-Soo Suk, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0633-2658" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0633-2658</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hak-Sun Kim, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Si Young Park, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1216-901X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1216-901X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Byung Ho Lee, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:bhlee96@yuhs.ac" class="" style="position: unset;">bhlee96@yuhs.ac</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7235-4981" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7235-4981</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Yonsei University College of Medicine, Seoul,
                South Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Ace Hospital, Ansan, South Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, International St. Mary’s Hospital, Catholic
                Kwandong University, Incheon, South Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Division of Biostatistics, Department of Biomedical Systems Informatics, Yonsei
                University College of Medicine, Seoul, South Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 32582.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
