import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { formatNumberWithAbbreviation } from "@memorang/helpers";
import { Text, type TextProps } from "react-native-paper";
import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";

const Responses = ({
	numResponses,
	color,
	textVariant = "bodyMedium",
}: {
	numResponses: number;
	color?: string;
	textVariant?: TextProps<never>["variant"];
}) => {
	const theme = useAppTheme();

	if (numResponses < 10) {
		return null;
	}
	return (
		<Box alignItems="center" flexDirection="row" gap={2}>
			<Text
				variant={textVariant}
				style={{ color: color ?? theme.colors.onSurfaceVariant }}
			>
				{formatNumberWithAbbreviation(numResponses)}
			</Text>
			<Icon
				color={color ?? theme.colors.onSurfaceVariant}
				name="poll"
				size={16}
			/>
		</Box>
	);
};

export default Responses;
