import {
	Area,
	AreaChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

import { useAppTheme } from "@hooks/useAppTheme";

type Props = {
	name: string;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	data: any[];
	fillColor: string;
	strokeColor: string;
	itemsCount: number;
};

const StackedChart = (props: Props) => {
	const { name, data, fillColor, strokeColor, itemsCount } = props;
	const theme = useAppTheme();
	const tickLabelTheme = {
		fill: theme.palette.text.secondary,
		fontSize: 12,
	};
	const axisTheme = {
		stroke: theme.colors.text,
		fontSize: 32,
	};
	const finalChartTheme = {
		tick: tickLabelTheme,
		axis: axisTheme,
	};

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const handleTickFormat = (arg: any) => {
		if (arg instanceof Date) {
			if (arg.toLocaleString("en-us", { day: "numeric" }) === "1") {
				return arg.toLocaleString("en-us", { month: "short" });
			}
			return "";
		}
		return "";
	};

	const handleTickFormatY = (arg: string) => {
		const intValue = Number.parseInt(arg, 10);
		if (Number.isNaN(intValue)) {
			return "";
		}
		return intValue.toLocaleString();
	};

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const handleTooltipLabel = (label: any) => {
		if (typeof label === "number") {
			return `${label} secs`;
		}
		if (typeof label === "object") {
			const date = new Date(label);
			const formattedDate = `${date.toLocaleDateString("en-US", {
				month: "long",
				day: "numeric",
				year: "numeric",
			})}`;
			return formattedDate;
		}
		return label;
	};
	const handleTooltipFormatter = (value: number) => [
		`${Math.round(value)} items mastered`,
	];
	return (
		<ResponsiveContainer width="100%" height={350}>
			<AreaChart
				data={data}
				margin={{
					top: 16,
					right: 16,
					bottom: 0,
					left: 4,
				}}
			>
				<XAxis
					dataKey="x"
					tickFormatter={handleTickFormat}
					tick={finalChartTheme.tick}
					allowDuplicatedCategory={false}
					fontFamily="OpenSans-SemiBold"
				/>
				<YAxis
					domain={[0, itemsCount]}
					tick={finalChartTheme.tick}
					tickFormatter={handleTickFormatY}
					width={32}
					fontFamily="OpenSans-SemiBold"
				/>
				<Tooltip
					labelStyle={{
						fontFamily: "OpenSans-SemiBold",
					}}
					labelFormatter={handleTooltipLabel}
					formatter={handleTooltipFormatter}
				/>
				<Area
					type="monotone"
					name={name}
					dataKey="y"
					fill={fillColor}
					stroke={strokeColor}
					fillOpacity={0.7}
					strokeWidth={2}
				/>
			</AreaChart>
		</ResponsiveContainer>
	);
};

export default StackedChart;
