/* ======== Types ========= */
import { useAppTheme } from "@hooks/useAppTheme";
import { getExpirationDateText } from "@memorang/helpers";
import type { Plan } from "@memorang/types";

/* ======== Components ========= */
import { Box } from "@memorang/ui";
import { StyleSheet } from "react-native";
import { Text, TouchableRipple } from "react-native-paper";

const styles = StyleSheet.create({
	amount: {
		fontFamily: "OpenSans-SemiBold",
	},
});
export type PlansSectionProps = {
	sortedPlans: Plan[];
	selectedPlan?: Plan;
	setSelectedPlan: (plan: Plan) => void;
	expiryDate?: string;
};

type PlanBoxProps = {
	isSelected: boolean;
	planAmount: number;
	alias: string;
	expirationText: string;
};
const PlansSection = ({
	sortedPlans,
	selectedPlan,
	setSelectedPlan,
}: PlansSectionProps) => {
	const theme = useAppTheme();

	const PlanBox = ({ isSelected, planAmount, alias }: PlanBoxProps) => {
		return (
			<Box alignItems="center" gap={8}>
				<Text
					variant="titleMedium"
					style={{
						color: isSelected ? theme.colors.primary : theme.colors.text,
						textAlign: "center",
						fontFamily: "OpenSans-SemiBold",
					}}
				>
					{`${alias}`}
				</Text>
				<Text
					variant="titleMedium"
					style={styles.amount}
				>{`$${planAmount}`}</Text>
			</Box>
		);
	};
	return (
		<Box flexDirection="row" gap={8} justifyContent="center">
			{sortedPlans.map((plan) => {
				const { amount: planAmount, id, alias } = plan;

				const expirationText = getExpirationDateText(plan);

				const isSelected = selectedPlan?.id === id;

				return (
					<TouchableRipple
						key={id}
						rippleColor="transparent"
						onPress={() => setSelectedPlan(plan)}
						style={{
							borderColor: isSelected
								? theme.colors.primary
								: theme.colors.primaryContainer,
							borderWidth: 2,
							borderRadius: 8,
							alignItems: "center",
							paddingVertical: 16,
							justifyContent: "center",
							paddingHorizontal: 8,
							flexGrow: 1,
							width: 100,
						}}
					>
						<PlanBox
							isSelected={isSelected}
							planAmount={planAmount}
							alias={alias}
							expirationText={expirationText}
						/>
					</TouchableRipple>
				);
			})}
		</Box>
	);
};

export default PlansSection;
