import { ids } from "@constants/common";
import { buildType } from "@helpers/expo-extras";
import useClerkUser from "./useClerkUser";

const useImageChallengeAppConfig = () => {
	const { userPublicMetadataId } = useClerkUser();
	const collectionId = ids[buildType].collectionId;
	const projectId = ids[buildType].projectId;
	const organizationId = ids[buildType].organizationId;
	const userId = userPublicMetadataId!;

	return { collectionId, projectId, organizationId, userId };
};

export default useImageChallengeAppConfig;
