/* ----------------- Globals --------------- */
import { OnboardingSelectionView } from "@memorang/ui";

import { pageViewTypes } from "@constants/tracking";
import { titleCase } from "@memorang/helpers";
import type { BreadcrumbType } from "@memorang/ui/src/components/CustomBreadcrumb";
import { trackPageView } from "analytics";
import { useEffect } from "react";
/* ----------------- Hooks --------------- */
import { useSpecialityOnboarding } from "../hooks/useSpecialityOnboarding";

const SelectSpecialtyRouteContainer = ({
	showSpecialityBack,
}: {
	showSpecialityBack: boolean;
}) => {
	const {
		handleSelectSpeciality,
		handleClickContinue,
		currentSpecialties,
		onboardingSpecialityStep,
		subSpecialities,
		speciality,
		mockedSpecialities,
		currentSelectedSpeciality,
		hasNestedItems,
		handleSkip,
		currentStepIndex,
	} = useSpecialityOnboarding();

	useEffect(() => {
		trackPageView({ type: pageViewTypes.onboardingSpecialty });
	}, []);

	const roleAppbartitle = onboardingSpecialityStep?.title!;

	const finalCards =
		speciality && currentStepIndex === 1
			? currentSpecialties.find((s) => s.value === speciality)?.items || []
			: mockedSpecialities;

	const breadcrumbs: BreadcrumbType[] =
		currentSelectedSpeciality && hasNestedItems
			? [
					{
						label: titleCase(currentSelectedSpeciality.label),
					},
					{
						label: "Specialty",
					},
				]
			: [];
	return (
		<OnboardingSelectionView
			cards={finalCards}
			title={roleAppbartitle}
			breadcrumbs={breadcrumbs}
			handleSelectRole={handleSelectSpeciality}
			handleClickContinue={handleClickContinue}
			showBackButton={showSpecialityBack}
			previousStepName="role"
			hasNestedItems={hasNestedItems}
			selectedItems={subSpecialities}
			suffix="interest"
			numMaxSelect={onboardingSpecialityStep?.numMaxSelect}
			handleSkip={handleSkip}
		/>
	);
};

export default SelectSpecialtyRouteContainer;
