/* -----------------UI--------------- */
import { Chip } from "react-native-paper";
import Box from "../Box";

type Props = {
	filters: string[];
	selectedFilters: string[];
	handleSelectFilter: (filter: string) => void;
};
export const Filters = ({
	filters,
	selectedFilters,
	handleSelectFilter,
}: Props) => {
	return (
		<Box
			flexDirection="row"
			style={{
				gap: 8,
				flexWrap: "wrap",
			}}
		>
			{filters.map((filter) => {
				const isSelected = selectedFilters.includes(filter);
				const mode = isSelected ? "flat" : "outlined";
				return (
					<Chip
						key={filter}
						mode={mode}
						// style={{
						//   alignSelf: 'baseline',
						// }}
						compact
						icon={isSelected ? "check" : undefined}
						onPress={() => {
							handleSelectFilter(filter);
						}}
					>
						{filter}
					</Chip>
				);
			})}
		</Box>
	);
};
