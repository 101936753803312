import type { Article } from "../../../../../types";

export const Article656: Article = {
	id: 656,
	rsuiteId: "9406a46e-30da-44fe-a9bf-3be2aeebbe67",
	type: "commentary and perspective",
	title:
		"Compelling Evidence Regarding Long-Term Performance of Highly Cross-Linked Versus Conventional Polyethylene in TKA at 10 Years",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/656.jpeg",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Compelling Evidence Regarding Long-Term Performance of
                Highly Cross-Linked Versus Conventional Polyethylene in TKA at 10 Years</div>
              <div class="text subtitle" style="position: unset;">Commentary on an article by Harold I. Salmons, MD, et
                al.: “Multicenter Randomized Clinical Trial of Highly Cross-Linked Polyethylene Versus Conventional
                Polyethylene in 518 Primary TKAs at 10 Years”</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jeremy M.
                      Gililland, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">While total joint arthroplasty provides truly amazing
                  results for our patients, all arthroplasty surgeons have some degree of anxiety about the long-term
                  implications of some of the technological “improvements” to the devices that we implant in our
                  patients. We have all seen well-intentioned modifications cause enormous amounts of harm, and as a
                  result, many of us very appropriately await Level-I data with long-term follow-up before making any
                  further changes to our practices. It is for this very reason that I was excited to read the article by
                  Salmons et al, as it provides surgeons with just such data regarding the use of highly cross-linked
                  polyethylene (HXLPE) in total knee arthroplasty (TKA).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">The article presents readers with some much-needed and
                  very useful data regarding the use of HXLPE in TKA. While HXLPE has revolutionized total hip
                  arthroplasty with dramatic reductions in wear rates and essentially the elimination of osteolysis,
                  there has still been concern regarding its routine use in TKA. This concern has primarily been driven
                  by the reduced ductility and fatigue resistance of HXLPE, which may confer an increased risk of
                  failure secondary to fracture of the polyethylene insert or the locking mechanism because the knee
                  experiences substantially different force profiles during gait when compared with the hip. Until now,
                  existing data on this topic have been limited to a few randomized controlled trials with short to
                  midterm follow-up, retrospective reviews, and registry data<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">4</span></a>. While a recently published meta-analysis did find that
                  HXLPE use was associated with a lower revision rate for aseptic loosening, its authors noted that they
                  did not have enough data to synthesize and compare liner fracture rates<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">5</span></a>. Those authors clearly noted this limitation in the existing
                  literature and stated that large scale studies with long-term follow-up are needed to document these
                  complications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">The article by Salmons et al. provides us with Level-I
                  multicenter prospective randomized data with a mean follow-up of 11 years to help us make more
                  informed decisions regarding the use of HXLPE in TKA. The authors found no differences in implant
                  survivorship, clinical outcomes, or radiographic results between the treatment and control groups.
                  Very importantly, in this series of posterior-stabilized TKAs, there were no failures due to tibial
                  post fracture or polyethylene wear in the HXLPE group, and only 1 case with a non-HXLPE (ultra-high
                  molecular weight polyethylene) insert required revision for osteolysis, wear, and loosening. While I
                  think it is important to highlight the fact that the rate of osteolysis was still very low in the
                  non-HXLPE group, I do think that the data provide compelling evidence in support of the use of HXLPE
                  in TKA. I commend the authors on successfully completing a large multicenter prospective randomized
                  clinical trial with 11 years of follow-up, as this is a monumental feat in orthopaedic surgery, and I
                  think that their efforts will provide guidance to the orthopaedic community for years to come.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Kim YH</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Park JW</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Comparison of highly
                  cross-linked and conventional polyethylene in posterior cruciate-substituting total knee arthroplasty
                  in the same patients</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2014;96:1807-1.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1201332" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Kindsfater KA</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Pomeroy D</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Clark CR</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Gruen TA</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Murphy J</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Himden S</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">In Vivo Performance of
                  Moderately Crosslinked, Thermally Treated Polyethylene in a Prospective Randomized Controlled Primary
                  Total Knee Arthroplasty Trial</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2015 Aug;30(8):1333-1.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=In%20Vivo%20Performance%20of%20Moderately%20Crosslinked%2C%20Thermally%20Treated%20Polyethylene%20in%20a%20Prospective%20Randomized%20Controlled%20Primary%20Total%20Knee%20Arthroplasty%20Trial&as_occt=title&as_sauthors=%20%22KA%20Kindsfater%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Lachiewicz PF</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Soileau ES</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Is There a Benefit to Highly
                  Crosslinked Polyethylene in Posterior-stabilized Total Knee Arthroplasty? A Randomized Trial</span>.
                <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2016 Jan;474(1):88-1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20There%20a%20Benefit%20to%20Highly%20Crosslinked%20Polyethylene%20in%20Posterior-stabilized%20Total%20Knee%20Arthroplasty%3F%20A%20Randomized%20Trial&as_occt=title&as_sauthors=%20%22PF%20Lachiewicz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Kim YH</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Park JW</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Jang YS</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Kim EJ</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Is Highly Cross-Linked
                  Polyethylene Safe for Use in High-Flexion Posterior Stabilized Total Knee Arthroplasty?</span><span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2023
                Feb;38(2):286-1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20Highly%20Cross-Linked%20Polyethylene%20Safe%20for%20Use%20in%20High-Flexion%20Posterior%20Stabilized%20Total%20Knee%20Arthroplasty%3F&as_occt=title&as_sauthors=%20%22YH%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Gkiatas I</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Karasavvidis T</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Sharma AK</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Xiang W</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Malahias MA</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Chalmers BP</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Sculco PK</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Highly cross-linked polyethylene
                  in primary total knee arthroplasty is associated with a lower rate of revision for aseptic loosening:
                  a meta-analysis of 962,467 cases</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Arch Orthop Trauma Surg.</span> 2022 Jun;142(6):1177-1.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Highly%20cross-linked%20polyethylene%20in%20primary%20total%20knee%20arthroplasty%20is%20associated%20with%20a%20lower%20rate%20of%20revision%20for%20aseptic%20loosening%3A%20a%20meta-analysis%20of%20962%2C467%20cases&as_occt=title&as_sauthors=%20%22I%20Gkiatas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D9406a46e-30da-44fe-a9bf-3be2aeebbe67%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Commentary and Perspective</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 15, 2025;
                      107 (2): e5</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.01004</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">January 15, 2025</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=a29eb012-899d-4a2a-9e08-690734ea4b8f&native=1"
                              class="article-title" style="position: unset;">Multicenter Randomized Clinical Trial of
                              Highly Cross-Linked Polyethylene Versus Conventional Polyethylene in 518 Primary TKAs at
                              10 Years</a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Salmons,
                              Harold I.; Larson, Dirk R.; Ortiguera, Cedric J.; Clarke, Henry D.; Spangehl, Mark J.;
                              Pagnano, Mark W.; Stuart, Michael J.; Abdel, Matthew P.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 107(2):121 | November 6, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2025 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;9406a46e-30da-44fe-a9bf-3be2aeebbe67&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=9406a46e-30da-44fe-a9bf-3be2aeebbe67&type=pdf&name=JBJS.24.01004.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=9406a46e-30da-44fe-a9bf-3be2aeebbe67&type=pdf&name=JBJS.24.01004.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_6" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_7"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I266" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I266</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;9406a46e-30da-44fe-a9bf-3be2aeebbe67&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=9406a46e-30da-44fe-a9bf-3be2aeebbe67&type=zip&name=JBJS.24.01004.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jeremy M. Gililland, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:jeremy.gililland@hsc.utah.edu" class=""
                    style="position: unset;">jeremy.gililland@hsc.utah.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3124-1558" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3124-1558</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, University of Utah, Salt Lake City,
                Utah</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 4878.52px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
