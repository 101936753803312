import { useCallback } from "react";
import { useEffect, useState } from "react";
import { Image, type ImageSourcePropType } from "react-native";

interface ImageDimensions {
	width: number;
	height: number;
}

const useImageSize = (
	sourceImage: ImageSourcePropType,
	isWeb?: boolean,
): { imageDimensions: ImageDimensions } => {
	const [imageDimensions, setImageDimensions] = useState<ImageDimensions>({
		width: 300,
		height: 56,
	});

	const fetchImageSize = useCallback(
		(
			image: ImageSourcePropType,
			callback: (dimensions: ImageDimensions) => void,
		) => {
			if (image && !isWeb) {
				const { uri } = Image.resolveAssetSource(image);
				Image.getSize(uri, (width, height) => callback({ width, height }));
			}
		},
		[isWeb],
	);

	useEffect(() => {
		if (!isWeb) {
			fetchImageSize(sourceImage, setImageDimensions);
		}
	}, [fetchImageSize, isWeb, sourceImage]);

	const height = 56;
	const width = (height * imageDimensions.width) / imageDimensions.height || 0;
	return {
		imageDimensions: {
			width,
			height,
		},
	};
};

export default useImageSize;
