/* ----------------- Hooks Imports --------------- */
import { pageViewTypes } from "@constants/tracking";
import { LeaderboardContainer } from "@features/dashboard";
import ProgressView from "@features/insights/components/InsightsView";
import { isWeb } from "@helpers/platform";
import useAnalytics from "@hooks/useAnalytics";
import { useAppTheme } from "@hooks/useAppTheme";

import { useDevice } from "@memorang/ui";
import CustomTabs from "components/CustomTabs";
/* ----------------- Components Imports --------------- */
import DocumentTitle from "components/DocumentTitle";
import { CustomAppbar } from "components/index";
const InsightsRoute = () => {
	const { isMobile } = useDevice();

	const tabs = [
		{
			label: "Progress",
			content: <ProgressView />,
		},
		{
			label: "Leaderboard",
			content: <LeaderboardContainer />,
		},
	];

	const theme = useAppTheme();
	useAnalytics({
		type: pageViewTypes.insights,
	});
	return (
		<>
			<CustomAppbar
				showCommandBar={isWeb}
				align="flex-start"
				mode="small"
				style={{
					backgroundColor: theme.colors.elevation.level3,
				}}
				options={{
					headerTitle: "Insights",
					subtitle: isMobile ? undefined : "Your insights",
				}}
			/>

			<DocumentTitle title="Insights" />
			<CustomTabs tabs={tabs} />
		</>
	);
};

export default InsightsRoute;
