/* ----------------- React Native --------------- */
import { StyleSheet, View } from "react-native";

/* ----------------- React Native Paper --------------- */
import { Card, Chip, FAB, IconButton } from "react-native-paper";

/* ----------------- Expo --------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { BlurView } from "expo-blur";

import { useImageDimensions } from "@react-native-community/hooks";
import { useAppTheme } from "../../useAppTheme";
import { useDevice } from "../../useDevice";
/* ----------------- Local Imports --------------- */
import Box from "../Box";
import { supabaseImageUrlParser } from "../Image";
import type { IconType } from "../TextLoop";
import { type HandleTrackVideo, VideoPlayer } from "../VideoPlayer";
import Responses from "./Responses";

interface ButtonProps {
	cta: string;
	onPress?: () => void;
	icon: IconType;
	variant?: "small" | "large";
}
interface Props {
	title: string;
	numResponses?: number;
	description?: string;
	imageUri?: string;
	buttonProps?: ButtonProps;
	onPress?: () => void;
	fullWidth?: boolean;
	type?: string;
	videoUri?: string;
	cardWidth?: number;
	locked?: boolean;
	completed?: boolean;
	handleTrackVideo?: HandleTrackVideo;
}

const ChallengeCardButton = ({ cta, onPress, icon, variant }: ButtonProps) => {
	const theme = useAppTheme();
	return (
		<Box
			position="absolute"
			left={0}
			right={0}
			justifyContent="center"
			alignItems="center"
		>
			{variant === "small" ? (
				<IconButton mode="contained" icon={icon} onPress={onPress} />
			) : (
				<FAB
					label={cta}
					style={{
						borderRadius: 28,
					}}
					icon={() => {
						return (
							<Icon name={icon} size={24} color={theme.colors.onSurface} />
						);
					}}
					onPress={onPress}
				/>
			)}
		</Box>
	);
};

const ChallengeCard = ({
	cardWidth = 250,
	title,
	description,
	numResponses,
	imageUri,
	buttonProps,
	onPress,
	fullWidth = true,
	videoUri,
	locked,
	completed,
	handleTrackVideo,
}: Props) => {
	const showSubtitle = description != null || numResponses != null;
	const theme = useAppTheme();

	const optimizedImageUri = supabaseImageUrlParser({
		src: imageUri!,
		width: cardWidth,
	});

	const { dimensions } = useImageDimensions({
		uri: optimizedImageUri,
	});

	const { isMobile } = useDevice();
	const Overlay = () => {
		return locked && !completed ? (
			<BlurView
				style={{
					...StyleSheet.absoluteFillObject,
					justifyContent: "center",
					alignItems: "center",
					borderTopLeftRadius: 10,
					borderTopRightRadius: 10,
					overflow: "hidden",
				}}
				intensity={20}
			>
				{locked ? (
					<Icon name="lock-outline" size={48} color={theme.colors.white} />
				) : null}
			</BlurView>
		) : completed ? (
			<Box
				backgroundColor={theme.colors.backdrop}
				borderTopLeftRadius={10}
				borderTopRightRadius={10}
				style={{
					...StyleSheet.absoluteFillObject,
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Icon
					name="checkbox-marked-circle"
					size={48}
					color={theme.palette.success.light}
				/>
			</Box>
		) : (
			<Box
				backgroundColor={theme.colors.backdrop}
				borderTopLeftRadius={10}
				borderTopRightRadius={10}
				style={{
					...StyleSheet.absoluteFillObject,
					justifyContent: "center",
					alignItems: "center",
				}}
			/>
		);
	};

	const Subtitle = () => {
		return (
			<View
				style={{
					width: "100%",
					flexDirection: "row",
					justifyContent: "space-between",
					gap: 8,
					// paddingVertical: 8,
				}}
			>
				<Chip
					compact
					style={{
						borderRadius: 20,
						backgroundColor: theme.colors.surfaceVariant,
					}}
					textStyle={{
						fontFamily: "OpenSans-SemiBold",
						fontSize: 12,
					}}
				>
					{description}
				</Chip>
				{numResponses ? <Responses numResponses={numResponses} /> : null}
			</View>
		);
	};

	const cardContainerWidth = fullWidth
		? isMobile
			? "100%"
			: 600
		: Math.min(cardWidth, 260);

	return (
		<Card
			onPress={onPress}
			style={{
				width: cardContainerWidth,
			}}
		>
			<Box position="relative" justifyContent="center">
				{videoUri ? (
					<VideoPlayer
						videoUrl={videoUri}
						handleTrackVideo={handleTrackVideo}
					/>
				) : (
					<Card.Cover
						style={{
							borderBottomLeftRadius: 0,
							borderBottomRightRadius: 0,
							height: fullWidth ? undefined : 140,
							maxWidth: cardContainerWidth,
							aspectRatio: dimensions?.aspectRatio,
							minWidth: "100%",
							alignSelf: "center",
						}}
						source={{
							uri: optimizedImageUri,
						}}
					/>
				)}
				<Overlay />
				{buttonProps && (
					<ChallengeCardButton {...buttonProps} onPress={onPress} />
				)}
			</Box>
			<Card.Title
				title={title}
				titleNumberOfLines={1}
				titleStyle={{
					fontFamily: "OpenSans-SemiBold",
					paddingVertical: 8,
				}}
				subtitleStyle={{
					paddingBottom: 8,
				}}
				subtitle={showSubtitle && <Subtitle />}
			/>
		</Card>
	);
};

export default ChallengeCard;
