import { mockedChallengesFolders } from "@constants/mocked-image-challenge-data";

export const useChallenges = () => {
	const handleQuickActionPress = (_type?: string) => {
		//TODO: Implement quick action press
	};

	const quickActionsChips = [
		{
			label: "Random challenge",
			icon: "shuffle",
			tooltip: "What’s new in knee?",
			type: "RANDOM_CHALLENGE",
		},
		{
			label: "Challenge round",
			icon: "playlist-play",
			tooltip: "Complete challenge",
			type: "CHALLENGE_ROUND",
		},
	];

	const challengesSectionList = [
		{
			title: "12 topics - 100 challenges",
			rightButtonProps: {
				cta: "View more",
				link: "/challenges/all",
			},
			data: [mockedChallengesFolders],
		},
	];

	return {
		handleQuickActionPress,
		quickActionsChips,
		challengesSectionList,
	};
};
