import type { Article } from "../../../../../types";

export const Article533: Article = {
	id: 533,
	rsuiteId: "76fefa39-b24a-4049-83a4-50e97505da20",
	type: "erratum",
	title:
		"Opioid-Free Analgesia Provides Pain Control Following Thumb Carpometacarpal Joint Arthroplasty",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/533.png",
	subSpecialties: ["hand_wrist"],
	content: `<div id="main" class="" style="opacity: 1; position: unset">
<div class="article lens-article" style="position: unset" data-context="toc">
<div class="panel content document width100" style="position: unset">
<div class="surface resource-view content" style="position: unset">
  <div class="nodes" style="padding-left: 0px; position: unset">
    <div
      class="content-node cover"
      data-id="cover"
      style="padding-top: 30px; position: unset"
    >
      <div class="content" style="position: unset">
        <div class="text title" style="position: unset">
          Erratum: Opioid-Free Analgesia Provides Pain Control Following
          Thumb Carpometacarpal Joint Arthroplasty
        </div>
        <div class="text subtitle" style="position: unset"></div>
        <div class="authors" style="position: unset"></div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_2"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_2"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            <span
              data-id="emphasis_1"
              class="annotation emphasis"
              style="position: unset"
              >The Journal</span
            >
            publishes corrections when they are of significance to patient
            care, scientific data or record-keeping, or authorship,
            whether that error was made by an author, editor, or staff.
            Errata also appear in the online version and are attached to
            files downloaded from
            <a
              href="http://jbjs.org"
              target="_blank"
              data-id="link_1"
              class="link"
              style="position: unset"
              >jbjs.org</a
            >.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_3"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_3"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            In the article entitled “Opioid-Free Analgesia Provides Pain
            Control Following Thumb Carpometacarpal Joint Arthroplasty” (J
            Bone Joint Surg Am. 2023;105[22]:1750-8), by Hysong et al.,
            there were errors on page 1757. Specifically, the Note that
            had read “The CORE Study Group includes Todd M. Chapman Jr.,
            MD; Bruce E. Cohen, MD; Patrick M. Connor, MD; Brian M.
            Curtin, MD; W. Hodges Davis, MD; J. Kent Ellington, MD; James
            E. Fleischli, MD; Samuel E. Ford, MD; Todd A. Irwin, MD;
            Carroll P. Jones III, MD; R. Alden Milam IV, MD; Bryan M.
            Saltzman, MD; P. Bradley P. Segebarth; Shadley C. Schiffern,
            MD; and Scott B. Shawen, MD.” should have read “The CORE Study
            Group includes Todd M. Chapman Jr., MD; Bruce E. Cohen, MD;
            Patrick M. Connor, MD; Brian M. Curtin, MD; W. Hodges Davis,
            MD; J. Kent Ellington, MD; James E. Fleischli, MD; Samuel E.
            Ford, MD; Todd A. Irwin, MD; Carroll P. Jones III, MD; Daniel
            P. Leas, MD; R. Alden Milam IV, MD; Bryan M. Saltzman, MD; P.
            Bradley Segebarth, MD; Shadley C. Schiffern, MD; and Scott B.
            Shawen, MD.”
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <a
      href=""
      data-id="eletter_reference"
      class="annotation eletter_reference resource-reference"
      style="position: unset"
    ></a>
    <div
      class="content-node eletter-submit"
      data-id="eletters"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="label">eLetters</div>
        <div class="link" style="position: unset">
          <a
            href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D76fefa39-b24a-4049-83a4-50e97505da20%26native%3D1"
            target="_blank"
            class=""
            style="position: unset"
            ><img
              class="image"
              src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
              style="position: unset"
            /><span class="label">Submit an eLetter</span></a
          >
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-1"
      data-id="heading_2"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset"
          >Additional information</span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node publication-info"
      data-id="publication_info"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="meta-data" style="position: unset">
          <div class="journal" style="position: unset">
            <div class="label">Journal</div>
            <div class="value" style="position: unset">
              <span class="" style="position: unset"
                >The Journal of Bone and Joint Surgery</span
              >
            </div>
          </div>
          <div class="subject" style="position: unset">
            <div class="label">Section</div>
            <div class="value" style="position: unset">Erratum</div>
          </div>
          <div class="publishing" style="position: unset">
            <div class="label">Published</div>
            <div class="value" style="position: unset">
              <span class="" style="position: unset"
                >September 4, 2024; 106 (17): e39</span
              >
            </div>
          </div>
          <div class="doi" style="position: unset">
            <div class="label">DOI</div>
            <div class="value" style="position: unset">
              <span class="" style="position: unset"
                >10.2106/JBJS.ER.22.01278</span
              >
            </div>
          </div>
          <div class="dates" style="position: unset">
            The article was first published on
            <b class="" style="position: unset">September 4, 2024</b>.
          </div>
        </div>
        <div
          class="content-node paragraph"
          data-id="articleinfo"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            <div
              class="content-node related-articles"
              data-id="related_articles_1"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div class="related-articles-box" style="position: unset">
                  <div class="related-article" style="position: unset">
                    <div class="heading" style="position: unset">
                      <i class="fa fa-link" style="position: unset"></i
                      >Article
                    </div>
                    <div class="article-info" style="position: unset">
                      <a
                        href="https://www.jbjs.org/reader.php?rsuite_id=482ec6c6-6899-4309-826f-c91af1d2d61e&native=1"
                        class="article-title"
                        style="position: unset"
                        >Opioid-Free Analgesia Provides Pain Control
                        Following Thumb Carpometacarpal Joint
                        Arthroplasty</a
                      >
                      <div
                        class="article-authors text-with-ellipsis one-line"
                        style="position: unset"
                      >
                        Hysong, Alexander A.; Odum, Susan M.; Lake,
                        Nicholas H.; Hietpas, Kayla T.; Michalek, Caleb
                        J.; Hamid, Nady; Gaston, Raymond G.; Loeffler,
                        Bryan J.; the CORE Study Group :~ Chapman, Todd
                        M.; Cohen, Bruce E.; Connor, Patrick M.; Curtin,
                        Brian M.; Davis, W. Hodges; Ellington, J. Kent;
                        Fleischli, James E.; Ford, Samuel E.; Irwin, Todd
                        A.; Jones, Carroll P.; Leas, Daniel P.; Alden
                        Milam, R.; Saltzman, Bryan M.; Segebarth, P.
                        Bradley; Schiffern, Shadley C.; Shawen, Scott B.;
                      </div>
                      <div
                        class="article-citations"
                        style="position: unset"
                      >
                        <img
                          src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg"
                          style="position: unset"
                        />J Bone Joint Surg Am, 105(22):1750 | August 31,
                        2023
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node heading level-3"
              data-id="heading_1"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <span class="text title" style="position: unset"
                  >Copyright & License</span
                >
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node paragraph"
              data-id="paragraph_1"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div
                  class="content-node text"
                  data-id="text_1"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    Copyright © 2024 by The Journal of Bone and Joint
                    Surgery, Incorporated.
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node supplement"
              data-id="supplement_1"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div class="file" style="position: unset">
                  <span class="" style="position: unset"
                    >Open article PDF</span
                  ><a
                    class="jbjs_tracking gtm_tracking"
                    jbjs_tracking_type="download"
                    jbjs_tracking_data='{"id":"76fefa39-b24a-4049-83a4-50e97505da20","type":"article","topics":[]}'
                    href="https://www.jbjs.org/reader.php?rsuite_id=76fefa39-b24a-4049-83a4-50e97505da20&type=pdf&name=JBJS.ER.22.01278.pdf"
                    target="_blank"
                    gtm_action="reader"
                    gtm_category="PDF_article_downloads"
                    gtm_label="https://www.jbjs.org/reader.php?rsuite_id=76fefa39-b24a-4049-83a4-50e97505da20&type=pdf&name=JBJS.ER.22.01278.pdf"
                    jbjs_tracking_source="reader"
                    style="position: unset"
                    ><img
                      src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                      style="position: unset"
                    />
                    Download</a
                  >
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <a class="logo" href="home.php" style="position: unset"></a>
  </div>
</div>
<div
  class="surface-scrollbar content hidden"
  style="display: none; position: unset"
>
  <div
    class="visible-area"
    style="top: 0px; height: 2172.21px; position: unset"
  ></div>
</div>
</div>
</div>
</div>
`,
};
