import type { Mode, SessionItem, SessionResponse } from "@memorang/types";
import { create } from "zustand";
import type {
	Answer,
	AttemptState,
	BlockState,
	ItemState,
	SessionState,
	UpdateItemStatesPayload,
} from "./types";

const useSessionStore = create<SessionState>()((set) => ({
	sessionItems: [],
	currentItemIndex: 0,
	currentProgress: 0,
	id: "",
	numericId: 0,
	isSummativeTest: false,
	confettiCannons: new Map<string, boolean>(),
	currentBlockIndex: 0,
	numItemsRequested: 0,
	attempts: new Map<string, AttemptState>(),
	mode: "QUESTION",
	totalTime: 0,
	sessionTime: 0,
	numGoal: 0,
	inReviewMode: false,
	timeElapsed: 0,
	startTime: "",
	blockStates: new Map<string, BlockState>(),
	itemStates: new Map<string, ItemState>(),
	updateTimeElapsed: (timeElapsed: number) => {
		set({ timeElapsed });
	},
	updateAnswerEventInProgress: (isAnswerEventInProgress: boolean) => {
		set({ isAnswerEventInProgress });
	},
	updateEndingSessionInProgress: (isEndingSessionInProgress: boolean) => {
		set({ isEndingSessionInProgress });
	},
	updateCurrentBlockIndex: (index: number) => {
		set({ currentBlockIndex: index });
	},
	toggleShowMoreItemsAlert: () => {
		set((state) => {
			return {
				showMoreItemsAlert: !state.showMoreItemsAlert,
			};
		});
	},
	updateShowConfetti: ({
		itemId,
		showConfetti,
	}: { itemId: string; showConfetti: boolean }) => {
		set((state) => {
			return {
				confettiCannons: new Map(state.confettiCannons).set(
					itemId,
					showConfetti,
				),
			};
		});
	},
	updateSession: ({
		sessionResponse,
		isReverseMode,
		mode,
		isSummativeTest,
		inReviewMode,
		numItemsRequested,
		isRecommendedTask,
		isWeeklyChallenge,
	}: {
		sessionResponse: SessionResponse;
		isReverseMode?: boolean;
		mode?: Mode;
		isSummativeTest?: boolean;
		inReviewMode?: boolean;
		numItemsRequested?: number;
		isRecommendedTask?: boolean;
		isWeeklyChallenge?: boolean;
	}) => {
		const initBlockStates = isSummativeTest
			? sessionResponse.sessionItems.reduce((acc, item) => {
					acc.set(item.id, {
						currentProgress: 0,
						completed: false,
						completedStatuses: {},
						markedItemsForReview: [],
						highlightedItems: new Map<string, string>(),
					});
					return acc;
				}, new Map<string, BlockState>())
			: new Map<string, BlockState>();

		const numGoal = isSummativeTest
			? sessionResponse.sessionItems[0].children.length
			: sessionResponse.sessionItems.length;

		const showMoreItemsAlert = numItemsRequested
			? numItemsRequested < numGoal
			: false;

		const isOlx = sessionResponse.examName?.includes("OLX");

		const startTime = sessionResponse.startTime;
		const totalTime = sessionResponse.totalTime || 0;

		const getOlxElapsedTime = () => {
			if (startTime) {
				const currentTime = Date.now();
				const timeElapsed = Math.floor(
					(currentTime - new Date(startTime).getTime()) / 1000,
				);

				return Math.max(0, timeElapsed);
			}
			return 0;
		};
		const finalSessionTime = isOlx
			? getOlxElapsedTime()
			: sessionResponse.sessionTime || 0;

		set({
			sessionItems: sessionResponse.sessionItems,
			numGoal,
			id: sessionResponse.id,
			isReverseMode,
			mode,
			numericId: sessionResponse.numericId,
			isSummativeTest,
			examName: sessionResponse.examName,
			blockStates: initBlockStates,
			totalTime,
			inReviewMode: inReviewMode,
			reportSessionType: sessionResponse.reportSessionType,
			sessionTime: finalSessionTime,
			showMoreItemsAlert,
			numItemsRequested: numItemsRequested || 0,
			isRecommendedTask,
			startTime,
			isEndingSessionInProgress: true,
			isWeeklyChallenge,
		});
	},
	updateCurrentItemIndex: (index: number) => set({ currentItemIndex: index }),
	updateAnswers: (id: string, answer: Answer) => {
		set((state) => {
			const answers = state.answers || new Map<string, Answer>();
			const selectedChoiceIds =
				state.itemStates?.get(id)?.selectedChoiceIds || [];
			answers.set(id, {
				...answer,
				selectedChoiceIds: selectedChoiceIds,
			});
			return {
				answers: new Map(answers),
			};
		});
	},

	updateProgress: (progress: number) => {
		set((state) => {
			const { currentBlockIndex, isSummativeTest } = state;
			const blockId = state.sessionItems?.[currentBlockIndex].id || "";
			const existingBlockState = state.blockStates.get(blockId);
			if (isSummativeTest) {
				const newBlockStates = new Map(state.blockStates);
				newBlockStates.set(blockId, {
					...(existingBlockState || {}),
					currentProgress: progress,
				});
				return { blockStates: newBlockStates };
			}
			return { currentProgress: progress };
		});
	},
	updateHintUsed: (itemId: string) => {
		set((state) => {
			const hintUsed = state.hintUsed || new Map<string, boolean>();
			hintUsed.set(itemId, true);
			return { hintUsed: new Map(hintUsed) };
		});
	},
	updateProgressForEachBlock: (progress: number, blockId: string) => {
		set((state) => {
			const blockStates = state.blockStates || new Map<string, BlockState>();
			const blockState = blockStates.get(blockId) || {};
			blockStates.set(blockId, { ...blockState, currentProgress: progress });
			return { blockStates: new Map(blockStates) };
		});
	},
	updateHighlightedItems: (itemId: string, highlightedHtml?: string) =>
		set((state) => {
			const { currentBlockIndex } = state;
			const blockId = state.sessionItems?.[currentBlockIndex].id || "";
			const existingBlockState = state.blockStates.get(blockId);
			const currentHighlightedItems =
				existingBlockState?.highlightedItems || new Map<string, string>();

			const updatedHighlightedItems = new Map(currentHighlightedItems);
			updatedHighlightedItems.set(itemId, highlightedHtml);

			const updatedBlockState = {
				...existingBlockState,
				highlightedItems: updatedHighlightedItems,
			};

			const updatedBlockStates = new Map(state.blockStates);
			updatedBlockStates.set(blockId, updatedBlockState);

			return {
				blockStates: updatedBlockStates,
			};
		}),
	updateNumericId: (numericId: number) => set({ numericId }),
	updateItemAttempts: ({
		itemId,
		selectedChoiceId,
		correct,
		crossOut,
		answered,
		showTryAgain,
		attempted,
	}: {
		itemId: string;
		selectedChoiceId?: string;
		correct?: boolean;
		crossOut?: boolean;
		answered?: boolean;
		showTryAgain?: boolean;
		attempted?: boolean;
	}) => {
		set((state) => {
			const attempts = new Map(state.attempts);
			let attempt = attempts.get(itemId) || { choiceAttempts: {} };

			if (crossOut && selectedChoiceId) {
				const currentChoiceIdCrossedOut =
					attempt.choiceAttempts[selectedChoiceId]?.crossOut;
				if (currentChoiceIdCrossedOut) {
					delete attempt.choiceAttempts[selectedChoiceId];
				} else {
					attempt.choiceAttempts[selectedChoiceId] = {
						correct,
						crossOut: true,
					};
				}
			} else if (selectedChoiceId) {
				if (
					attempt.currentSelectedChoiceId === selectedChoiceId &&
					answered == null
				) {
					delete attempt.choiceAttempts[selectedChoiceId];
					attempt.currentSelectedChoiceId = undefined;
				} else {
					if (answered == null) {
						attempt.choiceAttempts = Object.fromEntries(
							Object.entries(attempt.choiceAttempts).filter(
								([_, value]) => value.attempted,
							),
						);
					}

					attempt.choiceAttempts[selectedChoiceId] = {
						...attempt.choiceAttempts[selectedChoiceId],
						correct,
						crossOut: false,
						attempted: attempted,
					};
					attempt.currentSelectedChoiceId = selectedChoiceId;
				}
			}

			attempt = {
				...attempt,
				answered: attempt.answered || answered,
				showTryAgain,
				currentSelectedChoiceId: attempt.showTryAgain
					? undefined
					: attempt.currentSelectedChoiceId,
			};

			attempts.set(itemId, attempt);
			return { attempts };
		});
	},
	updateItemStates: ({
		itemId,
		selectedChoiceId,
		multiSelect,
		correctAnswerIds,
		crossOut,
		scores,
		answered,
		correctAnswerOrder,
	}: UpdateItemStatesPayload) => {
		set((state) => {
			const itemStates = state.itemStates || new Map<string, ItemState>();
			const itemState = itemStates.get(itemId);

			if (correctAnswerOrder?.length) {
				itemStates.set(itemId, {
					selectedChoiceIds: [],
					correctAnswerIds,
					crossedOutChoiceIds: [],
					scores: [],
					answered: answered,
					correctAnswerOrder: correctAnswerOrder,
				});
				return { itemStates: new Map(itemStates) };
			}
			let selectedChoiceIds = itemState?.selectedChoiceIds || [];
			const crossedOutChoiceIds = itemState?.crossedOutChoiceIds || [];
			const index = selectedChoiceIds.indexOf(selectedChoiceId);
			const crossOutIndex = crossedOutChoiceIds.indexOf(selectedChoiceId);

			if (crossOut) {
				if (crossOutIndex > -1) {
					crossedOutChoiceIds.splice(crossOutIndex, 1);
				} else {
					crossedOutChoiceIds.push(selectedChoiceId);
					if (index > -1) {
						selectedChoiceIds.splice(index, 1);
					}
				}
			} else {
				if (multiSelect) {
					if (index > -1) {
						selectedChoiceIds.splice(index, 1);
					} else {
						selectedChoiceIds.push(selectedChoiceId);
					}
				} else if (!correctAnswerIds?.length) {
					if (index > -1) {
						selectedChoiceIds = [];
					} else {
						selectedChoiceIds = [selectedChoiceId];
					}
				}
				if (crossOutIndex > -1) {
					crossedOutChoiceIds.splice(crossOutIndex, 1);
				}
			}

			itemStates.set(itemId, {
				selectedChoiceIds: [...selectedChoiceIds],
				correctAnswerIds,
				crossedOutChoiceIds: [...crossedOutChoiceIds],
				scores: scores || [],
				answered: answered,
				correctAnswerOrder: itemState?.correctAnswerOrder,
			});
			return { itemStates: new Map(itemStates) };
		});
	},
	updateInReview: (inReviewMode: boolean, sessionItems: SessionItem[]) =>
		set((state) => {
			if (inReviewMode) {
				return {
					inReviewMode,
					persistedCurrentProgress: state.currentProgress,
					currentProgress: 0,
					currentItemIndex: 0,
					persistedItems: state.sessionItems,
					sessionItems,
					numGoal: sessionItems.length,
				};
			}
			return {
				inReviewMode,
				currentProgress: state.persistedCurrentProgress,
				items: state.persistedSessionItems,
			};
		}),
	updateMarkedItemsForReview: (itemId: string) =>
		set((state) => {
			const { currentBlockIndex } = state;
			const blockId = state.sessionItems?.[currentBlockIndex].id || "";
			const existingBlockState = state.blockStates.get(blockId);
			const currentMarkedItems = existingBlockState?.markedItemsForReview || [];
			const isItemMarked = currentMarkedItems.includes(itemId);

			const updatedMarkedItems = isItemMarked
				? currentMarkedItems.filter((id) => id !== itemId)
				: [...currentMarkedItems, itemId];

			const updatedBlockState = {
				...existingBlockState,
				markedItemsForReview: updatedMarkedItems,
			};

			const updatedBlockStates = new Map(state.blockStates);
			updatedBlockStates.set(blockId, updatedBlockState);

			return {
				blockStates: updatedBlockStates,
			};
		}),
	resetSessionStore: () =>
		set({
			currentItemIndex: 0,
			currentProgress: 0,
			itemStates: undefined,
			answers: undefined,
			numGoal: 0,
			isSummativeTest: false,
			isReverseMode: false,
			inReviewMode: false,
			timeElapsed: 0,
			currentBlockIndex: 0,
			blockStates: new Map<string, BlockState>(),
			totalTime: 0,
			sessionTime: 0,
			numItemsRequested: 0,
			isEndingSessionInProgress: false,
			isAnswerEventInProgress: false,
			attempts: new Map<string, AttemptState>(),
			confettiCannons: new Map<string, boolean>(),
			hintUsed: new Map<string, boolean>(),
			numericId: undefined,
		}),
}));

export default useSessionStore;
