import type { Article } from "../../../../../types";

export const Article504: Article = {
	id: 504,
	rsuiteId: "b95cad4a-f475-4396-aca3-0349ac24686e",
	type: "the orthopaedic forum",
	title: "Medicare’s Post-Acute Care Reimbursement Models as of 2023",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b95cad4a-f475-4396-aca3-0349ac24686e&type=jpeg&name=JBJS.23.00422f1",
	subSpecialties: ["trauma"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Medicare’s Post-Acute Care Reimbursement Models as of
                2023</div>
              <div class="text subtitle" style="position: unset;">Past, Present, and Future</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ahmed
                      Siddiqi, DO, MBA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ignacio
                      Pasqualini, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 6 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract:</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">The Centers for Medicare & Medicaid Services is
                        continually working to mitigate unnecessary expenditures, particularly in post-acute care (PAC).
                        Medicare reimburses for orthopaedic surgeon services in varied models, including
                        fee-for-service, bundled payments, and merit-based incentive payment systems. The goal of these
                        models is to improve the quality of care, reduce health-care costs, and encourage providers to
                        adopt innovative and efficient health-care practices.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;">This article delves into the implications of each
                        payment model for the field of orthopaedic surgery, highlighting their unique features,
                        incentives, and potential impact in the PAC setting. By considering the historical, current, and
                        future Medicare reimbursement models, we hope to provide an understanding of the optimal payment
                        model based on the specific needs of patients and providers in the PAC setting.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Some of the most prominent Centers for Medicare &
                  Medicaid Services (CMS) payment models for orthopaedic surgery services include Fee-for-Service (FFS),
                  bundled payments, the Merit-based Incentive Payment System (MIPS), the Comprehensive Care for Joint
                  Replacement (CJR) model, and related advanced alternative payment models (APMs)<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">6</span></a>.
                  Medicare also employs other payment models that indirectly impact orthopaedic surgery services, such
                  as Accountable Care Organizations (ACOs) and Medicare Advantage (MA) plans. The goals of these payment
                  models are to improve quality of care, reduce health-care costs, and encourage providers to adopt
                  innovative and efficient health-care practices<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">12</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">As health-care spending continues to rise at a rate of 8%
                  to 10% annually (it reached &gt;$900.8 billion in 2021), Medicare continues to evaluate and modify
                  these payment models to achieve the above-mentioned goals. Approximately 36% of payments for total
                  joint replacement (TJR) are for the post-discharge period, with unplanned hospital readmissions and
                  post-acute care (PAC) facilities accounting for 11% and 70% of post-discharge payments, respectively<a
                    href="" data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">13</span></a>. Therefore, Medicare has paid special attention to the
                  development of PAC reimbursement models to reduce unnecessary expenditures<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">17</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">In this article, we describe recent Medicare legislation
                  in the area and the different reimbursement models, including FFS, bundled payments, the MIPS, and the
                  future unified PAC payment system, highlighting their unique features, incentives, and potential
                  impact in the PAC setting.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Medicare PAC
                Services</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Medicare provides coverage for a variety of PAC services,
                  including inpatient rehabilitation facilities (IRFs), skilled nursing facilities (SNFs), and home
                  health care (HHC) agencies<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">14</span></a>.
                  Comprehensive data on the effectiveness and cost-effectiveness of different types of PAC in the field
                  of orthopaedics are somewhat limited and inconclusive<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">18</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">19</span></a>. Several studies have suggested that patients can do just
                  as well, if not better, in HHC settings compared with institutional settings such as IRFs or SNFs <a
                    href="" data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">19</span></a>.
                  Therefore, while some studies suggest that certain types of PAC could potentially offer similar
                  outcomes at a lower cost, more research is needed to fully understand the tradeoffs. The most
                  effective and cost-effective approach may vary depending on a range of factors, including specifics
                  about the patient and the procedure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The
                Improving Medicare Post-Acute Care Transformation Act</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">The primary goal of the Improving Medicare Post-Acute Care
                  Transformation (IMPACT) Act of 2014 was to improve the quality and coordination of PAC services
                  provided to Medicare beneficiaries<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">14</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">20</span></a>. The act requires PAC providers, including SNFs, IRFs,
                  long-term care hospitals, and HHC agencies, to use a standardized assessment tool, the Continuity
                  Assessment Record and Evaluation (CARE) Item Set, to evaluate patients’ care needs and certain quality
                  measures<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">21</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">22</span></a>. Especially pertinent to the field of orthopaedics is the
                  standardized assessment of function on the CARE tool, Section GG, which is divided into self-care and
                  mobility sections<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">20</span></a> (<a
                    href="https://www.aota.org/practice/practice-essentials/documentation/section-gg-medicare-selfcare-measures"
                    target="_blank" data-id="link_6" class="link"
                    style="position: unset;">https://www.aota.org/practice/practice-essentials/documentation/section-gg-medicare-selfcare-measures</a>).
                  The American Occupational Therapy Association (AOTA) is deeply involved in PAC settings and the care
                  transitions of patients. Therefore, the AOTA has a vested interest in ensuring that the role of
                  occupational therapy is adequately represented and recognized in tools such as the CARE Item Set,
                  which evaluates functional status and other clinical domains that are central to occupational therapy.
                  The AOTA has played an essential role in providing feedback and advocating for the appropriate
                  representation of occupational therapy in the development and revision of standardized patient
                  assessments such as Section GG. Understanding Section GG is vital for occupational therapists because
                  it directly relates to the functional tasks that these therapists address in PAC settings, especially
                  after TJR.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The Medicare
                Access and CHIP Reauthorization Act</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The Medicare Access and CHIP (Children’s Health Insurance
                  Program) Reauthorization Act (MACRA) of 2015 is the U.S. federal law that aims to reform the payment
                  systems that are used by the Medicare program to pay health-care providers<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">23</span></a>. The main goal of MACRA was to shift Medicare from an FFS
                  payment model to a value-based payment model where providers are incentivized to deliver high-quality,
                  cost-effective care. MACRA established the Quality Payment Program, which includes 2 payment tracks:
                  the MIPS and advanced APMs<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">23</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">CMS Payment
                Models</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Medicare payment models for orthopaedic surgery services
                  include FFS, bundled payments, MIPS, CJR, and related APMs (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>, <a
                    href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). These models are designed to enhance care coordination,
                  improve efficiency, and reduce costs, thereby benefiting various stakeholders (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). The increasing trend of same-day TJR procedures can also
                  have implications for stakeholders under Medicare reimbursement models. For PAC providers, such as
                  rehabilitation facilities and HHC agencies, the shift toward same-day TJR may result in decreased
                  utilization of their services. It is important for reimbursement models to consider the evolving care
                  delivery models and adjust payment structures to ensure that appropriate care is provided to patients
                  who require PAC despite the shorter hospital stay.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00422f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b95cad4a-f475-4396-aca3-0349ac24686e&type=jpeg&name=JBJS.23.00422f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_13" style="position: unset;">
                        <div class="content" style="position: unset;">Timeline for past, current, and potential future
                          CMS payment models. BPCI = Bundled Payments for Care Improvement, and MVPs = Merit-based
                          Incentive Payment Value Pathways.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Key Features of Each Payment Model<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1355px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Model</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Incentives</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Structure</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CJR model</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Encourages providers to
                          reduce costs and improve quality by holding them accountable for the entire episode of care,
                          from the initial hospitalization through 90 days post-discharge. Providers can earn bonuses or
                          be penalized based on quality and cost metrics.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Bundled payment model
                          where a fixed payment is made for an entire episode of care, including PAC.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BPCI program</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Similar to CJR,
                          incentivizes providers to reduce costs and improve quality across an episode of care. Payments
                          are tied to performance on quality metrics.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Also a bundled payment
                          model, with different variants allowing for more or less risk and flexibility.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MIPS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Incentivizes individual
                          clinicians to improve quality and reduce costs. Clinicians can receive adjustments to their
                          Medicare payments based on performance in 4 categories: quality, cost, improvement activities,
                          and promoting interoperability.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Pay-for-performance
                          model, where payments are adjusted based on a composite performance score. Medicare determines
                          the scoring.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MVPs</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Designed to align
                          measures across a specific condition or specialty, making it easier for clinicians to
                          understand and participate in MIPS. The incentives are similar to MIPS as it is a part of the
                          broader MIPS program.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Still a part of MIPS but
                          aims to simplify and streamline the process by focusing on a smaller set of more relevant
                          measures.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Proposed UPAC PPS</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The incentives are still
                          being developed, but the goal is to reduce discrepancies and inefficiencies in the current
                          system by standardizing payments across different PAC settings.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The specific structure is
                          still being developed, but it aims to be a unified, standardized payment system for PAC across
                          different settings.</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">CJR = Comprehensive Care for Joint Replacement, PAC = post-acute care,
                        BPCI = Bundled Payments for Care Improvement, MIPS = Merit-based Incentive Payment System, MVPs
                        = Merit-based Incentive Payment Value Pathways, and UPAC PPS = Unified Post-Acute Care
                        Prospective Payment System.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Payment Model Effects on Different Stakeholders<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1828px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Stakeholders</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Effects of
                          Payment Models</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Effects of
                          Payment Models on Same-Day TJR</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Patients</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Improved care
                          coordination and potentially better outcomes<br data-id="break_1"
                            style="position: unset;">Potentially lower out-of-pocket costs<br data-id="break_2"
                            style="position: unset;">More seamless and customized patient care experience<br
                            data-id="break_3" style="position: unset;">Restricted choices if limited to certain
                          physician providers</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Potential for shorter
                          hospital stays and faster recovery<br data-id="break_4" style="position: unset;">Reduced
                          financial burden and cost savings due to outpatient setting</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hospitals</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Cost savings and improved
                          relationships with other providers<br data-id="break_5" style="position: unset;">Financial
                          risk as they may be accountable for costs and outcomes outside of their control<br
                            data-id="break_6" style="position: unset;">Increased expenditure on infrastructure such as
                          data analytics and care coordination teams</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Increased efficiency and
                          utilization of resources</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Physicians</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Opportunities for closer
                          collaboration with hospitals and other providers<br data-id="break_7"
                            style="position: unset;">Financial risks and increased administrative burdens<br
                            data-id="break_8" style="position: unset;">Potential loss of autonomy as they may be
                          pressured to adhere to certain care pathways or use specific providers</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Integration with surgical
                          teams and improved coordination for same-day TJR<br data-id="break_9"
                            style="position: unset;">Adherence to standardized protocols and guidelines for same-day
                          TJR</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PAC facilities</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Closer integration with
                          hospitals and physicians<br data-id="break_10" style="position: unset;">Potential increase in
                          patient volume if more care is shifted to PAC settings<br data-id="break_11"
                            style="position: unset;">Pressure to reduce costs and improve quality<br data-id="break_12"
                            style="position: unset;">Potential loss if care is shifted to other settings such as home
                          health care or outpatient rehabilitation therapy (RTM) settings</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Potential decrease in
                          patient volume for inpatient rehabilitation services<br data-id="break_13"
                            style="position: unset;">Opportunity for increased patient volume for outpatient
                          rehabilitation services<br data-id="break_14" style="position: unset;">Emphasis on
                          cost-efficiency and maintaining high-quality care standards for same-day TJR<br
                            data-id="break_15" style="position: unset;">Competition with alternative settings such as
                          home health care or outpatient RTM for TJR cases</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">TJR = total joint replacement, PAC = post-acute care, and RTM = remote
                        therapeutic monitoring.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">FFS
                Model</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Under the FFS model, providers are paid for each service
                  they provide rather than for the overall care they provide to a patient<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">4</span></a>. FFS remains an important payment model for Medicare, and it
                  continues to be used for many services and treatments<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">14</span></a>.
                  For instance, in the context of PAC, the complexity of services and the diversity of providers who are
                  involved make FFS a relevant payment model. PAC needs (rehabilitation needs, levels of required care,
                  and lengths of stay) can vary substantially among patients, and the FFS model allows for tailored
                  reimbursement based on the specific services and treatments that are provided.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Bundled
                Payment Models</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">APMs, such as bundled payments, aim to incentivize
                  cost-efficiency and quality of care for a specific episode of care, often encompassing post-discharge
                  services<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_15" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">26</span></a>. These payments, which are issued prospectively, cover the
                  entirety of the care episode, including related services that are provided within a 30- or 90-day
                  period following discharge. The specific services that are covered vary, depending on the bundle or
                  payment model that is employed; they encompass PAC that is delivered by SNFs, IRFs, long-term care
                  hospitals, HHC agencies, and other providers. Hospitals and PAC providers may establish an agreed-upon
                  allocation of payments, working collaboratively to manage patient care and control costs. Having fixed
                  payments and tying the payments to quality metrics and total cost of care incentivizes the appropriate
                  use of PAC services, such as avoiding unnecessary admissions to SNFs and IRFs and reducing hospital
                  readmissions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The Bundled Payments for Care Improvement (BPCI) program
                  was a CMS initiative for Medicare revenue for episodes of care<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_16" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">26</span></a>. The program involved voluntary participation by providers
                  (physician groups and/or hospitals) who agreed to accept a single payment for an episode of care,
                  covering all services and treatments related to a specific medical condition or procedure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The CJR model is a payment model that was introduced by
                  CMS to reduce the cost and improve the outcomes of joint replacement surgeries, by incentivizing the
                  coordination of care among hospitals, physicians, and PAC providers<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">3</span></a>. Clinicians are reimbursed through a blended payment that
                  includes a prospective payment for the inpatient stay and retrospective payments for PAC services that
                  are provided during the 90-day episode of care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Studies and evaluations of the bundled payment models have
                  shown mixed results<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_18" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">26</span></a>. Some providers have reported savings and improved quality
                  of care, while others have reported difficulty in coordinating care across multiple providers and in
                  achieving the financial goals of the program.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">ACOs and MA
                Plans</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The evolving landscape of health-care payment structures
                  increasingly prioritizes value-based care (VBC) over traditional FFS models. In this context,
                  understanding the incentives and strategies related to PAC becomes crucial, especially within
                  risk-based models such as ACOs and MA plans. ACOs, which were designed to enhance coordination and
                  quality of care, are increasingly integrating VBC incentives. This involves setting benchmarks for
                  performance and offering financial rewards for surpassing them, thus shifting from volume to value. MA
                  plans, which cater to 50% of Medicare beneficiaries in numerous states, are witnessing rapid
                  expansion. In MA plans, incentives often revolve around quality metrics, adherence to best practices,
                  and patient outcomes. PAC is pivotal, acting as a bridge between intensive medical interventions and
                  patient recovery. Both ACOs and MA plans are innovating in this space, introducing incentives to
                  enhance coordination, reduce readmissions, and ensure seamless care transitions. Commercial payers are
                  cognizant of the merits of accountable care models, as evidenced by their focus on expanding such
                  frameworks. This shift is motivated by the promise of better care quality, reduced costs, and enhanced
                  patient experience. An exploration into their strategies unveils a strong inclination toward VBC,
                  emphasizing patient-centric care and improved outcomes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The Center
                for Medicare and Medicaid Innovation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">The Center for Medicare and Medicaid Innovation (CMMI) has
                  been at the forefront of health-care transformation. While recent CMMI models might not explicitly
                  discuss PAC, this very absence is noteworthy. It underscores areas that might need more attention and
                  directs stakeholders to potential gaps in the current health-care paradigm.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The
                MIPS</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The MIPS evaluates participating providers based on 4
                  categories: quality, promoting interoperability, improvement activities, and cost. Based on their
                  performance in these categories, providers receive a score that determines their payment adjustment<a
                    href="" data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">27</span></a>. Each category contributes a certain percentage to the
                  overall MIPS score. The scoring itself is done by the CMS, using data submitted by clinicians and
                  groups, data captured through electronic medical records, and Medicare claims data. A high score can
                  result in a positive payment adjustment, while a low score can result in a negative payment
                  adjustment. There is ongoing debate about the effectiveness of the MIPS. Some critics argue that it is
                  too complex and burdensome and that its measures do not accurately reflect the quality of care. There
                  are also concerns that the MIPS may exacerbate disparities in health care, as clinicians who treat
                  sicker or lower-income patients may receive lower scores and payments. These concerns have led to
                  further reform, including the development of Merit-based Incentive Payment Value Pathways (MVPs) to
                  simplify the program and make it more meaningful.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">MVPs</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Introduced by the CMS, MVPs are a new payment framework
                  that replaced the MIPS program in 2023<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">29</span></a>. MVPs are designed to improve the quality of care while
                  reducing the administrative burden for clinicians. Under the MVP framework, clinicians report on a
                  smaller set of high-priority, outcome-based measures that are relevant to their specific specialty or
                  practice<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">29</span></a>. The measures are organized into clinically relevant,
                  interconnected pathways that are designed to improve patient outcomes and reduce disparities in care.
                  Although MIPS and MVPs are more directly focused on individual clinicians rather than episodes of
                  care, they can still have implications for PAC. The MIPS encourages clinicians to improve quality and
                  reduce costs, which can lead to changes in how they manage patient care, including PAC. The impacts of
                  PAC can vary depending on a variety of factors, including the particulars of the specific payment
                  model, the patient population, and the health-care market. Further research is needed to fully
                  understand these impacts and how they can be optimized to improve patient outcomes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">The MVP for TJR will likely include quality measures that
                  are related to the procedure, such as the percentage of patients who experience complications after
                  surgery and the percentage of patients who are able to return to normal activities after the
                  procedure. The MVP may also include patient-experience measures, such as the percentage of patients
                  who report being satisfied with their care. The specific details of the MVP for TJR are not yet known
                  as CMS is still finalizing the MVPs for the 2023 performance year<a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">29</span></a>. Given that MVPs aim to address specialty-specific outcomes
                  and reduce disparities in care, it is possible that the MVP for TJR may include measures related to
                  PAC. These measures could potentially encompass aspects such as patient outcomes and functional
                  improvement during the post-surgical recovery period, utilization of rehabilitation services, and care
                  coordination with PAC providers.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The Unified
                Post-Acute Care Prospective Payment System</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">The findings from past and existing models have
                  demonstrated some of the challenges in designing payment systems that incentivize high-quality,
                  cost-effective care. They highlight the need for a more unified and comprehensive approach to paying
                  for PAC, which is one of the goals of the proposed Unified Payment Model.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Under the IMPACT Act, the CMS is required to create a
                  Unified Post-Acute Care Prospective Payment System (Unified PAC PPS) to provide a more coordinated and
                  efficient payment structure for PAC services<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">30</span></a> (<a
                    href="" data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). To date, a Unified PAC PPS has been proposed to Congress, but
                  has yet to be finalized. The proposed model will replace the previous separate payment systems for
                  SNFs, IRFs, long-term care hospitals, and HHC agencies, and create a single, unified payment system
                  that applies to all PAC settings. It uses a single classification system to determine payment rates
                  for each PAC stay, which is based on the patient’s condition, functional status, care setting, and
                  other factors<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">30</span></a>. As
                  with the current disparate models, the payment rates would also be adjusted to account for regional
                  differences in labor costs and other factors.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.00422f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b95cad4a-f475-4396-aca3-0349ac24686e&type=jpeg&name=JBJS.23.00422f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;">Unified PAC Prospective Payment System. PAC =
                          post-acute care, IRF = inpatient rehabilitation facility, SNF = skilled nursing facility, HHA
                          = home health agency, LTCH = long-term care hospital, GI = gastrointestinal, LE = lower
                          extremity, TBI = traumatic brain injury, MMTA = medication management, teaching, and
                          assessment, and GU = genitourinary.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Unified PAC
                Clinical Groups and PAC Case-Mix Groups</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">In the proposed model, Unified PAC Clinical Groups (UPCGs)
                  and PAC Case-Mix Groups (P-CMGs) are 2 different patient classification systems that are used for
                  specifying reimbursement based on patient characteristics. P-CMGs are already used to classify
                  patients in SNFs under the Patient-Driven Payment Model (which was implemented in 2019) and in HHC
                  under the Patient-Driven Groupings Model (which was implemented in 2020)<a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">30</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_46" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">31</span></a>. P-CMGs account for each patient’s clinical characteristics
                  and functional status, and enable adjustment of the per diem rate paid to an SNF or the per-episode
                  rate paid to an HHC agency. In the proposed Unified PAC PPS, P-CMGs would be expanded to IRFs and
                  long-term care hospitals and to provide more accurate and appropriate payments for SNFs based on the
                  patient’s needs<a href="" data-id="citation_reference_47"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">31</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The
                Potential Impact of the Unified PAC PPS in Orthopaedic Surgery</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">The Unified PAC PPS could potentially affect orthopaedic
                  surgery in several ways. First, it aims to align incentives among providers, making it more likely
                  that patients receive the most appropriate care for their conditions. Orthopaedic surgeons could
                  benefit from this alignment, as it may lead to better patient outcomes and improved patient
                  satisfaction. Second, it could simplify the reimbursement process for orthopaedic surgeons. Currently
                  the reimbursement for PAC can be complicated, with different payment systems for different types of
                  PAC providers. This can make it difficult for orthopaedic surgeons to understand how much they and
                  their colleagues are being paid for their services, and how the payments relate to the quality and
                  cost-effectiveness of the care they provide. By unifying these payment systems, the Unified PAC PPS
                  could make the reimbursement process more transparent and easier to navigate. Third, it could help to
                  reduce administrative burdens, freeing time and resources that could be used to focus on patient care.
                  If and when PAC reimbursement rates are linked to patient outcomes, the Unified PAC PPS would
                  encourage the use of evidence-based practices. This could lead to better patient outcomes and improved
                  patient satisfaction.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Despite the potential advantages, the Unified PAC PPS also
                  may have negative impacts on the provision of orthopaedic surgery and PAC services. One potential
                  concern is that this model could lead to a reduction in the provision of PAC services, including
                  rehabilitation and skilled nursing care, which are often necessary for patients who undergo certain
                  types of orthopaedic surgical procedures. If PAC providers receive lower payments under this system,
                  it may disincentivize providers to accept patients who may require more intensive postoperative
                  services. Another potential negative impact is that it could limit the ability of orthopaedic surgeons
                  to choose the most appropriate PAC setting for their patients. If payments to PAC providers are
                  standardized under the unified model, orthopaedic surgeons may not have the same level of flexibility
                  to select the most appropriate care setting based on the individual needs and preferences of their
                  patients. It is also possible that this could lead to increased administrative burdens for clinicians
                  and PAC providers. If the payment system is more complex or requires more documentation, it could
                  increase the time and resources required for providers to participate in the system, leading to
                  reduced access to care for patients or higher costs for providers. Due to its prospective nature, it
                  may result in reimbursement that is lower than the actual costs of providing the required PAC
                  services. On one hand, this puts hospital systems at risk. On the other hand, it serves as an
                  incentive for well-coordinated and efficient care. It is possible that the potential negative impacts
                  could be mitigated by careful implementation and ongoing monitoring of the payment system.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Building a prototype of the Unified PAC PPS for joint
                  replacement involves creating a sample version of the system to assess its feasibility and
                  effectiveness. The goal of a joint replacement prototype is to provide a working model of the Unified
                  PAC PPS that can be used to test different aspects of the system, such as patient classification,
                  payment rates, and data-reporting requirements. The prototype of the Unified PAC PPS for joint
                  replacement would likely involve the following steps:</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">1. Patient classification: Develop a system for assigning
                  patients who have undergone joint replacement surgery to the appropriate clinical group based on their
                  functional status, clinical conditions, and comorbidities.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">2. Payment rates: Determine payment rates for each
                  clinical group that accurately reflect the cost of care for patients with similar needs and
                  conditions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">3. Data collection and reporting: Develop a system for
                  collecting and reporting data on patient conditions and functional status that are used to determine
                  payment rates under the Unified PAC PPS.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">4. Testing and evaluation: Test the prototype by using it
                  to process claims for PAC services that are provided to patients who undergo joint replacement and
                  evaluate the results.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">The potential impact of the proposed Unified PAC PPS on
                  reimbursement for hip and knee replacement surgery is not clear. The goal of the Unified Payment Model
                  is to create a more equitable and efficient payment system for PAC services, which would promote
                  better patient outcomes, reduce waste, and encourage the use of evidence-based practices<a href=""
                    data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">30</span></a>. This could result in a reduction in reimbursement for hip
                  and knee replacement surgery if the payment system is adjusted to reflect the actual cost of care for
                  these procedures. Conversely, the Unified Payment Model would base payment rates on a patient’s
                  clinical conditions and functional status rather than on the type of service provided. This could
                  potentially result in increased reimbursement for hip and knee replacement surgery if the payment
                  system recognizes the complexity and cost of care for these procedures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">In the end, the actual impact of the Unified Payment Model
                  on reimbursement for hip and knee replacement surgery will depend on the specifics of the
                  implementation. However, the overall goal of the model is to create a more equitable and efficient
                  payment system that promotes better patient outcomes, reduces waste, and encourages the use of
                  evidence-based practices.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Overall, the new and upcoming Medicare reimbursement
                  models for PAC are primarily driven by the goal of reducing health-care costs while maintaining or
                  improving quality of care. The models aim to incentivize providers to deliver care more efficiently
                  and to ensure that patients receive appropriate and cost-effective PAC services. While each payment
                  model that has been described in this article has its unique features and incentives, determining the
                  best model for the long term is challenging at this stage due to ongoing rule formation and
                  implementation. Continued monitoring and evaluation of the different payment models will be crucial in
                  assessing their impact on patient care and outcomes. As further data become available and the rules
                  are solidified, a clearer understanding may emerge regarding which payment model aligns most closely
                  with the desired goals of high-quality, patient-centered care for orthopaedic surgery patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_7" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/H927" target="_blank" data-id="link_8" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/H927</a>)</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Casalino LP</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Gans D</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Weber R</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Cea M</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Tuchovsky A</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Bishop TF</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Miranda Y</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Frankel BA</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Ziehler KB</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Wong MM</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Evenson TB</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">US Physician Practices Spend
                  More Than $15.4 Billion Annually To Report Quality Measures</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Health Aff (Millwood).</span> 2016
                Mar;35(3):401-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=US%20Physician%20Practices%20Spend%20More%20Than%20%2415.4%20Billion%20Annually%20To%20Report%20Quality%20Measures&as_occt=title&as_sauthors=%20%22LP%20Casalino%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Panzer RJ</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Gitomer RS</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Greene WH</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Webster PR</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Landry KR</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Riccobono CA</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Increasing demands for quality
                  measurement</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2013 Nov 13;310(18):1971-80.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Increasing%20demands%20for%20quality%20measurement&as_occt=title&as_sauthors=%20%22RJ%20Panzer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Siddiqi A</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> White PB</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Mistry JB</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Gwam CU</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Nace J</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Mont MA</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Delanois RE</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Effect of Bundled Payments and
                  Health Care Reform as Alternative Payment Models in Total Joint Arthroplasty: A Clinical
                  Review</span>. <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2017 Aug;32(8):2590-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20Bundled%20Payments%20and%20Health%20Care%20Reform%20as%20Alternative%20Payment%20Models%20in%20Total%20Joint%20Arthroplasty%3A%20A%20Clinical%20Review&as_occt=title&as_sauthors=%20%22A%20Siddiqi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Springer BD</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> McInerney J</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Medicare bundles for
                  arthroplasty: a journey back to fee for service?</span><span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2021 Jun;103-B(6)(Supple
                A):119-25.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Medicare%20bundles%20for%20arthroplasty%3A%20a%20journey%20back%20to%20fee%20for%20service%3F&as_occt=title&as_sauthors=%20%22BD%20Springer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Murphy WS</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Siddiqi A</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Cheng T</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Lin B</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Terry D</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Talmo CT</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Murphy SB</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">2018 John Charnley Award:
                  Analysis of US Hip Replacement Bundled Payments: Physician-initiated Episodes Outperform
                  Hospital-initiated Episodes</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2019 Feb;477(2):271-80.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=2018%20John%20Charnley%20Award%3A%20Analysis%20of%20US%20Hip%20Replacement%20Bundled%20Payments%3A%20Physician-initiated%20Episodes%20Outperform%20Hospital-initiated%20Episodes&as_occt=title&as_sauthors=%20%22WS%20Murphy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;">Centers for
                Medicare & Medicaid Services. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">National Health Spending grew slightly in 2021.</span> Accessed 2023 Feb 19.
                <a href="https://www.cms.gov/newsroom/press-releases/national-health-spending-grew-slightly-2021"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.cms.gov/newsroom/press-releases/national-health-spending-grew-slightly-2021</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Zimmerli W</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Bone and Joint Infections: From
                  Microbiology to Diagnostics and Treatment</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">John Wiley & Sons</span>; 2015.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20and%20Joint%20Infections%3A%20From%20Microbiology%20to%20Diagnostics%20and%20Treatment&as_occt=title&as_sauthors=%20%22W%20Zimmerli%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Cleveland OME</span>; OME
                Cleveland Clinic Orthopaedics. <span data-id="strong_7" class="annotation strong"
                  style="position: unset;">Value in Research: Achieving Validated Outcome Measurements While Mitigating
                  Follow-up Cost</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2020 Mar 4;102(5):419-27.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=2275134" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Sodhi N</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Dalton SE</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Garbarino LJ</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Gold PA</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Piuzzi NS</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Newman JM</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Khlopas A</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Sultan AA</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Chughtai M</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Mont MA</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Not all primary total hip
                  arthroplasties are equal-so is there a difference in reimbursement?</span><span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Ann Transl Med.</span> 2019
                Feb;7(4):74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Not%20all%20primary%20total%20hip%20arthroplasties%20are%20equal-so%20is%20there%20a%20difference%20in%20reimbursement%3F&as_occt=title&as_sauthors=%20%22N%20Sodhi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Andersson G</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">The Burden of Musculoskeletal
                  Diseases in the United States: Prevalence, Societal and Economic Cost</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">American Academy of
                  Orthopaedic Surgeons</span>; 2008.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Burden%20of%20Musculoskeletal%20Diseases%20in%20the%20United%20States%3A%20Prevalence%2C%20Societal%20and%20Economic%20Cost&as_occt=title&as_sauthors=%20%22G%20Andersson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Rullán PJ</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Deren ME</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Zhou G</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Emara AK</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Klika AK</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Schiltz NK</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Barsoum WK</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Koroukian S</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Piuzzi NS</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">The Arthroplasty Surgeon Growth
                  Indicator: A Tool for Monitoring Supply and Demand Trends in the Orthopaedic Surgeon Workforce from
                  2020 to 2050</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2023 Jul 5;105(13):1038-45.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=39948799-6ddd-462e-b0be-400d8a13757e"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Sinclair ST</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Klika AK</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Jin Y</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Higuera CA</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Piuzzi NS</span>; on behalf of
                the Cleveland Clinic OME Arthroplasty Group. <span data-id="strong_11" class="annotation strong"
                  style="position: unset;">The Impact of Surgeon Variability on Patient-Reported Outcome Measures,
                  Length of Stay, Discharge Disposition, and 90-Day Readmission in TKA</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2022 Nov 16;104(22):2016-25.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=3427253" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Bozic KJ</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Ward L</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Vail TP</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Maze M</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Bundled payments in total joint
                  arthroplasty: targeting opportunities for quality improvement and cost reduction</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2014 Jan;472(1):188-93.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bundled%20payments%20in%20total%20joint%20arthroplasty%3A%20targeting%20opportunities%20for%20quality%20improvement%20and%20cost%20reduction&as_occt=title&as_sauthors=%20%22KJ%20Bozic%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Coberly S</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Medicare’s Post-Acute Care
                  Payment: An Updated Review of the Issues and Policy Proposals</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">Washington (DC): National Health Policy
                  Forum</span>; 2015 Oct 5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Medicare%E2%80%99s%20Post-Acute%20Care%20Payment%3A%20An%20Updated%20Review%20of%20the%20Issues%20and%20Policy%20Proposals&as_occt=title&as_sauthors=%20%22S%20Coberly%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Liao JM</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Emanuel EJ</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Venkataramani AS</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Huang Q</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Dinh CT</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Shan EZ</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Wang E</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Zhu J</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Cousins DS</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Navathe AS</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Association of Bundled Payments
                  for Joint Replacement Surgery and Patient Outcomes With Simultaneous Hospital Participation in
                  Accountable Care Organizations</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">JAMA Netw Open.</span> 2019 Sep 4;2(9):e1912270.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20of%20Bundled%20Payments%20for%20Joint%20Replacement%20Surgery%20and%20Patient%20Outcomes%20With%20Simultaneous%20Hospital%20Participation%20in%20Accountable%20Care%20Organizations&as_occt=title&as_sauthors=%20%22JM%20Liao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Ng MK</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Lam A</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Diamond K</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Piuzzi NS</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Roche M</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Erez O</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Wong CHJ</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Mont MA</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">What are the Causes, Costs and
                  Risk-Factors for Emergency Department Visits Following Primary Total Hip Arthroplasty? An Analysis of
                  1,018,772 Patients</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2023 Jan;38(1):117-23.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20are%20the%20Causes%2C%20Costs%20and%20Risk-Factors%20for%20Emergency%20Department%20Visits%20Following%20Primary%20Total%20Hip%20Arthroplasty%3F%20An%20Analysis%20of%201%2C018%2C772%20Patients&as_occt=title&as_sauthors=%20%22MK%20Ng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Rullán PJ</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Xu JR</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Emara AK</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Molloy RM</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Krebs VE</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Mont MA</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Piuzzi NS</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Major National Shifts to
                  Outpatient Total Knee Arthroplasties in the United States: A 10-Year Trends Analysis of Procedure
                  Volumes, Complications, and Healthcare Utilizations (2010 to 2020)</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2023
                Jul;38(7):1209-1216.e5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Major%20National%20Shifts%20to%20Outpatient%20Total%20Knee%20Arthroplasties%20in%20the%20United%20States%3A%20A%2010-Year%20Trends%20Analysis%20of%20Procedure%20Volumes%2C%20Complications%2C%20and%20Healthcare%20Utilizations%20(2010%20to%202020)&as_occt=title&as_sauthors=%20%22PJ%20Rull%C3%A1n%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Buhagiar MA</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Naylor JM</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Harris IA</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Xuan W</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Adie S</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Lewin A</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Assessment of Outcomes of
                  Inpatient or Clinic-Based vs Home-Based Rehabilitation After Total Knee Arthroplasty: A Systematic
                  Review and Meta-analysis</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">JAMA Netw Open.</span> 2019 Apr 5;2(4):e192810.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Assessment%20of%20Outcomes%20of%20Inpatient%20or%20Clinic-Based%20vs%20Home-Based%20Rehabilitation%20After%20Total%20Knee%20Arthroplasty%3A%20A%20Systematic%20Review%20and%20Meta-analysis&as_occt=title&as_sauthors=%20%22MA%20Buhagiar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Austin MS</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Urbani BT</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Fleischman AN</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Fernando ND</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Purtill JJ</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Hozack WJ</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Parvizi J</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Rothman RH</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Formal Physical Therapy After
                  Total Hip Arthroplasty Is Not Required: A Randomized Controlled Trial</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2017 Apr 19;99(8):648-55.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1275685" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Chen EK</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Edelen MO</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> McMullen T</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Ahluwalia SC</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Dalton SE</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Paddock S</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Rodriguez A</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Shier V</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Mandl S</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Mota T</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Saliba D</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Developing standardized patient
                  assessment data elements for Medicare post-acute care assessments</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">J Am Geriatr Soc.</span> 2022
                Apr;70(4):981-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Developing%20standardized%20patient%20assessment%20data%20elements%20for%20Medicare%20post-acute%20care%20assessments&as_occt=title&as_sauthors=%20%22EK%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Hadad MJ</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Orr MN</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Emara AK</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Klika AK</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Johnson JK</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Piuzzi NS</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">PLAN and AM-PAC “6-Clicks”
                  Scores to Predict Discharge Disposition After Primary Total Hip and Knee Arthroplasty</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2022 Feb 16;104(4):326-35.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=3143223" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Jette DU</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Hunter SJ</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Burkett L</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Langham B</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Logerstedt DS</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Piuzzi NS</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Poirier NM</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Radach LJL</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Ritter JE</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Scalzitti DA</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Stevens-Lapsley JE</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Tompkins J</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Zeni J Jr</span>; American
                Physical Therapy Association. <span data-id="strong_21" class="annotation strong"
                  style="position: unset;">Physical Therapist Management of Total Knee Arthroplasty</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Phys Ther.</span> 2020 Aug
                31;100(9):1603-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Physical%20Therapist%20Management%20of%20Total%20Knee%20Arthroplasty&as_occt=title&as_sauthors=%20%22DU%20Jette%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Sayeed Z</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> El-Othmani M</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Shaffer WO</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Saleh KJ</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">The Medicare Access and CHIP
                  Reauthorization Act (MACRA) of 2015: What’s New?</span><span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2017
                Jun;25(6):e121-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Medicare%20Access%20and%20CHIP%20Reauthorization%20Act%20(MACRA)%20of%202015%3A%20What%E2%80%99s%20New%3F&as_occt=title&as_sauthors=%20%22Z%20Sayeed%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Self RH</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Coffin J</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Advanced Alternative Payment
                  Models Part II: Understanding the Next Generation Accountable Care Organization Model</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">J Med Pract Manage.</span>
                2017 Mar;32(5):340-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Advanced%20Alternative%20Payment%20Models%20Part%20II%3A%20Understanding%20the%20Next%20Generation%20Accountable%20Care%20Organization%20Model&as_occt=title&as_sauthors=%20%22RH%20Self%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Wolfe JD</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Epstein AM</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Zheng J</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Orav EJ</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Joynt Maddox KE</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Predictors of Success in the
                  Bundled Payments for Care Improvement Program</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">J Gen Intern Med.</span> 2022
                Feb;37(3):513-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predictors%20of%20Success%20in%20the%20Bundled%20Payments%20for%20Care%20Improvement%20Program&as_occt=title&as_sauthors=%20%22JD%20Wolfe%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Gold PA</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Magnuson JA</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Venkat N</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Krueger CA</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Courtney PM</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Life After BPCI: High Quality
                  Total Knee and Hip Arthroplasty Care Can Still Exist Outside of a Bundled Payment Program</span>.
                <span data-id="emphasis_26" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span>
                2022 Jul;37(7):1241-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Life%20After%20BPCI%3A%20High%20Quality%20Total%20Knee%20and%20Hip%20Arthroplasty%20Care%20Can%20Still%20Exist%20Outside%20of%20a%20Bundled%20Payment%20Program&as_occt=title&as_sauthors=%20%22PA%20Gold%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Dykes PC</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Curtin-Bowen M</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Li T</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Pullman A</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Businger A</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Lipsitz S</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Syrowatka A</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Sainlaire M</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Thai T</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Bates DW</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Development of four electronic
                  clinical quality measures (eCQMs) for use in the Merit-based Incentive Payment System (MIPS) following
                  elective primary total hip and knee arthroplasty</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">AMIA Annu Symp Proc.</span> 2022 Feb
                21;2021:408-17.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20of%20four%20electronic%20clinical%20quality%20measures%20(eCQMs)%20for%20use%20in%20the%20Merit-based%20Incentive%20Payment%20System%20(MIPS)%20following%20elective%20primary%20total%20hip%20and%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22PC%20Dykes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Crum E</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Clinicians and payers expect to
                  wait and see before embracing CMS MIPS value pathways</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">Am J Manag Care.</span> 2021 Aug;27(6 Spec
                No.):SP245-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinicians%20and%20payers%20expect%20to%20wait%20and%20see%20before%20embracing%20CMS%20MIPS%20value%20pathways&as_occt=title&as_sauthors=%20%22E%20Crum%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;">Quality
                Payment Program. <span data-id="emphasis_29" class="annotation emphasis" style="position: unset;">MIPS
                  value pathways.</span> Accessed 2023 Feb 19. <a href="https://qpp.cms.gov/mips/mips-value-pathways"
                  target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://qpp.cms.gov/mips/mips-value-pathways</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;">Centers
                for Medicare & Medicaid Services. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">Home - Centers for Medicare & Medicaid Services.</span> Accessed 2023 Feb
                19. <a href="https://www.cms.gov/files/document/unified-pac-report-congress.pdf" target="_blank"
                  data-id="link_3" class="link"
                  style="position: unset;">https://www.cms.gov/files/document/unified-pac-report-congress.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;">Centers
                for Medicare & Medicaid Services. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Patient driven payment model.</span> Accessed 2023 Feb 19. <a
                  href="https://www.cms.gov/Medicare/Medicare-Fee-for-Service-Payment/SNFPPS/PDPM" target="_blank"
                  data-id="link_4" class="link"
                  style="position: unset;">https://www.cms.gov/Medicare/Medicare-Fee-for-Service-Payment/SNFPPS/PDPM</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;b95cad4a-f475-4396-aca3-0349ac24686e&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=b95cad4a-f475-4396-aca3-0349ac24686e&type=supplement&name=10447"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Db95cad4a-f475-4396-aca3-0349ac24686e%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">August 21, 2024;
                      106 (16): 1521</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00422</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">April 23, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_32"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopedic Surgery, Cleveland Clinic, Cleveland, Ohio</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_4" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;b95cad4a-f475-4396-aca3-0349ac24686e&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=b95cad4a-f475-4396-aca3-0349ac24686e&type=pdf&name=JBJS.23.00422.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=b95cad4a-f475-4396-aca3-0349ac24686e&type=pdf&name=JBJS.23.00422.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_4" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_5" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_28"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_29" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/H923" target="_blank" data-id="link_5"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/H923</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;b95cad4a-f475-4396-aca3-0349ac24686e&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=b95cad4a-f475-4396-aca3-0349ac24686e&type=zip&name=JBJS.23.00422.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ahmed Siddiqi, DO, MBA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9434-671X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9434-671X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ignacio Pasqualini, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9551-827X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9551-827X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Joshua Tidd, BS<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Pedro J. Rullán, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alison K. Klika, MS<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Trevor G. Murray, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Joshua K. Johnson, DPT, PhD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span><span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7077-232X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7077-232X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nicolas S. Piuzzi, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:piuzzin@ccf.org" class="" style="position: unset;">piuzzin@ccf.org</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3007-7538" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3007-7538</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Orthopedic Institute Brielle Orthopedics (OrthoNJ) Wall, Manasquan, New
                Jersey</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Hackensack Meridian School of Medicine,
                Nutley, New Jersey</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Cleveland Clinic, Cleveland, Ohio</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Physical Medicine and Rehabilitation, Cleveland Clinic
                Foundation, Cleveland, Ohio</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Center for Value-Based Care Research, Cleveland Clinic Foundation, Cleveland,
                Ohio</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Department of Biomedical Engineering, Cleveland Clinic Foundation, Cleveland,
                Ohio</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 28401.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
