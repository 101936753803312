/* -----------------Globals--------------- */
import { useState } from "react";

/* -----------------UI--------------- */
import { ConfirmDialog } from "components/ConfirmDialog";

import { useExamContext } from "@features/exam";
/* -----------------Helpers & Hooks--------------- */
import { isAuthenticated } from "@memorang/helpers";

/* -----------------Types--------------- */
import { events } from "@constants/tracking";

import { fetchPracticeTestExams } from "@features/dashboard";
/* -----------------Child components--------------- */
import { createSession, useSessionStore } from "@features/session";
import { trackCustomEvent } from "analytics";
import { useRouter } from "expo-router";
import CreateSessionLoader from "./CreateSessionLoader";

const cta = "Start Diagnostic";

type Props = {
	visible: boolean;
	onClose: (navigate?: boolean) => void;
};
const FreeQuizSessionDialog = ({ visible, onClose }: Props) => {
	const [creatingSession, setCreatingSession] = useState(false);
	const {
		currentExamContext: { examName, practiceTestProductId },
	} = useExamContext();

	const router = useRouter();

	const title = "Diagnostic Assessment";
	const subtitle = `Subject: ${examName} • 20 Items`;
	const body = `Not sure where to start studying? Take our free **20-item Diagnostic Assessment**. It's a great way to familiarize yourself with the format and content of the exam. At the end of your diagnostic assessment we will provide you with a detailed breakdown of your strengths/weaknesses, rationales for each item, and tips for what you can do between now and your exam date to better prepare.  
    Your total time allotted is **30 mins** which comes out to about **72 seconds** per question.
    `;

	const updateSession = useSessionStore((state) => state.updateSession);

	const handleCreateSession = async (examId: string) => {
		const result = await isAuthenticated();
		const viewerId = result
			? // biome-ignore lint/suspicious/noExplicitAny: <explanation>
				(result as any).attributes["custom:logicalId"]
			: "";
		trackCustomEvent({
			eventName: events.freeQuizStarted,
			type: "summative",
		});
		const sessionResult = await createSession({
			action: "SUMMATIVE_SESSION",
			createSessionType: "PRACTICE",
			learningOrder: "SHUFFLED",
			examId,
			bundleId: practiceTestProductId,
			userId: viewerId!,
		});
		updateSession({
			sessionResponse: sessionResult,
			isSummativeTest: true,
		});
		setCreatingSession(false);
		onClose();

		router.replace({
			pathname: "/(protected)/session/[id]",
			params: {
				id: sessionResult.numericId,
				index: "1",
			},
		});
	};

	const handleStartFreeQuiz = () => {
		if (practiceTestProductId) {
			setCreatingSession(true);
			fetchPracticeTestExams(practiceTestProductId).then((res) => {
				if (res.getMixedItemDistribution?.exams) {
					const practiceTestQuizzes = res.getMixedItemDistribution.exams.filter(
						(exam) => !exam.title.includes("Test"),
					);
					handleCreateSession(practiceTestQuizzes[0].id);
				}
			});
		}
	};

	if (creatingSession) {
		return <CreateSessionLoader />;
	}
	return (
		<ConfirmDialog
			visible={visible}
			title={title}
			body={body}
			subtitle={subtitle}
			icon="format-list-checks"
			dismissCallback={() => {
				onClose(true);
			}}
			dismissCta="Maybe Later"
			confirmCallback={handleStartFreeQuiz}
			confirmCta={cta}
		/>
	);
};

export default FreeQuizSessionDialog;
