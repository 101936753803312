/* ----------------- Hooks --------------- */
import { useAppTheme } from "@hooks/useAppTheme";

/* ----------------- Memorang UI --------------- */
import { useDevice } from "@memorang/ui";

/* ----------------- React Native Paper --------------- */
import { Card } from "react-native-paper";

//TODO:Temp card until we fix the theme colors
const OutlinedCard = ({ children }: { children?: React.ReactNode }) => {
	const theme = useAppTheme();
	const { isMobile } = useDevice();
	return (
		<Card
			mode="outlined"
			style={{
				borderColor: theme.colors.surfaceVariant,
				borderRadius: isMobile ? 0 : 8,
				overflow: "hidden",
			}}
		>
			{children}
		</Card>
	);
};

export default OutlinedCard;
