import type { Article } from "../../../../../types";

export const Article655: Article = {
	id: 655,
	rsuiteId: "ed1051c9-dd29-4ccf-a376-8385514a3062",
	type: "the orthopaedic forum",
	title:
		"Sex Diversity and Equity Among Fellows of the American Orthopaedic Association",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ed1051c9-dd29-4ccf-a376-8385514a3062&type=jpeg&name=JBJS.24.00071f1",
	showOnlyAbstract: true,
	subSpecialties: ["pediatrics"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Sex Diversity and Equity Among Fellows of the American
                Orthopaedic Association</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jason
                      Silvestre, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Maya Moore,
                      BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Background:</span> We hypothesized that women are underrepresented
                        among fellows of the American Orthopaedic Association (AOA) relative to their prevalence among
                        faculty at departments of orthopaedic surgery.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Methods:</span> Clinical faculty at departments of orthopaedic
                        surgery that are affiliated with residency training programs were analyzed for AOA membership.
                        Participation-to-prevalence ratios (PPRs) were calculated for men and women with AOA membership
                        relative to their prevalence among orthopaedic surgeon faculty. A PPR of &lt;0.8 or &gt;1.2
                        indicated under- or overrepresentation, respectively. PPR values between 0.8 and 1.2 indicated
                        equivalent representation. Sex disparities in AOA membership were explored by academic rank and
                        orthopaedic subspecialty.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_35" class="annotation strong"
                          style="position: unset;">Results:</span> There were 4,310 orthopaedic surgery faculty, and 998
                        (23.2%) had AOA membership. Overall, women formed the minority of members in the AOA study
                        cohort (11.4%), but they had equivalent representation relative to their prevalence among
                        faculty (10.0%, PPR = 1.14, p = 0.210). In the AOA study cohort, women were the minority at each
                        academic rank, including assistant professor (20.2% versus 79.8%, p &lt; 0.001), associate
                        professor (16.8% versus 83.2%, p &lt; 0.001), and full professor (7.0% versus 93.0%, p &lt;
                        0.001). However, women had greater representation in the AOA study cohort at each academic rank
                        than would be expected based on their prevalence among faculty, including the assistant (PPR =
                        1.45), associate (PPR = 1.56), and full (PPR = 1.27) professor levels. Similarly, in the AOA
                        study cohort, women were the minority in each orthopaedic subspecialty, but were only
                        underrepresented in pediatric orthopaedics (PPR = 0.74). Women had greater representation in the
                        AOA study cohort than would be expected by their prevalence among faculty in hand surgery (PPR =
                        1.51) and orthopaedic sports medicine (PPR = 1.33). In contrast, men had equivalent
                        representation in each orthopaedic subspecialty.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_36" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Women form a minority group in the AOA, but they
                        exhibit greater representation in the AOA than would be expected based on their prevalence among
                        faculty at each academic rank, and they have greater representation than men, who exhibit
                        equivalent representation across all academic ranks. Collectively, these results disproved our
                        hypothesis, and suggest that women are participating in the AOA at rates that are greater than
                        their prevalence among faculty.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_37" class="annotation strong"
                          style="position: unset;">Clinical Relevance:</span> Equitable participation of women in
                        orthopaedic surgery is necessary to promote diversity and inclusion in the specialty. The AOA
                        may be an important catalyst for future positive diversity and inclusion efforts within
                        orthopaedic surgery.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Founded in 1887, the American Orthopaedic Association
                  (AOA) was the first professional organization for orthopaedic surgery in the United States<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. Today, membership in the AOA is a widely recognized
                  achievement in academic orthopaedic surgery. Invitation for membership is merit-based, peer-reviewed,
                  and only granted after holistic review of a candidate’s academic and leadership accomplishments<a
                    href="" data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>. Yet, despite its importance as an organization and function
                  within academic orthopaedic surgery, to our knowledge, there have been no studies on the membership
                  demographics of the AOA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Progress toward greater diversity and inclusion in
                  academic orthopaedic surgery has been limited<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>.
                  Women remain underrepresented at all levels in orthopaedic surgery, including residency and fellowship
                  training, academic faculty, and societal leadership<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">7</span></a>. Women face several unique challenges to academic career
                  progression, including limited mentorship opportunities, subconscious biases, and increased family
                  responsibilities<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">10</span></a>. Consideration for AOA membership requires nomination by
                  current AOA members, which may disadvantage candidates who have limited access to established peer
                  networks, academic pedigrees, and employment organizations. Furthermore, evaluation processes may be
                  subjective and susceptible to subconscious bias, which may negatively affect women in academia<a
                    href="" data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">10</span></a> and,
                  thus, possibly membership in the AOA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">This study assessed the sex representation of the AOA
                  membership. Based on previous research documenting sex disparities in orthopaedic surgery<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">7</span></a>, we
                  hypothesized that fewer women would obtain AOA membership than would be expected based on their
                  prevalence among academic faculty. We further hypothesized that sex disparities would exist in the AOA
                  at each academic rank and would be greatest in certain orthopaedic subspecialties (e.g., spine surgery
                  and adult reconstruction).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">This was a retrospective, cross-sectional analysis of
                  faculty at departments of orthopaedic surgery that are affiliated with a residency training program.
                  This study qualified for review exemption based on the policies of the institutional review board. The
                  American Medical Association’s Fellowship and Residency Electronic Interactive Database was queried
                  for accredited orthopaedic surgery residency training programs during the 2022 to 2023 academic year.
                  AOA membership of faculty at accredited orthopaedic surgery residency programs was determined via
                  querying of the AOA membership registry. Only clinical faculty with AOA membership and affiliation
                  with an accredited orthopaedic surgery residency training program were included in the cohort of
                  interest<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">6</span></a>.
                  Faculty rosters were obtained via analysis of residency program websites for clinical faculty with
                  orthopaedic surgery residency training. Thus, we excluded AOA members who did not have an affiliation
                  with an accredited orthopaedic surgery residency training program in order to adequately account for
                  their representation among available faculty pools.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Participation-to-prevalence ratios (PPRs) were calculated
                  for men and women to assess sex representation among AOA members across departments of orthopaedic
                  surgery. The PPR has been previously applied to provide insight into minority population
                  representation, primarily in the cardiovascular literature<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">11</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">12</span></a>. For women, the PPR numerator was defined as the percentage
                  of AOA members that were women. The PPR denominator was defined as the percentage of faculty at
                  departments of orthopaedic surgery that were women<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">6</span></a>.
                  Demographic data for AOA members were determined by analysis of first names with use of the
                  genderize.io algorithm<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">13</span></a> and
                  visual inspection of faculty roster photographs. Additionally, academic rank and orthopaedic
                  subspecialty data for each faculty member were obtained via review of residency program websites.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">A PPR of approximately 1.0 (range, 0.8 to 1.2) was defined
                  as equivalent sex representation, which reflects expected participation from the available faculty
                  pool. Thresholds for PPR analysis were derived from previous literature on the demographics for
                  clinical trial enrollment<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">12</span></a>. Importantly, the PPR is not a validated instrument to
                  assess for equitability in minority representation among faculty. It is simply a mathematical tool
                  used to understand a population’s actual representation relative to its predicted representation. A
                  PPR of &lt;0.8 was defined as underrepresentation relative to expected participation from available
                  faculty. A PPR of &gt;1.2 was defined as overrepresentation relative to expected participation from
                  available faculty. This PPR analysis was conducted for both men and women at different academic ranks,
                  including assistant professor, associate professor, and full professor. The PPR analysis was also
                  conducted across orthopaedic subspecialties, including spine surgery, musculoskeletal oncology,
                  orthopaedic trauma, pediatric orthopaedics, foot and ankle surgery, adult reconstruction, hand
                  surgery, and orthopaedic sports medicine<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">6</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Primary outcomes of interest were the percentage of women
                  with AOA membership in the study cohort and the PPR for women with AOA membership. These outcomes were
                  calculated across academic ranks and orthopaedic subspecialties. Comparisons were made with chi-square
                  tests. Alpha was set at 0.05, and all tests were 2-tailed. GraphPad (Prism) was used for all
                  statistical tests, which were performed in consultation with a biostatistician.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">There were 4,310 academic orthopaedic surgeons in the
                  faculty cohort; 998 (23.2%) were AOA members. Among the faculty in the AOA study cohort, there were
                  114 women (11.4%), which demonstrated equivalent representation relative to their prevalence in the
                  overall faculty cohort (432, 10.0%, PPR = 1.14, p = 0.210). There were 884 men (88.6%) among the
                  faculty in the AOA study cohort, which similarly demonstrated equivalent representation relative to
                  their prevalence in the overall faculty cohort (3,878, 90.0%, PPR = 0.98) (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Calculation of PPRs for Male and Female Faculty
                          Members in the AOA Cohort</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 662.396px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.599271);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Characteristic</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Female</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Male</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Participation in AOA Cohort (%)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Prevalence
                          in Faculty Cohort (%)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">PPR</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Participation in AOA Cohort (%)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Prevalence
                          in Faculty Cohort (%)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">PPR</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">Rank</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Assistant
                          professor</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18/89
                          (20.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">220/1,587
                          (13.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.45</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71/89
                          (79.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,367/1,587 (86.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.93</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Associate
                          professor</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45/268
                          (16.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">91/839
                          (10.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.56</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">223/268
                          (83.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">748/839
                          (89.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.93</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Full professor</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43/612
                          (7.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50/902
                          (5.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.27</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">569/612
                          (93.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">852/902
                          (94.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.98</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Unranked</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8/29
                          (27.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74/982
                          (7.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.83</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21/29
                          (72.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">911/982
                          (92.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.78</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="8" style="position: unset;"><span class="text"
                          style="position: unset;">Subspecialty</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Hand surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23/123
                          (18.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">77/623
                          (12.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.51</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100/123
                          (81.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">546/623
                          (87.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.93</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Orthopaedic sports
                          medicine</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19/157
                          (12.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69/761
                          (9.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.33</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">138/157
                          (87.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">692/761
                          (90.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.97</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Adult
                          reconstruction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6/152
                          (3.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20/590
                          (3.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.15</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">146/152
                          (96.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">570/590
                          (96.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.99</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Spine surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6/124
                          (4.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22/514
                          (4.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">118/124
                          (95.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">492/514
                          (95.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.99</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Musculoskeletal
                          oncology</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16/87
                          (18.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31/181
                          (17.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.08</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71/87
                          (81.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">150/181
                          (82.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.98</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Foot and ankle
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7/72
                          (9.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27/279
                          (9.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.00</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65/72
                          (90.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">252/279
                          (90.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.00</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Orthopaedic trauma</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13/140
                          (9.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40/422
                          (9.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.98</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">127/140
                          (90.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">382/422
                          (90.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.00</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pediatric
                          orthopaedics</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24/143
                          (16.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">119/522
                          (22.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.74</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">119/143
                          (83.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">403/522
                          (77.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.08</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">General</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0/0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27/418
                          (6.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0/0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">391/418
                          (93.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">114/998
                          (11.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">432/4,310
                          (10.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.14</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">884/998
                          (88.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,878/4,310 (90.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.98</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Among those in the AOA study cohort of 998 faculty, there
                  were 89 assistant professors (8.9%), 268 associate professors (26.9%), 612 full professors (61.3%),
                  and 29 unranked faculty (2.9%). There were more men than women in the AOA study cohort at each
                  academic rank, including assistant professor (79.8% versus 20.2%, p &lt; 0.001), associate professor
                  (83.2% versus 16.8%, p &lt; 0.001), and full professor (93.0% versus 7.0%, p &lt; 0.001). However,
                  women had greater representation in the AOA study cohort at each academic rank than would be expected
                  based on their prevalence among faculty members (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>),
                  including at the assistant professor (PPR = 1.45), associate professor (PPR = 1.56), and full
                  professor (PPR = 1.27) levels. Men had equivalent representation across each academic rank.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00071f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ed1051c9-dd29-4ccf-a376-8385514a3062&type=jpeg&name=JBJS.24.00071f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;">Participation-to-prevalence (PPR) ratios for AOA
                          membership by academic rank and sex.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">In the AOA study cohort, more men than women existed in
                  each orthopaedic subspecialty, including hand surgery (81.3% versus 18.7%, p &lt; 0.001), orthopaedic
                  sports medicine (87.9% versus 12.1%, p &lt; 0.001), adult reconstruction (96.1% versus 3.9%, p &lt;
                  0.001), spine surgery (95.2% versus 4.8%, p &lt; 0.001), musculoskeletal oncology (81.6% versus 18.4%,
                  p &lt; 0.001), foot and ankle surgery (90.3% versus 9.7%, p &lt; 0.001), orthopaedic trauma (90.7%
                  versus 9.3%, p &lt; 0.001), and pediatric orthopaedics (83.2% versus 16.8%, p &lt; 0.001). However,
                  when accounting for their prevalence among academic faculty members, women were underrepresented only
                  in pediatric orthopaedics (PPR = 0.74, <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>). Women
                  had greater representation in the AOA study cohort than would be expected by their prevalence among
                  faculty in hand surgery (PPR = 1.51) and orthopaedic sports medicine (PPR = 1.33). Women had
                  equivalent representation in the AOA study cohort for adult reconstruction (PPR = 1.15), spine surgery
                  (PPR = 1.12), musculoskeletal oncology (PPR = 1.08), foot and ankle surgery (PPR = 1.00), and
                  orthopaedic trauma (PPR = 0.98). Men had equivalent representation in the AOA study cohort for each
                  orthopaedic subspecialty (range, PPR = 0.93 to 1.08).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00071f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ed1051c9-dd29-4ccf-a376-8385514a3062&type=jpeg&name=JBJS.24.00071f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;">Participation-to-prevalence (PPR) ratios for AOA
                          membership by subspecialty and sex.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Election into the AOA is widely recognized as an academic
                  accomplishment in orthopaedic surgery. Fellows are elected after rigorous peer review of academic
                  accomplishments. In our study, approximately one-fourth of academic faculty at departments of
                  orthopaedic surgery had achieved AOA membership. Women formed approximately one-tenth of the AOA study
                  cohort, but they had greater representation in the AOA at each academic rank than would be expected
                  based on their prevalence among faculty in orthopaedic surgery. Furthermore, women had the highest
                  representation in hand surgery and orthopaedic sports medicine, but they were underrepresented in
                  pediatric orthopaedics. Women had equivalent representation in the AOA study cohort for adult
                  reconstruction, spine surgery, musculoskeletal oncology, foot and ankle surgery, and orthopaedic
                  trauma. Collectively, these results disproved our hypothesis that fewer women would obtain AOA
                  membership than would be expected based on their faculty prevalence. Importantly, representation in
                  this study was defined by the PPR, which controls for anticipated participation in the AOA based on
                  the prevalence of available faculty. In this sense, the overrepresentation of women in the AOA
                  relative to their faculty presence may be desirable for a specialty that is actively trying to
                  diversify its emerging workforce. Overall, men constituted the vast majority of AOA members, which
                  highlights the overall lack of women in academic orthopaedic surgery. Ultimately, insights generated
                  from this study may lead to greater awareness and interventions to promote sex diversity and inclusion
                  in the AOA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Encouragingly, women are participating in the AOA at rates
                  that are commensurate with, and at times greater than, their male counterparts. Moving forward,
                  academia will be increasingly instrumental in promoting the diversity of orthopaedic surgeons<a
                    href="" data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">14</span></a>. Academic institutions, including residency and fellowship
                  training programs, are the gatekeepers and orchestrators of the future orthopaedic workforce. Previous
                  research has demonstrated the important role of women in leadership positions across residency
                  training programs for recruiting more women into the field<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">18</span></a>. Additionally, women may have a greater propensity to focus
                  on diversity and inclusion efforts<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">19</span></a>. In
                  a similar fashion, the AOA may afford several opportunities to involve more female orthopaedic
                  surgeons in visible leadership roles at the trainee and faculty level, which may ultimately promote
                  greater diversity and inclusion across the specialty. For example, the Resident Leadership Forum and
                  Emerging Leaders Forum provide leadership training to emerging orthopaedic chief residents and junior
                  orthopaedic faculty, respectively. The Council of Orthopaedic Residency Directors (CORD) disseminates
                  best practices in orthopaedic education across North America, providing program directors with a
                  platform to advance education and diversity initiatives. By promoting diversity and inclusion in each
                  sponsored program, the AOA may provide a catalyst for future changes in the specialty.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">AOA membership is determined by demonstrated excellence
                  across 5 domains. First, candidates are assessed by their professional positions. This encompasses
                  residency and fellowship training, as well as attending faculty positions. Second, candidates are
                  evaluated by their scholarship and research. This includes research-grant funding, peer-reviewed
                  publications, podium presentations, and academic awards. Third, candidates are measured by obtainment
                  of leadership positions in orthopaedic surgery organizations (e.g., elected officer, committee chair,
                  and board member). Fourth, candidates are similarly measured by obtainment of leadership positions
                  across nonorthopaedic organizations, including health-care systems, medical organizations, and public
                  offices. Lastly, candidates are evaluated based on their participation in AOA-sponsored events (e.g.,
                  AOA traveling fellowships, AOA annual leadership meetings, CORD conferences, and the AOA Own the Bone
                  Program). Following satisfactory review of these 5 domains, prospective candidates are evaluated by
                  the entire AOA membership prior to the final election.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The AOA can focus on several areas to promote greater
                  levels of sex equity and inclusion within its membership. More women are needed in the training
                  pipeline<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">23</span></a>, which requires coordinated efforts across U.S. medical
                  schools to attract the best candidates from all backgrounds. The AOA can promote awareness among
                  faculty, program directors, and chairs to recruit more women into the specialty<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">26</span></a>. The AOA can also support female trainees during
                  orthopaedic surgery residency, who face unique challenges during training and greater rates of
                  attrition<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">28</span></a>. There is a clear need for greater mentorship of women in
                  orthopaedic surgery residencies<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">29</span></a>.
                  Existing collaborations with the Orthopaedic Research and Education Foundation can also be leveraged
                  to promote research opportunities for female residents in orthopaedic training<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">30</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">31</span></a>. Female orthopaedic surgeons often face difficulty in
                  establishing careers in certain geographic and practice settings<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">32</span></a>, which may be addressed through programmatic efforts and
                  training program strategies. For example, women are especially lacking in training programs and
                  subspecialty societies for spine surgery and adult reconstruction<a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">33</span></a>. Through intentional diversity efforts in subspecialty
                  societies, the AOA can encourage recruitment of more diverse members across each orthopaedic
                  subspecialty.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">This study has several limitations. First, it was not
                  designed to determine the etiologies for the current state of sex diversity in the AOA. Future studies
                  should investigate the attitudes of women orthopaedic surgeons regarding AOA membership, as well as
                  their application and success rates. AOA membership is entirely voluntary, and there may be bias as to
                  who decides to pursue election. Second, data on demographics and academic rank were collected from
                  internet-based resources such as residency program websites for faculty rosters, which may contain
                  errors or omissions. Third, demographic variables are social constructs, and the assignment of sex
                  based on the evaluation of names and photographs has obvious limitations. Fourth, promotion in
                  academic orthopaedic surgery varies across institutions, which was not accounted for in this study.
                  Fifth, the PPR is not a validated tool to determine equitability in representation, and thresholds
                  used to define over- and underrepresentation were derived from the literature<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">12</span></a>. Lastly, the AOA is not strictly an academic organization,
                  as there are many members who do not practice in strictly academic settings. However, despite these
                  limitations, this study affords important insights into sex diversity and inclusion in the AOA and
                  provides a potential framework to promote continued diversity efforts in academic orthopaedic surgery.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Women are a minority group in the AOA, but they exhibit
                  greater representation in the AOA than would be expected based on their prevalence among available
                  faculty at each academic rank. Among the AOA study cohort, women had the greatest representation in
                  hand surgery and the lowest representation in pediatric orthopaedics. Male AOA members exhibited
                  equivalent representation across academic ranks and orthopaedic subspecialties. Results from this
                  study ultimately provide a benchmark to advance positive diversity and inclusion efforts in the AOA.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;">American
                Orthopaedic Association. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">AOA Membership.</span> Accessed 2023 Mar 1. <a
                  href="https://www.aoassn.org/membership-in-aoa-is-earned/" target="_blank" data-id="link_1"
                  class="link" style="position: unset;">https://www.aoassn.org/membership-in-aoa-is-earned/</a>.</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Weinstein SL</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">The American Orthopaedic
                  Association: critical choices</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1997 Sep 1;79(9):1282-9.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=205679" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Acuña AJ</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Sato EH</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Jella TK</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Samuel LT</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Jeong SH</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Chen AF</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Kamath AF</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">How long will it take to reach
                  gender parity in orthopaedic surgery in the United States? An analysis of the National Provider
                  Identifier Registry</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2021 Jun 1;479(6):1179-89.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20long%20will%20it%20take%20to%20reach%20gender%20parity%20in%20orthopaedic%20surgery%20in%20the%20United%20States%3F%20An%20analysis%20of%20the%20National%20Provider%20Identifier%20Registry&as_occt=title&as_sauthors=%20%22AJ%20Acu%C3%B1a%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Shah KN</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Ruddell JH</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Scott B</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Reid DBC</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Sobel AD</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Katarincic JA</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Akelman E</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Orthopaedic surgery faculty: an
                  evaluation of gender and racial diversity compared with other specialties</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">JB JS Open Access.</span>
                2020 Jun 26;5(3):e20.00009.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Orthopaedic%20surgery%20faculty%3A%20an%20evaluation%20of%20gender%20and%20racial%20diversity%20compared%20with%20other%20specialties&as_occt=title&as_sauthors=%20%22KN%20Shah%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Meadows AM</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Skinner MM</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Faraj MT</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Hazime AA</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Day RG</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Fore JA</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Day CS</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Racial, Ethnic, and Gender
                  Diversity in Academic Orthopaedic Surgery Leadership</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2022 Jul
                6;104(13):1157-65.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=3282205"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Hoof MA</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Sommi C</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Meyer LE</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Bird ML</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Brown SM</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Mulcahey MK</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Gender-related differences in
                  research productivity, position, and advancement among academic orthopaedic faculty within the United
                  States</span>. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J Am
                  Acad Orthop Surg.</span> 2020 Nov 1;28(21):893-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Gender-related%20differences%20in%20research%20productivity%2C%20position%2C%20and%20advancement%20among%20academic%20orthopaedic%20faculty%20within%20the%20United%20States&as_occt=title&as_sauthors=%20%22MA%20Hoof%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Silvestre J</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Tippabhatla A</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Chopra A</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Nelson CL</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> LaPorte DM</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Sex Disparities Among Fellowship
                  Program Directors in Orthopaedic Surgery</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2024 Feb
                7;106(3):251-7.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=3d8e1cae-0955-4cfb-8065-c8e553e2d742" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Scerpella TA</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Spiker AM</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Lee CA</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Mulcahey MK</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Carnes ML</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Next steps: advocating for women
                  in orthopaedic surgery</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2022 Apr 15;30(8):377-86.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Next%20steps%3A%20advocating%20for%20women%20in%20orthopaedic%20surgery&as_occt=title&as_sauthors=%20%22TA%20Scerpella%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Miller EK</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> LaPorte DM</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Barriers to women entering the
                  field of orthopedic surgery</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Orthopedics.</span> 2015 Sep;38(9):530-3.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Barriers%20to%20women%20entering%20the%20field%20of%20orthopedic%20surgery&as_occt=title&as_sauthors=%20%22EK%20Miller%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Summers MA</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Matar RN</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Denning JR</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Dixon TL</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Ramalingam WG</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Asghar FA</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Grawe BM</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Closing the gender gap: barriers
                  to success for recruitment and retention of the female orthopaedic surgery applicant</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">JBJS Rev.</span> 2020
                May;8(5):e0211.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2421508"
                  target="_new" class="" style="position: unset;">JBJS Reviews</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Preventza O</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Critsinelis A</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Simpson K</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Olive JK</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> LeMaire SA</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Cornwell LD</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Jimenez E</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Byrne J</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Chatterjee S</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Rosengart TK</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Coselli JS</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Sex, Racial, and Ethnic
                  Disparities in U.S. Cardiovascular Trials in More Than 230,000 Patients</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Ann Thorac Surg.</span>
                2021 Sep;112(3):726-35.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%2C%20Racial%2C%20and%20Ethnic%20Disparities%20in%20U.S.%20Cardiovascular%20Trials%20in%20More%20Than%20230%2C000%20Patients&as_occt=title&as_sauthors=%20%22O%20Preventza%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Scott PE</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Unger EF</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Jenkins MR</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Southworth MR</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> McDowell TY</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Geller RJ</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Elahi M</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Temple RJ</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Woodcock J</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Participation of women in
                  clinical trials supporting FDA approval of cardiovascular drugs</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">J Am Coll Cardiol.</span> 2018 May
                8;71(18):1960-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Participation%20of%20women%20in%20clinical%20trials%20supporting%20FDA%20approval%20of%20cardiovascular%20drugs&as_occt=title&as_sauthors=%20%22PE%20Scott%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Wais K</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Gender prediction methods based
                  on first names with genderizeR</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">R Journal.</span> 2016 Jan;8(1):17.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Gender%20prediction%20methods%20based%20on%20first%20names%20with%20genderizeR&as_occt=title&as_sauthors=%20%22K%20Wais%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Kuhns B</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Haws BE</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Kaupp S</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Maloney MD</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Carmody EE</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Mannava S</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Academic Orthopaedics As a
                  Driver of Gender Diversity in the Orthopaedic Workforce: A Review of 4,519 Orthopaedic Faculty
                  Members</span>. <span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J Am
                  Acad Orthop Surg Glob Res Rev.</span> 2022 Feb 3;6(2):e21.00028.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Academic%20Orthopaedics%20As%20a%20Driver%20of%20Gender%20Diversity%20in%20the%20Orthopaedic%20Workforce%3A%20A%20Review%20of%204%2C519%20Orthopaedic%20Faculty%20Members&as_occt=title&as_sauthors=%20%22B%20Kuhns%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Okike K</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Phillips DP</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Swart E</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> O’Connor MI</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Orthopaedic faculty and
                  resident sex diversity are associated with the orthopaedic residency application rate of female
                  medical students</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2019 Jun 19;101(12):e56.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=2073260" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Munger AM</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Heckmann N</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> McKnight B</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Dusch MN</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Hatch GF</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Omid R</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Revisiting the gender gap in
                  orthopaedic surgery: investigating the relationship between orthopaedic surgery female faculty and
                  female residency applicants</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2019 Apr 15;27(8):295-300.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Revisiting%20the%20gender%20gap%20in%20orthopaedic%20surgery%3A%20investigating%20the%20relationship%20between%20orthopaedic%20surgery%20female%20faculty%20and%20female%20residency%20applicants&as_occt=title&as_sauthors=%20%22AM%20Munger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Sobel AD</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Cox RM</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Ashinsky B</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Eberson CP</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Mulcahey MK</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Analysis of factors related to
                  the sex diversity of orthopaedic residency programs in the United States</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2018 Jun 6;100(11):e79.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1692673" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Van Heest AE</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Fishman F</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Agel J</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">A 5-year update on the uneven
                  distribution of women in orthopaedic surgery residency training programs in the United States</span>.
                <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2016 Aug 3;98(15):e64.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1258649" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Ojo DE</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Martinez VH</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Zaheer A</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Williamson TK</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Baird MD</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Dingle M</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">A 25-Year Analysis of
                  Diversity, Equity, and Inclusion Research in Orthopaedics Shows Majority Female Authorship and
                  Increasing Gender Parity Research</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">JB JS Open Access.</span> 2023 Oct 29;8(4):e23.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%2025-Year%20Analysis%20of%20Diversity%2C%20Equity%2C%20and%20Inclusion%20Research%20in%20Orthopaedics%20Shows%20Majority%20Female%20Authorship%20and%20Increasing%20Gender%20Parity%20Research&as_occt=title&as_sauthors=%20%22DE%20Ojo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Wang JC</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Chang SW</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Nwachuku I</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Hill WJ</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Munger AM</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Suleiman LI</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Heckmann ND</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Trends in race and sex
                  representation among entering orthopaedic surgery residents: a continued call for active
                  diversification efforts</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2023 Jun 15;31(12):e530-e539.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trends%20in%20race%20and%20sex%20representation%20among%20entering%20orthopaedic%20surgery%20residents%3A%20a%20continued%20call%20for%20active%20diversification%20efforts&as_occt=title&as_sauthors=%20%22JC%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Chambers CC</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Ihnow SB</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Monroe EJ</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Suleiman LI</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Women in orthopaedic surgery:
                  population trends in trainees and practicing surgeons</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2018 Sep
                5;100(17):e116.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1781841"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Poon S</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Kiridly D</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Mutawakkil M</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Wendolowski S</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Gecelter R</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Kline M</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Lane LB</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Current trends in sex, race,
                  and ethnic diversity in orthopaedic surgery residency</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2019 Aug
                15;27(16):e725-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Current%20trends%20in%20sex%2C%20race%2C%20and%20ethnic%20diversity%20in%20orthopaedic%20surgery%20residency&as_occt=title&as_sauthors=%20%22S%20Poon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text"
                style="position: unset;">Association of American Medical Colleges. 2019 <span data-id="strong_22"
                  class="annotation strong" style="position: unset;">Fall Applicant, Matriculant, and Enrollment Data
                  Tables</span>. Accessed 2023 Dec 1. <a href="https://www.aamc.org/media/38821/download?attachment"
                  target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://www.aamc.org/media/38821/download?attachment</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fall%20Applicant%2C%20Matriculant%2C%20and%20Enrollment%20Data%20Tables&as_occt=title&as_sauthors=%20%22Association%20of%20American%20Medical%20Colleges%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_117" class="annotation" style="position: unset;"> O’Connor MI</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Medical school experiences
                  shape women students’ interest in orthopaedic surgery</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2016
                Sep;474(9):1967-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Medical%20school%20experiences%20shape%20women%20students%E2%80%99%20interest%20in%20orthopaedic%20surgery&as_occt=title&as_sauthors=%20%22MI%20O%E2%80%99Connor%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Lattanza LL</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Meszaros-Dearolf L</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> O’Connor MI</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Ladd A</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Bucha A</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Trauth-Nare A</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Buckley JM</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">The Perry Initiative’s medical
                  student outreach program recruits women into orthopaedic residency</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2016
                Sep;474(9):1962-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Perry%20Initiative%E2%80%99s%20medical%20student%20outreach%20program%20recruits%20women%20into%20orthopaedic%20residency&as_occt=title&as_sauthors=%20%22LL%20Lattanza%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Mason BS</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Ross W</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Ortega G</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Chambers MC</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Parks ML</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Can a strategic pipeline
                  initiative increase the number of women and underrepresented minorities in orthopaedic
                  surgery?</span><span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 2016 Sep;474(9):1979-85.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Can%20a%20strategic%20pipeline%20initiative%20increase%20the%20number%20of%20women%20and%20underrepresented%20minorities%20in%20orthopaedic%20surgery%3F&as_occt=title&as_sauthors=%20%22BS%20Mason%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Haruno LS</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Chen X</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Metzger M</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Lin CA</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Little MTM</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Kanim LEA</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Poon SC</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Racial and Sex Disparities in
                  Resident Attrition in Orthopaedic Surgery</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">JB JS Open Access.</span> 2023 Jun
                20;8(2):e22.00148.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Racial%20and%20Sex%20Disparities%20in%20Resident%20Attrition%20in%20Orthopaedic%20Surgery&as_occt=title&as_sauthors=%20%22LS%20Haruno%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Sobel AD</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Lavorgna TR</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Ames SE</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Templeton KJ</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Mulcahey MK</span>;
                Collaborative Orthopaedic Educational Research Group. <span data-id="strong_27"
                  class="annotation strong" style="position: unset;">Interpersonal Interactions and Biases in
                  Orthopaedic Surgery Residency: Do Experiences Differ Based on Gender?</span><span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2023 Feb 1;481(2):369-78.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Interpersonal%20Interactions%20and%20Biases%20in%20Orthopaedic%20Surgery%20Residency%3A%20Do%20Experiences%20Differ%20Based%20on%20Gender%3F&as_occt=title&as_sauthors=%20%22AD%20Sobel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Winfrey SR</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Parameswaran P</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Gerull KM</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> LaPorte D</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Cipriano CA</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Effective mentorship of women
                  and underrepresented minorities in orthopaedic surgery: a mixed-methods investigation</span>. <span
                  data-id="emphasis_28" class="annotation emphasis" style="position: unset;">JB JS Open Access.</span>
                2022 Nov 23;7(4):e22.00053.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effective%20mentorship%20of%20women%20and%20underrepresented%20minorities%20in%20orthopaedic%20surgery%3A%20a%20mixed-methods%20investigation&as_occt=title&as_sauthors=%20%22SR%20Winfrey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Rynecki ND</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Krell ES</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Potter JS</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Ranpura A</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Beebe KS</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">How well represented are women
                  orthopaedic surgeons and residents on major orthopaedic editorial boards and publications?</span><span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2020 Jul;478(7):1563-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20well%20represented%20are%20women%20orthopaedic%20surgeons%20and%20residents%20on%20major%20orthopaedic%20editorial%20boards%20and%20publications%3F&as_occt=title&as_sauthors=%20%22ND%20Rynecki%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Gerull KM</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Kim DJ</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Cogsil T</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Rhea L</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Cipriano C</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Are women proportionately
                  represented as speakers at orthopaedic surgery annual meetings? A cross-sectional analysis</span>.
                <span data-id="emphasis_30" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2020 Dec;478(12):2729-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Are%20women%20proportionately%20represented%20as%20speakers%20at%20orthopaedic%20surgery%20annual%20meetings%3F%20A%20cross-sectional%20analysis&as_occt=title&as_sauthors=%20%22KM%20Gerull%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Rodgers BM</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Moore ML</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Mead-Harvey C</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Pollock JR</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Thomas OJ</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Beauchamp CP</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Goulding KA</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">How does orthopaedic surgeon
                  gender representation vary by career stage, regional distribution, and practice size? A large-database
                  Medicare study</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2023 Feb 1;481(2):359-66.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20does%20orthopaedic%20surgeon%20gender%20representation%20vary%20by%20career%20stage%2C%20regional%20distribution%2C%20and%20practice%20size%3F%20A%20large-database%20Medicare%20study&as_occt=title&as_sauthors=%20%22BM%20Rodgers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Poon S</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Kiridly D</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Brown L</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Wendolowski S</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Gecelter R</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Vetere A</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Kline M</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Lane L</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Evaluation of sex, ethnic, and
                  racial diversity across US ACGME–accredited orthopedic subspecialty fellowship programs</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Orthopedics.</span> 2018
                Sep 1;41(5):282-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20sex%2C%20ethnic%2C%20and%20racial%20diversity%20across%20US%20ACGME%E2%80%93accredited%20orthopedic%20subspecialty%20fellowship%20programs&as_occt=title&as_sauthors=%20%22S%20Poon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Ded1051c9-dd29-4ccf-a376-8385514a3062%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 15, 2025;
                      107 (2): 222</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00071</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">July 25, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;ed1051c9-dd29-4ccf-a376-8385514a3062&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=ed1051c9-dd29-4ccf-a376-8385514a3062&type=pdf&name=JBJS.24.00071.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=ed1051c9-dd29-4ccf-a376-8385514a3062&type=pdf&name=JBJS.24.00071.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_38"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_39" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I123" target="_blank" data-id="link_3"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I123</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;ed1051c9-dd29-4ccf-a376-8385514a3062&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=ed1051c9-dd29-4ccf-a376-8385514a3062&type=zip&name=JBJS.24.00071.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jason Silvestre, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:jasonsilvestremd@gmail.com" class=""
                    style="position: unset;">jasonsilvestremd@gmail.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5831-0760" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5831-0760</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Maya Moore, BS<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5762-6333" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5762-6333</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Dawn M. LaPorte, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6564-2707" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6564-2707</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Vani J. Sabesan, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ann Van Heest, MD<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8064-5508" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8064-5508</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Medical University of South Carolina, Charleston, South Carolina</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">University of Miami Miller School of Medicine, Miami, Florida</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">The Johns Hopkins School of Medicine, Baltimore, Maryland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Atlantis Orthopaedics, Lake Worth, Florida</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">University of Minnesota, Minneapolis, Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 21073.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
