import type { Article } from "../../../../../types";

export const Article634: Article = {
	id: 634,
	rsuiteId: "98fde6dd-d467-4942-a931-860c2448ee6e",
	type: "scientific article",
	title:
		"The Impact of Sustained Outreach Efforts on Gender Diversity in Orthopaedic Surgery",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=98fde6dd-d467-4942-a931-860c2448ee6e&type=jpeg&name=JBJS.24.00210f1",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">The Impact of Sustained Outreach Efforts on Gender
                Diversity in Orthopaedic Surgery</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jenni M.
                      Buckley, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Laura M.
                      Dearolf, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Background:</span> Orthopaedic surgery is one of the least
                        gender-diverse surgical specialties, with only 7% women in practice and 20.4% in residency.
                        There are numerous “leaks” in the talent pipeline for women orthopaedic surgeons that lead to
                        the field as a whole falling short of a critical mass (30%) of women. For over a decade, a
                        network of professional and nonprofit organizations, including the Ruth Jackson Orthopaedic
                        Society, The Perry Initiative, Nth Dimensions, and others, have focused on targeted outreach and
                        mentoring of women in the talent pipeline; they report a positive effect of these interventions
                        on recruitment and retention of women in the field.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Methods:</span> In this study, we applied mathematical models to
                        estimate the historic and future impacts of current outreach and hands-on exposure efforts to
                        recruit more women into orthopaedic surgery. The model uses published data on program reach and
                        impact from one of the largest and longest-running programs, The Perry Initiative, and combines
                        it with AAMC and AAOS Census data. These data were used to forecast the percentage of women
                        entering the profession as postgraduate year 1 (PGY1) residents and among practicing orthopaedic
                        surgeons.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_35" class="annotation strong"
                          style="position: unset;">Results:</span> The results of the mathematical models suggest that
                        the increase in women in the PGY1 population from 14.7% to 20.9% from 2008 to 2022 is at least
                        partially attributable to current mentoring and outreach efforts by The Perry Initiative and
                        others. Assuming continued intervention at present levels, the PGY1 residency class will reach
                        peak diversity of 28% women in 2028, and the field as a whole will reach a steady-state
                        composition of approximately 25% practicing women orthopaedic surgeons by 2055.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_36" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The results of this study indicate that outreach
                        and exposure efforts, such as those of The Perry Initiative, are having a substantive impact on
                        gender diversity in orthopaedic surgery. With continued intervention, the field as a whole
                        should approach a critical mass of women within a generation. The collective efforts of the
                        orthopaedics community over the past decade to close the gender gap serve as a guidebook for
                        other professions seeking to diversify.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Orthopaedic surgery is one of the least gender-diverse
                  surgical specialties<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">6</span></a>, with 7% women in practice and 20.4% in residency<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">7</span></a>.
                  Although there have been some gains in gender diversity over the past decade, the field is still far
                  from achieving the 30% “critical mass” needed to reach self-sustaining diversification<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">9</span></a>. Two
                  recent studies<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">10</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">11</span></a> extrapolated historical trends in gender diversity in
                  orthopaedic surgery to predict the timeline for achieving critical mass. Pinpin et al.<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">11</span></a> predicted a critical mass of women in the incoming
                  residency class within the next 16 years, while Acuña et al.<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">10</span></a>
                  claimed that critical mass in the practicing orthopaedic surgeon population will take 200 years. The
                  discrepancy between these studies can be attributed to the time lag between recent, rapid changes in
                  the demographics of the residency population and the matriculation of these residents into a larger,
                  less diverse body of practicing orthopaedic surgeons. Given that the orthopaedics field is compact
                  (30,000 practicing surgeons<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">7</span></a>) and
                  has relatively high retention (2% attrition<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">12</span></a>),
                  gender diversification through targeted intervention should be achievable on a relatively short time
                  scale<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">10</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Any effective intervention to address the gender gap in
                  orthopaedics must target the “leaks” where women leave the career pipeline. The first leak occurs at
                  the transition from high school to college, with only 12% of college-bound women choosing STEM
                  (science, technology, engineering, and mathematics) majors compared with 26% of men<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">13</span></a>. The root cause of this attrition is multifaceted and
                  includes negative perceptions of the culture and climate in certain STEM disciplines, lack of
                  exposure, and fewer role models<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">14</span></a>.
                  The second leak occurs in medical school and residency. Only 0.9% of women medical students apply to
                  orthopaedic residency<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">16</span></a>, despite women now representing the slight majority of
                  medical students<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">17</span></a> and
                  having indistinguishable academic credentials from male applicants<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">18</span></a>. Women leave orthopaedic residency at twice the rate of
                  men<a href="" data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">19</span></a>, and this trend continues into the first 10 years of
                  clinical practice<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">12</span></a>.
                  The root causes of gender disparities in orthopaedics are multifaceted and include negative
                  perceptions of the orthopaedic surgery culture, a paucity of female mentors, work-life balance
                  concerns, and a lack of exposure to the discipline<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">23</span></a>. Bernstein et al.<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">23</span></a>
                  showed that men who pursue orthopaedics are more likely to be exposed to the discipline prior to
                  medical school, while women are exposed during medical school. This is problematic given that many
                  medical schools do not have required rotations in orthopaedic surgery<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">25</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">One strategy to close the gender gap in orthopaedics is to
                  expose large cohorts of women to the profession at critical junctures in their career development. For
                  the past 15 years, our nonprofit organization, The Perry Initiative, has conducted hands-on exposure
                  programs for women in the first and second years of medical school (Medical Student Outreach Program,
                  MSOP) and in the junior and senior years of high school (Perry Outreach Program, POP). Both the MSOP
                  and POP are national programs, enrolling approximately 2,000 students annually at 50 program sites in
                  the U.S. Since 2009, over 18,000 women have participated in The Perry Initiative. As described in
                  detail in prior publications<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">29</span></a>, the POP and MSOP feature a combination of hands-on
                  exercises and job talks with practicing surgeons.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Throughout the 15-year history of The Perry Initiative, we
                  have continuously evaluated the effectiveness of our programs<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">29</span></a> through biennial follow-up surveys of the entire alumnae
                  population, combined with case-by-case tracking of match-qualified individuals. For the past 4 match
                  cycles, 20% to 23% of MSOP alumnae have matched into an orthopaedic surgery residency<a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">28</span></a>, a rate that is approximately 20 times the historical match
                  rate for women medical students<a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">30</span></a>.
                  Ninety percent of POP alumnae matriculate into STEM majors in college, with 65% to 75% in the
                  biosciences<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">29</span></a>. The majority of alumnae in college (55%) state that they
                  intend to pursue medicine; and 5% to 10% successfully matriculate into medical school<a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">26</span></a>. Qualitative feedback from program participants indicates
                  that participation in The Perry Initiative was a key influencer in pursuing a career in orthopaedic
                  surgery<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">29</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The goal of the current study was to estimate the
                  historical impact of our programming efforts on recruiting more women into orthopaedics and to
                  forecast the potential impact of continued programming. Our study involved mathematical models that
                  are based on population dynamics algorithms<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">31</span></a> to
                  estimate the percentage of women entering orthopaedics as postgraduate year 1 (PGY1) residents and to
                  model gender diversification of the entire population of practicing orthopaedic surgeons. In
                  presenting this study, we acknowledge that The Perry Initiative is part of a collective effort to
                  diversify the orthopaedic workforce and that this effort includes partner organizations such as Nth
                  Dimensions, the Ruth Jackson Orthopaedic Society (RJOS), the J. Robert Gladden Orthopaedic Society
                  (JRGOS), and the American Association of Latino Orthopaedic Surgeons (AALOS). The findings of this
                  study are not only valuable to direct strategic-planning efforts domestically for women orthopaedic
                  surgeons but they also can be interpreted more broadly as a playbook for creating effective and
                  scalable diversity, equity, and inclusion (DEI) programs targeting different affinity groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Two separate mathematical models are presented in this
                  study. The first model determined the impact of The Perry Initiative’s outreach programs on the
                  percentage of women entering the profession as PGY1 residents; the second model used the output from
                  the first model to forecast the gender demographics of practicing, board-certified orthopaedic
                  surgeons. Both models are confined to orthopaedic surgeons in the U.S. and use the term “woman” to
                  refer to individuals who self-identify as such. Both models target a “critical mass” of 30% women. The
                  models employ a discrete time-step approach<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">31</span></a> and
                  were built using Excel (version 16.69.1; Microsoft).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Model 1:
                Women Entering Orthopaedic Residency</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The first mathematical model predicted the percentage of
                  PGY1 residents in orthopaedics who are women. The model used &gt;20 years of demographic data (2001 to
                  2022) sourced from Association of American Medical Colleges (AAMC)<a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">30</span></a> and Graduate Medical Education (GME)<a href=""
                    data-id="citation_reference_46" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">32</span></a> databases. These data include the annual census and gender
                  demographics of both the entire residency population (PGY1 to 5) and incoming residency class (PGY1)
                  (<a href="" data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Historical Demographic Data Sourced from AAMC and GME
                          Databases and Perry Initiative Data<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 983.618px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.692632);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Years</span>
                      </th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Residency Population</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">PGY1 Population</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Perry Initiative <span data-id="emphasis_32"
                            class="annotation emphasis" style="position: unset;">(no.)</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total
                          No.</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Women</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">%
                          Women</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total
                          No.</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Women</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">%
                          Women</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">POP</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">MSOP</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Matched</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2001-2002</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,002</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">271</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.0%</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2004-2005</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2,967</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">297</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">675</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.6%</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2005-2006</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,131</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">347</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">679</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.0%</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2006-2007</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,083</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">352</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">681</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.0%</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2007-2008</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,064</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">378</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">695</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.9%</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2008-2009</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,177</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">417</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">708</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">104</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2009-2010</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,650</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">488</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">707</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">92</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">106</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2010-2011</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,749</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">502</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">713</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">93</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">225</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2011-2012</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,833</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">520</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">725</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">114</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">504</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2012-2013</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,881</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">537</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">739</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">101</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">774</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2013-2014</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,973</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">547</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">777</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">102</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,060</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">227</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2014-2015</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,704</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">528</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">798</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">122</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,112</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">266</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2015-2016</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,786</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">548</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">832</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">122</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,240</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">334</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2016-2017</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,872</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">563</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">854</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">128</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,350</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">306</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2017-2018</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,955</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">603</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">859</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">150</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,303</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">289</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2018-2019</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,963</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">610</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">875</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">130</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,605</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">420</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2019-2020</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4,214</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">673</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">854</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">166</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">19.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">884</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">267</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2020-2021</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4,302</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">731</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">861</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">166</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">19.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,648</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">316</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2021-2022</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4,334</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">792</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">862</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">180</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,198</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">745</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">79</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Data regarding The Perry Initiative are also shown, with the number of
                        women participants at the high school (Perry Outreach Program, POP) and medical school (Medical
                        Student Outreach Program, MSOP) levels as well as the number of Perry Initiative alumnae who are
                        known to have matched into orthopaedic residency in the given year (Matched).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The model incorporated 3 fundamental assumptions. First,
                  we assumed that there are reasonably consistent matriculation patterns for our program alumnae. POP
                  participants would matriculate into their PGY1 year 10 to 11 years after program participation in
                  their junior or senior year in high school. MSOP participants are in their first or second year of
                  medical school and matriculate as PGY1 residents 2 to 3 years after program participation. Our first
                  MSOP alumnae entered residency in 2015, with POP participants matriculating beginning in 2019. Second,
                  the model incorporated 1.4% annual growth in total PGY1 class size. This growth rate was calculated by
                  curve-fitting historical data for PGY1 class size from 2004 to 2013 (p &lt; 0.001; R<span
                    data-id="superscript_31" class="annotation superscript" style="position: unset;">2</span> = 0.89),
                  purposefully before the MD and DO residency merger in 2014. Lastly, we incorporated a baseline
                  population of women orthopaedic residents who did not participate in our programs. For the 5 years
                  immediately preceding any impact from our programs (2010 to 2014), approximately 100 women entered as
                  PGY1 residents annually. This number of individuals was assumed to be our baseline, increasing at 1.4%
                  annually.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">We modeled the impact of the POP and MSOP by combining
                  enrollment and program evaluation data and using a time-step algorithm to predict matriculation
                  patterns. Historical enrollment for both the POP and MSOP are shown in <a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a> and reflect exponential growth in both programs over the past
                  15 years. POP participants are surveyed biennially, and this model was built on data from our most
                  recent survey<a href="" data-id="citation_reference_47"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">26</span></a> (n
                  = 1,115 respondents, 12% response rate). Every year, all match-eligible MSOP alumnae are also surveyed
                  immediately following the AAMC residency match. The model parameters that were derived from these
                  program evaluation data are shown in <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>. Our
                  MSOP program consistently yields 22% to 23% match rates in orthopaedics<a href=""
                    data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">27</span></a>,<a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">28</span></a>.
                  Past surveys have shown that between 5% and 10% of POP alumnae will enroll in medical school, with 30%
                  to 50% expressing interest in pursuing orthopaedic surgery<a href="" data-id="citation_reference_50"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_35"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">29</span></a>. This uncertainty was explicitly incorporated into our
                  mathematical model (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Parameters for the Mathematical Models Used to
                          Estimate the Past, Current, and Future Impact of The Perry Initiative’s Outreach Programs on
                          Gender Diversity in Orthopaedic Residency</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 366px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Parameter</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Value(s)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Annual growth rate of
                          overall PGY1 residency class<a href="" data-id="citation_reference_52"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_36" class="annotation superscript"
                              style="position: unset;">30</span></a><span data-id="superscript_36"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.40%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Percentage of POP alumnae
                          who enter medical school<a href="" data-id="citation_reference_53"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_37" class="annotation superscript"
                              style="position: unset;">26</span></a><span data-id="superscript_37"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                              style="position: unset;">29</a></span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5%-10%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Percentage of POP alumnae
                          in medical school intending to pursue orthopaedic residency<a href=""
                            data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                              style="position: unset;">26</span></a><span data-id="superscript_38"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                              style="position: unset;">29</a></span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">30%-50%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Percentage of
                          match-eligible MSOP program alumnae who match into orthopaedic residency annually<a href=""
                            data-id="citation_reference_57" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                              style="position: unset;">27</span></a><span data-id="superscript_39"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_58" class="annotation citation_reference resource-reference"
                              style="position: unset;">28</a></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23%</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Model 2:
                Practicing Women Orthopaedic Surgeons</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">We created a second mathematical model to predict the
                  impact of The Perry Initiative’s pipeline programs on the gender diversity of the entire population of
                  practicing orthopaedic surgeons. The age distribution of practicing surgeons and percentage of women
                  in practice were taken directly from the most recent American Academy of Orthopaedic Surgeons (AAOS)
                  Census<a href="" data-id="citation_reference_59"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_40" class="annotation superscript" style="position: unset;">7</span></a>. The
                  ages of entry into, and retirement from, the profession were modeled assuming that most individuals
                  enter practice at 30 to 35 years of age and retire by 75 years of age. The model incorporated a higher
                  rate of attrition for women during residency<a href="" data-id="citation_reference_60"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_41" class="annotation superscript" style="position: unset;">19</span></a> and
                  10 years post-residency<a href="" data-id="citation_reference_61"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_42" class="annotation superscript" style="position: unset;">12</span></a>.
                  The results of our first model were input into this second model to account for changing gender
                  demographics and the annual growth rate of the PGY1 residency class.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The results of our first model are presented in <a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figure 1</a>. At present, the PGY1 population is undergoing relatively
                  rapid gender diversification. If The Perry Initiative continues with the present volume of
                  programming, the incoming PGY1 class will continue to diversify until it reaches a peak of 28.0% ±
                  1.6% women in 2028. This will decrease slightly to a steady state of 27.1% ± 1.3%, which will hold
                  until at least 2040.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00210f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=98fde6dd-d467-4942-a931-860c2448ee6e&type=jpeg&name=JBJS.24.00210f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Historical and forecasted impact of The Perry
                          Initiative’s outreach programs on the percentage of women in the PGY1 population. The whiskers
                          represent uncertainty bounds associated with the mathematical model (see <a href=""
                            data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                            style="position: unset;">Table II</a> for parameter ranges).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The results of our second mathematical model are shown in
                  <a href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figure 2</a>. The results indicate that the field will reach a steady-state
                  composition of approximately 25% women in practice by 2055.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00210f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=98fde6dd-d467-4942-a931-860c2448ee6e&type=jpeg&name=JBJS.24.00210f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">Forecasted impact of The Perry Initiative’s
                          programs on the percentage of practicing orthopaedic surgeons in the U.S. who are women. The
                          whiskers represent uncertainty bounds associated with the mathematical model (see <a href=""
                            data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                            style="position: unset;">Table II</a> for parameter ranges).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">This study modeled the potential impact of The Perry
                  Initiative’s outreach efforts on increasing gender diversity in orthopaedic surgery. Over our 15-year
                  history, we have grown rapidly into a national organization that enrolls nearly 2,000 highly qualified
                  women students annually. This means that the success of our organization is built less on the
                  retention of any one individual participant and more on exposing large cohorts of women to the field.
                  Early exposure is a key part of The Perry Initiative’s mission and has been previously cited by other
                  authors as an important factor in increasing the number of women applicants to orthopaedic surgery<a
                    href="" data-id="citation_reference_62" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_43" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_43"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_63" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_43" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_43"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_64" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_43" class="annotation superscript"
                      style="position: unset;">33</span></a>. Additionally, there are other factors that have been
                  reported to affect women’s choice of orthopaedic surgery, including mentorship, negative perceptions
                  of the field<a href="" data-id="citation_reference_65"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_44" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_44" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_66" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">34</span></a>, and a lack of exposure to orthopaedics in medical school<a
                    href="" data-id="citation_reference_67" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_45" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_45"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_68" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_45" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_45"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_69" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_45" class="annotation superscript"
                      style="position: unset;">25</span></a>. These factors are directly addressed in The Perry
                  Initiative’s programs, helping to extinguish age-old stereotypes and biases.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">The percentage of women entering residency increased by 6
                  percentage points (14.7% to 20.9%) from the initiation of The Perry Initiative through 2022. While we
                  are not claiming full credit for this diversification, our models suggest that our POP and MSOP are
                  having a measurable and sustained impact on the field. We are joined in this effort by other
                  long-standing organizations, like Nth Dimensions and the Ruth Jackson Orthopaedic Society, which also
                  promote women in orthopaedics. If we continue to conduct our outreach efforts at a rate that matches
                  the anticipated growth of the profession, the gender diversity of the residency population will
                  approach critical mass within the next 5 years and drive diversification of the orthopaedic surgeon
                  population as a whole. Our second mathematical model, which incorporated incoming (graduating
                  residents) and outgoing (retiring) population streams, suggests that gender diversification of the
                  entire orthopaedics profession will be nonlinear in nature, with rapid acceleration in the coming 2
                  decades as less gender-diverse age cohorts enter retirement and are replaced by drastically more
                  diverse graduating residency classes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Considering that the results of this study are based
                  largely on mathematical models, we will directly address the strengths and limitations of our
                  approach. A major strength of this study is that our models were constructed from publicly available
                  historical data, i.e., AAMC residency class demographics and the AAOS Census<a href=""
                    data-id="citation_reference_70" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_46" class="annotation superscript"
                      style="position: unset;">7</span></a>, combined with published program evaluation results from our
                  own organization<a href="" data-id="citation_reference_71"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_47"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_72" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">29</span></a>. For the one parameter in which there is some uncertainty
                  in our model—that is, the rate of POP alumnae entering medical school—we calculated best and
                  worst-case outcomes that explicitly incorporated this uncertainty. Despite this analytical rigor,
                  there are always caveats to mathematical models. We acknowledge that The Perry Initiative is part of a
                  broader, collective effort to diversify orthopaedics and cannot definitively take credit for all past
                  and future gains in the representation of women in the field. That said, The Perry Initiative is the
                  largest and longest-running outreach intervention in orthopaedics, and the organization has
                  continuously tracked program outcomes<a href="" data-id="citation_reference_73"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_48"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_74" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">29</span></a>. Forecasts built from our program outcomes alone represent
                  a conservative estimate of the combined effect of all DEI efforts in orthopaedics. We were also as
                  conservative as possible in estimating the impact of our interventions, e.g., assuming a baseline
                  number of women per year who enter orthopaedics without participating in our programs and bounding the
                  uncertainty in high school to medical school matriculation rates. The second caveat is that our model
                  only accounts for our program’s impact on gender diversification. Race and ethnicity diversification
                  is being addressed through sustained efforts with other nonprofit organizations, such as Nth
                  Dimensions, the Gladden Society, and others. Our program registration was only recently modified to
                  track racial and ethnic identity, and we found that 18% of our present participants are Black/African
                  American and Latina women. Future work by our group will track the career trajectories of women of
                  color who participate in our outreach programs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">This study presents clear evidence that the orthopaedic
                  surgery profession can and will be gender-diverse within a reasonable time period if present pipeline
                  efforts such as those of The Perry Initiative are sustained. Acuña et al.<a href=""
                    data-id="citation_reference_75" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">10</span></a> asserted that it would take nearly 200 years to reach a
                  critical mass (30%) of women in orthopaedic practice; however, this model failed to account for
                  differential demographics of incoming (PGY1) and outgoing (retirement) populations, a factor that is
                  explicitly modeled in our study. Pinpin et al.<a href="" data-id="citation_reference_76"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_50" class="annotation superscript" style="position: unset;">11</span></a>
                  used recent historical trends in the composition of the PGY1 residency class to predict that the field
                  would reach “gender parity” (defined as 36.3% women) within 16 years, showing that critical mass would
                  be achieved between 2030 and 2035. Our study is in agreement with Pinpin et al.<a href=""
                    data-id="citation_reference_77" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_51" class="annotation superscript"
                      style="position: unset;">11</span></a> and extends their findings through a more rigorous
                  mathematical model that accounts for the matriculation of residents into the practicing orthopaedic
                  surgeon population as well as the differential attrition of women residents and early career surgeons
                  and the retirement over time of less diverse cohorts of practicing surgeons. Consistent with Pinpin et
                  al.<a href="" data-id="citation_reference_78" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_52" class="annotation superscript"
                      style="position: unset;">11</span></a>, our model predicted that the residency population will
                  approach a critical mass of women by 2030, and women will represent approximately 25% of the
                  practicing orthopaedic surgeon workforce by 2055.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Our findings have 2 important implications for the field
                  of orthopaedic surgery, particularly as they relate to strategic efforts to diversify the profession.
                  First, the target for gender diversification should be achieving and sustaining a critical mass of
                  women (approximately 30%) in the incoming (PGY1) residency stream. If interventions through
                  organizations such as The Perry Initiative and others are continued at the present rate, the PGY1
                  class will approach this threshold within the next 5 years. A second implication of our study is the
                  importance of conducting thoughtfully targeted interventions at a large scale. An increase in the
                  percentage of women PGY1 residents from 15% to 30% represents an additional 120 women entering the
                  profession annually. This gain is only achievable through national-level interventions like The Perry
                  Initiative that enroll an order of magnitude more women (thousands instead of hundreds) to account for
                  “leakage” from the pipeline.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Once critical mass is achieved and sustained, the culture
                  of the field will ideally shift to more self-sustaining recruiting patterns that may require less
                  intervention. In the meantime, it is imperative that corporate partners, universities and medical
                  centers, and foundations continue to support effective, scalable programs to encourage and inspire
                  more women to pursue orthopaedic surgery. The findings of this study, as well as the structure of The
                  Perry Initiative’s programming efforts<a href="" data-id="citation_reference_79"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_53"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_80" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">29</span></a>, help to serve as a playbook for other organizations
                  internationally that are similarly dedicated to increasing all types of diversity in the field of
                  orthopaedic surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Van Heest AE</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Agel J</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">The uneven distribution of women
                  in orthopaedic surgery resident training programs in the United States</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2012 Jan 18;94(2):e9.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1130059"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Brotherton SE</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Etzel SI</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Graduate Medical Education,
                  2022-2023</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2023 Sep 12;330(10):988-1-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Graduate%20Medical%20Education%2C%202022-2023&as_occt=title&as_sauthors=%20%22SE%20Brotherton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Van Heest AE</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Agel J</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Samora JB</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">A 15-Year Report on the Uneven
                  Distribution of Women in Orthopaedic Surgery Residency Training Programs in the United States</span>.
                <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">JBJS Open Access.</span>
                2021 May 28;6(2):e20.00157.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%2015-Year%20Report%20on%20the%20Uneven%20Distribution%20of%20Women%20in%20Orthopaedic%20Surgery%20Residency%20Training%20Programs%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22AE%20Van%20Heest%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Chambers CC</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Ihnow SB</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Monroe EJ</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Suleiman LI</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Women in Orthopaedic Surgery:
                  Population Trends in Trainees and Practicing Surgeons</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2018 Sep
                5;100(17):e116.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1781841"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Sobel AD</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Cox RM</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Ashinsky B</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Eberson CP</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Mulcahey MK</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Analysis of Factors Related to
                  the Sex Diversity of Orthopaedic Residency Programs in the United States</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2018 Jun 6;100(11):e79.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1692673"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Rohde RS</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Wolf JM</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Adams JE</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Where Are the Women in
                  Orthopaedic Surgery?</span><span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2016 Sep;474(9):1950-1-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Where%20Are%20the%20Women%20in%20Orthopaedic%20Surgery%3F&as_occt=title&as_sauthors=%20%22RS%20Rohde%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;">AAOS
                Department of Clinical Quality and Value. 2019 <span data-id="strong_7" class="annotation strong"
                  style="position: unset;">Orthopaedic Practice in the US 2018</span>. Accessed 2024 Aug 22. <a
                  href="https://www.aaos.org/globalassets/quality-and-practice-resources/census/2018-census.pdf"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.aaos.org/globalassets/quality-and-practice-resources/census/2018-census.pdf</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Orthopaedic%20Practice%20in%20the%20US%202018&as_occt=title&as_sauthors=%20%22AAOS%20Department%20of%20Clinical%20Quality%20and%20Value%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Valian V</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Why So Slow? The Advancement
                  of Women.</span> Cambridge: MIT Press; 1998.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Kanter RM</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Some Effects of Proportions on
                  Group Life: Skewed Sex Ratios and Responses to Token Women</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Am J Sociol.</span>
                1977;82(5):965-1-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Some%20Effects%20of%20Proportions%20on%20Group%20Life%3A%20Skewed%20Sex%20Ratios%20and%20Responses%20to%20Token%20Women&as_occt=title&as_sauthors=%20%22RM%20Kanter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Acuña AJ</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Sato EH</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Jella TK</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Samuel LT</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Jeong SH</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Chen AF</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Kamath AF</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">How Long Will It Take to Reach
                  Gender Parity in Orthopaedic Surgery in the United States? An Analysis of the National Provider
                  Identifier Registry</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2021 Jun 1;479(6):1179-1-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20Long%20Will%20It%20Take%20to%20Reach%20Gender%20Parity%20in%20Orthopaedic%20Surgery%20in%20the%20United%20States%3F%20An%20Analysis%20of%20the%20National%20Provider%20Identifier%20Registry&as_occt=title&as_sauthors=%20%22AJ%20Acu%C3%B1a%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Pinpin C</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> White PB</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Nellans KW</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Bitterman AD</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Mulcahey MK</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Cohn RM</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Exponential Growth in Female
                  Residency Applicants in Orthopaedic Surgery Over the Past 15 Years</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">JBJS Open Access.</span> 2023 May
                30;8(2):e23.00004.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Exponential%20Growth%20in%20Female%20Residency%20Applicants%20in%20Orthopaedic%20Surgery%20Over%20the%20Past%2015%20Years&as_occt=title&as_sauthors=%20%22C%20Pinpin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Jella TK</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Patel VR</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Cwalina TB</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Schmidt JE</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Lawler EA</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Vallier HA</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">What Factors Are Associated
                  With Early Career Attrition Among Orthopaedic Surgeons in the United States?</span><span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2023 Oct 1;481(10):1895-1-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20Factors%20Are%20Associated%20With%20Early%20Career%20Attrition%20Among%20Orthopaedic%20Surgeons%20in%20the%20United%20States%3F&as_occt=title&as_sauthors=%20%22TK%20Jella%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;">National
                Science Board. <span data-id="strong_12" class="annotation strong" style="position: unset;">2016 Science
                  and Engineering Indicators 2016</span>. Accessed 2024 Aug 22. <a
                  href="https://www.nsf.gov/statistics/2016/nsb20161/#/report" target="_blank" data-id="link_2"
                  class="link"
                  style="position: unset;">https://www.nsf.gov/statistics/2016/nsb20161/#/report</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=2016%20Science%20and%20Engineering%20Indicators%202016&as_occt=title&as_sauthors=%20%22National%20Science%20Board%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Cheryan S</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Ziegler SA</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Montoya AK</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Jiang L</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Why are some STEM fields more
                  gender balanced than others?</span><span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Psychol Bull.</span> 2017 Jan;143(1):1-1-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Why%20are%20some%20STEM%20fields%20more%20gender%20balanced%20than%20others%3F&as_occt=title&as_sauthors=%20%22S%20Cheryan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Baldwin K</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Namdari S</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Bowers A</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Keenan MA</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Levin LS</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Ahn J</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Factors affecting interest in
                  orthopedics among female medical students: a prospective analysis</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">Orthopedics.</span> 2011 Dec
                6;34(12):e919-1-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20affecting%20interest%20in%20orthopedics%20among%20female%20medical%20students%3A%20a%20prospective%20analysis&as_occt=title&as_sauthors=%20%22K%20Baldwin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Johnson AL</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Sharma J</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Chinchilli VM</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Emery SE</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> McCollister Evarts C</span>,
                <span data-id="annotation_56" class="annotation" style="position: unset;"> Floyd MW</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Kaeding CC</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Lavelle WF</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Marsh JL</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Pellegrini VD Jr</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Van Heest AE</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Black KP</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Why do medical students choose
                  orthopaedics as a career?</span><span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2012 Jun 6;94(11):e78.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1146210" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text"
                style="position: unset;">Accreditation Council for Graduate Medical Education. <span data-id="strong_16"
                  class="annotation strong" style="position: unset;">Data Resource Book Academic Year 2020-2021</span>.
                2021. Accessed 2022 Sep. <a
                  href="https://www.acgme.org/globalassets/pfassets/publicationsbooks/2020-2021_acgme__databook_document.pdf"
                  target="_blank" data-id="link_3" class="link"
                  style="position: unset;">https://www.acgme.org/globalassets/pfassets/publicationsbooks/2020-2021_acgme__databook_document.pdf</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Data%20Resource%20Book%20Academic%20Year%202020-2021&as_occt=title&as_sauthors=%20%22Accreditation%20Council%20for%20Graduate%20Medical%20Education%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Poon S</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Nellans K</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Crabb RAL</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Rothman A</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Wendolowski SF</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Kiridly D</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Gecelter R</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Akerman M</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Chahine NO</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Academic Metrics Do Not Explain
                  the Underrepresentation of Women in Orthopaedic Training Programs</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2019 Apr
                17;101(8):e32.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2006609"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Haruno LS</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Chen X</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Metzger M</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Lin CA</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Little MTM</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Kanim LEA</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Poon SC</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Racial and Sex Disparities in
                  Resident Attrition in Orthopaedic Surgery</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">JBJS Open Access.</span> 2023 Jun
                20;8(2):e22.00148.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Racial%20and%20Sex%20Disparities%20in%20Resident%20Attrition%20in%20Orthopaedic%20Surgery&as_occt=title&as_sauthors=%20%22LS%20Haruno%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Hill JF</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Yule A</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Zurakowski D</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Day CS</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Residents’ perceptions of sex
                  diversity in orthopaedic surgery</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2013 Oct 2;95(19):e1441-1-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Residents%E2%80%99%20perceptions%20of%20sex%20diversity%20in%20orthopaedic%20surgery&as_occt=title&as_sauthors=%20%22JF%20Hill%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Day CS</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Lage DE</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Ahn CS</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Diversity based on race,
                  ethnicity, and sex between academic orthopaedic surgery and other specialties: a comparative
                  study</span>. <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Bone
                  Joint Surg Am.</span> 2010 Oct 6;92(13):2328-1-6.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1101768" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Miller EK</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> LaPorte DM</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Barriers to Women Entering the
                  Field of Orthopedic Surgery</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">Orthopedics.</span> 2015 Sep;38(9):530-1-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Barriers%20to%20Women%20Entering%20the%20Field%20of%20Orthopedic%20Surgery&as_occt=title&as_sauthors=%20%22EK%20Miller%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Bernstein J</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Dicaprio MR</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Mehta S</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">The relationship between
                  required medical school instruction in musculoskeletal medicine and application rates to orthopaedic
                  surgery residency programs</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2004 Oct;86(10):2335-1-6.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=939080" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_91" class="annotation" style="position: unset;"> London DA</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Calfee RP</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Boyer MI</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Impact of a Musculoskeletal
                  Clerkship on Orthopedic Surgery Applicant Diversity</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">Am J Orthop (Belle Mead NJ).</span> 2016
                Sep/Oct;45(6):E347-1-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impact%20of%20a%20Musculoskeletal%20Clerkship%20on%20Orthopedic%20Surgery%20Applicant%20Diversity&as_occt=title&as_sauthors=%20%22DA%20London%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Rahman R</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Zhang B</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Humbyrd CJ</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> LaPorte D</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">How Do Medical Students
                  Perceive Diversity in Orthopaedic Surgery, and How Do Their Perceptions Change After an Orthopaedic
                  Clinical Rotation?</span><span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2021 Mar 1;479(3):434-1-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20Do%20Medical%20Students%20Perceive%20Diversity%20in%20Orthopaedic%20Surgery%2C%20and%20How%20Do%20Their%20Perceptions%20Change%20After%20an%20Orthopaedic%20Clinical%20Rotation%3F&as_occt=title&as_sauthors=%20%22R%20Rahman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Dearolf L</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Buckley J</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Heuschneider H</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Lattanza L</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">The Perry Initiative Perry
                  Outreach Program Participation Influences College Major, Graduate School Matriculation And Medical
                  School Specialization</span>. In: <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">Proceedings of the Orthopaedic Research Society.</span> Tampa:
                2022.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Perry%20Initiative%20Perry%20Outreach%20Program%20Participation%20Influences%20College%20Major%2C%20Graduate%20School%20Matriculation%20And%20Medical%20School%20Specialization&as_occt=title&as_sauthors=%20%22L%20Dearolf%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Harbold D</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Dearolf L</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Buckley J</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Lattanza L</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">The Perry Initiative’s Impact
                  on Gender Diversity Within Orthopedic Education</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">Curr Rev Musculoskelet Med.</span> 2021
                Dec;14(6):429-1-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Perry%20Initiative%E2%80%99s%20Impact%20on%20Gender%20Diversity%20Within%20Orthopedic%20Education&as_occt=title&as_sauthors=%20%22D%20Harbold%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Lattanza LL</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Meszaros-Dearolf L</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> O’Connor MI</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Ladd A</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Bucha A</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Trauth-Nare A</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Buckley JM</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">The Perry Initiative’s Medical
                  Student Outreach Program Recruits Women Into Orthopaedic Residency</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2016
                Sep;474(9):1962-1-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Perry%20Initiative%E2%80%99s%20Medical%20Student%20Outreach%20Program%20Recruits%20Women%20Into%20Orthopaedic%20Residency&as_occt=title&as_sauthors=%20%22LL%20Lattanza%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Buckley J</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Dearolf L</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Lattanza L</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">The Perry Initiative: Building
                  the Pipeline for Women in Orthopaedics</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2022 Apr 15;30(8):358-1-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Perry%20Initiative%3A%20Building%20the%20Pipeline%20for%20Women%20in%20Orthopaedics&as_occt=title&as_sauthors=%20%22J%20Buckley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text"
                style="position: unset;">Association of American Medical Colleges. <span data-id="strong_29"
                  class="annotation strong" style="position: unset;">Report on Residents</span>. 2020. Accessed 2024 Aug
                22. <a href="https://www.aamc.org/media/50286/download?attachment" target="_blank" data-id="link_4"
                  class="link"
                  style="position: unset;">https://www.aamc.org/media/50286/download?attachment</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Report%20on%20Residents&as_occt=title&as_sauthors=%20%22Association%20of%20American%20Medical%20Colleges%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Greenberg MD</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Advanced engineering
                  mathematics-</span> - 2nd edition. Simon & Schuster; 1998.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Brotherton SE</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Etzel SI</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Graduate Medical Education,
                  2020-2021</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2021 Sep 21;326(11):1088-1-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Graduate%20Medical%20Education%2C%202020-2021&as_occt=title&as_sauthors=%20%22SE%20Brotherton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_119" class="annotation" style="position: unset;"> O’Connor MI</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Medical School Experiences
                  Shape Women Students’ Interest in Orthopaedic Surgery</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2016
                Sep;474(9):1967-1-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Medical%20School%20Experiences%20Shape%20Women%20Students%E2%80%99%20Interest%20in%20Orthopaedic%20Surgery&as_occt=title&as_sauthors=%20%22MI%20O%E2%80%99Connor%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Hull B</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Pestrin O</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Brennan CM</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Hackney R</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Scott CEH</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Women in Surgery Events Alone
                  do not Change Medical Student Perceptions of Gender Bias and Discrimination in Orthopaedic
                  Surgery</span>. <span data-id="emphasis_30" class="annotation emphasis" style="position: unset;">Front
                  Surg.</span> 2022 May 25;9:905558.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Women%20in%20Surgery%20Events%20Alone%20do%20not%20Change%20Medical%20Student%20Perceptions%20of%20Gender%20Bias%20and%20Discrimination%20in%20Orthopaedic%20Surgery&as_occt=title&as_sauthors=%20%22B%20Hull%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D98fde6dd-d467-4942-a931-860c2448ee6e%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 1, 2025;
                      107 (1): e1</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00210</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 22, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_5" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_31"
                            class="annotation emphasis" style="position: unset;">Investigation performed at The Perry
                            Initiative, Newark, Delaware</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;">Disclaimer: This material is based on data
                          provided by the Association of American Medical Colleges (AAMC). The views expressed herein
                          are those of the authors and do not necessarily reflect the position or policy of the AAMC.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_7" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;98fde6dd-d467-4942-a931-860c2448ee6e&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=98fde6dd-d467-4942-a931-860c2448ee6e&type=pdf&name=JBJS.24.00210.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=98fde6dd-d467-4942-a931-860c2448ee6e&type=pdf&name=JBJS.24.00210.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_37"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The Perry Initiative
                          acknowledges major underwriting from The Zimmer Biomet Foundation, Stryker Corporation, and
                          the American Academy of Orthopaedic Surgeons. In-kind donations are provided to The Perry
                          Initiative by Sawbones® Pacific Research Labs. The <span data-id="strong_38"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I292" target="_blank" data-id="link_5" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I292</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;98fde6dd-d467-4942-a931-860c2448ee6e&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=98fde6dd-d467-4942-a931-860c2448ee6e&type=zip&name=JBJS.24.00210.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jenni M. Buckley, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:jbuckley@udel.edu" class="" style="position: unset;">jbuckley@udel.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5454-1716" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5454-1716</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Laura M. Dearolf, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6045-8588" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6045-8588</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lily Wood, MS<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8683-3035" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8683-3035</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Julie Agel, MA<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4296-7389" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4296-7389</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ann E. Van Heest, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8064-5508" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8064-5508</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lisa L. Lattanza, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8843-8418" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8843-8418</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Mechanical Engineering, University of Delaware, Newark,
                Delaware</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">The Perry Initiative, Newark, Delaware</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, University of Minnesota, Minneapolis,
                Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Orthopaedics and Rehabilitation, Yale University School of
                Medicine, New Haven, Connecticut</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 23788.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
