import type { OnboardingStep } from "@configs/custom-app-config-schema";
import { isDev } from "@constants/urls";
import { useAppStore } from "@hooks/useAppStore";

export const getSelectRoleOnboarding = (onboardings?: OnboardingStep[]) => {
	return onboardings?.find((item) => item.step === "role");
};

export const getSelectSpecialtyOnboarding = (
	onboardings?: OnboardingStep[],
) => {
	return onboardings?.find((item) => item.step === "specialty");
};

export const getSelectAvatarOnboarding = (onboardings?: OnboardingStep[]) => {
	return onboardings?.find((item) => item.step === "avatar");
};

export const getSelectCountryOnboarding = (onboardings?: OnboardingStep[]) => {
	return onboardings?.find((item) => item.step === "country");
};

export const getCollectNameOnboarding = (onboardings?: OnboardingStep[]) => {
	return onboardings?.find((item) => item.step === "name");
};

export const getClerkPublishableKey = () => {
	const app = useAppStore.getState().app;
	const publishableKey = isDev
		? app.keys.clerk?.publishableKeyDev
		: app.keys.clerk?.publishableKeyProd;
	if (!publishableKey) {
		throw new Error("Clerk publishable key not found");
	}
	return publishableKey;
};
