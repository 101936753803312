import { useClerk } from "@clerk/clerk-expo";
import { useMagicLinkTokens } from "@features/auth/hooks/useMagicLinkTokens";
import { captureErrorToSentry } from "@helpers/sentry-logger";
import useClerkUser from "@hooks/useClerkUser";
import { Stack } from "expo-router";
import { useEffect } from "react";

export default function Layout() {
	const { isLoaded } = useClerkUser();
	const { sessionId } = useMagicLinkTokens();

	const { setActive } = useClerk();

	useEffect(() => {
		async function verify() {
			try {
				if (sessionId) {
					await setActive({
						session: sessionId,
					});
				}
			} catch (err: unknown) {
				captureErrorToSentry(new Error("Email link verification error"), {
					error: JSON.stringify(err),
				});
			}
		}
		verify();
	}, [sessionId, setActive]);
	if (!isLoaded) {
		return null;
	}

	return <Stack />;
}
