import type { Article } from "../../../../../types";

export const Article573: Article = {
	id: 573,
	rsuiteId: "dc9062df-67b7-434a-bc2e-09cc8339edd0",
	type: "scientific article",
	title:
		"Preoperative Nutrition Impacts Retear Rate After Arthroscopic Rotator Cuff Repair",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=dc9062df-67b7-434a-bc2e-09cc8339edd0&type=jpeg&name=JBJS.23.01189f1",
	subSpecialties: ["shoulder_elbow"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Preoperative Nutrition Impacts Retear Rate After
                Arthroscopic Rotator Cuff Repair</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Hitoshi
                      Shitara, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Tsuyoshi
                      Ichinose, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 10 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_47" class="annotation strong"
                          style="position: unset;">Background:</span> A rotator cuff retear following arthroscopic
                        rotator cuff repair (ARCR) is a concern in older patients. However, only a few of its risk
                        factors are amenable to preoperative intervention. We aimed to elucidate the relationship
                        between preoperative nutritional status and rotator cuff retears after ARCR.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_48" class="annotation strong"
                          style="position: unset;">Methods:</span> This single-center retrospective study included
                        patients aged ≥65 years with rotator cuff tears who underwent ARCR. The Geriatric Nutritional
                        Risk Index (GNRI) was used to assess preoperative nutritional status. Data collection
                        encompassed patient demographics, clinical assessments, and surgical specifics. Patients were
                        divided into healed and retear groups based on 2-year post-ARCR magnetic resonance imaging
                        results. Logistic regression analysis was conducted to adjust for confounding factors and detect
                        independent risk factors for retears. The GNRI cutoff value for retear prediction was determined
                        by a stratum-specific likelihood ratio; clinical outcomes were compared based on the cutoff
                        values obtained.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_49" class="annotation strong"
                          style="position: unset;">Results:</span> Overall, 143 patients were included. The retear rate
                        was 20.3%. The albumin level, GNRI, postoperative shoulder strength of abduction and external
                        rotation, and postoperative Japanese Orthopaedic Association and Constant scores in the retear
                        group were significantly lower than those in the healed group. The logistic regression analysis
                        showed that low risk of morbidity and mortality (compared with no risk) based on the GNRI (odds
                        ratio [OR], 3.39) and medial-lateral tear size per mm (OR = 1.10) were independent risk factors
                        for a retear 2 years after ARCR. Stratum-specific likelihood ratio analysis identified
                        data-driven strata as GNRI &lt; 103, 103 ≤ GNRI &lt; 109, and GNRI ≥ 109. Univariate analysis
                        showed that patients with GNRI &lt; 103 had a significantly higher retear risk than those with
                        103 ≤ GNRI &lt; 109 and those with GNRI ≥ 109. Logistic regression analysis showed that GNRI
                        &lt; 103 compared with 103 ≤ GNRI &lt; 109 (OR = 3.88) and GNRI &lt; 103 compared with GNRI ≥
                        109 (OR = 5.62), along with the medial-lateral tear size per mm (OR = 1.10), were independent
                        risk factors for a retear at 2 years after ARCR.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_50" class="annotation strong"
                          style="position: unset;">Conclusions:</span> When assessing the risk of a retear after ARCR,
                        GNRI ≥ 103 may indicate good preoperative nutritional status. However, more data are essential
                        to ascertain the importance of this finding.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_51" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Rotator cuff tears (RCTs) are prevalent shoulder disorders
                  whose prevalence increases with age, affecting approximately 25%, 45%, and 50% of 60-, 70-, and
                  80-year-olds, respectively<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  Arthroscopic rotator cuff repair (ARCR) effectively manages this condition by restoring shoulder
                  function and relieving pain<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">3</span></a>. However, despite the popularity of ARCR<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">5</span></a>,
                  postoperative rotator cuff retears remain a concern, with the post-ARCR retear rate ranging from 11%
                  to 94%<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">6</span></a>. Risk
                  factors include a high body mass index (BMI), diabetes, low bone mineral density (BMD), and vitamin-D
                  deficiency<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">8</span></a>. However, it is challenging to address these factors before
                  surgery because only a few of them are amenable to preoperative intervention or improvement.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Elderly patients often exhibit malnutrition and frailty,
                  which often coexist and are intricately interconnected<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">12</span></a>. Preoperative malnutrition can lead to poor outcomes and
                  complications after surgery<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">13</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">16</span></a>. However, the relationship between preoperative nutritional
                  status and postoperative outcomes of ARCR for RCTs remains unclear. If preoperative nutrition affects
                  healing after ARCR, addressing preoperative malnutrition could help prevent retears. Preoperative
                  nutrition management may be a potential target for preventing retears. The Geriatric Nutritional Risk
                  Index (GNRI) is a proven tool for evaluating an individual’s nutritional status, particularly in
                  geriatric and surgical populations<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">13</span></a>.
                  Previous studies have investigated the relationship between other surgical procedures and preoperative
                  malnutrition<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">13</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">16</span></a>. We hypothesized that patients with a low GNRI would have a
                  higher prevalence of postoperative retears even after adjusting for confounding factors.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">In the present study, we aimed to elucidate the potential
                  role of nutrition as a modifiable factor in reducing retear rates and improving the overall outcomes
                  of RCT surgery by investigating the relationship between preoperative nutritional status and
                  postoperative retear occurrence.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Patients</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">This retrospective study included patients with RCTs who
                  underwent ARCR at Gunma University Hospital from April 2010 to December 2017. Inclusion criteria were
                  (1) age ≥ 65 years<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">17</span></a>,
                  (2) available blood test data including serum albumin levels within 1 month before ARCR, (3) available
                  record of height and weight at the time of ARCR admission, (4) follow-up ≥ 2 years, and (5) magnetic
                  resonance imaging (MRI) evaluation at 2 years after ARCR. Exclusion criteria were (1) previous
                  shoulder surgery; (2) concomitant traumatic injury such as proximal humeral fracture; (3) degenerative
                  arthritis, rheumatoid arthritis of the glenohumeral joint, or adhesive capsulitis; (4) ARCR with
                  superior capsular reconstruction; (5) patch augmentation for irreparable large or massive RCTs; and
                  (6) isolated subscapularis tears. Patients were divided into healed and retear groups according to the
                  results of the MRI evaluation 2 years after ARCR.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The study was designed and conducted according to the
                  ethical principles of the Declaration of Helsinki. Our institutional review board approved the
                  research protocol (HS2022-070). Informed consent was obtained using an opt-out method.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Technique</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The surgeons utilized the beach chair position with an arm
                  positioner (SPIDER; Smith & Nephew) to perform ARCR. As in our previous study<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">18</span></a>, they inserted the arthroscope through a standard posterior
                  portal into the glenohumeral joint. Subsequently, an anterior portal was created through the rotator
                  interval. Depending on the tear characteristics, an anterolateral portal, posterolateral portal,
                  and/or anchor portal were created to visualize and repair the rotator cuff from the bursal side. The
                  tear size, based on the Cofield classification<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">19</span></a>,
                  determined the repair approach, with a single-row repair used for small tears and a double-row or
                  suture bridge approach used for medium, large, and massive tears. As stated, cases with superior
                  capsular reconstruction or patch augmentation were excluded.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Postoperative Rehabilitation Protocols</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">As in our previous study<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">18</span></a>, an immobilizing sling was applied immediately after the
                  surgery. Pendulum exercise for the shoulder and passive range-of-motion forearm exercises were
                  initiated on postoperative day 2. The subsequent stages of the rehabilitation program were determined
                  based on the RCT size. Active range-of-motion and strength exercises were initiated at 4 and 6 weeks
                  after surgery for medium or small tears. For large or massive tears, active range-of-motion and
                  strength exercises were initiated at 6 and 8 weeks after surgery, respectively. High-demand physical
                  activities such as sports or heavy labor were allowed 6 months after surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">GNRI</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The GNRI was calculated as GNRI = 14.89 × serum albumin
                  level (g/dL) + 41.7 × BMI/22 and then used for group comparisons<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">13</span></a>. The serum albumin level was measured within 1 month before
                  ARCR; weight and height were measured on hospital admission (i.e., the first or second day before
                  ARCR).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Collection</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Preoperative demographic data included age, affected side,
                  tear type (degenerative or traumatic), serum albumin level, GNRI, global fatty degeneration index
                  (GFDI; the mean value of the Goutallier stages for the subscapularis, supraspinatus, and infraspinatus
                  muscles)<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">20</span></a>,
                  and critical shoulder angle<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">21</span></a>.
                  Factors including diabetes<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">22</span></a>,
                  osteoporosis<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">23</span></a>,
                  and hyperlipidemia<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">24</span></a>
                  were assessed for their association with postoperative retears. Diabetes was evaluated through blood
                  glucose levels. Osteoporosis screening was performed on women aged ≥65 years by dual x-ray
                  absorptiometry (DXA). The tear size was measured intraoperatively. The technique used for the ARCR
                  (single-row, double-row, or suture bridge repair) was determined from the surgical records.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Shoulder pain at rest, during motion, and at night was
                  evaluated using the visual analog scale (VAS) during the clinical evaluations. The active ranges of
                  shoulder flexion, abduction, and external rotation were measured using a goniometer (TODAI Angle
                  Meter; Yasuda Seisakusho, Tokyo, Japan)<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">26</span></a>. Internal rotation was measured by determining the highest
                  spinal segment (or distal level) the patient could reach with the thumb. Statistical analysis of
                  internal rotation was conducted by converting the levels that could be reached into numbers ranging
                  from 1 to 20 as follows: T1-T12, 1 through 12; L1-L5, 13 through 17; sacrum, 18; buttock, 19; and
                  trochanter, 20<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">27</span></a>. The shoulder strength of abduction at 90° with the elbow
                  extended and shoulder strength of external rotation at 0° with the elbow flexed to 90° were measured
                  quantitatively using a PowerTrack II Commander handheld dynamometer (JTECH Medical)<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">29</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Constant and Japanese Orthopaedic Association (JOA) scores
                  were used for the shoulder assessment. All clinical and radiographic evaluations, including MRI and
                  radiography, were performed before and 2 years after ARCR; Sugaya classification types IV and V were
                  considered postoperative retears<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">30</span></a>.
                  The interrater validity and reliability of the goniometer, handheld dynamometer, and MRI evaluations
                  were previously established<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">31</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">32</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Statistical analyses were performed using IBM SPSS
                  Statistics (version 28; IBM Japan) or Stata/SE (version 15; StataCorp); an alpha level of p &lt; 0.05
                  was used. Differences in continuous variables were analyzed using the t test or Mann-Whitney U test.
                  The Pearson chi-square test or Fisher exact test was used to evaluate differences in categorical
                  variables. Logistic regression analysis was performed to adjust for confounding factors, detect
                  independent risk factors for retears after ARCR, and calculate odds ratios (ORs) and 95% confidence
                  intervals (CIs). In addition to the GNRI as an explanatory variable, other variables were selected
                  based on the univariate analyses (p &lt; 0.05)<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">33</span></a> and
                  a previous study<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">7</span></a> on
                  retears after ARCR. Potential explanatory variables were evaluated by correlation analysis to avoid
                  multicollinearity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The variance inflation factor (VIF) confirmed variable
                  interaction; a VIF of &gt;10 indicated high correlation with other independent variables<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">34</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Stratum-specific likelihood ratio (SSLR) analysis was
                  employed to establish data-driven strata for the GNRI. This analysis, widely used in the orthopaedic
                  literature<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">35</span></a><span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">39</span></a>, offers more flexibility than using receiver operating
                  characteristic curves<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">40</span></a>.
                  Following the creation of strata, both univariate and logistic regression analyses were conducted to
                  validate the usefulness of GNRI cutoff values for clinical assessment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Patients</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Overall, 154 patients met the inclusion criteria; however,
                  11 were excluded because of previous shoulder surgery (n = 2), rheumatoid arthritis (n = 1), superior
                  capsular reconstruction (n = 2), patch augmentation (n = 3), or isolated subscapular tears (n = 3).
                  Thus, 143 patients were enrolled (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>).
                  Clinical follow-up and MRI were performed at 2 years post-ARCR. The retear rate was 20.3% (29 of 143).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01189f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=dc9062df-67b7-434a-bc2e-09cc8339edd0&type=jpeg&name=JBJS.23.01189f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;">Patient enrollment flowchart. ARCR = arthroscopic
                          rotator cuff repair, OA = osteoarthritis, RA = rheumatoid arthritis, GHj = glenohumeral joint.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Univariate
                Analyses of Demographic and Clinical Data</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Age, the GFDI, and the medial-lateral and
                  anterior-posterior tear sizes in the retear group were significantly greater than those in the healed
                  group. Additionally, the albumin level, GNRI, postoperative shoulder strength of abduction and
                  external rotation, and postoperative JOA and Constant scores in the retear group were significantly
                  lower than those in the healed group. No significant differences in the remaining parameters were
                  observed between the groups (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Comparison
                Between Preoperative and 2-Year Postoperative Clinical Evaluations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">The preoperative and 2-year postoperative clinical
                  evaluations were compared in each group to assess improvement after ARCR. Both groups had significant
                  improvement in the VAS score for pain during motion; the ranges of shoulder abduction, external
                  rotation, and internal rotation; and the shoulder strength of abduction and external rotation at 2
                  years after ARCR. Only the healed group had significant improvement in the VAS score for pain at
                  night, range of shoulder flexion, JOA score, and Constant score at 2 years after ARCR (p &lt; 0.001).
                  Finally, there was no significant change in the VAS score for pain at rest in either group at 2 years
                  following ARCR (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Logistic
                Regression Analysis of Groups Based on Original GNRI Strata</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">The GNRI category (no, low, moderate, and major risk of
                  morbidity and mortality)<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">13</span></a>,
                  age, and medial-lateral tear size were selected as explanatory variables. The logistic regression
                  analysis showed that low nutrition-related risk, compared with no nutrition-related risk, and
                  medial-lateral tear size per mm were independent risk factors for a retear at 2 years after ARCR; age
                  per year was not an independent risk factor. Sensitivity analyses (not reported) did not reveal
                  differences in outcomes compared with the outcomes of the logistic regression analysis. There was no
                  distortion due to multicollinearity (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">SSLR
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">SSLR analysis identified the data-driven strata as GNRI
                  &lt; 103, 103 ≤ GNRI &lt; 109, and GNRI ≥ 109. Univariate analysis revealed significant differences
                  between the healed and retear groups across these categories. Patients with GNRI &lt; 103 had a
                  significantly higher retear risk compared with patients with 103 ≤ GNRI &lt; 109 and those with GNRI ≥
                  109 (<a href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>). However, there was no significant difference in the retear
                  rate between patients with 103 ≤ GNRI &lt; 109 and those with GNRI ≥ 109.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Logistic
                Regression Analysis of Groups Based on Data-Driven GNRI Strata</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Logistic regression analysis showed that GNRI &lt; 103
                  compared with 103 ≤ GNRI &lt; 109, and GNRI &lt; 103 compared with GNRI ≥ 109, along with the
                  medial-lateral tear size per mm were independent risk factors for retears at 2 years after ARCR; age
                  was not an independent risk factor (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_30" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">The study found that a low GNRI was an independent risk
                  factor for a retear at 2 years after ARCR, after adjusting for confounding factors mentioned in
                  previous studies, and this result was validated by sensitivity analyses. This confirms our study
                  hypothesis. When using the original GNRI category and the data-driven GNRI strata in this study, we
                  found the optimal cutoff value for the GNRI for predicting retear at 2 years after ARCR to be 103. To
                  our knowledge, this is the first study to identify malnutrition as a risk factor for retears after
                  ARCR in patients with RCTs at an age of ≥65 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Relationship
                Between Malnutrition and Postoperative Outcomes or Complications</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Fang et al.<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">41</span></a>
                  showed that a low GNRI independently predicted adverse outcomes after total joint arthroplasty,
                  including 30-day readmission, revision surgery, and increased length of stay. GNRI utilization could
                  uncover hidden instances of malnutrition that would remain undetected when relying solely on plasma
                  albumin levels. To our knowledge, the relationship between the GNRI and clinical outcomes of ARCR has
                  not been previously investigated. In the present study, when we compared our findings with the
                  definition of malnutrition used by Flamant et al.<a href="" data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">42</span></a> and
                  Garcia et al.<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">43</span></a>,
                  who considered an albumin level of &lt;3.5 g/dL to indicate malnutrition, we observed that both the
                  healed group (average albumin level [and standard deviation]: 4.2 ± 0.3 g/dL) and the retear group
                  (average albumin level: 4.0 ± 0.3 g/dL) fell within a range that did not meet their criterion for
                  malnutrition. Future studies must determine whether 3.5 mg/dL of albumin is too low a threshold or
                  whether measurement of albumin alone is not enough to predict a rotator cuff retear.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Optimal GNRI
                Cutoff Values as a Risk Factor for Retear After ARCR</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Patients have previously been categorized into 4 groups
                  based on GNRI values: major risk of morbidity and mortality (GNRI &lt; 82), moderate risk (82 to
                  &lt;92), low risk (92 to ≤98), and no risk (&gt;98)<a href="" data-id="citation_reference_47"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">13</span></a>.
                  Although these criteria can be used to assess the nutrition-related risk in older patients<a href=""
                    data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_35"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">16</span></a>, they may need to be modified to determine the optimal
                  cutoff GNRI value for predicting a rotator cuff retear following ARCR because patients eligible for
                  ARCR surgery typically maintain good overall health despite their older age. Additionally,
                  intra-articular soft tissues such as the rotator cuff are known to not heal as reliably as
                  extra-articular structures such as the distal biceps and Achilles tendon<a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">44</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">In this study, there were no patients with major (GNRI:
                  &lt;82) or moderate (GNRI: 82 to &lt;92) nutrition-related risk according to the original GNRI
                  categories<a href="" data-id="citation_reference_51"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript" style="position: unset;">13</span></a>.
                  After adjusting for confounding factors, patients with a low risk (GNRI: 92 to ≤98) according to the
                  original GNRI categories were found to have a 3.4-fold higher risk of a retear than those with no risk
                  (GNRI: &gt;98) according to the original GNRI categories. When classified by our data-driven strata,
                  patients with GNRI &lt; 103 had a 3.9- and 5.6-fold higher risk of retear than those with 103 ≤ GNRI
                  &lt; 109 and those with GNRI ≥ 109, respectively. When comparing the original GNRI category and the
                  data-driven GNRI strata in this study, GNRI &lt; 103 emerged as a more effective cutoff value for
                  predicting retears because GNRI &lt; 98 may be too tolerant. However, more data are needed to
                  determine the importance of this finding.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_27" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Risk Factors
                Associated with Postoperative Retear</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">In a meta-analysis and systematic review, the risk factors
                  for retears after ARCR were age; BMI; diabetes; subscapularis and infraspinatus fatty infiltration;
                  symptom duration; BMD; tear length, width, area, and amount of retraction; critical shoulder angle;
                  acromiohumeral interval; distance from the musculotendinous junction to the glenoid; operative
                  duration; a biceps procedure; and postoperative University of California at Los Angeles (UCLA)
                  shoulder score<a href="" data-id="citation_reference_52"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript" style="position: unset;">7</span></a>.
                  Hyperlipidemia<a href="" data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">45</span></a><span data-id="superscript_39"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">46</span></a> and osteoporosis<a href="" data-id="citation_reference_55"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_40" class="annotation superscript" style="position: unset;">47</span></a>
                  were also reported as risk factors associated with postoperative rotator cuff retears. In the present
                  study, data were obtained for most of those confounders and adjusted to reduce bias.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_28" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Limitations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">This study had limitations, including its retrospective
                  nature and small sample size. Despite concerns about selection bias, no clinically relevant
                  demographic differences were found between sample groups. Using propensity scoring for validation
                  mitigated this limitation’s potential impact. Surgical bias was minimized by having a senior surgeon
                  present during the ARCRs performed by the 5 surgeons. Tear sizes were based on the Cofield
                  classification before technique selection, reducing variability. Patients with &lt;2 years of
                  follow-up were excluded to avoid selection bias, but this was not expected to have a significant
                  impact on preoperative nutritional status. Moreover, there were no missing data regarding preoperative
                  laboratory tests, height, weight, pre- and postoperative clinical evaluation, or MRI for the patients
                  followed for 2 years. Therefore, the results can be deemed reliable. Osteoporosis screening was
                  limited to female patients aged ≥65 years. As no patients in the retear group were diagnosed with
                  osteoporosis, this limitation is unimportant. This study did not include potential risk factors for
                  rotator cuff retears such as vitamin-D deficiency. However, there is a lack of high-level evidence
                  supporting a relationship between preoperative vitamin-D deficiency and higher retear rates after
                  ARCR. Although a retrospective study<a href="" data-id="citation_reference_56"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_41" class="annotation superscript" style="position: unset;">8</span></a>
                  suggested a relationship, the impact on postoperative rotator cuff healing remains unclear due to
                  unadjusted confounding factors in that study. Thus, this limitation is unlikely to impact the present
                  study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_29" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">This study identified a lower GNRI as an independent risk
                  factor for retears at 2 years after ARCR, even after adjusting for previously identified confounding
                  factors. A GNRI threshold of ≥103 should be considered when assessing a patient’s risk of a retear
                  after ARCR. However, additional data are needed to delineate the importance of this finding.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank Takeaki Kawai, Yu Yamanaka, Tatsunobu Ogino,
                  and Shunya Suzuki, who are medical students at Gunma University Faculty of Medicine, for organizing
                  the database for this study. They also thank Editage (<a href="http://www.editage.com" target="_blank"
                    data-id="link_3" class="link" style="position: unset;">www.editage.com</a>) for English-language
                  editing.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_40" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Univariate Analyses of Demographic and Clinical
                          Data<span data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 3083px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Healed (N
                          = 114)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Retear (N
                          = 29)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_49" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69.8 ±
                          3.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73.0 ±
                          5.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex: male/female</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">72/42</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">22/7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.273</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Affected side</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right/left</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">68/46</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20/9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.400</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text"
                          style="position: unset;"> Dominant/nondominant</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">71/43</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">21/8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.387</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Tear type:
                          degenerative/traumatic</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">58/56</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15/14</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.935</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Height <span
                            data-id="emphasis_50" class="annotation emphasis"
                            style="position: unset;">(cm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">159.2 ±
                          8.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">159.3 ±
                          7.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.951</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Weight <span
                            data-id="emphasis_51" class="annotation emphasis"
                            style="position: unset;">(kg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62.3 ±
                          10.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59.9 ±
                          9.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.242</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI <span
                            data-id="emphasis_52" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_53" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_42" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_42"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_54"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.5 ±
                          2.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.5 ±
                          2.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.096</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Albumin level <span
                            data-id="emphasis_55" class="annotation emphasis"
                            style="position: unset;">(g/dL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.2 ±
                          0.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.0 ±
                          0.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.001<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">GNRI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">109.3 ±
                          6.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">104.8 ±
                          6.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.001<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">GNRI risk category<a
                            href="" data-id="citation_reference_57"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_43" class="annotation superscript"
                              style="position: unset;">13</span></a><span data-id="superscript_43"
                            class="annotation superscript" style="position: unset;"></span>:
                          no/low/moderate/major</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">107/7/0/0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">24/5/0/0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.054</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Related
                          comorbidities</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Casual blood glucose
                          level <span data-id="emphasis_56" class="annotation emphasis"
                            style="position: unset;">(mg/dL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">114.4 ±
                          31.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">115.7 ±
                          34.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.841</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hyperlipidemia</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (5.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.207</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Osteoporosis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (2.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.377</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative imaging
                          studies</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Global fatty
                          degeneration index</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6 ±
                          0.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9 ±
                          0.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.003<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Critical shoulder angle
                          <span data-id="emphasis_57" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35.4 ±
                          3.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36.4 ±
                          4.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.206</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ARCR procedure:
                          single-row/double-row/suture bridging</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14/11/89</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5/2/22</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.728</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Tear size <span
                            data-id="emphasis_58" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medial-lateral</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.5 ±
                          9.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.4 ±
                          10.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_5"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text"
                          style="position: unset;"> Anterior-posterior</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.4 ±
                          11.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.8 ±
                          12.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_6"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative clinical
                          evaluation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pain VAS <span
                            data-id="emphasis_59" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  At rest</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.1 ±
                          18.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.1 ±
                          21.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.202</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  During motion</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55.3 ±
                          25.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51.2 ±
                          26.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.447</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  At night</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37.1 ±
                          28.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.9 ±
                          23.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.588</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ROM</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Flexion <span
                            data-id="emphasis_60" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">113.8 ±
                          34.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">110.5 ±
                          40.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.667</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Abduction <span
                            data-id="emphasis_61" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">110.5 ±
                          38.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">114.0 ±
                          47.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.680</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  External rotation <span
                            data-id="emphasis_62" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39.7 ±
                          18.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.2 ±
                          21.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.038<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Internal rotation<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.9 ±
                          3.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.0 ±
                          3.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.234</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Strength <span
                            data-id="emphasis_63" class="annotation emphasis"
                            style="position: unset;">(kgf)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Abduction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.6 ±
                          1.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.7 ±
                          1.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.938</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  External
                          rotation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5 ±
                          2.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.6 ±
                          2.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.052</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Clinical scores</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  JOA score</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63.6 ±
                          10.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62.1 ±
                          12.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.514</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Constant score</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46.4 ±
                          14.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47.0 ±
                          16.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.836</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postoperative clinical
                          evaluation at 2 yr</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pain VAS <span
                            data-id="emphasis_64" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  At rest</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.6 ±
                          55.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.0 ±
                          25.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.956</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  During motion</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.0 ±
                          14.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.4 ±
                          20.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.072</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  At night</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.8 ±
                          7.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.3 ±
                          11.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.165</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ROM</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Flexion <span
                            data-id="emphasis_65" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">148.7 ±
                          15.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">146.8 ±
                          12.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.535</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Abduction <span
                            data-id="emphasis_66" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">150.2 ±
                          16.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">147.9 ±
                          20.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.526</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  External rotation <span
                            data-id="emphasis_67" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48.5 ±
                          17.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46.1 ±
                          21.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.519</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Internal rotation<span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.7 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.3 ±
                          2.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.292</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Strength <span
                            data-id="emphasis_68" class="annotation emphasis"
                            style="position: unset;">(kgf)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Abduction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.3 ±
                          2.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.0 ±
                          1.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.007<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  External
                          rotation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.7 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.3 ±
                          2.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.013<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Clinical scores</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  JOA score</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94.8 ±
                          6.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90.2 ±
                          10.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.029<span
                            data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Constant score</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80.9 ±
                          9.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75.1 ±
                          11.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.004<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Continuous variables are expressed as the mean</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;"></b> <span class="text"
                        style="position: unset;">± standard deviation. Categorical variables as expressed as the number
                        of patients, with or without the percentage in parentheses. BMI = body mass index, GNRI =
                        Geriatric Nutritional Risk Index (major risk = GNRI &lt;82, moderate risk = 82 to &lt;92, low
                        risk = 92 to ≤98, no risk = &gt;98), VAS = visual analog scale, ROM = range of motion, JOA =
                        Japanese Orthopaedic Association.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">A significant difference.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The level that the patient could reach with the thumb was converted
                        into a number from 1 to 20 as follows: T1-T12: 1 to 12, L1-L5: 13 to 17, sacrum: 18, buttock:
                        19, and trochanter: 20.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_41" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Comparison of Preoperative and Postoperative
                          Clinical Evaluations in Each Group<span data-id="table_footnote_reference_31"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 678px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value for Preop. to Postop. Change</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Healed (N
                          = 114)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Retear (N
                          = 29)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pain VAS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> At rest</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.137</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.605</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> During motion</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_15"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.002<span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> At night</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_17"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.247</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ROM</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Flexion</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_18"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.058</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Abduction</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_19"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.026<span
                            data-id="table_footnote_reference_20" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> External rotation</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_21"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_22"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Internal rotation</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_23"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.011<span
                            data-id="table_footnote_reference_24" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Strength</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Abduction</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_25"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.045<span
                            data-id="table_footnote_reference_26" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> External rotation</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_27"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.007<span
                            data-id="table_footnote_reference_28" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Clinical scores</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> JOA score</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_29"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.229</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Constant score</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_30"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.302</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">VAS = visual analog scale, ROM = range of motion, JOA = Japanese
                        Orthopaedic Association.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">A significant difference.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_42" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Logistic Regression Analysis of Factors Associated
                          with Retears 2 Years After ARCR<span data-id="table_footnote_reference_34"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 278px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">OR</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          CI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">VIF</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">GNRI (low risk vs. no
                          risk)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.39</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.22-9.41</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.019<span
                            data-id="table_footnote_reference_32" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.061</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age (per yr)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.10</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.98-1.24</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.096</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.108</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medial-lateral tear size
                          (per mm)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.10</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.05-1.16</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_33"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.051</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">OR = odds ratio, CI = confidence interval, VIF = variance inflation
                        factor, GNRI = Geriatric Nutritional Risk Index.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">A significant difference.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_43" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Univariate Analysis of Association Between
                          Data-Driven GNRI Strata and Retears at 2 Years After ARCR<span
                            data-id="table_footnote_reference_38" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 449.439px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.950867);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Data-Driven
                          GNRI Strata</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Healed (N = 114)</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Retear (N = 29)</span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Overall</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">GNRI &lt;
                          103<br data-id="break_1" style="position: unset;">Vs.<br data-id="break_2"
                            style="position: unset;">103 ≤ GNRI &lt; 109</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">GNRI &lt;
                          103<br data-id="break_3" style="position: unset;">Vs. GNRI ≥ 109</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">103 ≤ GNRI
                          &lt; 109<br data-id="break_4" style="position: unset;">Vs. GNRI ≥ 109</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">GNRI &lt; 103/103 ≤ GNRI
                          &lt; 109/GNRI ≥ 109</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14/44/56</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13/9/7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_35"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.007<span
                            data-id="table_footnote_reference_36" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_37"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.424</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">GNRI = Geriatric Nutritional Risk Index.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">A significant difference.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_44" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Logistic Regression Analysis of the Association of
                          Data-Driven GNRI Strata, Age, and Medial-Lateral Tear Size with Retears at 2 Years After
                          ARCR<span data-id="table_footnote_reference_43" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 243px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">OR</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          CI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Data-driven GNRI
                          strata</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.021<span
                            data-id="table_footnote_reference_39" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> GNRI &lt; 103 vs. 103 ≤
                          GNRI &lt; 109</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.88</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.14-13.16</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.030<span
                            data-id="table_footnote_reference_40" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> GNRI &lt; 103 vs. GNRI ≥
                          109</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.62</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.58-20.00</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.008<span
                            data-id="table_footnote_reference_41" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age (per yr)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.09</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.97-1.23</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.137</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medial-lateral tear size
                          (per mm)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.10</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.05-1.16</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_42"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">GNRI = Geriatric Nutritional Risk Index.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_12" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">A significant difference.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Yamamoto A</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Takagishi K</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Osawa T</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Yanagawa T</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Nakajima D</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Shitara H</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Kobayashi T</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Prevalence and risk factors of a
                  rotator cuff tear in the general population</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2010
                Jan;19(1):116-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20and%20risk%20factors%20of%20a%20rotator%20cuff%20tear%20in%20the%20general%20population&as_occt=title&as_sauthors=%20%22A%20Yamamoto%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Zhang Z</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Gu B</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Zhu W</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Zhu L</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Li Q</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Arthroscopic versus mini-open
                  rotator cuff repair: a prospective, randomized study with 24-month follow-up</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Eur J Orthop Surg
                  Traumatol.</span> 2014 Aug;24(6):845-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthroscopic%20versus%20mini-open%20rotator%20cuff%20repair%3A%20a%20prospective%2C%20randomized%20study%20with%2024-month%20follow-up&as_occt=title&as_sauthors=%20%22Z%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Ma HL</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Chiang ER</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Wu HT</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Hung SC</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Wang ST</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Liu CL</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Chen TH</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Clinical outcome and imaging of
                  arthroscopic single-row and double-row rotator cuff repair: a prospective randomized trial</span>.
                <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2012
                Jan;28(1):16-24.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20outcome%20and%20imaging%20of%20arthroscopic%20single-row%20and%20double-row%20rotator%20cuff%20repair%3A%20a%20prospective%20randomized%20trial&as_occt=title&as_sauthors=%20%22HL%20Ma%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> McElvany MD</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> McGoldrick E</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Gee AO</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Neradilek MB</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Matsen FA 3rd</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Rotator cuff repair: published
                  evidence on factors associated with repair integrity and clinical outcome</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2015
                Feb;43(2):491-500.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Rotator%20cuff%20repair%3A%20published%20evidence%20on%20factors%20associated%20with%20repair%20integrity%20and%20clinical%20outcome&as_occt=title&as_sauthors=%20%22MD%20McElvany%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Longo UG</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Salvatore G</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Rizzello G</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Berton A</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Ciuffreda M</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Candela V</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Denaro V</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">The burden of rotator cuff
                  surgery in Italy: a nationwide registry study</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Arch Orthop Trauma Surg.</span> 2017
                Feb;137(2):217-24.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20burden%20of%20rotator%20cuff%20surgery%20in%20Italy%3A%20a%20nationwide%20registry%20study&as_occt=title&as_sauthors=%20%22UG%20Longo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Le BT</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Wu XL</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Lam PH</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Murrell GA</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Factors predicting rotator cuff
                  retears: an analysis of 1000 consecutive rotator cuff repairs</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2014
                May;42(5):1134-42.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20predicting%20rotator%20cuff%20retears%3A%20an%20analysis%20of%201000%20consecutive%20rotator%20cuff%20repairs&as_occt=title&as_sauthors=%20%22BT%20Le%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Zhao J</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Luo M</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Pan J</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Liang G</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Feng W</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Zeng L</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Yang W</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Liu J</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Risk factors affecting rotator
                  cuff retear after arthroscopic repair: a meta-analysis and systematic review</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2021 Nov;30(11):2660-70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20affecting%20rotator%20cuff%20retear%20after%20arthroscopic%20repair%3A%20a%20meta-analysis%20and%20systematic%20review&as_occt=title&as_sauthors=%20%22J%20Zhao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Chen J</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Lou J</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Wang W</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Xu G</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Association of Preoperative
                  Vitamin D Deficiency With Retear Rate and Early Pain After Arthroscopic Rotator Cuff Repair: A
                  Retrospective Cohort Study</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">Orthop J Sports Med.</span> 2022 Oct 17;10(10):23259671221130315.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20of%20Preoperative%20Vitamin%20D%20Deficiency%20With%20Retear%20Rate%20and%20Early%20Pain%20After%20Arthroscopic%20Rotator%20Cuff%20Repair%3A%20A%20Retrospective%20Cohort%20Study&as_occt=title&as_sauthors=%20%22J%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Liang H</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Li X</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Lin X</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Ju Y</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Leng J</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">The correlation between
                  nutrition and frailty and the receiver operating characteristic curve of different nutritional indexes
                  for frailty</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">BMC Geriatr.</span> 2021 Nov 2;21(1):619.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20correlation%20between%20nutrition%20and%20frailty%20and%20the%20receiver%20operating%20characteristic%20curve%20of%20different%20nutritional%20indexes%20for%20frailty&as_occt=title&as_sauthors=%20%22H%20Liang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Gingrich A</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Volkert D</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Kiesswetter E</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Thomanek M</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Bach S</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Sieber CC</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Zopf Y</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Prevalence and overlap of
                  sarcopenia, frailty, cachexia and malnutrition in older medical inpatients</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">BMC Geriatr.</span> 2019
                Apr 27;19(1):120.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20and%20overlap%20of%20sarcopenia%2C%20frailty%2C%20cachexia%20and%20malnutrition%20in%20older%20medical%20inpatients&as_occt=title&as_sauthors=%20%22A%20Gingrich%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Lorenzo-López L</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Maseda A</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> de Labra C</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Regueiro-Folgueira L</span>,
                <span data-id="annotation_64" class="annotation" style="position: unset;"> Rodríguez-Villamil JL</span>,
                <span data-id="annotation_65" class="annotation" style="position: unset;"> Millán-Calenti JC</span>.
                <span data-id="strong_11" class="annotation strong" style="position: unset;">Nutritional determinants of
                  frailty in older adults: A systematic review</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">BMC Geriatr.</span> 2017 May
                15;17(1):108.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Nutritional%20determinants%20of%20frailty%20in%20older%20adults%3A%20A%20systematic%20review&as_occt=title&as_sauthors=%20%22L%20Lorenzo-L%C3%B3pez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Wei K</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Nyunt MS</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Gao Q</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Wee SL</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Yap KB</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Ng TP</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Association of Frailty and
                  Malnutrition With Long-term Functional and Mortality Outcomes Among Community-Dwelling Older Adults:
                  Results From the Singapore Longitudinal Aging Study 1</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">JAMA Netw Open.</span> 2018 Jul
                6;1(3):e180650.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20of%20Frailty%20and%20Malnutrition%20With%20Long-term%20Functional%20and%20Mortality%20Outcomes%20Among%20Community-Dwelling%20Older%20Adults%3A%20Results%20From%20the%20Singapore%20Longitudinal%20Aging%20Study%201&as_occt=title&as_sauthors=%20%22K%20Wei%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Bouillanne O</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Morineau G</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Dupont C</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Coulombel I</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Vincent JP</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Nicolis I</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Benazeth S</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Cynober L</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Aussel C</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Geriatric Nutritional Risk
                  Index: a new index for evaluating at-risk elderly medical patients</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">Am J Clin Nutr.</span> 2005
                Oct;82(4):777-83.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Geriatric%20Nutritional%20Risk%20Index%3A%20a%20new%20index%20for%20evaluating%20at-risk%20elderly%20medical%20patients&as_occt=title&as_sauthors=%20%22O%20Bouillanne%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Puvanesarajah V</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Jain A</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Kebaish K</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Shaffrey CI</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Sciubba DM</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> De la Garza-Ramos R</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Khanna AJ</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Hassanzadeh H</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Poor Nutrition Status and
                  Lumbar Spine Fusion Surgery in the Elderly: Readmissions, Complications, and Mortality</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2017 Jul 1;42(13):979-83.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Poor%20Nutrition%20Status%20and%20Lumbar%20Spine%20Fusion%20Surgery%20in%20the%20Elderly%3A%20Readmissions%2C%20Complications%2C%20and%20Mortality&as_occt=title&as_sauthors=%20%22V%20Puvanesarajah%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Byl NN</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Richards S</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Asturias J</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Intrarater and interrater
                  reliability of strength measurements of the biceps and deltoid using a hand held dynamometer</span>.
                <span data-id="emphasis_15" class="annotation emphasis" style="position: unset;">J Orthop Sports Phys
                  Ther.</span> 1988;9(12):395-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intrarater%20and%20interrater%20reliability%20of%20strength%20measurements%20of%20the%20biceps%20and%20deltoid%20using%20a%20hand%20held%20dynamometer&as_occt=title&as_sauthors=%20%22NN%20Byl%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Takasawa E</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Iizuka Y</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Ishiwata S</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Kakuta Y</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Inomata K</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Tomomatsu Y</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Ito S</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Honda A</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Mieda T</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Chikuda H</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Impact of the preoperative
                  nutritional status on postoperative kyphosis in geriatric patients undergoing cervical
                  laminoplasty</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Eur Spine J.</span> 2023 Jan;32(1):374-81.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impact%20of%20the%20preoperative%20nutritional%20status%20on%20postoperative%20kyphosis%20in%20geriatric%20patients%20undergoing%20cervical%20laminoplasty&as_occt=title&as_sauthors=%20%22E%20Takasawa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Jeong HJ</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Rhee SM</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Oh JH</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Postoperative New-Onset
                  Pseudoparalysis: A Retrospective Analysis of 430 Consecutive Arthroscopic Repairs for Large to Massive
                  Rotator Cuff Tears</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2018 Jun;46(7):1701-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Postoperative%20New-Onset%20Pseudoparalysis%3A%20A%20Retrospective%20Analysis%20of%20430%20Consecutive%20Arthroscopic%20Repairs%20for%20Large%20to%20Massive%20Rotator%20Cuff%20Tears&as_occt=title&as_sauthors=%20%22HJ%20Jeong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Hamano N</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Yamamoto A</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Shitara H</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Ichinose T</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Shimoyama D</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Sasaki T</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Kobayashi T</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Kakuta Y</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Osawa T</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Takagishi K</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Does successful rotator cuff
                  repair improve muscle atrophy and fatty infiltration of the rotator cuff? A retrospective magnetic
                  resonance imaging study performed shortly after surgery as a reference</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2017 Jun;26(6):967-74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20successful%20rotator%20cuff%20repair%20improve%20muscle%20atrophy%20and%20fatty%20infiltration%20of%20the%20rotator%20cuff%3F%20A%20retrospective%20magnetic%20resonance%20imaging%20study%20performed%20shortly%20after%20surgery%20as%20a%20reference&as_occt=title&as_sauthors=%20%22N%20Hamano%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Cofield RH</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Subscapular muscle
                  transposition for repair of chronic rotator cuff tears</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Surg Gynecol Obstet.</span> 1982
                May;154(5):667-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Subscapular%20muscle%20transposition%20for%20repair%20of%20chronic%20rotator%20cuff%20tears&as_occt=title&as_sauthors=%20%22RH%20Cofield%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Goutallier D</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Postel JM</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Gleyze P</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Leguilloux P</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Van Driessche S</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Influence of cuff muscle fatty
                  degeneration on anatomic and functional outcomes after simple suture of full-thickness tears</span>.
                <span data-id="emphasis_20" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2003 Nov-Dec;12(6):550-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Influence%20of%20cuff%20muscle%20fatty%20degeneration%20on%20anatomic%20and%20functional%20outcomes%20after%20simple%20suture%20of%20full-thickness%20tears&as_occt=title&as_sauthors=%20%22D%20Goutallier%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Moor BK</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Bouaicha S</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Rothenfluh DA</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Sukthankar A</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Gerber C</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Is there an association between
                  the individual anatomy of the scapula and the development of rotator cuff tears or osteoarthritis of
                  the glenohumeral joint?: A radiological study of the critical shoulder angle</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2013
                Jul;95-B(7):935-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20there%20an%20association%20between%20the%20individual%20anatomy%20of%20the%20scapula%20and%20the%20development%20of%20rotator%20cuff%20tears%20or%20osteoarthritis%20of%20the%20glenohumeral%20joint%3F%3A%20A%20radiological%20study%20of%20the%20critical%20shoulder%20angle&as_occt=title&as_sauthors=%20%22BK%20Moor%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Hong CK</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Chang CJ</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Kuan FC</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Hsu KL</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Chen Y</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Chiang CH</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Su WR</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Patients With Diabetes Mellitus
                  Have a Higher Risk of Tendon Retear After Arthroscopic Rotator Cuff Repair: A Meta-analysis</span>.
                <span data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Orthop J Sports
                  Med.</span> 2020 Nov 5;8(11):2325967120961406.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patients%20With%20Diabetes%20Mellitus%20Have%20a%20Higher%20Risk%20of%20Tendon%20Retear%20After%20Arthroscopic%20Rotator%20Cuff%20Repair%3A%20A%20Meta-analysis&as_occt=title&as_sauthors=%20%22CK%20Hong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Chung SW</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Oh JH</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Gong HS</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Kim JY</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Kim SH</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Factors affecting rotator cuff
                  healing after arthroscopic repair: osteoporosis as one of the independent risk factors</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2011 Oct;39(10):2099-107.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20affecting%20rotator%20cuff%20healing%20after%20arthroscopic%20repair%3A%20osteoporosis%20as%20one%20of%20the%20independent%20risk%20factors&as_occt=title&as_sauthors=%20%22SW%20Chung%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Garcia GH</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Liu JN</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Wong A</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Cordasco F</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Dines DM</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Dines JS</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Gulotta LV</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Warren R</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Hyperlipidemia increases the
                  risk of retear after arthroscopic rotator cuff repair</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2017
                Dec;26(12):2086-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hyperlipidemia%20increases%20the%20risk%20of%20retear%20after%20arthroscopic%20rotator%20cuff%20repair&as_occt=title&as_sauthors=%20%22GH%20Garcia%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Kobayashi T</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Takagishi K</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Shitara H</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Ichinose T</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Shimoyama D</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Yamamoto A</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Osawa T</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Tajika T</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Prevalence of and risk factors
                  for shoulder osteoarthritis in Japanese middle-aged and elderly populations</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2014 May;23(5):613-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20of%20and%20risk%20factors%20for%20shoulder%20osteoarthritis%20in%20Japanese%20middle-aged%20and%20elderly%20populations&as_occt=title&as_sauthors=%20%22T%20Kobayashi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Takagishi K</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Shitara H</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Kobayashi T</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Tajika T</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Ichinose T</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Shimoyama D</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Sasaki T</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Hamano N</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Osawa T</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Kamiyama M</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Miyamoto R</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Yamamoto A</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Chikuda H</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Risk factors for shoulder
                  osteoarthritis with rotator cuff tear in the elderly general population</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2022 Dec;31(12):2562-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20for%20shoulder%20osteoarthritis%20with%20rotator%20cuff%20tear%20in%20the%20elderly%20general%20population&as_occt=title&as_sauthors=%20%22K%20Takagishi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Lee JG</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Peo H</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Cho JH</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Cho CH</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Kim DK</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Kim DH</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Dynamic Ultrasonographic
                  Measurement of Inferior Joint Capsule Thickness in Patients with Unilateral Frozen Shoulder</span>.
                <span data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Diagnostics
                  (Basel).</span> 2021 May 18;11(5):898.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dynamic%20Ultrasonographic%20Measurement%20of%20Inferior%20Joint%20Capsule%20Thickness%20in%20Patients%20with%20Unilateral%20Frozen%20Shoulder&as_occt=title&as_sauthors=%20%22JG%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Shitara H</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Tajika T</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Kuboi T</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Ichinose T</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Sasaki T</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Hamano N</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Kamiyama M</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Yamamoto A</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Kobayashi T</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Takagishi K</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Chikuda H</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Shoulder stretching versus
                  shoulder muscle strength training for the prevention of baseball-related arm injuries: a randomized,
                  active-controlled, open-label, non-inferiority study</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">Sci Rep.</span> 2022 Dec
                21;12(1):22118.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Shoulder%20stretching%20versus%20shoulder%20muscle%20strength%20training%20for%20the%20prevention%20of%20baseball-related%20arm%20injuries%3A%20a%20randomized%2C%20active-controlled%2C%20open-label%2C%20non-inferiority%20study&as_occt=title&as_sauthors=%20%22H%20Shitara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Shitara H</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Yamamoto A</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Shimoyama D</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Ichinose T</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Sasaki T</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Hamano N</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Ueno A</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Endo F</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Oshima A</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Sakane H</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Tachibana M</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Tomomatsu Y</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Tajika T</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Kobayashi T</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Osawa T</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Iizuka H</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Takagishi K</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Shoulder Stretching
                  Intervention Reduces the Incidence of Shoulder and Elbow Injuries in High School Baseball Players: a
                  Time-to-Event Analysis</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">Sci Rep.</span> 2017 Mar 27;7:45304.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Shoulder%20Stretching%20Intervention%20Reduces%20the%20Incidence%20of%20Shoulder%20and%20Elbow%20Injuries%20in%20High%20School%20Baseball%20Players%3A%20a%20Time-to-Event%20Analysis&as_occt=title&as_sauthors=%20%22H%20Shitara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Sugaya H</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Maeda K</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Matsuki K</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Moriishi J</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Functional and structural
                  outcome after arthroscopic full-thickness rotator cuff repair: single-row versus dual-row
                  fixation</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2005 Nov;21(11):1307-16.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Functional%20and%20structural%20outcome%20after%20arthroscopic%20full-thickness%20rotator%20cuff%20repair%3A%20single-row%20versus%20dual-row%20fixation&as_occt=title&as_sauthors=%20%22H%20Sugaya%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Tajika T</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Kobayashi T</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Yamamoto A</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Kaneko T</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Shitara H</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Shimoyama D</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Iizuka Y</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Okamura K</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Yonemoto Y</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Warita T</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Ohsawa T</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Nakajima I</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Iizuka H</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Takagishi K</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">A clinical and ultrasonographic
                  study of risk factors for elbow injury in young baseball players</span>. <span data-id="emphasis_31"
                  class="annotation emphasis" style="position: unset;">J Orthop Surg (Hong Kong).</span> 2016
                Apr;24(1):45-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20clinical%20and%20ultrasonographic%20study%20of%20risk%20factors%20for%20elbow%20injury%20in%20young%20baseball%20players&as_occt=title&as_sauthors=%20%22T%20Tajika%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Shitara H</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Kobayashi T</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Yamamoto A</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Shimoyama D</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Ichinose T</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Tajika T</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Osawa T</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Iizuka H</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Takagishi K</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Prospective multifactorial
                  analysis of preseason risk factors for shoulder and elbow injuries in high school baseball
                  pitchers</span>. <span data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Knee
                  Surg Sports Traumatol Arthrosc.</span> 2017 Oct;25(10):3303-10.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prospective%20multifactorial%20analysis%20of%20preseason%20risk%20factors%20for%20shoulder%20and%20elbow%20injuries%20in%20high%20school%20baseball%20pitchers&as_occt=title&as_sauthors=%20%22H%20Shitara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Shitara H</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Tajika T</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Kuboi T</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Ichinose T</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Sasaki T</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Hamano N</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Endo F</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Kamiyama M</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Miyamoto R</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Nakase K</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Yamamoto A</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Kobayashi T</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Takagishi K</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Chikuda H</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Risk factor for elbow symptom
                  manifestation in young baseball players with asymptomatic medial elbow abnormalities: a prospective
                  cohort study</span>. <span data-id="emphasis_33" class="annotation emphasis"
                  style="position: unset;">Sci Rep.</span> 2021 Jun 23;11(1):13119.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factor%20for%20elbow%20symptom%20manifestation%20in%20young%20baseball%20players%20with%20asymptomatic%20medial%20elbow%20abnormalities%3A%20a%20prospective%20cohort%20study&as_occt=title&as_sauthors=%20%22H%20Shitara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Vittinghoff E</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Shiboski SC</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Glidden DV</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> McCulloch CE</span>. <span
                  data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Regression Methods in
                  Biostatistics.</span> New York: Springer; 2005.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Rocks MC</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Zhao AY</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Liu IZ</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Agarwal AR</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Harris AB</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Gu A</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Unger AS</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Thakkar SC</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">The Creation of Data-Driven
                  Preoperative Hemoglobin A1c and Same-Day Glucose Strata to Stratify Complication Risk Following Total
                  Hip Arthroplasty</span>. <span data-id="emphasis_35" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2024 Apr;39(4):1013-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Creation%20of%20Data-Driven%20Preoperative%20Hemoglobin%20A1c%20and%20Same-Day%20Glucose%20Strata%20to%20Stratify%20Complication%20Risk%20Following%20Total%20Hip%20Arthroplasty&as_occt=title&as_sauthors=%20%22MC%20Rocks%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Agarwal AR</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Harris AB</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Tarawneh O</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Malyavko A</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Kreulen RT</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Thakkar SC</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Doerre T</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Best MJ</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Delay of Timing of Anterior
                  Cruciate Ligament Reconstruction Is Associated With Lower Risk of Arthrofibrosis Requiring
                  Intervention</span>. <span data-id="emphasis_36" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2023 Jul;39(7):1682-1689.e2.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Delay%20of%20Timing%20of%20Anterior%20Cruciate%20Ligament%20Reconstruction%20Is%20Associated%20With%20Lower%20Risk%20of%20Arthrofibrosis%20Requiring%20Intervention&as_occt=title&as_sauthors=%20%22AR%20Agarwal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Wilson S</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Marx RG</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Pan TJ</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Lyman S</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Meaningful Thresholds for the
                  Volume-Outcome Relationship in Total Knee Arthroplasty</span>. <span data-id="emphasis_37"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2016 Oct
                19;98(20):1683-90.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1262709"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Mehta N</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Chamberlin P</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Marx RG</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Hidaka C</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Ge Y</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Nawabi DH</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Lyman S</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Defining the Learning Curve for
                  Hip Arthroscopy: A Threshold Analysis of the Volume-Outcomes Relationship</span>. <span
                  data-id="emphasis_38" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2018 May;46(6):1284-93.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Defining%20the%20Learning%20Curve%20for%20Hip%20Arthroscopy%3A%20A%20Threshold%20Analysis%20of%20the%20Volume-Outcomes%20Relationship&as_occt=title&as_sauthors=%20%22N%20Mehta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Koltsov JCB</span>, <span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Marx RG</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Bachner E</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> McLawhorn AS</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Lyman S</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Risk-Based Hospital and
                  Surgeon-Volume Categories for Total Hip Arthroplasty</span>. <span data-id="emphasis_39"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2018 Jul
                18;100(14):1203-8.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1734218"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Peirce JC</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Cornell RG</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Integrating stratum-specific
                  likelihood ratios with the analysis of ROC curves</span>. <span data-id="emphasis_40"
                  class="annotation emphasis" style="position: unset;">Med Decis Making.</span> 1993
                Apr-Jun;13(2):141-51.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Integrating%20stratum-specific%20likelihood%20ratios%20with%20the%20analysis%20of%20ROC%20curves&as_occt=title&as_sauthors=%20%22JC%20Peirce%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Fang CJ</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Saadat GH</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Butler BA</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Bokhari F</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">The Geriatric Nutritional Risk
                  Index Is an Independent Predictor of Adverse Outcomes for Total Joint Arthroplasty Patients</span>.
                <span data-id="emphasis_41" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span>
                2022 Aug;37(8S):S836-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Geriatric%20Nutritional%20Risk%20Index%20Is%20an%20Independent%20Predictor%20of%20Adverse%20Outcomes%20for%20Total%20Joint%20Arthroplasty%20Patients&as_occt=title&as_sauthors=%20%22CJ%20Fang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Flamant EM</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Goltz DE</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Burnett RA</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Wickman JR</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Belay ES</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Saltzman EB</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Nicholson GP</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Garrigues GE</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Lassiter T</span>, <span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Anakwenze OA</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Klifto CS</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Malnutrition in elective
                  shoulder arthroplasty: a multi-institutional retrospective study of preoperative albumin and adverse
                  outcomes</span>. <span data-id="emphasis_42" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2021 Nov;30(11):2491-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Malnutrition%20in%20elective%20shoulder%20arthroplasty%3A%20a%20multi-institutional%20retrospective%20study%20of%20preoperative%20albumin%20and%20adverse%20outcomes&as_occt=title&as_sauthors=%20%22EM%20Flamant%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Garcia GH</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Fu MC</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Dines DM</span>, <span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Craig EV</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Gulotta LV</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Malnutrition: a marker for
                  increased complications, mortality, and length of stay after total shoulder arthroplasty</span>. <span
                  data-id="emphasis_43" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2016 Feb;25(2):193-200.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Malnutrition%3A%20a%20marker%20for%20increased%20complications%2C%20mortality%2C%20and%20length%20of%20stay%20after%20total%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22GH%20Garcia%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Derwin KA</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Galatz LM</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Ratcliffe A</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Thomopoulos S</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Enthesis Repair: Challenges and
                  Opportunities for Effective Tendon-to-Bone Healing</span>. <span data-id="emphasis_44"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2018 Aug
                15;100(16):e109.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1764081"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Cancienne JM</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Brockmeier SF</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Rodeo SA</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Werner BC</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Perioperative Serum Lipid
                  Status and Statin Use Affect the Revision Surgery Rate After Arthroscopic Rotator Cuff Repair</span>.
                <span data-id="emphasis_45" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2017 Nov;45(13):2948-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Perioperative%20Serum%20Lipid%20Status%20and%20Statin%20Use%20Affect%20the%20Revision%20Surgery%20Rate%20After%20Arthroscopic%20Rotator%20Cuff%20Repair&as_occt=title&as_sauthors=%20%22JM%20Cancienne%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_308" class="annotation" style="position: unset;"> O’Donnell EA</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Fu MC</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> White AE</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Taylor SA</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Dines JS</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Dines DM</span>, <span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Warren RF</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Gulotta LV</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">The Effect of Patient
                  Characteristics and Comorbidities on the Rate of Revision Rotator Cuff Repair</span>. <span
                  data-id="emphasis_46" class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2020
                Sep;36(9):2380-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Effect%20of%20Patient%20Characteristics%20and%20Comorbidities%20on%20the%20Rate%20of%20Revision%20Rotator%20Cuff%20Repair&as_occt=title&as_sauthors=%20%22EA%20O%E2%80%99Donnell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Cancienne JM</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Brockmeier SF</span>, <span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Kew ME</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Deasey MJ</span>, <span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Werner BC</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">The Association of Osteoporosis
                  and Bisphosphonate Use With Revision Shoulder Surgery After Rotator Cuff Repair</span>. <span
                  data-id="emphasis_47" class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2019
                Aug;35(8):2314-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Association%20of%20Osteoporosis%20and%20Bisphosphonate%20Use%20With%20Revision%20Shoulder%20Surgery%20After%20Rotator%20Cuff%20Repair&as_occt=title&as_sauthors=%20%22JM%20Cancienne%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="videosummary_reference" class="annotation videosummary_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node videosummary" data-id="videoabstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Video Abstract</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6364751204112&quot;,&quot;type&quot;:&quot;video&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;dc9062df-67b7-434a-bc2e-09cc8339edd0&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_1051" data-video-id="6364751204112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-workinghover vjs-v7 vjs-user-active bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-1 vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors vjs-layout-small"
                      data-id="6364751204112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6364751204112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6364751204112" id="vjs_video_1051_html5_api" tabindex="-1" preload="auto"
                        data-account="2324982687001" data-player="HyP0JSARx" data-embed="default"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/jit/2324982687001/b27ed60a-827f-4ab5-8bdb-e9e9b5b37eff/main/1280x720/1m27s114ms/match/image.jpg"
                        src="blob:https://www.jbjs.org/c777db46-f39d-4c61-b16c-312cd1b4029d"></video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/jit/2324982687001/b27ed60a-827f-4ab5-8bdb-e9e9b5b37eff/main/1280x720/1m27s114ms/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display" translate="yes" aria-live="off" aria-atomic="true">
                        <div style="position: absolute; inset: 0px; margin: 1.5%;"></div>
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-mouse-display">
                                <div class="vjs-volume-tooltip" aria-hidden="true"></div>
                              </div>
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">2:54</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 2:54">
                            <div class="vjs-load-progress" style="width: 5.7%;"><span
                                class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">5.70%</span></span>
                              <div data-start="0" data-end="9.919999" style="left: 0%; width: 100%;"></div>
                            </div>
                            <div class="vjs-mouse-display">
                              <div class="vjs-time-tooltip" aria-hidden="true"></div>
                            </div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;">
                              <div class="vjs-time-tooltip" aria-hidden="true" style="right: 0px;">0:00</div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">2:54</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_1051_component_1354">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_1051_component_1354"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-texttrack-settings" tabindex="-1" role="menuitem"
                                aria-disabled="false"><span class="vjs-menu-item-text">captions settings</span><span
                                  class="vjs-control-text" aria-live="polite">, opens captions settings dialog</span>
                              </li>
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">captions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button" type="button"
                          title="Picture-in-Picture" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_1051_component_1569_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_1051_component_1569_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_1051_component_1575_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_1051_component_1575_description">Beginning of dialog window. Escape will cancel
                          and close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_1051_component_1575">Text</legend><label
                                id="captions-foreground-color-vjs_video_1051_component_1575"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_1051_component_1575 captions-foreground-color-vjs_video_1051_component_1575">
                                <option id="captions-foreground-color-vjs_video_1051_component_1575-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_1051_component_1575 captions-foreground-color-vjs_video_1051_component_1575 captions-foreground-color-vjs_video_1051_component_1575-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_1051_component_1575-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_1051_component_1575 captions-foreground-color-vjs_video_1051_component_1575 captions-foreground-color-vjs_video_1051_component_1575-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_1051_component_1575-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_1051_component_1575 captions-foreground-color-vjs_video_1051_component_1575 captions-foreground-color-vjs_video_1051_component_1575-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_1051_component_1575-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_1051_component_1575 captions-foreground-color-vjs_video_1051_component_1575 captions-foreground-color-vjs_video_1051_component_1575-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_1051_component_1575-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_1051_component_1575 captions-foreground-color-vjs_video_1051_component_1575 captions-foreground-color-vjs_video_1051_component_1575-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_1051_component_1575-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_1051_component_1575 captions-foreground-color-vjs_video_1051_component_1575 captions-foreground-color-vjs_video_1051_component_1575-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_1051_component_1575-Magenta"
                                  value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_1051_component_1575 captions-foreground-color-vjs_video_1051_component_1575 captions-foreground-color-vjs_video_1051_component_1575-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_1051_component_1575-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_1051_component_1575 captions-foreground-color-vjs_video_1051_component_1575 captions-foreground-color-vjs_video_1051_component_1575-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_1051_component_1575"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_1051_component_1575 captions-foreground-opacity-vjs_video_1051_component_1575">
                                  <option id="captions-foreground-opacity-vjs_video_1051_component_1575-Opaque"
                                    value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_1051_component_1575 captions-foreground-opacity-vjs_video_1051_component_1575 captions-foreground-opacity-vjs_video_1051_component_1575-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_1051_component_1575-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_1051_component_1575 captions-foreground-opacity-vjs_video_1051_component_1575 captions-foreground-opacity-vjs_video_1051_component_1575-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_1051_component_1575">Background</legend><label
                                id="captions-background-color-vjs_video_1051_component_1575"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_1051_component_1575 captions-background-color-vjs_video_1051_component_1575">
                                <option id="captions-background-color-vjs_video_1051_component_1575-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_1051_component_1575 captions-background-color-vjs_video_1051_component_1575 captions-background-color-vjs_video_1051_component_1575-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_1051_component_1575-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_1051_component_1575 captions-background-color-vjs_video_1051_component_1575 captions-background-color-vjs_video_1051_component_1575-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_1051_component_1575-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_1051_component_1575 captions-background-color-vjs_video_1051_component_1575 captions-background-color-vjs_video_1051_component_1575-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_1051_component_1575-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_1051_component_1575 captions-background-color-vjs_video_1051_component_1575 captions-background-color-vjs_video_1051_component_1575-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_1051_component_1575-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_1051_component_1575 captions-background-color-vjs_video_1051_component_1575 captions-background-color-vjs_video_1051_component_1575-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_1051_component_1575-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_1051_component_1575 captions-background-color-vjs_video_1051_component_1575 captions-background-color-vjs_video_1051_component_1575-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_1051_component_1575-Magenta"
                                  value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_1051_component_1575 captions-background-color-vjs_video_1051_component_1575 captions-background-color-vjs_video_1051_component_1575-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_1051_component_1575-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_1051_component_1575 captions-background-color-vjs_video_1051_component_1575 captions-background-color-vjs_video_1051_component_1575-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_1051_component_1575"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_1051_component_1575 captions-background-opacity-vjs_video_1051_component_1575">
                                  <option id="captions-background-opacity-vjs_video_1051_component_1575-Opaque"
                                    value="1"
                                    aria-labelledby="captions-background-vjs_video_1051_component_1575 captions-background-opacity-vjs_video_1051_component_1575 captions-background-opacity-vjs_video_1051_component_1575-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_1051_component_1575-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_1051_component_1575 captions-background-opacity-vjs_video_1051_component_1575 captions-background-opacity-vjs_video_1051_component_1575-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_1051_component_1575-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_1051_component_1575 captions-background-opacity-vjs_video_1051_component_1575 captions-background-opacity-vjs_video_1051_component_1575-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_1051_component_1575">Window</legend><label
                                id="captions-window-color-vjs_video_1051_component_1575"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_1051_component_1575 captions-window-color-vjs_video_1051_component_1575">
                                <option id="captions-window-color-vjs_video_1051_component_1575-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_1051_component_1575 captions-window-color-vjs_video_1051_component_1575 captions-window-color-vjs_video_1051_component_1575-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_1051_component_1575-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_1051_component_1575 captions-window-color-vjs_video_1051_component_1575 captions-window-color-vjs_video_1051_component_1575-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_1051_component_1575-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_1051_component_1575 captions-window-color-vjs_video_1051_component_1575 captions-window-color-vjs_video_1051_component_1575-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_1051_component_1575-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_1051_component_1575 captions-window-color-vjs_video_1051_component_1575 captions-window-color-vjs_video_1051_component_1575-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_1051_component_1575-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_1051_component_1575 captions-window-color-vjs_video_1051_component_1575 captions-window-color-vjs_video_1051_component_1575-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_1051_component_1575-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_1051_component_1575 captions-window-color-vjs_video_1051_component_1575 captions-window-color-vjs_video_1051_component_1575-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_1051_component_1575-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_1051_component_1575 captions-window-color-vjs_video_1051_component_1575 captions-window-color-vjs_video_1051_component_1575-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_1051_component_1575-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_1051_component_1575 captions-window-color-vjs_video_1051_component_1575 captions-window-color-vjs_video_1051_component_1575-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_1051_component_1575"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_1051_component_1575 captions-window-opacity-vjs_video_1051_component_1575">
                                  <option id="captions-window-opacity-vjs_video_1051_component_1575-Transparent"
                                    value="0"
                                    aria-labelledby="captions-window-vjs_video_1051_component_1575 captions-window-opacity-vjs_video_1051_component_1575 captions-window-opacity-vjs_video_1051_component_1575-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_1051_component_1575-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_1051_component_1575 captions-window-opacity-vjs_video_1051_component_1575 captions-window-opacity-vjs_video_1051_component_1575-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_1051_component_1575-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_1051_component_1575 captions-window-opacity-vjs_video_1051_component_1575 captions-window-opacity-vjs_video_1051_component_1575-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_1051_component_1575" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_1051_component_1575">
                                <option id="captions-font-size-vjs_video_1051_component_1575-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_1051_component_1575 captions-font-size-vjs_video_1051_component_1575-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_1051_component_1575-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_1051_component_1575 captions-font-size-vjs_video_1051_component_1575-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_1051_component_1575-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_1051_component_1575 captions-font-size-vjs_video_1051_component_1575-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_1051_component_1575-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_1051_component_1575 captions-font-size-vjs_video_1051_component_1575-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_1051_component_1575-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_1051_component_1575 captions-font-size-vjs_video_1051_component_1575-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_1051_component_1575-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_1051_component_1575 captions-font-size-vjs_video_1051_component_1575-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_1051_component_1575-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_1051_component_1575 captions-font-size-vjs_video_1051_component_1575-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_1051_component_1575-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_1051_component_1575 captions-font-size-vjs_video_1051_component_1575-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_1051_component_1575-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_1051_component_1575 captions-font-size-vjs_video_1051_component_1575-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_1051_component_1575" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_1051_component_1575">
                                <option id="vjs_video_1051_component_1575-None" value="none"
                                  aria-labelledby="vjs_video_1051_component_1575 vjs_video_1051_component_1575-None">
                                  None</option>
                                <option id="vjs_video_1051_component_1575-Raised" value="raised"
                                  aria-labelledby="vjs_video_1051_component_1575 vjs_video_1051_component_1575-Raised">
                                  Raised</option>
                                <option id="vjs_video_1051_component_1575-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_1051_component_1575 vjs_video_1051_component_1575-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_1051_component_1575-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_1051_component_1575 vjs_video_1051_component_1575-Uniform">
                                  Uniform</option>
                                <option id="vjs_video_1051_component_1575-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_1051_component_1575 vjs_video_1051_component_1575-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_1051_component_1575" class="">Font Family
                              </legend><select aria-labelledby="captions-font-family-vjs_video_1051_component_1575">
                                <option id="captions-font-family-vjs_video_1051_component_1575-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_1051_component_1575 captions-font-family-vjs_video_1051_component_1575-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_1051_component_1575-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_1051_component_1575 captions-font-family-vjs_video_1051_component_1575-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_1051_component_1575-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_1051_component_1575 captions-font-family-vjs_video_1051_component_1575-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_1051_component_1575-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_1051_component_1575 captions-font-family-vjs_video_1051_component_1575-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_1051_component_1575-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_1051_component_1575 captions-font-family-vjs_video_1051_component_1575-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_1051_component_1575-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_1051_component_1575 captions-font-family-vjs_video_1051_component_1575-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_1051_component_1575-SmallCaps"
                                  value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_1051_component_1575 captions-font-family-vjs_video_1051_component_1575-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_1051_component_1833_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_1051_component_1833_description">This is a modal window. This modal can be
                          closed by pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Ddc9062df-67b7-434a-bc2e-09cc8339edd0%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F22%2F2111%26topics%3Dsh%2Beb%2Bsm"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_31" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2111</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01189</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">August 30, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_48"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopaedic Surgery, Gunma University Graduate School of Medicine, Maebashi,
                            Gunma, Japan</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_52"
                            class="annotation strong" style="position: unset;">A commentary by Eric T. Ricchetti,
                            MD,</span> is linked to the online version of this article.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=4f1da912-6324-4e97-8651-e7d41b816a69&native=1"
                              class="article-title" style="position: unset;">The Impact of Malnutrition on Healing After
                              Arthroscopic Rotator Cuff Repair<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">Commentary on an article by Hitoshi Shitara, MD, PhD, et al.:
                                “Preoperative Nutrition Impacts Retear Rate After Arthroscopic Rotator Cuff
                                Repair”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Ricchetti,
                              Eric T.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(22):e47 | November 20, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Authors. Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the terms of the <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank"
                            data-id="link_1" class="link" style="position: unset;">Creative Commons Attribution-Non
                            Commercial-No Derivatives License 4.0</a> (CCBY-NC-ND), where it is permissible to download
                          and share the work provided it is properly cited. The work cannot be changed in any way or
                          used commercially without permission from the journal.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;dc9062df-67b7-434a-bc2e-09cc8339edd0&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;sh eb sm&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=dc9062df-67b7-434a-bc2e-09cc8339edd0&type=pdf&name=JBJS.23.01189.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=dc9062df-67b7-434a-bc2e-09cc8339edd0&type=pdf&name=JBJS.23.01189.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_53"
                            class="annotation strong" style="position: unset;">Disclosure:</span> Dr. Shitara received
                          grants from Johnson & Johnson and Asahi Kasei Pharma for this work. The <span
                            data-id="strong_54" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I157" target="_blank" data-id="link_2"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I157</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;dc9062df-67b7-434a-bc2e-09cc8339edd0&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;sh eb sm&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=dc9062df-67b7-434a-bc2e-09cc8339edd0&type=zip&name=JBJS.23.01189.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hitoshi Shitara, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:hshitara@gunma-u.ac.jp" class=""
                    style="position: unset;">hshitara@gunma-u.ac.jp</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3547-0718" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3547-0718</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tsuyoshi Ichinose, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2663-5314" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2663-5314</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tsuyoshi Sasaki, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6312-4009" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6312-4009</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Noritaka Hamano, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5737-4183" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5737-4183</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Masataka Kamiyama, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2406-5688" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2406-5688</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ryosuke Miyamoto, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5852-1314" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5852-1314</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Fukuhisa Ino, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-9691-8767" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-9691-8767</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kurumi Nakase, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7401-2504" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7401-2504</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Akira Honda, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8462-6024" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8462-6024</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Atsushi Yamamoto, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9392-3570" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9392-3570</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kenji Takagishi, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0825-9685" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0825-9685</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hirotaka Chikuda, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7913-1542" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7913-1542</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Gunma University Graduate School of
                Medicine, Maebashi, Gunma, Japan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 33383.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
