/* ----------------- React Native Paper --------------- */
import { Divider, List } from "react-native-paper";

/* ----------------- Memorang UI --------------- */
import { Box, CustomListSubheader, DonutChart } from "@memorang/ui";

/* ----------------- Memorang Types --------------- */
import type { Distribution } from "@memorang/types";

/* ----------------- Local Components --------------- */
import { MasteryListItem, ScrollContainer } from "@memorang/ui";
import { CustomAppbar } from "components/index";

/* ----------------- Hooks --------------- */
import { useTagsStore } from "../../hooks/useTagsStore";

/* ----------------- Helpers --------------- */
import type { TagItem } from "@helpers/content/types";

type Props = {
	title: string;
};
export const TopicDetailView = ({ title }: Props) => {
	const renderLeftComp = (
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		p: any,
		distribution: Distribution,
		innerRadius = 12,
		outerRadius = 22,
	) => (
		<Box {...p} justifyContent="center">
			<DonutChart
				distribution={distribution}
				innerRadius={innerRadius}
				radius={outerRadius}
			/>
		</Box>
	);

	const renderSubChildTagItem = (item: TagItem, index: number) => (
		<Box key={`${item.title}-${index}`} paddingRight={6} marginLeft={-30}>
			<MasteryListItem
				title={item.title}
				divider={false}
				distribution={item.distribution}
				variant="subchild"
			/>
		</Box>
	);

	const renderChildTagItem = (
		{
			title: childLabel,
			distribution: childDist,
			childTags: subChildTags,
		}: TagItem,
		index: number,
	) => {
		const ContainerList = subChildTags?.length ? List.Accordion : List.Item;
		return (
			<Box key={`${childLabel}-${index}`}>
				<ContainerList
					title={childLabel}
					left={(p) => renderLeftComp(p, childDist, 10, 18)}
					titleNumberOfLines={3}
				>
					{subChildTags?.map(renderSubChildTagItem)}
				</ContainerList>
			</Box>
		);
	};
	const ListSection = (propsListSection: TagItem) => {
		const { title, distribution, childTags } = propsListSection;

		const hasChildTags = childTags && childTags.length > 0;

		const ContainerList = hasChildTags ? List.Accordion : List.Item;
		return (
			<>
				<ContainerList
					title={title}
					left={(p) => renderLeftComp(p, distribution)}
					titleNumberOfLines={3}
				>
					{hasChildTags && childTags.map(renderChildTagItem)}
				</ContainerList>
				<Divider />
			</>
		);
	};

	const subHeader = title;
	const currentSelectedMasteryItems = useTagsStore(
		(state) => state.currentSelectedMasteryItems,
	);
	return (
		<>
			<CustomAppbar
				options={{
					headerTitle: "Mastery",
				}}
				back
			/>
			<ScrollContainer>
				<Box paddingBottom={24}>
					<CustomListSubheader>{subHeader}</CustomListSubheader>
					{currentSelectedMasteryItems.map((item) => (
						<ListSection key={item.id} {...item} />
					))}
				</Box>
			</ScrollContainer>
		</>
	);
};
