/* -----------------Globals--------------- */
import { useCallback } from "react";

/* -----------------Helpers & Hooks--------------- */
import { useAuthContext } from "@memorang/applets";
import useSessionStore from "./useSessionStore";

/* -----------------Child components--------------- */
import { fetchSession } from "../relay/FetchSession";

import { events } from "@constants/tracking";
import { trackCustomEvent } from "analytics";
/* -----------------Types--------------- */
import { useRouter } from "expo-router";

export const useResumeSession = () => {
	const router = useRouter();
	const { viewerId: userId } = useAuthContext();
	const updateCurrentBlockIndex = useSessionStore(
		(state) => state.updateCurrentBlockIndex,
	);
	const updateCurrentItemIndex = useSessionStore(
		(state) => state.updateCurrentItemIndex,
	);
	const updateProgressForEachBlock = useSessionStore(
		(state) => state.updateProgressForEachBlock,
	);
	const updateSession = useSessionStore((state) => state.updateSession);
	const handleResumeSession = useCallback(
		async (numericId: number) => {
			const result = await fetchSession({
				numericId,
				userId: userId!,
			});
			const {
				response,
				lastUpdatedBlockIndex,
				lastUpdatedItemIndex,
				progressArray,
			} = result;
			const { status } = response;

			if (status === "COMPLETE") {
				router.replace(`/session/${numericId}/report`);
			} else {
				trackCustomEvent({
					eventName: events.sessionResumed,
					numericId,
				});
				updateCurrentBlockIndex(lastUpdatedBlockIndex);
				updateCurrentItemIndex(lastUpdatedItemIndex);
				updateSession({
					sessionResponse: response,
					isSummativeTest: true,
				});
				for (const { id, progress } of progressArray) {
					updateProgressForEachBlock(progress, id);
				}
				router.push({
					pathname: "/(protected)/session/[id]",
					params: {
						id: response.numericId,
						index: String(lastUpdatedItemIndex) || 1,
					},
				});
			}
		},
		[
			router,
			updateCurrentBlockIndex,
			updateCurrentItemIndex,
			updateProgressForEachBlock,
			updateSession,
			userId,
		],
	);

	return {
		handleResumeSession,
	};
};
