/* -----------------Globals--------------- */
import { Redirect, usePathname, useRouter } from "expo-router";

import { useUser } from "@clerk/clerk-expo";

/* -----------------Child components--------------- */
import { Loader } from "@memorang/ui";

import { pageViewTypes } from "@constants/tracking";
import { OnboardingAppBar } from "@features/auth/";
import { useAppStore } from "@hooks/useAppStore";
import { useDevice } from "@hooks/useDevice";
/* -----------------Helpers & Hooks--------------- */
import { Landing } from "@memorang/applets";
import { trackPageView } from "analytics";
import { useEffect } from "react";
export default function IndexRoute() {
	const router = useRouter();

	const { logo: appLogo, landing } = useAppStore((store) => store.app);

	const subtitle = landing?.subtitle;

	const { isSignedIn, isLoaded } = useUser();

	const { isMobile } = useDevice();
	const pathname = usePathname();

	useEffect(() => {
		trackPageView({ type: pageViewTypes.splash });
	}, []);

	const isVerify = pathname === "/verify";

	if (!isLoaded) {
		return <Loader />;
	}
	if (isSignedIn && !isVerify) {
		return <Redirect href="/(protected)/(tabs)/home" />;
	}

	const handleNext = () => {
		router.push("/(auth)/login");
	};
	return (
		<>
			{!isMobile && <OnboardingAppBar showLogo />}

			<Landing appLogo={appLogo} handleNext={handleNext} subtitle={subtitle} />
		</>
	);
}
