import * as Application from "expo-application";
import Constants from "expo-constants";
import { type AppIds, Apps } from "types/apps";
import { isAndroid, isWeb } from "./platform";

const isDev = process.env.NODE_ENV === "development";
//TODO: This module name does not make sense. also change getBuildType to getTargetApp, and buildType to targetApp
const getBuildType = (): Apps | undefined => {
	if (isDev) {
		//TODO: Change this to different apps if you want to test different apps on web/mobile
		return Apps.Jbjs;
	}
	if (isWeb) {
		const host = window.location.hostname;
		if (host.includes("partners")) {
			//TODO:Change this to other apps if you want to test different apps on preview
			return Apps.Psi;
		}
		if (host.includes("therapyed")) {
			return Apps.TherapyEd;
		}
		if (host.includes("psi")) {
			return Apps.Psi;
		}

		if (host.includes("thieme")) {
			return Apps.thiemeneetpg;
		}
		if (host.includes("simwerx")) {
			return Apps.Simwerx;
		}
		if (host.includes("jbjs")) {
			return Apps.Jbjs;
		}
		return undefined;
	}
	const appId = Application.applicationId as AppIds;
	switch (appId) {
		case "com.memorang.therapyed":
			return Apps.TherapyEd;
		case "com.memorang.psi.insurance":
		case "com.memorang.psi.insurance.testprep":
			return Apps.Psi;
		case "com.memorang.thieme.india.medical":
			return Apps.thiemeneetpg;
		case "com.memorang.simwerx":
			return Apps.Simwerx;

		case "com.memorang.jbjs.image.challenge":
			return Apps.Jbjs;
		default:
			return Apps.TherapyEd;
	}
};
const versionCode = isAndroid
	? Constants.expoConfig?.android?.versionCode
	: Constants.expoConfig?.ios?.buildNumber;
const currentAppVersion = Constants.expoConfig?.version;
const buildType = getBuildType()!;

export { buildType, currentAppVersion, versionCode };
