import { Box, MarkdownRenderer, ScrollContainer } from "@memorang/ui";
import { Surface, Text } from "react-native-paper";

/* -----------------Props--------------- */
type Props = {
	text: string;
	title: string;
	dates: {
		start: string;
		updated: string;
	};
};

/* -----------------Component--------------- */
const LegalView = (props: Props) => {
	const {
		title,
		text,
		dates: { start, updated },
	} = props;
	const subtitle = `Effective ${start} | Updated ${updated}`;
	return (
		<ScrollContainer maxWidth="md">
			<Surface
				style={{
					padding: 24,
				}}
			>
				<Box alignItems="center" paddingVertical={24}>
					<Text variant="displayMedium">{title}</Text>
					<Text variant="bodyMedium">{subtitle}</Text>
				</Box>
				<MarkdownRenderer text={text} variant="explanation" />
			</Surface>
		</ScrollContainer>
	);
};

export default LegalView;
