/* -----------------Globals--------------- */
import { useEffect } from "react";

/* -----------------UI--------------- */
import { Text } from "react-native-paper";

import { useExamContext, useExams } from "@features/exam";
/* -----------------Helpers & Hooks--------------- */
import { useAppStore } from "@hooks/useAppStore";
import { useLocalSearchParams } from "expo-router";

/* -----------------Types--------------- */
import { type ExamDetails, fetchExams } from "@features/exam";

/* -----------------Child components--------------- */
import { ProductsList } from "@features/dashboard";
import { AuthContainer, Box, Loader } from "@memorang/ui";

import { events } from "@constants/tracking";
import { useAppTheme } from "@hooks/useAppTheme";
import { trackCustomEvent } from "analytics";
/* -----------------Others--------------- */
import { useRouter } from "expo-router";
import OnboardingAppBar from "../components/OnboardingAppBar";

type Props = {
	handleNext: (step: string) => void;
};
export const ChooseExamContainer = ({ handleNext }: Props) => {
	const { category: selectedCategory, showBackButton = "true" } =
		useLocalSearchParams<{
			category: string;
			showBackButton: string;
		}>();

	const { handleSwitchExamContext } = useExamContext();
	const {
		app: { id: appId },
		tenant: { id: tenantId },
	} = useAppStore((store) => ({
		app: store.app,
		tenant: store.tenant,
	}));
	const { groupedExams, setExams } = useExams();

	const router = useRouter();

	const theme = useAppTheme();
	useEffect(() => {
		trackCustomEvent({
			eventName: events.chooseExamViewed,
			selectedCategory,
		});
	}, [selectedCategory]);
	useEffect(() => {
		if (!groupedExams.length) {
			fetchExams(tenantId, appId).then((exams) => {
				setExams(exams);
			});
		}
	}, [tenantId, appId, setExams, groupedExams.length]);
	if (!groupedExams.length) {
		return <Loader />;
	}

	const filteredExams = groupedExams.filter((exam) => {
		return selectedCategory
			? exam.title.toLocaleLowerCase() === selectedCategory?.toLocaleLowerCase()
			: true;
	});

	const interceptHandleSwitchExamContext = async (examDetails: ExamDetails) => {
		await handleSwitchExamContext(examDetails);
		handleNext("userdetails");
	};

	return (
		<Box flex={1}>
			<OnboardingAppBar
				elevated
				onBackAction={
					showBackButton === "true"
						? () => {
								router.setParams({
									step: "category",
									category: selectedCategory,
								});
							}
						: undefined
				}
				showLogo={showBackButton === "false"}
				showProfileMenu={false}
				title={"Choose Exam"}
			/>
			<Box paddingTop={24} flex={1} backgroundColor={theme.colors.surface}>
				<Text
					variant="titleMedium"
					style={{
						paddingHorizontal: 16,
						textAlign: "center",
					}}
				>
					Which specific
					<Text
						style={{
							fontWeight: "bold",
						}}
					>
						{selectedCategory ? ` ${selectedCategory?.toLowerCase()} ` : " "}
					</Text>
					exam are you preparing for?
				</Text>
				<AuthContainer maxWidth="xs">
					<ProductsList
						products={filteredExams}
						showProducts={false}
						showChevronRight
						handleSwitchExamContext={interceptHandleSwitchExamContext}
					/>
				</AuthContainer>
			</Box>
		</Box>
	);
};
