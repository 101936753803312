import type {
	Distribution,
	EndSessionTypes,
	QuestionVariant,
	SessionItemTypes,
} from "@memorang/types";
import { itemSchema } from "schema/item";
import { z } from "zod";

export const GetItemDetailsPayloadSchema = z.object({
	itemId: z.number(),
	userId: z.number(),
});

export const GetItemDetailsResponseSchema = z.object({
	data: z.object({
		items: itemSchema,
	}),
});

export const ItemDetailsSchema = z.object({
	type: z.enum(["ANSWER_EVENT", "CHALLENGE_EVENT"]),
	itemType: z.custom<SessionItemTypes>(),
	variant: z.custom<QuestionVariant>(),
});

export const ResponseSchema = z.object({
	selectedChoiceIds: z.array(z.string()),
	confidence: z.number().optional(),
	chosenAnswerId: z.string(),
	correctness: z.boolean(),
	aiAssistanceUsed: z.boolean().optional(),
	attempts: z.number().optional(),
});

export const PerformanceSchema = z.object({
	timeTaken: z.number(),
	timeFactor: z.number().optional(),
	points: z.number().optional(),
	score: z.number(),
	xp: z.number().optional(),
});

export const SettingsSchema = z
	.object({
		shuffled: z.boolean().optional(),
		numDistractors: z.number().optional(),
	})
	.optional();

export const UserInteractionSchema = z
	.object({
		highlight: z.string().optional(),
		strikeOut: z.array(z.number()).optional(),
		marked: z.boolean().optional(),
	})
	.optional();

export const AnswerEventPayloadSchema = z.object({
	itemId: z.number(),
	itemVersionId: z.number(),
	sessionId: z.number(),
	collectionId: z.number(),
	userId: z.number(),
	projectId: z.number(),
	organizationId: z.number(),
	itemDetails: ItemDetailsSchema,
	response: ResponseSchema,
	performance: PerformanceSchema,
	settings: SettingsSchema,
	userInteraction: UserInteractionSchema,
	platform: z.enum(["WEB", "IOS", "ANDROID"]),
	token: z.string(),
	tenantId: z.string(),
});

export const CreateSessionPayloadSchema = z.object({
	userId: z.number(),
	collectionId: z.number(),
	contentId: z.number().optional(),
	projectId: z.number(),
	organizationId: z.number(),
	sessionType: z.custom<EndSessionTypes>(),
	itemsSelected: z.number(),
	itemsSelectedDistribution: z.custom<Distribution>(),
	token: z.string(),
	tenantId: z.string(),
});

export type CreateSessionPayload = z.infer<typeof CreateSessionPayloadSchema>;
export const CreateSessionResponseSchema = z.object({
	data: z.object({
		id: z.number(),
	}),
});

export type AnswerEventPayload = z.infer<typeof AnswerEventPayloadSchema>;
