import Box from "../Box";
import { TextSkeleton } from "./TextSkeleton";

type RecommendedChipsSkeletonProps = React.ComponentProps<typeof Box> & {
	numLines?: number;
	skeletonHeight?: number;
	children?: never;
};

export const RecommendedChipsSkeleton = ({
	numLines = 2,
	skeletonHeight,
	...boxProps
}: RecommendedChipsSkeletonProps) => {
	return (
		<Box gap={8} width="100%" flexWrap="wrap" {...boxProps}>
			<TextSkeleton numLines={numLines} height={skeletonHeight} />
		</Box>
	);
};
