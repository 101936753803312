/* -----------------Globals--------------- */
import fetchQueryWrapper from "@helpers/relay-fetch-query-wrapper";
import { graphql } from "react-relay";
/* -------------------Query----------------------- */
const query = graphql`
  query FetchActiveCodesQuery($tenantId: String!, $input: String!) {
    getActiveCodes(tenantId: $tenantId, input: $input) {
      bundle {
        id
        name
        icon {
          url
        }
        features
        bundleType
      }
      codes
    }
  }
`;

export type BundleType = "STUDY_PACK" | "PRACTICE_TEST" | "EXAM";
export type ActiveCodesResult = {
	bundle: {
		id: string;
		name: string;
		icon: {
			url: string;
		};
		bundleType: BundleType;
		features: string[];
		isCustomAccess: boolean;
	};
	codes: string[];
};
type Result = {
	getActiveCodes: ActiveCodesResult[];
};
const fetchActiveCodes = async (
	tenantId: string,
	input: string,
): Promise<ActiveCodesResult[]> => {
	const variables = {
		tenantId,
		input,
	};

	const { getActiveCodes } = (await fetchQueryWrapper(
		query,
		variables,
	)) as Result;

	return getActiveCodes;
};

export default fetchActiveCodes;
