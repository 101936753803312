import type { BottomSheetModal } from "@gorhom/bottom-sheet";
/* -----------------Globals--------------- */
import { useEffect, useRef, useState } from "react";

import { useHaptics } from "@contexts/HapticContext";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useAppTheme } from "@hooks/useAppTheme";
import { useDevice } from "@hooks/useDevice";
import { Box, MarkdownRenderer, TextSkeleton } from "@memorang/ui";
import PostAnswerWrapper from "@memorang/ui/src/components/PostAnswerWrapper";
import Handle from "components/BottomSheetHandle";
import pluralize from "pluralize";
import { Pressable } from "react-native";
import { Button, List, Text } from "react-native-paper";

type Props = {
	hint?: string;
	loading?: boolean;
	currentQuestionAnswered: boolean;
	currentItemIndex: number;
	handleClickTryAgain: () => void;
	numAttempts?: number;
	numTotalAttempts?: number;
	hintEnabled?: boolean;
	numResponses?: number;
};

const MotivationCTA = ({
	handleClickTryAgain,
	disabled,
	attemptsLeft,
}: {
	handleClickTryAgain: () => void;
	disabled?: boolean;
	attemptsLeft: number;
}) => {
	const theme = useAppTheme();

	return (
		<Box justifyContent="flex-end">
			<Box
				flexDirection="row"
				gap={8}
				alignItems="center"
				justifyContent="center"
			>
				<Icon name="heart" size={24} color={theme.palette.error.dark} />
				<Text variant="titleMedium">{`${attemptsLeft} ${pluralize(
					"guess",
					attemptsLeft,
				)} left`}</Text>
			</Box>
			<Button
				onPress={handleClickTryAgain}
				mode="contained"
				disabled={disabled}
				style={{
					margin: 16,
					marginBottom: 24,
				}}
			>
				Take another try
			</Button>
		</Box>
	);
};

const TryAgainBottomSheet = ({
	currentQuestionAnswered,
	currentItemIndex,
	handleClickTryAgain,
	numAttempts = 0,
	numTotalAttempts = 0,
	hint,
	loading,
	hintEnabled,
	numResponses,
}: Props) => {
	const bottomSheetRef = useRef<BottomSheetModal>(null);
	const { triggerHaptic } = useHaptics();

	const showAnswerChoseThis = numResponses ? numResponses > 10 : false;

	const theme = useAppTheme();

	const { isMediumWindowSize } = useDevice();

	const [snapIndex, setSnapIndex] = useState(0);

	const handleOpenInitialPosition = () => {
		if (bottomSheetRef.current) {
			bottomSheetRef.current.present();
		}
	};

	const showModal = currentQuestionAnswered;

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (showModal) {
			triggerHaptic("error");
			handleOpenInitialPosition();
		} else if (bottomSheetRef.current) {
			bottomSheetRef.current.dismiss();
		}
	}, [showModal, currentItemIndex]);

	const handleExpand = () => {
		if (bottomSheetRef.current) {
			bottomSheetRef.current.snapToIndex(1);
		}
	};
	const handleSetSnapIndex = (index: number) => {
		setSnapIndex(index);
		if (index === -1) {
			handleClickTryAgain();
		}
	};

	const handleExpandCollapse = () => {
		if (snapIndex === 0) {
			handleExpand();
		} else if (bottomSheetRef.current) {
			bottomSheetRef.current.snapToIndex(0);
		}
	};

	const attemptsLeft = numTotalAttempts - numAttempts;
	const CustomHandle = () => (
		<Pressable onPress={handleExpandCollapse}>
			<Box
				width="100%"
				backgroundColor={theme.palette.error.info}
				style={{
					borderTopLeftRadius: 24,
					borderTopRightRadius: 24,
				}}
			>
				<Handle onPress={handleExpandCollapse} />
				<Box
					flexDirection="row"
					justifyContent="space-between"
					paddingHorizontal={16}
					paddingBottom={24}
				>
					<Box flexDirection="row" gap={8} alignItems="center">
						<Icon
							name={"thumb-down-outline"}
							size={24}
							color={theme.palette.error.dark}
						/>
						<Text
							variant="titleMedium"
							style={{
								color: theme.palette.error.dark,
							}}
						>
							Incorrect
						</Text>
					</Box>
					{showAnswerChoseThis && (
						<Box flexDirection="row" gap={4} alignItems="center">
							<Text
								variant="labelMedium"
								style={{
									color: theme.palette.error.dark,
								}}
							>
								{numResponses} chose this
							</Text>
							<Icon name="poll" size={24} color={theme.palette.error.dark} />
						</Box>
					)}
				</Box>
			</Box>
		</Pressable>
	);

	return (
		<PostAnswerWrapper
			showModal={showModal}
			enablePanDownToClose
			snapPoints={hintEnabled ? [isMediumWindowSize ? "30%" : "50%"] : ["20%"]}
			handleSetSnapIndex={handleSetSnapIndex}
			snapIndex={0}
			ref={bottomSheetRef}
			padding={0}
			flexGrow
			paddingBottom={0}
			animateOnMount
			customHandle={<CustomHandle />}
		>
			<Box justifyContent="space-between" flexGrow={1} height="100%">
				<Box gap={16}>
					{hintEnabled && (
						<List.Item
							left={(props) => (
								<List.Icon
									{...props}
									icon={"lightbulb-on-outline"}
									style={{
										...props.style,
										alignSelf: "baseline",
									}}
								/>
							)}
							style={{
								borderRadius: 8,
							}}
							title={() =>
								loading || !hint ? (
									<TextSkeleton height={14} numLines={1} />
								) : (
									<MarkdownRenderer
										text={hint}
										variant="answer"
										customStyles={{
											text: {
												fontSize: 14,
												lineHeight: 24,
											},
										}}
									/>
								)
							}
						/>
					)}
				</Box>
				<MotivationCTA
					disabled={loading}
					handleClickTryAgain={handleClickTryAgain}
					attemptsLeft={attemptsLeft}
				/>
			</Box>
		</PostAnswerWrapper>
	);
};

export default TryAgainBottomSheet;
