/* -----------------Helpers--------------- */
import { getLearningModes } from "../helpers/learningModes";
import type { LearningModes } from "../types";

import { Box, CustomListSubheader } from "@memorang/ui";
/* -----------------Components--------------- */
import ListItemLearningMode from "components/ListItemLearningMode";

type NumItemsMap = {
	numFlashcards: number;
	numQuestions: number;
};
type Props = {
	selectedLearningMode: string;
	handleSetLearningMode: (learningMode: LearningModes) => void;
	numItemsMap: NumItemsMap;
	disableAll?: boolean;
};
const LearningModesList = ({
	selectedLearningMode,
	handleSetLearningMode,
	disableAll,
	numItemsMap: { numFlashcards, numQuestions },
}: Props) => {
	const learningModes = getLearningModes(numFlashcards, numQuestions);

	return (
		<Box>
			<CustomListSubheader enableGutters>Learning mode</CustomListSubheader>
			<>
				{learningModes.map(({ title, value, disabled, icon, count }) => {
					const selected = value === selectedLearningMode;
					return (
						<ListItemLearningMode
							title={"Quiz challenge"}
							key={title}
							value={value}
							disabled={disabled || disableAll}
							icon={"arm-flex-outline" || icon}
							count={count}
							selected={selected}
							handleRadioSelection={handleSetLearningMode}
						/>
					);
				})}
			</>
		</Box>
	);
};

export default LearningModesList;
