import type { Answers, SessionChildItem } from "@memorang/types";

export const getPointsEarnedStatItemValue = ({
	answers,
	children,
}: {
	answers?: Answers;
	children?: SessionChildItem[];
}) => {
	if (answers?.pointsList?.length) {
		const numTotalPoints = children?.reduce(
			(acc, { points }) => acc + (points ?? 0),
			0,
		);
		const numChoicesPoints = answers.pointsList.reduce(
			(acc, { points }) => acc + (points ?? 0),
			0,
		);
		return `${numChoicesPoints} / ${numTotalPoints}`;
	}
};
