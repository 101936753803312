import { loadStripe } from "@stripe/stripe-js";
/* -----------------Globals--------------- */
import { useEffect, useState } from "react";

/* -----------------Helpers & Hooks--------------- */
import { useAppStore } from "@hooks/useAppStore";
import { useLocalSearchParams } from "expo-router";

/* -----------------Child components--------------- */
import { Box, Loader } from "@memorang/ui";

/* -----------------Constants--------------- */
import { urls } from "@constants/urls";

/* -----------------Features--------------- */
import { handleCreateCheckoutSession } from "@features/dashboard";
import { fetchBundleDetails } from "@features/store";

/* -----------------Stripe--------------- */
import {
	EmbeddedCheckout,
	EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import DocumentTitle from "components/DocumentTitle";

const stripeKey = process.env.EXPO_PUBLIC_STRIPE_KEY;

if (!stripeKey) {
	throw new Error("Stripe key not found");
}
const stripePromise = loadStripe(stripeKey);

const CheckoutRoute = () => {
	const baseUrl = useAppStore((store) => store.app.links.baseUrl);
	const [clientSecret, setClientSecret] = useState<string | null>(null);
	const { id, planId } = useLocalSearchParams<{
		id: string;
		planId?: string;
	}>();

	useEffect(() => {
		const fetchDetailsAndCreateSession = async () => {
			if (id) {
				const numericId = Number.parseInt(id);
				const bundleDetails = await fetchBundleDetails(numericId);

				if (bundleDetails) {
					const bundlePlans = bundleDetails.bundle.plans;
					const selectedPlan = bundlePlans.find((plan) => plan.id === planId);
					const routeSegment = `purchase/${numericId}/success`;
					//TODO:Remove this once testing is done
					// const testDomain = 'https://psi-web-vivekneel-memorang.vercel.app';
					const redirectUrl =
						process.env.NODE_ENV === "development"
							? `${urls.dev}/${routeSegment}`
							: `${baseUrl}/${routeSegment}`;

					const productDescription =
						"Unlock all items and maximize your test readiness";
					if (selectedPlan) {
						const res = await handleCreateCheckoutSession({
							selectedPlan,
							productDescription,
							productId: bundleDetails.bundle.id,
							redirectUrl,
							productName: bundleDetails.bundle.name,
						});
						setClientSecret(res);
					}
				}
			}
		};

		fetchDetailsAndCreateSession();
	}, [id, planId, baseUrl]);

	if (!clientSecret) {
		return (
			<Box flex={1} justifyContent="center" alignItems="center" height="100%">
				<Loader />
			</Box>
		);
	}

	return (
		<>
			<DocumentTitle title="Checkout" />
			{clientSecret && (
				<EmbeddedCheckoutProvider
					stripe={stripePromise}
					options={{ clientSecret }}
				>
					<EmbeddedCheckout />
				</EmbeddedCheckoutProvider>
			)}
		</>
	);
};

export default CheckoutRoute;
