import type { Article } from "../../../../../types";

export const Article569: Article = {
	id: 569,
	rsuiteId: "7c7a705d-d2f0-400b-bb34-456618e57deb",
	type: "scientific article",
	title:
		"Patient Factors Associated with 10-Year Survival After Arthroplasty for Hip Fracture",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=7c7a705d-d2f0-400b-bb34-456618e57deb&type=jpeg&name=JBJS.24.00379f1",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Patient Factors Associated with 10-Year Survival After
                Arthroplasty for Hip Fracture</div>
              <div class="text subtitle" style="position: unset;">A Population-Based Study in Ontario, Canada</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Mina Tohidi,
                      MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">George
                      Grammatopoulos, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_27" class="annotation strong"
                          style="position: unset;">Background:</span> The aim of this study was to describe long-term
                        (10-year) patient survival after arthroplasty for hip fracture and to determine what patient
                        factors are associated with that outcome.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_28" class="annotation strong"
                          style="position: unset;">Methods:</span> We performed a retrospective cohort analysis of
                        patients ≥60 years old who underwent either hemiarthroplasty or total hip arthroplasty for
                        femoral neck fracture between 2002 and 2009. We used routinely collected, validated health-care
                        databases linked through ICES (formerly known as the Institute for Clinical Evaluative
                        Sciences). We estimated the association between baseline variables and survival 10 years
                        post-fracture using Poisson regression. Restricted cubic spline functions modeled the
                        probability of 10-year survival by age and tested whether there was an inflection point after
                        which the probability of 10-year survival decreased more rapidly. We estimated 10-year survival
                        probabilities for different patient groups.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Results:</span> There were 19,659 patients in the final cohort.
                        Eighteen percent (3,564) of the patients were alive at 10 years postoperatively. Factors
                        associated with a higher likelihood of 10-year survival included younger age, female sex (risk
                        ratio [RR] = 1.56, 95% confidence interval [CI] = 1.46 to 1.68), lower American Society of
                        Anesthesiologists (ASA) class (ASA I or II versus IV or V: RR = 1.96, 95% CI = 1.76 to 2.19),
                        independent living status (RR = 2.68, 95% CI = 2.23 to 3.22), and fewer specific comorbidities.
                        A threshold age of 73 years was the inflection point after which the probability of 10-year
                        survival decreased more rapidly in females. Estimated 10-year survival probabilities ranged from
                        79.0% (95% CI = 75.5% to 82.5%) to 0.8% (95% CI = 0.6% to 1.0%).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Approximately 1 in 6 patients live at least 10
                        years following a hip fracture. This study identifies baseline characteristics that predict
                        survival greater than 10 years, including an age of &lt;75 years, an ASA class of I or II, and
                        independent living status prior to the hip fracture. Results can inform discussions around
                        treatment choices, anticipated outcomes, and the natural history of hip fractures.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Approximately 300,000 hip fractures occur annually across
                  the United States, and the incidence is rising with an aging population<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">1</span></a>. Displaced femoral neck fractures make up approximately
                  one-third of all hip fractures<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">2</span></a>.
                  Recent randomized studies and meta-analyses have shown a similar risk of death between patients
                  treated with hemiarthroplasty and those treated with total hip arthroplasty (THA)<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">6</span></a>. For
                  example, the HEALTH (Hip Fracture Evaluation with Alternatives of Total Hip Arthroplasty versus
                  Hemi-Arthroplasty) investigators recently found no significant difference (p = 0.48) between
                  hemiarthroplasty and THA for hip fracture in terms of the 24-month risk of death in a study of 1,495
                  patients (13.1% treated with hemiarthroplasty and 14.3%, with THA) aged 50 years and older<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">3</span></a>. While many studies have compared short-term outcomes after
                  hip fracture, only a few have evaluated long-term patient survival. Tol et al. found no difference in
                  the 12-year risk of death between patients treated with hemiarthroplasty and those who underwent THA<a
                    href="" data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">7</span></a>. Twenty percent of their original study population was still
                  alive at 12 years. Similarly, Avery et al. showed no significant difference in the risk of death at a
                  mean of 9 years<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">8</span></a>.
                  However, the studies did not identify patient characteristics that predict long-term survival.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Being able to predict how long a patient will live after a
                  hip fracture can help surgeons, patients, and family during shared decision-making. The primary
                  purpose of this study was to describe long-term (10-year) survival of patients after arthroplasty for
                  a hip fracture and to determine what patient factors are associated with that outcome. The secondary
                  purpose was to determine if there was a natural inflection point in patient age after which the
                  probability of long-term survival decreased more rapidly.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Design, Setting, and Population</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">This population-based retrospective cohort study utilized
                  provincial-level health-care data from Ontario, Canada. Individual-level health-care encounters and
                  patient demographics were linked from multiple administrative databases at ICES (formerly known as the
                  Institute for Clinical Evaluative Sciences), an independent, non-profit research institute.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The eligible study population consisted of patients ≥60
                  years old who were hospitalized for a femoral neck fracture in Ontario between 2002 and 2009 and
                  underwent hemiarthroplasty or THA. We restricted the cohort to these dates to allow for 10 years of
                  follow-up after treatment. Hip fractures were identified using International Classification of
                  Diseases, 10th Revision, Canada (ICD-10-CA) codes S72.010, S72.011, S72.080, S72.081, S72.090, and
                  S72.091. Treatment information for the index procedure was obtained using Ontario Health Insurance
                  Plan (OHIP) procedure codes billed by the primary surgeon.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Patients were excluded from the study for the following
                  reasons: not a resident of Ontario or not eligible for OHIP at the time of hip fracture, no ICES key
                  numbers (IKN) for linkage, age of &lt;60 years at diagnosis, pathologic hip fracture, &gt;1 procedure
                  code on the same day, surgery performed &gt;7 days after hospital admission, and missing rurality or
                  marginalization data. Patients &lt;60 years of age were excluded as this study focused on fragility
                  fractures rather than fractures related to high-energy trauma. The purpose of the final 3 exclusion
                  criteria was to limit the risk of misclassification of exposure or important demographic variables.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The index date was the date of surgery, and a lookback
                  window of 2 years from the date of hospital admission was used to determine baseline comorbidities.
                  Individuals accrued follow-up data until the end of OHIP eligibility, death, or December 31, 2018
                  (whichever came first).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Baseline
                Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">We measured the following baseline demographics: age, sex,
                  rurality, marginalization, American Society of Anesthesiologists (ASA) physical status class, frailty,
                  dementia, long-term-care residence, pre-admission home care services, congestive heart failure (CHF),
                  chronic obstructive pulmonary disease (COPD), diabetes, rheumatoid arthritis, hypertension, surgery
                  &gt;48 hours after fracture, and treatment year. Marginalization is an area-based measurement that
                  uses the Ontario Marginalization Index (ON-Marg; Public Health Ontario), a multifaceted data tool that
                  combines multiple axes of deprivation at the area level, including economic, ethno-racial, age-based,
                  and social marginalization<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">9</span></a>.
                  Frailty was measured using the Johns Hopkins Adjusted Clinical Groups (ACG) frailty-defining diagnoses
                  indicator<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">10</span></a>.
                  Additional details regarding variable definitions as well as validation studies are in the Appendix.
                  Details regarding race and ethnicity were not captured in available data sets and therefore were
                  omitted from this study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Outcome
                Measures</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The primary outcome measure was 10-year all-cause death
                  using the Registered Persons Database (RPDB), which provides basic demographic information on all
                  individuals who have ever received an Ontario health card number. Patients who did not have a reported
                  death date at 10 years were considered living in our analyses.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Baseline demographics were compared between patients who
                  were and were not alive at 10 years using the chi-square test for categorical variables and the
                  Student t test for continuous variables. Kaplan-Meier curves were used to estimate the time to death.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Poisson regression modeling was used to estimate the
                  association (relative risk) between patient- and treatment-related variables and whether patients
                  lived 10 years after the fracture.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Restricted cubic spline functions were used to investigate
                  the nonlinearity of the association between age and 10-year survival in multivariable Poisson
                  regression models. Each model computed the ratios across the range of the restricted cubic spline
                  variable for each year of increase in age, using 60 years old as the reference age. The relative risks
                  for age were then graphically represented to visualize the inflection point(s), if they existed, where
                  the 10-year survival rate began to decrease. Restricted cubic spline models were adjusted for the
                  following baseline covariates: sex (when appropriate), rurality, marginalization, frailty, long-term
                  care status, use of home care services, ASA class, CHF, COPD, hypertension, dementia, diabetes,
                  rheumatoid arthritis, and year.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Generalized linear regression models (binomial
                  distribution) were used to estimate predictive margins for variables in the model. Here, the effects
                  of specified categorical predictors on the response mean were obtained, while holding non-specific
                  variables at their overall observed proportions within the data set. These models were used to
                  estimate survival probabilities.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">A data set with the eligible study population was created
                  by a statistician at ICES and provided to the authors. The primary author cleaned the database,
                  applied additional exclusion criteria, and performed the analyses. Statistical analyses were performed
                  using SAS Enterprise Guide 7.15 (SAS Institute). Programming code is available upon request to the
                  authors. A 2-sided p value of &lt;0.05 was considered significant.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">During the study period, 20,855 Ontario residents ≥60
                  years old were admitted to a hospital with a non-pathological femoral neck fracture and underwent
                  hemiarthroplasty or THA. After application of exclusion criteria, the final cohort consisted of 19,659
                  patients (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00379f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=7c7a705d-d2f0-400b-bb34-456618e57deb&type=jpeg&name=JBJS.24.00379f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">Study enrollment process. *Including International
                          Classification of Diseases, 10th Revision, Canada (ICD-10-CA) codes S72.010, S72.011, S72.080,
                          S72.081, S72.090, S72.091; excluding Ontario Health Insurance Plan (OHIP) diagnosis codes 170
                          and 198 and ICD-10 codes M8445 and 73314. †OHIP fee codes R440, R439, F101.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a> shows
                  baseline patient characteristics according to vital status at 10 years after fracture. Eighteen
                  percent (3,564) of the patients were alive at 10 years. The strongest factors associated with 10-year
                  survival included younger age, female sex, independent living status prior to the hip fracture, an ASA
                  class of I or II, and an absence of frailty, CHF, or dementia (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). After
                  controlling for other baseline characteristics, the following variables remained strongly associated
                  with being alive at 10 years: young age, living independently prior to hip fracture, and absence of
                  dementia. <a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a> shows
                  the associations between patient- and treatment-related variables and 10-year survival. Factors
                  associated with 10-year survival were similar between patients treated with hemiarthroplasty and those
                  who underwent THA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Baseline Characteristics of Study Cohort by Vital
                          Status at 10 Years After Hip Fracture<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 4174px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Exposure</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">No. (%)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Alive (N =
                          3,564)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Dead (N =
                          16,095)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total (N =
                          19,659)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 60-64 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">280
                          (7.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">258
                          (1.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">538
                          (2.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 65-69 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">433
                          (12.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">536
                          (3.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">969
                          (4.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 70-74 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">678
                          (19.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,141
                          (7.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,819
                          (9.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 75-79 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">897
                          (25.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,548
                          (15.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,445
                          (17.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 80-84 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">785
                          (22.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,307
                          (26.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,092
                          (25.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 85-89 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">394
                          (11.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,314
                          (26.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,708
                          (23.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥90 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97
                          (2.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,991
                          (18.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,088
                          (15.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,925
                          (82.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11,623
                          (72.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14,548
                          (74.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">639
                          (17.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,472
                          (27.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,111
                          (26.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Residence type</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Rural</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">524
                          (14.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,198
                          (13.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,722
                          (13.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Urban</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,040
                          (85.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13,897
                          (86.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16,937
                          (86.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Marginalization
                          quintile</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Least</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">838
                          (23.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,296
                          (20.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,134
                          (21.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">777
                          (21.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,355
                          (20.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,132
                          (21.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">757
                          (21.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,466
                          (21.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,223
                          (21.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">694
                          (19.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,201
                          (19.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,895
                          (19.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Most</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">498
                          (14.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,777
                          (17.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,275
                          (16.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Frailty</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,395
                          (67.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,879
                          (36.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8,274
                          (42.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,169
                          (32.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10,216
                          (63.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11,385
                          (57.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Long-term care
                          residence</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,435
                          (96.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12,057
                          (74.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15,492
                          (78.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">129
                          (3.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,038
                          (25.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,167
                          (21.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Home care services in
                          prior 6 mo</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,087
                          (86.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10,567
                          (65.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13,654
                          (69.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">477
                          (13.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,528
                          (34.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,005
                          (30.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ASA class</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> I or II</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,455
                          (40.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,405
                          (21.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,860
                          (24.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,760
                          (49.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7,601
                          (47.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9,361
                          (47.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> IV or V</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">349
                          (9.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,089
                          (31.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,438
                          (27.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Specific
                          comorbidities</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> CHF</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,279
                          (92.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11,555
                          (71.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14,834
                          (75.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">285
                          (8.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,540
                          (28.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,825
                          (24.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> COPD</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,254
                          (91.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12,689
                          (78.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15,943
                          (81.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">310
                          (8.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,406
                          (21.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,716
                          (18.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hypertension</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,128
                          (31.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,583
                          (22.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,711
                          (24.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,436
                          (68.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12,412
                          (77.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14,848
                          (75.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dementia</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,236
                          (90.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9,686
                          (60.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12,922
                          (65.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">328
                          (9.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,409
                          (39.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,737
                          (34.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diabetes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,942
                          (82.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12,284
                          (76.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15,226
                          (77.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">622
                          (17.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,811
                          (23.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,433
                          (22.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Rheumatoid
                          arthritis</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">148
                          (4.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">622
                          (3.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">770
                          (3.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,416
                          (95.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15,473
                          (96.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18,889
                          (96.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Fiscal year</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2002</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">436
                          (12.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,154
                          (13.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,590
                          (13.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2003</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">513
                          (14.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,255
                          (14.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,768
                          (14.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2004</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">531
                          (14.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,366
                          (14.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,897
                          (14.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2005</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">486
                          (13.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,177
                          (13.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,663
                          (13.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2006</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">491
                          (13.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,377
                          (14.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,868
                          (14.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2007</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">574
                          (16.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,414
                          (15.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,988
                          (15.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2008</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">533
                          (15.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,352
                          (14.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,885
                          (14.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgery &gt;48 hr from
                          admission</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,401
                          (67.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10,067
                          (62.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12,468
                          (63.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,163
                          (32.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,028
                          (37.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7,191
                          (36.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Treatment</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total hip
                          arthroplasty</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">569
                          (16.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,239
                          (7.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,808
                          (9.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hemiarthroplasty</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,995
                          (84.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14,856
                          (92.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17,851
                          (90.8%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">ASA = American Society of Anesthesiologists physical status class, CHF
                        = congestive heart failure, COPD = chronic obstructive pulmonary disorder.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Unadjusted and Adjusted Relative Risk Ratios for
                          10-Year Survival After Hip Fracture in Overall Cohort and by Treatment Group<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 3181.06px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.668699);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Exposure</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Proportion of Cohort Alive</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Unadjusted RR (95% CI)</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Adjusted RR<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">†</span> (95%
                          CI)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Overall
                          Cohort</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Hemiarthroplasty</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total Hip
                          Arthroplasty</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 60-64 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">52.04%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_34" class="annotation strong" style="position: unset;">3.38
                            (2.95-3.87)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_35" class="annotation strong" style="position: unset;">2.23
                            (2.02-2.46)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_36" class="annotation strong" style="position: unset;">2.28
                            (2.04-2.50)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_37" class="annotation strong" style="position: unset;">1.97
                            (1.60-2.43)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 65-69 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">44.69%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_38" class="annotation strong" style="position: unset;">2.90
                            (2.56-3.26)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_39" class="annotation strong" style="position: unset;">2.07
                            (1.89-2.25)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_40" class="annotation strong" style="position: unset;">2.18
                            (1.97-2.40)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_41" class="annotation strong" style="position: unset;">1.67
                            (1.36-2.04)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 70-74 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">37.27%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_42" class="annotation strong" style="position: unset;">2.42
                            (2.18-2.68)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_43" class="annotation strong" style="position: unset;">1.87
                            (1.73-2.03)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_44" class="annotation strong" style="position: unset;">1.92
                            (1.76-2.09)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_45" class="annotation strong" style="position: unset;">1.61
                            (1.32-1.96)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 75-79 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">26.04%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_46" class="annotation strong" style="position: unset;">1.69
                            (1.53-1.86)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_47" class="annotation strong" style="position: unset;">1.51
                            (1.39-1.63)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_48" class="annotation strong" style="position: unset;">1.54
                            (1.41-1.67)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_49" class="annotation strong" style="position: unset;">1.31
                            (1.08-1.60)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 80-84 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.42%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 85-89 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.37%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_50" class="annotation strong" style="position: unset;">0.54
                            (0.48-0.61)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_51" class="annotation strong" style="position: unset;">0.62
                            (0.56-0.69)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_52" class="annotation strong" style="position: unset;">0.62
                            (0.55-0.70)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_53" class="annotation strong" style="position: unset;">0.63
                            (0.46-0.86)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥90 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.14%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_54" class="annotation strong" style="position: unset;">0.20
                            (0.17-0.25)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_55" class="annotation strong" style="position: unset;">0.27
                            (0.22-0.34)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_56" class="annotation strong" style="position: unset;">0.28
                            (0.23-0.35)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_57" class="annotation strong" style="position: unset;">0.22
                            (0.10-0.50)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20.11%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_58" class="annotation strong" style="position: unset;">1.61
                            (1.48-1.75)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_59" class="annotation strong" style="position: unset;">1.56
                            (1.46-1.68)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_60" class="annotation strong" style="position: unset;">1.60
                            (1.45-1.73)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_61" class="annotation strong" style="position: unset;">1.44
                            (1.24-1.67)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.50%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Residence type</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Rural</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">19.25%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.07
                          (0.98-1.17)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.01
                          (0.94-1.09)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.03
                          (0.95-1.11)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.95
                          (0.80-1.12)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Urban</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17.95%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Marginalization
                          quintile</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Least</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20.27%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_62" class="annotation strong" style="position: unset;">1.33
                            (1.19-1.49)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.01
                          (0.93-1.10)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (0.91-1.11)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.07
                          (0.87-1.31)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.80%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_63" class="annotation strong" style="position: unset;">1.24
                            (1.11-1.38)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.02
                          (0.93-1.12)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.01
                          (0.91-1.12)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.10
                          (0.89-1.36)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17.93%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_64" class="annotation strong" style="position: unset;">1.18
                            (1.05-1.32)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.98
                          (0.90-1.07)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.98
                          (0.88-1.08)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.02
                          (0.82-1.28)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17.82%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_65" class="annotation strong" style="position: unset;">1.17
                            (1.04-1.31)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.02
                          (0.93-1.11)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.02
                          (0.92-1.13)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.01
                          (0.81-1.26)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Most</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.21%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Frailty</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">28.95%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_66" class="annotation strong" style="position: unset;">2.82
                            (2.63-3.02)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_67" class="annotation strong" style="position: unset;">1.19
                            (1.12-1.27)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_68" class="annotation strong" style="position: unset;">1.19
                            (1.11-1.27)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_69" class="annotation strong" style="position: unset;">1.21
                            (1.05-1.40)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.27%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Long-term care
                          residence</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">22.17%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_70" class="annotation strong" style="position: unset;">7.16
                            (6.01-8.54)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_71" class="annotation strong" style="position: unset;">2.68
                            (2.23-3.22)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_72" class="annotation strong" style="position: unset;">2.68
                            (2.22-3.24)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_73" class="annotation strong" style="position: unset;">2.51
                            (1.21-5.21)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.10%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Home care services in
                          prior 6 mo</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">22.61%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_74" class="annotation strong" style="position: unset;">2.85
                            (2.58-3.13)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_75" class="annotation strong" style="position: unset;">1.77
                            (1.62-1.94)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_76" class="annotation strong" style="position: unset;">1.79
                            (1.62-1.97)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_77" class="annotation strong" style="position: unset;">1.67
                            (1.33-2.09)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.94%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ASA class</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> I or II</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">29.94%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_78" class="annotation strong" style="position: unset;">4.66
                            (4.15-5.24)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_79" class="annotation strong" style="position: unset;">1.96
                            (1.76-2.19)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_80" class="annotation strong" style="position: unset;">1.92
                            (1.71-2.16)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_81" class="annotation strong" style="position: unset;">2.26
                            (1.64-3.11)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> III</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.80%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_82" class="annotation strong" style="position: unset;">2.93
                            (2.52-3.29)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_83" class="annotation strong" style="position: unset;">1.67
                            (1.50-1.85)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_84" class="annotation strong" style="position: unset;">1.63
                            (1.45-1.82)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_85" class="annotation strong" style="position: unset;">1.95
                            (1.43-2.67)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> IV or V</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.42%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Specific
                          comorbidities</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> CHF</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">22.10%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_86" class="annotation strong" style="position: unset;">3.74
                            (3.32-4.22)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_87" class="annotation strong" style="position: unset;">1.92
                            (1.71-2.15)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_88" class="annotation strong" style="position: unset;">1.93
                            (1.71-2.18)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_89" class="annotation strong" style="position: unset;">1.81
                            (1.33-2.46)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.91%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> COPD</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20.41%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_90" class="annotation strong" style="position: unset;">2.45
                            (2.18-2.75)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_91" class="annotation strong" style="position: unset;">1.86
                            (1.67-2.07)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_92" class="annotation strong" style="position: unset;">1.89
                            (1.68-2.13)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_93" class="annotation strong" style="position: unset;">1.66
                            (1.30-2.13)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.34%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hypertension</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">23.94%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_94" class="annotation strong" style="position: unset;">1.43
                            (1.33-1.54)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.03
                          (0.98-1.09)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.04
                          (0.98-1.10)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.01
                          (0.89-1.15)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.41%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dementia</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">25.04%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_95" class="annotation strong" style="position: unset;">5.14
                            (4.59-5.76)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_96" class="annotation strong" style="position: unset;">2.18
                            (1.93-2.46)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_97" class="annotation strong" style="position: unset;">2.20
                            (1.93-2.51)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_98" class="annotation strong" style="position: unset;">1.97
                            (1.38-2.79)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.87%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diabetes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">19.32%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_99" class="annotation strong" style="position: unset;">1.38
                            (1.26-1.50)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_100" class="annotation strong" style="position: unset;">1.22
                            (1.12-1.31)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_101" class="annotation strong" style="position: unset;">1.20
                            (1.11-1.30)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_102" class="annotation strong" style="position: unset;">1.27
                            (1.06-1.53)</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.03%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Rheumatoid
                          arthritis</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">19.22%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.06
                          (0.90-1.25)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_103" class="annotation strong" style="position: unset;">1.20
                            (1.05-1.36)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_104" class="annotation strong" style="position: unset;">1.22
                            (1.05-1.42)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.11
                          (0.86-1.42)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.08%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Fiscal year</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2002</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.83%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.91
                          (0.80-1.03)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_105" class="annotation strong" style="position: unset;">0.77
                            (0.70-0.85)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_106" class="annotation strong" style="position: unset;">0.76
                            (0.68-0.85)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.83
                          (0.65-1.05)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2003</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.53%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (0.89-1.13)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_107" class="annotation strong" style="position: unset;">0.89
                            (0.81-0.98)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_108" class="annotation strong" style="position: unset;">0.88
                            (0.79-0.97)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.97
                          (0.76-1.23)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2004</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.33%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.99
                          (0.88-1.12)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_109" class="annotation strong" style="position: unset;">0.89
                            (0.81-0.98)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_110" class="annotation strong" style="position: unset;">0.87
                            (0.79-0.97)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.99
                          (0.79-1.25)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2005</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.25%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.99
                          (0.88-1.12)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.95
                          (0.86-1.04)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.92
                          (0.82-1.02)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.11
                          (0.89-1.40)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2006</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17.12%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.93
                          (0.82-1.05)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.92
                          (0.84-1.01)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.91
                          (0.82-1.02)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (0.80-1.25)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2007</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">19.21%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.04
                          (0.92-1.17)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.01
                          (0.92-1.10)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.01
                          (0.92-1.11)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.97
                          (0.76-1.23)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2008</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.47%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgery &gt;48 hr from
                          admission</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">19.26%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_111" class="annotation strong" style="position: unset;">1.19
                            (1.11-1.28)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00
                          (0.95-1.06)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.03
                          (0.97-1.09)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.90
                          (0.80-1.02)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.17%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Treatment</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total hip
                          arthroplasty</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">31.47%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_112" class="annotation strong" style="position: unset;">1.88
                            (1.72-2.05)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_113" class="annotation strong" style="position: unset;">1.17
                            (1.10-1.25)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hemiarthroplasty</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.78%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">ASA = American Society of Anesthesiologists physical status class, CHF
                        = congestive heart failure, COPD = chronic obstructive pulmonary disorder, RR = risk ratio, CI =
                        confidence interval. Bold results are significant.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Model was adjusted for the following baseline covariates: sex,
                        rurality, marginalization, frailty, long-term care status, use of home care services, ASA class,
                        CHF, COPD, hypertension, dementia, diabetes, rheumatoid arthritis, and year.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Ten-Year
                Survival by Age and Sex</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Kaplan-Meier curves demonstrating the time to death of
                  patients treated with hemiarthroplasty or THA for hip fracture by age are shown in <a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figures 2</a> and <a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">3</a>. As patient
                  age increased, the risk of death increased. In the oldest subset of patients, there was a steep slope
                  during the first 2 years after surgery. This was more pronounced for males compared with females. For
                  example, approximately one-third of female patients ≥90 years old died in the first year after
                  surgery, and approximately half died by 2 years. Over half of the men ≥90 years old died in the first
                  year after surgery, and over two-thirds died by 2 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00379f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=7c7a705d-d2f0-400b-bb34-456618e57deb&type=jpeg&name=JBJS.24.00379f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;">Ten-year survival, with 95% CI, by age group in
                          females.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00379f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=7c7a705d-d2f0-400b-bb34-456618e57deb&type=jpeg&name=JBJS.24.00379f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;">Ten-year survival, with 95% CI, by age group in
                          males.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Age
                Thresholds</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Restricted cubic splines were used to assess the
                  nonlinearity of the association between age and survival while controlling for other variables.
                  Adjusted spline models identified an area of inflection around 73 years of age, when the risk of
                  10-year mortality began to increase more rapidly (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>). The
                  inflection point changed after stratification by sex (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>). For
                  female patients, in whom the majority of hip fractures occur, the inflection point remained at 73
                  years of age. The probability of surviving 10 years was lower for male patients than female patients
                  at all ages, and the rate of change of survival probability remained relatively linear until &gt;80
                  years old.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00379f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=7c7a705d-d2f0-400b-bb34-456618e57deb&type=jpeg&name=JBJS.24.00379f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;">Restricted cubic spline curve showing adjusted
                          relative risks and 95% confidence intervals for 10-year survival associated with increasing
                          age in the overall cohort, with a reference age of 60 years.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.24.00379f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=7c7a705d-d2f0-400b-bb34-456618e57deb&type=jpeg&name=JBJS.24.00379f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;">Restricted cubic spline curve showing adjusted
                          relative risks and 95% confidence intervals for 10-year survival associated with increasing
                          age in the overall cohort, stratified by sex, with a reference age of 60 years.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Probability
                of 10-Year Survival for Patient Groups</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The proportion of patients who were alive at 10 years
                  after hip fracture varied significantly by age (p &lt; 0.001, <a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 6</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.24.00379f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=7c7a705d-d2f0-400b-bb34-456618e57deb&type=jpeg&name=JBJS.24.00379f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;">Proportion of cohort patients alive at 10 years
                          following hip fracture, with order-5 polynomial trendlines.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Estimated probabilities for 10-year survival by patient
                  factors were determined using generalized linear regression models. Estimated survival ranged from
                  79.0% (95% confidence interval [CI] = 75.5% to 82.5%) for a 60 to 64-year-old female who was
                  relatively healthy (defined as ASA I or II, not living in a long-term care facility, no home care
                  services prior to fracture, no frailty, and no CHF, dementia, COPD, or diabetes) to 0.8% (95% CI =
                  0.6% to 1.0%) for a frail, ≥90-year-old male living in a long-term care institution. <a href=""
                    data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a> provides predicted estimated probabilities of 10-year
                  survival for patients with various baseline characteristics.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Estimated Probabilities of 10-Year Survival After
                          Hip Fracture Based on Various Patient Characteristics<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 2233px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Estimate (95% CI)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Female</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Male</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Healthy patient</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 60-64 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">79.0%
                          (75.5%-82.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64.6%
                          (59.6%-69.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 65-69 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75.2%
                          (72.1%-78.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59.5%
                          (55.2%-63.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 70-74 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70.0%
                          (67.2%-72.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53.1%
                          (49.2%-56.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 75-79 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60.7%
                          (57.9%-63.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42.8%
                          (39.3%-46.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 80-84 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45.2%
                          (42.4%-47.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.6%
                          (25.6%-31.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 85-89 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.3%
                          (28.5%-34.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.1%
                          (15.7%-20.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥90 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.8%
                          (12.9%-18.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.4%
                          (6.5%-10.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Average patient without
                          dementia</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 60-64 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46.6%
                          (42.6%-50.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.7%
                          (28.8%-36.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 65-69 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42.3%
                          (39.2%-45.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.8%
                          (25.9%-31.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 70-74 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37.2%
                          (35.0%-39.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.4%
                          (22.2%-26.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 75-79 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.6%
                          (27.9%-31.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.4%
                          (16.7%-20.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 80-84 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.7%
                          (18.4%-20.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.3%
                          (10.1%-12.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 85-89 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.6%
                          (11.4%-13.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.8%
                          (5.9%-7.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥90 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.9%
                          (4.7%-6.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0%
                          (2.3%-3.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Average patient with
                          dementia</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 60-64 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.3%
                          (24.1%-32.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.3%
                          (14.1%-20.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 65-69 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.7%
                          (21.4%-27.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.7%
                          (12.3%-17.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 70-74 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.7%
                          (18.2%-23.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.0%
                          (10.1%-13.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 75-79 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.3%
                          (13.4%-17.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.4%
                          (7.1%-9.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 80-84 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.2%
                          (8.0%-10.35%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.8%
                          (4.0%-5.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 85-89 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.4%
                          (4.5%-6.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.8%
                          (2.2%-3.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥90 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.4%
                          (1.8%-2.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.2%
                          (0.8%-1.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Frail patient living in
                          long-term care facility</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 60-64 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.0%
                          (17.8%-26.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.8%
                          (9.9%-15.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 65-69 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.9%
                          (15.6%-22.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.8%
                          (8.4%-13.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 70-74 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.6%
                          (12.9%-18.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.6%
                          (6.8%-10.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 75-79 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.2%
                          (9.2%-13.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.0%
                          (4.7%-7.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 80-84 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.5%
                          (5.2%-7.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.3%
                          (2.6%-4.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 85-89 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.8%
                          (2.9%-4.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.9%
                          (1.4%-2.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥90 yr old</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.6%
                          (1.1%-2.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8%
                          (0.6%-1.0%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">CI = confidence interval. Healthy patient = ASA I or II, not living in
                        a long-term care facility, no home care services prior to fracture, and no frailty, congestive
                        heart failure, dementia, chronic obstructive pulmonary disorder, or diabetes. Average patient =
                        all non-specified baseline characteristics are at their overall observed proportions within the
                        data set.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Approximately 1 in 6 patients with a hip fracture live at
                  least 10 years following their injury. This study identified factors that predicted 10-year survival
                  after hip fracture, including being relatively healthy (ASA I or II), an age of &lt;75 years, not
                  having dementia, and living independently prior to hip fracture. Furthermore, even though hip fracture
                  is considered a marker of overall comorbidity and is a significant risk factor for death for most
                  patients, this study shows that not all patients with a hip fracture are the same. Even older
                  individuals have substantial variability in patient characteristics and predicted outcomes after
                  surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Multiple randomized trials and large population-based
                  studies have analyzed short-term survival after hip fracture<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_10" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">13</span></a>. Typically, these studies have reported 30-day, 1-year, and
                  2-year risks of death. The few studies that have examined long-term survival after hip fracture have
                  identified similar patient factors associated with long-term survival, including younger age, female
                  sex, lower comorbidity burden, and higher functional status<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">15</span></a>. However, they are limited to single-institution studies
                  with limited sample size<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">17</span></a>. In keeping with our results, other studies found that
                  between 20% and 33% of patients survive at least 10 years after hip fracture<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_13" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">17</span></a>.
                  The strength of our study lies in our ability to link multiple databases to estimate long-term
                  survival and to identify factors associated with survival in a large population-based group of
                  patients. To our knowledge, this is the largest study to date analyzing long-term survival following
                  arthroplasty for hip fracture and the first to provide predicted estimates of 10-year survival for
                  different patient groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Physicians rely on clinical intuition and their assessment
                  of their patients’ overall health to predict patient outcomes after hip fracture. These predictions
                  are used to tailor treatment recommendations and discussions with patients and family members. In this
                  study, we predicted 10-year survival for subgroups of patients using data from close to 20,000
                  patients treated in Ontario, Canada. Although the individual factors associated with long-term
                  survival are not surprising, the effect sizes, or relative importance of the variables, are clinically
                  important. Our study reports evidence-based, user-friendly data that physicians can reference when
                  tailoring discussions with patients about treatment options and expected outcomes after surgery.
                  Furthermore, because the estimates are population-based, they include patients who typically do not
                  meet the inclusion criteria for randomized controlled trials (RCTs), such as those with unstable
                  systemic disease and those with dementia. Therefore, these estimates are more representative of the
                  average patient with a hip fracture in real life compared with results from traditional RCTs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Being able to predict which patients will live 10 years or
                  longer after a hip fracture is important for shared decision-making regarding treatment options. For
                  example, some studies have shown that THA is associated with improved pain control and function
                  compared with hemiarthroplasty and minimizes the risk of acetabular erosion<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_14" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_14" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">19</span></a>. However, THA is more technically demanding than
                  hemiarthroplasty, and it requires increased operative time, surgeon experience, and implant costs<a
                    href="" data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">6</span></a>. There are also increased risks of intraoperative blood
                  loss, hip dislocation, and short-term revision compared with hemiarthroplasty<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_16" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">21</span></a>. Hemiarthroplasty has good outcomes in most patients with
                  displaced femoral neck fractures. However, there is a subset of patients who may still benefit more
                  from THA. By identifying factors associated with 10-year survival and estimating the probability of
                  10-year survival based on patient characteristics, this study helps to identify the types of patients
                  who would potentially benefit from THA. This is important so that patients receive individualized
                  treatments that optimize their functional status while minimizing surgical risks and postoperative
                  complications. In addition, these results are important for appropriate, evidence-based resource
                  allocation so that the patients who receive more costly treatments are the ones who would actually
                  benefit from them.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">We attribute a portion of the reported association between
                  treatment and survival on residual and/or unmeasured confounding, where healthier and
                  higher-functioning patients were more likely to receive THA instead of hemiarthroplasty. This
                  phenomenon, described as confounding by indication, as well as the substantial practice variation in
                  this population limits our ability to draw conclusions about treatment efficacy in various subgroups<a
                    href="" data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">22</span></a>. Future studies should determine whether there are clinical
                  and functional differences in outcomes between hemiarthroplasty and THA in the subset of healthier
                  patients who are expected to live at least 10 years after surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">The ability to follow patients across the province helped
                  to minimize loss to follow-up in our study. The study eligibility period was selected to allow at
                  least 10 years of follow-up for all patients. We expected some patients to be lost to follow-up due to
                  loss of OHIP eligibility and/or moving out of the province. Since no death data were available for
                  them, those patients were considered alive at the end of the study. This may have led to a small
                  overestimation of the probability of living 10 years after hip fracture. Data from Statistics Canada
                  shows that 0.3% of Ontario residents aged ≥60 years left the province each year during our study
                  follow-up period<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">25</span></a>. In the worst-case scenario that all of these patients had
                  died and were incorrectly classified as alive, there would be up to 3% overestimation of the
                  probability of living 10 years in this study. However, the residents leaving Ontario were likely
                  healthier than the average patient who has sustained a hip fracture; therefore, we believe that the
                  small proportion of patients lost to follow-up does not undermine the validity of our results.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">We did not have access to details on race or ethnicity in
                  the available data sets. The absence of these descriptive data is a limitation that may affect study
                  generalizability. This limitation is partially mitigated by using a population-based cohort.
                  Approximately 30% of Ontarians identify as visible minorities; the 4 largest visible minority groups
                  are South Asian, Chinese, Black, and Filipino, accounting for 73.2% of visible minorities<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">26</span></a>. This information can be used to assess the
                  generalizability of our results to different populations.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">We considered whether there was a natural inflection point
                  in age that could help dichotomize patient groups with respect to long-term survival. We found this
                  inflection point to be approximately 73 years for females, but not for males, and the overall
                  association between age and long-term survival was relatively linear. Therefore, although age was a
                  strong factor, we were not able to identify a specific age that could be used in isolation to predict
                  long-term survival in this patient population.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">There is a complex relationship among marginalization,
                  multimorbidity, and risk of death in older patients<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">27</span></a>.
                  After controlling for other patient factors, we found no significant association between
                  marginalization and risk of death. These results may be attributable to Canada’s health-care system,
                  where all residents have a universal public health insurance plan for medically necessary health care.
                  The association between marginalization and risk of death may differ in countries with different
                  health-care structures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, this study of a large population-based
                  cohort helps to identify which patients will live 10 years or longer after a hip fracture treated with
                  arthroplasty. Factors associated with a higher likelihood of 10-year survival included female sex, an
                  age of &lt;75 years, a lower ASA score, independent living status prior to the hip fracture, and fewer
                  specific comorbidities. The large range of 10-year survival estimates for patient subgroups
                  demonstrates the variability in this patient population and identifies which patients are very likely
                  to be alive 10 years after hip fracture surgery and which are unlikely to live long after such
                  surgery. Our study results offer evidence-based predictions to inform discussions around hip fracture
                  surgery and anticipated outcomes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_7" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I197" target="_blank" data-id="link_8" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I197</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: Parts of this material are based on data and/or information
                  compiled and provided by the Canadian Institute for Health Information (CIHI). However, the analyses,
                  conclusions, opinions, and statements expressed in the article are those of the authors, and not
                  necessarily those of the CIHI. Parts of this material are based on data and information compiled and
                  provided by the Ontario Ministry of Health. The analyses, conclusions, opinions, and statements
                  expressed herein are solely those of the authors and do not reflect those of the funding or data
                  sources; no endorsement is intended or should be inferred. The authors thank IQVIA Solutions Canada,
                  Inc., for use of their Drug Information File. They thank the Toronto Community Health Profiles
                  Partnership for providing access to the Ontario Marginalization Index. This document used data adapted
                  from the Statistics Canada Postal Code<span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">OM</span> Conversion File, which is based on data licensed from Canada Post
                  Corporation, and/or data adapted from the Ontario Ministry of Health Postal Code Conversion File,
                  which contains data copied under license from ©Canada Post Corporation and Statistics Canada.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Swenning T</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Leighton J</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Nentwig M</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Dart B</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Hip fracture care and national
                  systems: The United States and Canada</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">OTA Int.</span> 2020 Mar 23;3(1):e073.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20fracture%20care%20and%20national%20systems%3A%20The%20United%20States%20and%20Canada&as_occt=title&as_sauthors=%20%22T%20Swenning%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Johansson T</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Bachrach-Lindström M</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Aspenberg P</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Jonsson D</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Wahlström O</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The total costs of a displaced
                  femoral neck fracture: comparison of internal fixation and total hip replacement. A randomised study
                  of 146 hips</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Int Orthop.</span> 2006 Feb;30(1):1-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20total%20costs%20of%20a%20displaced%20femoral%20neck%20fracture%3A%20comparison%20of%20internal%20fixation%20and%20total%20hip%20replacement.%20A%20randomised%20study%20of%20146%20hips&as_occt=title&as_sauthors=%20%22T%20Johansson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Bhandari M</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Einhorn TA</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Guyatt G</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Schemitsch EH</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Zura RD</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Sprague S</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Frihagen F</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Guerra-Farfán E</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Kleinlugtenbelt YV</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Poolman RW</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Rangan A</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Bzovsky S</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Heels-Ansdell D</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Thabane L</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Walter SD</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Devereaux PJ</span>; HEALTH
                Investigators. <span data-id="strong_3" class="annotation strong" style="position: unset;">Total Hip
                  Arthroplasty or Hemiarthroplasty for Hip Fracture</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">N Engl J Med.</span> 2019 Dec
                5;381(23):2199-208.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20Hip%20Arthroplasty%20or%20Hemiarthroplasty%20for%20Hip%20Fracture&as_occt=title&as_sauthors=%20%22M%20Bhandari%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Blomfeldt R</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Törnkvist H</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Eriksson K</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Söderqvist A</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Ponzer S</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Tidermark J</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">A randomised controlled trial
                  comparing bipolar hemiarthroplasty with total hip replacement for displaced intracapsular fractures of
                  the femoral neck in elderly patients</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 2007 Feb;89(2):160-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20randomised%20controlled%20trial%20comparing%20bipolar%20hemiarthroplasty%20with%20total%20hip%20replacement%20for%20displaced%20intracapsular%20fractures%20of%20the%20femoral%20neck%20in%20elderly%20patients&as_occt=title&as_sauthors=%20%22R%20Blomfeldt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Macaulay W</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Nellans KW</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Iorio R</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Garvin KL</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Healy WL</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Rosenwasser MP</span>; DFACTO
                Consortium. <span data-id="strong_5" class="annotation strong" style="position: unset;">Total hip
                  arthroplasty is less painful at 12 months compared with hemiarthroplasty in treatment of displaced
                  femoral neck fracture</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">HSS J.</span> 2008 Feb;4(1):48-54.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20hip%20arthroplasty%20is%20less%20painful%20at%2012%20months%20compared%20with%20hemiarthroplasty%20in%20treatment%20of%20displaced%20femoral%20neck%20fracture&as_occt=title&as_sauthors=%20%22W%20Macaulay%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Ekhtiari S</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Gormley J</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Axelrod DE</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Devji T</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Bhandari M</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Guyatt GH</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Total Hip Arthroplasty Versus
                  Hemiarthroplasty for Displaced Femoral Neck Fracture: A Systematic Review and Meta-Analysis of
                  Randomized Controlled Trials</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2020 Sep 16;102(18):1638-45.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=2521075" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Tol MC</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> van den Bekerom MP</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Sierevelt IN</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Hilverdink EF</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Raaymakers EL</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Goslings JC</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Hemiarthroplasty or total hip
                  arthroplasty for the treatment of a displaced intracapsular fracture in active elderly patients:
                  12-year follow-up of randomised trial</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2017 Feb;99-B(2):250-4.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hemiarthroplasty%20or%20total%20hip%20arthroplasty%20for%20the%20treatment%20of%20a%20displaced%20intracapsular%20fracture%20in%20active%20elderly%20patients%3A%2012-year%20follow-up%20of%20randomised%20trial&as_occt=title&as_sauthors=%20%22MC%20Tol%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Avery PP</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Baker RP</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Walton MJ</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Rooker JC</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Squires B</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Gargan MF</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Bannister GC</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Total hip replacement and
                  hemiarthroplasty in mobile, independent patients with a displaced intracapsular fracture of the
                  femoral neck: a seven- to ten-year follow-up report of a prospective randomised controlled
                  trial</span>. <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">J Bone
                  Joint Surg Br.</span> 2011 Aug;93(8):1045-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20hip%20replacement%20and%20hemiarthroplasty%20in%20mobile%2C%20independent%20patients%20with%20a%20displaced%20intracapsular%20fracture%20of%20the%20femoral%20neck%3A%20a%20seven-%20to%20ten-year%20follow-up%20report%20of%20a%20prospective%20randomised%20controlled%20trial&as_occt=title&as_sauthors=%20%22PP%20Avery%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Matheson FI</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Dunn JR</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Smith KLW</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Moineddin R</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Glazier RH</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Development of the Canadian
                  Marginalization Index: a new tool for the study of inequality</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Can J Public Health.</span> 2012 Apr
                30;103(8):S12-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20of%20the%20Canadian%20Marginalization%20Index%3A%20a%20new%20tool%20for%20the%20study%20of%20inequality&as_occt=title&as_sauthors=%20%22FI%20Matheson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;">Johns
                Hopkins University. <span data-id="emphasis_10" class="annotation emphasis" style="position: unset;">The
                  Johns Hopkins ACG® System,</span>, Technical Reference Guide, Version 10.0. 2011. Accessed 2024 Jul 9.
                <a href="https://www.hopkinsacg.org/document/acg-system-version-10-0-technical-reference-guide/"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.hopkinsacg.org/document/acg-system-version-10-0-technical-reference-guide/</a>.</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Tohidi M</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Mann SM</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> McIsaac MA</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Groome PA</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Comparative Effectiveness of
                  Total Hip Arthroplasty and Hemiarthroplasty for Femoral Neck Fracture</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2023;105(8):591-9.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=e20d045a-6df4-419d-9656-ca472699224d" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Hu F</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Jiang C</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Shen J</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Tang P</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Wang Y</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Preoperative predictors for
                  mortality following hip fracture surgery: a systematic review and meta-analysis</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Injury.</span> 2012
                Jun;43(6):676-85.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preoperative%20predictors%20for%20mortality%20following%20hip%20fracture%20surgery%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22F%20Hu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Sobolev B</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Guy P</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Sheehan KJ</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Kuramoto L</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Sutherland JM</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Levy AR</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Blair JA</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Bohm E</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Kim JD</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Harvey EJ</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Morin SN</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Beaupre L</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Dunbar M</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Jaglal S</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Waddell J</span>; <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Canadian Collaborative Study of
                  Hip Fractures. Mortality effects of timing alternatives for hip fracture surgery</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">CMAJ.</span> 2018 Aug
                7;190(31):E923-32.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Canadian%20Collaborative%20Study%20of%20Hip%20Fractures.%20Mortality%20effects%20of%20timing%20alternatives%20for%20hip%20fracture%20surgery&as_occt=title&as_sauthors=%20%22B%20Sobolev%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Paksima N</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Koval KJ</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Aharanoff G</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Walsh M</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Kubiak EN</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Zuckerman JD</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Egol KA</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Predictors of mortality after
                  hip fracture: a 10-year prospective study</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">Bull NYU Hosp Joint Dis.</span>
                2008;66(2):111-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predictors%20of%20mortality%20after%20hip%20fracture%3A%20a%2010-year%20prospective%20study&as_occt=title&as_sauthors=%20%22N%20Paksima%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Knauf T</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Bücking B</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Bargello M</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Ploch S</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Bliemel C</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Knobe M</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Ruchholtz S</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Eschbach D</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Predictors of long-term
                  survival after hip fractures?-5-year results of a prospective study in Germany</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Arch Osteoporos.</span>
                2019 Mar 16;14(1):40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predictors%20of%20long-term%20survival%20after%20hip%20fractures%3F-5-year%20results%20of%20a%20prospective%20study%20in%20Germany&as_occt=title&as_sauthors=%20%22T%20Knauf%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Avery PP</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Baker RP</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Walton MJ</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Rooker JC</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Squires B</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Gargan MF</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Bannister GC</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Total hip replacement and
                  hemiarthroplasty in mobile, independent patients with a displaced intracapsular fracture of the
                  femoral neck: a seven- to ten-year follow-up report of a prospective randomised controlled
                  trial</span>. <span data-id="emphasis_16" class="annotation emphasis" style="position: unset;">J Bone
                  Joint Surg Br.</span> 2011 Aug;93(8):1045-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20hip%20replacement%20and%20hemiarthroplasty%20in%20mobile%2C%20independent%20patients%20with%20a%20displaced%20intracapsular%20fracture%20of%20the%20femoral%20neck%3A%20a%20seven-%20to%20ten-year%20follow-up%20report%20of%20a%20prospective%20randomised%20controlled%20trial&as_occt=title&as_sauthors=%20%22PP%20Avery%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> von Friesendorff M</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Besjakov J</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Åkesson K</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Long-term survival and fracture
                  risk after hip fracture: a 22-year follow-up in women</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">J Bone Miner Res.</span> 2008
                Nov;23(11):1832-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20survival%20and%20fracture%20risk%20after%20hip%20fracture%3A%20a%2022-year%20follow-up%20in%20women&as_occt=title&as_sauthors=%20%22M%20von%20Friesendorff%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Baker RP</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Squires B</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Gargan MF</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Bannister GC</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Total hip arthroplasty and
                  hemiarthroplasty in mobile, independent patients with a displaced intracapsular fracture of the
                  femoral neck. A randomized, controlled trial</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2006
                Dec;88(12):2583-9.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=984776"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Ravikumar KJ</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Marsh G</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Internal fixation versus
                  hemiarthroplasty versus total hip arthroplasty for displaced subcapital fractures of femur—13 year
                  results of a prospective randomised study</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Injury.</span> 2000 Dec;31(10):793-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Internal%20fixation%20versus%20hemiarthroplasty%20versus%20total%20hip%20arthroplasty%20for%20displaced%20subcapital%20fractures%20of%20femur%E2%80%9413%20year%20results%20of%20a%20prospective%20randomised%20study&as_occt=title&as_sauthors=%20%22KJ%20Ravikumar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Sonaje JC</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Meena PK</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Bansiwal RC</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Bobade SS</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Comparison of functional
                  outcome of bipolar hip arthroplasty and total hip replacement in displaced femoral neck fractures in
                  elderly in a developing country: a 2-year prospective study</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">Eur J Orthop Surg Traumatol.</span> 2018
                Apr;28(3):493-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20functional%20outcome%20of%20bipolar%20hip%20arthroplasty%20and%20total%20hip%20replacement%20in%20displaced%20femoral%20neck%20fractures%20in%20elderly%20in%20a%20developing%20country%3A%20a%202-year%20prospective%20study&as_occt=title&as_sauthors=%20%22JC%20Sonaje%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Wang Z</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Bhattacharyya T</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Outcomes of Hemiarthroplasty
                  and Total Hip Arthroplasty for Femoral Neck Fracture: A Medicare Cohort Study</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span>
                2017 May;31(5):260-3.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20Hemiarthroplasty%20and%20Total%20Hip%20Arthroplasty%20for%20Femoral%20Neck%20Fracture%3A%20A%20Medicare%20Cohort%20Study&as_occt=title&as_sauthors=%20%22Z%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Tohidi M</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Mann SM</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Groome PA</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Total hip arthroplasty versus
                  hemiarthroplasty for treatment of femoral neck fractures: a population-based analysis of practice
                  variation in Ontario, Canada</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2023 Feb;105-B(2):180-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20hip%20arthroplasty%20versus%20hemiarthroplasty%20for%20treatment%20of%20femoral%20neck%20fractures%3A%20a%20population-based%20analysis%20of%20practice%20variation%20in%20Ontario%2C%20Canada&as_occt=title&as_sauthors=%20%22M%20Tohidi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;">Statistics
                Canada. <span data-id="strong_22" class="annotation strong" style="position: unset;">Table 17-10-0005-01
                  Population estimates on July 1st, by age and gender</span>. 2024 Feb 21. Accessed 2024 Jul 9. <a
                  href="https://www150.statcan.gc.ca/t1/tbl1/en/tv.action?pid=1710000501" target="_blank"
                  data-id="link_2" class="link"
                  style="position: unset;">https://www150.statcan.gc.ca/t1/tbl1/en/tv.action?pid=1710000501</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Table%2017-10-0005-01%20Population%20estimates%20on%20July%201st%2C%20by%20age%20and%20gender&as_occt=title&as_sauthors=%20%22Statistics%20Canada%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;">Statistics
                Canada. <span data-id="strong_23" class="annotation strong" style="position: unset;">Table 17-10-0014-01
                  Estimates of the components of international migration, by age and sex, annual</span>. Accessed 2024
                Jul 9. <a href="https://www150.statcan.gc.ca/t1/tbl1/en/tv.action?pid=1710001501" target="_blank"
                  data-id="link_3" class="link"
                  style="position: unset;">https://www150.statcan.gc.ca/t1/tbl1/en/tv.action?pid=1710001501</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Table%2017-10-0014-01%20Estimates%20of%20the%20components%20of%20international%20migration%2C%20by%20age%20and%20sex%2C%20annual&as_occt=title&as_sauthors=%20%22Statistics%20Canada%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;">Statistics
                Canada. <span data-id="strong_24" class="annotation strong" style="position: unset;">Table 17-10-0015-01
                  Estimates of the components of interprovincial migration, by age and sex, annual</span>. Accessed 2024
                Jul 9. <a href="https://doi.org/10.25318/1710001501-eng" target="_blank" data-id="link_4" class="link"
                  style="position: unset;">https://doi.org/10.25318/1710001501-eng</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Table%2017-10-0015-01%20Estimates%20of%20the%20components%20of%20interprovincial%20migration%2C%20by%20age%20and%20sex%2C%20annual&as_occt=title&as_sauthors=%20%22Statistics%20Canada%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;">Government
                of Ontario. <span data-id="strong_25" class="annotation strong" style="position: unset;">Ethnic origin
                  and visible minorities</span>. Accessed 2024 Jul 9. <a
                  href="https://www.ontario.ca/document/2016-census-highlights/fact-sheet-9-ethnic-origin-and-visible-minorities#:%7E:text=South%20Asian%20was%20the%20single,and%20West%20Asian%20(4.0%25"
                  target="_blank" data-id="link_5" class="link"
                  style="position: unset;">https://www.ontario.ca/document/2016-census-highlights/fact-sheet-9-ethnic-origin-and-visible-minorities#:∼:text=South%20Asian%20was%20the%20single,and%20West%20Asian%20(4.0%25</a>).</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ethnic%20origin%20and%20visible%20minorities&as_occt=title&as_sauthors=%20%22Government%20of%20Ontario%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Alarilla A</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Mondor L</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Knight H</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Hughes J</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Koné AP</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Wodchis WP</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Stafford M</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Socioeconomic gradient in
                  mortality of working age and older adults with multiple long-term conditions in England and Ontario,
                  Canada</span>. <span data-id="emphasis_23" class="annotation emphasis" style="position: unset;">BMC
                  Public Health.</span> 2023 Mar 11;23(1):472.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Socioeconomic%20gradient%20in%20mortality%20of%20working%20age%20and%20older%20adults%20with%20multiple%20long-term%20conditions%20in%20England%20and%20Ontario%2C%20Canada&as_occt=title&as_sauthors=%20%22A%20Alarilla%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="infographic_reference" class="annotation infographic_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node infographic" data-id="infographic" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Infographic</div><a class="link jbjs_tracking" jbjs_tracking_type="download"
                jbjs_tracking_data="{&quot;id&quot;:&quot;7c7a705d-d2f0-400b-bb34-456618e57deb&quot;,&quot;type&quot;:&quot;infographic&quot;,&quot;topics&quot;:[]}"
                href="/php/content/content_api.php?op=download&rsuite_id=7c7a705d-d2f0-400b-bb34-456618e57deb&type=infographic"
                jbjs_tracking_source="reader" style="position: unset;">Download</a>
              <div class="infographic-wrapper" style="position: unset;">
                <div class="viewer" style="position: unset;">
                  <div class="progress-bar" style="position: unset; width: 100%; display: none;"></div>
                  <div class="pages" style="position: unset;"><canvas class="page" width="337" height="189"></canvas>
                  </div>
                  <div class="toolbar" style="position: unset;"><button id="zoom-out" class=""
                      style="position: unset;">-</button><button id="zoom-in" class=""
                      style="position: unset;">+</button><button id="full-screen" class="" style="position: unset;">Full
                      screen ON</button></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;7c7a705d-d2f0-400b-bb34-456618e57deb&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=7c7a705d-d2f0-400b-bb34-456618e57deb&type=supplement&name=12317"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D7c7a705d-d2f0-400b-bb34-456618e57deb%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2073</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00379</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 18, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_24"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Queen’s
                            University, Kingston, Ontario, Canada</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;7c7a705d-d2f0-400b-bb34-456618e57deb&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=7c7a705d-d2f0-400b-bb34-456618e57deb&type=pdf&name=JBJS.24.00379.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=7c7a705d-d2f0-400b-bb34-456618e57deb&type=pdf&name=JBJS.24.00379.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_32"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This study was
                          supported by the Frederick Banting and Charles Best Canadian Graduate Scholarship from the
                          Canadian Institutes of Health Research, Physician Services Incorporated Resident Research
                          Award, and Dr. John Kostuik Fellowship in Orthopaedic Surgery. The funding sources did not
                          play a role in the investigation. The <span data-id="strong_33" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I196"
                            target="_blank" data-id="link_6" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I196</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;7c7a705d-d2f0-400b-bb34-456618e57deb&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=7c7a705d-d2f0-400b-bb34-456618e57deb&type=zip&name=JBJS.24.00379.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mina Tohidi, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:mina.tohidi@queensu.ca" class=""
                    style="position: unset;">mina.tohidi@queensu.ca</a><span class=""
                    style="position: unset;">;</span><a href="mailto:mina.tohidi@gmail.com" class=""
                    style="position: unset;">mina.tohidi@gmail.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7661-2052" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7661-2052</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">George Grammatopoulos, MD, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7444-9498" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7444-9498</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Stephen M. Mann, MD, MMEd<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4711-8079" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4711-8079</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alexandra Pysklywec, BSc<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Patti A. Groome, PhD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4823-313X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4823-313X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Surgery, Queen’s University, Kingston, Ontario, Canada</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">The Ottawa Hospital, Ottawa, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Division of Cancer Care and Epidemiology, Cancer Research Institute at Queen’s
                University, Kingston, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 36404.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
