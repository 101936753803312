import type { Article } from "../../../../../types";

export const Article523: Article = {
	id: 523,
	rsuiteId: "87d19b96-3f06-4de6-8efc-22522d7ef802",
	type: "scientific article",
	title:
		"Tibial Baseplate Migration Is Not Associated with Change in Patient-Reported Outcome Measures and Clinical Scores After TKA",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=87d19b96-3f06-4de6-8efc-22522d7ef802&type=jpeg&name=JBJS.23.00957f1",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Tibial Baseplate Migration Is Not Associated with Change
                in Patient-Reported Outcome Measures and Clinical Scores After TKA</div>
              <div class="text subtitle" style="position: unset;">A Secondary Analysis of 5 Radiostereometric Analysis
                Studies with 10-Year Follow-up</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Thies J.N.
                      van der Lelij, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Bart L.
                      Kaptein, MSc, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_37" class="annotation strong"
                          style="position: unset;">Background:</span> Radiostereometric analysis (RSA) provides highly
                        accurate data about the migration of a total knee arthroplasty (TKA) component. However,
                        patient-reported outcome measures (PROMs) reflect the patients’ perspective of their functional
                        status, pain, and overall health after TKA. The aim of this study was to evaluate the
                        association between tibial implant migration and change in postoperative PROMs and clinical
                        scores, using data pooled from long-term follow-up RSA studies.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_38" class="annotation strong"
                          style="position: unset;">Methods:</span> Individual implant migration data were collected from
                        5 randomized RSA studies, including a total of 300 patients with 6 distinct TKA implant designs
                        (all Stryker). Tibial implant migration (maximum total point motion [MTPM]) was evaluated with
                        RSA at 3 months, 1 year, and 2, 5, 7, and 10 years postoperatively. The Knee Society Score
                        (KSS)-Knee and KSS-Function and Knee Injury and Osteoarthritis Outcome Score (KOOS) subscales
                        were collected in all studies at the same follow-up times. Linear mixed-effects models, with
                        adjustment for TKA implant design and patient characteristics, were used to analyze the data.
                        The 3-month follow-up visit was used as the baseline to assess the association between implant
                        migration and PROMs across the 10-year follow-up.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_39" class="annotation strong"
                          style="position: unset;">Results:</span> No association between tibial implant migration and
                        change in KSS-Knee (p = 0.384), KSS-Function (p = 0.737), KOOS-Symptoms (p = 0.398), KOOS-Pain
                        (p = 0.699), KOOS-Activities of Daily Living (p = 0.205), KOOS-Sport and Recreation (p = 0.702),
                        or KOOS-Quality of Life (p = 0.368) was found across the entire follow-up. Similar results were
                        found when using the 2-year follow-up as the baseline, after which both cemented and uncemented
                        implants are expected to have stabilized.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_40" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Tibial baseplate migration was not associated
                        with postoperative worsening in PROMs or clinical scores in patients who underwent TKA. These
                        findings suggest that implant migration, as measured with RSA, measures a different parameter
                        (i.e., implant-bone fixation) than PROMs (i.e., patient perception) and clinical scores.
                        Therefore, to assess the performance and safety of TKA implant designs, RSA and PROMs cannot be
                        used interchangeably during the postoperative follow-up of patients and evaluation of the
                        fixation of knee implants.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_41" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Patient-reported outcome measures (PROMs) are increasingly
                  employed in orthopaedics, reflecting the change of focus from volume-based to value-based health-care
                  delivery by evaluating what matters and what is expected by patients after arthroplasty<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">4</span></a>.
                  Given the increasing health-care costs of and the excellent performance after most total knee
                  arthroplasties (TKAs), PROMs have been suggested as a feasible alternative to the traditional regular
                  outpatient clinic follow-up after TKA<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">6</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Radiostereometric analysis (RSA) is a highly accurate and
                  objective technique to detect minimal implant migration (0.1 to 0.2 mm) during early follow-up, which
                  is associated with implant (e.g., TKA implant) revision risk<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">8</span></a>. If TKA implant migration (i.e., implant fixation in the
                  bone) is associated with a decrease in PROMs and/or clinical knee scores, this would suggest that
                  these scores can be used interchangeably for the follow-up of patients who underwent TKA, thereby
                  reducing costs (e.g., no clinical visits) while maintaining quality and safety for the patients who
                  underwent TKA. To our knowledge, no studies have investigated the association of tibial baseplate
                  migration in patients who underwent TKA and PROMs and clinical scores. Recently, Steiner et al. found
                  that hip stem migration did not significantly influence PROMs at 2 years postoperatively in patients
                  who underwent total hip arthroplasty (THA)<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">9</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The aim of the present study was to assess whether TKA
                  tibial component migration, as measured with RSA, is associated with changes in postoperative PROMs
                  and clinical scores in patients who undergo TKA. We hypothesized that tibial implant migration is not
                  associated with postoperative improvement in PROMs or clinical scores, as they measure different
                  constructs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Design</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Pooling individual tibial baseplate migration data from
                  multiple, long-term RSA studies increases the statistical power to detect possible associations<a
                    href="" data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">10</span></a>. Long-term follow-up data were collected from 5 individual
                  RSA studies, all conducted at a single center (Hässleholm Hospital) with inclusion periods between
                  2006 and 2010 (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).
                  Patient selection, baseline characteristics, and surgical procedures of the studies have been
                  described in previous short-term and mid-term reports<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">11</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">17</span></a>. In short, each study was a randomized controlled trial
                  (RCT) using RSA to assess differences in migration between 2 TKA implant designs. The studies included
                  300 patients in total and 6 distinct TKA implant designs. The Triathlon cruciate-retaining (CR)
                  cemented implant was included in 4 studies, and the Triathlon CR uncemented peri-apatite (PA)-coated
                  implant was included in 2 studies. The other TKA designs were included in 1 study each: the Duracon CR
                  cemented, Triathlon posterior-stabilized (PS) cemented, Triathlon CR uncemented porous-coated, and
                  Triathlon short-stem (i.e., short-keeled) CR cemented implants (all Stryker).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Study Characteristics</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 808.912px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.918994);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Implant
                          Designs</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Inclusion
                          Period</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Patients</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">ClinicalTrials.gov Registration</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Study 1<a href=""
                            data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                              style="position: unset;">11</span></a><span data-id="superscript_9"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                              style="position: unset;">12</a></span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Triathlon CR cemented,
                          Duracon CR cemented</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2006</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NCT00436982</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Study 2<a href=""
                            data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                              style="position: unset;">13</span></a><span data-id="superscript_10"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Triathlon CR cemented,
                          Triathlon PS cemented</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2007</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NCT02522728</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Study 3<a href=""
                            data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                              style="position: unset;">14</span></a><span data-id="superscript_11"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                              style="position: unset;">15</a></span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Triathlon CR uncemented
                          PA-coated, Triathlon CR uncemented porous-coated</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2007 to
                          2008</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NCT03198533</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Study 4<a href=""
                            data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                              style="position: unset;">16</span></a><span data-id="superscript_12"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Triathlon CR cemented,
                          Triathlon short-stem CR cemented</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2008 to
                          2010</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NCT02525614</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Study 5<a href=""
                            data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                              style="position: unset;">17</span></a><span data-id="superscript_13"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Triathlon CR cemented,
                          Triathlon CR uncemented PA-coated</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2009 to
                          2010</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NCT02525601</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">RSA</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">In all studies, RSA radiographs were made on the first day
                  after the surgical procedure when weight-bearing was achieved. Subsequent examinations were performed
                  at 3 months, 1 year, and 2, 5, 7, and 10 years postoperatively. RSA radiographs were made with the
                  patient in a supine position with the knee in a calibration cage (Cage 10; RSA Biomedical). Migration
                  was calculated using marker-based analysis, with 8 tantalum beads with a diameter of 0.8 mm (RSA
                  Biomedical) inserted into the tibial bone and 5 beads inserted into the polyethylene insert. The same
                  experienced RSA analyst performed the migration calculations in all studies using all available
                  markers at each follow-up that could be matched to the baseline RSA image. The postoperative RSA
                  examination served as the reference for migration calculations in all studies. Analyses were performed
                  with UmRSA software (version 6.0; RSA Biomedical) in concordance with the International Organization
                  for Standardization (ISO) standard and RSA guidelines<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">19</span></a>. Maximum total point motion (MTPM), which is the length of
                  the translation vector of the marker in a rigid body with the greatest migration, was used as the
                  primary outcome measure for implant migration.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">PROMs and
                Clinical Scores</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The Knee Society Score (KSS) and Knee Injury and
                  Osteoarthritis Outcome Score (KOOS) were obtained preoperatively and at 3 months, 1 year, and 2, 5, 7,
                  and 10 years postoperatively in each study<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">21</span></a>. The KSS can be divided into the KSS-Knee and the
                  KSS-Function. The KOOS has 5 separately scored subscales: Symptoms, Pain, Activities of Daily Living,
                  Sport and Recreation (SR), and Quality of Life (QoL). All scores can range from 0 to 100, with higher
                  scores indicating better outcomes. Only the KSS-Knee requires clinical assessment of the knee,
                  including the assessment of the range of motion and stability, and was therefore considered to be a
                  clinical score. All patient-reported outcome scores were obtained from validated questionnaires.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Ethics and
                Registration</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">All studies were approved by the local ethics committee<a
                    href="" data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">17</span></a> and registered at ClinicalTrials.gov (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>), and all patients gave their informed consent. A protocol to
                  pool the data from the studies was presented to the medical ethics committee of Leiden University
                  Medical Center, which waived the need for approval under Dutch law (P.15.198).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">A linear mixed-effects model (LMM) was used to assess the
                  MTPM of each specific TKA implant design over the 10-year follow-up period, as this model takes the
                  correlation of measurements performed on the same patient into account and deals effectively with
                  missing values during follow-up; for patients who withdrew from the study (e.g., due to revision), all
                  measurements until withdrawal were included<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">23</span></a>. MTPM was log-transformed, computed as log<span
                    data-id="subscript_1" class="annotation subscript" style="position: unset;">10</span>(MTPM + 1), to
                  obtain a normally distributed variable. The presented values have been back-transformed to the
                  original scale. To assess the PROMs for the different TKA implant groups at the specific follow-up
                  times, a comparable generalized estimating equation (GEE) approach was used, as a normal distribution
                  could not be obtained through transformation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">To assess the association of tibial baseplate migration
                  (MTPM) with PROMs and clinical scores, separate LMMs were used for the different subscores (KSS-Knee,
                  KSS-Function, KOOS-Symptoms, KOOS-Pain, KOOS-ADL, KOOS-SR, or KOOS-QoL). The 3-month follow-up visit
                  was used as the baseline, as this was the first follow-up time in which RSA examinations and PROMs
                  were collected at the same time. The changes in PROMs and clinical scores at each follow-up visit were
                  calculated, as well as the tibial implant migration (MTPM) relative to the 3-month follow-up. The
                  models included a PROM variable (change in score relative to the 3-month follow-up score), a time
                  variable (3 months, 1 year, and 2, 5, 7, and 10 years), and an interaction term between time and the
                  PROM to reflect that PROM improvement and thereby its association with migration might change over
                  time. TKA implant design was included as a fixed factor, to account for the possible influence of
                  implant design and fixation method. Baseline patient characteristics (age, sex, American Society of
                  Anesthesiologists [ASA] score, and body mass index [BMI]) were added to the model as fixed factors as
                  well. For the random-effects structure, a random-intercept term was used and the remaining variability
                  was modeled with an autoregressive order-1 covariance structure. Beyond 2 years, after the initial
                  settling phase, both cemented and uncemented implants should not show any progression in migration.
                  Continuous implant migration beyond 2 years indicates insufficient fixation in the bone, and these
                  implants are considered at risk for future aseptic loosening. Therefore, we additionally assessed the
                  association between implant migration and PROMs and clinical scores after 2 years postoperatively.
                  Means were reported with 95% confidence intervals (CIs), and significance was set at p &lt; 0.05.
                  Analyses were performed using SPSS (version 26.0; IBM) and R software (version 4.1.0; The R
                  Foundation).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">A total of 300 patients were initially included in all
                  studies. During the 10-year follow-up period, 7 implants were revised because of infection (n = 2),
                  component loosening (n = 2), instability (n = 1), or insert wear (n = 2). The number of RSA
                  examinations included in our analysis is presented in <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 1</a>. At the
                  10-year follow-up, RSA migration data were available for 163 patients (<a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). The complete CONSORT (Consolidated Standards of Reporting
                  Trials) flow diagrams of all individual studies are provided in Appendix Figure A-1. The most common
                  reasons for not including RSA measurements were inadequate quality of radiographs (e.g., not adhering
                  to the RSA guidelines and the ISO standard) or missing RSA radiographs of patients who remained
                  included in the study (e.g., a missed follow-up time)<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">19</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00957f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=87d19b96-3f06-4de6-8efc-22522d7ef802&type=jpeg&name=JBJS.23.00957f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;">Number of RSA examinations included in the present
                          study at each follow-up time for each individual RCT.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">RSA
                Migration Measurements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The different TKA implant designs showed distinct
                  long-term migration patterns, with the Triathlon CR uncemented porous-coated TKA implant showing the
                  highest absolute migration (i.e., MTPM) throughout the follow-up period (<a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). At the 10-year follow-up, the mean migration of this TKA
                  implant was 1.84 mm (95% CI, 1.59 to 2.12 mm) compared with 0.74 mm (95% CI, 0.58 to 0.92 mm) for the
                  Duracon CR cemented TKA implant, 0.70 mm (95% CI, 0.62 to 0.78 mm) for the Triathlon CR cemented TKA
                  implant, 0.76 mm (95% CI, 0.61 to 0.94 mm) for the Triathlon PS cemented TKA implant, 0.74 mm (95% CI,
                  0.59 to 0.90 mm) for the Triathlon CR short-stem cemented TKA implant, and 0.88 mm (95% CI, 0.76 to
                  1.02 mm) for the Triathlon CR uncemented PA-coated TKA implant. There was no difference in migration
                  pattern (i.e., initial implant migration and later stabilization) of the Triathlon CR cemented TKA
                  implant within the 4 studies in which this implant was included (p = 0.98). Also, the Triathlon CR
                  uncemented PA-coated TKA implant showed a comparable migration pattern during the 10-year follow-up in
                  the 2 studies evaluating this design (p = 0.99) (see Appendix Fig. A-2).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.00957f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=87d19b96-3f06-4de6-8efc-22522d7ef802&type=jpeg&name=JBJS.23.00957f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;">The mean MTPM, derived from the LMM analysis,
                          during the 10-year follow-up. The error bars indicate the 95% CIs. The individual lines
                          represent distinct TKA implant designs.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Association
                of Tibial Implant Migration with PROMs and Clinical Scores</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">The LMMs showed no significant association between
                  KSS-Knee (p = 0.384), KSS-Function (p = 0.737), KOOS-Symptoms (p = 0.398), KOOS-Pain (p = 0.699),
                  KOOS-ADL (p = 0.205), KOOS-SR (p = 0.702), or KOOS-QoL (p = 0.368) and TKA tibial component migration
                  during the 10-year follow-up when 3 months postoperatively was used as the baseline measure. Analyzing
                  tibial baseplate migration beyond 2 years postoperatively (i.e., when implants are expected to show no
                  progression in migration), no association with KSS-Knee (p = 0.063), KSS-Function (p = 0.169),
                  KOOS-Symptoms (p = 0.174), KOOS-Pain (p = 0.476), KOOS-ADL (p = 0.424), and KOOS-SR (p = 0.764) could
                  be found. Only a significant association between MTPM and change in KOOS-QoL between 2 and 10 years of
                  follow-up was found (p = 0.045). The mean PROMs and clinical scores for each TKA implant design at the
                  specific follow-up times are presented in Appendix Table A-I.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">No association between TKA tibial component migration and
                  postoperative change in PROMs or clinical scores of patients during the 10-year follow-up after TKA
                  was found in the present study, meaning that worsening or less improvement of postoperative PROMs and
                  clinical scores over time does not indicate greater TKA implant migration. Tibial component migration
                  likely measures something different (i.e., the fixation of the implant in the bone) than PROMs (i.e.,
                  patient perception) or clinical scores. Thus, when evaluating implant performance, migration cannot be
                  used interchangeably with PROMs or clinical scores. Furthermore, at an individual patient level, PROMs
                  will not provide the ability to detect implant loosening at an early stage, which would be necessary
                  for newly introduced implants to have a guarantee of clinical benefit (i.e., pain relief, function,
                  and bone fixation) and patient safety.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">For the primary analysis, the 3-month follow-up visit was
                  used as the baseline, as this was the first follow-up time at which RSA examinations and PROMs were
                  collected at the same follow-up time point. Although the greatest improvement in PROMs and clinical
                  scores relative to the preoperative period generally occurs within the first few months after TKA,
                  this was not of interest for the present study, as our goal was to assess whether implant migration
                  (as measured with RSA) and PROMs could be used interchangeably during a 10-year, long-term follow-up
                  period of patients who underwent TKA. No association between MTPM and any of the PROMs or clinical
                  scores was found in our primary analyses. Using the 2-year follow-up as the baseline, there was no
                  association between MTPM and PROMs or clinical scores, except for a marginally significant association
                  between MTPM and KOOS-QoL (p = 0.045). However, the coefficients of the change in KOOS-QoL and of the
                  interaction term of time with the change in KOOS-QoL, as derived from the specific LMM, were both very
                  small and were well outside the range of clinically relevant changes in MTPM that could predict
                  aseptic loosening. Also, even though the Triathlon CR uncemented porous-coated TKA implant group
                  showed the greatest mean migration (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>), the
                  mean KOOS-QoL at all follow-up times was comparable or even slightly higher compared with the other
                  TKA implant groups (see Appendix Table A-I).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">The societal pressure to control health-care costs has
                  prompted increased emphasis on PROMs as a measure of the outcome of treatment. Although patients’
                  opinions are important, associations with more objective measures of treatment outcome are complex.
                  PROMs have been used as an easy method to control health-care costs in value-based health-care
                  initiatives, where value is measured as dollars relative to quality of care<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">24</span></a>. Value-added care is more complex than simply the relation
                  between money and 1 outcome measure, even more so because the outcome measure is a subjective measure
                  such as PROMs, which are complex entities<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_20" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">25</span></a>. There is a lack of consensus on specific score differences
                  for the various PROMs that are clinically important or important to patients<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">28</span></a>. Nevertheless, defining a “successful” TKA for a patient is
                  important, although a single validated, reliable, and responsive questionnaire addressing the
                  priorities of patients who underwent TKA has been elusive<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">29</span></a>.
                  That elusiveness is related to the multidimensional aspects of outcomes, which are related to the
                  implant-bone fixation (i.e., to the more technical aspects of implant surgery) as well as to whether
                  the patients still have symptoms and whether the surgical procedure met their preoperative
                  expectations<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">1</span></a>.
                  Moreover, various patient factors, including age, sex, BMI, and psychological factors, have been
                  suggested to influence the improvement in patient-reported outcomes<a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">30</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">33</span></a>. Improvement in PROMs after TKA can be related to aspects
                  other than the prosthesis itself, such as the patient’s social context and other patient factors.
                  However, the performance of the implant, such as fixation or loosening, can be measured objectively by
                  RSA. Furthermore, cutoff values for MTPM show implants that are at risk for loosening (and therefore
                  warrant close monitoring).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Clinical RSA studies only need a small number of patients
                  to achieve adequate power, because of the high accuracy of the technique<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">19</span></a>. In addition to the primary outcome of implant migration,
                  clinical RSA studies frequently collect multiple PROMs and clinical scores at each follow-up time as
                  secondary outcomes. Collecting these questionnaire responses and clinical scores is a time-consuming
                  and expensive process. However, individual RSA studies are not powered to detect differences in PROMs
                  or clinical scores between TKA implant groups, raising questions regarding the purpose of collecting
                  these scores in the clinical RSA trials. Also, the accuracy of RSA has been described as 0.1 to 0.2
                  mm, raising the question of whether such small micromotion could translate to clinical symptoms that
                  would indicate an increased risk of implant failure due to aseptic loosening<a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">34</span></a>. Several RSA migration thresholds, based on either mean
                  migration in TKA implant groups or migration of individual implants, have been described in the
                  literature as being associated with increased risk of revision due to loosening<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_27" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">34</span></a><span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">36</span></a>. For example, TKA implants with a mean migration between
                  0.5 and 1.6 mm are considered to be at risk of having revision rates of &gt;5% at 10 years<a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">7</span></a>. As for individual implants, MTPM of ≥0.3 mm between 2 and 5
                  years is often used to classify individual implants as continuously migrating and at risk for revision
                  due to loosening<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">34</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">The present study has several strengths. First, by pooling
                  individual patient data (RSA migration data, differences in PROMs, and clinical scores) from multiple
                  studies, the sample size and statistical power were increased. Second, 10-year follow-up RSA migration
                  studies are scarce, as most studies remain limited to 2-year follow-up. Third, detection of implant
                  loosening on standard radiographs by clinicians differs from measurement of implant migration as
                  measured with RSA. Whereas loosening as identified by clinicians is subjective and categorizes
                  implants as either loose or stable, RSA provides highly accurate and objective implant migration
                  measurements on a continuous scale and can detect excessive migration before patients experience
                  clinical symptoms. Finally, all included studies used the same marker-based RSA method and every
                  examination was analyzed by the same experienced RSA analysist, using the same software and
                  marker-selection method, increasing comparability between studies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">This study also had limitations. First, a limited number
                  of TKA implant designs from a single manufacturer were included, which may have limited the
                  generalizability of the results to other designs, although the concept of implant fixation in the bone
                  is a generic principle of all well-performing orthopaedic implants. Second, loss to follow-up was
                  present in all studies. This may have biased the association between tibial implant migration and
                  changes in PROMs if patient withdrawal was related to worsening PROMs or migration. However, only a
                  few patients underwent a revision surgical procedure and the main reason for fewer RSA measurements at
                  later follow-up points involved the quality of RSA radiographs. Furthermore, all data from patients
                  withdrawn from the study were still included in the analysis until their last available follow-up,
                  which will have minimized any bias that might occur.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, the lack of association between implant
                  migration and changes in postoperative PROMs or clinical scores suggests that implant migration
                  measures something different (i.e., the implant-bone fixation) than PROMs (i.e., patient function) and
                  clinical scores. This suggests that both are needed for a comprehensive evaluation of TKA implant
                  performance and they cannot be used interchangeably in the follow-up of patients who underwent TKA.
                  Future studies should address whether our findings can be generalized to other arthroplasty implant
                  designs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_4" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I87" target="_blank" data-id="link_5" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I87</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank Håkan Leijon for performing the RSA migration
                  measurements.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Tilbury C</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Haanstra TM</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Leichtenberg CS</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Verdegaal SH</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Ostelo RW</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> de Vet HC</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Nelissen RG</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Vliet Vlieland TP</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Unfulfilled expectations after
                  total hip and knee arthroplasty surgery: there is a need for better preoperative patient information
                  and education</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2016 Oct;31(10):2139-45.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Unfulfilled%20expectations%20after%20total%20hip%20and%20knee%20arthroplasty%20surgery%3A%20there%20is%20a%20need%20for%20better%20preoperative%20patient%20information%20and%20education&as_occt=title&as_sauthors=%20%22C%20Tilbury%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Tilbury C</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Haanstra TM</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Verdegaal SHM</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Nelissen RGHH</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> de Vet HCW</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Vliet Vlieland TPM</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Ostelo RW</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Patients’ pre-operative general
                  and specific outcome expectations predict postoperative pain and function after total knee and total
                  hip arthroplasties</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Scand J Pain.</span> 2018 Jul 26;18(3):457-66.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patients%E2%80%99%20pre-operative%20general%20and%20specific%20outcome%20expectations%20predict%20postoperative%20pain%20and%20function%20after%20total%20knee%20and%20total%20hip%20arthroplasties&as_occt=title&as_sauthors=%20%22C%20Tilbury%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Latijnhouwers DAJM</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Vlieland TPMV</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Marijnissen WJ</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Damen PJ</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Nelissen RGHH</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Gademan MGJ</span>; <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Longitudinal Leiden Orthopaedics
                  Outcomes of Osteoarthritis Study (LOAS) Group. Sex differences in perceived expectations of the
                  outcome of total hip and knee arthroplasties and their fulfillment: an observational cohort
                  study</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Rheumatol Int.</span> 2023 May;43(5):911-22.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Longitudinal%20Leiden%20Orthopaedics%20Outcomes%20of%20Osteoarthritis%20Study%20(LOAS)%20Group.%20Sex%20differences%20in%20perceived%20expectations%20of%20the%20outcome%20of%20total%20hip%20and%20knee%20arthroplasties%20and%20their%20fulfillment%3A%20an%20observational%20cohort%20study&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Hossain FS</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Konan S</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Patel S</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Rodriguez-Merchan EC</span>,
                <span data-id="annotation_26" class="annotation" style="position: unset;"> Haddad FS</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">The assessment of outcome after
                  total knee arthroplasty: are we there yet?</span><span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2015
                Jan;97-B(1):3-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20assessment%20of%20outcome%20after%20total%20knee%20arthroplasty%3A%20are%20we%20there%20yet%3F&as_occt=title&as_sauthors=%20%22FS%20Hossain%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Kingsbury SR</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Dube B</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Thomas CM</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Conaghan PG</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Stone MH</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Is a questionnaire and
                  radiograph-based follow-up model for patients with primary hip and knee arthroplasty a viable
                  alternative to traditional regular outpatient follow-up clinic?</span><span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2016
                Feb;98-B(2):201-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20a%20questionnaire%20and%20radiograph-based%20follow-up%20model%20for%20patients%20with%20primary%20hip%20and%20knee%20arthroplasty%20a%20viable%20alternative%20to%20traditional%20regular%20outpatient%20follow-up%20clinic%3F&as_occt=title&as_sauthors=%20%22SR%20Kingsbury%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Marsh JD</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Bryant DM</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> MacDonald SJ</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Naudie DD</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> McCalden RW</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Howard JL</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Bourne RB</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> McAuley JP</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Feasibility, effectiveness and
                  costs associated with a web-based follow-up assessment following total joint arthroplasty</span>.
                <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span>
                2014 Sep;29(9):1723-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Feasibility%2C%20effectiveness%20and%20costs%20associated%20with%20a%20web-based%20follow-up%20assessment%20following%20total%20joint%20arthroplasty&as_occt=title&as_sauthors=%20%22JD%20Marsh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Pijls BG</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Valstar ER</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Nouta KA</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Plevier JW</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Fiocco M</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Middeldorp S</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Nelissen RG</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Early migration of tibial
                  components is associated with late revision: a systematic review and meta-analysis of 21,000 knee
                  arthroplasties</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Acta Orthop.</span> 2012 Dec;83(6):614-24.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Early%20migration%20of%20tibial%20components%20is%20associated%20with%20late%20revision%3A%20a%20systematic%20review%20and%20meta-analysis%20of%2021%2C000%20knee%20arthroplasties&as_occt=title&as_sauthors=%20%22BG%20Pijls%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Nelissen RG</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Pijls BG</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Kärrholm J</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Malchau H</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Nieuwenhuijse MJ</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Valstar ER</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">RSA and registries: the quest
                  for phased introduction of new implants</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2011 Dec 21;93(Suppl 3):62-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=RSA%20and%20registries%3A%20the%20quest%20for%20phased%20introduction%20of%20new%20implants&as_occt=title&as_sauthors=%20%22RG%20Nelissen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Steiner DK</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Drivsholm NS</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Buchardt STE</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Laursen M</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">The influence of migration of
                  the Exeter V40 stem on patient reported outcome measures: a 2-year follow-up of 112 total hip
                  arthroplasties using radiostereometric analysis</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Eur J Orthop Surg Traumatol.</span> 2022
                Jan;32(1):167-74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20influence%20of%20migration%20of%20the%20Exeter%20V40%20stem%20on%20patient%20reported%20outcome%20measures%3A%20a%202-year%20follow-up%20of%20112%20total%20hip%20arthroplasties%20using%20radiostereometric%20analysis&as_occt=title&as_sauthors=%20%22DK%20Steiner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Riley RD</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Lambert PC</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Abo-Zaid G</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Meta-analysis of individual
                  participant data: rationale, conduct, and reporting</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">BMJ.</span> 2010 Feb 5;340:c221.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Meta-analysis%20of%20individual%20participant%20data%3A%20rationale%2C%20conduct%2C%20and%20reporting&as_occt=title&as_sauthors=%20%22RD%20Riley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Molt M</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Ljung P</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Toksvig-Larsen S</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Does a new knee design perform
                  as well as the design it replaces?</span><span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">Bone Joint Res.</span> 2012 Dec 1;1(12):315-23.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20a%20new%20knee%20design%20perform%20as%20well%20as%20the%20design%20it%20replaces%3F&as_occt=title&as_sauthors=%20%22M%20Molt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Molt M</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Ryd L</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Toksvig-Larsen S</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">A randomized RSA study
                  concentrating especially on continuous migration</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">Acta Orthop.</span> 2016
                Jun;87(3):262-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20randomized%20RSA%20study%20concentrating%20especially%20on%20continuous%20migration&as_occt=title&as_sauthors=%20%22M%20Molt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Molt M</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Toksvig-Larsen S</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Similar early migration when
                  comparing CR and PS in Triathlon™ TKA: a prospective randomised RSA trial</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Knee.</span> 2014
                Oct;21(5):949-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Similar%20early%20migration%20when%20comparing%20CR%20and%20PS%20in%20Triathlon%E2%84%A2%20TKA%3A%20a%20prospective%20randomised%20RSA%20trial&as_occt=title&as_sauthors=%20%22M%20Molt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Molt M</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Toksvig-Larsen S</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Peri-Apatite<span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">TM</span> enhances
                  prosthetic fixation in TKA-a prospective randomised RSA study</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">J Arthritis.</span> 2014;3(3):1-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Peri-ApatiteTM%20enhances%20prosthetic%20fixation%20in%20TKA-a%20prospective%20randomised%20RSA%20study&as_occt=title&as_sauthors=%20%22M%20Molt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Van Hamersveld KT</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Marang-Van De Mheen PJ</span>,
                <span data-id="annotation_72" class="annotation" style="position: unset;"> Nelissen RGHH</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Toksvig-Larsen S</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Peri-apatite coating decreases
                  uncemented tibial component migration: long-term RSA results of a randomized controlled trial and
                  limitations of short-term results</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Acta Orthop.</span> 2018 Aug;89(4):425-30.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Peri-apatite%20coating%20decreases%20uncemented%20tibial%20component%20migration%3A%20long-term%20RSA%20results%20of%20a%20randomized%20controlled%20trial%20and%20limitations%20of%20short-term%20results&as_occt=title&as_sauthors=%20%22KT%20Van%20Hamersveld%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Molt M</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Toksvig-Larsen S</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">2-year follow-up report on
                  micromotion of a short tibia stem. A prospective, randomized RSA study of 59 patients</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Acta Orthop.</span>
                2015;86(5):594-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=2-year%20follow-up%20report%20on%20micromotion%20of%20a%20short%20tibia%20stem.%20A%20prospective%2C%20randomized%20RSA%20study%20of%2059%20patients&as_occt=title&as_sauthors=%20%22M%20Molt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_76" class="annotation" style="position: unset;"> van Hamersveld KT</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Marang-van de Mheen PJ</span>,
                <span data-id="annotation_78" class="annotation" style="position: unset;"> Tsonaka R</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Valstar ER</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Toksvig-Larsen S</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Fixation and clinical outcome
                  of uncemented peri-apatite-coated <span data-id="emphasis_18" class="annotation emphasis"
                    style="position: unset;">versus</span> cemented total knee arthroplasty: five-year follow-up of a
                  randomised controlled trial using radiostereometric analysis (RSA)</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2017
                Nov;99-B(11):1467-76.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fixation%20and%20clinical%20outcome%20of%20uncemented%20peri-apatite-coated%20versus%20cemented%20total%20knee%20arthroplasty%3A%20five-year%20follow-up%20of%20a%20randomised%20controlled%20trial%20using%20radiostereometric%20analysis%20(RSA)&as_occt=title&as_sauthors=%20%22KT%20van%20Hamersveld%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text"
                style="position: unset;">International Organization for Standardization. <span data-id="strong_18"
                  class="annotation strong" style="position: unset;">ISO 16087:2013. Implants for surgery: roentgen
                  stereophotogrammetric analysis for the assessment of migration of orthopaedic implants</span>.
                Accessed 2024 May 6. <a href="https://www.iso.org/standard/55662.html" target="_blank" data-id="link_1"
                  class="link" style="position: unset;">https://www.iso.org/standard/55662.html</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=ISO%2016087%3A2013.%20Implants%20for%20surgery%3A%20roentgen%20stereophotogrammetric%20analysis%20for%20the%20assessment%20of%20migration%20of%20orthopaedic%20implants&as_occt=title&as_sauthors=%20%22International%20Organization%20for%20Standardization%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Valstar ER</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Gill R</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Ryd L</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Flivik G</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Börlin N</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Kärrholm J</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Guidelines for standardization
                  of radiostereometry (RSA) of implants</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">Acta Orthop.</span> 2005 Aug;76(4):563-72.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Guidelines%20for%20standardization%20of%20radiostereometry%20(RSA)%20of%20implants&as_occt=title&as_sauthors=%20%22ER%20Valstar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Insall JN</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Dorr LD</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Scott RD</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Scott WN</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Rationale of the Knee Society
                  clinical rating system</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 1989 Nov;(248):13-4.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Rationale%20of%20the%20Knee%20Society%20clinical%20rating%20system&as_occt=title&as_sauthors=%20%22JN%20Insall%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Roos EM</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Lohmander LS</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">The Knee injury and
                  Osteoarthritis Outcome Score (KOOS): from joint injury to osteoarthritis</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Health Qual Life
                  Outcomes.</span> 2003 Nov 3;1:64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Knee%20injury%20and%20Osteoarthritis%20Outcome%20Score%20(KOOS)%3A%20from%20joint%20injury%20to%20osteoarthritis&as_occt=title&as_sauthors=%20%22EM%20Roos%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Krueger C</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Tian L</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">A comparison of the general
                  linear mixed model and repeated measures ANOVA using a dataset with multiple missing data
                  points</span>. <span data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Biol
                  Res Nurs.</span> 2004 Oct;6(2):151-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20comparison%20of%20the%20general%20linear%20mixed%20model%20and%20repeated%20measures%20ANOVA%20using%20a%20dataset%20with%20multiple%20missing%20data%20points&as_occt=title&as_sauthors=%20%22C%20Krueger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Ranstam J</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Turkiewicz A</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Boonen S</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Van Meirhaeghe J</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Bastian L</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Wardlaw D</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Alternative analyses for
                  handling incomplete follow-up in the intention-to-treat analysis: the randomized controlled trial of
                  balloon kyphoplasty versus non-surgical care for vertebral compression fracture (FREE)</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">BMC Med Res
                  Methodol.</span> 2012 Mar 24;12:35.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Alternative%20analyses%20for%20handling%20incomplete%20follow-up%20in%20the%20intention-to-treat%20analysis%3A%20the%20randomized%20controlled%20trial%20of%20balloon%20kyphoplasty%20versus%20non-surgical%20care%20for%20vertebral%20compression%20fracture%20(FREE)&as_occt=title&as_sauthors=%20%22J%20Ranstam%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Porter ME</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">What is value in health
                  care?</span><span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">N Engl J
                  Med.</span> 2010 Dec 23;363(26):2477-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20is%20value%20in%20health%20care%3F&as_occt=title&as_sauthors=%20%22ME%20Porter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Squitieri L</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Bozic KJ</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Pusic AL</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">The role of patient-reported
                  outcome measures in value-based payment reform</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">Value Health.</span> 2017
                Jun;20(6):834-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20role%20of%20patient-reported%20outcome%20measures%20in%20value-based%20payment%20reform&as_occt=title&as_sauthors=%20%22L%20Squitieri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Henseler JF</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Kolk A</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> van der Zwaal P</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Nagels J</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Vliet Vlieland TP</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Nelissen RG</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">The minimal detectable change
                  of the Constant score in impingement, full-thickness tears, and massive rotator cuff tears</span>.
                <span data-id="emphasis_27" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2015 Mar;24(3):376-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20minimal%20detectable%20change%20of%20the%20Constant%20score%20in%20impingement%2C%20full-thickness%20tears%2C%20and%20massive%20rotator%20cuff%20tears&as_occt=title&as_sauthors=%20%22JF%20Henseler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Keurentjes JC</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Van Tol FR</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Fiocco M</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Schoones JW</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Nelissen RG</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Minimal clinically important
                  differences in health-related quality of life after total hip or knee replacement: a systematic
                  review</span>. <span data-id="emphasis_28" class="annotation emphasis" style="position: unset;">Bone
                  Joint Res.</span> 2012 May 1;1(5):71-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Minimal%20clinically%20important%20differences%20in%20health-related%20quality%20of%20life%20after%20total%20hip%20or%20knee%20replacement%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22JC%20Keurentjes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Deckey DG</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Verhey JT</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Gerhart CRB</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Christopher ZK</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Spangehl MJ</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Clarke HD</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Bingham JS</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">There are considerable
                  inconsistencies among minimum clinically important differences in TKA: a systematic review</span>.
                <span data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2023 Jan 1;481(1):63-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=There%20are%20considerable%20inconsistencies%20among%20minimum%20clinically%20important%20differences%20in%20TKA%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22DG%20Deckey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Ramkumar PN</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Harris JD</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Noble PC</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Patient-reported outcome
                  measures after total knee arthroplasty: a systematic review</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">Bone Joint Res.</span> 2015
                Jul;4(7):120-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient-reported%20outcome%20measures%20after%20total%20knee%20arthroplasty%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22PN%20Ramkumar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Bourne RB</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> McCalden RW</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> MacDonald SJ</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Mokete L</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Guerin J</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Influence of patient factors on
                  TKA outcomes at 5 to 11 years followup</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2007 Nov;464(464):27-31.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Influence%20of%20patient%20factors%20on%20TKA%20outcomes%20at%205%20to%2011%20years%20followup&as_occt=title&as_sauthors=%20%22RB%20Bourne%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Khatib Y</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Madan A</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Naylor JM</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Harris IA</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Do psychological factors
                  predict poor outcome in patients undergoing TKA? A systematic review</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2015 Aug;473(8):2630-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Do%20psychological%20factors%20predict%20poor%20outcome%20in%20patients%20undergoing%20TKA%3F%20A%20systematic%20review&as_occt=title&as_sauthors=%20%22Y%20Khatib%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Sveikata T</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Porvaneckas N</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Kanopa P</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Molyte A</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Klimas D</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Uvarovas V</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Venalis A</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Age, sex, body mass index,
                  education, and social support influence functional results after total knee arthroplasty</span>. <span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Geriatr Orthop Surg
                  Rehabil.</span> 2017 Jun;8(2):71-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Age%2C%20sex%2C%20body%20mass%20index%2C%20education%2C%20and%20social%20support%20influence%20functional%20results%20after%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22T%20Sveikata%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_142" class="annotation" style="position: unset;"> MacDonald SJ</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Charron KD</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Bourne RB</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Naudie DD</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> McCalden RW</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Rorabeck CH</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">The John Insall Award:
                  gender-specific total knee replacement: prospectively collected clinical outcomes</span>. <span
                  data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2008 Nov;466(11):2612-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20John%20Insall%20Award%3A%20gender-specific%20total%20knee%20replacement%3A%20prospectively%20collected%20clinical%20outcomes&as_occt=title&as_sauthors=%20%22SJ%20MacDonald%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Ryd L</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Albrektsson BE</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Carlsson L</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Dansgård F</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Herberts P</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Lindstrand A</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Regnér L</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Toksvig-Larsen S</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Roentgen stereophotogrammetric
                  analysis as a predictor of mechanical loosening of knee prostheses</span>. <span data-id="emphasis_35"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 1995
                May;77(3):377-83.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Roentgen%20stereophotogrammetric%20analysis%20as%20a%20predictor%20of%20mechanical%20loosening%20of%20knee%20prostheses&as_occt=title&as_sauthors=%20%22L%20Ryd%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Pijls BG</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Plevier JWM</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Nelissen RGHH</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">RSA migration of total knee
                  replacements</span>. <span data-id="emphasis_36" class="annotation emphasis"
                  style="position: unset;">Acta Orthop.</span> 2018 Jun;89(3):320-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=RSA%20migration%20of%20total%20knee%20replacements&as_occt=title&as_sauthors=%20%22BG%20Pijls%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Strategy for RSA migration
                  thresholds</span>. <span data-id="emphasis_37" class="annotation emphasis"
                  style="position: unset;">Acta Orthop.</span> 2016 Aug;87(4):432-3.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;87d19b96-3f06-4de6-8efc-22522d7ef802&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=87d19b96-3f06-4de6-8efc-22522d7ef802&type=supplement&name=10446"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D87d19b96-3f06-4de6-8efc-22522d7ef802%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">August 21, 2024;
                      106 (16): 1479</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00957</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">June 28, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_38"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopaedics, Hässleholm Hospital, Hässleholm, Sweden, and the Department of
                            Orthopaedics, Leiden University Medical Center, Leiden, The Netherlands</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_42"
                            class="annotation strong" style="position: unset;">A commentary by Nicholas Michael
                            Hernandez, MD, and Ha Young Chang, PA-C,</span> is linked to the online version of this
                          article.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=3ccc25ce-2be1-4fa1-a729-342d566cf5f1&native=1"
                              class="article-title" style="position: unset;">If the Patient-Reported Outcome Measures
                              After Total Knee Arthroplasty Are Good, Do Any Other Measurements Really Matter?<span
                                class="subtitle" style="position: unset;"><br style="position: unset;">Commentary on an
                                article by Thies J.N. van der Lelij, MD, et al.: “Tibial Baseplate Migration Is Not
                                Associated with Change in Patient-Reported Outcome Measures and Clinical Scores After
                                Total Knee Arthroplasty. A Secondary Analysis of 5 Radiostereometric Analysis Studies
                                with 10-Year Follow-up”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Hernandez,
                              Nicholas Michael; Chang, Ha Young</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(16):e35 | August 21, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Authors. Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the <a href="http://creativecommons.org/licenses/by/4.0/" target="_blank" data-id="link_2"
                            class="link" style="position: unset;">Creative Commons Attribution License 4.0</a> (CCBY),
                          which permits unrestricted use, distribution, and reproduction in any medium, provided the
                          original work is properly cited.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;87d19b96-3f06-4de6-8efc-22522d7ef802&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=87d19b96-3f06-4de6-8efc-22522d7ef802&type=pdf&name=JBJS.23.00957.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=87d19b96-3f06-4de6-8efc-22522d7ef802&type=pdf&name=JBJS.23.00957.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_43"
                            class="annotation strong" style="position: unset;">Disclosure:</span> All individual studies
                          were funded by Stryker, but Stryker had no part in the design, conduct, analysis, and
                          interpretation of the present study. The <span data-id="strong_44" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I86"
                            target="_blank" data-id="link_3" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I86</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;87d19b96-3f06-4de6-8efc-22522d7ef802&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=87d19b96-3f06-4de6-8efc-22522d7ef802&type=zip&name=JBJS.23.00957.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Thies J.N. van der Lelij, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:T.J.N.van_der_lelij@lumc.nl" class=""
                    style="position: unset;">T.J.N.van_der_lelij@lumc.nl</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0638-6568" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0638-6568</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Bart L. Kaptein, MSc, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8772-9985" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8772-9985</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Roula Tsonaka, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3466-5401" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3466-5401</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Rob G.H.H. Nelissen, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1228-4162" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1228-4162</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sören Toksvig-Larsen, MD, PhD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5932-3128" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5932-3128</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Perla J. Marang-van de Mheen, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1439-0989" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1439-0989</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedics, Leiden University Medical Center, Leiden, The
                Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Biomedical Data Sciences, Medical Statistics, Leiden University
                Medical Center, Leiden, The Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopaedics, Hässleholm Hospital, Hässleholm, Sweden</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Clinical Sciences, Lund University, Lund, Sweden</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Safety and Security Science, Centre for Safety in Healthcare, Delft University
                of Technology, Delft, The Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 25929.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
