export const cacheKeys = {
	cumulativeContentsDist: "cumulativeContentsDist",
	studypack: "studypack",
	practiceTest: "practiceTest",
	progress: "progress",
	examsCount: "examsCount",
	leaderboard: "leaderboard",
	userLeaderboard: "userLeaderboard",
	home: "home",
	itemsCount: "itemsCount",
	sessionConfig: "sessionConfig",
	avatars: "avatars",
	cme: "cme",
	userInfo: "userInfo",
};
export const DEFAULT_STALE_TIME = 1000 * 60 * 60 * 24; // 24 hours
