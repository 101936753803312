/* ----------------- React Native Paper Imports --------------- */
import { Appbar } from "react-native-paper";

/* ----------------- Memorang UI Imports --------------- */
import { Box, CustomBreadcrumb, CustomListSubheader } from "@memorang/ui";

/* ----------------- Expo Router Imports --------------- */
import { router } from "expo-router";

import { titleCase } from "@memorang/helpers";
/* ----------------- Global Components Imports --------------- */
import { ScrollContainer } from "@memorang/ui";
import ChallengeListItem from "./ChallengeListItem";

type Item = {
	id: number;
	title: string;
	description: string;
	imageUri: string;
};

type Props = {
	challenges: Item[];
	type: string;
	handlePressChallenge: (id: number) => void;
};

const MoreChallengesView = ({
	challenges,
	type,
	handlePressChallenge,
}: Props) => {
	const typeLabel = titleCase(type);

	const breadcrumbs = [
		{
			label: "Challenges",
			action: () => router.back(),
		},
		{
			label: typeLabel,
		},
	];

	return (
		<>
			<Appbar.Header mode="medium">
				<Appbar.BackAction onPress={() => router.back()} />
				<Appbar.Content
					title={<CustomBreadcrumb breadcrumbs={breadcrumbs} />}
				/>
			</Appbar.Header>
			<ScrollContainer
				maxWidth="sm"
				contentContainerStyle={{
					paddingBottom: 100,
				}}
			>
				<Box paddingTop={8}>
					<CustomListSubheader>
						{`${challenges.length} Challenges`}
					</CustomListSubheader>
					{challenges.map((challenge) => (
						<ChallengeListItem
							key={challenge.id}
							{...challenge}
							onPress={() => handlePressChallenge(challenge.id)}
						/>
					))}
				</Box>
			</ScrollContainer>
		</>
	);
};

export default MoreChallengesView;
