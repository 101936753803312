/* -----------------UI--------------- */
import { useAppTheme } from "@hooks/useAppTheme";
import { Box } from "@memorang/ui";
import { Button } from "react-native-paper";

type Props = {
	handleAction: (shouldDelete?: boolean) => void;
	primaryActionLabel?: string;
	disablePrimaryButton?: boolean;
	loadingPrimaryButton?: boolean;
};

const DeleteFormActions = (props: Props) => {
	const {
		handleAction,
		primaryActionLabel,
		disablePrimaryButton,
		loadingPrimaryButton,
	} = props;

	const labelCancel = "Cancel";
	const labelContinue = primaryActionLabel ?? "Continue";

	const handleCancelPress = () => {
		handleAction(false);
	};

	const theme = useAppTheme();
	return (
		<Box flexDirection="row" gap={16} paddingTop={16} justifyContent="flex-end">
			<Button
				textColor={theme.colors.onSurfaceVariant}
				onPress={handleCancelPress}
			>
				{labelCancel}
			</Button>
			<Button
				mode="contained"
				onPress={() => handleAction(true)}
				disabled={disablePrimaryButton}
				loading={loadingPrimaryButton}
			>
				{labelContinue}
			</Button>
		</Box>
	);
};

export default DeleteFormActions;
