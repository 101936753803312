import { ClerkLoaded, ClerkProvider } from "@clerk/clerk-expo";
import { Stack } from "expo-router";
import * as SecureStore from "expo-secure-store";
import { StatusBar } from "expo-status-bar";

import type { ThemeProp } from "react-native-paper/lib/typescript/types";

/* -----------------UI--------------- */
import { PaperProvider } from "react-native-paper";

import theme from "@configs/theme";
import { useThemeContext } from "@contexts/ThemeContext";
import { relayEnv } from "@memorang/configs";
import { ThemeProvider } from "@react-navigation/native";
/* -----------------Types--------------- */
import { RelayEnvironmentProvider } from "react-relay";
/* -----------------Helpers & Hooks--------------- */
import useCachedResources from "../src/hooks/useCachedResources";

/* -----------------Child components--------------- */
import { ThemeContextProvider } from "@contexts/ThemeContext";
import { ExamContextProvider } from "@features/exam";
import { buildType } from "@helpers/expo-extras";
import { AuthProvider } from "@memorang/applets";
import { identifyUser, initializeMixpanel } from "analytics";
import { GestureHandlerRootHOC } from "components/GestureHandlerRootHOC";

import { isWeb } from "@helpers/platform";
import { applyGlobalPolyfills } from "@memorang/helpers";
import { CommandBarProviderWrapper } from "components/CommandBarProviderWrapper";
import Head from "expo-router/head";

import { HapticProvider } from "@contexts/HapticContext";
import ReactQueryProvider from "@contexts/ReactQueryContext";
import ToastProvider from "@contexts/ToastContext";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import { getClerkPublishableKey } from "@helpers/find-onboarding";
import { initSentry } from "@helpers/lib/sentry";
import { useAppStore } from "@hooks/useAppStore";
import * as SentryNative from "@sentry/react-native";

applyGlobalPolyfills();
initSentry();
initializeMixpanel();

const tokenCache = {
	async getToken(key: string) {
		try {
			const item = await SecureStore.getItemAsync(key);
			return item;
		} catch (error) {
			console.error("SecureStore get item error: ", error);
			await SecureStore.deleteItemAsync(key);
			return null;
		}
	},
	async saveToken(key: string, value: string) {
		try {
			return await SecureStore.setItemAsync(key, value);
		} catch (_err) {
			return;
		}
	},
};

function App() {
	const { isLoadingComplete, isDarkMode } = useCachedResources();

	const RenderRoot = () => {
		const { isDarkMode } = useThemeContext();

		const finalTheme = theme(isDarkMode);

		return (
			<PaperProvider theme={finalTheme as unknown as ThemeProp}>
				<ThemeProvider
					value={{
						...finalTheme,
						// TODO: remove if react native paper gets a major version bump
						fonts: {
							...finalTheme.typography.fonts,
							heavy: finalTheme.typography.fonts.extraBold,
							// biome-ignore lint/correctness/noUndeclaredVariables: available in global namespace
						} as ReactNavigation.Theme["fonts"],
					}}
				>
					<StatusBar
						key={isDarkMode ? "dark" : "light"}
						style={isDarkMode ? "light" : "dark"}
					/>
					<ToastProvider>
						<HapticProvider>
							<BottomSheetModalProvider>
								<CommandBarProviderWrapper>
									<Stack
										screenOptions={{
											headerShown: false,
										}}
									/>
								</CommandBarProviderWrapper>
							</BottomSheetModalProvider>
						</HapticProvider>
					</ToastProvider>
				</ThemeProvider>
			</PaperProvider>
		);
	};
	const { appId } = useAppStore((store) => ({
		appId: store.app?.id,
	}));

	if (!isLoadingComplete) {
		return null;
	}
	const clerkPublishableKey = getClerkPublishableKey();

	return (
		//@ts-ignore
		<RelayEnvironmentProvider environment={relayEnv}>
			<ClerkProvider
				tokenCache={tokenCache}
				publishableKey={clerkPublishableKey}
			>
				<ClerkLoaded>
					{isWeb && (
						<Head>
							<link
								rel="shortcut icon"
								href={`https://icon.memorang.com/app-resources/${buildType}/ios/icon.png`}
							/>
						</Head>
					)}
					<GestureHandlerRootHOC>
						<ThemeContextProvider initIsDarkMode={isDarkMode}>
							<ReactQueryProvider>
								<AuthProvider
									app={buildType}
									appId={appId}
									identifyUser={identifyUser}
								>
									<ExamContextProvider>
										<RenderRoot />
									</ExamContextProvider>
								</AuthProvider>
							</ReactQueryProvider>
						</ThemeContextProvider>
					</GestureHandlerRootHOC>
				</ClerkLoaded>
			</ClerkProvider>
		</RelayEnvironmentProvider>
	);
}
export default isWeb ? App : SentryNative.wrap(App);
