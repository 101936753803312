/* -----------------Globals--------------- */
import { StatusBar } from "expo-status-bar";
import { useEffect, useState } from "react";
import { Keyboard, Platform } from "react-native";

import theme from "@configs/theme";
/* -----------------UI--------------- */
import { PaperProvider } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";

/* -----------------Helpers & Hooks--------------- */
import { useThemeContext } from "@contexts/ThemeContext";
import { useAilaStore, useQASearch } from "@features/session";
import { isIOS } from "@helpers/platform";

/* -----------------Types--------------- */
import type { ThemeProp } from "react-native-paper/lib/typescript/types";

import { pageViewTypes } from "@constants/tracking";
import useAnalytics from "@hooks/useAnalytics";
import { useAppStore } from "@hooks/useAppStore";
/* -----------------Child components--------------- */
import { AiLAMobile } from "@memorang/features";
import { Box } from "@memorang/ui";
import * as WebBrowser from "expo-web-browser";
import { z } from "zod";

const filterSourceIdsSchema = z
	.number()
	.array()
	.transform((val) => val.map(String));

const getFilterSourceIds = (itemId: string | undefined) => {
	try {
		const parsed = JSON.parse(itemId || "[]");
		const result = filterSourceIdsSchema.safeParse(parsed);
		return result.success ? result.data : [];
	} catch (_err) {
		return [];
	}
};

const Modal = () => {
	const { isDarkMode } = useThemeContext();

	const finalTheme = theme(isDarkMode);

	const {
		viewerId,
		examId,
		contextId,
		handleTracking,
		feedbackDialogParams,
		examName,
		userProfilePrompt,
	} = useQASearch();

	useAnalytics({
		type: pageViewTypes.aila,
	});
	const itemId = useAilaStore((state) => state.itemId);
	const selectedQuery = useAilaStore((state) => state.selectedQuery);
	const userPrompt = useAilaStore((state) => state.userPrompt);
	const isItemAnswered = useAilaStore((state) => state.isItemAnswered);
	const originalQuestionData = useAilaStore(
		(state) => state.originalQuestionData,
	);

	const { bottom } = useSafeAreaInsets();
	const { aiAssistant } = useAppStore((store) => ({
		aiAssistant: store.app.aiAssistant,
	}));
	const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

	const filterSourceIds = getFilterSourceIds(itemId);
	const isArticle = !!filterSourceIds.length;

	useEffect(() => {
		const showListener = Keyboard.addListener("keyboardWillShow", () => {
			setIsKeyboardVisible(true);
		});
		const hideListener = Keyboard.addListener("keyboardWillHide", () => {
			setIsKeyboardVisible(false);
		});

		return () => {
			showListener.remove();
			hideListener.remove();
		};
	}, []);

	return (
		<Box flex={1}>
			<PaperProvider theme={finalTheme as unknown as ThemeProp}>
				{/* Native modals have dark backgrounds on iOS, set the status bar to light content. */}
				{isIOS && <StatusBar style="light" />}
				<AiLAMobile
					defaultQuery={selectedQuery.length > 0 ? selectedQuery : undefined}
					vectorStoreParams={{
						examId,
						itemId: String(itemId),
						contextId,
					}}
					userId={String(viewerId)}
					context={examName}
					handleTracking={handleTracking}
					feedbackDialogParams={feedbackDialogParams}
					key={itemId}
					userPrompt={userPrompt}
					isItemAnswered={isItemAnswered}
					originalQuestionData={originalQuestionData}
					bottomAppBarStyle={
						Platform.OS === "ios" && !isKeyboardVisible
							? { paddingBottom: bottom }
							: undefined
					}
					assistantInfo={aiAssistant}
					activityType={isArticle ? "reader" : "review"}
					filterSourceIds={filterSourceIds}
					handleNavigateToReference={(url) => {
						WebBrowser.openBrowserAsync(url);
					}}
					extraDetails={userProfilePrompt}
				/>
			</PaperProvider>
		</Box>
	);
};

export default Modal;
