import type { Article } from "../../../../../types";

export const Article664: Article = {
	id: 664,
	rsuiteId: "454de204-bb0f-4bbe-8ab9-367fe313d3a5",
	type: "scientific article",
	title: "Xylazine-Associated Necrotic Upper-Extremity Wounds",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=454de204-bb0f-4bbe-8ab9-367fe313d3a5&type=jpeg&name=JBJS.24.00534f1a",
	subSpecialties: ["hand_wrist"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Xylazine-Associated Necrotic Upper-Extremity Wounds</div>
              <div class="text subtitle" style="position: unset;">A Single Hospital System’s Experience with 82 Patients
                and 125 Wounds</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Sebastian D.
                      Arango, BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jason C.
                      Flynn, BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Background:</span> The rise in xylazine-adulterated heroin and
                        fentanyl poses novel challenges to hand surgeons and a rising epidemic of necrotic
                        upper-extremity wounds. While prior case studies have focused on particularly severe and complex
                        xylazine-associated necrotic (XAN) wounds, the aim of this consecutive case series was to
                        characterize the variability of presentations (ranging from mild to severe) at a single
                        institution at the epicenter of the xylazine epidemic.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Methods:</span> Patients presenting to a tertiary referral center for
                        XAN upper-extremity wounds were retrospectively identified from emergency department visits and
                        hospital admissions between January 2021 and December 2023. Patient characteristics, clinical
                        findings, treatment, and hospitalization-related measures were recorded. Wounds were classified
                        according to the depth, density, size, and presence of osteomyelitis. All of the variables were
                        quantified using descriptive statistics.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Results:</span> In total, 82 patients with 125 XAN wounds were
                        included in the study. The mean age was 40.3 ± 8.2 years, and 57% of the patients were men. Of
                        the 125 wounds, 54% had associated osteomyelitis, 78% were confluent, and 47%involved more than
                        two-thirds of the anatomic region. Surgery was recommended for 78% of the patients and was
                        performed in 62%, with 13% undergoing amputation. Complications rates were high (77%) and
                        included bacteremia (40%) and death (5%). Patients were hospitalized a mean of 4.1 times and
                        were discharged against medical advice 2.8 times per year.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Conclusions:</span> This study presents a broad perspective on
                        demographic, social, and medical factors in patients with XAN wounds of the upper extremity.
                        Given the complexity and burden of this public health crisis, early intervention is important to
                        prevent complications and mitigate costs.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_35" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level IV</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Xylazine is a non-opioid veterinary tranquilizer (known as
                  “tranq” or “tranq dope”) that is often combined with opioids (e.g., heroin and fentanyl) as a cheap
                  method to prolong the euphoric effects of the drug, and it poses substantial new challenges in the
                  opioid epidemic in the United States<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">6</span></a>. The Drug Enforcement Administration has found xylazine in
                  drug samples from 48 states, but it is most prevalent in Philadelphia, Maryland, and Connecticut<a
                    href="" data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">6</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">In the acute setting, xylazine intoxication is complicated
                  by its action as an alpha-2 agonist, which causes central nervous system depression but does not
                  respond to conventional opioid overdose reversal agents<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">11</span></a>. In
                  addition to its central effects, xylazine has been associated with necrotic peripheral wounds<a
                    href="" data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">16</span></a>.
                  While the exact mechanism of tissue injury is poorly understood, peripheral injections of xylazine are
                  thought to cause local vasoconstriction and subsequent soft-tissue hypoxia and necrosis<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">11</span></a>.
                  Interestingly, and for unknown reasons, these wounds have also been described at remote sites from the
                  original injection<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">17</span></a>. In
                  large animals, xylazine is injected intravenously, intramuscularly, or subcutaneously at doses ranging
                  from 0.2 to 1.1 mg/kg<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">18</span></a>.
                  While necrotic wounds have not been described in animals, safety thresholds are not available for
                  humans as the drug is not approved by the U.S. Food and Drug Administration.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The soft-tissue damage caused by xylazine is distinct from
                  that caused by typical injection-related infections and poses substantial treatment challenges to hand
                  surgeons<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">19</span></a>. To
                  date, few studies have examined the severe upper-extremity wounds associated with the growing epidemic
                  of xylazine-adulterated intravenous (IV) drugs<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">12</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">16</span></a>. In 2021, xylazine was detected in 91% of heroin and
                  fentanyl samples in Philadelphia<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">20</span></a>.
                  Therefore, the aim of this study was to present a single medical system’s experience treating
                  xylazine-associated necrotic (XAN) upper-extremity wounds. We sought to describe the presentation,
                  clinical findings, wound classification, treatment, and hospitalization-related outcomes of
                  xylazine-associated upper-extremity wounds in people who inject drugs (PWID). This case series from
                  the center of the growing xylazine epidemic highlights the scope and challenges associated with such
                  infections.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study Design
                and Setting</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">After obtaining institutional review board approval, we
                  analyzed a retrospective case series of consecutive patients presenting to a tertiary referral center
                  that is an epicenter for xylazine-adulterated heroin and fentanyl<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">20</span></a>. Patients were treated by an academic-affiliated
                  upper-extremity practice consisting of 15 fellowship-trained hand surgeons. All of the patients were
                  admitted to the medical service with consultations from the infectious disease, substance abuse, and
                  hand surgery teams.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Population</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">This study included patients who presented to the
                  emergency department or were admitted to the medical service for necrotic upper-extremity wounds
                  associated with self-reported IV drug use (IVDU) between January 1, 2021, and December 31, 2023.
                  Patients were identified from an internal database with use of the following key words: “xylazine,”
                  “tranq” (the street name for xylazine), “necrotic,” “wound,” “osteomyelitis,” “amputation,” or
                  “debridement.” Clinical photographs associated with each patient were used to identify wounds that are
                  characteristic of xylazine<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">16</span></a>. Such findings of XAN wounds include necrotic hand,
                  forearm, or arm wounds with chronic-appearing ulcerations and exposure of the subcutaneous tissue,
                  tendon, or bone. Patients with acute necrotizing infections, acute abscesses without soft-tissue
                  necrosis, or wounds that were unrelated to IVDU were excluded from the study. Additionally, patients
                  with isolated thrombotic or embolic causes of necrosis, in which they experienced acute ischemia and
                  pain distal to an injection site (typically in the radial digits) and subsequently developed
                  necrosis<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">21</span></a>,
                  were excluded. A comprehensive chart review was performed for the 82 patients and 125 extremities that
                  met the inclusion criteria. Demographic information, clinical findings, and radiographic findings of
                  osteomyelitis were collected.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Wound
                Classification</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">To show the range of XAN upper-extremity wounds included
                  in the study, we classified each of the 125 wounds based on the physical examination and radiographic
                  findings. Two fellowship-trained hand surgeons (A.J.M and T.B.W.) with experience treating XAN
                  upper-extremity wounds classified each wound based on the clinical images from the patient charts.
                  Wounds were classified according to the depth, density, size, and presence of osteomyelitis (<a
                    href="" data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 1-A</a> through <a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 1-F</a>). The
                  wound depth was stratified as “superficial” if the epidermis and subcutaneous tissue were involved,
                  “deep” if there was exposure of fascia or tendons, and “osteomyelitis” if there was clearly exposed
                  bone on clinical examination or findings of osteomyelitis on imaging. The wound density categorization
                  described whether the necrotic wounds were numerous and separated by septa of normal or at-risk skin
                  between each wound (i.e., “scattered”), whether multiple wounds coalesced into a single necrotic wound
                  (i.e., “confluent”), or both (i.e., “scattered and confluent”). The wound size was classified based on
                  the percentage of the upper-extremity region that was involved. The regions included the flexor or
                  extensor surfaces of the digits, hand, forearm, and arm/shoulder. “Small” wounds encompassed less than
                  one-third of the flexor or extensor surface of the involved region, “medium” wounds encompassed up to
                  two-thirds, and “large” wounds encompassed more than two-thirds of the flexor or extensor surface of
                  the region. “Circumferential” wounds were considered large wounds on both the flexor and extensor
                  surfaces of the involved region.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_38"
                            class="annotation strong" style="position: unset;">Figs. 1-A through 1-F</span> Clinical
                          photographs demonstrating the appearance of XAN wounds from mild to severe cases. Wounds
                          typically progressed from small, superficial, scattered lesions (<span data-id="strong_39"
                            class="annotation strong" style="position: unset;">Fig. 1-A</span>) to large confluent
                          lesions with exposed fascia (<span data-id="strong_40" class="annotation strong"
                            style="position: unset;">Fig. 1-B</span>) and tendon (<span data-id="strong_41"
                            class="annotation strong" style="position: unset;">Fig. 1-C</span>). With continued xylazine
                          injections, progression of the necrotic wounds resulted in exposed bone with osteomyelitis
                          (<span data-id="strong_42" class="annotation strong" style="position: unset;">Figs. 1-D and
                            1-E</span>) and complete necrosis of the extremity (<span data-id="strong_43"
                            class="annotation strong" style="position: unset;">Fig. 1-F</span>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-A</div>
                    <div class="image-download" name="JBJS.24.00534f1a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=454de204-bb0f-4bbe-8ab9-367fe313d3a5&type=jpeg&name=JBJS.24.00534f1a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_17" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-B</div>
                    <div class="image-download" name="JBJS.24.00534f1b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=454de204-bb0f-4bbe-8ab9-367fe313d3a5&type=jpeg&name=JBJS.24.00534f1b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-C</div>
                    <div class="image-download" name="JBJS.24.00534f1c" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=454de204-bb0f-4bbe-8ab9-367fe313d3a5&type=jpeg&name=JBJS.24.00534f1c"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_4" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-D</div>
                    <div class="image-download" name="JBJS.24.00534f1d" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=454de204-bb0f-4bbe-8ab9-367fe313d3a5&type=jpeg&name=JBJS.24.00534f1d"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-E</div>
                    <div class="image-download" name="JBJS.24.00534f1e" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=454de204-bb0f-4bbe-8ab9-367fe313d3a5&type=jpeg&name=JBJS.24.00534f1e"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-F</div>
                    <div class="image-download" name="JBJS.24.00534f1f" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=454de204-bb0f-4bbe-8ab9-367fe313d3a5&type=jpeg&name=JBJS.24.00534f1f"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Medical and
                Surgical Treatment Variables</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Medical treatment variables included the administration of
                  narcotics while patients were being admitted, the IV antibiotics that were administered, and the need
                  for a peripherally inserted central catheter (PICC) for extended IV antibiotic administration.
                  Clinical notes were evaluated to determine if patients had undergone bedside irrigation and
                  debridement (I&D) for acute abscesses that were separate from the XAN wounds. Finally, the number of
                  patients who were recommended to undergo surgical intervention was recorded, as well as the number of
                  patients who refused surgery and the number for whom surgery was ultimately performed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Hospitalization-Related Measures</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">On admission to our hospitals, charts were reviewed to
                  determine the number of patients who had been transferred from an outside facility. The total number
                  of hospital encounters was recorded, along with the longest hospital length of stay (LOS) during the
                  study period. Discharge disposition, including the number of discharges against medical advice (AMA),
                  were recorded. All of the patients were recommended for follow-up in our outpatient hand surgery
                  clinic; we recorded the number of patients who adhered to the follow-up recommendations. Finally,
                  complications within 30 days of discharge from the hospital that were related to IVDU and the XAN
                  upper-extremity wounds were recorded.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Yearly
                Clinical Encounters</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">To determine the yearly clinical encounters for our
                  hospital system, we recorded the number of emergency department visits, hospitalizations,
                  upper-extremity surgeries, AMA discharges, and outpatient follow-up visits per year for each patient.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Descriptive statistics were used to analyze the data.
                  Continuous variables were presented as means and standard deviations, while categorical data were
                  reported as counts and percentages. All of the analyses were performed in Microsoft Excel.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">The baseline patient characteristics for the 82 patients
                  with XAN upper-extremity wounds are summarized in <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>. The
                  average age was 40.3 ± 8.2 years, and there was a slight male predominance (57%). Patients had high
                  rates of hepatitis C (61%), homelessness (27%), polysubstance abuse (92%), and coverage by Medicare
                  and/or Medicaid insurance (96%). The mean follow-up after hand surgery was 270 ± 385 days.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Baseline Patient Characteristics<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1033px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of patients</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_30" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40.3 ±
                          8.2</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47
                          (57%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (43%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Year of consult</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2021</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (29%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2022</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (33%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2023</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (38%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Comorbid
                          conditions</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> HCV</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50
                          (61%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> HIV</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hypertension</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (20%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diabetes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Psychiatric
                          illness</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (22%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Homelessness</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (27%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Polysubstance
                          abuse</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75
                          (92%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tobacco</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71
                          (87%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cocaine</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (44%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Alcohol</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (10%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Benzodiazepines</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (16%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Insurance type</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Private</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medicare/Medicaid</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">79
                          (96%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Advanced imaging</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59
                          (72%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Follow-up <span
                            data-id="emphasis_31" class="annotation emphasis"
                            style="position: unset;">(days)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">270 ±
                          385</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients with the percentage in
                        parentheses or as the mean ± standard deviation. HCV = hepatitis C, and HIV = human
                        immunodeficiency virus.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Just over half (52%) of the patients had bilateral wounds,
                  for a total of 125 affected upper extremities (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). The
                  frequency distribution of wounds with respect to size, depth, and density is depicted in a heat map in
                  <a href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figure 2</a>. Physical examination revealed that most wounds occurred on
                  the dorsal surface of the extremity (74%), involved the forearm (71%), were large, including
                  circumferential (47%), were confluent (78%), and had osteomyelitis (54%). Sensory (23%) and motor
                  (31%) deficits, tendon ruptures (23%), and contractures (33%) were common. More than half of the
                  cohort (54%) also had at least 1 non-upper-extremity XAN wound.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Physical Examination and Wound Characteristics<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1507px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">UE physical
                          examination<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Sensory deficits</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (23%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Motor deficits</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (31%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tendon rupture</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (23%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Circulatory
                          compromise</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Contracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (33%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">UE wound
                          characteristics</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Region<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Digit</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (24%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Hand</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33
                          (40%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Wrist</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (34%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Forearm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58
                          (71%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Upper arm or
                          shoulder</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Extremities
                          involved<span data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Unilateral</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39
                          (48%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Bilateral</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43
                          (52%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Extremity surface
                          involved§</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Dorsal</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">93
                          (74%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Volar</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (14%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Circumferential</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (12%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">UE wound
                          classification§</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Depth</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Superficial</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (20%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Deep</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32
                          (26%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Osteomyelitis</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68
                          (54%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Density</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Scattered only</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (22%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Confluent only</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86
                          (69%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Scattered and
                          confluent</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Size</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Small</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34
                          (27%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Medium</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32
                          (26%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Large</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59
                          (47%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Non-UE necrotic wound
                          regions<span data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (54%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unilateral LE</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (23%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bilateral LE</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (29%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Torso</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Head/neck</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients or wounds (as indicated
                        below) with the percentage in parentheses. UE = upper extremity, and LE = lower
                        extremity.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The proportions are based on the total number of patients (82).</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The total number of regions exceeds the number of wounds (125) as some
                        wounds involved &gt;1 region. §The proportions are based on the total number of wounds
                        (125).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00534f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=454de204-bb0f-4bbe-8ab9-367fe313d3a5&type=jpeg&name=JBJS.24.00534f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;">A heat map showing the relative distribution of
                          XAN upper-extremity wounds based on wound size, depth, and density. The green cells represent
                          less severe wounds, the yellow cells represent wounds with intermediate severity, and the red
                          cells represent the most severe wounds. Superf. = superficial, Osteo. = osteomyelitis, and S +
                          C = scattered and confluent.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a> shows
                  the medical and surgical treatments that were provided. All of the patients initially had local wound
                  care with wet-to-dry dressing changes. PICC lines were inserted in 24% of patients, but only 4% were
                  discharged to acute rehabilitation facilities for continued IV antibiotic treatment. Surgery was
                  offered to 64 patients; however, initial rejection was common (22%), with only 27% of those who
                  initially refused surgery (6% of the cohort) ultimately proceeding with operative intervention. I&D
                  was the most common surgical procedure (59%). Soft-tissue reconstruction was less common, with 11% of
                  patients receiving split- or full-thickness skin grafts and 9% undergoing flap reconstruction. Of the
                  7 patients who received flaps, 3 had documented flap failure, 1 died 2 months following the flap
                  procedure due to bacteremia, and 3 did not have documented follow-up with the hand surgery team after
                  discharge. Amputations were offered to 20 patients but were only performed in 11.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_12" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Medical and Surgical Treatment Variables<span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1468px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of patients</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Inpatient
                          narcotics</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72
                          (88%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Methadone</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54
                          (66%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Suboxone</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (12%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hydromorphone</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39
                          (48%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Inpatient intravenous
                          antibiotics</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">77
                          (94%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Vancomycin</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64
                          (78%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text"
                          style="position: unset;"> Piperacillin-tazobactam</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34
                          (42%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text"
                          style="position: unset;"> Ampicillin-sulbactam</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (11%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PICC line
                          insertion</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (24%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Removed before
                          discharge</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (21%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Discharged with
                          PICC</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgical intervention
                          recommended</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64
                          (78%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Patient initially
                          rejected surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (22%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Progressed to
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Continued nonoperative
                          treatment</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (16%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Surgery performed</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51
                          (62%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Surgeries per
                          patient</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.2 ±
                          1.4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Bedside I&D for
                          abscess<span data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (13%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Progressed to
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (11%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Continued nonoperative
                          treatment</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgical intervention
                          type</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> I&D</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48
                          (59%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (37%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (12%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  ≥3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (10%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Compartment
                          release</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Carpal tunnel
                          release</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Free or pedicled
                          flap</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Split- or full-thickness
                          skin graft</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (11%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> TMR or RPNI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Amputation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (13%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Digit or
                          transmetacarpal</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Transradial</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Transhumeral</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Glenohumeral</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Negative-pressure wound
                          dressing</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (13%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients with the percentage in
                        parentheses or as the mean ± standard deviation. PICC = peripherally inserted central catheter,
                        I&D = irrigation and debridement, TMR = targeted muscle reinnervation, and RPNI = regenerative
                        peripheral nerve interface.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">I&D performed at the bedside for acute abscesses that were separate
                        from the XAN wounds.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a> shows
                  the hospitalization-related measures. The most common presentation was transfer from outside
                  facilities (54%). In our cohort, patients had a mean of 11.0 ± 13.4 hospital encounters during the
                  study period, and the longest hospitalization averaged 21.1 ± 26.4 days per patient. The most common
                  discharge disposition was elopement or AMA discharge (51%), which occurred an average of 5.4 ± 7.1
                  times per patient during the study period. Only 18% of patients returned to our outpatient clinics.
                  The 30-day all-cause complication rate was high (77%), and 4 patients (5%) died due to complications
                  of IVDU.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_13" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Hospitalization-Related Measures<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 717px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Transferred from outside
                          hospital</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (54%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Longest hospitalization
                          <span data-id="emphasis_32" class="annotation emphasis"
                            style="position: unset;">(days)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.1 ±
                          26.4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total hospitalization
                          encounters</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.0 ±
                          13.4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Discharge
                          variables</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> AMA discharge
                          events</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.4 ±
                          7.1</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Final disposition</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Home</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (31%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Facility</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (17%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Eloped or left
                          AMA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (51%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Expired</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Outpatient follow-up
                          compliance</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (18%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Complications, 30
                          days</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61
                          (74%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bacteremia</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33
                          (40%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Endocarditis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (10%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Septic embolism</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Abscess in non-upper
                          extremity</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (11%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Radial artery
                          thrombosis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Death</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (5%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients with the percentage in
                        parentheses based on the total number of patients (82) or as the mean ± standard deviation. AMA
                        = against medical advice.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a>
                  documents the yearly clinical encounters based on the year that each patient was last seen by the hand
                  surgery team.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_14" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Yearly Clinical Encounters with Hand Surgery<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 204px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Emergency department
                          visits</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.5 ±
                          8.6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hospitalizations</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.1 ±
                          4.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Upper-extremity
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7 ±
                          0.8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">AMA discharges</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.8 ±
                          3.6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Outpatient hand surgery
                          follow-up</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.4 ±
                          1.9</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean ± standard deviation; the values
                        represent the mean number of events per year for each clinical encounter type. AMA = against
                        medical advice.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">This study presents the largest known cohort of PWID with
                  necrotic upper-extremity wounds who presented to a single medical system at the epicenter for
                  xylazine-adulterated heroin and fentanyl<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_14" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">20</span></a>. While our patient population was similar to those
                  described in prior studies of upper-extremity infections in PWID<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">23</span></a>, the necrotic wounds treated in our cohort were distinctly
                  different. XAN wounds are complicated by their chronicity and severity, as well as the patient factors
                  associated with PWID<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">23</span></a>. The wounds appeared to exist on a spectrum, ranging from
                  small, superficial, and scattered wounds to large, confluent wounds with exposed bone and
                  osteomyelitis. With continued injections of xylazine, the smaller necrotic wounds begin to coalesce
                  and deepen as the normal intervening skin becomes increasingly involved.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">By describing the spectrum of our experience in treating
                  patients with such wounds, greater awareness of the growing xylazine epidemic may lead to improved
                  collaboration between hand surgeons and the medical community to improve treatment algorithms and
                  outcomes for this patient population.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Soderquist et al. presented a series of 8 of the most
                  severe cases of XAN wounds that required transhumeral amputations<a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">16</span></a>. However, they did not comment on the spectrum of such
                  wounds to determine the frequency with which major amputations are needed in this patient population.
                  In our cohort, amputations were performed in 13% of patients, and major (transradial to glenohumeral)
                  amputations were performed in 9% of patients. It should be noted that amputations were offered to 20
                  patients (24%), but 9 patients refused, including 6 patients (7%) who refused major amputations.
                  Similar to the series by Soderquist et al.<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">16</span></a>,
                  major amputations in our study were performed for patients with large, including circumferential,
                  wounds in the setting of functionless hands with sensory deficits. Patients with intact sensation and
                  focal osteomyelitis were generally offered serial I&D with osseous saucerization, ostectomy, and
                  eventual wound coverage with skin grafts or flap reconstruction. If patients did not have
                  osteomyelitis, serial I&D with or without skin grafts or flap reconstruction was offered. Due to the
                  frequency of continued injections into the flap, failure to follow up, or death, we rarely consider
                  flap coverage as a viable option for this patient population. Compared with acute hand infections<a
                    href="" data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">22</span></a>, XAN wounds are associated with higher interfacility
                  transfer rates, a longer hospital LOS, a higher rate of AMA discharges, more emergency department
                  visits per year, and high complication rates.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">As the prevalence of xylazine in U.S. illicit drug
                  supplies increases, hospital addiction care models are essential to prevent XAN wounds and optimize
                  their early management. Interprofessional addiction consult services (ACSs) consist of dedicated teams
                  of addiction physicians, social workers, peers, nurses, pharmacists, and consulting services (e.g.,
                  infectious disease, hand surgery, etc.)<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">25</span></a>. Such programs are the most widely studied and provide
                  comprehensive care through educating staff and trainees (e.g., addressing stigma) and initiating
                  system-level hospital changes (e.g., quality improvement efforts). In the context of XAN wounds, for
                  example, our hospital recently created an ACS (the Jefferson Addiction Multidisciplinary Service
                  [JAMS]) that streamlines the involvement of hand surgeons for earlier wound management. JAMS also
                  promotes continuity of care through medical, behavioral, and social support once patients leave the
                  hospital. Future studies will determine if such programs effectively reduce the morbidity of XAN
                  wounds to improve the outcomes in PWID.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Given the challenges and morbidity related to managing XAN
                  wounds, harm reduction programs (HRPs) may represent a pathway to prevent such wounds. HRPs are aimed
                  at improving the safety of PWID, acknowledging that only 20% of PWID initiate medical therapy with the
                  goal of abstinence<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">26</span></a>.
                  Therefore, the programs aim to reduce the harms associated with the inevitable drug use through
                  syringe exchange programs, overdose education and naloxone distribution, and supervised consumption
                  sites, and by providing patients with injectable opioids without adulterants. While some patients
                  enjoy the extended high of xylazine<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">6</span></a>,
                  most dislike the drug and note considerable concerns regarding the necrotic wounds described in the
                  present study<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_23" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">14</span></a>. One study that assessed the ability of PWID to predict the
                  presence of xylazine in drug samples showed only moderate agreement (kappa = 0.42) between predictions
                  and the actual presence of xylazine as determined by mass spectrometry<a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">13</span></a>. Drug checking services have been developed in 16 sites
                  across North America to test local drug samples for adulterants, including xylazine, in order to
                  reduce the harms to PWID<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">27</span></a>.
                  Analogous to the point-of-care test strips that enable patients to detect fentanyl prior to drug use,
                  xylazine test strips also have been developed, but they may not detect lower quantities of the drug<a
                    href="" data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">28</span></a>. Given that the concentration and dose of xylazine that
                  cause necrotic wounds are unknown, the utility of xylazine test strips remains unclear. Unfortunately,
                  the lack of reliable tests leaves patients to recognize xylazine administration retrospectively when
                  they observe the physical manifestations of necrotic wounds or a prolonged high<a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_27" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">28</span></a>. Drug checking has been shown to influence the decision to
                  use certain drugs in 25% of PWID and may be a viable option to prevent XAN wounds once more sensitive
                  tests become widely available<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">29</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">This study had multiple limitations, including its
                  retrospective design and the poor follow-up that is typical of PWID<a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_29" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_50"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_29" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">22</span></a>. The treatment algorithm utilized in this study evolved
                  over time, and there was heterogeneity among the medical personnel involved in patient care.
                  Additionally, laboratory detection of xylazine requires analytical techniques that are absent from
                  routine toxicology screens and are unavailable in our hospital system<a href=""
                    data-id="citation_reference_53" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">30</span></a>. In 2021, xylazine was found in &gt;90% of the heroin and
                  fentanyl drug samples that were tested in our city<a href="" data-id="citation_reference_54"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">20</span></a>. The characteristic appearance of wounds associated with
                  xylazine and its high prevalence in our community make it likely that all wounds in this cohort were
                  associated with xylazine use, but we did not have a confirmatory test to prove this assumption<a
                    href="" data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_32" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_57"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_32" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_58" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_32"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_59" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_32"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_60" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_32"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_61" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_32"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_62" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">31</span></a>. We were unable to determine the age of each wound in our
                  cohort because the patients were often poor historians and we did not have photographic evidence to
                  determine the progression of the wounds over time. In our experience, wounds progressed over a period
                  of months rather than hours or days, indicating that there is likely a dose-dependent relationship
                  between xylazine exposure and wound progression over time. Anecdotally, patients who remained in the
                  hospital for weeks at a time, presumably not using xylazine-containing drugs during that time frame,
                  did not have wound progression. Future studies should seek to establish the progression of XAN wounds
                  over time. Furthermore, there was a lack of patient-reported outcomes due to lack of follow-up, a high
                  rate of AMA discharges, and a high rate of homelessness in the study population.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, to our knowledge, this study provides the
                  largest known series of XAN upper-extremity wounds. We have attempted complex reconstructions with
                  flap coverage, but continued drug use, a lack of follow-up, and death have led to unsuccessful
                  results. We have found that patients with the best outcomes related to reconstructive efforts are
                  those who remain in the hospital for a prolonged period, are compliant with drug rehabilitation
                  efforts, or present to the outpatient clinic seeking follow-up treatment with the support of family
                  members. We currently employ IV antibiotics and dressing changes as the mainstays of treatment, and we
                  offer amputation to patients who have severe cases with insensate, nonfunctional hands. Unfortunately,
                  we have found that when hand surgeons are consulted in the emergency or inpatient setting, it is
                  typically too late to reverse the extensive damage and salvage the clinical situation. A
                  multidisciplinary approach may improve awareness of characteristic xylazine wounds and facilitate
                  earlier hand surgery consultations to improve outcomes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">This study presents a broad perspective on demographic,
                  social, and medical factors in patients with XAN wounds of the upper extremity. Given the complexity
                  and burden of this public health crisis, early intervention is important to prevent complications and
                  mitigate costs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank the staff at the Philadelphia Hand to
                  Shoulder Center and Thomas Jefferson University Hospital, including our hand surgeons, fellows,
                  residents, therapists, nurses, and support staff who helped care for the patients described in this
                  study. We also thank the staff of the various services who have assisted in the care of these
                  patients, including the infectious disease, internal medicine, and substance abuse services, among
                  others.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;">United
                States Drug Enforcement Administration. <span data-id="strong_1" class="annotation strong"
                  style="position: unset;">Public Safety Alert: DEA Reports Widespread Threat of Fentanyl Mixed with
                  Xylazine</span>. 2023. Accessed 2023 Dec 28. <a
                  href="https://www.dea.gov/alert/dea-reports-widespread-threat-fentanyl-mixed-xylazine" target="_blank"
                  data-id="link_1" class="link"
                  style="position: unset;">https://www.dea.gov/alert/dea-reports-widespread-threat-fentanyl-mixed-xylazine</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Public%20Safety%20Alert%3A%20DEA%20Reports%20Widespread%20Threat%20of%20Fentanyl%20Mixed%20with%20Xylazine&as_occt=title&as_sauthors=%20%22United%20States%20Drug%20Enforcement%20Administration%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;">National
                Institute on Drug Abuse. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Xylazine.</span> 2022. Accessed 2023 Dec 28. <a
                  href="https://nida.nih.gov/research-topics/xylazine" target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://nida.nih.gov/research-topics/xylazine</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Reyes JC</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Negrón JL</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Colón HM</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Padilla AM</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Millán MY</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Matos TD</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Robles RR</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The emerging of xylazine as a
                  new drug of abuse and its health consequences among drug users in Puerto Rico</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">J Urban Health.</span> 2012
                Jun;89(3):519-26.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20emerging%20of%20xylazine%20as%20a%20new%20drug%20of%20abuse%20and%20its%20health%20consequences%20among%20drug%20users%20in%20Puerto%20Rico&as_occt=title&as_sauthors=%20%22JC%20Reyes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Kariisa M</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Patel P</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Smith H</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Bitting J</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Notes from the Field: Xylazine
                  Detection and Involvement in Drug Overdose Deaths - United States, 2019</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">MMWR Morb Mortal Wkly
                  Rep.</span> 2021 Sep 17;70(37):1300-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Notes%20from%20the%20Field%3A%20Xylazine%20Detection%20and%20Involvement%20in%20Drug%20Overdose%20Deaths%20-%20United%20States%2C%202019&as_occt=title&as_sauthors=%20%22M%20Kariisa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Ruiz-Colón K</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Chavez-Arias C</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Díaz-Alcalá JE</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Martínez MA</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Xylazine intoxication in humans
                  and its importance as an emerging adulterant in abused drugs: A comprehensive review of the
                  literature</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Forensic Sci Int.</span> 2014 Jul;240:1-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Xylazine%20intoxication%20in%20humans%20and%20its%20importance%20as%20an%20emerging%20adulterant%20in%20abused%20drugs%3A%20A%20comprehensive%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22K%20Ruiz-Col%C3%B3n%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Friedman J</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Montero F</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Bourgois P</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Wahbi R</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Dye D</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Goodman-Meza D</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Shover C</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Xylazine spreads across the US:
                  A growing component of the increasingly synthetic and polysubstance overdose crisis</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Drug Alcohol Depend.</span>
                2022 Apr 1;233:109380.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Xylazine%20spreads%20across%20the%20US%3A%20A%20growing%20component%20of%20the%20increasingly%20synthetic%20and%20polysubstance%20overdose%20crisis&as_occt=title&as_sauthors=%20%22J%20Friedman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Bishnoi A</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Singh V</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Khanna U</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Vinay K</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Skin ulcerations caused by
                  xylazine: A lesser-known entity</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">J Am Acad Dermatol.</span> 2023 Aug;89(2):e99-102.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Skin%20ulcerations%20caused%20by%20xylazine%3A%20A%20lesser-known%20entity&as_occt=title&as_sauthors=%20%22A%20Bishnoi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Hopster K</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Wittenberg-Voges L</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Kästner SBR</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Xylazine infusion in
                  isoflurane-anesthetized and ventilated healthy horses: Effects on cardiovascular parameters and
                  intestinal perfusion</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Can J Vet Res.</span> 2017 Oct;81(4):249-54.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Xylazine%20infusion%20in%20isoflurane-anesthetized%20and%20ventilated%20healthy%20horses%3A%20Effects%20on%20cardiovascular%20parameters%20and%20intestinal%20perfusion&as_occt=title&as_sauthors=%20%22K%20Hopster%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Nunez J</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> DeJoseph ME</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Gill JR</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Xylazine, a Veterinary
                  Tranquilizer, Detected in 42 Accidental Fentanyl Intoxication Deaths</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Am J Forensic Med
                  Pathol.</span> 2021 Mar 1;42(1):9-11.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Xylazine%2C%20a%20Veterinary%20Tranquilizer%2C%20Detected%20in%2042%20Accidental%20Fentanyl%20Intoxication%20Deaths&as_occt=title&as_sauthors=%20%22J%20Nunez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Silva-Torres LA</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Vélez C</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Lyvia Alvarez J</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Ortiz JG</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Zayas B</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Toxic effects of xylazine on
                  endothelial cells in combination with cocaine and 6-monoacetylmorphine</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Toxicol In Vitro.</span>
                2014 Oct;28(7):1312-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Toxic%20effects%20of%20xylazine%20on%20endothelial%20cells%20in%20combination%20with%20cocaine%20and%206-monoacetylmorphine&as_occt=title&as_sauthors=%20%22LA%20Silva-Torres%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Malayala SV</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Papudesi BN</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Bobb R</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Wimbush A</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Xylazine-Induced Skin Ulcers in
                  a Person Who Injects Drugs in Philadelphia, Pennsylvania, USA</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">Cureus.</span> 2022 Aug
                19;14(8):e28160.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Xylazine-Induced%20Skin%20Ulcers%20in%20a%20Person%20Who%20Injects%20Drugs%20in%20Philadelphia%2C%20Pennsylvania%2C%20USA&as_occt=title&as_sauthors=%20%22SV%20Malayala%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> O’Neil J</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Kovach S</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Xylazine-Associated Skin
                  Injury</span>. <span data-id="emphasis_11" class="annotation emphasis" style="position: unset;">N Engl
                  J Med.</span> 2023 Jun 15;388(24):2274.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Xylazine-Associated%20Skin%20Injury&as_occt=title&as_sauthors=%20%22J%20O%E2%80%99Neil%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Park JN</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Serafinski R</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Ujeneza M</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> McKenzie M</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Tardif J</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Krotulski AJ</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Badea A</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Grossman ER</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Green TC</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Xylazine awareness, desire, use
                  and exposure: Preliminary findings from the Rhode Island community-based drug checking cohort
                  study</span>. <span data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Drug
                  Alcohol Depend Rep.</span> 2024 Jun 5;11:100247.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Xylazine%20awareness%2C%20desire%2C%20use%20and%20exposure%3A%20Preliminary%20findings%20from%20the%20Rhode%20Island%20community-based%20drug%20checking%20cohort%20study&as_occt=title&as_sauthors=%20%22JN%20Park%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Reed MK</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Imperato NS</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Bowles JM</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Salcedo VJ</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Guth A</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Rising KL</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Perspectives of people in
                  Philadelphia who use fentanyl/heroin adulterated with the animal tranquilizer xylazine; Making a case
                  for xylazine test strips</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Drug Alcohol Depend Rep.</span> 2022 Jun 30;4:100074.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Perspectives%20of%20people%20in%20Philadelphia%20who%20use%20fentanyl%2Fheroin%20adulterated%20with%20the%20animal%20tranquilizer%20xylazine%3B%20Making%20a%20case%20for%20xylazine%20test%20strips&as_occt=title&as_sauthors=%20%22MK%20Reed%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Rose L</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Kirven R</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Tyler K</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Chung C</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Korman AM</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Xylazine-induced acute skin
                  necrosis in two patients who inject fentanyl</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">JAAD Case Rep.</span> 2023 Apr
                26;36:113-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Xylazine-induced%20acute%20skin%20necrosis%20in%20two%20patients%20who%20inject%20fentanyl&as_occt=title&as_sauthors=%20%22L%20Rose%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Soderquist M</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Delgado G</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Abdelfattah H</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Thoder J</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Solarz M</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Necrotic Upper-Extremity
                  Infections in People Who Inject Drugs: A Case Series</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">J Hand Surg Am.</span> 2024
                May;49(5):459-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Necrotic%20Upper-Extremity%20Infections%20in%20People%20Who%20Inject%20Drugs%3A%20A%20Case%20Series&as_occt=title&as_sauthors=%20%22M%20Soderquist%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Gupta R</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Holtgrave DR</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Ashburn MA</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Xylazine - Medical and Public
                  Health Imperatives</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">N Engl J Med.</span> 2023 Jun 15;388(24):2209-12.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Xylazine%20-%20Medical%20and%20Public%20Health%20Imperatives&as_occt=title&as_sauthors=%20%22R%20Gupta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Robertson SA</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Sanchez LC</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Treatment of visceral pain in
                  horses</span>. <span data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Vet
                  Clin North Am Equine Pract.</span> 2010 Dec;26(3):603-17.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treatment%20of%20visceral%20pain%20in%20horses&as_occt=title&as_sauthors=%20%22SA%20Robertson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Rodríguez N</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Vargas Vidot J</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Panelli J</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Colón H</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Ritchie B</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Yamamura Y</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">GC-MS confirmation of xylazine
                  (Rompun), a veterinary sedative, in exchanged needles</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">Drug Alcohol Depend.</span> 2008 Aug
                1;96(3):290-3.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=GC-MS%20confirmation%20of%20xylazine%20(Rompun)%2C%20a%20veterinary%20sedative%2C%20in%20exchanged%20needles&as_occt=title&as_sauthors=%20%22N%20Rodr%C3%ADguez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Bettigole CF</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Franklin F</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Hom J</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Health Alert: Risks of Xylazine
                  Use and Withdrawal in People Who Use Drugs in Philadelphia</span>. 2022. Accessed 2024 Apr 15. <a
                  href="https://hip.phila.gov/document/2524/PDPH-HAN_Alert_1_Xylazine_03.16.2022.pdf/" target="_blank"
                  data-id="link_3" class="link"
                  style="position: unset;">https://hip.phila.gov/document/2524/PDPH-HAN_Alert_1_Xylazine_03.16.2022.pdf/</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20Alert%3A%20Risks%20of%20Xylazine%20Use%20and%20Withdrawal%20in%20People%20Who%20Use%20Drugs%20in%20Philadelphia&as_occt=title&as_sauthors=%20%22CF%20Bettigole%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Devulapalli C</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Han KD</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Bello RJ</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> LaPorte DM</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Hepper CT</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Katz RD</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Inadvertent Intra-Arterial Drug
                  Injections in the Upper Extremity: Systematic Review</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">J Hand Surg Am.</span> 2015
                Nov;40(11):2262-2268.e5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Inadvertent%20Intra-Arterial%20Drug%20Injections%20in%20the%20Upper%20Extremity%3A%20Systematic%20Review&as_occt=title&as_sauthors=%20%22C%20Devulapalli%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Pong TM</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Oflazoglu K</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Helliwell LA</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Chen NC</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Eberlin KR</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Intravenous Drug Use-Related
                  Complications of the Hand and Upper Extremity</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">Plast Reconstr Surg Glob Open.</span> 2019 Feb
                8;7(2):e2116.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intravenous%20Drug%20Use-Related%20Complications%20of%20the%20Hand%20and%20Upper%20Extremity&as_occt=title&as_sauthors=%20%22TM%20Pong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Chotai N</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Gallagher K</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Kieffer WK</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Rogers BA</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Ricketts DM</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">A comparative analysis of the
                  patient characteristics and cost of managing intravenous drug users (IVDU) with soft tissue abscesses
                  with non-IVDU</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Surgeon.</span> 2016 Feb;14(1):13-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20comparative%20analysis%20of%20the%20patient%20characteristics%20and%20cost%20of%20managing%20intravenous%20drug%20users%20(IVDU)%20with%20soft%20tissue%20abscesses%20with%20non-IVDU&as_occt=title&as_sauthors=%20%22N%20Chotai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Englander H</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Jones A</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Krawczyk N</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Patten A</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Roberts T</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Korthuis PT</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> McNeely J</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">A Taxonomy of Hospital-Based
                  Addiction Care Models: a Scoping Review and Key Informant Interviews</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">J Gen Intern Med.</span>
                2022 Aug;37(11):2821-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Taxonomy%20of%20Hospital-Based%20Addiction%20Care%20Models%3A%20a%20Scoping%20Review%20and%20Key%20Informant%20Interviews&as_occt=title&as_sauthors=%20%22H%20Englander%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Englander H</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Thakrar AP</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Bagley SM</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Rolley T</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Dong K</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Hyshka E</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Caring for Hospitalized Adults
                  With Opioid Use Disorder in the Era of Fentanyl: A Review</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">JAMA Intern Med.</span> 2024 Jun
                1;184(6):691-701.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Caring%20for%20Hospitalized%20Adults%20With%20Opioid%20Use%20Disorder%20in%20the%20Era%20of%20Fentanyl%3A%20A%20Review&as_occt=title&as_sauthors=%20%22H%20Englander%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Ellis JD</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Dunn KE</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Huhn AS</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Harm Reduction for Opioid Use
                  Disorder: Strategies and Outcome Metrics</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">Am J Psychiatry.</span> 2024 May
                1;181(5):372-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Harm%20Reduction%20for%20Opioid%20Use%20Disorder%3A%20Strategies%20and%20Outcome%20Metrics&as_occt=title&as_sauthors=%20%22JD%20Ellis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Park JN</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Tardif J</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Thompson E</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Rosen JG</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Lira JAS</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Green TC</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">A survey of North American drug
                  checking services operating in 2022</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">Int J Drug Policy.</span> 2023 Nov;121:104206.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20survey%20of%20North%20American%20drug%20checking%20services%20operating%20in%202022&as_occt=title&as_sauthors=%20%22JN%20Park%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Thompson E</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Tardif J</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Ujeneza M</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Badea A</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Green TC</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> McKee H</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> McKenzie M</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Park JN</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Pilot findings on the
                  real-world performance of xylazine test strips for drug residue testing and the importance of
                  secondary testing methods</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Drug Alcohol Depend Rep.</span> 2024 May 6;11:100241.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pilot%20findings%20on%20the%20real-world%20performance%20of%20xylazine%20test%20strips%20for%20drug%20residue%20testing%20and%20the%20importance%20of%20secondary%20testing%20methods&as_occt=title&as_sauthors=%20%22E%20Thompson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Klaire S</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Janssen RM</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Olson K</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Bridgeman J</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Korol EE</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Chu T</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Ghafari C</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Sabeti S</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Buxton JA</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Lysyshyn M</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Take-home drug checking as a
                  novel harm reduction strategy in British Columbia, Canada</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">Int J Drug Policy.</span> 2022
                Aug;106:103741.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Take-home%20drug%20checking%20as%20a%20novel%20harm%20reduction%20strategy%20in%20British%20Columbia%2C%20Canada&as_occt=title&as_sauthors=%20%22S%20Klaire%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;">U.S. Food
                and Drug Administration. <span data-id="strong_29" class="annotation strong"
                  style="position: unset;">FDA alerts health care professionals of risks to patients exposed to xylazine
                  in illicit drugs</span>. 2022. Accessed 2024 Oct 31. <a
                  href="https://www.fda.gov/drugs/drug-safety-and-availability/fda-alerts-health-care-professionals-risks-patients-exposed-xylazine-illicit-drugs"
                  target="_blank" data-id="link_4" class="link"
                  style="position: unset;">https://www.fda.gov/drugs/drug-safety-and-availability/fda-alerts-health-care-professionals-risks-patients-exposed-xylazine-illicit-drugs</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=FDA%20alerts%20health%20care%20professionals%20of%20risks%20to%20patients%20exposed%20to%20xylazine%20in%20illicit%20drugs&as_occt=title&as_sauthors=%20%22U.S.%20Food%20and%20Drug%20Administration%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Choueka J</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> De Tolla JE</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Necrotizing Infections of the
                  Hand and Wrist: Diagnosis and Treatment Options</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2020 Jan
                15;28(2):e55-63.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Necrotizing%20Infections%20of%20the%20Hand%20and%20Wrist%3A%20Diagnosis%20and%20Treatment%20Options&as_occt=title&as_sauthors=%20%22J%20Choueka%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D454de204-bb0f-4bbe-8ab9-367fe313d3a5%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">February 5, 2025;
                      107 (3): 279</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00534</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">December 23, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_29"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Philadelphia Hand to Shoulder Center, Thomas Jefferson University Hospital, Philadelphia,
                            Pennsylvania</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;454de204-bb0f-4bbe-8ab9-367fe313d3a5&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=454de204-bb0f-4bbe-8ab9-367fe313d3a5&type=pdf&name=JBJS.24.00534.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=454de204-bb0f-4bbe-8ab9-367fe313d3a5&type=pdf&name=JBJS.24.00534.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_36"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_37" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I326"
                            target="_blank" data-id="link_5" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I326</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;454de204-bb0f-4bbe-8ab9-367fe313d3a5&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=454de204-bb0f-4bbe-8ab9-367fe313d3a5&type=zip&name=JBJS.24.00534.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sebastian D. Arango, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0001-1241-5815" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0001-1241-5815</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jason C. Flynn, BS<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0006-2880-0474" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0006-2880-0474</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jacob H. Zeitlin, BA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6962-5100" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6962-5100</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tristan B. Weir, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:Tristanweirmd@gmail.com" class=""
                    style="position: unset;">Tristanweirmd@gmail.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9773-3013" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9773-3013</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Andrew J. Miller, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6828-823X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6828-823X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Philadelphia Hand to Shoulder Center, Thomas Jefferson University Hospital,
                Philadelphia, Pennsylvania</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Thomas Jefferson University Hospital, Sidney Kimmel Medical College,
                Philadelphia, Pennsylvania</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 32157.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
