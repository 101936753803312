const dummyImage =
	"https://s3-alpha-sig.figma.com/img/b6ac/710a/43732c8bfccd54881f6de34c10df7865?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=OqYBaZQwoZwAI4lBiYiEWX9nFeVzuNvJnR0-IPAKSwWH3MkjI69B0IRlCyeTul3hU9nbvPa7AV7XO~F8pnTV9Bzy4MSli-n~8lCeQ5U8HVzXHiWZDZCMAl1Lorfz-iXm5nV8V5ijAsWCxV717csN1Zwv7072TRLl~KTYpS7NcqC~sBM-GhemXqbBx3F7NMvJ~grxEHHDv3KseEPo2JHub5diNhQCKHvD9r9AyV2b0K9slEo3DFxvt1PO9LmU2P4fG45EWWYF1jZYgiWC10guwVSP8Ig3khHo-5ow7stVGzvvzWA9yoA4vCrLWYxzdYe8e-MFvIkJiAbsaBgiYYOPRA__";

const specialties: Record<
	string,
	{
		title: string;
		image: string;
		type: string;
		id: string;
	}[]
> = {
	doctor: [
		{
			title: "Radiology",
			image:
				"https://memorang-prod-icon.s3.amazonaws.com/app-resources/aprovatotal/nejm-demo-temp/radio.png",
			type: "radiology",
			id: "1",
		},
		{
			title: "Neurology",
			image:
				"https://memorang-prod-icon.s3.amazonaws.com/app-resources/aprovatotal/nejm-demo-temp/neuro.png",
			type: "knee",
			id: "2",
		},
		{
			title: "Cardiology",
			image:
				"https://memorang-prod-icon.s3.amazonaws.com/app-resources/aprovatotal/nejm-demo-temp/cardo.png",
			type: "footAnkle",
			id: "3",
		},
		{
			title: "Sports",
			image: "https://memorang-prod-media.s3.amazonaws.com/jbjs/new_sports.png",
			type: "sports",
			id: "4",
		},
		{
			title: "Dermatology",
			image:
				"https://memorang-prod-icon.s3.amazonaws.com/app-resources/aprovatotal/nejm-demo-temp/derm.png",
			type: "hand",
			id: "5",
		},
		{
			title: "Surgery",
			image:
				"https://memorang-prod-icon.s3.amazonaws.com/app-resources/aprovatotal/nejm-demo-temp/surgery.png",
			type: "spine",
			id: "6",
		},
	],
	resident: [
		{
			title: "Hip",
			image: "https://memorang-prod-media.s3.amazonaws.com/jbjs/new_hip.png",
			type: "hip",
			id: "1",
		},
		{
			title: "Knee",
			image: "https://memorang-prod-media.s3.amazonaws.com/jbjs/new_knee.png",
			type: "knee",
			id: "2",
		},
		{
			title: "Foot & Ankle",
			image: "https://memorang-prod-media.s3.amazonaws.com/jbjs/new_foot.png",
			type: "footAnkle",
			id: "3",
		},
		{
			title: "Sports",
			image: "https://memorang-prod-media.s3.amazonaws.com/jbjs/new_sports.png",
			type: "sports",
			id: "4",
		},
		{
			title: "Hand",
			image: "https://memorang-prod-media.s3.amazonaws.com/jbjs/new_hand.png",
			type: "hand",
			id: "5",
		},
		{
			title: "Spine",
			image: "https://memorang-prod-media.s3.amazonaws.com/jbjs/new_spine.png",
			type: "spine",
			id: "6",
		},
	],
};

const mockedAvatars = Array.from({ length: 36 }, (_, index) => ({
	id: (index + 1).toString(),
	url: `https://yzshdoomwjkaqrvelrki.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/avatars/${index + 1}.png`,
}));

export const disclaimerMarkdown = `
## Activity Overview and Instructions
The Image Challenge helps improve and hone diagnostic skills by reviewing common and unusual clinical conditions and providing immediate feedback and condition-specific insights. In addition to a new and intuitive layout, the new version allows the flexibility to select challenges by most popular, most difficult, and by specialty area, as well as to track results and easily share them with your peers.

### Key Features for App Subscribers
* Daily challenge offered in addition to an archive of over 100 image questions
* Earn CME credit while playing
* Browse/filter by specialty, image type, date, difficulty, popularity, and other factors
* Gamification features:
  * Countdown to the next challenge
  * Leaderboard-type competition
  * How you rank against all other players
  * See how other players answered
  * Share via social media
  * Track history of # attempts on a question
  * Track progress: # challenges completed

## Learning Objectives
Upon completion of this activity, learners should be able to:
* Increase your knowledge of clinical medicine
* Improve your competence in analyzing clinical images (from physical exams, radiology, histopathology, procedural studies, and gross pathology) to improve diagnostic skills and accuracy
* Recognize areas of personal strength and areas for growth in clinical knowledge and diagnostic skill
* Enhance your ability to interpret clinical images to improve your diagnostics skills and accuracy

## Intended Audience
The primary audience will be clinicians in practice, residents, and medical students. The tertiary audience will be physicians in other specialties as well as students, trainees and allied health care providers.

## Method of Participation
To participate in this CME activity:
1. Review the learning objectives, disclosure statement, and accreditation statements
2. Navigate through all course pages and/or view video(s)
3. Complete exams/assessments and evaluation
4. Be sure to CLAIM your credit
5. View/print certificate

## Disclosure Statement
All individuals in control of the content for a Massachusetts Medical Society (MMS) accredited continuing education activity must disclose all financial relationships with ineligible companies for the past 24-months. For this activity, individuals in control of content did not disclose any relevant financial relationships with ineligible companies.

## Accreditation Information
### Accreditation Statement
The Massachusetts Medical Society is accredited by the Accreditation Council for Continuing Medical Education (ACCME) to provide continuing medical education for physicians.

### AMA Credit Designation Statement
The Massachusetts Medical Society designates this other (visual learning) activity for a maximum of .25 AMA PRA Category 1 Credit™. Physicians should claim only the credit commensurate with the extent of their participation in the activity.

### Additional Information
* Every 12 image challenges correctly answered by learners will earn 1.0 AMA PRA Category 1 Credit™
* Available credits will increase over time as more image challenges are developed

## Evaluation and Feedback
A link to the evaluation form will be provided at the end of the activity. Participants should complete a post-activity evaluation about the educational experience.
[Evaluation Form](https://nejm.az1.qualtrics.com/jfe/form/SV_cVbggW5BpvGFhB4)

## Contact Information
For all questions and comments, please contact NEJM Group or call:
* USA and Canada: 1-800-843-6356
* International: 1-781-434-7888

## Additional Information
* **Privacy Policy:** Read our Privacy Policy
* **Copyright:** Copyright information can be found in the NEJM Group Terms of Use
* **Original Release Date:** August XX 2024
* **Review Date(s):** N/A
* **Termination Date:** August XX 2027`;

export { dummyImage, specialties, mockedAvatars };
