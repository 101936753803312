/* ======== UI ========= */
import { Appbar } from "react-native-paper";

type Props = {
	title?: string | React.ReactNode;
	onBackAction?: () => void;
};
const OnboardingAppBar = ({ title, onBackAction }: Props) => {
	const showBackAction = !!onBackAction;
	return (
		<Appbar.Header mode="center-aligned" elevated>
			{showBackAction && <Appbar.BackAction onPress={onBackAction} />}
			<Appbar.Content title={title} />
		</Appbar.Header>
	);
};

export { OnboardingAppBar };
