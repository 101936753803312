import type { Article } from "../../../../../types";

export const Article513: Article = {
	id: 513,
	rsuiteId: "2071d71c-aed0-4d25-a6f2-7bd5502668f0",
	type: "scientific article",
	title:
		"Anterior-Posterior Laxity in Midflexion After Posterior-Stabilized TKA Is Sensitive to MCL Tension in Passive Flexion",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=2071d71c-aed0-4d25-a6f2-7bd5502668f0&type=jpeg&name=JBJS.23.01061f1",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Anterior-Posterior Laxity in Midflexion After
                Posterior-Stabilized TKA Is Sensitive to MCL Tension in Passive Flexion</div>
              <div class="text subtitle" style="position: unset;">An in Vitro Biomechanical Study</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Erin E.
                      Berube, MS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">William
                      Xiang, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 8 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Background:</span> Knee instability in midflexion may contribute to
                        patient dissatisfaction following total knee arthroplasty (TKA). Midflexion instability involves
                        abnormal motions and tissue loading in multiple planes. Therefore, we quantified and compared
                        the tensions carried by the medial and lateral collateral ligaments (MCL and LCL) following
                        posterior-stabilized (PS) TKA through knee flexion, and then compared these tensions with those
                        carried by the native knee. Finally, we examined the relationships between collateral ligament
                        tensions and anterior tibial translation (ATT).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Methods:</span> Eight cadaveric knees (from 5 male and 3 female
                        donors with a mean age of 62.6 years and standard deviation of 10.9 years) underwent PS TKA.
                        Each specimen was mounted to a robotic manipulator and flexed to 90°. ATT was quantified by
                        applying 30 N of anterior force to the tibia. Tensions carried by the collateral ligaments were
                        determined via serial sectioning. Robotic testing was also conducted on a cohort of 15 healthy
                        native cadaveric knees (from 9 male and 6 female donors with a mean age of 36 years and standard
                        deviation of 11 years). Relationships between collateral ligament tensions during passive
                        flexion and ATT were assessed via linear and nonlinear regressions.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Results:</span> MCL tensions were greater following PS TKA than in
                        the native knee at 15° and 30° of passive flexion, by a median of ≥27 N (p = 0.002), while the
                        LCL tensions did not differ. Median tensions following PS TKA were greater in the MCL than in
                        the LCL at 15°, 30°, and 90° of flexion, by ≥4 N (p ≤ 0.02). Median tensions in the MCL of the
                        native knee were small (≤11 N) and did not exceed those in the LCL (p ≥ 0.25). A logarithmic
                        relationship was identified between MCL tension and ATT following TKA.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Conclusions:</span> MCL tensions were greater following PS TKA with
                        this typical nonconforming PS implant than in the native knee. Anterior laxity at 30° of flexion
                        was highly sensitive to MCL tension during passive flexion following PS TKA but not in the
                        native knee.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_35" class="annotation strong"
                          style="position: unset;">Clinical Relevance:</span> Surgeons face competing objectives when
                        performing PS TKA: they can either impart supraphysiological MCL tension to reduce
                        anterior-posterior laxity or maintain native MCL tensions that lead to heightened
                        anterior-posterior laxity, as shown in this study.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Total knee arthroplasty (TKA) is a common surgery in the
                  United States, but nearly 20% of patients are dissatisfied with their outcome<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">2</span></a>. A
                  potential cause of patient dissatisfaction and revision surgery following TKA is a patient perception
                  of knee instability<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">3</span></a>,
                  which is related to the bone resection technique, implant design, and ligament balancing<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">4</span></a>. Surgeons evaluate ligament balance intraoperatively via
                  assessment of coronal-plane laxity at multiple flexion angles, including full extension, midflexion,
                  and full flexion<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">5</span></a>.
                  However, instability in midflexion can involve abnormal motions and tissue loading in planes other
                  than the coronal plane<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">6</span></a>. For
                  example, excessive anterior-posterior (AP) laxity in midflexion can lead to a patient perception of
                  knee instability and contribute to patient dissatisfaction<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">10</span></a>. Thus, considering knee laxity and tissue loading outside
                  of the coronal plane is important when assessing knee stability<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">11</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Use of posterior-stabilized (PS) implant designs involves
                  resecting the anterior and posterior cruciate ligaments (ACL and PCL, respectively), which eliminates
                  the primary passive stabilizers resisting AP loads<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">12</span></a>.
                  Thus, surgeons must tension the remaining collateral ligaments intraoperatively to reestablish passive
                  knee stability. Unfortunately, quantitative intraoperative targets for collateral ligament tensioning
                  through a range of knee flexion to achieve clinically acceptable AP laxity in midflexion remain
                  ill-defined<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">4</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Therefore, we posed the following 3 research questions:
                  (1) In PS TKA, do the tensions carried by the medial collateral ligament (MCL) and lateral collateral
                  ligament (LCL) exceed those in the native knee during passive flexion? (2) Following PS TKA and in the
                  native knee, does the tension in one of the collateral ligaments exceed that in the other during
                  passive flexion? (3) Are the tensions carried by the MCL and LCL during passive flexion associated
                  with AP laxity following PS TKA and in the native knee?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">We hypothesized that collateral ligament tension during
                  passive flexion following PS TKA exceeds that in the native knee in extension, midflexion, and full
                  flexion. We also hypothesized that the tensions in the MCL during passive flexion are greater than
                  those in the LCL, both following TKA and in the native knee. Finally, we hypothesized that both MCL
                  and LCL tensions during passive flexion are related to AP laxity following PS TKA but not in the
                  native knee.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">TKA
                Surgery</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">With institutional review board approval, PS TKAs were
                  performed on 8 cadaveric knees (from 5 male and 3 female donors, mean age = 62.6 years [standard
                  deviation = 10.9 years, range = 47 to 79 years], all left-sided) by a fellowship-trained arthroplasty
                  surgeon (C.A.K.). These cadavers had been fresh-frozen and stored at −20°C before being thawed for 24
                  hours prior to testing. Prior to testing, axial computed tomography (CT) scans were performed
                  utilizing a 0.625-mm slice thicknesses and 0.5 × 0.5-mm in-plane resolution (Biograph mCT; Siemens).
                  Imaging data, medical history, and physical examination were used to exclude knees with chondral,
                  ligamentous, and meniscal injury; prior surgery; osteoarthritis; gross ligamentous laxity;
                  malalignment; and flexion contracture. One PS TKA implant system (Persona; Zimmer Biomet) was utilized
                  (<a href="" data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>) for all knees. This implant design is representative of typical
                  nonconforming PS implant designs with a dual-radius femoral component.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01061f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=2071d71c-aed0-4d25-a6f2-7bd5502668f0&type=jpeg&name=JBJS.23.01061f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;">Posterior-stabilized (PS) implant system (Zimmer
                          Persona) installed in a cadaveric knee.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The surgical technique consisted of standard
                  measured-resection TKA with a neutral mechanical alignment, as previously described<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">13</span></a>. The surgeon used manual instrumentation and a medial
                  parapatellar approach with a standard 12-cm midline incision. Next, the cruciate ligaments were
                  removed, and a standard distal resection was performed. A tibial cutting jig was set with 3° of
                  posterior slope. After sizing the femur, the 4-in-1 cutting jig was placed parallel to the
                  transepicondylar axis<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">13</span></a>.
                  The surgeon then performed trialing and selected the tibial insert thickness. In 2 knees, the surgeon
                  performed a partial release of the posterior capsule to achieve full extension. Finally, the
                  components were cemented into place.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Robotic
                Testing</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Following implantation of the TKA components, the 8
                  cadaveric legs were prepared for robotic testing. The femur and tibia were sectioned 15 cm proximal
                  and distal to the joint line, respectively, and set in potting cement (Bondo; 3M). The popliteus
                  muscle-tendon complex and the distal aspect of the iliotibial band (ITB) were kept intact to include
                  their contributions to the passive laxity of the knee. The distal aspect of the ITB included its
                  superficial layer with distal attachments on the lateral aspect of the tibia at the Gerdy tubercle,
                  the Kaplan fibers, and the biceps femoris muscle<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">14</span></a>.
                  Each specimen was then mounted to a robotic manipulator (ZX165U; Kawasaki Robotics) with a 6-axis
                  force/torque sensor mounted to its end effector (Theta; ATI Industrial Automation)<a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">15</span></a>. The potted femur was rigidly fixed to the ground through a
                  pedestal, and the tibia was fixed to the end effector of the robotic manipulator at full extension.
                  The specimens were kept hydrated throughout testing by wrapping them in saline solution-soaked gauze<a
                    href="" data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">16</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The knee coordinate system was defined on the basis of
                  geometric features of the tibial tray and the femoral component, and tibiofemoral kinematics were
                  defined as described previously<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">17</span></a>. The internal-external rotation axis was oriented
                  perpendicular to the tibial tray. The AP axis was centered within the tibial tray and aligned parallel
                  to the tibial tray and the femoral box<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">19</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Subsequently, the knee was flexed in 1° increments from 0°
                  to 90° while 10 N of compressive force was applied to the knee joint by the manipulator. In this
                  flexion path, the forces and moments in the remaining directions were minimized. Convergence
                  tolerances for achieving the target loads were resultant forces within 5 N and moments within 0.4 Nm<a
                    href="" data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">20</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">After passively flexing the knee, varus and valgus moments
                  from −6 to +6 Nm were applied at full extension for 5 cycles to precondition the posterior capsule and
                  the collateral ligaments. AP laxity assessments were then performed. The applied AP forces and the
                  resulting AP translations were measured along the previously defined AP axis<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">13</span></a>. AP laxity was quantified by measuring the net anterior
                  tibial translation (ATT) in millimeters in response to 30 N of applied anterior tibial force at 30° of
                  flexion relative to an applied posterior force of 20 N.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Following the AP laxity assessments, resultant in situ MCL
                  and LCL tensions during passive knee flexion were calculated via serial ligament sectioning and the
                  principle of superposition<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">19</span></a> (<a
                    href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). We focused on ligament tensions at full extension (0°),
                  midflexion (15° and 30°), and flexion (90°) because these are key angles at which surgeons assess the
                  knee intraoperatively<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">21</span></a>.
                  The MCL and LCL were identified according to anatomic descriptions and were sectioned at the joint
                  line (<a href="" data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>)<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">23</span></a>. The resultant force during passive flexion was determined
                  by positioning the knee at each of the 4 passive flexion angles and calculating the vector difference
                  between the tensions measured across the knee before and after sectioning each structure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01061f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=2071d71c-aed0-4d25-a6f2-7bd5502668f0&type=jpeg&name=JBJS.23.01061f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;">Flowchart summarizing each stage of the testing
                          protocol, including knee status, robot action, and outputs. In step 1, knee flexion and
                          simulated tests of AP laxity were conducted, and the resulting knee kinematics were recorded.
                          The MCL and LCL were sectioned in steps 2 and 3, respectively, and the kinematics were
                          replayed to determine the tensions carried by the collateral ligaments. The order of steps 2
                          and 3 was alternated from specimen to specimen.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01061f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=2071d71c-aed0-4d25-a6f2-7bd5502668f0&type=jpeg&name=JBJS.23.01061f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Dissections of the MCL (<span
                            data-id="emphasis_33" class="annotation emphasis" style="position: unset;">left</span>) and
                          LCL (<span data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">right</span>) during serial sectioning. The femur is at the top of
                          the image, and the tibia is at the bottom. Structures were identified according to anatomic
                          markers and isolated. Scissors were inserted beneath the structure before sectioning at the
                          joint line to identify each ligament.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The outcomes from the 8 cadaveric knees with PS TKA were
                  compared with those from an independent set of 15 consecutively tested healthy native cadaveric knees
                  (from 9 male and 6 female donors, mean age = 36 years [standard deviation, 11 years; range, 20 to 54
                  years]) assessed under the same loading conditions using the same robotic manipulator. Data from this
                  cohort of native knee specimens had been collected as part of previous studies on native knee
                  mechanics<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">24</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Our data were not normally distributed, as determined via
                  Kolmogorov-Smirnov tests (p &lt; 0.05). Therefore, all outcome measures are reported as the median and
                  interquartile range (IQR). Regarding our first research question, collateral ligament tensions in
                  passive flexion were compared between the native and TKA knees using unpaired Wilcoxon rank-sum tests
                  (α = 0.05). Regarding our second question, MCL and LCL tensions in the passive flexion path were
                  compared using paired Wilcoxon signed-rank tests in the native knees and in the TKA knees (α = 0.05).
                  Regarding our third question, the relationships between ligament tension and ATT were analyzed using
                  linear and nonlinear regressions. Regression coefficients (β for linear relationships, k for nonlinear
                  ones) are reported along with 95% confidence intervals (CIs) and coefficients of determination (r<span
                    data-id="superscript_23" class="annotation superscript" style="position: unset;">2</span>); in
                  addition, p values (α = 0.05) are reported for the linear regressions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Regarding our first research question, MCL tensions during
                  passive flexion were significantly greater following TKA than in the native knee at 15° and 30° of
                  flexion (by a median of 40 N and 27 N, respectively; p = 0.002 for both), but not at 0° or 90° of
                  flexion (p = 0.2 for both) (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>). LCL
                  tensions during passive flexion following TKA did not exceed those in the LCL in the native knee at
                  any flexion angle (p &gt; 0.2).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01061f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=2071d71c-aed0-4d25-a6f2-7bd5502668f0&type=jpeg&name=JBJS.23.01061f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Tension carried by the MCL and LCL during passive
                          flexion following PS TKA (black) and in the native knee (white) at 0°, 15°, 30°, and 90° of
                          flexion. Boxes indicate the interquartile range, and whiskers indicate the 5th and 95th
                          percentiles. The horizonal black line within each box indicates the median. *P &lt; 0.005.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Regarding our second question, tensions in the MCL
                  following TKA were significantly greater than those in the LCL at 15°, 30°, and 90° of flexion, by a
                  median of 39, 23, and 4 N (p ≤ 0.04), but not at full extension (p = 0.15) (<a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). In the native knee, tensions in the MCL did not exceed those
                  in the LCL at any flexion angle (p ≥ 0.25) (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Comparison of Median MCL and LCL Tensions During
                          Passive Knee Flexion Following TKA and in the Native Knee<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 386px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Flexion
                          Angle</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">TKA</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Native</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">MCL <span
                            data-id="emphasis_35" class="annotation emphasis" style="position: unset;">(N)</span></span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">LCL <span
                            data-id="emphasis_36" class="annotation emphasis" style="position: unset;">(N)</span></span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">MCL <span
                            data-id="emphasis_37" class="annotation emphasis" style="position: unset;">(N)</span></span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">LCL <span
                            data-id="emphasis_38" class="annotation emphasis" style="position: unset;">(N)</span></span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">0°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19 [6,
                          46]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12 [4,
                          18]</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.15</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11 [6,
                          13]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8 [5,
                          21]</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.93</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">15°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47 [33,
                          68]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8 [1,
                          18]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_38" class="annotation strong" style="position: unset;">0.0006</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7 [4,
                          10]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8 [3,
                          10]</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.85</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">30°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31 [3,
                          62]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8 [2,
                          19]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_39" class="annotation strong" style="position: unset;">0.02</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4 [3,
                          15]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4 [3,
                          6]</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.25</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">90°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6 [3,
                          16]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2 [1,
                          3]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_40" class="annotation strong" style="position: unset;">0.008</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4 [3,
                          6]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3 [2,
                          5]</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.28</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the median with the interquartile range in
                        brackets. MCL = medial collateral ligament, LCL = lateral collateral ligament, TKA = total knee
                        arthroplasty.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Regarding our third question, MCL tension in the passive
                  flexion path was negatively correlated with ATT at 30° of flexion (p = 0.04, β ± standard error [and
                  95% CI] = −0.1 ± 0.04, [-0.22, -0.01], r<span data-id="superscript_24" class="annotation superscript"
                    style="position: unset;">2</span> = 0.5). Further analysis showed that this relationship was
                  nonlinear and logarithmic (k = −0.02, r<span data-id="superscript_25" class="annotation superscript"
                    style="position: unset;">2</span> = 0.7) (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>). No
                  relationship was detected between MCL tension and ATT at 0°, 15°, or 90° of flexion (p ≥ 0.2).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.01061f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=2071d71c-aed0-4d25-a6f2-7bd5502668f0&type=jpeg&name=JBJS.23.01061f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;">Scatterplots of anterior tibial translation versus
                          MCL tension (<span data-id="emphasis_39" class="annotation emphasis"
                            style="position: unset;">left</span>) and LCL tension (<span data-id="emphasis_40"
                            class="annotation emphasis" style="position: unset;">right</span>) at 30° of passive
                          flexion. Data following TKA (black circles) showed a logarithmic relationship for both the MCL
                          and LCL. No such relationships were detected for the native knee (red diamonds).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">LCL tension was negatively correlated with ATT at 30° of
                  flexion (p = 0.04, β = −0.4 ± 0.2, r<span data-id="superscript_26" class="annotation superscript"
                    style="position: unset;">2</span> = 0.5). This relationship was also logarithmic (k = −0.09, r<span
                    data-id="superscript_27" class="annotation superscript" style="position: unset;">2</span> = 0.8) (<a
                    href="" data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 5</a>). A similar relationship was found at 15° of flexion (p = 0.01,
                  β = −0.5 ± 0.2, r<span data-id="superscript_28" class="annotation superscript"
                    style="position: unset;">2</span> = 0.7), which also was logarithmic (k = −0.07, r<span
                    data-id="superscript_29" class="annotation superscript" style="position: unset;">2</span> = 0.8). No
                  significant relationship was detected at 0° or 90° of flexion (p ≥ 0.1).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">In the native knee, neither MCL tension nor LCL tension in
                  passive flexion was significantly related to ATT at any flexion angle (p ≥ 0.08 and p ≥ 0.27,
                  respectively) (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Relationship of MCL and LCL Tensions with ATT at 30°
                          of Flexion in the Native Knee as Assessed Via Linear Regression<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 511px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Relationship</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">β ±
                          Standard Error</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          CI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">R<a
                            href="" data-id="citation_reference_31"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_30" class="annotation superscript"
                              style="position: unset;">2</span></a><span data-id="superscript_30"
                            class="annotation superscript" style="position: unset;"></span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MCL tension at 0° vs. ATT
                          at 30°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.01 ±
                          0.06</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.14,
                          0.12</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.87</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.002</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MCL tension at 30° vs.
                          ATT at 30°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.10 ±
                          0.05</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.21,
                          0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.08</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.22</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MCL tension at 90° vs.
                          ATT at 30°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.07 ±
                          0.05</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.18,
                          0.03</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.20</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.15</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">LCL tension at 0° vs. ATT
                          at 30°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.04 ±
                          0.05</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.16,
                          0.08</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.44</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">LCL tension at 30° vs.
                          ATT at 30°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.13 ±
                          0.11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.38,
                          0.11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.27</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.09</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">LCL tension at 90° vs.
                          ATT at 30°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.03 ±
                          0.09</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.23,
                          0.17</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.78</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">MCL = medial collateral ligament, LCL = lateral collateral ligament,
                        ATT = anterior tibial translation, β = regression coefficient, CI = confidence interval, R<span
                          data-id="superscript_31" class="annotation superscript" style="position: unset;">2</span> =
                        coefficient of determination.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Our first key finding was that the MCL carried at least a
                  6-fold greater median tension at 15° and 30° of passive knee flexion following PS TKA compared with
                  the tension carried by the MCL in the native knee (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>). These
                  results partially support our first hypothesis, although this finding did not hold at full extension
                  and at 90° of flexion. Our second key finding was that median tensions in the LCL were up to 6-fold
                  less than those in the MCL following TKA, supporting our second hypothesis. In contrast, collateral
                  ligament tensions in the native knee were all low during passive flexion (<a href=""
                    data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). Our third key finding was that AP laxity was related to MCL
                  tension during passive flexion at 30° following PS TKA, and that the relationship was nonlinear. The
                  LCL exhibited a similar nonlinear relationship between tension during passive flexion at 30° and ATT
                  at this flexion angle, despite the lower tensions in this ligament. In contrast, no such relationship
                  was identified between collateral ligament tensions and ATT in the native knee. Thus, these results
                  support our third hypothesis. MCL and LCL tensions during passive flexion are clinically relevant
                  because they represent the tensions that surgeons impart on these ligaments during TKA. Thus, MCL
                  tension during passive flexion likely plays a key role in controlling ATT in midflexion when utilizing
                  PS TKA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">In PS TKA, the primary native restraints to AP laxity,
                  namely the ACL and the medial meniscus, have been removed<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">25</span></a>.
                  The removal of these restraints necessitates that the collateral ligaments resist applied anterior
                  tibial loads. However, the collateral ligaments are more vertically oriented and are located on the
                  periphery of the joint. Therefore, they are less effective than the more AP-oriented cruciate
                  ligaments at accomplishing this task<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">26</span></a>.
                  Since the collateral ligaments are not aligned to resist ATT, tensions greater than those in the
                  native MCL are required to constrain ATT to &lt;1 cm<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">27</span></a> (<a
                    href="" data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 5</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Similar to previous findings, the LCL in the native knee
                  was load-bearing at full extension<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">28</span></a> (<a
                    href="" data-id="figure_reference_13" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). Other than at full extension, LCL tensions in the native knee
                  and following TKA both were small and differed minimally during passive flexion (<a href=""
                    data-id="figure_reference_14" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). In contrast, median MCL tension at 30° of passive flexion
                  following TKA was 8-fold greater than that in the native knee (<a href=""
                    data-id="figure_reference_15" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4</a>). This finding indicates that, unlike in the native knee,
                  surgeons rely on the MCL for knee stability in PS TKA, as corroborated by previous literature<a
                    href="" data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_36"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">29</span></a>. Furthermore, heightened MCL tension in midflexion could
                  partially account for the sensation of stiffness that many patients express following TKA even though
                  they have adequate range of motion.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Our findings of high sensitivity of AP laxity to MCL
                  tension in midflexion could help inform intraoperative tension targets to achieve knee stability using
                  this PS implant design. Specifically, MCL tension that was ≥48 N in midflexion following TKA resulted
                  in ATT of ≤8 mm (<a href="" data-id="figure_reference_16"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>).
                  Conversely, MCL tension of ≤12 N yielded ATT of ≥12 mm.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">The nonlinear relationship observed between MCL tension
                  during passive flexion and ATT in midflexion (<a href="" data-id="figure_reference_17"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>)
                  highlights the competing objectives that a surgeon faces in reducing anterior knee laxity.
                  Specifically, restoring native MCL tension during TKA comes at the expense of elevated ATT in
                  midflexion. Conversely, achieving native ATT in midflexion requires supraphysiological MCL tension.
                  This relationship underscores the difficulty in tensioning the MCL intraoperatively, so it is not
                  surprising that elevated AP laxity can contribute to patient dissatisfaction<a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_37" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript" style="position: unset;">9</span></a>.
                  Clinical studies are required to identify the MCL tension that can best satisfy these 2 competing
                  objectives.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">This study had limitations. First, our results cannot be
                  generalized to cruciate-preserving implant designs, designs with a single-radius femoral component,
                  and designs with minimally constrained bearing surfaces. Nevertheless, our findings are likely
                  generalizable to condylar PS TKA systems with a dual-radius femoral component and relatively
                  nonconforming bearing surfaces. Second, common clinical examinations with low compressive load (10 N)
                  were simulated that maximized the role of the ligaments and minimized the role of the tibial bearing
                  surface in resisting the applied anterior tibial force<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript" style="position: unset;">30</span></a>.
                  Third, this study was performed in a cadaveric model with no applied muscle forces. Neither the active
                  restraint of the hamstrings, quadriceps, or popliteus muscles nor the restraint provided by the
                  proximal ITB were modeled. Follow-up studies under loads that occur during gait and stair descent,
                  including muscle forces, would aid in relating ligament tension to knee stability during these
                  functional activities. Nevertheless, in vivo measurements of AP laxity are within 10% of corresponding
                  cadaveric measurements; therefore, cadaveric models are representative of the in vivo state of the
                  knee<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_39"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">31</span></a>. Fourth, mechanical cutting jigs were used to implant the
                  knee prosthesis without robotic assistance or computer navigation, which likely increased variability
                  in ligament tensions. Nevertheless, this variability can be considered a strength because it helped
                  reveal a nonlinear relationship between collateral ligament tension and AP laxity. Moreover, a manual
                  approach reflects the majority of TKAs performed; therefore, our data are likely representative of the
                  variability in ligament tensions seen clinically in measured-resection TKA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, the median tension carried by the MCL
                  following cruciate-resecting PS TKA exceeded that in the native knee by up to 8-fold in midflexion,
                  while LCL loading was small and similar in magnitude to that in the native knee. Sagittal-plane laxity
                  was highly sensitive to MCL tension during passive flexion at 30°. Restoring native MCL tension during
                  TKA comes at the expense of elevated ATT in midflexion while achieving native ATT in midflexion
                  requires supraphysiological MCL tension. Surgeons should be aware of this tradeoff when tensioning the
                  MCL.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Bourne RB</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Chesworth BM</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Davis AM</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Mahomed NN</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Charron KD</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Patient satisfaction after total
                  knee arthroplasty: who is satisfied and who is not?</span><span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2010
                Jan;468(1):57-63.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20satisfaction%20after%20total%20knee%20arthroplasty%3A%20who%20is%20satisfied%20and%20who%20is%20not%3F&as_occt=title&as_sauthors=%20%22RB%20Bourne%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Kahlenberg CA</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Nwachukwu BU</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> McLawhorn AS</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Cross MB</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Cornell CN</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Padgett DE</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Patient Satisfaction After Total
                  Knee Replacement: A Systematic Review</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">HSS J.</span> 2018 Jul;14(2):192-201.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20Satisfaction%20After%20Total%20Knee%20Replacement%3A%20A%20Systematic%20Review&as_occt=title&as_sauthors=%20%22CA%20Kahlenberg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Paxton EW</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Namba RS</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Maletis GB</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Khatod M</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Yue EJ</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Davies M</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Low RB Jr</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Wyatt RW</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Inacio MC</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Funahashi TT</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">A prospective study of 80,000
                  total joint and 5000 anterior cruciate ligament reconstruction procedures in a community-based
                  registry in the United States</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2010 Dec;92(Suppl 2):117-32.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20prospective%20study%20of%2080%2C000%20total%20joint%20and%205000%20anterior%20cruciate%20ligament%20reconstruction%20procedures%20in%20a%20community-based%20registry%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22EW%20Paxton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Wright TM</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Joint Stability in Total Knee
                  Arthroplasty: What Is the Target for a Stable Knee?</span><span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2017 Feb;25(Suppl
                1):S25-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Joint%20Stability%20in%20Total%20Knee%20Arthroplasty%3A%20What%20Is%20the%20Target%20for%20a%20Stable%20Knee%3F&as_occt=title&as_sauthors=%20%22TM%20Wright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Matsuda Y</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Ishii Y</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Noguchi H</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Ishii R</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Varus-valgus balance and range
                  of movement after total knee arthroplasty</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 2005
                Jun;87(6):804-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Varus-valgus%20balance%20and%20range%20of%20movement%20after%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22Y%20Matsuda%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Abdel MP</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Haas SB</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">The unstable knee: wobble and
                  buckle</span>. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Bone
                  Joint J.</span> 2014 Nov;96-B(11)(Supple A):112-4.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20unstable%20knee%3A%20wobble%20and%20buckle&as_occt=title&as_sauthors=%20%22MP%20Abdel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Parratte S</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Pagnano MW</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Instability after total knee
                  arthroplasty</span>. <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2008 Jan;90(1):184-94.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=911137" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Rodriguez-Merchan EC</span>.
                <span data-id="strong_8" class="annotation strong" style="position: unset;">Patient Satisfaction
                  Following Primary Total Knee Arthroplasty: Contributing Factors</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Arch Bone Jt Surg.</span> 2021
                Jul;9(4):379-86.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20Satisfaction%20Following%20Primary%20Total%20Knee%20Arthroplasty%3A%20Contributing%20Factors&as_occt=title&as_sauthors=%20%22EC%20Rodriguez-Merchan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Vajapey SP</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Pettit RJ</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Li M</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Chen AF</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Spitzer AI</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Glassman AH</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Risk Factors for Mid-Flexion
                  Instability After Total Knee Arthroplasty: A Systematic Review</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2020
                Oct;35(10):3046-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20Factors%20for%20Mid-Flexion%20Instability%20After%20Total%20Knee%20Arthroplasty%3A%20A%20Systematic%20Review&as_occt=title&as_sauthors=%20%22SP%20Vajapey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Schwab JH</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Haidukewych GJ</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Hanssen AD</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Jacofsky DJ</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Pagnano MW</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Flexion instability without
                  dislocation after posterior stabilized total knees</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2005
                Nov;440(440):96-100.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Flexion%20instability%20without%20dislocation%20after%20posterior%20stabilized%20total%20knees&as_occt=title&as_sauthors=%20%22JH%20Schwab%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_43" class="annotation" style="position: unset;"> McAuliffe M</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> O’Connor P</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Major L</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Garg G</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Whitehouse SL</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Crawford R</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Which Pre- and Postoperative
                  Coronal Plane Laxity Parameters Influence Patient Satisfaction and Function after Primary Total Knee
                  Arthroplasty?</span><span data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J
                  Knee Surg.</span> 2021 Nov;34(13):1446-53.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Which%20Pre-%20and%20Postoperative%20Coronal%20Plane%20Laxity%20Parameters%20Influence%20Patient%20Satisfaction%20and%20Function%20after%20Primary%20Total%20Knee%20Arthroplasty%3F&as_occt=title&as_sauthors=%20%22M%20McAuliffe%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Evans J</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Nielson JL</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Anterior Cruciate Ligament Knee
                  Injuries</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Treasure Island</span>: StatPearls; 2023.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20Cruciate%20Ligament%20Knee%20Injuries&as_occt=title&as_sauthors=%20%22J%20Evans%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Kahlenberg CA</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Berube EE</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Xiang W</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Manzi JE</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Jahandar H</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Chalmers BP</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Cross MB</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Mayman DJ</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Wright TM</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Westrich GH</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Imhauser CW</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Sculco PK</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Posterior-stabilized versus
                  mid-level constraint polyethylene components in total knee arthroplasty</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Bone Jt Open.</span> 2023
                Jun 5;4(6):432-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Posterior-stabilized%20versus%20mid-level%20constraint%20polyethylene%20components%20in%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22CA%20Kahlenberg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Kittl C</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> El-Daou H</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Athwal KK</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Gupte CM</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Weiler A</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Williams A</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Amis AA</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">The Role of the Anterolateral
                  Structures and the ACL in Controlling Laxity of the Intact and ACL-Deficient Knee</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2016 Feb;44(2):345-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Role%20of%20the%20Anterolateral%20Structures%20and%20the%20ACL%20in%20Controlling%20Laxity%20of%20the%20Intact%20and%20ACL-Deficient%20Knee&as_occt=title&as_sauthors=%20%22C%20Kittl%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Marom N</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Ouanezar H</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Jahandar H</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Zayyad ZA</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Fraychineaud T</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Hurwit D</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Imhauser CW</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Wickiewicz TL</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Pearle AD</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Nawabi DH</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Lateral Extra-articular
                  Tenodesis Reduces Anterior Cruciate Ligament Graft Force and Anterior Tibial Translation in Response
                  to Applied Pivoting and Anterior Drawer Loads</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2020
                Nov;48(13):3183-93.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lateral%20Extra-articular%20Tenodesis%20Reduces%20Anterior%20Cruciate%20Ligament%20Graft%20Force%20and%20Anterior%20Tibial%20Translation%20in%20Response%20to%20Applied%20Pivoting%20and%20Anterior%20Drawer%20Loads&as_occt=title&as_sauthors=%20%22N%20Marom%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Viidik A</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Functional properties of
                  collagenous tissues</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Int Rev Connect Tissue Res.</span> 1973;6:127-215.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Functional%20properties%20of%20collagenous%20tissues&as_occt=title&as_sauthors=%20%22A%20Viidik%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Grood ES</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Suntay WJ</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">A joint coordinate system for
                  the clinical description of three-dimensional motions: application to the knee</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J Biomech Eng.</span> 1983
                May;105(2):136-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20joint%20coordinate%20system%20for%20the%20clinical%20description%20of%20three-dimensional%20motions%3A%20application%20to%20the%20knee&as_occt=title&as_sauthors=%20%22ES%20Grood%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Camp CL</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Jahandar H</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Sinatro AM</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Imhauser CW</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Altchek DW</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Dines JS</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Quantitative Anatomic Analysis
                  of the Medial Ulnar Collateral Ligament Complex of the Elbow</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">Orthop J Sports Med.</span> 2018 Mar
                26;6(3):2325967118762751.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Quantitative%20Anatomic%20Analysis%20of%20the%20Medial%20Ulnar%20Collateral%20Ligament%20Complex%20of%20the%20Elbow&as_occt=title&as_sauthors=%20%22CL%20Camp%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Woo SLFR</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Masataka S</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Livesay GA</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Rudy TW</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Fu FH</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Biomechanics of the ACL:
                  measurement of in situ force in the ACL and knee kinematics</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Knee.</span> 1998;5(4):267-88.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Biomechanics%20of%20the%20ACL%3A%20measurement%20of%20in%20situ%20force%20in%20the%20ACL%20and%20knee%20kinematics&as_occt=title&as_sauthors=%20%22SLFR%20Woo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Imhauser C</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Mauro C</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Choi D</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Rosenberg E</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Mathew S</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Nguyen J</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Ma Y</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Wickiewicz T</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Abnormal tibiofemoral contact
                  stress and its association with altered kinematics after center-center anterior cruciate ligament
                  reconstruction: an in vitro study</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2013 Apr;41(4):815-25.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Abnormal%20tibiofemoral%20contact%20stress%20and%20its%20association%20with%20altered%20kinematics%20after%20center-center%20anterior%20cruciate%20ligament%20reconstruction%3A%20an%20in%20vitro%20study&as_occt=title&as_sauthors=%20%22C%20Imhauser%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Elmasry SS</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Sculco PK</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Kahlenberg CA</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Mayman DJ</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Cross MB</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Pearle AD</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Wright TM</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Westrich GH</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Imhauser CW</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Arthroplasty Surgeons Differ in
                  Their Intraoperative Soft Tissue Assessments: A Study in Human Cadavers to Quantify Surgical
                  Decision-making in TKA</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2022 Aug 1;480(8):1604-15.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthroplasty%20Surgeons%20Differ%20in%20Their%20Intraoperative%20Soft%20Tissue%20Assessments%3A%20A%20Study%20in%20Human%20Cadavers%20to%20Quantify%20Surgical%20Decision-making%20in%20TKA&as_occt=title&as_sauthors=%20%22SS%20Elmasry%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Espregueira-Mendes J</span>,
                <span data-id="annotation_112" class="annotation" style="position: unset;"> da Silva MV</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Anatomy of the lateral
                  collateral ligament: a cadaver and histological study</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2006
                Mar;14(3):221-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anatomy%20of%20the%20lateral%20collateral%20ligament%3A%20a%20cadaver%20and%20histological%20study&as_occt=title&as_sauthors=%20%22J%20Espregueira-Mendes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> LaPrade MD</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Kennedy MI</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Wijdicks CA</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> LaPrade RF</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Anatomy and biomechanics of the
                  medial side of the knee and their surgical implications</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">Sports Med Arthrosc Rev.</span> 2015
                Jun;23(2):63-70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anatomy%20and%20biomechanics%20of%20the%20medial%20side%20of%20the%20knee%20and%20their%20surgical%20implications&as_occt=title&as_sauthors=%20%22MD%20LaPrade%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Razu SS</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Jahandar H</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Zhu A</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Berube EE</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Manzi JE</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Pearle AD</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Nawabi DH</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Wickiewicz TL</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Santner TJ</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Imhauser CW</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Bayesian Calibration of
                  Computational Knee Models to Estimate Subject-Specific Ligament Properties, Tibiofemoral Kinematics,
                  and Anterior Cruciate Ligament Force With Uncertainty Quantification</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">J Biomech Eng.</span> 2023
                Jul 1;145(7):071003.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bayesian%20Calibration%20of%20Computational%20Knee%20Models%20to%20Estimate%20Subject-Specific%20Ligament%20Properties%2C%20Tibiofemoral%20Kinematics%2C%20and%20Anterior%20Cruciate%20Ligament%20Force%20With%20Uncertainty%20Quantification&as_occt=title&as_sauthors=%20%22SS%20Razu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Papageorgiou CD</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Gil JE</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Kanamori A</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Fenwick JA</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Woo SL</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Fu FH</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">The biomechanical
                  interdependence between the anterior cruciate ligament replacement graft and the medial
                  meniscus</span>. <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Am J
                  Sports Med.</span> 2001 Mar-Apr;29(2):226-31.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20biomechanical%20interdependence%20between%20the%20anterior%20cruciate%20ligament%20replacement%20graft%20and%20the%20medial%20meniscus&as_occt=title&as_sauthors=%20%22CD%20Papageorgiou%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Burstein A</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Wright T</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">Fundamentals of Orthopaedic
                  Biomechanics.</span> Williams & Wilkins; 1994.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_135" class="annotation" style="position: unset;"> LaPrade RF</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Engebretsen AH</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Ly TV</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Johansen S</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Wentorf FA</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Engebretsen L</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">The anatomy of the medial part
                  of the knee</span>. <span data-id="emphasis_27" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2007 Sep;89(9):2000-10.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1025333" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Kia M</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Schafer K</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Lipman J</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Cross M</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Mayman D</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Pearle A</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Wickiewicz T</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Imhauser C</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">A Multibody Knee Model
                  Corroborates Subject-Specific Experimental Measurements of Low Ligament Forces and Kinematic Coupling
                  During Passive Flexion</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">J Biomech Eng.</span> 2016 May;138(5):051010.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Multibody%20Knee%20Model%20Corroborates%20Subject-Specific%20Experimental%20Measurements%20of%20Low%20Ligament%20Forces%20and%20Kinematic%20Coupling%20During%20Passive%20Flexion&as_occt=title&as_sauthors=%20%22M%20Kia%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Sakane M</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Livesay GA</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Fox RJ</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Rudy TW</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Runco TJ</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Woo SL</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Relative contribution of the
                  ACL, MCL, and bony contact to the anterior stability of the knee</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span>
                1999;7(2):93-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Relative%20contribution%20of%20the%20ACL%2C%20MCL%2C%20and%20bony%20contact%20to%20the%20anterior%20stability%20of%20the%20knee&as_occt=title&as_sauthors=%20%22M%20Sakane%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Wang X</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Malik A</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Bartel DL</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Wright TM</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Padgett DE</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Load Sharing Among Collateral
                  Ligaments, Articular Surfaces, and the Tibial Post in Constrained Condylar Knee Arthroplasty</span>.
                <span data-id="emphasis_30" class="annotation emphasis" style="position: unset;">J Biomech Eng.</span>
                2016 Aug 1;138(8).</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Load%20Sharing%20Among%20Collateral%20Ligaments%2C%20Articular%20Surfaces%2C%20and%20the%20Tibial%20Post%20in%20Constrained%20Condylar%20Knee%20Arthroplasty&as_occt=title&as_sauthors=%20%22X%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Fleming BC</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Brattbakk B</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Peura GD</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Badger GJ</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Beynnon BD</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Measurement of
                  anterior-posterior knee laxity: a comparison of three techniques</span>. <span data-id="emphasis_31"
                  class="annotation emphasis" style="position: unset;">J Orthop Res.</span> 2002
                May;20(3):421-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Measurement%20of%20anterior-posterior%20knee%20laxity%3A%20a%20comparison%20of%20three%20techniques&as_occt=title&as_sauthors=%20%22BC%20Fleming%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D2071d71c-aed0-4d25-a6f2-7bd5502668f0%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">August 21, 2024;
                      106 (16): 1486</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01061</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">June 13, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_32"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the Hospital
                            for Special Surgery, New York, NY</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;2071d71c-aed0-4d25-a6f2-7bd5502668f0&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=2071d71c-aed0-4d25-a6f2-7bd5502668f0&type=pdf&name=JBJS.23.01061.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=2071d71c-aed0-4d25-a6f2-7bd5502668f0&type=pdf&name=JBJS.23.01061.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_36"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This study was funded
                          by the Clark Foundation and Kirby Foundation. Zimmer Biomet donated the implants used in this
                          study. The <span data-id="strong_37" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I61"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I61</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;2071d71c-aed0-4d25-a6f2-7bd5502668f0&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=2071d71c-aed0-4d25-a6f2-7bd5502668f0&type=zip&name=JBJS.23.01061.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Erin E. Berube, MS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3204-9381" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3204-9381</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">William Xiang, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7476-683X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7476-683X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Joseph E. Manzi, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8825-0105" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8825-0105</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">David J. Mayman, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Geoffrey H. Westrich, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8099-4620" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8099-4620</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Timothy M. Wright, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7139-1908" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7139-1908</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Brian P. Chalmers, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4195-8648" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4195-8648</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Carl W. Imhauser, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:imhauserc@hss.edu" class="" style="position: unset;">imhauserc@hss.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2445-7112" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2445-7112</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Peter K. Sculco, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7050-5571" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7050-5571</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Cynthia A. Kahlenberg, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3852-2926" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3852-2926</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Biomechanics, Hospital for Special Surgery, New York, NY</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Adult Reconstruction and Joint Replacement Service, Hospital for Special
                Surgery, New York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 24391.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
