import { useKBar } from "kbar";
import { useCallback } from "react";

export const useToggleCommandbar = () => {
	const kbar = useKBar();

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	const toggle = useCallback(() => {
		kbar.query.toggle();
	}, []);

	return toggle;
};
