import { useToast } from "@contexts/ToastContext";
import { useAppStore } from "@hooks/useAppStore";
import useClerkUser from "@hooks/useClerkUser";
import { updateUserInfo } from "@memorang/api-client";
import { useAuthContext } from "@memorang/applets";
import { router } from "expo-router";
import { useState } from "react";

const useChangeDisplayUsername = () => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");

	const [updatingUsername, setUpdatingUsername] = useState(false);

	const { email } = useClerkUser();

	const { setUserInfoNew } = useAuthContext();

	const { showToast } = useToast();

	const appId = useAppStore((state) => state.app.id);

	const handleUpdateUsername = async () => {
		setUpdatingUsername(true);
		setUserInfoNew(firstName, lastName);

		showToast({
			message: "Username updated successfully",
			duration: 2000,
		});
		router.back();
		await updateUserInfo(email!, appId, {
			firstName: firstName.trim(),
			lastName: lastName.trim(),
		});

		setUpdatingUsername(false);
	};

	return {
		firstName,
		setFirstName,
		lastName,
		setLastName,
		updatingUsername,
		handleUpdateUsername,
	};
};

export default useChangeDisplayUsername;
