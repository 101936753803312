import type { Article } from "../../../../../types";

export const Article532: Article = {
	id: 532,
	rsuiteId: "df947fed-dbf5-44d6-b3f3-e8de4bf264a6",
	type: "commentary and perspective",
	title:
		"Accurate Preoperative Digital Planning: The First Mandatory Step to Success in Complex and Unusual Total Hip Arthroplasties",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/532.png",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Accurate Preoperative Digital Planning: The First
                Mandatory Step to Success in Complex and Unusual Total Hip Arthroplasties</div>
              <div class="text subtitle" style="position: unset;">Commentary on an article by Babar Kayani, MBBS, BSc,
                FRCS(Tr&Orth), PhD, et al.: “The Wagner Cone Stem for Atypical Femoral Anatomy in Total Hip
                Arthroplasty. A Report of 320 Cases with Minimum 5-Year Follow-up”</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Cécile
                      Batailler, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">Total hip arthroplasty (THA) in a young population with
                  abnormal proximal femoral morphology, such as due to Legg-Calvé Perthes disease, is always
                  challenging. The young age, the femoral deformity, the small size of the femoral shaft, the potential
                  severe leg length discrepancy, etc., explain the difficulties with the surgery and the substantial
                  risk of early or delayed complications<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">2</span></a>. Several implants and surgical techniques have been
                  developed to manage these complex hips, such as a customized stem, shortening femoral osteotomy, or
                  trochanteric osteotomy. Even if these surgical techniques are very interesting and attractive for
                  severe hip deformities, they can lead to dramatic complications and residual disability<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">3</span></a>. They should be used only as a last resort. Most of the
                  time, difficulties with complex surgery should be solved with standard implants and surgical
                  techniques that the surgeon can better control.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">Kayani et al. reported a high rate of long-term survival
                  of the Wagner Cone Prosthesis for abnormal proximal femoral morphology. Their patients had no
                  stem-related aseptic loosening or mechanical complications except for 1 case of hip dislocation. Only
                  3.8% of patients had stem subsidence of 5 mm or more during the first 12 months, which was
                  asymptomatic in all cases, and 1.25% had a nondisplaced intraoperative femoral fracture during bone
                  preparation, requiring cerclage wires and partial weight-bearing. These excellent results demonstrate
                  the relevance of the indication and the type of implant used. The femoral stem used in this series was
                  not standard, but a monoblock conical prosthesis for which femoral preparation was close to that for a
                  standard stem.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">The strength of this study is the high number of included
                  patients with long-term follow-up for an uncommon pathological condition. Even without a control
                  group, the results in this large series with complex THA were interesting and reliable and validate
                  the relevance of the surgical strategy, with a monoblock conical stem and without osteotomy. Kayani et
                  al. determined the required implant and surgical technique before the surgery, although they did not
                  explain the preoperative templating. The main question is how to determine which procedure and implant
                  you need for these complex, unusual hip deformities. Specific implants, particularly customized ones,
                  require time to be produced; the decision cannot be made in the operating room on the morning of the
                  surgery. The principle is the same for the surgical technique; hip osteotomies are not always easy and
                  intuitive and should be planned before the surgery. Preoperative planning is thus crucial to
                  anticipate the required implant, the potential femoral osteotomies and their localization, and the
                  degree and axis of correction. But when and how do you perform reliable surgical planning before the
                  surgery?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Preoperative planning must be systematic before every THA,
                  including simple ones. We do well what we do often. Plan for every hip so that you will be ready when
                  you need to plan for a complex and unusual hip. Several preoperative planning software programs have
                  been developed in 2D with calibrated radiographs or in 3D with preoperative computed tomography (CT);
                  3D planning is more attractive and reliable thanks to the assessment of the 3 planes<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">5</span></a>.
                  Nevertheless, surgeons are less accustomed to 3D planning and can sometimes be less accurate than when
                  they use traditional radiography. Therefore, the learning of 3D planning must be combined initially
                  with radiograph-based planning. The current software allowing 3D planning is that used for
                  robotic-assisted systems or software for customized implants or cutting guides, but not all implants
                  can be used with these systems. The scale of the preoperative image (radiograph or CT scan) must be
                  known and precise to perform reliable scaled planning. The surgeon can perform several types of
                  surgical planning for these complex cases of abnormal proximal femoral morphology to determine the
                  best option to position the hip rotation center well, restore an adjusted femoral offset, and correct
                  leg length discrepancy as well as possible with an appropriate size of femoral stem. Kayani et al.
                  probably planned their surgical procedures well, because they had very few perioperative
                  complications. However, we do not know if they needed to make some changes during the surgery such as
                  an unplanned osteotomy or implant.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">This robust study brings to light 2 primary points. First,
                  preoperative surgical planning is essential for complex surgery, particularly THA on abnormal proximal
                  femoral morphology, to anticipate the required implants and surgical technique. Second, simple surgery
                  and typical implants must always be preferred, if possible, to avoid unnecessary complications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Sofu H</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Kockara N</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Gursu S</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Issin A</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Oner A</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Sahin V</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Transverse Subtrochanteric
                  Shortening Osteotomy During Cementless Total Hip Arthroplasty in Crowe Type-III or IV Developmental
                  Dysplasia</span>. <span data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2015 Jun;30(6):1019-1-2.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Transverse%20Subtrochanteric%20Shortening%20Osteotomy%20During%20Cementless%20Total%20Hip%20Arthroplasty%20in%20Crowe%20Type-III%20or%20IV%20Developmental%20Dysplasia&as_occt=title&as_sauthors=%20%22H%20Sofu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Hanna SA</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Sarraf KM</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Ramachandran M</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Achan P</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Systematic review of the outcome
                  of total hip arthroplasty in patients with sequelae of Legg-Calvé-Perthes disease</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Arch Orthop Trauma
                  Surg.</span> 2017 Aug;137(8):1149-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Systematic%20review%20of%20the%20outcome%20of%20total%20hip%20arthroplasty%20in%20patients%20with%20sequelae%20of%20Legg-Calv%C3%A9-Perthes%20disease&as_occt=title&as_sauthors=%20%22SA%20Hanna%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Grappiolo G</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> La Camera F</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Della Rocca A</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Mazziotta G</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Santoro G</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Loppini M</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Total hip arthroplasty with a
                  monoblock conical stem and subtrochanteric transverse shortening osteotomy in Crowe type IV dysplastic
                  hips</span>. <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Int
                  Orthop.</span> 2019 Jan;43(1):77-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20hip%20arthroplasty%20with%20a%20monoblock%20conical%20stem%20and%20subtrochanteric%20transverse%20shortening%20osteotomy%20in%20Crowe%20type%20IV%20dysplastic%20hips&as_occt=title&as_sauthors=%20%22G%20Grappiolo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Wang Z</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Li H</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Zhou Y</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Deng W</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Three-dimensional femoral
                  morphology in Hartofilakidis type C developmental dysplastic hips and the implications for total hip
                  arthroplasty</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Int Orthop.</span> 2020 Oct;44(10):1935-1-2.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Three-dimensional%20femoral%20morphology%20in%20Hartofilakidis%20type%20C%20developmental%20dysplastic%20hips%20and%20the%20implications%20for%20total%20hip%20arthroplasty&as_occt=title&as_sauthors=%20%22Z%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Crutcher JP</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Hameed D</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Dubin J</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Mont MA</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Mont M</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Comparison of three-versus
                  two-dimensional pre-operative planning for total hip arthroplasty</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">J Orthop.</span> 2023 Nov
                7;47:100-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20three-versus%20two-dimensional%20pre-operative%20planning%20for%20total%20hip%20arthroplasty&as_occt=title&as_sauthors=%20%22JP%20Crutcher%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Ddf947fed-dbf5-44d6-b3f3-e8de4bf264a6%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Commentary and Perspective</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">August 21, 2024;
                      106 (16): e34</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00555</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">August 21, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7&native=1"
                              class="article-title" style="position: unset;">The Wagner Cone Stem for Atypical Femoral
                              Anatomy in Total Hip Arthroplasty<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">A Report of 320 Cases with Minimum 5-Year
                                Follow-up</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Kayani,
                              Babar; Neufeld, Michael E.; Bautista, Maria; Howard, Lisa C.; Abdelmalek, Mohammed;
                              Greidanus, Nelson V.; Masri, Bassam A.; Garbuz, Donald S.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(16):1461 | May 30, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;df947fed-dbf5-44d6-b3f3-e8de4bf264a6&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=df947fed-dbf5-44d6-b3f3-e8de4bf264a6&type=pdf&name=JBJS.24.00555.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=df947fed-dbf5-44d6-b3f3-e8de4bf264a6&type=pdf&name=JBJS.24.00555.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_6" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_7"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I44" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I44</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;df947fed-dbf5-44d6-b3f3-e8de4bf264a6&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=df947fed-dbf5-44d6-b3f3-e8de4bf264a6&type=zip&name=JBJS.24.00555.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Cécile Batailler, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:cecile-batailler@hotmail.fr" class=""
                    style="position: unset;">cecile-batailler@hotmail.fr</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3990-0906" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3990-0906</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Orthopedic Surgery Department, Croix-Rousse Hospital, Lyon, France</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Univ Lyon, Université Claude Bernard Lyon, Villeurbanne, France</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 5922.43px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
