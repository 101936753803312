import { relayEnv } from "@memorang/configs";
/* -----------------Globals--------------- */
import { fetchData } from "@memorang/helpers";
import type { BundleDetails } from "@memorang/types";
import { graphql } from "react-relay";

type Response = {
	bundleList: BundleDetails[];
};
const query = graphql`
  query FetchBundlesQuery($tenantId: ID) {
    bundleList(tenantId: $tenantId) {
      metadata {
        permissions
        subscription {
          status
          endDate
        }
        trial {
          isOpted
          endDate
          status
        }
      }
      bundle {
        id
        name
        numTrialDays
        isActive
        name
        numericId
        description
        tagline
        isMobileOnly
        categories
        features
        contentsItemsCount {
          numQuestions
          numFacts
        }
        plans {
          id
          name
          amount
          currency
          interval
          duration
        }
        contents {
          folder {
            contentsItemsCount {
              numFacts
              numQuestions
            }
            contentsCount {
              numFolders
              numStudySets
              numQuestionSets
            }
            contents {
              edges {
                node {
                  content {
                    id
                    ... on Folder {
                      contents {
                        edges {
                          node {
                            content {
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        icon {
          url
        }
      }
    }
  }
`;

const fetchBundleList = async (
	tenantId: string,
	isWeb: boolean,
): Promise<BundleDetails[]> => {
	const variables = {
		tenantId,
	};
	const { bundleList } = (await fetchData(
		relayEnv,
		query,
		variables,
	)) as Response;
	const filteredBundleList = bundleList.filter(
		({ bundle: { isMobileOnly } }) => {
			return isWeb ? !isMobileOnly : isMobileOnly;
		},
	);
	return filteredBundleList;
};

export default fetchBundleList;
