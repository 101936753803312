/* -----------------Globals--------------- */
import { useRef } from "react";

import { defaultAiAssistantInfo } from "@memorang/helpers";
/* -----------------Helpers & Hooks--------------- */
import {
	SearchContextProvider,
	useSearchContext,
} from "./contexts/SearchContext";
import { useSearchView } from "./hooks/useSearchView";

import type { AiAssistantInfo } from "@memorang/ui";
/* -----------------Types--------------- */
import type { TextInput } from "react-native";
import type { FeedbackParams } from "../feedback";
import type { HandleTracking } from "../types";
import type { VectorStoreParams } from "./types";
import type { OriginalQuestionData } from "./types/question";

/* -----------------Child components--------------- */
import { Box, ChatUI } from "@memorang/ui";

type Props = {
	vectorStoreParams: VectorStoreParams;
	context: string;
	defaultQuery?: string;
	feedbackDialogParams?: FeedbackParams;
	handleTracking: HandleTracking;
	userId: string;
	handleClose?: () => void;
	handleNavigateToReference?: (url: string) => void;
	searchContext?: string;
	referenceThumbnails?: Record<string, string>;
	assistantInfo?: AiAssistantInfo;
	userPrompt?: string;
	isItemAnswered?: boolean;
	originalQuestionData?: OriginalQuestionData;
	bottomAppBarStyle?: React.ComponentProps<typeof Box>;
	activityType?: "review" | "reader";
	filterSourceIds?: string[];
	extraDetails?: string;
};

// TODO: Change component name
export const AiLAMobile = (props: Props) => {
	return (
		<SearchContextProvider
			defaultQuery={props.defaultQuery}
			userId={props.userId}
			vectorStoreParams={props.vectorStoreParams}
			context={props.context}
			sessionId={props.feedbackDialogParams?.sessionId!}
			assistantInfo={props.assistantInfo ?? defaultAiAssistantInfo}
			originalQuestionData={props.originalQuestionData}
			userPrompt={props.userPrompt}
			activityType={props.activityType}
			filterSourceIds={props.filterSourceIds}
			extraDetails={props.extraDetails}
		>
			<InnerAiLAMobile {...props} />
		</SearchContextProvider>
	);
};

const InnerAiLAMobile = ({
	feedbackDialogParams,
	handleTracking,
	handleNavigateToReference,
	searchContext,
	referenceThumbnails,
	isItemAnswered,
	bottomAppBarStyle,
}: Props) => {
	const inputRef = useRef<TextInput | null>(null);

	const {
		loading,
		streaming,
		context,
		showReference,
		searchQuery,
		messages,
		abortSearch,
		assistantInfo,
		isThreadLoading,
	} = useSearchContext();

	const {
		handleSelectQuery,
		localRecommendedQueries,
		messageActions,
		appBarFeedbackAction,
		messageFeedbackComponents,
		isRecommendedQuestionsFetching,
		interceptSearchQuery,
		handleResetChatState,
		initialTitle,
	} = useSearchView({
		feedbackDialogParams,
		handleTracking,
		searchContext,
	});

	const shouldScrollViewScrollToEnd =
		loading || streaming || isRecommendedQuestionsFetching || isThreadLoading;

	return (
		<Box position="relative" height="100%">
			<ChatUI>
				<ChatUI.AppBar
					title={assistantInfo.title}
					subtitle={isItemAnswered ? initialTitle : null}
					isSubtitleLoading={
						(isRecommendedQuestionsFetching && !initialTitle) || isThreadLoading
					}
					menuActions={[appBarFeedbackAction]}
					// ?: Add proper actions
					// actions={[
					//   {
					//     handleAction: handleResetChatState,
					//     title: 'New Chat',
					//     icon: 'plus',
					//     disabled: loading || streaming,
					//   },
					// ]}
				/>
				<ChatUI.ScrollView shouldScrollToEnd={shouldScrollViewScrollToEnd}>
					<ChatUI.MessageList
						assistantInfo={assistantInfo}
						messages={messages}
						isRecommendedQuestionsFetching={isRecommendedQuestionsFetching}
						loading={loading}
						streaming={streaming}
						recommendedQueries={localRecommendedQueries}
						handleSelectQuery={handleSelectQuery}
						context={context}
						showSummarizeResponse={false}
						handleNavigateToReference={handleNavigateToReference}
						showReference={showReference}
						messageActions={messageActions}
						referenceThumbnails={referenceThumbnails}
						isThreadLoading={isThreadLoading}
						isItemAnswered={isItemAnswered}
					/>
				</ChatUI.ScrollView>
				<ChatUI.BottomAppBar
					showConversationMode
					searchQuery={searchQuery.value}
					interceptSearchQuery={interceptSearchQuery}
					searchContext={context}
					handleReset={handleResetChatState}
					disableSearch={loading || streaming || !isItemAnswered}
					handleAbortSearch={abortSearch}
					ref={inputRef}
					showAdornments={isItemAnswered}
					style={bottomAppBarStyle}
				/>
			</ChatUI>
			{messageFeedbackComponents}
		</Box>
	);
};
