import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";
/* ----------------- Local Imports --------------- */
import MarkdownRenderer from "../MarkdownRenderer";

type Props = {
	title: string;
	subtitle?: string;
};

const Header = ({
	title,
	subtitle = "Maximize your exam readiness",
}: Props) => {
	const theme = useAppTheme();

	return (
		<Box alignItems="center">
			<MarkdownRenderer
				text={title}
				textAlign="center"
				fontSize={24}
				customStyles={{
					text: {
						lineHeight: 32,
						color: theme.colors.secondary,
					},
				}}
			/>

			<MarkdownRenderer
				text={subtitle}
				textAlign="center"
				fontSize={20}
				customStyles={{
					text: {
						lineHeight: 24,
						color: theme.colors.text,
					},
				}}
			/>
		</Box>
	);
};

export default Header;
