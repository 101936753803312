/* ----------------- Features --------------- */
import { useExamContext } from "@features/exam";
import {
	NotesCategorySectionList,
	fetchStudyGuides,
	subjectCategoryMap,
} from "@features/notes";

import { Loader } from "@memorang/ui";
import { useQuery } from "@tanstack/react-query";
/* ----------------- Components --------------- */
import { CustomAppbar } from "components/index";

const NotesRoute = () => {
	const {
		currentExamContext: { studyPackProductId },
	} = useExamContext();

	const { data } = useQuery({
		queryKey: ["GET_STUDY_GUIDE_TAGS", studyPackProductId],
		queryFn: async () => {
			const res = await fetchStudyGuides(
				studyPackProductId,
				"GET_STUDY_GUIDE_TAGS",
			);
			return res.fetchStudyGuideItems.items;
		},
	});

	const sections = Object.keys(subjectCategoryMap).map((part) => {
		return {
			title: part,
			data:
				data
					?.filter((item) => subjectCategoryMap[part].includes(item.title))
					.sort((a, b) => a.title.localeCompare(b.title)) || [],
		};
	});

	return (
		<>
			<CustomAppbar
				options={{
					headerTitle: "Study Guides",
				}}
			/>
			{data?.length ? (
				<NotesCategorySectionList sections={sections} isMainCategory />
			) : (
				<Loader />
			)}
		</>
	);
};

export default NotesRoute;
