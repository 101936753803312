/* ----------------- React Native Paper --------------- */
import { Button, Card, Divider, Text } from "react-native-paper";

/* ----------------- Expo --------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

/* ----------------- Helpers --------------- */
import { getFeedbackTextAndIcon } from "./helpers";

/* ----------------- Components --------------- */
import CardContent from "./CardContent";
import { ScoreRanges } from "./ScoreRanges";

/* ----------------- React --------------- */
import { useMemo } from "react";

/* ----------------- Types --------------- */
import type { StatProps } from "../../StatItem";
import type { ScoreRange } from "./types";

/* ----------------- Hooks --------------- */
import { useAppTheme } from "../../../useAppTheme";

/* ----------------- Components --------------- */
import Box from "../../Box";

/* ----------------- Index --------------- */
import { type IconType, useDevice } from "../../../index";

/* ----------------- Components --------------- */
import DonutScore from "../../DonutScore";

type Props = {
	title: string;
	score: number;
	stats: StatProps[];
	numItems: number;
	handleContinue: () => void;
	scoreRanges: ScoreRange[];
};

const Feedback = ({ score }: { score: number }) => {
	const theme = useAppTheme();

	const { icon, text } = useMemo(() => getFeedbackTextAndIcon(score), [score]);
	return (
		<Box flexDirection="row" gap={8} alignItems="center">
			<Icon
				color={theme.colors.success.main}
				name={icon as IconType}
				size={24}
			/>
			<Text variant="bodySmall">{`${text}`}</Text>
		</Box>
	);
};

const Score = ({ score }: { score: number }) => {
	const theme = useAppTheme();

	return (
		<Box
			top={-40}
			backgroundColor={theme.colors.elevation.level1}
			position="absolute"
			alignSelf="center"
			width={88}
			height={88}
			borderRadius={88 / 2}
			zIndex={1}
			padding={8}
		>
			<Box
				elevation={0}
				width={72}
				height={72}
				display="flex"
				justifyContent="center"
			>
				<DonutScore
					variant="progress"
					distribution={[score, 100 - score]}
					radius={40}
					innerRadius={30}
					score={score}
					textVariant="titleMedium"
				/>
			</Box>
		</Box>
	);
};

const CardHeader = ({
	title,
	score,
	numItems,
	handleContinue,
	scoreRanges,
}: Omit<Props, "stats">) => {
	const { isMobile } = useDevice();
	const [passScoreRange] = scoreRanges.slice(-1);
	const tooltip = `You scored ${score}% on this ${numItems}-item assessment. An ideal target score is ${passScoreRange.range} to maximize your chance of passing.`;

	return (
		<Box gap={8} flexDirection="column" alignItems="center" position="relative">
			<Score score={score} />
			<ScoreRanges scoreRanges={scoreRanges} tooltip={tooltip} />

			<Text
				style={{
					textAlign: "center",
				}}
				variant="titleMedium"
			>
				{title}
			</Text>
			<Feedback score={score} />
			{!isMobile && (
				<Button mode="contained" onPress={handleContinue}>
					Continue
				</Button>
			)}
		</Box>
	);
};

const StudySessionSummaryCard = ({
	title,
	score,
	stats,
	numItems,
	handleContinue,
	scoreRanges,
}: Props) => {
	return (
		<Card
			style={{
				borderRadius: 12,
				width: "100%",
			}}
		>
			<Box gap={16}>
				<CardHeader
					title={title}
					handleContinue={handleContinue}
					numItems={numItems}
					score={score}
					scoreRanges={scoreRanges}
				/>
				<Divider />
			</Box>
			<CardContent stats={stats} />
		</Card>
	);
};

export default StudySessionSummaryCard;
