import type Icon from "@expo/vector-icons/MaterialCommunityIcons";
export type SESSION_STATUS =
	| "ACTIVE"
	| "COMPLETE"
	| "NOT_STARTED"
	| "LEGACYREPORT"
	| "MIGRATEDREPORT";

interface ButtonProps {
	cta: string;
	icon: IconType;
	onPress: () => void;
	variant?: "small" | "large";
}

interface FeaturedChallenge {
	sectionTitle: string;
	id: number;
	title: string;
	description: string;
	numResponses: number;
	buttonProps?: ButtonProps;
	imageUri?: string;
	videoUri?: string;
}
export type Distribution = {
	low: number;
	medium: number;
	high: number;
	none: number;
};

export type { ButtonProps, FeaturedChallenge };
export type IconType = keyof typeof Icon.glyphMap;
