import { Box, Container } from "@memorang/ui";
import AiInsightsSuggestion from "@memorang/ui/src/components/AiInsightsSuggestion";
import { useRouter } from "expo-router";
import { useState } from "react";
import { ScrollView } from "react-native";
import { CME_EXPORT_CONSTANTS } from "../constants";
import { useCertificate } from "../contexts/CertificateContext";
import { useCME } from "../hooks/useCME";
import type { CmeType } from "../types";
import { CMENameConfirmationDialog } from "./CMENameConfirmationDialog";
import { CMETypeSelector } from "./CMETypeSelector";
import { DateRangeInput } from "./DateRangeInput";
import { ExportActionBar } from "./ExportActionBar";
import { ExportMethodSelector } from "./ExportMethodSelector";

const CMEExportView = () => {
	const router = useRouter();
	const [selectedDateRange, setSelectedDateRange] = useState({
		from: "",
		to: "",
	});
	const [selectedExportMethod, setSelectedExportMethod] = useState("pdf");
	const [selectedCMEType, setSelectedCMEType] = useState<CmeType>("physician");
	const { getEarnedCreditsForDateRange } = useCME();
	const [showConfirmNameDialog, setShowConfirmNameDialog] = useState(false);
	const { handleExport, isPending, toEmail, resetNamesToInitial } =
		useCertificate();

	const handleDismissConfirmNameDialog = () => {
		resetNamesToInitial();
		setShowConfirmNameDialog(false);
	};

	const handleConfirmName = () => {
		setShowConfirmNameDialog(false);
		handleExport({
			fromDate: selectedDateRange.from,
			toDate: selectedDateRange.to,
			selectedMethod: selectedExportMethod as "pdf" | "email",
			cmeType: selectedCMEType,
			totalCreditsEarned,
		});
	};

	const handleNavigateToActivityLog = () => {
		router.push({
			pathname: "/cme/log",
			params:
				selectedDateRange.from && selectedDateRange.to
					? {
							from: selectedDateRange.from,
							to: selectedDateRange.to,
						}
					: undefined,
		});
	};

	const handleLearnMore = () => {
		router.push("/aiinsights");
	};

	const totalCreditsEarned = getEarnedCreditsForDateRange(
		selectedDateRange.from,
		selectedDateRange.to,
	);

	const subtitle =
		totalCreditsEarned === 0
			? "No credits earned between start date - end date. Complete more activities or adjust selected range."
			: "CME Credit Earned";

	return (
		<Container containerStyle={{ flex: 1 }}>
			<ScrollView contentContainerStyle={{ flexGrow: 1 }}>
				<Box gap={16} paddingVertical={16} flex={1}>
					<Box paddingHorizontal={16}>
						<AiInsightsSuggestion
							text={CME_EXPORT_CONSTANTS.INSIGHTS_MESSAGE}
							buttonProps={{
								cta: "Learn More",
								onPress: handleLearnMore,
							}}
						/>
					</Box>

					<DateRangeInput
						value={selectedDateRange}
						onChange={setSelectedDateRange}
						minimumDate={null}
						maximumDate={new Date()}
						initialDate={new Date()}
					/>
					<CMETypeSelector
						selectedType={selectedCMEType}
						onSelect={setSelectedCMEType}
					/>

					<ExportMethodSelector
						selectedMethod={selectedExportMethod}
						onSelect={setSelectedExportMethod}
						toEmail={toEmail}
					/>
				</Box>
			</ScrollView>

			<ExportActionBar
				selectedMethod={selectedExportMethod}
				subtitle={subtitle}
				totalCreditsEarned={totalCreditsEarned}
				disabled={!totalCreditsEarned}
				onNavigateToActivityLog={handleNavigateToActivityLog}
				onExport={() => setShowConfirmNameDialog(true)}
				isLoading={isPending}
				toEmail={toEmail}
			/>
			<CMENameConfirmationDialog
				visible={showConfirmNameDialog}
				onDismiss={handleDismissConfirmNameDialog}
				onConfirm={handleConfirmName}
			/>
		</Container>
	);
};

export default CMEExportView;
