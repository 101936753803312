import { EmptyState } from "@memorang/ui";

import { router } from "expo-router";
import ComingSoonSvg from "../../assets/coming-soon.svg";

type Props = {
	view: string;
};
export const ComingSoonEmptyState = ({ view }: Props) => {
	const title = "Coming Soon";
	const subtitle = "What would you like to see? Leave Feedback";
	return (
		<EmptyState
			title={title}
			subtitle={subtitle}
			image={ComingSoonSvg}
			cta="Leave Feedback"
			handleAction={() =>
				router.push({
					pathname: "/feedback",
					params: {
						isComingSoon: "true",
						view,
					},
				})
			}
		/>
	);
};
