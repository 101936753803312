import { newEvents } from "@constants/tracking";
import useSubscriptionPurchase from "@hooks/useSubscriptionPurchase";
import { Box, Container } from "@memorang/ui";
import { trackCustomEvent } from "analytics";
import { useCallback, useMemo } from "react";
import { ChallengesHeader } from "../components/ChallengesHeader";
import ChallengesView from "../components/ChallengesView";
import FilterModalContainer from "../components/FilterModalContainer";
import SortModalContainer from "../components/SortModalContainer";
import { sortItems } from "../constants";
import useLibrary from "../hooks/useLibrary";

const ChallengesListContainer = () => {
	const { isPremiumUser } = useSubscriptionPurchase();
	const {
		quickActionsChips,
		specialitiesFilter,
		filteredSections,
		handleChallengePress,
		searchQuery,
		isPremiumSelected,
		isCompletedSelected,
		clearAllFilters,
		applySpecialityFilters,
		numChallenges,
		numTotalChallenges,
		handleQuickActionChipPress,
		showFilterModal,
		showSortModal,
		setShowFilterModal,
		setShowSortModal,
		handleSortPress,
		sortType,
	} = useLibrary();

	const sections = useMemo(() => {
		return filteredSections();
	}, [filteredSections]);

	const handleQuickActionPress = useCallback(
		(type: string) => {
			if (type === "speciality") {
				setShowFilterModal(true);
			} else {
				handleQuickActionChipPress(type);
			}
		},
		[handleQuickActionChipPress, setShowFilterModal],
	);

	const handleCloseFilterModal = useCallback(() => {
		setShowFilterModal(false);
		trackCustomEvent({
			eventName: newEvents.bottomSheetClosed,
			type: "filter",
			source: "challenges",
		});
	}, [setShowFilterModal]);

	const handleCloseSortModal = useCallback(() => {
		setShowSortModal(false);
		trackCustomEvent({
			eventName: newEvents.bottomSheetClosed,
			type: "sort",
			source: "challenges",
		});
	}, [setShowSortModal]);

	return (
		<Container containerStyle={{ paddingTop: 16 }} maxWidth="sm">
			<ChallengesHeader
				numChallenges={numChallenges}
				quickActionsChips={quickActionsChips}
				onQuickActionPress={handleQuickActionPress}
				searchQuery={searchQuery}
			/>
			<Box paddingTop={8} paddingBottom={8}>
				<ChallengesView
					contentContainerStyle={{ paddingBottom: 200 }}
					sections={sections}
					handleChallengePress={handleChallengePress}
					handleClearFilters={clearAllFilters}
					isPremiumUser={isPremiumUser}
					activeFilters={{
						searchQuery,
						isPremiumSelected,
						isCompletedSelected,
					}}
					totalChallenges={numTotalChallenges}
					isCME={false}
				/>
			</Box>
			<SortModalContainer
				showModal={showSortModal}
				handleSheetClose={handleCloseSortModal}
				handleSelectSortItem={handleSortPress}
				sortItems={sortItems}
				currentSortType={sortType}
			/>
			<FilterModalContainer
				showModal={showFilterModal}
				handleSheetClose={handleCloseFilterModal}
				specialitiesFilter={specialitiesFilter}
				applyFilters={applySpecialityFilters}
			/>
		</Container>
	);
};

export default ChallengesListContainer;
