/* -----------------UI--------------- */
import { Button, Text } from "react-native-paper";

import { blackListedStudypackIdsForWeb } from "@constants/common";
import { FeaturesSection } from "@features/dashboard";
import { useExamContext, useExams } from "@features/exam";
import { images } from "@helpers/images";
import { isWeb } from "@helpers/platform";
import { useAppStore } from "@hooks/useAppStore";
import { formatCount } from "@memorang/helpers";
import type { ProductType } from "@memorang/types";
import { Box, MarkdownRenderer, useDevice } from "@memorang/ui";
import StoresDownloadBadge from "components/DrawerContents/StoresDownloadBadge";
import { OLXExamEligibilityDialog } from "components/OLXExamEligibilityDialog";
import { useRouter } from "expo-router";
import { useState } from "react";
import { GenerateFreeQuizButton } from "./GenerateFreeQuizButton";
/* -----------------Child components--------------- */
import RedeemableProductListItem from "./RedeemableProductListItem";
import { studyPackAccessNote } from "./constants";

export type Purchase = {
	id: string;
	title: string;
	icon: string;
	description?: string;
	codes?: string[];
	bundleType?: ProductType;
	features?: string[];
	isCustomAccess: boolean;
	examId?: string;
	beginStartDate?: string;
	endStartDate?: string;
};
type Props = {
	purchases: Purchase[];
	email?: string;
	redeemStatus?: "redeeming" | "redeemed";
	handleAction?: () => void;
	selectedProducts?: string[];
	handleSelectProduct?: (id: string) => void;
	redeemedProductsExamName?: string;
	handleDismiss?: () => void;
	externalPurchase?: boolean;
};
export const AutoRedeemContentView = ({
	purchases,
	email,
	redeemStatus,
	handleAction,
	selectedProducts,
	handleSelectProduct,
	redeemedProductsExamName,
	handleDismiss,
	externalPurchase,
}: Props) => {
	const numPurchases = purchases.length;

	const [olxExamEligibility, setOlxExamEligibility] = useState<{
		endDate: string;
		startDate: string;
	} | null>(null);

	const { isMobile } = useDevice();

	const router = useRouter();

	const { exams } = useExams();

	const { handleSwitchExamContext } = useExamContext();

	const hasRedeemed = redeemStatus === "redeemed";
	const isRedeeming = redeemStatus === "redeeming";

	const isPracticeTestPurchased = purchases.some(
		(purchase) =>
			purchase.description?.includes("Test") ||
			purchase.description?.includes("Prep"),
	);

	const {
		app: {
			links: { ios, android },
		},
		exam: { quizEnabled },
	} = useAppStore((store) => ({
		app: store.app,
		exam: store.exam,
	}));

	const mainCta = isPracticeTestPurchased
		? "Start Diagnostic"
		: "Take free diagnostic";
	const getCta = () => {
		if (isRedeeming) {
			return "Redeeming...";
		}
		if (hasRedeemed) {
			return mainCta;
		}
		return "Redeem";
	};
	const cta = getCta();

	const redeemSuccesstitle = `Congratulations! The ${
		numPurchases > 1
			? `**${numPurchases} products** below have`
			: "**product** below has"
	} been unlocked on your account. After pressing ${
		externalPurchase ? "continue" : "go to home"
	}, the context of your app will be changed to reflect the exam:  **${redeemedProductsExamName}**. You can change this at any time from your account settings if you wish to study for a different exam or purchase a different subscription.`;

	const hasBlackListedStudyPack = purchases.some((item) => {
		return blackListedStudypackIdsForWeb.includes(item.id);
	});

	const productId = purchases[0].id;

	const showOlxExamEligibilityDialog = !!olxExamEligibility;

	const switchExamContext = async () => {
		const examDetails =
			exams.find((examDetails) =>
				examDetails.exam.bundles.find(
					(bundleDetails) => bundleDetails.bundle.id === productId,
				),
			) ||
			exams.find((examDetails) => examDetails.exam.id === purchases[0].examId);
		if (examDetails) {
			await handleSwitchExamContext(examDetails);
		}
	};

	const handleNavigation = () => {
		const bundleType = purchases[0].bundleType;
		const isPracticeTest = bundleType === "PRACTICE_TEST";
		if (isPracticeTest) {
			router.replace("/(protected)/(tabs)/practice-tests");
		} else {
			router.replace("/(protected)/study-pack");
		}
	};
	return (
		<Box gap={8} flexGrow={1}>
			{hasRedeemed ? (
				<Box paddingHorizontal={16}>
					<MarkdownRenderer text={redeemSuccesstitle} variant="stem" />
				</Box>
			) : (
				<Text
					variant="bodyMedium"
					style={{
						paddingHorizontal: 16,
					}}
				>
					<>
						{numPurchases > 1 ? "There are " : "There is "}
						<Text
							style={{ fontWeight: "bold" }}
						>{`${numPurchases} ${formatCount(
							"subscription",
							numPurchases,
						)}`}</Text>{" "}
						associated with the email address{" "}
						<Text style={{ fontWeight: "bold" }}>{`${email} `}</Text>
						{`that ${
							numPurchases > 1 ? "have" : "has"
						} not been redeemed. Would you like to add them to your account?`}
					</>
				</Text>
			)}

			<Box gap={16}>
				{purchases.map((purchase) => (
					<Box key={purchase.id} gap={8} paddingHorizontal={16} flexGrow={1}>
						<RedeemableProductListItem
							title={purchase.title}
							icon={purchase.icon}
							hasRedeemed={hasRedeemed}
							disabled={isRedeeming}
							description={purchase.description}
							selected={selectedProducts?.includes(purchase.id)}
							handleSelectProduct={
								handleSelectProduct
									? () => handleSelectProduct(purchase.id)
									: undefined
							}
						/>
						{!purchase.isCustomAccess &&
						purchase.features?.length &&
						hasRedeemed ? (
							<FeaturesSection features={purchase.features} />
						) : null}
					</Box>
				))}
			</Box>
			{!hasRedeemed && (
				<Button
					style={{
						marginHorizontal: 16,
					}}
					onPress={handleAction}
					loading={isRedeeming}
					disabled={isRedeeming || selectedProducts?.length === 0}
					mode="contained"
				>
					{cta}
				</Button>
			)}
			{hasRedeemed && isWeb && hasBlackListedStudyPack && (
				<Box gap={16} paddingHorizontal={16} alignItems="center">
					<Text
						style={{
							textAlign: "center",
						}}
						variant="labelMedium"
					>
						{studyPackAccessNote}
					</Text>
					<StoresDownloadBadge
						ios={{
							badge: images.appStores.ios,
							href: ios || "",
						}}
						android={{
							badge: images.appStores.android,
							href: android || "",
						}}
					/>
				</Box>
			)}
			{hasRedeemed &&
				(externalPurchase ? (
					<Button
						style={{
							marginHorizontal: 16,
						}}
						onPress={async () => {
							await switchExamContext();
							handleDismiss?.();
							if (isWeb && !isMobile && quizEnabled) {
								router.replace({
									pathname: "/home",
									params: {
										startDiagnostic: "true",
									},
								});
							} else {
								const beginStartDate = purchases[0].beginStartDate;
								const endStartDate = purchases[0].endStartDate;

								if (beginStartDate && endStartDate) {
									setOlxExamEligibility({
										startDate: beginStartDate,
										endDate: endStartDate,
									});
								} else {
									handleNavigation();
								}
							}
						}}
						mode="contained"
					>
						Continue
					</Button>
				) : (
					<GenerateFreeQuizButton
						handleDismiss={handleDismiss}
						cta={mainCta}
						productId={purchases[0].id}
						switchExamContext={switchExamContext}
					/>
				))}
			{showOlxExamEligibilityDialog && (
				<OLXExamEligibilityDialog
					visible={showOlxExamEligibilityDialog}
					handleClose={() => {
						setOlxExamEligibility?.(null);
						router.push("/practice-tests");
					}}
					startDateTime={olxExamEligibility?.startDate}
					endDateTime={olxExamEligibility?.endDate}
				/>
			)}
		</Box>
	);
};
