// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const mockedReportData: any = {
	id: "782f6393-5382-4401-bb2e-057c727f4622",
	answered: 3,
	accuracy: 33.33333333333333,
	timeElapsed: 6,
	totalTime: 0,
	avgSpeed: 2,
	sessionDate: "2024-03-06T07:33:22.195Z",
	reportSessionType: "STUDYPACK" as const,
	score: 5,
	numQuestions: 20,
	numCorrect: 1,
	numIncorrect: 2,
	numSkipped: 0,
	markedCount: 0,
	distribution: {
		low: 2,
		medium: 0,
		high: 1,
		none: 0,
	},
	numericId: 10,
	examName: "Image Challenge",
	tagDetails: [
		{
			id: "9d6bc9c8-77e6-49e7-9f74-d03c37f3a906",
			title: "Knee",
			type: "Topic",
			weight: 32,
			icon: null,
			displayType: ["Display", "Filter", "Progress"],
			numCorrect: 7,
			numIncorrect: 44,
			numSkipped: 0,
			items: [
				{
					distribution: "low",
					type: "question",
				},
				{
					distribution: "low",
					type: "question",
				},
				{
					distribution: "high",
					type: "question",
				},
			],
		},
	],
	sessionType: null,
};
