/* ----------------- React Native Paper --------------- */
import { Card } from "react-native-paper";

/* ----------------- Components --------------- */
import {
	MasteryListItem,
	type MasteryListItemProps,
} from "../../MasteryListItem";

type Props = {
	items: MasteryListItemProps[];
	tagType?: string;
};

export type { MasteryListItemProps };

const getToolTip = (item: MasteryListItemProps, tagType?: string) => {
	const { low, high, medium, none } = item.distribution;

	return `The ${tagType || "topic"} ${item.title} has ${
		item.rightText
	}. You've mastered ${high}, are proficient in ${medium}, and are familiar with ${low}${
		none ? `, and have not yet studied ${none}` : ""
	}.`;
};

const CardContent = ({ items, tagType }: Props) => {
	return (
		<Card.Content style={{ paddingLeft: 4, paddingRight: 0, marginRight: -8 }}>
			{items.map((item) => {
				const tooltip = getToolTip(item, tagType);
				return <MasteryListItem key={item.title} {...item} tooltip={tooltip} />;
			})}
		</Card.Content>
	);
};

export default CardContent;
