/* -----------------Globals--------------- */
import { forwardRef, useEffect, useState } from "react";
import { Keyboard, type TextInput } from "react-native";

/* -----------------UI--------------- */
import { ActivityIndicator, FAB } from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "../../useAppTheme";

import Box from "../Box";
/* -----------------Child components--------------- */
import { MessageInputBar } from "./MessageInputBar";

type ChatInputProps = {
	searchQuery: string;
	handleSubmitQuery: (query: string) => void;
	handleAbortSearch: () => void;
	disabled?: boolean;
	placeholder?: string;
	renderFilter?: () => React.ReactNode;
	handleReset?: () => void;
	icon?: string;
	showInlineButtons?: boolean;
	showResetButton?: boolean;
	shouldResetQuery?: boolean;
	showAdornments?: boolean;
};

export const ChatInput = forwardRef<TextInput, ChatInputProps>((props, ref) => {
	const {
		searchQuery,
		handleAbortSearch,
		handleSubmitQuery,
		placeholder = "Ask a question...",
		icon = "magnify",
		disabled,
		renderFilter,
		handleReset,
		showInlineButtons,
		showResetButton,
		shouldResetQuery = true,
		showAdornments,
	} = props;

	const theme = useAppTheme();
	const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery);
	const label = "";

	useEffect(() => {
		if (disabled && shouldResetQuery) {
			setLocalSearchQuery("");
		}
	}, [disabled, shouldResetQuery]);

	const handleOnChangeInput = (query: string) => {
		setLocalSearchQuery(query);
	};

	const handleClearInput = () => {
		setLocalSearchQuery("");
	};

	const handleOnAction = () => {
		if (disabled) {
			handleAbortSearch();
		} else {
			handleOnSubmit(localSearchQuery);
		}
	};

	const handleOnSubmit = (query: string) => {
		if (query.trim().length > 0) {
			Keyboard.dismiss();
			handleSubmitQuery(query.trim());
		}
	};

	return (
		<Box gap={8} flexDirection="row" width={"100%"} alignItems="center">
			{handleReset && showResetButton && (
				<FAB
					icon="plus"
					mode="flat"
					variant="surface"
					elevation={0}
					customSize={48}
					onPress={handleReset}
				/>
			)}
			<Box flex={1} justifyContent="center">
				<MessageInputBar
					placeholder={placeholder}
					label={label}
					disabled={disabled}
					localSearchQuery={localSearchQuery}
					handleClearInput={handleClearInput}
					handleOnChangeInput={handleOnChangeInput}
					handleSubmitQuery={handleOnSubmit}
					showSubmitButton={showInlineButtons}
					ref={ref}
					icon={icon}
					handleAbortSearch={handleAbortSearch}
					showAdornments={showAdornments}
					autoFocus={!searchQuery}
				/>
				{disabled && showAdornments && (
					<ActivityIndicator
						size="small"
						color={theme.colors.primary}
						style={{
							position: "absolute",
							right: 16,
						}}
					/>
				)}
			</Box>
			{!showInlineButtons && showAdornments && (
				<FAB
					icon={disabled ? "stop" : "send-outline"}
					mode="flat"
					customSize={48}
					onPress={handleOnAction}
					disabled={disabled ? false : localSearchQuery.trim().length === 0}
					style={{
						borderRadius: 24,
					}}
				/>
			)}

			{renderFilter?.()}
		</Box>
	);
});
