import type { Article } from "../../../../../types";

export const Article514: Article = {
	id: 514,
	rsuiteId: "4c43e834-5dad-4356-a76f-3838bb0b5ad2",
	type: "scientific article",
	title:
		"Mortality, Analgesic Use, and Care Requirements After Vertebral Compression Fractures",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4c43e834-5dad-4356-a76f-3838bb0b5ad2&type=jpeg&name=JBJS.23.01438f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Mortality, Analgesic Use, and Care Requirements After
                Vertebral Compression Fractures</div>
              <div class="text subtitle" style="position: unset;">A Retrospective Cohort Study of 18,392 Older Adult
                Patients</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Akira Honda,
                      MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Hayato
                      Yamana, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 10
                      more contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_25" class="annotation strong"
                          style="position: unset;">Background:</span> Vertebral compression fractures (VCFs) in older
                        adults cause considerable health and socioeconomic burdens due to worsening ability to perform
                        activities of daily living. The long-term effects of VCFs on patient outcomes, particularly
                        prolonged analgesic use and functional decline, remain unknown. The aims of this study were to
                        examine long-term clinical outcomes and to determine the risk factors for persistent pain and
                        functional disability after VCFs.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_26" class="annotation strong"
                          style="position: unset;">Methods:</span> This retrospective cohort study evaluated mortality,
                        duration of analgesic use, and changes in care requirements in older adults with VCFs using
                        claims data from a suburban prefecture in the Greater Tokyo Area. Patients were included if they
                        were ≥65 years of age and had been diagnosed with a VCF between June 2014 and February 2019, as
                        determined on the basis of International Classification of Diseases, Tenth Revision (ICD-10)
                        codes; we also used claims data that could determine whether the patients underwent imaging
                        examinations. Patients who discontinued outpatient visits within 1 month after the VCF diagnosis
                        were excluded.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_27" class="annotation strong"
                          style="position: unset;">Results:</span> We included 18,392 patients with VCFs and a mean age
                        of 80 years. Seventy-six percent of patients were women, and the median follow-up period was 670
                        days. At the index VCF diagnosis, 3,631 patients (19.7%) were care-dependent. Overall, 968
                        patients (5.3%) died within 1 year. Among the 8,375 patients who received analgesics, 22%
                        required analgesics for &gt;4 months. Factors associated with prolonged analgesic use for &gt;1
                        year were female sex (odds ratio [OR], 1.39 [95% confidence interval (CI), 1.16 to 1.65]) and
                        VCFs in the thoracolumbar region (OR, 1.95 [95% CI, 1.50 to 2.55]) or lumbar region (OR, 1.59
                        [95% CI, 1.23 to 2.04]) (the reference was the thoracic region). The care needs of 1,510
                        patients (8.2%) increased within 1 year. Patients with a preexisting care dependency had a 10
                        times higher risk of increased care need (30.2% [1,060 of 3,509]) than those who had been
                        independent at the time of the index diagnosis (3.0% [450 of 14,761]) (p &lt; 0.001).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_28" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Individuals with preexisting care dependency were
                        more likely to experience functional decline following VCFs than those who were independent,
                        which underscores the need for intensive and appropriate allocation of health-care resources to
                        care-dependent patients.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Vertebral compression fractures (VCFs) are the most common
                  type of fragility fracture and affect 1 in 5 persons ≥50 years of age<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">2</span></a>. As
                  the population ages, the incidence of VCF continues to increase globally, with an estimated annual
                  incidence of 4,000 to 6,000 cases per 100,000 individuals in Japan<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">7</span></a> and
                  2,000 to 2,500 cases per 100,000 individuals in the United States and Europe<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">8</span></a>.
                  Although VCFs are often insidious in nature in that they develop gradually without any obvious
                  symptoms, patients may seek medical care because of persistent back pain, progressive kyphosis, and
                  paralysis due to spinal cord compromise.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">The clinical outcomes of VCFs are satisfactory in most
                  patients. A small secondary analysis conducted as part of a randomized controlled trial revealed that
                  a majority of patients with acute VCFs show sufficient pain relief during the first 3 months with
                  conservative treatment<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">9</span></a>.
                  However, the study also showed that disabling pain persists in a substantial proportion of patients at
                  1 year after the injury, indicating the variable severity of symptoms in patients with VCFs in the
                  chronic phase<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">9</span></a>.
                  Although VCFs can have a substantial impact on the patients’ quality of life and care dependency, the
                  long-term impact of VCFs in a real-world setting has been investigated in few studies<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">11</span></a>. To
                  our knowledge, no predictors of persistent disability after VCF have been determined.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">In this study, we examined the long-term clinical and
                  functional outcomes in older adults with VCFs using large administrative data sets. We aimed to
                  determine the risk factors for persistent pain and functional disability after VCFs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study Design
                and Participants</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">In this retrospective cohort study, we used claims data
                  from Tochigi, a suburban prefecture with a population of 2 million people, in the Greater Tokyo Area
                  of Japan. Eighteen of the 25 municipalities in the Tochigi Prefecture participated in this study. We
                  merged data from the following 3 insurance systems: the National Health Insurance (for self-employed
                  individuals, retired individuals, and their dependents), the Advanced Elderly Medical Service System
                  (for all people ≥75 years of age), and the Long-term Care Insurance system<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">15</span></a>.
                  Consequently, our combined data set covers virtually the entire cohort of residents ≥65 years of age
                  in the 18 municipalities. In the Long-term Care Insurance system, eligibility for care services and
                  care-need levels are assessed on the basis of a standardized care-need certification system<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">17</span></a>.
                  Care-requiring individuals are classified into 7 levels according to an estimate of the total daily
                  number of minutes of support (support level 1, 25 to 31 minutes; support level 2, 32 to 49 minutes)
                  and care (care-need level 1, 32 to 49 minutes; care-need level 2, 50 to 69 minutes; care-need level 3,
                  70 to 89 minutes; care-need level 4, 90 to 109 minutes; care-need level 5, ≥110 minutes) they need<a
                    href="" data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">17</span></a>. The
                  database contains encrypted unique identifiers, sex, birth months, diagnoses coded according to the
                  International Classification of Diseases, Tenth Revision (ICD-10), institution types, care-need
                  levels, long-term care services (facility service, home-based service, and community-based service),
                  surgical and nonsurgical treatments coded according to the original Japanese codes, medications,
                  costs, and dates of death.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">This study was approved by the institutional review board
                  of Jichi Medical University (22-202). The requirement for informed consent was waived because of the
                  anonymous nature of the data.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Collection and Outcome Measures</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Inclusion
                and Exclusion Criteria</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">We identified all patients diagnosed with thoracic,
                  thoracolumbar, or lumbar VCFs (ICD-10 codes S22.00, S22.10, S32.00, S32.70, T02.10, T08-0, and T14.20)
                  between June 2014 and February 2019 (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). We then
                  excluded patients who had missing data on the date of insurance enrollment; had no data on the
                  treating institution; were &lt;65 years of age; were diagnosed with cervical fractures, old fractures,
                  or Chance fractures; or did not undergo imaging studies (radiography, computed tomography [CT], or
                  magnetic resonance imaging [MRI]) using claims data. To further enhance the validity of the VCF
                  diagnosis, we excluded patients who discontinued outpatient visits within 1 month after diagnosis. We
                  also excluded patients who had &lt;3 months of information in the database prior to the index VCF
                  diagnosis. Pathologic fractures were excluded on the basis of ICD-10 codes for spinal malignancies
                  (C412) and spinal metastases and pathologic fractures (C793-C795).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01438f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4c43e834-5dad-4356-a76f-3838bb0b5ad2&type=jpeg&name=JBJS.23.01438f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;">Flowchart showing patient selection for the study.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Covariates</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The covariates included were age; sex; VCF region;
                  care-need level; type of treating institution (clinics, hospitals with &lt;200 beds, and hospitals
                  with ≥200 beds); emergency admission status; ambulance usage; preexisting comorbidities, including
                  hypertension (I10-I15), diabetes mellitus (E10-E14), and chronic lung disease (J40-J47); history of
                  cardiac disease (I20-I25 and I30-I52), cerebrovascular disease (I60-I69), and rheumatoid arthritis
                  (M05 and M06); treatment for malignancy (C00-C34, C37-C38, C40-C41, C43, C45-C58, C60-C76, C77-C80,
                  C81-C85, C88, C90-C97) within 3 months prior to the VCF diagnosis; history of fracture within the
                  previous 1 year, including femoral neck fracture (S72.0-S72.2), other femoral fracture (S72.3-S72.9),
                  lower-extremity fracture (S82 and S92), and upper-extremity fracture (S42, S52, and S62); admission
                  history within the previous 3 months; and medication history involving analgesics (acetaminophen,
                  nonsteroidal anti-inflammatory drugs [NSAIDs], and opioids), osteoporosis medications (bisphosphonate,
                  teriparatide, anti-RANKL [receptor activator of nuclear factor-kappa B ligand] antibodies, active
                  vitamin D<span data-id="subscript_1" class="annotation subscript" style="position: unset;">3</span>
                  analogs, and selective estrogen receptor modulators), and steroids within the previous 3 months. We
                  also examined the use of back bracing, hospitalization within 3 months after the index diagnosis, and
                  surgical treatment within 3 months after the diagnosis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The outcomes of this study were all-cause mortality,
                  duration of analgesic use, and increase in care needs after the index diagnosis of VCF. The duration
                  of analgesic use was defined as the period from the index date of diagnosis to the date of the last
                  analgesic prescription. Data on opioid use were extracted separately.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">First, we described the demographic characteristics of
                  patients with VCFs according to their primary treating institution (clinics, small hospitals, and
                  large hospitals). Categorical variables were described using counts and proportions, and continuous
                  variables were described using means and standard deviations or medians and interquartile ranges
                  (IQRs).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">We then constructed Kaplan-Meier survival curves for
                  mortality stratified by age and preexisting care-need levels. Patients were followed from the index
                  VCF diagnosis to the occurrence of death, withdrawal from insurance, or February 2019, whichever came
                  first. We performed a multivariable Cox regression analysis to identify the risk factors for overall
                  mortality.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Next, we constructed a Kaplan-Meier curve for continuous
                  analgesic prescriptions following the VCF diagnosis. In order to focus on the pain caused by VCFs,
                  this analysis excluded patients who had been prescribed analgesics before the index VCF diagnosis and
                  those who were not prescribed analgesics after the diagnosis (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). Death,
                  withdrawal from insurance, and the date of February 2019 were censoring events. We also performed a
                  multivariable logistic regression analysis to identify the risk factors for prolonged analgesic for
                  &gt;1 year.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Lastly, we constructed Kaplan-Meier curves for the
                  increase in care dependency within 1 year, stratified according to the patients’ preexisting care
                  dependency status, which was categorized as “independent” (not requiring assistance) or “dependent”
                  (encompassing support levels 1 to 2 and care-need levels 1 to 4). This analysis excluded patients who
                  had been at care-need level 5 before the index diagnosis, as their care-need level could not increase
                  further (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). In this
                  analysis, death was considered a worsening event. We also performed a multivariable logistic
                  regression analysis to identify risk factors for an increase in care dependency within 1 year.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Each multivariable model controlled for potential risk
                  factors, including age, sex, VCF region, preexisting care dependency, type of treating institution,
                  modality of treatment, use of back bracing, use of anti-RANKL antibodies, emergency admission,
                  ambulance use, comorbid conditions, concomitant malignancy, and history of fractures. A 2-sided p
                  value of 0.05 was used as the threshold for significance. All analyses were performed using Stata/MP
                  (version 15; StataCorp).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">A total of 18,392 patients with VCFs were included, with a
                  mean age (and standard deviation) of 80 ± 7.1 years. Seventy-six percent of patients were women, and
                  the median follow-up period was 670 days (IQR, 307 to 1,068 days) (<a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). Prior to the index VCF diagnosis, 1,348 patients (7.3%) had
                  been treated for osteoporosis. Overall, 3,631 patients (19.7%) had been dependent at the time of the
                  index VCF diagnosis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Overall, 968 patients (5.3%) died within 1 year. Survival
                  curves stratified by age and care-need levels revealed that mortality increased monotonically with age
                  and care-need levels (see Appendix eFigure 1a, eFigure 1b, and eTable1).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Among the 8,375 patients who were prescribed analgesics,
                  22% continued to require analgesics for &gt;4 months and 8.2% continued to require analgesics for ≥1
                  year (<a href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). The use of opioids decreased gradually in a similar fashion
                  (see Appendix eFigure 2). The results of the multivariable logistic regression analysis for analgesic
                  use for &gt;1 year are presented in <a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>. Among
                  the factors associated with prolonged analgesic use were female sex (odds ratio [OR], 1.39 [95%
                  confidence interval (CI), 1.16 to 1.65]), fracture in the thoracolumbar region (OR, 1.95 [95% CI, 1.5
                  to 2.6]) or the lumbar region (OR, 1.59 [95% CI, 1.23 to 2.04]) (with the thoracic region as the
                  reference), emergency admission (OR, 1.56 [95% CI, 1.22 to 2.00]), and history of cardiac disease (OR,
                  1.28 [95% CI, 1.09 to 1.52]).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01438f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4c43e834-5dad-4356-a76f-3838bb0b5ad2&type=jpeg&name=JBJS.23.01438f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Kaplan-Meier curve for the duration of analgesic
                          use after VCFs.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">A total of 1,510 patients (8.2%) experienced an increase
                  in care-need level within 1 year of the index diagnosis. Approximately 30% of the patients who had
                  been care-dependent at the time of the VCF diagnosis experienced further functional decline; these
                  patients were at 10 times higher risk (30.2% [1,060 of 3,509]) compared with those who had been
                  independent at the time of the VCF diagnosis (3.0% [450 of 14,761]) (p &lt; 0.001) (<a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>). A multivariable analysis showed that preexisting care
                  dependency (OR, 9.66 [95% CI, 8.53 to 10.93]) and older age were risk factors for increased care-need
                  levels within 1 year of the VCF (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01438f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4c43e834-5dad-4356-a76f-3838bb0b5ad2&type=jpeg&name=JBJS.23.01438f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;">Kaplan-Meier curve for worsening of care-need
                          levels after VCFs. Data are stratified on the basis of preexisting care-need levels.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">In this retrospective cohort study of 18,392 older adult
                  patients with VCFs, we evaluated the risk factors for clinical outcomes, including long-term
                  mortality, duration of analgesic use, and increase in care needs after the VCF diagnosis. Our study
                  revealed that 5.3% of the patients died within 1 year, 22% required analgesic prescriptions for &gt;4
                  months, and 8.2% experienced an increase in care dependency. Notably, patients who had been dependent
                  at the time of diagnosis had a tenfold higher risk of functional decline after the VCF than those who
                  had been independent. Although recent efforts to examine the prognosis of VCFs using large real-world
                  data sets have primarily focused on mortality as an outcome<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">11</span></a>, our study investigated the change in care dependency and
                  prolonged use of analgesics to clarify patients’ functional impairment following a VCF. This study
                  also demonstrated the wide variation in the treatment modalities used and treatment flow among
                  patients with a VCF in real-world practice.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Despite the high incidence and importance of VCFs, a
                  comprehensive clinical picture of VCFs has been relatively elusive, as patients with VCFs present with
                  varying degrees of severity and heterogeneous prognoses. The treatment of VCFs also varies
                  considerably; some cases are treated solely on an outpatient basis, whereas others require
                  hospitalization and surgical interventions. The major strengths of the present study are the nearly
                  complete coverage of the patients in the prefecture, large sample size, and long follow-up period,
                  which collectively ensured the robustness and generalizability of our findings. In this study, we
                  merged information from multiple administrative databases and provided a comprehensive longitudinal
                  picture of real-world treatments, which was not possible on the basis of the findings of previous
                  studies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">The current understanding of pain associated with VCFs is
                  limited. A previous report indicated that pain persists in up to 40% of the patients with VCFs 1 year
                  after the injury<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">9</span></a>.
                  Another study showed that the findings of MRI scans that had been conducted at the time of the injury
                  can predict delayed union, which is closely associated with persistent back pain 6 months after the
                  injury<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">18</span></a>.
                  Although most patients with VCFs show an improvement in clinical symptoms after several months, the
                  subgroup of patients who experience debilitating pain that requires long-term care is not known. Our
                  analysis revealed a higher risk of prolonged analgesic use in female patients and those with fractures
                  in the thoracolumbar or lumbar regions. Few studies have investigated sex-based differences in
                  persistent chronic pain associated with VCFs. A Norwegian study showed that the severity of VCFs was
                  associated with an increased risk of back pain and a lower health-related quality of life in women but
                  not in men<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">19</span></a>.
                  With regard to anatomical factors, the thoracolumbar and lumbar regions of the spine are particularly
                  mobile and, thus, receive the greatest dynamic load. This can explain why VCFs in the thoracolumbar
                  and lumbar regions were more likely to cause chronic disabling pain. For these potentially high-risk
                  subgroups of patients, comprehensive treatments, including back bracing and, if necessary, vertebral
                  augmentation, may be required along with the adequate use of analgesics.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">A pivotal finding of our study was the substantial impact
                  of preexisting care dependency on outcomes following VCFs. Few studies have examined the factors
                  influencing functional decline in patients with VCFs. A 2018 systematic review of patients who were
                  hospitalized for VCFs showed that up to 50% of these patients were discharged from the hospital to a
                  care facility, indicating a high risk of functional decline after VCFs<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">20</span></a>. In the present study, patients who had already been
                  care-dependent were at a 10-fold higher risk for experiencing an increase in care-need levels compared
                  with those who had been independent. This alarmingly high risk of functional decline underscores the
                  need for the intensive and appropriate allocation of health-care resources to these patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">This study had several limitations. First, as is the case
                  with other administrative database studies, detailed information on the patients’ symptoms or
                  radiographic findings was not available. Inevitably, some uncertainty remains in both the accurate
                  diagnosis of VCFs and the actual timing of their occurrence in this study. To enhance the validity of
                  the diagnosis, we only included patients who had undergone imaging procedures, and we excluded those
                  who had terminated their outpatient visits within 1 month. Furthermore, a previous study using the
                  administrative claims databases of a large regional U.S. health-care organization showed that
                  administrative claims data, comprising information on VCF diagnostic codes and follow-up spinal
                  imaging test results, were associated with high positive predictive values for the presence of a VCF<a
                    href="" data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">21</span></a>. However, some non-acute VCF cases might have been included
                  in our analyses because we could not determine exactly when the fracture occurred. Therefore, we
                  believe that future prospective studies combined with MRI evaluation are necessary to address this
                  issue. Second, we were unable to obtain serial data on imaging studies or bone mineral densities.
                  Therefore, we could not investigate the risk of secondary VCFs based on osteoporosis severity. Third,
                  specific data on the patients’ functional status, such as walking ability, self-care, and cognitive
                  function, were not available. Instead, we focused on changes in care-need levels to assess the impact
                  of VCFs on functional decline. Previous studies have shown a close association between care-need
                  levels and Barthel Index scores or cognitive decline<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">24</span></a>. Despite these limitations, we believe that the findings of
                  this study offer valuable information on the long-term clinical outcomes of patients with VCFs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, our study revealed the substantial impact
                  of VCFs among older adults and identified the subgroups of patients who were at a high risk for
                  prolonged analgesic use and functional decline after VCFs. Our findings underscore the importance of
                  optimizing treatment strategies and appropriately allocating health-care resources for high-risk
                  individuals.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_3" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I74" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I74</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Baseline Characteristics of Patients Diagnosed with
                          VCFs</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 3724px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Characteristics</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total (N =
                          18,392)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Clinic (N
                          = 8,496)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Hospital
                          with &lt;200 Beds (N = 4,711)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Hospital
                          with ≥200 Beds (N = 5,185)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Observation period<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_25" class="annotation emphasis"
                            style="position: unset;">(days)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">670 (307
                          to 1,068)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">672 (336
                          to 1,097)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">642 (307
                          to 1,068)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">671 (307
                          to 1,068)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_26" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80 ±
                          7.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80 ±
                          7.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81 ±
                          7.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80 ±
                          7.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age category<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 65 to 74 years</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,928
                          (21%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,866
                          (22%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">816
                          (17%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,246
                          (24%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 75 to 84 years</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9,083
                          (49%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,157
                          (49%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,307
                          (49%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,619
                          (51%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 85 to 94 years</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,112
                          (28%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,354
                          (28%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,506
                          (32%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,252
                          (24%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥95 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">269
                          (1.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">119
                          (1.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82
                          (1.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68
                          (1.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Women<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13,916
                          (76%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,679
                          (79%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,520
                          (75%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,717
                          (72%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Imaging study<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Radiographic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18,198
                          (99%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8,432
                          (99%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,651
                          (99%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,115
                          (99%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> CT</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,402
                          (7.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">329
                          (3.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">374
                          (7.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">699
                          (14%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> MRI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7,909
                          (43%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,216
                          (26%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,681
                          (57%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,012
                          (58%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">VCF region<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Thoracic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,650
                          (14%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,318
                          (16%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">591
                          (13%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">741
                          (14%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Thoracolumbar</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,036
                          (22%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,449
                          (17%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">981
                          (21%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,606
                          (31%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lumbar</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8,630
                          (47%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,944
                          (46%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,223
                          (47%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,463
                          (48%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unknown</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,076
                          (17%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,785
                          (21%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">916
                          (19%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">375
                          (7.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ambulance use<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">376
                          (2.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60
                          (0.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">123
                          (2.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">193
                          (3.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Emergency admission<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,562
                          (8.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">258
                          (3.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">658
                          (14%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">646
                          (13%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Treatment<span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Back bracing</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,909
                          (32%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,773
                          (33%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,627
                          (35%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,509
                          (29%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Anti-RANKL
                          antibodies</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">878
                          (4.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">308
                          (3.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">273
                          (5.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">297
                          (5.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Admission within 3
                          months</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,635
                          (31%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,403
                          (17%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,282
                          (48%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,950
                          (38%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">224
                          (1.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83
                          (1.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">110
                          (2.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medication history within
                          3 months<span data-id="table_footnote_reference_10"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Analgesics</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">728
                          (4.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">243
                          (2.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">204
                          (4.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">281
                          (5.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Osteoporotic
                          medication</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,348
                          (7.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">498
                          (5.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">374
                          (7.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">476
                          (9.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Steroid</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">135
                          (0.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (0.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (0.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68
                          (1.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Admission history within
                          3 months<span data-id="table_footnote_reference_11"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,547
                          (8.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">408
                          (4.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">496
                          (11%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">643
                          (12%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Fracture history within 1
                          year<span data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Femoral neck
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">685
                          (3.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">227
                          (2.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">219
                          (4.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">239
                          (4.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other femoral
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">123
                          (0.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (0.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (0.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lower-extremity
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">592
                          (3.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">229
                          (2.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">164
                          (3.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">199
                          (3.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Upper-extremity
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">913
                          (5.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">383
                          (4.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">257
                          (5.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">273
                          (5.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Comorbidities<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diabetes mellitus</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,109
                          (11%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">874
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">596
                          (13%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">639
                          (12%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hypertension</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,998
                          (16%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,289
                          (15%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">828
                          (18%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">881
                          (17%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Ischemic heart
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,615
                          (8.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">665
                          (7.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">453
                          (9.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">497
                          (9.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other cardiac
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,436
                          (19%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,502
                          (18%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">975
                          (21%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">959
                          (19%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cerebrovascular
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,562
                          (14%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,104
                          (13%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">775
                          (17%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">683
                          (13%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Chronic lung
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,551
                          (14%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,107
                          (13%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">660
                          (14%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">784
                          (15%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Rheumatoid
                          arthritis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">487
                          (2.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">212
                          (2.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">131
                          (2.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">144
                          (2.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Concomitant
                          malignancy<span data-id="table_footnote_reference_14"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,684
                          (9.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">684
                          (8.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">426
                          (9.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">574
                          (11%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dependency at
                          diagnosis<span data-id="table_footnote_reference_15"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Independent</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14,761
                          (80%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,901
                          (81%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,698
                          (79%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,162
                          (80%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Support level 1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">351
                          (1.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">173
                          (2.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84
                          (1.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94
                          (1.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Support level 2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">527
                          (2.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">238
                          (2.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">142
                          (3.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">147
                          (2.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Care-needs level
                          1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">853
                          (4.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">396
                          (4.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">244
                          (5.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">213
                          (4.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Care-needs level
                          2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">863
                          (4.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">350
                          (4.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">245
                          (5.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">268
                          (5.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Care-needs level
                          3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">559
                          (3.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">256
                          (3.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">153
                          (3.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">150
                          (2.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Care-needs level
                          4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">356
                          (1.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">141
                          (1.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">106
                          (2.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">109
                          (2.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Care-needs level
                          5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">122
                          (0.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41
                          (0.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39
                          (0.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (0.8%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the median, with the IQR in parentheses.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, with the percentage in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Multivariable Logistic Regression Analysis of
                          Analgesic Use for &gt;1 Year</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 2435px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Variable</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">OR<span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age category</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 65 to 74 years</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 75 to 84 years</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.21 (1.01
                          to 1.47)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.043</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 85 to 94 years</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.20 (0.97
                          to 1.49)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.091</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥95 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.63 (0.29
                          to 1.37)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.24</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Men</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Women</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.39 (1.16
                          to 1.65)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">VCF region</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Thoracic</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Thoracolumbar</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.95 (1.50
                          to 2.55)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lumbar</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.59 (1.23
                          to 2.04)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preexisting care
                          dependency</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Independent</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dependent</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.94 (0.78
                          to 1.13)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.49</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Primary treating
                          institution</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Clinic</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Small hospital, &lt;200
                          beds</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.75 (0.62
                          to 0.91)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.003</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Large hospital, ≥200
                          beds</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.08 (0.91
                          to 1.28)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.36</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Treatment type</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Outpatient without
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hospitalization without
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.74 (0.62
                          to 0.89)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.22 (0.72
                          to 2.07)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.46</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Back bracing</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.18 (1.02
                          to 1.36)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.031</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Anti-RANKL
                          antibodies</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.45 (0.28
                          to 0.74)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Emergency
                          admission</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.56 (1.22
                          to 2.00)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ambulance use</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.67 (0.38
                          to 1.19)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.17</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Comorbidities</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hypertension</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.96 (0.79
                          to 1.17)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.68</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diabetes mellitus</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.00 (0.81
                          to 1.25)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.97</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cardiac disease</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.28 (1.09
                          to 1.52)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.003</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cerebrovascular
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.18 (0.97
                          to 1.43)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.10</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Chronic lung
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.03 (0.84
                          to 1.25)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.80</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Rheumatoid
                          arthritis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.96 (0.64
                          to 1.46)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.85</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Concomitant
                          malignancy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.13 (0.89
                          to 1.42)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.32</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">History of
                          fracture</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> None</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Femoral neck
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.74 (0.46
                          to 1.19)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.21</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other femoral
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.66 (0.76
                          to 3.61)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.21</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lower-extremity
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.09 (0.71
                          to 1.67)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.70</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Upper-extremity
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.17 (0.86
                          to 1.59)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.31</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the OR, with the 95% CI in parentheses.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Multivariable Logistic Regression Analysis of
                          Increase in Care Dependency within 1 Year</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1751px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Variable</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">OR<span
                            data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age category</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 65 to 74 years</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 75 to 84 years</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.09 (1.64
                          to 2.65)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 85 to 94 years</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.43 (2.69
                          to 4.36)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥95 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.39 (2.30
                          to 5.01)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Men</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Women</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.02 (0.89
                          to 1.17)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.82</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">VCF region</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Thoracic</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Thoracolumbar</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.23 (0.99
                          to 1.51)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.054</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lumbar</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.15 (0.95
                          to 1.39)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.16</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dependency</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Independent</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dependent</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.66 (8.53
                          to 10.93)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Primary treating
                          institution</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Clinic</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Small hospital, &lt;200
                          beds</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.04 (0.90
                          to 1.21)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.59</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Large hospital, ≥200
                          beds</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.18 (1.02
                          to 1.37)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.028</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Treatment type</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Outpatient without
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hospitalization without
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.09 (1.83
                          to 2.38)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.59 (1.63
                          to 4.11)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Back bracing</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.21 (1.07
                          to 1.37)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.002</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Anti-RANKL
                          antibodies</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.55 (0.36
                          to 0.83)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.005</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Emergency
                          admission</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.25 (1.05
                          to 1.48)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.013</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ambulance use</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.33 (0.97
                          to 1.82)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.076</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Comorbidities</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hypertension</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.97 (0.82
                          to 1.14)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.71</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diabetes mellitus</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.27 (1.07
                          to 1.51)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.007</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cardiac disease</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.29 (1.13
                          to 1.48)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cerebrovascular
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.34 (1.15
                          to 1.55)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Chronic lung
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.07 (0.91
                          to 1.26)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.39</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Rheumatoid
                          arthritis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.36 (0.99
                          to 1.86)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.058</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Concomitant
                          malignancy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.08 (0.89
                          to 1.31)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.42</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">History of
                          fracture</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> None</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Femoral neck
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.07 (0.83
                          to 1.38)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.61</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other femoral
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.74 (0.35
                          to 1.56)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.43</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lower-extremity
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.91 (0.62
                          to 1.34)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.65</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Upper-extremity
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.92 (0.70
                          to 1.21)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.57</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the OR, with the 95% CI in parentheses.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Riggs BL</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Melton LJ 3rd</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">The worldwide problem of
                  osteoporosis: insights afforded by epidemiology</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Bone.</span> 1995
                Nov;17(5)(Suppl):505S-11S.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20worldwide%20problem%20of%20osteoporosis%3A%20insights%20afforded%20by%20epidemiology&as_occt=title&as_sauthors=%20%22BL%20Riggs%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Kendler DL</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Bauer DC</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Davison KS</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Dian L</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Hanley DA</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Harris ST</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> McClung MR</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Miller PD</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Schousboe JT</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Yuen CK</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Lewiecki EM</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Vertebral fractures: clinical
                  importance and management</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Am J Med.</span> 2016 Feb;129(2):221.e1-10.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vertebral%20fractures%3A%20clinical%20importance%20and%20management&as_occt=title&as_sauthors=%20%22DL%20Kendler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Ross PD</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Fujiwara S</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Huang C</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Davis JW</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Epstein RS</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Wasnik RD</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Kodama K</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Melton LJ 3rd</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Vertebral fracture prevalence in
                  women in Hiroshima compared to Caucasians or Japanese in the US</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Int J Epidemiol.</span> 1995
                Dec;24(6):1171-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vertebral%20fracture%20prevalence%20in%20women%20in%20Hiroshima%20compared%20to%20Caucasians%20or%20Japanese%20in%20the%20US&as_occt=title&as_sauthors=%20%22PD%20Ross%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Horii C</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Iidaka T</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Muraki S</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Oka H</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Asai Y</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Tsutsui S</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Hashizume H</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Yamada H</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Yoshida M</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Kawaguchi H</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Nakamura K</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Akune T</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Oshima Y</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Tanaka S</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Yoshimura N</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">The cumulative incidence of and
                  risk factors for morphometric severe vertebral fractures in Japanese men and women: the ROAD study
                  third and fourth surveys</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Osteoporos Int.</span> 2022 Apr;33(4):889-99.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20cumulative%20incidence%20of%20and%20risk%20factors%20for%20morphometric%20severe%20vertebral%20fractures%20in%20Japanese%20men%20and%20women%3A%20the%20ROAD%20study%20third%20and%20fourth%20surveys&as_occt=title&as_sauthors=%20%22C%20Horii%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Fujiwara S</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Hamaya E</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Goto W</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Masunari N</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Furukawa K</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Fukunaga M</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Nakamura T</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Miyauchi A</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Chen P</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Vertebral fracture status and
                  the World Health Organization risk factors for predicting osteoporotic fracture risk in Japan</span>.
                <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Bone.</span> 2011
                Sep;49(3):520-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vertebral%20fracture%20status%20and%20the%20World%20Health%20Organization%20risk%20factors%20for%20predicting%20osteoporotic%20fracture%20risk%20in%20Japan&as_occt=title&as_sauthors=%20%22S%20Fujiwara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Kadowaki E</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Tamaki J</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Iki M</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Sato Y</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Chiba Y</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Kajita E</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Kagamimori S</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Kagawa Y</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Yoneshima H</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Prevalent vertebral deformity
                  independently increases incident vertebral fracture risk in middle-aged and elderly Japanese women:
                  the Japanese Population-based Osteoporosis (JPOS) Cohort Study</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">Osteoporos Int.</span> 2010
                Sep;21(9):1513-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalent%20vertebral%20deformity%20independently%20increases%20incident%20vertebral%20fracture%20risk%20in%20middle-aged%20and%20elderly%20Japanese%20women%3A%20the%20Japanese%20Population-based%20Osteoporosis%20(JPOS)%20Cohort%20Study&as_occt=title&as_sauthors=%20%22E%20Kadowaki%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Tsuda T</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Epidemiology of fragility
                  fractures and fall prevention in the elderly: a systematic review of the literature</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Curr Orthop Pract.</span>
                2017 Nov;28(6):580-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Epidemiology%20of%20fragility%20fractures%20and%20fall%20prevention%20in%20the%20elderly%3A%20a%20systematic%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22T%20Tsuda%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Hernlund E</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Svedbom A</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Ivergård M</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Compston J</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Cooper C</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Stenmark J</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> McCloskey EV</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Jönsson B</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Kanis JA</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Osteoporosis in the European
                  Union: medical management, epidemiology and economic burden</span>. A report prepared in collaboration
                with the International Osteoporosis Foundation (IOF) and the European Federation of Pharmaceutical
                Industry Associations (EFPIA). <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">Arch Osteoporos.</span> 2013;8(1):136.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteoporosis%20in%20the%20European%20Union%3A%20medical%20management%2C%20epidemiology%20and%20economic%20burden&as_occt=title&as_sauthors=%20%22E%20Hernlund%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Venmans A</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Klazen CA</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Lohle PNM</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Mali WP</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> van Rooij WJ</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Natural history of pain in
                  patients with conservatively treated osteoporotic vertebral compression fractures: results from VERTOS
                  II</span>. <span data-id="emphasis_9" class="annotation emphasis" style="position: unset;">AJNR Am J
                  Neuroradiol.</span> 2012 Mar;33(3):519-21.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Natural%20history%20of%20pain%20in%20patients%20with%20conservatively%20treated%20osteoporotic%20vertebral%20compression%20fractures%3A%20results%20from%20VERTOS%20II&as_occt=title&as_sauthors=%20%22A%20Venmans%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Kim JE</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Koh SY</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Swan H</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Kazmi SZ</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Kim HJ</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Ahn HS</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Hong SS</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Incidence and mortality of
                  vertebral compression fracture among all age groups: a nationwide, population-based study in the
                  Republic of Korea</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Pain Physician.</span> 2023 May;26(3):E203-11.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%20and%20mortality%20of%20vertebral%20compression%20fracture%20among%20all%20age%20groups%3A%20a%20nationwide%2C%20population-based%20study%20in%20the%20Republic%20of%20Korea&as_occt=title&as_sauthors=%20%22JE%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Gold LS</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Suri P</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> O’Reilly MK</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Kallmes DF</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Heagerty PJ</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Jarvik JG</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Mortality among older adults
                  with osteoporotic vertebral fracture</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">Osteoporos Int.</span> 2023 Sep;34(9):1561-75.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mortality%20among%20older%20adults%20with%20osteoporotic%20vertebral%20fracture&as_occt=title&as_sauthors=%20%22LS%20Gold%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Ikegami N</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Yoo BK</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Hashimoto H</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Matsumoto M</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Ogata H</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Babazono A</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Watanabe R</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Shibuya K</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Yang BM</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Reich MR</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Kobayashi Y</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Japanese universal health
                  coverage: evolution, achievements, and challenges</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">Lancet.</span> 2011 Sep
                17;378(9796):1106-15.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Japanese%20universal%20health%20coverage%3A%20evolution%2C%20achievements%2C%20and%20challenges&as_occt=title&as_sauthors=%20%22N%20Ikegami%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Yasunaga H</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Real World Data in Japan:
                  Chapter I NDB</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Ann Clin Epidemiol.</span> 2019;1(2):28-30.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Real%20World%20Data%20in%20Japan%3A%20Chapter%20I%20NDB&as_occt=title&as_sauthors=%20%22H%20Yasunaga%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Tamiya N</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Noguchi H</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Nishi A</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Reich MR</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Ikegami N</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Hashimoto H</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Shibuya K</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Kawachi I</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Campbell JC</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Population ageing and
                  wellbeing: lessons from Japan’s long-term care insurance policy</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">Lancet.</span> 2011 Sep
                24;378(9797):1183-92.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Population%20ageing%20and%20wellbeing%3A%20lessons%20from%20Japan%E2%80%99s%20long-term%20care%20insurance%20policy&as_occt=title&as_sauthors=%20%22N%20Tamiya%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Iwagami M</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Tamiya N</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">The long-term care insurance
                  system in Japan: past, present, and future</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">JMA J.</span> 2019 Mar 4;2(1):67-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20long-term%20care%20insurance%20system%20in%20Japan%3A%20past%2C%20present%2C%20and%20future&as_occt=title&as_sauthors=%20%22M%20Iwagami%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Konishi T</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Inokuchi H</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Yasunaga H</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Services in public long-term
                  care insurance in Japan</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Ann Clin Epidemiol.</span> 2023 Jun 10;6(1):1-4.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Services%20in%20public%20long-term%20care%20insurance%20in%20Japan&as_occt=title&as_sauthors=%20%22T%20Konishi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Tsutsui T</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Muramatsu N</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Care-needs certification in the
                  long-term care insurance system of Japan</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">J Am Geriatr Soc.</span> 2005
                Mar;53(3):522-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Care-needs%20certification%20in%20the%20long-term%20care%20insurance%20system%20of%20Japan&as_occt=title&as_sauthors=%20%22T%20Tsutsui%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Takahashi S</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Hoshino M</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Takayama K</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Iseki K</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Sasaoka R</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Tsujio T</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Yasuda H</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Sasaki T</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Kanematsu F</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Kono H</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Toyoda H</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Nakamura H</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Predicting delayed union in
                  osteoporotic vertebral fractures with consecutive magnetic resonance imaging in the acute phase: a
                  multicenter cohort study</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Osteoporos Int.</span> 2016 Dec;27(12):3567-75.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predicting%20delayed%20union%20in%20osteoporotic%20vertebral%20fractures%20with%20consecutive%20magnetic%20resonance%20imaging%20in%20the%20acute%20phase%3A%20a%20multicenter%20cohort%20study&as_occt=title&as_sauthors=%20%22S%20Takahashi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Waterloo S</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Søgaard AJ</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Ahmed LA</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Damsgård E</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Morseth B</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Emaus N</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Vertebral fractures and
                  self-perceived health in elderly women and men in a population-based cross-sectional study: the Tromsø
                  Study 2007-08</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">BMC Geriatr.</span> 2013 Sep 30;13:102.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vertebral%20fractures%20and%20self-perceived%20health%20in%20elderly%20women%20and%20men%20in%20a%20population-based%20cross-sectional%20study%3A%20the%20Troms%C3%B8%20Study%202007-08&as_occt=title&as_sauthors=%20%22S%20Waterloo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Ong T</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Kantachuvesiri P</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Sahota O</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Gladman JRF</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Characteristics and outcomes of
                  hospitalised patients with vertebral fragility fractures: a systematic review</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Age Ageing.</span> 2018 Jan
                1;47(1):17-25.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Characteristics%20and%20outcomes%20of%20hospitalised%20patients%20with%20vertebral%20fragility%20fractures%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22T%20Ong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Curtis JR</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Mudano AS</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Solomon DH</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Xi J</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Melton ME</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Saag KG</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Identification and validation
                  of vertebral compression fractures using administrative claims data</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">Med Care.</span> 2009
                Jan;47(1):69-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Identification%20and%20validation%20of%20vertebral%20compression%20fractures%20using%20administrative%20claims%20data&as_occt=title&as_sauthors=%20%22JR%20Curtis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Matsuda T</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Iwagami M</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Suzuki T</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Jin X</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Watanabe T</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Tamiya N</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Correlation between the Barthel
                  Index and care need levels in the Japanese long-term care insurance system</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Geriatr Gerontol
                  Int.</span> 2019 Nov;19(11):1186-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Correlation%20between%20the%20Barthel%20Index%20and%20care%20need%20levels%20in%20the%20Japanese%20long-term%20care%20insurance%20system&as_occt=title&as_sauthors=%20%22T%20Matsuda%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Lin HR</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Otsubo T</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Imanaka Y</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">The effects of dementia and
                  long-term care services on the deterioration of care-needs levels of the elderly in Japan</span>.
                <span data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Medicine
                  (Baltimore).</span> 2015 Feb;94(7):e525.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effects%20of%20dementia%20and%20long-term%20care%20services%20on%20the%20deterioration%20of%20care-needs%20levels%20of%20the%20elderly%20in%20Japan&as_occt=title&as_sauthors=%20%22HR%20Lin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Lin HR</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Otsubo T</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Imanaka Y</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Survival analysis of increases
                  in care needs associated with dementia and living alone among older long-term care service users in
                  Japan</span>. <span data-id="emphasis_24" class="annotation emphasis" style="position: unset;">BMC
                  Geriatr.</span> 2017 Aug 15;17(1):182.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Survival%20analysis%20of%20increases%20in%20care%20needs%20associated%20with%20dementia%20and%20living%20alone%20among%20older%20long-term%20care%20service%20users%20in%20Japan&as_occt=title&as_sauthors=%20%22HR%20Lin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="videosummary_reference" class="annotation videosummary_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node videosummary" data-id="videoabstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Video Abstract</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6360462451112&quot;,&quot;type&quot;:&quot;video&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;4c43e834-5dad-4356-a76f-3838bb0b5ad2&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_1084" data-video-id="6360462451112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-workinghover vjs-v7 vjs-user-active bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-1 vjs-mouse vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors vjs-layout-small"
                      data-id="6360462451112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6360462451112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6360462451112" id="vjs_video_1084_html5_api" tabindex="-1" preload="auto"
                        data-account="2324982687001" data-player="HyP0JSARx" data-embed="default"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/1b663d7c-73bc-4bd0-935e-79b30773240b/28ff2c03-326f-4efe-8076-545b684a7be5/1280x720/match/image.jpg"
                        src="blob:https://www.jbjs.org/496d85ad-339c-4e38-9e1b-e5f538df3b54"></video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/1b663d7c-73bc-4bd0-935e-79b30773240b/28ff2c03-326f-4efe-8076-545b684a7be5/1280x720/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display" translate="yes" aria-live="off" aria-atomic="true">
                        <div style="position: absolute; inset: 0px; margin: 1.5%;"></div>
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-mouse-display">
                                <div class="vjs-volume-tooltip" aria-hidden="true"></div>
                              </div>
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">2:14</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 0:00">
                            <div class="vjs-load-progress" style="width: 7.37%;"><span
                                class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">7.37%</span></span>
                              <div data-start="0" data-end="9.919999" style="left: 0%; width: 100%;"></div>
                            </div>
                            <div class="vjs-mouse-display">
                              <div class="vjs-time-tooltip" aria-hidden="true"></div>
                            </div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;">
                              <div class="vjs-time-tooltip" aria-hidden="true" style="right: 0px;">0:00</div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">2:14</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_1084_component_1387">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_1084_component_1387"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-texttrack-settings" tabindex="-1" role="menuitem"
                                aria-disabled="false"><span class="vjs-menu-item-text">captions settings</span><span
                                  class="vjs-control-text" aria-live="polite">, opens captions settings dialog</span>
                              </li>
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">captions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button" type="button"
                          title="Picture-in-Picture" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                        <div class="vjs-overlay vjs-overlay-bottom-right vjs-overlay-no-background vjs-hidden"><img
                            src="//players.brightcove.net/2324982687001/studio_overlays/HyP0JSARx-0efbccee-026f-4138-bed4-07fddabe7725.png"
                            style="max-width: 100%;"></div>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_1084_component_1602_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_1084_component_1602_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_1084_component_1608_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_1084_component_1608_description">Beginning of dialog window. Escape will cancel
                          and close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_1084_component_1608">Text</legend><label
                                id="captions-foreground-color-vjs_video_1084_component_1608"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_1084_component_1608 captions-foreground-color-vjs_video_1084_component_1608">
                                <option id="captions-foreground-color-vjs_video_1084_component_1608-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_1084_component_1608 captions-foreground-color-vjs_video_1084_component_1608 captions-foreground-color-vjs_video_1084_component_1608-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_1084_component_1608-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_1084_component_1608 captions-foreground-color-vjs_video_1084_component_1608 captions-foreground-color-vjs_video_1084_component_1608-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_1084_component_1608-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_1084_component_1608 captions-foreground-color-vjs_video_1084_component_1608 captions-foreground-color-vjs_video_1084_component_1608-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_1084_component_1608-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_1084_component_1608 captions-foreground-color-vjs_video_1084_component_1608 captions-foreground-color-vjs_video_1084_component_1608-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_1084_component_1608-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_1084_component_1608 captions-foreground-color-vjs_video_1084_component_1608 captions-foreground-color-vjs_video_1084_component_1608-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_1084_component_1608-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_1084_component_1608 captions-foreground-color-vjs_video_1084_component_1608 captions-foreground-color-vjs_video_1084_component_1608-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_1084_component_1608-Magenta"
                                  value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_1084_component_1608 captions-foreground-color-vjs_video_1084_component_1608 captions-foreground-color-vjs_video_1084_component_1608-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_1084_component_1608-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_1084_component_1608 captions-foreground-color-vjs_video_1084_component_1608 captions-foreground-color-vjs_video_1084_component_1608-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_1084_component_1608"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_1084_component_1608 captions-foreground-opacity-vjs_video_1084_component_1608">
                                  <option id="captions-foreground-opacity-vjs_video_1084_component_1608-Opaque"
                                    value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_1084_component_1608 captions-foreground-opacity-vjs_video_1084_component_1608 captions-foreground-opacity-vjs_video_1084_component_1608-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_1084_component_1608-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_1084_component_1608 captions-foreground-opacity-vjs_video_1084_component_1608 captions-foreground-opacity-vjs_video_1084_component_1608-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_1084_component_1608">Background</legend><label
                                id="captions-background-color-vjs_video_1084_component_1608"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_1084_component_1608 captions-background-color-vjs_video_1084_component_1608">
                                <option id="captions-background-color-vjs_video_1084_component_1608-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_1084_component_1608 captions-background-color-vjs_video_1084_component_1608 captions-background-color-vjs_video_1084_component_1608-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_1084_component_1608-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_1084_component_1608 captions-background-color-vjs_video_1084_component_1608 captions-background-color-vjs_video_1084_component_1608-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_1084_component_1608-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_1084_component_1608 captions-background-color-vjs_video_1084_component_1608 captions-background-color-vjs_video_1084_component_1608-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_1084_component_1608-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_1084_component_1608 captions-background-color-vjs_video_1084_component_1608 captions-background-color-vjs_video_1084_component_1608-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_1084_component_1608-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_1084_component_1608 captions-background-color-vjs_video_1084_component_1608 captions-background-color-vjs_video_1084_component_1608-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_1084_component_1608-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_1084_component_1608 captions-background-color-vjs_video_1084_component_1608 captions-background-color-vjs_video_1084_component_1608-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_1084_component_1608-Magenta"
                                  value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_1084_component_1608 captions-background-color-vjs_video_1084_component_1608 captions-background-color-vjs_video_1084_component_1608-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_1084_component_1608-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_1084_component_1608 captions-background-color-vjs_video_1084_component_1608 captions-background-color-vjs_video_1084_component_1608-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_1084_component_1608"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_1084_component_1608 captions-background-opacity-vjs_video_1084_component_1608">
                                  <option id="captions-background-opacity-vjs_video_1084_component_1608-Opaque"
                                    value="1"
                                    aria-labelledby="captions-background-vjs_video_1084_component_1608 captions-background-opacity-vjs_video_1084_component_1608 captions-background-opacity-vjs_video_1084_component_1608-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_1084_component_1608-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_1084_component_1608 captions-background-opacity-vjs_video_1084_component_1608 captions-background-opacity-vjs_video_1084_component_1608-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_1084_component_1608-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_1084_component_1608 captions-background-opacity-vjs_video_1084_component_1608 captions-background-opacity-vjs_video_1084_component_1608-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_1084_component_1608">Window</legend><label
                                id="captions-window-color-vjs_video_1084_component_1608"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_1084_component_1608 captions-window-color-vjs_video_1084_component_1608">
                                <option id="captions-window-color-vjs_video_1084_component_1608-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_1084_component_1608 captions-window-color-vjs_video_1084_component_1608 captions-window-color-vjs_video_1084_component_1608-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_1084_component_1608-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_1084_component_1608 captions-window-color-vjs_video_1084_component_1608 captions-window-color-vjs_video_1084_component_1608-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_1084_component_1608-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_1084_component_1608 captions-window-color-vjs_video_1084_component_1608 captions-window-color-vjs_video_1084_component_1608-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_1084_component_1608-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_1084_component_1608 captions-window-color-vjs_video_1084_component_1608 captions-window-color-vjs_video_1084_component_1608-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_1084_component_1608-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_1084_component_1608 captions-window-color-vjs_video_1084_component_1608 captions-window-color-vjs_video_1084_component_1608-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_1084_component_1608-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_1084_component_1608 captions-window-color-vjs_video_1084_component_1608 captions-window-color-vjs_video_1084_component_1608-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_1084_component_1608-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_1084_component_1608 captions-window-color-vjs_video_1084_component_1608 captions-window-color-vjs_video_1084_component_1608-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_1084_component_1608-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_1084_component_1608 captions-window-color-vjs_video_1084_component_1608 captions-window-color-vjs_video_1084_component_1608-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_1084_component_1608"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_1084_component_1608 captions-window-opacity-vjs_video_1084_component_1608">
                                  <option id="captions-window-opacity-vjs_video_1084_component_1608-Transparent"
                                    value="0"
                                    aria-labelledby="captions-window-vjs_video_1084_component_1608 captions-window-opacity-vjs_video_1084_component_1608 captions-window-opacity-vjs_video_1084_component_1608-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_1084_component_1608-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_1084_component_1608 captions-window-opacity-vjs_video_1084_component_1608 captions-window-opacity-vjs_video_1084_component_1608-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_1084_component_1608-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_1084_component_1608 captions-window-opacity-vjs_video_1084_component_1608 captions-window-opacity-vjs_video_1084_component_1608-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_1084_component_1608" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_1084_component_1608">
                                <option id="captions-font-size-vjs_video_1084_component_1608-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_1084_component_1608 captions-font-size-vjs_video_1084_component_1608-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_1084_component_1608-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_1084_component_1608 captions-font-size-vjs_video_1084_component_1608-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_1084_component_1608-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_1084_component_1608 captions-font-size-vjs_video_1084_component_1608-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_1084_component_1608-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_1084_component_1608 captions-font-size-vjs_video_1084_component_1608-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_1084_component_1608-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_1084_component_1608 captions-font-size-vjs_video_1084_component_1608-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_1084_component_1608-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_1084_component_1608 captions-font-size-vjs_video_1084_component_1608-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_1084_component_1608-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_1084_component_1608 captions-font-size-vjs_video_1084_component_1608-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_1084_component_1608-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_1084_component_1608 captions-font-size-vjs_video_1084_component_1608-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_1084_component_1608-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_1084_component_1608 captions-font-size-vjs_video_1084_component_1608-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_1084_component_1608" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_1084_component_1608">
                                <option id="vjs_video_1084_component_1608-None" value="none"
                                  aria-labelledby="vjs_video_1084_component_1608 vjs_video_1084_component_1608-None">
                                  None</option>
                                <option id="vjs_video_1084_component_1608-Raised" value="raised"
                                  aria-labelledby="vjs_video_1084_component_1608 vjs_video_1084_component_1608-Raised">
                                  Raised</option>
                                <option id="vjs_video_1084_component_1608-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_1084_component_1608 vjs_video_1084_component_1608-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_1084_component_1608-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_1084_component_1608 vjs_video_1084_component_1608-Uniform">
                                  Uniform</option>
                                <option id="vjs_video_1084_component_1608-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_1084_component_1608 vjs_video_1084_component_1608-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_1084_component_1608" class="">Font Family
                              </legend><select aria-labelledby="captions-font-family-vjs_video_1084_component_1608">
                                <option id="captions-font-family-vjs_video_1084_component_1608-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_1084_component_1608 captions-font-family-vjs_video_1084_component_1608-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_1084_component_1608-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_1084_component_1608 captions-font-family-vjs_video_1084_component_1608-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_1084_component_1608-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_1084_component_1608 captions-font-family-vjs_video_1084_component_1608-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_1084_component_1608-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_1084_component_1608 captions-font-family-vjs_video_1084_component_1608-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_1084_component_1608-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_1084_component_1608 captions-font-family-vjs_video_1084_component_1608-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_1084_component_1608-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_1084_component_1608 captions-font-family-vjs_video_1084_component_1608-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_1084_component_1608-SmallCaps"
                                  value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_1084_component_1608 captions-font-family-vjs_video_1084_component_1608-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_1084_component_1872_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_1084_component_1872_description">This is a modal window. This modal can be
                          closed by pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;4c43e834-5dad-4356-a76f-3838bb0b5ad2&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[&quot;sp&quot;]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=4c43e834-5dad-4356-a76f-3838bb0b5ad2&type=supplement&name=10448"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D4c43e834-5dad-4356-a76f-3838bb0b5ad2%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F16%2F1453%26topics%3Dsp"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">August 21, 2024;
                      106 (16): 1453</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01438</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">July 1, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Authors. Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the terms of the <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank"
                            data-id="link_1" class="link" style="position: unset;">Creative Commons Attribution-Non
                            Commercial-No Derivatives License 4.0</a> (CCBY-NC-ND), where it is permissible to download
                          and share the work provided it is properly cited. The work cannot be changed in any way or
                          used commercially without permission from the journal.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;4c43e834-5dad-4356-a76f-3838bb0b5ad2&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;sp&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=4c43e834-5dad-4356-a76f-3838bb0b5ad2&type=pdf&name=JBJS.23.01438.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=4c43e834-5dad-4356-a76f-3838bb0b5ad2&type=pdf&name=JBJS.23.01438.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_30"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This work was
                          supported by grants from the Ministry of Health, Labour, and Welfare, Japan (23AA2003) and the
                          Cross-ministerial Strategic Innovation Promotion Program (SIP) (“Integrated Health Care
                          System”, JPJ012425). The <span data-id="strong_31" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I73"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I73</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;4c43e834-5dad-4356-a76f-3838bb0b5ad2&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;sp&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=4c43e834-5dad-4356-a76f-3838bb0b5ad2&type=zip&name=JBJS.23.01438.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Akira Honda, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8462-6024" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8462-6024</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hayato Yamana, MD, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2214-6695" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2214-6695</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yusuke Sasabuchi, MD, PhD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7828-0549" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7828-0549</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Eiji Takasawa, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7995-6093" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7995-6093</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tokue Mieda, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yusuke Tomomatsu, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1702-1145" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1702-1145</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kazuhiro Inomata, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0001-0000-2714" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0001-0000-2714</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kenta Takakura, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0006-1976-4037" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0006-1976-4037</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Toshiki Tsukui, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0002-7458-147X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0002-7458-147X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hiroki Matsui, PT, PhD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0004-4743" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0004-4743</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hideo Yasunaga, MD, PhD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6017-469X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6017-469X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hirotaka Chikuda, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:chikuda-tky@umin.ac.jp" class=""
                    style="position: unset;">chikuda-tky@umin.ac.jp</a></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Gunma University Graduate School of
                Medicine, Maebashi, Gunma, Japan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Data Science Center, Jichi Medical University, Shimotsuke, Tochigi,
                Japan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Real World Evidence, Graduate School of Medicine, The University
                of Tokyo, Bunkyo-ku, Tokyo, Japan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Clinical Epidemiology and Health Economics, School of Public
                Health, The University of Tokyo, Bunkyo-ku, Tokyo, Japan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 30855.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
