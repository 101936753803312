/* -----------------Globals--------------- */
import { ActivityIndicator, Modal } from "react-native";

/* -----------------Third parties--------------- */
import ImageViewer from "react-native-image-zoom-viewer";

/* -----------------UI--------------- */
import { Appbar, IconButton, Portal } from "react-native-paper";

import { useState } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { MarkdownRenderer } from "../../index";
import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";

/* -----------------Props--------------- */
type Props = {
	images: { url: string; metadata?: { media_type_description?: string } }[];
	handleClose: () => void;
	visible: boolean;
	initialIndex?: number;
};

type Position = Parameters<
	NonNullable<React.ComponentProps<typeof ImageViewer>["onMove"]>
>[0];

/* -----------------Component--------------- */
const ImageViewerModal = ({
	images,
	handleClose,
	visible,
	initialIndex = 0,
}: Props) => {
	const [isZoomed, setIsZoomed] = useState(false);

	const [currentImageIndex, setCurrentImageIndex] = useState(initialIndex);
	const theme = useAppTheme();

	if (!visible) {
		return null;
	}

	const handleOnMove = (position: Position) => {
		if (position?.scale && position.scale > 1) {
			setIsZoomed(true);
		} else {
			setIsZoomed(false);
		}
	};

	const handleOnChange = (index?: number) => {
		setCurrentImageIndex(index || 0);
	};

	const caption = images[currentImageIndex]?.metadata?.media_type_description;

	const showIndicator = images.length > 1;
	const showLeftArrow = currentImageIndex > 0;
	const showRightArrow = currentImageIndex < images.length - 1;

	const Caption = ({ text, isZoomed }: { text: string; isZoomed: boolean }) => {
		const { bottom } = useSafeAreaInsets();
		const theme = useAppTheme();

		return (
			<Box paddingHorizontal={16} bottom={bottom}>
				<MarkdownRenderer
					text={text}
					variant="answer"
					textAlign="center"
					customStyles={{
						text: {
							fontSize: 14,
							fontWeight: isZoomed ? "bold" : "400",
							color: isZoomed
								? theme.colors.primary
								: theme.colors.onSurfaceVariant,
						},
					}}
				/>
			</Box>
		);
	};
	return (
		<Portal>
			<Modal transparent={false}>
				<Appbar.Header mode="small" elevated>
					<Appbar.Action icon="arrow-left" onPress={handleClose} />
					<Appbar.Content
						title={caption || `Fig. ${currentImageIndex + 1}`}
						titleStyle={{
							textAlign: "left",
						}}
					/>
				</Appbar.Header>

				<ImageViewer
					style={{
						// for full screen images, it should have some spacing at the bottom.
						paddingTop: 24,
						maxHeight: "60%",
					}}
					enableImageZoom
					index={currentImageIndex}
					onMove={handleOnMove}
					renderIndicator={() => <></>}
					imageUrls={images}
					renderArrowRight={() =>
						showIndicator && showRightArrow ? (
							<IconButton
								mode="contained-tonal"
								iconColor={theme.colors.onSurfaceVariant}
								icon="chevron-right"
								size={44}
								style={{
									zIndex: 1,
									marginRight: 16,
								}}
							/>
						) : (
							<></>
						)
					}
					onCancel={handleClose}
					onChange={handleOnChange}
					backgroundColor="white"
					loadingRender={() => <ActivityIndicator size="small" />}
					renderArrowLeft={() =>
						showIndicator && showLeftArrow ? (
							<IconButton
								mode="contained-tonal"
								iconColor={theme.colors.onSurfaceVariant}
								icon="chevron-left"
								size={44}
								style={{
									marginLeft: 16,
								}}
							/>
						) : (
							<></>
						)
					}
					onSwipeDown={handleClose}
					enableSwipeDown
					useNativeDriver
					enablePreload
					pageAnimateTime={300}
				/>
				{caption && (
					<Box position="absolute" bottom={16} width="100%">
						<Caption text={caption} isZoomed={isZoomed} />
					</Box>
				)}
			</Modal>
		</Portal>
	);
};

export default ImageViewerModal;
