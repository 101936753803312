/* -----------------Third parties--------------- */
import { relayEnv } from "@memorang/configs";
import { commitMutation, graphql } from "react-relay";
import type { MutationParameters } from "relay-runtime";

interface ChangeUserMutationResponse {
	changeAvatar: boolean;
}

interface ChangeUserMutationVariables {
	iconUrl: string;
}

interface DeleteUserMutation extends MutationParameters {
	response: ChangeUserMutationResponse;
	variables: ChangeUserMutationVariables;
}

/* ----------------------- Query ------------------ */
const mutation = graphql`
  mutation ChangeAvatarMutation($iconUrl: AWSURL!) {
    changeAvatar(iconUrl: $iconUrl)
  }
`;

const changeAvatar = async (url: string): Promise<boolean> => {
	const promise = new Promise<boolean>((resolve, reject) => {
		const variables = {
			iconUrl: url,
		};

		commitMutation<DeleteUserMutation>(relayEnv, {
			mutation,
			variables,
			onCompleted: (response, errors) => {
				if (errors) {
					reject(errors);
				} else if (response.changeAvatar) {
					resolve(response.changeAvatar);
				} else {
					reject(new Error("ChangeUser is undefined"));
				}
			},
			onError: (error) => {
				reject(error);
			},
		});
	});

	return promise;
};

export default changeAvatar;
