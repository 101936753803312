/* -----------------Globals--------------- */
import { pageViewTypes } from "@constants/tracking";

import { Box, Container, EmptyState, Loader, useDevice } from "@memorang/ui";
/* -----------------UI--------------- */
import { Card } from "react-native-paper";

import useAnalytics from "@hooks/useAnalytics";
import UndrawWinnersImage from "../../../../assets/no-search-results.svg";
/* -----------------Helpers & Hooks--------------- */
import useLeaderboard from "../hooks/useLeaderboard";

import { useAppStore } from "@hooks/useAppStore";
import { useMemo } from "react";
/* -----------------Child components--------------- */
import LeaderboardView from "../components/Leaderboard/LeaderboardView";

const LeaderboardContainer = () => {
	const {
		leaderboards,
		podiumData,
		selectedChip,
		handleSelectChip,
		fetchingLeaderboard,
		userRank,
	} = useLeaderboard();

	const { isMobile } = useDevice();

	const avatarType = useAppStore((state) => state.app.avatarType);
	const Wrapper = isMobile ? Box : Card;

	const eventsObject = useMemo(() => {
		return {
			type: pageViewTypes.leaderboard,
		};
	}, []);
	useAnalytics(eventsObject);
	if (fetchingLeaderboard) {
		return <Loader />;
	}

	if (leaderboards?.length < 3) {
		return (
			<Box flexGrow={1} display="flex" justifyContent="center">
				<EmptyState
					title="Collecting Leaderboard Data..."
					subtitle="Come back later to check your rank!"
					image={UndrawWinnersImage}
				/>
			</Box>
		);
	}
	return (
		<Container>
			<Wrapper>
				<LeaderboardView
					userLeaderboardItem={userRank}
					leaderboards={leaderboards}
					topRankers={podiumData}
					selectedChip={selectedChip}
					handleSelectChip={handleSelectChip}
					avatarType={avatarType}
				/>
			</Wrapper>
		</Container>
	);
};

export default LeaderboardContainer;
