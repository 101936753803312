/* ----------------- React Native Paper --------------- */
import { Button, Card } from "react-native-paper";

/* ----------------- Hooks --------------- */
import { useAppTheme } from "../../../useAppTheme";

/* ----------------- Types --------------- */
import type { CheckboxStateMap, Section } from "./types";

import CardContainer from "../../CardContainer";
/* ----------------- Child components --------------- */
import CardContent from "./CardContent";

type Props = {
	title: string;
	subtitle: string;
	sections: Section[];
	handleReviewButtonClick: () => void;
	handleChangeCheckboxStateMap: (key: keyof CheckboxStateMap) => void;
	checkboxStateMap: CheckboxStateMap;
	showReview?: boolean;
};
const StudySessionPerformanceCard = ({
	title,
	subtitle,
	sections,
	handleReviewButtonClick,
	checkboxStateMap,
	handleChangeCheckboxStateMap,
	showReview,
}: Props) => {
	const theme = useAppTheme();
	const ActionsCard = () => (
		<Card.Actions
			style={{
				justifyContent: "flex-end",
			}}
		>
			<Button
				mode="text"
				theme={{ colors: { primary: theme.colors.secondary } }}
				onPress={handleReviewButtonClick}
			>
				Review Answers
			</Button>
		</Card.Actions>
	);

	return (
		<>
			<CardContainer
				title={title}
				subtitle={subtitle}
				icon="arm-flex-outline"
				actions={showReview && <ActionsCard />}
			>
				<CardContent
					sections={sections}
					checkboxStateMap={checkboxStateMap}
					handleCheckboxStateMapChange={(type) => {
						handleChangeCheckboxStateMap(type);
						handleReviewButtonClick();
					}}
					showReview={showReview}
				/>
			</CardContainer>
		</>
	);
};

export { CardContent, StudySessionPerformanceCard };
