import type { Article } from "../../../../../types";

export const Article649: Article = {
	id: 649,
	rsuiteId: "56648022-9b4f-4f5e-b17e-1c658634a9d8",
	type: "scientific article",
	title:
		"3D-Printed Prosthesis with an Articular Interface for Anatomical Acetabular Reconstruction After Type I + II (+ III) Internal Hemipelvectomy",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=56648022-9b4f-4f5e-b17e-1c658634a9d8&type=jpeg&name=JBJS.23.01462f1",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">3D-Printed Prosthesis with an Articular Interface for
                Anatomical Acetabular Reconstruction After Type I + II (+ III) Internal Hemipelvectomy</div>
              <div class="text subtitle" style="position: unset;">Clinical Outcomes and Finite Element Analysis</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Xin Huang,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Donghua
                      Huang, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_36" class="annotation strong"
                          style="position: unset;">Background:</span> Pelvic reconstruction after type I + II (or type I
                        + II + III) internal hemipelvectomy with extensive ilium removal is a great challenge. In an
                        attempt to anatomically reconstruct the hip rotation center (HRC) and achieve a low mechanical
                        failure rate, a custom-made, 3D-printed prosthesis with a porous articular interface was
                        developed. The aim of this study was to investigate the clinical outcomes of patients treated
                        with this prosthesis.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_37" class="annotation strong"
                          style="position: unset;">Methods:</span> This retrospective cohort study included 28 patients
                        with type I + II (+ III) internal hemipelvectomy through the articular interface of the
                        sacroiliac joint and managed with a prosthesis at a single center between August 2016 and August
                        2021. Complications and oncological outcomes were analyzed. The position of the reconstructed
                        HRC was assessed and lower-limb function was evaluated. Biomechanical analyses of different
                        fixation modes of the prosthesis were conducted using finite element analysis.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_38" class="annotation strong"
                          style="position: unset;">Results:</span> The displacement distance of the HRC from
                        preoperatively to postoperatively was a mean (and standard deviation) of 14.12 ± 8.75 mm. The
                        rate of implant-related complications was 14.3% (4 of 28) for prosthetic breakage, 14.3% (4 of
                        28) for aseptic loosening, 7.1% (2 of 28) for dislocation, and 7.1% (2 of 28) for deep
                        infection. The mean Musculoskeletal Tumor Society (MSTS)-93 score was 18.2. The aseptic
                        loosening rate was significantly greater for prostheses fixed with 3 sacral screws (4 of 10,
                        40.0%) than for those fixed with 4 (0 of 10, 0%) or 5 screws (0 of 8, 0%) (p = 0.024). The
                        prosthetic breakage rate was lower in patients who underwent lumbosacral fixation (0 of 13, 0%)
                        than in those who did not (4 of 15, 26.7%), although the difference did not reach significance
                        (p = 0.102). Biomechanical analyses suggested that the addition of lumbosacral fixation or
                        increasing the number of sacral screws from 3 to 4 or 5 visibly reduced the peak stress of the
                        sacral screws.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_39" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The use of a 3D-printed prosthesis with an
                        articular interface for pelvic reconstruction demonstrated stable prosthetic fixation,
                        anatomical acetabular reconstruction, and acceptable early functional outcomes.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_6" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_40" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Restoring patients’ ability to stand and walk after pelvic
                  tumor resection is challenging<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>. A
                  variety of prostheses, such as a stemmed acetabular pedestal cup<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a> and custom-made<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">6</span></a> and modular hemipelvic prostheses<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">10</span></a>,
                  have been developed to achieve better outcomes of pelvic reconstruction after the resection of
                  periacetabular tumors. The residual ilium can provide reliable osseous anchorage for prostheses, which
                  is conducive to mechanical fixation. However, most of these methods may not be suitable for type I +
                  II (or type I + II + III) reconstruction<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">11</span></a> when
                  both the ilium and acetabulum are extensively excised.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">In the present study, a newly designed, 3D-printed,
                  custom-made hemipelvic prosthesis (Chunli) (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>) with a
                  porous articular interface was developed for pelvic reconstruction after type I + II (+ III) internal
                  hemipelvectomy. To achieve precise matching between the articular interface of the prosthesis and the
                  sacrum, we modified the osteotomy method. Instead of using a traditional line saw for direct bone
                  cutting, we employed an electric knife to gradually separate the sacroiliac joint, ensuring complete
                  preservation of the sacral articular interface. Benefiting from a seamless “bone-implant” interface,
                  the position of the prosthesis could be uniquely determined during installation allowing placement of
                  the acetabular cup in the native position, which could maintain the function of the affected limb well
                  and decrease prosthesis-related complications. More importantly, our method allowed the use of a
                  custom-made prosthesis without requiring navigation or custom cutting guides.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01462f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=56648022-9b4f-4f5e-b17e-1c658634a9d8&type=jpeg&name=JBJS.23.01462f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;">Preoperative simulation and prosthesis design and
                          fabrication. The pelvic model (yellow), iliac component (cyan), acetabular component (khaki),
                          sacral screw (purple), and tumor (purplish red) are shown. <span data-id="strong_43"
                            class="annotation strong" style="position: unset;">Fig. 1-A</span> First, preoperative
                          thin-slice computed tomography (CT) and magnetic resonance (MR) images were imported into
                          Mimics V20.0 software (Materialise) to reconstruct virtual 3D pelvic models. <span
                            data-id="strong_44" class="annotation strong" style="position: unset;">Fig. 1-B</span>
                          Second, an individualized prosthesis was designed and optimized. The porous articular
                          interface of the prosthesis was designed to seamlessly fit the articular interface of the
                          sacrum. <span data-id="strong_45" class="annotation strong" style="position: unset;">Fig.
                            1-C</span> After the above procedures, the location of the acetabular cup was determined
                          according to the location of the native hip joint. The inclination and anteversion of the
                          acetabular cup were artificially set to 40° and 15°, respectively. <span data-id="strong_46"
                            class="annotation strong" style="position: unset;">Fig. 1-D</span> On the basis of the
                          sacral geometry in the patient, 3 to 5 sacral screws were designed to fix the prosthesis to
                          the sacrum. The trajectories and lengths of the sacral screws were strictly planned to avoid
                          penetrating into the sacral canal or anterior neurovascular plexus. A sacral tray was
                          introduced to fully fit the ventral sacral interface. <span data-id="strong_47"
                            class="annotation strong" style="position: unset;">Fig. 1-E</span> Third, a metal prosthesis
                          with a porous articular interface was 3D-printed and fabricated. There was a line on the
                          surface of the cup and another on the base (lines 1 and 2). If the 2 lines were aligned, the
                          inclination and anteversion of the acetabular cup would reach the preset angles (40° and 15°,
                          respectively). During prosthetic fixation, the acetabular cup could be rotated to change the
                          anteversion angle according to the intraoperative situation. Usually, a total of approximately
                          10 days is needed for the preparation of the prosthesis, including 1 to 2 days for the
                          prosthesis design, 5 to 6 days for the 3D printing and further fabrication of the prosthesis,
                          and 2 to 3 days for prosthesis transportation and disinfection measures.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The specific goals of this study were to (1) evaluate
                  whether anatomical reconstruction of the acetabulum could be achieved with the new prosthesis in cases
                  of type I + II (+ III) pelvic resection, (2) report the functional outcomes and complications
                  associated with this prosthesis at a minimum of 2 years of follow-up, and (3) identify an optimal
                  design for prostheses with excellent biomechanical properties by finite element analysis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Patients</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The current study was approved by the institutional review
                  board of our hospital (2021-0141). Between August 2016 and August 2021, a total of 136 patients
                  underwent hemipelvectomy with prosthetic reconstruction for oncological indications in our
                  musculoskeletal tumor center. The general indications for this prosthesis were periacetabular tumors
                  with extensive iliac involvement, which resulted in a lack of remaining iliac bone for conventional
                  hemipelvic prosthesis fixation after tumor resection. The contraindications were (1) invasion of the
                  sacroiliac joint by the tumor, (2) a tumor of the ilium with a huge soft-tissue mass hindering
                  disarticulation of the sacroiliac joint, and (3) ankylosed sacroiliac joints. An extensive osteolytic
                  lesion in the ilium near the sacroiliac joint was regarded as a relative contraindication. The process
                  of inclusion and exclusion is shown in <a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 2</a>.
                  Overall, 28 patients were included in the study. The demographic and clinical characteristics are
                  presented in <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>.
                  Briefly, there were 22 patients with primary malignant tumors and 6 patients with metastatic disease.
                  According to the Enneking and Dunham classification system<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">11</span></a>, a
                  type I + II resection was carried out for 12 patients, and a type I + II + III resection was performed
                  for 16 patients. Three patients underwent pelvic radiographic imaging only, without computed
                  tomography (CT) scans, for various reasons. Thus, 25 of the 28 patients were included in the
                  assessment of the accuracy of implant installation (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01462f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=56648022-9b4f-4f5e-b17e-1c658634a9d8&type=jpeg&name=JBJS.23.01462f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;">A flow diagram depicting the processes of case
                          inclusion and exclusion for the overall analyses and subgroup analyses. HRC = hip rotation
                          center, MSTS = Musculoskeletal Tumor Society.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">Table I</span>Demographics and Clinical Outcomes of Patients<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 480.222px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.375143);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Patient</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Sex</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Side</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Diagnosis</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Tumor
                          Region<span data-id="superscript_7" class="annotation superscript"
                            style="position: unset;">11</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Blood Loss
                          <span data-id="emphasis_38" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Operative
                          Time <span data-id="emphasis_39" class="annotation emphasis"
                            style="position: unset;">(hr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Sacral Screws</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Lumbosacral Fixation</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Follow-up
                          <span data-id="emphasis_40" class="annotation emphasis"
                            style="position: unset;">(mo)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Complications</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Margin</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Oncologic
                          Outcome</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Status</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">MSTS-93
                          Score<span data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II + III</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2,000</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">41.5</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">DM<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">AWD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,200</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.1</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">LR, DM<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">DOD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II + III</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,000</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">22.3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NED</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">RCCC</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2,000</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">36.8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">AL</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">DM</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">DOD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">UPS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,400</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">34.0</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">SI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">LR, DM</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">DOD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">RCCC</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,000</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">54.3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">PB, AL</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">DOD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II + III</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,600</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">84.9</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">PB</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NED</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">PNET</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II + III</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2,000</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">38.7</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">DM<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">DOD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,600</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">39.2</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">PB</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NED</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II + III</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,600</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">68.1</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">PB, AL</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NED</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II + III</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,000</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17.1</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">SI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">DM</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">DOD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II + III</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,500</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">61.6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Dislocation, DI</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NED</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">13</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">OS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II + III</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2,000</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">43.6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">DM<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">DOD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">14</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">UPS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,500</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">40.6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NED</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">15</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">OS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2,000</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">24.0</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">DOD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">16</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">RCCC</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,600</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">35.7</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">AL, SI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">DM<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">AWD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">17</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">OS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">600</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20.3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">DOD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">18</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">FTC</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I+II</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,500</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">32.4</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">AWD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">19</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">M-PEComa</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II + III</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2,000</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">30.5</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">DI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NED</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">20</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">M-PEComa</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,000</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">26.3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Dislocation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">DOD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">21</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">OS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II + III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">500</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">28.9</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NED</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">24</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">22</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">LMS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II + III</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2,000</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">28.2</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">LR, DM</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">AWD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">23</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">OS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,000</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">24.0</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NED</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">24</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II + III</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,400</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">29.4</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NED</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">25</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">F</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">FTC</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II + III</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,000</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">26.8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">AWD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">26</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II + III</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2,000</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">71.2</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">DM<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">AWD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">27</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II + III</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,600</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.2</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">DM</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">DOD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">28</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">M</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">L</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">OS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">I + II + III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">800</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">31.7</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">R0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NED</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">CS = chondrosarcoma, RCCC = renal clear cell carcinoma, UPS =
                        undifferentiated pleomorphic sarcoma, PNET = primitive neuroectodermal tumors derived from
                        esophagus, OS = osteosarcoma, FTC = follicular thyroid cancer, M-PEComa = malignant perivascular
                        epithelioid cell tumor, LMS = leiomyosarcoma, I + II = iliac and periacetabular areas, I + II +
                        III = iliac, periacetabular, and pubic areas, AL = aseptic loosening, SI = superficial
                        infection, PB = prosthetic breakage, DI = deep infection, DM = distant metastasis, LR = local
                        recurrence, R0 = negative surgical margin, R1 = positive surgical margin, NED = no evidence of
                        disease, AWD = alive with disease, DOD = died of disease, and MSTS = Musculoskeletal Tumor
                        Society.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Score at latest follow-up.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Patient experienced metastasis before surgery.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">Table II</span>Measurements of the Displacement Distance of the Hip
                          Rotation Center and Postoperative Acetabular Angle</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 816.744px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.627863);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Patient</span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Displacement Distance<span data-id="table_footnote_reference_9"
                            class="annotation table_footnote_reference" style="position: unset;">*</span><span
                            data-id="emphasis_41" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Postoperative Acetabular Angle</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Overall</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Lateromedial</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Anteroposterior</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Craniocaudal</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Inclination</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Anteversion</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.23</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.71</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.18</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">40.93°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17.40°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17.30</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.76</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.54</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.96</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">57.81°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.48°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.07</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.83</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.19</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.65</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">37.35°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">29.83°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">19.67</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.71</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.08</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">45.04°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.08°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">32.41</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">28.33</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.67</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.13</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">45.79°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.12°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">24.86</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.83</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.91</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">22.81</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">66.76°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17.05°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">32.08</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.72</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.82</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">25.92</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">59.18°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.59°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">20.29</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.24</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.61</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.83</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">52.82°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.71°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.92</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.30</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.00</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.36</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">41.35°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">24.38°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">10</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">19.39</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.81</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.61</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.38</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">54.00°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−4.99°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.50</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.57</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.54</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.38</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">44.52°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.21°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">12</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.78</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.31</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.85</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.03</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">33.86°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−24.42°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">13</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.47</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.10</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.37</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.75</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">45.51°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">19.74°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">14</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">25.33</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.05</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.38</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">24.42</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">44.61°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.76°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">15</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.43</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.00</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.94</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.90</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">32.28°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">21.19°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">16</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.13</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.28</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.11</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.17</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">36.39°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">27.56°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">17</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">22.38</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.54</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.71</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.28</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">33.65°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">43.51°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">18</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.95</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.86</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.42</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.96</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">42.57°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">23.39°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">19</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.09</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.64</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.02</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.43</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">37.72°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">23.68°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">20</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.25</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.64</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.86</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.09</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">39.32°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">27.58°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">21</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.70</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.80</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.02</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.52</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">41.82°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">32.29°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">22</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.75</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.23</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.16</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.28</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">39.54°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">35.33°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">23</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.32</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.86</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.09</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.54</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">39.28°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">32.01°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">24</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.44</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.21</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.20</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.23</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">38.49°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">26.88°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">25</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.38</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.05</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.04</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.08</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">44.10°</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">24.78°</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">26<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">27<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">28<span
                            data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total (n = 25)<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.12 ±
                          8.75</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.71 ±
                          6.86</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.95 ±
                          5.39</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.29 ±
                          7.34</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43.79 ±
                          8.49°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.89 ±
                          14.76°</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Data are given, and were analyzed, as absolute values.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">No postoperative CT.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the mean and standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Prosthesis
                Design and Manufacture</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The 3D-printed hemipelvic prosthesis was designed and
                  manufactured in 3 main steps, with details shown in <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 1</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Technique</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">After the tumor was routinely exposed, a distal osteotomy
                  of the pubic and ischial rami or pubic symphysis was performed, depending on the extent of tumor
                  invasion(<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3-A</a> and <a
                    href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">3-B</a>). Then, disarticulation of the sacroiliac joint was performed by
                  gradually detaching the connected tissues using an electrotome. It should be noted that the sacroiliac
                  joint is very wide and uneven, so it is necessary to dissect the ligamentous connection of the
                  articular interface with an electrotome while gradually breaking it open.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01462f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=56648022-9b4f-4f5e-b17e-1c658634a9d8&type=jpeg&name=JBJS.23.01462f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;">A 44-year-old male patient with a malignant
                          perivascular epithelioid cell tumor underwent prosthetic reconstruction using 5 sacral screws
                          and lumbosacral fixation after type I + II internal hemipelvectomy. <span data-id="strong_48"
                            class="annotation strong" style="position: unset;">Figs. 3-A and 3-B</span> A preoperative
                          radiograph and CT images show an osteolytic tumor involving the ilium and acetabulum. <span
                            data-id="strong_49" class="annotation strong" style="position: unset;">Fig. 3-C</span>
                          Disarticulation of the sacroiliac joint was performed, and the articular cartilage of the
                          sacral interface of the sacrum was removed (black arrow). <span data-id="strong_50"
                            class="annotation strong" style="position: unset;">Fig. 3-D</span> En bloc resection of the
                          tumor. <span data-id="strong_51" class="annotation strong" style="position: unset;">Fig.
                            3-E</span> The prosthesis was installed on the articular interface of the sacrum, and
                          ipsilateral lumbar pedicle screws were inserted at L5 for lumbosacral fixation to further
                          enhance prosthetic stability. <span data-id="strong_52" class="annotation strong"
                            style="position: unset;">Fig. 3-F</span> A radiograph made immediately postoperatively shows
                          that the prosthesis seamlessly matched the articular interface of the sacrum. <span
                            data-id="strong_53" class="annotation strong" style="position: unset;">Fig. 3-G</span> A
                          radiograph at the latest follow-up (24 months postoperatively) demonstrates no aseptic
                          loosening, prosthetic breakage, or dislocation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The cartilage of the sacral articular interface was
                  completely removed by curettage (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3-C</a>). The
                  subchondral bone was preserved to maintain the osseous strength (<a href=""
                    data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3-D</a>). Because of the uneven surface of the sacroiliac joint, the
                  prosthesis was seamlessly matched and installed (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3-E</a>). The
                  sacral tray of the prosthesis was placed to fully fit the ventral sacral interface to alleviate the
                  shear force. Three to 5 screws were inserted into the sacrum to fix the prosthesis as planned (<a
                    href="" data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>). For 13 patients, ipsilateral lumbar pedicle screws were
                  inserted at L5 for lumbosacral fixation to further enhance prosthetic stability (<a href=""
                    data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3-E</a>). Finally, the femoral stem prosthesis was routinely placed.
                  The ligaments and muscles around the hip joint were reconstructed whenever possible.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">Table III</span>Comparisons of Demographic Data</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1171.54px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.90137);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Lumbosacral Fixation</span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">No. of Sacral Screws</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Total (N = 28)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Yes (N =
                          13)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No (N =
                          15)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">3 (N =
                          10)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">4 (N =
                          10)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">5 (N =
                          8)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">*</span> (yr)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53.3 ±
                          12.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49.7 ±
                          14.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.492<span
                            data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53.7 ±
                          13.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54.1 ±
                          11.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45.0 ±
                          15.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.308<span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51.4 ±
                          13.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex <span
                            data-id="emphasis_42" class="annotation emphasis"
                            style="position: unset;">(no.)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.433<span
                            data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.785<span
                            data-id="table_footnote_reference_18" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Side <span
                            data-id="emphasis_43" class="annotation emphasis"
                            style="position: unset;">(no.)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.476<span
                            data-id="table_footnote_reference_19" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.802<span
                            data-id="table_footnote_reference_20" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Tumor region<span
                            data-id="table_footnote_reference_21" class="annotation table_footnote_reference"
                            style="position: unset;">**</span><span data-id="emphasis_44" class="annotation emphasis"
                            style="position: unset;">(no.)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.020<span
                            data-id="table_footnote_reference_22" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.126<span
                            data-id="table_footnote_reference_23" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> I + II</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> I + II + III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Source of tumor <span
                            data-id="emphasis_45" class="annotation emphasis"
                            style="position: unset;">(no.)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.000<span
                            data-id="table_footnote_reference_24" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.308<span
                            data-id="table_footnote_reference_25" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Primary
                          malignancy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Metastasis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Follow-up<span
                            data-id="table_footnote_reference_26" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_46" class="annotation emphasis"
                            style="position: unset;">(mo)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.9 ±
                          6.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43.5 ±
                          21.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.013<span
                            data-id="table_footnote_reference_27" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41.7 ±
                          22.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.6 ±
                          17.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.4 ±
                          5.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.168<span
                            data-id="table_footnote_reference_28" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36.2 ±
                          17.8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of sacral screws
                          <span data-id="emphasis_47" class="annotation emphasis"
                            style="position: unset;">(no.)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.415<span
                            data-id="table_footnote_reference_29" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lumbosacral fixation
                          <span data-id="emphasis_48" class="annotation emphasis"
                            style="position: unset;">(no.)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.415<span
                            data-id="table_footnote_reference_30" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Independent-samples t test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">One-way analysis of variance (ANOVA).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Fisher exact test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">Mann-Whitney U test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_12" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">**</b> <span class="text"
                        style="position: unset;">I + II = iliac and periacetabular areas, I + II + III = iliac,
                        periacetabular, and pubic areas.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Postoperative Management</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">The initiation of bed-based early rehabilitation started 2
                  weeks postoperatively. Full weight-bearing walking was not encouraged until 3 months after surgery.
                  All 28 patients were followed for a minimum of 24 months or until death.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Assessment
                of Complications and Oncological and Functional Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Complications were assessed at the time of follow-up as
                  scheduled. Aseptic loosening was defined as reported in a previous study<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">12</span></a>. Using 3D modeling and matching techniques, as we
                  previously reported<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">13</span></a>, the
                  displacement distance of the hip rotation center (HRC) from before to after surgery and the
                  postoperative inclination and anteversion were calculated. Oncological outcomes were assessed through
                  a review of patient records. Lower-limb function was evaluated according to the Musculoskeletal Tumor
                  Society (MSTS)-93 score at the latest follow-up<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">11</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Finite
                Element Analysis (FEA)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Details of the FEA are provided in the Appendix.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Continuous variables were compared using an
                  independent-samples t test or 1-way analysis of variance (ANOVA) for parametric data and a
                  Mann-Whitney U test or Kruskal-Wallis test for nonparametric data. Categorical variables were compared
                  with a Fisher exact test. Tests were 2-sided, and p values of &lt;0.05 were considered significant.
                  All statistical analyses were performed using IBM SPSS Statistics (version 22.0; IBM).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">In the overall cohort, the mean operative time (and
                  standard deviation) was 6.2 ± 1.3 hours, and the mean blood loss was 1,443 ± 466 mL. The surgical
                  margin was positive in 1 case; the patient received radiation therapy and chemotherapy
                  postoperatively. In the other 27 cases, a negative margin was achieved. Twelve patients received
                  chemotherapy, 7 patients received immunotherapy, and 2 patients with thyroid cancer received
                  iodine-131 treatment. No patients received radiation therapy postoperatively with the exception of the
                  1 patient with a positive margin as noted above.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Accuracy of
                Prosthesis Placement</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">For 24 of the 25 patients analyzed, all of the sacral
                  screws were accurately inserted into the sacral bone as assessed on CT images postoperatively. Only 1
                  patient experienced a screw (at the S1 level) breaking the anterior cortical bone of the sacral canal.
                  We speculated that this might have been because of small rotational errors in prosthesis installation.
                  Fortunately, no neurological symptoms were observed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The mean overall, lateromedial, anteroposterior, and
                  craniocaudal displacement distances of the HRC from preoperatively to postoperatively were 14.12 ±
                  8.75, 7.71 ± 6.86, 5.95 ± 5.39, and 7.29 ± 7.34 mm, respectively (<a href=""
                    data-id="figure_reference_13" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). The mean postoperative inclination and anteversion angles
                  were 43.79° ± 8.49° and 17.89° ± 14.76°, respectively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Complications and Oncological and Functional Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">At a mean follow-up of 36.2 months (range, 6.2 to 84.9
                  months), 17 patients were alive, including 11 who were free of disease (<a href=""
                    data-id="figure_reference_14" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). The rate of implant-related complications was 14.3% (4 of 28)
                  for prosthetic breakage (<a href="" data-id="figure_reference_15"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>), 14.3%
                  (4 of 28) for aseptic loosening, and 7.1% (2 of 28) for dislocation (<a href=""
                    data-id="figure_reference_16" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>). The rate of superficial infection was 10.7% (3 of 28) and
                  the rate of deep infection was 7.1% (2 of 28). For 1 of the patients with deep infection, the
                  infection was resolved by means of debridement, while the other patient with deep infection underwent
                  prosthesis removal.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01462f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=56648022-9b4f-4f5e-b17e-1c658634a9d8&type=jpeg&name=JBJS.23.01462f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;">A 59-year-old male patient with chondrosarcoma
                          underwent prosthetic reconstruction using 3 sacral screws without lumbosacral fixation. <span
                            data-id="strong_54" class="annotation strong" style="position: unset;">Figs. 4-A and
                            4-B</span> A preoperative radiograph and magnetic resonance imaging (MRI) scans (1.5-T
                          contrast-enhanced T1-weighted images) show mixed osteogenic and osteolytic lesions involving
                          the ilium, acetabulum, and pubis. <span data-id="strong_55" class="annotation strong"
                            style="position: unset;">Fig. 4-C</span> Radiographic image of the tumor. <span
                            data-id="strong_56" class="annotation strong" style="position: unset;">Fig. 4-D</span>
                          Postoperative computed tomography (CT) images show that the prosthesis seamlessly matched the
                          articular interface of the sacrum and that the sacral screws successfully avoided penetrating
                          the sacral canal or anterior neurovascular plexus. Radiographs immediately postoperatively
                          (<span data-id="strong_57" class="annotation strong" style="position: unset;">Fig.
                            4-E</span>), 12 months postoperatively (<span data-id="strong_58" class="annotation strong"
                            style="position: unset;">Fig. 4-F</span>), and 21 months postoperatively show that sacral
                          screw breakage occurred (<span data-id="strong_59" class="annotation strong"
                            style="position: unset;">Fig. 4-G</span>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">Table IV</span>Comparisons of Complications, Prognosis of Oncology,
                          and Functional Outcomes<span data-id="table_footnote_reference_43"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 603.883px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.847938);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Lumbosacral Fixation</span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">No. of Sacral Screws</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Total (N = 28)<span data-id="table_footnote_reference_31"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Yes (N =
                          13)<span data-id="table_footnote_reference_32" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No (N =
                          15)<span data-id="table_footnote_reference_33" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_34" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">3 (N =
                          10)<span data-id="table_footnote_reference_35" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">4 (N =
                          10)<span data-id="table_footnote_reference_36" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">5 (N =
                          8)<span data-id="table_footnote_reference_37" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_38" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Prosthetic
                          breakage</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4<span
                            data-id="table_footnote_reference_39" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.102</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.290</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (14.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic loosening</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.600</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.024</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (14.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dislocation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.000</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.735</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (7.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.639</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.835</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (17.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Local recurrence</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.583</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.756</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (10.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Distant metastasis<span
                            data-id="table_footnote_reference_40" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.137</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.126</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (39.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MSTS-93 score</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.0 ±
                          5.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.3 ±
                          5.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.263<span
                            data-id="table_footnote_reference_41" class="annotation table_footnote_reference"
                            style="position: unset;">**</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.2 ±
                          6.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.4 ±
                          3.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.3 ±
                          5.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.331<span
                            data-id="table_footnote_reference_42" class="annotation table_footnote_reference"
                            style="position: unset;">††</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.2 ±
                          5.3</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_13" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, except for the MSTS-93
                        scores, which are given as the mean and standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_14" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Fisher exact test, except where otherwise noted.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_15" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, with the percentage in
                        parentheses, except for the MSTS-93 scores, which are given as the mean and standard
                        deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_16" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">All 4 patients had breakage of screws.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_17" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">Six patients experienced metastasis before surgery.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_18" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">**</b> <span class="text"
                        style="position: unset;">Independent-samples t test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_19" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">††</b> <span class="text"
                        style="position: unset;">One-way analysis of variance (ANOVA).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">The local recurrence and distant metastasis rates were
                  10.7% and 39.3%, respectively (<a href="" data-id="figure_reference_17"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>). At
                  the latest follow-up, the mean MSTS-93 score was 18.2 (range, 9 to 28).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Comparison
                of Groups with or without Lumbosacral Fixation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Comparisons between the groups with or without lumbosacral
                  fixation are shown in <a href="" data-id="figure_reference_18"
                    class="annotation figure_reference resource-reference" style="position: unset;">Tables III</a> and
                  <a href="" data-id="figure_reference_19" class="annotation figure_reference resource-reference"
                    style="position: unset;">IV</a>. The prosthetic breakage rate was lower for patients who underwent
                  lumbosacral fixation (0 of 13, 0.0%) than for those who did not (4 of 15, 26.7%), although the
                  difference did not reach significance (p = 0.102). No significant difference in the rate of aseptic
                  loosening was identified between the groups with (1 of 13, 7.7%) or without (3 of 15, 20.0%)
                  lumbosacral fixation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Comparison
                of Groups with 3, 4, or 5 Sacral Screws</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Comparisons among the groups with 3, 4, or 5 sacral screws
                  are shown in <a href="" data-id="figure_reference_20"
                    class="annotation figure_reference resource-reference" style="position: unset;">Tables III</a> and
                  <a href="" data-id="figure_reference_21" class="annotation figure_reference resource-reference"
                    style="position: unset;">IV</a>. The aseptic loosening rate was significantly greater in the group
                  with 3 sacral screws (4 of 10, 40.0%) compared with the other 2 groups (4 screws, 0 of 10, 0.0%; and 5
                  screws, 0 of 8, 0.0%) (p = 0.024). In addition, inserting 3 sacral screws (3 of 10, 30.0%) resulted in
                  a higher risk of prosthetic breakage than use of 4 screws (1 of 10, 10.0%) or 5 screws (0 of 8, 0.0%),
                  although no significant difference was detected (p = 0.290).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">FEA of
                Stress</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">The distributions of the stresses on the postoperative
                  pelvis, prosthesis, and screws were compared in the static standing position. The overall
                  distributions of von Mises stresses relative to implant locations, sacral screws, and lumbosacral
                  systems among the 5 models are shown in <a href="" data-id="figure_reference_22"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 5</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.01462f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=56648022-9b4f-4f5e-b17e-1c658634a9d8&type=jpeg&name=JBJS.23.01462f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_40" style="position: unset;">
                        <div class="content" style="position: unset;">Five typical models of von Mises stress
                          constructed for comparison based on a single prototype (Patient Number 20 in <a href=""
                            data-id="figure_reference_23" class="annotation figure_reference resource-reference"
                            style="position: unset;">Tables I</a> and <a href="" data-id="figure_reference_24"
                            class="annotation figure_reference resource-reference" style="position: unset;">II</a>) and
                          the respective radiograph are shown: the initially designed prosthesis was fixed with 3 sacral
                          screws (Model 1); 3 sacral screws and the lumbosacral system (Model 2); 3 sacral screws with
                          the length of the screws halved (Model 3); 4 sacral screws (Model 4); and 5 sacral screws
                          (Model 5).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">The addition of lumbosacral fixation (Model 1 versus Model
                  2) visibly reduced the peak stress on the sacral screws by 48.2% (from 157.1 to 81.4 MPa) (<a href=""
                    data-id="figure_reference_25" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table V</a>). Increasing the number of sacral screws from 3 to 4 (Model 1
                  versus Model 4) not surprisingly also alleviated the peak stress on the sacral screws by 28.4% (from
                  157.1 to 112.5 MPa). However, the peak stress decreased only by 11.5% (from 112.5 to 99.6 MPa) with a
                  further increase from 4 to 5 screws (Model 4 versus Model 5). Halving all screw lengths exacerbated
                  the peak stress on the sacral screws, from 157.1 to 233.2 MPa (Model 1 versus Model 3).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_42" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">Table V</span>Comparisons of Maximum and Average von Mises Stress
                          on the 3D-Printed Prosthesis Under 700 N Preload</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 422.306px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.774118);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;"></span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;"></span>
                      </th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Stress on Sacral Screws <span data-id="emphasis_49"
                            class="annotation emphasis" style="position: unset;">(MPa)</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Stress on Titanium Prosthesis <span data-id="emphasis_50"
                            class="annotation emphasis" style="position: unset;">(MPa)</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Model</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Sacral Screws</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Lumbosacral Fixation</span></th>
                      <th style="position: unset;"><span class="text" style="position: unset;">Maximum</span></th>
                      <th style="position: unset;"><span class="text" style="position: unset;">Average</span></th>
                      <th style="position: unset;"><span class="text" style="position: unset;">Maximum</span></th>
                      <th style="position: unset;"><span class="text" style="position: unset;">Average</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Model 1</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">157.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">176.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Model 2</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">81.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">213.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Model 3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3 halved screws</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">233.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">30.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">317.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.6</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Model 4</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">112.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">185.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.7</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Model 5</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">5</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">99.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">191.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.1</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Whether to reconstruct a stable pelvis after internal
                  hemipelvectomy still remains controversial. Some studies<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">15</span></a> have advocated for hip transposition, which showed
                  comparable functional outcomes with lower rates of reoperation and infection than those of
                  reconstructing the pelvis using protheses, while avoiding the risk of implant failure. However, the
                  possibilities of shortening of the leg, a change in gait, and a longer period of rehabilitation are
                  also of concern<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">16</span></a>.
                  Consequently, extensive research efforts have been dedicated to the construction of a stable pelvis
                  after Enneking Type I + II (+ III) resection (<a href="" data-id="figure_reference_26"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VI</a>).
                  However, the reconstruction procedure is highly challenging after extensive type I + II (+ III)
                  internal hemipelvectomy, since the prosthesis can only be fitted and fixed to the sacrum<a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">17</span></a>. After an osteotomy using an ultrasonic bone knife or a
                  line saw in most of the studies shown in <a href="" data-id="figure_reference_27"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VI</a>, the
                  predominantly flat sacral side of the bone-implant interface would be composed of loose cancellous
                  bone and may be vulnerable to shear loading. Simple screw fixation can lead to screw breakage and
                  prosthetic loosening. Moreover, the loss of the iliac wing makes it difficult to precisely relocate
                  the acetabulum because of the complete removal of anatomical landmarks. In the present study, a novel
                  3D-printed, custom-made prosthesis with an articular interface was designed for accurate pelvic
                  reconstruction after type I + II (+ III) tumor resection. The outcomes suggest that the HRC was
                  accurately restored, that limb function was satisfactory for the majority of the patients, and that,
                  overall, relatively few prosthesis-related complications were found.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_44" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">Table VI</span>Literature Review of Pelvic Reconstruction
                          Approaches After Extensive Enneking Type I + II (+ III) Resection<span
                            data-id="table_footnote_reference_63" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1070.07px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.462079);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Study (Year)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total No.
                          of Cases</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. (%) of
                          Enneking I + II (+ III) Resections</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Prosthesis
                          Used</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          Follow-up (Range) <span data-id="emphasis_51" class="annotation emphasis"
                            style="position: unset;">(mo)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">NED</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">AWD</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">DOD</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean MSTS
                          Score (%)<span data-id="table_footnote_reference_44"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">PB</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">AL</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Dislocation</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">WRC</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">LR</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">DM</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ji et al. (2013)<a
                            href="" data-id="citation_reference_16"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_14" class="annotation superscript"
                              style="position: unset;">22</span></a><span data-id="superscript_14"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">16 (16%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Modular hemipelvic
                          prosthesis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52.9
                          (NM)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.8
                          (49.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NM</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NM</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NM</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NM</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Wafa et al. (2014)<a
                            href="" data-id="citation_reference_17"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_15" class="annotation superscript"
                              style="position: unset;">23</span></a><span data-id="superscript_15"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">11 (61%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Irradiated
                          autografts</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51.6
                          (4-185)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.3
                          (74.2%)<span data-id="table_footnote_reference_45" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Zhang et al. (2018)<a
                            href="" data-id="citation_reference_18"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_16" class="annotation superscript"
                              style="position: unset;">18</span></a><span data-id="superscript_16"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">5 (25%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Modular hemipelvic
                          prosthesis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39.4
                          (17-52)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.4
                          (58.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Guo et al. (2023)<a
                            href="" data-id="citation_reference_19"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_17" class="annotation superscript"
                              style="position: unset;">19</span></a><span data-id="superscript_17"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4 (100%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Custom-made
                          prosthesis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.5
                          (6-24)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.5
                          (65%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Kekeç et al. (2022)<a
                            href="" data-id="citation_reference_20"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_18" class="annotation superscript"
                              style="position: unset;">24</span></a><span data-id="superscript_18"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">9 (47.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Fresh-frozen pelvic
                          allograft</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66.6
                          (6-124)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.6
                          (68.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hu et al. (2022)<a
                            href="" data-id="citation_reference_21"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_19" class="annotation superscript"
                              style="position: unset;">25</span></a><span data-id="superscript_19"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">5 (31.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Custom-made
                          prosthesis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.2
                          (9-39)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.4
                          (91.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NM</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NM</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Peng et al. (2020)<a
                            href="" data-id="citation_reference_22"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_20" class="annotation superscript"
                              style="position: unset;">26</span></a><span data-id="superscript_20"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">5 (83.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Custom-made
                          prosthesis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.0
                          (5-24)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.8
                          (66%)<span data-id="table_footnote_reference_46" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NM</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Xie et al. (2022)<a
                            href="" data-id="citation_reference_23"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_21" class="annotation superscript"
                              style="position: unset;">27</span></a><span data-id="superscript_21"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">8 (57.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Acetabular component with
                          sacral components</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.0
                          (20-62)<span data-id="table_footnote_reference_47" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.9
                          (76.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Tang et al. (2017)<a
                            href="" data-id="citation_reference_24"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_22" class="annotation superscript"
                              style="position: unset;">28</span></a><span data-id="superscript_22"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">8 (61.5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Acetabular component with
                          pedicle screw-rod system</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.0
                          (8-43)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.7
                          (78.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Escudero-Acurio et al.
                          (2022)<a href="" data-id="citation_reference_25"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_23" class="annotation superscript"
                              style="position: unset;">29</span></a><span data-id="superscript_23"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 (100%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Custom-made
                          hydroxyapatite Schanz prosthesis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NM</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Beadel et al. (2005)<a
                            href="" data-id="citation_reference_26"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_24" class="annotation superscript"
                              style="position: unset;">30</span></a><span data-id="superscript_24"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">21 (80.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Fresh-frozen irradiated
                          hemipelvic allografts<span data-id="table_footnote_reference_48"
                            class="annotation table_footnote_reference" style="position: unset;">#</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NM</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.5
                          (45%)<span data-id="table_footnote_reference_49" class="annotation table_footnote_reference"
                            style="position: unset;">**</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NM</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Çolak et al. (2020)<a
                            href="" data-id="citation_reference_27"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_25" class="annotation superscript"
                              style="position: unset;">31</span></a><span data-id="superscript_25"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 (100%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Pedestal conic cup</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (96.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1<span
                            data-id="table_footnote_reference_50" class="annotation table_footnote_reference"
                            style="position: unset;">††</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Wang et al. (2020)<a
                            href="" data-id="citation_reference_28"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_26" class="annotation superscript"
                              style="position: unset;">32</span></a><span data-id="superscript_26"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">13 (100%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Custom-made
                          prosthesis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.3
                          (25-31)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (76.7%)<span data-id="table_footnote_reference_51" class="annotation table_footnote_reference"
                            style="position: unset;">‡‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Wang et al. (2018)<a
                            href="" data-id="citation_reference_29"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_27" class="annotation superscript"
                              style="position: unset;">33</span></a><span data-id="superscript_27"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unknown</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Custom-made
                          prosthesis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.5
                          (NM)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.2
                          (64%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Guo et al. (2010)<a
                            href="" data-id="citation_reference_30"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_28" class="annotation superscript"
                              style="position: unset;">34</span></a><span data-id="superscript_28"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">6 (33.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Modular hemipelvic
                          prosthesis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41
                          (7-73)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.5
                          (71.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Qu et al. (2019)<a
                            href="" data-id="citation_reference_31"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_29" class="annotation superscript"
                              style="position: unset;">35</span></a><span data-id="superscript_29"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">22 (100%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">A femoral head autograft
                          plus a modular hemipelvic prosthesis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47.2
                          (15-125)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.2
                          (60.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hoffmann et al. (2006)<a
                            href="" data-id="citation_reference_32"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_30" class="annotation superscript"
                              style="position: unset;">15</span></a><span data-id="superscript_30"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">18 (40%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Hip transposition</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NM</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NM</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NM</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NM</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.2
                          (60.8%)<span data-id="table_footnote_reference_52" class="annotation table_footnote_reference"
                            style="position: unset;">§§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NM</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NM</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NM</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Kunisada et al. (2019)<a
                            href="" data-id="citation_reference_33"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_31" class="annotation superscript"
                              style="position: unset;">14</span></a><span data-id="superscript_31"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">5 (71.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Hip transposition</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57.2
                          (18-102)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.2
                          (67.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1<span
                            data-id="table_footnote_reference_53" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4<span
                            data-id="table_footnote_reference_54" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Zhang et al. (2023)<a
                            href="" data-id="citation_reference_34"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_32" class="annotation superscript"
                              style="position: unset;">16</span></a><span data-id="superscript_32"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">11 (28.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Hip transposition</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17<span
                            data-id="table_footnote_reference_55" class="annotation table_footnote_reference"
                            style="position: unset;">‡‡</span> (6-110)<span data-id="table_footnote_reference_56"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23<span
                            data-id="table_footnote_reference_57" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6<span
                            data-id="table_footnote_reference_58" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9<span
                            data-id="table_footnote_reference_59" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.9
                          (53%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16<span
                            data-id="table_footnote_reference_60" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0<span
                            data-id="table_footnote_reference_61" class="annotation table_footnote_reference"
                            style="position: unset;">##</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13<span
                            data-id="table_footnote_reference_62" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Current study</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">28 (100%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Custom-made
                          prosthesis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36.2
                          (6.2-84.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.2
                          (60.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_20" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The data in the table are limited to the patients undergoing Enneking I
                        + II (+ III) resections unless otherwise specified. NED = no evidence of disease, AWD = alive
                        with disease, DOD = died of disease, PB = prosthetic breakage, AL = aseptic loosening, WRC =
                        wound-related complications, LR = local recurrence, DM = distant metastasis, NM = not mentioned,
                        and NA = not applicable.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_21" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Percentages are of a total possible score of 30.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_22" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Data of 1 patient were missing.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_23" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">The data were calculated on the basis of all patients in the
                        study.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_24" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">One patient had a fresh-unfrozen osteochondral allograft.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_25" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">**</b> <span class="text"
                        style="position: unset;">The data of only 6 patients with a minimum follow-up of 60 months were
                        analyzed.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_26" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">††</b> <span class="text"
                        style="position: unset;">One patient had implant failure resulting from a motorcycle
                        accident.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_27" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡‡</b> <span class="text"
                        style="position: unset;">The data are presented as the median instead of the mean.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_28" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§§</b> <span class="text"
                        style="position: unset;">Two unrelated patients were also included and analyzed.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_29" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">##</b> <span class="text"
                        style="position: unset;">Patients with local recurrence were excluded from the study.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">In this study, we aimed to establish a safe osteotomy
                  margin, accurately restore the HRC, and achieve low mechanical failure via our customized prosthesis
                  after type I + II (+ III) internal hemipelvectomy. The use of this prosthesis has 3 advantages. First,
                  the 3D-printed articular interface of the prosthesis was designed to seamlessly match the irregular
                  sacral interface. Thus, the position of the prosthesis could be uniquely determined during
                  installation, allowing placement of the acetabular cup at the native position. The mean
                  anteroposterior and craniocaudal displacement distances of the HRC in the present study were 5.95 and
                  7.29 mm, respectively, which were fully within the safe range of dislocation risk (&lt;18 mm and
                  &lt;20 mm, respectively), as reported in our previous study<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">13</span></a>. In
                  addition, an acceptable dislocation rate (7.1%, 2 of 28) was identified for the cohort, which appears
                  to be favorable compared with the rates of most other types of reconstructions (<a href=""
                    data-id="figure_reference_28" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VI</a>). Second, the relatively large, seamless, and porous
                  bone-prosthesis interface, the novel sacral tray structure, and the lumbosacral fixation system
                  collectively relieved shear force and may promote osseointegration, subsequently reducing
                  implant-related complications and leading to stable reconstruction. In this study, although the total
                  rate of the prosthesis‐related complications of aseptic loosening, prosthetic breakage, and
                  dislocation reached 28.6% (8 of 28), this rate decreased for patients who underwent lumbosacral
                  fixation (15.4%, 2 of 13) or who had 4 or 5 sacral screws implanted (16.7%, 3 of 18). Thus, short to
                  medium-term mechanical complications may be able to be controlled during the process of prosthesis
                  design and fixation. In addition, the rate of the prosthesis‐related complication of deep infection
                  was 7.1% (2 of 28), which is no higher than that reported for hip transposition<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_34"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">16</span></a>. The rapid and relatively straightforward installation of
                  the prosthesis could reduce the operative time and thus might reduce the infection rate. As for the
                  porous “bone-implant” interface, Zhang et al.<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">18</span></a>
                  reported on a series of hemipelvic reconstructions using a prosthesis with a 3D-printed porous
                  interface and showed the occurrence of osseointegration at the bone-prosthesis interface as assessed
                  by hard-tissue slicing and micro-CT scanning, with 1 case requiring a second external hemipelvectomy
                  for local recurrence. However, the evidence of osseointegration could not be captured in our cases
                  since we have not performed a revision surgery, in which we can check the osseointegration of the
                  bone-implant interface by pathology. Third, the number and trajectories of sacral screws were planned
                  on an individualized basis to avoid injuring the surrounding neurovascular structures. A high degree
                  of safety and convenience of operation were achieved during the prosthesis fixation process. Our newly
                  designed prosthesis demonstrated acceptable early functional outcomes, which seemed no inferior to
                  those reported by other studies (<a href="" data-id="figure_reference_29"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VI</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">Good biomechanical properties are essential for the design
                  of artificial implants to prevent prosthesis-related complications<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">19</span></a>. In the present study, we observed that the presence of
                  lumbosacral fixation or an increase in the number of sacral screws from 3 to 4 or 5 decreased the risk
                  of instrumentation failure. This result was further confirmed by the finite element analysis, which
                  showed that lumbosacral fixation or more sacral screws could decrease and disperse the shear force
                  exerted on each screw. This vertical force would gradually contribute to the breakage of screws and
                  prosthetic loosening. Similar phenomena have also been confirmed by multiple studies<a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_37"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">21</span></a>. On the basis of these findings, we recommend the use of 4
                  to 5 sacral screws to improve the stability of prostheses in clinical practice. If there is only room
                  to accommodate 3 sacral screws, the use of a unilateral lumbosacral prosthesis fixation system is
                  highly recommended. In addition, halving all screw lengths exacerbates the peak stress on the sacral
                  screws. Thus, ensuring sufficient sacral screw length is necessary for a stable fixation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">This study had a number of limitations. First, it was a
                  retrospective study without a control group. We cannot directly compare the outcomes with available
                  alternatives. Second, a minimum of 2 years of follow-up is insufficient to fully assess prosthesis
                  durability, and we will continue to follow this cohort. Third, the finite element model for the
                  biomechanical analysis was simplified to reduce computational complexity. Only static-load analysis
                  was incorporated into the study; gait analysis with a motion capture system should be the focus of a
                  future study. Fourth, the follow-up duration of the lumbosacral fixation group was shorter than that
                  of the group without lumbosacral fixation. Initially, the prosthesis was designed only for fixation
                  with sacral screws. However, as relatively high aseptic loosening and prosthetic breakage rates were
                  observed, especially when only 3 sacral screws were used for fixation, the lumbosacral system was
                  introduced and used when possible, with more stable fixation expected. This development of the
                  fixation approach contributed to the difference in follow-up duration, which might result in bias in
                  the mechanical failure analysis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">In summary, the use of a 3D-printed hemipelvic prosthesis
                  could help surgeons reconstruct the HRC at the native position and lead to satisfactory functional
                  recovery. The lumbosacral fixation system and more sacral screws combined with the sacral tray system
                  worked efficiently to alleviate shear force, which may decrease the risk of prosthesis-related
                  complications. The seamlessly matched and porous bone-prosthesis interface might achieve
                  osseointegration and long-term stabilization.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_3" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I230" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I230</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">NOTE: The authors thank Junyan Xie, Jiadan Wu, and Keyi
                  Wang from the Second Affiliated Hospital of Zhejiang University for their assistance in collecting
                  data.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Dang J</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Fu J</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Liu D</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Zhang Z</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Mi Z</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Cheng D</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Liu X</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Zhu D</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Wang L</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Shi Y</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Fan H</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Clinical application of
                  3D-printed patient-specific guide plate combined with computer navigation in acetabular reconstruction
                  following resection of periacetabular tumors</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Ann Transl Med.</span> 2022
                Jan;10(2):76.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20application%20of%203D-printed%20patient-specific%20guide%20plate%20combined%20with%20computer%20navigation%20in%20acetabular%20reconstruction%20following%20resection%20of%20periacetabular%20tumors&as_occt=title&as_sauthors=%20%22J%20Dang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Hipfl C</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Stihsen C</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Puchner SE</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Kaider A</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Dominkus M</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Funovics PT</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Windhager R</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Pelvic reconstruction following
                  resection of malignant bone tumours using a stemmed acetabular pedestal cup</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2017
                Jun;99-B(6):841-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pelvic%20reconstruction%20following%20resection%20of%20malignant%20bone%20tumours%20using%20a%20stemmed%20acetabular%20pedestal%20cup&as_occt=title&as_sauthors=%20%22C%20Hipfl%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Witte D</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Bernd L</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Bruns J</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Gosheger G</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Hardes J</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Hartwig E</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Lehner B</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Melcher I</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Mutschler W</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Schulte M</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Tunn PU</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Wozniak W</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Zahlten-Hinguranage A</span>,
                <span data-id="annotation_33" class="annotation" style="position: unset;"> Zeifang F</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Limb-salvage reconstruction with
                  MUTARS hemipelvic endoprosthesis: a prospective multicenter study</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Eur J Surg Oncol.</span> 2009
                Dec;35(12):1318-25.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Limb-salvage%20reconstruction%20with%20MUTARS%20hemipelvic%20endoprosthesis%3A%20a%20prospective%20multicenter%20study&as_occt=title&as_sauthors=%20%22D%20Witte%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Wu J</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Xie K</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Luo D</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Wang L</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Wu W</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Yan M</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Ai S</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Dai K</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Hao Y</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Three-dimensional printing-based
                  personalized limb salvage and reconstruction treatment of pelvic tumors</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">J Surg Oncol.</span> 2021
                Sep;124(3):420-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Three-dimensional%20printing-based%20personalized%20limb%20salvage%20and%20reconstruction%20treatment%20of%20pelvic%20tumors&as_occt=title&as_sauthors=%20%22J%20Wu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Park JW</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Kang HG</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Kim JH</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Kim HS</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">The application of 3D-printing
                  technology in pelvic bone tumor surgery</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">J Orthop Sci.</span> 2021 Mar;26(2):276-83.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20application%20of%203D-printing%20technology%20in%20pelvic%20bone%20tumor%20surgery&as_occt=title&as_sauthors=%20%22JW%20Park%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Feng Q</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Li Z</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Zhang X</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Feng J</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Wang D</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Study of the efficacy of
                  3D-printed prosthetic reconstruction after pelvic tumour resection</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">J Biomater Appl.</span> 2023
                Apr;37(9):1626-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Study%20of%20the%20efficacy%20of%203D-printed%20prosthetic%20reconstruction%20after%20pelvic%20tumour%20resection&as_occt=title&as_sauthors=%20%22Q%20Feng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Guo W</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Sun X</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Ji T</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Tang X</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Outcome of surgical treatment of
                  pelvic osteosarcoma</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">J Surg Oncol.</span> 2012 Sep 15;106(4):406-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcome%20of%20surgical%20treatment%20of%20pelvic%20osteosarcoma&as_occt=title&as_sauthors=%20%22W%20Guo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Zhou Y</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Duan H</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Liu Y</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Min L</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Kong Q</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Tu C</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Outcome after pelvic sarcoma
                  resection and reconstruction with a modular hemipelvic prostheses</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2011
                Dec;35(12):1839-46.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcome%20after%20pelvic%20sarcoma%20resection%20and%20reconstruction%20with%20a%20modular%20hemipelvic%20prostheses&as_occt=title&as_sauthors=%20%22Y%20Zhou%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Guo W</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Li D</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Tang X</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Ji T</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Surgical treatment of pelvic
                  chondrosarcoma involving periacetabulum</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">J Surg Oncol.</span> 2010 Feb 1;101(2):160-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20treatment%20of%20pelvic%20chondrosarcoma%20involving%20periacetabulum&as_occt=title&as_sauthors=%20%22W%20Guo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Xiong Y</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Lang Y</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Tu C</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Duan H</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Pelvis metastasis from primary
                  choroidal melanoma: a case report</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Onco Targets Ther.</span> 2014 Nov 14;7:2107-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pelvis%20metastasis%20from%20primary%20choroidal%20melanoma%3A%20a%20case%20report&as_occt=title&as_sauthors=%20%22Y%20Xiong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Enneking WF</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Dunham W</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Gebhardt MC</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Malawar M</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Pritchard DJ</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">A system for the functional
                  evaluation of reconstructive procedures after surgical treatment of tumors of the musculoskeletal
                  system</span>. <span data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 1993 Jan;(286):241-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20system%20for%20the%20functional%20evaluation%20of%20reconstructive%20procedures%20after%20surgical%20treatment%20of%20tumors%20of%20the%20musculoskeletal%20system&as_occt=title&as_sauthors=%20%22WF%20Enneking%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Deng Y</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Smith PN</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Li RW</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Diabetes mellitus is a
                  potential risk factor for aseptic loosening around hip and knee arthroplasty</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">BMC Musculoskelet
                  Disord.</span> 2023 Apr 5;24(1):266.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diabetes%20mellitus%20is%20a%20potential%20risk%20factor%20for%20aseptic%20loosening%20around%20hip%20and%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22Y%20Deng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Qu H</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Mou H</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Wang K</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Tao H</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Huang X</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Yan X</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Lin N</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Ye Z</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Risk factor investigation for
                  hip dislocation after periacetabular tumour resection and endoprosthetic reconstruction via thin-slice
                  CT-based 3D model</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2022 Oct;104-B(10):1180-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factor%20investigation%20for%20hip%20dislocation%20after%20periacetabular%20tumour%20resection%20and%20endoprosthetic%20reconstruction%20via%20thin-slice%20CT-based%203D%20model&as_occt=title&as_sauthors=%20%22H%20Qu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Kunisada T</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Fujiwara T</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Hasei J</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Nakata E</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Senda M</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Ozaki T</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Temporary External Fixation Can
                  Stabilize Hip Transposition Arthroplasty After Resection of Malignant Periacetabular Bone
                  Tumors</span>. <span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 2019 Aug;477(8):1892-901.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Temporary%20External%20Fixation%20Can%20Stabilize%20Hip%20Transposition%20Arthroplasty%20After%20Resection%20of%20Malignant%20Periacetabular%20Bone%20Tumors&as_occt=title&as_sauthors=%20%22T%20Kunisada%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Hoffmann C</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Gosheger G</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Gebert C</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Jürgens H</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Winkelmann W</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Functional results and quality
                  of life after treatment of pelvic sarcomas involving the acetabulum</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2006 Mar;88(3):575-82.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=968268" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Zhang L</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Iwata S</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Saito M</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Nakagawa M</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Tsukushi S</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Yoshida S</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Gokita T</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Ae K</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Nakashima S</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Watanuki M</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Akiyama T</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Hip Transposition Can Provide
                  Early Walking Function After Periacetabular Tumor Resection: A Multicenter Study</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2023 Dec 1;481(12):2406-16.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20Transposition%20Can%20Provide%20Early%20Walking%20Function%20After%20Periacetabular%20Tumor%20Resection%3A%20A%20Multicenter%20Study&as_occt=title&as_sauthors=%20%22L%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Wang J</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Min L</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Lu M</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Wang Y</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Luo Y</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Zhou Y</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Duan H</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Tu C</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Three-dimensional-printed
                  custom-made hemipelvic endoprosthesis for primary malignancies involving acetabulum: the design
                  solution and surgical techniques</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">J Orthop Surg Res.</span> 2019 Nov 27;14(1):389.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Three-dimensional-printed%20custom-made%20hemipelvic%20endoprosthesis%20for%20primary%20malignancies%20involving%20acetabulum%3A%20the%20design%20solution%20and%20surgical%20techniques&as_occt=title&as_sauthors=%20%22J%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Tang X</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Ji T</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Yan T</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Yang R</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Yang Y</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Wei R</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Liang H</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Guo W</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Is a Modular Pedicle-hemipelvic
                  Endoprosthesis Durable at Short Term in Patients Undergoing Enneking Type I + II Tumor Resections With
                  or Without Sacroiliac Involvement?</span><span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2018 Sep;476(9):1751-61.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20a%20Modular%20Pedicle-hemipelvic%20Endoprosthesis%20Durable%20at%20Short%20Term%20in%20Patients%20Undergoing%20Enneking%20Type%20I%20%2B%20II%20Tumor%20Resections%20With%20or%20Without%20Sacroiliac%20Involvement%3F&as_occt=title&as_sauthors=%20%22Y%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Guo Z</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Peng Y</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Shen Q</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Li J</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> He P</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Yuan P</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Liu Y</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Que Y</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Guo W</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Hu Y</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Xu S</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Reconstruction with 3D-printed
                  prostheses after type I + II + III internal hemipelvectomy: Finite element analysis and preliminary
                  outcomes</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">Front Bioeng Biotechnol.</span> 2023 Jan 9;10:1036882.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reconstruction%20with%203D-printed%20prostheses%20after%20type%20I%20%2B%20II%20%2B%20III%20internal%20hemipelvectomy%3A%20Finite%20element%20analysis%20and%20preliminary%20outcomes&as_occt=title&as_sauthors=%20%22Z%20Guo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Ji T</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Yang Y</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Tang X</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Liang H</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Yan T</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Yang R</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Guo W</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">3D-Printed Modular Hemipelvic
                  Endoprosthetic Reconstruction Following Periacetabular Tumor Resection: Early Results of 80
                  Consecutive Cases</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2020 Sep 2;102(17):1530-41.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=2429068" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Wang B</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Sun P</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Xie X</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Wu W</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Tu J</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Ouyang J</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Shen J</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">A novel combined hemipelvic
                  endoprosthesis for peri-acetabular tumours involving sacroiliac joint: a finite element study</span>.
                <span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2015
                Nov;39(11):2253-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20novel%20combined%20hemipelvic%20endoprosthesis%20for%20peri-acetabular%20tumours%20involving%20sacroiliac%20joint%3A%20a%20finite%20element%20study&as_occt=title&as_sauthors=%20%22B%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Ji T</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Guo W</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Yang RL</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Tang XD</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Wang YF</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Modular hemipelvic
                  endoprosthesis reconstruction—experience in 100 patients with mid-term follow-up results</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Eur J Surg Oncol.</span>
                2013 Jan;39(1):53-60.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Modular%20hemipelvic%20endoprosthesis%20reconstruction%E2%80%94experience%20in%20100%20patients%20with%20mid-term%20follow-up%20results&as_occt=title&as_sauthors=%20%22T%20Ji%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Wafa H</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Grimer RJ</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Jeys L</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Abudu AT</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Carter SR</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Tillman RM</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">The use of extracorporeally
                  irradiated autografts in pelvic reconstruction following tumour resection</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2014
                Oct;96-B(10):1404-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20use%20of%20extracorporeally%20irradiated%20autografts%20in%20pelvic%20reconstruction%20following%20tumour%20resection&as_occt=title&as_sauthors=%20%22H%20Wafa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Kekeç AF</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Güngör BŞ</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Mid-term outcomes of hemipelvic
                  allograft reconstruction after pelvic bone tumor resections</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">Jt Dis Relat Surg.</span>
                2022;33(1):117-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mid-term%20outcomes%20of%20hemipelvic%20allograft%20reconstruction%20after%20pelvic%20bone%20tumor%20resections&as_occt=title&as_sauthors=%20%22AF%20Keke%C3%A7%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Hu X</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Chen Y</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Cai W</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Cheng M</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Yan W</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Huang W</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Computer-Aided Design and 3D
                  Printing of Hemipelvic Endoprosthesis for Personalized Limb-Salvage Reconstruction after
                  Periacetabular Tumor Resection</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">Bioengineering (Basel).</span> 2022 Aug 18;9(8):400.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Computer-Aided%20Design%20and%203D%20Printing%20of%20Hemipelvic%20Endoprosthesis%20for%20Personalized%20Limb-Salvage%20Reconstruction%20after%20Periacetabular%20Tumor%20Resection&as_occt=title&as_sauthors=%20%22X%20Hu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Peng W</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Zheng R</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Wang H</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Huang X</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Reconstruction of Bony Defects
                  after Tumor Resection with 3D-Printed Anatomically Conforming Pelvic Prostheses through a Novel
                  Treatment Strategy</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Biomed Res Int.</span> 2020 Dec 1;2020:8513070.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reconstruction%20of%20Bony%20Defects%20after%20Tumor%20Resection%20with%203D-Printed%20Anatomically%20Conforming%20Pelvic%20Prostheses%20through%20a%20Novel%20Treatment%20Strategy&as_occt=title&as_sauthors=%20%22W%20Peng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Xie X</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Jin Q</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Zhao Z</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Wang Y</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Wang B</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Zou C</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Yin J</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Huang G</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Shen J</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">A novel limb-salvage
                  reconstruction strategy with a custom hemipelvic endoprosthesis and preserved femoral head following
                  the resection of periacetabular tumors: A preliminary study</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">J Surg Oncol.</span> 2022
                Sep;126(4):804-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20novel%20limb-salvage%20reconstruction%20strategy%20with%20a%20custom%20hemipelvic%20endoprosthesis%20and%20preserved%20femoral%20head%20following%20the%20resection%20of%20periacetabular%20tumors%3A%20A%20preliminary%20study&as_occt=title&as_sauthors=%20%22X%20Xie%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Tang X</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Guo W</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Yang R</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Yan T</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Tang S</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Li D</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Acetabular Reconstruction With
                  Femoral Head Autograft After Intraarticular Resection of Periacetabular Tumors is Durable at
                  Short-term Followup</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2017 Dec;475(12):3060-70.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acetabular%20Reconstruction%20With%20Femoral%20Head%20Autograft%20After%20Intraarticular%20Resection%20of%20Periacetabular%20Tumors%20is%20Durable%20at%20Short-term%20Followup&as_occt=title&as_sauthors=%20%22X%20Tang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Escudero-Acurio P</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Mahaluf F</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Bahamonde L</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Reconstruction of Type I-II
                  Internal Hemipelvectomy in a Patient With Pelvic Myxoid Chondrosarcoma: A Case Report</span>. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Cureus.</span> 2022 Jul
                6;14(7):e26621.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reconstruction%20of%20Type%20I-II%20Internal%20Hemipelvectomy%20in%20a%20Patient%20With%20Pelvic%20Myxoid%20Chondrosarcoma%3A%20A%20Case%20Report&as_occt=title&as_sauthors=%20%22P%20Escudero-Acurio%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Beadel GP</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> McLaughlin CE</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Wunder JS</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Griffin AM</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Ferguson PC</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Bell RS</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Outcome in two groups of
                  patients with allograft-prosthetic reconstruction of pelvic tumor defects</span>. <span
                  data-id="emphasis_30" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2005 Sep;438(438):30-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcome%20in%20two%20groups%20of%20patients%20with%20allograft-prosthetic%20reconstruction%20of%20pelvic%20tumor%20defects&as_occt=title&as_sauthors=%20%22GP%20Beadel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Çolak TS</span>, Ahmet Fevzi
                Kekeç AFKAFK. <span data-id="strong_31" class="annotation strong" style="position: unset;">Adolescent
                  pelvic chondrosarcoma, surgical treatment, and unusual reconstruction with pedestal conic cup
                  (LUMiC®): A case report</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Jt Dis Relat Surg.</span> 2020;31(3):648-52.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Adolescent%20pelvic%20chondrosarcoma%2C%20surgical%20treatment%2C%20and%20unusual%20reconstruction%20with%20pedestal%20conic%20cup%20(LUMiC%C2%AE)%3A%20A%20case%20report&as_occt=title&as_sauthors=%20%22TS%20%C3%87olak%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Wang J</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Min L</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Lu M</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Wang Y</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Luo Y</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Zhou Y</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Duan H</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Tu C</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">What are the Complications of
                  Three-dimensionally Printed, Custom-made, Integrative Hemipelvic Endoprostheses in Patients with
                  Primary Malignancies Involving the Acetabulum, and What is the Function of These Patients?</span><span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2020 Nov;478(11):2487-501.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20are%20the%20Complications%20of%20Three-dimensionally%20Printed%2C%20Custom-made%2C%20Integrative%20Hemipelvic%20Endoprostheses%20in%20Patients%20with%20Primary%20Malignancies%20Involving%20the%20Acetabulum%2C%20and%20What%20is%20the%20Function%20of%20These%20Patients%3F&as_occt=title&as_sauthors=%20%22J%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Wang B</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Hao Y</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Pu F</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Jiang W</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Shao Z</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Computer-aided designed, three
                  dimensional-printed hemipelvic prosthesis for peri-acetabular malignant bone tumour</span>. <span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2018
                Mar;42(3):687-94.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Computer-aided%20designed%2C%20three%20dimensional-printed%20hemipelvic%20prosthesis%20for%20peri-acetabular%20malignant%20bone%20tumour&as_occt=title&as_sauthors=%20%22B%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Guo Z</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Li J</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Pei GX</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Li XD</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Wang Z</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Pelvic reconstruction with a
                  combined hemipelvic prostheses after resection of primary malignant tumor</span>. <span
                  data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Surg Oncol.</span> 2010
                Jun;19(2):95-105.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pelvic%20reconstruction%20with%20a%20combined%20hemipelvic%20prostheses%20after%20resection%20of%20primary%20malignant%20tumor&as_occt=title&as_sauthors=%20%22Z%20Guo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Qu H</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Li D</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Tang S</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Zang J</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Wang Y</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Guo W</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Pelvic reconstruction following
                  resection of tumour involving the whole ilium and acetabulum</span>. <span data-id="emphasis_35"
                  class="annotation emphasis" style="position: unset;">J Bone Oncol.</span> 2019 Mar
                27;16:100234.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pelvic%20reconstruction%20following%20resection%20of%20tumour%20involving%20the%20whole%20ilium%20and%20acetabulum&as_occt=title&as_sauthors=%20%22H%20Qu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;56648022-9b4f-4f5e-b17e-1c658634a9d8&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=56648022-9b4f-4f5e-b17e-1c658634a9d8&type=supplement&name=12537"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D56648022-9b4f-4f5e-b17e-1c658634a9d8%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_27" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 15, 2025;
                      107 (2): 184</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01462</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 15, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_36"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Musculoskeletal Tumor Center, Department of Orthopedics, The Second Affiliated Hospital of
                            Zhejiang University School of Medicine, Hangzhou, Zhejiang, People’s Republic of
                            China</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Authors. Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the terms of the <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank"
                            data-id="link_1" class="link" style="position: unset;">Creative Commons Attribution-Non
                            Commercial-No Derivatives License 4.0</a> (CCBY-NC-ND), where it is permissible to download
                          and share the work provided it is properly cited. The work cannot be changed in any way or
                          used commercially without permission from the journal.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;56648022-9b4f-4f5e-b17e-1c658634a9d8&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=56648022-9b4f-4f5e-b17e-1c658634a9d8&type=pdf&name=JBJS.23.01462.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=56648022-9b4f-4f5e-b17e-1c658634a9d8&type=pdf&name=JBJS.23.01462.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_11" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_41"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This work was funded
                          by the Zhejiang Provincial Natural Science Foundation of China (LY23H060010, LQ21H060004) and
                          the National Natural Science Foundation of China (82372382, 82002333). These funding sources
                          did not play a role in the investigation. The <span data-id="strong_42"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I229" target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I229</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;56648022-9b4f-4f5e-b17e-1c658634a9d8&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=56648022-9b4f-4f5e-b17e-1c658634a9d8&type=zip&name=JBJS.23.01462.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Xin Huang, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span><span class=""
                  style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6247-1946" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6247-1946</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Donghua Huang, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span><span class=""
                  style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9988-3146" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9988-3146</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nong Lin, MD<span data-id="affiliation_reference_1"
                  class="label annotation cross_reference">1</span><span data-id="affiliation_reference_2"
                  class="label annotation cross_reference">2</span><span data-id="affiliation_reference_3"
                  class="label annotation cross_reference">3</span><span data-id="affiliation_reference_4"
                  class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span><span class=""
                  style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4939-113X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4939-113X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Xiaobo Yan, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span><span class=""
                  style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8509-869X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8509-869X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hao Qu, MD<span data-id="affiliation_reference_1"
                  class="label annotation cross_reference">1</span><span data-id="affiliation_reference_2"
                  class="label annotation cross_reference">2</span><span data-id="affiliation_reference_3"
                  class="label annotation cross_reference">3</span><span data-id="affiliation_reference_4"
                  class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span><span class=""
                  style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7633-7097" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7633-7097</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Zhaoming Ye, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span><span class=""
                  style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:yezhaoming@zju.edu.cn" class=""
                    style="position: unset;">yezhaoming@zju.edu.cn</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5951-5840" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5951-5840</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Musculoskeletal Tumor Center, Department of Orthopedics, The Second Affiliated
                Hospital of Zhejiang University School of Medicine, Hangzhou, Zhejiang, People’s Republic of
                China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Orthopedics Research Institute of Zhejiang University, Hangzhou, Zhejiang,
                People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Key Laboratory of Motor System Disease Research and Precision Therapy of
                Zhejiang Province, Hangzhou, Zhejiang, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Clinical Research Center of Motor System Disease of Zhejiang Province,
                Hangzhou, Zhejiang, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">Xin Huang, MD, and Donghua Huang, MD, contributed
                        equally to this work.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 33986.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
