/* -----------------UI--------------- */
import type { Distribution } from "@memorang/types";

import { getQuickActionsChips } from "@features/dashboard/";
import { useExamContext } from "@features/exam";
import { Box, DonutChart } from "@memorang/ui";
import { QuickActionChips } from "components/QuickActionChips";
import type { QuickActionsChipType } from "components/QuickActionChips/types";
import ProductIcon from "../ProductIcon";
/* -----------------Components--------------- */
import RowStats, { type Stat } from "../RowStats/RowStats";

/* -----------------Props--------------- */
type Props = {
	distribution: Distribution;
	title: string;
	image?: string;
	stats?: Stat[];
	handleQuickActionPress: (action: QuickActionsChipType["type"]) => void;
};

/* -----------------Component--------------- */
const ContentHero = (props: Props) => {
	const { distribution, stats, image, handleQuickActionPress } = props;

	const {
		currentExamContext: { studyPackProductId },
	} = useExamContext();

	const HeroOverallProgressDonutChart = () => {
		return (
			<>
				{image && (
					<DonutChart distribution={distribution} radius={64} innerRadius={50}>
						<ProductIcon image={image} size={64} />
					</DonutChart>
				)}
			</>
		);
	};

	const showStats = stats?.length;

	const Stats = () => <RowStats stats={stats || []} />;

	const actionChips = getQuickActionsChips(studyPackProductId);

	return (
		<>
			<Box padding={16} gap={16}>
				<HeroOverallProgressDonutChart />
				{showStats ? <Stats /> : null}
			</Box>
			<Box paddingBottom={8}>
				<QuickActionChips
					quickActionsChips={actionChips}
					handleQuickActionPress={handleQuickActionPress}
				/>
			</Box>
		</>
	);
};

export default ContentHero;
