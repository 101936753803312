import type { Article } from "../../../../../types";

export const Article669: Article = {
	id: 669,
	rsuiteId: "453b353f-2e93-4d83-94a8-95d510ebdda3",
	type: "the orthopaedic forum",
	title:
		"What's Important: Breaking Bread Together, Finding an Extracurricular Pursuit",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=453b353f-2e93-4d83-94a8-95d510ebdda3&type=jpeg&name=JBJS.24.00718f1",
	subSpecialties: ["sports_medicine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">What’s Important: Breaking Bread Together, Finding an
                Extracurricular Pursuit</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Stephen P.
                      Maier, II, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">Most health-care practitioners pursue a vocation in
                  medicine to help others. It is the collective goal shared by members of medical teams, just as the
                  Jesuit mantra states, “Be a man for others.” We dedicate our time, energy, attention, and lives toward
                  taking care of patients; it has the potential to become consuming.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">This is why it is critical to find an extracurricular
                  pursuit. There are unique passions within each of us, and it is important to cultivate these passions
                  when not caring for our patients. Why should we intentionally dedicate our coveted private time to
                  something other than rest, recuperation, and continued refined practice? First, it’s good for you.
                  Engagement in hobbies has been shown to be associated with greater general happiness levels and longer
                  life expectancy<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  Second, it will make you a better provider. Innovative behavior and positive engagement in activities
                  have been shown to improve employee mental health and job performance<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>. Furthermore, you may find a friend. I certainly did.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">I was ecstatic to begin my fellowship and excited about
                  the case volume and variety, the mentorship, the coverage opportunities, and the overall preparation
                  being offered to me before beginning a career as an attending physician. The year has been
                  spectacular, even better than advertised or imagined. I am fortunate to have found a program that
                  offered what I wanted and delivered on its pledge. This year has been one of growth, development,
                  maturation, and confidence fortification.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">Unexpectedly, this year also flipped the script on me, as
                  the teacher became the learner, and the apprentice took on the role of expert. I was sitting in
                  clinic, wrapping up note-writing for the morning, when my off-service attending physician approached
                  me. “I hear you make bread,” he stated. This began a 20-minute conversation about sourdough, yeast,
                  dough rise, hydration, bannetons, Dutch ovens, and boules. It was at this point that my year took on a
                  different “flavor.” I had a passion outside of orthopaedic surgery, sports, and academia, which was
                  revealed and shared with others.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Shortly thereafter, I shared all of my sourdough recipes:
                  starter, levain, bread, bagels, English muffins, pizza, and cinnamon rolls. Conversations in clinic
                  and the operating room (OR) revolved around sourdough (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). In
                  fact, I was summoned from my OR one day to “meet [my attending physician] in the break room.” I was
                  finishing a case, but hurried over when I was able. I found him in his OR mid-dissection—he had moved
                  on from the break room. He paused and told me there was a tin foil package in the refrigerator for me.
                  In it I found a chunk of delicious homemade bread. He had been inspired by our discussions and made 2
                  loaves of bread with his brother, who was visiting from out of town. This newly discovered fondness
                  was allowing him to connect in novel ways with his family too!</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00718f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=453b353f-2e93-4d83-94a8-95d510ebdda3&type=jpeg&name=JBJS.24.00718f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">Back of the napkin-style notes offered regarding
                          the process of sourdough bread baking between cases in the ambulatory surgery center.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">As the year continued, bread facilitated a connection that
                  “bred” familiarity and comfort. It allowed me to speak more freely without fear of seeming inept. I
                  asked more questions, I gained more clarity, and I accumulated confidence. We made plans to bake
                  together, which evolved quickly into an organized dinner for several fellowship programs, hosted by my
                  attending physician and preceded by stretching and folding our sourdough loaves (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). In a punny way, our initial interest in sourdough was
                  developing the “gluten” in the relationships across the program. Residents, fellows, nurses, scrub
                  technicians, athletic trainers, anesthetists, and orthopaedists were bonding over bread and inviting
                  others to do the same.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00718f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=453b353f-2e93-4d83-94a8-95d510ebdda3&type=jpeg&name=JBJS.24.00718f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_11" style="position: unset;">
                        <div class="content" style="position: unset;">The final baked product beside a hand-crank
                          bread-slicing device.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">In short, pursue and share your passions. Humans are
                  innately social beings, continuously seeking fulfillment. You may be surprised by who takes an
                  interest in your penchant.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Mak HW</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Noguchi T</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Bone JK</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Wels J</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Gao Q</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Kondo K</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Saito T</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Fancourt D</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Hobby engagement and mental
                  wellbeing among people aged 65 years and older in 16 countries</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Nat Med.</span> 2023
                Sep;29(9):2233-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hobby%20engagement%20and%20mental%20wellbeing%20among%20people%20aged%2065%20years%20and%20older%20in%2016%20countries&as_occt=title&as_sauthors=%20%22HW%20Mak%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Lu X</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Yu H</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Shan B</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Relationship between Employee
                  Mental Health and Job Performance: Mediation Role of Innovative Behavior and Work Engagement</span>.
                <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Int J Environ Res Public
                  Health.</span> 2022 May 28;19(11):6599.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Relationship%20between%20Employee%20Mental%20Health%20and%20Job%20Performance%3A%20Mediation%20Role%20of%20Innovative%20Behavior%20and%20Work%20Engagement&as_occt=title&as_sauthors=%20%22X%20Lu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D453b353f-2e93-4d83-94a8-95d510ebdda3%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">February 5, 2025;
                      107 (3): 321</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00718</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 26, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_3"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the Division
                            of Sports Medicine, Department of Orthopedic Surgery, Emory University, Atlanta,
                            Georgia</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;453b353f-2e93-4d83-94a8-95d510ebdda3&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=453b353f-2e93-4d83-94a8-95d510ebdda3&type=pdf&name=JBJS.24.00718.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=453b353f-2e93-4d83-94a8-95d510ebdda3&type=pdf&name=JBJS.24.00718.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_3" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_4"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I217" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I217</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;453b353f-2e93-4d83-94a8-95d510ebdda3&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=453b353f-2e93-4d83-94a8-95d510ebdda3&type=zip&name=JBJS.24.00718.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Stephen P. Maier, II, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:stephen.p.maier@gmail.com" class=""
                    style="position: unset;">stephen.p.maier@gmail.com</a></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Division of Sports Medicine, Department of Orthopedic Surgery, Emory
                University, Atlanta, Georgia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 5391.47px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
