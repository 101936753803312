/* -----------------------Globals-------------------- */
import { useCallback, useEffect, useState } from "react";

/* -----------------------UI-------------------- */
import { Image, Pressable } from "react-native";
import { Button, HelperText, Text, TextInput } from "react-native-paper";

import { useExamContext, useExams } from "@features/exam";
import { useSessionStore } from "@features/session";
/* -----------------------Hooks-------------------- */
import { useAppStore } from "@hooks/useAppStore";
import { useAppTheme } from "@hooks/useAppTheme";
import { useAuthContext } from "@memorang/applets";
import { Redirect, useLocalSearchParams, useRouter } from "expo-router";

/* -----------------------Features-------------------- */
import { type AccessCodeResult, applyAccessCode } from "@features/auth";
import { createSession } from "@features/session";

import { events } from "@constants/tracking";
import { currentAppVersion } from "@helpers/expo-extras";
import { createRelativeURL } from "@helpers/url";
/* -----------------------Helpers-------------------- */
import { composeMessage, getPlatform } from "@memorang/helpers";
import { trackCustomEvent } from "analytics";

/* -----------------------UI Components-------------------- */
import { AuthButton, Box, Container, MarkdownRenderer } from "@memorang/ui";
import { ConfirmDialog } from "components/ConfirmDialog";

/* -----------------------Constants-------------------- */
import { redeemContactSupportTemplate } from "../constants";

/* -----------------------Types-------------------- */
import type { SessionItem } from "@memorang/types";

import { CollectUserDetailsDialog } from "@features/account";
/* -----------------------Session-------------------- */
import { helpDialog } from "@features/session/";
import useClerkUser from "@hooks/useClerkUser";
import { getOlxPostRedeemDialogConfig } from "../helpers";

type Props = {
	handleNext: (accedssCodeResult: AccessCodeResult) => void;
};
//TODO: This component needs to be refactored as container and presentational components
const RedeemCode = ({ handleNext }: Props) => {
	const {
		supportEmail,
		app: {
			logo: { company },
			name: appName,
		},
	} = useAppStore((store) => ({
		supportEmail: store.supportEmail,
		app: store.app,
	}));

	const router = useRouter();

	const { exams } = useExams();

	const [helpDialogVisible, setHelpDialogVisible] = useState(false);

	const [showCollectUserDialog, setShowCollectUserDialog] = useState(false);

	const toggleShowCollectUserDialog = () => {
		setShowCollectUserDialog((prev) => !prev);
	};

	const [olxDates, setOlxDates] = useState<{
		beginStartDate: string;
		endStartDate: string;
		tempAccessCodeResponse?: AccessCodeResult;
	} | null>(null);

	const params = useLocalSearchParams<{
		redeemCode: string;
		showOnboarding: string;
	}>();

	const pathCode = params ? params.redeemCode : "";
	const showOnboarding = params ? params.showOnboarding : "";

	const theme = useAppTheme();

	const [code, setCode] = useState(pathCode);

	const { sessionItems, examName, totalTime, numericId } = useSessionStore(
		(state) => ({
			sessionItems: state.sessionItems,
			examName: state.examName,
			totalTime: state.totalTime,
			numericId: state.numericId,
		}),
	);

	useEffect(() => {
		setCode(pathCode);
	}, [pathCode]);

	const [errorMessage, setErrorMessage] = useState<string | undefined>(
		undefined,
	);

	const [loading, setLoading] = useState(false);

	const { isSignedIn } = useClerkUser();

	const { loading: authLoading, newUserInfo, viewerId } = useAuthContext();

	const {
		currentExamContext: { practiceTestProductId },
		handleSwitchExamContext,
	} = useExamContext();

	const updateSession = useSessionStore((state) => state.updateSession);

	const [creatingSession, setCreatingSession] = useState(false);

	const handleStartPracticeTestSession = async (examId: string) => {
		try {
			setCreatingSession(true);
			const result = await createSession({
				action: "SUMMATIVE_SESSION",
				createSessionType: "PRACTICE",
				learningOrder: "SHUFFLED",
				examId,
				bundleId: practiceTestProductId,
				userId: viewerId!,
			});
			updateSession({
				sessionResponse: result,
				isSummativeTest: true,
			});
		} catch (_error) {
			handleNext(olxDates?.tempAccessCodeResponse!);
		} finally {
			setCreatingSession(false);
		}
	};

	const username = newUserInfo?.name || "";

	const email = newUserInfo?.email || "";
	const showError = errorMessage != null;

	const switchExamContext = async (examId: string) => {
		const examDetails = exams.find(
			(examDetails) => examDetails.exam.id === examId,
		);

		if (examDetails) {
			await handleSwitchExamContext(examDetails);
		}
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	const handleSubmit = useCallback(async () => {
		setLoading(true);
		if (code) {
			if (code.length === 6 && code.match(/^[0-9]+$/)) {
				setErrorMessage(
					"The code you have entered is a One Time Verification Code. Please enter a valid Access Code.",
				);
				setLoading(false);
			} else {
				trackCustomEvent({
					eventName: events.redeemAccessCodeStarted,
					code,
				});
				try {
					const result = await applyAccessCode(code);
					if (result.data.beginStartDate && result.data.endStartDate) {
						switchExamContext(result.data.examId);
						setOlxDates({
							beginStartDate: result.data.beginStartDate,
							endStartDate: result.data.endStartDate,
							tempAccessCodeResponse: result,
						});
					} else {
						handleNext(result);
					}
				} catch (error) {
					setErrorMessage(error as never as string);
				} finally {
					setLoading(false);
				}
			}
		}
	}, [code]);

	useEffect(() => {
		if (pathCode && isSignedIn && !authLoading) {
			handleSubmit();
		}
	}, [authLoading, handleSubmit, isSignedIn, pathCode]);

	if (!(isSignedIn || authLoading)) {
		const newUrl = createRelativeURL("/(auth)/login", params);

		return <Redirect href={newUrl as never} />;
	}
	const handleChangeText = (text: string) => {
		if (showError) {
			setErrorMessage(undefined);
		}
		setCode(text);
	};

	const handlePressContactSupport = () => {
		const version = currentAppVersion;
		const platform = getPlatform();
		const subject = `[${appName}] Access Code Issue`;
		const body = `Hey ${appName} support, my username is ${username} and email is ${email}. I'm using version ${version} of the ${appName} ${platform} app. I'm having some trouble with my access code. I've included it here: [copy paste your access code here]
  
  Thank you!`;
		composeMessage(subject, body, supportEmail);
	};
	const renderErrorState = () => {
		if (showError) {
			return (
				<MarkdownRenderer
					text={errorMessage}
					variant="explanation"
					textAlign="center"
					customStyles={{
						text: {
							color: theme.colors.error,
						},
					}}
				/>
			);
		}
		return (
			<HelperText type="info">
				If you’ve previously purchased an access code, please enter it here,
				including the dashes.
			</HelperText>
		);
	};
	const disableFab = !code || code.length < 6 || loading;

	const getCompanyLogo = () => {
		if (company) {
			const { light, dark } = company;
			// biome-ignore lint/suspicious/noExplicitAny: <explanation>
			return (theme as any).dark ? dark : light;
		}
		return null;
	};

	const logo = getCompanyLogo();

	const RenderPostRedeemOlxDialog = () => {
		const showOlxDialog = olxDates != null;
		if (showOlxDialog) {
			const config = getOlxPostRedeemDialogConfig(
				olxDates.beginStartDate,
				olxDates.endStartDate,
			);

			return (
				<ConfirmDialog
					visible={showOlxDialog}
					title={config.title}
					body={config.body}
					confirmCta={config.confirmCta}
					dismissCta={config.cancelCta}
					icon="information-outline"
					confirmCtaLoading={creatingSession}
					confirmCallback={async () => {
						await handleStartPracticeTestSession("OLX1");
						toggleShowCollectUserDialog();
						setOlxDates(null);
					}}
					dismissCallback={() => {
						setOlxDates(null);
						router.push("/practice-tests");
					}}
				/>
			);
		}
		return null;
	};

	const handlePostSubmitUserDetails = () => {
		toggleShowCollectUserDialog();
		setHelpDialogVisible(true);
	};

	const RenderSessionHelpDialog = () => {
		if (helpDialogVisible) {
			const currentSessionItems = sessionItems[0].children;
			const numSections = sessionItems.length;
			const confirmDialogConfig = helpDialog({
				sessionItems: currentSessionItems as SessionItem[],
				numSections,
				examName: examName!,
				totalTime,
				isDiagnosticTest: false,
				numTotalQuestions: sessionItems.reduce(
					(acc, item) => acc + item.children.length,
					0,
				),
			});

			return (
				<ConfirmDialog
					visible={helpDialogVisible}
					title={confirmDialogConfig.title}
					icon={confirmDialogConfig.icon}
					body={confirmDialogConfig.body}
					confirmCallback={() => {
						setHelpDialogVisible(false);
						router.replace({
							pathname: "/(protected)/session/[id]",
							params: {
								id: numericId,
								index: "1",
							},
						});
					}}
					confirmCta="Got it"
				/>
			);
		}
		return null;
	};

	const RenderCollectUserDetailsDialog = () => {
		if (showCollectUserDialog) {
			return (
				<CollectUserDetailsDialog
					visible={showCollectUserDialog}
					handleClose={handlePostSubmitUserDetails}
				/>
			);
		}
		return null;
	};
	return (
		<Container maxWidth="xs">
			<Box flex={1} gap={24} padding={16} width="100%">
				{logo && (
					<Image
						source={logo}
						resizeMode="contain"
						style={{
							width: "100%",
							height: 88,
							borderRadius: 20,
							alignSelf: "center",
						}}
					/>
				)}
				<Box>
					<Text
						variant="titleLarge"
						style={{
							color: theme.colors.text,
							textAlign: "center",
						}}
					>
						Redeem Purchase
					</Text>
					<Text
						variant="bodyMedium"
						style={{
							color: theme.colors.onSurfaceVariant,
							textAlign: "center",
						}}
					>
						Enter your complete access code including the dashes
					</Text>
				</Box>
				<Box>
					<TextInput
						placeholder="XXX-XXX-XXXXXX"
						mode="outlined"
						autoFocus
						defaultValue={pathCode}
						onChangeText={handleChangeText}
						disabled={loading}
					/>
					{renderErrorState()}
				</Box>
				<Button
					mode="contained"
					onPress={handleSubmit}
					disabled={disableFab}
					loading={loading}
				>
					Submit
				</Button>
				{showError && (
					<Pressable
						onPress={handlePressContactSupport}
						style={{
							paddingBottom: 8,
						}}
					>
						<MarkdownRenderer
							variant="explanation"
							textAlign="center"
							text={redeemContactSupportTemplate}
						/>
					</Pressable>
				)}
				{showOnboarding && (
					<AuthButton
						onPress={() => {
							if (showOnboarding) {
								router.replace("/onboarding");
							}
						}}
						mode="text"
						disabled={loading}
					>
						{"Skip this step"}
					</AuthButton>
				)}
			</Box>
			<RenderSessionHelpDialog />
			<RenderPostRedeemOlxDialog />
			<RenderCollectUserDetailsDialog />
		</Container>
	);
};

export default RedeemCode;
