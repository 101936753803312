import type { Article } from "../../../../../types";

export const Article578: Article = {
	id: 578,
	rsuiteId: "749412cc-8f07-404c-8764-366f3cd0c793",
	type: "scientific article",
	title:
		"Strong Correlation Between Femoral Condyle Radius of Curvature and Anterior-Posterior Length",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=749412cc-8f07-404c-8764-366f3cd0c793&type=jpeg&name=JBJS.23.00609f1",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Strong Correlation Between Femoral Condyle Radius of
                Curvature and Anterior-Posterior Length</div>
              <div class="text subtitle" style="position: unset;">Implications for Osteochondral Allograft Matching
              </div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Saeed Jerban,
                      PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Suzanne M.
                      Tabbaa, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_36" class="annotation strong"
                          style="position: unset;">Background:</span> The radius of curvature (ROC) of the femoral
                        condyle is a factor in potential cartilage incongruities following osteochondral allograft (OCA)
                        transplantation. Accurate restoration of the chondral surface may be achievable by using
                        “best-fit” donor-recipient matching based on linear femorotibial dimensions, such as the femoral
                        condyle anterior-posterior length (APL), femoral condyle width (lateral-medial length, LML),
                        femoral hemicondyle width (HCW), and tibial plateau width (TPW), particularly if they correlate
                        well with the ROC. This study aimed to investigate the correlative relationship between
                        femorotibial dimensions and the ROC.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_37" class="annotation strong"
                          style="position: unset;">Methods:</span> Computed tomography (CT) scans from 49 patients (31
                        men 28 ± 10 years old and 18 women 27 ± 6 years old) were analyzed. Axial images were used for
                        APL and LML measurements, while coronal images were used for HCW and TPW. True sagittal images
                        of the medial femoral condyle (MFC) and lateral femoral condyle (LFC) were used to calculate
                        their individual ROCs by determining the best-fit circles along the condylar surface. Linear
                        regression models were used to determine the relationship between the femorotibial dimensions
                        and ROC. Measurements were repeated for a randomly selected subset of the data, and intraclass
                        correlation coefficients (ICCs) were calculated to investigate intra- and interobserver
                        reliability.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_38" class="annotation strong"
                          style="position: unset;">Results:</span> All femorotibial dimensions showed significant
                        correlations with the MFC and LFC ROCs (p &lt; 0.01). The ROC correlations with femorotibial
                        dimensions were found to be in the following descending order: APL (R<span
                          data-id="superscript_1" class="annotation superscript" style="position: unset;">2</span> ≥
                        0.83), LML (R<span data-id="superscript_2" class="annotation superscript"
                          style="position: unset;">2</span> ≥ 0.52), TPW (R<span data-id="superscript_3"
                          class="annotation superscript" style="position: unset;">2</span> ≥ 0.36), and HCW (R<span
                          data-id="superscript_4" class="annotation superscript" style="position: unset;">2</span> ≥
                        0.27). The intra- and interobserver reliabilities for the APL (ICC &gt; 0.98) and ROC (ICC &gt;
                        0.94) were excellent.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_39" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The ROC was strongly correlated with the APL of
                        the MFC and LFC. Donor-recipient APL matching in OCA transplantation may provide a level of
                        matching similar to that achievable by direct ROC measurements.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_40" class="annotation strong"
                          style="position: unset;">Clinical Relevance:</span> Determining the most predictive
                        femorotibial dimension for ROC restoration in the OCA matching process may improve clinical
                        outcomes, particularly for patients with large osteochondral lesions.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Large focal osteochondral lesions (&gt;2 cm<span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">2</span>) of the
                  femoral condyle can cause substantial knee pain and loss of function in the general population and
                  athletes<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">2</span></a>. Several surgical treatment options are available, including
                  autologous chondrocyte implantation (ACI), osteochondral autograft transfer system (OATS), and
                  osteochondral allograft (OCA) transplantation<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">4</span></a>. OCA transplantation has been performed for decades and has
                  demonstrated favorable patient-reported outcomes and long-term success, with survivorship of nearly
                  90% at 5 years and 75% at 10 years<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">6</span></a>. OCA transplantation offers a single-stage solution to treat
                  large focal lesions by restoring the cartilage surface and subchondral bone topography using
                  well-matched grafts from deceased donors<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">3</span></a>. The rise in OCA transplantation is attributable to both the
                  long-term success of the procedure and advancements in storage procedures at commercial tissue banks<a
                    href="" data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_10" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">7</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Several investigations have focused on the process of
                  matching an OCA to the recipient condyle and the potential impacts of the quality of the match on the
                  clinical outcomes and the survivorship rate of the graft<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_11" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_11" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">8</span></a>. OCA
                  matching aims to optimize the congruity of the contours at the cartilage surface and osteochondral
                  junction by minimizing the size, curvature, and shape differences between the graft and the recipient
                  condyle<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_12" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_12" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">10</span></a>.
                  Bernstein et al.<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">11</span></a>
                  investigated the radius of curvature (ROC) of the femoral condyle as an appropriate and isolated
                  criterion for donor-recipient matching in OCA transplantation. They reported substantially better
                  restoration of the cartilage surface by matching ROCs in comparison with the traditional matching
                  process based on femorotibial dimensions<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">11</span></a>.
                  Gursoy et al. also reported the negative impacts of donor-recipient ROC mismatches on the midterm
                  clinical outcomes<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">8</span></a>. ROC
                  matching has also been recommended for OCA transplantation in other joints<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">12</span></a> as well as for OATS<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">13</span></a>. Nevertheless, the native topography in patients with large
                  osteochondral lesions has been substantially altered, and an accurate ROC measurement is not always
                  feasible.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">The donor-recipient matching process for OCA
                  transplantation could be performed using simple femorotibial dimensions if they correlate well with
                  the femoral condyle ROC. Several femorotibial dimensions that have been previously used in the
                  literature for the OCA donor-recipient matching process are the femoral condyle anterior-posterior
                  length (APL), femoral condyle width (lateral-medial length, LML), femoral hemicondyle width (HCW), and
                  tibial plateau width (TPW)<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_18" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">17</span></a>. Du et al.<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">14</span></a>
                  reported significant positive linear correlations between the ROC and APL for both the medial femoral
                  condyle (MFC) (R<span data-id="superscript_20" class="annotation superscript"
                    style="position: unset;">2</span> = 0.56, p &lt; 0.01) and lateral femoral condyle (LFC) (R<span
                    data-id="superscript_21" class="annotation superscript" style="position: unset;">2</span> = 0.45, p
                  &lt; 0.01) using magnetic resonance imaging (MRI). However, to our knowledge, correlations of other
                  femorotibial dimensions with the ROC of a condyle have not been investigated or compared with the
                  APL-ROC correlation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">This study aimed to investigate the linear correlations
                  between femorotibial dimensions used in clinical practice for OCA matching (APL, LML, HCW, and TPW)
                  and the femoral condyle ROC along the anterior-posterior direction (i.e., sagittal plane). We
                  hypothesized that the width-related dimensions measured in axial and coronal planes (LML, HCW, and
                  TPW) would demonstrate weaker correlations with the ROC than the APL-ROC correlation. Computed
                  tomography (CT) images were used in this study because of their superior visualization of bone and
                  lower variability compared with MRI<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">18</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">This study was conducted in accordance with applicable
                  good clinical practice requirements and relevant guidelines and regulations, and was compliant with
                  the Health Insurance Portability and Accountability Act (HIPAA).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">CT Imaging
                and Inclusion and Exclusion Criteria</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Deidentified knee CT scans from 68 patients seeking
                  consultations for allograft pre-surgical planning were provided by JRF Ortho. These were the
                  consecutive CT scans provided to JRF from September 2020 until June 2022 by several medical clinics.
                  The scans were performed across 43 different imaging facilities in the United States.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Nineteen of the knee scans were excluded because of
                  missing axial image slices through the condyle. The remaining 49 CT scans were from 31 men (mean age
                  [and standard deviation], 28 ± 10 years) and 18 women (27 ± 6 years). MFC measurements were excluded
                  from 9 of the 49 data sets and LFC measurements were excluded from 5 because of exceptionally large
                  lesions that made meaningful ROC measurements challenging. Thus, a total of 40 MFCs and 44 LFCs were
                  measured. Notably, most of the included CT scans showed intact osseous surfaces. The surfaces of minor
                  osteochondral lesions and disruptions were approximated by the image processing algorithm (using
                  morphological closing) and were not expected to adversely affect the ROC measurements. Thirty, 11, 4,
                  3, and 1 of the 49 data sets were acquired with scanners made by Siemens, GE, Canon, Philips, and
                  Toshiba, respectively. The mean in-plane voxel size and slice thickness for the analyzed CT images
                  were 0.44 ± 0.09 and 1.28 ± 0.57 mm, respectively. The average effective radiation dose was 0.12 ±
                  0.012 mSv for the studied joints, as calculated from the dose-length products<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">19</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Femorotibial
                Dimension Measurement</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The APLs were measured by scrolling through the axial CT
                  images and selecting an optimal slice showing the longest distance between the most anterior and
                  posterior points of the visualized bone on the MFC and on the LFC. The selection of the optimal slice
                  was performed by a medical image analyst (S.J., a scientist with &gt;10 years of experience in image
                  processing, including CT and MRI) by visual evaluation. All femorotibial dimension measurements were
                  performed by this medical image analyst. This approach is consistent with those in the literature and
                  tissue banks<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">14</span></a>. <a
                    href="" data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figure 1-A</a> shows the schematics of the method for the APL measurement
                  of the MFC and LFC performed on a representative CT image of the knee joint in the axial plane. All
                  measurements were performed using an in-house semiautomatic computer code developed in MATLAB
                  (Mathworks).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00609f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=749412cc-8f07-404c-8764-366f3cd0c793&type=jpeg&name=JBJS.23.00609f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;">Femorotibial dimension measurements depicted on
                          the CT images of a representative knee joint (right side). <span data-id="strong_43"
                            class="annotation strong" style="position: unset;">Fig. 1-A</span> The anterior-posterior
                          lengths (APLs) of the medial femoral condyle (MFC) and lateral femoral condyle (MFC)—i.e., the
                          medial APL and lateral APL—are measured on the same axial slice. Specifically, the APL of the
                          MFC was defined as the distance between a point at the medial margin of the subchondral bone
                          of the femoral trochlea and a point at the center of the subchondral bone of the far posterior
                          MFC. Likewise, the APL of the LFC was defined as the distance between a point at the lateral
                          margin of the subchondral bone of the femoral trochlea and a point at the center of the
                          subchondral bone of the far posterior LFC. <span data-id="strong_44" class="annotation strong"
                            style="position: unset;">Fig. 1-B</span> The lateral-medial length (LML) is the width of the
                          condyle and is also measured in the axial plane. The LML was defined as the distance between
                          the cortices of the medial and lateral epicondyles. <span data-id="strong_45"
                            class="annotation strong" style="position: unset;">Fig. 1-C</span> The medial and lateral
                          hemicondyle widths (medial HCW and lateral HCW) and the tibial plateau width (TPW) are
                          measured in the coronal plane. Specifically, the TPW was defined as the distance between the
                          medial and lateral cortices of the tibial plateau within the epiphysis on the selected image.
                          Medial and lateral HCWs were measured from the cortical surfaces of the periphery to the
                          intercondylar fossa, parallel to the surface of the subchondral bone of the respective
                          condyle.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The LML (condyle width) was measured on an optimal axial
                  slice showing the longest distance between the most medial and lateral points of the visualized bone
                  in the epicondylar region. Notably, the axial CT image used for the APL measurement was often used for
                  the LML measurement as well. <a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 1-B</a> shows
                  the schematics of the LML measurement on a representative CT image of the knee joint in the axial
                  plane.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The HCW of the MFC (medial HCW) and of the LFC (lateral
                  HCW), as well as the TPW, were measured by scrolling through the coronal CT images and selecting an
                  optimal slice showing the longest distance between the most medial and lateral points of the
                  visualized bone on the tibial plateau. <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 1-C</a> shows
                  the schematics of the TPW, medial HCW, and lateral HCW measurements on a representative CT image of
                  the knee joint in the coronal plane.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">ROC
                Measurement</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">MFC and LFC ROCs were measured on true-sagittal-plane
                  images of the MFC and LFC, respectively. ROC measurements on the MFC and LFC are presented
                  schematically in <a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figures 2</a> and <a
                    href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">3</a>, respectively, for a representative knee data set. The
                  true-sagittal-plane images (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 2-B</a> and <a
                    href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">3-B</a>) were reconstructed from axial images along the plane of the APL
                  measurement for both compartments (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 2-A</a> and <a
                    href="" data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">3-A</a>). A section of the visualized osseous surface in the true sagittal
                  plane covering the anterior, distal, and posterior regions was selected by the image analyst for the
                  ROC calculation. The osseous surface of the femur was extracted using an edge-detection algorithm
                  after applying a morphological closing step to fill the minor chondral disruptions. Anterior and
                  posterior limit lines, which were assumed to represent the anterior and posterior ends of the
                  cartilage, were drawn below the intersection of the osseous surface and the epiphyseal scar line. The
                  coordinates of the pixels at the osteochondral junction were fitted to a mathematical model of a
                  circle. The best-fit circle was defined as the one with minimum deviation from the inputted
                  osteochondral junction pixels. The radius of the circle was calculated as an estimate of the ROC for
                  each condyle (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 2-C</a> and <a
                    href="" data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">3-C</a>)<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">14</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.00609f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=749412cc-8f07-404c-8764-366f3cd0c793&type=jpeg&name=JBJS.23.00609f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_46"
                            class="annotation strong" style="position: unset;">Fig. 2-A</span> The selected
                          anterior-posterior direction used to reconstruct the true sagittal plane of the medial femoral
                          condyle (MFC) in a representative knee joint. <span data-id="strong_47"
                            class="annotation strong" style="position: unset;">Fig. 2-B</span> A manually selected
                          section of the osseous surface of the MFC. OCJ = osteochondral junction. <span
                            data-id="strong_48" class="annotation strong" style="position: unset;">Fig. 2-C</span> The
                          best-fit circle derived from the selected osseous surface. Its calculated radius was used as
                          an estimation of the MFC ROC.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.00609f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=749412cc-8f07-404c-8764-366f3cd0c793&type=jpeg&name=JBJS.23.00609f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_49"
                            class="annotation strong" style="position: unset;">Fig. 3-A</span> The selected
                          anterior-posterior direction used to reconstruct the true sagittal plane of the lateral
                          femoral condyle (LFC) in a representative knee joint. <span data-id="strong_50"
                            class="annotation strong" style="position: unset;">Fig. 3-B</span> A manually selected
                          section of the osseous surface of the LFC. OCJ = osteochondral junction. <span
                            data-id="strong_51" class="annotation strong" style="position: unset;">Fig. 3-C</span> The
                          best-fit circle derived from the selected osseous surface. Its calculated radius was used as
                          an estimation of the LFC radius of curvature (ROC).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Linear regression models of the ROC of the MFC and LFC as
                  functions of femorotibial dimensions were defined to investigate these relationships and their
                  significance (according to a Student t test). The most predictive femorotibial dimension was
                  determined by comparing the obtained coefficient of determination, R<span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">2</span>, among all models. A power analysis
                  showed that 49 data sets would provide at least 80% power to detect an R<span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">2</span> of ≥0.16 between any 2 sets of
                  performed measurements in this study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">After determining the femorotibial dimension that was the
                  most predictive of (had the highest correlation with) the ROCs, additional linear regression models of
                  the ROCs as functions of combined femorotibial dimensions (e.g., ROC = β0 + β1 × APL + β2 × HCW, where
                  β0, β1, and β2 are constants) were created to investigate whether there was an optimal combination of
                  the femorotibial dimensions for predicting the ROC.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">In addition, the correlations of the most predictive
                  femorotibial dimension with the other dimensions were investigated using linear regression models to
                  determine the interdependency of these dimensions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">The measurement of the ROCs and of the most predictive
                  femorotibial dimension was repeated for 13 randomly selected scans (approximately 25% of the total
                  data set) by the initial observer (S.J.) to investigate intraobserver reliability. The same
                  measurements were also independently performed by a second observer (E.Y.C., a radiologist with &gt;15
                  years of experience, blinded to the first observer’s measurements) to investigate the interobserver
                  reliability. Intraclass correlation coefficients (ICCs) were calculated for the repeated measures.
                  According to the literature, using a 25% subset is sufficient for assessing interobserver reliability
                  in this study, because the measurements were not overly complex or sophisticated<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">20</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">In addition, correlations between measurements of the MFC
                  and LFC were investigated using linear regression models. A paired Student t test was used to examine
                  the differences.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a> presents
                  the measured femorotibial dimensions and ROCs of the MFC and LFC. On average, ROC, APL, and HCW
                  measurements were lower for the MFC than for the LFC. However, except for the ROC (p = 0.02), the
                  differences between the MFC and LFC were not significant (p = 0.39 for APL and 0.11 for HCW).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Radius of Curvature (ROC) and Femorotibial
                          Measurements for Each Condyle<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 185.334px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.87037);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th colspan="2" style="position: unset;"><span class="text" style="position: unset;">ROC <span
                            data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">APL <span data-id="emphasis_36" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">HCW <span data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">LML <span data-id="emphasis_38" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">TPW <span data-id="emphasis_39" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Medial</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Lateral</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Medial</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Lateral</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Medial</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Lateral</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">31.9 ± 2.8
                          (26.9-36.4)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">33.0 ± 2.6
                          (27.9-37.2)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">64.7 ± 5.8
                          (54.5-77.1)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">65.0 ± 5.6
                          (53.3-74.8)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">28.4 ± 3.3
                          (23.3-35.9)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30.0 ± 3.9
                          (21.4-37.1)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">82.2 ± 7.3
                          (65.6-93.7)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">75.5 ± 7.3
                          (62.6-87.4)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean ± standard deviation, with the range
                        in parentheses. ROC = radius of curvature, APL = anterior-posterior length, HCW = hemicondyle
                        width, LML = lateral-medial length, TPW = tibial plateau width.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 4</a> shows
                  the association of ROC measurements with the APL, HCW, LML, and TPW measurements of the MFC and LFC.
                  All femorotibial dimensions showed significant correlations with the ROC (p &lt; 0.01). The APL was
                  the most predictive femorotibial dimension, demonstrating the highest correlation with the ROC of the
                  MFC (R<span data-id="superscript_29" class="annotation superscript" style="position: unset;">2</span>
                  = 0.83) and LFC (R<span data-id="superscript_30" class="annotation superscript"
                    style="position: unset;">2</span> = 0.84). The ROC correlations with femorotibial dimensions were
                  found to be in the following descending order: APL (R<span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">2</span> = 0.83 and 0.84), LML (R<span
                    data-id="superscript_32" class="annotation superscript" style="position: unset;">2</span> = 0.52 and
                  0.56), TPW (R<span data-id="superscript_33" class="annotation superscript"
                    style="position: unset;">2</span> = 0.36 and 0.37), and HCW (R<span data-id="superscript_34"
                    class="annotation superscript" style="position: unset;">2</span> = 0.27 and 0.29).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.00609f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=749412cc-8f07-404c-8764-366f3cd0c793&type=jpeg&name=JBJS.23.00609f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;">Scatterplots with linear regressions of the radius
                          of curvature (ROC) of the medial and lateral femoral condyles versus the anterior-posterior
                          length (APL) (<span data-id="strong_52" class="annotation strong"
                            style="position: unset;">Fig. 4-A</span>), hemicondyle width (HCW) (<span
                            data-id="strong_53" class="annotation strong" style="position: unset;">Fig. 4-B</span>),
                          lateral-medial length (LML) (<span data-id="strong_54" class="annotation strong"
                            style="position: unset;">Fig. 4-C</span>), and tibial plateau width (TPW) (<span
                            data-id="strong_55" class="annotation strong" style="position: unset;">Fig. 4-D</span>).
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">The linear regression models of the ROCs of the MFCs and
                  LFCs as functions of the APL combined with other femorotibial dimensions (LML, TPW, and HCW) are
                  presented in <a href="" data-id="figure_reference_14"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>. The
                  adjusted coefficient of determination (R<span data-id="superscript_35" class="annotation superscript"
                    style="position: unset;">2</span>) did not improve by combining the APL with any other femorotibial
                  dimensions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Linear Regression Models of the ROCs of the MFC and
                          LFC as Functions of the APL in Combination with the HCW, LML, and TPW<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 498px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Linear
                          Model</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Medial Condyle</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Lateral Condyle</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">R<span
                            data-id="superscript_36" class="annotation superscript"
                            style="position: unset;">2</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P1
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P2
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">R<span
                            data-id="superscript_37" class="annotation superscript"
                            style="position: unset;">2</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P1
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P2
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ROC = β<span
                            data-id="subscript_1" class="annotation subscript" style="position: unset;">0</span> +
                          β<span data-id="subscript_2" class="annotation subscript" style="position: unset;">1</span> ×
                          APL</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.83</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.84</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ROC = β<span
                            data-id="subscript_3" class="annotation subscript" style="position: unset;">0</span> +
                          β<span data-id="subscript_4" class="annotation subscript" style="position: unset;">1</span> ×
                          APL + β<span data-id="subscript_5" class="annotation subscript"
                            style="position: unset;">2</span> × HCW</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.83</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.54</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.81</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.18</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ROC = β<span
                            data-id="subscript_6" class="annotation subscript" style="position: unset;">0</span> +
                          β<span data-id="subscript_7" class="annotation subscript" style="position: unset;">1</span> ×
                          APL + β<span data-id="subscript_8" class="annotation subscript"
                            style="position: unset;">2</span> × LML</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.83</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.56</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.82</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.51</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ROC = β<span
                            data-id="subscript_9" class="annotation subscript" style="position: unset;">0</span> +
                          β<span data-id="subscript_10" class="annotation subscript" style="position: unset;">1</span> ×
                          APL + β<span data-id="subscript_11" class="annotation subscript"
                            style="position: unset;">2</span> × TPW</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.83</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.55</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.80</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.77</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">ROC = radius of curvature, MFC and LFC = medial and lateral femoral
                        condyle, APL = anterior-posterior length, HCW = hemicondyle width, LML = lateral-medial length,
                        TPW = tibial plateau width. The R<span data-id="superscript_38" class="annotation superscript"
                          style="position: unset;">2</span> value for each model indicates the percentage of the
                        variability in ROC that is explained by the combination of femorotibial dimensions (e.g., APL
                        and HCW). The P1 value indicates the significance level of the ROC-APL relationship independent
                        of the LML, TPW, or HCW. The P2 value indicates the significance level of the ROC relationship
                        with the LML, TPW, or HCW independent of the APL.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_15"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>
                  presents the linear correlations of the APL of the MFC and LFC with the HCW, LML, and TPW, which were
                  all significant. The APL correlations with the LML (R<span data-id="superscript_39"
                    class="annotation superscript" style="position: unset;">2</span> = 0.67) were higher than those with
                  the HCW (R<span data-id="superscript_40" class="annotation superscript"
                    style="position: unset;">2</span> = 0.26 and 0.32) and TPW (R<span data-id="superscript_41"
                    class="annotation superscript" style="position: unset;">2</span> = 0.45 and 0.50).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Linear Correlations Between the Studied
                          Femorotibial Dimensions of the MFC and LFC<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 406px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">HCW</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">LML</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">TPW</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">R<span
                            data-id="superscript_42" class="annotation superscript"
                            style="position: unset;">2</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">R<span
                            data-id="superscript_43" class="annotation superscript"
                            style="position: unset;">2</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">R<span
                            data-id="superscript_44" class="annotation superscript"
                            style="position: unset;">2</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MFC</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> APL</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.26</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.67</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.45</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> HCW</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.36</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.46</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> LML</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.71</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">LFC</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> APL</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.32</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.67</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.50</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> HCW</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.53</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.66</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> LML</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.65</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">MFC and LFC = medial and lateral femoral condyle, HCW = hemicondyle
                        width, LML = lateral-medial length, TPW = tibial plateau width, APL = anterior-posterior
                        length.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The intra- and interobserver reliabilities for the APL and
                  ROC were excellent (ICC &gt; 0.98 for APL and ICC &gt; 0.94 for ROC, as shown in the Appendix)<a
                    href="" data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_45" class="annotation superscript"
                      style="position: unset;">20</span></a>. The ROC measurement depends largely on the selected region
                  of the osseous surface on the condyle, whereas there is no perfect agreement between observers on the
                  limit selection for the anterior and posterior ends of the cartilage. As expected, therefore, higher
                  intra- and interobserver reliability were found for the APL measurements than for the ROC.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_16"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>
                  presents the correlations between the ROC, APL, and HCW of the MFC and those of the LFC. The
                  medial-lateral correlations for the ROC, APL, and HCW were significant.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Linear Correlations Between the ROC, APL, and HCW of
                          the MFC and Those of the LFC<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 182px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">ROC</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">APL</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">HCW</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">R<span
                            data-id="superscript_46" class="annotation superscript"
                            style="position: unset;">2</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">R<span
                            data-id="superscript_47" class="annotation superscript"
                            style="position: unset;">2</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">R<span
                            data-id="superscript_48" class="annotation superscript"
                            style="position: unset;">2</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medial-lateral
                          correlation</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.64</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.77</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.87</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">ROC = radius of curvature, APL = anterior-posterior length, HCW =
                        hemicondyle width, MFC and LFC = medial and lateral femoral condyle.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Our study demonstrates that in both the MFC and LFC, the
                  ROC variations between patients can be predicted by the APL measurements of the knee. Therefore,
                  matching of the APL between the recipient and donor in OCA transplantation may provide a level of
                  matching similar to that achievable by matching on the basis of direct ROC measurements<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">11</span></a>. The APL matching would be particularly useful for patients
                  with large osteochondral lesions, in whom a precise delineation of the femoral condylar or
                  osteochondral surface is not feasible and an accurate ROC measurement is thus precluded. Moreover, a
                  consistent APL measurement between recipients and donors is much simpler to achieve than a consistent
                  ROC measurement, which would require more sophisticated resources.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">As hypothesized, the ROC in the anterior-posterior
                  direction (i.e., sagittal plane) of a condyle showed weaker correlations with the width-related
                  measures (the LML, TPW, and HCW) than with the APL. Width-related measures are likely to show stronger
                  correlations with medial-lateral ROCs, which is yet to be investigated. Among the width-related
                  dimensions, only the LML was measured on axial images between true anatomical sites (medial and
                  lateral epicondyles) that should not be affected by the positioning of the joint in the scanner. In
                  contrast, the HCWs and TPW were measured on coronal CT images that might not represent the true
                  coronal plane for all of the provided data sets. This likely led to the weaker correlations of the ROC
                  with the HCW and TPW than with the LML.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Combining the APL with other widely used femorotibial
                  dimensions (the LML, TPW, and HCW) did not improve the correlation strengths for the ROC prediction.
                  This was likely due to the significant interdependency between the femorotibial dimensions in the
                  studied knee joints. Therefore, using the APL alone, rather than in combination with other
                  femorotibial dimensions, may be adequate for achieving ROC restoration in OCA transplantation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Particular attention should be paid to standardization of
                  the APL measurement before promoting its widespread utilization for research investigations and in
                  clinical practice. Our study measured the APLs of the MFC and LFC separately, along with their true
                  anterior-posterior dimensions (i.e., in true sagittal plane), similar to what was done by Du et al.<a
                    href="" data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                      style="position: unset;">14</span></a>. This is in contrast to other studies that used an
                  identical APL for both the MFC and LFC, measured along the global anterior-posterior direction of the
                  knee joint<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_51" class="annotation superscript" style="position: unset;">21</span></a>.
                  The controversial outcomes of using APL measurements for donor-recipient matching<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_52" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_52" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_52" class="annotation superscript" style="position: unset;">21</span></a> are
                  likely due to the lack of literature regarding the standardization of the technique.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Orthotopic OCA transplantation (from an MFC to an MFC or
                  an LFC to an LFC) has traditionally been the most effective approach for restoring focal chondral and
                  osteochondral lesions. More recent research in OCA transplantation suggests that condyle-specific
                  matching is unnecessary, particularly for single-plug transplantation<a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_53" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_53" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_53" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_53" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_53"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_53"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">22</span></a>. The current study found a strong correlation between the
                  APL and ROC in both the MFC and LFC, which implies that selection of a plug from a different condyle
                  or a contralateral knee, based on matching of the APL value, can also provide a reasonable ROC match
                  between donor and recipient. Conflicting results have been reported in the literature, ranging from
                  significant<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_54" class="annotation superscript" style="position: unset;">23</span></a> to
                  nonsignificant<a href="" data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_55" class="annotation superscript" style="position: unset;">24</span></a> ROC
                  correlations between the MFC and LFC, depending on the methods used for the ROC measurements.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">As accuracy was paramount for this anthropometric study,
                  all measurements were performed using CT scans, which have superior visualization of bone and lower
                  variability compared with MRI<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_56" class="annotation superscript" style="position: unset;">18</span></a>.
                  For instance, there is no opportunity to reconstruct the images along desired planes because of the
                  thick sections used in typical MRI protocols. The average CT scan radiation dose in this study (0.12
                  mSv) is substantially lower than the average annual background radiation globally (&gt;1 mSv) or the
                  radiation exposure from a typical hip or chest CT scan<a href="" data-id="citation_reference_47"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_57" class="annotation superscript" style="position: unset;">19</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">There are limitations to this study. First, the sample
                  size was limited (n = 49); therefore, future investigations with larger sample sizes and more varied
                  data sets are required to confirm the presented results. Second, the CT scans were performed across 43
                  different imaging facilities using instruments from 5 different manufacturers. The variations in the
                  scanning setup (in particular, the slice thickness and in-plane resolution) might have reduced the
                  strengths of the correlations that we observed. Third, although CT scans provide the most accurate
                  visualization of bone in the femoral condyle and consistent ROC and APL measurements, this modality
                  may not be available in all clinical cases. Alternatively, improvements in MRI technology, such as
                  3-dimensional imaging<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_58" class="annotation superscript" style="position: unset;">25</span></a> or
                  CT-like contrast<a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_59" class="annotation superscript" style="position: unset;">26</span></a>,
                  may obviate the need for CT. It should be noted that most clinicians are using MRI preoperatively to
                  assess the chondral lesion and concomitant pathology, as MRI provides excellent image contrast for
                  evaluating most tissues in the knee joint. Therefore, the investigated correlations between
                  femorotibial dimensions and ROC should also be assessed using MRI in future studies. Fourth, the ROC
                  is not constant over the femoral condyle; rather, it varies among the posterior, distal, and anterior
                  portions of the MFC and LFC<a href="" data-id="citation_reference_50"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_60" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_60"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_60" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_60"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_60" class="annotation superscript"
                      style="position: unset;">31</span></a>. Future studies are necessary to investigate the potential
                  relationships between the local ROCs and femorotibial dimensions<a href=""
                    data-id="citation_reference_53" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_61" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_61"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_61" class="annotation superscript"
                      style="position: unset;">30</span></a>. Fifth, significant gender- and ethnicity-related
                  differences in condylar anatomy have been reported in the literature<a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_62" class="annotation superscript"
                      style="position: unset;">31</span></a><span data-id="superscript_62"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_62" class="annotation superscript"
                      style="position: unset;">35</span></a>, and the correlations between femorotibial dimensions and
                  the ROC may depend on the subgroup in which the analysis is performed. Sixth, successful OCA
                  transplantation requires restoration of not only the chondral surface but also the chondral thickness,
                  and it would therefore be preferable to also match the cartilage thickness between the donor and
                  recipient. Notably, the relationship between the osseous ROC and chondral ROC varies among the
                  posterior, distal, and anterior portions of the condyle.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">This study evaluated the linear correlations between the
                  femoral condyle ROCs and a set of femorotibial dimensions used widely in the literature for
                  donor-recipient OCA matching. The primary finding of this study was that the APL was the most
                  predictive femorotibial dimension, demonstrating strong correlations with the ROC. The ROC variations
                  in both the MFC and LFC between patients can therefore be well predicted by the APL measurements of
                  the knee. Donor-recipient APL matching in OCA transplantation is likely to provide a level of matching
                  similar to that achievable by direct ROC measurements.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_2" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I3" target="_blank" data-id="link_3" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I3</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Flanigan DC</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Harris JD</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Trinh TQ</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Siston RA</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Brophy RH</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Prevalence of chondral defects
                  in athletes’ knees: a systematic review</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Med Sci Sports Exerc.</span> 2010 Oct;42(10):1795-801.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20of%20chondral%20defects%20in%20athletes%E2%80%99%20knees%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22DC%20Flanigan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Falah M</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Nierenberg G</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Soudry M</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Hayden M</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Volpin G</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Treatment of articular cartilage
                  lesions of the knee</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Int Orthop.</span> 2010 Jun;34(5):621-30.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treatment%20of%20articular%20cartilage%20lesions%20of%20the%20knee&as_occt=title&as_sauthors=%20%22M%20Falah%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Lai WC</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Bohlen HL</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Fackler NP</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Wang D</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Osteochondral allografts in knee
                  surgery: narrative review of evidence to date</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Orthop Res Rev.</span> 2022 Aug
                11;14:263-74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteochondral%20allografts%20in%20knee%20surgery%3A%20narrative%20review%20of%20evidence%20to%20date&as_occt=title&as_sauthors=%20%22WC%20Lai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Görtz S</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Tabbaa SM</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Jones DG</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Polousky JD</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Crawford DC</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Bugbee WD</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Cole BJ</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Farr J</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Fleischli JE</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Getgood A</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Gomoll AH</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Gross AE</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Krych AJ</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Lattermann C</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Mandelbaum BR</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Mandt PR</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Mirzayan R</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Mologne TS</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Provencher MT</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Rodeo SA</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Safir O</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Strauss ED</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Wahl CJ</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Williams RJ</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Yanke AB</span>; MOCA Committee.
                <span data-id="strong_4" class="annotation strong" style="position: unset;">Metrics of osteochondral
                  allografts (MOCA) group consensus statements on the use of viable osteochondral allograft</span>.
                <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Orthop J Sports
                  Med.</span> 2021 Mar 23;9(3):2325967120983604.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Metrics%20of%20osteochondral%20allografts%20(MOCA)%20group%20consensus%20statements%20on%20the%20use%20of%20viable%20osteochondral%20allograft&as_occt=title&as_sauthors=%20%22S%20G%C3%B6rtz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Bugbee WD</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Convery FR</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Osteochondral allograft
                  transplantation</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Clin Sports Med.</span> 1999 Jan;18(1):67-75.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteochondral%20allograft%20transplantation&as_occt=title&as_sauthors=%20%22WD%20Bugbee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Briggs DT</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Sadr KN</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Pulido PA</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Bugbee WD</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">The use of osteochondral
                  allograft transplantation for primary treatment of cartilage lesions in the knee</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Cartilage.</span> 2015
                Oct;6(4):203-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20use%20of%20osteochondral%20allograft%20transplantation%20for%20primary%20treatment%20of%20cartilage%20lesions%20in%20the%20knee&as_occt=title&as_sauthors=%20%22DT%20Briggs%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Torrie AM</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Kesler WW</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Elkin J</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Gallo RA</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Osteochondral allograft</span>.
                <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Curr Rev Musculoskelet
                  Med.</span> 2015 Dec;8(4):413-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteochondral%20allograft&as_occt=title&as_sauthors=%20%22AM%20Torrie%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Gursoy S</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Simsek ME</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Akkaya M</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Kaya O</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Bozkurt M</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Local curvature mismatch may
                  worsen the midterm functional outcomes of osteochondral allograft transplantation</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2021 Sep;29(9):2948-57.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Local%20curvature%20mismatch%20may%20worsen%20the%20midterm%20functional%20outcomes%20of%20osteochondral%20allograft%20transplantation&as_occt=title&as_sauthors=%20%22S%20Gursoy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Wang D</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Jones KJ</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Eliasberg CD</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Pais MD</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Rodeo SA</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Williams RJ 3rd</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Condyle-specific matching does
                  not improve midterm clinical outcomes of osteochondral allograft transplantation in the knee</span>.
                <span data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2017 Oct 4;99(19):1614-20.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1431080" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Koh JL</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Wirsing K</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Lautenschlager E</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Zhang LO</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">The effect of graft height
                  mismatch on contact pressure following osteochondral grafting: a biomechanical study</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2004 Mar;32(2):317-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20graft%20height%20mismatch%20on%20contact%20pressure%20following%20osteochondral%20grafting%3A%20a%20biomechanical%20study&as_occt=title&as_sauthors=%20%22JL%20Koh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Bernstein DT</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> O’Neill CA</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Kim RS</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Jones HL</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Noble PC</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Harris JD</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> McCulloch PC</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Osteochondral allograft
                  donor-host matching by the femoral condyle radius of curvature</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2017
                Feb;45(2):403-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteochondral%20allograft%20donor-host%20matching%20by%20the%20femoral%20condyle%20radius%20of%20curvature&as_occt=title&as_sauthors=%20%22DT%20Bernstein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Decker MM</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Strohmeyer GC</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Wood JP</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Hatch GM</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Qualls CR</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Treme GP</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Benson EC</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Distal tibia allograft for
                  glenohumeral instability: does radius of curvature match?</span><span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2016
                Sep;25(9):1542-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Distal%20tibia%20allograft%20for%20glenohumeral%20instability%3A%20does%20radius%20of%20curvature%20match%3F&as_occt=title&as_sauthors=%20%22MM%20Decker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Massey PA</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Lowery MT</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Houk G</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> McClary KN</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Barton RS</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Solitro GF</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Ideal donor site for
                  osteochondral autografting of the distal femur using radius of curvature: a 3-dimensional
                  high-resolution scanner comparison</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Cartilage.</span> 2021 Dec;13(1_suppl)(suppl):928S-36S.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ideal%20donor%20site%20for%20osteochondral%20autografting%20of%20the%20distal%20femur%20using%20radius%20of%20curvature%3A%20a%203-dimensional%20high-resolution%20scanner%20comparison&as_occt=title&as_sauthors=%20%22PA%20Massey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Du PZ</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Markolf KL</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Levine BD</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> McAllister DR</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Jones KJ</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Differences in the radius of
                  curvature between femoral condyles: Implications for osteochondral allograft matching</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2018 Aug 1;100(15):1326-31.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1749440" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Mologne TS</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Cory E</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Hansen BC</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Naso AN</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Chang N</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Murphy MM</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Provencher MT</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Bugbee WD</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Sah RL</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Osteochondral allograft
                  transplant to the medial femoral condyle using a medial or lateral femoral condyle allograft: is there
                  a difference in graft sources?</span><span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2014 Sep;42(9):2205-13.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteochondral%20allograft%20transplant%20to%20the%20medial%20femoral%20condyle%20using%20a%20medial%20or%20lateral%20femoral%20condyle%20allograft%3A%20is%20there%20a%20difference%20in%20graft%20sources%3F&as_occt=title&as_sauthors=%20%22TS%20Mologne%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Yanke AB</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Urita A</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Shin JJ</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Cvetanovich GL</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Moran EK</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Bach BR Jr</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Cole BJ</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Inoue N</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Verma NN</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Topographic analysis of the
                  distal femoral condyle articular cartilage surface: adequacy of the graft from opposite condyles of
                  the same or different size for the osteochondral allograft transplantation</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Cartilage.</span> 2019
                Apr;10(2):205-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Topographic%20analysis%20of%20the%20distal%20femoral%20condyle%20articular%20cartilage%20surface%3A%20adequacy%20of%20the%20graft%20from%20opposite%20condyles%20of%20the%20same%20or%20different%20size%20for%20the%20osteochondral%20allograft%20transplantation&as_occt=title&as_sauthors=%20%22AB%20Yanke%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Urita A</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Cvetanovich GL</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Madden BT</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Verma NN</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Inoue N</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Cole BJ</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Yanke AB</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Topographic matching of
                  osteochondral allograft transplantation using lateral femoral condyle for the treatment of medial
                  femoral condyle lesions: a computer-simulated model study</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2018
                Nov;34(11):3033-42.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Topographic%20matching%20of%20osteochondral%20allograft%20transplantation%20using%20lateral%20femoral%20condyle%20for%20the%20treatment%20of%20medial%20femoral%20condyle%20lesions%3A%20a%20computer-simulated%20model%20study&as_occt=title&as_sauthors=%20%22A%20Urita%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Florkow MC</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Willemsen K</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Mascarenhas VV</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Oei EHG</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> van Stralen M</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Seevinck PR</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Magnetic resonance imaging
                  versus computed tomography for three‐dimensional bone imaging of musculoskeletal pathologies: a
                  review</span>. <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Magn
                  Reson Imaging.</span> 2022 Jul;56(1):11-34.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Magnetic%20resonance%20imaging%20versus%20computed%20tomography%20for%20three%E2%80%90dimensional%20bone%20imaging%20of%20musculoskeletal%20pathologies%3A%20a%20review&as_occt=title&as_sauthors=%20%22MC%20Florkow%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Saltybaeva N</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Jafari ME</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Hupfer M</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Kalender WA</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Estimates of effective dose for
                  CT scans of the lower extremities</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">Radiology.</span> 2014 Oct;273(1):153-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Estimates%20of%20effective%20dose%20for%20CT%20scans%20of%20the%20lower%20extremities&as_occt=title&as_sauthors=%20%22N%20Saltybaeva%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Koo TK</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Li MY</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">A Guideline of Selecting and
                  Reporting Intraclass Correlation Coefficients for Reliability Research</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">J Chiropr Med.</span> 2016
                Jun;15(2):155-63.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Guideline%20of%20Selecting%20and%20Reporting%20Intraclass%20Correlation%20Coefficients%20for%20Reliability%20Research&as_occt=title&as_sauthors=%20%22TK%20Koo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Wang D</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Coxe FR</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Balazs GC</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Chang B</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Jones KJ</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Rodeo SA</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Williams RJ 3rd</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Graft-recipient anteroposterior
                  mismatch does not affect the midterm clinical outcomes of osteochondral allograft transplantation of
                  the femoral condyle</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2018 Aug;46(10):2441-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Graft-recipient%20anteroposterior%20mismatch%20does%20not%20affect%20the%20midterm%20clinical%20outcomes%20of%20osteochondral%20allograft%20transplantation%20of%20the%20femoral%20condyle&as_occt=title&as_sauthors=%20%22D%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Salka N</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Grant JA</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Contralateral lateral femoral
                  condyle allografts provide an acceptable surface match for simulated classic osteochondritis dissecans
                  lesions of the medial femoral condyle</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Orthop J Sports Med.</span> 2020 Jan 28;8(1):2325967119898413.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Contralateral%20lateral%20femoral%20condyle%20allografts%20provide%20an%20acceptable%20surface%20match%20for%20simulated%20classic%20osteochondritis%20dissecans%20lesions%20of%20the%20medial%20femoral%20condyle&as_occt=title&as_sauthors=%20%22N%20Salka%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Howell SM</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Howell SJ</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Hull ML</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Assessment of the radii of the
                  medial and lateral femoral condyles in varus and valgus knees with osteoarthritis</span>. J Bone Joint
                Surg Am. 2010;92(1):98-104.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Assessment%20of%20the%20radii%20of%20the%20medial%20and%20lateral%20femoral%20condyles%20in%20varus%20and%20valgus%20knees%20with%20osteoarthritis&as_occt=title&as_sauthors=%20%22SM%20Howell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Kosel J</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Giouroudi I</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Scheffer C</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Dillon E</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Erasmus P</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Anatomical study of the radius
                  and center of curvature of the distal femoral condyle</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">J Biomech Eng.</span> 2010
                Sep;132(9):091002.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anatomical%20study%20of%20the%20radius%20and%20center%20of%20curvature%20of%20the%20distal%20femoral%20condyle&as_occt=title&as_sauthors=%20%22J%20Kosel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;">.<span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Li CQ</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Chen W</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Rosenberg JK</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Beatty PJ</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Kijowski R</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Hargreaves BA</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Busse RF</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Gold GE</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Optimizing isotropic
                  three-dimensional fast spin-echo methods for imaging the knee</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">J Magn Reson Imaging.</span> 2014
                Jun;39(6):1417-25.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Optimizing%20isotropic%20three-dimensional%20fast%20spin-echo%20methods%20for%20imaging%20the%20knee&as_occt=title&as_sauthors=%20%22CQ%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_152" class="annotation" style="position: unset;"> de Mello RAF</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Ma YJ</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Ashir A</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Jerban S</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Hoenecke H</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Carl M</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Du J</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Chang EY</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Three-Dimensional Zero Echo
                  Time Magnetic Resonance Imaging Versus 3-Dimensional Computed Tomography for Glenoid Bone
                  Assessment</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2020 Sep;36(9):2391-400.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Three-Dimensional%20Zero%20Echo%20Time%20Magnetic%20Resonance%20Imaging%20Versus%203-Dimensional%20Computed%20Tomography%20for%20Glenoid%20Bone%20Assessment&as_occt=title&as_sauthors=%20%22RAF%20de%20Mello%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Röstlund T</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Carlsson L</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Albrektsson B</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Albrektsson T</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Morphometrical studies of human
                  femoral condyles</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">J Biomed Eng.</span> 1989 Nov;11(6):442-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Morphometrical%20studies%20of%20human%20femoral%20condyles&as_occt=title&as_sauthors=%20%22T%20R%C3%B6stlund%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Elias SG</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Freeman MAR</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Gokcay EI</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">A correlative study of the
                  geometry and anatomy of the distal femur</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 1990
                Nov;260(260):98-103.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20correlative%20study%20of%20the%20geometry%20and%20anatomy%20of%20the%20distal%20femur&as_occt=title&as_sauthors=%20%22SG%20Elias%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Nuño N</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Ahmed AM</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Three-dimensional morphometry
                  of the femoral condyles</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Clin Biomech (Bristol, Avon).</span> 2003 Dec;18(10):924-32.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Three-dimensional%20morphometry%20of%20the%20femoral%20condyles&as_occt=title&as_sauthors=%20%22N%20Nu%C3%B1o%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Monk AP</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Choji K</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> O’Connor JJ</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Goodfellow JW</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">The shape of the distal femur:
                  a geometrical study using MRI</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2014 Dec;96-B(12):1623-30.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20shape%20of%20the%20distal%20femur%3A%20a%20geometrical%20study%20using%20MRI&as_occt=title&as_sauthors=%20%22AP%20Monk%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Yue B</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Varadarajan KM</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Ai S</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Tang T</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Rubash HE</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Li G</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Gender differences in the knees
                  of Chinese population</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2011 Jan;19(1):80-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Gender%20differences%20in%20the%20knees%20of%20Chinese%20population&as_occt=title&as_sauthors=%20%22B%20Yue%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Li P</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Tsai TY</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Li JS</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Wang S</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Kwon YM</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Rubash HE</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Li G</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Gender analysis of the anterior
                  femoral condyle geometry of the knee</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Knee.</span> 2014 Mar;21(2):529-33.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Gender%20analysis%20of%20the%20anterior%20femoral%20condyle%20geometry%20of%20the%20knee&as_occt=title&as_sauthors=%20%22P%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Asseln M</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Hänisch C</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Schick F</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Radermacher K</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Gender differences in knee
                  morphology and the prospects for implant design in total knee replacement</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Knee.</span> 2018
                Aug;25(4):545-58.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Gender%20differences%20in%20knee%20morphology%20and%20the%20prospects%20for%20implant%20design%20in%20total%20knee%20replacement&as_occt=title&as_sauthors=%20%22M%20Asseln%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Mahfouz M</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Abdel Fatah EE</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Bowers LS</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Scuderi G</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Three-dimensional morphology of
                  the knee reveals ethnic differences</span>. <span data-id="emphasis_33" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2012 Jan;470(1):172-85.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Three-dimensional%20morphology%20of%20the%20knee%20reveals%20ethnic%20differences&as_occt=title&as_sauthors=%20%22M%20Mahfouz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Koh YG</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Nam JH</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Chung HS</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Kim HJ</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Baek C</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Kang KT</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Gender difference exists in
                  sagittal curvature of the distal femoral condyle morphology for osteoarthritic population</span>.
                <span data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Knee Surg Sports
                  Traumatol Arthrosc.</span> 2020 Dec;28(12):3740-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Gender%20difference%20exists%20in%20sagittal%20curvature%20of%20the%20distal%20femoral%20condyle%20morphology%20for%20osteoarthritic%20population&as_occt=title&as_sauthors=%20%22YG%20Koh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;749412cc-8f07-404c-8764-366f3cd0c793&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=749412cc-8f07-404c-8764-366f3cd0c793&type=supplement&name=12309"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D749412cc-8f07-404c-8764-366f3cd0c793%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2152</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00609</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">May 9, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;749412cc-8f07-404c-8764-366f3cd0c793&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=749412cc-8f07-404c-8764-366f3cd0c793&type=pdf&name=JBJS.23.00609.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=749412cc-8f07-404c-8764-366f3cd0c793&type=pdf&name=JBJS.23.00609.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_41"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This study was funded
                          by Joint Restoration Foundation (JRF) Ortho. The authors were not affiliated with JRF Ortho,
                          and JRF Ortho was not involved in the design of the study, data analysis, and writing of the
                          article. The <span data-id="strong_42" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I2"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I2</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;749412cc-8f07-404c-8764-366f3cd0c793&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=749412cc-8f07-404c-8764-366f3cd0c793&type=zip&name=JBJS.23.00609.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Saeed Jerban, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:s.jerban@gmail.com" class="" style="position: unset;">s.jerban@gmail.com</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6450-2892" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6450-2892</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Suzanne M. Tabbaa, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sara Pearson, PhD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0007-0299-7165" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0007-0299-7165</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Paul E. Caldwell, III, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0007-4339-5662" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0007-4339-5662</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kristofer J. Jones, MD<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0513-0475" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0513-0475</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Eric Y. Chang, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Radiology, University of California San Diego, La Jolla,
                California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, University of California San Francisco, San
                Francisco, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Orthopaedic Research of Virginia, Richmond, Virginia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Tuckahoe Orthopaedic Associates, Richmond, Virginia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, University of California Los Angeles, Los
                Angeles, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Radiology Service, VA San Diego Healthcare System, San Diego, California</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 28365.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
