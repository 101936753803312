import { z } from "zod";

const childSchema = z.object({
	id: z.string(),
	stem: z.string(),
	isCorrect: z.boolean(),
	explanation: z.string().nullable(),
});

const metadataSourceSchema = z.object({
	doi: z.string(),
	url: z.string().url(),
	title: z.string(),
	author: z.string(),
	article_type: z.string(),
	published_date: z.string(),
});

const evaluationRunSchema = z.object({
	stem: z.object({
		score: z.number(),
		reasoning: z.string(),
	}),
	choices: z.object({
		score: z.number(),
		reasoning: z.string(),
	}),
	overall: z.object({
		score: z.number(),
		reasoning: z.string(),
	}),
	revision: z.object({
		stem: z.string(),
		choices: z.array(
			z.object({
				text: z.string(),
				correct: z.boolean(),
			}),
		),
		reasoning: z.string(),
		explanation: z.string(),
	}),
	explanation: z
		.object({
			score: z.number(),
			reasoning: z.string(),
		})
		.optional(),
});

const mediaSchema = z.object({
	id: z.number(),
	url: z.string().url(),
	title: z.string(),
	metadata: z
		.object({
			media_type_description: z.string(),
		})
		.optional(),
});
export const itemSchema = z.object({
	id: z.number(),
	versionId: z.number(),
	projectId: z.number(),
	organizationId: z.number(),
	schemaId: z.number().nullable(),
	tags: z.array(
		z.object({
			id: z.number(),
			label: z.string(),
		}),
	),
	data: z.object({
		exam: z.string(),
		stem: z.string(),
		variant: z.string(),
		children: z.array(childSchema),
		difficulty: z.string(),
	}),
	type: z.enum(["QUESTION"]),
	media: z.array(mediaSchema).optional(),
	accessType: z.enum(["PREMIUM", "FREE"]).nullable(),
	shuffle: z.boolean().nullable(),
	metadata: z.object({
		generatedType: z.enum(["GENERATED", "VARIANT"]).nullable(),
		source: metadataSourceSchema,
		diagnosis: z.string(),
		population: z.string(),
		ic_original: z.object({
			id: z.string(),
			link: z.string().url(),
			published_at: z.string(),
		}),
		presentation: z.string(),
		review_status: z.string(),
		decision_focus: z.string(),
		evaluation_run: evaluationRunSchema,
		disease_prevalence: z.string(),
		evaluation_quality: z.number(),
		speciality_primary: z.string(),
		speciality_secondary: z.string().nullable(),
		discriminating_options: z.string(),
	}),
	status: z.enum(["ARCHIVED", "IN_PROGRESS", "PUBLISHED", "APPROVED"]),
	createdAt: z.string(),
	updatedAt: z.string(),
	createdBy: z.string(),
	updatedBy: z.string().nullable(),
	isDraft: z.boolean(),
	isDeleted: z.boolean(),
	generatedBy: z.string(),
	assignedTo: z.number().nullable(),
	publishedAt: z.string().nullable(),
	totalAttempts: z.string(),
	completedAt: z.string().nullable(),
	numAttempts: z.number(),
	selectedChoiceIds: z.array(z.number()).nullable(),
	timezone: z.string().nullable(),
});

export type ItemData = z.infer<typeof itemSchema>;
