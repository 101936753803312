/* ----------------- React --------------- */
import { useState } from "react";

/* ----------------- Hooks --------------- */
import { useExamContext, useExams } from "@features/exam";

import { formatDate } from "@helpers/date";
import { getShowStudyPackViews } from "@helpers/exams";
import { isWeb } from "@helpers/platform";
import { useAppStore } from "@hooks/useAppStore";
/* ----------------- Helpers --------------- */
import { getPremiumContext } from "@memorang/helpers";

export const useMoreSettings = () => {
	const {
		currentExamContext: { examIcon, examName, examDate },
	} = useExamContext();

	const examEnabled = useAppStore((store) => store.exam.enabled);
	const storeEnabled = useAppStore((store) => store.store.enabled);

	const [showExamsFilterDialog, setShowExamsFilterDialog] = useState(false);

	const toggleShowExamsFilterDialog = () => {
		setShowExamsFilterDialog((prev) => !prev);
	};

	const [showDatePicker, setShowDatePicker] = useState(false);

	const toggleShowDatePicker = () => {
		setShowDatePicker((prev) => !prev);
	};

	const { groupedExams } = useExams();

	const exams = groupedExams?.flatMap(({ data }) => data);

	const purchasedExams = exams?.filter(({ exam: { bundles } }) => {
		return bundles.find((item) => {
			const { hasAccess } = getPremiumContext(item.metadata);
			return hasAccess;
		});
	});

	const purchasedProducts = purchasedExams?.flatMap(({ exam }) =>
		exam.bundles.filter((item) => {
			return (
				getPremiumContext(item.metadata).hasAccess &&
				(item.bundle.bundleType === "PRACTICE_TEST"
					? isWeb
					: getShowStudyPackViews(item.bundle.id))
			);
		}),
	);

	const showSubscriptionSection = purchasedProducts.length > 0 || storeEnabled;
	const moreSettingsSections = [
		{
			sectionTitle: "About You",
			data: [
				{
					icon: "account-outline" as const,
					title: "Profile",
					rightCta: "View",
					href: "/profile",
					type: "profile" as const,
				},
				// {
				// 	thumbnail: userIcon,
				// 	title: "Change avatar",
				// 	type: "change-avatar" as const,
				// 	rightIcon: "account-edit-outline" as const,
				// },
			],
		},
		...(examEnabled
			? [
					{
						sectionTitle: "Your Exam",
						data: [
							{
								thumbnail: examIcon,
								title: examName,
								rightCta: "Switch",
								type: "switch-exam" as const,
							},
							{
								icon: "calendar-outline" as const,
								type: "change-date" as const,
								title: examDate || "Select date",
								rightCta: "Update",
							},
						],
					},
				]
			: []),
		...(showSubscriptionSection
			? [
					{
						sectionTitle: "Your Subscriptions",
						data: [
							...(storeEnabled
								? [
										{
											icon: "gift-outline" as const,
											title: "Redeem Purchase",
											type: "redeem" as const,
											href: "/redeem?step=redeem",
										},
									]
								: []),
							...(purchasedProducts.length
								? purchasedProducts.map(
										({
											bundle: {
												name,
												icon: { url },
												id,
											},
											metadata,
										}) => {
											const endDate = metadata.subscription?.endDate;
											return {
												title: name,
												subtitle: endDate
													? `Expires in ${formatDate(endDate)}`
													: "",
												rightCta: "Extend",
												type: "subscription" as const,
												thumbnail: url,
												id: id,
											};
										},
									)
								: []),
						],
					},
				]
			: []),
	];
	return {
		moreSettingsSections,
		toggleShowDatePicker,
		showDatePicker,
		showExamsFilterDialog,
		toggleShowExamsFilterDialog,
		purchasedProducts,
	};
};
