import { isAndroid } from "@helpers/platform";
import { useAppStore } from "@hooks/useAppStore";
import {
	Box,
	MarkdownRenderer,
	ScrollContainer,
	useDevice,
} from "@memorang/ui";
import { CustomAppbar } from "components/index";
import { router, useLocalSearchParams } from "expo-router";
import { Button } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { disclaimerMarkdown } from "../constants";

const DisclaimerView = ({ fromOnboarding }: { fromOnboarding?: boolean }) => {
	const { companyName } = useAppStore((state) => ({
		companyName: state.companyName,
	}));

	const showBack = useLocalSearchParams().showBack || "true";
	const title = `About ${companyName} and CME`;

	const { isMobile } = useDevice();
	const insets = useSafeAreaInsets();
	return (
		<>
			<CustomAppbar
				showCommandBar={false}
				showHambugerMenu
				mode="medium"
				align="flex-start"
				back={showBack === "true"}
				showSettings={false}
				showUserMenu={false}
				options={{
					headerTitle: title,
					subtitle: isMobile ? undefined : "Read and accept the disclaimer",
				}}
			/>
			<Box flex={1} justifyContent="space-between">
				<ScrollContainer
					maxWidth="sm"
					contentContainerStyle={{
						padding: 16,
					}}
				>
					<MarkdownRenderer text={disclaimerMarkdown} />
				</ScrollContainer>
				<Box padding={16} paddingBottom={isAndroid ? 16 : insets.bottom}>
					<Button
						mode="contained"
						style={{
							alignSelf: "center",
							minWidth: isMobile ? "100%" : 500,
							maxWidth: 500,
						}}
						onPress={() => {
							if (fromOnboarding) {
								router.replace("/home");
							} else {
								router.back();
								//TODO:Track in the backend
							}
						}}
					>
						Continue
					</Button>
				</Box>
			</Box>
		</>
	);
};

export default DisclaimerView;
