/* -----------------Globals--------------- */
import { SectionList } from "react-native";

/* -----------------UI--------------- */
import { Chip, RadioButton } from "react-native-paper";

import pluralize from "pluralize";
import { memo } from "react";
import { useAppTheme } from "../../useAppTheme";
import useBreakpoints from "../../useBreakpoints";
import Box from "../Box";
/* -----------------Child components--------------- */
import { CustomListSubheader } from "../CustomListSubheader";
import EmptyState from "../EmptyView";

interface Section {
	title: string;
	data: {
		name: string;
		id: string;
	}[];
}

interface Props {
	sections: Section[];
	handleSelectItem: (itemId: string) => void;
	selectedItemId: string;
	emptySearchResultSvg?: React.ReactNode;
	handleClearSearch: () => void;
	type?: string;
	initSelectedItemId?: string;
}

const RenderItem = memo(
	({
		item,
		handleSelectItem,
		selectedItemId,
		initSelectedItemId,
		type,
	}: {
		item: { name: string; id: string };
		handleSelectItem: (itemId: string) => void;
		selectedItemId: string;
		initSelectedItemId?: string;
		type?: string;
	}) => {
		const { xsUp } = useBreakpoints();
		return (
			<>
				<RadioButton.Item
					onPress={() => handleSelectItem(item.id)}
					label={item.name}
					mode="android"
					position="leading"
					labelStyle={{
						textAlign: "left",
						paddingLeft: 16,
					}}
					style={{
						paddingLeft: 8,
					}}
					status={selectedItemId === item.id ? "checked" : "unchecked"}
					value={selectedItemId}
				/>
				{initSelectedItemId === item.id && xsUp && (
					<Box position="absolute" right={16} top={10}>
						<Chip selected compact>
							{`Current ${type}`}
						</Chip>
					</Box>
				)}
			</>
		);
	},
);
const SearchableSectionList = ({
	sections,
	handleSelectItem,
	selectedItemId,
	emptySearchResultSvg,
	handleClearSearch,
	initSelectedItemId,
	type = "exam",
}: Props) => {
	const theme = useAppTheme();

	const showSectionHeader = sections.length > 1;
	return (
		<SectionList
			sections={sections}
			showsVerticalScrollIndicator={false}
			ListEmptyComponent={
				<Box paddingBottom={24}>
					<EmptyState
						title={`No ${pluralize(type)} found`}
						subtitle="Try adjusting your filters or search query"
						image={emptySearchResultSvg}
						cta="Clear search"
						handleAction={handleClearSearch}
					/>
				</Box>
			}
			contentContainerStyle={{
				paddingBottom: 100,
				minHeight: "100%",
			}}
			initialNumToRender={10}
			keyExtractor={(item) => item.id}
			renderItem={({ item }) => (
				<RenderItem
					item={item}
					handleSelectItem={handleSelectItem}
					selectedItemId={selectedItemId}
					initSelectedItemId={initSelectedItemId}
					type={type}
				/>
			)}
			renderSectionHeader={({ section: { title } }) =>
				showSectionHeader ? (
					<CustomListSubheader
						enableGutters={false}
						style={{
							paddingLeft: 16,
							backgroundColor: theme.colors.background,
						}}
					>
						{title}
					</CustomListSubheader>
				) : null
			}
		/>
	);
};

export default SearchableSectionList;
