/* -----------------Globals--------------- */
import { StyleSheet, type ViewStyle } from "react-native";

import { type AppTheme, useAppTheme } from "@hooks/useAppTheme";
/* -----------------UI--------------- */
import { Checkbox, List, type ListItemProps } from "react-native-paper";

/* -----------------Icons--------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { Box, CustomChip } from "@memorang/ui";

/* -----------------Styles--------------- */
const getStyles = (theme: AppTheme) =>
	StyleSheet.create({
		none: {
			backgroundColor: theme.colors.progress.noneBackground,
		},
		medium: {
			backgroundColor: theme.colors.progress.medium,
		},
		low: {
			backgroundColor: theme.colors.progress.low,
		},
		high: {
			backgroundColor: theme.colors.progress.high,
		},
	});

export type BgColorType = "none" | "low" | "medium" | "high";
/* -----------------Props--------------- */
type Props = {
	count: number;
	handleSelectCheckbox: () => void;
	bgColorType?: BgColorType;
	locked?: boolean;
	status: "checked" | "unchecked";
	textColor?: string;
	disableAll?: boolean;
	testID?: string;
} & ListItemProps;

/* --------------Component-----------*/
const CheckboxListItem = ({
	count,
	title,
	handleSelectCheckbox,
	bgColorType,
	disabled,
	locked,
	status,
	textColor,
	disableAll,
	testID,
}: Props) => {
	const theme = useAppTheme();
	const styles = getStyles(theme);

	const renderLeftComp = () => (
		<List.Icon
			icon={() => {
				return (
					<>
						{locked ? (
							<Icon
								name="lock-outline"
								size={24}
								color={theme.colors.onSurfaceVariant}
							/>
						) : (
							<Box
								height={24}
								width={24}
								alignItems="center"
								justifyContent="center"
							>
								<Checkbox.Android
									status={status}
									disabled={disabled || disableAll}
									testID={testID ? testID : undefined}
								/>
							</Box>
						)}
					</>
				);
			}}
		/>
	);

	const renderRightComp = (p: {
		color: string;
		style?: ViewStyle;
	}) => (
		<List.Icon
			{...p}
			icon={() => (
				<CustomChip
					style={{
						...(bgColorType ? styles[bgColorType] : null),
					}}
					textStyle={{
						color: textColor || theme.colors.white,
					}}
				>
					{count}
				</CustomChip>
			)}
		/>
	);
	return (
		<List.Item
			disabled={disabled || disableAll}
			onPress={handleSelectCheckbox}
			title={title}
			style={{
				paddingRight: 16,
				paddingLeft: 16,
			}}
			left={renderLeftComp}
			right={renderRightComp}
		/>
	);
};

export default CheckboxListItem;
