/* ----------------- Local Imports --------------- */
import { useAppTheme } from "../../useAppTheme";
import type { ProductCardProps } from "./types";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* ----------------- Third Party Imports --------------- */
import { Card, Chip, Text } from "react-native-paper";

const ProductCard = ({
	id,
	title,
	description,
	icon,
	selected,
	disabled,
	handleSelectProduct,
	selectButtonText = "Select Plan",
	alreadySelectedButtonText = "Selected",
	alreadyPurchasedButtonText = "Already Purchased",
}: ProductCardProps) => {
	const theme = useAppTheme();

	let buttonText = selectButtonText;

	const isSelected = !disabled && selected;

	let color = theme.colors.onSurfaceVariant;

	if (disabled) {
		color = theme.colors.outline;
		buttonText = alreadyPurchasedButtonText;
	}

	if (selected) {
		color = theme.colors.onSecondaryContainer;
		buttonText = alreadySelectedButtonText;
	}

	return (
		<Card
			mode="outlined"
			onPress={() => handleSelectProduct(id)}
			disabled={disabled || selected}
			style={{
				backgroundColor: selected
					? theme.colors.secondaryContainer
					: theme.colors.surface,
				flex: 1,
			}}
			contentStyle={{
				alignItems: "center",
				justifyContent: "center",
				padding: 16,
			}}
		>
			{!!icon && (
				<Icon
					name={isSelected ? icon.filled : icon.outlined}
					size={24}
					color={color}
				/>
			)}

			<Card.Title
				title={title}
				subtitle={description}
				subtitleNumberOfLines={2}
				titleVariant="titleMedium"
				titleStyle={{
					color,
					textAlign: "center",
					padding: 0,
				}}
				subtitleVariant="bodySmall"
				subtitleStyle={{
					color,
					textAlign: "center",
					padding: 0,
				}}
				style={{
					flexDirection: "column",
					justifyContent: "center",
					padding: 0,
				}}
			/>

			<Chip
				mode={selected ? "flat" : "outlined"}
				disabled={disabled || selected}
				showSelectedCheck={isSelected}
				onPress={() => handleSelectProduct(id)}
				icon={() =>
					isSelected ? (
						<Icon
							name="check"
							size={18}
							color={theme.colors.onSecondaryContainer}
						/>
					) : null
				}
				style={{
					backgroundColor: selected
						? theme.colors.secondaryContainer
						: "transparent",
					maxWidth: "100%",
				}}
				textStyle={{
					color,
				}}
			>
				<Text numberOfLines={1}>{buttonText}</Text>
			</Chip>
		</Card>
	);
};

export default ProductCard;
