import type { Article } from "../../../../../types";

export const Article509: Article = {
	id: 509,
	rsuiteId: "3dc1750d-b9a7-46cc-81e1-92bd58e5a2c8",
	type: "symposium series",
	title:
		"The Role of Sex and Gender in Transgender Bone and Other Musculoskeletal Health",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/509.png",
	subSpecialties: ["sports_medicine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">The Role of Sex and Gender in Transgender Bone and Other
                Musculoskeletal Health</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Leland
                      Graves, III, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Barbara P.
                      Lukert, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract:</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">Musculoskeletal changes occur with gender-affirming
                        hormonal therapy (GAHT) and gender-affirming surgery (GAS) used in the care of transgender
                        adolescents and adults. Survey results have shown that orthopaedic surgeons desire to care for
                        transgender individuals but express concern over a knowledge deficit. This article reviews the
                        physiology and pathophysiology that may occur with GAHT and GAS. Transgender women have lower
                        bone mineral density (BMD) prior to GAHT than cisgender men. Limited fracture data would suggest
                        that transgender women &gt;50 years of age have fracture rates similar to those of cisgender
                        women. Transgender men have normal BMD prior to GAHT and are not at an increased risk for
                        fracture compared with cisgender women. The use of puberty-blocking medications in the care of
                        transgender youth does result in a decline in BMD, which returns to baseline with GAHT, but the
                        effect of delaying puberty on maximal BMD and the lifetime fracture risk are unknown. At
                        present, dual x-ray absorptiometry (DXA) is used to measure BMD and assess fracture risk.
                        Attention should be paid to using the appropriate reference group in the interpretation of DXA
                        for transgender individuals. Promote musculoskeletal health by ensuring appropriate calcium,
                        vitamin D, weight-bearing activity, and a healthy lifestyle. Adherence to GAHT needs to be
                        encouraged to avoid bone loss. Data with regard to therapy for osteoporosis in transgender
                        patients have been lacking, but, at present, use of available therapies is expected to be
                        effective. Information with regard to differences in other musculoskeletal health issues such as
                        joint injuries has been lacking in transgender individuals.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">There were estimated to be &gt;1.6 million individuals
                  identifying as transgender or nonbinary in the United States in 2022. This number represents 0.5% of
                  the U.S. adult population, and, for adolescents who were 13 to 17 years of age, 1.4% identified as
                  transgender. In the adult population identifying as transgender, 38.5% identified as transgender
                  women, 25.6% identified as transgender men, and 35.9% identified as gender nonconforming. The
                  percentage within each racial or ethnic group was similar. Within the U.S. population, individuals who
                  were 13 to 24 years of age comprised 18.6% of the population while representing 42.7% of the U.S.
                  transgender population. Adults ≥25 years of age represented 81.4% of the population while representing
                  only 57.3% of the transgender adult population. The U.S. population that identifies as transgender has
                  remained steady between 2016 and 2022. However, the percentage of the transgender population that
                  falls between 13 and 17 years of age has increased from 10% to 18%<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. In a survey of 3,200 high school students from a
                  Northeastern mid-sized school district, 9.2% of participants had incongruence between sex assigned at
                  birth and gender identity<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Transgender individuals have higher rates of anxiety and
                  depression as well as death by suicide. Survey results have reported that 40% of transgender
                  individuals have attempted suicide<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>.
                  Gender-affirming medical care such as psychological care, gender-affirming hormone therapy (GAHT), and
                  gender-affirming surgery (GAS) have been shown to improve quality-of-life scores, anxiety, and
                  depression and may reduce the rate of death by suicide<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a>.
                  Despite these improved outcomes, access to care remains a problem for transgender individuals. Among
                  primary care internists and family medicine physicians surveyed, only 50% were willing to continue
                  GAHT for transgender patients. Primary care physicians willing to prescribe GAHT were much more likely
                  to provide comprehensive health services as well<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">5</span></a>. The
                  reluctance to prescribe GAHT is multifactorial but includes minimal education around sexual and gender
                  minority health during training<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">6</span></a>.
                  Pediatric orthopaedic specialists surveyed in 2022 were found to be comfortable and interested in
                  treating transgender individuals for bone and musculoskeletal health but expressed a perceived
                  knowledge deficit regarding specifically how GAHT affects musculoskeletal health<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">7</span></a>. Loss of bone mass due to hormonal changes and an increase
                  in fracture risk are among the potential complications of transgender care. Data are also sparse with
                  regard to muscle strength changes, ligamentous stability, and joint injury patterns that may be
                  related to GAHT in transgender individuals<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">8</span></a>. This
                  article will review musculoskeletal health in transgender care and support the need for more studies
                  to better remedy that knowledge deficit.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Bone
                Development During Normal Puberty</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">In cisgender women during puberty, estrogen stimulates
                  bone growth through periosteal apposition, and the growth spurt through stimulation of the growth
                  hormone insulin-like growth factor 1 (GH-IGF1) axis. IGF-1 is a bone-trophic hormone that promotes
                  bone formation by acting on osteoblasts and collagen synthesis. During puberty, signals of the GH-IGF
                  axis are upregulated in both humans and mice and correlate with changes in bone formation markers.
                  Male humans will achieve a higher peak bone mass, a greater bone size, and a stronger skeleton
                  compared with female humans.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Understanding when bone accrual is most rapid could
                  suggest when delaying the progression of puberty would be most detrimental to bone. In a prospective
                  study of how much bone mineral was gained during adolescence, Bailey found that, in both boys and
                  girls, &gt;35% of total body and lumbar spine bone mineral and &gt;27% of the bone mineral at the
                  femoral neck developed during the 4-year adolescent period surrounding peak linear growth velocity.
                  The clinical importance of these values can be appreciated when one considers that as much bone
                  mineral will be laid down during these 4 adolescent growing years as most people will lose during
                  their adult life. If the inhibition of this remarkable increase in bone mass by delaying puberty is
                  not made up for with subsequent hormonal therapy, an increase in fractures would be anticipated<a
                    href="" data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">9</span></a>. Some studies have emphasized the importance of the timing
                  of the start as well as the duration of suppression of puberty to the bone health of transgender
                  people<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">12</span></a>. Delaying the onset of puberty with gonadotropin-releasing
                  hormone agonist (GnRHa) or progestins is indicated as early as Tanner stage 2 of pubertal development,
                  whereas hormone therapy with estrogen or testosterone is typically prescribed around the patient age
                  of 16 years. An important unanswered question is whether the bone lost during the suppression of
                  puberty is fully replaced after starting GAHT. Long-term longitudinal studies designed to answer this
                  question have been sparse. Klink et al. evaluated areal bone mineral density (BMD) at the patient age
                  of 22 years (mean duration of GAHT in transgender girls, 5.8 years, following 1.3 years of puberty
                  suppression with GnRHa) and showed that areal BMD-lumbar spine (LS) Z-scores after GAHT were still
                  well below the values at baseline, whereas the areal BMD-femoral neck (FN) Z-scores were almost
                  restored. Between the start of GnRHa and the patient age of 22 years, the lumbar areal BMD Z-score
                  (relative to natal sex) in transgender women decreased significantly from −0.8 to −1.4 and, in
                  transgender men, there was a nonsignificant decrease from 0.2 to −0.3. This suggests that the BMD was
                  below the individuals’ pre-treatment potential and either attainment of peak bone mass has been
                  delayed or the peak bone mass itself was attenuated<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">13</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Testosterone administration in transgender men
                  significantly increases areal BMD/bone mineral apparent density (BMAD) Z-scores after at least 2 years
                  of treatment, although, in most cases, the baseline values are not restored. In a study comparing
                  transgender men taking testosterone for 2 years with age and body mass index-matched cisgender men,
                  transgender men undergoing testosterone therapy had lower muscle strength, muscle mass, Trabecular
                  Bone Score (TBS), and total body and femoral neck BMD values compared with cisgender men despite
                  similar serum testosterone levels<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">12</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Dual X-Ray
                Absorptiometry (DXA) and TBS in Assessing Fracture Risk</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">DXA is the most used test to assess bone density and
                  assist in fracture risk assessment. However, it is less able to predict fracture risk in younger
                  individuals. DXA also does not account for larger bone size in cisgender men compared with cisgender
                  women. Using a female database for a normal reference, as directed by the International Society of
                  Clinical Densitometry (ISCD), will lead to fewer cisgender men being diagnosed with low bone mass<a
                    href="" data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">14</span></a>. There has been debate about what reference population to
                  use in gender-nonconforming individuals. In 2019, the ISCD released an official position with regard
                  to gender-nonconforming patients, stating that if bone density measurement is indicated for
                  transgender women and transgender men ≥50 years of age, T-scores should be calculated using a White
                  female normative database. The Z-score should be used in a gender-nonconforming individual &lt;50
                  years of age and should match the gender identity of the individual. For a nonbinary individual, sex
                  assigned at birth should be used for the database. If requested by the ordering physician, a Z-score
                  should be provided for sex assigned at birth as well<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">14</span></a>.
                  The recommendation to use a White, non-race-adjusted, female database is due to the better
                  availability of fracture data according to bone density measurement. Despite these recommendations by
                  the ISCD, they may not have been adopted by those providing densitometry testing and reporting. A
                  review at a single academic institution regarding adherence to the 2019 ISCD recommendation for
                  transgender and gender-nonconforming patients found that 67% of the T-scores and Z-scores were
                  calculated incorrectly<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">15</span></a>.
                  Unpublished survey data from 24 academic centers found 74% of T-scores and 96% of Z-scores reported
                  incorrectly in gender-nonconforming individuals. Although bone density is used most to determine
                  fracture risk, it is hoped that assessment of bone architecture will add to our ability to determine
                  fracture risk using techniques such as the TBS, quantitative computed tomography, or ultrasound. Only
                  very long-term studies assessing fractures will enable an understanding of the usefulness of these
                  imaging techniques for better predicting fracture risk.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">GAHT</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The importance of maintaining an adequate level of
                  estrogen in adult transgender women is essential for maintaining bone mass. It is well known that
                  estrogen inhibits osteoclastic bone resorption and that estrogen deficiency results in rapid bone
                  loss. Estrogen, primarily available via the aromatization of testosterone, is also important in
                  maintaining bone mass in cisgender men and transgender men. Transgender women have lower BMD prior to
                  GAHT than cisgender men.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">A study of the effects of different estrogen dosages on
                  BMD in 87 transgender girls revealed that higher estrogen dosages (4 mg) were associated with a
                  greater increase in lumbar spine BMD Z-scores, compared with the more conservative dosage of 2 mg.
                  These results seem to suggest that there is a dose-dependent effect of estrogen on BMD in transgender
                  girls. Health-care providers must remain alerted to possible complications of higher doses of estrogen
                  (e.g., thromboembolic phenomena). The dose of estrogen required to prevent bone loss in transgender
                  women does not appear to be affected by gonadectomy.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The effects of testosterone are mediated through the
                  aromatization of testosterone to estradiol. However, testosterone is also important in men because it
                  increases bone size during growth and development and during adulthood in men, thus contributing to
                  reduced fracture risk<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">16</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Fracture
                Risk in Transgender Women and Transgender Men</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The effects on bone quantity and quality resulting from
                  transgender care (which may include pubertal delay, GAHT, and gonadectomy), related changes in muscle
                  mass, and noted lower levels of physical activity (especially among transgender women) all raise the
                  concern as to whether the actual fracture risk is increased in transgender individuals. Limited
                  fracture data would suggest that transgender women &gt;50 years of age have fracture rates similar to
                  those of cisgender women. Transgender men have normal BMD prior to GAHT and are not at an increased
                  risk for fracture compared with cisgender women. The available data addressing the fracture risk
                  remain limited. Most reports involve small numbers (&lt;50 subjects) and a short period of study time
                  (mostly &lt;2 years). The study by Wiepjes et al. utilized the Amsterdam Cohort of Gender Dysphoria
                  study<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">16</span></a>,
                  which included 6,793 individuals who had visited the gender identity clinic at the Amsterdam
                  University Medical Center between 1972 to 2016. Living individuals who had started GAHT were included
                  and were matched to 5 age-matched cisgender men and cisgender women through the Netherlands National
                  Civil Record Registry. Fracture diagnoses from emergency department visits nationwide were collected
                  for 2013 to 2015. Comparisons were made between transgender women, transgender men, cisgender women,
                  and cisgender men and included fracture rates, GAHT duration, DXA results, smoking rates, and hormonal
                  values. Fractures occurred in 3.3% of transgender women, 2.7% of age-matched reference cisgender men,
                  and 2.8% of age-matched reference cisgender women. When stratifying by age of ≥50 years, 4.4% of
                  transgender women had fractures compared with 2.4% of age-matched reference men (odds ratio, 1.9 [95%
                  confidence interval, 1.32 to 2.74]). Transgender women had a similar fracture risk as age-matched
                  cisgender women at 4.2%. In 3 years, 1.7% of transgender men, 3.0% of age-matched reference cisgender
                  men, and 2.2% of age-matched reference cisgender women had a fracture. As the overall fracture rate in
                  transgender men was low, stratification could not be made by age or type of fracture. In comparing
                  transgender women who had sustained fractures with those who had not sustained fractures, older age
                  (55 compared with 48 years) and lower lumbar spine T-score (−1.34 compared with −1.02) were found to
                  be significant differences, whereas transgender women with and without fracture had lumbar T-scores at
                  baseline that were similar, as were the changes over time. This supports previous studies suggesting
                  that the increased fracture risk is related to the lower pre-treatment bone density at baseline in
                  transgender women compared with cisgender men<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">16</span></a>.
                  There have been no data that demonstrate differences in fracture-healing between transgender and
                  cisgender individuals, other than extrapolation from preclinical studies of the impact of sex hormones
                  on bone healing<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">17</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Maintaining
                Bone Health and Treating Low Bone Mass and Osteoporosis in Transgender Individuals</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">To promote bone health, all transgender individuals should
                  be encouraged to have adequate calcium intake through diet and supplementation, along with
                  weight-bearing activity and appropriate vitamin D. Because transgender-specific data are lacking, the
                  recommended daily allowance for calcium should be recommended: 1,000 mg daily for adult men and women
                  18 to 50 years of age, and 1,200 mg daily for women ≥50 years. Vitamin D levels have been shown to be
                  lower in transgender women compared with transgender men, cisgender women, and cisgender men. These
                  lower levels may be related to dietary differences and reduced physical activity and sun exposure.
                  Vitamin D<span data-id="subscript_1" class="annotation subscript" style="position: unset;">3</span>
                  intake of 1,000 units daily is recommended by many organizations. There has been debate regarding the
                  ideal vitamin D target concentration; the American Association of Clinical Endocrinologists/American
                  College of Endocrinology Clinical Practice Guidelines for the Treatment of Postmenopausal
                  Osteoporosis—2020 Update have recommended ≥30 ng/mL<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">19</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">A single protocol for following bone health in transgender
                  patients is not feasible. Transgender women frequently have low bone mass compared with cisgender men
                  at the onset of GAHT. Transgender men whose testosterone levels are not maintained in the normal range
                  will be at greater risk for osteoporosis. Noncompliant patients taking GAHT intermittently may lose
                  bone. An increasing number of patients are given puberty-delaying medication, causing attenuation of
                  peak bone mass. However, long-term impacts on bone mass are not known. More data are needed to better
                  understand the impacts on long-term bone health resulting from the changes in peak bone mass and then
                  subsequent changes from the continued use of GAHT. Failure to follow these patients with assessments
                  of bone mass, BMD, and the rate of bone loss will delay appropriate intervention.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Presently, guidelines regarding screening for and treating
                  osteoporosis in transgender individuals are based on expert opinion because of the absence of adequate
                  long-term studies. The guidelines continue to recommend BMD measurements starting at age 60 or 65
                  years, coinciding with the recommendations for postmenopausal women. This recommendation is unlikely
                  to be adequate for transgender women or patients who have been treated with puberty-delaying drugs. In
                  order to make the right comparisons, a request for bone density measurements and imaging to evaluate
                  bone architecture would require complete information (e.g., history of puberty-delaying medications,
                  sex assigned at birth, self-identity, gender nonconformance, GAHT including medications and specific
                  dosages, history of gonadectomy, and frequency of noncompliance with GAHT).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">We are not aware of any studies on the effects of
                  bisphosphonates or other osteoporosis therapies used specifically for treating osteoporosis in
                  transgender patients. The assumption is that the response to these drugs is the same as in cisgender
                  patients. This is based in part on studies showing the same response to these drugs in patients with
                  hypogonadism in cisgender individuals. There is clearly a need for studies of the efficacy of these
                  drugs in treating transgender patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Muscle Mass
                and Joint Laxity in Transgender Individuals</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Muscle mass in cisgender men is greater than that in
                  cisgender women, with testosterone creating greater muscle volume and higher muscle power output.
                  Transgender women taking GAHT, including estrogen and other testosterone suppression therapy, have
                  reduced muscle mass and muscle strength following only 12 months of GAHT. Interestingly, transgender
                  women had 14% lower hand grip strength compared with cisgender men prior to beginning GAHT, and this
                  was reduced an additional 7% with GAHT. After 12 months of GAHT, thigh muscle volume increased 15% in
                  transgender men and decreased 5% in transgender women<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">20</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">There are differences in athletic injuries between
                  cisgender men and women. Following puberty, there is an increased risk of anterior cruciate ligament
                  (ACL) tears in cisgender women compared with cisgender men. This is related to differences in the
                  mechanics of muscle strength, quadriceps contraction, and neuromuscular control<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">21</span></a>. Additionally, estrogen is associated with ligamentous
                  laxity, and some evidence has shown higher rates of ACL injuries during the pre-ovulatory phase, when
                  estrogen is highest<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">22</span></a>.
                  Dragoo et al. also demonstrated that fibroblasts within the ACL of cisgender women have receptors for
                  the hormone relaxin, which is produced by the corpus luteum. Relaxin increases collagenase, which
                  results in the reduction of ACL size and strength<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">23</span></a>.
                  The ACL of cisgender men does not have these receptors. The ACL of transgender women would not possess
                  relaxin receptors, and transgender women who have undergone hysterectomy would have reduced relaxin
                  levels present. Neuromuscular control is influenced, in part, by sex hormones, and it is unknown how
                  this control is impacted or changes as a result of gender-affirming care. GAHT or an oral
                  contraceptive in cisgender women may bring about more stable hormone levels, which may result in more
                  ligamentous stability, than the widely variable estrogen levels occurring during the normal menstrual
                  cycle. However, the impact of exogenous sex hormones on the properties of the ACL and related ACL
                  injury risk are not known and are, in part, extrapolated from preclinical studies<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">24</span></a>. There has been a lack of studies examining joint stability
                  and injury risk and incidence in transgender women or transgender men<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">21</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Musculoskeletal health for transgender patients is an
                  important part of maintaining overall health. More research is needed to best accomplish this goal,
                  particularly understanding the effect of GAHT and pubertal delay on fracture risk. BMD has been the
                  primary focus as a measure of bone strength and fracture risk. More research is needed around
                  techniques such as the TBS or quantitative computed tomography to assess bone geometry and
                  microstructure in hopes of better understanding fracture risk in transgender individuals, especially
                  for those undergoing longer-term GAHT. In addition, more needs to be known about soft-tissue injury
                  risk, especially related to neuromuscular control and ligamentous laxity, in transgender individuals.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">At this point, there is no single standard approach to
                  providing musculoskeletal health in transgender patients. The risk of fracture for transgender women
                  who transition after puberty and consistently undergo GAHT appears similar to that for cisgender
                  women. The fracture risk for transgender men adhering to consistent GAHT does not appear to be
                  significantly different from that for cisgender men. There remains a concern with regard to the effect
                  of delaying puberty on eventual bone density and bone strength and whether that will increase fracture
                  risk. Prospective data on fracture risk in transgender individuals are still lacking. Diligence is
                  needed in assessing bone density and mitigating fracture risk in transgender individuals, especially
                  those who are not consistent in undergoing GAHT, particularly if they have undergone gonadectomy as
                  part of GAS. To improve musculoskeletal health in transgender patients, we also need to pay attention
                  to reducing the risks of eating disorders, smoking, alcoholism, and depression that are seen more
                  commonly in transgender patients. Lastly, improvement in our educational programs in medical school
                  and residency around transgender care is critical.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Herman JL</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Flores AR</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> O’Neill KK</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">How many adults and youth
                  identify as transgender in the United States?</span> Los Angeles: The Williams Institute, UCLA School
                of Law; 2022.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Kidd KM</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Sequeira GM</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Douglas C</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Paglisotti T</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Inwards-Breland DJ</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Miller E</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Coulter RWS</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Prevalence of gender-diverse
                  youth in an urban school district</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Pediatrics.</span> 2021 Jun;147(6):e2020049823.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20of%20gender-diverse%20youth%20in%20an%20urban%20school%20district&as_occt=title&as_sauthors=%20%22KM%20Kidd%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Schulman JK</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Erickson-Schroth L</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Mental health in sexual minority
                  and transgender women</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Psychiatr Clin North Am.</span> 2017 Jun;40(2):309-19.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mental%20health%20in%20sexual%20minority%20and%20transgender%20women&as_occt=title&as_sauthors=%20%22JK%20Schulman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Baker KE</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Wilson LM</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Sharma R</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Dukhanin V</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> McArthur K</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Robinson KA</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Hormone therapy, mental health,
                  and quality of life among transgender people: a systematic review</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">J Endocr Soc.</span> 2021 Feb
                2;5(4):bvab011.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hormone%20therapy%2C%20mental%20health%2C%20and%20quality%20of%20life%20among%20transgender%20people%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22KE%20Baker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Shires DA</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Stroumsa D</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Jaffee KD</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Woodford MR</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Primary care clinicians’
                  willingness to care for transgender patients</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Ann Fam Med.</span> 2018
                Nov;16(6):555-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Primary%20care%20clinicians%E2%80%99%20willingness%20to%20care%20for%20transgender%20patients&as_occt=title&as_sauthors=%20%22DA%20Shires%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> White W</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Brenman S</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Paradis E</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Goldsmith ES</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Lunn MR</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Obedin-Maliver J</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Stewart L</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Tran E</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Wells M</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Chamberlain LJ</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Fetterman DM</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Garcia G</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Lesbian, gay, bisexual, and
                  transgender patient care: medical students’ preparedness and comfort</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Teach Learn Med.</span>
                2015;27(3):254-63.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lesbian%2C%20gay%2C%20bisexual%2C%20and%20transgender%20patient%20care%3A%20medical%20students%E2%80%99%20preparedness%20and%20comfort&as_occt=title&as_sauthors=%20%22W%20White%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Feroe AG</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Hutchinson LE</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Miller PE</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Samora JB</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Kocher MS</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Knowledge, attitudes, and
                  practices in the orthopaedic care of sexual and gender minority youth: a survey of two pediatric
                  academic hospitals</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2022 Jul 1;480(7):1313-28.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Knowledge%2C%20attitudes%2C%20and%20practices%20in%20the%20orthopaedic%20care%20of%20sexual%20and%20gender%20minority%20youth%3A%20a%20survey%20of%20two%20pediatric%20academic%20hospitals&as_occt=title&as_sauthors=%20%22AG%20Feroe%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> McGovern MM</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Lowenstein NA</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Matzkin EG</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Sports medicine considerations
                  when caring for the transgender athlete</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">Arthrosc Sports Med Rehabil.</span> 2023 May 24;5(4):100736.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sports%20medicine%20considerations%20when%20caring%20for%20the%20transgender%20athlete&as_occt=title&as_sauthors=%20%22MM%20McGovern%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Bailey DA</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">The Saskatchewan Pediatric Bone
                  Mineral Accrual Study: bone mineral acquisition during the growing years</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Int J Sports Med.</span>
                1997 Jul;18(Suppl 3):S191-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Saskatchewan%20Pediatric%20Bone%20Mineral%20Accrual%20Study%3A%20bone%20mineral%20acquisition%20during%20the%20growing%20years&as_occt=title&as_sauthors=%20%22DA%20Bailey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> McCormack SE</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Cousminer DL</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Chesi A</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Mitchell JA</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Roy SM</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Kalkwarf HJ</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Lappe JM</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Gilsanz V</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Oberfield SE</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Shepherd JA</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Winer KK</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Kelly A</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Grant SFA</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Zemel BS</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Association between linear
                  growth and bone accrual in a diverse cohort of children and adolescents</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">JAMA Pediatr.</span> 2017
                Sep 5;171(9):e171769.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20between%20linear%20growth%20and%20bone%20accrual%20in%20a%20diverse%20cohort%20of%20children%20and%20adolescents&as_occt=title&as_sauthors=%20%22SE%20McCormack%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Cousminer DL</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Mitchell JA</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Chesi A</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Roy SM</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Kalkwarf HJ</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Lappe JM</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Gilsanz V</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Oberfield SE</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Shepherd JA</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Kelly A</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> McCormack SE</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Voight BF</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Zemel BS</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Grant SF</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Genetically determined later
                  puberty impacts lowered bone mineral density in childhood and adulthood</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J Bone Miner Res.</span>
                2018 Mar;33(3):430-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Genetically%20determined%20later%20puberty%20impacts%20lowered%20bone%20mineral%20density%20in%20childhood%20and%20adulthood&as_occt=title&as_sauthors=%20%22DL%20Cousminer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Andrade SRL</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Mucida YM</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Xavier JDC</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Fernandes LN</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Silva RO</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Bandeira F</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Bone mineral density,
                  Trabecular Bone Score and muscle strength in transgender men receiving testosterone therapy versus
                  cisgender men</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Steroids.</span> 2022 Feb;178:108951.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20mineral%20density%2C%20Trabecular%20Bone%20Score%20and%20muscle%20strength%20in%20transgender%20men%20receiving%20testosterone%20therapy%20versus%20cisgender%20men&as_occt=title&as_sauthors=%20%22SRL%20Andrade%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Klink D</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Caris M</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Heijboer A</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> van Trotsenburg M</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Rotteveel J</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Bone mass in young adulthood
                  following gonadotropin-releasing hormone analog treatment and cross-sex hormone treatment in
                  adolescents with gender dysphoria</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">J Clin Endocrinol Metab.</span> 2015 Feb;100(2):E270-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20mass%20in%20young%20adulthood%20following%20gonadotropin-releasing%20hormone%20analog%20treatment%20and%20cross-sex%20hormone%20treatment%20in%20adolescents%20with%20gender%20dysphoria&as_occt=title&as_sauthors=%20%22D%20Klink%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Rosen HN</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Hamnvik OR</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Jaisamrarn U</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Malabanan AO</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Safer JD</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Tangpricha V</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Wattanachanya L</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Yeap SS</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Bone densitometry in
                  transgender and gender non-conforming (TGNC) individuals: 2019 ISCD official position</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J Clin Densitom.</span>
                2019 Oct-Dec;22(4):544-53.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20densitometry%20in%20transgender%20and%20gender%20non-conforming%20(TGNC)%20individuals%3A%202019%20ISCD%20official%20position&as_occt=title&as_sauthors=%20%22HN%20Rosen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Walcott Q</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Dallman J</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Crow H</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Graves L</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Marsh C</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">DXA scan variants in
                  transgender patients</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">J Clin Densitom.</span> 2022 Oct-Dec;25(4):615-21.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=DXA%20scan%20variants%20in%20transgender%20patients&as_occt=title&as_sauthors=%20%22Q%20Walcott%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Wiepjes CM</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> de Blok CJ</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Staphorsius AS</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Nota NM</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Vlot MC</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> de Jongh RT</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> den Heijer M</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Fracture risk in trans women
                  and trans men using long-term gender-affirming hormonal treatment: a nationwide cohort study</span>.
                <span data-id="emphasis_16" class="annotation emphasis" style="position: unset;">J Bone Miner
                  Res.</span> 2020 Jan;35(1):64-70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fracture%20risk%20in%20trans%20women%20and%20trans%20men%20using%20long-term%20gender-affirming%20hormonal%20treatment%3A%20a%20nationwide%20cohort%20study&as_occt=title&as_sauthors=%20%22CM%20Wiepjes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Kurapaty SS</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Hsu WK</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Sex-based difference in bone
                  healing: a review of recent pre-clinical literature</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Curr Rev Musculoskelet Med.</span> 2022
                Dec;15(6):651-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex-based%20difference%20in%20bone%20healing%3A%20a%20review%20of%20recent%20pre-clinical%20literature&as_occt=title&as_sauthors=%20%22SS%20Kurapaty%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Camacho PM</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Petak SM</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Binkley N</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Diab DL</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Eldeiry LS</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Farooki A</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Harris ST</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Hurley DL</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Kelly J</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Camacho PM</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Petak SM</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Binkley N</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Diab DL</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Eldeiry LS</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Farooki A</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Harris ST</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Hurley DL</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Kelly J</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Lewiecki EM</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Pessah-Pollock R</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> McClung M</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Wimalawansa SJ</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Watts NB</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">American Association of
                  Clinical Endocrinologists/American College of Endocrinology clinical practice guidelines for the
                  diagnosis and treatment of postmenopausal osteoporosis-2020 update</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">Endocr Pract.</span> 2020 May;26(Suppl
                1):1-46.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=American%20Association%20of%20Clinical%20Endocrinologists%2FAmerican%20College%20of%20Endocrinology%20clinical%20practice%20guidelines%20for%20the%20diagnosis%20and%20treatment%20of%20postmenopausal%20osteoporosis-2020%20update&as_occt=title&as_sauthors=%20%22PM%20Camacho%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Watts NB</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Camacho PM</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Lewiecki EM</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Petak SM</span>; AACE/ACE
                Postmenopausal Osteoporosis Guidelines Task Force. <span data-id="strong_18" class="annotation strong"
                  style="position: unset;">American Association of Clinical Endocrinologists/American College of
                  Endocrinology clinical practice guidelines for the diagnosis and treatment of postmenopausal
                  osteoporosis-2020 update</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">Endocr Pract.</span> 2021 Apr;27(4):379-80.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=American%20Association%20of%20Clinical%20Endocrinologists%2FAmerican%20College%20of%20Endocrinology%20clinical%20practice%20guidelines%20for%20the%20diagnosis%20and%20treatment%20of%20postmenopausal%20osteoporosis-2020%20update&as_occt=title&as_sauthors=%20%22NB%20Watts%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> McGovern MM</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Lowenstein NA</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Matzkin EG</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Sports medicine considerations
                  when caring for the transgender athlete</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">Arthrosc Sports Med Rehabil.</span> 2023 May
                24;5(4):100736.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sports%20medicine%20considerations%20when%20caring%20for%20the%20transgender%20athlete&as_occt=title&as_sauthors=%20%22MM%20McGovern%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Bassett AJ</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Ahlmen A</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Rosendorf JM</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Romeo AA</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Erickson BJ</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Bishop ME</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">The biology of sex and
                  sport</span>. <span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">JBJS
                  Rev.</span> 2020 Mar;8(3):e0140-0140.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=2368294" target="_new" class="" style="position: unset;">JBJS Reviews</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Beynnon BD</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Johnson RJ</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Braun S</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Sargent M</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Bernstein IM</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Skelly JM</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Vacek PM</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">The relationship between
                  menstrual cycle phase and anterior cruciate ligament injury: a case-control study of recreational
                  alpine skiers</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2006 May;34(5):757-64.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20relationship%20between%20menstrual%20cycle%20phase%20and%20anterior%20cruciate%20ligament%20injury%3A%20a%20case-control%20study%20of%20recreational%20alpine%20skiers&as_occt=title&as_sauthors=%20%22BD%20Beynnon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Dragoo JL</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Castillo TN</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Braun HJ</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Ridley BA</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Kennedy AC</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Golish SR</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Prospective correlation between
                  serum relaxin concentration and anterior cruciate ligament tears among elite collegiate female
                  athletes</span>. <span data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Am J
                  Sports Med.</span> 2011 Oct;39(10):2175-80.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prospective%20correlation%20between%20serum%20relaxin%20concentration%20and%20anterior%20cruciate%20ligament%20tears%20among%20elite%20collegiate%20female%20athletes&as_occt=title&as_sauthors=%20%22JL%20Dragoo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Konopka JA</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Hsue L</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Chang W</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Thio T</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Dragoo JL</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">The effect of oral
                  contraceptive hormones on anterior cruciate ligament strength</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2020
                Jan;48(1):85-92.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20oral%20contraceptive%20hormones%20on%20anterior%20cruciate%20ligament%20strength&as_occt=title&as_sauthors=%20%22JA%20Konopka%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D3dc1750d-b9a7-46cc-81e1-92bd58e5a2c8%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Symposium Series</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">August 21, 2024;
                      106 (16): 1507</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00119</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">August 21, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_25"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            University of Kansas Medical Center, Kansas City, Kansas</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;3dc1750d-b9a7-46cc-81e1-92bd58e5a2c8&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=3dc1750d-b9a7-46cc-81e1-92bd58e5a2c8&type=pdf&name=JBJS.24.00119.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=3dc1750d-b9a7-46cc-81e1-92bd58e5a2c8&type=pdf&name=JBJS.24.00119.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_4" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_4" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_24"
                            class="annotation strong" style="position: unset;">Disclosure:</span> Research reported in
                          this publication was supported by the National Institute of Arthritis and Musculoskeletal and
                          Skin Diseases of the National Institutes of Health under Award Number R13AR082710. The content
                          is solely the responsibility of the authors and does not necessarily represent the official
                          views of the National Institutes of Health. The <span data-id="strong_25"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I92" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I92</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;3dc1750d-b9a7-46cc-81e1-92bd58e5a2c8&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=3dc1750d-b9a7-46cc-81e1-92bd58e5a2c8&type=zip&name=JBJS.24.00119.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Leland Graves, III, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:lgraves@kumc.edu" class="" style="position: unset;">lgraves@kumc.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3181-0052" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3181-0052</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Barbara P. Lukert, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4698-1150" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4698-1150</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">University of Kansas Medical Center, Kansas City, Kansas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 20867.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
