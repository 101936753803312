import { isWeb } from "@helpers/platform";
/* -----------------Helpers & Hooks--------------- */
import { useAppStore } from "@hooks/useAppStore";

/* -----------------Child components--------------- */
import Head from "expo-router/head";
const DocumentTitle = ({
	title,
	description,
}: {
	title: string;
	description?: string;
}) => {
	const appName = useAppStore((store) => store.app.name);
	if (!isWeb) {
		return null;
	}

	const finalTitle = `${appName} | ${title}`;
	return (
		<Head>
			<title>{finalTitle}</title>
			{description && <meta name="description" content={description} />}
		</Head>
	);
};

export default DocumentTitle;
