import {
	type CustomConfigType,
	customConfigTypeSchema,
} from "@configs/custom-app-config-schema";
import camelCase from "lodash-es/camelCase";
import mapKeys from "lodash-es/mapKeys";

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const parseAppConfig = (data: any): CustomConfigType | null => {
	if (!data) {
		return null;
	}
	const { _id, ...rest } = data;

	const camelCasedData = mapKeys(rest, (_value, key) => camelCase(key));
	const parsedData = customConfigTypeSchema.safeParse(camelCasedData);

	if (!parsedData.success) {
		console.error("❌ Error parsing custom app config:", parsedData.error);
		return null;
	}

	return parsedData.data;
};
