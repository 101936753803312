import {
	getSelectRoleOnboarding,
	getSelectSpecialtyOnboarding,
} from "@helpers/find-onboarding";
import { useAppStore } from "@hooks/useAppStore";
import useClerkUser from "@hooks/useClerkUser";
import { useCurrentCountry } from "@hooks/useCurrentCountry";
import { updateUserInfo } from "@memorang/api-client";
import { useAuthContext } from "@memorang/applets";
import { useLocalSearchParams, useRouter } from "expo-router";
import { useState } from "react";
import { useOnboardingStore } from "./useOnboardingStore";

export const useNameOnboarding = () => {
	const router = useRouter();
	const [firstName, setFirstName] = useState<string | undefined>("");
	const [lastName, setLastName] = useState<string | undefined>("");
	const [updatingUserInfo, setUpdatingUserInfo] = useState(false);

	const { email } = useClerkUser();

	const { setUserInfoNew } = useAuthContext();
	const { selectedAvatarUrl } = useLocalSearchParams<{
		selectedAvatarUrl: string;
	}>();
	const { appId, onboarding, showDisclaimer } = useAppStore((store) => ({
		appId: store.app.id,
		onboarding: store.onboarding,
		showDisclaimer: store.app.showDisclaimer,
	}));

	const { speciality, subRole, subSpecialities, role } = useOnboardingStore();

	const onboardingRoleStep = getSelectRoleOnboarding(onboarding!);

	const roleItems = onboardingRoleStep?.itemsData?.items || [];

	const primaryRole = roleItems.find((item) => item.value === role);

	const primaryRoleLabel = primaryRole?.label;

	const currentRoleIndex = roleItems.findIndex(
		(item) => item.value === role || subRole,
	);

	const nestedItems = roleItems[currentRoleIndex]?.items;

	const subRoleLabel = nestedItems?.find(
		(item) => item.value === subRole,
	)?.label;

	const { currentCountryCode } = useCurrentCountry();

	const currentRole = nestedItems?.length
		? nestedItems.find((item) => item.value === subRole)
		: roleItems[currentRoleIndex];

	const onboardingSpecialityStep = getSelectSpecialtyOnboarding(onboarding!);

	const specialityItems = onboardingSpecialityStep?.itemsData?.items || [];

	const currentSpecialityIndex = specialityItems.findIndex(
		(item) => item.value === speciality,
	);

	const unfilteredSpecialities =
		specialityItems[currentSpecialityIndex]?.items || specialityItems;

	const selectedSubspecialitesIconUrls = unfilteredSpecialities
		?.filter((item) => subSpecialities?.includes(item.value!))
		?.map((item) => item.icon!);

	const handleSetName = (type: "firstName" | "lastName", value: string) => {
		if (type === "firstName") {
			setFirstName(value);
		} else {
			setLastName(value);
		}
	};

	const handleClickContinue = () => {
		try {
			setUpdatingUserInfo(true);
			setUserInfoNew(firstName, lastName);
			updateUserInfo(email!, appId, {
				firstName,
				lastName,
				country: currentCountryCode,
			});
		} catch (error) {
			console.error(error);
		} finally {
			setUpdatingUserInfo(false);
			if (showDisclaimer) {
				router.setParams({
					step: "disclaimer",
					showBack: "true",
				});
			} else {
				router.replace("/home");
			}
		}
	};
	return {
		handleSetName,
		handleClickContinue,
		firstName,
		lastName,
		selectedAvatarUrl,
		updatingUserInfo,
		selectedSubspecialitesIconUrls,
		currentRole,
		primaryRoleLabel,
		primaryRoleType: currentRole?.value,
		subRoleLabel,
	};
};
