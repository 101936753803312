import type { Article } from "../../../../../types";

export const Article500: Article = {
	id: 500,
	rsuiteId: "02d5b0ec-fb0a-4544-8b95-eb6e561c1d0f",
	type: "guest editorial",
	title: "What’s New in Limb Lengthening and Deformity Correction",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/500.png",
	subSpecialties: ["pediatrics"],
	content: `
  <div id="main" class="" style="opacity: 1; position: unset">
  <div class="article lens-article" style="position: unset" data-context="toc">
    <div class="panel content document width100" style="position: unset">
      <div class="surface resource-view content" style="position: unset">
        <div class="nodes" style="padding-left: 0px; position: unset">
          <div
            class="content-node cover"
            data-id="cover"
            style="padding-top: 30px; position: unset"
          >
            <div class="content" style="position: unset">
              <div class="text title" style="position: unset">
                What’s New in Limb Lengthening and Deformity Correction
              </div>
              <div class="text subtitle" style="position: unset"></div>
              <div class="authors" style="position: unset">
                <div
                  class="content-node text"
                  data-id="text_contributor_1_reference"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    <a
                      href=""
                      data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference"
                      style="position: unset"
                      >Anirejuoritse Bafor, MBBS, FMCS(Ortho), FACS</a
                    >
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
                <div
                  class="content-node text"
                  data-id="text_contributor_2_reference"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    <a
                      href=""
                      data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference"
                      style="position: unset"
                      >Christopher A. Iobst, MD, FAOA</a
                    >
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_4"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_4"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  This update highlights and provides a summary of a selection
                  of published works in the field of limb lengthening and
                  deformity correction between April 2023 and March 2024.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-1"
            data-id="heading_4"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset"
                >Lower-Extremity Assessment</span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_5"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_5"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  There were several interesting articles that focused on
                  improving techniques for assessing limb alignment and bone
                  age. Breen et al. compared several methods for assessing bone
                  age and predicting growth remaining around the knee using
                  leg-length, hand, and elbow radiographs and recommended using
                  the Greulich and Pyle method based on a manual evaluation or
                  automated BoneXpert (version 3.0.3; Visiana) estimation of
                  bone age for this purpose<a
                    href=""
                    data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_1"
                      class="annotation superscript"
                      style="position: unset"
                      >1</span
                    ></a
                  >. Pennock et al. developed and validated an ankle bone age
                  atlas and a predictive growth model for the lower limb using
                  ankle radiographs<a
                    href=""
                    data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_2"
                      class="annotation superscript"
                      style="position: unset"
                      >2</span
                    ></a
                  >. Chen et al. reviewed 700 radiographs made between January
                  2000 and May 2022 from 350 patients and another 954
                  radiographs made from a historic cohort of 66 patients and
                  performed an assessment of 8 different skeletal maturity
                  systems<a
                    href=""
                    data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_3"
                      class="annotation superscript"
                      style="position: unset"
                      >3</span
                    ></a
                  >. With the Proximal Humeral Ossification System (PHOS)
                  demonstrating the greatest amount of variance and the modified
                  Fels knee system demonstrating the least amount of variance,
                  they concluded that variations in anatomical regions and the
                  number of available assessment parameters may affect the
                  precision of skeletal maturity systems.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_6"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_6"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Vogt et al. defined reference values for central knee anatomy
                  based on an analysis of standing anteroposterior
                  lower-extremity radiographs of 254 patients who were 8 to 16
                  years of age<a
                    href=""
                    data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_4"
                      class="annotation superscript"
                      style="position: unset"
                      >4</span
                    ></a
                  >. Cadaveric measurement of tibial rotational alignment using
                  computed tomographic (CT) scans, magnetic resonance imaging
                  (MRI) scans, and biplanar radiographs showed moderate to good
                  reliability compared with assessment using 3-dimensional
                  (3D)-CT reconstructed imaging<a
                    href=""
                    data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_5"
                      class="annotation superscript"
                      style="position: unset"
                      >5</span
                    ></a
                  >.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_7"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_7"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Hees et al. found a positive correlation between tibial bowing
                  angles and the posterior tibial slope<a
                    href=""
                    data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_6"
                      class="annotation superscript"
                      style="position: unset"
                      >6</span
                    ></a
                  >. They concluded that the posterior tibial slope on short
                  knee radiographs is underestimated compared with measurements
                  using the lateral mechanical axis of the tibia as a reference,
                  with increasing anterior bowing of the tibia.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_8"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_8"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  As the use of artificial intelligence (AI) in health care
                  continues to evolve, 2 studies utilizing AI systems explored
                  its role in assessing lower-limb alignment. Kuiper et al.
                  developed an automated system to assess mechanical and
                  anatomical lower-limb alignment using 3D-CT scans<a
                    href=""
                    data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_7"
                      class="annotation superscript"
                      style="position: unset"
                      >7</span
                    ></a
                  >. Compared with a manual system, which required 60 minutes to
                  complete measurements, the automated system required 12
                  minutes for computation and demonstrated good to excellent
                  reliability. Mitterer et al. evaluated the reliability of AI
                  software in assessing lower-limb alignment following distal
                  femoral or proximal tibial osteotomies and found excellent
                  agreement (intraclass correlation coefficient of 0.81 to 0.99)
                  between the AI and the manual measurements<a
                    href=""
                    data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_8"
                      class="annotation superscript"
                      style="position: unset"
                      >8</span
                    ></a
                  >.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_9"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_9"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Lintz et al. demonstrated a correlation between bone mineral
                  density distribution and hindfoot alignment<a
                    href=""
                    data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_9"
                      class="annotation superscript"
                      style="position: unset"
                      >9</span
                    ></a
                  >. Their findings showed increased bone density medially in
                  the tibia and talus in varus-aligned feet and ankles and an
                  increased bone density laterally in valgus alignment.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-1"
            data-id="heading_5"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset"
                >Upper Extremity</span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_10"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_10"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Shah et al. performed a 6-year literature review of congenital
                  upper-limb differences<a
                    href=""
                    data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_10"
                      class="annotation superscript"
                      style="position: unset"
                      >10</span
                    ></a
                  >. They provided a summary of 75 selected articles related to
                  classification, diagnosis, treatment options, outcomes, and
                  psychosocial challenges in this group of patients. Their
                  conclusion highlighted the advancements and refinements in the
                  diagnosis, classification, and treatment in this group.
                  Danisman et al. evaluated the upper limbs of 45 patients who
                  were 6 months to 18 years of age and had obstetric brachial
                  plexus palsy, and determined that length discrepancies
                  developed in this group of patients<a
                    href=""
                    data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_11"
                      class="annotation superscript"
                      style="position: unset"
                      >11</span
                    ></a
                  >.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-1"
            data-id="heading_6"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset"
                >Lower Extremity</span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_11"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_11"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Belthur et al. described the clinical findings, treatment
                  approach, and outcomes in a review of 8 patients with
                  congenital synostosis of the knee<a
                    href=""
                    data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_12"
                      class="annotation superscript"
                      style="position: unset"
                      >12</span
                    ></a
                  >. They reported that a syndromic presentation was the most
                  common form, and most cases were associated with phocomelia or
                  upper-extremity hypoplasia. Most patients ended up with knee
                  fusion following treatment.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_12"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_12"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Liu et al. evaluated a new telescopic rod design for the
                  treatment of congenital pseudarthrosis of the tibia in
                  children, reporting healing in 14 of 15 patients in their
                  series<a
                    href=""
                    data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_13"
                      class="annotation superscript"
                      style="position: unset"
                      >13</span
                    ></a
                  >. Distraction osteogenesis through the physis demonstrated
                  good callus morphology in a series of 9 children treated for
                  congenital pseudarthrosis of the tibia<a
                    href=""
                    data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_14"
                      class="annotation superscript"
                      style="position: unset"
                      >14</span
                    ></a
                  >. Despite high complication rates, including stress fractures
                  and deformity, El-Gammal et al. reported excellent union rates
                  in a series of 11 patients with congenital pseudarthrosis of
                  the tibia treated with an intramedullary vascularized fibular
                  graft and Ilizarov distraction<a
                    href=""
                    data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_15"
                      class="annotation superscript"
                      style="position: unset"
                      >15</span
                    ></a
                  >.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_13"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_13"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Burosumab, a monoclonal antibody that neutralizes fibroblast
                  growth factor 23 (FGF23), was shown to favorably affect the
                  malalignment of the lower limbs in patients with FGF23-related
                  hypophosphatemic rickets<a
                    href=""
                    data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_16"
                      class="annotation superscript"
                      style="position: unset"
                      >16</span
                    ></a
                  >.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_14"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_14"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Saragaglia et al. described a technique based on the
                  assumption that 1 mm of opening-wedge height equates to 1°, to
                  estimate correction when performing a medial opening-wedge
                  high tibial osteotomy for genu varum, and achieved the desired
                  magnitude of overcorrection in 92% of cases based on
                  postoperative radiographs<a
                    href=""
                    data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_17"
                      class="annotation superscript"
                      style="position: unset"
                      >17</span
                    ></a
                  >. In a similar study, Yang et al. compared pre-osteotomy and
                  post-osteotomy wedge heights in patients treated for excessive
                  posterior tibial slopes using an anterior closing-wedge
                  osteotomy<a
                    href=""
                    data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_18"
                      class="annotation superscript"
                      style="position: unset"
                      >18</span
                    ></a
                  >. They found that using a calculation based on the assumption
                  that a 1-mm wedge height corresponds with 1° of slope
                  correction created an overcorrection of 1.6.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_15"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_15"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Three studies examined treatment outcomes following high
                  tibial osteotomies. The use of an additional lag screw for
                  added support following medial opening-wedge high tibial
                  osteotomy allowed immediate weight-bearing and resulted in
                  significantly faster bone union and overall better functional
                  outcome scores<a
                    href=""
                    data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_19"
                      class="annotation superscript"
                      style="position: unset"
                      >19</span
                    ></a
                  >. Batailler et al. performed a multicenter study to assess
                  long-term survival in high tibial osteotomies and to determine
                  predictive factors for survival<a
                    href=""
                    data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_20"
                      class="annotation superscript"
                      style="position: unset"
                      >20</span
                    ></a
                  >. They identified age of &lt;55 years, female sex, body mass
                  index (BMI) of &lt;25 kg/m<span
                    data-id="superscript_21"
                    class="annotation superscript"
                    style="position: unset"
                    >2</span
                  >, and incomplete joint-space narrowing as positive
                  preoperative predictive factors for survival and proposed the
                  SKOOP (Sfa Knee OsteOtomy Predictive) predictive score for
                  high tibial osteotomy. Yamada et al. performed 3D MRI of the
                  knee in flexion before and after open-wedge high tibial
                  osteotomy and found no significant decrease in the patellar
                  center angle<a
                    href=""
                    data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_22"
                      class="annotation superscript"
                      style="position: unset"
                      >21</span
                    ></a
                  >.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-1"
            data-id="heading_7"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset"
                >Blount Disease</span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_16"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_16"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Adulkasem et al. retrospectively reviewed a cohort of patients
                  with infantile Blount disease and identified demographic,
                  severity grading, and angular predictors of recurrence
                  following tibial osteotomy<a
                    href=""
                    data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_23"
                      class="annotation superscript"
                      style="position: unset"
                      >22</span
                    ></a
                  >. Using age, LaMont classification type, and medial
                  metaphyseal beak angle, they developed a predictive score for
                  recurrence that showed excellent performance and validity.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_17"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_17"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  A retrospective callback study was performed to determine the
                  long-term radiographic and functional status of patients
                  treated for Blount disease<a
                    href=""
                    data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_24"
                      class="annotation superscript"
                      style="position: unset"
                      >23</span
                    ></a
                  >. Despite the low yield, the results suggest that physical
                  scores were inversely related to BMI, and satisfaction with
                  life was inversely related to mechanical axis deviation. This
                  study also found no correlation between the severity of
                  radiographic features of osteoarthritis and the mechanical
                  axis deviation or BMI.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-1"
            data-id="heading_8"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset"
                >Limb Lengthening</span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_18"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_18"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Medium-term outcomes for a cohort of 15 pediatric patients who
                  had circumferential periosteal release for leg-length
                  discrepancy revealed a mild but significant decrease in
                  leg-length discrepancy<a
                    href=""
                    data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_25"
                      class="annotation superscript"
                      style="position: unset"
                      >24</span
                    ></a
                  >. The authors advocated its use in patients with a congenital
                  etiology in which the discrepancy is predicted to increase
                  with age.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_19"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_19"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  In the last few years, a lot of attention has been drawn to
                  osteolytic changes related to the STRYDE magnetic lengthening
                  nail (NuVasive), causing it to be withdrawn from the market.
                  Despite this, a recent study showed that bone healing was not
                  impaired with the use of the STRYDE nail for bone
                  lengthening<a
                    href=""
                    data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_26"
                      class="annotation superscript"
                      style="position: unset"
                      >25</span
                    ></a
                  >. Three other studies explored the versatility of
                  intramedullary lengthening nails in managing limb-length
                  discrepancy. The efficacy of the PRECICE nail (NuVasive) in
                  managing limb-length discrepancy in patients following a
                  surgical procedure for bone tumors was demonstrated in a group
                  of 17 patients<a
                    href=""
                    data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_27"
                      class="annotation superscript"
                      style="position: unset"
                      >26</span
                    ></a
                  >. Georgiadis et al. described the off-label use of the same
                  motorized intramedullary lengthening nail in 2 scenarios in
                  which additional lengthening is required<a
                    href=""
                    data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_28"
                      class="annotation superscript"
                      style="position: unset"
                      >27</span
                    ></a
                  >. Iobst et al. demonstrated that, under the right conditions,
                  magnetically driven lengthening nails may be safely used in
                  patients with preexisting implanted programmable devices<a
                    href=""
                    data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_29"
                      class="annotation superscript"
                      style="position: unset"
                      >28</span
                    ></a
                  >.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_20"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_20"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Depaoli et al. challenged the current threshold of 45 days/cm
                  for the diagnosis of delayed consolidation in their
                  retrospective assessment of healing following external fixator
                  lengthening in a cohort of 178 patients who underwent 240
                  lengthening procedures<a
                    href=""
                    data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_30"
                      class="annotation superscript"
                      style="position: unset"
                      >29</span
                    ></a
                  >. They reported healing indices of 55 days/cm for the tibia
                  and 57 days/cm for the femur, suggesting that a review of the
                  current threshold is necessary.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_21"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_21"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Vogt et al. retrospectively reviewed a series of 15 patients
                  with achondroplasia who underwent simultaneous bilateral
                  femoral lengthening and reported 97% reliability of the
                  lengthening nails<a
                    href=""
                    data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_31"
                      class="annotation superscript"
                      style="position: unset"
                      >30</span
                    ></a
                  >.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-1"
            data-id="heading_9"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset"
                >Bone Defects</span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_22"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_22"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  The management of bone defects continues to prove challenging.
                  Several studies focused on this difficult condition are
                  highlighted in this section.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_23"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_23"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  In a retrospective case-control study, Xu et al. demonstrated
                  better outcomes with bone transport over a nail compared with
                  the traditional Ilizarov technique for the management of
                  tibial bone defects<a
                    href=""
                    data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_32"
                      class="annotation superscript"
                      style="position: unset"
                      >31</span
                    ></a
                  >. Wang et al. retrospectively evaluated a group of patients
                  and demonstrated efficacious infection control in infected
                  bone defects using antibiotic bone cement-coated implants<a
                    href=""
                    data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_33"
                      class="annotation superscript"
                      style="position: unset"
                      >32</span
                    ></a
                  >. Compared with external fixator treatment, this method
                  proved more effective in restoring limb function.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_24"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_24"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Two systematic reviews compared treatment outcomes in the
                  management of critical-size bone defects using the Masquelet
                  technique or bone transport. Allesina et al. found no
                  significant differences when most outcomes were compared;
                  however, bone transport showed significantly better bone ASAMI
                  (Association for the Study and Application of the Method of
                  Ilizarov) score results, and a lower incidence of residual
                  deformity, compared with the Masquelet technique<a
                    href=""
                    data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_34"
                      class="annotation superscript"
                      style="position: unset"
                      >33</span
                    ></a
                  >. In their review of 1,136 patients from 32 studies comparing
                  the Ilizarov method and the Masquelet technique for the
                  treatment of patients with infected tibial nonunions,
                  Wakefield et al. similarly found no significant difference in
                  outcomes<a
                    href=""
                    data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_35"
                      class="annotation superscript"
                      style="position: unset"
                      >34</span
                    ></a
                  >.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-1"
            data-id="heading_10"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset"
                >Bone Transport</span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_25"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_25"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  The use of intramedullary bone transport nails to manage
                  segmental bone defects using the principles of distraction
                  osteogenesis is a recent innovation in limb reconstruction
                  surgery. Current concerns regarding metallurgy have
                  necessitated its withdrawal from the market without a timeline
                  for its potential return. However, 2 separate case series
                  described the use of these nails in the management of
                  segmental defects of the tibia. Geiger et al. reviewed 4
                  patients with a median tibial defect of 6.5 cm who had a
                  median follow-up of 13.5 months<a
                    href=""
                    data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_36"
                      class="annotation superscript"
                      style="position: unset"
                      >35</span
                    ></a
                  >. The median transport index was 18 days/cm, and the
                  regenerate healing index was 37 days/cm. Delayed union at the
                  docking site was noted in 2 patients and failure of the
                  external remote controller in 1 patient. Blair et al. managed
                  4 patients with a minimum tibial defect of 5 cm using the
                  transport nail<a
                    href=""
                    data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_37"
                      class="annotation superscript"
                      style="position: unset"
                      >36</span
                    ></a
                  >. One patient in this series developed a nonunion at the
                  docking site. The mean bone-healing index was 41.4 days/cm in
                  the 3 other patients. Other complications reported in their
                  series include axial malalignment of the implant and technical
                  failure of the external remote controller.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_26"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_26"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  One randomized controlled study compared union rates at the
                  docking site and recurrence of infection in bone transport
                  through an induced membrane compared with conventional bone
                  transport for the management of infected defects of long bones
                  of the lower limb<a
                    href=""
                    data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_38"
                      class="annotation superscript"
                      style="position: unset"
                      >37</span
                    ></a
                  >. Transport through an induced membrane resulted in a shorter
                  time to docking and lower rates of nonunion and recurrence of
                  infection. There was no significant difference in the external
                  fixation index between the 2 groups.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_27"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_27"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Freischmidt et al. published clinical and radiographic
                  outcomes in a retrospective series of 15 patients who were
                  managed with plate-assisted bone segment transport for bone
                  defects of &gt;3 cm<a
                    href=""
                    data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_39"
                      class="annotation superscript"
                      style="position: unset"
                      >38</span
                    ></a
                  >. Consolidation of the regenerate bone was achieved in 93% of
                  the population at 7.3 months, whereas 60% had union at the
                  docking site and a consolidated regenerate at 11.5 months
                  following the surgical procedure.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_28"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_28"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  In a systematic review of studies evaluating union at the
                  docking site following bone transport with an external
                  fixator, there were union rates of 90% in patients who had
                  routinely planned intervention at the docking site compared
                  with 66% in patients who did not have routinely planned
                  intervention<a
                    href=""
                    data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_40"
                      class="annotation superscript"
                      style="position: unset"
                      >39</span
                    ></a
                  >. However, a 99% union rate was achieved in both groups, with
                  a mean of 3.8 procedures to achieve union in the routine
                  intervention group compared with 2.2 procedures in the
                  observed group (in which routine intervention was not
                  planned).
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-1"
            data-id="heading_11"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset"
                >Foot and Ankle</span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_29"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_29"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Wang et al. reported a 94% primary fusion rate, a 6% rate of
                  nonunion, and a 2% rate of recurrent infection in an
                  assessment of the outcomes of ankle arthrodesis for septic
                  arthritis in a cohort of 59 patients treated using the
                  Ilizarov fixator<a
                    href=""
                    data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_41"
                      class="annotation superscript"
                      style="position: unset"
                      >40</span
                    ></a
                  >.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_30"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_30"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Xie et al. assessed fixation methods following minimally
                  invasive correction of hallux valgus using 3D finite element
                  analysis of foot CT imaging and determined that Herbert screw
                  fixation provided good stability and uniform stress
                  distribution<a
                    href=""
                    data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_42"
                      class="annotation superscript"
                      style="position: unset"
                      >41</span
                    ></a
                  >.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-1"
            data-id="heading_12"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset">Clubfoot</span>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_31"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_31"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Depression and anxiety levels in primiparous mothers with
                  children diagnosed prenatally with clubfeet were evaluated at
                  4 periods<a
                    href=""
                    data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_43"
                      class="annotation superscript"
                      style="position: unset"
                      >42</span
                    ></a
                  >. The results showed elevated anxiety and depression scores
                  following prenatal diagnosis of clubfeet and a reduction of
                  these scores following successful treatment using the Ponseti
                  method.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_32"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_32"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  In a review, the pathologic anatomy, radiographic
                  characteristics, and iatrogenic causes of flattening of the
                  talus following clubfoot treatment were highlighted and
                  discussed<a
                    href=""
                    data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_44"
                      class="annotation superscript"
                      style="position: unset"
                      >43</span
                    ></a
                  >. It may be present prior to treatment or it may be a
                  complication of some treatment modalities for clubfoot, but it
                  is unlikely to be a complication of the Ponseti method of
                  treatment.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-1"
            data-id="heading_13"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset"
                >Guided Growth</span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_33"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_33"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Transphyseal screws for hemiepiphysiodesis demonstrated
                  significantly faster rates of correction of the mechanical
                  axis deviation and the mechanical lateral distal femoral angle
                  compared with growth modulation plates<a
                    href=""
                    data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_45"
                      class="annotation superscript"
                      style="position: unset"
                      >44</span
                    ></a
                  >. In contrast, Heckel et al. compared the treatment outcomes
                  for hemiepiphysiodesis using tension-band plates and
                  percutaneous epiphysiodesis transphyseal screws and
                  demonstrated similar correction effects<a
                    href=""
                    data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_46"
                      class="annotation superscript"
                      style="position: unset"
                      >45</span
                    ></a
                  >.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_34"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_34"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Eberle et al. retrospectively evaluated a cohort of patients
                  who underwent tension-band plating for idiopathic genu valgum
                  deformity to determine the factors responsible for rebound
                  growth following plate removal<a
                    href=""
                    data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_47"
                      class="annotation superscript"
                      style="position: unset"
                      >46</span
                    ></a
                  >. Sex, age at implantation, mechanical axis length at
                  implantation, mechanical lateral distal femoral angle,
                  mechanical medial proximal tibial angle, and mechanical axis
                  deviation correction rates were identified as predictive
                  factors for rebound growth.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_35"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_35"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Two studies highlighted problems with the use of a sleeper
                  plate following correction of angular deformities around the
                  knee with temporary hemiepiphysiodesis. Retzky et al. reported
                  that tibial sleeper plates caused tethering, which resulted in
                  overcorrection of knee deformity in patients with multiple
                  hereditary exostosis, and recommended avoiding this form of
                  treatment in this group of patients<a
                    href=""
                    data-id="citation_reference_47"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_48"
                      class="annotation superscript"
                      style="position: unset"
                      >47</span
                    ></a
                  >. Bakircioglu et al. also demonstrated an increased risk of
                  tethering using this technique and further highlighted the
                  additional complication of difficulty in metaphyseal screw
                  reinsertion due to osseous overgrowth of the plate<a
                    href=""
                    data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_49"
                      class="annotation superscript"
                      style="position: unset"
                      >48</span
                    ></a
                  >.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_36"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_36"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  In a retrospective review of 491 patients treated with
                  tension-band plates around the knee, a convergent orientation
                  of the epiphyseal screw was found to increase the risk of
                  physeal migration<a
                    href=""
                    data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_50"
                      class="annotation superscript"
                      style="position: unset"
                      >49</span
                    ></a
                  >.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-1"
            data-id="heading_14"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset"
                >Basic Science</span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_37"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_37"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Reverse dynamization was shown to accelerate the formation and
                  remodeling of the regenerate bone during distraction in a goat
                  tibial distraction osteogenesis model<a
                    href=""
                    data-id="citation_reference_50"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_51"
                      class="annotation superscript"
                      style="position: unset"
                      >50</span
                    ></a
                  >.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_38"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_38"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Two studies assessed the role of ultrasound on bone healing.
                  Brueton et al. evaluated the effect of ultrasound on bone
                  healing in a lapine tibial model of delayed healing and found
                  no evidence of accelerated healing, a reduced rate of delayed
                  union, or increased callus formation<a
                    href=""
                    data-id="citation_reference_51"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_52"
                      class="annotation superscript"
                      style="position: unset"
                      >51</span
                    ></a
                  >. In another study, low-intensity pulsed ultrasound was shown
                  to enhance healing during the Masquelet technique by promoting
                  the osteogenic differentiation of human induced
                  membrane-derived cells<a
                    href=""
                    data-id="citation_reference_52"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_53"
                      class="annotation superscript"
                      style="position: unset"
                      >52</span
                    ></a
                  >.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-1"
            data-id="heading_15"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset"
                >External Fixator Biomechanics</span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_39"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_39"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Bridges et al. compared 2 groups of pediatric patients treated
                  with 2 different types of hexapod frames and reported that
                  proximal half-pin breakage was likely due to multiple
                  factors<a
                    href=""
                    data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_54"
                      class="annotation superscript"
                      style="position: unset"
                      >53</span
                    ></a
                  >. Some of the factors identified included an increased number
                  of half-pins in the proximal fragment, a younger age at the
                  time of the surgical procedure, the length gained, and
                  nonstandard frame configurations, which included stacked
                  frames or frames placed across a joint for soft-tissue
                  correction.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-1"
            data-id="heading_16"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset">Pin Sites</span>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_40"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_40"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Using principles similar to those for the definition of
                  fracture-related and periprosthetic joint infection, Frank et
                  al. proposed a definition of pin-site infection based on
                  clinical, radiographic, and laboratory criteria<a
                    href=""
                    data-id="citation_reference_54"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_55"
                      class="annotation superscript"
                      style="position: unset"
                      >54</span
                    ></a
                  >. They grouped the criteria into suggestive and diagnostic
                  categories.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_41"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_41"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  A prospectively conducted, multicenter study comparing
                  hydroxyapatite-coated pins and stainless steel pins found no
                  significant difference in pin-site infection rates in patients
                  treated with external fixators<a
                    href=""
                    data-id="citation_reference_55"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_56"
                      class="annotation superscript"
                      style="position: unset"
                      >55</span
                    ></a
                  >.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-1"
            data-id="heading_17"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset"
                >Practical Tips</span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_42"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_42"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Laymouna et al. described a percutaneous outpatient procedure
                  for treating the dimpling resulting from pin sites following
                  the removal of fixators<a
                    href=""
                    data-id="citation_reference_56"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_57"
                      class="annotation superscript"
                      style="position: unset"
                      >56</span
                    ></a
                  >. They highlighted the short duration, cosmetic appeal, and
                  cost-effectiveness of the procedure in 382 scars in a cohort
                  of 25 patients.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_43"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_43"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Chaclas et al. designed an experimental training model to
                  assess the degree of accuracy of correction during femoral
                  derotation osteotomies and showed that prior surgical exposure
                  as well as training using the experimental model improved
                  accuracy<a
                    href=""
                    data-id="citation_reference_57"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_58"
                      class="annotation superscript"
                      style="position: unset"
                      >57</span
                    ></a
                  >.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-1"
            data-id="heading_18"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset"
                >Miscellaneous Topics</span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_44"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_44"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Munger et al. reviewed the literature and grouped the uses of
                  ultrasound in limb lengthening and reconstruction into
                  diagnostic and therapeutic categories<a
                    href=""
                    data-id="citation_reference_58"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_59"
                      class="annotation superscript"
                      style="position: unset"
                      >58</span
                    ></a
                  >. They also pointed out the limitations of user dependence
                  and a learning curve with this technology.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_45"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_45"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  A systematic review to assess educational and training
                  opportunities for limb-deformity surgeons in low-income and
                  middle-income countries revealed limited availability of
                  information relating to educational interventions in limb
                  deformity<a
                    href=""
                    data-id="citation_reference_59"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_60"
                      class="annotation superscript"
                      style="position: unset"
                      >59</span
                    ></a
                  >. The authors concluded by emphasizing the need for more
                  research in this area.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_46"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_46"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  Two new disease-specific patient-reported outcome measures
                  were developed and validated in pediatric patients with limb
                  deformity<a
                    href=""
                    data-id="citation_reference_60"
                    class="annotation citation_reference resource-reference"
                    style="position: unset"
                    ><span
                      data-id="superscript_61"
                      class="annotation superscript"
                      style="position: unset"
                      >60</span
                    ></a
                  >. The Limb Deformity Early Onset Scoliosis Questionnaire
                  (LD-EOSQ) for children ≤10 years of age and the Limb Deformity
                  Scoliosis Research Society (LD-SRS) survey for children who
                  were 11 to 18 years of age were created as modifications of
                  the EOSQ and SRS survey and demonstrated validity and
                  reliability in children with limb deformity.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-1"
            data-id="heading_19"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset"
                >Current and Future Events</span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node paragraph"
            data-id="paragraph_47"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div
                class="content-node text"
                data-id="text_47"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  The Limb Lengthening and Reconstruction Society held its 33rd
                  Annual Scientific Meeting in West Palm Beach, Florida, from
                  July 11 to 13, 2024, including a pre-course on Trauma and Limb
                  Salvage held on July 11, 2024. The 34th Annual Meeting is
                  scheduled from July 17 to 19, 2025, in Philadelphia. The 34th
                  Annual Baltimore Limb Deformity Course is scheduled to be held
                  from August 21 to 25, 2024. Texas Scottish Rite Hospital,
                  Dallas, Texas, will host the Essentials of Lower Extremity
                  Reconstruction (ELER) course from January 24 to 25, 2025, and
                  the Controversies in Pediatric Limb Reconstruction (CPLR)
                  course from February 7 to 8, 2025.
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-1"
            data-id="undefined_1"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset">References</span>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_1"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              1&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_1"
                  class="annotation"
                  style="position: unset"
                >
                  Breen AB</span
                >,
                <span
                  data-id="annotation_2"
                  class="annotation"
                  style="position: unset"
                >
                  Steen H</span
                >,
                <span
                  data-id="annotation_3"
                  class="annotation"
                  style="position: unset"
                >
                  Pripp A</span
                >,
                <span
                  data-id="annotation_4"
                  class="annotation"
                  style="position: unset"
                >
                  Hvid I</span
                >,
                <span
                  data-id="annotation_5"
                  class="annotation"
                  style="position: unset"
                >
                  Horn J</span
                >.
                <span
                  data-id="strong_1"
                  class="annotation strong"
                  style="position: unset"
                  >Comparison of different bone age methods and chronological
                  age in prediction of remaining growth around the knee</span
                >.
                <span
                  data-id="emphasis_1"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Pediatr Orthop.</span
                >
                2023 Jul 1;43(6):386-91.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20different%20bone%20age%20methods%20and%20chronological%20age%20in%20prediction%20of%20remaining%20growth%20around%20the%20knee&as_occt=title&as_sauthors=%20%22AB%20Breen%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_2"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              2&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_6"
                  class="annotation"
                  style="position: unset"
                >
                  Pennock AT</span
                >,
                <span
                  data-id="annotation_7"
                  class="annotation"
                  style="position: unset"
                >
                  Bomar JD</span
                >,
                <span
                  data-id="annotation_8"
                  class="annotation"
                  style="position: unset"
                >
                  Pedowitz JM</span
                >,
                <span
                  data-id="annotation_9"
                  class="annotation"
                  style="position: unset"
                >
                  Carveth SL</span
                >.
                <span
                  data-id="strong_2"
                  class="annotation strong"
                  style="position: unset"
                  >The creation and validation of an ankle bone age atlas and
                  data predicting remaining ankle growth</span
                >.
                <span
                  data-id="emphasis_2"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Pediatr Orthop.</span
                >
                2024 Mar 1;44(3):e278-84.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20creation%20and%20validation%20of%20an%20ankle%20bone%20age%20atlas%20and%20data%20predicting%20remaining%20ankle%20growth&as_occt=title&as_sauthors=%20%22AT%20Pennock%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_3"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              3&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_10"
                  class="annotation"
                  style="position: unset"
                >
                  Chen KJ</span
                >,
                <span
                  data-id="annotation_11"
                  class="annotation"
                  style="position: unset"
                >
                  Mysore A</span
                >,
                <span
                  data-id="annotation_12"
                  class="annotation"
                  style="position: unset"
                >
                  Furdock RJ</span
                >,
                <span
                  data-id="annotation_13"
                  class="annotation"
                  style="position: unset"
                >
                  Sattar A</span
                >,
                <span
                  data-id="annotation_14"
                  class="annotation"
                  style="position: unset"
                >
                  Sinkler MA</span
                >,
                <span
                  data-id="annotation_15"
                  class="annotation"
                  style="position: unset"
                >
                  Glotzbecker MP</span
                >,
                <span
                  data-id="annotation_16"
                  class="annotation"
                  style="position: unset"
                >
                  Liu RW</span
                >.
                <span
                  data-id="strong_3"
                  class="annotation strong"
                  style="position: unset"
                  >Correlations between eight comprehensive skeletal maturity
                  systems in a modern peripubertal pediatric population</span
                >.
                <span
                  data-id="emphasis_3"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Pediatr Orthop.</span
                >
                2024 Jan 1;44(1):e51-6.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Correlations%20between%20eight%20comprehensive%20skeletal%20maturity%20systems%20in%20a%20modern%20peripubertal%20pediatric%20population&as_occt=title&as_sauthors=%20%22KJ%20Chen%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_4"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              4&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_17"
                  class="annotation"
                  style="position: unset"
                >
                  Vogt B</span
                >,
                <span
                  data-id="annotation_18"
                  class="annotation"
                  style="position: unset"
                >
                  Hvidberg E</span
                >,
                <span
                  data-id="annotation_19"
                  class="annotation"
                  style="position: unset"
                >
                  Rölfing JD</span
                >,
                <span
                  data-id="annotation_20"
                  class="annotation"
                  style="position: unset"
                >
                  Gosheger G</span
                >,
                <span
                  data-id="annotation_21"
                  class="annotation"
                  style="position: unset"
                >
                  Møller-Madsen B</span
                >,
                <span
                  data-id="annotation_22"
                  class="annotation"
                  style="position: unset"
                >
                  Abood AA</span
                >,
                <span
                  data-id="annotation_23"
                  class="annotation"
                  style="position: unset"
                >
                  Weyer-Elberich V</span
                >,
                <span
                  data-id="annotation_24"
                  class="annotation"
                  style="position: unset"
                >
                  Laufer A</span
                >,
                <span
                  data-id="annotation_25"
                  class="annotation"
                  style="position: unset"
                >
                  Toporowski G</span
                >,
                <span
                  data-id="annotation_26"
                  class="annotation"
                  style="position: unset"
                >
                  Roedl R</span
                >,
                <span
                  data-id="annotation_27"
                  class="annotation"
                  style="position: unset"
                >
                  Frommer A</span
                >.
                <span
                  data-id="strong_4"
                  class="annotation strong"
                  style="position: unset"
                  >Radiographic reference values of the central knee anatomy in
                  8-16-year-old children</span
                >.
                <span
                  data-id="emphasis_4"
                  class="annotation emphasis"
                  style="position: unset"
                  >Acta Orthop.</span
                >
                2023 Jul 31;94:393-8.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiographic%20reference%20values%20of%20the%20central%20knee%20anatomy%20in%208-16-year-old%20children&as_occt=title&as_sauthors=%20%22B%20Vogt%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_5"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              5&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_28"
                  class="annotation"
                  style="position: unset"
                >
                  Edmonds EW</span
                >,
                <span
                  data-id="annotation_29"
                  class="annotation"
                  style="position: unset"
                >
                  Parvaresh KC</span
                >,
                <span
                  data-id="annotation_30"
                  class="annotation"
                  style="position: unset"
                >
                  Price MJ</span
                >,
                <span
                  data-id="annotation_31"
                  class="annotation"
                  style="position: unset"
                >
                  Farnsworth CL</span
                >,
                <span
                  data-id="annotation_32"
                  class="annotation"
                  style="position: unset"
                >
                  Bomar JD</span
                >,
                <span
                  data-id="annotation_33"
                  class="annotation"
                  style="position: unset"
                >
                  Hughes JL</span
                >,
                <span
                  data-id="annotation_34"
                  class="annotation"
                  style="position: unset"
                >
                  Upasani VV</span
                >.
                <span
                  data-id="strong_5"
                  class="annotation strong"
                  style="position: unset"
                  >The reliability of measurements for tibial torsion: a
                  comparison of CT, MRI, biplanar radiography, and 3D
                  reconstructions with and without standardized measurement
                  training</span
                >.
                <span
                  data-id="emphasis_5"
                  class="annotation emphasis"
                  style="position: unset"
                  >J POSNA.</span
                >
                2023;5(3).</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20reliability%20of%20measurements%20for%20tibial%20torsion%3A%20a%20comparison%20of%20CT%2C%20MRI%2C%20biplanar%20radiography%2C%20and%203D%20reconstructions%20with%20and%20without%20standardized%20measurement%20training&as_occt=title&as_sauthors=%20%22EW%20Edmonds%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_6"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              6&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_35"
                  class="annotation"
                  style="position: unset"
                >
                  Hees T</span
                >,
                <span
                  data-id="annotation_36"
                  class="annotation"
                  style="position: unset"
                >
                  Zielke J</span
                >,
                <span
                  data-id="annotation_37"
                  class="annotation"
                  style="position: unset"
                >
                  Petersen W</span
                >.
                <span
                  data-id="strong_6"
                  class="annotation strong"
                  style="position: unset"
                  >Effect of anterior tibial bowing on measurement of posterior
                  tibial slope on conventional X-rays</span
                >.
                <span
                  data-id="emphasis_6"
                  class="annotation emphasis"
                  style="position: unset"
                  >Arch Orthop Trauma Surg.</span
                >
                2023 Jun;143(6):2959-64.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20anterior%20tibial%20bowing%20on%20measurement%20of%20posterior%20tibial%20slope%20on%20conventional%20X-rays&as_occt=title&as_sauthors=%20%22T%20Hees%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_7"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              7&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_38"
                  class="annotation"
                  style="position: unset"
                >
                  Kuiper RJA</span
                >,
                <span
                  data-id="annotation_39"
                  class="annotation"
                  style="position: unset"
                >
                  Seevinck PR</span
                >,
                <span
                  data-id="annotation_40"
                  class="annotation"
                  style="position: unset"
                >
                  Viergever MA</span
                >,
                <span
                  data-id="annotation_41"
                  class="annotation"
                  style="position: unset"
                >
                  Weinans H</span
                >,
                <span
                  data-id="annotation_42"
                  class="annotation"
                  style="position: unset"
                >
                  Sakkers RJB</span
                >.
                <span
                  data-id="strong_7"
                  class="annotation strong"
                  style="position: unset"
                  >Automatic assessment of lower-limb alignment from computed
                  tomography</span
                >.
                <span
                  data-id="emphasis_7"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Bone Joint Surg Am.</span
                >
                2023 May 3;105(9):700-12.</span
              ><span class="jbjs" style="position: unset"
                ><a
                  href="?rsuite_id=59f0a28d-49ce-4927-9c7f-0e4fa86a11fe"
                  target="_new"
                  class=""
                  style="position: unset"
                  >J Bone Joint Surg Am</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_8"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              8&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_43"
                  class="annotation"
                  style="position: unset"
                >
                  Mitterer JA</span
                >,
                <span
                  data-id="annotation_44"
                  class="annotation"
                  style="position: unset"
                >
                  Huber S</span
                >,
                <span
                  data-id="annotation_45"
                  class="annotation"
                  style="position: unset"
                >
                  Schwarz GM</span
                >,
                <span
                  data-id="annotation_46"
                  class="annotation"
                  style="position: unset"
                >
                  Simon S</span
                >,
                <span
                  data-id="annotation_47"
                  class="annotation"
                  style="position: unset"
                >
                  Pallamar M</span
                >,
                <span
                  data-id="annotation_48"
                  class="annotation"
                  style="position: unset"
                >
                  Kissler F</span
                >,
                <span
                  data-id="annotation_49"
                  class="annotation"
                  style="position: unset"
                >
                  Frank BJH</span
                >,
                <span
                  data-id="annotation_50"
                  class="annotation"
                  style="position: unset"
                >
                  Hofstaetter JG</span
                >.
                <span
                  data-id="strong_8"
                  class="annotation strong"
                  style="position: unset"
                  >Fully automated assessment of the knee alignment on long leg
                  radiographs following corrective knee osteotomies in patients
                  with valgus or varus deformities</span
                >.
                <span
                  data-id="emphasis_8"
                  class="annotation emphasis"
                  style="position: unset"
                  >Arch Orthop Trauma Surg.</span
                >
                2024 Mar;144(3):1029-38.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fully%20automated%20assessment%20of%20the%20knee%20alignment%20on%20long%20leg%20radiographs%20following%20corrective%20knee%20osteotomies%20in%20patients%20with%20valgus%20or%20varus%20deformities&as_occt=title&as_sauthors=%20%22JA%20Mitterer%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_9"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              9&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_51"
                  class="annotation"
                  style="position: unset"
                >
                  Lintz F</span
                >,
                <span
                  data-id="annotation_52"
                  class="annotation"
                  style="position: unset"
                >
                  Bernasconi A</span
                >,
                <span
                  data-id="annotation_53"
                  class="annotation"
                  style="position: unset"
                >
                  Buedts K</span
                >,
                <span
                  data-id="annotation_54"
                  class="annotation"
                  style="position: unset"
                >
                  Welck M</span
                >,
                <span
                  data-id="annotation_55"
                  class="annotation"
                  style="position: unset"
                >
                  Ellis S</span
                >,
                <span
                  data-id="annotation_56"
                  class="annotation"
                  style="position: unset"
                >
                  de Cesar Netto C</span
                >.
                <span
                  data-id="strong_9"
                  class="annotation strong"
                  style="position: unset"
                  >Ankle joint bone density distribution correlates with overall
                  3-dimensional foot and ankle alignment</span
                >.
                <span
                  data-id="emphasis_9"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Bone Joint Surg Am.</span
                >
                2023 Nov 15;105(22):1801-11.</span
              ><span class="jbjs" style="position: unset"
                ><a
                  href="?rsuite_id=cf2cd807-ee24-442c-a41d-f24c340882db"
                  target="_new"
                  class=""
                  style="position: unset"
                  >J Bone Joint Surg Am</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_10"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              10&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_57"
                  class="annotation"
                  style="position: unset"
                >
                  Shah A</span
                >,
                <span
                  data-id="annotation_58"
                  class="annotation"
                  style="position: unset"
                >
                  Bohn DC</span
                >,
                <span
                  data-id="annotation_59"
                  class="annotation"
                  style="position: unset"
                >
                  Van Heest AE</span
                >,
                <span
                  data-id="annotation_60"
                  class="annotation"
                  style="position: unset"
                >
                  Hu CH</span
                >.
                <span
                  data-id="strong_10"
                  class="annotation strong"
                  style="position: unset"
                  >Congenital upper-limb differences: a 6-year literature
                  review</span
                >.
                <span
                  data-id="emphasis_10"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Bone Joint Surg Am.</span
                >
                2023 Oct 4;105(19):1537-49.</span
              ><span class="jbjs" style="position: unset"
                ><a
                  href="?rsuite_id=53b12422-dc2c-44ef-a914-7478b94cce88"
                  target="_new"
                  class=""
                  style="position: unset"
                  >J Bone Joint Surg Am</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_11"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              11&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_61"
                  class="annotation"
                  style="position: unset"
                >
                  Danisman M</span
                >,
                <span
                  data-id="annotation_62"
                  class="annotation"
                  style="position: unset"
                >
                  Emet A</span
                >,
                <span
                  data-id="annotation_63"
                  class="annotation"
                  style="position: unset"
                >
                  Kocyigit IA</span
                >,
                <span
                  data-id="annotation_64"
                  class="annotation"
                  style="position: unset"
                >
                  Hassa E</span
                >,
                <span
                  data-id="annotation_65"
                  class="annotation"
                  style="position: unset"
                >
                  Uzumcugil A</span
                >.
                <span
                  data-id="strong_11"
                  class="annotation strong"
                  style="position: unset"
                  >Examination of upper extremity length discrepancy in patients
                  with obstetric brachial plexus paralysis</span
                >.
                <span
                  data-id="emphasis_11"
                  class="annotation emphasis"
                  style="position: unset"
                  >Children (Basel).</span
                >
                2023 May 13;10(5):1-10.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Examination%20of%20upper%20extremity%20length%20discrepancy%20in%20patients%20with%20obstetric%20brachial%20plexus%20paralysis&as_occt=title&as_sauthors=%20%22M%20Danisman%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_12"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              12&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_66"
                  class="annotation"
                  style="position: unset"
                >
                  Belthur MV</span
                >,
                <span
                  data-id="annotation_67"
                  class="annotation"
                  style="position: unset"
                >
                  Huser AJ</span
                >,
                <span
                  data-id="annotation_68"
                  class="annotation"
                  style="position: unset"
                >
                  Nahm NJ</span
                >,
                <span
                  data-id="annotation_69"
                  class="annotation"
                  style="position: unset"
                >
                  McClure PK</span
                >,
                <span
                  data-id="annotation_70"
                  class="annotation"
                  style="position: unset"
                >
                  Burghardt RD</span
                >,
                <span
                  data-id="annotation_71"
                  class="annotation"
                  style="position: unset"
                >
                  Segev E</span
                >,
                <span
                  data-id="annotation_72"
                  class="annotation"
                  style="position: unset"
                >
                  Wientroub S</span
                >,
                <span
                  data-id="annotation_73"
                  class="annotation"
                  style="position: unset"
                >
                  Paley D</span
                >,
                <span
                  data-id="annotation_74"
                  class="annotation"
                  style="position: unset"
                >
                  Herzenberg JE</span
                >.
                <span
                  data-id="strong_12"
                  class="annotation strong"
                  style="position: unset"
                  >Congenital synostosis of the knee: long-term outcomes of limb
                  reconstruction surgery</span
                >.
                <span
                  data-id="emphasis_12"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Pediatr Orthop.</span
                >
                2023 Apr 1;43(4):246-54.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Congenital%20synostosis%20of%20the%20knee%3A%20long-term%20outcomes%20of%20limb%20reconstruction%20surgery&as_occt=title&as_sauthors=%20%22MV%20Belthur%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_13"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              13&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_75"
                  class="annotation"
                  style="position: unset"
                >
                  Liu Y</span
                >,
                <span
                  data-id="annotation_76"
                  class="annotation"
                  style="position: unset"
                >
                  Zheng Y</span
                >,
                <span
                  data-id="annotation_77"
                  class="annotation"
                  style="position: unset"
                >
                  Huang Y</span
                >,
                <span
                  data-id="annotation_78"
                  class="annotation"
                  style="position: unset"
                >
                  Yang G</span
                >,
                <span
                  data-id="annotation_79"
                  class="annotation"
                  style="position: unset"
                >
                  Zhu G</span
                >,
                <span
                  data-id="annotation_80"
                  class="annotation"
                  style="position: unset"
                >
                  Tan Q</span
                >,
                <span
                  data-id="annotation_81"
                  class="annotation"
                  style="position: unset"
                >
                  Wu J</span
                >,
                <span
                  data-id="annotation_82"
                  class="annotation"
                  style="position: unset"
                >
                  Liu K</span
                >,
                <span
                  data-id="annotation_83"
                  class="annotation"
                  style="position: unset"
                >
                  Mei H</span
                >.
                <span
                  data-id="strong_13"
                  class="annotation strong"
                  style="position: unset"
                  >Clinical study of a new type of telescopic rod for the
                  treatment of congenital pseudarthrosis of the tibia in
                  children</span
                >.
                <span
                  data-id="emphasis_13"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Pediatr Orthop B.</span
                >
                2023 Sep 1;32(5):405-10.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20study%20of%20a%20new%20type%20of%20telescopic%20rod%20for%20the%20treatment%20of%20congenital%20pseudarthrosis%20of%20the%20tibia%20in%20children&as_occt=title&as_sauthors=%20%22Y%20Liu%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_14"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              14&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_84"
                  class="annotation"
                  style="position: unset"
                >
                  Liu Y</span
                >,
                <span
                  data-id="annotation_85"
                  class="annotation"
                  style="position: unset"
                >
                  Tan Q</span
                >,
                <span
                  data-id="annotation_86"
                  class="annotation"
                  style="position: unset"
                >
                  Liu K</span
                >,
                <span
                  data-id="annotation_87"
                  class="annotation"
                  style="position: unset"
                >
                  Yang G</span
                >,
                <span
                  data-id="annotation_88"
                  class="annotation"
                  style="position: unset"
                >
                  Zhu G</span
                >,
                <span
                  data-id="annotation_89"
                  class="annotation"
                  style="position: unset"
                >
                  Wu J</span
                >,
                <span
                  data-id="annotation_90"
                  class="annotation"
                  style="position: unset"
                >
                  Mei H</span
                >.
                <span
                  data-id="strong_14"
                  class="annotation strong"
                  style="position: unset"
                  >Is physeal distraction osteogenesis a good choice to treat
                  CPT patients with tibial shortening?</span
                ><span
                  data-id="emphasis_14"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Pediatr Orthop B.</span
                >
                2023 Nov 1;32(6):617-20.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20physeal%20distraction%20osteogenesis%20a%20good%20choice%20to%20treat%20CPT%20patients%20with%20tibial%20shortening%3F&as_occt=title&as_sauthors=%20%22Y%20Liu%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_15"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              15&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_91"
                  class="annotation"
                  style="position: unset"
                >
                  El-Gammal TA</span
                >,
                <span
                  data-id="annotation_92"
                  class="annotation"
                  style="position: unset"
                >
                  Ali AES</span
                >,
                <span
                  data-id="annotation_93"
                  class="annotation"
                  style="position: unset"
                >
                  Kotb MM</span
                >,
                <span
                  data-id="annotation_94"
                  class="annotation"
                  style="position: unset"
                >
                  Saleh WR</span
                >,
                <span
                  data-id="annotation_95"
                  class="annotation"
                  style="position: unset"
                >
                  Ragheb YF</span
                >,
                <span
                  data-id="annotation_96"
                  class="annotation"
                  style="position: unset"
                >
                  Refai OA</span
                >,
                <span
                  data-id="annotation_97"
                  class="annotation"
                  style="position: unset"
                >
                  Morsy MM</span
                >,
                <span
                  data-id="annotation_98"
                  class="annotation"
                  style="position: unset"
                >
                  El-Gammal YT</span
                >.
                <span
                  data-id="strong_15"
                  class="annotation strong"
                  style="position: unset"
                  >Congenital pseudarthrosis of the tibia: long-term outcome of
                  treatment with intramedullary vascularized fibular graft
                  combined with Ilizarov distraction</span
                >.
                <span
                  data-id="emphasis_15"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Pediatr Orthop.</span
                >
                2023 Jul 1;43(6):e487-92.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Congenital%20pseudarthrosis%20of%20the%20tibia%3A%20long-term%20outcome%20of%20treatment%20with%20intramedullary%20vascularized%20fibular%20graft%20combined%20with%20Ilizarov%20distraction&as_occt=title&as_sauthors=%20%22TA%20El-Gammal%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_16"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              16&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_99"
                  class="annotation"
                  style="position: unset"
                >
                  Sawamura K</span
                >,
                <span
                  data-id="annotation_100"
                  class="annotation"
                  style="position: unset"
                >
                  Hamajima T</span
                >,
                <span
                  data-id="annotation_101"
                  class="annotation"
                  style="position: unset"
                >
                  Izawa M</span
                >,
                <span
                  data-id="annotation_102"
                  class="annotation"
                  style="position: unset"
                >
                  Kaneko H</span
                >,
                <span
                  data-id="annotation_103"
                  class="annotation"
                  style="position: unset"
                >
                  Kitamura A</span
                >,
                <span
                  data-id="annotation_104"
                  class="annotation"
                  style="position: unset"
                >
                  Kitoh H</span
                >.
                <span
                  data-id="strong_16"
                  class="annotation strong"
                  style="position: unset"
                  >Changes of the lower limb deformity in children with
                  FGF23-related hypophosphatemic rickets treated with Burosumab:
                  a single-center prospective study</span
                >.
                <span
                  data-id="emphasis_16"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Pediatr Orthop B.</span
                >
                2024 Jan 1;33(1):90-6.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Changes%20of%20the%20lower%20limb%20deformity%20in%20children%20with%20FGF23-related%20hypophosphatemic%20rickets%20treated%20with%20Burosumab%3A%20a%20single-center%20prospective%20study&as_occt=title&as_sauthors=%20%22K%20Sawamura%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_17"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              17&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_105"
                  class="annotation"
                  style="position: unset"
                >
                  Saragaglia D</span
                >,
                <span
                  data-id="annotation_106"
                  class="annotation"
                  style="position: unset"
                >
                  Horteur C</span
                >,
                <span
                  data-id="annotation_107"
                  class="annotation"
                  style="position: unset"
                >
                  Refaie R</span
                >.
                <span
                  data-id="strong_17"
                  class="annotation strong"
                  style="position: unset"
                  >“One millimetre equals one degree”: a simple way to achieve
                  in 92% of cases the desired correction after opening proximal
                  tibial osteotomy for genu varum</span
                >.
                <span
                  data-id="emphasis_17"
                  class="annotation emphasis"
                  style="position: unset"
                  >Arch Orthop Trauma Surg.</span
                >
                2023 May;143(5):2395-400.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%E2%80%9COne%20millimetre%20equals%20one%20degree%E2%80%9D%3A%20a%20simple%20way%20to%20achieve%20in%2092%25%20of%20cases%20the%20desired%20correction%20after%20opening%20proximal%20tibial%20osteotomy%20for%20genu%20varum&as_occt=title&as_sauthors=%20%22D%20Saragaglia%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_18"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              18&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_108"
                  class="annotation"
                  style="position: unset"
                >
                  Yang CZ</span
                >,
                <span
                  data-id="annotation_109"
                  class="annotation"
                  style="position: unset"
                >
                  Abdelhameed MA</span
                >,
                <span
                  data-id="annotation_110"
                  class="annotation"
                  style="position: unset"
                >
                  AlMaeen BN</span
                >,
                <span
                  data-id="annotation_111"
                  class="annotation"
                  style="position: unset"
                >
                  Bernard de Villeneuve F</span
                >,
                <span
                  data-id="annotation_112"
                  class="annotation"
                  style="position: unset"
                >
                  Fernandes LR</span
                >,
                <span
                  data-id="annotation_113"
                  class="annotation"
                  style="position: unset"
                >
                  Jacquet C</span
                >,
                <span
                  data-id="annotation_114"
                  class="annotation"
                  style="position: unset"
                >
                  Ollivier M</span
                >.
                <span
                  data-id="strong_18"
                  class="annotation strong"
                  style="position: unset"
                  >In slope-changing osteotomy one millimeter is not one degree:
                  results of an artificial intelligence-automated software
                  analysis</span
                >.
                <span
                  data-id="emphasis_18"
                  class="annotation emphasis"
                  style="position: unset"
                  >Int Orthop.</span
                >
                2023 Apr;47(4):915-20.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=In%20slope-changing%20osteotomy%20one%20millimeter%20is%20not%20one%20degree%3A%20results%20of%20an%20artificial%20intelligence-automated%20software%20analysis&as_occt=title&as_sauthors=%20%22CZ%20Yang%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_19"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              19&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_115"
                  class="annotation"
                  style="position: unset"
                >
                  Ma HH</span
                >,
                <span
                  data-id="annotation_116"
                  class="annotation"
                  style="position: unset"
                >
                  Lobenhoffer P</span
                >,
                <span
                  data-id="annotation_117"
                  class="annotation"
                  style="position: unset"
                >
                  Yang JCS</span
                >.
                <span
                  data-id="strong_19"
                  class="annotation strong"
                  style="position: unset"
                  >The benefits of a percutaneous supplemental screw to
                  reinforce the hinge of a medial open wedge tibial
                  osteotomy</span
                >.
                <span
                  data-id="emphasis_19"
                  class="annotation emphasis"
                  style="position: unset"
                  >Arch Orthop Trauma Surg.</span
                >
                2023 Jul;143(7):3707-13.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20benefits%20of%20a%20percutaneous%20supplemental%20screw%20to%20reinforce%20the%20hinge%20of%20a%20medial%20open%20wedge%20tibial%20osteotomy&as_occt=title&as_sauthors=%20%22HH%20Ma%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_20"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              20&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_118"
                  class="annotation"
                  style="position: unset"
                >
                  Batailler C</span
                >,
                <span
                  data-id="annotation_119"
                  class="annotation"
                  style="position: unset"
                >
                  Gicquel T</span
                >,
                <span
                  data-id="annotation_120"
                  class="annotation"
                  style="position: unset"
                >
                  Bouguennec N</span
                >,
                <span
                  data-id="annotation_121"
                  class="annotation"
                  style="position: unset"
                >
                  Steltzlen C</span
                >,
                <span
                  data-id="annotation_122"
                  class="annotation"
                  style="position: unset"
                >
                  Tardy N</span
                >,
                <span
                  data-id="annotation_123"
                  class="annotation"
                  style="position: unset"
                >
                  Cartier JL</span
                >,
                <span
                  data-id="annotation_124"
                  class="annotation"
                  style="position: unset"
                >
                  Mertl P</span
                >,
                <span
                  data-id="annotation_125"
                  class="annotation"
                  style="position: unset"
                >
                  Pailhé R</span
                >,
                <span
                  data-id="annotation_126"
                  class="annotation"
                  style="position: unset"
                >
                  Rochcongar G</span
                >,
                <span
                  data-id="annotation_127"
                  class="annotation"
                  style="position: unset"
                >
                  Fayard JM</span
                >;
                <span
                  data-id="annotation_128"
                  class="annotation"
                  style="position: unset"
                >
                  Francophone Arthroscopy Society</span
                >.
                <span
                  data-id="strong_20"
                  class="annotation strong"
                  style="position: unset"
                  >A predictive score of high tibial osteotomy survivorship to
                  help in surgical decision-making: the SKOOP score</span
                >.
                <span
                  data-id="emphasis_20"
                  class="annotation emphasis"
                  style="position: unset"
                  >Arch Orthop Trauma Surg.</span
                >
                2023 Aug;143(8):4843-51.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20predictive%20score%20of%20high%20tibial%20osteotomy%20survivorship%20to%20help%20in%20surgical%20decision-making%3A%20the%20SKOOP%20score&as_occt=title&as_sauthors=%20%22C%20Batailler%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_21"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              21&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_129"
                  class="annotation"
                  style="position: unset"
                >
                  Yamada Y</span
                >,
                <span
                  data-id="annotation_130"
                  class="annotation"
                  style="position: unset"
                >
                  Toritsuka Y</span
                >,
                <span
                  data-id="annotation_131"
                  class="annotation"
                  style="position: unset"
                >
                  Nakamura N</span
                >,
                <span
                  data-id="annotation_132"
                  class="annotation"
                  style="position: unset"
                >
                  Hiramatsu K</span
                >,
                <span
                  data-id="annotation_133"
                  class="annotation"
                  style="position: unset"
                >
                  Mitsuoka T</span
                >,
                <span
                  data-id="annotation_134"
                  class="annotation"
                  style="position: unset"
                >
                  Sugamoto K</span
                >.
                <span
                  data-id="strong_21"
                  class="annotation strong"
                  style="position: unset"
                  >Open wedge high tibial osteotomy does not decrease patellar
                  height relative to femur: a three-dimensional computer model
                  analysis</span
                >.
                <span
                  data-id="emphasis_21"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Orthop Sci.</span
                >
                2023 Sep;28(5):1052-9.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Open%20wedge%20high%20tibial%20osteotomy%20does%20not%20decrease%20patellar%20height%20relative%20to%20femur%3A%20a%20three-dimensional%20computer%20model%20analysis&as_occt=title&as_sauthors=%20%22Y%20Yamada%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_22"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              22&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_135"
                  class="annotation"
                  style="position: unset"
                >
                  Adulkasem N</span
                >,
                <span
                  data-id="annotation_136"
                  class="annotation"
                  style="position: unset"
                >
                  Wongcharoenwatana J</span
                >,
                <span
                  data-id="annotation_137"
                  class="annotation"
                  style="position: unset"
                >
                  Ariyawatkul T</span
                >,
                <span
                  data-id="annotation_138"
                  class="annotation"
                  style="position: unset"
                >
                  Chotigavanichaya C</span
                >,
                <span
                  data-id="annotation_139"
                  class="annotation"
                  style="position: unset"
                >
                  Eamsobhana P</span
                >.
                <span
                  data-id="strong_22"
                  class="annotation strong"
                  style="position: unset"
                  >A predictive score for infantile Blount disease recurrence
                  after tibial osteotomy</span
                >.
                <span
                  data-id="emphasis_22"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Pediatr Orthop.</span
                >
                2023 Apr 1;43(4):e299-304.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20predictive%20score%20for%20infantile%20Blount%20disease%20recurrence%20after%20tibial%20osteotomy&as_occt=title&as_sauthors=%20%22N%20Adulkasem%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_23"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              23&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_140"
                  class="annotation"
                  style="position: unset"
                >
                  Makarov MR</span
                >,
                <span
                  data-id="annotation_141"
                  class="annotation"
                  style="position: unset"
                >
                  Polk JL</span
                >,
                <span
                  data-id="annotation_142"
                  class="annotation"
                  style="position: unset"
                >
                  Shivers CR</span
                >,
                <span
                  data-id="annotation_143"
                  class="annotation"
                  style="position: unset"
                >
                  Jo CH</span
                >,
                <span
                  data-id="annotation_144"
                  class="annotation"
                  style="position: unset"
                >
                  Rathjen KE</span
                >,
                <span
                  data-id="annotation_145"
                  class="annotation"
                  style="position: unset"
                >
                  Birch JG</span
                >.
                <span
                  data-id="strong_23"
                  class="annotation strong"
                  style="position: unset"
                  >Clinical and radiographic assessment of adult function after
                  Blount disease in childhood: an exercise in futility</span
                >.
                <span
                  data-id="emphasis_23"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Pediatr Orthop.</span
                >
                2023 Oct 1;43(9):e757-60.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20and%20radiographic%20assessment%20of%20adult%20function%20after%20Blount%20disease%20in%20childhood%3A%20an%20exercise%20in%20futility&as_occt=title&as_sauthors=%20%22MR%20Makarov%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_24"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              24&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_146"
                  class="annotation"
                  style="position: unset"
                >
                  Chatterton BD</span
                >,
                <span
                  data-id="annotation_147"
                  class="annotation"
                  style="position: unset"
                >
                  Kuiper JH</span
                >,
                <span
                  data-id="annotation_148"
                  class="annotation"
                  style="position: unset"
                >
                  Williams DP</span
                >.
                <span
                  data-id="strong_24"
                  class="annotation strong"
                  style="position: unset"
                  >Circumferential periosteal release to treat paediatric leg
                  length discrepancy: medium term outcomes</span
                >.
                <span
                  data-id="emphasis_24"
                  class="annotation emphasis"
                  style="position: unset"
                  >Strategies Trauma Limb Reconstr.</span
                >
                2023 May-Aug;18(2):67-72.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Circumferential%20periosteal%20release%20to%20treat%20paediatric%20leg%20length%20discrepancy%3A%20medium%20term%20outcomes&as_occt=title&as_sauthors=%20%22BD%20Chatterton%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_25"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              25&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_149"
                  class="annotation"
                  style="position: unset"
                >
                  Reif TJ</span
                >,
                <span
                  data-id="annotation_150"
                  class="annotation"
                  style="position: unset"
                >
                  Geffner A</span
                >,
                <span
                  data-id="annotation_151"
                  class="annotation"
                  style="position: unset"
                >
                  Hoellwarth JS</span
                >,
                <span
                  data-id="annotation_152"
                  class="annotation"
                  style="position: unset"
                >
                  Fragomen AT</span
                >,
                <span
                  data-id="annotation_153"
                  class="annotation"
                  style="position: unset"
                >
                  Rozbruch SR</span
                >.
                <span
                  data-id="strong_25"
                  class="annotation strong"
                  style="position: unset"
                  >Precice Stryde® Magnetic Internal Lengthening Nail does not
                  impair bone healing despite radiographic and clinical
                  symptoms</span
                >.
                <span
                  data-id="emphasis_25"
                  class="annotation emphasis"
                  style="position: unset"
                  >Strategies Trauma Limb Reconstr.</span
                >
                2023 May-Aug;18(2):94-9.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Precice%20Stryde%C2%AE%20Magnetic%20Internal%20Lengthening%20Nail%20does%20not%20impair%20bone%20healing%20despite%20radiographic%20and%20clinical%20symptoms&as_occt=title&as_sauthors=%20%22TJ%20Reif%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_26"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              26&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_154"
                  class="annotation"
                  style="position: unset"
                >
                  Campanacci L</span
                >,
                <span
                  data-id="annotation_155"
                  class="annotation"
                  style="position: unset"
                >
                  Cevolani L</span
                >,
                <span
                  data-id="annotation_156"
                  class="annotation"
                  style="position: unset"
                >
                  Focaccia M</span
                >,
                <span
                  data-id="annotation_157"
                  class="annotation"
                  style="position: unset"
                >
                  Di Gennaro GL</span
                >,
                <span
                  data-id="annotation_158"
                  class="annotation"
                  style="position: unset"
                >
                  Dozza B</span
                >,
                <span
                  data-id="annotation_159"
                  class="annotation"
                  style="position: unset"
                >
                  Staals E</span
                >,
                <span
                  data-id="annotation_160"
                  class="annotation"
                  style="position: unset"
                >
                  Zuccheri F</span
                >,
                <span
                  data-id="annotation_161"
                  class="annotation"
                  style="position: unset"
                >
                  Bianchi G</span
                >,
                <span
                  data-id="annotation_162"
                  class="annotation"
                  style="position: unset"
                >
                  Donati DM</span
                >,
                <span
                  data-id="annotation_163"
                  class="annotation"
                  style="position: unset"
                >
                  Manfrini M</span
                >.
                <span
                  data-id="strong_26"
                  class="annotation strong"
                  style="position: unset"
                  >Lengthening patients previously treated for massive lower
                  limb reconstruction for bone tumors with the PRECICE 2
                  Nail</span
                >.
                <span
                  data-id="emphasis_26"
                  class="annotation emphasis"
                  style="position: unset"
                  >Children (Basel).</span
                >
                2023 Oct 31;10(11):1-12.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lengthening%20patients%20previously%20treated%20for%20massive%20lower%20limb%20reconstruction%20for%20bone%20tumors%20with%20the%20PRECICE%202%20Nail&as_occt=title&as_sauthors=%20%22L%20Campanacci%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_27"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              27&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_164"
                  class="annotation"
                  style="position: unset"
                >
                  Georgiadis AG</span
                >,
                <span
                  data-id="annotation_165"
                  class="annotation"
                  style="position: unset"
                >
                  Nahm NJ</span
                >,
                <span
                  data-id="annotation_166"
                  class="annotation"
                  style="position: unset"
                >
                  Dahl MT</span
                >.
                <span
                  data-id="strong_27"
                  class="annotation strong"
                  style="position: unset"
                  >Re-use of motorised intramedullary limb lengthening
                  nails</span
                >.
                <span
                  data-id="emphasis_27"
                  class="annotation emphasis"
                  style="position: unset"
                  >Strategies Trauma Limb Reconstr.</span
                >
                2023 May-Aug;18(2):106-10.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Re-use%20of%20motorised%20intramedullary%20limb%20lengthening%20nails&as_occt=title&as_sauthors=%20%22AG%20Georgiadis%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_28"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              28&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_167"
                  class="annotation"
                  style="position: unset"
                >
                  Iobst CA</span
                >,
                <span
                  data-id="annotation_168"
                  class="annotation"
                  style="position: unset"
                >
                  Hatfield DN</span
                >,
                <span
                  data-id="annotation_169"
                  class="annotation"
                  style="position: unset"
                >
                  Forro SD</span
                >,
                <span
                  data-id="annotation_170"
                  class="annotation"
                  style="position: unset"
                >
                  Quinnan SM</span
                >.
                <span
                  data-id="strong_28"
                  class="annotation strong"
                  style="position: unset"
                  >Magnetically driven intramedullary limb lengthening in
                  patients with pre-existing implanted programmable devices: a
                  case series</span
                >.
                <span
                  data-id="emphasis_28"
                  class="annotation emphasis"
                  style="position: unset"
                  >Strategies Trauma Limb Reconstr.</span
                >
                2023 May-Aug;18(2):111-6.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Magnetically%20driven%20intramedullary%20limb%20lengthening%20in%20patients%20with%20pre-existing%20implanted%20programmable%20devices%3A%20a%20case%20series&as_occt=title&as_sauthors=%20%22CA%20Iobst%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_29"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              29&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_171"
                  class="annotation"
                  style="position: unset"
                >
                  Depaoli A</span
                >,
                <span
                  data-id="annotation_172"
                  class="annotation"
                  style="position: unset"
                >
                  Magnani M</span
                >,
                <span
                  data-id="annotation_173"
                  class="annotation"
                  style="position: unset"
                >
                  Casamenti A</span
                >,
                <span
                  data-id="annotation_174"
                  class="annotation"
                  style="position: unset"
                >
                  Cerasoli T</span
                >,
                <span
                  data-id="annotation_175"
                  class="annotation"
                  style="position: unset"
                >
                  Ramella M</span
                >,
                <span
                  data-id="annotation_176"
                  class="annotation"
                  style="position: unset"
                >
                  Menozzi GC</span
                >,
                <span
                  data-id="annotation_177"
                  class="annotation"
                  style="position: unset"
                >
                  Mordenti M</span
                >,
                <span
                  data-id="annotation_178"
                  class="annotation"
                  style="position: unset"
                >
                  Rocca G</span
                >,
                <span
                  data-id="annotation_179"
                  class="annotation"
                  style="position: unset"
                >
                  Trisolino G</span
                >.
                <span
                  data-id="strong_29"
                  class="annotation strong"
                  style="position: unset"
                  >Is the high healing index a complication of progressive long
                  bone lengthening? Observations from a cohort of 178 children
                  treated with circular external fixation for lower limb length
                  discrepancy</span
                >.
                <span
                  data-id="emphasis_29"
                  class="annotation emphasis"
                  style="position: unset"
                  >Children (Basel).</span
                >
                2023 Sep 22;10(10):1586.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20the%20high%20healing%20index%20a%20complication%20of%20progressive%20long%20bone%20lengthening%3F%20Observations%20from%20a%20cohort%20of%20178%20children%20treated%20with%20circular%20external%20fixation%20for%20lower%20limb%20length%20discrepancy&as_occt=title&as_sauthors=%20%22A%20Depaoli%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_30"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              30&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_180"
                  class="annotation"
                  style="position: unset"
                >
                  Vogt B</span
                >,
                <span
                  data-id="annotation_181"
                  class="annotation"
                  style="position: unset"
                >
                  Laufer A</span
                >,
                <span
                  data-id="annotation_182"
                  class="annotation"
                  style="position: unset"
                >
                  Gosheger G</span
                >,
                <span
                  data-id="annotation_183"
                  class="annotation"
                  style="position: unset"
                >
                  Toporowski G</span
                >,
                <span
                  data-id="annotation_184"
                  class="annotation"
                  style="position: unset"
                >
                  Antfang C</span
                >,
                <span
                  data-id="annotation_185"
                  class="annotation"
                  style="position: unset"
                >
                  Rölfing JD</span
                >,
                <span
                  data-id="annotation_186"
                  class="annotation"
                  style="position: unset"
                >
                  Rödl R</span
                >,
                <span
                  data-id="annotation_187"
                  class="annotation"
                  style="position: unset"
                >
                  Frommer A</span
                >.
                <span
                  data-id="strong_30"
                  class="annotation strong"
                  style="position: unset"
                  >Evaluation of simultaneous bilateral femoral distraction
                  osteogenesis with antegrade intramedullary lengthening nails
                  in achondroplasia with rhizomelic short stature: a
                  retrospective study of 15 patients with a minimum follow-up of
                  2 years</span
                >.
                <span
                  data-id="emphasis_30"
                  class="annotation emphasis"
                  style="position: unset"
                  >Acta Orthop.</span
                >
                2024 Jan 30;95:47-54.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20simultaneous%20bilateral%20femoral%20distraction%20osteogenesis%20with%20antegrade%20intramedullary%20lengthening%20nails%20in%20achondroplasia%20with%20rhizomelic%20short%20stature%3A%20a%20retrospective%20study%20of%2015%20patients%20with%20a%20minimum%20follow-up%20of%202%20years&as_occt=title"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_31"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              31&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_188"
                  class="annotation"
                  style="position: unset"
                >
                  Xu Y</span
                >,
                <span
                  data-id="annotation_189"
                  class="annotation"
                  style="position: unset"
                >
                  Ma T</span
                >,
                <span
                  data-id="annotation_190"
                  class="annotation"
                  style="position: unset"
                >
                  Ren C</span
                >,
                <span
                  data-id="annotation_191"
                  class="annotation"
                  style="position: unset"
                >
                  Li M</span
                >,
                <span
                  data-id="annotation_192"
                  class="annotation"
                  style="position: unset"
                >
                  Lu Y</span
                >,
                <span
                  data-id="annotation_193"
                  class="annotation"
                  style="position: unset"
                >
                  Sun L</span
                >,
                <span
                  data-id="annotation_194"
                  class="annotation"
                  style="position: unset"
                >
                  Huang Q</span
                >,
                <span
                  data-id="annotation_195"
                  class="annotation"
                  style="position: unset"
                >
                  Wang Q</span
                >,
                <span
                  data-id="annotation_196"
                  class="annotation"
                  style="position: unset"
                >
                  Xue H</span
                >,
                <span
                  data-id="annotation_197"
                  class="annotation"
                  style="position: unset"
                >
                  Li Z</span
                >,
                <span
                  data-id="annotation_198"
                  class="annotation"
                  style="position: unset"
                >
                  Zhang K</span
                >.
                <span
                  data-id="strong_31"
                  class="annotation strong"
                  style="position: unset"
                  >Treatment of tibial large bone defects: a comparative study
                  of bone transport over an intramedullary nail in combination
                  with antibiotic-impregnated calcium sulphate versus bone
                  transport alone with antibiotic-impregnated calcium
                  sulphate</span
                >.
                <span
                  data-id="emphasis_31"
                  class="annotation emphasis"
                  style="position: unset"
                  >Injury.</span
                >
                2023 Apr;54(Suppl 2):S78-85.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treatment%20of%20tibial%20large%20bone%20defects%3A%20a%20comparative%20study%20of%20bone%20transport%20over%20an%20intramedullary%20nail%20in%20combination%20with%20antibiotic-impregnated%20calcium%20sulphate%20versus%20bone%20transport%20alone%20with%20antibiotic-impregnated%20calcium%20sulphate&as_occt=title"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_32"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              32&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_199"
                  class="annotation"
                  style="position: unset"
                >
                  Wang L</span
                >,
                <span
                  data-id="annotation_200"
                  class="annotation"
                  style="position: unset"
                >
                  Lu S</span
                >,
                <span
                  data-id="annotation_201"
                  class="annotation"
                  style="position: unset"
                >
                  Luo W</span
                >,
                <span
                  data-id="annotation_202"
                  class="annotation"
                  style="position: unset"
                >
                  Wang G</span
                >,
                <span
                  data-id="annotation_203"
                  class="annotation"
                  style="position: unset"
                >
                  Zhu Z</span
                >,
                <span
                  data-id="annotation_204"
                  class="annotation"
                  style="position: unset"
                >
                  Liu Y</span
                >,
                <span
                  data-id="annotation_205"
                  class="annotation"
                  style="position: unset"
                >
                  Gao H</span
                >,
                <span
                  data-id="annotation_206"
                  class="annotation"
                  style="position: unset"
                >
                  Fu C</span
                >,
                <span
                  data-id="annotation_207"
                  class="annotation"
                  style="position: unset"
                >
                  Ren J</span
                >,
                <span
                  data-id="annotation_208"
                  class="annotation"
                  style="position: unset"
                >
                  Zhang Y</span
                >,
                <span
                  data-id="annotation_209"
                  class="annotation"
                  style="position: unset"
                >
                  Zhang Y</span
                >.
                <span
                  data-id="strong_32"
                  class="annotation strong"
                  style="position: unset"
                  >Efficacy comparison of antibiotic bone cement-coated implants
                  and external fixations for treating infected bone
                  defects</span
                >.
                <span
                  data-id="emphasis_32"
                  class="annotation emphasis"
                  style="position: unset"
                  >Int Orthop.</span
                >
                2023 May;47(5):1171-9.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20comparison%20of%20antibiotic%20bone%20cement-coated%20implants%20and%20external%20fixations%20for%20treating%20infected%20bone%20defects&as_occt=title&as_sauthors=%20%22L%20Wang%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_33"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              33&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_210"
                  class="annotation"
                  style="position: unset"
                >
                  Allesina L</span
                >,
                <span
                  data-id="annotation_211"
                  class="annotation"
                  style="position: unset"
                >
                  Alessio-Mazzola M</span
                >,
                <span
                  data-id="annotation_212"
                  class="annotation"
                  style="position: unset"
                >
                  Belluati A</span
                >,
                <span
                  data-id="annotation_213"
                  class="annotation"
                  style="position: unset"
                >
                  Mosca S</span
                >,
                <span
                  data-id="annotation_214"
                  class="annotation"
                  style="position: unset"
                >
                  Placella G</span
                >,
                <span
                  data-id="annotation_215"
                  class="annotation"
                  style="position: unset"
                >
                  Salini V</span
                >.
                <span
                  data-id="strong_33"
                  class="annotation strong"
                  style="position: unset"
                  >Surgical treatment of critical size bone defects with
                  Masquelet technique versus bone transport: a systematic review
                  and meta-analysis of comparative studies</span
                >.
                <span
                  data-id="emphasis_33"
                  class="annotation emphasis"
                  style="position: unset"
                  >Arch Orthop Trauma Surg.</span
                >
                2023 Dec;143(12):7081-96.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20treatment%20of%20critical%20size%20bone%20defects%20with%20Masquelet%20technique%20versus%20bone%20transport%3A%20a%20systematic%20review%20and%20meta-analysis%20of%20comparative%20studies&as_occt=title&as_sauthors=%20%22L%20Allesina%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_34"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              34&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_216"
                  class="annotation"
                  style="position: unset"
                >
                  Wakefield SM</span
                >,
                <span
                  data-id="annotation_217"
                  class="annotation"
                  style="position: unset"
                >
                  Papakostidis C</span
                >,
                <span
                  data-id="annotation_218"
                  class="annotation"
                  style="position: unset"
                >
                  Giannoudis VP</span
                >,
                <span
                  data-id="annotation_219"
                  class="annotation"
                  style="position: unset"
                >
                  Mandía-Martínez A</span
                >,
                <span
                  data-id="annotation_220"
                  class="annotation"
                  style="position: unset"
                >
                  Giannoudis PV</span
                >.
                <span
                  data-id="strong_34"
                  class="annotation strong"
                  style="position: unset"
                  >Distraction osteogenesis versus induced membrane technique
                  for infected tibial non-unions with segmental bone loss: a
                  systematic review of the literature and meta-analysis of
                  available studies</span
                >.
                <span
                  data-id="emphasis_34"
                  class="annotation emphasis"
                  style="position: unset"
                  >Eur J Trauma Emerg Surg.</span
                >
                2023 Nov 3. Epub ahead of print.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Distraction%20osteogenesis%20versus%20induced%20membrane%20technique%20for%20infected%20tibial%20non-unions%20with%20segmental%20bone%20loss%3A%20a%20systematic%20review%20of%20the%20literature%20and%20meta-analysis%20of%20available%20studies&as_occt=title&as_sauthors=%20%22SM%20Wakefield%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_35"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              35&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_221"
                  class="annotation"
                  style="position: unset"
                >
                  Geiger EJ</span
                >,
                <span
                  data-id="annotation_222"
                  class="annotation"
                  style="position: unset"
                >
                  Geffner AD</span
                >,
                <span
                  data-id="annotation_223"
                  class="annotation"
                  style="position: unset"
                >
                  Rozbruch SR</span
                >,
                <span
                  data-id="annotation_224"
                  class="annotation"
                  style="position: unset"
                >
                  Fragomen AT</span
                >.
                <span
                  data-id="strong_35"
                  class="annotation strong"
                  style="position: unset"
                  >Management of segmental tibial bone defects with the magnetic
                  motorized intramedullary transport nail: a case series</span
                >.
                <span
                  data-id="emphasis_35"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Orthop Trauma.</span
                >
                2023 Nov 1;37(11):e459-65.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20segmental%20tibial%20bone%20defects%20with%20the%20magnetic%20motorized%20intramedullary%20transport%20nail%3A%20a%20case%20series&as_occt=title&as_sauthors=%20%22EJ%20Geiger%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_36"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              36&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_225"
                  class="annotation"
                  style="position: unset"
                >
                  Blair JA</span
                >,
                <span
                  data-id="annotation_226"
                  class="annotation"
                  style="position: unset"
                >
                  Puneky GA</span
                >,
                <span
                  data-id="annotation_227"
                  class="annotation"
                  style="position: unset"
                >
                  Swaminathan N</span
                >,
                <span
                  data-id="annotation_228"
                  class="annotation"
                  style="position: unset"
                >
                  Klahs KJ</span
                >,
                <span
                  data-id="annotation_229"
                  class="annotation"
                  style="position: unset"
                >
                  Davis JM</span
                >.
                <span
                  data-id="strong_36"
                  class="annotation strong"
                  style="position: unset"
                  >Tibial bone transport with a single implant all-internal bone
                  transport nail</span
                >.
                <span
                  data-id="emphasis_36"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Orthop Trauma.</span
                >
                2023 Jul 1;37(7):e294-300.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tibial%20bone%20transport%20with%20a%20single%20implant%20all-internal%20bone%20transport%20nail&as_occt=title&as_sauthors=%20%22JA%20Blair%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_37"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              37&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_230"
                  class="annotation"
                  style="position: unset"
                >
                  Thakeb MF</span
                >,
                <span
                  data-id="annotation_231"
                  class="annotation"
                  style="position: unset"
                >
                  Kozman MA</span
                >,
                <span
                  data-id="annotation_232"
                  class="annotation"
                  style="position: unset"
                >
                  ElGebeily MA</span
                >,
                <span
                  data-id="annotation_233"
                  class="annotation"
                  style="position: unset"
                >
                  Baraka MM</span
                >,
                <span
                  data-id="annotation_234"
                  class="annotation"
                  style="position: unset"
                >
                  Al Kersh MA</span
                >.
                <span
                  data-id="strong_37"
                  class="annotation strong"
                  style="position: unset"
                  >Bone transport through induced membrane versus conventional
                  bone transport in management of infected long-bone defects of
                  lower limbs: a randomized controlled trial</span
                >.
                <span
                  data-id="emphasis_37"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Orthop Trauma.</span
                >
                2023 Sep 1;37(9):462-8.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20transport%20through%20induced%20membrane%20versus%20conventional%20bone%20transport%20in%20management%20of%20infected%20long-bone%20defects%20of%20lower%20limbs%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22MF%20Thakeb%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_38"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              38&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_235"
                  class="annotation"
                  style="position: unset"
                >
                  Freischmidt H</span
                >,
                <span
                  data-id="annotation_236"
                  class="annotation"
                  style="position: unset"
                >
                  Guehring T</span
                >,
                <span
                  data-id="annotation_237"
                  class="annotation"
                  style="position: unset"
                >
                  Thomé P</span
                >,
                <span
                  data-id="annotation_238"
                  class="annotation"
                  style="position: unset"
                >
                  Armbruster J</span
                >,
                <span
                  data-id="annotation_239"
                  class="annotation"
                  style="position: unset"
                >
                  Reiter G</span
                >,
                <span
                  data-id="annotation_240"
                  class="annotation"
                  style="position: unset"
                >
                  Grützner PA</span
                >,
                <span
                  data-id="annotation_241"
                  class="annotation"
                  style="position: unset"
                >
                  Nolte PC</span
                >.
                <span
                  data-id="strong_38"
                  class="annotation strong"
                  style="position: unset"
                  >Treatment of large femoral and tibial bone defects with
                  plate-assisted bone segment transport</span
                >.
                <span
                  data-id="emphasis_38"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Orthop Trauma.</span
                >
                2024 May 1;38(5):285-90.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treatment%20of%20large%20femoral%20and%20tibial%20bone%20defects%20with%20plate-assisted%20bone%20segment%20transport&as_occt=title&as_sauthors=%20%22H%20Freischmidt%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_39"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              39&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_242"
                  class="annotation"
                  style="position: unset"
                >
                  Liodakis E</span
                >,
                <span
                  data-id="annotation_243"
                  class="annotation"
                  style="position: unset"
                >
                  Giannoudis VP</span
                >,
                <span
                  data-id="annotation_244"
                  class="annotation"
                  style="position: unset"
                >
                  Harwood PJ</span
                >,
                <span
                  data-id="annotation_245"
                  class="annotation"
                  style="position: unset"
                >
                  Giannoudis PV</span
                >.
                <span
                  data-id="strong_39"
                  class="annotation strong"
                  style="position: unset"
                  >Docking site interventions following bone transport using
                  external fixation: a systematic review of the literature</span
                >.
                <span
                  data-id="emphasis_39"
                  class="annotation emphasis"
                  style="position: unset"
                  >Int Orthop.</span
                >
                2024 Feb;48(2):365-88.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Docking%20site%20interventions%20following%20bone%20transport%20using%20external%20fixation%3A%20a%20systematic%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22E%20Liodakis%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_40"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              40&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_246"
                  class="annotation"
                  style="position: unset"
                >
                  Wang S</span
                >,
                <span
                  data-id="annotation_247"
                  class="annotation"
                  style="position: unset"
                >
                  Li B</span
                >,
                <span
                  data-id="annotation_248"
                  class="annotation"
                  style="position: unset"
                >
                  Yu X</span
                >,
                <span
                  data-id="annotation_249"
                  class="annotation"
                  style="position: unset"
                >
                  Wu H</span
                >,
                <span
                  data-id="annotation_250"
                  class="annotation"
                  style="position: unset"
                >
                  Liu L</span
                >.
                <span
                  data-id="strong_40"
                  class="annotation strong"
                  style="position: unset"
                  >Is ankle arthrodesis with an Ilizarov external fixator an
                  effective treatment for septic ankle arthritis? A study with a
                  minimum of 6 years of follow-up</span
                >.
                <span
                  data-id="emphasis_40"
                  class="annotation emphasis"
                  style="position: unset"
                  >Clin Orthop Relat Res.</span
                >
                2023 Apr 1;481(4):717-25.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20ankle%20arthrodesis%20with%20an%20Ilizarov%20external%20fixator%20an%20effective%20treatment%20for%20septic%20ankle%20arthritis%3F%20A%20study%20with%20a%20minimum%20of%206%20years%20of%20follow-up&as_occt=title&as_sauthors=%20%22S%20Wang%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_41"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              41&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_251"
                  class="annotation"
                  style="position: unset"
                >
                  Xie Q</span
                >,
                <span
                  data-id="annotation_252"
                  class="annotation"
                  style="position: unset"
                >
                  Li X</span
                >,
                <span
                  data-id="annotation_253"
                  class="annotation"
                  style="position: unset"
                >
                  Wang P</span
                >.
                <span
                  data-id="strong_41"
                  class="annotation strong"
                  style="position: unset"
                  >Three dimensional finite element analysis of biomechanics of
                  osteotomy ends with three different fixation methods after
                  hallux valgus minimally invasive osteotomy</span
                >.
                <span
                  data-id="emphasis_41"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Orthop Surg (Hong Kong).</span
                >
                2023 May-Aug;31(2):10225536231175235.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Three%20dimensional%20finite%20element%20analysis%20of%20biomechanics%20of%20osteotomy%20ends%20with%20three%20different%20fixation%20methods%20after%20hallux%20valgus%20minimally%20invasive%20osteotomy&as_occt=title&as_sauthors=%20%22Q%20Xie%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_42"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              42&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_254"
                  class="annotation"
                  style="position: unset"
                >
                  Dong W</span
                >,
                <span
                  data-id="annotation_255"
                  class="annotation"
                  style="position: unset"
                >
                  Shi N</span
                >,
                <span
                  data-id="annotation_256"
                  class="annotation"
                  style="position: unset"
                >
                  Wen C</span
                >,
                <span
                  data-id="annotation_257"
                  class="annotation"
                  style="position: unset"
                >
                  Zhang Y</span
                >.
                <span
                  data-id="strong_42"
                  class="annotation strong"
                  style="position: unset"
                  >An investigation of maternal psychological status of children
                  with congenital talipes equinovarus treated with the Ponseti
                  method</span
                >.
                <span
                  data-id="emphasis_42"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Child Orthop.</span
                >
                2023 Jan 5;17(2):184-90.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=An%20investigation%20of%20maternal%20psychological%20status%20of%20children%20with%20congenital%20talipes%20equinovarus%20treated%20with%20the%20Ponseti%20method&as_occt=title&as_sauthors=%20%22W%20Dong%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_43"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              43&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_258"
                  class="annotation"
                  style="position: unset"
                >
                  Zioupos S</span
                >,
                <span
                  data-id="annotation_259"
                  class="annotation"
                  style="position: unset"
                >
                  Westacott D</span
                >.
                <span
                  data-id="strong_43"
                  class="annotation strong"
                  style="position: unset"
                  >Flat-top talus after clubfoot treatment: a literature
                  review</span
                >.
                <span
                  data-id="emphasis_43"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Pediatr Orthop B.</span
                >
                2024 Jan 1;33(1):44-7.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Flat-top%20talus%20after%20clubfoot%20treatment%3A%20a%20literature%20review&as_occt=title&as_sauthors=%20%22S%20Zioupos%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_44"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              44&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_260"
                  class="annotation"
                  style="position: unset"
                >
                  McGinley J</span
                >,
                <span
                  data-id="annotation_261"
                  class="annotation"
                  style="position: unset"
                >
                  Worrall H</span
                >,
                <span
                  data-id="annotation_262"
                  class="annotation"
                  style="position: unset"
                >
                  Althoff C</span
                >,
                <span
                  data-id="annotation_263"
                  class="annotation"
                  style="position: unset"
                >
                  Clark C</span
                >,
                <span
                  data-id="annotation_264"
                  class="annotation"
                  style="position: unset"
                >
                  Jo CH</span
                >,
                <span
                  data-id="annotation_265"
                  class="annotation"
                  style="position: unset"
                >
                  Birch JG</span
                >,
                <span
                  data-id="annotation_266"
                  class="annotation"
                  style="position: unset"
                >
                  Wilson PL</span
                >,
                <span
                  data-id="annotation_267"
                  class="annotation"
                  style="position: unset"
                >
                  Ellis HB</span
                >.
                <span
                  data-id="strong_44"
                  class="annotation strong"
                  style="position: unset"
                  >Faster rate of correction with distal femoral transphyseal
                  screws versus plates in hemiepiphysiodesis for coronal-plane
                  knee deformity: age- and sex-matched cohorts of skeletally
                  immature patients</span
                >.
                <span
                  data-id="emphasis_44"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Bone Joint Surg Am.</span
                >
                2023 Aug 16;105(16):1252-60.</span
              ><span class="jbjs" style="position: unset"
                ><a
                  href="?rsuite_id=6fe0e10b-53df-430e-854c-40de6cf8de85"
                  target="_new"
                  class=""
                  style="position: unset"
                  >J Bone Joint Surg Am</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_45"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              45&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_268"
                  class="annotation"
                  style="position: unset"
                >
                  Heckel N</span
                >,
                <span
                  data-id="annotation_269"
                  class="annotation"
                  style="position: unset"
                >
                  von der Heydt K</span
                >,
                <span
                  data-id="annotation_270"
                  class="annotation"
                  style="position: unset"
                >
                  Burgkart R</span
                >,
                <span
                  data-id="annotation_271"
                  class="annotation"
                  style="position: unset"
                >
                  Bernius P</span
                >,
                <span
                  data-id="annotation_272"
                  class="annotation"
                  style="position: unset"
                >
                  Herzenberg JE</span
                >,
                <span
                  data-id="annotation_273"
                  class="annotation"
                  style="position: unset"
                >
                  Burghardt RD</span
                >.
                <span
                  data-id="strong_45"
                  class="annotation strong"
                  style="position: unset"
                  >Hemiepiphysiodesis with tension band plates versus
                  percutaneous epiphysiodesis transphyseal screws: a comparative
                  study</span
                >.
                <span
                  data-id="emphasis_45"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Limb Lengthening Reconstr.</span
                >
                2023;9(1):3-11.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hemiepiphysiodesis%20with%20tension%20band%20plates%20versus%20percutaneous%20epiphysiodesis%20transphyseal%20screws%3A%20a%20comparative%20study&as_occt=title&as_sauthors=%20%22N%20Heckel%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_46"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              46&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_274"
                  class="annotation"
                  style="position: unset"
                >
                  Eberle A</span
                >,
                <span
                  data-id="annotation_275"
                  class="annotation"
                  style="position: unset"
                >
                  Stephan A</span
                >,
                <span
                  data-id="annotation_276"
                  class="annotation"
                  style="position: unset"
                >
                  Tedeus MP</span
                >,
                <span
                  data-id="annotation_277"
                  class="annotation"
                  style="position: unset"
                >
                  Manner HM</span
                >,
                <span
                  data-id="annotation_278"
                  class="annotation"
                  style="position: unset"
                >
                  Rüdiger HA</span
                >,
                <span
                  data-id="annotation_279"
                  class="annotation"
                  style="position: unset"
                >
                  Stadelmann VA</span
                >.
                <span
                  data-id="strong_46"
                  class="annotation strong"
                  style="position: unset"
                  >Isolating factors for the prediction of rebound after guided
                  growth with tension band plating for the valgus deformity of
                  the knee</span
                >.
                <span
                  data-id="emphasis_46"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Child Orthop.</span
                >
                2023 Jul 11;17(5):459-68.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Isolating%20factors%20for%20the%20prediction%20of%20rebound%20after%20guided%20growth%20with%20tension%20band%20plating%20for%20the%20valgus%20deformity%20of%20the%20knee&as_occt=title&as_sauthors=%20%22A%20Eberle%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_47"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              47&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_280"
                  class="annotation"
                  style="position: unset"
                >
                  Retzky J</span
                >,
                <span
                  data-id="annotation_281"
                  class="annotation"
                  style="position: unset"
                >
                  Pascual-Leone N</span
                >,
                <span
                  data-id="annotation_282"
                  class="annotation"
                  style="position: unset"
                >
                  Cirrincione P</span
                >,
                <span
                  data-id="annotation_283"
                  class="annotation"
                  style="position: unset"
                >
                  Nichols E</span
                >,
                <span
                  data-id="annotation_284"
                  class="annotation"
                  style="position: unset"
                >
                  Blanco J</span
                >,
                <span
                  data-id="annotation_285"
                  class="annotation"
                  style="position: unset"
                >
                  Widmann R</span
                >,
                <span
                  data-id="annotation_286"
                  class="annotation"
                  style="position: unset"
                >
                  Dodwell E</span
                >.
                <span
                  data-id="strong_47"
                  class="annotation strong"
                  style="position: unset"
                  >The perils of sleeper plates in multiple hereditary
                  exostosis: tibial deformity overcorrection due to tether at
                  empty metaphyseal hole</span
                >.
                <span
                  data-id="emphasis_47"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Pediatr Orthop.</span
                >
                2023 Sep 1;43(8):471-4.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20perils%20of%20sleeper%20plates%20in%20multiple%20hereditary%20exostosis%3A%20tibial%20deformity%20overcorrection%20due%20to%20tether%20at%20empty%20metaphyseal%20hole&as_occt=title&as_sauthors=%20%22J%20Retzky%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_48"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              48&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_287"
                  class="annotation"
                  style="position: unset"
                >
                  Bakircioglu S</span
                >,
                <span
                  data-id="annotation_288"
                  class="annotation"
                  style="position: unset"
                >
                  Kolac UC</span
                >,
                <span
                  data-id="annotation_289"
                  class="annotation"
                  style="position: unset"
                >
                  Yigit YA</span
                >,
                <span
                  data-id="annotation_290"
                  class="annotation"
                  style="position: unset"
                >
                  Aksoy T</span
                >,
                <span
                  data-id="annotation_291"
                  class="annotation"
                  style="position: unset"
                >
                  Aksoy MC</span
                >,
                <span
                  data-id="annotation_292"
                  class="annotation"
                  style="position: unset"
                >
                  Yazici M</span
                >,
                <span
                  data-id="annotation_293"
                  class="annotation"
                  style="position: unset"
                >
                  Yilmaz G</span
                >.
                <span
                  data-id="strong_48"
                  class="annotation strong"
                  style="position: unset"
                  >Does the sleeper plate application for temporary
                  epiphysiodesis make life easier or complicated? Increased risk
                  of tethering</span
                >.
                <span
                  data-id="emphasis_48"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Pediatr Orthop.</span
                >
                2023 Oct 1;43(9):572-7.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20the%20sleeper%20plate%20application%20for%20temporary%20epiphysiodesis%20make%20life%20easier%20or%20complicated%3F%20Increased%20risk%20of%20tethering&as_occt=title&as_sauthors=%20%22S%20Bakircioglu%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_49"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              49&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_294"
                  class="annotation"
                  style="position: unset"
                >
                  Galán-Olleros M</span
                >,
                <span
                  data-id="annotation_295"
                  class="annotation"
                  style="position: unset"
                >
                  Sánchez Del Saz J</span
                >,
                <span
                  data-id="annotation_296"
                  class="annotation"
                  style="position: unset"
                >
                  Miranda-Gorozarri C</span
                >,
                <span
                  data-id="annotation_297"
                  class="annotation"
                  style="position: unset"
                >
                  Serrano JI</span
                >,
                <span
                  data-id="annotation_298"
                  class="annotation"
                  style="position: unset"
                >
                  Egea-Gámez RM</span
                >,
                <span
                  data-id="annotation_299"
                  class="annotation"
                  style="position: unset"
                >
                  Garríguez-Pérez D</span
                >,
                <span
                  data-id="annotation_300"
                  class="annotation"
                  style="position: unset"
                >
                  Alonso-Hernández J</span
                >,
                <span
                  data-id="annotation_301"
                  class="annotation"
                  style="position: unset"
                >
                  Martínez-Caballero I</span
                >.
                <span
                  data-id="strong_49"
                  class="annotation strong"
                  style="position: unset"
                  >Physeal migration during knee-guided growth with tension band
                  plates: influence of implant position</span
                >.
                <span
                  data-id="emphasis_49"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Pediatr Orthop.</span
                >
                2024 Feb 1;44(2):e174-83.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Physeal%20migration%20during%20knee-guided%20growth%20with%20tension%20band%20plates%3A%20influence%20of%20implant%20position&as_occt=title&as_sauthors=%20%22M%20Gal%C3%A1n-Olleros%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_50"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              50&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_302"
                  class="annotation"
                  style="position: unset"
                >
                  Bafor A</span
                >,
                <span
                  data-id="annotation_303"
                  class="annotation"
                  style="position: unset"
                >
                  Iobst C</span
                >,
                <span
                  data-id="annotation_304"
                  class="annotation"
                  style="position: unset"
                >
                  Samchukov M</span
                >,
                <span
                  data-id="annotation_305"
                  class="annotation"
                  style="position: unset"
                >
                  Cherkashin A</span
                >,
                <span
                  data-id="annotation_306"
                  class="annotation"
                  style="position: unset"
                >
                  Singh S</span
                >,
                <span
                  data-id="annotation_307"
                  class="annotation"
                  style="position: unset"
                >
                  Aguilar L</span
                >,
                <span
                  data-id="annotation_308"
                  class="annotation"
                  style="position: unset"
                >
                  Glatt V</span
                >.
                <span
                  data-id="strong_50"
                  class="annotation strong"
                  style="position: unset"
                  >Reverse dynamization accelerates regenerate bone formation
                  and remodeling in a goat distraction osteogenesis model</span
                >.
                <span
                  data-id="emphasis_50"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Bone Joint Surg Am.</span
                >
                2023 Dec 20;105(24):1937-46.</span
              ><span class="jbjs" style="position: unset"
                ><a
                  href="?rsuite_id=8d4c1a51-3330-4dda-bd94-a37d10900a9d"
                  target="_new"
                  class=""
                  style="position: unset"
                  >J Bone Joint Surg Am</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_51"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              51&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_309"
                  class="annotation"
                  style="position: unset"
                >
                  Brueton RN</span
                >,
                <span
                  data-id="annotation_310"
                  class="annotation"
                  style="position: unset"
                >
                  Heatley FW</span
                >,
                <span
                  data-id="annotation_311"
                  class="annotation"
                  style="position: unset"
                >
                  Brookes M</span
                >.
                <span
                  data-id="strong_51"
                  class="annotation strong"
                  style="position: unset"
                  >The effect of ultrasound on bone healing across a bone gap,
                  an experimental study of a delayed union model</span
                >.
                <span
                  data-id="emphasis_51"
                  class="annotation emphasis"
                  style="position: unset"
                  >Injury.</span
                >
                2023 Aug;54(8):110820.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20ultrasound%20on%20bone%20healing%20across%20a%20bone%20gap%2C%20an%20experimental%20study%20of%20a%20delayed%20union%20model&as_occt=title&as_sauthors=%20%22RN%20Brueton%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_52"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              52&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_312"
                  class="annotation"
                  style="position: unset"
                >
                  Takase K</span
                >,
                <span
                  data-id="annotation_313"
                  class="annotation"
                  style="position: unset"
                >
                  Fukui T</span
                >,
                <span
                  data-id="annotation_314"
                  class="annotation"
                  style="position: unset"
                >
                  Oe K</span
                >,
                <span
                  data-id="annotation_315"
                  class="annotation"
                  style="position: unset"
                >
                  Sawauchi K</span
                >,
                <span
                  data-id="annotation_316"
                  class="annotation"
                  style="position: unset"
                >
                  Yoshikawa R</span
                >,
                <span
                  data-id="annotation_317"
                  class="annotation"
                  style="position: unset"
                >
                  Yamamoto Y</span
                >,
                <span
                  data-id="annotation_318"
                  class="annotation"
                  style="position: unset"
                >
                  Hayashi S</span
                >,
                <span
                  data-id="annotation_319"
                  class="annotation"
                  style="position: unset"
                >
                  Matsumoto T</span
                >,
                <span
                  data-id="annotation_320"
                  class="annotation"
                  style="position: unset"
                >
                  Kuroda R</span
                >,
                <span
                  data-id="annotation_321"
                  class="annotation"
                  style="position: unset"
                >
                  Niikura T</span
                >.
                <span
                  data-id="strong_52"
                  class="annotation strong"
                  style="position: unset"
                  >Effect of low-intensity pulsed ultrasound on osteogenic
                  differentiation of human induced membrane-derived cells in
                  Masquelet technique</span
                >.
                <span
                  data-id="emphasis_52"
                  class="annotation emphasis"
                  style="position: unset"
                  >Injury.</span
                >
                2023 Apr 10;54(6):1444-50.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20low-intensity%20pulsed%20ultrasound%20on%20osteogenic%20differentiation%20of%20human%20induced%20membrane-derived%20cells%20in%20Masquelet%20technique&as_occt=title&as_sauthors=%20%22K%20Takase%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_53"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              53&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_322"
                  class="annotation"
                  style="position: unset"
                >
                  Bridges CS</span
                >,
                <span
                  data-id="annotation_323"
                  class="annotation"
                  style="position: unset"
                >
                  Taylor TN</span
                >,
                <span
                  data-id="annotation_324"
                  class="annotation"
                  style="position: unset"
                >
                  Lee T</span
                >,
                <span
                  data-id="annotation_325"
                  class="annotation"
                  style="position: unset"
                >
                  Moore K</span
                >,
                <span
                  data-id="annotation_326"
                  class="annotation"
                  style="position: unset"
                >
                  Shenava VR</span
                >,
                <span
                  data-id="annotation_327"
                  class="annotation"
                  style="position: unset"
                >
                  Hill JF</span
                >.
                <span
                  data-id="strong_53"
                  class="annotation strong"
                  style="position: unset"
                  >Half-pin breakage in multiplanar external fixators used for
                  pediatric deformity correction</span
                >.
                <span
                  data-id="emphasis_53"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Pediatr Orthop.</span
                >
                2023 Sep 1;43(8):e686-91.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Half-pin%20breakage%20in%20multiplanar%20external%20fixators%20used%20for%20pediatric%20deformity%20correction&as_occt=title&as_sauthors=%20%22CS%20Bridges%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_54"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              54&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_328"
                  class="annotation"
                  style="position: unset"
                >
                  Frank FA</span
                >,
                <span
                  data-id="annotation_329"
                  class="annotation"
                  style="position: unset"
                >
                  Stubbs D</span
                >,
                <span
                  data-id="annotation_330"
                  class="annotation"
                  style="position: unset"
                >
                  Ferguson JY</span
                >,
                <span
                  data-id="annotation_331"
                  class="annotation"
                  style="position: unset"
                >
                  McNally M</span
                >.
                <span
                  data-id="strong_54"
                  class="annotation strong"
                  style="position: unset"
                  >A practical definition of pin site infection</span
                >.
                <span
                  data-id="emphasis_54"
                  class="annotation emphasis"
                  style="position: unset"
                  >Injury.</span
                >
                2024 Feb;55(2):111230.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20practical%20definition%20of%20pin%20site%20infection&as_occt=title&as_sauthors=%20%22FA%20Frank%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_55"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              55&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_332"
                  class="annotation"
                  style="position: unset"
                >
                  Stoffel C</span
                >,
                <span
                  data-id="annotation_333"
                  class="annotation"
                  style="position: unset"
                >
                  de Lima E</span
                >,
                <span
                  data-id="annotation_334"
                  class="annotation"
                  style="position: unset"
                >
                  Salles MJ</span
                >.
                <span
                  data-id="strong_55"
                  class="annotation strong"
                  style="position: unset"
                  >Hydroxyapatite-coated compared with stainless steel external
                  fixation pins did not show impact in the rate of pin track
                  infection: a multicenter prospective study</span
                >.
                <span
                  data-id="emphasis_55"
                  class="annotation emphasis"
                  style="position: unset"
                  >Int Orthop.</span
                >
                2023 May;47(5):1163-9.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hydroxyapatite-coated%20compared%20with%20stainless%20steel%20external%20fixation%20pins%20did%20not%20show%20impact%20in%20the%20rate%20of%20pin%20track%20infection%3A%20a%20multicenter%20prospective%20study&as_occt=title&as_sauthors=%20%22C%20Stoffel%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_56"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              56&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_335"
                  class="annotation"
                  style="position: unset"
                >
                  Laymouna K</span
                >,
                <span
                  data-id="annotation_336"
                  class="annotation"
                  style="position: unset"
                >
                  Hammouda AI</span
                >,
                <span
                  data-id="annotation_337"
                  class="annotation"
                  style="position: unset"
                >
                  Elbatrawy YA</span
                >,
                <span
                  data-id="annotation_338"
                  class="annotation"
                  style="position: unset"
                >
                  Artemiev A</span
                >.
                <span
                  data-id="strong_56"
                  class="annotation strong"
                  style="position: unset"
                  >New minimal-invasive technique for cosmetic treatment of pin
                  site scars after removal of external fixations</span
                >.
                <span
                  data-id="emphasis_56"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Limb Lengthening Reconstr.</span
                >
                2023;9(1):32-4.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=New%20minimal-invasive%20technique%20for%20cosmetic%20treatment%20of%20pin%20site%20scars%20after%20removal%20of%20external%20fixations&as_occt=title&as_sauthors=%20%22K%20Laymouna%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_57"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              57&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_339"
                  class="annotation"
                  style="position: unset"
                >
                  Chaclas N</span
                >,
                <span
                  data-id="annotation_340"
                  class="annotation"
                  style="position: unset"
                >
                  Dyer O</span
                >,
                <span
                  data-id="annotation_341"
                  class="annotation"
                  style="position: unset"
                >
                  Mayers A</span
                >,
                <span
                  data-id="annotation_342"
                  class="annotation"
                  style="position: unset"
                >
                  Wheatley B</span
                >,
                <span
                  data-id="annotation_343"
                  class="annotation"
                  style="position: unset"
                >
                  Grandizio LC</span
                >,
                <span
                  data-id="annotation_344"
                  class="annotation"
                  style="position: unset"
                >
                  Seeley M</span
                >.
                <span
                  data-id="strong_57"
                  class="annotation strong"
                  style="position: unset"
                  >Eye of the carpenter: how well do orthopaedic surgeons
                  estimate angular measurements in derotational
                  osteotomies?</span
                ><span
                  data-id="emphasis_57"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Pediatr Orthop.</span
                >
                2024 Feb 1;44(2):112-6.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Eye%20of%20the%20carpenter%3A%20how%20well%20do%20orthopaedic%20surgeons%20estimate%20angular%20measurements%20in%20derotational%20osteotomies%3F&as_occt=title&as_sauthors=%20%22N%20Chaclas%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_58"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              58&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_345"
                  class="annotation"
                  style="position: unset"
                >
                  Munger AM</span
                >,
                <span
                  data-id="annotation_346"
                  class="annotation"
                  style="position: unset"
                >
                  Amick M</span
                >,
                <span
                  data-id="annotation_347"
                  class="annotation"
                  style="position: unset"
                >
                  Frumberg DB</span
                >.
                <span
                  data-id="strong_58"
                  class="annotation strong"
                  style="position: unset"
                  >Applications of ultrasonography in limb lengthening and
                  reconstruction</span
                >.
                <span
                  data-id="emphasis_58"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Limb Lengthening Reconstr.</span
                >
                2023;9(1):17-25.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Applications%20of%20ultrasonography%20in%20limb%20lengthening%20and%20reconstruction&as_occt=title&as_sauthors=%20%22AM%20Munger%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_59"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              59&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_348"
                  class="annotation"
                  style="position: unset"
                >
                  Flores MJ</span
                >,
                <span
                  data-id="annotation_349"
                  class="annotation"
                  style="position: unset"
                >
                  Brown KE</span
                >,
                <span
                  data-id="annotation_350"
                  class="annotation"
                  style="position: unset"
                >
                  Simister SK</span
                >,
                <span
                  data-id="annotation_351"
                  class="annotation"
                  style="position: unset"
                >
                  Sabharwal S</span
                >.
                <span
                  data-id="strong_59"
                  class="annotation strong"
                  style="position: unset"
                  >Educational and training opportunities on limb deformities
                  for surgeons and trainees in low- and middle-income countries:
                  hardly any peer-reviewed studies</span
                >.
                <span
                  data-id="emphasis_59"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Limb Lengthening Reconstr.</span
                >
                2023;9(1):41-6.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Educational%20and%20training%20opportunities%20on%20limb%20deformities%20for%20surgeons%20and%20trainees%20in%20low-%20and%20middle-income%20countries%3A%20hardly%20any%20peer-reviewed%20studies&as_occt=title&as_sauthors=%20%22MJ%20Flores%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node citation"
            data-id="article_citation_60"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              60&nbsp;<span class="text" style="position: unset"
                ><span
                  data-id="annotation_352"
                  class="annotation"
                  style="position: unset"
                >
                  Moon TJ</span
                >,
                <span
                  data-id="annotation_353"
                  class="annotation"
                  style="position: unset"
                >
                  Canitia E</span
                >,
                <span
                  data-id="annotation_354"
                  class="annotation"
                  style="position: unset"
                >
                  Amakoutou K</span
                >,
                <span
                  data-id="annotation_355"
                  class="annotation"
                  style="position: unset"
                >
                  Jasty N</span
                >,
                <span
                  data-id="annotation_356"
                  class="annotation"
                  style="position: unset"
                >
                  Sachwani N</span
                >,
                <span
                  data-id="annotation_357"
                  class="annotation"
                  style="position: unset"
                >
                  Flanagan JC</span
                >,
                <span
                  data-id="annotation_358"
                  class="annotation"
                  style="position: unset"
                >
                  Liu RW</span
                >.
                <span
                  data-id="strong_60"
                  class="annotation strong"
                  style="position: unset"
                  >Prospective multicenter preliminary validation of limb
                  deformity-modified scoliosis instruments in pediatric patients
                  with limb deformity</span
                >.
                <span
                  data-id="emphasis_60"
                  class="annotation emphasis"
                  style="position: unset"
                  >J Pediatr Orthop.</span
                >
                2024 Mar 1;44(3):e260-6.</span
              ><span class="googlescholar" style="position: unset"
                ><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prospective%20multicenter%20preliminary%20validation%20of%20limb%20deformity-modified%20scoliosis%20instruments%20in%20pediatric%20patients%20with%20limb%20deformity&as_occt=title&as_sauthors=%20%22TJ%20Moon%22"
                  target="_new"
                  class=""
                  style="position: unset"
                  >GoogleScholar</a
                ></span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <a
            href=""
            data-id="eletter_reference"
            class="annotation eletter_reference resource-reference"
            style="position: unset"
          ></a>
          <div
            class="content-node eletter-submit"
            data-id="eletters"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset">
                <a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D02d5b0ec-fb0a-4544-8b95-eb6e561c1d0f%26native%3D1"
                  target="_blank"
                  class=""
                  style="position: unset"
                  ><img
                    class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset"
                  /><span class="label">Submit an eLetter</span></a
                >
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-1"
            data-id="heading_20"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset"
                >Additional information</span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node publication-info"
            data-id="publication_info"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div class="meta-data" style="position: unset">
                <div class="journal" style="position: unset">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset">
                    <span class="" style="position: unset"
                      >The Journal of Bone and Joint Surgery</span
                    >
                  </div>
                </div>
                <div class="subject" style="position: unset">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset">
                    Guest Editorial
                  </div>
                </div>
                <div class="publishing" style="position: unset">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset">
                    <span class="" style="position: unset"
                      >August 21, 2024; 106 (16): 1447</span
                    >
                  </div>
                </div>
                <div class="doi" style="position: unset">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset">
                    <span class="" style="position: unset"
                      >10.2106/JBJS.24.00458</span
                    >
                  </div>
                </div>
                <div class="dates" style="position: unset">
                  The article was first published on
                  <b class="" style="position: unset">June 19, 2024</b>.
                </div>
              </div>
              <div
                class="content-node paragraph"
                data-id="articleinfo"
                style="position: unset"
              >
                <div class="content" style="position: unset">
                  <div
                    class="content-node paragraph"
                    data-id="paragraph_1"
                    style="position: unset"
                  >
                    <div class="content" style="position: unset">
                      <div
                        class="content-node text"
                        data-id="text_1"
                        style="position: unset"
                      >
                        <div class="content" style="position: unset">
                          <span
                            data-id="emphasis_61"
                            class="annotation emphasis"
                            style="position: unset"
                            >Investigation performed at Nationwide Children’s
                            Hospital, Columbus, Ohio</span
                          >
                        </div>
                        <div class="focus-handle" style="position: unset"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset"></div>
                  </div>
                  <div
                    class="content-node heading level-3"
                    data-id="heading_2"
                    style="position: unset"
                  >
                    <div class="content" style="position: unset">
                      <span class="text title" style="position: unset"
                        >Copyright & License</span
                      >
                    </div>
                    <div class="focus-handle" style="position: unset"></div>
                  </div>
                  <div
                    class="content-node paragraph"
                    data-id="paragraph_2"
                    style="position: unset"
                  >
                    <div class="content" style="position: unset">
                      <div
                        class="content-node text"
                        data-id="text_2"
                        style="position: unset"
                      >
                        <div class="content" style="position: unset">
                          Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated.
                        </div>
                        <div class="focus-handle" style="position: unset"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset"></div>
                  </div>
                  <div
                    class="content-node supplement"
                    data-id="supplement_1"
                    style="position: unset"
                  >
                    <div class="content" style="position: unset">
                      <div class="file" style="position: unset">
                        <span class="" style="position: unset"
                          >Open article PDF</span
                        ><a
                          class="jbjs_tracking gtm_tracking"
                          jbjs_tracking_type="download"
                          jbjs_tracking_data='{"id":"02d5b0ec-fb0a-4544-8b95-eb6e561c1d0f","type":"article","topics":[]}'
                          href="https://www.jbjs.org/reader.php?rsuite_id=02d5b0ec-fb0a-4544-8b95-eb6e561c1d0f&type=pdf&name=JBJS.24.00458.pdf"
                          target="_blank"
                          gtm_action="reader"
                          gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=02d5b0ec-fb0a-4544-8b95-eb6e561c1d0f&type=pdf&name=JBJS.24.00458.pdf"
                          jbjs_tracking_source="reader"
                          style="position: unset"
                          ><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset"
                          />
                          Download</a
                        >
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset"></div>
                  </div>
                  <div
                    class="content-node heading level-3"
                    data-id="heading_3"
                    style="position: unset"
                  >
                    <div class="content" style="position: unset">
                      <span class="text title" style="position: unset"
                        >Disclosures of Potential Conflicts of Interest</span
                      >
                    </div>
                    <div class="focus-handle" style="position: unset"></div>
                  </div>
                  <div
                    class="content-node paragraph"
                    data-id="paragraph_3"
                    style="position: unset"
                  >
                    <div class="content" style="position: unset">
                      <div
                        class="content-node text"
                        data-id="text_3"
                        style="position: unset"
                      >
                        <div class="content" style="position: unset">
                          <span
                            data-id="strong_61"
                            class="annotation strong"
                            style="position: unset"
                            >Disclosure:</span
                          >
                          The
                          <span
                            data-id="strong_62"
                            class="annotation strong"
                            style="position: unset"
                            >Disclosure of Potential Conflicts of Interest</span
                          >
                          forms are provided with the online version of the
                          article (<a
                            href="http://links.lww.com/JBJS/I69"
                            target="_blank"
                            data-id="link_1"
                            class="link"
                            style="position: unset"
                            >http://links.lww.com/JBJS/I69</a
                          >).
                        </div>
                        <div class="focus-handle" style="position: unset"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset"></div>
                  </div>
                  <div
                    class="content-node supplement"
                    data-id="supplement_2"
                    style="position: unset"
                  >
                    <div class="content" style="position: unset">
                      <div class="file" style="position: unset">
                        <span class="" style="position: unset"
                          >Disclosures of Potential Conflicts of Interest</span
                        ><a
                          class="jbjs_tracking"
                          jbjs_tracking_type="download"
                          jbjs_tracking_data='{"id":"02d5b0ec-fb0a-4544-8b95-eb6e561c1d0f","type":"disclosure","topics":[]}'
                          href="/php/content/content_api.php?op=download&rsuite_id=02d5b0ec-fb0a-4544-8b95-eb6e561c1d0f&type=zip&name=JBJS.24.00458.disclosure.zip&subtype=disclosure"
                          target="_blank"
                          jbjs_tracking_source="reader"
                          style="position: unset"
                          ><i class="fa fa-download" style="position: unset">
                            Download</i
                          ></a
                        >
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node heading level-3"
            data-id="heading_1_author_list"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="text title" style="position: unset">Authors</span>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node contributor"
            data-id="contributor_1"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div class="contributor-name" style="position: unset">
                Anirejuoritse Bafor, MBBS, FMCS(Ortho), FACS<span
                  data-id="affiliation_reference_1"
                  class="label annotation cross_reference"
                  >1</span
                >
              </div>
              <div class="affiliations" style="position: unset">
                <span class="" style="position: unset"></span>
              </div>
              <div class="contrib-data" style="position: unset">
                <img
                  class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png"
                  style="position: unset"
                /><a
                  href="https://orcid.org/0000-0001-9278-5324"
                  target="_blank"
                  class="orcid"
                  style="position: unset"
                  >https://orcid.org/0000-0001-9278-5324</a
                >
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node contributor"
            data-id="contributor_2"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <div class="contributor-name" style="position: unset">
                Christopher A. Iobst, MD, FAOA<span
                  data-id="affiliation_reference_1"
                  class="label annotation cross_reference"
                  >1</span
                >
              </div>
              <div class="affiliations" style="position: unset">
                <span class="" style="position: unset"></span>
              </div>
              <div class="emails contrib-data" style="position: unset">
                <span class="contrib-label" style="position: unset"
                  >For correspondence: </span
                ><span class="" style="position: unset"
                  ><a
                    href="mailto:Christopher.Iobst@nationwidechildrens.org"
                    class=""
                    style="position: unset"
                    >Christopher.Iobst@nationwidechildrens.org</a
                  ></span
                >
              </div>
              <div class="contrib-data" style="position: unset">
                <img
                  class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png"
                  style="position: unset"
                /><a
                  href="https://orcid.org/0000-0002-0329-5321"
                  target="_blank"
                  class="orcid"
                  style="position: unset"
                  >https://orcid.org/0000-0002-0329-5321</a
                >
              </div>
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <div
            class="content-node affiliation"
            data-id="affiliation_1"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <span class="label">1</span
              ><span class="text" style="position: unset"
                >Department of Orthopedic Surgery, Nationwide Children’s
                Hospital, Columbus, Ohio</span
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
          <a class="logo" href="home.php" style="position: unset"></a>
        </div>
      </div>
      <div
        class="surface-scrollbar content hidden"
        style="display: none; position: unset"
      >
        <div
          class="visible-area"
          style="top: 0px; height: 27571.1px; position: unset"
        ></div>
      </div>
    </div>
  </div>
</div>
`,
};
