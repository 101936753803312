import { DialogWrapper, Loader } from "@memorang/ui";
/* -----------------UI--------------- */
import { Button, IconButton, Dialog as PaperDialog } from "react-native-paper";
import ChangAvatarView from "../components/ChangeAvatarView";
import { useAvatars } from "../hooks/useAvatars";

type Props = {
	visible: boolean;
	handleClose?: () => void;
};
export const ChangeAvatarDialog = ({ visible, handleClose }: Props) => {
	const {
		avatars,
		setSelectedAvatarId,
		selectedAvatarId,
		isUpdatingAvatar,
		handleUpdateAvatar,
	} = useAvatars(handleClose);

	return (
		<DialogWrapper visible={visible} handleClose={handleClose}>
			<PaperDialog.Icon icon={"account-circle-outline"} />
			<PaperDialog.Title
				style={{
					textAlign: "center",
				}}
			>
				Choose your avatar
			</PaperDialog.Title>
			<IconButton
				icon={"close"}
				onPress={handleClose}
				style={{
					position: "absolute",
					right: 8,
					top: 6,
				}}
			/>
			<PaperDialog.Content
				style={{
					flex: 1,
					paddingBottom: 0,
					paddingHorizontal: 16,
					minHeight: 300,
				}}
			>
				{avatars?.length ? (
					<ChangAvatarView
						avatars={avatars}
						handleSelectAvatar={(id) => setSelectedAvatarId(id)}
						selectedAvatarId={selectedAvatarId}
					/>
				) : (
					<Loader />
				)}
			</PaperDialog.Content>
			<PaperDialog.Actions>
				<Button
					labelStyle={{
						paddingHorizontal: 8,
					}}
					onPress={handleClose}
				>
					Cancel
				</Button>
				<Button
					labelStyle={{
						paddingHorizontal: 8,
					}}
					loading={isUpdatingAvatar}
					disabled={isUpdatingAvatar || !selectedAvatarId}
					onPress={handleUpdateAvatar}
				>
					Change Avatar
				</Button>
			</PaperDialog.Actions>
		</DialogWrapper>
	);
};
