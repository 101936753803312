/* -----------------Types--------------- */
import type { Choice, Confidence, MixedItemTag } from "@memorang/types";

import { useAppTheme } from "@hooks/useAppTheme";
import { Box } from "@memorang/ui";
/* -----------------Third parties--------------- */
import * as Animatable from "react-native-animatable";
import ExplanationSection from "../ExplanationSection";
/* -----------------Components--------------- */
import PostAnswerStats from "../PostAnswerStats";
import { TagsList } from "../TagsList";

type Props = {
	choices: Choice[];
	timeTaken: string;
	confidence?: Confidence;
	correct?: boolean;
	currentQuestionAnswered?: boolean;
	showExplanation?: boolean;
	tags?: MixedItemTag[];
	showStats?: boolean;
	pointsEarnedStatValue?: string;
};

const BorderWrapper = ({ children }: { children: React.ReactNode }) => {
	const theme = useAppTheme();
	return (
		<Box
			borderWidth={2}
			padding={16}
			borderColor={theme.colors.divider}
			borderRadius={8}
		>
			{children}
		</Box>
	);
};
const PostAnswerModal = ({
	choices,
	timeTaken,
	confidence,
	correct,
	currentQuestionAnswered,
	showExplanation,
	tags,
	pointsEarnedStatValue,
}: Props) => {
	const ModalContent = () => (
		<Box gap={24}>
			<BorderWrapper>
				<PostAnswerStats
					timeTaken={timeTaken}
					correct={correct}
					confidence={confidence}
					numAnsweredStat={correct ? "Correct" : "Incorrect"}
					pointsEarnedStatValue={pointsEarnedStatValue}
				/>
			</BorderWrapper>
			<BorderWrapper>
				{showExplanation ? <ExplanationSection choices={choices} /> : null}
				{tags && tags.length > 0 ? <TagsList tags={tags} /> : null}
			</BorderWrapper>
		</Box>
	);

	return (
		<Animatable.View
			useNativeDriver
			duration={300}
			animation="fadeIn"
			easing="ease-out"
		>
			{currentQuestionAnswered ? <ModalContent /> : null}
		</Animatable.View>
	);
};

export default PostAnswerModal;
