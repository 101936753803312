import Slider from "@mui/material/Slider";

type Props = {
	maxValue: number;
	handleChange: (value: number) => void;
	value: number;
	step: number;
	disabled?: boolean;
	minValue?: number;
	testID?: string;
};

const CustomSlider = ({
	maxValue,
	handleChange,
	value,
	step,
	disabled,
	minValue = 0,
	testID = "custom-slider",
}: Props) => {
	return (
		<Slider
			sx={{ cursor: "grab" }}
			min={minValue}
			max={maxValue}
			onChange={(_e, value) => handleChange(value as number)}
			step={step}
			disabled={disabled}
			value={value}
			data-testid={testID}
		/>
	);
};

export default CustomSlider;
