/* -----------------Globals--------------- */
import { Image, SectionList } from "react-native";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* -----------------UI--------------- */
import { Button, Divider, Text, TouchableRipple } from "react-native-paper";

import { useExamContext } from "@features/exam";
import { useBundleStore } from "@features/store";
/* -----------------Helpers & Hooks--------------- */
import { getPremiumContext } from "@memorang/helpers";

/* -----------------Types--------------- */
import type { ExamDetails } from "@features/exam";

/* -----------------Child components--------------- */
import { ProductListItem } from "@features/store";
import { getShowStudyPackViews } from "@helpers/exams";
import { isWeb } from "@helpers/platform";
import { useAppTheme } from "@hooks/useAppTheme";
import type { ProductType } from "@memorang/types";
import { Box, EmptyState } from "@memorang/ui";
import { useRouter } from "expo-router";
import { useState } from "react";
import { PurchaseDialog } from "../PurchaseDialog";

export type Section = {
	title: string;
	data: ExamDetails[];
	icon: string;
};
type Props = {
	products: Section[];
	handleSwitchExamContext: (context: ExamDetails) => void;
	showProducts?: boolean;
	showChevronRight?: boolean;
	stickySectionHeadersEnabled?: boolean;
};
const ProductsList = ({
	products,
	handleSwitchExamContext,
	showProducts = true,
	showChevronRight,
}: Props) => {
	const router = useRouter();
	const { currentExamContext } = useExamContext();
	const theme = useAppTheme();
	const [selectedProductType, setSelectedProductType] = useState<
		ProductType | undefined
	>(undefined);
	const { setCurrentlySelectedBundleDetailsForIAP } = useBundleStore();
	const renderSectionHeader = ({
		section: { title, icon },
	}: {
		section: Section;
	}) => {
		if (products.length === 1) {
			return <Box marginTop={16} />;
		}
		return (
			<Box
				padding={16}
				gap={16}
				backgroundColor={theme.colors.elevation.level0}
			>
				<Box flexDirection="row" alignItems="center" gap={1}>
					{icon && (
						<Image
							source={{
								uri: icon,
							}}
							style={{
								width: 32,
								height: 32,
								marginRight: 8,
								tintColor: theme.colors.onSurface,
							}}
						/>
					)}
					<Text variant="titleMedium">{title}</Text>
				</Box>
				<Divider />
			</Box>
		);
	};

	const renderItem = ({ item: examItem }: { item: ExamDetails }) => {
		const {
			exam: { name: subtitle, bundles: products, id: examId },
		} = examItem;

		const isCurrentExamContext = examId === currentExamContext.examId;

		return (
			<Box paddingBottom={16}>
				<TouchableRipple
					onPress={async () => {
						await handleSwitchExamContext(examItem);
					}}
				>
					<Box
						flexDirection="row"
						alignItems="center"
						justifyContent="space-between"
						paddingHorizontal={16}
					>
						<Text
							style={{
								flex: 1,
							}}
							variant="titleSmall"
						>
							{subtitle}
						</Text>
						{showChevronRight ? (
							<Icon
								name="chevron-right"
								color={theme.colors.text}
								size={24}
								style={{
									paddingVertical: 8,
								}}
								onPress={() => {
									handleSwitchExamContext(examItem);
								}}
							/>
						) : (
							<>
								{isCurrentExamContext ? (
									<Button icon="check" disabled>
										Current Exam
									</Button>
								) : (
									<Button
										compact
										mode="text"
										onPress={() => {
											handleSwitchExamContext(examItem);
										}}
										icon="arrow-right-bottom"
									>
										Switch to Exam
									</Button>
								)}
							</>
						)}
					</Box>
				</TouchableRipple>
				{showProducts &&
					products.map((productItem) => {
						const { subscriptionExpired, subscriptionExpirationDate } =
							getPremiumContext(productItem.metadata);
						const expirationDateText = subscriptionExpirationDate
							? `Expires ${subscriptionExpirationDate}`
							: "";
						const bundleStatusText = subscriptionExpired
							? "Expired"
							: expirationDateText;

						if (
							isWeb &&
							productItem.bundle.bundleType === "STUDY_PACK" &&
							!getShowStudyPackViews(productItem.bundle.id)
						) {
							return null;
						}
						if (!isWeb && productItem.bundle.bundleType === "PRACTICE_TEST") {
							return null;
						}

						return (
							<Box key={productItem.bundle.id}>
								<ProductListItem
									bundle={productItem.bundle}
									size={64}
									statusText={bundleStatusText}
									expired={subscriptionExpired as boolean}
									showRightView
									handleOpenProductModal={() => {
										setCurrentlySelectedBundleDetailsForIAP(productItem);
										if (isWeb) {
											setSelectedProductType(productItem.bundle.bundleType);
										} else {
											router.push({
												pathname: "/purchase",
												params: {
													productType: productItem.bundle.bundleType,
													fromStore: "true",
												},
											});
										}
									}}
								/>
							</Box>
						);
					})}
			</Box>
		);
	};

	if (!products.length) {
		return (
			<Box flexGrow={1} height={"100%"}>
				<EmptyState
					title="No results found!"
					subtitle="Try a different search term"
				/>
			</Box>
		);
	}

	const showPurchaseDialog = selectedProductType != null;

	return (
		<>
			<SectionList
				sections={products}
				contentContainerStyle={{
					flexGrow: 1,
					paddingBottom: 16,
				}}
				showsVerticalScrollIndicator={false}
				keyExtractor={(item) => item.id}
				renderItem={renderItem}
				stickySectionHeadersEnabled={true}
				renderSectionHeader={renderSectionHeader}
			/>
			{showPurchaseDialog ? (
				<PurchaseDialog
					visible={showPurchaseDialog}
					handleClose={() => setSelectedProductType(undefined)}
					productType={selectedProductType}
				/>
			) : null}
		</>
	);
};

export { ProductsList };
