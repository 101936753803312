import { useUser } from "@clerk/clerk-expo";
import { QAChatWrapper } from "@features/session";
import { isWeb } from "@helpers/platform";
import { Box } from "@memorang/ui";
import ModalHeader from "components/ModalHeader";
import { Redirect, Stack, usePathname } from "expo-router";

export default function Layout() {
	const { isSignedIn, isLoaded } = useUser();

	const pathname = usePathname();

	if (!(isSignedIn || isLoaded) && pathname !== "/redeem") {
		return <Redirect href={"/(auth)/login"} />;
	}
	return (
		<Box flex={1} flexDirection="row" justifyContent="space-between">
			<Stack
				screenOptions={{
					headerShown: false,
					contentStyle: {
						flex: 1,
					},
				}}
			>
				<Stack.Screen
					name="purchase/index"
					options={{
						presentation: "modal",
						headerShown: true,
						header: () => <ModalHeader />,
					}}
				/>
				<Stack.Screen
					name="aiinsights"
					options={{
						presentation: "modal",
						headerShown: true,
						header: () => (
							<ModalHeader backIcon="arrow-left" headerTitle="AI Insights" />
						),
					}}
				/>
				<Stack.Screen
					name="session/customize"
					options={{
						presentation: "modal",
						headerShown: true,
						header: () => (
							<ModalHeader
								backIcon="arrow-left"
								headerTitle="Custom Study Session"
							/>
						),
					}}
				/>
				<Stack.Screen
					name="suggestcorrection"
					options={{
						presentation: "modal",
						headerShown: false,
					}}
				/>
				<Stack.Screen
					name="profile/change-avatar"
					options={{
						presentation: "modal",
						headerShown: true,
						header: () => (
							<ModalHeader
								backIcon="arrow-left"
								headerTitle="Choose your avatar"
							/>
						),
					}}
				/>
				<Stack.Screen
					name="feedback"
					options={{
						presentation: "modal",
						headerShown: false,
					}}
				/>
				<Stack.Screen
					name="change-country"
					options={{
						presentation: "modal",
						headerShown: false,
					}}
				/>
				<Stack.Screen
					name="exams"
					options={{
						presentation: "modal",
						headerShown: false,
					}}
				/>
				<Stack.Screen
					name="aila/[itemId]"
					options={{
						presentation: "modal",
						headerShown: false,
					}}
				/>
			</Stack>
			{isWeb && <QAChatWrapper />}
		</Box>
	);
}
