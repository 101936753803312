/* Lens Base styles */
/* Textish Styles
-------------------------------------------------------------------------------*/

.content-node.text .content {
	/* text-align: justify; */
}

/* Paragraph Node
-------------------------------------------------------------------------------*/
.content-node.paragraph .content {
	min-height: 28px;
}

/* Heading Node
-------------------------------------------------------------------------------*/



.content-node.heading .content {
	font-weight: 600;
	line-height: 40px;
	min-height: 40px;
}

.content-node.heading.level-1 .content {
	font-size: 1.375rem;  /* 22px */
}

.content-node.heading.level-2 .content {
	font-size: 1rem;  /* 16px */
	font-style: italic;
}

.content-node.heading.level-3 .content {
	font-size: 0.875rem;  /* 14px */
}

/* List Node
-------------------------------------------------------------------------------*/
.content-node.list {
	padding: 10px 0px;
	padding-top: 0px;
}

.content-node .listitem {
	padding: 2px 0px;
	padding-left: 20px;
	position: relative;
}

.content-node .listitem .bullet {
	position: absolute;
	left: 0px;
	top: 14px;
	width: 4px;
	height: 4px;
	background: #bbb;
}

.content-node.list .content {
	min-height: 30px;
}

.list[class*="nested-level"] {
	padding: 0;
}

.content-node.list.level-2 .content {
	font-size: 1rem;  /* 16px */
}

.content-node.list.level-3 .content {
	font-size: 0.875rem;  /* 14px */
}

.content-node.list.nested-level-1 {
	margin-left: 16px;
}

.content-node.list.nested-level-2 {
	margin-left: 32px;
}

.content-node.list.nested-level-3 {
	margin-left: 48px;
}

.content-node.list.nested-level-4 {
	margin-left: 64px;
}

.content-node.list.nested-level-5 {
	margin-left: 80px;
}

ul.simple {
	list-style-type: none;
}

ul.simple li .label {
	top: 0;
	padding: 0;
	float: left;
	left: -40px;
	width: 0;
	white-space: nowrap;
}

.list[class*="nested-level"] ul.simple li .label {
	left: -16px;
}

ul ul {
	padding-left: 0px;
}

/* Image Node
-------------------------------------------------------------------------------*/
.content-node.image .content {
	padding: 0px;
}

.content-node.image div.image-char {
	width: 100%;
}

.content-node.image div.selected .overlay {
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background: rgba(0, 169, 255, 0.25);
}

.content-node.image div img {
	width: 100%;
}

/* Codeblock Node
-------------------------------------------------------------------------------*/
.content-node.codeblock > .content {
	border: 1px dashed #ddd;
	line-height: 20px;
	padding: 10px 10px;
}

.content-node.codeblock > .content {
	font-family: "Menlo", monospace;
	font-size: 0.875rem;  /* 14px */
	color: #777;
	min-height: 28px;
}

.lens-article .content-node.cover {
	/* text-align: center; */
}

.lens-article .content-node.cover .content {
	background: none;
}

.lens-article .content-node.cover .title {
	font-weight: 600;
	line-height: 1.3em;
	font-size: 1.375rem;  /* 22px */
	padding-top: 20px;
}

.lens-article .content-node.cover .subtitle {
	font-weight: 600;
	line-height: 1.3em;
	font-size: 1rem;  /* 16px */
	padding-top: 10px;
}

.lens-article .content-node.cover .label {
	padding: 0 2px 0 2px;
	margin: 0 2px 0 2px;
	text-align: center;
}

.lens-article .content-node.cover .title .label {
	font-size: 1rem;  /* 16px */
	top: -0.2em;
}

.lens-article .content-node.cover .subtitle .label {
	font-size: 0.75rem;  /* 12px */
}

.lens-article .content-node.cover .published-on {
	color: #999;
}

.lens-article .content-node.cover .published-on a {
	font-weight: 600;
	color: #999;
}

.lens-article .content-node.cover .published-on a:hover {
	color: #0277bd;
}

.lens-article .content-node.cover .doi {
	margin-top: 30px;
	margin-bottom: 20px;
	font-size: 0.875rem;  /* 14px */
}

.lens-article .content-node.cover .content .authors {
	padding-top: 16px;
	color: #1b6685;
	overflow: auto;
	margin-bottom: 8px;
}

.lens-article .content-node.cover .content .links {
	margin-top: 20px;
	font-size: 0.875rem;  /* 14px */
}

.lens-article .content-node.cover .content .links a {
	padding-left: 4px;
	margin-right: 20px;
}

/* One para per author */
.lens-article .content-node.cover .authors .text {
	display: inline-flex;
	padding: 0px;
	margin: 0px;
	font-size: 0.875rem;  /* 14px */
	margin-right: 10px;
	margin-bottom: 0px;
}

.lens-article .content-node.cover .authors .text.plain {
	color: #777;
	padding-left: 1px;
}

.lens-article .intro {
	font-size: 0.8125rem;  /* 13px */
	background: #fffef5;
	border: 1px solid #d8d9c1;
	color: rgba(0, 0, 0, 0.6);
	padding: 0 10px;
	line-height: 30px;
}

.lens-article .intro .intro-text {
	float: left;
}

.lens-article .intro .send-feedback {
	float: right;
	color: #e40000;
	font-weight: bold;
}

.lens-article .intro .send-feedback:hover {
	color: #ff0000;
}

.lens-article .content-node.abstract {
	background-color: #fff;
	z-index: 1;
}

.lens-article .content-node.abstract .content {
	background: none;
}

.lens-article .content-node.abstract .sections .content-node {
	padding-top: 5px;
	padding-bottom: 5px;
	word-wrap: break-word;
}

.lens-article .content-node.abstract .sections ul.simple li .label {
	padding-top: 5px;
}

.surface.content .nodes > .content-node.supplement {
	padding-top: 0;
	padding-bottom: 0;
}

.surface.content > .nodes > .content-node.supplement > .content > .label {
	font-size: 1rem;  /* 16px */
	font-weight: 600;
	line-height: 40px;
	margin-top: 10px;
	margin-bottom: 15px;
}

.surface.content > .nodes > .content-node.supplement > .content .file {
	padding: 10px 20px;
}

.content-node.supplement .content .file {
	font-weight: 600;
	font-size: 0.875rem;  /* 14px */
}

.content-node.supplement .content .file a {
	font-weight: 600;
}

.content-node.supplement .content .file span {
	padding-right: 3px;
}

/*
Box
--------------------------------------- */
.content-node.box > .content {
	padding: 20px;
}

.surface.content .content-node.box {
	/*margin-left: 60px;*/
	/*  padding-left: 100px;
  padding-right: 60px;*/
	/*padding: 0px;*/
	/*background: #ccc;*/
}

.document .content-node.box .content-node.paragraph {
	/*padding: 0px;*/
	/*padding: 20px 60px 20px 100px;*/
}

.surface.content .content-node.box {
	/*background: #ccc;*/
}

.surface.content .content-node.box > .content {
	border: 1px dotted #ddd;
}

.surface.content .content-node.box .content {
	background: #fbfbfb;
}

.surface.content .content-node.box > .content > .label {
	font-weight: 600;
	padding-bottom: 20px;
}

/*
Citations 
--------------------------------------- */
.lens-article .resources .content-node.citation .resource-header .name {
	font-weight: normal;
	font-size: 0.75rem;  /* 12px */
	color: #888;
}

.lens-article .content-node.citation .content .title {
	font-size: 0.875rem;  /* 14px */
	font-weight: 600;
	margin-bottom: 20px;
}

.lens-article .content-node.citation .content .authors {
	font-size: 0.875rem;  /* 14px */
}

.lens-article .content-node.citation .content .source {
	/*color: #777;*/
}

.lens-article .content-node.citation .content .citation-urls .url {
	padding-right: 20px;
}

/*
Definition 
--------------------------------------- */
.lens-article .content-node.definition .description {
	font-size: 0.875rem;  /* 14px */
}

/* Figure Node
-------------------------------------------------------------------------------*/
/* When displayed in resource panel */
.resources .nodes > .content-node.figure .label {
	display: none;
}

.panel.content .nodes > .content-node.figure .header {
	color: #777;
	padding-bottom: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.panel.content .nodes > .content-node.figure .header i {
	margin: 0 8px;
}

.panel.content .nodes > .content-node.figure .header .image-download {
	cursor: pointer;
}

/* Special styles when figure is displayed in document flow */
.panel.content .nodes > .content-node.figure > .content {
	padding: 15px;
	border: 1px solid #ddd;
}

.content-node.figure div.image-wrapper {
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
}

.content-node.figure div .image-wrapper img {
	max-height: 500px;
	max-width: 100%;
}

.lens-article .table-caption-label {
	font-weight: bold;
	display: block;
	text-align: center;
}

.lens-article .content-node.html-table > .content {
	/* padding: 20px; */
}

.lens-article .content-node.html-table > .content img {
	width: 100%;
}

.lens-article .content-node.html-table .table-wrapper {
	width: 100%;
	overflow: auto !important;
	max-height: 80vh;
	margin-inline: auto;
	height: auto !important;
}


.lens-article .content-node.html-table table {
	position: relative;
	border-collapse: collapse;
	border-spacing: 0;
	margin-bottom: 20px;
	margin: 0 auto;
	transform: scale(1) !important;
	font-size: 0.875rem !important;  /* 14px */
	border: 1px solid lightgrey;
}

.lens-article .content-node.html-table thead tr {
	background: #eee;
}

/* Table sub tags */
.lens-article .content-node.html-table table sub {
	vertical-align: baseline;
	position: relative;
	top: 0.4em;
	font-size: 0.6875rem;  /* 11px */
}

.lens-article .content-node.html-table table td,
th {
	padding: 5px;
	font-size: 0.75rem;  /* 12px */
	border: 1px solid lightgrey;
	/* Enable white-space: nowrap; if author controls wrapping (<br> in HTML)
  /* white-space: nowrap; */
	vertical-align: top;
}

.lens-article .content-node.html-table table thead td,
th {
	font-size: 0.8125rem;  /* 13px */
	font-weight: bold;
}

.lens-article .content-node.html-table .footers {
	padding-top: 10px;
	font-size: 0.6875rem;  /* 11px */
	background: #fff;
}

.resources .content-node.video .video-wrapper {
	text-align: center;
	margin-bottom: 20px;
}

.resources .content-node.video .content video {
	/* We use a fix value of 100% now (instead of max-width: 100%), so small videos don't look so ugly; */
	width: 100%;
	max-height: 500px;
}

/* Fullscreen overrides */
.resources .content-node.video.fullscreen .content .video-wrapper {
	padding: 50px 0;
	text-align: center;
}

.lens-article .content-node.caption .caption-title {
	font-weight: 600;
	font-size: 0.875rem;  /* 14px */
}

.lens-article .content-node.caption > .content > .content-node.paragraph {
	padding-bottom: 10px;
}

/*
Contributor
--------------------------------------- */
.lens-article .resources .content-node.contributor .resource-header .name {
	font-weight: normal;
	font-size: 0.75rem;  /* 12px */
	color: #888;
}

.surface.content .nodes > .content-node.contributor {
	padding-top: 0px;
	padding-bottom: 0px;
}

.lens-article .content-node.contributor .affiliation {
	margin-top: 12px;
	margin-bottom: 12px;
}

.lens-article .content-node.contributor .contributor-bio {
	padding-top: 16px;
}

.lens-article .content-node.contributor .contributor-bio .bio {
	font-size: 0.875rem;  /* 14px */
}

.lens-article .content-node.contributor .contributor-bio .bio .content {
	background: transparent;
}

.lens-article .content-node.contributor .contributor-bio img {
	max-width: 120px;
	padding-right: 20px;
	padding-bottom: 20px;
	float: left;
	display: block;
}

.lens-article .content-node.contributor .contributor-name {
	font-size: 0.875rem;  /* 14px */
	font-weight: 600;
	margin-bottom: 0px;
}

.lens-article .content-node.contributor .contrib-label {
	font-weight: 600;
}

.lens-article .content-node.contributor .contrib-data {
	margin-bottom: 2px;
}

.lens-article .content-node.contributor .contributor-name .label {
	padding: 0px 2px 0px 2px;
	margin-right: 2px;
	margin-left: 2px;
}

/* Publication Info */
.lens-article .content-node.publication-info {
	color: #333;
	font-size: 0.875rem;  /* 14px */
}

.lens-article .content-node.publication-info table {
	padding: 10px 0;
	border: none;
	width: 100%;
}

.lens-article .content-node.publication-info .meta-data > .dates {
	margin-top: 10px;
}

.lens-article .content-node.publication-info .label {
	float: left;
	width: 100px;
	font-size: 0.875rem;  /* 14px */
	color: #999;
}

.lens-article .content-node.publication-info .value {
	margin-left: 140px;
}

.article .resources .nodes > .content-node.publication-info > .content {
	border: none;
	padding: 20px;
}

.article
	.resources
	.nodes
	> .content-node.publication-info
	.content-node[data-id="articleinfo"]
	.heading.level-3
	.content {
	font-size: 1rem;  /* 16px */
	margin-top: 12px;
	/* The 'content-node paragraph' below has a padding top making the space between the heading and text 22px rather than 12. */
	/* margin-bottom: 12px; */
}

.article
	.resources
	.nodes
	> .content-node.publication-info
	.content-node[data-id="articleinfo"]
	.content-node {
	padding-top: 10px;
}

.article
	.resources
	.nodes
	> .content-node.publication-info
	.content-node[data-id="articleinfo"]
	.content-node.footnote.article-note
	.content-node {
	padding-top: 0;
}

.content-node.verse-group {
	padding: 10px 0px;
}

/* verse_line Node
-------------------------------------------------------------------------------*/
/* Related Article Node
-------------------------------------------------------------------------------*/
.related-articles-box {
	border: 1px solid #4f759c;
	background: #e3eaf1;
	padding: 0.6em 1em;
	border-radius: 5px;
}

.mobile .related-articles-box {
	margin-top: 10px;
}

.related-article .heading {
	font-size: 1.2em;
	font-weight: 700;
	display: flex;
	align-items: center;
	gap: 5px;
}

.related-article .heading i {
	font-size: 0.9em;
}

.related-article + .related-article .heading {
	margin-top: 0.8em;
}

.related-article .article-info {
	font-size: 0.9em;
	margin-top: 0.3em;
}

.related-article .article-info > * {
	margin-top: 3px;
}

.related-article .article-info .article-title {
	font-weight: 700;
	font-size: 1.1em;
}

.related-article .article-info .article-title .subtitle {
	font-weight: 600;
	font-size: 0.9em;
}

.related-article .article-info .article-citations {
	display: flex;
	align-items: start;
}

.related-article a {
	text-decoration: none;
	color: blue;
}

.related-article .article-citations > img {
	margin-right: 3px;
	width: 24px;
}

.text-with-ellipsis {
	line-height: 25px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.text-with-ellipsis.one-line {
	max-height: 25px;
	-webkit-line-clamp: 1;
}

/* Surface
-------------------------------------------------------------------------------*/
.surface {
	position: relative;
}

/* Very important dude */
/* Makes sure .nodes is used as a reference for absolute positioning */
.surface .nodes {
	position: relative;
}

/* Generic node styles */
.surface .content-node {
	position: relative;
}

.surface .nodes > .content-node {
	/*margin: 0 60px;*/
}

/* Cursor
-------------------------------------------------------------------------------*/
.surface .cursor {
	position: absolute;
	top: 0px;
	height: 20px;
	width: 2px;
	background: #00acea;
}

/*
  blinking from http://codepen.io/hofweber/pen/vbJHc
  thanks patch!
*/
.surface .cursor {
	-webkit-animation: blink 1.2s step-end infinite;
	-moz-animation: blink 1.2s step-end infinite;
	-ms-animation: blink 1.2s step-end infinite;
	-o-animation: blink 1.2s step-end infinite;
	animation: blink 1.2s step-end infinite;
}

@-webkit-keyframes blink {
	0% {
		background: #00acea;
	}
	50% {
		background: transparent;
	}
}
@-moz-keyframes blink {
	0% {
		background: #00acea;
	}
	50% {
		background: transparent;
	}
}
@-ms-keyframes blink {
	0% {
		background: #00acea;
	}
	50% {
		background: transparent;
	}
}
@-o-keyframes blink {
	0% {
		background: #00acea;
	}
	50% {
		background: transparent;
	}
}
@keyframes blink {
	0% {
		background: #00acea;
	}
	50% {
		background: transparent;
	}
}
/* Fix weird behavior
-------------------------------------------------------------------------------*/
/*
When using event delegation if the parent element has a click handler, when
the user touches anything inside this element, the whole region will gray
out (on iOS 5.1), or flicker (on iOS 6). */
* {
	-webkit-tap-highlight-color: transparent;
}

/* Clearfix solution */
.container:after {
	content: "";
	display: table;
	clear: both;
}

/* Article
-------------------------------------------------------------------------------*/
.article {
	height: 100%;
}

/* Surface specific styles */
.hidden {
	visibility: none;
}

.content-node .content {
	margin: 0px;
}

/* Main content panel
======================================================================= */
.article .document {
	line-height: 28.5px;
	font-size: 0.875rem;  /* 14px */
	position: relative;
	float: left;
	overflow: hidden;
	height: 100%;
	width: 50%;
	border-right: 1px solid #ddd;
}

.width100 {
	width: 100% !important;
}

.article .document .surface.content {
	position: absolute;
	top: 0px;
	bottom: 0px;
	right: 0px;
	left: 0px;
	overflow-y: scroll;
	overflow-x: hidden;
	/* Hide the content panel's scrollbar in Firefox */
	scrollbar-width: none;
	/* Hide the content panel's scrollbar in MS Edge */
	-ms-overflow-style: none;
	/*overflow: auto;*/
	/*height: 100%;*/
	-webkit-overflow-scrolling: touch;
}

.article .document .surface.content .nodes {
	min-height: 100%;
	padding-left: 20px;
	padding-right: 0px;
	background: #ffffff;
	padding-bottom: 50px;
}

/* Back Navigation
=======================================================================*/
/* Context Toggles
=======================================================================*/
.article .panel.hidden {
	visibility: hidden;
}

.menu-bar {
	position: absolute;
	top: 0px;
	height: 40px;
	left: 0px;
	right: 0px;
	overflow: hidden;
	/*background: #444;*/
	color: #444;
	border-bottom: 1px solid #ddd;
	z-index: 20000;
}

.menu-bar .context-toggles a {
	display: block;
	float: left;
	font-size: 0.8125rem;  /* 13px */
	font-weight: 600;
	line-height: 40px;
	color: rgba(0, 0, 0, 0.4);
	border-right: 1px solid #ddd;
	padding-right: 15px;
	padding-left: 15px;
}

.menu-bar .context-toggles a.context-toggle.hidden {
	display: none;
}

.menu-bar .context-toggles a:hover {
	/*color: #fff;*/
	color: rgba(0, 0, 0, 0.5);
	background: rgba(0, 0, 0, 0.03);
}

.menu-bar .context-toggles a.active {
	background: rgba(0, 0, 0, 0.05);
	color: #444;
	border-bottom: 3px solid #444;
}

@media (max-width: 1500px) {
	.menu-bar .context-toggles:not(.external-menu) a {
		width: 48px;
		padding-top: 4px;
		font-size: 0;
		text-align: center;
	}
	.menu-bar .context-toggles:not(.external-menu) a > .fa {
		font-size: 0.8125rem;  /* 13px */
	}
}
/* Resources Panel
======================================================================= */
.article .resources {
	float: right;
	line-height: 25px;
	width: 50%;
	height: 100%;
	padding-left: 50px;
	padding-right: 50px;
	position: relative;
}

/* It's not exactly good to have the overflow-y: scroll for the container AND the surface.
   There should be just one overflowing container, if possible
*/
.article .resources .panel {
	position: absolute;
	top: 40px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	/*  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;*/
}

.article .resources .surface {
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	overflow-y: scroll;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
}

.article .nodes {
	padding-left: 50px;
	padding-right: 50px;
}

.article .resources .nodes {
	padding-left: 0px;
	padding-right: 20px;
}

.article .resources .nodes > .content-node {
	position: relative;
	background: #fff;
	border-bottom: 1px solid #ddd;
	border-left: 3px solid transparent;
	padding: 0px;
}

.article .resources .nodes > .content-node.publication-info {
	border-bottom: unset;
}

.article .resources .nodes > .content-node > .content {
	border-top: none;
}

.article .resources .nodes > .content-node.contributor > .content {
	padding-bottom: 8px;
}

.article .contrib-data img.orcid {
	margin-right: 8px;
	display: inline-block;
	vertical-align: middle;
	width: 1.2em;
}

.article .contrib-data a.orcid {
	vertical-align: middle;
}

.article
	.resources
	.nodes
	> .content-node[data-id$="author_list"].heading.level-3
	> .content {
	font-size: 1rem;  /* 16px */
}

/* Clickable header */
.article .resources .content-node .resource-header {
	padding-top: 15px;
	font-weight: 600;
	position: relative;
}

.article .resources .content-node.contributor .resource-header div {
	display: none;
}

/* TOC View
----------------------------------------------------------------------- */
.article .resources .panel .toc {
	padding-left: 20px;
	padding-right: 20px;
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	overflow: auto;
	padding-top: 16px;
	padding-bottom: 30px;
	z-index: 1;
	background: white;
}

/* Info View
----------------------------------------------------------------------- */
/* Consistent Resource Header for figures captions and person cards
----------------------------------------------------------------------- */
.article .resources .resource-header .name {
	display: block;
	min-height: 30px;
	font-size: 1rem;  /* 16px */
	line-height: 21px;
	padding: 0px 20px;
	padding-right: 200px;
}

.article .resources .content-node .reference-count {
	font-size: 0.8125rem;  /* 13px */
	position: absolute;
	right: 20px;
	top: 40px;
	text-align: right;
}

/* Resource toggles */
.article .resources .content-node .resource-header .toggles {
	position: absolute;
	top: 0px;
	right: 0px;
	overflow: auto;
}

/* Resource toggle */
.article .resources .content-node .resource-header .toggle {
	float: left;
	font-size: 0.75rem;  /* 12px */
	font-weight: 600;
	bottom: 0px;
	text-align: center;
	line-height: 50px;
	color: rgba(0, 0, 0, 0.4);
	padding: 0px 20px;
}

.article .resources .content-node .resource-header .toggle.image-download {
	cursor: pointer;
}

.article .resources .content-node .resource-header .toggle i {
	padding-right: 5px;
}

.article
	.resources
	.figures
	.content-node.highlighted
	.resource-header
	.toggle.toggle-res,
.article
	.resources
	.math
	.content-node.highlighted
	.resource-header
	.toggle.toggle-res {
	color: rgba(145, 187, 4, 1);
}

.article
	.resources
	.citations
	.content-node.highlighted
	.resource-header
	.toggle.toggle-res {
	color: rgba(11, 157, 217, 1);
}

.article
	.resources
	.info
	.content-node.highlighted
	.resource-header
	.toggle.toggle-res {
	color: #5c6570;
}

.article .resources .content-node .resource-header .toggle:hover {
	color: rgba(0, 0, 0, 0.6);
}

#container .article .content-node.highlighted .fa.fa-eye-slash {
	display: inline;
}

.article .resources .content-node .findnext_control,
.article .resources .content-node .hide_control {
	display: none;
}

.article .resources .content-node.highlighted .find_control {
	display: none;
}

.article .resources .content-node.highlighted_next .findnext_control {
	display: inline;
}

.article .resources .content-node.highlighted_last .findnext_control {
	display: none;
}

.article .resources .content-node.highlighted_last .hide_control {
	display: inline;
}

/* Resource Fullscreen Mode
======================================================================= */
/* Styles for fullscreen toggle on resource cards */
.article
	.resources
	.figures
	.content-node.fullscreen
	.resource-header
	.toggle.toggle-fullscreen,
.article
	.resources
	.math
	.content-node.fullscreen
	.resource-header
	.toggle.toggle-fullscreen {
	color: rgba(145, 187, 4, 1);
}

#container .article .content-node.fullscreen {
	position: fixed;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	z-index: 100000;
	margin-bottom: 0px;
	margin-right: 0px;
	padding: 0px;
	-webkit-overflow-scrolling: touch;
}

#container .article .content-node.fullscreen > .content {
	position: absolute;
	top: 80px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	overflow: auto;
}

/* Info panel overrides
----------------------------------------------------------------------- */
#container .article .surface.info .content-node.caption .content {
	padding: 20px;
}

/* Annotations
----------------------------------------------------------------------- */
.article span.annotation {
	/*  -webkit-transition-property: background, color;
    -moz-transition-property: background, color;
    transition-property: background, color;

    -webkit-transition-duration: 0.1s;
    -moz-transition-duration: 0.1s;
    transition-duration: 0.1s;*/
}

/* Small caps */
span.annotation.sc {
	font-variant: small-caps;
}

span.annotation.formula_reference,
span.publication_reference {
	padding-left: 3px;
	padding-right: 3px;
}

.article span.emphasis,
.article em {
	font-style: italic;
}

.article span.superscript,
.article sup {
	vertical-align: super;
	font-size: 0.75rem;  /* 12px */
}

.article span.subscript,
.article sub {
	vertical-align: sub;
	font-size: 0.75rem;  /* 12px */
}

.article .cover span.superscript,
.article .cover span.subscript,
.article .cover sup,
.article .cover sub {
	font-size: inherit;
}

.article span.strong,
.article strong {
	font-weight: bold;
}

.article span.underline {
	text-decoration: underline;
}

.article span.label {
	vertical-align: baseline;
	position: relative;
	top: -0.4em;
	font-size: 0.75rem;  /* 12px */
	padding: 5px;
	font-weight: bold;
}

.article .emails.contrib-data span span {
	margin-right: 5px;
}

.content-node .idea {
	background-color: rgba(102, 182, 32, 0.3);
}

.content-node .question {
	background-color: rgba(16, 167, 222, 0.3);
}

.content-node .error {
	background-color: rgba(237, 96, 48, 0.3);
}

.content-node .link {
	font-weight: bold;
}

.content-node .link:hover,
.content-node .link.highlighted {
	color: rgba(11, 157, 217, 1);
}

/* Inline Code Annotations */
.content-node .code {
	font-family: Consolas, "Liberation Mono", Courier, monospace;
	background: #eee;
	font-size: 0.8125rem;  /* 13px */
	padding: 2px 3px;
	-webkit-font-smoothing: subpixel-antialiased;
}

/* Highlighted states */
.content-node .idea.highlight {
	background-color: rgba(102, 182, 32, 0.6);
}

.content-node .question.highlight {
	background-color: rgba(16, 167, 222, 0.6);
}

.content-node .error.highlight {
	background-color: rgba(237, 96, 48, 0.6);
}

/* Selected states */
.content-node .content span.selected {
	background: rgba(0, 0, 0, 0.08);
}

.content-node .content .idea.selected {
	background-color: rgba(102, 182, 32, 0.4);
}

.content-node .content .question.selected {
	background-color: rgba(16, 167, 222, 0.4);
}

.content-node .content .error.selected {
	background-color: rgba(237, 96, 48, 0.4);
}

/* Em, Strong, Link */
.content-node .emphasis {
	font-style: italic;
}

.content-node .strong {
	font-weight: bold;
}

.content-node .figure_reference {
	background: rgba(145, 187, 4, 0.15);
	border-bottom: 1px solid rgba(145, 187, 4, 0.6);
	color: #495a11;
	cursor: pointer;
}

.content-node .contributor_reference {
	padding: 2px 1px;
	cursor: pointer;
}

.content-node .citation_reference {
	background: rgba(11, 157, 217, 0.075);
	color: #1b6685;
	border-bottom: 1px solid rgba(11, 157, 217, 0.4);
	cursor: pointer;
	padding-right: 3px;
	padding-left: 3px;
}

.content-node .cross_reference {
	background: rgba(0, 0, 0, 0.05);
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);
	cursor: pointer;
}

.content-node .definition_reference {
	font-style: italic;
	background: rgba(0, 0, 0, 0.05);
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);
	cursor: pointer;
}

.content-node.citation .link {
	word-break: break-all;
}

/* Hover states */
.content-node .figure_reference:hover {
	background-color: rgba(145, 187, 4, 0.35);
}

.content-node .citation_reference:hover {
	background-color: rgba(11, 157, 217, 0.3);
}

.content-node .contributor_reference:hover {
	background-color: rgba(0, 0, 0, 0.05);
}

.content-node .definition_reference:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

/* Highlighted states */
.content-node .figure_reference.highlighted {
	background: rgba(145, 187, 4, 0.7);
}

.content-node .citation_reference.highlighted {
	background: rgba(11, 157, 217, 0.6);
}

.content-node .contributor_reference.highlighted {
	background-color: #5c6570;
	color: white;
}

.content-node .definition_reference.highlighted {
	background-color: #5c6570;
	color: white;
}

/* Highlighted current states */
.content-node .figure_reference.highlighted_current {
	background: rgba(145, 230, 4, 1);
}

.content-node .citation_reference.highlighted_current {
	background: rgba(11, 157, 217, 1);
}

/* Cross Reference */
.content-node .cross_reference:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

/* Reader-specific overrides for content nodes
======================================================================= */
/* For the main content area
----------------------------------------------------------------------- */
.surface.content .nodes > .content-node {
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 16px;
	padding-right: 16px;
	word-wrap: break-word;
}

/* Some extra cover spacing */
.surface.content .nodes > .content-node.cover {
	padding-top: 16px !important;
}

.surface.content .nodes > .content-node.quote {
	padding-left: 80px;
}

/* For the resources panel
----------------------------------------------------------------------- */
.resources .content-node.image div img {
	width: auto;
}

.resources .content-node.paragraph .content-node.image div img {
	width: auto;
}

/* padding for the content */
.resources .nodes > .content-node > .content {
	padding: 20px;
	padding-top: 0px;
}

/* For the resources panel */
.resources .info .nodes > .content-node.paragraph > .content > .content-node {
	padding: 20px 0;
}

.article .document .surface.content::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

.article .document .surface.content::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0);
}

/* Disable native scrollbar for resources except TOC */
.article .resources .panel::-webkit-scrollbar,
.article .resources .surface::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

.article .resources .panel::-webkit-scrollbar-thumb,
.article .resources .surface::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0);
}

/* Resources Scrollbar */
/* ---------------------------------------*/
/* make it overlay the slider for now */
.surface-scrollbar .overlay {
	z-index: 3000;
}

.resources .surface-scrollbar {
	position: absolute;
	top: 0px;
	right: 0px;
	left: auto;
	bottom: 0px;
}

#container.touchable .resources .surface-scrollbar {
	right: 5px;
}

/* Responsiveness */
/* ---------------------------------------*/
/* Hide fullscreen toggle for small screens */
#container.touchable .toggle-fullscreen {
	display: none;
}

#container.touchable .article .document .surface.content {
	right: 20px;
}

/* Only hide for IOS devices because of scrolling issues! */
#container.touchable.ios .surface-scrollbar .visible-area {
	display: none;
}

#container.touchable .surface-scrollbar .node:hover {
	opacity: 0.15;
}

#container.touchable .surface-scrollbar .arrow {
	display: none;
}

.content-node.heading .top {
	display: none;
}

/* Shared styles for selected and mouseover states
----------------------------------------------------------------------- */
/* This shouldn't live in reader.css, but we'll modularize as soon
as we have a plugin concept for functionality / panels */
/* Not... still there is no idea how to 'export' css packages

/* Active resource */
.article .resources .content-node.highlighted .resource-header .name {
	/*color: #fff;*/
}

.article .resources .figures .content-node.highlighted {
	border-left: 3px solid rgba(145, 187, 4, 1);
}

/* active citation */
.article .resources .citations .content-node.highlighted {
	border-left: 3px solid rgba(11, 157, 217, 1);
}

/* active definition */
.article .resources .definitions .content-node.highlighted {
	border-left: 3px solid #5c6570;
}

/* active contributor */
.article .resources .info .content-node.highlighted {
	border-left: 3px solid #5c6570;
}

/* Table of contents */
/*
TOC
--------------------------------------- */
.resource-view.toc .heading-ref {
	cursor: pointer;
	display: block;
	color: rgba(0, 0, 0, 0.8);
	font-weight: 600;
	font-size: 1rem;  /* 16px */
	padding: 4px 10px;
	line-height: 20px;
	margin-right: 10px;
	border: 1px solid transparent;
}

.resource-view.toc .heading-ref .label {
	display: inline;
	padding-right: 10px;
}

.resource-view.toc .heading-ref.active {
	color: rgba(0, 0, 0, 0.9);
	background: #fff;
	border: 1px solid #eee;
}

.resource-view.toc .heading-ref.level-2 {
	padding-left: 20px;
	font-size: 0.875rem;  /* 14px */
}

.resource-view.toc .heading-ref.level-3 {
	padding-left: 40px;
	font-size: 0.875rem;  /* 14px */
}

.resource-view.toc .heading-ref.level-4 {
	padding-left: 60px;
	font-size: 0.875rem;  /* 14px */
}

.resource-view.toc .heading-ref.level-5 {
	font-size: 0.875rem;  /* 14px */
}

.resource-view.toc .heading-ref:hover {
	color: rgba(0, 0, 0, 1);
}

/* Scrollbar
--------------------------------------- */
.surface-scrollbar {
	background: #eee;
	position: absolute;
	left: 0px;
	width: 20px;
	top: 0px;
	bottom: 0px;
}

.surface-scrollbar .node.overlay {
	position: absolute;
	min-height: 2px;
}

.surface-scrollbar .visible-area {
	position: absolute;
	top: 40px;
	left: 0px;
	right: 0px;
	height: 80px;
	z-index: 4000;
	background-color: rgba(0, 0, 0, 0.2);
}

.surface-scrollbar.hidden .visible-area {
	display: none;
}

.surface-scrollbar:hover .visible-area {
	background-color: rgba(0, 0, 0, 0.25);
}

.surface-scrollbar .visible-area:hover {
	background-color: rgba(0, 0, 0, 0.3);
}

.surface-scrollbar .node {
	margin: 0 0px;
	width: 20px;
}

.node.highlighted {
	background: #5c6570;
}

/* Colors for scroll-bar overlays */
.article .resources .figures .surface-scrollbar .highlighted,
.article .content .surface-scrollbar .highlighted.figure_reference,
.article .content .surface-scrollbar .highlighted.figure {
	background-color: rgba(145, 187, 4, 1);
}

.article .content .surface-scrollbar .highlighted.figure.main-occurrence {
	border-right: 3px solid #5c6148;
}

.article .resources .citations .surface-scrollbar .highlighted,
.article .content .surface-scrollbar .highlighted.citation_reference {
	background-color: rgba(11, 157, 217, 1);
}

.article .resources .definitions .surface-scrollbar .highlighted,
.article .content .surface-scrollbar .highlighted.definition_reference {
	background-color: #5c6570;
}

/* new FOCUS handles */
.panel.document .nodes > .content-node > .focus-handle {
	display: none;
	position: absolute;
	top: 0px;
	bottom: 2px;
	left: 0px;
	width: 30px;
	background: white;
	border-left: 3px solid #ccc;
	cursor: pointer;
}

.panel.document .nodes > .content-node > .focus-handle:hover {
	border-left: 3px solid #bbb;
}

/* Footnote
=============================== */
.content-node.footnote {
	font-size: 0.8125rem;  /* 13px */
	display: block !important;
	margin: 20px 0;
	border: 1px solid #ddd;
	border-radius: 5px;
	padding: 20px;
}

.surface.content .nodes > .content-node.footnote,
.article .resources .nodes > .content-node.footnote {
	border: none;
	margin: 4px 0 0 0;
	font-size: unset;
}

.surface.content .nodes > .content-node.footnote {
	padding-top: 0;
	padding-bottom: 0;
}

.surface.content .nodes > .content-node.footnote + .content-node.footnote,
.article .resources .nodes > .content-node.footnote + .content-node.footnote {
	margin-top: 0;
}

.footnote-reference > a {
	vertical-align: super;
	padding: 0 2px;
	border: 1px solid #ddd;
	font-size: 0.8125rem;  /* 13px */
}

.footnote-reference > a:hover {
	background: #eee;
}

.footnote-reference.sm-expanded > a {
	background: #ddd;
}

.footnote-reference > .footnote {
	display: none !important;
}

.footnote-reference.sm-expanded > .footnote {
	display: block !important;
}

.content-node.footnote.article-note {
	padding: 0;
	border: unset;
	font-size: 0.875rem;  /* 14px */
	margin: 20px 0 0 0;
}

.surface .content-node.footnote.author-note > .content.author-note {
	padding-bottom: 0;
}

.surface .content-node.footnote.author-note:last-child > .content.author-note {
	padding-bottom: 20px;
}

.content-node.footnote > .content.author-note > span.label {
	float: left;
	padding: 0 5px;
}

.content-node.footnote > .content.article-note > span.label {
	float: left;
	padding: 0 5px;
	width: auto;
}

.content-node.footnote .content.article-note .content-node {
	padding: 0;
}

#MathJax_Message {
	display: none !important;
}

/*
  Typography / Basics
---------------------------------------------*/
* {
	margin: 0;
}

body {
	padding: 0;
	margin: 0;
	background-color: white;
	/* -moz-transition: background-color 200ms linear;
  -o-transition: background-color 200ms linear;
  -webkit-transition: background-color 200ms linear;
  transition: background-color 200ms linear;*/
}

html {
	height: 100%;
	-webkit-font-smoothing: antialiased;
}

body.reader {
	height: 100%;
	overflow: hidden;
	position: fixed;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
}

.container::after {
	content: ""; /* not "."! */
	display: block;
	clear: both;
}

/*
General Layout
--------------------------------------- */
#container {
	position: relative;
	margin: 0 auto;
	min-width: 800px;
}

/* In reader view */
body.reader #container {
	/*max-width: 1400px;*/ /* 988px / 16px = 61.75em */
	height: 100%;
}

body,
textarea,
p,
input {
  font-family: "opensans-regular", "Source Sans Pro", Helvetica, Arial, Verdana, sans-serif;
	color: #505050;
}

a {
	color: #1b6685;
	font-weight: normal;
	text-decoration: none;
	/*  -moz-transition: background-color 100ms linear, color 100ms linear, opacity 100ms linear;
    -o-transition: background-color 100ms linear, color 100ms linear, opacity 100ms linear;
    -webkit-transition: background-color 100ms linear, color 100ms linear, opacity 100ms linear;
    transition: background-color 100ms linear, color 100ms linear, opacity 100ms linear;*/
}

a:hover {
	color: rgba(11, 157, 217, 1);
}

img {
	border: none;
	padding: 0;
	margin: 0;
}

strong {
	font-weight: 700;
}

h1,
h2,
h3 {
	font-weight: 700;
}

h1 a {
	color: white;
}

h1 a:hover {
	color: white;
}

h2 {
	font-size: 1.75em;
	padding-bottom: 20px;
}

h3,
h4,
h5,
h6 {
	margin-bottom: 20px;
	font-size: 1em;
	font-weight: 700;
}

p {
	padding-bottom: 20px;
}

p:last-child {
	padding-bottom: 0;
}

.small {
	color: #505050;
	font-size: 0.75em;
	font-weight: 400;
}

/* Use border-box box model */
* {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
}

/* Article Overrides */
#container .article .resources .info .content-node.active .resource-header {
	background: #5c6570;
}

.article .resources .nodes > #articleinfo > .content {
	border-top: 1px solid #ddd;
}

.article span.annotation.author-callout-style1 {
	font-weight: bold;
	color: rgb(183, 14, 14);
}

.article span.annotation.author-callout-style2 {
	font-weight: bold;
	color: rgb(0, 179, 255);
}

.article span.annotation.author-callout-style3 {
	font-weight: bold;
	color: rgb(4, 195, 4);
}

.article span.annotation.author-callout-style4 {
	font-weight: bold;
	color: gold;
}

/* main
   --------------------------------------- */
#main {
	position: relative;
}

body.reader #main {
	height: 100%;
}

#container .loading {
	position: absolute;
	top: 15px;
	left: 70px;
	right: 0px;
	height: 49px;
	line-height: 50px;
	font-size: 0.75rem;  /* 12px */
}

/* Loading bar */
.spinner-wrapper {
	position: fixed;
	display: none;
	left: 50%;
	margin-left: -75px;
	width: 150px;
	top: 40%;
	z-index: 5000;
}

body.loading .spinner-wrapper {
	display: block;
}

.spinner-wrapper .spinner {
	width: 40px;
	height: 40px;
	margin: 0 auto;
	background: #444;
	-webkit-animation: rotateplane 1.2s infinite ease-in-out;
	animation: rotateplane 1.2s infinite ease-in-out;
}

.spinner-wrapper .message {
	color: #444;
	text-align: center;
	font-size: 0.75rem;  /* 12px */
	padding-top: 20px;
}

@-webkit-keyframes rotateplane {
	0% {
		-webkit-transform: perspective(120px);
	}
	50% {
		-webkit-transform: perspective(120px) rotateY(180deg);
	}
	100% {
		-webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
	}
}
@keyframes rotateplane {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
	}
	50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
	}
	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}
/* Footnote reference Node
-------------------------------------------------------------------------------*/
.caption .sm-expanded .footnote {
	position: relative;
}

.sm-expanded .footnote {
	background: lightgray;
	position: absolute;
	margin: 0;
	padding: 10px;
	right: 0;
	left: 0;
}

.footnote-reference a {
	border: none;
	padding-left: 0;
}

.document .content-node.figure-group > .content {
	padding: 15px;
	border: 1px solid #ddd;
}

.content-node.figure-group .caption .caption-title {
	color: #777;
}

.resources .content-node.figure-group > .content {
	padding: 15px;
}

.fullscreen .action-toggle-resource {
	display: none;
}

.document .content-node.formula {
	padding: 30px 60px;
	padding-left: 100px;
	min-height: 80px;
	text-align: center;
}

.document .content-node.formula .label {
	position: absolute;
	right: 50px;
	top: 0px;
	bottom: 0px;
	line-height: 40px;
}

/* TODO: This must be smarter */
.document .content-node .MathJax_Display {
	margin: 0;
	padding: 20px;
	overflow: auto;
}

.document .content-node.formula .MathJax_Preview img {
	width: 80%;
}

.textual_annotation:after {
	content: " ";
}

.back {
	cursor: pointer;
}

.pubmed {
	margin-left: 8px;
}

.pubmed a {
	font-weight: bold;
}

.googlescholar {
	margin-left: 8px;
}

.googlescholar a {
	font-weight: bold;
}

.jbjs {
	margin-left: 8px;
}

.jbjs a {
	font-weight: bold;
}

.lens-article .content-node.html-table .caption {
	text-align: center;
}

.lens-article .content-node.html-table table {
	border-style: solid;
}

.lens-article .content-node.html-table table caption {
	text-align: left;
}

.lens-article .content-node.html-table .footers .footer {
	line-height: 0.875rem;  /* 14px */
}

.lens-article .content-node.html-table .table-caption-label {
	padding-right: 16px;
}

.surface.content > .nodes > .content-node.videosummary > .content > .label {
	font-size: 1rem;  /* 16px */
	font-weight: 600;
	line-height: 40px;
	margin-top: 5px;
	margin-bottom: 5px;
}

/* Bug fix - video player control panel was visible on other tabs */
.article > .resources > .panel.hidden .video-js {
	display: none;
}

video {
	max-height: unset !important;
}

.vjs-playlist img {
	width: 40%;
	float: left;
}

.vjs-playlist-title-container {
	width: 100%;
}

.vjs-playlist-name {
	width: 100%;
	text-align: right;
}

.lens-article .content-node.affiliation .label {
	font-weight: normal;
	font-size: 0.75rem;  /* 12px */
	color: #888;
}

.article .nodes > .content-node.affiliation:last-child {
	padding-bottom: 20px;
}

.article .nodes > .content-node.affiliation > .content {
	padding-bottom: 0;
}

.article .nodes > .content-node.affiliation {
	border-bottom: none;
	padding-top: 0;
	padding-bottom: 0;
}

/* Infographic Node
-------------------------------------------------------------------------------*/
.surface.supplemental
	> .nodes
	> .content-node
	> .content
	> .infographic-wrapper
	> .viewer
	> .pages {
	height: 500px;
}

.surface.content
	> .nodes
	> .content-node
	> .content
	> .infographic-wrapper
	> .viewer
	> .pages {
	height: 300px;
	overflow: auto;
}

.content-node.infographic > .content > .label {
	display: inline-block;
	margin-right: 40px;
}

.surface.content > .nodes > .content-node.infographic > .content > .label {
	font-size: 1rem;  /* 16px */
	font-weight: 600;
	line-height: 40px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.content-node.infographic > .content > .link {
	display: inline-block;
}

.viewer {
	position: relative !important;
	text-align: center;
}

.viewer > .progress-bar {
	position: absolute !important;
	top: 0;
	left: 0;
	width: 0%;
	height: 8px;
	background-color: blue;
}

.viewer > .pages {
	overflow: hidden;
	text-align: center;
	background-color: #808080;
	padding: 16px 16px 0;
	cursor: -webkit-grab;
	cursor: -moz-grab;
	cursor: -o-grab;
	cursor: grab;
}

.viewer > .pages:active {
	cursor: -webkit-grabbing;
	cursor: -moz-grabbing;
	cursor: -o-grabbing;
	cursor: grabbing;
}

.viewer.full-screen > .pages {
	height: 100% !important;
}

.viewer > .pages > .page {
	margin-bottom: 16px;
	box-shadow: 0 0 4px 3px;
}

.viewer > .toolbar {
	position: absolute !important;
	bottom: 0;
	width: 100%;
}

.viewer > .toolbar > button {
	margin: 8px;
	min-width: 32px;
	height: 32px;
	border-radius: 16px;
	border: 1px solid #505050;
	background-color: white;
	box-shadow: 0 0 4px;
}

.full-screen {
	width: 100% !important;
	height: 100% !important;
}

.full-screen-pseudo {
	overflow: hidden !important;
}

.full-screen-pseudo .full-screen {
	position: fixed !important;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 9999999;
}

.infographic_reference {
	display: none;
}

video {
	max-height: unset !important;
}

.vjs-playlist img {
	width: 40%;
	float: left;
}

.vjs-playlist-title-container {
	width: 100%;
}

.vjs-playlist-name {
	width: 100%;
	text-align: right;
}

.videosummary_reference {
	display: none;
}

video {
	max-height: unset !important;
}

.vjs-playlist img {
	width: 40%;
	float: left;
}

.vjs-playlist-title-container {
	width: 100%;
}

.vjs-playlist-name {
	width: 100%;
	text-align: right;
}

.authorinsights_reference {
	display: none;
}

.supplement_reference {
	display: none;
}

.surface.content .nodes > .content-node.eletter {
	padding-top: 0;
	padding-bottom: 0;
}

.content-node.eletter > .content {
	padding: 10px 20px;
}

.content-node.eletter .content .file {
	font-weight: 600;
	font-size: 1rem;  /* 16px */
}

.content-node.eletter .content .file a {
	font-weight: 600;
}

.content-node.eletter .content .file span {
	padding-right: 3px;
}

.surface.content .nodes > .content-node.eletter-submit {
	padding-top: 0;
	padding-bottom: 0;
	display: none !important;
}

.surface.content > .nodes > .content-node.eletter-submit > .content .link {
	padding: 0 20px;
}

.content-node.eletter-submit > .content .link {
	position: relative !important;
}

.surface.content > .nodes > .content-node.eletter-submit > .content > .label {
	font-size: 1rem;  /* 16px */
	font-weight: 600;
	line-height: 40px;
	margin-top: 10px;
	margin-bottom: 15px;
}

.content-node.eletter-submit > .content a > .label {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font-size: 0.875rem;  /* 14px */
}

.eletter_reference {
	display: none;
}

.menu-bar .context-toggles.external-menu {
	float: right;
	border-left: 1px solid #ddd;
}

.menu-bar .context-toggles.external-menu a {
	color: #1b6685;
}

.surface.supplemental > .nodes > .content-node > .content {
	padding-top: 20px;
}

.highlight {
	background-color: orange;
	transition: background-color 0.5s ease-in-out;
}

.image-download {
	display: none;
}

.videosummary {
	display: none;
}
