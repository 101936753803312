import type { Article } from "../../../../../types";

export const Article520: Article = {
	id: 520,
	rsuiteId: "e54020bc-6e41-4b4c-8f08-a33b0fa740ee",
	type: "evidence-based orthopaedics",
	title:
		"In Older Patients with an Unreconstructible Distal Humeral Fracture, Elbow Hemiarthroplasty and Total Elbow Arthroplasty Did Not Differ for Function at ≥2 Years",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/520.png",
	subSpecialties: ["shoulder_elbow"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">In Older Patients with an Unreconstructible Distal
                Humeral Fracture, Elbow Hemiarthroplasty and Total Elbow Arthroplasty Did Not Differ for Function at ≥2
                Years</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Joaquin
                      Sanchez-Sotelo, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">Jonsson EÖ, Ekholm C, Hallgren HB, Nestorson J, Etzner M,
                  Adolfsson L. Elbow hemiarthroplasty and total elbow arthroplasty provided a similar functional outcome
                  for unreconstructable distal humeral fractures in patients aged 60 years or older: a multicenter
                  randomized controlled trial. J Shoulder Elbow Surg. 2024 Feb;33(2):343-55.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_7" class="annotation strong"
                    style="position: unset;">Question:</span> In older patients with an unreconstructible distal humeral
                  fracture, what are the effects of elbow hemiarthroplasty (EHA) versus total elbow arthroplasty (TEA)
                  on function?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_8" class="annotation strong"
                    style="position: unset;">Design:</span> Randomized (allocation concealed), unblinded, controlled
                  trial with ≥2 years of follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_9" class="annotation strong"
                    style="position: unset;">Setting:</span> 3 hospitals in Sweden.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_10" class="annotation strong"
                    style="position: unset;">Patients</span>: 40 patients who were ≥60 years of age (mean age, 75 years;
                  89% women; 60% with AO/OTA C1.3 fractures), were living independently, and had an unreconstructible
                  distal humeral fracture (not amenable to stable internal fixation). Key exclusion criteria were
                  inability to restore elbow stability at the time of replacement, degenerative disease of the elbow,
                  pathologic fracture, preexisting upper-extremity condition, or concurrent injury affecting function. 5
                  patients died during follow-up; 35 patients (88%) were included in the analysis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_11" class="annotation strong"
                    style="position: unset;">Intervention:</span> Patients were allocated to EHA, using the Latitude
                  Anatomic (Wright Medical) (n = 20), or TEA, using 2 types of semiconstrained arthroplasty (Latitude
                  Total Elbow Arthroplasty, Wright Medical; or Discovery Elbow System, Lima) (n = 20). 7 surgeons with
                  extensive experience in elbow arthroplasty performed the procedures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_12" class="annotation strong"
                    style="position: unset;">Main outcome measures:</span> The primary outcome was the Disabilities of
                  the Arm, Shoulder and Hand (DASH) score at ≥2 years. Secondary outcomes included the Mayo Elbow
                  Performance Score (MEPS), the EuroQol-5 Dimensions (EQ-5D) index, grip strength, and range of motion.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_13" class="annotation strong"
                    style="position: unset;">Main results:</span> Results for the primary outcome and other functional
                  outcomes are in <a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>. EHA and
                  TEA did not differ for grip strength (mean difference, 0.4 kg [95% confidence interval (CI), −4.9 to
                  5.7]) or for various measures of range of motion, except for flexion, which was borderline significant
                  (mean difference, −9° [95% CI, −19° to 0°]). 6 patients in each group reported adverse events
                  (periprosthetic joint infection, joint instability, periprosthetic fracture, prominent hardware, and
                  stiffness requiring arthrolysis).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_11" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Elbow hemiarthroplasty (EHA) versus total elbow
                          arthroplasty (TEA) in older adults with unreconstructible distal humeral fractures
                          (intention-to-treat analysis)<span data-id="table_footnote_reference_5"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 261px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Outcomes</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Mean score</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Mean difference at ≥2 years (95% CI)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">EHA</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">TEA</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">DASH score<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">21.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">27.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">–5.6
                          (–18.6 to 7.5)<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MEPS<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">85.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">88.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−3.2
                          (−15.4 to 8.9)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">EQ-5D index<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.92</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.86</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.06
                          (−0.02 to 0.14)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">CI = confidence interval, DASH = Disabilities of the Arm, Shoulder and
                        Hand, MEPS = Mayo Elbow Performance Score, EQ-5D = EuroQol-5 Dimensions.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Score range, 0 to 100 (most disability).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">≥16 patients per group were required to detect a 10-point difference
                        with 80% power, α = 0.05%.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Score range, 0 to 100 (excellent).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">Score range, 0 to 1 (full health).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_14" class="annotation strong"
                    style="position: unset;">Conclusion:</span> In patients ≥60 years of age with unreconstructible
                  distal humeral fractures, EHA and TEA did not differ for function at ≥2 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Source of funding: Gothenburg Society of Medicine.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="emphasis_5" class="annotation emphasis"
                    style="position: unset;">For correspondence: Dr. Eythor Ö Jonsson, Sahlgrenska
                    Universitetssjukhuset, Område 3 Ortopedi, Mölndal, Sweden. E-mail address: <a
                      href="mailto:eythororn@gmail.com" target="_blank" data-id="link_2" class="link"
                      style="position: unset;">eythororn@gmail.com</a></span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The relative indications and expected outcomes of TEA
                  versus EHA for distal humeral fractures not amenable to internal fixation continue to be debated.
                  Although most consider TEA to be the gold standard<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">2</span></a>, there is substantial interest in the potential value of EHA
                  to avoid ulnar loosening and polyethylene wear, and possibly allow unrestricted activity<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">4</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The multicenter randomized controlled trial by Jonsson and
                  colleagues was well planned and executed. However, the “worst” fractures (unreconstructible columns)
                  were excluded, which reduces the generalizability of the results. The trial would have been cleaner if
                  only the Latitude implant had been used in all elbows, as opposed to implantation of the Discovery
                  implant in only 1 TEA. Selection of the DASH score as a primary outcome measure is controversial.
                  Radiographs were available for most, but not all, elbows. Finally, follow-up times (&lt;3 years for
                  both groups) were too short to detect meaningful rates of mechanical failure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Between-group differences in DASH, MEPS, and EQ-5D were
                  not significant, but a so-called “functional” arc of flexion and extension was achieved in 35% of the
                  EHA group and 59% of the TEA group. Although the difference between groups did not reach significance,
                  it could be considered clinically relevant. Complication rates were similar, and although ulnar
                  erosion after EHA was uncommon, follow-up was only 2 to 3 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">From a practical perspective, this trial does indicate
                  that patient-reported outcomes and reoperation rates at 2 to 3 years are similar after TEA and EHA.
                  TEA should be favored in patients with preexisting arthritis or if there are concerns about achieving
                  joint stability at the time of surgery. TEA is also very safe and effective in older women. Currently,
                  most would agree that EHA should be considered for men who are physiologically young and present with
                  an unreconstructible distal humeral fracture. In my opinion, it is not clear whether unrestricted use
                  of the elbow can be safely recommended after hemiarthroplasty for distal humeral fractures because it
                  might increase the risk of painful ulnar erosion in the long term.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Barco R</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Streubel PN</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Morrey BF</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Sanchez-Sotelo J</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Total Elbow Arthroplasty for
                  Distal Humeral Fractures: A Ten-Year-Minimum Follow-up Study</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2017 Sep
                20;99(18):1524-31.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1420145"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Dehghan N</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Furey M</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Schemitsch L</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Ristevski B</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Goetz T</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Schemitsch EH</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> McKee M</span>; Canadian
                Orthopaedic Trauma Society (COTS). <span data-id="strong_2" class="annotation strong"
                  style="position: unset;">Long-term outcomes of total elbow arthroplasty for distal humeral fracture:
                  results from a prior randomized clinical trial</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2019
                Nov;28(11):2198-204.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20outcomes%20of%20total%20elbow%20arthroplasty%20for%20distal%20humeral%20fracture%3A%20results%20from%20a%20prior%20randomized%20clinical%20trial&as_occt=title&as_sauthors=%20%22N%20Dehghan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Taylor F</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Page R</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Wheeler J</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Lorimer M</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Corfield S</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Peng Y</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Burnton J</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Distal humeral hemiarthroplasty
                  compared to total elbow replacement for distal humeral fractures: a registry analysis of 906
                  procedures</span>. <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2024 Feb;33(2):356-65.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Distal%20humeral%20hemiarthroplasty%20compared%20to%20total%20elbow%20replacement%20for%20distal%20humeral%20fractures%3A%20a%20registry%20analysis%20of%20906%20procedures&as_occt=title&as_sauthors=%20%22F%20Taylor%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Wilfred AM</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Akhter S</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Horner NS</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Aljedani A</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Khan M</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Alolabi B</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Outcomes and complications of
                  distal humeral hemiarthroplasty for distal humeral fractures - A systematic review</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Shoulder Elbow.</span> 2022
                Feb;14(1):65-74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20and%20complications%20of%20distal%20humeral%20hemiarthroplasty%20for%20distal%20humeral%20fractures%20-%20A%20systematic%20review&as_occt=title&as_sauthors=%20%22AM%20Wilfred%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3De54020bc-6e41-4b4c-8f08-a33b0fa740ee%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Evidence-Based Orthopaedics</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">August 21, 2024;
                      106 (16): 1504</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00566</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">June 3, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;e54020bc-6e41-4b4c-8f08-a33b0fa740ee&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=e54020bc-6e41-4b4c-8f08-a33b0fa740ee&type=pdf&name=JBJS.24.00566.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=e54020bc-6e41-4b4c-8f08-a33b0fa740ee&type=pdf&name=JBJS.24.00566.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_5" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_6"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I53" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I53</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;e54020bc-6e41-4b4c-8f08-a33b0fa740ee&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=e54020bc-6e41-4b4c-8f08-a33b0fa740ee&type=zip&name=JBJS.24.00566.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Joaquin Sanchez-Sotelo, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Division of Shoulder and Elbow Surgery, Mayo Clinic, Rochester,
                Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 6351.4px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
