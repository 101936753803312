import type { Article } from "../../../../../types";

export const Article524: Article = {
	id: 524,
	rsuiteId: "92569169-3cfa-40b7-a840-10f9b8a81118",
	type: "scientific article",
	title:
		"A Matched Comparison of Implant and Functional Outcomes of Cemented and Cementless Unicompartmental Knee Replacements",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=92569169-3cfa-40b7-a840-10f9b8a81118&type=jpeg&name=JBJS.23.01418f1",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">A Matched Comparison of Implant and Functional Outcomes
                of Cemented and Cementless Unicompartmental Knee Replacements</div>
              <div class="text subtitle" style="position: unset;">A Study from the National Joint Registry for England,
                Wales, Northern Ireland and the Isle of Man and the Hospital Episode Statistics Patient Reported Outcome
                Measures Database</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Hasan R.
                      Mohammad, MRCS, MRes(Dist), DPhil(Oxon)</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Andrew Judge,
                      BSc, MSc, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 1 more
                      contributor</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_41" class="annotation strong"
                          style="position: unset;">Background:</span> Unicompartmental knee replacement (UKR) is an
                        effective treatment for end-stage medial compartment osteoarthritis, but there can be problems
                        with fixation. The cementless UKR was introduced to address this issue. It is unknown how its
                        functional outcomes compare with those of the cemented version on a national scale. We performed
                        a matched comparison of the clinical and functional outcomes of cementless and cemented UKRs.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_42" class="annotation strong"
                          style="position: unset;">Methods:</span> Using the National Joint Registry for England, Wales,
                        Northern Ireland and the Isle of Man (NJR), 14,764 Oxford UKRs with linked data regarding
                        patient-reported outcomes were identified. A total of 6,906 UKRs (3,453 cemented and 3,453
                        cementless) were propensity score matched on the basis of patient, surgical, and implant
                        factors.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_43" class="annotation strong"
                          style="position: unset;">Results:</span> The 10-year cumulative implant survival rate was
                        93.0% (95% confidence interval [CI], 90.0% to 95.1%) for cementless UKRs and 91.3% (95% CI,
                        89.0% to 93.0%) for cemented UKRs. The cementless UKR group had a significantly lower revision
                        risk (hazard ratio [HR], 0.74; p = 0.02). Subgroup analyses showed a stronger effect size (HR,
                        0.66) among UKRs performed by high-caseload surgeons (i.e., surgeons performing ≥30 UKRs/year).
                        In the overall cohort, the postoperative Oxford Knee Score (OKS) in the cementless group (mean
                        and standard deviation, 39.1 ± 8.7) was significantly higher (p = 0.001) than that in the
                        cemented group (38.5 ± 8.6). The cementless group gained a mean of 17.6 ± 9.3 points in the OKS
                        postoperatively and the cemented group gained 16.5 ± 9.6 points, with a difference of 1.1 points
                        between the groups (p &lt; 0.001). The difference in OKS points gained postoperatively was
                        highest among UKRs performed by high-caseload surgeons, with the cementless group gaining 1.8
                        points more (p &lt; 0.001) than the cemented group.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_44" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The cementless UKR demonstrated better 10-year
                        implant survival and postoperative functional outcomes than the cemented UKR. The difference was
                        largest among UKRs performed by high-caseload surgeons, with the cementless fixation group
                        having an HR for revision of 0.66 and an approximately 2-point greater improvement in the OKS
                        compared with the cemented fixation group.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_45" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The 2 main surgical treatments for end-stage medial
                  compartment osteoarthritis are total knee replacement (TKR) and unicompartmental knee replacement
                  (UKR). Although UKR offers advantages over TKR, including faster recovery, better function, and lower
                  morbidity and mortality<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">3</span></a>, its revision rate is higher according to joint registries<a
                    href="" data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">6</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The most commonly utilized UKR is the mobile-bearing
                  Oxford (Zimmer Biomet) UKR<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">7</span></a>. The
                  original design was cemented, but in response to concerns regarding radiolucent lines<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">8</span></a>, a cementless version was introduced in 2004. A randomized
                  controlled trial found that the prevalence of radiolucencies was markedly reduced with cementless
                  UKRs<a href="" data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">9</span></a>. However, the trial was inadequately powered to assess
                  revision and to assess functional outcomes. Previous matched studies<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">11</span></a> and
                  a systematic review<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">12</span></a>
                  found that cementless UKRs had lower revision rates than cemented UKRs, but those studies did not have
                  data to compare functional outcomes. A study by Martin et al.<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">13</span></a> did show a functional advantage of the cementless UKR, but
                  that study was unmatched and from a single center. Therefore, it is not certain how the functional
                  outcomes of cementless and cemented UKRs compare on a national scale.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The aim of the present study was to utilize linked data
                  from the National Joint Registry for England, Wales, Northern Ireland and the Isle of Man (NJR) and
                  the Hospital Episode Statistics Patient Reported Outcome Measures (HES-PROMs) database to compare the
                  implant and functional outcomes of cemented versus cementless Oxford UKRs in a single matched cohort.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">A retrospective observational study was performed with use
                  of linked data from the NJR and the HES-PROMs database after receiving ethical and legal approval. The
                  NJR is the largest arthroplasty registry in the world<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">4</span></a>, and
                  HES-PROMs started collecting PROMs for all National Health Service (NHS)-funded knee replacements from
                  2009 onward. Preoperative and 6-month postoperative scores for the Oxford Knee Score (OKS)<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">14</span></a> and the EuroQol-5 Dimension (EQ-5D)<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">16</span></a> are collected. The time points were selected by NHS Digital
                  to be close enough to surgery to provide timely feedback after the postoperative recovery period while
                  avoiding the influence of nonoperative factors in the longer term. Research has indicated that most
                  improvement in PROMs occurs in the first 6 months after the joint replacement, with only minor
                  improvements observed between 6 months and 1 year, and thereafter<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">20</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Logistic regression was performed to generate, on the
                  basis of patient demographics and implant and surgical factors, a propensity score representing the
                  probability that a patient underwent a cementless UKR. All patient, implant, and surgical factors in
                  <a href="" data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a> except body mass index (BMI) were utilized for matching,
                  including the preoperative OKS and EQ-5D. BMI was not utilized for matching because of the large
                  proportion of UKRs with missing data<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">24</span></a> but was well balanced between the groups. Surgical factors
                  included surgeon caseload, which was defined as the average number of UKRs performed per year, as
                  described previously<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">25</span></a>. An algorithm was utilized to match UKRs 1:1 on the basis
                  of the logit of the propensity score, with a caliper width of 0.02 standard deviations (SDs). Greedy
                  matching without replacement was utilized on the basis of its superior performance in estimating
                  treatment effects<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">26</span></a>.
                  Standardized mean differences (SMDs) were examined both before and after matching to assess for any
                  imbalance between groups, with SMDs of &gt;10% suggestive of a covariate imbalance<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">27</span></a>. After matching, 6,906 UKRs (3,453 cemented and 3,453
                  cementless) were included for analysis. The outcomes of interest were 10-year implant survival,
                  indications for revision, and preoperative and 6-month postoperative OKS and EQ-5D scores.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Patient and Surgical Factors Before and After
                          Propensity Score Matching<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 2920.72px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.747727);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Covariate</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Unmatched</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Matched</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Cemented
                          (N = 8,774)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Cementless
                          (N = 5,777)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">SMD</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Cemented
                          (N = 3,453)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Cementless
                          (N = 3,453)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">SMD</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at surgery <span
                            data-id="emphasis_41" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65.2 ±
                          9.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66.0 ±
                          9.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.09</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65.7 ±
                          9.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65.8 ±
                          8.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Gender</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.11</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,178
                          (47.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,437
                          (42.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,457
                          (42.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,485
                          (43.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,596
                          (52.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,340
                          (57.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,996
                          (57.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,968
                          (57.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI <span
                            data-id="emphasis_42" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_43" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_17" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_17"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_44"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.2 ± 4.9
                          (n = 6,290)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.3 ± 5.0
                          (n = 5,013)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.2 ± 4.9
                          (n = 2,688)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.4 ± 4.9
                          (n = 2,961)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.03</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Primary diagnosis</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Primary OA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8,694
                          (99.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,716
                          (98.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,419
                          (99.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,415
                          (98.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80
                          (0.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61
                          (1.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34
                          (1.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38
                          (1.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgical approach</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.007</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medial
                          parapatellar</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8,014
                          (91.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,288
                          (91.5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,194
                          (92.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,205
                          (92.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">760
                          (8.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">489
                          (8.5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">259
                          (7.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">248
                          (7.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ASA grade</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.05</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,550
                          (17.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,028
                          (17.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">612
                          (17.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">589
                          (17.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,483
                          (73.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,179
                          (72.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,511
                          (72.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,526
                          (73.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">741
                          (8.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">570
                          (9.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">330
                          (9.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">338
                          (9.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative OKS</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.7 ±
                          7.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.5 ±
                          7.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.0 ±
                          7.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.5 ±
                          7.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative EQ-5D</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.48 ±
                          0.29</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.48 ±
                          0.29</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.49 ±
                          0.29</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.47 ±
                          0.29</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Year of surgery</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.13</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.08</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2009</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">808
                          (9.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (0.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41
                          (1.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (1.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2010</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,073
                          (12.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97
                          (1.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">91
                          (2.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97
                          (2.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2011</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,048
                          (11.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">154
                          (2.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">145
                          (4.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">152
                          (4.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2012</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,142
                          (13.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">236
                          (4.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">241
                          (7.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">231
                          (6.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2013</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">999
                          (11.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">348
                          (6.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">316
                          (9.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">307
                          (8.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2014</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,081
                          (12.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">610
                          (10.6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">548
                          (15.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">486
                          (14.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2015</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">843
                          (9.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">789
                          (13.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">527
                          (15.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">553
                          (16.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2016</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">691
                          (7.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,122
                          (19.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">542
                          (15.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">630
                          (18.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2017</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">628
                          (7.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,195
                          (20.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">570
                          (16.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">526
                          (15.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2018</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">461
                          (5.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,182
                          (20.5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">432
                          (12.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">427
                          (12.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgeon caseload</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.54</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;10 cases/year</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,735
                          (31.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">793
                          (13.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">674
                          (19.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">655
                          (19.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 10 to &lt;30
                          cases/year</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,019
                          (45.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,434
                          (42.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,605
                          (46.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,616
                          (46.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥30 cases/year</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,020
                          (23.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,550
                          (44.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,174
                          (34.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,182
                          (34.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgical approach</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.007</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medial
                          parapatellar</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8,014
                          (91.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,288
                          (91.5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,194
                          (92.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,205
                          (92.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">760
                          (8.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">489
                          (8.5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">259
                          (7.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">248
                          (7.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimally invasive
                          surgery</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.04</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,571
                          (52.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,122
                          (54.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,822
                          (52.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,851
                          (53.6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,203
                          (47.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,655
                          (46.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,631
                          (47.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,602
                          (46.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Femoral component
                          size</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.12</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Extra small</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (0.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (0.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (0.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (0.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Small</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,051
                          (23.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,472
                          (25.5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">815
                          (23.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">815
                          (23.6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medium</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,741
                          (54.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,821
                          (48.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,731
                          (50.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,730
                          (50.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Large</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,954
                          (22.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,443
                          (25.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">891
                          (25.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">891
                          (25.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Extra large</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (0.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (0.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (0.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (0.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Tibial component
                          size</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.38</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.03</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> AA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47
                          (0.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (0.5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (0.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (0.6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> A</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,053
                          (12.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">204
                          (3.5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">165
                          (4.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">181
                          (5.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> B</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,980
                          (22.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,141
                          (19.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">741
                          (21.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">736
                          (21.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> C</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,447
                          (27.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,666
                          (28.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,026
                          (29.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">993
                          (28.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> D</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,087
                          (23.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,508
                          (26.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">890
                          (25.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">910
                          (26.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> E</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">924
                          (10.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">916
                          (15.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">464
                          (13.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">466
                          (13.5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> F</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">236
                          (2.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">311
                          (5.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">149
                          (4.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">146
                          (4.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Bearing size</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.35</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,995
                          (22.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,951
                          (33.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,025
                          (29.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">999
                          (28.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,514
                          (40.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,405
                          (41.6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,444
                          (41.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,456
                          (42.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,880
                          (21.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,004
                          (17.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">668
                          (19.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">674
                          (19.5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">826
                          (9.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">296
                          (5.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">211
                          (6.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">221
                          (6.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">364
                          (4.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78
                          (1.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65
                          (1.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65
                          (1.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 8 and 9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">195
                          (2.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43
                          (0.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40
                          (1.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38
                          (1.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the number of UKRs, with the percentage in
                        parentheses, or as the mean ± SD. OA = osteoarthritis, ASA = American Society of
                        Anesthesiologists.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Cumulative survival to 10 years was determined with use of
                  the Kaplan-Meier method. The end point for implant survival was revision surgery (i.e., the removal,
                  exchange, or addition of any implant component). Implant survival rates were compared between the
                  groups with use of Cox regression models, with the proportional hazards assumptions assessed and found
                  to be satisfied in all analyses. Hazard ratios (HRs) of &lt;1 favored cementless fixation. A
                  multilevel frailty model was tested in the regression models in order to control for patient
                  clustering within surgeons. Additionally, to account for clustering within the matched cohort, a
                  robust variance estimator was utilized in the regression models. The proportional chi-square test with
                  Yates correction was utilized to compare the frequency of revisions for specific indications between
                  the groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The OKS has 12 items related to knee pain and function and
                  is presented as an overall score between 0 and 48<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">14</span></a>.
                  The percentage of the possible change (PoPC) expresses the actual OKS change attained as a percentage
                  of the possible OKS change. The OKS was categorized as poor (&lt;27), fair (27 to 33), good (34 to
                  41), and excellent (&gt;41), as described by Kalairajah et al.<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">28</span></a>. A subscale analysis of the OKS pain and functional
                  components was also performed, with each component scored out of 100<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">14</span></a>. The EQ-5D comprises 5 questions regarding mobility,
                  self-care, activities of daily living, pain, and anxiety and depression. These answers can be
                  presented as a weighted overall index from 1 (perfect health) to –0.594 (worst possible state)<a
                    href="" data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">16</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Subgroup analyses of survival and functional outcomes were
                  performed for surgeons with different caseloads, as described previously<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">11</span></a>. Low-caseload surgeons were defined as those performing
                  &lt;10 UKRs per year; medium-caseload surgeons, 10 to &lt;30 UKRs per year; and high-caseload
                  surgeons, ≥30 UKRs per year. Survival rates in the subgroups were quoted at 8 years given limited
                  numbers at risk at 10 years. PROM scores were not normally distributed and therefore the appropriate
                  nonparametric tests were utilized. The Mann-Whitney test was employed to compare postoperative scores
                  and changes in scores between the groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">All statistical analyses were performed with use of Stata
                  (version 15.1; StataCorp). P values of ≤0.05 were considered significant, and 95% confidence intervals
                  (CIs) are presented.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Ethical
                Approval</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">This study was approved by the NJR Research subcommittee
                  and received ethical approval from the South Central Oxford B Research Ethics Committee (19/SC/0292).
                  The linkage of the datasets was approved by the Confidentiality Advisory Group (19/CAG/0054).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Between January 1, 2004, and December 31, 2018, a total of
                  50,926 medial Oxford UKRs were recorded in the NJR dataset<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">4</span></a>. Of
                  these, 14,764 were successfully linked to the HES-PROMs dataset<a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">29</span></a> (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>) with use
                  of pseudo-anonymized identification numbers. There were significant differences in the baseline
                  characteristics of the unmatched groups (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). After
                  matching, 6,906 UKRs (3,453 cemented and 3,453 cementless) were available for analysis, and the groups
                  were well balanced for all covariates (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). In the
                  matched cohort, the mean follow-up (and SD) for both cemented and cementless UKRs was 4 ± 2.2 years.
                  Overall, 19% of the procedures were performed by low-caseload surgeons (&lt;10 UKRs/year); 47%, by
                  medium-caseload surgeons (10 to &lt;30 UKRs/year); and 34%, by high-caseload surgeons (≥30 UKRs/year).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01418f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=92569169-3cfa-40b7-a840-10f9b8a81118&type=jpeg&name=JBJS.23.01418f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;">Flowchart showing the data-cleaning process.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Implant
                Survival and Indications for Revision</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The 10-year cumulative implant survival rate was 93.0%
                  (95% CI, 90.0% to 95.1%) for cementless UKRs and 91.3% (95% CI, 89.0% to 93.0%) for cemented UKRs.
                  Cementless UKRs had significantly better implant survival than cemented UKRs (HR, 0.74; p = 0.02).
                  Among UKRs performed by low-caseload surgeons, the implant survival rate at 8 years was 92.1% (95% CI,
                  87.1% to 95.2%) for cementless UKRs and 89.0% (95% CI, 81.3% to 93.7%) for cemented UKRs, with an HR
                  of 0.69 (p = 0.17). For medium-caseload surgeons, the rate was 94.8% (95% CI, 92.0% to 96.6%) for
                  cementless and 92.5% (95% CI, 89.4% to 94.7%) for cemented, with an HR of 0.85 (p = 0.38). For
                  high-caseload surgeons, the rate was 95.5% (95% CI, 93.5% to 97.0%) for cementless and 91.7% (95% CI,
                  88.4% to 94.0%) for cemented, with an HR of 0.66 (p = 0.045).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">The most common reasons for revision in the cemented group
                  were osteoarthritis progression (n = 43; 1.25%), aseptic loosening (n = 38; 1.10%), and pain (n = 24;
                  0.70%) (<a href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). In the cementless group, the most common reasons for
                  revision were osteoarthritis progression (n = 25; 0.72%) and pain (n = 15; 0.43%) (<a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). The only significant differences in revision indications
                  between the groups were in aseptic loosening and osteoarthritis progression, with the cementless group
                  demonstrating lower rates of both aseptic loosening (0.35% versus 1.10%; p &lt; 0.001) and
                  osteoarthritis progression (0.72% versus 1.25%; p = 0.03).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Reasons for Revision in the Matched Cohort<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 702px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Revision
                          Indication</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Cemented
                          (N = 3,453)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Cementless
                          (N = 3,453)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic loosening</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38
                          (1.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (0.35%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">OA progression</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43
                          (1.25%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (0.72%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.03</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pain</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (0.70%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (0.43%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.15</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (0.52%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (0.43%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.60</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dislocation or
                          subluxation revision</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (0.23%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (0.29%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.64</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Instability</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (0.43%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (0.32%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.43</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Component
                          dissociation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (0.23%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (0.29%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.64</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Malalignment</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (0.12%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (0.12%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (0.17%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (0.26%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.44</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Periprosthetic
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (0.06%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (0.23%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lysis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (0.12%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (0.12%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Wear</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (0.20%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (0.14%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.56</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stiffness</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (0.12%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.03%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.18</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">OA = osteoarthritis. Values are given as the number of UKRs, with the
                        percentage in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Chi-square test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Oxford Knee
                Score</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">The mean preoperative OKS was 21.5 ± 7.6 in the matched
                  cementless group and 22.0 ± 7.7 in the matched cemented group, with no significant difference (SMD,
                  0.06) in baseline characteristics. Both groups showed significant (p &lt; 0.001) improvements in their
                  scores at 6 months postoperatively. The mean 6-month OKS in the cementless group (39.1 ± 8.7) was
                  significantly higher than that in the cemented group (38.5 ± 8.6; p = 0.001). The cementless group
                  gained 17.6 ± 9.3 points postoperatively, whereas the cemented group gained 16.5 ± 9.6 points; the
                  difference of 1.1 points between the groups was significant (p &lt; 0.001). The PoPC was 66.7% ± 31.5%
                  in the cementless group and 63.0% ± 39.8% in the cemented group, with a significant difference between
                  the groups (p &lt; 0.001).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Subgroup analyses showed a trend of larger differences in
                  the postoperative OKS and in changes in the OKS between the cementless and cemented UKR groups with
                  higher surgeon caseloads (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                  There was no significant difference (p = 0.87) in the change in OKS postoperatively between the
                  cementless and cemented groups with low-caseload surgeons (&lt;10 UKRs/year). However, there was a
                  significant difference of 0.9 (p = 0.003) between cementless and cemented UKRs performed by
                  medium-caseload surgeons (10 to &lt;30 UKRs), as well as a significant difference of 1.8 (p &lt;
                  0.001) between cementless and cemented UKRs performed by high-caseload surgeons (≥30 UKRs/year) (<a
                    href="" data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>). A surgeon caseload of ≥30 UKRs/year was associated with the
                  highest postoperative OKS recorded, 39.8 ± 8.3 in the cementless group, which significantly differed
                  from that in the cemented group (p &lt; 0.001). This surgeon caseload was also associated with the
                  highest PoPC recorded, 68.7% in the cementless group, which significantly differed from that in the
                  cemented group (p &lt; 0.001; <a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Comparison of the OKS Between the Fixation Groups,
                          by Surgeon Caseload<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 169.763px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.523052);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Surgeon
                          Caseload</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Postop. OKS</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Change in OKS</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">PoPC</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Cemented</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Cementless</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Cemented</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Cementless</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Cemented</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Cementless</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;10 UKRs/yr</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.3 ±
                          8.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.2 ±
                          9.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.82</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.4 ±
                          9.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.6 ±
                          9.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.87</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62.4% ±
                          34.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63.1% ±
                          33.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.65</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">10 to &lt;30
                          UKRs/yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.9 ±
                          8.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39.0 ±
                          8.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.38</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.7 ±
                          9.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.6 ±
                          9.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.003</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64.0% ±
                          35.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66.6% ±
                          31.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.08</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">≥30 UKRs/yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.2 ±
                          9.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39.8 ±
                          8.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.4 ±
                          9.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.2 ±
                          9.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62.0% ±
                          47.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68.7% ±
                          30.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the mean ± SD.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Preoperatively, the proportions of poor, fair, good, and
                  excellent scores did not differ between the fixation groups. Postoperatively, among UKRs performed by
                  high-caseload surgeons, there were significantly more excellent scores (55.5% versus 47.7%, p = 0.04)
                  and fewer poor scores (8.5% versus 11.8%, p = 0.01) in the cementless group than in the cemented
                  group. No differences were found in the proportions of postoperative scores between the cemented and
                  cementless groups with low or medium-caseload surgeons (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Comparison of OKS Proportions Between the Fixation
                          Groups, by Surgeon Caseload<span data-id="table_footnote_reference_17"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1443px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Fixation</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Cemented
                          (N = 3,453)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Cementless
                          (N = 3,453)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Poor</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,467
                          (71.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,563
                          (74.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.30</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Fair</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">752
                          (21.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">681
                          (19.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.09</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Good</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">220
                          (6.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">196
                          (5.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.25</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Excellent</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.41</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postoperative</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Poor</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Overall</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">371
                          (10.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">350
                          (10.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.46</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Low caseload<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70
                          (10.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83
                          (12.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.25</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Medium caseload<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">162
                          (10.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">167
                          (10.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.84</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  High caseload<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">139
                          (11.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100
                          (8.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Fair</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Overall</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">408
                          (11.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">345
                          (10.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.03</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Low caseload<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75
                          (11.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66
                          (10.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.58</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Medium caseload<span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">195
                          (12.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">170
                          (10.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.19</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  High caseload<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">138
                          (11.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">109
                          (9.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.07</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Good</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Overall</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">982
                          (28.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">948
                          (27.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.49</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Low caseload<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">217
                          (32.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">199
                          (30.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.61</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Medium caseload<span
                            data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">428
                          (26.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">432
                          (26.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.97</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  High caseload<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">337
                          (28.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">317
                          (26.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.41</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Excellent</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Overall</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,692
                          (49.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,810
                          (52.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Low caseload<span
                            data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">312
                          (46.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">307
                          (46.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.89</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Medium caseload<span
                            data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">820
                          (51.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">847
                          (52.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.67</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  High caseload<span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">560
                          (47.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">656
                          (55.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.04</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Low caseload was defined as &lt;10 UKRs/yr; medium caseload, 10-30
                        UKRs/yr; and high caseload, &gt;30 UKRs/yr. Percentages are based on the total number of UKRs in
                        that fixation group, except as noted.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Percentages are based on the number of UKRs in that fixation group for
                        a given caseload.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">There were no significant differences in the postoperative
                  scores for the pain or function components of the OKS between the fixation groups with low or
                  medium-caseload surgeons. However, among UKRs performed by high-caseload surgeons, the scores for both
                  the pain and function components of the OKS were significantly higher in the cementless group (<a
                    href="" data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table V</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Comparison of the OKS Pain and Function Scores
                          Between the Fixation Groups, by Surgeon Caseload<span data-id="table_footnote_reference_18"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 240.645px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.746032);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Surgeon
                          Caseload</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Pain</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Function</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Cemented</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Cementless</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Cemented</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Cementless</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;10 UKRs/yr</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">79.5 ±
                          20.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">79.8 ±
                          20.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.51</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80.1 ±
                          17.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">79.4 ±
                          18.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.71</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">10 to &lt;30
                          UKRs/yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81.0 ±
                          19.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81.7 ±
                          19.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.24</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80.8 ±
                          17.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80.8 ±
                          18.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.78</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">≥30 UKRs/yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80.0 ±
                          20.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83.6 ±
                          18.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">79.2 ±
                          19.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81.9 ±
                          17.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the mean ± SD.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">EQ-5D
                Score</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The mean preoperative EQ-5D index was 0.474 ± 0.294 in the
                  matched cementless group and 0.489 ± 0.288 in the matched cemented group, with no significant
                  difference (SMD, 0.05) in baseline values between the groups, which was expected given the matching
                  process. Both groups showed significant improvements (p &lt; 0.001) in their scores at 6 months
                  postoperatively, with mean scores of 0.808 ± 0.231 in the cementless group and 0.799 ± 0.232 in the
                  cemented group. Although the postoperative scores were not significantly different between the groups
                  (p = 0.06), the cementless group gained 0.334 ± 0.312 points postoperatively, which was significantly
                  (p = 0.004) more than the 0.310 ± 0.306 points gained by the cemented group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Subgroup analyses did not show significant differences in
                  the EQ-5D between the fixation groups with surgeon caseloads of &lt;10 UKRs/year or 10 to &lt;30
                  UKRs/year. However, in the groups with a surgeon caseload of ≥30 UKRs/year, the postoperative EQ-5D
                  score was significantly higher (p = 0.02) with cementless fixation than with cemented fixation (<a
                    href="" data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VI</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VI</span>Comparison of the EQ-5D Between the Fixation Groups,
                          By Surgeon Caseload<span data-id="table_footnote_reference_19"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 242.822px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.75286);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Surgeon
                          Caseload</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Postop. Score</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Change in Score</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Cemented</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Cementless</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Cemented</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Cementless</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;10 UKRs/yr</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.787 ±
                          0.242</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.789 ±
                          0.230</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.86</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.298 ±
                          0.307</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.315 ±
                          0.312</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.25</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">10 to &lt;30
                          UKRs/yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.806 ±
                          0.226</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.812 ±
                          0.227</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.36</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.307 ±
                          0.303</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.339 ±
                          0.312</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.008</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">≥30 UKRs/yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.797 ±
                          0.235</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.813 ±
                          0.236</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.321 ±
                          0.309</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.332 ±
                          0.313</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.35</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the mean ± SD.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Early cementless UKR implant designs had poor clinical
                  outcomes<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">30</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">33</span></a>, but some newer designs have demonstrated improved
                  outcomes<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">34</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">36</span></a>. The present study showed, for the first time, that the
                  cementless Oxford UKR has better implant survival and functional outcomes than the cemented version,
                  particularly when performed by a high-caseload surgeon.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">In the analysis of our overall study cohort, we found that
                  cementless UKRs had a higher implant survival at 10 years than cemented UKRs and a 26% reduced risk of
                  revision (HR, 0.74). This finding is consistent with that of a previous registry matched comparison of
                  cemented and cementless UKRs<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">10</span></a> and
                  with that reported by the New Zealand Joint Registry<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">37</span></a>.
                  The primary reason for the difference in the present study is that the rate of revision for aseptic
                  loosening was much lower in the cementless group (0.35%) than in the cemented group (1.10%). This
                  finding is supported by randomized controlled trials<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_29" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">38</span></a> that have shown a marked reduction in radiolucent lines
                  with cementless fixation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">In the present study, the mean postoperative OKS was 39.1
                  for cementless UKRs and 38.5 for cemented UKRs, with a significant difference between the groups. In
                  addition, the mean points gained after surgery were 17.6 and 16.5, respectively, with a mean
                  difference of 1.1 points. A surgeon caseload of ≥30 UKRs/year was associated with a higher
                  postoperative OKS, with values of 39.8 ± 8.3 in the cementless group and 38.2 ± 9.2 in the cemented
                  group. Additionally, with a high-caseload surgeon, the mean postoperative gain in the OKS was 18.2
                  points in the cementless group and 16.4 points in the cementless group, with a mean difference of 1.8
                  points between the groups. Although these differences are below the minimal clinically important
                  difference (MCID)<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">14</span></a>,
                  they clearly show that functional outcomes following a cementless UKR are in fact better than those
                  following a cemented UKR and that these differences are exaggerated in UKRs performed by high-caseload
                  surgeons. This was further confirmed in the subgroup analysis of the scores for the pain and
                  functional components of the OKS. In addition, among UKRs performed by high-caseload surgeons, there
                  was a significantly higher proportion of excellent scores (55.5% versus 47.7%) and a significantly
                  lower proportion of poor scores (8.5% versus 11.8%) in the cementless group compared with the cemented
                  group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">The findings related to the EQ-5D were similar to those
                  for the OKS but not so marked, which is not surprising since the EQ-5D is a generic rather than a
                  knee-specific score. Overall, the cementless group gained 0.334 ± 0.312 points postoperatively, which
                  was significantly (p = 0.004) more than the 0.310 ± 0.306 points gained by the cemented group.
                  Subgroup analyses did not show significant differences in the EQ-5D between the fixation groups with
                  surgeon caseloads of &lt;10 or 10 to &lt;30 UKRs/year. However, with a surgeon caseload of ≥30
                  UKRs/year, the postoperative EQ-5D was significantly higher (p = 0.02) in the cementless group than in
                  the cemented group (<a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VI</a>),
                  although this difference was below the MCID for the EQ-5D<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">39</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Overall, we found that the cementless UKR provided better
                  implant survival and PROMs than the cemented UKR. The advantages of cementless fixation increased with
                  increasing surgeon caseload. For medium and high-caseload surgeons, the differences were appreciable
                  and, particularly for high-caseload surgeons, mostly statistically significant, indicating that these
                  surgeons should ideally utilize cementless UKRs. For low-caseload surgeons, the cementless fixation
                  results tended to be better than the cemented fixation results, but the differences were generally not
                  statistically significant. However, since the results for cementless fixation were not worse than
                  those for cemented fixation, low-caseload surgeons could consider using the cementless procedure.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">When compared with TKR, both the cementless UKR and the
                  cemented UKR demonstrate superior functional and quality-of-life outcomes. Studies of TKRs that
                  utilized datasets similar to that of the present study have demonstrated a mean 6-month OKS of 36 and
                  a mean 6-month EQ-5D of 0.750<a href="" data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">40</span></a><span data-id="superscript_32"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_46" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">41</span></a>. Therefore, the OKS in the present study is higher than
                  that reported for TKR by 3.1 points for the cementless UKR and 2.5 points for the cemented UKR. This
                  difference for the cementless UKR is around the MCID of the OKS<a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">14</span></a>, suggesting that it clinically has better functional
                  outcomes than TKR. Likewise, the EQ-5D is higher for both the cementless UKR (by 0.058 points) and the
                  cemented UKR (by 0.049 points) when compared with TKR. These differences are at the MCID for the
                  EQ-5D<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">39</span></a>,
                  which is believed to range from 0.03 to 0.54 points. This suggests superior functional outcomes and
                  quality of life for both cementless and cemented UKRs compared with TKRs, albeit with a larger
                  difference with the cementless version.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, this is the first study to have
                  shown—using the same cohort—that both the implant and functional outcomes of the cementless Oxford UKR
                  are superior to those of the cemented version, particularly when the procedure is performed by a
                  high-caseload surgeon. Our results may, however, be related to the design of the implant and therefore
                  may not apply to all types of UKRs and TKRs. In fact, a fixed-bearing cementless UKR implant recently
                  had to be withdrawn from the market because it had a high failure rate<a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">42</span></a>. In addition, some registry studies have suggested that
                  cementless TKRs have worse functional outcomes and higher revision rates than cemented TKRs<a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">40</span></a><span data-id="superscript_36"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">43</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">The main limitation of the present study is the use of
                  retrospective registry data. Despite matching, there is potential for residual confounding.
                  Additionally, matching can reduce the generalizability of our findings. The only way to achieve
                  perfect matching is with a randomized trial. However, comparing revision rates would have required
                  huge numbers and a long follow-up period, which would have been impractical; we believe that
                  propensity matching offered the next best alternative. Another limitation of the study is that, even
                  though cementless UKRs had HRs of &lt;1 in the subgroup analyses, the HR only reached significance in
                  the high-caseload group. The lack of significance in the other caseload groups was likely due to
                  subdividing the cohort, which reduced the statistical power. Survival analyses are more likely than
                  PROM analyses to be affected by low power because the former analyze the relatively rare events of
                  revision surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">Overall, the cementless UKR provided better implant
                  survival and functional outcomes than the cemented UKR. The biggest difference between the 2
                  procedures was the much lower risk of revision for aseptic loosening with the cementless UKR,
                  suggesting that improved fixation was the main reason for this difference. With increasing surgeon
                  caseload, the survival and functional outcomes of both cemented and cementless UKRs improved and the
                  difference between cemented and cementless UKRs increased. With low surgeon caseload (&lt;10
                  UKRs/year), the differences were small and not significant. With medium (10 to &lt;30 UKRs/year) and,
                  particularly, with high (≥30 UKRs/year) surgeon caseload, the benefits of cementless fixation compared
                  with cemented fixation were substantial and mostly significant.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank the patients and staff of all of the
                  hospitals in England and Wales who have contributed data to the NJR. The authors also thank the
                  Healthcare Quality Improvement Partnership, the NJR Research subcommittee, and staff at the NJR Centre
                  for facilitating this work. The views expressed represent those of the authors and do not necessarily
                  reflect those of the National Joint Registry Steering Committee or the Healthcare Quality Improvement
                  Partnership, who do not vouch for how the information is presented.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Liddle AD</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Pandit H</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Adverse outcomes after total and
                  unicompartmental knee replacement in 101,330 matched patients: a study of data from the National Joint
                  Registry for England and Wales</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 2014 Oct 18;384(9952):1437-45.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Adverse%20outcomes%20after%20total%20and%20unicompartmental%20knee%20replacement%20in%20101%2C330%20matched%20patients%3A%20a%20study%20of%20data%20from%20the%20National%20Joint%20Registry%20for%20England%20and%20Wales&as_occt=title&as_sauthors=%20%22AD%20Liddle%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Liddle AD</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Pandit H</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Patient-reported outcomes after
                  total and unicompartmental knee arthroplasty: a study of 14,076 matched patients from the National
                  Joint Registry for England and Wales</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2015 Jun;97-B(6):793-801.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient-reported%20outcomes%20after%20total%20and%20unicompartmental%20knee%20arthroplasty%3A%20a%20study%20of%2014%2C076%20matched%20patients%20from%20the%20National%20Joint%20Registry%20for%20England%20and%20Wales&as_occt=title&as_sauthors=%20%22AD%20Liddle%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Burn E</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Liddle AD</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Hamilton TW</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Pandit HG</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Murray DW</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Pinedo-Villanueva R</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Cost-effectiveness of
                  unicompartmental compared with total knee replacement: a population-based study using data from the
                  National Joint Registry for England and Wales</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">BMJ Open.</span> 2018 Apr
                29;8(4):e020977.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cost-effectiveness%20of%20unicompartmental%20compared%20with%20total%20knee%20replacement%3A%20a%20population-based%20study%20using%20data%20from%20the%20National%20Joint%20Registry%20for%20England%20and%20Wales&as_occt=title&as_sauthors=%20%22E%20Burn%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;">National
                Joint Registry. <span data-id="strong_4" class="annotation strong" style="position: unset;">National
                  Joint Registry 15th Annual Report</span>. 2018. Accessed 2019 Dec 1. <a
                  href="https://www.hqip.org.uk/wp-content/uploads/2018/11/NJR-15th-Annual-Report-2018.pdf"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.hqip.org.uk/wp-content/uploads/2018/11/NJR-15th-Annual-Report-2018.pdf</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=National%20Joint%20Registry%2015th%20Annual%20Report&as_occt=title&as_sauthors=%20%22National%20Joint%20Registry%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;">Australian
                Orthopaedic Association National Joint Replacement Registry. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Hip, Knee & Shoulder Arthroplasty.</span>
                2018. Accessed 2024 May 7. <a href="https://aoanjrr.sahmri.com/en-US/annual-reports-2018"
                  target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://aoanjrr.sahmri.com/en-US/annual-reports-2018</a>.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;">The New
                Zealand Joint Registry. <span data-id="strong_5" class="annotation strong"
                  style="position: unset;">Seventeen Year Report January 1999 to December 2015</span>. 2016. Accessed
                2024 May 7. <a href="https://www.nzoa.org.nz/sites/default/files/NZJR%2017%20year%20Report.pdf"
                  target="_blank" data-id="link_3" class="link"
                  style="position: unset;">https://www.nzoa.org.nz/sites/default/files/NZJR%2017%20year%20Report.pdf</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Seventeen%20Year%20Report%20January%201999%20to%20December%202015&as_occt=title&as_sauthors=%20%22The%20New%20Zealand%20Joint%20Registry%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Pandit H</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Jenkins C</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Barker K</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Dodd CA</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">The Oxford medial
                  unicompartmental knee replacement using a minimally-invasive approach</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span>
                2006 Jan;88(1):54-60.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Oxford%20medial%20unicompartmental%20knee%20replacement%20using%20a%20minimally-invasive%20approach&as_occt=title&as_sauthors=%20%22H%20Pandit%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Gulati A</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Chau R</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Pandit HG</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Gray H</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Price AJ</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Dodd CA</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">The incidence of physiological
                  radiolucency following Oxford unicompartmental knee replacement and its relationship to
                  outcome</span>. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J Bone
                  Joint Surg Br.</span> 2009 Jul;91(7):896-902.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20incidence%20of%20physiological%20radiolucency%20following%20Oxford%20unicompartmental%20knee%20replacement%20and%20its%20relationship%20to%20outcome&as_occt=title&as_sauthors=%20%22A%20Gulati%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Pandit H</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Liddle AD</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Kendrick BJ</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Jenkins C</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Price AJ</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Gill HS</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Dodd CA</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Improved fixation in cementless
                  unicompartmental knee replacement: five-year results of a randomized controlled trial</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2013 Aug 7;95(15):1365-72.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1168259" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Mohammad HR</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Matharu GS</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Comparison of the 10-year
                  outcomes of cemented and cementless unicompartmental knee replacements: data from the National Joint
                  Registry for England, Wales, Northern Ireland and the Isle of Man</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Acta Orthop.</span> 2020
                Feb;91(1):76-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20the%2010-year%20outcomes%20of%20cemented%20and%20cementless%20unicompartmental%20knee%20replacements%3A%20data%20from%20the%20National%20Joint%20Registry%20for%20England%2C%20Wales%2C%20Northern%20Ireland%20and%20the%20Isle%20of%20Man&as_occt=title&as_sauthors=%20%22HR%20Mohammad%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Mohammad HR</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Matharu GS</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">The Effect of Surgeon Caseload
                  on the Relative Revision Rate of Cemented and Cementless Unicompartmental Knee Replacements: An
                  Analysis from the National Joint Registry for England, Wales, Northern Ireland and the Isle of
                  Man</span>. <span data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J Bone
                  Joint Surg Am.</span> 2020 Apr 15;102(8):644-53.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=2322005" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Mohammad HR</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Strickland L</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Hamilton TW</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Long-term outcomes of over
                  8,000 medial Oxford Phase 3 Unicompartmental Knees-a systematic review</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Acta Orthop.</span> 2018
                Feb;89(1):101-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20outcomes%20of%20over%208%2C000%20medial%20Oxford%20Phase%203%20Unicompartmental%20Knees-a%20systematic%20review&as_occt=title&as_sauthors=%20%22HR%20Mohammad%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Martin B</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Rahman A</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Jenkins C</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Mohammad H</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Barker K</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Dodd C</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Jackson W</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Price A</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Mellon S</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Murray D</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Comparison of five-year
                  clinical outcomes of 524 cemented and cementless medial unicompartmental knee replacements</span>.
                <span data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Knee.</span> 2022
                Jan;34:89-97.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20five-year%20clinical%20outcomes%20of%20524%20cemented%20and%20cementless%20medial%20unicompartmental%20knee%20replacements&as_occt=title&as_sauthors=%20%22B%20Martin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Murray DW</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Fitzpatrick R</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Rogers K</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Pandit H</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Beard DJ</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Carr AJ</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Dawson J</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">The use of the Oxford hip and
                  knee scores</span>. <span data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Br.</span> 2007 Aug;89(8):1010-4.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20use%20of%20the%20Oxford%20hip%20and%20knee%20scores&as_occt=title&as_sauthors=%20%22DW%20Murray%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Group TE</span>; EuroQol Group.
                <span data-id="strong_14" class="annotation strong" style="position: unset;">EuroQol—a new facility for
                  the measurement of health-related quality of life</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">Health Policy.</span> 1990
                Dec;16(3):199-208.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=EuroQol%E2%80%94a%20new%20facility%20for%20the%20measurement%20of%20health-related%20quality%20of%20life&as_occt=title&as_sauthors=%20%22TE%20Group%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Devlin NJ</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Parkin D</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Browne J</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Patient-reported outcome
                  measures in the NHS: new methods for analysing and reporting EQ-5D data</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Health Econ.</span> 2010
                Aug;19(8):886-905.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient-reported%20outcome%20measures%20in%20the%20NHS%3A%20new%20methods%20for%20analysing%20and%20reporting%20EQ-5D%20data&as_occt=title&as_sauthors=%20%22NJ%20Devlin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Pandit H</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Jenkins C</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Gill HS</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Barker K</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Dodd CA</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Minimally invasive Oxford phase
                  3 unicompartmental knee replacement: results of 1000 cases</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 2011
                Feb;93(2):198-204.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Minimally%20invasive%20Oxford%20phase%203%20unicompartmental%20knee%20replacement%3A%20results%20of%201000%20cases&as_occt=title&as_sauthors=%20%22H%20Pandit%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Williams DP</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Blakey CM</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Hadfield SG</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Murray DW</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Price AJ</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Field RE</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Long-term trends in the Oxford
                  knee score following total knee replacement</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2013
                Jan;95-B(1):45-51.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20trends%20in%20the%20Oxford%20knee%20score%20following%20total%20knee%20replacement&as_occt=title&as_sauthors=%20%22DP%20Williams%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Breeman S</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Campbell MK</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Dakin H</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Fiddian N</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Fitzpatrick R</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Grant A</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Gray A</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Johnston L</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> MacLennan GS</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Morris RW</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Murray DW</span>; KAT Trial
                Group. <span data-id="strong_18" class="annotation strong" style="position: unset;">Five-year results of
                  a randomised controlled trial comparing mobile and fixed bearings in total knee replacement</span>.
                <span data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Bone Joint J.</span>
                2013 Apr;95-B(4):486-92.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Five-year%20results%20of%20a%20randomised%20controlled%20trial%20comparing%20mobile%20and%20fixed%20bearings%20in%20total%20knee%20replacement&as_occt=title&as_sauthors=%20%22S%20Breeman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Browne JP</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Bastaki H</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Dawson J</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">What is the optimal time point
                  to assess patient-reported recovery after hip and knee replacement? A systematic review and analysis
                  of routinely reported outcome data from the English patient-reported outcome measures
                  programme</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Health Qual Life Outcomes.</span> 2013 Jul 30;11(1):128.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20is%20the%20optimal%20time%20point%20to%20assess%20patient-reported%20recovery%20after%20hip%20and%20knee%20replacement%3F%20A%20systematic%20review%20and%20analysis%20of%20routinely%20reported%20outcome%20data%20from%20the%20English%20patient-reported%20outcome%20measures%20programme&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Mohammad HR</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Matharu GS</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">A matched comparison of
                  revision rates of cemented Oxford Unicompartmental Knee Replacements with Single and Twin Peg femoral
                  components, based on data from the National Joint Registry for England, Wales, Northern Ireland and
                  the Isle of Man</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">Acta Orthop.</span> 2020 Aug;91(4):420-5.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20matched%20comparison%20of%20revision%20rates%20of%20cemented%20Oxford%20Unicompartmental%20Knee%20Replacements%20with%20Single%20and%20Twin%20Peg%20femoral%20components%2C%20based%20on%20data%20from%20the%20National%20Joint%20Registry%20for%20England%2C%20Wales%2C%20Northern%20Ireland%20and%20the%20Isle%20of%20Man&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Mohammad HR</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Matharu GS</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">New surgical instrumentation
                  reduces the revision rate of unicompartmental knee replacement: A propensity score matched comparison
                  of 15,906 knees from the National Joint Registry</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">Knee.</span> 2020 Jun;27(3):993-1002.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=New%20surgical%20instrumentation%20reduces%20the%20revision%20rate%20of%20unicompartmental%20knee%20replacement%3A%20A%20propensity%20score%20matched%20comparison%20of%2015%2C906%20knees%20from%20the%20National%20Joint%20Registry&as_occt=title&as_sauthors=%20%22HR%20Mohammad%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Matharu GS</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Murray DW</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Pandit HG</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Trabecular Metal acetabular
                  components reduce the risk of revision following primary total hip arthroplasty: A propensity score
                  matched study from the National Joint Registry for England and Wales</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2018
                Feb;33(2):447-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trabecular%20Metal%20acetabular%20components%20reduce%20the%20risk%20of%20revision%20following%20primary%20total%20hip%20arthroplasty%3A%20A%20propensity%20score%20matched%20study%20from%20the%20National%20Joint%20Registry%20for%20England%20and%20Wales&as_occt=title&as_sauthors=%20%22GS%20Matharu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Matharu GS</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Murray DW</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Pandit HG</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Outcomes after metal-on-metal
                  hip revision surgery depend on the reason for failure: a propensity score-matched study</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2018 Feb;476(2):245-58.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20after%20metal-on-metal%20hip%20revision%20surgery%20depend%20on%20the%20reason%20for%20failure%3A%20a%20propensity%20score-matched%20study&as_occt=title&as_sauthors=%20%22GS%20Matharu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Liddle AD</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Pandit H</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Effect of surgical caseload on
                  revision rate following total and unicompartmental knee replacement</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2016 Jan 6;98(1):1-8.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1243012" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Austin PC</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Some methods of
                  propensity-score matching had superior performance to others: results of an empirical investigation
                  and Monte Carlo simulations</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Biom J.</span> 2009 Feb;51(1):171-84.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Some%20methods%20of%20propensity-score%20matching%20had%20superior%20performance%20to%20others%3A%20results%20of%20an%20empirical%20investigation%20and%20Monte%20Carlo%20simulations&as_occt=title&as_sauthors=%20%22PC%20Austin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Austin PC</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Balance diagnostics for
                  comparing the distribution of baseline covariates between treatment groups in propensity-score matched
                  samples</span>. <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Stat
                  Med.</span> 2009 Nov 10;28(25):3083-107.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Balance%20diagnostics%20for%20comparing%20the%20distribution%20of%20baseline%20covariates%20between%20treatment%20groups%20in%20propensity-score%20matched%20samples&as_occt=title&as_sauthors=%20%22PC%20Austin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Kalairajah Y</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Azurza K</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Hulme C</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Molloy S</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Drabu KJ</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Health outcome measures in the
                  evaluation of total hip arthroplasties—a comparison between the Harris hip score and the Oxford hip
                  score</span>. <span data-id="emphasis_26" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2005 Dec;20(8):1037-41.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20outcome%20measures%20in%20the%20evaluation%20of%20total%20hip%20arthroplasties%E2%80%94a%20comparison%20between%20the%20Harris%20hip%20score%20and%20the%20Oxford%20hip%20score&as_occt=title&as_sauthors=%20%22Y%20Kalairajah%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;">NHS
                England - Digital. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">Hospital Admitted Patient Care Activity 2019-20.</span> 2020. Accessed 2020
                Apr 10. <a
                  href="https://digital.nhs.uk/data-and-information/publications/statistical/hospital-admitted-patient-care-activity/2019-20"
                  target="_blank" data-id="link_4" class="link"
                  style="position: unset;">https://digital.nhs.uk/data-and-information/publications/statistical/hospital-admitted-patient-care-activity/2019-20</a>.</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Bernasek TL</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Rand JA</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Bryan RS</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Unicompartmental porous coated
                  anatomic total knee arthroplasty</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 1988 Nov;(236):52-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Unicompartmental%20porous%20coated%20anatomic%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22TL%20Bernasek%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Lindstrand A</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Stenström A</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Egund N</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">The PCA unicompartmental knee.
                  A 1-4-year comparison of fixation with or without cement</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">Acta Orthop Scand.</span> 1988
                Dec;59(6):695-700.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20PCA%20unicompartmental%20knee.%20A%201-4-year%20comparison%20of%20fixation%20with%20or%20without%20cement&as_occt=title&as_sauthors=%20%22A%20Lindstrand%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Harilainen A</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Sandelin J</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Ylinen P</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Vahvanen V</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Revision of the PCA
                  unicompartmental knee. 52 arthrosis knees followed for 2-5 years</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">Acta Orthop Scand.</span> 1993
                Aug;64(4):428-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Revision%20of%20the%20PCA%20unicompartmental%20knee.%2052%20arthrosis%20knees%20followed%20for%202-5%20years&as_occt=title&as_sauthors=%20%22A%20Harilainen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Bergenudd H</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Porous-coated anatomic
                  unicompartmental knee arthroplasty in osteoarthritis. A 3- to 9-year follow-up study</span>. <span
                  data-id="emphasis_31" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 1995
                Nov;10(Suppl):S8-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Porous-coated%20anatomic%20unicompartmental%20knee%20arthroplasty%20in%20osteoarthritis.%20A%203-%20to%209-year%20follow-up%20study&as_occt=title&as_sauthors=%20%22H%20Bergenudd%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Epinette JA</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Manley MT</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Hydroxyapatite-coated total
                  knee replacement: clinical experience at 10 to 15 years</span>. <span data-id="emphasis_32"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 2007
                Jan;89(1):34-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hydroxyapatite-coated%20total%20knee%20replacement%3A%20clinical%20experience%20at%2010%20to%2015%20years&as_occt=title&as_sauthors=%20%22JA%20Epinette%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Fernandez-Fairen M</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Hernández-Vaquero D</span>,
                <span data-id="annotation_137" class="annotation" style="position: unset;"> Murcia A</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Torres A</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Llopis R</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Trabecular metal in total knee
                  arthroplasty associated with higher knee scores: a randomized controlled trial</span>. <span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2013 Nov;471(11):3543-53.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trabecular%20metal%20in%20total%20knee%20arthroplasty%20associated%20with%20higher%20knee%20scores%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22M%20Fernandez-Fairen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Mohammad HR</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Kennedy JA</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Mellon SJ</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Dodd CA</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Ten-year clinical and
                  radiographic results of 1000 cementless Oxford unicompartmental knee replacements</span>. <span
                  data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2020 May;28(5):1479-87.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ten-year%20clinical%20and%20radiographic%20results%20of%201000%20cementless%20Oxford%20unicompartmental%20knee%20replacements&as_occt=title&as_sauthors=%20%22HR%20Mohammad%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;">The New
                Zealand Joint Registry. Twenty-One Year Report January 1999 to December 2019. 2020. Accessed 2024 May 7.
                <a href="https://www.nzoa.org.nz/sites/default/files/DH8426_NZJR_2020_Report_v5_30Sep.pdf"
                  target="_blank" data-id="link_5" class="link"
                  style="position: unset;">https://www.nzoa.org.nz/sites/default/files/DH8426_NZJR_2020_Report_v5_30Sep.pdf</a>.</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Campi S</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Kendrick BJL</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Kaptein BL</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Valstar ER</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Jackson WFM</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Dodd CAF</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Price AJ</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Five-year results of a
                  randomised controlled trial comparing cemented and cementless Oxford unicompartmental knee replacement
                  using radiostereometric analysis</span>. <span data-id="emphasis_35" class="annotation emphasis"
                  style="position: unset;">Knee.</span> 2021 Jan;28:383-90.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Five-year%20results%20of%20a%20randomised%20controlled%20trial%20comparing%20cemented%20and%20cementless%20Oxford%20unicompartmental%20knee%20replacement%20using%20radiostereometric%20analysis&as_occt=title&as_sauthors=%20%22S%20Campi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Coretti S</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Ruggeri M</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> McNamee P</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">The minimum clinically
                  important difference for EQ-5D index: a critical review</span>. <span data-id="emphasis_36"
                  class="annotation emphasis" style="position: unset;">Expert Rev Pharmacoecon Outcomes Res.</span> 2014
                Apr;14(2):221-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20minimum%20clinically%20important%20difference%20for%20EQ-5D%20index%3A%20a%20critical%20review&as_occt=title&as_sauthors=%20%22S%20Coretti%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Mohammad HR</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">A matched comparison of the
                  patient-reported outcome measures of cemented and cementless total knee replacements, based on the
                  National Joint Registry of England, Wales, Northern Ireland, and Isle of Man and England’s National
                  PROM collection programme</span>. <span data-id="emphasis_37" class="annotation emphasis"
                  style="position: unset;">Acta Orthop.</span> 2022 Jan 3;93:164-70.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20matched%20comparison%20of%20the%20patient-reported%20outcome%20measures%20of%20cemented%20and%20cementless%20total%20knee%20replacements%2C%20based%20on%20the%20National%20Joint%20Registry%20of%20England%2C%20Wales%2C%20Northern%20Ireland%2C%20and%20Isle%20of%20Man%20and%20England%E2%80%99s%20National%20PROM%20collection%20programme&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Mohammad HR</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">A matched comparison of the
                  patient-reported outcome measures of 38,716 total and unicompartmental knee replacements: an analysis
                  of linked data from the National Joint Registry of England, Northern Ireland and Isle of Man and
                  England’s National PROM collection programme</span>. <span data-id="emphasis_38"
                  class="annotation emphasis" style="position: unset;">Acta Orthop.</span> 2021
                Dec;92(6):701-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20matched%20comparison%20of%20the%20patient-reported%20outcome%20measures%20of%2038%2C716%20total%20and%20unicompartmental%20knee%20replacements%3A%20an%20analysis%20of%20linked%20data%20from%20the%20National%20Joint%20Registry%20of%20England%2C%20Northern%20Ireland%20and%20Isle%20of%20Man%20and%20England%E2%80%99s%20National%20PROM%20collection%20programme&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;">U.S. Food
                & Drug Administration. <span data-id="strong_39" class="annotation strong"
                  style="position: unset;">Class 2 Device Recall ENGAGE Cementless Partial Knee System</span>. 2023.
                Accessed 2023. <a
                  href="https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfres/res.cfm?id=200133#:%7E:text=Class%202%20Device%20Recall%20ENGAGE%20Cementless%20Partial%20Knee%20System,-1&text=Smith%20&%20Nephew%2C%20Inc.&text=Recent%20complaint%20data%20indicates%20that,in%20global%"
                  target="_blank" data-id="link_6" class="link"
                  style="position: unset;">https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfres/res.cfm?id=200133#:∼:text=Class%202%20Device%20Recall%20ENGAGE%20Cementless%20Partial%20Knee%20System,-1&text=Smith%20%26%20Nephew%2C%20Inc.&text=Recent%20complaint%20data%20indicates%20that,in%20global%20joint%20replacement%20registries</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Class%202%20Device%20Recall%20ENGAGE%20Cementless%20Partial%20Knee%20System&as_occt=title&as_sauthors=%20%22U.S.%20Food%20%26%20Drug%20Administration%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Mohammad HR</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">A matched comparison of the
                  long-term outcomes of cemented and cementless total knee replacements: an analysis from the national
                  joint registry of England, Wales, Northern Ireland and the Isle of Man</span>. <span
                  data-id="emphasis_39" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2021 Dec 15;103(24):2270-80.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=3040311" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D92569169-3cfa-40b7-a840-10f9b8a81118%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node eletter" data-id="eletter_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="file" style="position: unset;"><span class="" style="position: unset;">eLetter 1</span><a
                  class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;92569169-3cfa-40b7-a840-10f9b8a81118&quot;,&quot;type&quot;:&quot;eletter&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=92569169-3cfa-40b7-a840-10f9b8a81118&type=eletter&name=1436"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">September 4, 2024;
                      106 (17): 1553</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01418</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">July 9, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_40"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the Nuffield
                            Department of Orthopaedics, Rheumatology and Musculoskeletal Sciences, Nuffield Orthopaedic
                            Centre, University of Oxford, Oxford, United Kingdom</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_46"
                            class="annotation strong" style="position: unset;">A commentary by Aaron G. Chen, MD, MSc,
                            and Michael S. Reich, MD,</span> is linked to the online version of this article.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=aaac54e3-9c37-4708-a259-bd51942f0306&native=1"
                              class="article-title" style="position: unset;">Cementless Oxford Unicompartmental Knee
                              Replacements: More Data, More Questions<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">Commentary on an article by Hasan R. Mohammad, MRCS,
                                MRes(Dist), DPhil(Oxon), et al.: “A Matched Comparison of Implant and Functional
                                Outcomes of Cemented and Cementless Unicompartmental Knee Replacements. A Study from the
                                National Registry for England, Wales, Northern Ireland and the Isle of Man and the
                                Hospital Episode Statistics Patient Reported Outcome Measures Database”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Chen,
                              Aaron G.; Reich, Michael S.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(17):e37 | September 4, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;92569169-3cfa-40b7-a840-10f9b8a81118&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=92569169-3cfa-40b7-a840-10f9b8a81118&type=pdf&name=JBJS.23.01418.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=92569169-3cfa-40b7-a840-10f9b8a81118&type=pdf&name=JBJS.23.01418.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_47"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This work was funded
                          by an institutional grant from Zimmer Biomet to the University of Oxford and by the University
                          of Oxford Henni Mester Scholarship. One author has received benefits from Zimmer Biomet. The
                          commercial party played no role in the design, conduct, or interpretation of this study. The
                          <span data-id="strong_48" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I101" target="_blank" data-id="link_7"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I101</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;92569169-3cfa-40b7-a840-10f9b8a81118&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=92569169-3cfa-40b7-a840-10f9b8a81118&type=zip&name=JBJS.23.01418.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hasan R. Mohammad, MRCS, MRes(Dist),
                DPhil(Oxon)<span data-id="affiliation_reference_1"
                  class="label annotation cross_reference">1</span><span data-id="affiliation_reference_2"
                  class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:hasanmohammad@doctors.org.uk" class=""
                    style="position: unset;">hasanmohammad@doctors.org.uk</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2146-8601" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2146-8601</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Andrew Judge, BSc, MSc, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">David W. Murray, MD, FRCS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0839-3166" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0839-3166</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Nuffield Department of Orthopaedics, Rheumatology and Musculoskeletal Sciences,
                Nuffield Orthopaedic Centre, University of Oxford, Oxford, United Kingdom</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Barts Bone & Joint Health, Blizard Institute, Barts and The London School
                of Medicine and Dentistry, Queen Mary University of London, London, United Kingdom</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Musculoskeletal Research Unit, Bristol Medical School, University of Bristol,
                Bristol, United Kingdom</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 34314.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
