/* ----------------- React Native Paper --------------- */
import { Button, Card } from "react-native-paper";

import CardContainer from "../../CardContainer";
/* ----------------- Components --------------- */
import CardContent, { type MasteryListItemProps } from "./CardContent";

type Props = {
	title: string;
	subtitle: string;
	items: MasteryListItemProps[];
	handleClickCard?: () => void;
	cta?: string;
	icon?: string;
	tooltip?: string;
	tagType?: string;
};
export const StudySessionMasteryTagProgressCard = ({
	title,
	subtitle,
	items,
	handleClickCard,
	cta,
	icon,
	tooltip,
	tagType,
}: Props) => {
	const showViewAll = cta || handleClickCard;
	const finalItems = items.slice(0, 4);

	const ActionsCard = () => {
		if (showViewAll) {
			return (
				<Card.Actions>
					<Button mode="text" onPress={handleClickCard}>
						{cta || "View Details"}
					</Button>
				</Card.Actions>
			);
		}
		return null;
	};

	return (
		<CardContainer
			title={title}
			subtitle={subtitle}
			icon={icon || "tag-multiple-outline"}
			onPress={handleClickCard}
			actions={<ActionsCard />}
			tooltip={tooltip}
		>
			<CardContent items={finalItems} tagType={tagType} />
		</CardContainer>
	);
};
