import type { Issue } from "../types";
import { Article500 } from "./articles-data/issues/106/16/500";
import { Article501 } from "./articles-data/issues/106/16/501";
import { Article502 } from "./articles-data/issues/106/16/502";
import { Article504 } from "./articles-data/issues/106/16/504";
import { Article508 } from "./articles-data/issues/106/16/508";
import { Article509 } from "./articles-data/issues/106/16/509";
import { Article511 } from "./articles-data/issues/106/16/511";
import { Article513 } from "./articles-data/issues/106/16/513";
import { Article514 } from "./articles-data/issues/106/16/514";
import { Article517 } from "./articles-data/issues/106/16/517";
import { Article518 } from "./articles-data/issues/106/16/518";
import { Article520 } from "./articles-data/issues/106/16/520";
import { Article521 } from "./articles-data/issues/106/16/521";
import { Article522 } from "./articles-data/issues/106/16/522";
import { Article523 } from "./articles-data/issues/106/16/523";
import { Article527 } from "./articles-data/issues/106/16/527";
import { Article532 } from "./articles-data/issues/106/16/532";
import { Article534 } from "./articles-data/issues/106/16/534";
import { Article503 } from "./articles-data/issues/106/17/503";
import { Article505 } from "./articles-data/issues/106/17/505";
import { Article506 } from "./articles-data/issues/106/17/506";
import { Article507 } from "./articles-data/issues/106/17/507";
import { Article510 } from "./articles-data/issues/106/17/510";
import { Article512 } from "./articles-data/issues/106/17/512";
import { Article515 } from "./articles-data/issues/106/17/515";
import { Article516 } from "./articles-data/issues/106/17/516";
import { Article519 } from "./articles-data/issues/106/17/519";
import { Article524 } from "./articles-data/issues/106/17/524";
import { Article525 } from "./articles-data/issues/106/17/525";
import { Article526 } from "./articles-data/issues/106/17/526";
import { Article528 } from "./articles-data/issues/106/17/528";
import { Article529 } from "./articles-data/issues/106/17/529";
import { Article530 } from "./articles-data/issues/106/17/530";
import { Article531 } from "./articles-data/issues/106/17/531";
import { Article533 } from "./articles-data/issues/106/17/533";
import { Article535 } from "./articles-data/issues/106/20/535";
import { Article536 } from "./articles-data/issues/106/20/536";
import { Article537 } from "./articles-data/issues/106/20/537";
import { Article538 } from "./articles-data/issues/106/20/538";
import { Article539 } from "./articles-data/issues/106/20/539";
import { Article540 } from "./articles-data/issues/106/20/540";
import { Article541 } from "./articles-data/issues/106/20/541";
import { Article542 } from "./articles-data/issues/106/20/542";
import { Article543 } from "./articles-data/issues/106/20/543";
import { Article544 } from "./articles-data/issues/106/20/544";
import { Article545 } from "./articles-data/issues/106/20/545";
import { Article546 } from "./articles-data/issues/106/20/546";
import { Article547 } from "./articles-data/issues/106/20/547";
import { Article548 } from "./articles-data/issues/106/20/548";
import { Article550 } from "./articles-data/issues/106/21/550";
import { Article551 } from "./articles-data/issues/106/21/551";
import { Article552 } from "./articles-data/issues/106/21/552";
import { Article553 } from "./articles-data/issues/106/21/553";
import { Article554 } from "./articles-data/issues/106/21/554";
import { Article555 } from "./articles-data/issues/106/21/555";
import { Article556 } from "./articles-data/issues/106/21/556";
import { Article557 } from "./articles-data/issues/106/21/557";
import { Article558 } from "./articles-data/issues/106/21/558";
import { Article559 } from "./articles-data/issues/106/21/559";
import { Article560 } from "./articles-data/issues/106/21/560";
import { Article561 } from "./articles-data/issues/106/21/561";
import { Article562 } from "./articles-data/issues/106/21/562";
import { Article563 } from "./articles-data/issues/106/21/563";
import { Article564 } from "./articles-data/issues/106/21/564";
import { Article565 } from "./articles-data/issues/106/21/565";
import { Article566 } from "./articles-data/issues/106/21/566";
import { Article567 } from "./articles-data/issues/106/22/567";
import { Article568 } from "./articles-data/issues/106/22/568";
import { Article569 } from "./articles-data/issues/106/22/569";
import { Article570 } from "./articles-data/issues/106/22/570";
import { Article571 } from "./articles-data/issues/106/22/571";
import { Article572 } from "./articles-data/issues/106/22/572";
import { Article573 } from "./articles-data/issues/106/22/573";
import { Article574 } from "./articles-data/issues/106/22/574";
import { Article575 } from "./articles-data/issues/106/22/575";
import { Article576 } from "./articles-data/issues/106/22/576";
import { Article577 } from "./articles-data/issues/106/22/577";
import { Article578 } from "./articles-data/issues/106/22/578";
import { Article579 } from "./articles-data/issues/106/22/579";
import { Article580 } from "./articles-data/issues/106/22/580";
import { Article581 } from "./articles-data/issues/106/22/581";
import { Article582 } from "./articles-data/issues/106/22/582";
import { Article583 } from "./articles-data/issues/106/22/583";
import { Article584 } from "./articles-data/issues/106/22/584";
import { Article585 } from "./articles-data/issues/106/22/585";
import { Article586 } from "./articles-data/issues/106/22/586";

import { Article587 } from "./articles-data/issues/106/23/587";
import { Article588 } from "./articles-data/issues/106/23/588";
import { Article589 } from "./articles-data/issues/106/23/589";
import { Article590 } from "./articles-data/issues/106/23/590";
import { Article591 } from "./articles-data/issues/106/23/591";
import { Article592 } from "./articles-data/issues/106/23/592";
import { Article593 } from "./articles-data/issues/106/23/593";
import { Article594 } from "./articles-data/issues/106/23/594";
import { Article595 } from "./articles-data/issues/106/23/595";
import { Article596 } from "./articles-data/issues/106/23/596";
import { Article597 } from "./articles-data/issues/106/23/597";
import { Article598 } from "./articles-data/issues/106/23/598";
import { Article599 } from "./articles-data/issues/106/23/599";
import { Article600 } from "./articles-data/issues/106/23/600";
import { Article601 } from "./articles-data/issues/106/23/601";

import { Article602 } from "./articles-data/issues/106/24/602";
import { Article603 } from "./articles-data/issues/106/24/603";
import { Article604 } from "./articles-data/issues/106/24/604";
import { Article605 } from "./articles-data/issues/106/24/605";
import { Article606 } from "./articles-data/issues/106/24/606";
import { Article607 } from "./articles-data/issues/106/24/607";
import { Article608 } from "./articles-data/issues/106/24/608";
import { Article609 } from "./articles-data/issues/106/24/609";
import { Article610 } from "./articles-data/issues/106/24/610";
import { Article611 } from "./articles-data/issues/106/24/611";
import { Article612 } from "./articles-data/issues/106/24/612";
import { Article613 } from "./articles-data/issues/106/24/613";
import { Article614 } from "./articles-data/issues/106/24/614";
import { Article615 } from "./articles-data/issues/106/24/615";
import { Article616 } from "./articles-data/issues/106/24/616";
import { Article617 } from "./articles-data/issues/106/24/617";
import { Article618 } from "./articles-data/issues/106/24/618";
import { Article619 } from "./articles-data/issues/106/24/619";
import { Article620 } from "./articles-data/issues/106/24/620";
import { Article621 } from "./articles-data/issues/106/24/621";
import { Article622 } from "./articles-data/issues/106/24/622";
import { Article623 } from "./articles-data/issues/106/24/623";
import { Article624 } from "./articles-data/issues/106/24/624";

import { Article625 } from "./articles-data/issues/107/1/625";
import { Article626 } from "./articles-data/issues/107/1/626";
import { Article627 } from "./articles-data/issues/107/1/627";
import { Article628 } from "./articles-data/issues/107/1/628";
import { Article629 } from "./articles-data/issues/107/1/629";
import { Article630 } from "./articles-data/issues/107/1/630";
import { Article631 } from "./articles-data/issues/107/1/631";
import { Article632 } from "./articles-data/issues/107/1/632";
import { Article633 } from "./articles-data/issues/107/1/633";
import { Article634 } from "./articles-data/issues/107/1/634";
import { Article635 } from "./articles-data/issues/107/1/635";
import { Article636 } from "./articles-data/issues/107/1/636";
import { Article637 } from "./articles-data/issues/107/1/637";
import { Article638 } from "./articles-data/issues/107/1/638";
import { Article639 } from "./articles-data/issues/107/1/639";
import { Article640 } from "./articles-data/issues/107/1/640";

import { Article641 } from "./articles-data/issues/107/2/641";
import { Article642 } from "./articles-data/issues/107/2/642";
import { Article643 } from "./articles-data/issues/107/2/643";
import { Article644 } from "./articles-data/issues/107/2/644";
import { Article645 } from "./articles-data/issues/107/2/645";
import { Article646 } from "./articles-data/issues/107/2/646";
import { Article647 } from "./articles-data/issues/107/2/647";
import { Article648 } from "./articles-data/issues/107/2/648";
import { Article649 } from "./articles-data/issues/107/2/649";
import { Article650 } from "./articles-data/issues/107/2/650";
import { Article651 } from "./articles-data/issues/107/2/651";
import { Article652 } from "./articles-data/issues/107/2/652";
import { Article653 } from "./articles-data/issues/107/2/653";
import { Article654 } from "./articles-data/issues/107/2/654";
import { Article655 } from "./articles-data/issues/107/2/655";
import { Article656 } from "./articles-data/issues/107/2/656";
import { Article657 } from "./articles-data/issues/107/2/657";

import { Article658 } from "./articles-data/issues/107/3/658";
import { Article659 } from "./articles-data/issues/107/3/659";
import { Article660 } from "./articles-data/issues/107/3/660";
import { Article661 } from "./articles-data/issues/107/3/661";
import { Article662 } from "./articles-data/issues/107/3/662";
import { Article663 } from "./articles-data/issues/107/3/663";
import { Article664 } from "./articles-data/issues/107/3/664";
import { Article665 } from "./articles-data/issues/107/3/665";
import { Article666 } from "./articles-data/issues/107/3/666";
import { Article667 } from "./articles-data/issues/107/3/667";
import { Article668 } from "./articles-data/issues/107/3/668";
import { Article669 } from "./articles-data/issues/107/3/669";
import { Article670 } from "./articles-data/issues/107/3/670";
import { Article671 } from "./articles-data/issues/107/3/671";
import { Article672 } from "./articles-data/issues/107/3/672";
import { Article673 } from "./articles-data/issues/107/3/673";

export const issues: Issue[] = [
	{
		id: 3,
		volume: 107,
		date: "February 5, 2025",
		articles: [
			Article658,
			Article659,
			Article660,
			Article661,
			Article662,
			Article663,
			Article664,
			Article665,
			Article666,
			Article667,
			Article668,
			Article669,
			Article670,
			Article671,
			Article672,
			Article673,
		],
	},
	{
		id: 2,
		volume: 107,
		date: "January 15, 2025",
		articles: [
			Article641,
			Article642,
			Article643,
			Article644,
			Article645,
			Article646,
			Article647,
			Article648,
			Article649,
			Article650,
			Article651,
			Article652,
			Article653,
			Article654,
			Article655,
			Article656,
			Article657,
		],
	},
	{
		id: 1,
		volume: 107,
		date: "January 01, 2025",
		articles: [
			Article625,
			Article626,
			Article627,
			Article628,
			Article629,
			Article630,
			Article631,
			Article632,
			Article633,
			Article634,
			Article635,
			Article636,
			Article637,
			Article638,
			Article639,
			Article640,
		],
	},
	{
		id: 24,
		volume: 106,
		date: "December 18, 2024",
		articles: [
			Article602,
			Article603,
			Article604,
			Article605,
			Article606,
			Article607,
			Article608,
			Article609,
			Article610,
			Article611,
			Article612,
			Article613,
			Article614,
			Article615,
			Article616,
			Article617,
			Article618,
			Article619,
			Article620,
			Article621,
			Article622,
			Article623,
			Article624,
		],
	},
	{
		id: 23,
		volume: 106,
		date: "December 4, 2024",
		articles: [
			Article587,
			Article588,
			Article589,
			Article590,
			Article591,
			Article592,
			Article593,
			Article594,
			Article595,
			Article596,
			Article597,
			Article598,
			Article599,
			Article600,
			Article601,
		],
	},
	{
		id: 22,
		volume: 106,
		date: "November 20, 2024",
		articles: [
			Article567,
			Article568,
			Article569,
			Article570,
			Article571,
			Article572,
			Article573,
			Article574,
			Article575,
			Article576,
			Article577,
			Article578,
			Article579,
			Article580,
			Article581,
			Article582,
			Article583,
			Article584,
			Article585,
			Article586,
		],
	},
	{
		id: 21,
		volume: 106,
		date: "November 6, 2024",
		articles: [
			Article550,
			Article551,
			Article552,
			Article553,
			Article554,
			Article555,
			Article556,
			Article557,
			Article558,
			Article559,
			Article560,
			Article561,
			Article562,
			Article563,
			Article564,
			Article565,
			Article566,
		],
	},
	{
		id: 20,
		volume: 106,
		date: "October 16, 2024",
		articles: [
			Article535,
			Article536,
			Article537,
			Article538,
			Article539,
			Article540,
			Article541,
			Article542,
			Article543,
			Article544,
			Article545,
			Article546,
			Article547,
			Article548,
		],
	},
	{
		id: 17,
		volume: 106,
		date: "September 4, 2024",
		articles: [
			Article516,
			Article510,
			Article529,
			Article524,
			Article526,
			Article515,
			Article507,
			Article506,
			Article519,
			Article531,
			Article503,
			Article525,
			Article528,
			Article530,
			Article505,
			Article512,
			Article533,
		],
	},
	{
		id: 16,
		volume: 106,
		date: "August 21, 2024",
		articles: [
			Article500,
			Article514,
			Article501,
			Article502,
			Article523,
			Article513,
			Article521,
			Article520,
			Article508,
			Article518,
			Article509,
			Article511,
			Article527,
			Article504,
			Article517,
			Article532,
			Article522,
			Article534,
		],
	},
];
