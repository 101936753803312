import type { Article } from "../../../../../types";

export const Article633: Article = {
	id: 633,
	rsuiteId: "4f2856eb-61fb-456a-8ede-873e4be14cbb",
	type: "scientific article",
	title: "Thoracolumbar Fracture",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4f2856eb-61fb-456a-8ede-873e4be14cbb&type=jpeg&name=JBJS.24.00706f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Thoracolumbar Fracture</div>
              <div class="text subtitle" style="position: unset;">A Natural History Study of Survival Following Injury
              </div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Brendan M.
                      Striano, MD, MPH</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Alexander M.
                      Crawford, MD, MPH</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 8 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_18" class="annotation strong"
                          style="position: unset;">Background:</span> Fractures of the thoracic and lumbar spine are
                        increasingly common. Although it is known that such fractures may elevate the risk of near-term
                        morbidity, the natural history of patients who sustain such injuries remains poorly described.
                        We sought to characterize the natural history of patients treated for thoracolumbar fractures
                        and to understand clinical and sociodemographic factors associated with survival.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_19" class="annotation strong"
                          style="position: unset;">Methods:</span> Patients treated for acute thoracic or lumbar spine
                        fractures within a large academic health-care network between 2015 and 2021 were identified.
                        Clinical, radiographic, and mortality data were obtained from medical records and administrative
                        charts. Survival was assessed using Kaplan-Meier curves. We used multivariable logistic
                        regression to evaluate factors associated with survival, while adjusting for confounders.
                        Results were expressed as odds ratios (ORs) and 95% confidence intervals (CIs).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_20" class="annotation strong"
                          style="position: unset;">Results:</span> The study included 717 patients (median age, 66
                        years; 59.8% male; 69% non-Hispanic White). The mortality rate was 7.0% (n = 50), 16.2% (n =
                        116), and 20.4% (n = 146) at 3, 12, and 24 months following injury, respectively. In adjusted
                        analysis, patients who died within the first year following injury were more likely to be older
                        (OR = 1.03; 95% CI = 1.01 to 1.05) and male (OR = 1.67; 95% CI = 1.05 to 2.69). A higher Injury
                        Severity Score, lower Glasgow Coma Scale score, and higher Charlson Comorbidity Index at
                        presentation were also influential factors. The final model explained 81% (95% CI = 81% to 83%)
                        of the variation in survival.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_21" class="annotation strong"
                          style="position: unset;">Conclusions:</span> We identified a previously underappreciated fact:
                        thoracolumbar fractures are associated with a mortality risk comparable with that of hip
                        fractures. The risk of mortality is greatest in elderly patients and those with multiple
                        comorbidities. The results of our model can be used in patient and family counseling, informed
                        decision-making, and resource allocation to mitigate the potential risk of near-term mortality
                        in high-risk individuals.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_22" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Thoracolumbar spine trauma is a growing issue across the
                  globe. With a reported annual incidence of 30 per 100,000 people, millions of these injuries occur
                  every year worldwide<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  Similar to most types of trauma, thoracolumbar spine fractures generally manifest in a dichotomous age
                  distribution as typically low-energy injuries in the elderly and higher-energy injuries in younger
                  patients. Particularly in older patients, such fractures may portend major physiologic decline in the
                  months and year following the injury. As injuries to the thoracolumbar junction can threaten
                  neurologic function and impact mobility, they carry an increased risk of post-injury morbidity that
                  may also adversely impact survival. While some studies have putatively described these associations,
                  most work has focused on osteoporotic compression fractures (OCFs) in the elderly or fractures
                  associated with spinal cord injury (SCI), and a reasonable understanding of the natural history of
                  patients in the years following a thoracolumbar spine fracture is not readily available<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">3</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Fractures with similar morphology can result from
                  drastically different mechanisms of injury<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">4</span></a>. For
                  example, burst fractures are classically described in the context of falls from a height or motor
                  vehicle accidents, but they are also encountered in some elderly patients injured as a result of
                  low-energy trauma. Whether such fractures behave the same in terms of their influence on survival and
                  function or represent distinct injury phenotypes with shared radiographic characteristics remains an
                  important clinical question. Similarly, the interactions among medical comorbidities, physiologic
                  frailty, mechanism of injury, and fracture morphology may differ based on patient age. Thus,
                  literature-based estimates of survival developed using the data of older patients with OCFs are likely
                  not applicable to younger patients with a comparable fracture pattern. Conversely, determinations
                  regarding burst fractures in younger patients with SCI are unlikely to translate to octogenarians who
                  sustained a similar injury as a result of a ground-level fall.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">In this context, we sought to better understand the
                  natural history of survival following thoracolumbar spine fractures across the age span of adulthood,
                  with a particular focus on the influence of comorbidities, preinjury ambulatory status, mechanism of
                  injury, and injury severity. Specifically, we hypothesized, based on our conceptual model, that older
                  patients as well as those with greater comorbidities and lower functional capacity at baseline would
                  have a higher likelihood of mortality at 1 year following the date of injury.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Design</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">This study was conducted using data from a large
                  integrated academic health-care system within a metropolitan area in the United States. Patients who
                  were included in this investigation were identified via a query of our health-care system’s Research
                  Patient Data Registry (RPDR). This clinical registry has been used in previous investigations focused
                  on clinical care and health-care policy in the field of orthopaedic surgery<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">7</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Selection</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">We identified adult patients who presented with an acute
                  fracture of the thoracic and/or lumbar spine at 1 of 2 major level-I trauma centers or 2 affiliated
                  community-based hospitals between January 1, 2015, and December 31, 2021. Patients were excluded if
                  they had concurrent diagnoses related to infections of the spine or documented spinal metastases.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Abstraction</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Candidates were confirmed as eligible for inclusion in the
                  study following dedicated chart review. We then used the clinical registry and direct review of
                  medical records to obtain the following variables for all eligible patients: age, sex, body mass index
                  (BMI, in kg/m<span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">2</span>), self-reported race, smoking status, Charlson Comorbidity Index
                  (CCI), presence of autofusion within the spinal column (i.e., diffuse idiopathic skeletal hyperostosis
                  or ankylosing spondylitis), Injury Severity Score (ISS), Glasgow Coma Scale (GCS) score, level of
                  injury within the thoracic or lumbar spine, whether non-contiguous spine fractures were present,
                  fracture morphology (based on the AO Spine classification system<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">9</span></a>),
                  whether the patient had sustained multi-organ system trauma (i.e., polytrauma), whether the injury
                  mechanism was high energy (e.g., pedestrian struck by a motor vehicle, accident between motor
                  vehicles, fall from a height) or low energy (e.g., fall from standing or a bed), whether surgical
                  intervention was performed, the patient’s preinjury ambulatory status (dichotomized as dependent on an
                  assistive device or nonambulatory versus independently ambulatory), presence of neurologic symptoms at
                  the time of injury, and date of death if applicable. The Area Deprivation Index was also determined,
                  using national percentiles, for each patient based on the Neighborhood Atlas<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">10</span></a>. Patient sex was recorded as male or female based on chart
                  documentation (presumably based on self-report of biologic sex) as our system did not include other
                  data related to sex or gender in the RPDR during the time period under study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">In all analyses, mortality within 1 year of the date of
                  injury represented the primary outcome. Unadjusted, bivariate comparisons were made across all
                  abstracted variables, comparing those who died within 1 year of injury to those who survived. One-year
                  survival for the cohort was also visualized using Kaplan-Meier curves. Patients were censored based on
                  the date of data abstraction, as mortality information is constantly updated in institutional medical
                  records irrespective of a patient’s last health-care encounter within the system. Discrete end points
                  of 3 months, 1 year, and 2 years following injury were also used to describe the survival trajectory.
                  We also used Kaplan-Meier curves to describe survival in patient cohorts using an age of 65 years
                  (i.e., the age of Medicare eligibility) as a threshold and across different age groups by decade
                  starting at age 60. Actuarial life table data from the Social Security Administration were used in
                  order to establish age-specific baseline survival data<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">11</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">We then performed a multivariable logistic regression
                  analysis adjusting for confounders and including all abstracted variables. This model was built using
                  our conceptual model of the anticipated importance of the included variables. We evaluated
                  interactions between patient age and mechanism of injury. A bootstrap analysis was performed with
                  1,000 replicates to evaluate final model discrimination using the C-statistic. Calibration was
                  assessed using the Hosmer-Lemeshow goodness-of-fit test. Results are expressed as the odds ratio (OR)
                  and 95% confidence interval (CI) along with the p value. Variables were defined as significant, a
                  priori, if they demonstrated an OR and 95% CI exclusive of 1.0 with p &lt; 0.05. All statistical
                  analyses were performed using R (version 2022.07.1+554; Posit). This investigation received an exempt
                  determination from our investigational review board prior to commencement.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">We identified 717 individuals who were eligible for
                  inclusion. The median age of this population was 66 years, with a range of 18 to 98 years. A majority
                  (59.8%) of the population was male; 69.3% were non-Hispanic White. Most of the sample (90.1%) had
                  sustained AO type-A fractures. Only 57 (7.9%) had sustained distraction injuries (AO type B) and 14
                  (2.0%) had displaced or rotational injuries (AO type C). Overall, there were very low rates of missing
                  data. The variable with the highest amount of missingness was BMI (n = 14; 2.0%), for which missing
                  values were imputed using the median BMI of the population.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The mortality rate at 3, 12, and 24 months following
                  injury was 7.0% (n = 50), 16.2% (n = 116; <a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>), and
                  20.4% (n = 146), respectively. Unadjusted analyses revealed an increased risk of mortality for
                  patients with advanced age, lower BMI, higher ISS, lower GCS score, higher CCI, need for an assistive
                  ambulatory device, and lower-energy trauma (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00706f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4f2856eb-61fb-456a-8ede-873e4be14cbb&type=jpeg&name=JBJS.24.00706f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;">Kaplan-Meier curve depicting survival in the first
                          year following thoracolumbar spinal fracture. The linear reference line represents the
                          expected survival probability of an uninjured American citizen of the median age in our cohort
                          (66 years), as calculated from the 2021 Actuarial Life Table from the U.S. Social Security
                          Administration (SSA)<a href="" data-id="citation_reference_11"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_9" class="annotation superscript"
                              style="position: unset;">11</span></a>. The shaded area indicates the 95% confidence
                          interval.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Bivariate Effect Estimates of Influence of Baseline
                          Clinical and Sociodemographic Characteristics on 1-Year Survival Following Thoracolumbar
                          Fracture</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 2632.05px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.979167);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Total</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Deceased
                          by 1 Year</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Alive at 1
                          Year</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">OR (95%
                          CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of subjects</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">717</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">116</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">601</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_19" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66
                          (49-80)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">77
                          (66-86)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64
                          (44-78)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.03
                          (1.02-1.05)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_25" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex <span
                            data-id="emphasis_20" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59.8%
                          (429)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63.8%
                          (74)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59.1%
                          (355)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40.2%
                          (288)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36.2%
                          (42)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40.9%
                          (246)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.22
                          (0.81-1.86)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.34</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_21" class="annotation emphasis"
                            style="position: unset;">(kg/m</span><span data-id="emphasis_22" class="annotation emphasis"
                            style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_10"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_23"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.8
                          (22.5-29.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.8
                          (20.5-28)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.2
                          (22.8-29.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.95
                          (0.92-0.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_26" class="annotation strong" style="position: unset;">0.01</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Race <span
                            data-id="emphasis_24" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Non-Hispanic
                          White</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69.3%
                          (497)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71.6%
                          (83)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68.9%
                          (414)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> All other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.7%
                          (220)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.4%
                          (33)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.1%
                          (187)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.88
                          (0.56-1.35)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.57</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ISS<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (4-19)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (4-27)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (4-17)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.02
                          (1.01-1.03)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_27" class="annotation strong" style="position: unset;">0.004</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">GCS<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (15-15)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (13.5-15)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (15-15)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.89
                          (0.85-0.94)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_28" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Present smoking <span
                            data-id="emphasis_25" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.3%
                          (157)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.3%
                          (18)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.4%
                          (139)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.64
                          (0.36-1.08)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.11</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Autofusion <span
                            data-id="emphasis_26" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.3%
                          (82)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.4%
                          (17)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.7%
                          (65)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.38
                          (0.75-2.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.28</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">AO TLICS
                          classification<span data-id="table_footnote_reference_5"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="emphasis_27" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> A</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90.1%
                          (646)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87.1%
                          (101)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90.6%
                          (545)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> B</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.9%
                          (57)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.6%
                          (10)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.8%
                          (47)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.15
                          (0.53-2.26)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> C</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0%
                          (14)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.3%
                          (5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.5%
                          (9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0
                          (0.91-8.86)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Polytrauma <span
                            data-id="emphasis_28" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58.4%
                          (413)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57.4%
                          (66)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58.7%
                          (347)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.95
                          (0.63-1.43)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.81</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">High-energy mechanism
                          <span data-id="emphasis_29" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53.1%
                          (381)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37.9%
                          (44)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56.1%
                          (337)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.48
                          (0.32-0.72)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_29" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CCI<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1-6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (3-8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1-5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.20
                          (1.14-1.27)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_30" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgical intervention
                          <span data-id="emphasis_30" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.2%
                          (102)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.4%
                          (19)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.8%
                          (83)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.22
                          (0.69-2.07)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.47</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preinjury independent
                          ambulator <span data-id="emphasis_31" class="annotation emphasis"
                            style="position: unset;">(no. [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78.8%
                          (565)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62.9%
                          (73)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81.9%
                          (492)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.37
                          (0.25-0.58)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_31" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Symptom complex <span
                            data-id="emphasis_32" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Axial or
                          asymptomatic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">93.4%
                          (654)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">92.0%
                          (104)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">93.7%
                          (550)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Neurologic
                          symptoms</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.6%
                          (46)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.0%
                          (9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.3%
                          (37)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.29
                          (0.57-2.63)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.52</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Area Deprivation
                          Index<span data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (10-27)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (8-25)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (11-27)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.99
                          (0.97-1.00)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.18</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Level of injury <span
                            data-id="emphasis_33" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Junctional</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44.9%
                          (322)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46.6%
                          (54)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44.6%
                          (268)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Thoracic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35.8%
                          (257)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39.7%
                          (46)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35.1%
                          (211)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.08
                          (0.70-1.67)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.72</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lumbar</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.2%
                          (138)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.8%
                          (16)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.3%
                          (122)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.65
                          (0.35-1.16)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.16</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the median with the interquartile range in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">TLICS = Thoracolumbar Injury Classification System.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">When the group was dichotomized based on the threshold of
                  65 years of age, there was significantly higher survival for younger individuals when compared with
                  the older cohort (p &lt; 0.001). The difference between the cohorts began within the first 30 days
                  following injury (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>). When
                  evaluated by decile starting at age 60, similar significant differences between the groups based on
                  age were apparent, starting within 30 days of the date of injury (p = 0.004; <a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00706f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4f2856eb-61fb-456a-8ede-873e4be14cbb&type=jpeg&name=JBJS.24.00706f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Kaplan-Meier curve depicting survival in the first
                          year following thoracolumbar spinal fracture based on age group. The linear reference lines
                          demonstrate projected survival probabilities of uninjured American citizens 42 and 82 years
                          old for the younger and older groups, respectively, as calculated from the 2021 Actuarial Life
                          Table from the U.S. Social Security Administration (SSA)<a href=""
                            data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                              style="position: unset;">11</span></a>.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00706f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4f2856eb-61fb-456a-8ede-873e4be14cbb&type=jpeg&name=JBJS.24.00706f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;">Kaplan-Meier curve depicting survival in the first
                          year following thoracolumbar spinal fracture among individuals aged 60 years and older,
                          depicted by decade of life. The linear reference lines demonstrate projected survival
                          probabilities of uninjured American citizens 65, 75, and 85 years old for the younger,
                          intermediate, and older groups, respectively, as calculated from the 2021 Actuarial Life Table
                          from the U.S. Social Security Administration (SSA)<a href="" data-id="citation_reference_13"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_12" class="annotation superscript"
                              style="position: unset;">11</span></a>.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">In our multivariable adjusted model, patients who died
                  within the first year following injury were more likely to be older (OR = 1.03 per annual increase in
                  age; 95% CI = 1.01 to 1.05) and male (OR = 1.67; 95% CI = 1.05 to 2.69). A higher ISS (OR = 1.03; 95%
                  CI = 1.01 to 1.05), lower GCS score (OR = 0.82; 95% CI = 0.76 to 0.89), and higher CCI (OR = 1.16; 95%
                  CI = 1.07 to 1.25) at presentation were also significant factors (<a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). The paradoxical association between a high-energy injury
                  mechanism and better survival (OR = 0.50; 95% CI = 0.27 to 0.90) was postulated to result from a
                  predilection for younger individuals to sustain injuries from high-energy trauma. This was confirmed
                  in our interaction analysis considering age and mechanism of injury, where the significance of the
                  injury mechanism was lost (p = 0.19) (see Appendix Supplementary Fig. 1). The final model demonstrated
                  good discriminative properties, with a C-statistic of 0.81 (95% CI = 0.81 to 0.83). There was no
                  statistical evidence for a lack of fit (p = 0.43).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Results of Multivariable Regression Model Evaluating
                          Association Between Clinical and Sociodemographic Factors and 1-Year Survival Following
                          Thoracolumbar Fracture</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1450px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Characteristic</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">OR</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          CI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.03</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.01-1.05</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_32" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.67</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.05-2.69</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_33" class="annotation strong" style="position: unset;">0.03</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Race</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Non-Hispanic
                          White</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> All others</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.06</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.64-1.75</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.81</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.96</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.92-1.00</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.07</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CCI</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.16</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.07-1.25</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_34" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ISS</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.03</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.01-1.05</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_35" class="annotation strong" style="position: unset;">0.005</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">GCS</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.82</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.76-0.89</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_36" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">AO TLICS
                          classification<span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> A</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> B</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.80</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.32-1.88</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.62</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> C</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.93</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.86-17.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.07</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">High-energy
                          mechanism</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.50</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.27-0.90</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_37" class="annotation strong" style="position: unset;">0.02</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgical
                          intervention</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.21</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.58-2.42</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.59</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preinjury ambulatory
                          status</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text"
                          style="position: unset;"> Device-dependent/nonambulatory</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Independent</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.73</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.42-1.24</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.24</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Level of injury</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Junctional</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Thoracic</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.07</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.65-1.75</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.80</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lumbar</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.63</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.31-1.21</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.18</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">TLICS = Thoracolumbar Injury Classification System.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">In this investigation, we studied the natural history of
                  survival following thoracolumbar spine fractures in a large cohort with broad clinical variation and
                  comprehensive longitudinal surveillance for mortality. Prior studies in this arena have generally
                  fallen short of a comprehensive assessment of survival and have generally been impaired by limited
                  periods of observation, restricted samples, or specific subsets of thoracolumbar trauma<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_13" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">13</span></a>. Our work is strengthened by a granular capture of patient-
                  and injury-specific variables as well as a robust statistical approach that limits the impact of
                  confounding and creates estimates that we believe are generalizable to clinical care in any North
                  American context.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Given the wide age range (18 to 98 years) and broad
                  spectrum of thoracolumbar injury morphologies included in our study set, we found the mortality rates
                  of 7.0%, 16.2%, and 20.4% at 3, 12, and 24 months following injury, respectively, to be quite
                  sobering. These estimates are comparable with the anticipated impact of cervical fractures<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">14</span></a> and hip fractures<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">2</span></a> on
                  survival among older patients within the first year following injury. In the past, thoracolumbar
                  injuries and particularly OCFs have generally been viewed as innocuous, with limited anticipated
                  impact on survival once they have healed. Our findings suggest that a paradigm shift is warranted,
                  with a thoracolumbar fracture of any type viewed as a sentinel event in the life of individuals aged
                  60 years and older and with perceptions of these fractures being more aligned with current perceptions
                  around cervical spine trauma and hip fractures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">We identified several clinical and demographic
                  characteristics that are associated with survival after thoracolumbar fractures, with 81% of the
                  variation in 1-year mortality explained by the factors that we included in our final regression model.
                  Patients who died within a year following their injury were more likely to be older, be biologically
                  male, have a greater number of comorbidities, and present with a higher ISS and lower GCS score. These
                  findings generally align with previous studies on survival following spinal trauma. For example, in a
                  large-scale investigation relying on Medicare claims, Lau et al. reported that males, older patients,
                  and those with an elevated CCI were at higher risk for death<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">12</span></a>. In
                  their series of elderly patients treated for any type of spinal trauma, Carlile et al. identified age
                  as the single most important predictor of survival, followed by factors such as frailty, head injury,
                  Maximum Abbreviated Injury Scale score, and male sex<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">15</span></a>.
                  Male sex has long been purported to be associated with higher mortality in the trauma population as a
                  whole and is postulated to stem from a greater number of comorbidities in male patients and a
                  proclivity for higher-energy trauma<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">16</span></a>. In
                  general, our results add to prior literature by demonstrating the intersections among thoracolumbar
                  fracture pattern, age, injury mechanism, trauma burden, and medical comorbidities and their influence
                  on survival in the years following an injury event.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">At a minimum, age and comorbidities are clear markers of
                  physiologic frailty, and individuals with reduced physiologic reserves are likely to have greater
                  challenges recovering from thoracolumbar trauma given its impact on mobility and daily function
                  irrespective of the presence of concomitant neurologic involvement. Ambulatory function at baseline is
                  another important marker of general health and frailty at the time of clinical presentation<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">18</span></a>. In this context, our multivariable model estimated that
                  independent ambulatory status at the time of injury had a protective effect, but this finding did not
                  reach significance due to a wide 95% CI (OR = 0.73; 95% CI = 0.42 to 1.24). In a larger sample with
                  similar characteristics, we would anticipate this factor to exert a significant influence on survival.
                  In individuals with reduced physiologic reserve, a thoracolumbar fracture could represent an inciting
                  event that initiates a cascade of functional degradation via a combination of limited mobility similar
                  to that experienced with hip fractures. Mobility restrictions can lead to accelerated muscle atrophy;
                  cognitive deterioration; and an increased risk of venous thromboembolic events, aspiration, pneumonia,
                  and falls; these factors may accumulate in a synergistic way to heighten the likelihood of near-term
                  mortality. While operative fixation of hip fractures can mitigate some of the risk of these events,
                  this is not the case in the setting of thoracolumbar fractures, where the limitations are likely to
                  persist or possibly even worsen following surgical intervention. This is why our multivariable
                  regression results showed no significant difference in survival between individuals managed
                  operatively and those treated nonoperatively, although the point estimate did suggest a heightened
                  risk of mortality for individuals treated with surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Therefore, we believe that the results presented here have
                  immediate relevance to clinical practice and would be useful to clinicians, patients, their families,
                  and health-care systems. Foremost, our estimates regarding longevity and prospects for survival,
                  delineated according to clinical and demographic characteristics discernible at baseline, could inform
                  discussions regarding the natural history of thoracolumbar fractures, patient education, and
                  decision-making regarding care plans in the inpatient and outpatient settings. In addition, high-risk
                  patients who possess the characteristics that increase the likelihood of mortality might benefit from
                  early intervention when practicable, enhanced mobilization, nutritional optimization, and expedited
                  comorbidity management as ways to mitigate the prospect of near-term mortality following thoracolumbar
                  trauma. The potential for such interventions in high-risk patients and their anticipated impact on
                  longevity remain to be discerned and represent additional lines of future research that can
                  conceivably follow from the current work.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">We also recognize several important limitations to this
                  investigation. First, we acknowledge the inherent risks associated with our study’s retrospective
                  observational design, including inaccurate documentation, limited understanding of the reasons for
                  clinical decisions, expertise bias, and inability to demonstrate causality. There is also the prospect
                  of restricted clinical variation and limited power in certain study domains. This is evident in the
                  fact that AO type-A fractures were overrepresented, with more limited numbers of type-B and C
                  injuries. Similarly, as noted above, there was a smaller sample of patients presenting as not
                  independently ambulatory at the time of injury, especially among those aged 60 years and older. This
                  impacted our ability to detect the true influence of these variables on 1-year survival. Finally, we
                  recognize that these patients were treated at 4 hospitals within an integrated health-care system that
                  share training programs and faculty. This could have created a clustering effect that muted variation
                  in clinical management in spite of the multicenter design of this study. We emphasize, however, that
                  management protocols and treatment approaches in our system are well aligned with other level-I trauma
                  centers. As a result, we believe that the findings are translatable to other centers treating patient
                  populations whose composition and characteristics are similar to our own.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, we described the natural history of
                  survival out to 2 years following thoracolumbar spine fractures. Notably, we found non-negligible
                  rates of mortality, similar to estimates for individuals who sustain injuries generally considered
                  more inimical to survival, such as hip and cervical spine fractures. We believe that this is an
                  underappreciated fact and that a paradigm shift is necessary in terms of how thoracolumbar spine
                  injuries are viewed, especially in those aged 60 years and older and individuals with multiple
                  comorbidities. The results presented here can be used in patient and family counseling, informed
                  decision-making, and resource allocation aimed at mitigating the risk of near-term mortality in
                  high-risk individuals who sustained thoracolumbar spinal fractures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_3" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I283" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I283</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Jansson KÅ</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Blomqvist P</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Svedmark P</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Granath F</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Buskens E</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Larsson M</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Adami J</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Thoracolumbar vertebral
                  fractures in Sweden: an analysis of 13,496 patients admitted to hospital</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Eur J Epidemiol.</span> 2010
                Jun;25(6):431-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Thoracolumbar%20vertebral%20fractures%20in%20Sweden%3A%20an%20analysis%20of%2013%2C496%20patients%20admitted%20to%20hospital&as_occt=title&as_sauthors=%20%22K%C3%85%20Jansson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Schnell S</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Friedman SM</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Mendelson DA</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Bingham KW</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Kates SL</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The 1-year mortality of patients
                  treated in a hip fracture program for elders</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Geriatr Orthop Surg Rehabil.</span> 2010
                Sep;1(1):6-14.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%201-year%20mortality%20of%20patients%20treated%20in%20a%20hip%20fracture%20program%20for%20elders&as_occt=title&as_sauthors=%20%22S%20Schnell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Zuckerman JD</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Skovron ML</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Koval KJ</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Aharonoff G</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Frankel VH</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Postoperative complications and
                  mortality associated with operative delay in older patients who have a fracture of the hip</span>.
                <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 1995 Oct;77(10):1551-6.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=615946" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Lightsey Iv HM</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Giberson-Chen CC</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Crawford AM</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Striano BM</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Harris MB</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Bono CM</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Simpson AK</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Schoenfeld AJ</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Thoracolumbar Injury
                  Classification Systems: The Importance of Concepts and Language in the Move Toward
                  Standardization</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2023 Mar 15;48(6):436-43.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Thoracolumbar%20Injury%20Classification%20Systems%3A%20The%20Importance%20of%20Concepts%20and%20Language%20in%20the%20Move%20Toward%20Standardization&as_occt=title&as_sauthors=%20%22HM%20Lightsey%20Iv%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Crawford AM</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Xiong GX</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Lightsey HM</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Goh BC</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Smith JT</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Hershman SH</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Schoenfeld AJ</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Simpson AK</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Insurance type is associated
                  with baseline patient-reported outcome measures in patients with lumbar stenosis</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2022 May 15;47(10):737-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Insurance%20type%20is%20associated%20with%20baseline%20patient-reported%20outcome%20measures%20in%20patients%20with%20lumbar%20stenosis&as_occt=title&as_sauthors=%20%22AM%20Crawford%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Crawford AM</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Striano BM</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Gong J</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Koehlmoos TP</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Simpson AK</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Schoenfeld AJ</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Validation of the Stopping
                  Opioids After Surgery (SOS) Score for the Sustained Use of Prescription Opioids Following Orthopaedic
                  Surgery</span>. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J Bone
                  Joint Surg Am.</span> 2023 Sep 20;105(18):1403-9.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=018e2352-f324-4ec9-80bc-add9d7ea4556" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Nigwekar SU</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Solid CA</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Ankers E</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Malhotra R</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Eggert W</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Turchin A</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Thadhani RI</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Herzog CA</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Quantifying a rare disease in
                  administrative data: the example of calciphylaxis</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">J Gen Intern Med.</span> 2014 Aug;29(Suppl
                3):S724-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Quantifying%20a%20rare%20disease%20in%20administrative%20data%3A%20the%20example%20of%20calciphylaxis&as_occt=title&as_sauthors=%20%22SU%20Nigwekar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Meinberg EG</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Agel J</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Roberts CS</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Karam MD</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Kellam JF</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Fracture and Dislocation
                  Classification Compendium-2018</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2018 Jan;32(Suppl 1):S1-170.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fracture%20and%20Dislocation%20Classification%20Compendium-2018&as_occt=title&as_sauthors=%20%22EG%20Meinberg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;">Spine.
                <span data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span>
                2018 Jan;32(Suppl 1):S145-60.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Kind AJH</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Buckingham WR</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Making Neighborhood-Disadvantage
                  Metrics Accessible-The Neighborhood Atlas</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">N Engl J Med.</span> 2018 Jun
                28;378(26):2456-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Making%20Neighborhood-Disadvantage%20Metrics%20Accessible-The%20Neighborhood%20Atlas&as_occt=title&as_sauthors=%20%22AJH%20Kind%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;">Social
                Security Administration. <span data-id="strong_10" class="annotation strong"
                  style="position: unset;">Actuarial Life Table</span>. 2021. Accessed 2024 Sep 30. <a
                  href="https://www.ssa.gov/oact/STATS/table4c6.html#fn1" target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.ssa.gov/oact/STATS/table4c6.html#fn1</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Actuarial%20Life%20Table&as_occt=title&as_sauthors=%20%22Social%20Security%20Administration%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Lau E</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Ong K</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Kurtz S</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Schmier J</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Edidin A</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Mortality Following the
                  Diagnosis of a Vertebral Compression Fracture in the Medicare Population</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2008;90(7):1479-86.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1037544" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Son HJ</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Park SJ</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Kim JK</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Park JS</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Mortality risk after the first
                  occurrence of osteoporotic vertebral compression fractures in the general population: A nationwide
                  cohort study</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">PLoS One.</span> 2023 Sep 14;18(9):e0291561.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mortality%20risk%20after%20the%20first%20occurrence%20of%20osteoporotic%20vertebral%20compression%20fractures%20in%20the%20general%20population%3A%20A%20nationwide%20cohort%20study&as_occt=title&as_sauthors=%20%22HJ%20Son%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Cronin PK</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Ferrone ML</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Marso CC</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Stieler EK</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Beck AW</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Blucher JA</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Makhni MC</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Simpson AK</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Harris MB</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Schoenfeld AJ</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Predicting survival in older
                  patients treated for cervical spine fractures: development of a clinical survival score</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Spine J.</span> 2019
                Sep;19(9):1490-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predicting%20survival%20in%20older%20patients%20treated%20for%20cervical%20spine%20fractures%3A%20development%20of%20a%20clinical%20survival%20score&as_occt=title&as_sauthors=%20%22PK%20Cronin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Carlile CR</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Rees AB</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Schultz JD</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Steinle AM</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Nian H</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Smith MD</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Guillamondegui O</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Archer KR</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Pennings JS</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Zuckerman SL</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Abtahi AM</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Stephens BF</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Predicting Mortality in Elderly
                  Spine Trauma Patients</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2022 Jul 15;47(14):977-85.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predicting%20Mortality%20in%20Elderly%20Spine%20Trauma%20Patients&as_occt=title&as_sauthors=%20%22CR%20Carlile%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Center JR</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Nguyen TV</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Schneider D</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Sambrook PN</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Eisman JA</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Mortality after all major types
                  of osteoporotic fracture in men and women: an observational study</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">Lancet.</span> 1999 Mar
                13;353(9156):878-82.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mortality%20after%20all%20major%20types%20of%20osteoporotic%20fracture%20in%20men%20and%20women%3A%20an%20observational%20study&as_occt=title&as_sauthors=%20%22JR%20Center%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Schoenfeld AJ</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Yeung CM</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Tobert DG</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Nguyen L</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Passias PG</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Shin JH</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Kang JD</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Ferrone ML</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Characterizing Health-Related
                  Quality of Life by Ambulatory Status in Patients with Spinal Metastases</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2022 Jan 15;47(2):99-104.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Characterizing%20Health-Related%20Quality%20of%20Life%20by%20Ambulatory%20Status%20in%20Patients%20with%20Spinal%20Metastases&as_occt=title&as_sauthors=%20%22AJ%20Schoenfeld%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Xiong GX</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Fisher MWA</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Schwab JH</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Simpson AK</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Nguyen L</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Tobert DG</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Balboni TA</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Shin JH</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Ferrone ML</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Schoenfeld AJ</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">A Natural History of Patients
                  Treated Operatively and Nonoperatively for Spinal Metastases Over 2 Years Following Treatment:
                  Survival and Functional Outcomes</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2022 Apr 1;47(7):515-22.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Natural%20History%20of%20Patients%20Treated%20Operatively%20and%20Nonoperatively%20for%20Spinal%20Metastases%20Over%202%20Years%20Following%20Treatment%3A%20Survival%20and%20Functional%20Outcomes&as_occt=title&as_sauthors=%20%22GX%20Xiong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;4f2856eb-61fb-456a-8ede-873e4be14cbb&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=4f2856eb-61fb-456a-8ede-873e4be14cbb&type=supplement&name=12523"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D4f2856eb-61fb-456a-8ede-873e4be14cbb%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 1, 2025;
                      107 (1): 73</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00706</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 19, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_18"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Mass General
                            Brigham, Harvard Medical School, Boston, Massachusetts</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;4f2856eb-61fb-456a-8ede-873e4be14cbb&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=4f2856eb-61fb-456a-8ede-873e4be14cbb&type=pdf&name=JBJS.24.00706.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=4f2856eb-61fb-456a-8ede-873e4be14cbb&type=pdf&name=JBJS.24.00706.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_23"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this study. The <span data-id="strong_24" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I282"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I282</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;4f2856eb-61fb-456a-8ede-873e4be14cbb&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=4f2856eb-61fb-456a-8ede-873e4be14cbb&type=zip&name=JBJS.24.00706.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Brendan M. Striano, MD, MPH<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8665-3269" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8665-3269</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alexander M. Crawford, MD, MPH<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4965-6046" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4965-6046</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jonathan Gong, BS<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0008-1235-0282" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0008-1235-0282</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Vineet Desai, BS<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0007-0059-8160" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0007-0059-8160</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Daniel C. Gabriel, BS<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0004-3796-410X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0004-3796-410X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">J. Taylor Bellamy, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6195-8293" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6195-8293</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tino Mukorombindo, MD, MBA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0005-8356-7065" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0005-8356-7065</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lara L. Cohen, MD, MPH<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1412-2001" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1412-2001</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Andrew K. Simpson, MD, MBA, MHS<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8731-3576" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8731-3576</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Andrew J. Schoenfeld, MD, MSc<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:aschoenfeld@bwh.harvard.edu" class=""
                    style="position: unset;">aschoenfeld@bwh.harvard.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3691-1215" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3691-1215</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Harvard Combined Orthopaedic Residency Program, Harvard Medical School, Boston,
                Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Brigham and Women’s Hospital, Harvard
                Medical School, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Harvard Medical School, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 25062.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
