import StemExpandable from "../../StemExpandable";

interface Props {
	stem: string;
	stemTitle: string;
}
const StudySessionItemGroupPassageStem = ({ stem, stemTitle }: Props) => {
	return (
		<StemExpandable initExpand expandable stemTitle={stemTitle} stem={stem} />
	);
};

export default StudySessionItemGroupPassageStem;
