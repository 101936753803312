/* ----------------- Helpers --------------- */
import { getMappedExams } from "@helpers/exams";
import { getPracticeTestsQuizzesAndExams } from "../helpers/practice-tests-data";

/* ----------------- Hooks --------------- */
import { usePracticeTests } from "./usePracticeTests";

import { events } from "@constants/tracking";
import { isWeb } from "@helpers/platform";
import type { Distribution, PracticeTestExam } from "@memorang/types";
import { trackCustomEvent } from "analytics";
/* ----------------- Types --------------- */
import type { EXAM_STATUS } from "types/tag";
import { taskCardBodyTemplate } from "./constants";
import { useDailyProgress } from "./useDailyProgress";
interface SectionData {
	title: string;
	subtitle: string;
	status: EXAM_STATUS;
	handleClickTask: () => void;
	body: string;
	loading?: boolean;
	completion?: number;
	showRibbon?: boolean;
	free?: boolean;
	showReport?: boolean;
}

export const isCompletedInLast24Hours = (dateCompleted: string): boolean => {
	const completedDate = new Date(dateCompleted);
	const currentDate = new Date();
	const timeDifference = currentDate.getTime() - completedDate.getTime();
	const hoursDifference = timeDifference / (1000 * 3600);
	return hoursDifference < 24;
};
const filterExamsByStatus = (
	exams: PracticeTestExam[],
	status: EXAM_STATUS,
) => {
	return exams.filter((exam) => exam.status === status);
};

const createSectionData = ({
	exam,
	body,
	selectedExamId,
	creatingSession,
	handlePracticeTestSession,
}: {
	exam: PracticeTestExam;
	body: string;
	selectedExamId?: string;
	creatingSession?: boolean;
	handlePracticeTestSession?: (
		examId: string,
		status: EXAM_STATUS,
		numericId?: number,
		isQuiz?: boolean,
		hasExamAccess?: boolean,
	) => void;
}): SectionData => {
	const {
		title,
		description,
		status,
		id,
		hasExamAccess,
		numericId,
		score,
		type,
	} = getMappedExams([exam])[0];

	const isQuiz = type === "QUIZ";
	return {
		title,
		subtitle: description,
		status,
		completion: score,
		loading: creatingSession && selectedExamId === id,
		handleClickTask: () => {
			trackCustomEvent({
				eventName: events.taskCardClicked,
				isDiagnostic: `${isQuiz}`,
				status,
				numericId,
				hasAccess: `${hasExamAccess}`,
			});
			handlePracticeTestSession!(id, status, numericId, isQuiz, hasExamAccess);
		},
		body,
		showRibbon: isQuiz ? true : !hasExamAccess,
		free: isQuiz,
	};
};

export const useRecommendationSection = (distribution?: Distribution) => {
	const {
		mixedItemDistResponse,
		handlePracticeTestSession,
		selectedExamId,
		creatingSession,
		loading,
		showPurchaseDialog,
		toggleShowPurchaseDialog,
		olxExamEligibility,
		setOlxExamEligibility,
		showCollectUserDetailsDialog,
		toggleShowCollectUserDetailsDialog,
	} = usePracticeTests();

	const dailyProgressHookData = useDailyProgress(distribution);

	if (!mixedItemDistResponse || loading) {
		return {
			tasksSection: {
				loading: true,
				sectionTitle: "Recommended Tasks",
				type: "tasks" as const,
				onPress: undefined,
				data: [],
			},
			showPurchaseDialog,
			toggleShowPurchaseDialog,
		};
	}

	const {
		getMixedItemDistribution: { exams, hasAccess },
	} = mixedItemDistResponse!;

	const { practiceTestQuizzes, practiceTests } =
		getPracticeTestsQuizzesAndExams(exams || []);
	const sectionData: SectionData[] = [];

	if (dailyProgressHookData?.dailyProgressSectionData) {
		sectionData.push(dailyProgressHookData?.dailyProgressSectionData);
	}

	const processCompletedItems = (exams: PracticeTestExam[]) => {
		const recentCompletedItems = exams.filter((item) =>
			isCompletedInLast24Hours(item.dateCompleted),
		);
		if (recentCompletedItems.length > 0) {
			for (const exam of recentCompletedItems) {
				const isDiagonstic = exam.type === "QUIZ";
				const body = taskCardBodyTemplate({ isDiagonstic }).COMPLETE;
				sectionData.push(
					createSectionData({
						exam,
						handlePracticeTestSession,
						body,
					}),
				);
			}
			return true;
		}
	};
	const processPausedItems = (exams: PracticeTestExam[]) => {
		const pausedItems = filterExamsByStatus(exams, "ACTIVE");

		if (pausedItems.length > 0) {
			const exam = pausedItems[0];
			const isDiagonstic = exam.type === "QUIZ";

			const body = taskCardBodyTemplate({ isDiagonstic }).ACTIVE;

			sectionData.push(
				createSectionData({
					exam,
					body,
					handlePracticeTestSession,
					selectedExamId,
					creatingSession,
				}),
			);
			return true;
		}
	};
	const processNotStartedItems = (items: PracticeTestExam[]) => {
		const notStartedItems = filterExamsByStatus(items, "NOT_STARTED");

		const finalNotStartedItems = notStartedItems.filter((item) =>
			item.id.includes("OLX") ? item.hasAccess : true,
		);

		if (finalNotStartedItems.length > 0) {
			const exam = finalNotStartedItems[0];
			const isDiagonstic = exam.type === "QUIZ";
			const body = taskCardBodyTemplate({ isDiagonstic }).NOT_STARTED;
			sectionData.push(
				createSectionData({
					exam,
					body,
					handlePracticeTestSession,
					selectedExamId,
					creatingSession,
				}),
			);
		}

		return sectionData;
	};

	const processItems = (
		practiceTests: PracticeTestExam[],
		practiceTestQuizzes: PracticeTestExam[],
	) => {
		const hasPausedPracticeTests = processPausedItems(practiceTests);
		const hasPausedQuiz = processPausedItems(practiceTestQuizzes);

		const processQuiz = () => {
			if (!hasPausedQuiz) {
				const hasCompletedQuizzes = processCompletedItems(practiceTestQuizzes);
				if (!hasCompletedQuizzes) {
					processNotStartedItems(practiceTestQuizzes);
				}
			}
		};
		if (hasPausedPracticeTests) {
			processQuiz();
		} else {
			const hasCompletedPracticeTests = processCompletedItems(practiceTests);
			if (hasCompletedPracticeTests) {
				processQuiz();
			} else if (hasAccess) {
				processNotStartedItems(practiceTests);
				processQuiz();
			} else {
				processQuiz();
				processNotStartedItems(practiceTests);
			}
		}
	};

	if (isWeb) {
		processItems(practiceTests, practiceTestQuizzes);
	}

	if (sectionData.length) {
		return {
			tasksSection: {
				sectionTitle: "Recommended Tasks",
				type: "tasks" as const,
				onPress: undefined,
				data: sectionData,
			},
			showPurchaseDialog,
			toggleShowPurchaseDialog,
			olxExamEligibility,
			setOlxExamEligibility,
			showCollectUserDetailsDialog,
			toggleShowCollectUserDetailsDialog,
		};
	}
	return null;
};
