/**
 * @generated SignedSource<<2305ca5a602bb1addbd4178d0a3b3f4f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */

// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FetchCategoriesQuery$variables = {
  appId?: string | null;
  tenantId?: string | null;
};
export type FetchCategoriesQuery$data = {
  readonly examList: ReadonlyArray<{
    readonly exam: {
      readonly category: ReadonlyArray<{
        readonly icon: any | null;
        readonly id: string;
        readonly name: string;
      } | null> | null;
      readonly id: string;
    };
    readonly id: string;
  }>;
};
export type FetchCategoriesQuery = {
  response: FetchCategoriesQuery$data;
  variables: FetchCategoriesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "appId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "appId",
        "variableName": "appId"
      },
      {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
      }
    ],
    "concreteType": "ExamDetails",
    "kind": "LinkedField",
    "name": "examList",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ExamContext",
        "kind": "LinkedField",
        "name": "exam",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Category",
            "kind": "LinkedField",
            "name": "category",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "icon",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchCategoriesQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FetchCategoriesQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "ea70cba42efb810e3edf939ab85fc487",
    "id": null,
    "metadata": {},
    "name": "FetchCategoriesQuery",
    "operationKind": "query",
    "text": "query FetchCategoriesQuery(\n  $tenantId: ID\n  $appId: ID\n) {\n  examList(tenantId: $tenantId, appId: $appId) {\n    id\n    exam {\n      id\n      category {\n        id\n        name\n        icon\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "82d082987985371e1cc5ea200b7ad3c7";

export default node;
