import type { Article } from "../../../../../types";

export const Article554: Article = {
	id: 554,
	rsuiteId: "6cdfcdbd-ca7f-4e61-8193-cbcbfc99e499",
	type: "scientific article",
	title:
		"No Discernible Difference in Revision Rate or Survivorship Between Posterior Cruciate-Retaining and Posterior Cruciate-Substituting TKA",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6cdfcdbd-ca7f-4e61-8193-cbcbfc99e499&type=jpeg&name=JBJS.24.00007f1",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">No Discernible Difference in Revision Rate or
                Survivorship Between Posterior Cruciate-Retaining and Posterior Cruciate-Substituting TKA</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Young-Hoo
                      Kim, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jang-Won
                      Park, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 2 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_39" class="annotation strong"
                          style="position: unset;">Background:</span> Many authors and the data of multiple registries
                        have suggested that the use of posterior cruciate-substituting (posterior stabilized [PS]) total
                        knee arthroplasty (TKA) leads to a higher risk of revision compared with the use of posterior
                        cruciate-retaining (CR) TKA. The aim of the present prospective, randomized, long-term study was
                        to compare PS and CR TKA with regard to the clinical, radiographic, and computed tomography (CT)
                        results; the prevalence of osteolysis; revision rate; and survivorship.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_40" class="annotation strong"
                          style="position: unset;">Methods:</span> This study included a consecutive series of 300
                        patients (mean age [and standard deviation], 63.6 ± 6 years) who underwent simultaneous,
                        bilateral TKA in the same anesthetic session. Each patient received a NexGen CR-Flex prosthesis
                        on 1 side and a NexGen LPS-Flex prosthesis on the contralateral side. The mean follow-up period
                        was 18 years (range, 17.5 to 19.5 years).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_41" class="annotation strong"
                          style="position: unset;">Results:</span> There were no significant differences between the
                        NexGen CR and LPS-Flex TKA groups at the latest follow-up with regard to the mean Knee Society
                        knee score (93 versus 92 points, respectively); the Western Ontario and McMaster Universities
                        Osteoarthritis Index score (19.1 points for both); the University of California Los Angeles
                        activity score (6.1 points for both); range of motion (125° ± 6.1° versus 126° ± 6.5°);
                        radiographic and CT results; and revision rate (6.0% versus 6.3%). No knee had osteolysis. The
                        estimated survival rate at 19.5 years was 94% (95% confidence interval [CI], 91% to 100%) for
                        the NexGen CR-Flex prosthesis and 93.7% (95% CI, 91% to 100%) for the LPS-Flex prosthesis, with
                        revision or aseptic loosening as the end point.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_42" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The findings of the present, long-term (minimum
                        follow-up of 17.5 years) clinical study showed that NexGen CR-Flex and NexGen LPS-Flex implants
                        produced excellent clinical and radiographic results. There was no notable clinical advantage of
                        a NexGen CR-Flex implant over a NexGen LPS-Flex implant.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_43" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level II</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">An unresolved question is whether posterior
                  cruciate-retaining (CR) total knee arthroplasty (TKA) has better long-term survival than posterior
                  cruciate-substituting (posterior stabilized [PS]) TKA<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">5</span></a>. In numerous follow-up studies, authors have investigated CR
                  and PS TKAs on the basis of clinical, radiographic, and survivorship analyses<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">17</span></a>.
                  Although there is some information about the early or midterm performance of CR and PS TKAs<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">18</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">20</span></a>, to our knowledge, there is limited information about the
                  long-term performance of these TKAs performed by 1 surgeon at 1 academic institution.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Registry data and other retrospective studies<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">28</span></a> have
                  suggested a higher risk of revision for PS TKA compared with CR TKA<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">28</span></a>.
                  However, registry data are retrospective and involve multiple surgeons; variable, older TKA prosthesis
                  designs; and multiple institutions. Furthermore, there is the potential for selection and reporting
                  biases. A comparison of the long-term results among patients who underwent TKA procedures performed by
                  a single surgeon, using contemporary TKA prostheses, would eliminate the variability that is
                  introduced by differences in sex, age, weight, comorbidity, bone quality, and activity level, and
                  allow for a meaningful comparison of the outcomes of TKA. Thus, the purpose of this prospective,
                  randomized study was to compare the long-term results (minimum follow-up of 17.5 years) of patients
                  with osteoarthritis (≤65 years of age) who underwent simultaneous CR and PS TKAs that were performed
                  by 1 surgeon using contemporary TKA prostheses. We compared functional scores, radiographic and
                  computed tomography (CT) results, the prevalence of osteolysis, the revision rate, and survivorship
                  between CR and PS TKAs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study Design
                and Patient Identification</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">From January 2004 to June 2006, the senior author
                  performed 660 consecutive TKAs in 330 Korean patients ≤65 years of age during the same anesthetic
                  session at 1 academic institution. These patients had bilateral primary osteoarthritis of the knees
                  (Ahlbäck grade III to V) that was severe enough to require TKA<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">29</span></a>. Patients who were ≥66 years of age and patients with
                  inflammatory arthritis were excluded. Five patients (10 knees) were excluded because the patients
                  moved abroad. Six patients died, and 19 patients (38 knees) were lost to follow-up before 5 years. A
                  total of 300 patients (600 knees; 91% of the patients) were ultimately available for clinical and
                  radiographic evaluation at a mean of 18 years (range, 17.5 to 19.5 years) (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00007f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6cdfcdbd-ca7f-4e61-8193-cbcbfc99e499&type=jpeg&name=JBJS.24.00007f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;">CONSORT (Consolidated Standards of Reporting
                          Trials) flow diagram of patients in the study.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The study was registered at ClinicalTrials.gov
                  (NCT06124651). Our institutional review board approved the study, and informed consent was obtained
                  from all patients. The use of a CR TKA prosthesis (NexGen CR-Flex; Zimmer) or PS TKA prosthesis
                  (NexGen LPS-Flex; Zimmer) was determined by using a sequential pool based on a table of randomized
                  numbers. Each patient received a CR-Flex component on 1 side and an LPS-Flex component on the
                  contralateral side.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">One hundred and fifteen patients (230 knees) were male and
                  185 patients (370 knees) were female. The mean age of the patients (and standard deviation) at the
                  time of the operation was 63.6 ± 6 years (range, 40 to 65 years). The predominance of women in the
                  current series is presumably attributable to inherent bowleggedness and performing activities of daily
                  living in a squatting position. The high prevalence of younger patients who underwent TKA is related
                  to a high activity level while in the squatting position. The mean height was 154.8 ± 6.2 cm (range,
                  146 to 189 cm), and the mean weight was 64.2 ± 8.4 kg (range, 45 to 91 kg). The mean body mass index
                  was 26.8 ± 5.1 kg/m<span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">2</span> (range, 20 to 31 kg/m<span data-id="superscript_8"
                    class="annotation superscript" style="position: unset;">2</span>) (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_17" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Demographic Data<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 555px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of patients (no. of
                          knees)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">300
                          (600)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex <span
                            data-id="emphasis_42" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">115</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">185</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_43" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63.6 ± 6
                          (40-65)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Height<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_44" class="annotation emphasis"
                            style="position: unset;">(cm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">154.8 ±
                          6.2 (146-189)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Weight<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_45" class="annotation emphasis"
                            style="position: unset;">(kg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64.2 ± 8.4
                          (45-91)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_46" class="annotation emphasis"
                            style="position: unset;">(kg/m</span><span data-id="emphasis_47" class="annotation emphasis"
                            style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_9"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_48"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.8 ± 5.1
                          (20-31)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Diagnosis of
                          osteoarthritis <span data-id="emphasis_49" class="annotation emphasis"
                            style="position: unset;">(no. of knees)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">600</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">UCLA activity level at
                          the time of final follow-up <span data-id="emphasis_50" class="annotation emphasis"
                            style="position: unset;">(points)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.1
                          (4-10)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Follow-up<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_51" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (17.5-19.5)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">BMI = body mean index, and UCLA = University of California Los
                        Angeles.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Values are given as the mean and standard deviation, with the range in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Operative
                Technique and Postoperative Treatment</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The senior author performed all simultaneous, bilateral
                  TKAs during the same anesthetic session with tourniquet inflation to 250 mm Hg. An anterior midline
                  skin incision (10 to 15 cm in length) and a medial parapatellar capsular incision were made. Ten
                  millimeters of tibial bone were resected, with a 3° to 7° posterior tibial slope in both groups per
                  the manufacturer recommendation. The slope was set at 6° for the CR TKA and 3° for the PS TKA. The
                  anterior-posterior cut of the distal part of the femur was performed by using an anterior cortical
                  reference. The rotational alignment of the femoral component (Co-Cr-Mo alloy) was determined with use
                  of 3 reference axes: (1) the transepicondylar axis, (2) the midtrochlear (Whiteside) line<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">30</span></a>, and (3) 3° of external rotation relative to the posterior
                  aspect of the condyles. The tibial component (Ti-6V-4Al alloy) was inserted perpendicular to the
                  anatomical axis of the tibia. Compression-molded polyethylene (machined from GUR 1050 resin bar) was
                  used for both knees. Ligamentous balance was established first for knee extension and then for knee
                  flexion with the use of a tensor. All patellae were resurfaced with a polyethylene implant. All
                  implants were cemented after pulsed lavage irrigation, drying, and pressurization of vacuum-mixed
                  cement. Operative data are presented in <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Operative Data</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 561px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Parameter</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">CR-Flex (N
                          = 300 Knees)<span data-id="table_footnote_reference_7"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">LPS-Flex
                          (N = 300 Knees)<span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Operative time <span
                            data-id="emphasis_52" class="annotation emphasis"
                            style="position: unset;">(min)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75
                          (56-82)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76
                          (58-87)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.219</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Tourniquet time <span
                            data-id="emphasis_53" class="annotation emphasis"
                            style="position: unset;">(min)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (31-42)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (32-41)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.637</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Length of incision <span
                            data-id="emphasis_54" class="annotation emphasis"
                            style="position: unset;">(cm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Extension</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.6
                          (13-18.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.8
                          (12-17.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.832</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Flexion</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.7
                          (15-19.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.1
                          (14-21)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.812</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Intraoperative blood loss
                          <span data-id="emphasis_55" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">238
                          (69-480)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">243
                          (96-510)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.519</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Drainage volume <span
                            data-id="emphasis_56" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">351
                          (67-418)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">368
                          (71-510)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.176</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Volume of transfusion
                          <span data-id="emphasis_57" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">320
                          (0-400)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">380
                          (0-400)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.078</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean, with the range in parentheses.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Clinical
                Outcomes and Data Collection</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The Knee Society score<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">31</span></a>, the Western Ontario and McMaster Universities
                  Osteoarthritis Index (WOMAC) score<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">32</span></a>,
                  and the University of California Los Angeles (UCLA) activity score<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">33</span></a> were determined preoperatively and then at 3 months, 1
                  year, and every 2 to 3 years postoperatively. The active range of motion of each knee, determined with
                  the patient in the supine position, was determined with the use of a standard (60-cm) clinical
                  goniometer preoperatively and at each follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Regarding the WOMAC score, we found that it was relatively
                  easy for patients to distinguish the degree of pain in each knee. We also asked about function
                  separately for each knee. Although it was somewhat difficult for the patients to distinguish the
                  degree of impairment of the function of each knee, they were able to do so upon careful questioning. A
                  visual analog scale (VAS) of 0 to 10 was utilized to assess patient satisfaction. The answers were
                  categorized as “fully dissatisfied” (≤2), “somewhat dissatisfied” (3 to 5), “somewhat satisfied” (6 to
                  8), or “fully satisfied” (9 or 10). The patients were also asked which knee they thought was better.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                and CT Evaluation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">The alignment of the limb (tibiofemoral angle), the
                  position of the components, the level of the joint line, and the presence and location of radiolucent
                  lines at the bone-cement or cement-implant interface were examined on the anteroposterior, lateral,
                  and skyline patellar radiographs. All patients underwent a CT scan with a multislice scanner (General
                  Electric Light Plus; GE Medical Systems) at the latest follow-up to determine the rotational alignment
                  of the components and the presence of osteolysis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">A power analysis was used to estimate the sample size.
                  Considering an effect size of 0.5, corresponding to an anticipated difference of 7 points on the Knee
                  Society score, a standard deviation of 6 points, power of 90%, and a level of significance of 5%, 277
                  knees would be needed in each group (p &lt; 0.05)<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">34</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">35</span></a>. An a priori power calculation using a difference in the
                  prevalence of aseptic loosening in the range of 3% and a standard deviation of 5% revealed that 252
                  knees would be needed in each group. An a priori power calculation using a difference in the
                  prevalence of osteolysis in the range of 5% and a standard deviation of 10% revealed that 259 knees
                  would be needed in each group. An a priori power calculation using a survivorship difference in the
                  range of 5% and a standard deviation of 10% revealed that 263 knees would be needed in each group. In
                  anticipation of a small dropout rate, we aimed to recruit 300 patients per group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The change in the Knee Society and WOMAC scores, range of
                  motion of the knee, complication rates, and radiographic and CT findings were compared with use of a
                  paired t test, with p &lt; 0.05 set as the level of significance. Kaplan-Meier curves were used to
                  analyze the rate of TKA prosthesis survival, with revision for any reason or aseptic loosening as the
                  end point<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">36</span></a>;
                  95% confidence intervals (CIs) were determined.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Clinical
                Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a> shows
                  that the pre- and postoperative Knee Society scores, range of motion, WOMAC scores, and UCLA activity
                  scores did not differ significantly between the knees with CR TKA and those with PS TKA. The Knee
                  Society knee score did not differ significantly between the 2 groups preoperatively (25 and 26 points
                  for CR TKA and PS TKA, respectively; p = 0.712, paired t test) or postoperatively (93 and 92 points
                  for CR TKA and PS TKA, respectively; p = 0. 813, paired t test). The pain score at the latest
                  follow-up did not differ significantly between the groups (41 and 43 points in the CR TKA and PS TKA
                  groups, respectively; p = 0.823, chi-square test).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Comparison of Clinical Results at Preoperative and
                          Mean 18-Year Postoperative Evaluations<span data-id="table_footnote_reference_27"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 580.342px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.54023);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Parameter</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">NexGen CR-Flex (N = 300 Knees)</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">NexGen LPS-Flex (N = 300 Knees)</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Difference of Differences (95% CI)</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Preop.<span data-id="table_footnote_reference_9"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Postop.<span data-id="table_footnote_reference_10"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Difference</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Preop.<span data-id="table_footnote_reference_11"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Postop.<span data-id="table_footnote_reference_12"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Difference</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Preop.</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Postop.</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Knee Society knee score
                          <span data-id="emphasis_58" class="annotation emphasis"
                            style="position: unset;">(points)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (8-38)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">93
                          (75-100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (7-40)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">92
                          (75-100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2 (1.1 to
                          2.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.712<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.813<span
                            data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Knee Society pain score
                          <span data-id="emphasis_59" class="annotation emphasis"
                            style="position: unset;">(points)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (5-28)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41
                          (38-50)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (6-31)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43
                          (39-50)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3 (2.1 to
                          4.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.912<span
                            data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.823<span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Knee Society deformity
                          score <span data-id="emphasis_60" class="annotation emphasis"
                            style="position: unset;">(points)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (1-9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6
                          (0-3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (5-14)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5
                          (0-3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9 (0.5
                          to 1.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.125<span
                            data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.143<span
                            data-id="table_footnote_reference_18" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Knee Society function
                          score <span data-id="emphasis_61" class="annotation emphasis"
                            style="position: unset;">(points)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (13-26)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81
                          (65-100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (15-29)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83
                          (72-100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1 (0.8 to
                          2.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.913<span
                            data-id="table_footnote_reference_19" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.981<span
                            data-id="table_footnote_reference_20" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Range of motion (n = 261
                          patients) <span data-id="emphasis_62" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">126
                          (85-140)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">125
                          (90-150)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">127
                          (90-150)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">126
                          (90-150)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.814<span
                            data-id="table_footnote_reference_21" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.852<span
                            data-id="table_footnote_reference_22" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">WOMAC score <span
                            data-id="emphasis_63" class="annotation emphasis"
                            style="position: unset;">(points)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67.9
                          (31-96)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.1
                          (6-43)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">48.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67.9
                          (31-96)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.1
                          (6-43)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">48.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.921<span
                            data-id="table_footnote_reference_23" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.921<span
                            data-id="table_footnote_reference_24" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">UCLA activity score <span
                            data-id="emphasis_64" class="annotation emphasis"
                            style="position: unset;">(points)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.6
                          (1-3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.1
                          (4-10)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.6
                          (1-3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.1
                          (4-10)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.912<span
                            data-id="table_footnote_reference_25" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.937<span
                            data-id="table_footnote_reference_26" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">CI = confidence interval, WOMAC = Western Ontario and McMaster
                        Universities Osteoarthritis Index, and UCLA = University of California Los Angeles.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Values are given as the mean, with the range in parentheses.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Paired t test (CR-Flex versus LPS-Flex).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Chi-square test (CR-Flex versus LPS-Flex).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">We evaluated range of knee motion in 261 patients (<a
                    href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>). No knee had a measurable flexion contracture at the latest
                  follow-up. The mean range of knee motion at the latest follow-up was 125° ± 6.1° (range, 90° to 150°)
                  in the CR TKA group and 126° ± 6.5° (range, 90° to 150°) in the PS TKA group. This difference was not
                  significant (p = 0.852, paired t test).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">The preoperative WOMAC scores (mean, 67.9 points [range,
                  31 to 96 points] for both knees, p = 0.921) decreased significantly (mean score, 19.1 points [range, 6
                  to 43 points] for both knees; p = 0.921) at the latest follow-up, indicating an improvement.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">The mean UCLA activity score was 6.1 points (range, 4 to
                  10 points) for both knees at the latest follow-up, indicating participation in moderate light work
                  activity, occasional housework, swimming, and light bench work.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Patient satisfaction was similar for CR TKA and PS TKA;
                  the mean patient satisfaction score was 7.6 ± 2.4 points for the CR TKA group and 7.9 ± 2.1 points for
                  the PS TKA group. This difference was not significant (p = 0.389). Two hundred and fifty-five patients
                  with CR TKA (85%) were satisfied with their knee range of motion, and 261 patients with PS TKA (87%)
                  were satisfied with their knee range of motion.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                and CT Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a> shows
                  the radiographic and CT results. There were no significant differences between the CR TKA and PS TKA
                  knees with regard to radiographic parameters, including alignment of the knee, the position of the
                  femoral and tibial components in the coronal and sagittal planes, or the pre- and postoperative joint
                  line, the pre- and postoperative condylar offset, or radiolucent lines (p &gt; 0.05 for all, paired t
                  test) (<a href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 2-A</a> and <a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">2-B</a>). The mean
                  postoperative alignment of the knee was 5.6° of valgus for the CR TKA group and 5.4° of valgus for the
                  PS TKA group. Four (1.3%) of the knees in the CR TKA group and 4 (1.3%) of the knees in the PS TKA
                  group had radiolucent lines (&gt;2 mm in width) around the tibial component, and the tibial components
                  were loose. Seven (2.3%) of the knees in the CR TKA group and 8 (2.7%) of the knees in the PS TKA
                  group had radiolucent lines (&gt;2 mm in width) around the femoral component, and the femoral
                  components were loose.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Radiographic and CT Results</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1031px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Parameter</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">CR-Flex (N
                          = 300 Knees)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">LPS-Flex
                          (N = 300 Knees)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_28" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Knee alignment<span
                            data-id="table_footnote_reference_29" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_65" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.5 varus
                          (5-20)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.2 varus
                          (1-20)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.615</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.6 valgus
                          (3-6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.4 valgus
                          (3-7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.152</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Femoral component
                          position: femoral angle<span data-id="table_footnote_reference_30"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="emphasis_66" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Coronal</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95
                          (93-103)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97
                          (94-100)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.265</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Sagittal</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.1
                          (−0.6-9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.6
                          (1-8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.903</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Joint line<span
                            data-id="table_footnote_reference_31" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_67" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.5
                          (10-25)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.7
                          (8-24)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.786</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.9
                          (8-20)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.5
                          (8-20)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.793</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Posterior condylar
                          offset<span data-id="table_footnote_reference_32" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_68" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.2
                          (22-32)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.1
                          (19-32)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.771</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.9
                          (18-30)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.7
                          (21-31)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.152</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiolucent line, &gt;2
                          mm, femoral side <span data-id="emphasis_69" class="annotation emphasis"
                            style="position: unset;">(no. [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7 knees
                          (2.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8 knees
                          (2.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.126</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiolucent line, &gt;2
                          mm, tibial side <span data-id="emphasis_70" class="annotation emphasis"
                            style="position: unset;">(no. [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4 knees
                          (1.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4 knees
                          (1.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.912</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Patellar component
                          angle<span data-id="table_footnote_reference_33" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_71" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.3 ±
                          4.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.8 ±
                          4.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.663</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.6 ±
                          5.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.5 ±
                          3.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.896</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Paired t test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the mean, with the range in parentheses.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the mean and standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_48"
                            class="annotation strong" style="position: unset;">Figs. 2-A and 2-B</span> Radiographs of
                          the knees of a 56-year-old woman with osteoarthritis.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-A</div>
                    <div class="image-download" name="JBJS.24.00007f2a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6cdfcdbd-ca7f-4e61-8193-cbcbfc99e499&type=jpeg&name=JBJS.24.00007f2a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_34" style="position: unset;">
                            <div class="content" style="position: unset;">Anteroposterior radiographs at 18 years
                              postoperatively showing that the NexGen LPS-Flex implant (left side of image) and the
                              NexGen CR-Flex implant (right side of image) are solidly fixed in a satisfactory position.
                              There are no radiolucent lines or other signs of osteolysis around the tibial components.
                            </div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-B</div>
                    <div class="image-download" name="JBJS.24.00007f2b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6cdfcdbd-ca7f-4e61-8193-cbcbfc99e499&type=jpeg&name=JBJS.24.00007f2b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_35" style="position: unset;">
                            <div class="content" style="position: unset;">Lateral radiographs at 18 years
                              postoperatively showing that both the NexGen LPS-Flex implant (left side of image) and the
                              NexGen CR-Flex implant (right side of image) are solidly fixed in a satisfactory position.
                              There are no radiolucent lines or other signs of osteolysis around the femoral, tibial, or
                              patellar component.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">There was no significant difference in CT findings between
                  the CR TKA and PS TKA groups with regard to the external rotation of the femoral or the tibial
                  component. There was no evidence of femoral, tibial, or patellar osteolysis in the CR TKA or PS TKA
                  knees (<a href="" data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 3-A</a> and <a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">3-B</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_50"
                            class="annotation strong" style="position: unset;">Figs. 3-A and 3-B</span> Postoperative
                          computed tomography (CT) scans of a 56-year-old woman with osteoarthritis.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-A</div>
                    <div class="image-download" name="JBJS.24.00007f3a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6cdfcdbd-ca7f-4e61-8193-cbcbfc99e499&type=jpeg&name=JBJS.24.00007f3a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_39" style="position: unset;">
                            <div class="content" style="position: unset;">CT scans of the femoral components at 18 years
                              postoperatively showing no osteolysis in either knee.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-B</div>
                    <div class="image-download" name="JBJS.24.00007f3b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6cdfcdbd-ca7f-4e61-8193-cbcbfc99e499&type=jpeg&name=JBJS.24.00007f3b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_12" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_40" style="position: unset;">
                            <div class="content" style="position: unset;">CT scans of the tibial components at 18 years
                              postoperatively showing no osteolysis in either knee.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Revision</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Eighteen (6.0%) of the knees in the CR TKA group were
                  revised: 3 (1.0%) for infection, 7 (2.3%) for aseptic loosening of the femoral component, 4 (1.3%) for
                  aseptic loosening of the tibial component, and 4 (1.3%) for instability. Eighteen (6.0%) of the knees
                  in the PS TKA group were revised: 3 (1.0%) for infection, 8 (2.7%) for aseptic loosening of the
                  femoral component, 4 (1.3%) for aseptic loosening of the tibial component, and 3 (1.0%) for
                  instability. Rates of revision of the femoral and tibial components did not differ significantly
                  between the 2 groups (p = 0.182).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Survivorship
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">On the basis of a Kaplan-Meier survivorship<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">36</span></a> analysis of the TKA implants, at 19.5 years
                  postoperatively, the rate of survival was 94% (95% CI, 91% to 100%) for the CR-Flex implants and 93.7%
                  (95% CI, 91% to 100%) for the NexGen LPS-Flex implants (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00007f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6cdfcdbd-ca7f-4e61-8193-cbcbfc99e499&type=jpeg&name=JBJS.24.00007f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_14" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_44" style="position: unset;">
                        <div class="content" style="position: unset;">Kaplan-Meier curves show the survival rate for the
                          NexGen CR-Flex and NexGen LPS-Flex implants at 19.5 years, with revision or aseptic loosening
                          as the end point.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, there are no studies with long-term
                  follow-up that have compared CR TKA with PS TKA in the same patient. Therefore, we evaluated the
                  long-term clinical, radiographic, and CT results; the revision rate; and survivorship in patients ≤65
                  years of age at a minimum of 17.5 years after undergoing simultaneous, bilateral CR and PS TKA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">Several studies have reported the results of CR and PS TKA
                  with a follow-up of 10 years<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">37</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">39</span></a>. Mayne et al.<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">37</span></a>
                  found similar functional scores, range of motion, and revision rates for CR and PS TKA. Likewise,
                  Beaupre et al.<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">21</span></a>
                  found no differences in the functional outcome or revision rate between CR and PS TKA. However,
                  another long-term study<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">38</span></a>
                  reported significantly better functional outcomes and range of motion for PS TKA. In contrast, another
                  large retrospective study<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">23</span></a> and
                  data from the American Joint Replacement Registry<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">24</span></a>,
                  the Australian Orthopaedic Association National Joint Replacement Registry (AOANJRR)<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">25</span></a>, the Dutch Arthroplasty Register<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">26</span></a>, the New Zealand Joint Registry<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">27</span></a>, and the British National Joint Registry<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">28</span></a> suggest increased risks of all-cause revision and revision
                  for infection for PS TKA. Kendall et al.<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">4</span></a>
                  postulated that there are several reasons for failure that are unique to PS TKA; they are most often
                  related to the tibial post and cam mechanism. Retrieval analysis has shown wear at this site<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">38</span></a>, and the intercondylar notch bone resection to accommodate
                  the box of PS designs places the femur at increased fracture risk<a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">39</span></a>. In addition, authors have suggested that prolonged
                  operative time<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">40</span></a>, a
                  surgeon’s skill, preoperative deformity, implant-specific issues, and other factors are responsible
                  for the increased risk of revision after PS TKA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">The high heterogeneity seen in the many registries is
                  consistent with what is seen in the literature<a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">28</span></a>. It may be attributed to the multiple surgeons; variable
                  older implant designs, including the use of dished tibial components, constrained condylar implants,
                  metal-backed, and all-polyethylene components; and the presence or absence of patellar resurfacing<a
                    href="" data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">28</span></a>. Each of these factors could be a potential source of
                  confounding. Furthermore, differences in surgical techniques, changes in clinical practice over time
                  (including how patients are selected), and the use of older-generation polyethylene with different
                  sterilization techniques could also be sources of confounding.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">The current prospective, randomized study is unique
                  because we avoided selection bias by enrolling consecutive patients who underwent simultaneous CR and
                  PS TKAs performed by 1 surgeon at 1 academic institution. In addition, we have provided long-term
                  follow-up regarding clinical and radiographic results, revision rate, and implant survival. The
                  surgeon utilized a uniform surgical technique and used contemporary TKA implants. Overall, this
                  approach led to no discernible difference between CR and PS TKAs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">This study had some limitations. First, the lack of
                  interobserver comparisons could produce bias when interpreting radiographic and CT results. However,
                  we previously determined the intraobserver agreement of the radiographic and CT measurements<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">41</span></a><span data-id="superscript_33"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">42</span></a>. Second, the WOMAC score used to assess functional outcome
                  cannot distinguish the function of 1 knee from the other; rather, it assesses patient function as a
                  whole. Third, the UCLA activity score is not really meaningful because respondents are unlikely able
                  to differentiate activity level by knee. Finally, the low patient weight, good preoperative range of
                  motion, predominance of female patients, and younger age of the patients in the current study may
                  limit the generalizability of our findings to other patient groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_50" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_50" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, after a minimum follow-up of 17.5 years in
                  our prospective, randomized study comparing CR and PS TKA, we found no significant differences between
                  the knees that underwent TKA with use of the NexGen CR-Flex or NexGen LPS-Flex prosthesis in terms of
                  functional outcome, range of knee motion, prevalence of osteolysis, revision rate, or implant
                  survival. Continued longer-term follow-up in randomized controlled trials such as this one is
                  recommended to add to the volume of evidence that can guide clinical practice.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Li G</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Zayontz S</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Most E</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Otterberg E</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Sabbag K</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Rubash HE</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Cruciate-retaining and
                  cruciate-substituting total knee arthroplasty: an in vitro comparison of the kinematics under muscle
                  loads</span>. <span data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2001 Dec;16(8)(Suppl 1):150-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cruciate-retaining%20and%20cruciate-substituting%20total%20knee%20arthroplasty%3A%20an%20in%20vitro%20comparison%20of%20the%20kinematics%20under%20muscle%20loads&as_occt=title&as_sauthors=%20%22G%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Scott DFMD</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Smith RR</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">A prospective, randomized
                  comparison of posterior stabilized versus cruciate-substituting total knee arthroplasty: a preliminary
                  report with minimum 2-year results</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2014 Sep;29(9)(Suppl):179-81.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20prospective%2C%20randomized%20comparison%20of%20posterior%20stabilized%20versus%20cruciate-substituting%20total%20knee%20arthroplasty%3A%20a%20preliminary%20report%20with%20minimum%202-year%20results&as_occt=title&as_sauthors=%20%22DFMD%20Scott%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Berend KR</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Lombardi AV Jr</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Adams JB</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Which total knee replacement
                  implant should I pick? Correcting the pathology: the role of knee bearing designs</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2013
                Nov;95-B(11)(Suppl A):129-32.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Which%20total%20knee%20replacement%20implant%20should%20I%20pick%3F%20Correcting%20the%20pathology%3A%20the%20role%20of%20knee%20bearing%20designs&as_occt=title&as_sauthors=%20%22KR%20Berend%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Kendall J</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Pelt CE</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Imlay B</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Yep P</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Mullen K</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Kagan R</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Revision risk for total knee
                  arthroplasty polyethylene designs in patients 65 years of age or older: an analysis from the American
                  Joint Replacement Registry</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2022 Sep 7;104(17):1548-53.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=3343328" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Kim YH</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Choi Y</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Kwon OR</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Kim JS</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Functional outcome and range of
                  motion of high-flexion posterior cruciate-retaining and high-flexion posterior cruciate-substituting
                  total knee prostheses. A prospective, randomized study</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2009
                Apr;91(4):753-60.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1049892"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Sorger JI</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Federle D</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Kirk PG</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Grood E</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Cochran J</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Levy M</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">The posterior cruciate ligament
                  in total knee arthroplasty</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 1997 Dec;12(8):869-79.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20posterior%20cruciate%20ligament%20in%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22JI%20Sorger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Clark CR</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Rorabeck CH</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> MacDonald S</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> MacDonald D</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Swafford J</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Cleland D</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Posterior-stabilized and
                  cruciate-retaining total knee replacement: a randomized study</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2001
                Nov;(392):208-12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Posterior-stabilized%20and%20cruciate-retaining%20total%20knee%20replacement%3A%20a%20randomized%20study&as_occt=title&as_sauthors=%20%22CR%20Clark%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Pereira DS</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Jaffe FF</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Ortiguera C</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Posterior cruciate
                  ligament-sparing versus posterior cruciate ligament-sacrificing arthroplasty. Functional results using
                  the same prosthesis</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 1998 Feb;13(2):138-44.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Posterior%20cruciate%20ligament-sparing%20versus%20posterior%20cruciate%20ligament-sacrificing%20arthroplasty.%20Functional%20results%20using%20the%20same%20prosthesis&as_occt=title&as_sauthors=%20%22DS%20Pereira%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Lewandowski PJ</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Askew MJ</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Lin DF</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Hurst FW</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Melby A</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Kinematics of posterior cruciate
                  ligament-retaining and -sacrificing mobile bearing total knee arthroplasties. An in vitro comparison
                  of the New Jersey LCS meniscal bearing and rotating platform prostheses</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 1997
                Oct;12(7):777-84.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Kinematics%20of%20posterior%20cruciate%20ligament-retaining%20and%20-sacrificing%20mobile%20bearing%20total%20knee%20arthroplasties.%20An%20in%20vitro%20comparison%20of%20the%20New%20Jersey%20LCS%20meniscal%20bearing%20and%20rotating%20platform%20prostheses&as_occt=title&as_sauthors=%20%22PJ%20Lewandowski%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Stiehl JB</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Komistek RD</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Cloutier JM</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Dennis DA</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">The cruciate ligaments in total
                  knee arthroplasty: a kinematic analysis of 2 total knee arthroplasties</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2000
                Aug;15(5):545-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20cruciate%20ligaments%20in%20total%20knee%20arthroplasty%3A%20a%20kinematic%20analysis%20of%202%20total%20knee%20arthroplasties&as_occt=title&as_sauthors=%20%22JB%20Stiehl%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Goldstein WM</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Raab DJ</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Gleason TF</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Branson JJ</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Berland K</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Why posterior
                  cruciate-retaining and substituting total knee replacements have similar ranges of motion. The
                  importance of posterior condylar offset and cleanout of posterior condylar space</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2006 Dec;88(Suppl 4):182-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Why%20posterior%20cruciate-retaining%20and%20substituting%20total%20knee%20replacements%20have%20similar%20ranges%20of%20motion.%20The%20importance%20of%20posterior%20condylar%20offset%20and%20cleanout%20of%20posterior%20condylar%20space&as_occt=title&as_sauthors=%20%22WM%20Goldstein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Swanik CB</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Lephart SM</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Rubash HE</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Proprioception, kinesthesia,
                  and balance after total knee arthroplasty with cruciate-retaining and posterior stabilized
                  prostheses</span>. <span data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2004 Feb;86(2):328-34.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=883583" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Sextro GS</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Berry DJ</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Rand JA</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Total knee arthroplasty using
                  cruciate-retaining kinematic condylar prosthesis</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2001
                Jul;(388):33-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20knee%20arthroplasty%20using%20cruciate-retaining%20kinematic%20condylar%20prosthesis&as_occt=title&as_sauthors=%20%22GS%20Sextro%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Dejour D</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Deschamps G</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Garotta L</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Dejour H</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Laxity in posterior cruciate
                  sparing and posterior stabilized total knee prostheses</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 1999
                Jul;(364):182-93.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Laxity%20in%20posterior%20cruciate%20sparing%20and%20posterior%20stabilized%20total%20knee%20prostheses&as_occt=title&as_sauthors=%20%22D%20Dejour%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Dixon MC</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Brown RR</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Parsch D</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Scott RD</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Modular fixed-bearing total
                  knee arthroplasty with retention of the posterior cruciate ligament. A study of patients followed for
                  a minimum of fifteen years</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2005 Mar;87(3):598-603.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=961581" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Misra AN</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Hussain MR</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Fiddian NJ</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Newton G</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">The role of the posterior
                  cruciate ligament in total knee replacement</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 2003
                Apr;85(3):389-92.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20role%20of%20the%20posterior%20cruciate%20ligament%20in%20total%20knee%20replacement&as_occt=title&as_sauthors=%20%22AN%20Misra%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Conditt MA</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Noble PC</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Bertolusso R</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Woody J</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Parsley BS</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">The PCL significantly affects
                  the functional outcome of total knee arthroplasty</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2004 Oct;19(7)(Suppl
                2):107-12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20PCL%20significantly%20affects%20the%20functional%20outcome%20of%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22MA%20Conditt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Lozano-Calderón SA</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Shen J</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Doumato DF</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Greene DA</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Zelicof SB</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Cruciate-retaining vs
                  posterior-substituting inserts in total knee arthroplasty: functional outcome comparison</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2013
                Feb;28(2):234-242.e1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cruciate-retaining%20vs%20posterior-substituting%20inserts%20in%20total%20knee%20arthroplasty%3A%20functional%20outcome%20comparison&as_occt=title&as_sauthors=%20%22SA%20Lozano-Calder%C3%B3n%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Joglekar S</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Gioe TJ</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Yoon P</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Schwartz MH</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Gait analysis comparison of
                  cruciate retaining and substituting TKA following PCL sacrifice</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Knee.</span> 2012 Aug;19(4):279-85.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Gait%20analysis%20comparison%20of%20cruciate%20retaining%20and%20substituting%20TKA%20following%20PCL%20sacrifice&as_occt=title&as_sauthors=%20%22S%20Joglekar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Argenson JN</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Boisgard S</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Parratte S</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Descamps S</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Bercovy M</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Bonnevialle P</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Briard JL</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Brilhault J</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Chouteau J</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Nizard R</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Saragaglia D</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Servien E</span>; French Society
                of Orthopedic and Traumatologic Surgery (SOFCOT). <span data-id="strong_20" class="annotation strong"
                  style="position: unset;">Survival analysis of total knee arthroplasty at a minimum 10 years’
                  follow-up: a multicenter French nationwide study including 846 cases</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Orthop Traumatol Surg
                  Res.</span> 2013 Jun;99(4):385-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Survival%20analysis%20of%20total%20knee%20arthroplasty%20at%20a%20minimum%2010%20years%E2%80%99%20follow-up%3A%20a%20multicenter%20French%20nationwide%20study%20including%20846%20cases&as_occt=title&as_sauthors=%20%22JN%20Argenson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Beaupre LA</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Sharifi B</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Johnston DWC</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">A randomized clinical trial
                  comparing posterior cruciate-stabilizing vs posterior cruciate-retaining prostheses in primary total
                  knee arthroplasty: 10-year follow-up</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2017 Mar;32(3):818-23.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20randomized%20clinical%20trial%20comparing%20posterior%20cruciate-stabilizing%20vs%20posterior%20cruciate-retaining%20prostheses%20in%20primary%20total%20knee%20arthroplasty%3A%2010-year%20follow-up&as_occt=title&as_sauthors=%20%22LA%20Beaupre%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Sando T</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> McCalden RW</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Bourne RB</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> MacDonald SJ</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Somerville LE</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Ten-year results comparing
                  posterior cruciate-retaining versus posterior cruciate-substituting total knee arthroplasty</span>.
                <span data-id="emphasis_22" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span>
                2015 Feb;30(2):210-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ten-year%20results%20comparing%20posterior%20cruciate-retaining%20versus%20posterior%20cruciate-substituting%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22T%20Sando%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Abdel MP</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Morrey ME</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Jensen MR</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Morrey BF</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Increased long-term survival of
                  posterior cruciate-retaining versus posterior cruciate-stabilizing total knee replacements</span>.
                <span data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2011 Nov 16;93(22):2072-8.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1136993" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;">American
                Academy of Orthopaedic Surgeons (AAOS). <span data-id="strong_24" class="annotation strong"
                  style="position: unset;">Download the AJRR 2022 Annual Report</span>. Accessed 2022 May 16. <a
                  href="https://connect.registryapps.net/2022-ajrr-annual-report" target="_blank" data-id="link_1"
                  class="link"
                  style="position: unset;">https://connect.registryapps.net/2022-ajrr-annual-report</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Download%20the%20AJRR%202022%20Annual%20Report&as_occt=title&as_sauthors=%20%22American%20Academy%20of%20Orthopaedic%20Surgeons%20(AAOS)%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;">Australian
                Orthopaedic Association National Joint Replacement Registry. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">20th annual report</span> 2019. Accessed 2022 May
                16. <a href="https://aoanjrr.sahmri.com/annual-reports-2019" target="_blank" data-id="link_2"
                  class="link" style="position: unset;">https://aoanjrr.sahmri.com/annual-reports-2019</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Spekenbrink-Spooren A</span>,
                <span data-id="annotation_108" class="annotation" style="position: unset;"> Van Steenbergen LN</span>,
                <span data-id="annotation_109" class="annotation" style="position: unset;"> Denissen GAW</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Swierstra BA</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Poolman RW</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Nelissen RGHH</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Higher mid-term revision rates
                  of posterior stabilized compared with cruciate retaining total knee arthroplasties: 133,841 cemented
                  arthroplasties for osteoarthritis in the Netherlands in 2007-2016</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Acta Orthop.</span> 2018
                Dec;89(6):640-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Higher%20mid-term%20revision%20rates%20of%20posterior%20stabilized%20compared%20with%20cruciate%20retaining%20total%20knee%20arthroplasties%3A%20133%2C841%20cemented%20arthroplasties%20for%20osteoarthritis%20in%20the%20Netherlands%20in%202007-2016&as_occt=title&as_sauthors=%20%22A%20Spekenbrink-Spooren%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="strong_26" class="annotation strong" style="position: unset;">The New Zealand Joint
                  Registry</span>. Twenty-four year report January 1999 to December 2022. Accessed 2024 Jul 1. <a
                  href="https://www.nzoa.org.nz/sites/default/files/NZJR%20Twenty%20Four%20Year%20Report__29Aug2023.pdf"
                  target="_blank" data-id="link_3" class="link"
                  style="position: unset;">https://www.nzoa.org.nz/sites/default/files/NZJR%20Twenty%20Four%20Year%20Report__29Aug2023.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;">NJR
                Editorial Board. <span data-id="emphasis_26" class="annotation emphasis" style="position: unset;">The
                  National Joint Registry 18th Annual Report 2021.</span> London: National Joint Registry; 2021.</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Ahlbäck S</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Osteoarthrosis of the knee. A
                  radiographic investigation</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">Acta Radiol Diagn (Stockh).</span> 1968;Suppl 277:7-72.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteoarthrosis%20of%20the%20knee.%20A%20radiographic%20investigation&as_occt=title&as_sauthors=%20%22S%20Ahlb%C3%A4ck%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Arima J</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Whiteside LA</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> McCarthy DS</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> White SE</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Femoral rotational alignment,
                  based on the anteroposterior axis, in total knee arthroplasty in a valgus knee. A technical
                  note</span>. <span data-id="emphasis_28" class="annotation emphasis" style="position: unset;">J Bone
                  Joint Surg Am.</span> 1995 Sep;77(9):1331-4.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=612602" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Insall JN</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Dorr LD</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Scott RD</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Scott WN</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Rationale of the Knee Society
                  clinical rating system</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 1989 Nov;(248):13-4.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Rationale%20of%20the%20Knee%20Society%20clinical%20rating%20system&as_occt=title&as_sauthors=%20%22JN%20Insall%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Bellamy N</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Buchanan WW</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Goldsmith CH</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Campbell J</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Stitt LW</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Validation study of WOMAC: a
                  health status instrument for measuring clinically important patient relevant outcomes to antirheumatic
                  drug therapy in patients with osteoarthritis of the hip or knee</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">J Rheumatol.</span> 1988
                Dec;15(12):1833-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validation%20study%20of%20WOMAC%3A%20a%20health%20status%20instrument%20for%20measuring%20clinically%20important%20patient%20relevant%20outcomes%20to%20antirheumatic%20drug%20therapy%20in%20patients%20with%20osteoarthritis%20of%20the%20hip%20or%20knee&as_occt=title&as_sauthors=%20%22N%20Bellamy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Zahiri CA</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Schmalzried TP</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Szuszczewicz ES</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Amstutz HC</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Assessing activity in joint
                  replacement patients</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 1998 Dec;13(8):890-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Assessing%20activity%20in%20joint%20replacement%20patients&as_occt=title&as_sauthors=%20%22CA%20Zahiri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Rosner B.</span><span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Fundamentals of
                  biostatistics.</span> 5th ed. Pacific Grove, CA: Duxbury; 1999.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Cohen J.</span><span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Statistical power analysis
                  for the behavioral sciences.</span> 2nd ed. Hillsdale, NJ: Lawrence Erlbaum; 1988.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Kaplan EL</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Meier P</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Nonparametric Estimation from
                  Incomplete Observations</span>. <span data-id="emphasis_34" class="annotation emphasis"
                  style="position: unset;">J Am Stat Assoc.</span> 1958;53(282):457-81.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Nonparametric%20Estimation%20from%20Incomplete%20Observations&as_occt=title&as_sauthors=%20%22EL%20Kaplan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Mayne A</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Harshavardhan HP</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Johnston LR</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Wang W</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Jariwala A</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Cruciate Retaining compared
                  with Posterior Stabilised Nexgen total knee arthroplasty: results at 10 years in a matched
                  cohort</span>. <span data-id="emphasis_35" class="annotation emphasis" style="position: unset;">Ann R
                  Coll Surg Engl.</span> 2017 Nov;99(8):602-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cruciate%20Retaining%20compared%20with%20Posterior%20Stabilised%20Nexgen%20total%20knee%20arthroplasty%3A%20results%20at%2010%20years%20in%20a%20matched%20cohort&as_occt=title&as_sauthors=%20%22A%20Mayne%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Puloski SK</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> McCalden RW</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> MacDonald SJ</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Rorabeck CH</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Bourne RB</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Tibial post wear in posterior
                  stabilized total knee arthroplasty. An unrecognized source of polyethylene debris</span>. <span
                  data-id="emphasis_36" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2001 Mar;83(3):390-7.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=779081" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Lombardi AV Jr</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Mallory TH</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Waterman RA</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Eberle RW</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Intercondylar distal femoral
                  fracture. An unreported complication of posterior-stabilized total knee arthroplasty</span>. <span
                  data-id="emphasis_37" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 1995
                Oct;10(5):643-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intercondylar%20distal%20femoral%20fracture.%20An%20unreported%20complication%20of%20posterior-stabilized%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22AV%20Jr%20Lombardi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Migliorini F</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Eschweiler J</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Tingart M</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Rath B</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Posterior-stabilized versus
                  cruciate-retained implants for total knee arthroplasty: a meta-analysis of clinical trials</span>.
                <span data-id="emphasis_38" class="annotation emphasis" style="position: unset;">Eur J Orthop Surg
                  Traumatol.</span> 2019 May;29(4):937-46.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Posterior-stabilized%20versus%20cruciate-retained%20implants%20for%20total%20knee%20arthroplasty%3A%20a%20meta-analysis%20of%20clinical%20trials&as_occt=title&as_sauthors=%20%22F%20Migliorini%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Rand JA</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Trousdale RT</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Ilstrup DM</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Harmsen WS</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Factors affecting the
                  durability of primary total knee prostheses</span>. <span data-id="emphasis_39"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2003
                Feb;85(2):259-65.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=833592"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Bach CM</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Biedermann R</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Goebel G</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Mayer E</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Rachbauer F</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Reproducible assessment of
                  radiolucent lines in total knee arthroplasty</span>. <span data-id="emphasis_40"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2005
                May;(434):183-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reproducible%20assessment%20of%20radiolucent%20lines%20in%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22CM%20Bach%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D6cdfcdbd-ca7f-4e61-8193-cbcbfc99e499%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F21%2F1978%26topics%3Dkn"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 6, 2024;
                      106 (21): 1978</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00007</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 27, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_41"
                            class="annotation emphasis" style="position: unset;">Investigation performed at The Joint
                            Replacement Center of Seoul Metropolitan Government, SeoNam Hospital, Seoul, Republic of
                            Korea, and the Department of Orthopaedic Surgery, Seoul Hospital, Ewha Womans University,
                            Seoul, Republic of Korea</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_44"
                            class="annotation strong" style="position: unset;">A commentary by Ayesha Abdeen, MD,
                            FRCS(C),</span> is linked to the online version of this article.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=f251df32-c1da-4f19-baf4-ec23a6f2b6e5&native=1"
                              class="article-title" style="position: unset;">The CR Versus PS Debate: A Throwback
                              Throw-Down in Total Knee Arthroplasty<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">Commentary on an article by Young-Hoo Kim, MD, et al: “No
                                Discernible Difference in Revision Rate or Survivorship Between Posterior
                                Cruciate-Retaining and Posterior Cruciate-Substituting TKA”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Abdeen,
                              Ayesha</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(21):e45 | November 6, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;6cdfcdbd-ca7f-4e61-8193-cbcbfc99e499&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;kn&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=6cdfcdbd-ca7f-4e61-8193-cbcbfc99e499&type=pdf&name=JBJS.24.00007.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=6cdfcdbd-ca7f-4e61-8193-cbcbfc99e499&type=pdf&name=JBJS.24.00007.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_45"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this study. The <span data-id="strong_46" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I211"
                            target="_blank" data-id="link_4" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I211</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;6cdfcdbd-ca7f-4e61-8193-cbcbfc99e499&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;kn&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=6cdfcdbd-ca7f-4e61-8193-cbcbfc99e499&type=zip&name=JBJS.24.00007.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                        Sharing</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;">A <span data-id="strong_47"
                            class="annotation strong" style="position: unset;">data-sharing statement</span> is provided
                          with the online version of the article (<a href="http://links.lww.com/JBJS/I210"
                            target="_blank" data-id="link_5" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I210</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Sharing
                          PDF</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;6cdfcdbd-ca7f-4e61-8193-cbcbfc99e499&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;kn&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=6cdfcdbd-ca7f-4e61-8193-cbcbfc99e499&type=pdf&name=JBJS.24.00007.dataavailability.pdf&subtype=dataavailability"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Young-Hoo Kim, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:younghookim@ewha.ac.kr" class=""
                    style="position: unset;">younghookim@ewha.ac.kr</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2090-7315" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2090-7315</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jang-Won Park, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6269-6451" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6269-6451</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Young-Soo Jang, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3586-9446" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3586-9446</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Eun-Jung Kim, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8617-2417" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8617-2417</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">The Joint Replacement Center of Seoul Metropolitan Government, SeoNam Hospital,
                Seoul, Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Seoul Hospital, Ewha Womans University,
                Seoul, Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 29662.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
