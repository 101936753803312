/* -----------------Globals--------------- */
import { ScrollView } from "react-native";
/* -----------------UI--------------- */
import { Text } from "react-native-paper";

import { Box } from "@memorang/ui";
import type { FilterChipType } from "../../hooks/useLeaderboard";
import type { LeaderboardItem } from "../../queries/schema";
import FilterChips from "./FilterChips";
import LeaderboardList from "./LeaderboardList";
import LeaderboardUser from "./LeaderboardUser";
/* -----------------Child components--------------- */
import TopRankersList from "./TopRankersList";

type Props = {
	leaderboards: LeaderboardItem[];
	daysToReset?: string;
	userLeaderboardItem?: LeaderboardItem;
	topRankers: LeaderboardItem[];
	selectedChip: FilterChipType;
	avatarType?: "initials" | "identicon";
	handleSelectChip: (type: FilterChipType) => void;
};

const LeaderboardView = (props: Props) => {
	const {
		leaderboards,
		daysToReset,
		topRankers,
		userLeaderboardItem,
		selectedChip,
		handleSelectChip,
		avatarType,
	} = props;

	if (!leaderboards?.length) {
		return null;
	}

	const LeaderboardCaption = ({ days }: { days: string }) => (
		<Text variant="bodySmall">{` Leaderboard resets in ${days} days`}</Text>
	);

	return (
		<Box flexGrow={1} height="100%">
			<ScrollView
				contentContainerStyle={{
					padding: 16,
					gap: 16,
					flexGrow: 1,
					paddingBottom: userLeaderboardItem ? 80 : 16,
				}}
				showsVerticalScrollIndicator={false}
			>
				<FilterChips
					selectedChip={selectedChip}
					handleSelectChip={handleSelectChip}
				/>
				<TopRankersList avatarType={avatarType} topRankers={topRankers} />
				{daysToReset ? <LeaderboardCaption days={daysToReset} /> : null}
				<LeaderboardList avatarType={avatarType} leaderboards={leaderboards} />
			</ScrollView>
			{userLeaderboardItem && (
				<Box position="absolute" bottom={16} left={16} right={16}>
					<LeaderboardUser
						leaderboardItem={userLeaderboardItem}
						avatarType={avatarType}
					/>
				</Box>
			)}
		</Box>
	);
};

export default LeaderboardView;
