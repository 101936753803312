import type { ConfigMap } from './custom-app-config-schema';

export const customConstants: ConfigMap = {
  "simwerx": {
    "tenant": {
      "id": "923fd6c2-6363-4650-b958-6f63db202c54",
      "name": "Simwerx"
    },
    "supportEmail": "support@simwerx.mail.memorang.com",
    "companyName": "Simwerx",
    "app": {
      "id": "dd889731-dc4d-4bba-96ce-f4b3a39a7bf5",
      "name": "Simwerx Beta",
      "fontSizes": {
        "stem": 17,
        "answer": 15
      },
      "analytics": {
        "mixpanel": {
          "id": "fa9c619bbdd5631c565a957a1bc0b1b5"
        }
      },
      "logo": {
        "stacked": {
          "light": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/simwerx/app_logo_stacked_light.png"
          },
          "dark": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/simwerx/app_logo_stacked_dark.png"
          }
        },
        "default": {
          "light": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/simwerx/app_logo_light.png"
          },
          "dark": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/simwerx/app_logo_dark.png"
          }
        }
      },
      "links": {
        "privacy": "https://app.simwerx.com/legal/privacy",
        "tos": "https://app.simwerx.com/legal/terms",
        "redeemSupportUrl": "https://app.simwerx.com/legal/terms"
      },
      "keys": {
        "revenueCat": {
          "ios": "appl_tfbBxQbsoZLVyISjLSZWoccqCzi",
          "android": "goog_xnLVKZpZmlknMbzVJXNOLTdigye"
        },
        "oneSignalAppId": "67d6088f-0bcd-4d04-9984-b5f8efa0dcfc"
      },
      "aiAssistant": {
        "title": "Ai Learning Assistant",
        "alias": "AiLA",
        "icon": "https://memorang-prod-media.s3.amazonaws.com/aila-default-icon.png"
      },
      "isImageChallengeApp": false,
      "showCountrySelection": false,
      "showChallengeCountdown": false,
      "showDisclaimer": false,
      "cmeEnabled": false
    },
    "groupByCategory": null,
    "exam": {
      "enabled": false
    },
    "store": {
      "enabled": false
    },
    "homeView": null,
    "showStudyPackAsHome": null,
    "reportView": null,
    "onboarding": [],
    "navigation": {
      "routes": {
        "main": [
          {
            "title": "Home",
            "name": "home",
            "icon": {
              "default": "study-pack-outline",
              "selected": "study-pack"
            },
            "enabled": true,
            "custom": true
          },
          {
            "title": "Progress",
            "name": "progress",
            "icon": {
              "default": "chart-timeline-variant-shimmer",
              "selected": "chart-line-variant"
            },
            "enabled": true
          },
          {
            "title": "Leaderboard",
            "name": "leaderboard",
            "icon": {
              "default": "trophy-outline",
              "selected": "trophy"
            },
            "enabled": true
          },
          {
            "title": "Profile",
            "name": "profile",
            "icon": {
              "default": "account-outline",
              "selected": "account"
            },
            "enabled": true
          },
          {
            "title": "Store",
            "name": "store",
            "icon": {
              "default": "cart-outline",
              "selected": "cart"
            },
            "enabled": false
          },
          {
            "title": "More",
            "name": "more",
            "icon": {
              "default": "menu",
              "selected": "microsoft-xbox-controller-menu"
            },
            "enabled": true
          },
          {
            "title": "Study Guides",
            "name": "study-guides",
            "icon": {
              "default": "note-multiple",
              "selected": "note-multiple-outline"
            },
            "enabled": false
          }
        ]
      }
    },
    "sessionView": null,
    "theme": {
      "colors": {
        "dark": {
          "error": "rgb(255, 180, 171)",
          "scrim": "rgb(0, 0, 0)",
          "shadow": "rgb(0, 0, 0)",
          "onError": "rgb(105, 0, 5)",
          "outline": "rgb(142, 146, 133)",
          "primary": "rgb(156, 215, 92)",
          "surface": "rgb(27, 28, 24)",
          "backdrop": "rgba(46, 50, 40, 0.4)",
          "tertiary": "rgb(160, 207, 204)",
          "elevation": {
            "level0": "transparent",
            "level1": "rgb(33, 37, 27)",
            "level2": "rgb(37, 43, 29)",
            "level3": "rgb(41, 49, 32)",
            "level4": "rgb(43, 50, 32)",
            "level5": "rgb(45, 54, 34)"
          },
          "onPrimary": "rgb(29, 55, 0)",
          "onSurface": "rgb(227, 227, 219)",
          "secondary": "rgb(191, 203, 173)",
          "background": "rgb(27, 28, 24)",
          "onTertiary": "rgb(0, 55, 53)",
          "onSecondary": "rgb(42, 51, 31)",
          "onBackground": "rgb(227, 227, 219)",
          "errorContainer": "rgb(147, 0, 10)",
          "inversePrimary": "rgb(61, 106, 0)",
          "inverseSurface": "rgb(227, 227, 219)",
          "outlineVariant": "rgb(68, 72, 61)",
          "surfaceVariant": "rgb(68, 72, 61)",
          "surfaceDisabled": "rgba(227, 227, 219, 0.12)",
          "inverseOnSurface": "rgb(48, 49, 44)",
          "onErrorContainer": "rgb(255, 180, 171)",
          "onSurfaceVariant": "rgb(196, 200, 186)",
          "primaryContainer": "rgb(45, 80, 0)",
          "onSurfaceDisabled": "rgba(227, 227, 219, 0.38)",
          "tertiaryContainer": "rgb(31, 78, 76)",
          "onPrimaryContainer": "rgb(183, 244, 117)",
          "secondaryContainer": "rgb(64, 74, 51)",
          "onTertiaryContainer": "rgb(187, 236, 232)",
          "onSecondaryContainer": "rgb(219, 231, 200)"
        },
        "light": {
          "error": "rgb(186, 26, 26)",
          "scrim": "rgb(0, 0, 0)",
          "shadow": "rgb(0, 0, 0)",
          "onError": "rgb(255, 255, 255)",
          "outline": "rgb(117, 121, 108)",
          "primary": "rgb(61, 106, 0)",
          "surface": "rgb(253, 252, 245)",
          "backdrop": "rgba(46, 50, 40, 0.4)",
          "tertiary": "rgb(56, 102, 99)",
          "elevation": {
            "level0": "transparent",
            "level1": "rgb(243, 245, 233)",
            "level2": "rgb(238, 240, 225)",
            "level3": "rgb(232, 236, 218)",
            "level4": "rgb(230, 235, 216)",
            "level5": "rgb(226, 232, 211)"
          },
          "onPrimary": "rgb(255, 255, 255)",
          "onSurface": "rgb(27, 28, 24)",
          "secondary": "rgb(87, 98, 74)",
          "background": "rgb(253, 252, 245)",
          "onTertiary": "rgb(255, 255, 255)",
          "onSecondary": "rgb(255, 255, 255)",
          "onBackground": "rgb(27, 28, 24)",
          "errorContainer": "rgb(255, 218, 214)",
          "inversePrimary": "rgb(156, 215, 92)",
          "inverseSurface": "rgb(48, 49, 44)",
          "outlineVariant": "rgb(196, 200, 186)",
          "surfaceVariant": "rgb(225, 228, 213)",
          "surfaceDisabled": "rgba(27, 28, 24, 0.12)",
          "inverseOnSurface": "rgb(242, 241, 234)",
          "onErrorContainer": "rgb(65, 0, 2)",
          "onSurfaceVariant": "rgb(68, 72, 61)",
          "primaryContainer": "rgb(183, 244, 117)",
          "onSurfaceDisabled": "rgba(27, 28, 24, 0.38)",
          "tertiaryContainer": "rgb(187, 236, 232)",
          "onPrimaryContainer": "rgb(15, 32, 0)",
          "secondaryContainer": "rgb(219, 231, 200)",
          "onTertiaryContainer": "rgb(0, 32, 31)",
          "onSecondaryContainer": "rgb(21, 30, 11)"
        }
      }
    }
  },
  "ada": {
    "tenant": {
      "id": "dcfc373c-0870-4a88-88c3-4a706de51463",
      "name": "ADA"
    },
    "supportEmail": "support@ada.mail.memorang.com",
    "companyName": "ADA",
    "app": {
      "id": "2c183c41-6dc0-4ef4-837c-0eb0f86a61b1",
      "name": "ADA Beta",
      "analytics": {
        "mixpanel": {
          "id": "fa9c619bbdd5631c565a957a1bc0b1b5"
        }
      },
      "logo": {
        "stacked": {
          "light": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/ada/app_logo_stacked_light.png"
          },
          "dark": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/ada/app_logo_stacked_dark.png"
          }
        },
        "default": {
          "light": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/ada/app_logo_light.png"
          },
          "dark": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/ada/app_logo_dark.png"
          }
        }
      },
      "links": {
        "privacy": "https://app.nejm.com/legal/privacy",
        "tos": "https://app.nejm.com/legal/terms",
        "redeemSupportUrl": "https://app.nejm.com/legal/terms"
      },
      "keys": {
        "revenueCat": {
          "ios": "appl_tfbBxQbsoZLVyISjLSZWoccqCzi",
          "android": "goog_xnLVKZpZmlknMbzVJXNOLTdigye"
        },
        "oneSignalAppId": "67d6088f-0bcd-4d04-9984-b5f8efa0dcfc"
      },
      "aiAssistant": {
        "title": "Ai Learning Assistant",
        "alias": "AiLA",
        "icon": "https://memorang-prod-media.s3.amazonaws.com/aila-default-icon.png"
      },
      "isImageChallengeApp": false,
      "showCountrySelection": false,
      "showChallengeCountdown": false,
      "showDisclaimer": false,
      "cmeEnabled": false
    },
    "groupByCategory": null,
    "exam": {
      "enabled": true
    },
    "store": {
      "enabled": false
    },
    "homeView": null,
    "showStudyPackAsHome": null,
    "reportView": null,
    "onboarding": null,
    "navigation": {
      "routes": {
        "main": [
          {
            "title": "Home",
            "name": "home",
            "icon": {
              "default": "home-outline",
              "selected": "home"
            },
            "enabled": true
          },
          {
            "title": "Progress",
            "name": "progress",
            "icon": {
              "default": "chart-timeline-variant-shimmer",
              "selected": "chart-line-variant"
            },
            "enabled": true
          },
          {
            "title": "Leaderboard",
            "name": "leaderboard",
            "icon": {
              "default": "trophy-outline",
              "selected": "trophy"
            },
            "enabled": true
          },
          {
            "title": "Profile",
            "name": "profile",
            "icon": {
              "default": "account-outline",
              "selected": "account"
            },
            "enabled": true
          }
        ]
      }
    },
    "sessionView": null,
    "theme": {
      "colors": {
        "dark": {
          "error": "rgb(255, 180, 171)",
          "scrim": "rgb(0, 0, 0)",
          "shadow": "rgb(0, 0, 0)",
          "onError": "rgb(105, 0, 5)",
          "outline": "rgb(140, 147, 136)",
          "primary": "rgb(119, 221, 109)",
          "surface": "rgb(26, 28, 25)",
          "backdrop": "rgba(44, 50, 42, 0.4)",
          "tertiary": "rgb(160, 207, 211)",
          "elevation": {
            "level0": "transparent",
            "level1": "rgb(31, 38, 29)",
            "level2": "rgb(33, 43, 32)",
            "level3": "rgb(36, 49, 34)",
            "level4": "rgb(37, 51, 35)",
            "level5": "rgb(39, 55, 37)"
          },
          "onPrimary": "rgb(0, 58, 5)",
          "onSurface": "rgb(226, 227, 221)",
          "secondary": "rgb(106, 211, 255)",
          "background": "rgb(26, 28, 25)",
          "onTertiary": "rgb(0, 54, 58)",
          "onSecondary": "rgb(0, 53, 70)",
          "onBackground": "rgb(226, 227, 221)",
          "errorContainer": "rgb(147, 0, 10)",
          "inversePrimary": "rgb(0, 110, 18)",
          "inverseSurface": "rgb(226, 227, 221)",
          "outlineVariant": "rgb(66, 73, 63)",
          "surfaceVariant": "rgb(66, 73, 63)",
          "surfaceDisabled": "rgba(226, 227, 221, 0.12)",
          "inverseOnSurface": "rgb(47, 49, 45)",
          "onErrorContainer": "rgb(255, 180, 171)",
          "onSurfaceVariant": "rgb(194, 200, 188)",
          "primaryContainer": "rgb(0, 83, 11)",
          "onSurfaceDisabled": "rgba(226, 227, 221, 0.38)",
          "tertiaryContainer": "rgb(30, 77, 81)",
          "onPrimaryContainer": "rgb(146, 250, 134)",
          "secondaryContainer": "rgb(0, 77, 101)",
          "onTertiaryContainer": "rgb(188, 235, 239)",
          "onSecondaryContainer": "rgb(190, 233, 255)"
        },
        "light": {
          "error": "rgb(186, 26, 26)",
          "scrim": "rgb(0, 0, 0)",
          "shadow": "rgb(0, 0, 0)",
          "onError": "rgb(255, 255, 255)",
          "outline": "rgb(115, 121, 111)",
          "primary": "rgb(0, 110, 18)",
          "surface": "rgb(252, 253, 246)",
          "backdrop": "rgba(44, 50, 42, 0.4)",
          "tertiary": "rgb(56, 101, 105)",
          "elevation": {
            "level0": "transparent",
            "level1": "rgb(239, 246, 235)",
            "level2": "rgb(232, 242, 228)",
            "level3": "rgb(224, 237, 221)",
            "level4": "rgb(222, 236, 219)",
            "level5": "rgb(217, 233, 214)"
          },
          "onPrimary": "rgb(255, 255, 255)",
          "onSurface": "rgb(26, 28, 25)",
          "secondary": "rgb(0, 102, 133)",
          "background": "rgb(252, 253, 246)",
          "onTertiary": "rgb(255, 255, 255)",
          "onSecondary": "rgb(255, 255, 255)",
          "onBackground": "rgb(26, 28, 25)",
          "errorContainer": "rgb(255, 218, 214)",
          "inversePrimary": "rgb(119, 221, 109)",
          "inverseSurface": "rgb(47, 49, 45)",
          "outlineVariant": "rgb(194, 200, 188)",
          "surfaceVariant": "rgb(222, 228, 216)",
          "surfaceDisabled": "rgba(26, 28, 25, 0.12)",
          "inverseOnSurface": "rgb(241, 241, 235)",
          "onErrorContainer": "rgb(65, 0, 2)",
          "onSurfaceVariant": "rgb(66, 73, 63)",
          "primaryContainer": "rgb(146, 250, 134)",
          "onSurfaceDisabled": "rgba(26, 28, 25, 0.38)",
          "tertiaryContainer": "rgb(188, 235, 239)",
          "onPrimaryContainer": "rgb(0, 34, 2)",
          "secondaryContainer": "rgb(190, 233, 255)",
          "onTertiaryContainer": "rgb(0, 32, 34)",
          "onSecondaryContainer": "rgb(0, 31, 42)"
        }
      }
    }
  },
  "zoetis": {
    "tenant": {
      "id": "dcfc373c-0870-4a88-88c3-4a706de51463",
      "name": "Zoetis"
    },
    "supportEmail": "support@zoetis.mail.memorang.com",
    "companyName": "Zoetis",
    "app": {
      "id": "2c183c41-6dc0-4ef4-837c-0eb0f86a61b1",
      "name": "Zoetis Beta",
      "analytics": {
        "mixpanel": {
          "id": "fa9c619bbdd5631c565a957a1bc0b1b5"
        }
      },
      "logo": {
        "stacked": {
          "light": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/zoetis/app_logo_stacked_light.png"
          },
          "dark": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/zoetis/app_logo_stacked_dark.png"
          }
        },
        "default": {
          "light": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/zoetis/app_logo_light.png"
          },
          "dark": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/zoetis/app_logo_dark.png"
          }
        }
      },
      "links": {
        "privacy": "https://app.nejm.com/legal/privacy",
        "tos": "https://app.nejm.com/legal/terms",
        "redeemSupportUrl": "https://app.nejm.com/legal/terms"
      },
      "keys": {
        "revenueCat": {
          "ios": "appl_tfbBxQbsoZLVyISjLSZWoccqCzi",
          "android": "goog_xnLVKZpZmlknMbzVJXNOLTdigye"
        },
        "oneSignalAppId": "67d6088f-0bcd-4d04-9984-b5f8efa0dcfc"
      },
      "aiAssistant": {
        "title": "Ai Learning Assistant",
        "alias": "AiLA",
        "icon": "https://memorang-prod-media.s3.amazonaws.com/aila-default-icon.png"
      },
      "isImageChallengeApp": false,
      "showCountrySelection": false,
      "showChallengeCountdown": false,
      "showDisclaimer": false,
      "cmeEnabled": false
    },
    "groupByCategory": null,
    "exam": {
      "enabled": true
    },
    "store": {
      "enabled": false
    },
    "homeView": null,
    "showStudyPackAsHome": null,
    "reportView": null,
    "onboarding": null,
    "navigation": {
      "routes": {
        "main": [
          {
            "title": "Home",
            "name": "home",
            "icon": {
              "default": "home-outline",
              "selected": "home"
            },
            "enabled": true
          },
          {
            "title": "Progress",
            "name": "progress",
            "icon": {
              "default": "chart-timeline-variant-shimmer",
              "selected": "chart-line-variant"
            },
            "enabled": true
          },
          {
            "title": "Leaderboard",
            "name": "leaderboard",
            "icon": {
              "default": "trophy-outline",
              "selected": "trophy"
            },
            "enabled": true
          },
          {
            "title": "Profile",
            "name": "profile",
            "icon": {
              "default": "account-outline",
              "selected": "account"
            },
            "enabled": true
          }
        ]
      }
    },
    "sessionView": null,
    "theme": {
      "colors": {
        "dark": {
          "error": "rgb(255, 180, 171)",
          "scrim": "rgb(0, 0, 0)",
          "shadow": "rgb(0, 0, 0)",
          "onError": "rgb(105, 0, 5)",
          "outline": "rgb(160, 141, 134)",
          "primary": "rgb(255, 181, 152)",
          "surface": "rgb(32, 26, 24)",
          "backdrop": "rgba(59, 45, 40, 0.4)",
          "tertiary": "rgb(212, 199, 142)",
          "elevation": {
            "level0": "transparent",
            "level1": "rgb(43, 34, 30)",
            "level2": "rgb(50, 38, 34)",
            "level3": "rgb(57, 43, 38)",
            "level4": "rgb(59, 45, 39)",
            "level5": "rgb(63, 48, 42)"
          },
          "onPrimary": "rgb(89, 28, 0)",
          "onSurface": "rgb(237, 224, 220)",
          "secondary": "rgb(79, 216, 235)",
          "background": "rgb(32, 26, 24)",
          "onTertiary": "rgb(56, 48, 6)",
          "onSecondary": "rgb(0, 54, 61)",
          "onBackground": "rgb(237, 224, 220)",
          "errorContainer": "rgb(147, 0, 10)",
          "inversePrimary": "rgb(166, 59, 0)",
          "inverseSurface": "rgb(237, 224, 220)",
          "outlineVariant": "rgb(83, 67, 62)",
          "surfaceVariant": "rgb(83, 67, 62)",
          "surfaceDisabled": "rgba(237, 224, 220, 0.12)",
          "inverseOnSurface": "rgb(54, 47, 44)",
          "onErrorContainer": "rgb(255, 180, 171)",
          "onSurfaceVariant": "rgb(216, 194, 186)",
          "primaryContainer": "rgb(126, 44, 0)",
          "onSurfaceDisabled": "rgba(237, 224, 220, 0.38)",
          "tertiaryContainer": "rgb(79, 71, 27)",
          "onPrimaryContainer": "rgb(255, 219, 206)",
          "secondaryContainer": "rgb(0, 79, 88)",
          "onTertiaryContainer": "rgb(240, 227, 168)",
          "onSecondaryContainer": "rgb(151, 240, 255)"
        },
        "light": {
          "error": "rgb(186, 26, 26)",
          "scrim": "rgb(0, 0, 0)",
          "shadow": "rgb(0, 0, 0)",
          "onError": "rgb(255, 255, 255)",
          "outline": "rgb(133, 115, 109)",
          "primary": "rgb(166, 59, 0)",
          "surface": "rgb(255, 251, 255)",
          "backdrop": "rgba(59, 45, 40, 0.4)",
          "tertiary": "rgb(104, 95, 48)",
          "elevation": {
            "level0": "transparent",
            "level1": "rgb(251, 241, 242)",
            "level2": "rgb(248, 236, 235)",
            "level3": "rgb(245, 230, 227)",
            "level4": "rgb(244, 228, 224)",
            "level5": "rgb(243, 224, 219)"
          },
          "onPrimary": "rgb(255, 255, 255)",
          "onSurface": "rgb(32, 26, 24)",
          "secondary": "rgb(0, 104, 116)",
          "background": "rgb(255, 251, 255)",
          "onTertiary": "rgb(255, 255, 255)",
          "onSecondary": "rgb(255, 255, 255)",
          "onBackground": "rgb(32, 26, 24)",
          "errorContainer": "rgb(255, 218, 214)",
          "inversePrimary": "rgb(255, 181, 152)",
          "inverseSurface": "rgb(54, 47, 44)",
          "outlineVariant": "rgb(216, 194, 186)",
          "surfaceVariant": "rgb(245, 222, 214)",
          "surfaceDisabled": "rgba(32, 26, 24, 0.12)",
          "inverseOnSurface": "rgb(251, 238, 234)",
          "onErrorContainer": "rgb(65, 0, 2)",
          "onSurfaceVariant": "rgb(83, 67, 62)",
          "primaryContainer": "rgb(255, 219, 206)",
          "onSurfaceDisabled": "rgba(32, 26, 24, 0.38)",
          "tertiaryContainer": "rgb(240, 227, 168)",
          "onPrimaryContainer": "rgb(55, 14, 0)",
          "secondaryContainer": "rgb(151, 240, 255)",
          "onTertiaryContainer": "rgb(33, 27, 0)",
          "onSecondaryContainer": "rgb(0, 31, 36)"
        }
      }
    }
  },
  "thiemeneetpg": {
    "tenant": {
      "id": "933f7f49-7018-4a6a-b40c-ca5af345e792",
      "name": "Thieme"
    },
    "supportEmail": "support@thieme.mail.memorang.com",
    "companyName": "Thieme",
    "app": {
      "id": "1d116d8e-33d4-4fcd-a940-67b54ff82378",
      "name": "Thieme PG NEET",
      "analytics": {
        "mixpanel": {
          "id": "292790c46be7f83c4f6a10c2f106bffb"
        }
      },
      "logo": {
        "stacked": {
          "light": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/thiemeneetpg/app_logo_stacked_light.png"
          },
          "dark": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/thiemeneetpg/app_logo_stacked_dark.png"
          }
        },
        "default": {
          "light": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/thiemeneetpg/app_logo_light.png"
          },
          "dark": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/thiemeneetpg/app_logo_dark.png"
          }
        },
        "company": {
          "light": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/thiemeneetpg/company_logo_light.png"
          },
          "dark": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/thiemeneetpg/company_logo_dark.png"
          }
        }
      },
      "links": {
        "baseUrl": "",
        "privacy": "https://www.thieme.de/de/thieme-gruppe/privacy-policy-statement-Thieme-Publishers-Delhi-EN.htm",
        "tos": "https://www.thieme.in/terms-n-conditions"
      },
      "keys": {
        "revenueCat": {
          "ios": "",
          "android": ""
        },
        "oneSignalAppId": "140e7377-0f9a-4dbc-8504-31f484f14565"
      },
      "aiAssistant": {
        "title": "Ai Learning Assistant",
        "alias": "AiLA",
        "icon": "https://memorang-prod-media.s3.amazonaws.com/aila-default-icon.png"
      },
      "isImageChallengeApp": false,
      "showCountrySelection": false,
      "showChallengeCountdown": false,
      "showDisclaimer": false,
      "cmeEnabled": false
    },
    "groupByCategory": true,
    "exam": {
      "enabled": true
    },
    "store": {
      "enabled": false
    },
    "homeView": null,
    "showStudyPackAsHome": true,
    "reportView": null,
    "onboarding": null,
    "navigation": {
      "routes": {
        "main": [
          {
            "title": "Home",
            "name": "home",
            "icon": {
              "default": "home-outline",
              "selected": "home"
            },
            "enabled": true
          },
          {
            "title": "Study Guides",
            "name": "study-guides",
            "icon": {
              "default": "note-multiple-outline",
              "selected": "note-multiple"
            },
            "enabled": true
          },
          {
            "title": "Progress",
            "name": "progress",
            "icon": {
              "default": "chart-timeline-variant-shimmer",
              "selected": "chart-line-variant"
            },
            "enabled": true
          },
          {
            "title": "Leaders",
            "name": "leaderboard",
            "icon": {
              "default": "trophy-outline",
              "selected": "trophy"
            },
            "enabled": true
          },
          {
            "title": "Profile",
            "name": "profile",
            "icon": {
              "default": "account-outline",
              "selected": "account"
            },
            "enabled": true
          },
          {
            "title": "Store",
            "name": "store",
            "icon": {
              "default": "cart-outline",
              "selected": "cart"
            },
            "enabled": false
          }
        ]
      }
    },
    "sessionView": null,
    "theme": {
      "colors": {
        "dark": {
          "text": {
            "primary": "rgba(255,255,255,0.87)",
            "disabled": "rgba(255,255,255,0.38)",
            "secondary": "rgba(255,255,255,0.60)"
          },
          "error": "rgb(255, 180, 171)",
          "scrim": "rgb(0, 0, 0)",
          "shadow": "rgb(0, 0, 0)",
          "onError": "rgb(105, 0, 5)",
          "outline": "rgb(143, 144, 154)",
          "primary": "rgb(178, 197, 255)",
          "surface": "rgb(27, 27, 31)",
          "backdrop": "rgba(46, 48, 56, 0.4)",
          "tertiary": "rgb(225, 187, 221)",
          "elevation": {
            "level0": "transparent",
            "level1": "rgb(35, 36, 42)",
            "level2": "rgb(39, 41, 49)",
            "level3": "rgb(44, 46, 56)",
            "level4": "rgb(45, 47, 58)",
            "level5": "rgb(48, 51, 62)"
          },
          "onPrimary": "rgb(0, 43, 115)",
          "onSurface": "rgb(228, 226, 230)",
          "secondary": "rgb(113, 210, 255)",
          "background": "rgb(27, 27, 31)",
          "onTertiary": "rgb(65, 39, 65)",
          "onSecondary": "rgb(0, 53, 71)",
          "onBackground": "rgb(228, 226, 230)",
          "errorContainer": "rgb(147, 0, 10)",
          "inversePrimary": "rgb(58, 91, 169)",
          "inverseSurface": "rgb(228, 226, 230)",
          "outlineVariant": "rgb(69, 70, 79)",
          "surfaceVariant": "rgb(69, 70, 79)",
          "surfaceDisabled": "rgba(228, 226, 230, 0.12)",
          "inverseOnSurface": "rgb(48, 48, 52)",
          "onErrorContainer": "rgb(255, 180, 171)",
          "onSurfaceVariant": "rgb(197, 198, 208)",
          "primaryContainer": "rgb(30, 67, 143)",
          "onSurfaceDisabled": "rgba(228, 226, 230, 0.38)",
          "tertiaryContainer": "rgb(90, 61, 89)",
          "onPrimaryContainer": "rgb(218, 226, 255)",
          "secondaryContainer": "rgb(0, 77, 102)",
          "onTertiaryContainer": "rgb(254, 215, 249)",
          "onSecondaryContainer": "rgb(192, 232, 255)"
        },
        "light": {
          "text": {
            "primary": "rgba(0,0,0,0.87)",
            "disabled": "rgba(0,0,0,0.38)",
            "secondary": "rgba(0,0,0,0.60)"
          },
          "error": "rgb(186, 26, 26)",
          "scrim": "rgb(0, 0, 0)",
          "shadow": "rgb(0, 0, 0)",
          "onError": "rgb(255, 255, 255)",
          "outline": "rgb(117, 119, 128)",
          "primary": "rgb(58, 91, 169)",
          "surface": "rgb(254, 251, 255)",
          "backdrop": "rgba(46, 48, 56, 0.4)",
          "tertiary": "rgb(115, 84, 113)",
          "elevation": {
            "level0": "transparent",
            "level1": "rgb(244, 243, 251)",
            "level2": "rgb(238, 238, 248)",
            "level3": "rgb(232, 233, 246)",
            "level4": "rgb(231, 232, 245)",
            "level5": "rgb(227, 229, 243)"
          },
          "onPrimary": "rgb(255, 255, 255)",
          "onSurface": "rgb(27, 27, 31)",
          "secondary": "rgb(0, 102, 134)",
          "background": "rgb(254, 251, 255)",
          "onTertiary": "rgb(255, 255, 255)",
          "onSecondary": "rgb(255, 255, 255)",
          "onBackground": "rgb(27, 27, 31)",
          "errorContainer": "rgb(255, 218, 214)",
          "inversePrimary": "rgb(178, 197, 255)",
          "inverseSurface": "rgb(48, 48, 52)",
          "outlineVariant": "rgb(197, 198, 208)",
          "surfaceVariant": "rgb(225, 226, 236)",
          "surfaceDisabled": "rgba(27, 27, 31, 0.12)",
          "inverseOnSurface": "rgb(242, 240, 244)",
          "onErrorContainer": "rgb(65, 0, 2)",
          "onSurfaceVariant": "rgb(69, 70, 79)",
          "primaryContainer": "rgb(218, 226, 255)",
          "onSurfaceDisabled": "rgba(27, 27, 31, 0.38)",
          "tertiaryContainer": "rgb(254, 215, 249)",
          "onPrimaryContainer": "rgb(0, 24, 71)",
          "secondaryContainer": "rgb(192, 232, 255)",
          "onTertiaryContainer": "rgb(43, 18, 43)",
          "onSecondaryContainer": "rgb(0, 30, 43)"
        }
      }
    }
  },
  "psi": {
    "tenant": {
      "id": "dcfc373c-0870-4a88-88c3-4a706de51463",
      "name": "PSI"
    },
    "supportEmail": "ptsupport@psionline.com",
    "companyName": "PSI",
    "app": {
      "id": "2c183c41-6dc0-4ef4-837c-0eb0f86a61b1",
      "name": "PSI Test Prep",
      "scoreRanges": [
        {
          "rangeArray": [
            0,
            74
          ],
          "range": "0-74",
          "color": "#d66565",
          "icon": "emoticon-sad-outline"
        },
        {
          "rangeArray": [
            75,
            84
          ],
          "range": "75-84",
          "color": "#dda53a",
          "icon": "emoticon-neutral-outline"
        },
        {
          "rangeArray": [
            85,
            100
          ],
          "range": "85-100",
          "color": "#66bb68",
          "icon": "emoticon-happy-outline"
        }
      ],
      "fontSizes": {
        "stem": 16,
        "answer": 16
      },
      "analytics": {
        "mixpanel": {
          "id": "e2354d451161a9deacf641952f49ab00"
        }
      },
      "logo": {
        "stacked": {
          "light": {
            "uri": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/psi/icons/app_logo_stacked_light.png?t=2024-09-11T02%3A52%3A07.652Z"
          },
          "dark": {
            "uri": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/psi/icons/app_logo_stacked_dark.png?t=2024-09-11T02%3A52%3A21.053Z"
          }
        },
        "default": {
          "light": {
            "uri": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/psi/icons/app_logo_light.png?t=2024-09-11T02%3A52%3A32.632Z"
          },
          "dark": {
            "uri": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/psi/icons/app_logo_dark.png?t=2024-09-11T02%3A52%3A42.516Z"
          }
        },
        "company": {
          "light": {
            "uri": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/psi/icons/company_logo_light.png?t=2024-09-11T02%3A53%3A01.928Z"
          },
          "dark": {
            "uri": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/psi/icons/company_logo_dark.png?t=2024-09-11T02%3A52%3A51.342Z"
          }
        }
      },
      "links": {
        "baseUrl": "https://app.psiexams.com",
        "privacy": "https://app.psiexams.com/legal/privacy",
        "tos": "https://app.psiexams.com/legal/terms",
        "ios": "https://apps.apple.com/in/app/psi-test-prep/id1565909858",
        "android": "https://play.google.com/store/apps/details?id=com.memorang.psi.insurance.testprep&hl=en&gl=US",
        "redeemSupportUrl": "https://app.psiexams.com/legal/privacy",
        "companyUrl": "https://www.psiexams.com/en-gb/"
      },
      "keys": {
        "revenueCat": {
          "ios": "appl_tfbBxQbsoZLVyISjLSZWoccqCzi",
          "android": "goog_xnLVKZpZmlknMbzVJXNOLTdigye"
        },
        "oneSignalAppId": "67d6088f-0bcd-4d04-9984-b5f8efa0dcfc"
      },
      "font": "beausite",
      "aiAssistant": {
        "title": "Ai Learning Assistant",
        "alias": "AiLA",
        "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/psi/assistant/assistant_dark.png?t=2024-09-20T04%3A10%3A34.686Z"
      },
      "isImageChallengeApp": false,
      "showCountrySelection": false,
      "showChallengeCountdown": false,
      "showDisclaimer": false,
      "cmeEnabled": false
    },
    "groupByCategory": null,
    "exam": {
      "enabled": true,
      "quizEnabled": true,
      "allowChangeExam": true
    },
    "store": {
      "enabled": true
    },
    "homeView": null,
    "showStudyPackAsHome": null,
    "reportView": {
      "practiceTest": {
        "reviewEnabled": true
      }
    },
    "onboarding": [
      {
        "step": "chooseContext",
        "enabled": true,
        "title": "Choose your Exam"
      }
    ],
    "navigation": {
      "routes": {
        "main": [
          {
            "title": "Home",
            "name": "home",
            "icon": {
              "default": "home-outline",
              "selected": "home"
            },
            "enabled": true,
            "custom": false
          },
          {
            "title": "Study Guides",
            "name": "study-guides",
            "icon": {
              "default": "note-multiple",
              "selected": "note-multiple-outline"
            },
            "enabled": false
          },
          {
            "title": "Progress",
            "name": "progress",
            "icon": {
              "default": "chart-timeline-variant-shimmer",
              "selected": "chart-line-variant"
            },
            "enabled": true
          },
          {
            "title": "Leaders",
            "name": "leaderboard",
            "icon": {
              "default": "trophy-outline",
              "selected": "trophy"
            },
            "enabled": true
          },
          {
            "title": "Store",
            "name": "store",
            "icon": {
              "default": "cart-outline",
              "selected": "cart"
            },
            "enabled": true
          },
          {
            "title": "Articles",
            "name": "articles",
            "icon": {
              "default": "file-document-multiple-outline",
              "selected": "file-document-multiple"
            },
            "enabled": false
          },
          {
            "title": "Challenges",
            "name": "challenges",
            "icon": {
              "default": "arm-flex-outline",
              "selected": "arm-flex"
            },
            "enabled": false
          },
          {
            "title": "More",
            "name": "more",
            "icon": {
              "default": "menu",
              "selected": "microsoft-xbox-controller-menu"
            },
            "enabled": true
          }
        ]
      }
    },
    "sessionView": null,
    "theme": {
      "colors": {
        "dark": {
          "error": "rgb(255, 180, 171)",
          "scrim": "rgb(0, 0, 0)",
          "shadow": "rgb(0, 0, 0)",
          "neutral": "rgba(116, 120, 119, 1)",
          "onError": "rgb(105, 0, 5)",
          "outline": "rgb(141, 145, 153)",
          "primary": "rgb(93, 212, 252)",
          "surface": "#1A212B",
          "backdrop": "rgba(44, 49, 55, 0.4)",
          "tertiary": "rgb(191, 207, 96)",
          "elevation": {
            "level0": "transparent",
            "level1": "#1C242E",
            "level2": "#1A212B",
            "level3": "#1E2631",
            "level4": "#202934",
            "level5": "#222B37"
          },
          "onPrimary": "rgb(0, 0, 0)",
          "onSurface": "#FFFFFF",
          "secondary": "#CEBAAB",
          "background": "rgb(25, 28, 29)",
          "onTertiary": "rgb(45, 52, 0)",
          "onSecondary": "#103D4B",
          "onBackground": "rgb(225, 227, 228)",
          "errorContainer": "rgb(147, 0, 10)",
          "inversePrimary": "rgb(20, 96, 165)",
          "inverseSurface": "rgb(227, 226, 230)",
          "outlineVariant": "rgb(67, 71, 78)",
          "surfaceVariant": "rgb(67, 71, 78)",
          "surfaceDisabled": "rgba(227, 226, 230, 0.12)",
          "inverseOnSurface": "rgb(47, 48, 51)",
          "onErrorContainer": "rgb(255, 180, 171)",
          "onSurfaceVariant": "rgb(195, 198, 207)",
          "primaryContainer": "rgb(0, 72, 129)",
          "onSurfaceDisabled": "rgba(227, 226, 230, 0.38)",
          "tertiaryContainer": "rgb(66, 75, 0)",
          "onPrimaryContainer": "rgb(211, 228, 255)",
          "secondaryContainer": "#103D4B",
          "onTertiaryContainer": "rgb(219, 236, 121)",
          "onSecondaryContainer": "#F2E9D8"
        },
        "light": {
          "error": "rgb(186, 26, 26)",
          "scrim": "rgb(0, 0, 0)",
          "shadow": "rgb(0, 0, 0)",
          "onError": "rgb(255, 255, 255)",
          "outline": "rgb(112, 120, 124)",
          "primary": "#103D4B",
          "surface": "rgb(251, 252, 254)",
          "backdrop": "rgba(42, 50, 53, 0.4)",
          "tertiary": "rgb(88, 100, 0)",
          "elevation": {
            "level0": "transparent",
            "level1": "rgb(238, 245, 248)",
            "level2": "rgb(231, 240, 244)",
            "level3": "rgb(223, 236, 240)",
            "level4": "rgb(221, 234, 239)",
            "level5": "rgb(216, 231, 236)"
          },
          "onPrimary": "#F2E9D8",
          "onSurface": "rgb(25, 28, 29)",
          "secondary": "#BC5221",
          "background": "rgb(251, 252, 254)",
          "onTertiary": "rgb(255, 255, 255)",
          "onSecondary": "#fff",
          "onBackground": "rgb(25, 28, 29)",
          "errorContainer": "rgb(255, 218, 214)",
          "inversePrimary": "rgb(93, 212, 252)",
          "inverseSurface": "rgb(46, 49, 50)",
          "outlineVariant": "rgb(191, 200, 204)",
          "surfaceVariant": "rgb(220, 228, 232)",
          "surfaceDisabled": "rgba(25, 28, 29, 0.12)",
          "inverseOnSurface": "rgb(239, 241, 242)",
          "onErrorContainer": "rgb(65, 0, 2)",
          "onSurfaceVariant": "rgb(64, 72, 76)",
          "primaryContainer": "rgb(184, 234, 255)",
          "onSurfaceDisabled": "rgba(25, 28, 29, 0.38)",
          "tertiaryContainer": "rgb(219, 236, 121)",
          "onPrimaryContainer": "rgb(0, 31, 40)",
          "secondaryContainer": "#F2E9D8",
          "onTertiaryContainer": "rgb(25, 30, 0)",
          "onSecondaryContainer": "#1A212B"
        }
      }
    }
  },
  "jbjs": {
    "tenant": {
      "id": "31da93b9-653e-44e9-94ff-b67a7ffd7012",
      "name": "JBJS"
    },
    "supportEmail": "support@mail.jbjs.org",
    "companyName": "JBJS",
    "app": {
      "id": "864cbe1b-be3e-43a5-a079-d7220aaf61a0",
      "name": "JBJS Mobility",
      "scoreRanges": [
        {
          "rangeArray": [
            0,
            74
          ],
          "range": "0-74",
          "color": "#d66565",
          "icon": "emoticon-sad-outline"
        },
        {
          "rangeArray": [
            75,
            84
          ],
          "range": "75-84",
          "color": "#dda53a",
          "icon": "emoticon-neutral-outline"
        },
        {
          "rangeArray": [
            85,
            100
          ],
          "range": "85-100",
          "color": "#66bb68",
          "icon": "emoticon-happy-outline"
        }
      ],
      "analytics": {
        "mixpanel": {
          "id": "ffff090966b3236a89669efc6f77c88a"
        }
      },
      "logo": {
        "stacked": {
          "light": {
            "uri": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/logos/app_logo-stacked_light.png?t=2024-10-07T03%3A44%3A11.974Z"
          },
          "dark": {
            "uri": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/logos/app-logo-stacked-dark.png?t=2024-11-18T04%3A17%3A36.802Z"
          }
        },
        "default": {
          "light": {
            "uri": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/logos/app_logo-stacked_dark.png?t=2024-10-07T03%3A43%3A59.701Z"
          },
          "dark": {
            "uri": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/logos/app-logo-stacked-dark.png?t=2024-11-18T04%3A17%3A36.802Z"
          }
        }
      },
      "links": {
        "baseUrl": "https://app.jbjs.org",
        "privacy": "https://customersupport.jbjs.org/en-US/privacy-policy-136463",
        "tos": "https://customersupport.jbjs.org/en-US/jbjs-terms-of-use-136462",
        "redeemSupportUrl": "https://app.jbjs.org/legal/terms"
      },
      "keys": {
        "revenueCat": {
          "ios": "appl_tfbBxQbsoZLVyISjLSZWoccqCzi",
          "android": "goog_xnLVKZpZmlknMbzVJXNOLTdigye"
        },
        "oneSignalAppId": "67d6088f-0bcd-4d04-9984-b5f8efa0dcfc",
        "clerk": {
          "publishableKeyProd": "pk_live_Y2xlcmsuYXBwLmpianMub3JnJA",
          "publishableKeyDev": "pk_test_c2ltcGxlLW1vbmFyY2gtMTIuY2xlcmsuYWNjb3VudHMuZGV2JA"
        }
      },
      "supportUrl": "https://myorg.com/terms",
      "aiAssistant": {
        "title": "JBJS AI",
        "alias": "JBJS AI",
        "icon": "https://memorang-prod-icon.s3.amazonaws.com/app-resources/jbjs/ios/icon.png",
        "fabIcon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/aila-fab-icon.svg"
      },
      "isImageChallengeApp": true,
      "landing": {
        "title": "First assist",
        "subtitle": "Your mobile companion for orthopedics"
      },
      "showCountrySelection": false,
      "showChallengeCountdown": false,
      "showDisclaimer": false,
      "cmeEnabled": false,
      "avatarType": "initials"
    },
    "groupByCategory": null,
    "exam": {
      "enabled": false
    },
    "store": {
      "enabled": false
    },
    "homeView": {
      "showRecommendedChallenge": true,
      "showArticles": true,
      "showChallengeOfTheWeek": true
    },
    "showStudyPackAsHome": null,
    "reportView": null,
    "onboarding": [
      {
        "step": "role",
        "enabled": true,
        "title": "What best describes your role?",
        "suffix": "role",
        "itemsData": {
          "items": [
            {
              "label": "Orthopaedic Professionals",
              "value": "orthopaedic_professionals",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/roles_objects/Orthopaedic%20Professionals.png?t=2024-11-18T11%3A45%3A47.821Z",
              "items": [
                {
                  "label": "Orthopaedic Surgeon",
                  "value": "orthopaedic_surgeon",
                  "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/roles_objects/Orthopaedic%20Surgeon.png?t=2024-11-18T11%3A45%3A58.317Z"
                },
                {
                  "label": "Physician Associate",
                  "value": "physician_associate",
                  "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/roles_objects/Physician%20Associate.png?t=2024-11-18T11%3A46%3A09.573Z"
                },
                {
                  "label": "Physical Therapist",
                  "value": "physical_therapist",
                  "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/roles_objects/Physical%20Therapist.png?t=2024-11-18T11%3A46%3A18.998Z"
                },
                {
                  "label": "Nurse",
                  "value": "nurse",
                  "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/roles_objects/Nurse.png?t=2024-11-18T11%3A46%3A27.377Z"
                }
              ]
            },
            {
              "label": "Medical Trainees",
              "value": "medical_trainees",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/roles_objects/Medical%20Trainees.png?t=2024-11-18T11%3A46%3A44.534Z",
              "items": [
                {
                  "label": "Medical Student",
                  "value": "medical_student",
                  "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/roles_objects/Medical%20Student.png?t=2024-11-18T11%3A46%3A55.704Z"
                },
                {
                  "label": "Resident",
                  "value": "resident",
                  "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/roles_objects/Resident.png?t=2024-11-18T11%3A47%3A06.289Z"
                },
                {
                  "label": "Fellow",
                  "value": "fellow",
                  "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/roles_objects/Fellow.png?t=2024-11-18T11%3A47%3A18.320Z"
                }
              ]
            },
            {
              "label": "Academic & Research",
              "value": "academic_research",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/roles_objects/Academic%20&%20Research.png?t=2024-11-18T11%3A47%3A30.777Z",
              "items": [
                {
                  "label": "Academic",
                  "value": "academic",
                  "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/roles_objects/Academic.png?t=2024-11-18T11%3A47%3A40.785Z"
                },
                {
                  "label": "Education",
                  "value": "education",
                  "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/roles_objects/Education.png?t=2024-11-18T11%3A47%3A48.799Z"
                }
              ]
            },
            {
              "label": "Non-Healthcare",
              "value": "non_healthcare",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/roles_objects/Non-Healthcare.png?t=2024-11-18T11%3A48%3A07.519Z",
              "items": [
                {
                  "label": "Non-Healthcare Professional",
                  "value": "non_healthcare_professional",
                  "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/roles_objects/Non-Healthcare%20Professional.png"
                },
                {
                  "label": "Other",
                  "value": "other",
                  "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/roles_objects/Other.png?t=2024-11-18T11%3A48%3A28.002Z"
                }
              ]
            },
            {
              "label": "Retired Professionals",
              "value": "retired_professionals",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/roles_objects/Retired%20Professionals.png?t=2024-11-18T11%3A48%3A37.458Z",
              "items": [
                {
                  "label": "Retired",
                  "value": "retired",
                  "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/roles_objects/Retired.png?t=2024-11-18T11%3A48%3A48.236Z"
                }
              ]
            }
          ]
        }
      },
      {
        "step": "specialty",
        "enabled": true,
        "title": "What are your interests?",
        "numMaxSelect": 3,
        "itemsData": {
          "items": [
            {
              "label": "Knee",
              "value": "knee",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Knee%20Background%20Removed.png?t=2024-11-13T09%3A42%3A48.593Z",
              "items": []
            },
            {
              "label": "Trauma",
              "value": "trauma",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Trauma%20Background%20Removed.png?t=2024-11-13T09%3A44%3A17.888Z",
              "items": []
            },
            {
              "label": "Hip",
              "value": "hip",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Hip%20Background%20Removed.png?t=2024-11-13T09%3A48%3A14.727Z",
              "items": []
            },
            {
              "label": "Spine",
              "value": "spine",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Spine%20Background%20Removed.png?t=2024-11-13T09%3A44%3A39.718Z",
              "items": []
            },
            {
              "label": "Pediatrics",
              "value": "pediatrics",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Pediatrics%20Background%20Removed.png?t=2024-11-13T09%3A44%3A51.117Z",
              "items": []
            },
            {
              "label": "Basic Science",
              "value": "basic_science",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Basic_Science%20Background%20Removed.png?t=2024-11-13T09%3A45%3A02.159Z",
              "items": []
            },
            {
              "label": "Sports Medicine",
              "value": "sports_medicine",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Sports_Medicine%20Background%20Removed.png?t=2024-11-13T09%3A45%3A12.860Z",
              "items": []
            },
            {
              "label": "Shoulder & Elbow",
              "value": "shoulder_elbow",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Shoulder_And_Elbow%20Background%20Removed.png?t=2024-11-13T09%3A45%3A23.059Z",
              "items": []
            },
            {
              "label": "Infection",
              "value": "infection",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Infection%20Background%20Removed.png?t=2024-11-13T09%3A45%3A37.245Z",
              "items": []
            },
            {
              "label": "Hand & Wrist",
              "value": "hand_wrist",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Hand_And_Wrist%20Background%20Removed.png?t=2024-11-13T09%3A45%3A54.324Z",
              "items": []
            },
            {
              "label": "Foot & Ankle",
              "value": "foot_ankle",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Foot_And_Ankle%20Background%20Removed.png?t=2024-11-13T09%3A46%3A05.887Z",
              "items": []
            },
            {
              "label": "Oncology",
              "value": "oncology",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Oncology%20Background%20Removed.png?t=2024-11-13T09%3A46%3A15.144Z",
              "items": []
            },
            {
              "label": "Rehabilitation",
              "value": "rehabilitation",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Rehabilitation%20Background%20Removed.png?t=2024-11-13T09%3A46%3A27.348Z",
              "items": []
            },
            {
              "label": "Education & Training",
              "value": "education_training",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Education_And_Training%20Background%20Removed%20Background%20Removed.png?t=2024-11-13T09%3A46%3A40.726Z",
              "items": []
            },
            {
              "label": "Ethics",
              "value": "ethics",
              "icon": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Ethics%20Background%20Removed.png?t=2024-11-13T09%3A46%3A56.050Z",
              "items": []
            }
          ]
        }
      },
      {
        "step": "avatar",
        "enabled": true,
        "title": "Choose your avatar"
      },
      {
        "step": "name",
        "enabled": true,
        "title": "What do you prefer to be called?"
      }
    ],
    "navigation": {
      "routes": {
        "main": [
          {
            "title": "Home",
            "name": "home",
            "icon": {
              "default": "home-outline",
              "selected": "home"
            },
            "enabled": true
          },
          {
            "title": "Challenges",
            "name": "challenges",
            "icon": {
              "default": "arm-flex-outline",
              "selected": "arm-flex"
            },
            "enabled": false
          },
          {
            "title": "Library",
            "name": "articles",
            "icon": {
              "default": "book-open-page-variant-outline",
              "selected": "book-open-page-variant"
            },
            "enabled": true
          },
          {
            "title": "Challenges",
            "name": "library",
            "icon": {
              "default": "arm-flex-outline",
              "selected": "arm-flex"
            },
            "enabled": true
          },
          {
            "title": "Store",
            "name": "store",
            "icon": {
              "default": "cart-outline",
              "selected": "cart"
            },
            "enabled": false
          },
          {
            "title": "Study Guides",
            "name": "study-guides",
            "icon": {
              "default": "note-multiple-outline",
              "selected": "note-multiple"
            },
            "enabled": false
          },
          {
            "title": "CME",
            "name": "cme",
            "icon": {
              "default": "school-outline",
              "selected": "school"
            },
            "enabled": false
          },
          {
            "title": "Videos",
            "name": "videos",
            "icon": {
              "default": "video-outline",
              "selected": "video"
            },
            "enabled": true
          },
          {
            "title": "Insights",
            "name": "progress",
            "icon": {
              "default": "lightbulb-on-outline",
              "selected": "lightbulb-on"
            },
            "enabled": true
          },
          {
            "title": "Leaders",
            "name": "leaderboard",
            "icon": {
              "default": "podium-gold",
              "selected": "podium"
            },
            "enabled": false
          },
          {
            "title": "Profile",
            "name": "profile",
            "icon": {
              "default": "account-outline",
              "selected": "account"
            },
            "enabled": false
          },
          {
            "title": "More",
            "name": "more",
            "icon": {
              "default": "menu",
              "selected": "microsoft-xbox-controller-menu"
            },
            "enabled": false
          }
        ]
      }
    },
    "sessionView": {
      "postAnswerStats": {
        "showXpEarned": true,
        "showNumAttempts": true
      },
      "showCrossOut": false
    },
    "theme": {
      "colors": {
        "dark": {
          "error": "rgb(255, 180, 171)",
          "scrim": "rgb(0, 0, 0)",
          "shadow": "rgb(0, 0, 0)",
          "onError": "rgb(105, 0, 5)",
          "outline": "rgb(160, 140, 137)",
          "primary": "rgb(255, 180, 167)",
          "surface": "rgb(32, 26, 25)",
          "backdrop": "rgba(59, 45, 43, 0.4)",
          "tertiary": "rgb(221, 196, 140)",
          "elevation": {
            "level0": "transparent",
            "level1": "rgb(43, 34, 32)",
            "level2": "rgb(50, 38, 36)",
            "level3": "rgb(57, 43, 41)",
            "level4": "rgb(59, 45, 42)",
            "level5": "rgb(63, 48, 45)"
          },
          "onPrimary": "rgb(101, 11, 3)",
          "onSurface": "rgb(237, 224, 221)",
          "secondary": "rgb(174, 198, 255)",
          "background": "rgb(32, 26, 25)",
          "onTertiary": "rgb(61, 46, 4)",
          "onSecondary": "rgb(0, 46, 107)",
          "onBackground": "rgb(237, 224, 221)",
          "errorContainer": "rgb(147, 0, 10)",
          "inversePrimary": "rgb(165, 59, 43)",
          "inverseSurface": "rgb(237, 224, 221)",
          "outlineVariant": "rgb(83, 67, 64)",
          "surfaceVariant": "rgb(83, 67, 64)",
          "surfaceDisabled": "rgba(237, 224, 221, 0.12)",
          "inverseOnSurface": "rgb(54, 47, 45)",
          "onErrorContainer": "rgb(255, 180, 171)",
          "onSurfaceVariant": "rgb(216, 194, 190)",
          "primaryContainer": "rgb(133, 35, 22)",
          "onSurfaceDisabled": "rgba(237, 224, 221, 0.38)",
          "tertiaryContainer": "rgb(86, 69, 25)",
          "onPrimaryContainer": "rgb(255, 218, 212)",
          "secondaryContainer": "rgb(19, 68, 143)",
          "onTertiaryContainer": "rgb(251, 224, 166)",
          "onSecondaryContainer": "rgb(216, 226, 255)"
        },
        "light": {
          "error": "rgb(186, 26, 26)",
          "scrim": "rgb(0, 0, 0)",
          "shadow": "rgb(0, 0, 0)",
          "onError": "rgb(255, 255, 255)",
          "outline": "rgb(133, 115, 112)",
          "primary": "rgb(165, 59, 43)",
          "surface": "rgb(255, 251, 255)",
          "backdrop": "rgba(59, 45, 43, 0.4)",
          "tertiary": "rgb(111, 92, 46)",
          "elevation": {
            "level0": "transparent",
            "level1": "rgb(251, 241, 244)",
            "level2": "rgb(248, 236, 238)",
            "level3": "rgb(245, 230, 232)",
            "level4": "rgb(244, 228, 230)",
            "level5": "rgb(242, 224, 225)"
          },
          "onPrimary": "rgb(255, 255, 255)",
          "onSurface": "rgb(32, 26, 25)",
          "secondary": "rgb(51, 92, 168)",
          "background": "rgb(255, 251, 255)",
          "onTertiary": "rgb(255, 255, 255)",
          "onSecondary": "rgb(255, 255, 255)",
          "onBackground": "rgb(32, 26, 25)",
          "errorContainer": "rgb(255, 218, 214)",
          "inversePrimary": "rgb(255, 180, 167)",
          "inverseSurface": "rgb(54, 47, 45)",
          "outlineVariant": "rgb(216, 194, 190)",
          "surfaceVariant": "rgb(245, 221, 217)",
          "surfaceDisabled": "rgba(32, 26, 25, 0.12)",
          "inverseOnSurface": "rgb(251, 238, 235)",
          "onErrorContainer": "rgb(65, 0, 2)",
          "onSurfaceVariant": "rgb(83, 67, 64)",
          "primaryContainer": "rgb(255, 218, 212)",
          "onSurfaceDisabled": "rgba(32, 26, 25, 0.38)",
          "tertiaryContainer": "rgb(251, 224, 166)",
          "onPrimaryContainer": "rgb(64, 2, 0)",
          "secondaryContainer": "rgb(216, 226, 255)",
          "onTertiaryContainer": "rgb(37, 26, 0)",
          "onSecondaryContainer": "rgb(0, 26, 66)"
        }
      }
    }
  },
  "therapyed": {
    "tenant": {
      "id": "53d94851-4af6-4dfc-943d-92929b3e9dcc",
      "name": "TherapyEd"
    },
    "supportEmail": "info@therapyed.com",
    "companyName": "TherapyEd",
    "app": {
      "id": "db878dfd-9617-456b-b458-14902e06462f",
      "name": "TherapyEd",
      "scoreRanges": [
        {
          "rangeArray": [
            0,
            65
          ],
          "range": "0-65",
          "color": "#d66565",
          "icon": "emoticon-sad-outline"
        },
        {
          "rangeArray": [
            66,
            75
          ],
          "range": "66-75",
          "color": "#dda53a",
          "icon": "emoticon-neutral-outline"
        },
        {
          "rangeArray": [
            76,
            100
          ],
          "range": "76-100",
          "color": "#66bb68",
          "icon": "emoticon-happy-outline"
        }
      ],
      "fontSizes": {
        "stem": 18,
        "answer": 18
      },
      "analytics": {
        "mixpanel": {
          "id": "5b3fe7e0378f1a1bd87bc085ddc9b66e"
        }
      },
      "logo": {
        "stacked": {
          "light": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/therapyed/app_logo_stacked_light.png"
          },
          "dark": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/therapyed/app_logo_stacked_dark.png"
          }
        },
        "default": {
          "light": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/therapyed/app_logo_light.png"
          },
          "dark": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/therapyed/app_logo_dark.png"
          }
        },
        "company": {
          "light": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/therapyed/company_logo_light.png"
          },
          "dark": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/therapyed/company_logo_dark.png"
          }
        }
      },
      "links": {
        "baseUrl": "https://app.therapyed.com",
        "privacy": "https://app.therapyed.com/legal/privacy",
        "tos": "https://app.therapyed.com/legal/terms",
        "ios": "https://apps.apple.com/in/app/therapyed/id1590041414",
        "android": "https://play.google.com/store/apps/details?id=com.memorang.therapyed&hl=en&gl=US",
        "redeemSupportUrl": "https://therapyed.zendesk.com/hc/en-us/articles/6864418376468-How-can-I-redeem-access-to-my-purchased-content-",
        "companyUrl": "https://www.therapyed.com/"
      },
      "keys": {
        "revenueCat": {
          "ios": "appl_LCnNlSXwMCLCGLQsftcakAoejTs",
          "android": "goog_cFBRaPbCakxtxjBbmsUDprlTzfN"
        },
        "oneSignalAppId": "ac799c9b-47da-4692-b722-ce25feffc4ca"
      },
      "supportUrl": "https://therapyed.zendesk.com/hc/en-us",
      "aiAssistant": {
        "title": "Ai Learning Assistant",
        "alias": "AiLA",
        "icon": "https://memorang-prod-media.s3.amazonaws.com/aila-default-icon.png"
      },
      "isImageChallengeApp": false,
      "showCountrySelection": false,
      "showChallengeCountdown": false,
      "showDisclaimer": false,
      "cmeEnabled": false
    },
    "groupByCategory": null,
    "exam": {
      "enabled": true,
      "quizEnabled": false,
      "allowChangeExam": false
    },
    "store": {
      "enabled": true
    },
    "homeView": null,
    "showStudyPackAsHome": null,
    "reportView": {
      "practiceTest": {
        "reviewEnabled": true
      }
    },
    "onboarding": [
      {
        "step": "chooseContext",
        "enabled": true,
        "title": "Choose your Exam"
      },
      {
        "step": "examDate",
        "enabled": false,
        "title": "Choose your Exam Date"
      }
    ],
    "navigation": {
      "routes": {
        "main": [
          {
            "title": "Home",
            "name": "home",
            "icon": {
              "default": "home-outline",
              "selected": "home"
            },
            "enabled": true
          },
          {
            "title": "Progress",
            "name": "progress",
            "icon": {
              "default": "chart-timeline-variant-shimmer",
              "selected": "chart-line-variant"
            },
            "enabled": true
          },
          {
            "title": "Leaders",
            "name": "leaderboard",
            "icon": {
              "default": "trophy-outline",
              "selected": "trophy"
            },
            "enabled": true
          },
          {
            "title": "Store",
            "name": "store",
            "icon": {
              "default": "cart-outline",
              "selected": "cart"
            },
            "enabled": true
          },
          {
            "title": "More",
            "name": "more",
            "icon": {
              "default": "menu",
              "selected": "microsoft-xbox-controller-menu"
            },
            "enabled": true
          },
          {
            "title": "Articles",
            "name": "articles",
            "icon": {
              "default": "file-document-multiple-outline",
              "selected": "file-document-multiple"
            },
            "enabled": false
          },
          {
            "title": "Challenges",
            "name": "challenges",
            "icon": {
              "default": "arm-flex-outline",
              "selected": "arm-flex"
            },
            "enabled": false
          },
          {
            "title": "Study Guides",
            "name": "study-guides",
            "icon": {
              "default": "note-multiple",
              "selected": "note-multiple-outline"
            },
            "enabled": false
          }
        ]
      }
    },
    "sessionView": {
      "cta": "Study Now",
      "maxItems": {
        "flashcards": 100,
        "questions": 50
      },
      "timer": false,
      "showCrossOut": true,
      "selectionStrategy": {
        "default": "random",
        "optimized": true,
        "random": true,
        "shuffled": true
      }
    },
    "theme": {
      "colors": {
        "dark": {
          "error": "rgb(255, 180, 171)",
          "scrim": "rgb(0, 0, 0)",
          "shadow": "rgb(0, 0, 0)",
          "neutral": "rgba(116, 120, 119, 1)",
          "onError": "rgb(105, 0, 5)",
          "outline": "rgb(140, 145, 153)",
          "primary": "#F78F1E",
          "surface": "rgb(26, 28, 30)",
          "backdrop": "rgba(44, 49, 55, 0.4)",
          "tertiary": "rgb(196, 203, 151)",
          "elevation": {
            "level0": "transparent",
            "level1": "rgb(33, 37, 41)",
            "level2": "rgb(37, 42, 48)",
            "level3": "rgb(40, 47, 55)",
            "level4": "rgb(42, 49, 57)",
            "level5": "rgb(44, 52, 62)"
          },
          "onPrimary": "rgb(76, 39, 0)",
          "onSurface": "rgb(226, 226, 230)",
          "secondary": "rgb(157, 202, 255)",
          "background": "rgb(26, 28, 30)",
          "onTertiary": "rgb(45, 51, 13)",
          "onSecondary": "rgb(0, 50, 87)",
          "onBackground": "rgb(226, 226, 230)",
          "errorContainer": "rgb(147, 0, 10)",
          "inversePrimary": "rgb(0, 98, 161)",
          "inverseSurface": "rgb(226, 226, 230)",
          "outlineVariant": "rgb(66, 71, 78)",
          "surfaceVariant": "rgb(66, 71, 78)",
          "surfaceDisabled": "rgba(226, 226, 230, 0.12)",
          "inverseOnSurface": "rgb(47, 48, 51)",
          "onErrorContainer": "rgb(255, 180, 171)",
          "onSurfaceVariant": "rgb(194, 199, 207)",
          "primaryContainer": "rgb(109, 58, 0)",
          "onSurfaceDisabled": "rgba(226, 226, 230, 0.38)",
          "tertiaryContainer": "rgb(68, 74, 34)",
          "onPrimaryContainer": "rgb(255, 220, 194)",
          "secondaryContainer": "rgb(0, 73, 123)",
          "onTertiaryContainer": "rgb(224, 231, 177)",
          "onSecondaryContainer": "rgb(208, 228, 255)"
        },
        "light": {
          "error": "rgb(186, 26, 26)",
          "scrim": "rgb(0, 0, 0)",
          "shadow": "rgb(0, 0, 0)",
          "neutral": "rgba(116, 120, 119, 1)",
          "onError": "rgb(255, 255, 255)",
          "outline": "rgb(133, 115, 111)",
          "primary": "#ab351a",
          "surface": "rgb(255, 255, 255)",
          "backdrop": "rgba(59, 45, 42, 0.4)",
          "tertiary": "rgb(106, 87, 120)",
          "elevation": {
            "level0": "transparent",
            "level1": "rgb(255, 255, 255)",
            "level2": "rgb(255, 255, 255)",
            "level3": "rgb(255, 255, 255)",
            "level4": "rgb(255, 255, 255)",
            "level5": "rgb(255, 255, 255)"
          },
          "onPrimary": "rgb(255, 255, 255)",
          "onSurface": "rgb(32, 26, 25)",
          "secondary": "rgb(0, 98, 162)",
          "background": "#f2f2f2",
          "onTertiary": "rgb(255, 255, 255)",
          "onSecondary": "rgb(255, 255, 255)",
          "onBackground": "rgb(32, 26, 25)",
          "errorContainer": "rgb(255, 218, 214)",
          "inversePrimary": "rgb(255, 180, 163)",
          "inverseSurface": "rgb(54, 47, 45)",
          "outlineVariant": "rgb(216, 194, 189)",
          "surfaceVariant": "rgb(245, 221, 216)",
          "surfaceDisabled": "rgba(32, 26, 25, 0.12)",
          "inverseOnSurface": "rgb(251, 238, 235)",
          "onErrorContainer": "rgb(65, 0, 2)",
          "onSurfaceVariant": "rgb(83, 67, 64)",
          "primaryContainer": "rgb(255, 218, 210)",
          "onSurfaceDisabled": "rgba(32, 26, 25, 0.38)",
          "tertiaryContainer": "rgb(242, 218, 255)",
          "onPrimaryContainer": "rgb(61, 6, 0)",
          "secondaryContainer": "rgb(209, 228, 255)",
          "onTertiaryContainer": "rgb(37, 20, 50)",
          "onSecondaryContainer": "rgb(0, 29, 53)"
        }
      }
    }
  },
  "hiset": {
    "tenant": {
      "id": "ab10130c-2a45-4f14-8c14-aae29b744640",
      "name": "HiSet"
    },
    "supportEmail": "ptsupport@psionline.com",
    "companyName": "PSI",
    "app": {
      "id": "2c183c41-6dc0-4ef4-837c-0eb0f86a61b2",
      "name": "HiSet",
      "scoreRanges": [
        {
          "rangeArray": [
            0,
            74
          ],
          "range": "0-74",
          "color": "#d66565",
          "icon": "emoticon-sad-outline"
        },
        {
          "rangeArray": [
            75,
            84
          ],
          "range": "75-84",
          "color": "#dda53a",
          "icon": "emoticon-neutral-outline"
        },
        {
          "rangeArray": [
            85,
            100
          ],
          "range": "85-100",
          "color": "#66bb68",
          "icon": "emoticon-happy-outline"
        }
      ],
      "fontSizes": {
        "stem": 16,
        "answer": 16
      },
      "analytics": {
        "mixpanel": {
          "id": "fa9c619bbdd5631c565a957a1bc0b1b5"
        }
      },
      "logo": {
        "stacked": {
          "light": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/hiset/app_logo_stacked_light.png"
          },
          "dark": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/hiset/app_logo_stacked_dark.png"
          }
        },
        "default": {
          "light": {
            "uri": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/psi/icons/hiset/app_logo_light.png?t=2024-09-16T04%3A56%3A45.812Z"
          },
          "dark": {
            "uri": "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/psi/icons/hiset/app_logo_dark.png?t=2024-09-16T04%3A56%3A33.067Z"
          }
        },
        "company": {
          "light": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/psi/company_logo_light.png"
          },
          "dark": {
            "uri": "https://memorang-prod-media.s3.amazonaws.com/app-logos/psi/company_logo_dark.png"
          }
        }
      },
      "links": {
        "baseUrl": "https://app.psiexams.com",
        "privacy": "https://app.psiexams.com/legal/privacy",
        "tos": "https://app.psiexams.com/legal/terms",
        "ios": "https://apps.apple.com/in/app/psi-test-prep/id1565909858",
        "android": "https://play.google.com/store/apps/details?id=com.memorang.psi.insurance.testprep&hl=en&gl=US",
        "redeemSupportUrl": "https://app.psiexams.com/legal/privacy",
        "companyUrl": "https://www.psiexams.com/en-gb/"
      },
      "keys": {
        "revenueCat": {
          "ios": "",
          "android": ""
        },
        "oneSignalAppId": ""
      },
      "aiAssistant": {
        "title": "Ai Learning Assistant",
        "alias": "AiLA",
        "icon": "https://memorang-prod-media.s3.amazonaws.com/aila-default-icon.png"
      },
      "isImageChallengeApp": false,
      "showCountrySelection": false,
      "showChallengeCountdown": false,
      "showDisclaimer": false,
      "cmeEnabled": false
    },
    "groupByCategory": true,
    "exam": {
      "enabled": true,
      "quizEnabled": true,
      "allowChangeExam": true
    },
    "store": {
      "enabled": false
    },
    "homeView": null,
    "showStudyPackAsHome": null,
    "reportView": null,
    "onboarding": [
      {
        "step": "chooseContext",
        "enabled": true,
        "title": "Choose your Exam"
      }
    ],
    "navigation": {
      "routes": {
        "main": [
          {
            "title": "Home",
            "name": "home",
            "icon": {
              "default": "home-outline",
              "selected": "home"
            },
            "enabled": true,
            "custom": false
          },
          {
            "title": "Study Guides",
            "name": "study-guides",
            "icon": {
              "default": "note-multiple",
              "selected": "note-multiple-outline"
            },
            "enabled": false
          },
          {
            "title": "Progress",
            "name": "progress",
            "icon": {
              "default": "chart-timeline-variant-shimmer",
              "selected": "chart-line-variant"
            },
            "enabled": true
          },
          {
            "title": "Leaders",
            "name": "leaderboard",
            "icon": {
              "default": "trophy-outline",
              "selected": "trophy"
            },
            "enabled": true
          },
          {
            "title": "Store",
            "name": "store",
            "icon": {
              "default": "cart-outline",
              "selected": "cart"
            },
            "enabled": false
          },
          {
            "title": "Articles",
            "name": "articles",
            "icon": {
              "default": "file-document-multiple-outline",
              "selected": "file-document-multiple"
            },
            "enabled": false
          },
          {
            "title": "Challenges",
            "name": "challenges",
            "icon": {
              "default": "arm-flex-outline",
              "selected": "arm-flex"
            },
            "enabled": false
          },
          {
            "title": "More",
            "name": "more",
            "icon": {
              "default": "menu",
              "selected": "microsoft-xbox-controller-menu"
            },
            "enabled": true
          }
        ]
      }
    },
    "sessionView": null,
    "theme": {
      "colors": {
        "dark": {
          "error": "rgb(255, 180, 171)",
          "scrim": "rgb(0, 0, 0)",
          "shadow": "rgb(0, 0, 0)",
          "onError": "rgb(105, 0, 5)",
          "outline": "rgb(141, 145, 153)",
          "primary": "rgb(161, 201, 255)",
          "surface": "rgb(26, 28, 30)",
          "backdrop": "rgba(44, 49, 55, 0.4)",
          "tertiary": "rgb(255, 178, 191)",
          "elevation": {
            "level0": "transparent",
            "level1": "rgb(33, 37, 41)",
            "level2": "rgb(37, 42, 48)",
            "level3": "rgb(41, 47, 55)",
            "level4": "rgb(42, 49, 57)",
            "level5": "rgb(45, 52, 62)"
          },
          "onPrimary": "rgb(0, 50, 90)",
          "onSurface": "rgb(227, 226, 230)",
          "secondary": "rgb(99, 219, 181)",
          "background": "rgb(26, 28, 30)",
          "onTertiary": "rgb(102, 0, 40)",
          "onSecondary": "rgb(0, 56, 42)",
          "onBackground": "rgb(227, 226, 230)",
          "errorContainer": "rgb(147, 0, 10)",
          "inversePrimary": "rgb(16, 96, 164)",
          "inverseSurface": "rgb(227, 226, 230)",
          "outlineVariant": "rgb(67, 71, 78)",
          "surfaceVariant": "rgb(67, 71, 78)",
          "surfaceDisabled": "rgba(227, 226, 230, 0.12)",
          "inverseOnSurface": "rgb(47, 48, 51)",
          "onErrorContainer": "rgb(255, 180, 171)",
          "onSurfaceVariant": "rgb(195, 198, 207)",
          "primaryContainer": "rgb(0, 72, 128)",
          "onSurfaceDisabled": "rgba(227, 226, 230, 0.38)",
          "tertiaryContainer": "rgb(144, 0, 59)",
          "onPrimaryContainer": "rgb(210, 228, 255)",
          "secondaryContainer": "rgb(0, 81, 62)",
          "onTertiaryContainer": "rgb(255, 217, 222)",
          "onSecondaryContainer": "rgb(129, 248, 208)"
        },
        "light": {
          "error": "rgb(186, 26, 26)",
          "scrim": "rgb(0, 0, 0)",
          "shadow": "rgb(0, 0, 0)",
          "onError": "rgb(255, 255, 255)",
          "outline": "rgb(118, 118, 128)",
          "primary": "rgb(62, 85, 191)",
          "surface": "#fff",
          "backdrop": "rgba(47, 48, 56, 0.4)",
          "tertiary": "rgb(0, 108, 81)",
          "elevation": {
            "level0": "transparent",
            "level1": "rgb(255, 255, 255)",
            "level2": "rgb(255, 255, 255)",
            "level3": "rgb(255, 255, 255)",
            "level4": "rgb(255, 255, 255)",
            "level5": "rgb(255, 255, 255)"
          },
          "onPrimary": "rgb(255, 255, 255)",
          "onSurface": "rgb(27, 27, 31)",
          "secondary": "rgb(0, 102, 137)",
          "background": "#fafafa",
          "onTertiary": "rgb(255, 255, 255)",
          "quartenary": "rgb(177, 38, 77)",
          "onSecondary": "rgb(255, 255, 255)",
          "onBackground": "rgb(27, 27, 31)",
          "onQuartenary": "rgb(255, 255, 255)",
          "errorContainer": "rgb(255, 218, 214)",
          "inversePrimary": "rgb(185, 195, 255)",
          "inverseSurface": "rgb(48, 48, 52)",
          "outlineVariant": "rgb(198, 197, 208)",
          "surfaceVariant": "rgb(227, 225, 236)",
          "surfaceDisabled": "rgba(27, 27, 31, 0.12)",
          "inverseOnSurface": "rgb(243, 240, 244)",
          "onErrorContainer": "rgb(65, 0, 2)",
          "onSurfaceVariant": "rgb(70, 70, 79)",
          "primaryContainer": "rgb(222, 225, 255)",
          "onSurfaceDisabled": "rgba(27, 27, 31, 0.38)",
          "tertiaryContainer": "#39A583",
          "onPrimaryContainer": "rgb(0, 18, 88)",
          "secondaryContainer": "rgb(195, 232, 255)",
          "onTertiaryContainer": "#fff",
          "quartenaryContainer": "rgb(255, 217, 221)",
          "onSecondaryContainer": "rgb(0, 30, 44)",
          "onQuartenaryContainer": "rgb(64, 0, 19)"
        }
      }
    }
  }
}