import type { Article } from "../../../../../types";

export const Article538: Article = {
	id: 538,
	rsuiteId: "81ba9ba0-6708-465a-a5b7-84520d20a16c",
	type: "scientific article",
	title:
		"Landscape of the Lumbar Cartilaginous End Plate Microbiota and Metabolites in Patients with Modic Changes",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=81ba9ba0-6708-465a-a5b7-84520d20a16c&type=jpeg&name=JBJS.23.00805f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Landscape of the Lumbar Cartilaginous End Plate
                Microbiota and Metabolites in Patients with Modic Changes</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Sunqi Nian,
                      MM</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Shaohua Tang,
                      MM</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 8 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_52" class="annotation strong"
                          style="position: unset;">Background:</span> Modic changes (MCs), vertebral end plate and bone
                        marrow damage observed by magnetic resonance imaging, are an independent risk factor for low
                        back pain. The compositions of and interaction between microbiota and metabolites in the lumbar
                        cartilaginous end plates (LCEPs) of patients with MCs have not been identified.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_53" class="annotation strong"
                          style="position: unset;">Methods:</span> Patients with lumbar disc degeneration who were
                        undergoing lumbar spinal fusion surgery were recruited between April 2020 and April 2021. LCEPs
                        were collected for 16S rRNA sequencing and liquid chromatography-mass spectrometry (LC/MS)-based
                        targeted metabolomic profiling. Of the 54 patients recruited, 24 had no MCs and 30 had changes
                        classified as Modic type 2 or 3. The primary goal was to identify specific genera of microbiota
                        associated with MCs, and secondary goals included investigating differences in metabolites
                        between patients with and without MCs and exploring the correlation between these metabolites
                        and microorganisms.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_54" class="annotation strong"
                          style="position: unset;">Results:</span> Investigation of the microbiota community structure
                        revealed that both alpha diversity and beta diversity were significantly different between
                        patients with and without MCs, and the abundances of 26 genera were significantly different
                        between these 2 groups. Metabolomic analysis revealed that 26 metabolites were significantly
                        different between the 2 groups. The unsaturated fatty acid pathway was found to be the main
                        pathway related to MCs. Multiomic correlation analysis suggested that Caulobacteraceae
                        (unclassified) and Mycobacterium, Clostridium, Blautia, and Bifidobacterium at the genus level
                        were linked to dysregulation of fatty acid metabolism, contributing to the pathogenesis of MCs.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_55" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Our study represents a foundational effort to
                        examine the landscape of the microbiota and metabolites in patients with MCs, informing future
                        studies on the pathogenesis of and targeted therapy for MCs.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_6" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_56" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level II</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Low back pain is one of the leading causes of disability,
                  with rising societal and economic cost<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">2</span></a>. Most structures in the lower back can contribute to low
                  back pain: muscles, fascia, vertebrae, intervertebral discs, vascular tissue, dura mater, nerve roots,
                  dorsal root ganglia, etc.<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">3</span></a>.
                  Delineating the cause of the pain is crucial for effective treatment. Modic changes (MCs) of the
                  lumbar end plate, representing vertebral end plate and bone marrow changes, are an independent risk
                  factor for severe and disabling episodes of low back pain<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">4</span></a>.
                  Among patients with lumbar disc herniation who undergo percutaneous endoscopic lumbar discectomy,
                  those with preoperative type-2 MCs have shown higher Oswestry Disability Index (ODI) scores and
                  recurrence rates than those without preoperative MCs<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">5</span></a>. In
                  line with this, preoperative type-1 MCs have been shown to be associated with worse functional status
                  at 2 years after discectomy<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">6</span></a>.
                  Thus, elucidating the pathogenesis of MCs is an important step toward improving surgical outcomes for
                  lumbar disc disease.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">It has been found that microbiota are present in normal
                  healthy intervertebral discs and that “ecological dysbiosis” of the disc microbiota may be the cause
                  of disc degeneration<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">9</span></a>. A herniated intervertebral disc or torn anulus fibrosus may
                  provide a pathway for bacterial migration from the bloodstream to the interior of the disc, resulting
                  in infection by organisms that are not typical of the microbiota of a healthy disc, thereby inducing
                  an inflammatory response that leads to the development of MCs<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">10</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">12</span></a>. However, to our knowledge, there have been no studies
                  exploring the microbiota and metabolite composition of the lumbar end plate. In this study, we aimed
                  to identify microbial and metabolite features that might contribute to the pathogenesis of MCs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study Design
                and Sample Collection</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Patients who underwent transforaminal lumbar interbody
                  fusion (TLIF) at the First People’s Hospital of Yunnan Province, People’s Republic of China, from
                  April 2020 to April 2021 were recruited. Inclusion criteria were (1) a clear diagnosis of lumbar disc
                  degeneration with or without MCs and (2) an age of ≥18 years. Exclusion criteria were (1) a history of
                  acute or severe infection of the lumbar spine within the previous 1 year, (2) combined metabolic
                  syndrome or immune dysfunction, (3) malignancy, and (4) a history of spinal surgery. All patients
                  underwent magnetic resonance imaging (MRI) of the lumbar spine and met surgical criteria for lumbar
                  spinal fusion. The group with MCs showed signal changes on T1 and T2-weighted MRI that were consistent
                  with the diagnostic criteria for MCs<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">13</span></a>.
                  Informed consent was obtained from all patients. The primary goal of this study was identifying the
                  specific genera of microorganisms associated with MCs. The secondary goals involved investigating the
                  differences in metabolites between patients with and without MCs and exploring the correlation between
                  these metabolites and microorganisms. General clinical data including age, sex, body mass index (BMI),
                  history of smoking, history of alcohol consumption, the diseased disc segment, grading of the
                  degeneration, MC type, low back pain and leg pain on a visual analog scale (VAS), and ODI scores were
                  recorded for each patient. All samples were taken from lumbar cartilaginous end plates (LCEPs) removed
                  during TLIF. The end plate tissue collected intraoperatively was rinsed during the sampling process to
                  eliminate soft tissue and blood attached to the end plate, then frozen overnight in liquid nitrogen
                  and stored at −80°C for further testing. All tests were performed under sterile conditions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">DNA
                Extraction for Microbiota Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Genomic DNA samples were extracted from the LCEP tissue
                  with the Mag-Bind Soil DNA Kit (M5635-02; Omega Bio-tek), following the manufacturer’s instructions,
                  and stored at −20°C. After individual quantification, amplicons were pooled in equal amounts, followed
                  by paired-end 2 × 250-base pair sequencing on the Illumina NovaSeq platform with the NovaSeq 6000 SP
                  Reagent Kit (500 cycles) at Shanghai Personal Biotechnology. Detailed steps are described in the
                  Appendix.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Metabolic
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">An ultra-performance liquid chromatography-tandem mass
                  spectrometry (LC/MS) system (ACQUITY UPLC Xevo TQ-S; Waters) was used for the quantitative analysis of
                  all target metabolites. A brief description of the optimized instrument setup and specific
                  experimental steps are given in the Appendix.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Differences in clinical characteristics were assessed
                  using the Pearson chi-square test or Student t test. Pearson rank correlation analysis was used to
                  calculate the correlation coefficients between metabolites and genera. Differences were considered
                  significant when p &lt; 0.05. False discovery rate (FDR) correction factors, which can be used to
                  reduce the rate of false-positive results due to multiple comparisons, were calculated for the
                  comparisons of metabolites between groups (see Appendix Supplementary Table S1). Orthogonal partial
                  least squares discriminant analysis (OPLS-DA) was used to select the metabolites with the greatest
                  differences between the 2 groups. All data were analyzed with R (version 4.1.0; R Foundation for
                  Statistical Computing) and Microsoft Excel. Linear discriminant analysis (LDA) integrated with effect
                  size (LEfSe) was performed on a Galaxy server (<a href="http://huttenhower.sph.harvard.edu/galaxy/"
                    target="_blank" data-id="link_3" class="link"
                    style="position: unset;">http://huttenhower.sph.harvard.edu/galaxy/</a>). Microbiome differences
                  between the 2 groups were analyzed using STAMP (<a href="https://beikolab.cs.dal.ca/software/STAMP"
                    target="_blank" data-id="link_4" class="link"
                    style="position: unset;">https://beikolab.cs.dal.ca/software/STAMP</a>)<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">14</span></a>. Network maps of microbiome-metabolome interactions were
                  created using Gephi visualization software (<a href="https://gephi.org/" target="_blank"
                    data-id="link_5" class="link" style="position: unset;">https://gephi.org/</a>)<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">15</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Summary of
                Clinical Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The study involved 54 patients, of whom 30 had lumbar disc
                  degeneration with MCs (MC group) and 24 had lumbar disc degeneration only (non-MC group). <a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a> shows the clinical and demographic details of the 2 groups of
                  patients. Patients in the MC group had significantly more severe disc degeneration, as expected since
                  the development of MCs is usually seen in the late stages of disc degeneration<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">18</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Baseline Characteristics of the Groups without and
                          with Modic Changes (MCs)<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1057px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Characteristic</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Non-MC (N
                          = 24)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">MC (N =
                          30)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (75%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (70%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.68</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_63" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60.96 ±
                          8.81</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61.57 ±
                          8.30</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.80</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI <span
                            data-id="emphasis_64" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_65" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_14" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_14"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_66"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.78 ±
                          3.34</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.23 ±
                          3.26</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.12</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Smoking</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (13%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (13%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.93</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Alcohol
                          consumption</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (17%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (17%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.65</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Diabetes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (13%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.50</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Disc segments</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> L2/L3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.32</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> L3/L4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (33%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> L4/L5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (75%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (37%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> L5/S1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (17%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (20%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.75</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pfirrmann
                          classification</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> C</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (79%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (23%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> D</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (21%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (63%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.004</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> E</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (13%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.18</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Modic type</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (83%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (17%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ODI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36.63 ±
                          19.54</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35.11 ±
                          18.22</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.77</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">VAS for low back
                          pain</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.08 ±
                          2.34</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.17 ±
                          2.39</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.90</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">VAS for leg pain</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.04 ±
                          2.01</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.73 ±
                          2.50</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.63</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the number with the percentage in parentheses
                        or as the mean ± standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Species
                Diversity Analysis of the Microbiota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">A mean of 125,892 reads per sample were achieved after
                  mass filtering based on 16S rRNA data. Notably, there was a significant difference (p &lt; 0.05) in
                  the number of reads between the MC group (mean and standard deviation, 135,871 ± 31,910) and the
                  non-MC group (113,417 ± 16,959). Taxonomic processing yielded 935 genera. The sampling quality was
                  validated by species accumulation curves (see Appendix Fig. S1A) and rarefaction curves (see Appendix
                  Fig. S1B). The rank abundance distribution curves (see Appendix Supplementary Fig. S1C) revealed
                  higher bacterial abundance in the non-MC group compared with the MC group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">There were significant differences in the Shannon (p =
                  0.00017), observed species (p = 0.0025), Chao1 (p = 0.0019), and Simpson (p = 0.00026) indices between
                  the 2 groups (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1-A</a>). Venn
                  diagram analysis revealed 3,853 unique operational taxonomic units (OTUs) in the MC group and 3,944 in
                  the non-MC group, with 902 OTUs shared between them (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1-B</a>); OTUs
                  are artificial markers that are assigned to the various taxonomic units (phyla, genera, and/or
                  species) in a sample by identifying clusters of similar sequences.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00805f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=81ba9ba0-6708-465a-a5b7-84520d20a16c&type=jpeg&name=JBJS.23.00805f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">Diversity and structure analysis of LCEP
                          microbiota in the groups with and without Modic changes (MCs). <span data-id="strong_60"
                            class="annotation strong" style="position: unset;">Fig. 1-A</span> Species diversity
                          differences between the non-MC and MC groups were analyzed using the observed species,
                          Shannon, Simpson, and Chao1 indices. The interquartile range is shown as a box, the median is
                          shown as a line within the box, and points within 1.5 times the width of the interquartile
                          range are shown as whiskers. **P &lt; 0.01, ***p &lt; 0.001. <span data-id="strong_61"
                            class="annotation strong" style="position: unset;">Fig. 1-B</span> Venn diagram of the
                          observed features in the non-MC and MC groups.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Alterations
                in Microbial Composition Associated with Modic Changes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Dominant taxa at the phylum and genus levels were compared
                  between the non-MC and MC groups, revealing notable differences in the microbial communities of the
                  LCEP (<a href="" data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2-A</a>; see also Appendix Fig. S2A). Among 41 phyla and 935 genera
                  identified, the 4 most prevalent phyla were Proteobacteria (non-MC: 68.08%, MC: 75.69%),
                  Actinobacteria (non-MC: 12.49%, MC: 9.9%), Firmicutes (non-MC: 9.83%, MC: 7.15%), and Bacteroidetes
                  (non-MC: 2.96%, MC: 1.87%) (see Appendix Figs. S2A, S2B, and S2C). At the genus level, the LCEP
                  samples predominantly comprised Caulobacteraceae (unclassified) (non-MC: 46.00%, MC: 59.15%) and
                  Rhodococcus (non-MC: 5.83%, MC: 6.01%) (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 2-A</a> and <a
                    href="" data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">2-B</a>). A Welch t test revealed significant differences in the LCEP
                  microbiota between the groups. Proteobacteria were notably higher in the MC group (p &lt; 0.05), while
                  Bacteroidetes and Cyanobacteria were significantly higher in the non-MC group (p &lt; 0.05 and p &lt;
                  0.001, respectively) (see Appendix Figs. S2B and S3A). At the genus level, Caulobacteraceae
                  (unclassified) were significantly higher in the MC group (p &lt; 0.001), whereas Gammaproteobacteria
                  (unclassified) were more abundant in the non-MC group (p &lt; 0.05) (<a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2-B</a>; see also Appendix Fig. S3B).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.00805f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=81ba9ba0-6708-465a-a5b7-84520d20a16c&type=jpeg&name=JBJS.23.00805f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_62"
                            class="annotation strong" style="position: unset;">Fig. 2-A</span> Relative abundances of
                          LCEP microbiota at the genus level for each sample; n = 24 for the group without Modic changes
                          (Cs) and n = 30 for the group with MCs. <span data-id="strong_63" class="annotation strong"
                            style="position: unset;">Fig. 2-B</span> Proportions of LCEP microbiota at the genus level
                          for the non-MC and MC groups. *P &lt; 0.05, **p &lt; 0.01.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Using LDA integrated with LEfSe, a cladogram was
                  constructed to identify specific bacteria distinguishing MC and non-MC groups (see Appendix Fig. S4).
                  The analysis revealed 79 discriminatory OTUs as crucial factors. The non-MC group exhibited an
                  enrichment in 63 OTUs, whereas the MC group showed enrichment in 16 OTUs. Specifically, Proteobacteria
                  at the phylum level and 5 genera displayed significantly higher abundance in the MC group, with an LDA
                  score (log<span data-id="subscript_1" class="annotation subscript" style="position: unset;">10</span>)
                  of &gt;3. Conversely, Firmicutes and Cyanobacteria at the phylum level and 21 genera were
                  significantly enriched in the non-MC group (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>; see also
                  Appendix Fig. S4). Among these, Ralstonia, Bifidobacterium, Planktothrix, Gaiella, and Collinsella
                  were the 5 most enriched genera, relative to the MC group (LDA score [log<span data-id="subscript_2"
                    class="annotation subscript" style="position: unset;">10</span>] of &gt;3) (<a href=""
                    data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.00805f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=81ba9ba0-6708-465a-a5b7-84520d20a16c&type=jpeg&name=JBJS.23.00805f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;">Differences in microbiota abundances in the lumbar
                          cartilaginous end plates in the group with Modic changes (MCs) relative to the group without
                          MCs, calculated using linear discriminant analysis (LDA &gt; 3) combined with the effect size
                          (LEfSe).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Potential
                Microbiota Biomarkers of MCs</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">The LEfSe analysis revealed 26 genera with an LDA score of
                  &gt;3 that distinguished patients with MCs. Five of these genera were notably overabundant in the MC
                  group, while the other 21 were more prevalent in the non-MC group (<a href=""
                    data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>). To evaluate the efficacy of the 26 potential bacterial
                  biomarkers that had different abundances in patients with and without MCs, a receiver operating
                  characteristic (ROC) curve was generated, and the area under the ROC curve (AUC) was calculated, for
                  each of these 26 genera (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>). The AUC
                  reflects the ability of each genus to differentiate between the groups. Nine genera exhibited AUC
                  values of &gt;70%, including Clostridium (83.3%), Mycobacterium (79.8%), Caulobacteraceae (78.6%),
                  Blautia (73.4%), and Bifidobacterium (72.8%), among others.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.00805f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=81ba9ba0-6708-465a-a5b7-84520d20a16c&type=jpeg&name=JBJS.23.00805f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;">ROC curves of the genera best able to distinguish
                          between the groups with and without Modic changes (MCs), as shown by their AUC (area under the
                          ROC curve) values of &gt;70%.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Metabolites
                Associated with MCs</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Targeted LC/MS-based metabolomics identified 169
                  metabolites that were differentially expressed in LCEP samples from the MC and non-MC groups. OPLS-DA
                  revealed a distinct metabolite profile in each group, indicating robust discrimination (<a href=""
                    data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 5-A</a>), and a volcano plot (<a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5-B</a>)
                  highlighted the differences. Nineteen metabolites, consisting of 8 fatty acids (adrenic acid,
                  dihomo-gamma-linolenic acid, docosapentaenoic acid [DPA], docosahexaenoic acid [DHA], arachidonic
                  acid, ricinoleic acid, linoelaidic acid, linoleic acid), 4 carnitines (stearylcarnitine,
                  palmitoylcarnitine, oleylcarnitine, linoleylcarnitine), 4 organic acids (citric acid,
                  2-hydroxyglutaric acid, benzoic acid, malic acid), xylose, kynurenine, and 4-hydroxyheptanoic acid,
                  were more abundant in the MC group. Seven metabolites, consisting of oxalic acid, homocitrulline,
                  decanoylcarnitine, octanoylcarnitine, glycolithocholate (GLCA), 3,4-dihydroxyhydrocinnamic acid, and
                  N-acetylneuraminic acid, were less abundant in the MC group (see Appendix Table S1).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.00805f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=81ba9ba0-6708-465a-a5b7-84520d20a16c&type=jpeg&name=JBJS.23.00805f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;">Metabolomic analysis of metabolites that differed
                          significantly between the groups with and without Modic changes (MCs). <span
                            data-id="strong_64" class="annotation strong" style="position: unset;">Fig. 5-A</span>
                          Orthogonal partial least squares discriminant analysis (OPLS-DA) of the metabolites whose
                          levels were significantly higher in the LCEPs of either the MC or non-MC group. P1 is the
                          predictive score, and O1 is the orthogonal score. <span data-id="strong_65"
                            class="annotation strong" style="position: unset;">Fig. 5-B</span> Volcano plot analysis of
                          metabolites (VIP &gt; 1, P ≥ 0.5, jackknifed 95% confidence interval). VIP = variable
                          importance in the projection, log<span data-id="subscript_3" class="annotation subscript"
                            style="position: unset;">2</span>FC = logarithm of the fold change, GLCA =
                          glycolithocholate, DPA = docosapentaenoic acid, DHA = docosahexaenoic acid. <span
                            data-id="strong_66" class="annotation strong" style="position: unset;">Fig. 5-C</span>
                          Pathway enrichment and accompanying significance level for the pathways involving the 19
                          metabolites significantly enriched in the group with Modic changes. <span data-id="strong_67"
                            class="annotation strong" style="position: unset;">Fig. 5-D</span> Pathway enrichment and
                          accompanying significance level for the pathways involving the 7 metabolites significantly
                          enriched in the group without Modic changes.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Analyzing the identified metabolites revealed their
                  involvement in 72 metabolic pathways in the Human Metabolomic Database (HMDB), with the top 25
                  depicted in Appendix Figures S5A and S5B. The 19 metabolites enriched in the MC group were associated
                  with 9 key pathways, such as unsaturated fatty acid metabolism, the malate-aspartate shuttle, and the
                  citric acid cycle (<a href="" data-id="figure_reference_14"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5-C</a>).
                  Pathway enrichment analysis of the 7 metabolites that were more highly expressed in the non-MC group
                  highlighted pathways such as mitochondrial beta-oxidation of short-chain saturated fatty acids and
                  amino sugar metabolism (<a href="" data-id="figure_reference_15"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5-D</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Multiomic
                Analysis of Microbe-Metabolite Interactions in MCs</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Spearman correlation and network analyses were employed to
                  examine microbe-metabolite interactions, generating a correlation matrix (<a href=""
                    data-id="figure_reference_16" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 6</a>) and a co-occurrence network (see Appendix Fig. S6D). Overall,
                  microbiota genera enriched in the MC group had trends of negative correlation with metabolites
                  enriched in the non-MC group and positive correlation with metabolites enriched in the MC group,
                  whereas microbiota genera enriched in the non-MC group had trends of negative correlation with
                  metabolites enriched in the MC group and positive correlation with metabolites enriched in the non-MC
                  group. More importantly, Caulobacteraceae (unclassified), which were enriched in the MC group, were
                  positively correlated with fatty acids while Clostridium, Mycobacterium, Blautia, and Bifidobacterium,
                  which were enriched in the non-MC group, were negatively correlated with fatty acids. We also examined
                  the relationships of clinical characteristics with microbiota genera that were more or less prevalent
                  in the MC group (see Appendix Figs. S6A and S6C) as well as with metabolites that were more or less
                  prevalent in the MC group (see Appendix Figs. S6B and S6C). The presence of particular bacteria and
                  metabolites correlated with clinical indicators such as height, weight, BMI, ODI, low back pain, and
                  leg pain (see Appendix Table S2).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.23.00805f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=81ba9ba0-6708-465a-a5b7-84520d20a16c&type=jpeg&name=JBJS.23.00805f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;">Correlation of microbiota genera and metabolites
                          that differed significantly between the lumbar end plates with and without Modic changes
                          (MCs). Correlation strengths are indicated by a color gradient from blue (negative
                          correlation) to red (positive correlation). *P &lt; 0.05, **p &lt; 0.01.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Low-grade bacterial infections, particularly with <span
                    data-id="emphasis_67" class="annotation emphasis" style="position: unset;">Propionibacterium
                    acnes,</span> are increasingly recognized as potential causes of disc degeneration, MCs, sciatica,
                  and low back pain<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">22</span></a>. In some studies, antibiotic therapy has shown some
                  efficacy for chronic low back pain associated with type-1 MCs. However, there are conflicting opinions
                  regarding the effectiveness of antibiotic treatment for low back pain associated with MCs<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">24</span></a>. Recent studies have explored the biological changes in
                  intervertebral disc with MCs<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">26</span></a>. All of these changes point to an intense inflammatory
                  status in patients with MCs. In line with this, Gilligan et al. critically reviewed studies on
                  bacteria in intervertebral discs, again supporting the presence of microorganisms in the discs<a
                    href="" data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">27</span></a>. Others have found a host defense response mechanism in the
                  intervertebral discs, suggesting that the bacterial infection is long-lasting<a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">29</span></a>. Building on previous studies, our study showed that both
                  microbiota and metabolites in LCEPs were significantly different between the non-MC and MC groups,
                  which may pave the way for novel diagnostics and therapeutic strategies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Alpha diversity analysis focuses on biodiversity within a
                  disease, while beta diversity analysis is used to compare biodiversity between different diseases.
                  Indices such as the Shannon index and the Simpson index are used to measure biodiversity. Principal
                  coordinate analysis (PCoA) is commonly used to compare community compositions, and the Jaccard index
                  compares the number of species co-occurring in 2 sets of samples to the total number of species. There
                  were significant differences in the alpha diversity and beta diversity of the microbiota between the
                  MC and non-MC groups, suggesting that the microbiota may relate to MCs. The study also showed that the
                  level of bacterial load was lower in the MC group than in the non-MC group, highlighting a plausible
                  connection between the microbiota and MCs. A previous study showed that infection with the phylum
                  Proteobacteria were associated with airway inflammation and poorer lung function<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">30</span></a>. Bifidobacterium is an important component of the human
                  microbiome and is associated with several aspects of health, especially digestive health and immune
                  support<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">31</span></a>. It
                  has been well documented that interactions between immunity and microbes early in life influence the
                  risk of inflammatory diseases, such as a lack of gut Bifidobacterium correlating with systemic
                  inflammation<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">32</span></a>. In
                  recent years, Blautia have been shown to have inhibitory effects in inflammatory and metabolic
                  diseases<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">35</span></a>. Consistent with these results, we found that
                  Bifidobacterium and Blautia had a higher abundance in the non-MC group than in the MC group. It is
                  plausible that these alterations may contribute to the inflammatory status in LCEPs with MCs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">The metabolomic analysis in this study showed that many
                  important metabolites associated with inflammation, such as fatty acids and 3,4-dihydroxyhydrocinnamic
                  acid, were altered in the MC group. Increased levels of fatty acids usually correlate with increased
                  production of pro-inflammatory and pro-apoptotic markers<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">36</span></a>. A
                  recent study indicated that fatty acids could contribute to inflammatory processes and cartilage
                  degradation in osteoarthritis<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">37</span></a>.
                  Adrenic acid has been confirmed to exacerbate inflammation<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">38</span></a>.
                  Furthermore, patients with chronic inflammatory diseases such as rheumatoid arthritis have been shown
                  to have elevated serum dihomo-gamma-linolenic acid concentrations<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">39</span></a>. In contrast, 3,4-dihydroxyhydrocinnamic acid alleviates
                  hepatic ischemia-reperfusion injury by suppressing the macrophage-mediated pro-inflammatory response<a
                    href="" data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">40</span></a>. Intervening in the arachidonic acid metabolic pathway is
                  an effective way to clinically treat inflammation-related diseases<a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">41</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">42</span></a>. Taken together, these results support the hypothesis that
                  the metabolites in LCEPs may play a key role in MC pathogenesis by promoting inflammation and
                  cartilage degradation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Furthermore, we identified that the unsaturated fatty acid
                  metabolism pathway was the main pathway related to MCs. This metabolic pathway has also been found to
                  be upregulated in the fecal samples of patients with rheumatoid arthritis<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">43</span></a>. Linoleic acid, a precursor of pro-inflammatory
                  eicosanoids, can increase systemic, low-grade inflammation in degenerative diseases<a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">44</span></a>. Arachidonic acid, a precursor of prostaglandins and
                  leukotrienes, plays a key role in inflammatory responses and is associated with many neurodegenerative
                  diseases, cardiovascular diseases, and pain<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">45</span></a><span data-id="superscript_32"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">46</span></a>. Consistent with the situation in other skeletal diseases
                  such as osteoporosis, osteoarthritis, and rheumatoid arthritis<a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_33"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">43</span></a><span data-id="superscript_33"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">47</span></a>, our study showed that dysbiosis of the microbiota was
                  closely correlated with MC development and that unsaturated fatty acid metabolism may be implicated.
                  These findings underscore the importance of considering changes in the microbiota and metabolites in
                  the broader clinical context of MC-related conditions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">We found a strong correlation between over- or
                  underabundance of specific bacterial genera within LCEPs with MC and with over- or underabundance of
                  specific metabolites. Notably, fatty acids emerged as the main metabolites between these groups. These
                  findings suggest that changes in the microbiota may disrupt fatty acid metabolism within the LCEP
                  microenvironment, potentially influencing the onset and progression of MCs. Although MCs commonly
                  coexist with disc degeneration and disc herniation<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">48</span></a><span data-id="superscript_34"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">49</span></a>, it is unclear why some of the patients with these
                  conditions develop MCs while others do not. Our findings provide novel evidence of a potential
                  contribution of the LCEP microbiota and metabolites to MCs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Twenty-six microbial genera and 26 metabolites were found
                  to differ significantly between patients with and without MCs. The interrelationships between these
                  metabolites and microbial genera were also elucidated. Multiomic correlation analysis suggested that
                  Caulobacteraceae (unclassified), Mycobacterium, Clostridium, Blautia, and Bifidobacterium were linked
                  to dysregulation of unsaturated fatty acid metabolism, contributing to the pathogenesis of MCs. In
                  future studies, it may be feasible to influence the onset and progression of MCs by modulating the
                  microbiota and metabolites in patients. It is also plausible that modulating these biomarkers can
                  alleviate MC-induced low back pain. Furthermore, additional research is required to elucidate the
                  mechanisms underlying the interactions between MCs and disc degeneration.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">This study had several limitations. To our knowledge, it
                  is the first single-center preliminary study exploring the composition of the microbiome and the
                  metabolome in vertebrae with MCs. Although the sample size is larger than those in previous studies on
                  the microbiome of intervertebral discs<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_35" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">50</span></a>, which may allow it to yield more reliable results, future
                  studies with larger sample sizes are needed for further in-depth research. Second, none of the
                  recruited patients had type-1 MCs. MC types 1, 2 and 3 represent different stages of the same
                  pathological process<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript" style="position: unset;">51</span></a>.
                  Patients with lumbar disc degeneration usually undergo surgery only when nonoperative therapy is not
                  effective. All of the patients in our study met the criteria for lumbar spinal fusion surgery, which
                  may account for why only patients with types 2 and 3, and none with type 1, were recruited. Third,
                  there is a possibility of iatrogenic contamination. However, we believe that the identified
                  differences in the microbiome composition between the MC and non-MC groups were unlikely to have been
                  due to contamination. We followed stringent protocols during sample collection. The identified
                  differences in Bifidobacterium and Blautia between the 2 groups were consistent with their known roles
                  in metabolism and immunity, which have implications for human diseases. Of note, the microbiomes in
                  the end plate and in the disc are not mutually exclusive. Future studies to compare the end plate and
                  disc from the same patients are warranted. Nonetheless, our study provides a landscape of microbiota
                  components and metabolites that are likely linked to MCs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">This study identified changes in the microbiota and
                  metabolites associated with MCs in the end plate. Although our study design cannot be used to infer
                  causal relationships, the associations involving specific microbiota components and metabolomic
                  signatures in the end plate may provide mechanistic insights into the pathogenesis and treatment of
                  MCs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_6" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I103" target="_blank" data-id="link_7" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I103</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank all the subjects in this study for their
                  participation and cooperation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;">GBD 2019
                Diseases and Injuries Collaborators. <span data-id="strong_1" class="annotation strong"
                  style="position: unset;">Global burden of 369 diseases and injuries in 204 countries and territories,
                  1990-2019: a systematic analysis for the Global Burden of Disease Study 2019</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Lancet.</span>
                2020;396(10258):1204-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Global%20burden%20of%20369%20diseases%20and%20injuries%20in%20204%20countries%20and%20territories%2C%201990-2019%3A%20a%20systematic%20analysis%20for%20the%20Global%20Burden%20of%20Disease%20Study%202019&as_occt=title&as_sauthors=%20%22GBD%202019%20Diseases%20and%20Injuries%20Collaborators%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Hoy D</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Bain C</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Williams G</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> March L</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Brooks P</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Blyth F</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Woolf A</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Vos T</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Buchbinder R</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">A systematic review of the
                  global prevalence of low back pain</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Arthritis Rheum.</span> 2012;64(6):2028-37.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20systematic%20review%20of%20the%20global%20prevalence%20of%20low%20back%20pain&as_occt=title&as_sauthors=%20%22D%20Hoy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Vlaeyen JWS</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Maher CG</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Wiech K</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Van Zundert J</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Meloto CB</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Diatchenko L</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Battié MC</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Goossens M</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Koes B</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Linton SJ</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Low back pain</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Nat Rev Dis Primers.</span>
                2018;4(1):52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Low%20back%20pain&as_occt=title&as_sauthors=%20%22JWS%20Vlaeyen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Määttä JH</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Wadge S</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> MacGregor A</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Karppinen J</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Williams FMK</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">ISSLS Prize Winner: Vertebral
                  Endplate (Modic) Change is an Independent Risk Factor for Episodes of Severe and Disabling Low Back
                  Pain</span>. <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Spine
                  (Phila Pa 1976).</span> 2015;40(15):1187-93.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=ISSLS%20Prize%20Winner%3A%20Vertebral%20Endplate%20(Modic)%20Change%20is%20an%20Independent%20Risk%20Factor%20for%20Episodes%20of%20Severe%20and%20Disabling%20Low%20Back%20Pain&as_occt=title&as_sauthors=%20%22JH%20M%C3%A4%C3%A4tt%C3%A4%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Zhu H</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Hussain Z</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Zhang M</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Ji F</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Mao H</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Li H</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Chen H</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Percutaneous Endoscopic Lumbar
                  Discectomy for Lumbar Disc Herniation With Type II Modic Changes</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">World Neurosurg.</span>
                2022;164:e143-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Percutaneous%20Endoscopic%20Lumbar%20Discectomy%20for%20Lumbar%20Disc%20Herniation%20With%20Type%20II%20Modic%20Changes&as_occt=title&as_sauthors=%20%22H%20Zhu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Nian S</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Li N</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Kong F</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Lu S</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Chen J</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Is discectomy effective for
                  treating low back pain in patients with lumbar disc herniation and Modic changes? A systematic review
                  and meta-analysis of cohort studies</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Spine J.</span> 2023;23(4):533-49.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20discectomy%20effective%20for%20treating%20low%20back%20pain%20in%20patients%20with%20lumbar%20disc%20herniation%20and%20Modic%20changes%3F%20A%20systematic%20review%20and%20meta-analysis%20of%20cohort%20studies&as_occt=title&as_sauthors=%20%22S%20Nian%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Rajasekaran S</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Soundararajan DCR</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Tangavel C</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Muthurajan R</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Sri Vijay Anand KS</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Matchado MS</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Nayagam SM</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Shetty AP</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Kanna RM</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Dharmalingam K</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Human intervertebral discs
                  harbour a unique microbiome and dysbiosis determines health and disease</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Eur Spine J.</span>
                2020;29(7):1621-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Human%20intervertebral%20discs%20harbour%20a%20unique%20microbiome%20and%20dysbiosis%20determines%20health%20and%20disease&as_occt=title&as_sauthors=%20%22S%20Rajasekaran%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Capoor MN</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Birkenmaier C</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Wang JC</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> McDowell A</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Ahmed FS</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Brüggemann H</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Coscia E</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Davies DG</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Ohrt-Nissen S</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Raz A</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Ruzicka F</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Schmitz JE</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Fischetti VA</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Slaby O</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">A review of microscopy-based
                  evidence for the association of Propionibacterium acnes biofilms in degenerative disc disease and
                  other diseased human tissue</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">Eur Spine J.</span> 2019;28(12):2951-71.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20review%20of%20microscopy-based%20evidence%20for%20the%20association%20of%20Propionibacterium%20acnes%20biofilms%20in%20degenerative%20disc%20disease%20and%20other%20diseased%20human%20tissue&as_occt=title&as_sauthors=%20%22MN%20Capoor%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Chen Z</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Cao P</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Zhou Z</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Yuan Y</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Jiao Y</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Zheng Y</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Overview: the role of
                  Propionibacterium acnes in nonpyogenic intervertebral discs</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2016;40(6):1291-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Overview%3A%20the%20role%20of%20Propionibacterium%20acnes%20in%20nonpyogenic%20intervertebral%20discs&as_occt=title&as_sauthors=%20%22Z%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Manniche C</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> O’Neill S</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">New insights link low-virulent
                  disc infections to the etiology of severe disc degeneration and Modic changes</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Future Sci OA.</span>
                2019;5(5):FSO389.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=New%20insights%20link%20low-virulent%20disc%20infections%20to%20the%20etiology%20of%20severe%20disc%20degeneration%20and%20Modic%20changes&as_occt=title&as_sauthors=%20%22C%20Manniche%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Albert HB</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Manniche C</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Modic changes following lumbar
                  disc herniation</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">Eur Spine J.</span> 2007;16(7):977-82.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Modic%20changes%20following%20lumbar%20disc%20herniation&as_occt=title&as_sauthors=%20%22HB%20Albert%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Albert HB</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Kjaer P</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Jensen TS</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Sorensen JS</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Bendix T</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Manniche C</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Modic changes, possible causes
                  and relation to low back pain</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Med Hypotheses.</span> 2008;70(2):361-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Modic%20changes%2C%20possible%20causes%20and%20relation%20to%20low%20back%20pain&as_occt=title&as_sauthors=%20%22HB%20Albert%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Fields AJ</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Battié MC</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Herzog RJ</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Jarvik JG</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Krug R</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Link TM</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Lotz JC</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> O’Neill CW</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Sharma A</span>; ISSLS
                Degenerative Spinal Phenotypes Group. <span data-id="strong_13" class="annotation strong"
                  style="position: unset;">Measuring and reporting of vertebral endplate bone marrow lesions as seen on
                  MRI (Modic changes): recommendations from the ISSLS Degenerative Spinal Phenotypes Group</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Eur Spine J.</span>
                2019;28(10):2266-74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Measuring%20and%20reporting%20of%20vertebral%20endplate%20bone%20marrow%20lesions%20as%20seen%20on%20MRI%20(Modic%20changes)%3A%20recommendations%20from%20the%20ISSLS%20Degenerative%20Spinal%20Phenotypes%20Group&as_occt=title&as_sauthors=%20%22AJ%20Fields%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Parks DH</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Tyson GW</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Hugenholtz P</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Beiko RG</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">STAMP: Statistical analysis of
                  taxonomic and functional profiles</span>.<span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">Bioinformatics.</span> 2014; 30(21):3123-4.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=STAMP%3A%20Statistical%20analysis%20of%20taxonomic%20and%20functional%20profiles&as_occt=title&as_sauthors=%20%22DH%20Parks%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Bastian M.</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Heymann S.</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Jacomy M</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Gephi: an open source software
                  for exploring and manipulating networks</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">International AAAI Conference on Weblogs and
                  Social Media.</span> 2009; 3(1), 361-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Gephi%3A%20an%20open%20source%20software%20for%20exploring%20and%20manipulating%20networks&as_occt=title&as_sauthors=%20%22M.%20Bastian%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Farshad-Amacker NA</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Hughes A</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Herzog RJ</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Seifert B</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Farshad M</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">The intervertebral disc, the
                  endplates and the vertebral bone marrow as a unit in the process of degeneration</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Eur Radiol.</span>
                2017;27(6):2507-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20intervertebral%20disc%2C%20the%20endplates%20and%20the%20vertebral%20bone%20marrow%20as%20a%20unit%20in%20the%20process%20of%20degeneration&as_occt=title&as_sauthors=%20%22NA%20Farshad-Amacker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Moser M</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Adl Amini D</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Albertini Sanchez L</span>,
                <span data-id="annotation_101" class="annotation" style="position: unset;"> Oezel L</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Haffer H</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Muellner M</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Zhu J</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Carrino JA</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Shue J</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Sama AA</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Cammisa FP</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Girardi FP</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Hughes AP</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">The association between
                  vertebral endplate defects, subchondral bone marrow changes, and lumbar intervertebral disc
                  degeneration: a retrospective, 3-year longitudinal study</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Eur Spine J.</span>
                2023;32(7):2350-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20association%20between%20vertebral%20endplate%20defects%2C%20subchondral%20bone%20marrow%20changes%2C%20and%20lumbar%20intervertebral%20disc%20degeneration%3A%20a%20retrospective%2C%203-year%20longitudinal%20study&as_occt=title&as_sauthors=%20%22M%20Moser%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Munir S</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Freidin MB</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Rade M</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Määttä J</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Livshits G</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Williams FMK</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Endplate Defect Is Heritable,
                  Associated With Low Back Pain and Triggers Intervertebral Disc Degeneration: A Longitudinal Study From
                  TwinsUK</span>. <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">Spine
                  (Phila Pa 1976).</span> 2018;43(21):1496-501.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Endplate%20Defect%20Is%20Heritable%2C%20Associated%20With%20Low%20Back%20Pain%20and%20Triggers%20Intervertebral%20Disc%20Degeneration%3A%20A%20Longitudinal%20Study%20From%20TwinsUK&as_occt=title&as_sauthors=%20%22S%20Munir%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Stirling A</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Worthington T</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Rafiq M</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Lambert PA</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Elliott TS</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Association between sciatica
                  and Propionibacterium acnes</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 2001;357(9273):2024-5.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20between%20sciatica%20and%20Propionibacterium%20acnes&as_occt=title&as_sauthors=%20%22A%20Stirling%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Urquhart DM</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Zheng Y</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Cheng AC</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Rosenfeld JV</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Chan P</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Liew S</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Hussain SM</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Cicuttini FM</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Could low grade bacterial
                  infection contribute to low back pain? A systematic review</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">BMC Med.</span> 2015;13:13.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Could%20low%20grade%20bacterial%20infection%20contribute%20to%20low%20back%20pain%3F%20A%20systematic%20review&as_occt=title&as_sauthors=%20%22DM%20Urquhart%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Jiao Y</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Lin Y</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Zheng Y</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Yuan Y</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Chen Z</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Cao P</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">The bacteria-positive
                  proportion in the disc tissue samples from surgery: a systematic review and meta-analysis</span>.
                <span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">Eur Spine J.</span>
                2019;28(12):2941-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20bacteria-positive%20proportion%20in%20the%20disc%20tissue%20samples%20from%20surgery%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22Y%20Jiao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Lan W</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Wang X</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Tu X</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Hu X</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Lu H</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Different phylotypes of
                  Cutibacterium acnes cause different Modic changes in intervertebral disc degeneration</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">PLoS One.</span>
                2022;17(7):e0270982.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Different%20phylotypes%20of%20Cutibacterium%20acnes%20cause%20different%20Modic%20changes%20in%20intervertebral%20disc%20degeneration&as_occt=title&as_sauthors=%20%22W%20Lan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Albert HB</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Sorensen JS</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Christensen BS</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Manniche C</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Antibiotic treatment in
                  patients with chronic low back pain and vertebral bone edema (Modic type 1 changes): a double-blind
                  randomized clinical controlled trial of efficacy</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">Eur Spine J.</span>
                2013;22(4):697-707.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Antibiotic%20treatment%20in%20patients%20with%20chronic%20low%20back%20pain%20and%20vertebral%20bone%20edema%20(Modic%20type%201%20changes)%3A%20a%20double-blind%20randomized%20clinical%20controlled%20trial%20of%20efficacy&as_occt=title&as_sauthors=%20%22HB%20Albert%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Bråten LCH</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Rolfsen MP</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Espeland A</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Wigemyr M</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Aßmus J</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Froholdt A</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Haugen AJ</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Marchand GH</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Kristoffersen PM</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Lutro O</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Randen S</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Wilhelmsen M</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Winsvold BS</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Kadar TI</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Holmgard TE</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Vigeland MD</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Vetti N</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Nygaard ØP</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Lie BA</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Hellum C</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Anke A</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Grotle M</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Schistad EI</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Skouen JS</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Grøvle L</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Brox JI</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Zwart JA</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Storheim K</span>; AIM study
                group. <span data-id="strong_24" class="annotation strong" style="position: unset;">Efficacy of
                  antibiotic treatment in patients with chronic low back pain and Modic changes (the AIM study): double
                  blind, randomised, placebo controlled, multicentre trial</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">BMJ.</span> 2019;367:l5654.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20of%20antibiotic%20treatment%20in%20patients%20with%20chronic%20low%20back%20pain%20and%20Modic%20changes%20(the%20AIM%20study)%3A%20double%20blind%2C%20randomised%2C%20placebo%20controlled%2C%20multicentre%20trial&as_occt=title&as_sauthors=%20%22LCH%20Br%C3%A5ten%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Djuric N</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Rajasekaran S</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Tangavel C</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Raveendran M</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Soundararajan DCR</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Nayagam SM</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Matchado MS</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Anand KSSV</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Shetty AP</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Kanna RM</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Influence of endplate avulsion
                  and Modic changes on the inflammation profile of herniated discs: a proteomic and bioinformatic
                  approach</span>. <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Eur
                  Spine J.</span> 2022;31(2):389-99.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Influence%20of%20endplate%20avulsion%20and%20Modic%20changes%20on%20the%20inflammation%20profile%20of%20herniated%20discs%3A%20a%20proteomic%20and%20bioinformatic%20approach&as_occt=title&as_sauthors=%20%22N%20Djuric%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Rajasekaran S</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Soundararajan DCR</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Nayagam SM</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Tangavel C</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Raveendran M</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Thippeswamy PB</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Djuric N</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Anand SV</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Shetty AP</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Kanna RM</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Modic changes are associated
                  with activation of intense inflammatory and host defense response pathways - molecular insights from
                  proteomic analysis of human intervertebral discs</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">Spine J.</span> 2022;22(1):19-38.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Modic%20changes%20are%20associated%20with%20activation%20of%20intense%20inflammatory%20and%20host%20defense%20response%20pathways%20-%20molecular%20insights%20from%20proteomic%20analysis%20of%20human%20intervertebral%20discs&as_occt=title&as_sauthors=%20%22S%20Rajasekaran%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Gilligan CJ</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Cohen SP</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Fischetti VA</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Hirsch JA</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Czaplewski LG</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Chronic low back pain,
                  bacterial infection and treatment with antibiotics</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">Spine J.</span> 2021;21(6):903-14.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Chronic%20low%20back%20pain%2C%20bacterial%20infection%20and%20treatment%20with%20antibiotics&as_occt=title&as_sauthors=%20%22CJ%20Gilligan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Ohrt-Nissen S</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Fritz BG</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Walbom J</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Kragh KN</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Bjarnsholt T</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Dahl B</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Manniche C</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Bacterial biofilms: a possible
                  mechanism for chronic infection in patients with lumbar disc herniation - a prospective
                  proof-of-concept study using fluorescence in situ hybridization</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">APMIS. APMIS.</span>
                2018;126(5):440-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bacterial%20biofilms%3A%20a%20possible%20mechanism%20for%20chronic%20infection%20in%20patients%20with%20lumbar%20disc%20herniation%20-%20a%20prospective%20proof-of-concept%20study%20using%20fluorescence%20in%20situ%20hybridization&as_occt=title&as_sauthors=%20%22S%20Ohrt-Nissen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Rajasekaran S</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Chitraa T</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Dilip Chand Raja S</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Raveendran M</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Sharon Miracle N</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Sri Vijayanand KS</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Ajoy Prasad S</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Rishi Mugesh K</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Subclinical infection can be an
                  initiator of inflammaging leading to degenerative disk disease: evidence from host-defense response
                  mechanisms</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">Eur Spine J.</span> 2021;30(9):2586-604.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Subclinical%20infection%20can%20be%20an%20initiator%20of%20inflammaging%20leading%20to%20degenerative%20disk%20disease%3A%20evidence%20from%20host-defense%20response%20mechanisms&as_occt=title&as_sauthors=%20%22S%20Rajasekaran%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Guan WJ</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Yuan JJ</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Li HM</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Gao YH</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Huang Y</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Chen CL</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Chen RC</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Zhong NS</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Proteobacteria community
                  compositions correlate with bronchiectasis severity</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">Int J Tuberc Lung Dis.</span>
                2018;22(9):1095-105.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Proteobacteria%20community%20compositions%20correlate%20with%20bronchiectasis%20severity&as_occt=title&as_sauthors=%20%22WJ%20Guan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Li H</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Xie J</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Guo X</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Yang G</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Cai B</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Liu J</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Yue M</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Tang Y</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Wang G</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Chen S</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Guo J</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Qi X</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Wang D</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Zheng H</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Liu W</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Yu H</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Wang C</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Zhu SJ</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Guo F</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;"><span data-id="strong_31"
                    class="annotation strong" style="position: unset;">Bifidobacterium</span></span><span
                  data-id="strong_31" class="annotation strong" style="position: unset;"> spp. and their metabolite
                  lactate protect against acute pancreatitis via inhibition of pancreatic and systemic inflammatory
                  responses</span>. <span data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Gut
                  Microbes.</span> 2022;14(1):2127456.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bifidobacterium%20spp.%20and%20their%20metabolite%20lactate%20protect%20against%20acute%20pancreatitis%20via%20inhibition%20of%20pancreatic%20and%20systemic%20inflammatory%20responses&as_occt=title&as_sauthors=%20%22H%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Henrick BM</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Rodriguez L</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Lakshmikanth T</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Pou C</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Henckel E</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Arzoomand A</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Olin A</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Wang J</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Mikes J</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Tan Z</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Chen Y</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Ehrlich AM</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Bernhardsson AK</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Mugabo CH</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Ambrosiani Y</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Gustafsson A</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Chew S</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Brown HK</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Prambs J</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Bohlin K</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Mitchell RD</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Underwood MA</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Smilowitz JT</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> German JB</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Frese SA</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Brodin P</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Bifidobacteria-mediated immune
                  system imprinting early in life</span>. <span data-id="emphasis_34" class="annotation emphasis"
                  style="position: unset;">Cell.</span> 2021;184(15):3884-3898.e11.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bifidobacteria-mediated%20immune%20system%20imprinting%20early%20in%20life&as_occt=title&as_sauthors=%20%22BM%20Henrick%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Kalyana Chakravarthy S</span>,
                <span data-id="annotation_267" class="annotation" style="position: unset;"> Jayasudha R</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Sai Prashanthi G</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Ali MH</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Sharma S</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Tyagi M</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Shivaji S</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Dysbiosis in the Gut Bacterial
                  Microbiome of Patients with Uveitis, an Inflammatory Disease of the Eye</span>. <span
                  data-id="emphasis_35" class="annotation emphasis" style="position: unset;">Indian J Microbiol.</span>
                2018;58(4):457-69.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dysbiosis%20in%20the%20Gut%20Bacterial%20Microbiome%20of%20Patients%20with%20Uveitis%2C%20an%20Inflammatory%20Disease%20of%20the%20Eye&as_occt=title&as_sauthors=%20%22S%20Kalyana%20Chakravarthy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Nomura S</span>, <span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Sukowati EW</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Shigeno Y</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Takahashi M</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Kato A</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Benno Y</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Yamashita F</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Mukai H</span>. <span
                  data-id="emphasis_37" class="annotation emphasis" style="position: unset;"><span data-id="strong_34"
                    class="annotation strong" style="position: unset;">Blautia coccoides</span></span><span
                  data-id="strong_34" class="annotation strong" style="position: unset;"> JCM1395<span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">T</span> Achieved
                  Intratumoral Growth with Minimal Inflammation: Evidence for Live Bacterial Therapeutic Potential by an
                  Optimized Sample Preparation and Colony PCR Method</span>. <span data-id="emphasis_38"
                  class="annotation emphasis" style="position: unset;">Pharmaceutics.</span> 2023;15(3):989.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Blautia%20coccoides%20JCM1395T%20Achieved%20Intratumoral%20Growth%20with%20Minimal%20Inflammation%3A%20Evidence%20for%20Live%20Bacterial%20Therapeutic%20Potential%20by%20an%20Optimized%20Sample%20Preparation%20and%20Colony%20PCR%20Method&as_occt=title&as_sauthors=%20%22S%20Nomura%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Liu X</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Mao B</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Gu J</span>, <span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Wu J</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Cui S</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Wang G</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Zhao J</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Zhang H</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Chen W</span>. <span
                  data-id="emphasis_40" class="annotation emphasis" style="position: unset;"><span data-id="strong_35"
                    class="annotation strong" style="position: unset;">Blautia</span></span><span data-id="strong_35"
                  class="annotation strong" style="position: unset;">-a new functional genus with potential probiotic
                  properties?</span><span data-id="emphasis_41" class="annotation emphasis" style="position: unset;">Gut
                  Microbes.</span> 2021;13(1):1-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Blautia-a%20new%20functional%20genus%20with%20potential%20probiotic%20properties%3F&as_occt=title&as_sauthors=%20%22X%20Liu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Wang X</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Ning Y</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Li C</span>, <span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Gong Y</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Huang R</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Hu M</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Poulet B</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Xu K</span>, <span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Zhao G</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Zhou R</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Lammi MJ</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Guo X</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Alterations in the gut
                  microbiota and metabolite profiles of patients with Kashin-Beck disease, an endemic osteoarthritis in
                  China</span>. <span data-id="emphasis_42" class="annotation emphasis" style="position: unset;">Cell
                  Death Dis.</span> 2021;12(11):1015.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Alterations%20in%20the%20gut%20microbiota%20and%20metabolite%20profiles%20of%20patients%20with%20Kashin-Beck%20disease%2C%20an%20endemic%20osteoarthritis%20in%20China&as_occt=title&as_sauthors=%20%22X%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Mustonen AM</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Lehmonen N</span>, <span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Paakkonen T</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Raekallio M</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Käkelä R</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Niemelä T</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Mykkänen A</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Sihvo SP</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Nieminen P</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Equine osteoarthritis modifies
                  fatty acid signatures in synovial fluid and its extracellular vesicles</span>. <span
                  data-id="emphasis_43" class="annotation emphasis" style="position: unset;">Arthritis Res Ther.</span>
                2023;25(1):39.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Equine%20osteoarthritis%20modifies%20fatty%20acid%20signatures%20in%20synovial%20fluid%20and%20its%20extracellular%20vesicles&as_occt=title&as_sauthors=%20%22AM%20Mustonen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Horas H</span><span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Nababan S</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Nishiumi S</span>, <span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Kawano Y</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Kobayashi T</span>, <span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Yoshida M</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Azuma T</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Adrenic acid as an inflammation
                  enhancer in non-alcoholic fatty liver disease</span>. <span data-id="emphasis_44"
                  class="annotation emphasis" style="position: unset;">Arch Biochem Biophys.</span>
                2017;623-624:64-75.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Adrenic%20acid%20as%20an%20inflammation%20enhancer%20in%20non-alcoholic%20fatty%20liver%20disease&as_occt=title&as_sauthors=%20%22H%20Horas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Mustonen AM</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Nieminen P</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Dihomo-<span
                    data-id="emphasis_46" class="annotation emphasis" style="position: unset;">γ</span>-Linolenic Acid
                  (20:3n-6)-Metabolism, Derivatives, and Potential Significance in Chronic Inflammation</span>. <span
                  data-id="emphasis_47" class="annotation emphasis" style="position: unset;">Int J Mol Sci.</span>
                2023;24(3):2116.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dihomo-%CE%B3-Linolenic%20Acid%20(20%3A3n-6)-Metabolism%2C%20Derivatives%2C%20and%20Potential%20Significance%20in%20Chronic%20Inflammation&as_occt=title&as_sauthors=%20%22AM%20Mustonen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Li R</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Xie L</span>, <span
                  data-id="annotation_322" class="annotation" style="position: unset;"> Li L</span>, <span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Chen X</span>, <span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Yao T</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Tian Y</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Li Q</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Wang K</span>, <span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Huang C</span>, <span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Li C</span>, <span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Li Y</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Zhou H</span>, <span
                  data-id="annotation_332" class="annotation" style="position: unset;"> Kaplowitz N</span>, <span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Jiang Y</span>, <span
                  data-id="annotation_334" class="annotation" style="position: unset;"> Chen P</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">The gut microbial metabolite,
                  3,4-dihydroxyphenylpropionic acid, alleviates hepatic ischemia/reperfusion injury <span
                    data-id="emphasis_49" class="annotation emphasis" style="position: unset;">via</span> mitigation of
                  macrophage pro-inflammatory activity in mice</span>. <span data-id="emphasis_50"
                  class="annotation emphasis" style="position: unset;">Acta Pharm Sin B.</span>
                2022;12(1):182-96.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20gut%20microbial%20metabolite%2C%203%2C4-dihydroxyphenylpropionic%20acid%2C%20alleviates%20hepatic%20ischemia%2Freperfusion%20injury%20via%20mitigation%20of%20macrophage%20pro-inflammatory%20activity%20in%20mice&as_occt=title&as_sauthors=%20%22R%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Liu Y</span>, <span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Sun J</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> Zhang W</span>, <span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Guo Z</span>, <span
                  data-id="annotation_340" class="annotation" style="position: unset;"> Ma Q</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Arachidonic acid metabolism in
                  health and disease</span>. <span data-id="emphasis_51" class="annotation emphasis"
                  style="position: unset;">MedComm (2020).</span> 2023;4(5):e363.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arachidonic%20acid%20metabolism%20in%20health%20and%20disease&as_occt=title&as_sauthors=%20%22Y%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Wang B</span>, <span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Wu L</span>, <span
                  data-id="annotation_343" class="annotation" style="position: unset;"> Chen J</span>, <span
                  data-id="annotation_344" class="annotation" style="position: unset;"> Dong L</span>, <span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Chen C</span>, <span
                  data-id="annotation_346" class="annotation" style="position: unset;"> Wen Z</span>, <span
                  data-id="annotation_347" class="annotation" style="position: unset;"> Hu J</span>, <span
                  data-id="annotation_348" class="annotation" style="position: unset;"> Fleming I</span>, <span
                  data-id="annotation_349" class="annotation" style="position: unset;"> Wang DW</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Metabolism pathways of
                  arachidonic acids: mechanisms and potential therapeutic targets</span>. <span data-id="emphasis_52"
                  class="annotation emphasis" style="position: unset;">Signal Transduct Target Ther.</span>
                2021;6(1):94.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Metabolism%20pathways%20of%20arachidonic%20acids%3A%20mechanisms%20and%20potential%20therapeutic%20targets&as_occt=title&as_sauthors=%20%22B%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_350" class="annotation" style="position: unset;"> Yu D</span>, <span
                  data-id="annotation_351" class="annotation" style="position: unset;"> Du J</span>, <span
                  data-id="annotation_352" class="annotation" style="position: unset;"> Pu X</span>, <span
                  data-id="annotation_353" class="annotation" style="position: unset;"> Zheng L</span>, <span
                  data-id="annotation_354" class="annotation" style="position: unset;"> Chen S</span>, <span
                  data-id="annotation_355" class="annotation" style="position: unset;"> Wang N</span>, <span
                  data-id="annotation_356" class="annotation" style="position: unset;"> Li J</span>, <span
                  data-id="annotation_357" class="annotation" style="position: unset;"> Chen S</span>, <span
                  data-id="annotation_358" class="annotation" style="position: unset;"> Pan S</span>, <span
                  data-id="annotation_359" class="annotation" style="position: unset;"> Shen B</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">The Gut Microbiome and
                  Metabolites Are Altered and Interrelated in Patients With Rheumatoid Arthritis</span>. <span
                  data-id="emphasis_53" class="annotation emphasis" style="position: unset;">Front Cell Infect
                  Microbiol.</span> 2022;11:763507.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Gut%20Microbiome%20and%20Metabolites%20Are%20Altered%20and%20Interrelated%20in%20Patients%20With%20Rheumatoid%20Arthritis&as_occt=title&as_sauthors=%20%22D%20Yu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_360" class="annotation" style="position: unset;"> Poli A</span>, <span
                  data-id="annotation_361" class="annotation" style="position: unset;"> Agostoni C</span>, <span
                  data-id="annotation_362" class="annotation" style="position: unset;"> Visioli F</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Dietary Fatty Acids and
                  Inflammation: Focus on the n-6 Series</span>. <span data-id="emphasis_54" class="annotation emphasis"
                  style="position: unset;">Int J Mol Sci.</span> 2023;24(5):4567.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dietary%20Fatty%20Acids%20and%20Inflammation%3A%20Focus%20on%20the%20n-6%20Series&as_occt=title&as_sauthors=%20%22A%20Poli%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_363" class="annotation" style="position: unset;"> Beccacece L</span>, <span
                  data-id="annotation_364" class="annotation" style="position: unset;"> Abondio P</span>, <span
                  data-id="annotation_365" class="annotation" style="position: unset;"> Bini C</span>, <span
                  data-id="annotation_366" class="annotation" style="position: unset;"> Pelotti S</span>, <span
                  data-id="annotation_367" class="annotation" style="position: unset;"> Luiselli D</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">The Link between Prostanoids
                  and Cardiovascular Diseases</span>. <span data-id="emphasis_55" class="annotation emphasis"
                  style="position: unset;">Int J Mol Sci.</span> 2023;24(4):4193.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Link%20between%20Prostanoids%20and%20Cardiovascular%20Diseases&as_occt=title&as_sauthors=%20%22L%20Beccacece%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_368" class="annotation" style="position: unset;"> Chen C</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Inhibiting degradation of
                  2-arachidonoylglycerol as a therapeutic strategy for neurodegenerative diseases</span>. <span
                  data-id="emphasis_56" class="annotation emphasis" style="position: unset;">Pharmacol Ther.</span>
                2023;244:108394.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Inhibiting%20degradation%20of%202-arachidonoylglycerol%20as%20a%20therapeutic%20strategy%20for%20neurodegenerative%20diseases&as_occt=title&as_sauthors=%20%22C%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_369" class="annotation" style="position: unset;"> Zaiss MM</span>, <span
                  data-id="annotation_370" class="annotation" style="position: unset;"> Jones RM</span>, <span
                  data-id="annotation_371" class="annotation" style="position: unset;"> Schett G</span>, <span
                  data-id="annotation_372" class="annotation" style="position: unset;"> Pacifici R</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">The gut-bone axis: how
                  bacterial metabolites bridge the distance</span>. <span data-id="emphasis_57"
                  class="annotation emphasis" style="position: unset;">J Clin Invest.</span>
                2019;129(8):3018-28.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20gut-bone%20axis%3A%20how%20bacterial%20metabolites%20bridge%20the%20distance&as_occt=title&as_sauthors=%20%22MM%20Zaiss%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_373" class="annotation" style="position: unset;"> Kjaer P</span>, <span
                  data-id="annotation_374" class="annotation" style="position: unset;"> Korsholm L</span>, <span
                  data-id="annotation_375" class="annotation" style="position: unset;"> Bendix T</span>, <span
                  data-id="annotation_376" class="annotation" style="position: unset;"> Sorensen JS</span>, <span
                  data-id="annotation_377" class="annotation" style="position: unset;"> Leboeuf-Yde C</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">Modic changes and their
                  associations with clinical findings</span>. <span data-id="emphasis_58" class="annotation emphasis"
                  style="position: unset;">Eur Spine J.</span> 2006;15(9):1312-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Modic%20changes%20and%20their%20associations%20with%20clinical%20findings&as_occt=title&as_sauthors=%20%22P%20Kjaer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_378" class="annotation" style="position: unset;"> Jensen TS</span>, <span
                  data-id="annotation_379" class="annotation" style="position: unset;"> Kjaer P</span>, <span
                  data-id="annotation_380" class="annotation" style="position: unset;"> Korsholm L</span>, <span
                  data-id="annotation_381" class="annotation" style="position: unset;"> Bendix T</span>, <span
                  data-id="annotation_382" class="annotation" style="position: unset;"> Sorensen JS</span>, <span
                  data-id="annotation_383" class="annotation" style="position: unset;"> Manniche C</span>, <span
                  data-id="annotation_384" class="annotation" style="position: unset;"> Leboeuf-Yde C</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">Predictors of new vertebral
                  endplate signal (Modic) changes in the general population</span>. <span data-id="emphasis_59"
                  class="annotation emphasis" style="position: unset;">Eur Spine J.</span>
                2010;19(1):129-35.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predictors%20of%20new%20vertebral%20endplate%20signal%20(Modic)%20changes%20in%20the%20general%20population&as_occt=title&as_sauthors=%20%22TS%20Jensen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_385" class="annotation" style="position: unset;"> Rajasekaran S</span>, <span
                  data-id="annotation_386" class="annotation" style="position: unset;"> Tangavel C</span>, <span
                  data-id="annotation_387" class="annotation" style="position: unset;"> Vasudevan G</span>, <span
                  data-id="annotation_388" class="annotation" style="position: unset;"> Easwaran M</span>, <span
                  data-id="annotation_389" class="annotation" style="position: unset;"> Muthurajan R</span>, <span
                  data-id="annotation_390" class="annotation" style="position: unset;"> K S SVA</span>, <span
                  data-id="annotation_391" class="annotation" style="position: unset;"> Murugan C</span>, <span
                  data-id="annotation_392" class="annotation" style="position: unset;"> Nayagam SM</span>, <span
                  data-id="annotation_393" class="annotation" style="position: unset;"> Kanna RM</span>, <span
                  data-id="annotation_394" class="annotation" style="position: unset;"> Shetty AP</span>. <span
                  data-id="strong_50" class="annotation strong" style="position: unset;">Bacteria in human lumbar discs
                  - subclinical infection or contamination? Metabolomic evidence for colonization, multiplication, and
                  cell-cell cross-talk of bacteria</span>. <span data-id="emphasis_60" class="annotation emphasis"
                  style="position: unset;">Spine J.</span> 2023;23(1):163-77.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bacteria%20in%20human%20lumbar%20discs%20-%20subclinical%20infection%20or%20contamination%3F%20Metabolomic%20evidence%20for%20colonization%2C%20multiplication%2C%20and%20cell-cell%20cross-talk%20of%20bacteria&as_occt=title&as_sauthors=%20%22S%20Rajasekaran%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_395" class="annotation" style="position: unset;"> Thompson KJ</span>, <span
                  data-id="annotation_396" class="annotation" style="position: unset;"> Dagher AP</span>, <span
                  data-id="annotation_397" class="annotation" style="position: unset;"> Eckel TS</span>, <span
                  data-id="annotation_398" class="annotation" style="position: unset;"> Clark M</span>, <span
                  data-id="annotation_399" class="annotation" style="position: unset;"> Reinig JW</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">Modic changes on MR images as
                  studied with provocative diskography: clinical relevance—a retrospective study of 2457 disks</span>.
                <span data-id="emphasis_61" class="annotation emphasis" style="position: unset;">Radiology.</span>
                2009;250(3):849-55.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Modic%20changes%20on%20MR%20images%20as%20studied%20with%20provocative%20diskography%3A%20clinical%20relevance%E2%80%94a%20retrospective%20study%20of%202457%20disks&as_occt=title&as_sauthors=%20%22KJ%20Thompson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="infographic_reference" class="annotation infographic_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node infographic" data-id="infographic" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Infographic</div><a class="link jbjs_tracking" jbjs_tracking_type="download"
                jbjs_tracking_data="{&quot;id&quot;:&quot;81ba9ba0-6708-465a-a5b7-84520d20a16c&quot;,&quot;type&quot;:&quot;infographic&quot;,&quot;topics&quot;:[&quot;sp&quot;]}"
                href="/php/content/content_api.php?op=download&rsuite_id=81ba9ba0-6708-465a-a5b7-84520d20a16c&type=infographic"
                jbjs_tracking_source="reader" style="position: unset;">Download</a>
              <div class="infographic-wrapper" style="position: unset;">
                <div class="viewer" style="position: unset;">
                  <div class="progress-bar" style="position: unset; width: 100%; display: none;"></div>
                  <div class="pages" style="position: unset;"><canvas class="page" width="337" height="189"></canvas>
                  </div>
                  <div class="toolbar" style="position: unset;"><button id="zoom-out" class=""
                      style="position: unset;">-</button><button id="zoom-in" class=""
                      style="position: unset;">+</button><button id="full-screen" class="" style="position: unset;">Full
                      screen ON</button></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;81ba9ba0-6708-465a-a5b7-84520d20a16c&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[&quot;sp&quot;]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=81ba9ba0-6708-465a-a5b7-84520d20a16c&type=supplement&name=10520"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fhub.jbjs.org%2Freader.php%3Frsuite_id%3D81ba9ba0-6708-465a-a5b7-84520d20a16c%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F20%2F1866%26topics%3Dsp"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">October 16, 2024;
                      106 (20): 1866</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00805</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">August 19, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_62"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopedics, The First People’s Hospital of Yunnan Province, Kunming, Yunnan,
                            People’s Republic of China</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_57"
                            class="annotation strong" style="position: unset;">A commentary by Jari Dahmen, MD, BSc, and
                            Gino M.M.J. Kerkhoffs, MD, PhD,</span> is linked to the online version of this article.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=dc6f4b6f-a9df-401b-8b3f-eb1fa03b4e40&native=1"
                              class="article-title" style="position: unset;">The Microbial Road Trip: Are We Nearing the
                              Golden Era of Orthopaedics?<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">Commentary on an article by Sunqi Nian, MM, et al.:
                                “Landscape of the Lumbar Cartilaginous End Plate Microbiota and Metabolites in Patients
                                with Modic Changes”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Dahmen,
                              Jari; Kerkhoffs, Gino M.M.J.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(20):e43 | October 16, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Authors. Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_11" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the terms of the <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank"
                            data-id="link_1" class="link" style="position: unset;">Creative Commons Attribution-Non
                            Commercial-No Derivatives License 4.0</a> (CC-BY-NC-ND), where it is permissible to download
                          and share the work provided it is properly cited. The work cannot be changed in any way or
                          used commercially without permission from the journal.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;81ba9ba0-6708-465a-a5b7-84520d20a16c&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;sp&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=81ba9ba0-6708-465a-a5b7-84520d20a16c&type=pdf&name=JBJS.23.00805.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=81ba9ba0-6708-465a-a5b7-84520d20a16c&type=pdf&name=JBJS.23.00805.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_12" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_58"
                            class="annotation strong" style="position: unset;">Disclosure:</span> Funding for this study
                          was provided by the following grants: The Yunnan Province Ten Thousand People Plan Young
                          Talent Program (YNWR-QNBJ-2019-184), The Yunnan Provincial Science and Technology
                          Department/Kunming Medical University Joint Project of Basic Research (202301AY070001-080),
                          The Reserve Talents for Academic and Technical Leaders of Middle-aged and Young People in
                          Yunnan Province (202305AC160069), The Kunming University of Science and Technology School of
                          Medicine Postgraduate Innovation Fund, and The Kunming University of Science and Technology
                          Postgraduate Talent Program (CA22369M099A). Additional support was provided by the Yunnan
                          Provincial Key Laboratory of Digital Orthopedics (202005AG070004), Yunnan Provincial Digital
                          Orthopedics Innovation Team (202105AE160015), and Yunnan Provincial Spinal Cord Disease
                          Clinical Medical Centre (ZX2022000101). The <span data-id="strong_59"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I102" target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I102</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;81ba9ba0-6708-465a-a5b7-84520d20a16c&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;sp&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=81ba9ba0-6708-465a-a5b7-84520d20a16c&type=zip&name=JBJS.23.00805.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sunqi Nian, MM<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4717-0982" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4717-0982</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Shaohua Tang, MM<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0002-7704-3829" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0002-7704-3829</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Shiqian Shen, MD, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0062-6831" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0062-6831</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Wenqiang Yue, MM<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0001-7483-760X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0001-7483-760X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Caiwang Zhao, MM<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0002-5801-1653" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0002-5801-1653</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tiannan Zou, MM<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0004-9758-1311" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0004-9758-1311</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Weichao Li, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9036-9028" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9036-9028</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Na Li, MD<span data-id="affiliation_reference_3"
                  class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2821-0547" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2821-0547</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sheng Lu, MD<span data-id="affiliation_reference_1"
                  class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:drlusheng@163.com" class="" style="position: unset;">drlusheng@163.com</a><span
                    class="" style="position: unset;">;</span><a href="mailto:jchen@kust.edu.cn" class=""
                    style="position: unset;">jchen@kust.edu.cn</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7532-8981" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7532-8981</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jiayu Chen, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:drlusheng@163.com" class="" style="position: unset;">drlusheng@163.com</a><span
                    class="" style="position: unset;">;</span><a href="mailto:jchen@kust.edu.cn" class=""
                    style="position: unset;">jchen@kust.edu.cn</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1119-6486" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1119-6486</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopedics, The First People’s Hospital of Yunnan Province,
                Kunming, Yunnan, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Anesthesia, Critical Care and Pain Medicine, Massachusetts
                General Hospital, Harvard Medical School, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Anesthesiology, 920th Hospital of the Joint Logistics Support
                Force, Kunming, Yunnan, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">Sunqi Nian, MM, and Shaohua Tang, MM, contributed
                        equally to this work.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 36189.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
