import type { Article } from "../../../../../types";

export const Article589: Article = {
	id: 589,
	rsuiteId: "923cbce0-01e5-4400-8995-2db3f8ada600",
	type: "scientific article",
	title:
		"Deep Learning-Based Computer-Aided Diagnosis of Osteochondritis Dissecans of the Humeral Capitellum Using Ultrasound Images",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=923cbce0-01e5-4400-8995-2db3f8ada600&type=jpeg&name=JBJS.23.01164f1",
	subSpecialties: ["shoulder_elbow"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Deep Learning-Based Computer-Aided Diagnosis of
                Osteochondritis Dissecans of the Humeral Capitellum Using Ultrasound Images</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Kenta
                      Takatsuji, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Yoshikazu
                      Kida, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 8 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_28" class="annotation strong"
                          style="position: unset;">Background:</span> Ultrasonography is used to diagnose
                        osteochondritis dissecans (OCD) of the humerus; however, its reliability depends on the
                        technical proficiency of the examiner. Recently, computer-aided diagnosis (CAD) using deep
                        learning has been applied in the field of medical science, and high diagnostic accuracy has been
                        reported. We aimed to develop a deep learning-based CAD system for OCD detection on ultrasound
                        images and to evaluate the accuracy of OCD detection using the CAD system.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Methods:</span> The CAD process comprises 2 steps: humeral capitellum
                        detection using an object-detection algorithm and OCD classification using an image
                        classification network. Four-directional ultrasound images of the elbow of the throwing arm of
                        196 baseball players (mean age, 11.2 years), including 104 players with normal findings and 92
                        with OCD, were used for training and validation. An external dataset of 20 baseball players (10
                        with normal findings and 10 with OCD) was used to evaluate the accuracy of the CAD system. A
                        confusion matrix and the area under the receiver operating characteristic curve (AUC) were used
                        to evaluate the system.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Results:</span> Clinical evaluation using the external dataset
                        resulted in high AUCs in all 4 directions: 0.969 for the anterior long axis, 0.966 for the
                        anterior short axis, 0.996 for the posterior long axis, and 0.993 for the posterior short axis.
                        The accuracy of OCD detection thus exceeded 0.9 in all 4 directions.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Conclusions:</span> We propose a deep learning-based CAD system to
                        detect OCD lesions on ultrasound images. The CAD system achieved high accuracy in all 4
                        directions of the elbow. This CAD system with a deep learning model may be useful for OCD
                        screening during medical checkups to reduce the probability of missing an OCD lesion.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Diagnostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level II</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Osteochondritis dissecans (OCD) of the humeral capitellum
                  is an osteochondral disorder that can occur in the throwing arm of young baseball players<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. The incidence of OCD of the humeral capitellum in
                  adolescent baseball players has been reported to be 1.3% to 3.4%<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">5</span></a>. The
                  major symptoms of OCD are elbow pain and restricted range of motion, which become more noticeable as
                  the disease progresses. Conservative treatment with discontinuation of sports activity is effective in
                  the early stage but may be prolonged as the lesion progresses, and surgical treatment may be indicated
                  for advanced or severe cases<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">7</span></a>. Thus, detecting OCD lesions in the early stage is essential
                  for developing a treatment strategy. However, patients with early lesions may not feel pain and often
                  continue competing without seeking medical attention until the lesion progresses and the patient
                  becomes aware of pain. Therefore, screening for early lesions is important for asymptomatic players.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Radiography, computed tomography (CT), magnetic resonance
                  imaging (MRI), and ultrasonography have been used to detect OCD lesions. Radiography is usually used
                  to diagnose OCD of the humeral capitellum; however, early lesions occasionally cannot be detected on
                  anteroposterior and lateral radiographs of the elbow<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">8</span></a>. CT
                  is appropriate for detecting the location and size of OCD lesions and intra-articular free bodies<a
                    href="" data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">9</span></a>. MRI is useful for detecting early lesions that cannot be
                  detected with radiography and for assessing the stability of OCD lesions<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">10</span></a>. However, performing these tests on every player is not
                  feasible because of radiation exposure, the time required for examinations, and medical costs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Ultrasonography can detect subtle changes in the early
                  stages of OCD without radiation exposure. In addition, ultrasonography is less time-consuming and
                  expensive; therefore, it is often used to screen for OCD<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">13</span></a>.
                  However, the reliability of ultrasonography depends on the technical proficiency of the examiner, and
                  intraobserver and interobserver variability should be considered<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">14</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">In recent years, computer-aided diagnosis (CAD) has been
                  widely adopted in medical science because advancements in deep learning (DL), a subfield of artificial
                  intelligence (AI), have enabled the creation of computer models that can accurately perform many
                  visual tasks, including object detection, localization, and classification<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">15</span></a>. Fujioka et al. used DL with a convolutional neural network
                  (CNN) to create a model that differentiated between benign and malignant breast masses on ultrasound
                  images and reported that their model showed equal or better diagnostic performance compared with
                  radiologists<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">16</span></a>. If
                  OCD lesions could be automatically detected using an AI model and ultrasonography, then even novice
                  examiners would be able to perform highly sensitive examinations. Furthermore, automatic detection
                  would reduce human resources, save time in medical checkups, and reduce unnecessary radiation
                  exposure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">We aimed to develop a DL-based OCD detection method to
                  automatically detect the presence or absence of OCD on ultrasound images. We constructed an OCD
                  detection model using an internal dataset and evaluated the clinical performance of the proposed CAD
                  system using an external dataset. Our CAD procedure comprised 2 steps: (1) detecting the humeral
                  capitellum of the elbow using the You Only Look Once (YOLO; Ultralytics) object-detection algorithm
                  and (2) classifying the region within the bounding box detected by YOLO in the first step as OCD or
                  normal using the 16-layer Visual Geometry Group (VGG16; Department of Engineering Science, University
                  of Oxford, England) image classification network. This CAD system was then applied to ultrasound
                  images obtained during medical checkups for OCD screening, and the performance of OCD detection during
                  medical checkups was evaluated. This article uses several machine learning terms, which are explained
                  in the Appendix.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">A total of 196 baseball players who participated in our
                  medical checkups or visited associated medical centers between 2014 and 2022 were recruited for this
                  DL model as an internal dataset. Participants with a history of fracture or elbow surgery were
                  excluded from the study. The mean age (and standard deviation) of all participants was 11.2 ± 2.1
                  years (range, 7 to 17 years); all patients were male. The diagnosis of OCD was made using radiographs
                  with the elbow of the throwing arm in the 45° flexion position; 104 players (mean age, 10.6 ± 1.8
                  years; range, 7 to 15 years) had normal findings, and 92 players (mean age, 11.8 ± 2.1 years; range, 7
                  to 17 years) were diagnosed with OCD. This study was approved by the Ethical Review Committee of Kyoto
                  Prefectural University of Medicine.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The ultrasound images for the 92 players with OCD were
                  classified as follows, according to the modified Ishizaki classification as reported by Okada et al.<a
                    href="" data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">17</span></a>: Pattern S (9 players; 9.8%), Pattern I (46 players; 50%),
                  Pattern II (30 players; 32.6%), and Pattern III (7 players; 7.6%) (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). These categories of OCD lesions were treated as 1 class in
                  this DL model.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01164f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=923cbce0-01e5-4400-8995-2db3f8ada600&type=jpeg&name=JBJS.23.01164f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_35"
                            class="annotation strong" style="position: unset;">Figs 1-A through 1-E</span> Posterior
                          long-axis ultrasound images of the capitellum used to classify OCD with use of the modified
                          Ishizaki classification. <span data-id="strong_36" class="annotation strong"
                            style="position: unset;">Fig. 1-A</span> Normal findings. <span data-id="strong_37"
                            class="annotation strong" style="position: unset;">Fig. 1-B</span> Pattern S, in which
                          lesions are confined to the subchondral bone surface (white arrow). <span data-id="strong_38"
                            class="annotation strong" style="position: unset;">Fig. 1-C</span> Pattern I, in which
                          lesions extend to the cancellous bone below the subchondral bone. <span data-id="strong_39"
                            class="annotation strong" style="position: unset;">Fig. 1-D</span> Pattern II, in which
                          boundary of the subchondral bone within the lesion deviates from the boundary elsewhere, but
                          the cartilage layer is preserved. <span data-id="strong_40" class="annotation strong"
                            style="position: unset;">Fig. 1-E</span> Pattern III, in which the continuity of the
                          cartilage layer is interrupted and a free body is formed (white arrowhead).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Ultrasonography was performed in 4 directions (anterior
                  long axis [AL], anterior short axis [AS], posterior long axis [PL], and posterior short axis [PS])
                  using a standardized method, and videos and still images in each direction were used in the DL model.
                  The videos were archived in AVI format, divided into frames, and then used as still images in BMP
                  format. Only images containing the humeral capitellum were analyzed. Duplicate images of the same
                  region were excluded, and ≥1 still images from different frames were used. The number of images used
                  for analysis varied among participants, with a minimum of 1 image and a maximum of 80 images for the
                  participants with normal findings and a minimum of 1 image and a maximum of 48 images for the
                  participants with OCD. The sizes of the images varied among participants and ranged from 400 to 500
                  pixels in height and width. The ultrasound images used in this experiment were captured by orthopaedic
                  surgeons with &gt;10 years of experience in OCD medical checkups using the 5 ultrasound systems listed
                  in <a href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>. Anterior scanning was performed with the transducer placed on
                  the lateral side of the elbow at 0° of extension. Posterior scanning was performed with the transducer
                  placed on the lateral side of the elbow at maximum flexion. Both anterior and posterior images of the
                  humeral capitellum were taken along the long and short axes, and images were collected from 4
                  directions (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Ultrasonographic Devices Used in the Present Study
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 414px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Name of Product</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Probe
                          Types</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Manufacturer</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">HI VISION Avius</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">14 or 15-MHz
                          high-frequency linear probe</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Hitachi Medical,
                          Japan</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Noblus</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">14-MHz high-frequency
                          linear probe</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Hitachi Medical,
                          Japan</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ACUSON P300</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">12-MHz high-frequency
                          linear probe</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Siemens Medical
                          Solutions, U.S.A.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ARIETTA Prologue</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">15-MHz high-frequency
                          linear probe</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Hitachi Medical,
                          Japan</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SNiBLE2</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">15-MHz high-frequency
                          linear probe</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">KONICA MINOLTA,
                          Japan</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01164f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=923cbce0-01e5-4400-8995-2db3f8ada600&type=jpeg&name=JBJS.23.01164f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;">Photographs made during the ultrasound procedure
                          (top row) and ultrasonographic images of the humeral capitellum in 4 directions for patients
                          with normal findings (middle row) and OCD (bottom row). In the patients with OCD, irregularity
                          and a double floor line were found on the humeral capitellum (white arrowheads). C =
                          capitellum, OCD = osteochondritis dissecans, R = radial head.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">To evaluate the clinical performance of the proposed CAD
                  system, the accuracy of OCD detection was assessed using external data that were collected during
                  medical checkups that were conducted on January 15, 2023. Ultrasound images for 10 participants who
                  had been diagnosed with OCD (mean age, 10.6 ± 1.5 years; range, 8 to 13 years) and 10 aged-matched
                  participants with normal findings (mean age, 10.7 ± 0.5 years; range, 10 to 11 years), from a total of
                  591 participants who participated in the medical checkup, were used for this evaluation. The OCD
                  lesions were classified as Pattern I in 8 participants and as Pattern II in 2 participants according
                  to the modified Ishizaki classification. Videos were captured by scanning the entire capitellum in 4
                  directions: AL, AS, PL, and PS. Orthopaedic surgeons with experience in baseball medical checkups
                  performed the examinations using ultrasonography (ARIETTA Prologue; Hitachi Medical) with a 15-MHz
                  linear probe. These videos were converted into still images, and 10 frames were selected at
                  approximately equal intervals from the lateral to the medial capitellum or from the proximal to the
                  distal capitellum. If an OCD lesion was present in a video, frames were selected to include the
                  lesion. Using the developed OCD detection model, we evaluated the OCD detection accuracy for 200
                  images per direction.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The proposed CAD process comprised 2 main steps. First,
                  the subchondral bone surface of the humeral capitellum was detected from the entire ultrasound image
                  using the YOLO object-detection algorithm. YOLO is a powerful and fast algorithm that can detect and
                  classify multiple objects in real time<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">18</span></a>.
                  The bounding boxes encompassing the detected subchondral bone surface of the humeral capitellum served
                  as regions of interest (ROIs). Second, the probability of OCD within the ROI was estimated using a
                  fine-tuned VGG16. The flow of the CAD system is illustrated in <a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 3</a>. First,
                  the YOLO object-detection algorithm was applied to detect the humeral capitellum in the entire
                  ultrasound image. In this study, YOLOv5s was used, which was pretrained on the COCO (Common Objects in
                  Context) dataset<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">19</span></a>. A
                  model to detect the humeral capitellum on ultrasound images of the elbow was constructed by transfer
                  learning. Annotation was performed using Roboflow (Roboflow, Inc.), an annotation tool with bounding
                  boxes that encompass the surface of the humeral capitellum in the image<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">20</span></a> (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>). Images
                  cropped with the predicted bounding boxes were evaluated as ROIs in the second step. The training
                  conditions of YOLOv5s were 50 epochs, a batch size of 128, stochastic gradient descent as the
                  optimization function, 0.01 as the learning rate, and 0.0 as the probability threshold for detection.
                  In Step 2, the OCD probabilities were estimated by classifying the ROI into 2 categories, OCD and
                  normal, using VGG16, a CNN model<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">21</span></a>.
                  The VGG16 model was pretrained on the ImageNet dataset and output probabilities for each of the 1,000
                  classes<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">22</span></a>. To
                  estimate the probability of OCD, the output layer is replaced by a layer that outputs the
                  probabilities of OCD and normal. The input size was 224 × 224, and the network structure included 5
                  blocks of 2 convolutional layers and max pooling. All of the coupling layers were modified, and a
                  dropout layer with a dropout rate of 0.5 was added. The convolution layer closest to the output was
                  retrained using the ultrasound images. The numbers of images for the OCD and normal cases were
                  unbalanced; therefore, the weights between the classes were adjusted to correct this imbalance.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01164f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=923cbce0-01e5-4400-8995-2db3f8ada600&type=jpeg&name=JBJS.23.01164f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Overview of the proposed CAD system. OCD =
                          osteochondritis dissecans, ROI = regions of interest, VGG16 = 16-layer visual geometry group,
                          YOLOv5s = You Only Look Once version 5 small.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01164f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=923cbce0-01e5-4400-8995-2db3f8ada600&type=jpeg&name=JBJS.23.01164f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_41"
                            class="annotation strong" style="position: unset;">Figs. 4-A through 4-D</span> Annotation
                          of the surface on the humeral capitellum in 4 directions: anterior long-axis view (<span
                            data-id="strong_42" class="annotation strong" style="position: unset;">Fig. 4-A</span>),
                          anterior short-axis view (<span data-id="strong_43" class="annotation strong"
                            style="position: unset;">Fig. 4-B</span>), posterior long-axis view (<span
                            data-id="strong_44" class="annotation strong" style="position: unset;">Fig. 4-C</span>),
                          posterior short-axis view (<span data-id="strong_45" class="annotation strong"
                            style="position: unset;">Fig. 4-D</span>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Evaluation
                Metrics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The performance of the OCD detection model was evaluated
                  using metrics derived from the confusion matrix, including accuracy (the percentage of correct answers
                  among all data), precision (the percentage of the data classified as OCD by the AI that represents
                  true OCD [i.e., positive predictive value]), recall (the percentage of the data representing true OCD
                  that is classified as OCD by the AI [i.e., sensitivity]), F1 score (the harmonic mean of accuracy and
                  recall), and area under the receiver operating characteristic (ROC) curve (AUC) (<a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 5</a>). The mean average precision (mAP), calculated using precision
                  and recall, was used to evaluate the performance of the YOLO object-detection model. In the
                  object-detection evaluation, true positive (TP), false positive (FP), and false negative were
                  determined using the intersection over union (IoU), which measures the overlap between the ground
                  truth and the predicted bounding boxes. In this study, mAP50 was calculated using an IoU threshold of
                  0.5.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.01164f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=923cbce0-01e5-4400-8995-2db3f8ada600&type=jpeg&name=JBJS.23.01164f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_46"
                            class="annotation strong" style="position: unset;">Fig. 5-A</span> Confusion matrix of the 4
                          combinations based on true and predicted classifications. OCD = osteochondritis dissecans.
                          <span data-id="strong_47" class="annotation strong" style="position: unset;">Fig. 5-B</span>
                          The accuracy of the trained model was calculated from the confusion matrix.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">In constructing the OCD detection model using the internal
                  data set, participant-based fivefold cross-validation was applied, in which the participants were
                  divided into 2 sets at a ratio of 4:1 for the training and validation data, respectively, with no
                  overlap between the 2 sets. As a result, the best-performing fold model was used for experiments using
                  the external dataset. In the YOLO model, the fold with the highest mAP50:95, that is, the mean of
                  mAP50 to mAP95, was selected. In the VGG16 model, the fold with the lowest validation loss, based on
                  the learning curve, was selected. In the evaluation of the CAD model using external data, appropriate
                  thresholds were set to the value at which the difference between the TP and FP rates was maximized on
                  the basis of the ROC curve, and each evaluation metric was calculated from the confusion matrix.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">The mAP values obtained through fivefold cross-validation
                  to evaluate the performance of the object-detection model during Step 1 are listed in <a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>. The mean mAP50 values of AL, AS, PL, and PS were 0.994,
                  0.989, 0.995, and 0.995, respectively. The validation losses for OCD detection in each direction using
                  the trained VGG16 model during Step 2 are presented in <a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>.
                  Furthermore, the AUCs for OCD detection in each direction using the external dataset were 0.969,
                  0.966, 0.996, and 0.993 for AL, AS, PL, and PS, respectively (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 6</a>). The
                  confusion matrix resulting from the application of this CAD system to the external dataset are shown
                  in <a href="" data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figure 7</a>. Each evaluation metric was calculated from the confusion
                  matrix (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Performance of the Object-Detection Model for
                          Detection of the Humeral Capitellum<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 379.749px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.961988);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;"></span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Fold-1</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Fold-2</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Fold-3</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Fold-4</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Fold-5</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Average</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td rowspan="2" style="position: unset;"><span class="text" style="position: unset;">AL</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">mAP50</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.995</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.995</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.992</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.995</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.995</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.994</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">mAP50:95</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.768</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.792</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.770</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.753</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.768</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.770</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="2" style="position: unset;"><span class="text" style="position: unset;">AS</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">mAP50</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.990</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.995</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.995</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.989</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.977</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.989</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">mAP50:95</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.617</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.654</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.688</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.684</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.563</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.641</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="2" style="position: unset;"><span class="text" style="position: unset;">PL</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">mAP50</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.995</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.995</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.995</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.995</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.995</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.995</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">mAP50:95</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.785</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.808</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.806</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.785</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.763</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.789</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="2" style="position: unset;"><span class="text" style="position: unset;">PS</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">mAP50</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.995</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.995</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.995</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.994</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.995</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.995</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">mAP50:95</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.846</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.829</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.880</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.864</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.886</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.861</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">AL = anterior long axis, AS = anterior short axis, PL = posterior long
                        axis, PS = posterior short axis, mAP = mean average precision.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Validation Loss of the Trained VGG16 Model in the 4
                          Directions<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 204px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Direction</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Fold-1</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Fold-2</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Fold-3</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Fold-4</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Fold-5</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">AL</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.045</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.194</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.174</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.061</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.055</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">AS</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.023</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.109</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.028</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.025</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.087</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PL</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.198</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.027</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.070</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.076</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.179</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PS</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.038</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.044</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.134</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.016</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.061</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">AL = anterior long axis, AS = anterior short axis, PL = posterior long
                        axis, PS = posterior short axis.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.23.01164f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=923cbce0-01e5-4400-8995-2db3f8ada600&type=jpeg&name=JBJS.23.01164f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;">Area under the curve (AUC) based on the receiver
                          operating characteristic (ROC) curve for the 4 directions.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 7</div>
                <div class="image-download" name="JBJS.23.01164f7" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=923cbce0-01e5-4400-8995-2db3f8ada600&type=jpeg&name=JBJS.23.01164f7"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;">Confusion matrix for OCD detection using the
                          proposed system with the medical checkup data. OCD = osteochondritis dissecans.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Diagnostic Accuracy of the 4-Directional Deep
                          Learning Model with the Medical Checkup Data<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 204px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Accuracy</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Precision</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Recall</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">F1
                          Score</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">AL</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.915</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.951</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.856</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.901</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">AS</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.920</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.821</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.986</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.896</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PL</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.970</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.970</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.970</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.970</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PS</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.960</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.979</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.940</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.959</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">AL = anterior long axis, AS = anterior short axis, PL = posterior long
                        axis, PS = posterior short axis.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">We developed a DL model to detect OCD lesions on
                  ultrasound images and achieved successful results. The CAD system achieved high accuracy in all 4 axes
                  of the elbow.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">In recent years, reports on DL involving ultrasound images
                  have been increasing. Image classification models have been utilized to diagnose breast cancer and
                  gastrointestinal tumors on ultrasound images, and high diagnostic performance has been reported<a
                    href="" data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">24</span></a>. Regarding the object-detection model, YOLO has been used
                  to automatically detect thyroid nodules using ultrasound images and is useful for extracting ROIs<a
                    href="" data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">26</span></a>. The proposed model combined 2 DL models for object
                  detection (YOLOv5s algorithm) and image classification (VGG16 network) and demonstrated high values
                  for the evaluation metrics and for the OCD detection rate. OCD detection accuracy might be improved by
                  restricting the ROI to the humeral capitellum<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">27</span></a>. In
                  the present study, a high OCD detection rate was achieved by limiting the ROI using an
                  object-detection algorithm. In addition, the results of OCD detection using the 4-directional DL model
                  for the clinical medical checkup data showed that the AUC was high in all directions: 0.969 for AL,
                  0.966 for AS, 0.996 for PL, and 0.993 for PS. In cases in which the size of the OCD lesion is small,
                  the risk of missing OCD lesions is reduced by conducting a 4-directional assessment rather than
                  evaluating the lesion in only 1 direction. In addition, the bone contour at the edge of the capitellum
                  may be unclear when the probe is not applied perpendicular to the bone surface, which may lead to
                  false-positive results. However, performing both long-axis and short-axis scans enables proper
                  assessment of the presence or absence of OCD lesions. This CAD system demonstrated a high OCD
                  detection rate in all 4 directions and would likely be a useful screening system for OCD lesions.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Shinohara et al., in a previous study involving the use of
                  a DL model for the detection of OCD of the humeral capitellum, reported that the accuracy of OCD
                  detection ranged from 0.818 to 0.872<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">28</span></a>.
                  The DL model performed well, but the small number of participants and the quality of the images taken
                  were mentioned as limitations. In the present study, we increased the number of participants with OCD,
                  and only experienced examiners conducted ultrasonography to reduce variations in image quality. The
                  accuracy was satisfactory, exceeding 0.90 in all directions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Medical checkups for OCD screening are conducted
                  throughout Japan, and ultrasonography is used for OCD detection. However, diagnostic accuracy depends
                  on the technical proficiency of the examiner. In addition, the frequency of medical checkups is
                  limited to once or twice a year because of the cost of the medical checkups and human resources. With
                  use of this CAD system, even novice examiners can detect OCD with high accuracy, which may reduce the
                  human resources needed and save time during the medical examinations. This would consequently increase
                  the number of opportunities for screening and might contribute to the early detection of OCD lesions.
                  To our knowledge, this CAD system is the first to present a 4-directional DL model for OCD lesions and
                  to demonstrate high values for the evaluation metrics and for the OCD detection rates.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">The present study has several limitations. First, the
                  number of images used in the study was small compared with that of a typical CAD model. Furthermore,
                  the sample size of the external dataset was dependent on resource constraints due to the limited
                  number of participants with OCD detectable in an annual medical checkup. This study provided an
                  estimate of the variance of OCD detection accuracy. To clarify the efficacy of this system, a
                  validation study with prior power calculation and sample size determination is necessary in future
                  research. Second, all patients with OCD had lesions in the central region of the capitellum, and
                  patients with OCD localized to the lateral wall of the capitellum were not evaluated. Third, all of
                  the OCD patterns were included in the DL model to increase the number of OCD images. However, the
                  accuracy might be increased by recruiting only images of early lesions, in order to better detect OCD
                  lesions at an early stage in young baseball players. Furthermore, the study design was validated on
                  previously saved images, and the accuracy of OCD detection using real-time ultrasonography, including
                  probe manipulation techniques, was not evaluated. Further research is needed to assess the diagnostic
                  accuracy of OCD detection using real-time ultrasonography with the assistance of the CAD system.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">The DL model on standard 4-directional ultrasound images
                  of the capitellum demonstrated high values for the OCD detection rate. Combining the 2 DL models
                  (object detection and image classification) yielded high accuracy for OCD detection. This CAD system
                  with a DL model may be useful for OCD screening during medical checkups to reduce the probability of
                  missing OCD.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_4" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I14" target="_blank" data-id="link_5" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I14</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Maruyama M</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Takahara M</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Satake H</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Diagnosis and treatment of
                  osteochondritis dissecans of the humeral capitellum</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">J Orthop Sci.</span> 2018
                Mar;23(2):213-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagnosis%20and%20treatment%20of%20osteochondritis%20dissecans%20of%20the%20humeral%20capitellum&as_occt=title&as_sauthors=%20%22M%20Maruyama%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Iwase T</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Ikate T</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Kasbiwagushi S</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Elbow osteochondral lesion in
                  young baseball players</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">J Pediatr Surg.</span> 1996;28:703-10. [Japanese].</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Elbow%20osteochondral%20lesion%20in%20young%20baseball%20players&as_occt=title&as_sauthors=%20%22T%20Iwase%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Harada M</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Takahara M</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Sasaki J</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Mura N</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Ito T</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Ogino T</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Using sonography for the early
                  detection of elbow injuries among young baseball players</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">AJR Am J Roentgenol.</span> 2006
                Dec;187(6):1436-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Using%20sonography%20for%20the%20early%20detection%20of%20elbow%20injuries%20among%20young%20baseball%20players&as_occt=title&as_sauthors=%20%22M%20Harada%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Kida Y</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Morihara T</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Kotoura Y</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Hojo T</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Tachiiri H</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Sukenari T</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Iwata Y</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Furukawa R</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Oda R</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Arai Y</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Fujiwara H</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Kubo T</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Prevalence and Clinical
                  Characteristics of Osteochondritis Dissecans of the Humeral Capitellum Among Adolescent Baseball
                  Players</span>. <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Am J
                  Sports Med.</span> 2014 Aug;42(8):1963-71.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20and%20Clinical%20Characteristics%20of%20Osteochondritis%20Dissecans%20of%20the%20Humeral%20Capitellum%20Among%20Adolescent%20Baseball%20Players&as_occt=title&as_sauthors=%20%22Y%20Kida%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Otoshi K</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Kikuchi S</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Kato K</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Sato R</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Igari T</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Kaga T</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Konno S</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Age-specific prevalence and
                  clinical characteristics of humeral medial epicondyle apophysitis and osteochondritis dissecans:
                  Ultrasonographic assessment of 4249 players</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Orthop J Sports Med.</span> 2017 May
                24;5(5):2325967117707703.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Age-specific%20prevalence%20and%20clinical%20characteristics%20of%20humeral%20medial%20epicondyle%20apophysitis%20and%20osteochondritis%20dissecans%3A%20Ultrasonographic%20assessment%20of%204249%20players&as_occt=title&as_sauthors=%20%22K%20Otoshi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Minami M</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Nakashita K</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Ishii S</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Usui M</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Muramatsu I</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Ogino T</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Fukuda K</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Sugawara M</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Twenty-five cases of
                  osteochondritis dissecans of the elbow</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Rinsho Seikei Geka.</span> 1979;14(8):805-10. [Japanese].</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Twenty-five%20cases%20of%20osteochondritis%20dissecans%20of%20the%20elbow&as_occt=title&as_sauthors=%20%22M%20Minami%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Matsuura T</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Kashiwaguchi S</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Iwase T</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Takeda Y</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Yasui N</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Conservative treatment for
                  osteochondrosis of the humeral capitellum</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2008
                May;36(5):868-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Conservative%20treatment%20for%20osteochondrosis%20of%20the%20humeral%20capitellum&as_occt=title&as_sauthors=%20%22T%20Matsuura%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Bradley JP</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Petrie RS</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Osteochondritis dissecans of the
                  humeral capitellum. Diagnosis and treatment</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Clin Sports Med.</span> 2001
                Jul;20(3):565-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteochondritis%20dissecans%20of%20the%20humeral%20capitellum.%20Diagnosis%20and%20treatment&as_occt=title&as_sauthors=%20%22JP%20Bradley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Takahara M</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Mura N</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Sasaki J</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Harada M</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Ogino T</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Classification, treatment, and
                  outcome of osteochondritis dissecans of the humeral capitellum. Surgical technique</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2008 Mar;90 2:47-62.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Classification%2C%20treatment%2C%20and%20outcome%20of%20osteochondritis%20dissecans%20of%20the%20humeral%20capitellum.%20Surgical%20technique&as_occt=title&as_sauthors=%20%22M%20Takahara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Takahara M</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Shundo M</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Kondo M</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Suzuki K</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Nambu T</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Ogino T</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Early detection of
                  osteochondritis dissecans of the capitellum in young baseball players. Report of three cases</span>.
                <span data-id="emphasis_10" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 1998 Jun;80(6):892-7.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=445345" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Matsuura T</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Suzue N</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Iwame T</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Nishio S</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Sairyo K</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Prevalence of Osteochondritis
                  Dissecans of the Capitellum in Young Baseball Players: Results Based on Ultrasonographic
                  Findings</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">Orthop J Sports Med.</span> 2014 Aug 12;2(8):2325967114545298.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20of%20Osteochondritis%20Dissecans%20of%20the%20Capitellum%20in%20Young%20Baseball%20Players%3A%20Results%20Based%20on%20Ultrasonographic%20Findings&as_occt=title&as_sauthors=%20%22T%20Matsuura%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Yoshizuka M</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Sunagawa T</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Nakashima Y</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Shinomiya R</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Masuda T</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Makitsubo M</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Adachi N</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Comparison of sonography and
                  MRI in the evaluation of stability of capitellar osteochondritis dissecans</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J Clin Ultrasound.</span>
                2018 May;46(4):247-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20sonography%20and%20MRI%20in%20the%20evaluation%20of%20stability%20of%20capitellar%20osteochondritis%20dissecans&as_occt=title&as_sauthors=%20%22M%20Yoshizuka%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Iwame T</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Matsuura T</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Suzue N</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Tamaki S</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Yokoyama K</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Sairyo K</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Two-year follow-up study of
                  subchondral surface irregularities of the capitellum on ultrasonography in baseball players under the
                  age of 10 years</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Skeletal Radiol.</span> 2017 Nov;46(11):1499-505.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Two-year%20follow-up%20study%20of%20subchondral%20surface%20irregularities%20of%20the%20capitellum%20on%20ultrasonography%20in%20baseball%20players%20under%20the%20age%20of%2010%20years&as_occt=title&as_sauthors=%20%22T%20Iwame%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Ohrndorf S</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Naumann L</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Grundey J</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Scheel T</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Scheel AK</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Werner C</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Backhaus M</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Is musculoskeletal
                  ultrasonography an operator-dependent method or a fast and reliably teachable diagnostic tool?
                  Interreader agreements of three ultrasonographers with different training levels</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Int J Rheumatol.</span>
                2010;2010:164518.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20musculoskeletal%20ultrasonography%20an%20operator-dependent%20method%20or%20a%20fast%20and%20reliably%20teachable%20diagnostic%20tool%3F%20Interreader%20agreements%20of%20three%20ultrasonographers%20with%20different%20training%20levels&as_occt=title&as_sauthors=%20%22S%20Ohrndorf%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> LeCun Y</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Bengio Y</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Hinton G</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Deep learning</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Nature.</span> 2015 May
                28;521(7553):436-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Deep%20learning&as_occt=title&as_sauthors=%20%22Y%20LeCun%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Fujioka T</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Kubota K</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Mori M</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Kikuchi Y</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Katsuta L</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Kasahara M</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Oda G</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Ishiba T</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Nakagawa T</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Tateishi U</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Distinction between benign and
                  malignant breast masses at breast ultrasound using deep learning method with convolutional neural
                  network</span>. <span data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Jpn J
                  Radiol.</span> 2019 Jun;37(6):466-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Distinction%20between%20benign%20and%20malignant%20breast%20masses%20at%20breast%20ultrasound%20using%20deep%20learning%20method%20with%20convolutional%20neural%20network&as_occt=title&as_sauthors=%20%22T%20Fujioka%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Okada C</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Kashiwaguchi S</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Kamitani T</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Mishima S</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Miyatake K</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Sakaguchi T</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Ishizaki K</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Relationship between the
                  location and staging of osteochondritis dissecans of the humeral capitellum using ultrasonographic
                  examination</span>. <span data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J
                  Jpn Soc Clin Sports Med.</span> 2017;25(1):38-44. [Japanese].</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Relationship%20between%20the%20location%20and%20staging%20of%20osteochondritis%20dissecans%20of%20the%20humeral%20capitellum%20using%20ultrasonographic%20examination&as_occt=title&as_sauthors=%20%22C%20Okada%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Redmon J</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Divvala S</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Girshick R</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Farhadi A</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">You Only Look Once: Unified,
                  real-time object detection</span>. Proceedings of the IEEE Conference on Computer Vision and Pattern
                Recognition (CVPR). 2016. p 779-88. <a
                  href="https://www.cv-foundation.org/openaccess/content_cvpr_2016/html/Redmon_You_Only_Look_CVPR_2016_paper.html"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.cv-foundation.org/openaccess/content_cvpr_2016/html/Redmon_You_Only_Look_CVPR_2016_paper.html</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=You%20Only%20Look%20Once%3A%20Unified%2C%20real-time%20object%20detection&as_occt=title&as_sauthors=%20%22J%20Redmon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Lin TY</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Maire M</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Belongie S</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Hays J</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Perona P</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Ramanan D</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Dollár P</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Zitnick CL</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Microsoft COCO: Common Objects
                  in Context</span>. In: <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Computer Vision – ECCV 2014.</span> Springer International; 2014. p
                740-55.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Microsoft%20COCO%3A%20Common%20Objects%20in%20Context&as_occt=title&as_sauthors=%20%22TY%20Lin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Dwyer B</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Nelson J</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Solawetz J</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">Roboflow (Version
                  1.0).</span> 2022. <a href="https://roboflow.com" target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://roboflow.com</a>.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Simonyan K</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Zisserman A</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Very Deep Convolutional
                  Networks for Large-Scale Image Recognition</span>. Proceedings of the International Conference on
                Learning Representations (ICLR). 2015.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Very%20Deep%20Convolutional%20Networks%20for%20Large-Scale%20Image%20Recognition&as_occt=title&as_sauthors=%20%22K%20Simonyan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Deng J</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Dong W</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Socher R</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Li LJ</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Li K</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Fei-Fei L</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">ImageNet: A large-scale
                  hierarchical image database</span>. In: 2009 IEEE Conference on Computer Vision and Pattern
                Recognition. 2009. p 248-55.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=ImageNet%3A%20A%20large-scale%20hierarchical%20image%20database&as_occt=title&as_sauthors=%20%22J%20Deng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Zhuang Z</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Kang Y</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Joseph Raj AN</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Yuan Y</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Ding W</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Qiu S</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Breast ultrasound lesion
                  classification based on image decomposition and transfer learning</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">Med Phys.</span> 2020
                Dec;47(12):6257-69.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Breast%20ultrasound%20lesion%20classification%20based%20on%20image%20decomposition%20and%20transfer%20learning&as_occt=title&as_sauthors=%20%22Z%20Zhuang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Hirai K</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Kuwahara T</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Furukawa K</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Kakushima N</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Furune S</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Yamamoto H</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Marukawa T</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Asai H</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Matsui K</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Sasaki Y</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Sakai D</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Yamada K</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Nishikawa T</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Hayashi D</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Obayashi T</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Komiyama T</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Ishikawa E</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Sawada T</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Maeda K</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Yamamura T</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Ishikawa T</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Ohno E</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Nakamura M</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Kawashima H</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Ishigami M</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Fujishiro M</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Artificial intelligence-based
                  diagnosis of upper gastrointestinal subepithelial lesions on endoscopic ultrasonography images</span>.
                <span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">Gastric Cancer.</span>
                2022 Mar;25(2):382-91.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Artificial%20intelligence-based%20diagnosis%20of%20upper%20gastrointestinal%20subepithelial%20lesions%20on%20endoscopic%20ultrasonography%20images&as_occt=title&as_sauthors=%20%22K%20Hirai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Fu R</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Yang H</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Zeng D</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Yang S</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Luo P</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Yang Z</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Teng H</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Ren J</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">PTC-MAS: A Deep Learning-Based
                  Preoperative Automatic Assessment of Lymph Node Metastasis in Primary Thyroid Cancer</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Diagnostics (Basel).</span>
                2023 May 12;13(10):1723.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=PTC-MAS%3A%20A%20Deep%20Learning-Based%20Preoperative%20Automatic%20Assessment%20of%20Lymph%20Node%20Metastasis%20in%20Primary%20Thyroid%20Cancer&as_occt=title&as_sauthors=%20%22R%20Fu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Zhou S</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Qiu Y</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Han L</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Liao G</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Zhuang Y</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Ma B</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Luo Y</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Lin J</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Chen K</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">A lightweight network for
                  automatic thyroid nodules location and recognition with high speed and accuracy in ultrasound
                  images</span>. <span data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J Xray
                  Sci Technol.</span> 2022;30(5):967-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20lightweight%20network%20for%20automatic%20thyroid%20nodules%20location%20and%20recognition%20with%20high%20speed%20and%20accuracy%20in%20ultrasound%20images&as_occt=title&as_sauthors=%20%22S%20Zhou%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Sasaki K</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Fujita D</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Takatsuji K</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Kotoura Y</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Minami M</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Kobayashi Y</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Sukenari T</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Kida Y</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Takahashi K</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Kobashi S</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Deep learning-based
                  osteochondritis dissecans detection in ultrasound images with humeral capitellum localization</span>.
                <span data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Int J Comput Assist
                  Radiol Surg [Internet].</span> 2024 Jan 17; Available from: .</span>
              <div class="doi" style="position: unset;"><b class="" style="position: unset;">DOI: </b><a
                  href="http://dx.doi.org/10.1007/s11548-023-03040-8" target="_new" class=""
                  style="position: unset;">http://dx.doi.org/10.1007/s11548-023-03040-8</a></div><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Deep%20learning-based%20osteochondritis%20dissecans%20detection%20in%20ultrasound%20images%20with%20humeral%20capitellum%20localization&as_occt=title&as_sauthors=%20%22K%20Sasaki%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Shinohara I</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Yoshikawa T</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Inui A</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Mifune Y</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Nishimoto H</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Mukohara S</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Kato T</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Furukawa T</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Tanaka S</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Kusunose M</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Hoshino Y</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Matsushita T</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Kuroda R</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Degree of Accuracy With Which
                  Deep Learning for Ultrasound Images Identifies Osteochondritis Dissecans of the Humeral
                  Capitellum</span>. <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Am
                  J Sports Med.</span> 2023 Feb;51(2):358-66.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Degree%20of%20Accuracy%20With%20Which%20Deep%20Learning%20for%20Ultrasound%20Images%20Identifies%20Osteochondritis%20Dissecans%20of%20the%20Humeral%20Capitellum&as_occt=title&as_sauthors=%20%22I%20Shinohara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;923cbce0-01e5-4400-8995-2db3f8ada600&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=923cbce0-01e5-4400-8995-2db3f8ada600&type=supplement&name=12501"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D923cbce0-01e5-4400-8995-2db3f8ada600%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="authorinsights_reference"
            class="annotation authorinsights_reference resource-reference" style="position: unset;"></a>
          <div class="content-node authorinsights" data-id="authorinsights" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Author Insights</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6364510114112&quot;,&quot;type&quot;:&quot;author_insights&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;923cbce0-01e5-4400-8995-2db3f8ada600&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_3" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6364510114112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-0 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="6364510114112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6364510114112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6364510114112" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_3_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/9c33f785-56b7-4922-8560-789e3133c5cb/5ef9d41c-d658-4c49-8fd1-b6ae0c7f27ad/1280x720/match/image.jpg"
                        src="blob:https://www.jbjs.org/fbb1faa3-0e23-4daf-ace3-7ece32bbed90">
                        <track kind="metadata" label="segment-metadata">
                        <track kind="subtitles" label="English" srclang="en" default="" id="English">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/9c33f785-56b7-4922-8560-789e3133c5cb/5ef9d41c-d658-4c49-8fd1-b6ae0c7f27ad/1280x720/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">2:35</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 2:35">
                            <div class="vjs-load-progress"><span class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">0%</span></span></div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">2:35</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_3_component_293">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_3_component_293"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden vjs-disabled">
                          <button
                            class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button vjs-disabled"
                            type="button" aria-disabled="true" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false" disabled="disabled"><span class="vjs-icon-placeholder"
                              aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu vjs-hidden">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content" role="menu">
                              <li class="vjs-menu-item" tabindex="-1" role="menuitemradio" aria-disabled="false"
                                aria-checked="false"><span class="vjs-menu-item-text">captions off</span><span
                                  class="vjs-control-text" aria-live="polite"></span></li>
                              <li class="vjs-menu-item vjs-subtitles-menu-item vjs-selected" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">English</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button vjs-disabled"
                          type="button" title="Picture-in-Picture" aria-disabled="true" disabled="disabled"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_511_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_511_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_3_component_517_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_517_description">Beginning of dialog window. Escape will cancel and
                          close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_3_component_517">Text</legend><label
                                id="captions-foreground-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517">
                                <option id="captions-foreground-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517">
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_3_component_517">Background</legend><label
                                id="captions-background-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517">
                                <option id="captions-background-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517">
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_3_component_517">Window</legend><label
                                id="captions-window-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517">
                                <option id="captions-window-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517">
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Transparent" value="0"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_3_component_517" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_3_component_517">
                                <option id="captions-font-size-vjs_video_3_component_517-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_3_component_517" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_3_component_517">
                                <option id="vjs_video_3_component_517-None" value="none"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-None">None
                                </option>
                                <option id="vjs_video_3_component_517-Raised" value="raised"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Raised">Raised
                                </option>
                                <option id="vjs_video_3_component_517-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_3_component_517-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Uniform">Uniform
                                </option>
                                <option id="vjs_video_3_component_517-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_3_component_517" class="">Font Family</legend>
                              <select aria-labelledby="captions-font-family-vjs_video_3_component_517">
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_3_component_517-SmallCaps" value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_705_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_705_description">This is a modal window. This modal can be closed by
                          pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 4, 2024;
                      106 (23): 2196</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01164</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">May 14, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_26"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopaedics, Graduate School of Medical Science, Kyoto Prefectural University
                            of Medicine, Kyoto, Japan, and the Graduate School of Engineering, University of Hyogo,
                            Hyogo, Japan</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;923cbce0-01e5-4400-8995-2db3f8ada600&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=923cbce0-01e5-4400-8995-2db3f8ada600&type=pdf&name=JBJS.23.01164.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=923cbce0-01e5-4400-8995-2db3f8ada600&type=pdf&name=JBJS.23.01164.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_33"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This study was
                          supported in part by a research project grant from the Japan Elbow Society (JES202001). The
                          <span data-id="strong_34" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I13" target="_blank" data-id="link_3" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I13</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;923cbce0-01e5-4400-8995-2db3f8ada600&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=923cbce0-01e5-4400-8995-2db3f8ada600&type=zip&name=JBJS.23.01164.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kenta Takatsuji, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1466-0160" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1466-0160</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yoshikazu Kida, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:kida@koto.kpu-m.ac.jp" class=""
                    style="position: unset;">kida@koto.kpu-m.ac.jp</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6604-3797" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6604-3797</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kenta Sasaki, ME<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0008-9095-441X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0008-9095-441X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Daisuke Fujita, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0008-7676-0053" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0008-7676-0053</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yusuke Kobayashi, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0005-8485-6782" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0005-8485-6782</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tsuyoshi Sukenari, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0007-7476-458X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0007-7476-458X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yoshihiro Kotoura, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3779-9691" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3779-9691</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Masataka Minami, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5411-3498" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5411-3498</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Syoji Kobashi, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3659-4114" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3659-4114</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kenji Takahashi, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedics, Graduate School of Medical Science, Kyoto
                Prefectural University of Medicine, Kyoto, Japan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Graduate School of Engineering, University of Hyogo, Hyogo, Japan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 25656.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
