/**
 * @generated SignedSource<<428b8681798a941071664d64cbbcf65a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */

// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BundleType = "EXAM" | "PRACTICE_TEST" | "STUDY_PACK" | "%future added value";
export type FetchActiveCodesQuery$variables = {
  input: string;
  tenantId: string;
};
export type FetchActiveCodesQuery$data = {
  readonly getActiveCodes: ReadonlyArray<{
    readonly bundle: {
      readonly bundleType: BundleType | null;
      readonly features: ReadonlyArray<string | null> | null;
      readonly icon: {
        readonly url: any;
      } | null;
      readonly id: string;
      readonly name: string;
    };
    readonly codes: ReadonlyArray<string> | null;
  }> | null;
};
export type FetchActiveCodesQuery = {
  response: FetchActiveCodesQuery$data;
  variables: FetchActiveCodesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  },
  {
    "kind": "Variable",
    "name": "tenantId",
    "variableName": "tenantId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "features",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bundleType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "codes",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchActiveCodesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CodeDetails",
        "kind": "LinkedField",
        "name": "getActiveCodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Bundle",
            "kind": "LinkedField",
            "name": "bundle",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "icon",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FetchActiveCodesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CodeDetails",
        "kind": "LinkedField",
        "name": "getActiveCodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Bundle",
            "kind": "LinkedField",
            "name": "bundle",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "icon",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f742a66b55c3b0c665ff1858a5d61bb2",
    "id": null,
    "metadata": {},
    "name": "FetchActiveCodesQuery",
    "operationKind": "query",
    "text": "query FetchActiveCodesQuery(\n  $tenantId: String!\n  $input: String!\n) {\n  getActiveCodes(tenantId: $tenantId, input: $input) {\n    bundle {\n      id\n      name\n      icon {\n        url\n        id\n      }\n      features\n      bundleType\n    }\n    codes\n  }\n}\n"
  }
};
})();

(node as any).hash = "bcc04fe111ddae2d58a7a55e2fcd38e8";

export default node;
