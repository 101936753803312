import type { Article } from "../../../../../types";

export const Article641: Article = {
	id: 641,
	rsuiteId: "64c9460c-78b1-4c68-b1a1-81d73cb09e25",
	type: "guest editorial",
	title: "What's New in Adult Reconstructive Knee Surgery",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/641.jpeg",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">What’s New in Adult Reconstructive Knee Surgery</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">T. David Luo,
                      MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Samuel Rosas,
                      MD, PhD, MBA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 1 more
                      contributor</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">In this Guest Editorial, we review and summarize the key
                  findings from the most noteworthy and impactful studies relating to adult reconstructive knee surgery
                  across different areas of research over the past year. This review includes studies of various Levels
                  of Evidence, but special attention was paid to higher Levels of Evidence and award-winning
                  publications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Health-Care
                Policy and Economics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">As orthopaedic surgeons strive to provide improved patient
                  outcomes, there remains a wide disparity in the equity of health-care access for the most vulnerable
                  patient populations. There is an increased recognition of adverse outcomes based on social
                  determinants of health (SDOH) and of the need for policy and health-care system changes to bridge the
                  gap in patient access and the quality of care that they receive<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. The International Classification of Diseases, Tenth
                  Revision (ICD-10) contains Z code categories (Z55-Z65) to document the presence of SDOH. Although
                  these codes currently lack financial incentives for their use, they provide data for tracking and
                  research purposes to identify potential areas for targeted interventions<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">1</span></a>. In a large database study, Z codes were used to identify
                  matched cohorts of patients with and without SDOH. After the propensity matching of 207,844 patients,
                  the authors observed that patients with disparities in SDOH had higher odds of readmissions and
                  complications within 90 days and higher rates of revision surgery and periprosthetic joint infection
                  (PJI) within 2 years following total knee arthroplasty (TKA) compared with the control group<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">2</span></a>. One specific socioeconomic metric, the Social Vulnerability
                  Index (SVI), uses 16 variables from the U.S. Census data to identify at-risk communities<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a>. Two
                  database studies separately demonstrated that higher SVI was associated with increased length of stay,
                  readmissions, and complications after TKA<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">3</span></a>,
                  whereas SVI subthemes of household composition and disability were risk factors for 90-day
                  complications following TKA<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">4</span></a>.
                  These studies encourage a broader adoption of the SDOH and the SVI for screening and preoperative
                  intervention to optimize outcomes in the most vulnerable patient populations.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Treatment of
                Knee Osteoarthritis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Biologic interventions to treat knee osteoarthritis and
                  prevent progression remain a trending topic. In a randomized controlled trial (RCT), platelet-rich
                  plasma (PRP) injections alone were compared with exercise and PRP combined with exercise in the
                  treatment of symptomatic grade-2 and 3 knee osteoarthritis. A commercially available kit was used to
                  prepare the PRP injections, which were given 3 times at weekly intervals. Although improvements in
                  patient-reported outcomes were seen in all 3 groups after 24 weeks, the exercise group and the
                  exercise combined with PRP group were superior to the PRP group alone with respect to pain, function,
                  and quality of life. PRP alone had no benefit in treating knee osteoarthritis compared with exercise,
                  allowing the authors to conclude that there is no role of PRP in the treatment of mild to moderate
                  knee osteoarthritis<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">5</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Unicompartmental Knee Arthroplasty (UKA)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Indications
                and Utilization</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">The utilization of UKA continues to increase as a
                  treatment for compartment-specific osteoarthritis. A review of the American Board of Orthopaedic
                  Surgery (ABOS) Part II Oral Examination Case List database revealed that the volume of UKAs performed
                  by newly trained surgeons more than doubled between 2011 (18.8 per 10,000 cases) and 2021 (39.5 per
                  10,000 cases). UKA, compared with periarticular knee osteotomy performed for osteoarthritis,
                  demonstrated significantly lower rates of surgical complications (7.3% compared with 23.7%; p &lt;
                  0.001), reoperation (1.9% compared with 5.2%; p = 0.002), and infection (1.4% compared with 6.7%; p
                  &lt; 0.001)<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">6</span></a>.
                  Similarly, a large health-care network database study demonstrated a 590% increase in utilization
                  between 2012 (241 UKAs) and 2022 (1,662 UKAs), with a similar increase in the adoption of robotic UKA
                  (11 in 2012 and 68 in 2022 [518%])<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">7</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Lateral
                UKA</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">There is growing interest in the utilization of lateral
                  UKA for isolated lateral compartment osteoarthritis. In a matched cohort analysis, comparing causes of
                  lateral knee osteoarthritis, all patients had significant functional improvements with respect to the
                  Knee Society Score, Forgotten Joint Score, and visual analog scale (VAS) for pain. Compared with
                  patients with primary lateral knee osteoarthritis, patients who underwent lateral meniscectomy
                  demonstrated less medial compartment osteoarthritis progression and thus significantly greater implant
                  survivorship (97.6% compared with 83.3%) at a mean follow-up of 10 years<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">8</span></a>. Plancher et al.<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">9</span></a>
                  demonstrated that the presence of grade-3 or 4 chondromalacia of the lateral patellar facet did not
                  negatively impact functional scores or survivorship of lateral UKA. Bunyoz et al.<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">10</span></a> found a learning curve of approximately 33 cases, based on
                  surgical duration. Oxford Knee Scores (OKS) did not reveal adverse functional outcomes during the
                  learning curve but did reveal 95.4% revision-free survivorship at 7 years<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">10</span></a>. Similarly, a single-surgeon cohort of 161 lateral UKAs in
                  153 patients demonstrated revision-free survivorship of 97.4% at 5 years, 95.4% at 10 years, and 91.3%
                  at 15 years<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">11</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Registry
                Data</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Data from the National Joint Registry for England, Wales,
                  Northern Ireland and the Isle of Man (NJR) compared the OKS and survivorship of 3,453 medial UKAs
                  performed with cement and 3,453 cementless medial UKAs in a propensity-matched study. The authors
                  found a greater postoperative mean OKS (39.1 ± 8.7) in the cementless mobile-bearing Oxford UKA cohort
                  compared with the cohort of patients who underwent UKAs performed with cement (38.5 ± 8.6), although
                  the difference was below the minimum clinically important difference (MCID). Additionally, the
                  cementless Oxford UKA had significantly greater 10-year survivorship (93.0%) compared with its
                  cemented counterpart (91.3%). The differences in clinical outcomes were greater, in favor of UKAs
                  performed without cement, when the procedures were performed by high-volume surgeons (≥30 UKAs per
                  year)<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">12</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">UKA
                Revision</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Data from the New Zealand Joint Registry indicated that
                  there is a significantly lower threshold to revise a poorly functioning (OKS, ≤25) UKA compared with a
                  TKA at 6 months (19.6% compared with 5.1%; p &lt; 0.001), 5 years (12.5% compared with 4.3%; p &lt;
                  0.001), and 10 years (15.0% compared with 6.4%; p = 0.024). The risk of revision for an unknown
                  etiology was approximately 2.5 times higher for UKA compared with TKA<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">13</span></a>. Acute PJI following UKA presents a unique challenge
                  involving a joint with a prosthetic component as well as native cartilage. A multicenter study
                  explored the effectiveness of debridement, antibiotics, and implant retention (DAIR) in this scenario.
                  The mean time from index UKA to PJI diagnosis was 11.1 months. The eradication rate at 1 year was
                  80.8% (42 of 52 UKAs), with the failures proceeding to either 1-stage or 2-stage revision to TKA.
                  Although the surgical techniques and irrigation solutions varied widely across institutions, a wide
                  exposure with thorough synovectomy was associated with infection eradication<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">14</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Primary
                TKA</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Antibiotic
                Prophylaxis and Infection Prevention</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Debate persists with regard to the optimal antibiotic
                  prophylaxis for primary TKA. In a multicenter, double-blinded, placebo-controlled superiority trial,
                  the investigators sought to evaluate the efficacy of the addition of intravenous vancomycin compared
                  with placebo in preventing surgical site infection within 90 days postoperatively. Over 4,000 patients
                  without known methicillin-resistant <span data-id="emphasis_54" class="annotation emphasis"
                    style="position: unset;">Staphylococcus aureus</span> (MRSA) colonization were included, including
                  2,233 patients who underwent TKA. Patients received 2 g of cefazolin plus weight-based vancomycin or
                  placebo. Sixty-three (5.7%) of 1,109 patients in the vancomycin group developed a surgical site
                  infection compared with 42 (3.7%) of 1,124 patients in the placebo group (relative risk, 1.52 [95%
                  confidence interval (CI), 1.04 to 2.23]). An additional subgroup analysis on the safety of vancomycin
                  administration yielded a similar rate of adverse events (1.7%) in both groups<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">15</span></a>. In a systematic review of penicillin allergy in the
                  setting of total joint arthroplasty (TJA), true allergy rates were found to be low (0.7% to 3%). The
                  rate of allergic reaction in patients with a penicillin allergy who received a cephalosporin was 0% to
                  2%, leading the authors to conclude that perioperative allergy screening and testing can safely
                  increase the usage of first-line antibiotic prophylaxis in TJA<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">16</span></a>. In a single-blinded RCT of patients undergoing
                  tourniquetless primary TKA, 10 patients received either weight-based intravenous vancomycin 1 hour
                  before the surgical procedure or 500-mg intraosseous vancomycin at the time of incision. Systemic
                  samples prior to incision and at closure showed significantly greater levels of vancomycin in the
                  intravenous group compared with the interosseous group. Intraoperative tissue samples demonstrated
                  similar levels in the intravenous and intraosseous groups, including in samples from the distal femur
                  (61.0 ± 16.0 and 66.2 ± 12.3; p = 0.80), proximal tibia (52.8 ± 13.5 and 57.1 ± 17.0; p = 0.84), and
                  suprapatellar synovial tissue (10.7 ± 5.3 and 9.0 ± 3.3; p = 0.80). The authors highlighted the
                  efficacy of intraosseous vancomycin while avoiding the possible systemic toxicity of vancomycin<a
                    href="" data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">17</span></a>. A study from the Catalan Arthroplasty Register of TKAs
                  performed between 2011 and 2020 assessed the association of antibiotic-loaded bone cement (ALBC) and
                  revision rates. Their analysis of 22,781 TKAs (57.6% with plain cement, and 42.4% with ALBC) revealed
                  that the 3-month revision TKA rate for infection was significantly lower in the ALBC group (0.52%
                  compared with 0.78%, p = 0.04)<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">18</span></a>.
                  Conversely, in a study of 14 national or regional registries, &gt;2 million TKAs were reviewed to
                  compare the risk of 1-year revision for PJI between TKA using ALBC and TKA using plain bone cement.
                  The authors reported a cumulative 1-year revision rate for PJI of &lt;1% in both the ALBC group
                  (range, 0.21% to 0.80%) and the plain cement group (range, 0.23% to 0.70%). There was no significant
                  difference in revision for PJI at 1 year (hazard ratio [HR], 1.16 [95% CI, 0.89 to 1.52]) or for all
                  causes (HR, 1.12 [95% CI, 0.89 to 1.40]) between ALBC and plain bone cement<a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">19</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Blood Loss
                Prophylaxis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The winners of the American Association of Hip and Knee
                  Surgeons (AAHKS) Clinical Research Award, Kirwan et al.<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">20</span></a>,
                  investigated the efficacy of extended oral tranexamic acid (TXA) in a double-blinded RCT. Patients who
                  underwent outpatient TKA at a surgery center were randomized to receive 1.95-g oral TXA on the day of
                  the surgical procedure after ambulation and 3 additional doses on postoperative days 1, 2, and 3. The
                  control group took placebo pills in the same manner. The TXA group demonstrated better knee flexion
                  (116.05° compared with 106.5°; p = 0.0308), VAS pain scores (1.35 compared with 2.8; p = 0.011), and
                  Knee Injury and Osteoarthritis Outcome Scores for Joint Replacement) (73.33 compared with 62.47; p =
                  0.0019) at 6 weeks compared with the placebo group. As the adoption of perioperative TXA
                  administration becomes more universal, an additional benefit of its use is the potential to lower the
                  risk of PJI, with an odds ratio (OR) of 0.63 (95% CI, 0.42 to 0.96; p &lt; 0.001) shown in a recent
                  systematic review and meta-analysis, although it included a small number of studies. The authors
                  hypothesized that TXA prevents PJI by reducing the need for transfusions, postoperative wound
                  drainage, and/or hematoma formation<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">21</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Perioperative Care and Pain Management</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Several new studies assessed the effectiveness of various
                  multimodal anesthesia regimens. In an RCT assessing the efficacy of periarticular injection in the
                  setting of an adductor canal block plus infiltration between the popliteal artery and capsule of the
                  knee (IPACK), a sham saline-solution periarticular injection was found to be noninferior to an active
                  periarticular injection in terms of the pain score and oral morphine equivalents<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">22</span></a>. Maniar et al.<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">23</span></a>
                  demonstrated that an adductor canal block can be safely administered by the surgeon intraoperatively
                  to yield improved pain relief and decreased opioid consumption. Their described technique avoids the
                  need for specialized equipment or an anesthesiologist. In a double-blinded RCT of 106 patients
                  undergoing bilateral TKA, the addition of 20-mg oral duloxetine significantly improved VAS pain scores
                  and patient satisfaction compared with placebo<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">24</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Sleep disturbance is a commonly reported symptom after
                  TKA. The winners of the John N. Insall Award, LeBrun et al.<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">25</span></a>,
                  demonstrated no benefit from exogenous melatonin with respect to subjective sleep quality, opioid
                  usage, or patient-reported outcomes at 6 weeks or 90 days after TKA. The investigators randomized 86
                  patients each to the melatonin and vitamin C placebo arms of the study in a double-blinded RCT. In a
                  similar double-blinded RCT, Haider et al.<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">26</span></a>
                  found a trend toward longer sleep duration in patients taking melatonin for the initial 3 days
                  following TKA, but these attenuating effects on sleep disturbance waned thereafter. Wearable devices
                  that track sleep have gained popularity. In an award-winning study, patient-reported sleep quality
                  returned to baseline by 90 days after TKA. Although sleep metrics, such as time in bed, time asleep,
                  and minutes of REM (rapid eye movement) sleep were nicely captured by the wearable devices, their
                  correlation with patient-reported sleep quality was weak and they may not be a useful objective
                  tracking tool<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">27</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Postoperative Complications</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The winners of the Chitranjan S. Ranawat Award, Abdel et
                  al.<a href="" data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">28</span></a>, performed a multicenter RCT to evaluate the efficacy of
                  adjuvant anti-inflammatory medications following manipulation under anesthesia (MUA) for postoperative
                  arthrofibrosis in 124 patients across 15 institutions. Patients were randomized to receive either 8-mg
                  intravenous dexamethasone followed by MUA, physical therapy, and 14 days of oral celecoxib (200 mg) or
                  MUA followed by physical therapy. Range of motion significantly improved in both groups, but, after
                  the MUA, there was no significant difference between the anti-inflammatory group and the control group
                  at 6 weeks (101° compared with 99°; p = 0.35) or at 1 year (108° compared with 108°; p = 0.98).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Implant
                Design</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Bearing designs in contemporary TKA continue to generate
                  substantial debate. A meta-analysis demonstrated no difference in function and patient-reported
                  outcomes between posterior-stabilized, cruciate-retaining, and ultracongruent liners, although the
                  pooled analysis was limited by the paucity of RCTs and high-quality studies<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">29</span></a>. In an RCT evaluating a specific single-radius design,
                  patients who had a posterior-stabilized knee implant demonstrated greater flexion compared with
                  patients with a cruciate-retaining knee implant (median, 120.0° compared with 115°; p = 0.017),
                  although functional outcomes were similar at 2 years<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">30</span></a>. In
                  another RCT involving a different TKA design, knees with posterior-stabilized implants demonstrated
                  greater maximal flexion (129° [95% CI, 127° to 131°]) compared with knees with cruciate-retaining
                  implants (120° [95% CI, 121° to 124°]) and knees with anterior-stabilized implants (122° [95% CI, 120°
                  to 124°]). Similarly, no differences in pain or functional outcomes were seen between the 3 types of
                  liners at the 2-year follow-up<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">31</span></a>. In
                  a meta-analysis of 11 RCTs, all-polyethylene and metal-backed tibial implants performed similarly with
                  respect to clinical outcomes and survivorship. Five of the studies included radiostereometric analysis
                  (RSA), which showed greater liftoff in the metal-backed group (0.3 mm) compared with the
                  all-polyethylene group (0.19 mm) (p = 0.03); however, this difference was not impactful on clinical
                  outcomes and 5-year survivorship<a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">32</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Cementless TKAs continue to garner substantial interest.
                  In a paired RCT of bilateral cruciate-retaining TKAs with patellar resurfacing performed under the
                  same anesthetic setting, 1 knee in each of the 40 patients underwent TKA with cement and the other
                  knee underwent TKA without cement. At a minimum 2-year follow-up, functional and pain outcomes were
                  similar in both knees. In 4 of the knees with cementless implants, there was early evidence of
                  superior migration of the metal-backed patellar component on lateral radiographs, none of which
                  required revision<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript" style="position: unset;">33</span></a>. In
                  another series, metal-backed cementless patellar implants demonstrated 10-year survivorship of 95.9%
                  compared with 98.9% for the cemented implant, with similar rates of patellar revisions (p = 0.151) and
                  patellar aseptic loosening (p = 0.737)<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript" style="position: unset;">34</span></a>.
                  Concerns remain about metal-backed patellar components, as the most common mode of failure remains
                  polyethylene dissociation from the metal backing<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript" style="position: unset;">35</span></a>.
                  The commonly held belief of increased early postoperative pain during osseointegration in cementless
                  TKA was debunked in a systematic review and meta-analysis that demonstrated similar short-term (≤6
                  months) pain and early function compared with TKA performed with cement<a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">36</span></a>. A Canadian Joint Replacement Registry analysis of
                  &gt;202,000 TKAs performed from 2012 to 2021 demonstrated an all-cause revision rate of 4.49% at 2
                  years for cementless knee implants that was slightly higher than the 3.14% rate for cemented implants
                  when not accounting for confounders. When confounders were taken into consideration, the most common
                  cementless TKA implants demonstrated a lower risk of revision compared with their cemented
                  counterparts, which was significant at 4 years (HR, 0.66 [95% CI, 0.51 to 0.85], p = 0.001).
                  Nonetheless, this database study had multiple limitations that decreased its external validity, given
                  the large number of patient, surgeon, and facility variabilities<a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">37</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Technology-Assisted TKA</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The utility of robotic-assisted TKA continues to garner
                  much research interest. In an unblinded RCT comparing manual TKA with a computed tomography (CT)-based
                  robotic-assisted TKA platform, 46 patients who underwent robotic-assisted TKA and 41 patients who
                  underwent manual TKA were assessed at 6 months for pain and functional outcomes. There were no
                  differences in the Western Ontario and McMaster Universities Osteoarthritis Index (WOMAC) functional
                  score (p = 0.425) at 6 months. The robotic-assisted TKA group demonstrated greater improvement in the
                  WOMAC pain score at 2 months (p = 0.037), although this difference was no longer significant at 6
                  months (p = 0.198). A greater proportion of patients who underwent robotic-assisted TKA, compared with
                  patients who underwent manual TKA, reached the MCID for the WOMAC pain score at both 2 months (36
                  [78.3%] compared with 24 [58.5%]; p = 0.047) and 6 months (40 [87.0%] compared with 29 [68.3%]; p =
                  0.036). The proportion of satisfied patients favored robotic-assisted TKA (45 [97.8%] of 46) over
                  manual TKA (36 [87.8%] of 41), although this finding was not significant (p = 0.096)<a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">38</span></a>. A study of the American Joint Replacement Registry (AJRR)
                  comparing 14,216 robotic-assisted TKAs and 128,334 conventional TKAs found no difference in revision
                  risk at 2 years (OR, 1.0 [95% CI, 0.8 to 1.3]; p = 0.92)<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_42" class="annotation superscript" style="position: unset;">39</span></a>. In
                  a review of 300 patients undergoing robotic-assisted TKA in a multicenter study, preoperative CT
                  scanograms demonstrated high correlation with long-leg radiographs for assessing the coronal plane
                  alignment of the knee<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_43" class="annotation superscript" style="position: unset;">40</span></a>. A
                  Markov model incorporating key parameters obtained from the literature (costs of the initial surgical
                  procedure, episode of care, revision, implant failure; probability of complications, 15-year failure
                  rate), computer-assisted TKA was associated with higher quality-adjusted life-years (QALYs) and lower
                  cost compared with conventional TKA. The model further estimates that 100% adoption of
                  computer-assisted TKA would result in nearly $1 billion annually in lifetime cost savings for Medicare
                  and commercial payers<a href="" data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_44" class="annotation superscript" style="position: unset;">41</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Revision
                TKA</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">A variety of topics in revision TKA continue to gain
                  attention internationally. The range of newly published articles is wide and is categorized and
                  summarized next.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Implant
                Design</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The use of a rotating-hinge component was studied in cases
                  of arthrofibrosis and PJI. The Total Knee Arthrofibrosis (TKAF) Consortium reported on arc-of-motion
                  improvement after revision TKA for arthrofibrosis in 56 patients. The authors reported that there was
                  a significantly greater arc of motion (p &lt; 0.001) for patients treated with a rotating-hinge
                  construct (41.3° ± 19.4°) compared with patients treated with a non-rotating-hinge construct (18.3° ±
                  15.2°); however, no differences in patient-reported outcomes were seen at the final follow-up. In
                  patients with severe arthrofibrosis (preoperative range of motion, &lt;70°), a mean improvement of
                  31.1° ± 20.9° in range of motion was seen<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_45" class="annotation superscript" style="position: unset;">42</span></a>. In
                  the infection setting, the use of hinged implants in multistage revisions was reported in 79 patients
                  treated from 2010 to 2018 with minimum 5-year outcomes. Despite the complex surgical history, the
                  patients demonstrated good functional outcomes and 87.3% were free from infection<a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_46" class="annotation superscript"
                      style="position: unset;">43</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">PJI</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The winners of the James A. Rand Young Investigator’s
                  Award, Kreinces et al.<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_47" class="annotation superscript" style="position: unset;">44</span></a>,
                  evaluated the utility and necessity of additional tests included in the 2018 Musculoskeletal Infection
                  Society (MSIS) criteria to diagnose PJI. The authors retrospectively reviewed 204 revision TKA cases
                  and 158 revision total hip arthroplasty (THA) cases involving suspected PJI from 2018 to 2020 that had
                  a minimum 2-year follow-up. Most cases (94.6% of TKA cases and 98.7% of THA cases) met the “infected”
                  criteria based on preoperative and intraoperative data without using alpha-defensin or synovial
                  C-reactive protein. When applying preoperative data only, 88.4% of TKA and THA cases met the MSIS
                  criteria for infection, highlighting that an algorithmic approach would be both effective and
                  economical for diagnosing PJI. Several studies over the past year have reviewed the temporal trends in
                  PJI. Data from the New York Statewide Planning and Research Cooperative System found no year-to-year
                  change in the likelihood of revision for PJI among primary TKAs performed from 2006 to 2013. For
                  surgical procedures performed from 2014 to 2016, the likelihood of revision for PJI decreased by year
                  (OR, 0.76 [95% CI, 0.66 to 0.88]; p = 0.0002), which the authors attributed to advances in infection
                  prevention strategies<a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_48" class="annotation superscript" style="position: unset;">45</span></a>.
                  Data from the Dutch Arthroplasty Register and the Dutch National Nosocomial Surveillance Network
                  (PREZIES) revealed that early PJI after TKA primarily involved <span data-id="emphasis_55"
                    class="annotation emphasis" style="position: unset;">S</span>. <span data-id="emphasis_56"
                    class="annotation emphasis" style="position: unset;">aureus</span> (39%), followed by
                  coagulase-negative staphylococci (19%), especially <span data-id="emphasis_57"
                    class="annotation emphasis" style="position: unset;">Staphylococcus epidermidis</span> (11%)<a
                    href="" data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">46</span></a>. Polymicrobial PJI continues to rise. In a single-center
                  review of PJI comparing 2 time frames (2001 to 2006 and 2018 to 2022), there was a significant
                  increase (p &lt; 0.001) in the involvement of <span data-id="emphasis_58" class="annotation emphasis"
                    style="position: unset;">S</span>. <span data-id="emphasis_59" class="annotation emphasis"
                    style="position: unset;">epidermidis</span> and <span data-id="emphasis_60"
                    class="annotation emphasis" style="position: unset;">Cutibacterium acnes</span> in polymicrobial
                  PJI. Culture data demonstrated that these 2 microorganisms frequently coexisted<a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                      style="position: unset;">47</span></a>. In another study, polymicrobial involvement was found in
                  41.7% of PJIs following TKA in patients with an overlying sinus tract compared with 29.1% in patients
                  who did not have a sinus tract<a href="" data-id="citation_reference_52"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_51" class="annotation superscript" style="position: unset;">48</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Several studies evaluated the prevalence, outcomes, and
                  implications of unexpected positive cultures during presumed aseptic revisions. In 1 series, the
                  prevalence of 1 or ≥2 unexpected positive cultures for different organisms was 19.5%, whereas the
                  prevalence of 1 uncommon contaminant or ≥2 unexpected positive cultures for the same organism was
                  4.7%. The presence of unexpected positive cultures was not associated with greater risk of revision at
                  5 years<a href="" data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_52" class="annotation superscript" style="position: unset;">49</span></a>. In
                  another series of 691 presumed aseptic revision TKAs, the rates were 7.1% for 1 unexpected positive
                  culture, 1.4% for ≥2 unexpected positive cultures of the same organism, and 0.2% for ≥2 unexpected
                  positive cultures of different organisms. Regression analysis demonstrated that ≥2 unexpected positive
                  cultures of the same microorganism (HR, 11.0; p &lt; 0.001), 1 unexpected positive culture (HR, 4.2; p
                  = 0.018), and the use of hinged constructs (HR, 4.1; p = 0.008) were associated with an increased risk
                  of re-revision for PJI<a href="" data-id="citation_reference_54"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_53" class="annotation superscript" style="position: unset;">50</span></a>.
                  The prevalence of unexpected positive cultures during revision of 159 hips and 61 knees for
                  periprosthetic fracture was 6.8% (10 hips and 5 knees). <span data-id="emphasis_61"
                    class="annotation emphasis" style="position: unset;">S</span>. <span data-id="emphasis_62"
                    class="annotation emphasis" style="position: unset;">epidermidis</span> was the most common
                  causative organism (35%). Re-revision for infection was required in 6 of the 15 patients<a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_54" class="annotation superscript"
                      style="position: unset;">51</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Special
                Considerations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The learning curve of revision TKA was quantified in a
                  single-institution study comparing surgeons grouped on the basis of years in practice (“inexperienced”
                  [little experience; the first 2 years], early experience [4 to 6 years], and senior experience [15 to
                  17 years]). The duration of the surgical procedure was significantly longer (p &lt; 0.001) for
                  inexperienced surgeons (216.8 minutes) when compared with early experience surgeons (135.1 minutes)
                  and senior experience surgeons (95.0 minutes); however, the rates of reoperation within 1 year were
                  similar: 5.3% for inexperienced surgeons, 3.6% for early experience surgeons, and 5.0% for senior
                  experience surgeons (p = 0.916)<a href="" data-id="citation_reference_56"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_55" class="annotation superscript" style="position: unset;">52</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Evidence-Based Orthopaedics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The editorial staff of <span data-id="emphasis_63"
                    class="annotation emphasis" style="position: unset;">JBJS</span> reviewed a large number of recently
                  published studies related to the musculoskeletal system that received a higher Level of Evidence
                  grade. In addition to articles cited already in this update, 9 other articles relevant to adult
                  reconstructive knee surgery are appended to this review after the standard bibliography, with a brief
                  commentary about each article to help guide further evidence-based reading in this subspecialty area.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Evidence-Based Orthopaedics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_55" class="annotation strong"
                    style="position: unset;">Di Matteo B, Anzillotti G, Gallese A, Vitale U, Gaggia GMMC, Ronzoni FL,
                    Marcacci M, Kon E.</span> Placenta-derived products demonstrate good safety profile and overall
                  satisfactory outcomes for treating knee osteoarthritis: a systematic review of clinical evidence.
                  <span data-id="emphasis_64" class="annotation emphasis" style="position: unset;">Arthroscopy</span>.
                  2023 Aug;39(8):1892-904.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">In this systematic review, placenta-derived products
                  demonstrated a good safety profile and good patient-reported outcomes in the treatment of knee
                  osteoarthritis. Sixteen studies were included, of which 5 reported on the use of placenta-derived
                  products for augmentation during surgical procedures. The remaining studies evaluated its use as an
                  injectable therapeutic agent. Although the results are encouraging, the conclusions should be
                  approached with caution, as the study was limited by the inclusion of only 4 RCTs, a lack of a
                  meta-analysis, and the variability of preparation methods.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_56" class="annotation strong"
                    style="position: unset;">Gupta PK, Maheshwari S, Cherian JJ, Goni V, Sharma AK, Tripathy SK, Talari
                    K, Pandey V, Sancheti PK, Singh S, Bandyopadhyay S, Shetty N, Kamath SU, Prahaldbhai PS, Abraham J,
                    Kannan S, Bhat S, Parshuram S, Shahavi V, Sharma A, Verma NN, Kumar U.</span> Efficacy and safety of
                  stempeucel in osteoarthritis of the knee: a Phase 3 randomized, double-blind, multicenter,
                  placebo-controlled study. <span data-id="emphasis_65" class="annotation emphasis"
                    style="position: unset;">Am J Sports Med</span>. 2023 Jul;51(9):2254-66.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">This was an RCT that assessed the efficacy of allogenic
                  mesenchymal stromal cells compared with placebo in patients with Kellgren-Lawrence grade-2 and 3
                  osteoarthritis. The placebo consisted of the same 2 mL of the medium used to suspend the bone
                  marrow-derived mesenchymal stromal cells (BMMSC) in the experimental group. Both groups also received
                  2-mL injections of hyaluronic acid. At 12 months, the WOMAC and VAS pain scores significantly improved
                  in the BMMSC group, whereas those parameters returned to baseline for the placebo group. Magnetic
                  resonance imaging (MRI) revealed gradual worsening of the medial tibiofemoral joint cartilage in the
                  placebo group from baseline to 6 months to 12 months. However, these changes and the cartilage volume
                  did not significantly differ between the 2 groups. The authors present their promising results with
                  novel treatment strategies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_57" class="annotation strong"
                    style="position: unset;">Jawanda H, Khan ZA, Warrier AA, Acuña AJ, Allahabadi S, Kaplan DJ, Ritz E,
                    Jackson GR, Mameri ES, Batra A, Dornan G, Westrick J, Verma NN, Chahla J.</span> Platelet-rich
                  plasma, bone marrow aspirate concentrate, and hyaluronic acid injections outperform corticosteroids in
                  pain and function scores at a minimum of 6 months as intra-articular injections for knee
                  osteoarthritis: a systematic review and network meta-analysis. <span data-id="emphasis_66"
                    class="annotation emphasis" style="position: unset;">Arthroscopy</span>. 2024 May;40(5):1623-36.e1.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">In this systematic review and network meta-analysis, the
                  authors evaluated 48 studies including 9,338 knees that underwent intra-articular injections for knee
                  osteoarthritis. Only Level-I and II RCTs were included for review. Hyaluronic acid was injected in
                  40.9% of the knees, followed by placebo in 26.2%, PRP in 21.5%, cortisone in 8.8%, and bone marrow
                  aspirate concentrate (BMAC) in 2.5%. The pooled results demonstrated that PRP injections yielded
                  greater pain and functional improvements compared with placebo at a minimum of 6 months. Because of
                  the heterogeneity of the included studies, a surface under the cumulative ranking curve (SUCRA)
                  analysis was performed in the network meta-analysis, which demonstrated that PRP had the highest
                  likelihood of improvement compared with BMAC, hyaluronic acid, and cortisone. Although cost was not
                  evaluated in this study, these findings are nevertheless important for clinicians to consider in the
                  approach to the conservative treatment of knee osteoarthritis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_58" class="annotation strong"
                    style="position: unset;">Jiao S, Feng Z, Dai T, Huang J, Liu R, Meng Q.</span> High-intensity
                  progressive rehabilitation versus routine rehabilitation after total knee arthroplasty: a randomized
                  controlled trial. <span data-id="emphasis_67" class="annotation emphasis" style="position: unset;">J
                    Arthroplasty</span>. 2024 Mar;39(3):665-71.e2.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">This double-blinded RCT assessed the efficacy of
                  high-intensity progressive training compared with routine therapy following TKA. The investigators and
                  patients were all blinded to the grouping. The high-intensity regimen included preoperative training
                  and was divided into 3 phases (muscle strengthening, active rehabilitation, and weight-bearing
                  training) with a progression process. The routine therapy differed in the lack of progressive
                  training, standardized instructions, and preoperative training, along with a lower frequency of
                  training. A total of 78 patients were equally divided into the 2 training groups. The progressive
                  training group demonstrated greater functional outcomes and patient satisfaction, lower VAS pain
                  scores, and shorter length of stay compared with the routine rehabilitation group. These outcomes
                  demonstrate the efficacy of a high-intensity progressive training program in early recovery,
                  especially as outpatient TKA becomes more popular.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_59" class="annotation strong"
                    style="position: unset;">Oeding JF, Varady NH, Fearington FW, Pareek A, Strickland SM, Nwachukwu BU,
                    Camp CL, Krych AJ.</span> Platelet-rich plasma versus alternative injections for osteoarthritis of
                  the knee: a systematic review and statistical Fragility Index-based meta-analysis of randomized
                  controlled trials. <span data-id="emphasis_68" class="annotation emphasis" style="position: unset;">Am
                    J Sports Med</span>. 2024 Feb 29:3635465231224463.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">In this systematic review and meta-analysis of RCTs, the
                  authors compared the efficacy of PRP and alternative nonoperative treatments. Ten of the 16 included
                  RCTs represented Level-I evidence, and the remaining 6 RCTs represented Level-II evidence. Outcomes of
                  1,993 patients were evaluated. The authors assessed the fragility index of all of the studies to
                  determine whether the study outcomes were fragile or robust. Briefly, this involves simultaneously
                  adding and subtracting outcome events in a 2 × 2 contingency table until the significance is reversed.
                  If only 1 event leads to reversal of significance (i.e., fragility index of 1), then the study outcome
                  is deemed fragile and less robust. The meta-analysis demonstrated that PRP had higher rates of symptom
                  improvement, achieving the MCID for pain, and not requiring reintervention compared with alternative
                  injectables, such as hyaluronic acid. The mean number of events required to change the significance of
                  the pooled treatment effect was 8.67 ± 4.50. Based on this fragility index, the conclusions drawn from
                  the meta-analysis are slightly robust and may help the treating physician in determining the optimal
                  nonoperative modality for knee osteoarthritis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_60" class="annotation strong"
                    style="position: unset;">Stubnya BG, Kocsis K, Váncsa S, Kovács K, Agócs G, Stubnya MP, Suskó E,
                    Hegyi P, Bejek Z.</span> Subvastus approach supporting fast-track total knee arthroplasty over the
                  medial parapatellar approach: a systematic review and network meta-analysis. <span
                    data-id="emphasis_69" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span>
                  2023 Dec;38(12):2750-8.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">This systematic review and network meta-analysis compared
                  the early outcomes of TKA according to the surgical approach. A total of 33 RCTs including 2,895 TKAs
                  were included for review. In terms of range of motion, the subvastus and mini-subvastus approaches
                  were generally superior to other approaches (midvastus, mini-midvastus, medial parapatellar) in the
                  first 14 days after TKA. With respect to VAS pain scores, the mini-subvastus was superior on
                  postoperative day 1. In general, the quadriceps-sparing approaches outperformed the medial
                  parapatellar approach for postoperative pain. Functional outcomes also favored the subvastus and
                  mini-subvastus approaches, although these differences all decreased with time. The main limitation of
                  the study was the heterogeneity of time-reporting across all studies (i.e., the sample sizes to
                  compare outcomes were drastically lower at postoperative day 7 compared with postoperative days 42 or
                  365). Despite this limitation, the results supported the benefits of the quadriceps-sparing approach
                  to TKA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_61" class="annotation strong"
                    style="position: unset;">Tao X, Aw AAL, Leeu JJ, Bin Abd Razak HR.</span> Three doses of
                  platelet-rich plasma therapy are more effective than one dose of platelet-rich plasma in the treatment
                  of knee osteoarthritis: a systematic review and meta-analysis. <span data-id="emphasis_70"
                    class="annotation emphasis" style="position: unset;">Arthroscopy</span>. 2023 Dec;39(12):2568-76.e2.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">In this systematic review and meta-analysis of RCTs, the
                  authors sought to compare a single dose with multiple doses of PRP in the treatment of knee
                  osteoarthritis. Seven studies involving 575 patients were included. The knee osteoarthritis ranged
                  from Kellgren-Lawrence grades 1 to 3. At 12 months, triple-dose PRP therapy demonstrated better VAS
                  pain scores compared with single-dose therapy, but this was only based on 3 studies that showed
                  12-month outcomes. The safety profile was similar between the different PRP doses. The results of this
                  study should be interpreted with caution because of the small number of studies. Additionally, the
                  authors attempted to evaluate WOMAC scores, but omitted the data from the article because of data
                  heterogeneity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_62" class="annotation strong"
                    style="position: unset;">Wang Q, Jin Q, Cai L, Zhao C, Feng P, Jia J, Xu W, Qian Q, Ding Z, Xu J, Gu
                    C, Zhang S, Shi H, Ma H, Deng Y, Zhang T, Song Y, Wang Q, Zhang Y, Zhou X, Pei L, Yang Y, Liang J,
                    Jiang T, Li H, Liu H, Wu L, Kang P.</span> Efficacy of diosmin in reducing lower-extremity swelling
                  and pain after total knee arthroplasty: a randomized, controlled multicenter trial. <span
                    data-id="emphasis_71" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                    Am</span>. 2024 Mar 20;106(6):492-500.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">This RCT evaluated the efficacy of diosmin on
                  postoperative swelling and pain following TKA. Diosmin is a semisynthetic flavonoid derived from
                  hesperidin that is used to treat venous insufficiency. Its mechanism involves its ability to improve
                  venous tone and lymphatic drainage. A total of 330 patients were randomized to either receive or not
                  receive diosmin (0.9 g twice per day for 14 days) after TKA. There was no placebo group. Swelling at
                  the thigh, patella, and calf levels was measured by an assessor blinded to the treatment group. The
                  diosmin group demonstrated significantly less swelling and pain with motion compared with the control
                  group. There were no differences in pain at rest, patient-reported outcomes, range of motion,
                  inflammatory markers, or complication rates. Although early postoperative effectiveness was
                  demonstrated in this study, further studies regarding diosmin’s long-term safety are warranted before
                  its widespread use may be recommended.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_63" class="annotation strong"
                    style="position: unset;">Yoo JD, Huh MH, Lee SH, D’Lima DD, Shin YS.</span> A network meta-analysis
                  of randomized controlled trials assessing intraoperative anesthetic therapies for analgesic efficacy
                  and morphine consumption following total knee arthroplasty. <span data-id="emphasis_72"
                    class="annotation emphasis" style="position: unset;">J Arthroplasty</span>. 2024 May;39(5):1361-73.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">This network meta-analysis of RCTs aimed to compare
                  analgesic effectiveness and morphine consumption after TKA between different modalities. A total of 40
                  studies were included. Local infiltration anesthesia combined with a saphenous nerve block produced
                  the best analgesic effect after TKA on postoperative days 1 and 2 (SUCRA, 80.0) and the lowest
                  morphine consumption on postoperative days 1 (SUCRA, 80.0) and 3 (SUCRA, 100.0). A femoral nerve block
                  demonstrated the best analgesic effect on postoperative day 3 (SUCRA, 90.0). These results suggest
                  that local infiltration anesthesia combined with a saphenous nerve block should be considered a
                  first-line modality for pain control following TKA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Meacock SS</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Khan IA</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Hohmann AL</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Cohen-Rosenblum A</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Krueger CA</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Purtill JJ</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Fillingham YA</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">What are social determinants of
                  health and why should they matter to an orthopaedic surgeon?</span><span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2024 Sep
                18;106(18):1731-7.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=70602ff8-6817-41e8-a0b7-569a8b3ca1e5" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Pearson ZC</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Ahiarakwe U</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Bahoravitch TJ</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Schmerler J</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Harris AB</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Thakkar SC</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Best MJ</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Srikumaran U</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Social determinants of health
                  disparities increase the rate of complications after total knee arthroplasty</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2023
                Dec;38(12):2531-36.e3.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Social%20determinants%20of%20health%20disparities%20increase%20the%20rate%20of%20complications%20after%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22ZC%20Pearson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Rahman TM</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Shaw JH</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Mehaidli A</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Hennekes M</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Hansen L</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Castle JP</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Kulkarni M</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Silverton CD</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">The impact of social
                  determinants of health on outcomes and complications after total knee arthroplasty: an analysis of
                  neighborhood deprivation indices</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2024 Feb 21;106(4):288-303.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=a613aec3-7cb4-43f6-9766-c8a6bf25255a"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Dubin JA</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Bains SS</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Hameed D</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Monárrez R</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Moore MC</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Mont MA</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Nace J</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Delanois RE</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">The utility of the Social
                  Vulnerability Index as a proxy for social disparities following total knee arthroplasty</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2024
                Aug;39(8S1):S33-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20utility%20of%20the%20Social%20Vulnerability%20Index%20as%20a%20proxy%20for%20social%20disparities%20following%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22JA%20Dubin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Karaborklu Argut S</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Celik D</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Ergin ON</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Kilicoglu OI</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Does the combination of
                  platelet-rich plasma and supervised exercise yield better pain relief and enhanced function in knee
                  osteoarthritis? A randomized controlled trial</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2024 Jun
                1;482(6):1051-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20the%20combination%20of%20platelet-rich%20plasma%20and%20supervised%20exercise%20yield%20better%20pain%20relief%20and%20enhanced%20function%20in%20knee%20osteoarthritis%3F%20A%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22Argut%20S%20Karaborklu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Callan KT</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Smith E</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Karasavvidis T</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Wang D</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Comparison of trends and
                  complications of unicompartmental knee arthroplasty versus periarticular knee osteotomy among ABOS
                  Part II Oral Examination candidates</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Orthop J Sports Med.</span> 2024 Jul 31;12(7):23259671241257818.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20trends%20and%20complications%20of%20unicompartmental%20knee%20arthroplasty%20versus%20periarticular%20knee%20osteotomy%20among%20ABOS%20Part%20II%20Oral%20Examination%20candidates&as_occt=title&as_sauthors=%20%22KT%20Callan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Sanghvi PA</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Burkhart RJ</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Belt NG</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Nedder VJ</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Saleh A</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Kaelber DC</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Mistovich RJ</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Epidemiological trends and
                  outcomes of unicompartmental knee arthroplasty among 104 million patients in the United States</span>.
                <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span>
                2024 Jun 22:S0883-5403(24)00647-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Epidemiological%20trends%20and%20outcomes%20of%20unicompartmental%20knee%20arthroplasty%20among%20104%20million%20patients%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22PA%20Sanghvi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Marullo M</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Russo A</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Spreafico A</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Romagnoli S</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Lateral unicompartmental knee
                  arthroplasty for osteoarthritis secondary to lateral meniscectomy: high functional results and
                  survivorship and low osteoarthritis progression at a mean 10 years of follow-up</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2024 Jun 5;106(11):992-9.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=561c9698-cb45-4460-acf0-8e8add42b6f7" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Plancher KD</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Comulada DB</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> DiVella MF</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Briggs KK</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Schwartz EN</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Mannina CM</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Petterson SC</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Severe lateral facet patella
                  osteoarthritis is not associated with increased failure at mean 10 years after lateral
                  unicompartmental knee arthroplasty</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2024 Aug 14:S0883-5403(24)00807-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Severe%20lateral%20facet%20patella%20osteoarthritis%20is%20not%20associated%20with%20increased%20failure%20at%20mean%2010%20years%20after%20lateral%20unicompartmental%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22KD%20Plancher%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Bunyoz KI</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Gromov K</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Troelsen A</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Starting up a lateral
                  unicompartmental knee arthroplasty practice - is outcome affected?</span><span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2024 Jul
                11:S0883-5403(24)00691-0.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Starting%20up%20a%20lateral%20unicompartmental%20knee%20arthroplasty%20practice%20-%20is%20outcome%20affected%3F&as_occt=title&as_sauthors=%20%22KI%20Bunyoz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Harkin W</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Kurina S</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Berger A</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Terhune EB</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Bradley A</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Karas V</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Berger RA</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Clinical outcomes and
                  survivorship of lateral unicompartmental knee arthroplasty: a large single surgeon cohort</span>.
                <span data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span>
                2024 Nov;39(11):2702-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20outcomes%20and%20survivorship%20of%20lateral%20unicompartmental%20knee%20arthroplasty%3A%20a%20large%20single%20surgeon%20cohort&as_occt=title&as_sauthors=%20%22W%20Harkin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Mohammad HR</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">A matched comparison of implant
                  and functional outcomes of cemented and cementless unicompartmental knee replacements: a study from
                  the National Joint Registry for England, Wales, Northern Ireland and the Isle of Man and the Hospital
                  Episode Statistics Patient Reported Outcome Measures Database</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2024 Sep
                4;106(17):1553-62.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=92569169-3cfa-40b7-a840-10f9b8a81118" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Tay ML</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Monk AP</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Frampton CM</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Hooper GJ</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Young SW</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">A comparison of clinical
                  thresholds for revision following total and unicompartmental knee arthroplasty</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2023
                Mar 1;105-B(3):269-76.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20comparison%20of%20clinical%20thresholds%20for%20revision%20following%20total%20and%20unicompartmental%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22ML%20Tay%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_76" class="annotation" style="position: unset;"> McCormick KL</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Xu W</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Cozzarelli NF</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Crawford D</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Wilson EJ</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Berend KR</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Fricka KB</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Lonner JH</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Geller JA</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Debridement, antibiotics, and
                  implant retention in unicompartmental knee arthroplasty infection</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2024
                Aug;39(8S1):S285-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Debridement%2C%20antibiotics%2C%20and%20implant%20retention%20in%20unicompartmental%20knee%20arthroplasty%20infection&as_occt=title&as_sauthors=%20%22KL%20McCormick%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Peel TN</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Astbury S</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Cheng AC</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Paterson DL</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Buising KL</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Spelman T</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Tran-Duy A</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Adie S</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Boyce G</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> McDougall C</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Molnar R</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Mulford J</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Rehfisch P</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Solomon M</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Crawford R</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Harris-Brown T</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Roney J</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Wisniewski J</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> de Steiger R</span>; ASAP Trial
                Group. <span data-id="strong_15" class="annotation strong" style="position: unset;">Trial of vancomycin
                  and cefazolin as surgical prophylaxis in arthroplasty</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">N Engl J Med.</span> 2023 Oct
                19;389(16):1488-98.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trial%20of%20vancomycin%20and%20cefazolin%20as%20surgical%20prophylaxis%20in%20arthroplasty&as_occt=title&as_sauthors=%20%22TN%20Peel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Porto JR</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Lavu MS</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Hecht CJ</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> McNassor R</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Burkhart RJ</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Kamath AF</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Is penicillin allergy a
                  clinical problem? A systematic review of total joint arthroplasty procedures with implications for
                  patient safety and antibiotic stewardship</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2024
                Jun;39(6):1616-23.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20penicillin%20allergy%20a%20clinical%20problem%3F%20A%20systematic%20review%20of%20total%20joint%20arthroplasty%20procedures%20with%20implications%20for%20patient%20safety%20and%20antibiotic%20stewardship&as_occt=title&as_sauthors=%20%22JR%20Porto%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Wininger AE</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Gurusamy P</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Sullivan TC</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Serpelloni S</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Taraballi F</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Park KJ</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Brown TS</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Intraosseous versus intravenous
                  vancomycin in tourniquetless primary total knee arthroplasty: a randomized trial</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2024
                Sep;39(9S2):S224-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intraosseous%20versus%20intravenous%20vancomycin%20in%20tourniquetless%20primary%20total%20knee%20arthroplasty%3A%20a%20randomized%20trial&as_occt=title&as_sauthors=%20%22AE%20Wininger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Sergi GG</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Borja VR</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Jesus C</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Pedro H</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Joan Carles M</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Xavier P</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Antibiotic-loaded bone cement
                  is associated with a reduction of the risk of revision of total knee arthroplasty: analysis of the
                  Catalan Arthroplasty Register</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2024 Jul 21.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Antibiotic-loaded%20bone%20cement%20is%20associated%20with%20a%20reduction%20of%20the%20risk%20of%20revision%20of%20total%20knee%20arthroplasty%3A%20analysis%20of%20the%20Catalan%20Arthroplasty%20Register&as_occt=title&as_sauthors=%20%22GG%20Sergi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Leta TH</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Lie SA</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Fenstad AM</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Lygre SHL</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Lindberg-Larsen M</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Pedersen AB</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> W-Dahl A</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Rolfson O</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Bülow E</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> van Steenbergen LN</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Nelissen RGHH</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Harries D</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> de Steiger R</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Lutro O</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Mäkelä K</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Venäläinen MS</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Willis J</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Wyatt M</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Frampton C</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Grimberg A</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Steinbrück A</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Wu Y</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Armaroli C</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Gentilini MA</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Picus R</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Bonetti M</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Dragosloveanu S</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Vorovenci AE</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Dragomirescu D</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Dale H</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Brand C</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Christen B</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Shapiro J</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Wilkinson JM</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Armstrong R</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Wooster K</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Hallan G</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Gjertsen JE</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Chang RN</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Prentice HA</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Sedrakyan A</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Paxton EW</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Furnes O</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Periprosthetic joint infection
                  after total knee arthroplasty with or without antibiotic bone cement</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">JAMA Netw Open.</span> 2024
                May 1;7(5):e2412898.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Periprosthetic%20joint%20infection%20after%20total%20knee%20arthroplasty%20with%20or%20without%20antibiotic%20bone%20cement&as_occt=title&as_sauthors=%20%22TH%20Leta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Kirwan MJ</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Diltz ZR</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Dixon DT</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Rivera-Peraza CA</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Gammage CJ</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Mihalko WM</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Harkess JW</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Guyton JL</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Crockarell JR</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Ford MC</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">The AAHKS Clinical Research
                  Award: Extended postoperative oral tranexamic acid in total knee arthroplasty: a randomized controlled
                  pilot study</span>. <span data-id="emphasis_20" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2024 Sep;39(9S2):S13-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20AAHKS%20Clinical%20Research%20Award%3A%20Extended%20postoperative%20oral%20tranexamic%20acid%20in%20total%20knee%20arthroplasty%3A%20a%20randomized%20controlled%20pilot%20study&as_occt=title&as_sauthors=%20%22MJ%20Kirwan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Elmenawi KA</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Mohamed FAE</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Poilvache H</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Prokop LJ</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Abdel MP</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Bedard NA</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Association between tranexamic
                  acid and decreased periprosthetic joint infection risk in patients undergoing total hip and knee
                  arthroplasty: a systematic review and meta-analysis of over 2 million patients</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2024
                Sep;39(9):2389-2394.e2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20between%20tranexamic%20acid%20and%20decreased%20periprosthetic%20joint%20infection%20risk%20in%20patients%20undergoing%20total%20hip%20and%20knee%20arthroplasty%3A%20a%20systematic%20review%20and%20meta-analysis%20of%20over%202%20million%20patients&as_occt=title&as_sauthors=%20%22KA%20Elmenawi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_182" class="annotation" style="position: unset;"> YaDeau JT</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Cushner FD</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Westrich G</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Lauzadis J</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Kahn RL</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Lin Y</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Goytizolo EA</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Mayman DJ</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Jules-Elysee KM</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Gbaje E</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Padgett DE</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">What is the role of a
                  periarticular injection for knee arthroplasty patients receiving a multimodal analgesia regimen
                  incorporating adductor canal and infiltration between the popliteal artery and capsule of the knee
                  blocks? A randomized blinded placebo-controlled noninferiority trial</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Anesth Analg.</span> 2024
                Jun 1;138(6):1163-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20is%20the%20role%20of%20a%20periarticular%20injection%20for%20knee%20arthroplasty%20patients%20receiving%20a%20multimodal%20analgesia%20regimen%20incorporating%20adductor%20canal%20and%20infiltration%20between%20the%20popliteal%20artery%20and%20capsule%20of%20the%20knee%20blocks%3F%20A%20randomized%20blinded%20placebo-controlled%20noninferiority%20trial&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Maniar AR</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Khokhar A</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Nayak A</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Kumar D</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Khanna I</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Maniar RN</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Addition of
                  surgeon-administered adductor canal infiltration to the periarticular infiltration in total knee
                  arthroplasty: effect on pain and early outcomes</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2024
                Aug;39(8S1):S115-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Addition%20of%20surgeon-administered%20adductor%20canal%20infiltration%20to%20the%20periarticular%20infiltration%20in%20total%20knee%20arthroplasty%3A%20effect%20on%20pain%20and%20early%20outcomes&as_occt=title&as_sauthors=%20%22AR%20Maniar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Rajani AM</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Mittal ARS</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Kulkarni VU</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Desai MK</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Dubey RR</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Rajani KA</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Rajani KA</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Duloxetine as an analgesic in
                  patients who do not have central sensitivity undergoing single-setting, bilateral total knee
                  arthroplasty: a orospective, double-blinded, randomized, placebo-controlled trial</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2024
                Aug;39(8):2055-60.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Duloxetine%20as%20an%20analgesic%20in%20patients%20who%20do%20not%20have%20central%20sensitivity%20undergoing%20single-setting%2C%20bilateral%20total%20knee%20arthroplasty%3A%20a%20orospective%2C%20double-blinded%2C%20randomized%2C%20placebo-controlled%20trial&as_occt=title&as_sauthors=%20%22AM%20Rajani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_206" class="annotation" style="position: unset;"> LeBrun DG</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Grubel J</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Ong J</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Chiu YF</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Blevins JL</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Haas SB</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Rodriguez JA</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Gausden EB</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Cushner FD</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Lee GC</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Gonzalez Della Valle A</span>,
                <span data-id="annotation_217" class="annotation" style="position: unset;"> Chalmers BP</span>. <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> The John N</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Insall Award: Does melatonin
                  improve subjective sleep quality after total knee arthroplasty? A randomized, placebo-controlled
                  trial</span>. <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2024 Aug;39(8S1):S15-21.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Insall%20Award%3A%20Does%20melatonin%20improve%20subjective%20sleep%20quality%20after%20total%20knee%20arthroplasty%3F%20A%20randomized%2C%20placebo-controlled%20trial&as_occt=title&as_sauthors=%20%22DG%20LeBrun%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Haider MA</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Lawrence KW</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Christensen T</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Schwarzkopf R</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Macaulay W</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Rozell JC</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Does melatonin improve sleep
                  following primary total knee arthroplasty? A randomized, double-blind, placebo-controlled
                  trial</span>. <span data-id="emphasis_26" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2024 Aug;39(8S1):S154-60.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20melatonin%20improve%20sleep%20following%20primary%20total%20knee%20arthroplasty%3F%20A%20randomized%2C%20double-blind%2C%20placebo-controlled%20trial&as_occt=title&as_sauthors=%20%22MA%20Haider%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Gibian JT</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Bartosiak KA</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Riegler V</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> King J</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Lucey BP</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Barrack RL</span>. <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> The CCJR(R) Gerard A</span>.
                <span data-id="strong_27" class="annotation strong" style="position: unset;">Engh Excellence in Knee
                  Research Award: Remote monitoring of sleep disturbance following total knee arthroplasty: a cautionary
                  note</span>. <span data-id="emphasis_27" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2024 Aug;39(8S1):S22-S6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Engh%20Excellence%20in%20Knee%20Research%20Award%3A%20Remote%20monitoring%20of%20sleep%20disturbance%20following%20total%20knee%20arthroplasty%3A%20a%20cautionary%20note&as_occt=title&as_sauthors=%20%22JT%20Gibian%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Abdel MP</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Salmons HI</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Larson DR</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Austin MS</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Barnes CL</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Bolognesi MP</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Della Valle CJ</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Dennis DA</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Garvin KL</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Geller JA</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Incavo SJ</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Lombardi AV Jr</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Peters CL</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Schwarzkopf R</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Sculco PK</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Springer BD</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Pagnano MW</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Berry DJ</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">The Chitranjan S. Ranawat
                  Award: Manipulation under anesthesia to treat postoperative stiffness after total knee arthroplasty: a
                  multicenter randomized clinical trial</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2024 Aug;39(8S1):S9: 14.e1.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Chitranjan%20S.%20Ranawat%20Award%3A%20Manipulation%20under%20anesthesia%20to%20treat%20postoperative%20stiffness%20after%20total%20knee%20arthroplasty%3A%20a%20multicenter%20randomized%20clinical%20trial&as_occt=title&as_sauthors=%20%22MP%20Abdel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Wenzel AN</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Hasan SA</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Chaudhry YP</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Mekkawy KL</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Oni JK</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Khanuja HS</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Ultracongruent designs compared
                  to posterior-stabilized and cruciate-retaining tibial inserts - what does the evidence tell us? A
                  systematic review and meta-analysis</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2023 Dec;38(12):2739-2749.e7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ultracongruent%20designs%20compared%20to%20posterior-stabilized%20and%20cruciate-retaining%20tibial%20inserts%20-%20what%20does%20the%20evidence%20tell%20us%3F%20A%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22AN%20Wenzel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Tille E</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Beyer F</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Lützner C</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Postler A</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Lützner J</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Better flexion but unaffected
                  satisfaction after treatment with posterior stabilized versus cruciate retaining total knee
                  arthroplasty - 2-year results of a prospective, randomized trial</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2024
                Feb;39(2):368-73.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Better%20flexion%20but%20unaffected%20satisfaction%20after%20treatment%20with%20posterior%20stabilized%20versus%20cruciate%20retaining%20total%20knee%20arthroplasty%20-%202-year%20results%20of%20a%20prospective%2C%20randomized%20trial&as_occt=title&as_sauthors=%20%22E%20Tille%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Rehman Y</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Korsvold AM</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Lerdal A</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Aamodt A</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">No difference in
                  patient-reported outcomes with cruciate-retaining, anterior-stabilized, and posterior-stabilized total
                  knee arthroplasty designs</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2023 Dec 1;105-B(12):1271-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=No%20difference%20in%20patient-reported%20outcomes%20with%20cruciate-retaining%2C%20anterior-stabilized%2C%20and%20posterior-stabilized%20total%20knee%20arthroplasty%20designs&as_occt=title&as_sauthors=%20%22Y%20Rehman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Bonanzinga T</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Gambaro FM</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Iacono F</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Leogrande F</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Di Lascio L</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Marcacci M</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">All-polyethylene versus
                  metal-backed tibial components in total knee arthroplasty: a meta-analysis of randomized controlled
                  trials</span>. <span data-id="emphasis_32" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2024 May;39(5):1353-60.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=All-polyethylene%20versus%20metal-backed%20tibial%20components%20in%20total%20knee%20arthroplasty%3A%20a%20meta-analysis%20of%20randomized%20controlled%20trials&as_occt=title&as_sauthors=%20%22T%20Bonanzinga%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Tanariyakul Y</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Kanitnate S</span>, <span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Tammachote N</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Cementless and cemented total
                  knee arthroplasties have similar outcomes but cementless patellar component migration was observed in
                  a paired randomized control trial</span>. <span data-id="emphasis_33" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2024 May;39(5):1266-72.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cementless%20and%20cemented%20total%20knee%20arthroplasties%20have%20similar%20outcomes%20but%20cementless%20patellar%20component%20migration%20was%20observed%20in%20a%20paired%20randomized%20control%20trial&as_occt=title&as_sauthors=%20%22Y%20Tanariyakul%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Jouflas AC</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Nadar AC</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Royster BW</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Smith LS</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Ziegele MJ</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Yakkanti MR</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Malkani AL</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Cementless metal-backed
                  patellar components in primary total knee arthroplasty: an average 10-year follow-up</span>. <span
                  data-id="emphasis_34" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2023
                Jun;38(6S):S137-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cementless%20metal-backed%20patellar%20components%20in%20primary%20total%20knee%20arthroplasty%3A%20an%20average%2010-year%20follow-up&as_occt=title&as_sauthors=%20%22AC%20Jouflas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Baker JF</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Nadar AC</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Jouflas AC</span>, <span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Smith LS</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Sachdeva S</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Yakkanti MR</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Malkani AL</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Cementless metal-backed
                  patellar components in primary total knee arthroplasty using an implant of modern design</span>. <span
                  data-id="emphasis_35" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2023
                Dec 1;105-B(12):1279-85.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cementless%20metal-backed%20patellar%20components%20in%20primary%20total%20knee%20arthroplasty%20using%20an%20implant%20of%20modern%20design&as_occt=title&as_sauthors=%20%22JF%20Baker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Ailaney N</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Barra MF</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Schloemann DT</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Thirukumaran CP</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Kaplan NB</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Short-term (6 months or less)
                  pain in cemented versus cementless total knee arthroplasty: a systematic review and
                  meta-analysis</span>. <span data-id="emphasis_36" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2024 Aug;39(8):2137-46.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Short-term%20(6%20months%20or%20less)%20pain%20in%20cemented%20versus%20cementless%20total%20knee%20arthroplasty%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22N%20Ailaney%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Chen AG</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Sogbein OA</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> McCalden RW</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Bohm ER</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Lanting BA</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Survivorship of modern
                  cementless total knee arthroplasty: analysis from the Canadian Joint Replacement Registry</span>.
                <span data-id="emphasis_37" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span>
                2024 Aug 8:S0883-5403(24)00808-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Survivorship%20of%20modern%20cementless%20total%20knee%20arthroplasty%3A%20analysis%20from%20the%20Canadian%20Joint%20Replacement%20Registry&as_occt=title&as_sauthors=%20%22AG%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Clement ND</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Galloway S</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Baron YJ</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Smith K</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Weir DJ</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Deehan DJ</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Robotic arm-assisted versus
                  manual (ROAM) total knee arthroplasty: a randomized controlled trial</span>. <span
                  data-id="emphasis_38" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2023
                Sep 1;105-B(9):961-70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Robotic%20arm-assisted%20versus%20manual%20(ROAM)%20total%20knee%20arthroplasty%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22ND%20Clement%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Kirchner GJ</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Stambough JB</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Jimenez E</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Nikkel LE</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Robotic-assisted TKA is not
                  associated with decreased odds of early revision: an analysis of the American Joint Replacement
                  Registry</span>. <span data-id="emphasis_39" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 2024 Feb 1;482(2):303-10.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Robotic-assisted%20TKA%20is%20not%20associated%20with%20decreased%20odds%20of%20early%20revision%3A%20an%20analysis%20of%20the%20American%20Joint%20Replacement%20Registry&as_occt=title&as_sauthors=%20%22GJ%20Kirchner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Fontalis A</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Luyckx T</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Vanspauwen T</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Moreels R</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Mancino F</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Raj RD</span>, <span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Winnock de Grave P</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Plastow R</span>, <span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Putzeys P</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Haddad FS</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Strong correlation between
                  standing long-leg radiographs and CT scans in measuring coronal knee alignment</span>. <span
                  data-id="emphasis_40" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2024 May 13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Strong%20correlation%20between%20standing%20long-leg%20radiographs%20and%20CT%20scans%20in%20measuring%20coronal%20knee%20alignment&as_occt=title&as_sauthors=%20%22A%20Fontalis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Tian Y</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Ahmed AG</span>, <span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Hiredesai AN</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Huang LW</span>, <span
                  data-id="annotation_322" class="annotation" style="position: unset;"> Patel AM</span>, <span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Ghomrawi HMK</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">The cost-effectiveness of
                  computer-assisted compared with conventional total knee arthroplasty: a payer’s perspective</span>.
                <span data-id="emphasis_41" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2024 Sep 18;106(18):1680-7.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=11c3f406-011f-4050-9617-f7c7894ecdee" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Liow MHL</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Flevas DA</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Braun S</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Nocon A</span>, <span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Lee GC</span>, <span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Sculco PK</span>; <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">TKAF Consortium. Rotating hinge
                  revision total knee arthroplasty provides greater arc of motion gains for patients who have severe
                  arthrofibrosis</span>. <span data-id="emphasis_42" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2024 Aug 9:S0883-5403(24)00655-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=TKAF%20Consortium.%20Rotating%20hinge%20revision%20total%20knee%20arthroplasty%20provides%20greater%20arc%20of%20motion%20gains%20for%20patients%20who%20have%20severe%20arthrofibrosis&as_occt=title&as_sauthors=%20%22MHL%20Liow%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Schnetz M</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Ewald L</span>, <span
                  data-id="annotation_332" class="annotation" style="position: unset;"> Jakobi T</span>, <span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Klug A</span>, <span
                  data-id="annotation_334" class="annotation" style="position: unset;"> Hoffmann R</span>, <span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Gramlich Y</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Use of hinged implants for
                  multistage revision knee arthroplasty for severe periprosthetic joint infection: remission rate and
                  outcomes after a minimum follow-up of 5 years</span>. <span data-id="emphasis_43"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2024 Jul
                26:S0883-5403(24)00798-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Use%20of%20hinged%20implants%20for%20multistage%20revision%20knee%20arthroplasty%20for%20severe%20periprosthetic%20joint%20infection%3A%20remission%20rate%20and%20outcomes%20after%20a%20minimum%20follow-up%20of%205%20years&as_occt=title&as_sauthors=%20%22M%20Schnetz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Kreinces JB</span>, <span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Ashkenazi I</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> Shichman I</span>, <span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Roof MA</span>, <span
                  data-id="annotation_340" class="annotation" style="position: unset;"> Schwarzkopf R</span>, <span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Aggarwal VK</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">The James A. Rand Young
                  Investigator’s Award: Keeping it simple: are all Musculoskeletal Infection Society tests useful to
                  diagnose periprosthetic joint infection?</span><span data-id="emphasis_44" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2024 Sep;39(9S2):S3: 7.e1.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20James%20A.%20Rand%20Young%20Investigator%E2%80%99s%20Award%3A%20Keeping%20it%20simple%3A%20are%20all%20Musculoskeletal%20Infection%20Society%20tests%20useful%20to%20diagnose%20periprosthetic%20joint%20infection%3F&as_occt=title&as_sauthors=%20%22JB%20Kreinces%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Bass AR</span>, <span
                  data-id="annotation_343" class="annotation" style="position: unset;"> Mehta B</span>, <span
                  data-id="annotation_344" class="annotation" style="position: unset;"> Sculco PK</span>, <span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_346" class="annotation" style="position: unset;"> Do HT</span>, <span
                  data-id="annotation_347" class="annotation" style="position: unset;"> Glaser KKJ</span>, <span
                  data-id="annotation_348" class="annotation" style="position: unset;"> Aude C</span>, <span
                  data-id="annotation_349" class="annotation" style="position: unset;"> Carli AV</span>, <span
                  data-id="annotation_350" class="annotation" style="position: unset;"> Figgie MP</span>, <span
                  data-id="annotation_351" class="annotation" style="position: unset;"> Goodman SM</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">Temporal trends in the rate of
                  revision total knee arthroplasty for prosthetic joint infection</span>. <span data-id="emphasis_45"
                  class="annotation emphasis" style="position: unset;">Arthroplast Today.</span> 2024 Jul
                3;28:101442.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Temporal%20trends%20in%20the%20rate%20of%20revision%20total%20knee%20arthroplasty%20for%20prosthetic%20joint%20infection&as_occt=title&as_sauthors=%20%22AR%20Bass%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_352" class="annotation" style="position: unset;"> van Veghel MHW</span>, <span
                  data-id="annotation_353" class="annotation" style="position: unset;"> van Steenbergen LN</span>, <span
                  data-id="annotation_354" class="annotation" style="position: unset;"> Wertheim HFL</span>, <span
                  data-id="annotation_355" class="annotation" style="position: unset;"> van der Kooi TII</span>, <span
                  data-id="annotation_356" class="annotation" style="position: unset;"> Schreurs BW</span>, <span
                  data-id="annotation_357" class="annotation" style="position: unset;"> Hannink G</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Early periprosthetic joint
                  infections in total hip and knee arthroplasty: microorganisms, mortality, and implant survival using a
                  combined dataset from the Dutch Arthroplasty Register and the Dutch National Nosocomial Surveillance
                  Network</span>. <span data-id="emphasis_46" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2024 Jul 16:S0883-5403(24)00721-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Early%20periprosthetic%20joint%20infections%20in%20total%20hip%20and%20knee%20arthroplasty%3A%20microorganisms%2C%20mortality%2C%20and%20implant%20survival%20using%20a%20combined%20dataset%20from%20the%20Dutch%20Arthroplasty%20Register%20and%20the%20Dutch%20National%20Nosocomial%20Surveillance%20Network&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_358" class="annotation" style="position: unset;"> Yapar A</span>, <span
                  data-id="annotation_359" class="annotation" style="position: unset;"> Köse Ö</span>, <span
                  data-id="annotation_360" class="annotation" style="position: unset;"> Özdöl Ç</span>, <span
                  data-id="annotation_361" class="annotation" style="position: unset;"> Luo TD</span>, <span
                  data-id="annotation_362" class="annotation" style="position: unset;"> Budin M</span>, <span
                  data-id="annotation_363" class="annotation" style="position: unset;"> Rosa GF</span>, <span
                  data-id="annotation_364" class="annotation" style="position: unset;"> Gehrke T</span>, <span
                  data-id="annotation_365" class="annotation" style="position: unset;"> Citak M</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Increased involvement of
                  Staphylococcus epidermidis in the rise of polymicrobial periprosthetic joint infections</span>. <span
                  data-id="emphasis_47" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2024
                May 31:S0883-5403(24)00547-3.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Increased%20involvement%20of%20Staphylococcus%20epidermidis%20in%20the%20rise%20of%20polymicrobial%20periprosthetic%20joint%20infections&as_occt=title&as_sauthors=%20%22A%20Yapar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_366" class="annotation" style="position: unset;"> Luo TD</span>, <span
                  data-id="annotation_367" class="annotation" style="position: unset;"> Budin M</span>, <span
                  data-id="annotation_368" class="annotation" style="position: unset;"> Karlidag T</span>, <span
                  data-id="annotation_369" class="annotation" style="position: unset;"> Lausmann C</span>, <span
                  data-id="annotation_370" class="annotation" style="position: unset;"> Gehrke T</span>, <span
                  data-id="annotation_371" class="annotation" style="position: unset;"> Citak M</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">Risk factors and
                  microbiological profile of knee periprosthetic joint infections with sinus tract</span>. <span
                  data-id="emphasis_48" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2024
                Jul 3:S0883-5403(24)00683-1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20and%20microbiological%20profile%20of%20knee%20periprosthetic%20joint%20infections%20with%20sinus%20tract&as_occt=title&as_sauthors=%20%22TD%20Luo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_372" class="annotation" style="position: unset;"> Vargas-Reverón C</span>, <span
                  data-id="annotation_373" class="annotation" style="position: unset;"> Fernández-Valencia JÁ</span>,
                <span data-id="annotation_374" class="annotation" style="position: unset;"> Martínez-Pastor JC</span>,
                <span data-id="annotation_375" class="annotation" style="position: unset;"> Combalia A</span>, <span
                  data-id="annotation_376" class="annotation" style="position: unset;"> Soriano Á</span>, <span
                  data-id="annotation_377" class="annotation" style="position: unset;"> Muñoz-Mahamud E</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">Prevalence and impact of
                  unexpected positive intraoperative cultures in total hip or knee revision surgery</span>. <span
                  data-id="emphasis_49" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2024
                Jul 23:S0883-5403(24)00754-X. [Epub ahead of print].</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20and%20impact%20of%20unexpected%20positive%20intraoperative%20cultures%20in%20total%20hip%20or%20knee%20revision%20surgery&as_occt=title&as_sauthors=%20%22C%20Vargas-Rever%C3%B3n%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_378" class="annotation" style="position: unset;"> Wu CJ</span>, <span
                  data-id="annotation_379" class="annotation" style="position: unset;"> Leal J</span>, <span
                  data-id="annotation_380" class="annotation" style="position: unset;"> Seyler TM</span>, <span
                  data-id="annotation_381" class="annotation" style="position: unset;"> Jiranek WA</span>, <span
                  data-id="annotation_382" class="annotation" style="position: unset;"> Wellman SS</span>, <span
                  data-id="annotation_383" class="annotation" style="position: unset;"> Bolognesi MP</span>, <span
                  data-id="annotation_384" class="annotation" style="position: unset;"> Ryan SP</span>. <span
                  data-id="strong_50" class="annotation strong" style="position: unset;">Unsuspected positive
                  intraoperative cultures in aseptic revision knee arthroplasty: prevalence, management, and
                  infection-free survivorship</span>. <span data-id="emphasis_50" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2024 Oct;39(10):2581-90.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Unsuspected%20positive%20intraoperative%20cultures%20in%20aseptic%20revision%20knee%20arthroplasty%3A%20prevalence%2C%20management%2C%20and%20infection-free%20survivorship&as_occt=title&as_sauthors=%20%22CJ%20Wu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_385" class="annotation" style="position: unset;"> Barberis L</span>, <span
                  data-id="annotation_386" class="annotation" style="position: unset;"> Abdelrahman T</span>, <span
                  data-id="annotation_387" class="annotation" style="position: unset;"> Driscoll DA</span>, <span
                  data-id="annotation_388" class="annotation" style="position: unset;"> Daşcı MF</span>, <span
                  data-id="annotation_389" class="annotation" style="position: unset;"> Gehrke T</span>, <span
                  data-id="annotation_390" class="annotation" style="position: unset;"> Citak M</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">Unexpected positive cultures in
                  hip and knee periprosthetic fractures</span>. <span data-id="emphasis_51" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2024 Jul;39(7):1834-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Unexpected%20positive%20cultures%20in%20hip%20and%20knee%20periprosthetic%20fractures&as_occt=title&as_sauthors=%20%22L%20Barberis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_391" class="annotation" style="position: unset;"> Kraus KR</span>, <span
                  data-id="annotation_392" class="annotation" style="position: unset;"> Harris AC</span>, <span
                  data-id="annotation_393" class="annotation" style="position: unset;"> Ziemba-Davis M</span>, <span
                  data-id="annotation_394" class="annotation" style="position: unset;"> Buller LT</span>, <span
                  data-id="annotation_395" class="annotation" style="position: unset;"> Meneghini RM</span>. <span
                  data-id="strong_52" class="annotation strong" style="position: unset;">Fellowship-trained surgeons
                  experience a learning curve performing revision total joint arthroplasty</span>. <span
                  data-id="emphasis_52" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2024
                Aug 10:S0883-5403(24)00833-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fellowship-trained%20surgeons%20experience%20a%20learning%20curve%20performing%20revision%20total%20joint%20arthroplasty&as_occt=title&as_sauthors=%20%22KR%20Kraus%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D64c9460c-78b1-4c68-b1a1-81d73cb09e25%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Guest Editorial</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 15, 2025;
                      107 (2): 113</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.01130</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 20, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_53"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopaedic Surgery, London Health Sciences Centre, London, Ontario,
                            Canada</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;64c9460c-78b1-4c68-b1a1-81d73cb09e25&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=64c9460c-78b1-4c68-b1a1-81d73cb09e25&type=pdf&name=JBJS.24.01130.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=64c9460c-78b1-4c68-b1a1-81d73cb09e25&type=pdf&name=JBJS.24.01130.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_53"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_54" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I306" target="_blank" data-id="link_1"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I306</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;64c9460c-78b1-4c68-b1a1-81d73cb09e25&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=64c9460c-78b1-4c68-b1a1-81d73cb09e25&type=zip&name=JBJS.24.01130.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">T. David Luo, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8466-5154" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8466-5154</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Samuel Rosas, MD, PhD, MBA<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7330-3753" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7330-3753</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Brent A. Lanting, MD, FRCSC, BEng, MSc<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:brent.lanting@lhsc.on.ca" class=""
                    style="position: unset;">brent.lanting@lhsc.on.ca</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8176-3824" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8176-3824</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Indiana Joint Replacement Institute, Fort Wayne, Indiana</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">London Health Sciences Centre, London, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Duke University Medical Center, Durham, North Carolina</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 37429.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
