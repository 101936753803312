import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useAppTheme } from "@hooks/useAppTheme";
import { supabaseImageUrlParser } from "@memorang/ui";
import Responses from "@memorang/ui/src/components/ChallengeCard/Responses";
import { BlurView } from "expo-blur";
/* ----------------- Third parties --------------- */
import { ImageBackground } from "expo-image";
import { StyleSheet, View } from "react-native";
import { List, Text } from "react-native-paper";
interface Props {
	title: string;
	description: string;
	imageUri: string;
	onPress: (id: string) => void;
	locked?: boolean;
	completed?: boolean;
	isCME?: boolean;
	numResponses?: number;
	id: number;
}

const CHALLENGE_IMAGE_WIDTH = 92;
const CHALLENGE_IMAGE_HEIGHT = 56;

const ChallengeListItem = ({
	title,
	description,
	imageUri,
	onPress,
	locked,
	completed,
	isCME,
	numResponses = 0,
}: Props) => {
	const theme = useAppTheme();

	const optimizedImageUri = supabaseImageUrlParser({
		src: imageUri,
		width: CHALLENGE_IMAGE_WIDTH,
	});

	return (
		<List.Item
			title={title}
			onPress={() => onPress(title)}
			description={description}
			style={{
				paddingHorizontal: 16,
			}}
			descriptionStyle={{
				paddingTop: 4,
			}}
			right={(p) =>
				isCME ? (
					<List.Icon
						{...p}
						icon={() => <Text variant="labelMedium">+1/12</Text>}
					/>
				) : (
					<List.Icon
						{...p}
						icon={() => <Responses numResponses={numResponses} />}
					/>
				)
			}
			left={(p) => (
				<ImageBackground
					{...p}
					source={{ uri: optimizedImageUri }}
					style={{
						width: CHALLENGE_IMAGE_WIDTH,
						height: CHALLENGE_IMAGE_HEIGHT,
						justifyContent: "center",
						alignItems: "center",
						position: "relative",
						borderRadius: 4,
						overflow: "hidden",
					}}
				>
					{(completed || isCME) && (
						<View
							style={{
								...StyleSheet.absoluteFillObject,
								backgroundColor: theme.colors.backdrop,
							}}
						/>
					)}
					{completed || isCME ? (
						<Icon
							name="checkbox-marked-circle"
							size={24}
							color={theme.palette.success.light}
						/>
					) : null}
					{locked && (
						<BlurView
							style={{
								...StyleSheet.absoluteFillObject,
								justifyContent: "center",
								alignItems: "center",
							}}
							intensity={10}
						>
							<Icon name="lock-outline" size={24} color={theme.colors.white} />
						</BlurView>
					)}
				</ImageBackground>
			)}
		/>
	);
};

export default ChallengeListItem;
