/* ----------------- React Native --------------- */
import { ScrollView, type ScrollViewProps } from "react-native";

/* ----------------- Components --------------- */
import Container from "../Container";

/* ----------------- Types --------------- */
import type { AlignItems } from "../Box";

type Props = {
	children: React.ReactNode;
	maxWidth?: "xs" | "sm" | "md" | "lg";
	alignItems?: AlignItems;
} & ScrollViewProps;
const ScrollContainer = ({
	children,
	maxWidth,
	alignItems,
	...rest
}: Props) => {
	return (
		<ScrollView
			showsVerticalScrollIndicator={false}
			contentContainerStyle={{
				flexGrow: 1,
			}}
			{...rest}
		>
			<Container alignItems={alignItems} maxWidth={maxWidth || "md"}>
				{children}
			</Container>
		</ScrollView>
	);
};

export default ScrollContainer;
