import { Box } from "@memorang/ui";
import CustomListItem from "@memorang/ui/src/components/CustomListItem";
import CustomListSubheaderContainer from "components/CustomListSubheaderContainer";
import { List, RadioButton } from "react-native-paper";
import { CME_EXPORT_CONSTANTS } from "../constants";

type Props = {
	selectedMethod: string;
	onSelect: (id: string) => void;
	toEmail: string;
};

export const ExportMethodSelector = ({
	selectedMethod,
	onSelect,
	toEmail,
}: Props) => {
	return (
		<Box>
			<CustomListSubheaderContainer>
				Choose export method
			</CustomListSubheaderContainer>
			{CME_EXPORT_CONSTANTS.EXPORT_METHODS(toEmail).map((method) => (
				<CustomListItem
					key={method.id}
					title={method.title}
					description={method.subtitle}
					onPress={() => onSelect(method.id)}
					style={{
						paddingHorizontal: 16,
					}}
					titleStyle={{
						fontFamily: "OpenSans-SemiBold",
					}}
					descriptionStyle={{
						fontSize: 12,
						fontFamily: "OpenSans-SemiBold",
					}}
					left={() => <List.Icon icon={method.icon} />}
					right={(p) => (
						<List.Icon
							{...p}
							icon={() => (
								<RadioButton.Android
									value={method.id}
									status={
										selectedMethod === method.id ? "checked" : "unchecked"
									}
									onPress={() => onSelect(method.id)}
								/>
							)}
						/>
					)}
				/>
			))}
		</Box>
	);
};
