import type { Article } from "../../../../../types";

export const Article663: Article = {
	id: 663,
	rsuiteId: "2e6a58ea-643d-463b-865c-f04de49f27ba",
	type: "scientific article",
	title:
		"Determining If Post-Injury Financial Distress Was Preexisting or Injury-Induced",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=2e6a58ea-643d-463b-865c-f04de49f27ba&type=jpeg&name=JBJS.24.00345f1",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Determining If Post-Injury Financial Distress Was
                Preexisting or Injury-Induced</div>
              <div class="text subtitle" style="position: unset;">A Prospective Cohort Study of Patients with
                Lower-Extremity Trauma</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Nathan N.
                      O’Hara, PhD, MHA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Mark J. Gage,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 10 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Background:</span> Previous research has estimated that over one-half
                        of patients with orthopaedic trauma experience financial distress after the injury. However, it
                        is unknown what proportion of patients lived under financial distress before the injury and,
                        therefore, the causal effect of the injury on financial distress. This study examined changes in
                        financial distress after the injury and factors associated with new post-injury financial
                        distress.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Methods:</span> A prospective cohort study was performed at a single
                        academic trauma center, leveraging patients’ 2-week recall of their pre-injury financial
                        circumstances to permit a quasi-experiment design. Adult patients with a surgically treated
                        lower-extremity fracture were included. The primary outcome was self-reported financial
                        distress. Pre-injury financial distress was compared with financial distress in the 6 months
                        after the injury. Multinomial logistic regression was used to identify factors associated with
                        new financial distress after the injury.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Results:</span> A total of 200 study participants were enrolled
                        (median age, 42 years [interquartile range, 32 to 59 years]); 56% of patients were male.
                        Financial distress was present in 40% of the study participants before the injury. The fracture
                        was associated with an absolute increase of 19% (95% confidence interval [CI], 5% to 34%; p &lt;
                        0.001) in the prevalence of financial distress. New post-injury financial distress was
                        associated with working before the injury (odds ratio [OR], 6.9 [95% CI, 2.2 to 22]; p &lt;
                        0.001) and earning &lt;$70,000 per year (OR, 3.6 [95% CI, 1.2 to 10]; p = 0.02).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The findings suggest that 2 of 5 patients with a
                        lower-extremity fracture had experienced financial distress before the injury. The prevalence of
                        financial distress increased to 3 of 5 after the injury. Working and earning &lt;$70,000 per
                        year before the injury substantially elevated the patients’ risk of new financial distress
                        post-injury. Future efforts should target interventions to reduce financial distress in this
                        at-risk population.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level II</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Lower-extremity injuries can have devastating impacts on
                  patients’ lives<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">3</span></a>. The physical and mental consequences of these injuries have
                  been extensively explored<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">5</span></a>, yet
                  the financial implications of these injuries remain less defined. Studies that draw meaningful
                  inferences on the patient’s socioeconomic well-being and the injury’s role in further worsening a
                  patient’s financial condition are needed to augment postoperative and discharge planning protocols<a
                    href="" data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">11</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Financial toxicity describes the financial hardship,
                  distress, and strain experienced by patients related to the health-care condition, including the
                  direct and indirect costs<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">12</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">13</span></a>. Various instruments have been used to measure the
                  prevalence of financial toxicity and distress among patients after orthopaedic trauma, with estimates
                  ranging from 25% to 88%<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">10</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">15</span></a>.
                  Previous cross-sectional studies identified low income, a lack of health insurance, and being of
                  working age (18 to 65 years of age) to be risk factors for increased financial toxicity<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">15</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">16</span></a>. However, these factors have not yet been evaluated
                  prospectively. Furthermore, it is unknown to what extent the patient’s financial distress was present
                  before the injury or was caused by the injury.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">This study aimed to estimate the change in financial
                  toxicity, as measured by financial distress and financial impact, associated with a lower-extremity
                  fracture. We hypothesized that a lower-extremity fracture would increase financial distress. We also
                  assessed if certain patient factors were associated with the onset of financial distress after the
                  injury. These factors included the adverse effects of multiple planned operations<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">10</span></a> and economic connectedness, a novel measure of social
                  capital<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">17</span></a>.
                  Finally, we determined if patient access to new forms of supplemental income offset financial toxicity
                  after the injury and whether new financial distress increased the likelihood of an unplanned
                  reoperation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Design</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">We performed a prospective cohort study that leveraged the
                  patients’ 2-week recall of their pre-injury financial circumstances to permit a quasi-experiment
                  design. The study was conducted at a single academic trauma center and enrolled patients within 2
                  weeks of the injury from August 2022 through May 2023. The study was approved by the local
                  institutional review board (HP-00102264), and all patients provided informed consent.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Participants</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Adult patients with a surgically treated fracture of the
                  lower extremity, defined as from the calcaneus to the femoral shaft, were included. We selected these
                  fractures because of their higher incidence in the working-age population and, therefore, the creation
                  of an increased risk of financial distress<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">7</span></a>. We
                  excluded patients who could not read or speak English and patients who were unable to consent because
                  of medical reasons during the 2-week enrollment window.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The primary outcome was self-reported financial distress
                  as a binary indicator. The term “financial distress” has been extensively studied in patients with
                  cancer and is defined as a subjective adverse feeling attributed to financial burden<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">20</span></a>. Previous research has demonstrated that the question used
                  to assess financial distress in this study is also sensitive to variations in financial toxicity in
                  patients with a lower-extremity fracture<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">15</span></a>.
                  The secondary outcomes included the LIMB-Q Financial Impact score to quantify the magnitude of
                  financial toxicity experienced by study participants. The LIMB-Q Financial Impact score is reported on
                  a scale from 0 to 100, with higher values indicating less financial impact. The score was created
                  specifically for patients with lower-extremity trauma. Both the self-reported measure of financial
                  distress and the LIMB-Q Financial Impact score have been validated in patients with lower-extremity
                  fracture<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">24</span></a>. Also assessed was self-reported supplemental income from
                  government programs and informal sources, such as family or friends.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The financial outcomes were assessed within 2 weeks of
                  admission and 3 and 6 months after the injury. For the initial assessment, study participants were
                  asked to report their financial circumstances before the injury. Patients were compensated $20 for
                  completing each of the 3 assessments. Minimal recall bias is suspected in this reporting, as previous
                  research has demonstrated excellent recall of preoperative quality of life within 4 weeks after knee
                  surgery<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">25</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Finally, all instances of unplanned reoperation at the
                  fracture site occurring between 3 and 6 months after the injury were identified. This time period was
                  selected to determine if changes in financial distress within 3 months of the injury were associated
                  with subsequent unplanned reoperations.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Exposures</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The primary variable of interest was the injury, which
                  formed the time anchor for this analysis. However, other factors and their relationship with financial
                  toxicity in this study population were also considered. These factors included retirement age, sex,
                  marital status, education, health insurance, household income, workforce participation, fracture
                  location, and fracture severity measured as either open or closed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Our analysis also tested 2 novel measures. The first
                  measure was multiple planned operations, given the potential stress and anxiety that these create for
                  the patients and their caregivers<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">10</span></a>.
                  The second measure was economic connectedness, a community-level estimate of social capital based on
                  the work of Chetty et al.<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">17</span></a>.
                  Economic connectedness is the share of high socioeconomic status friends among low socioeconomic
                  status individuals as calculated by Chetty et al.<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">17</span></a>
                  after obtaining deidentified Facebook data on 21 billion friendships. The results are summarized by
                  United States ZIP Codes, with higher values indicating a higher degree of economic connectedness. The
                  research by Chetty et al.<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">17</span></a>
                  suggested that economic connectedness is among the strongest predictors of income mobility and, in
                  this population, may be protective against financial toxicity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The target number of participants was 200. This sample
                  size allowed us to estimate the incidence of post-injury financial distress within a 7% margin of
                  error, given a 20% change from pre-injury to post-injury and an alpha of 5%.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The study population and their financial toxicity were
                  described using counts with percentages and medians with interquartile ranges (IQRs). The association
                  between the study exposure and study outcomes was fitted using mixed-effects linear probability
                  models. The models used a binary indicator for pre-injury compared with post-injury, a continuous
                  measure of time from the injury in months, and a random intercept for the patient, which estimated the
                  within-subject change from pre-injury to post-injury. Multinomial logistic regression was used to
                  assess the association between patient factors and financial distress. In this model, the outcome was
                  coded as 1 of 3 levels: (1) the patient had financial distress after the injury, but it had already
                  existed before the injury; (2) the patient had new financial distress after the injury; and (3) the
                  patient had no financial distress at either time. The third level was used as the reference level. All
                  estimates are reported with 95% confidence intervals (CIs). The threshold for significance was p &lt;
                  0.05. The widths of those CIs were not adjusted for multiple testing. Less than 9% of the outcome data
                  were missing. Outcome data in the modeling were not imputed but were assumed to be missing at random,
                  consistent with the modeling approach. All analyses were performed using R (version 4.2.2; The R
                  Foundation).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Participants</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">During the study period, 235 patients met the eligibility
                  criteria, and 200 patients agreed to participate. The median age of the study population was 42 years
                  (IQR, 32 to 59 years), and 56% (112 patients) were male (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). Over
                  two-thirds of the study participants had worked before the injury, and the median annual pre-injury
                  household income was $60,000 (IQR, $35,000 to $100,000). Forty-two percent of study participants lived
                  in communities ranked in the top decile for economic connectedness, indicating a high integration of
                  low-income and high-income individuals within the patients’ communities, based on research by Chetty
                  et al.<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">17</span></a>.
                  The most common injuries were fractures of the femoral shaft (18%) or distal tibia (17%). One-third of
                  the fractures were open, and 15% were treated with more than 1 planned operation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Patient Characteristics (N = 200)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1902px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_30" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42 (32 to
                          59)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">112
                          (56%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87
                          (44%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Education level<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_5"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Some high school</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (13%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> High school diploma or
                          GED<span data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67
                          (34%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Trade school</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (10%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Some college but no
                          degree</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33
                          (17%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> University or college
                          degree</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (16%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Masters, PhD, or
                          professional school</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (11%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Marital status<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Single</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97
                          (49%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Married</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64
                          (32%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Divorced</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (11%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Widowed</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Common law</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Separated</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Health insurance
                          status<span data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Private or
                          employer-based</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80
                          (40%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medicaid</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63
                          (32%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medicare</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (14%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No insurance</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> TRICARE</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Annual pre-injury
                          household income<span data-id="table_footnote_reference_10"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;$30,000</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46
                          (23%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> $30,000 to
                          $49,999</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (18%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> $50,000 to
                          $69,999</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> $70,000 to
                          $99,999</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32
                          (16%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥$100,000</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48
                          (24%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Retired or on
                          pension</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Working before
                          injury<span data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">141
                          (71%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Economic
                          connectedness<span data-id="table_footnote_reference_13"
                            class="annotation table_footnote_reference" style="position: unset;">*</span>,<span
                            data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">**</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.95 (0.81
                          to 1.16)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Fracture location<span
                            data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Femoral shaft</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (18%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Distal tibia</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34
                          (17%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Proximal tibia</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (15%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tibial shaft</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (15%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Distal femur</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (13%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Malleolus</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (13%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Talus</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Patella</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Calcaneus</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Open fracture<span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66
                          (33%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of planned
                          operations<span data-id="table_footnote_reference_17"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">169
                          (85%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (16%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the median, with the IQR in parentheses.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, with the percentage in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">One participant preferred not to answer.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">GED = General Educational Development.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">Fifteen participants preferred not to answer.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">**</b> <span class="text"
                        style="position: unset;">Economic connectedness is a community-level estimate based on the data
                        produced by Chetty et al.<a href="" data-id="citation_reference_30"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_19" class="annotation superscript"
                            style="position: unset;">17</span></a><span data-id="superscript_19"
                          class="annotation superscript" style="position: unset;"></span></span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;"></b> <span class="text"
                        style="position: unset;">and is the share of high socioeconomic status friends among
                        low-socioeconomic status individuals by U.S. ZIP Code; higher values indicate a higher degree of
                        economic connectedness. For context, the national median is 0.88 (IQR, 0.73 to 1.03).</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Financial
                Distress</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Seventy-nine study participants (40%) had been
                  experiencing financial distress before the injury. Thirteen patients (7% of the sample) experienced
                  financial distress before the injury but did not experience financial distress in the 6 months after
                  the injury. Fifty-two patients (26% of the sample) did not experience financial distress before the
                  injury but did report new financial distress after the injury. Sixty-three patients (32%) experienced
                  financial distress before and after the injury. Given these data, the injury was associated with an
                  increase of 19% (95% CI, 5% to 34%; p &lt; 0.001) in financial distress and did not change
                  significantly between the 3-month and 6-month assessments (mean difference, 0% per month [95% CI, −3%
                  to 3% per month]; p = 0.96) (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1-A</a>). Among
                  study participants who reported pre-injury financial distress, the primary sources of distress were
                  living expenses (e.g., food and bills) (58%), financial debt (52%), and housing costs (53%). Among
                  study participants who reported post-injury financial distress, the primary source was a change in
                  income (77%). In comparison with patients who did not experience financial distress before or after
                  the injury, being ≥65 years of age (odds ratio [OR], 0.22 [95% CI, 0.07 to 0.71]; p = 0.01) or having
                  a household income of ≥$70,000 (OR, 0.13 [95% CI, 0.04 to 0.40]; p &lt; 0.001) were protective against
                  pre-injury financial distress (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). An
                  annual income of &lt;$70.000 was associated with increased odds of financial distress after the injury
                  (OR, 3.57 [95% CI, 1.23 to 10.03]; p = 0.02), although none of the retired patients experienced
                  financial distress after the injury (OR, 0.00 [95% CI, 0.00 to 0.00]; p &lt; 0.001). Working before
                  the injury was associated with a sevenfold increase in the odds of financial distress (OR, 6.91 [95%
                  CI, 2.19 to 21.84]; p &lt; 0.001).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00345f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=2e6a58ea-643d-463b-865c-f04de49f27ba&type=jpeg&name=JBJS.24.00345f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;">Mean financial distress (<span data-id="strong_37"
                            class="annotation strong" style="position: unset;">Fig. 1-A</span>) and LIMB-Q Financial
                          Impact score (<span data-id="strong_38" class="annotation strong"
                            style="position: unset;">Fig. 1-B</span>) before the injury and in the 6 months after the
                          injury. For the LIMB-Q Financial Impact score, higher values indicate less financial impact.
                          The error bars represent the 95% CIs.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Factors Associated with Financial Distress Before
                          the Injury and New Financial Distress After the Injury Relative to No Financial Distress<span
                            data-id="table_footnote_reference_18" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1061px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Factor</span>
                      </th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Financial Distress Before Injury vs. No Financial Distress</span>
                      </th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">New Financial Distress After Injury vs. No Financial Distress</span>
                      </th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Adjusted
                          OR†</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Adjusted
                          OR†</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age, ≥65 years</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.22 (0.07
                          to 0.71)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.45 (0.13
                          to 1.57)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.21</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male sex</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.81 (0.34
                          to 1.92)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.63</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.88 (0.36
                          to 2.12)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.77</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">College education</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.03 (0.42
                          to 2.53)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.95</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.77 (0.30
                          to 1.97)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.59</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Married or common
                          law</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.75 (0.31
                          to 1.84)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.53</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.74 (0.30
                          to 1.83)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.51</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Health insurance</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.57 (0.09
                          to 3.45)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.54</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.71 (0.11
                          to 4.80)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.73</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Top decile economic
                          connectedness</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.40 (0.58
                          to 3.36)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.45</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.42 (0.58
                          to 3.44)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.44</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Working before the
                          injury</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.42 (0.55
                          to 3.64)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.46</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.91 (2.19
                          to 21.84)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Retired before the
                          injury</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.67 (0.11
                          to 4.23)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.67</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.00 (0.00
                          to 0.00)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Annual pre-injury income
                          ≥$70,000</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.13 (0.04
                          to 0.40)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.28 (0.10
                          to 0.81)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Open fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.49 (0.20
                          to 1.20)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.60 (0.24
                          to 1.52)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.28</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Multiple surgeries in the
                          first admission</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.05 (0.30
                          to 3.67)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.93</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.26 (0.67
                          to 7.65)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.19</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The reported results were obtained from a single, multinomial logistic
                        regression model that included a 3-level categorical outcome classifying study participants as
                        having (1) no financial distress before or after the injury, (2) financial distress before the
                        injury, or (3) new financial distress reported after the injury. The group with no financial
                        distress before or after the injury forms the reference category for this analysis.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the adjusted OR, with the 95% CI in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Financial
                Impact Score</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Before the injury, the mean financial impact score was 82
                  of 100 (standard deviation, 24). Post-injury, a −10-point change (95% CI, −17 to −3 points; p &lt;
                  0.01) was observed in the financial impact score, without significant attenuation in the following 6
                  months (mean difference, 1 point [95% CI, −1 to 2 points]; p = 0.25) (<a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1-B</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Supplemental
                Income</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">In the 12 months before the injury, 102 study participants
                  (51%) were receiving supplemental income (mean, $8,785 [95% CI, $6,824 to $10,746]). The most common
                  sources of supplemental income were support from family or friends (42%), retirement income (31%),
                  disability income (14%), and Supplemental Security Income (12%). The injury was not significantly
                  associated with an increase in the proportion of patients receiving supplemental income (mean
                  difference, 9 percentage points [95% CI, −6 to 24 percentage points]; p = 0.24). The injury was also
                  not associated with an increase in the amount of supplemental income received (mean difference, $824
                  [95% CI, −$2,531 to $4,216]; p = 0.62). The sources of supplementary income were similar to the
                  pre-injury sources.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Financial
                Distress and Unplanned Reoperation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Eighteen patients (9%) required an unplanned reoperation
                  between 3 and 6 months after the injury. After adjusting for potential confounders, the odds of an
                  unplanned reoperation were not significantly different for patients experiencing financial distress
                  before the injury (OR, 1.01 [95% CI, 0.27 to 4.28]; p = 0.99) or patients with new financial distress
                  after the injury (OR, 1.55 [95% CI, 0.41 to 6.71]; p = 0.53) compared with patients who did not
                  experience financial distress throughout the study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Our findings show that approximately 2 of every 5 patients
                  with a lower-extremity fracture were experiencing financial distress before the injury. The prevalence
                  of financial distress increased to 3 of 5 after the injury. Being ≥65 years of age and having an
                  income of ≥$70,000 were protective for patients against financial distress before the injury. Being
                  retired was protective against new financial distress after the injury. Conversely, working and
                  earning &lt;$70,000 per year before the injury substantially elevated the odds of new financial
                  distress after the injury. We did not observe an increase in patients receiving financial assistance
                  to offset this new hardship.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Our estimated prevalence of financial distress after the
                  injury is consistent with previous research on patients with a tibial fracture treated at our
                  institution but is substantially higher than estimates from another U.S. trauma center<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">15</span></a>. This discrepancy is likely the result of measuring
                  financial distress using different scales. In addition, the protective associations of being at least
                  65 years of age and having an income of ≥$70,000 are consistent with previous research in the fracture
                  population<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">26</span></a>. The increased prevalence of financial distress after the
                  injury did not attenuate during the following 6 months. Previous research supports this observation
                  and the long-lasting financial effects of orthopaedic trauma<a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_22" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">15</span></a>. Therefore, early interventions should be considered to
                  prevent these long-term consequences.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The primary source of financial distress before the injury
                  stemmed from an inability to pay regular bills and expenses. Most patients have health insurance
                  coverage to protect them against catastrophic health expenditures<a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">16</span></a>. As such, it appears that the direct costs of care
                  contribute little to patients’ financial toxicity. Consequently, the primary driver of new financial
                  distress appears to involve indirect costs, specifically, a sudden reduction in income, presumably
                  from the physical impairment and psychological distress from the injury. However, meaningful gains
                  from supplementary income sources to offset these losses were rare, a finding consistent with previous
                  studies<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_24" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">8</span></a>. Paid time off, used as a broad policy lever to reduce new
                  financial distress in this population, might prevent some of this financial distress.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Several factors associated with new-onset financial
                  distress were identified. Retirement was strongly protective against new financial distress after the
                  injury. Those patients working before the injury were much more likely to sustain new post-injury
                  financial distress, as they are at risk for income loss from physical impairment. Our finding that
                  annual income of &lt;$70,000 per year was associated with increased odds of new financial distress
                  after injury is supported by a landmark paper by Kahneman and Deaton<a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">27</span></a>. Their 2010 study of U.S. residents concluded that negative
                  life events are exacerbated and evoke additional stress when the individual has an annual income of
                  &lt;$75,000. Additional research confirms that lower-income individuals experience greater relative
                  instability from income changes<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">28</span></a>.
                  Finally, our hypothesis that economic connectedness would protect against new financial distress after
                  injury was not supported by our results, as demonstrated by the lack of significance in the
                  association of living in areas in the top decile of economic connectedness and new-onset financial
                  distress (OR, 1.42 [95% CI, 0.58 to 3.44]).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The relation between multiple planned operations and
                  new-onset financial distress was also not significant, but the point estimate was large (OR, 2.26). A
                  larger sample size might have uncovered this association that has been detected in other research<a
                    href="" data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">29</span></a>. Further work is required to understand this potentially
                  bidirectional relationship between stress and anxiety invoked by the patients’ acute clinical
                  experience and new financial distress.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The study had several limitations. Pre-injury outcomes
                  were measured on the basis of patient recall within 2 weeks after the injury. We acknowledge how this
                  approach can introduce recall bias. However, research by Bryant et al.<a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">25</span></a> mitigates that concern. A longer observation window might
                  have revealed additional instances of financial distress. However, nearly all observed cases in the
                  current study occurred by 3 months after the injury, and the prevalence did not vary significantly
                  over the ensuing 3 months. There is currently no consensus on the best approach for measuring
                  financial toxicity. Our primary outcome was a binary self-report of financial distress. Thus, the
                  LIMB-Q Financial Impact score was included as a secondary outcome to provide a more nuanced measure of
                  financial toxicity, and it confirmed our primary findings. Our first assessment of post-injury
                  financial distress occurred 3 months after the injury, and we were unable to disentangle if the
                  new-onset financial distress was due to the injury or an early postoperative complication. Finally, we
                  did not collect pre-injury occupation data or the physical demands of the pre-injury employment. Both
                  factors likely impacted return-to-work timelines and, therefore, the likelihood of financial distress.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, this is the first study to measure
                  changes in financial toxicity after a lower-extremity injury. The study prospectively enrolled 200
                  patients and repeatedly measured their financial distress over 6 months, with &lt;9% missing outcome
                  data. The rate of unwillingness to participate was &lt;15%, limiting selection bias. In addition, our
                  study population was more heterogeneous than that of previous studies<a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">15</span></a>, increasing the generalizability of the findings.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, the findings suggest that 2 of 5
                  individuals who sustained a lower-extremity fracture had been living with financial distress before
                  the injury, and the injury elevated that ratio to 3 of 5. Patients who are working and earning
                  &lt;$70,000 per year before the injury are at the greatest risk of new-onset financial distress and
                  should get priority access to interventions, such as those provided by social workers and case
                  managers, to help to mitigate this added burden.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Bosse MJ</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> MacKenzie EJ</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Kellam JF</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Burgess AR</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Webb LX</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Swiontkowski MF</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Sanders RW</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Jones AL</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> McAndrew MP</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Patterson BM</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> McCarthy ML</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Travison TG</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Castillo RC</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">An analysis of outcomes of
                  reconstruction or amputation after leg-threatening injuries</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">N Engl J Med.</span> 2002 Dec
                12;347(24):1924-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=An%20analysis%20of%20outcomes%20of%20reconstruction%20or%20amputation%20after%20leg-threatening%20injuries&as_occt=title&as_sauthors=%20%22MJ%20Bosse%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;">Major
                Extremity Trauma Research Consortium (METRC). <span data-id="strong_2" class="annotation strong"
                  style="position: unset;">Outcomes Following Severe Distal Tibial, Ankle, and/or Mid/Hindfoot Trauma:
                  Comparison of Limb Salvage and Transtibial Amputation (OUTLET)</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2021 Sep
                1;103(17):1588-97.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2870381"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> McCarthy ML</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> MacKenzie EJ</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Edwin D</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Bosse MJ</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Castillo RC</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Starr A</span>; <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">LEAP study group. Psychological
                  distress associated with severe lower-limb injury</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2003
                Sep;85(9):1689-97.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=838194"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> MacKenzie EJ</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Bosse MJ</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Kellam JF</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Pollak AN</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Webb LX</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Swiontkowski MF</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Smith DG</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Sanders RW</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Jones AL</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Starr AJ</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> McAndrew MP</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Patterson BM</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Burgess AR</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Travison T</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Castillo RC</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Early predictors of long-term
                  work disability after major limb trauma</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">J Trauma.</span> 2006 Sep;61(3):688-94.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Early%20predictors%20of%20long-term%20work%20disability%20after%20major%20limb%20trauma&as_occt=title&as_sauthors=%20%22EJ%20MacKenzie%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Muscatelli S</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Spurr H</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> OʼHara NN</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> OʼHara LM</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Sprague SA</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Slobogean GP</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Prevalence of depression and
                  posttraumatic stress disorder after acute orthopaedic trauma: a systematic review and
                  meta-analysis</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2017 Jan;31(1):47-55.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20of%20depression%20and%20posttraumatic%20stress%20disorder%20after%20acute%20orthopaedic%20trauma%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22S%20Muscatelli%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Rodriguez EK</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">If socioeconomic stress is a
                  relevant factor in fracture-healing outcomes, should it also merit intervention? Commentary on an
                  article by Zachery Hong, MPHS, et al.: “The Effect of Social Deprivation on Fracture-Healing and
                  Patient-Reported Outcomes Following Intramedullary Nailing of Tibial Shaft Fractures”</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2022 Nov 16;104(22):e98.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=3512034"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> O’Hara NN</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Slobogean GP</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Klazinga NS</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Kringos DS</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Analysis of patient income in
                  the 5 years following a fracture treated surgically</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">JAMA Netw Open.</span> 2021 Feb
                1;4(2):e2034898.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Analysis%20of%20patient%20income%20in%20the%205%20years%20following%20a%20fracture%20treated%20surgically&as_occt=title&as_sauthors=%20%22NN%20O%E2%80%99Hara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> O’Hara NN</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Mullins CD</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Slobogean GP</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Harris AD</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Kringos DS</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Klazinga NS</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Association of postoperative
                  infections after fractures with long-term income among adults</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">JAMA Netw Open.</span> 2021 Apr
                1;4(4):e216673.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20of%20postoperative%20infections%20after%20fractures%20with%20long-term%20income%20among%20adults&as_occt=title&as_sauthors=%20%22NN%20O%E2%80%99Hara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> O’Hara NN</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Kringos DS</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Slobogean GP</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Degani Y</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Klazinga NS</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Patients place more of an
                  emphasis on physical recovery than return to work or financial recovery</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2021 Jun 1;479(6):1333-43.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patients%20place%20more%20of%20an%20emphasis%20on%20physical%20recovery%20than%20return%20to%20work%20or%20financial%20recovery&as_occt=title&as_sauthors=%20%22NN%20O%E2%80%99Hara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Bhashyam AR</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> McGovern MM</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Mueller T</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Heng M</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Harris MB</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Weaver MJ</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">The personal financial burden
                  associated with musculoskeletal trauma</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2019 Jul 17;101(14):1245-52.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=2102119" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Mertz K</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Eppler SL</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Thomas K</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Alokozai A</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Yao J</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Amanatullah DF</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Chou L</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Wood KB</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Safran M</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Steffner R</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Gardner M</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Kamal RN</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">The association of financial
                  distress with disability in orthopaedic surgery</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2019 Jun
                1;27(11):e522-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20association%20of%20financial%20distress%20with%20disability%20in%20orthopaedic%20surgery&as_occt=title&as_sauthors=%20%22K%20Mertz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Zafar SY</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Financial toxicity of cancer
                  care: it’s time to intervene</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">J Natl Cancer Inst.</span> 2015 Dec 11;108(5):djv370.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Financial%20toxicity%20of%20cancer%20care%3A%20it%E2%80%99s%20time%20to%20intervene&as_occt=title&as_sauthors=%20%22SY%20Zafar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Ramsey SD</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Bansal A</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Fedorenko CR</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Blough DK</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Overstreet KA</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Shankaran V</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Newcomb P</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Financial insolvency as a risk
                  factor for early mortality among patients with cancer</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">J Clin Oncol.</span> 2016 Mar
                20;34(9):980-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Financial%20insolvency%20as%20a%20risk%20factor%20for%20early%20mortality%20among%20patients%20with%20cancer&as_occt=title&as_sauthors=%20%22SD%20Ramsey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Murphy PB</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Severance S</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Savage S</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Obeng-Gyasi S</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Timsina LR</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Zarzaur BL</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Financial toxicity is
                  associated with worse physical and emotional long-term outcomes after traumatic injury</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J Trauma Acute Care
                  Surg.</span> 2019 Nov;87(5):1189-96.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Financial%20toxicity%20is%20associated%20with%20worse%20physical%20and%20emotional%20long-term%20outcomes%20after%20traumatic%20injury&as_occt=title&as_sauthors=%20%22PB%20Murphy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Mundy LR</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Zingas NH</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> McKibben N</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Healey K</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> O’Hara NN</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> O’Toole RV</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Pensy RA</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Financial toxicity is common in
                  patients after tibia fracture</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2023 Apr 1;37(4):e147-52.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Financial%20toxicity%20is%20common%20in%20patients%20after%20tibia%20fracture&as_occt=title&as_sauthors=%20%22LR%20Mundy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Law JM</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Brody M</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Cavanaugh KE</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Dy CJ</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Catastrophic health expenditure
                  in patients with lower-extremity orthopaedic trauma</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2023 Mar
                1;105(5):363-8.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=257c52a7-5fde-4a51-8872-0b9327a8c5cd" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Chetty R</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Jackson MO</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Kuchler T</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Stroebel J</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Hendren N</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Fluegge RB</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Gong S</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Gonzalez F</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Grondin A</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Jacob M</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Johnston D</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Koenen M</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Laguna-Muggenburg E</span>,
                <span data-id="annotation_113" class="annotation" style="position: unset;"> Mudekereza F</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Rutter T</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Thor N</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Townsend W</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Zhang R</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Bailey M</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Barberá P</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Bhole M</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Wernerfelt N</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Social capital I: measurement
                  and associations with economic mobility</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Nature.</span> 2022
                Aug;608(7921):108-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Social%20capital%20I%3A%20measurement%20and%20associations%20with%20economic%20mobility&as_occt=title&as_sauthors=%20%22R%20Chetty%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Mercadante S</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Aielli F</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Adile C</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Bonanno G</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Casuccio A</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Financial distress and its
                  impact on symptom expression in advanced cancer patients</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">Support Care Cancer.</span> 2021
                Jan;29(1):485-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Financial%20distress%20and%20its%20impact%20on%20symptom%20expression%20in%20advanced%20cancer%20patients&as_occt=title&as_sauthors=%20%22S%20Mercadante%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Barbaret C</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Brosse C</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Rhondali W</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Ruer M</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Monsarrat L</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Michaud P</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Schott AM</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Delgado-Guay M</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Bruera E</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Sanchez S</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Filbet M</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Financial distress in patients
                  with advanced cancer</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">PLoS One.</span> 2017 May 18;12(5):e0176470.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Financial%20distress%20in%20patients%20with%20advanced%20cancer&as_occt=title&as_sauthors=%20%22C%20Barbaret%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Delgado-Guay M</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Ferrer J</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Rieber AG</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Rhondali W</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Tayjasanant S</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Ochoa J</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Cantu H</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Chisholm G</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Williams J</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Frisbee-Hume S</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Bruera E</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Financial distress and its
                  associations with physical and emotional symptoms and quality of life among advanced cancer
                  patients</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">Oncologist.</span> 2015 Sep;20(9):1092-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Financial%20distress%20and%20its%20associations%20with%20physical%20and%20emotional%20symptoms%20and%20quality%20of%20life%20among%20advanced%20cancer%20patients&as_occt=title&as_sauthors=%20%22M%20Delgado-Guay%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Mundy LR</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Klassen A</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Grier AJ</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Gibbons C</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Lane W</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Carty MJ</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Pusic AL</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Hollenbeck ST</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Gage MJ</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Identifying factors most
                  important to lower extremity trauma patients: key concepts from the development of a patient-reported
                  outcome instrument for lower extremity trauma, The LIMB-Q</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">Plast Reconstr Surg.</span> 2020
                May;145(5):1292-301.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Identifying%20factors%20most%20important%20to%20lower%20extremity%20trauma%20patients%3A%20key%20concepts%20from%20the%20development%20of%20a%20patient-reported%20outcome%20instrument%20for%20lower%20extremity%20trauma%2C%20The%20LIMB-Q&as_occt=title&as_sauthors=%20%22LR%20Mundy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Mundy LR</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Klassen A</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Grier J</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Carty MJ</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Pusic AL</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Hollenbeck ST</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Gage MJ</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Development of a
                  patient-reported outcome instrument for patients with severe lower extremity trauma (LIMB-Q): protocol
                  for a multiphase mixed methods study</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">JMIR Res Protoc.</span> 2019 Oct 17;8(10):e14397.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20of%20a%20patient-reported%20outcome%20instrument%20for%20patients%20with%20severe%20lower%20extremity%20trauma%20(LIMB-Q)%3A%20protocol%20for%20a%20multiphase%20mixed%20methods%20study&as_occt=title&as_sauthors=%20%22LR%20Mundy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Mundy LR</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Klassen A</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Sergesketter AR</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Grier AJ</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Carty MJ</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Hollenbeck ST</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Pusic AL</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Gage MJ</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Content validity of the LIMB-Q:
                  a patient-reported outcome instrument for lower extremity trauma patients</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J Reconstr
                  Microsurg.</span> 2020 Nov;36(9):625-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Content%20validity%20of%20the%20LIMB-Q%3A%20a%20patient-reported%20outcome%20instrument%20for%20lower%20extremity%20trauma%20patients&as_occt=title&as_sauthors=%20%22LR%20Mundy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Mundy LR</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Klassen AF</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Pusic AL</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> deJong T</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Hollenbeck ST</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Gage MJ</span>; LIMB-Q
                Development Team. <span data-id="strong_24" class="annotation strong" style="position: unset;">The
                  LIMB-Q: reliability and validity of a novel patient-reported outcome measure for lower extremity
                  trauma patients</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Plast Reconstr Surg.</span> 2024 Dec 1;154(6):1332-40.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20LIMB-Q%3A%20reliability%20and%20validity%20of%20a%20novel%20patient-reported%20outcome%20measure%20for%20lower%20extremity%20trauma%20patients&as_occt=title&as_sauthors=%20%22LR%20Mundy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Bryant D</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Norman G</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Stratford P</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Marx RG</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Walter SD</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Guyatt G</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Patients undergoing knee
                  surgery provided accurate ratings of preoperative quality of life and function 2 weeks after
                  surgery</span>. <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">J
                  Clin Epidemiol.</span> 2006 Sep;59(9):984-93.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patients%20undergoing%20knee%20surgery%20provided%20accurate%20ratings%20of%20preoperative%20quality%20of%20life%20and%20function%202%20weeks%20after%20surgery&as_occt=title&as_sauthors=%20%22D%20Bryant%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Jella TK</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Cwalina TB</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Vallier HA</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Concurrent mental illness and
                  financial barriers to mental health care among a nationally representative sample of orthopaedic
                  trauma survivors</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2022 Dec 1;36(12):665-73.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Concurrent%20mental%20illness%20and%20financial%20barriers%20to%20mental%20health%20care%20among%20a%20nationally%20representative%20sample%20of%20orthopaedic%20trauma%20survivors&as_occt=title&as_sauthors=%20%22TK%20Jella%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Kahneman D</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Deaton A</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">High income improves evaluation
                  of life but not emotional well-being</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">Proc Natl Acad Sci U S A.</span> 2010 Sep 21;107(38):16489-93.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High%20income%20improves%20evaluation%20of%20life%20but%20not%20emotional%20well-being&as_occt=title&as_sauthors=%20%22D%20Kahneman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Mills G</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Amick J</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Can savings help overcome
                  income instability?</span> 2010. Accessed 2024 Jan 15. <a
                  href="https://www.urban.org/sites/default/files/publication/32771/412290-Can-Savings-Help-Overcome-Income-Instability-.PDF"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.urban.org/sites/default/files/publication/32771/412290-Can-Savings-Help-Overcome-Income-Instability-.PDF</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Can%20savings%20help%20overcome%20income%20instability%3F&as_occt=title&as_sauthors=%20%22G%20Mills%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Bernstein DN</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Lans A</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Karhade AV</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Heng M</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Poolman RW</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Schwab JH</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Tobert DG</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Are detailed, patient-level
                  social determinant of health factors associated with physical function and mental health at
                  presentation among new patients with orthopaedic conditions?</span><span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2023 May
                1;481(5):912-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Are%20detailed%2C%20patient-level%20social%20determinant%20of%20health%20factors%20associated%20with%20physical%20function%20and%20mental%20health%20at%20presentation%20among%20new%20patients%20with%20orthopaedic%20conditions%3F&as_occt=title&as_sauthors=%20%22DN%20Bernstein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="videosummary_reference" class="annotation videosummary_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node videosummary" data-id="videoabstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Video Abstract</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6368256004112&quot;,&quot;type&quot;:&quot;video&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;2e6a58ea-643d-463b-865c-f04de49f27ba&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_3" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6368256004112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-0 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="6368256004112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6368256004112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6368256004112" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_3_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/jit/2324982687001/89caf4be-13b8-41a8-8c92-340a2196758d/main/1280x720/1m10s37ms/match/image.jpg"
                        src="blob:https://www.jbjs.org/4bb1ed20-8306-4de0-8634-26812cdc4081">
                        <track kind="metadata" label="segment-metadata">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/jit/2324982687001/89caf4be-13b8-41a8-8c92-340a2196758d/main/1280x720/1m10s37ms/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">2:20</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 2:20">
                            <div class="vjs-load-progress" style="width: 0%;"><span
                                class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">0.00%</span></span>
                              <div data-start="0" data-end="0" style="left: 0%; width: 0%;"></div>
                            </div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">2:20</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_3_component_293">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_3_component_293"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">captions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button vjs-disabled"
                          type="button" title="Picture-in-Picture" aria-disabled="true" disabled="disabled"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_511_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_511_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_3_component_517_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_517_description">Beginning of dialog window. Escape will cancel and
                          close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_3_component_517">Text</legend><label
                                id="captions-foreground-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517">
                                <option id="captions-foreground-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517">
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_3_component_517">Background</legend><label
                                id="captions-background-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517">
                                <option id="captions-background-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517">
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_3_component_517">Window</legend><label
                                id="captions-window-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517">
                                <option id="captions-window-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517">
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Transparent" value="0"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_3_component_517" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_3_component_517">
                                <option id="captions-font-size-vjs_video_3_component_517-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_3_component_517" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_3_component_517">
                                <option id="vjs_video_3_component_517-None" value="none"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-None">None
                                </option>
                                <option id="vjs_video_3_component_517-Raised" value="raised"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Raised">Raised
                                </option>
                                <option id="vjs_video_3_component_517-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_3_component_517-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Uniform">Uniform
                                </option>
                                <option id="vjs_video_3_component_517-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_3_component_517" class="">Font Family</legend>
                              <select aria-labelledby="captions-font-family-vjs_video_3_component_517">
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_3_component_517-SmallCaps" value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_705_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_705_description">This is a modal window. This modal can be closed by
                          pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D2e6a58ea-643d-463b-865c-f04de49f27ba%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">February 5, 2025;
                      107 (3): 272</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00345</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">December 24, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_29"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the R Adams
                            Cowley Shock Trauma Center, Department of Orthopaedics, University of Maryland School of
                            Medicine, Baltimore, Maryland</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Disclaimer: The content is solely the
                          responsibility of the authors and does not necessarily represent the official views of the
                          National Institutes of Health.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;2e6a58ea-643d-463b-865c-f04de49f27ba&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=2e6a58ea-643d-463b-865c-f04de49f27ba&type=pdf&name=JBJS.24.00345.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=2e6a58ea-643d-463b-865c-f04de49f27ba&type=pdf&name=JBJS.24.00345.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_35"
                            class="annotation strong" style="position: unset;">Disclosure:</span> Research support was
                          received from the University of Maryland, Baltimore, Institute for Clinical & Translational
                          Research (ICTR) and National Center for Advancing Translational Sciences (NCATS) Clinical
                          Translational Science Award (CTSA) grant UL1TR003098. Partial support was also received from
                          the National Institute of Arthritis and Musculoskeletal and Skin Diseases of the National
                          Institutes of Health under Award K24AR076445. The funders had no role in the investigation or
                          manuscript development. The <span data-id="strong_36" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I349"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I349</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;2e6a58ea-643d-463b-865c-f04de49f27ba&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=2e6a58ea-643d-463b-865c-f04de49f27ba&type=zip&name=JBJS.24.00345.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nathan N. O’Hara, PhD, MHA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:nohara@som.umaryland.edu" class=""
                    style="position: unset;">nohara@som.umaryland.edu</a></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mark J. Gage, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7410-7850" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7410-7850</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Casey Loudermilk<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0000-4330-1039" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0000-4330-1039</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alice Bell, BA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0002-3403-1756" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0002-3403-1756</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">David Okhuereigbe, MS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1424-4458" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1424-4458</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Vivian Li, BA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Joshua E. Lawrence, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-5190-5532" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-5190-5532</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kristin E. Turner, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0008-8155-9186" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0008-8155-9186</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Murali Kovvur, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6258-5011" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6258-5011</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Robert V. O’Toole, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5628-6584" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5628-6584</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Gerard P. Slobogean, MD, MPH<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9111-9239" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9111-9239</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lily R. Mundy, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4821-1424" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4821-1424</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">R Adams Cowley Shock Trauma Center, Department of Orthopaedics, University of
                Maryland School of Medicine, Baltimore, Maryland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Plastic and Reconstructive Surgery, Johns Hopkins University,
                Baltimore, Maryland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 26756.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
