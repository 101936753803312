/* -----------------Child components--------------- */
import { AuthContainer } from "@memorang/ui";
import EmailForm from "./EmailForm";

interface Props {
	handleSubmit: (email: string, passcode?: string) => void;
	loading?: boolean;
	emailFromUrl?: string;
	lastLoggedInEmail?: string;
	tos?: string;
	privacy?: string;
	showPasscodeField?: boolean;
	onChangeEmail?: (email: string) => void;
}
const EmailView = ({
	handleSubmit,
	loading,
	emailFromUrl,
	lastLoggedInEmail,
	tos,
	privacy,
	showPasscodeField,
	onChangeEmail,
}: Props) => {
	return (
		<>
			<AuthContainer>
				<EmailForm
					loading={loading}
					handleSubmitEmail={handleSubmit}
					emailFromUrl={emailFromUrl}
					lastLoggedInEmail={lastLoggedInEmail}
					tos={tos}
					privacy={privacy}
					showPasscodeField={showPasscodeField}
					onChangeEmail={onChangeEmail}
				/>
			</AuthContainer>
		</>
	);
};

export default EmailView;
