import type { Article } from "../../../../../types";

export const Article643: Article = {
	id: 643,
	rsuiteId: "76d67681-a6e9-4e24-9c3f-02760cc5368f",
	type: "scientific article",
	title: "Not All Antiseptic Solutions Are Equivalent in Removing Biofilm",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=76d67681-a6e9-4e24-9c3f-02760cc5368f&type=jpeg&name=JBJS.23.01118f1",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Not All Antiseptic Solutions Are Equivalent in Removing
                Biofilm</div>
              <div class="text subtitle" style="position: unset;">A Comparison Across Different Orthopaedic Surfaces
              </div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Christina A.
                      Chao, MSc</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Tyler K.
                      Khilnani, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_38" class="annotation strong"
                          style="position: unset;">Background:</span> Antiseptic solutions are commonly utilized during
                        total joint arthroplasty (TJA) to prevent and treat periprosthetic joint infection (PJI). The
                        purpose of this study was to investigate which antiseptic solution is most effective against
                        methicillin-sensitive <span data-id="emphasis_39" class="annotation emphasis"
                          style="position: unset;">Staphylococcus aureus</span> (MSSA) and <span data-id="emphasis_40"
                          class="annotation emphasis" style="position: unset;">Escherichia coli</span> biofilms
                        established in vitro on orthopaedic surfaces commonly utilized in total knee arthroplasty:
                        cobalt-chromium (CC), oxidized zirconium (OxZr), and polymethylmethacrylate (PMMA).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_39" class="annotation strong"
                          style="position: unset;">Methods:</span> MSSA and <span data-id="emphasis_41"
                          class="annotation emphasis" style="position: unset;">E. coli</span> biofilms were grown on CC,
                        OxZr, and PMMA discs for 24 and 72 hours. Biofilm-coated discs were treated with control or
                        various antiseptic solutions for 3 minutes. Solutions included 10% povidone-iodine, a 1:1
                        mixture of 10% povidone-iodine plus 3% hydrogen peroxide, diluted povidone-iodine, 0.05%
                        chlorhexidine gluconate, and a surfactant-based formulation of ethanol, acetic acid, sodium
                        acetate, benzalkonium chloride, and water. Following treatment, discs were sonicated to quantify
                        adherent bacteria or underwent imaging with scanning electron microscopy to identify biofilm.
                        Antiseptic solutions were considered efficacious if they produced a 3-log (1,000-fold) reduction
                        in colony-forming units compared with controls.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_40" class="annotation strong"
                          style="position: unset;">Results:</span> On both OxZr and CC, 10% povidone-iodine with
                        hydrogen peroxide eradicated all MSSA, and it achieved clinical efficacy on PMMA at both 24-hour
                        MSSA biofilm (p &lt; 0.0002) and 72-hour MSSA biofilm (p = 0.002). On 72-hour MSSA biofilm, 10%
                        povidone-iodine eradicated all bacteria on OxZr and CC, and it achieved clinical efficacy on
                        PMMA (p = 0.04). On 24-hour MSSA biofilm, 10% povidone-iodine achieved efficacy on all surfaces
                        (all p &lt; 0.01). The surfactant-based formulation only achieved clinical efficacy on 72-hour
                        MSSA biofilms on CC (p = 0.04) and OxZr (p = 0.07). On 72-hour <span data-id="emphasis_42"
                          class="annotation emphasis" style="position: unset;">E. coli</span> biofilm, 10%
                        povidone-iodine with or without hydrogen peroxide achieved clinical efficacy on all surfaces. No
                        other solution achieved clinical efficacy on either MSSA or <span data-id="emphasis_43"
                          class="annotation emphasis" style="position: unset;">E. coli</span>.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_41" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Antiseptic solutions vary considerably in
                        efficacy against bacterial biofilm. The 10% povidone-iodine solution with or without hydrogen
                        peroxide consistently removed MSSA and <span data-id="emphasis_44" class="annotation emphasis"
                          style="position: unset;">E. coli</span> biofilms on multiple orthopaedic surfaces and should
                        be considered for clinical use.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_42" class="annotation strong"
                          style="position: unset;">Clinical Relevance:</span> Clinicians should be aware of the
                        differences in the efficacy of antiseptic solutions on different orthopaedic surfaces when
                        treating MSSA or <span data-id="emphasis_45" class="annotation emphasis"
                          style="position: unset;">E. coli</span> biofilms.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Periprosthetic joint infection (PJI) is a devastating
                  complication following total joint arthroplasty (TJA) and unfortunately is now the most common
                  indication for revision knee and hip arthroplasty<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>. A
                  key characteristic of PJI pathogenesis is that invading bacteria form an extracellular biofilm on the
                  surface of orthopaedic implants, negating the effect of systemic antibiotic treatment<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a> and resisting direct mechanical debridement<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a>. Consequently, delayed-onset PJI treatment (defined as
                  occurring at between 3 and 12 months) or late-onset PJI treatment (defined as occurring at &gt;12
                  months) often requires the removal of well-fixed arthroplasty implants, a morbid procedure associated
                  with bone loss and compromised function<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">5</span></a>. Improving outcomes for debridement, antibiotics, and
                  implant retention (DAIR) procedures is crucial, but previous approaches such as antibiotic powder or
                  chemical carriers<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">4</span></a> have
                  not substantially increased success rates, which still stand at only 14% to 21%<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">6</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Antiseptic irrigation solutions, used for over a century<a
                    href="" data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">7</span></a> to reduce pathogens in surgical wounds, have gained
                  popularity in joint arthroplasty because of their efficacy and safety<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">8</span></a>. Over the last 5 years, a number of new products have
                  entered the orthopaedic market, providing surgeons with choices that range from longstanding,
                  inexpensive formulations to proprietary anti-biofilm compounds that can cost several hundred dollars.
                  Our group has previously published an in vitro comparison of several antiseptic solutions on bacterial
                  biofilm formed on porous titanium and polymethylmethacrylate (PMMA)<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">9</span></a>. Although large differences between antiseptic solutions
                  were identified between the surfaces, their surface areas were not standardized. Furthermore, no study
                  to date has examined the effects of antiseptic irrigation solutions on cobalt-chromium (CC) and
                  ceramicized metal (oxidized zirconium [OxZr]), which is commonly utilized in femoral components in
                  knee arthroplasty and in femoral heads in hip arthroplasty.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Therefore, the purpose of the current study was to compare
                  the efficacy of several commercially available antiseptic solutions in eradicating established
                  biofilms formed by methicillin-sensitive <span data-id="emphasis_47" class="annotation emphasis"
                    style="position: unset;">Staphylococcus aureus</span> (MSSA) and <span data-id="emphasis_48"
                    class="annotation emphasis" style="position: unset;">Escherichia coli</span> on standardized CC,
                  OxZr, and PMMA surfaces. We hypothesized that the efficacy of irrigation solutions would vary across
                  different surfaces, irrigation solutions would be less effective in eradicating bacteria as biofilm
                  maturity increased, and efficacy patterns would be similar against MSSA and <span
                    data-id="emphasis_49" class="annotation emphasis" style="position: unset;">E. coli</span> biofilms.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Antiseptic
                Solutions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Five irrigation solutions were chosen on the basis of
                  previously published literature documenting their intraoperative use and either scientific or
                  commercial claims suggesting that they eradicate bacterial biofilm<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">20</span></a>. The tested solutions included 10% povidone-iodine (10%
                  PI), a 1:1 mixture of 10% PI and 3% hydrogen peroxide (PI + HP; Hydrox Laboratories), diluted
                  povidone-iodine (dPI) (0.35%; Aplicare), 0.05% chlorhexidine gluconate (CHG) in sterile water
                  (Irrisept; Irrimax), and a surfactant-based formulation (SBF) of ethanol, acetic acid, sodium acetate,
                  benzalkonium chloride, and water (Bactisure; Zimmer-Biomet). dPI was made by diluting 10% PI with
                  sterile water. Phosphate-buffered saline solution (PBS) was used as a control. All solutions were kept
                  sterile and were stored and prepared per manufacturer instructions. Manufacturers who sold or donated
                  materials were consulted for the appropriate storage of their products and harvesting of aliquots, but
                  their responses did not influence the experimental design and the manufacturers were not made aware of
                  the results of this study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Orthopaedic
                Surfaces</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Three orthopaedic surfaces commonly utilized in hip and
                  knee arthroplasty were tested: CC, OxZr, and PMMA. CC and OxZr discs were initially machined from a
                  wrought bar and then were polished to meet the same proprietary surface roughness limits desired for
                  clinical implants. Six CC discs were then utilized to generate a negative mold composed of silicone
                  rubber compound (Smooth-On). Surgical Simplex P (Stryker) PMMA powder and monomer were mixed under
                  sterile conditions, sectioned into the silicone molds and permitted to cure at room temperature
                  overnight in a Class-II biosafety cabinet (Baker SterilGARD). This consistently generated 36-mm
                  diameter × 6-mm-thick PMMA discs with a smooth top surface and a slightly more textured bottom
                  surface, which closely resembled their CC and OxZr counterparts (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). A pilot trial was completed to determine the consistency of
                  PMMA disc fabrication. Experimental PMMA discs were only used if their weights were within 5% of the
                  pilot mean weight. PMMA discs were kept sterile and were used experimentally within 72 hours of
                  creation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01118f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=76d67681-a6e9-4e24-9c3f-02760cc5368f&type=jpeg&name=JBJS.23.01118f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_45"
                            class="annotation strong" style="position: unset;">Figs. 1-A, 1-B, and 1-C</span> CC, OxZr,
                          and PMMA discs. Images of the CC discs (Smith+Nephew) (<span data-id="strong_46"
                            class="annotation strong" style="position: unset;">Fig. 1-A</span>), OxZr discs
                          (Smith+Nephew) (<span data-id="strong_47" class="annotation strong"
                            style="position: unset;">Fig. 1-B</span>), and PMMA discs (Surgical Simplex P radiopaque
                          bone cement [Stryker]) (<span data-id="strong_48" class="annotation strong"
                            style="position: unset;">Fig. 1-C</span>). Each disc measured 36 mm in diameter and 6 mm in
                          height.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Bacterial
                Preparation and Visual Evaluation of Biofilm Formation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">MSSA Xen36 (Perkin Elmer), with intrinsic resistance to
                  kanamycin, was utilized. This strain was chosen as <span data-id="emphasis_50"
                    class="annotation emphasis" style="position: unset;">S</span>. <span data-id="emphasis_51"
                    class="annotation emphasis" style="position: unset;">aureus</span> is a commonly isolated pathogen
                  in PJI<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">21</span></a>.
                  MSSA Xen36 has been utilized in several fundamental orthopaedic models of PJI<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_12" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">22</span></a> and
                  is derived from the parental American Type Culture Collection (ATCC) 49525 strain, which originally
                  was a clinical isolate. Tryptic soy broth (TSB) (BACTO [BD]; 30 g/L) with kanamycin (Sigma-Aldrich;
                  200 µg/mL) was used as growth media for all MSSA experiments. TSB-agar plates with kanamycin were used
                  for plating MSSA bacterial cultures in all experiments. Furthermore, <span data-id="emphasis_52"
                    class="annotation emphasis" style="position: unset;">E</span>. <span data-id="emphasis_53"
                    class="annotation emphasis" style="position: unset;">coli</span> (ATCC 25922) was investigated
                  because of the recognition of the difficulty in treating gram-negative PJI<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">23</span></a>. Luria-Bertani broth (LB [Miller’s Modification];
                  Sigma-Aldrich) was used for both agar plates and growth media.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Bacteria were streaked on TSB or LB-agar plates and
                  incubated overnight at 37°C to create a source of bacteria. A single colony from this freshly streaked
                  plate was suspended in 5 mL of TSB or LB and was grown overnight in a 37°C shaking incubator at 250
                  rpm. Following incubation, bacteria were subcultured and grown to a density of 3 × 10<span
                    data-id="superscript_14" class="annotation superscript" style="position: unset;">8</span>
                  colony-forming units (CFUs) per milliliter as detected by a spectrophotometer (Bio-Rad SmartSpec Plus;
                  OD 600). Bacteria were then diluted in TSB or LB to achieve 1 × 10<span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">7</span> CFU/mL. Sterile CC, OxZr, and PMMA
                  discs were placed into individual Petri dishes, immersed in 12 mL of TSB or LB containing 1 × 10<span
                    data-id="superscript_16" class="annotation superscript" style="position: unset;">7</span> CFU/mL of
                  MSSA or <span data-id="emphasis_54" class="annotation emphasis" style="position: unset;">E.
                    coli</span>, and maintained at 37°C in a static incubator for 72 hours, changing media every 24
                  hours.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Following completed 72-hour bacterial growth, scanning
                  electron microscopy (SEM) was performed to verify Xen36 biofilm formation. Discs were removed from
                  media and were washed 3 times with sterile PBS, were placed in 2.5% glutaraldehyde in 0.1M cacodylate
                  buffer for 20 minutes, were washed 3 times in deionized water, and were dehydrated in a series of
                  ethanol mixtures (30%, 50%, 70%, 80%, 90%, 95%, and 100%) for 10 minutes each. Discs were then left to
                  air-dry for a minimum of 3 hours. Discs were sputter-coated (Desk V; Denton Vacuum) with palladium and
                  were placed in the SEM (ZEISS Gemini 300 FE-SEM). Images were taken at increasing magnification to
                  identify individual cocci.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Exposure to
                Antiseptic Solution and Quantification of Effect</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Biofilm was grown on sterile CC, OxZr, and PMMA discs as
                  above and was maintained at 37°C in a static incubator to establish an immature biofilm (at 24 hours)
                  or a mature biofilm (at 72 hours). These time points were based on previous literature<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_17" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">26</span></a> and pilot work that confirmed that surface CFU counts
                  plateaued at 72 hours. Media were changed every 24 hours without disturbing the disc surfaces. After
                  incubation and removal of media, 12 mL of antiseptic solution or PBS was added and allowed to stand
                  for 3 minutes at room temperature. Discs were then dipped in sterile PBS 3 times and were placed in
                  new dishes containing TSB or LB broth and sonicated in an ice-water mixture for 30 minutes at 52 kHz
                  and 137 W (550HT Aquasonic; VWR Scientific Products) to dislodge viable bacteria from biofilm<a
                    href="" data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">9</span></a>. The resulting sonicated fluid was serially diluted in
                  appropriate media, plated in 50-µL aliquots on TSB or LB agar, and grown overnight at 37°C in a static
                  incubator. CFUs from each plate were then counted by observers blinded to treatment conditions. On
                  MSSA biofilm, each combination of irrigation solution and testing surface was evaluated in
                  quadruplicate and experiments were repeated (yielding 8 replicates per group). Irrigation solutions
                  that were effective against MSSA were then tested against <span data-id="emphasis_55"
                    class="annotation emphasis" style="position: unset;">E</span>. <span data-id="emphasis_56"
                    class="annotation emphasis" style="position: unset;">coli</span> on CC, OxZr, and PMMA in
                  triplicate.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The primary outcome of the study was the determination of
                  which antiseptic solutions achieved a 3-log (1,000-fold) reduction in bacterial CFU counts compared
                  with saline solution, a threshold considered to represent clinical effectiveness against biofilm, as
                  described previously<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">9</span></a>. CFU
                  counts were reported as the mean CFU/mL and the standard error of the mean. Statistical comparisons
                  were performed using the Kruskal-Wallis test, and a post hoc comparison was performed using a Dunn
                  multiple-comparison test to assess differences between groups. Significance was set at p = 0.05. All
                  analyses were performed using GraphPad Prism 9 (Dotmatics).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">SEM demonstrated robust Xen 36 biofilm formation on all
                  orthopaedic surfaces, with 1-micron spherical staphylococci organized in clusters with fibrin
                  extensions linking smaller and larger colonies (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>). PMMA
                  was, as expected, rougher than CC and OxZr, with biofilm extending throughout the peaks and valleys of
                  the complex topography.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01118f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=76d67681-a6e9-4e24-9c3f-02760cc5368f&type=jpeg&name=JBJS.23.01118f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_49"
                            class="annotation strong" style="position: unset;">Figs. 2-A through 2-F</span> SEM imaging
                          of Xen 36 biofilm on CC surfaces (<span data-id="strong_50" class="annotation strong"
                            style="position: unset;">Figs. 2-A and 2-B</span>), OxZr surfaces (<span data-id="strong_51"
                            class="annotation strong" style="position: unset;">Figs. 2-C and 2-D</span>), and PMMA
                          surfaces (<span data-id="strong_52" class="annotation strong" style="position: unset;">Figs.
                            2-E and 2-F</span>) 72 hours after inoculation with MSSA. One-micron spherical <span
                            data-id="emphasis_57" class="annotation emphasis" style="position: unset;">S</span>. <span
                            data-id="emphasis_58" class="annotation emphasis" style="position: unset;">aureus</span>
                          clusters (white triangles) are present, with interconnecting fibrin links (white arrows). Top
                          row scale bar = 10 µm, and bottom row scale bar = 2 µm.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Effects on
                Immature MSSA Biofilm</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Immature, viable MSSA biofilm was successfully grown on
                  all surfaces, with controls growing 1.59 ± 0.11 × 10<span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">8</span> CFU/mL on CC, 1.88 ± 0.30 × 10<span
                    data-id="superscript_21" class="annotation superscript" style="position: unset;">8</span> CFU/mL on
                  OxZr, and 2.65 ± 0.50 × 10<span data-id="superscript_22" class="annotation superscript"
                    style="position: unset;">7</span> CFU/mL on PMMA (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01118f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=76d67681-a6e9-4e24-9c3f-02760cc5368f&type=jpeg&name=JBJS.23.01118f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">CFU counts following treatment of 24-hour
                          (immature) MSSA biofilm. Efficacy (dotted lines) was defined as existing if antiseptic
                          solutions produced a 3-log reduction in CFU counts compared with control. PI + HP = 1:1 ratio
                          of 10% povidone-iodine [PI] and 3% hydrogen peroxide [HP], dPI = 0.35% PI in sterile water,
                          CHG = 0.05% chlorhexidine gluconate in sterile water, and SBF = ethanol, acetic acid, sodium
                          acetate, benzalkonium chloride, and water. The interquartile range is indicated by a box, the
                          median is indicated by a line within the box, and the range is indicated by whiskers. **p &lt;
                          0.01 compared with the control.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">PI + HP completely eradicated all bacteria on OxZr and CC
                  (0 CFU/mL) and achieved clinical efficacy on PMMA (1.04 ± 0.44 × 10<span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">3</span> CFU/mL; p = 0.0002). The 10% PI did
                  not eradicate all bacteria, but did achieve clinical efficacy on all surfaces (CC, 1.00 ± 0.57 ×
                  10<span data-id="superscript_24" class="annotation superscript" style="position: unset;">1</span>
                  CFU/mL, p = 0.0007; OxZr, 0.50 ± 0.50 × 10<span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">1</span> CFU/mL, p = 0.0003; and PMMA, 1.59
                  ± 0.52 × 10<span data-id="superscript_26" class="annotation superscript"
                    style="position: unset;">4</span> CFU/mL, p = 0.0004). dPI, 0.05% CHG, and SBF failed to achieve
                  clinical efficacy on any surface.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Effects on
                Mature MSSA Biofilm</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Mature MSSA biofilm was successfully grown on all 3
                  surfaces, with controls growing 1.20 ± 0.07 × 10<span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">8</span> CFU/mL on CC, 1.34 ± 0.36 × 10<span
                    data-id="superscript_28" class="annotation superscript" style="position: unset;">8</span> CFU/mL on
                  OxZr, and 1.63 ± 0.39 × 10<span data-id="superscript_29" class="annotation superscript"
                    style="position: unset;">7</span> CFU/mL on PMMA (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>). Similar
                  to the immature biofilm setting, PI + HP and 10% PI completely eradicated all viable bacteria on CC
                  and OxZr and achieved clinical efficacy on PMMA (PI + HP, 7.85 ± 2.42 × 10<span
                    data-id="superscript_30" class="annotation superscript" style="position: unset;">2</span> CFU/mL, p
                  = 0.002; 10% PI, 5.51 ± 2.00 × 10<span data-id="superscript_31" class="annotation superscript"
                    style="position: unset;">3</span> CFU/mL; p = 0.04). SBF achieved clinical efficacy only on CC (1.56
                  ± 0.19 × 10<span data-id="superscript_32" class="annotation superscript"
                    style="position: unset;">3</span> CFU/mL; p = 0.042) and OxZr (1.34 ± 0.91 × 10<span
                    data-id="superscript_33" class="annotation superscript" style="position: unset;">4</span> CFU/mL; p
                  = 0.07), but not on PMMA. The reduction on OxZr was not significant due to the wide range in efficacy
                  (<a href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4</a>). dPI and 0.05% CHG failed to achieve efficacy on any surface.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01118f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=76d67681-a6e9-4e24-9c3f-02760cc5368f&type=jpeg&name=JBJS.23.01118f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;">CFU counts following treatment of 72-hour (mature)
                          MSSA biofilm. Efficacy (dotted lines) was defined as existing if antiseptic solutions produced
                          a 3-log reduction in CFU counts compared with control. PI + HP = 1:1 ratio of 10%
                          povidone-iodine [PI] and 3% hydrogen peroxide [HP], dPI = 0.35% PI in sterile water, CHG =
                          0.05% chlorhexidine gluconate in sterile water, and SBF = ethanol, acetic acid, sodium
                          acetate, benzalkonium chloride, and water. The interquartile range is indicated by a box, the
                          median is indicated by a line within the box, and the range is indicated by whiskers. *p &lt;
                          0.05 and **p &lt; 0.01 compared with the control.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;"><span
                  data-id="emphasis_62" class="annotation emphasis" style="position: unset;">Effects on</span> E. coli
                <span data-id="emphasis_63" class="annotation emphasis" style="position: unset;">Biofilm</span></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Mature <span data-id="emphasis_59"
                    class="annotation emphasis" style="position: unset;">E</span>. <span data-id="emphasis_60"
                    class="annotation emphasis" style="position: unset;">coli</span> biofilm was successfully grown on
                  all 3 surfaces, with controls growing 7.31 ± 2.86 × 10<span data-id="superscript_34"
                    class="annotation superscript" style="position: unset;">6</span> CFU/mL on CC, 1.14 ± 0.14 × 10<span
                    data-id="superscript_35" class="annotation superscript" style="position: unset;">7</span> CFU/mL on
                  OxZr, and 9.8 ± 3.49 × 10<span data-id="superscript_36" class="annotation superscript"
                    style="position: unset;">6</span> CFU/mL on PMMA. Similar to the MSSA 72-hour biofilm, setting, PI +
                  HP and 10% PI achieved clinical efficacy on CC (PI + HP, 1.87 ± 0.86 × 10<span
                    data-id="superscript_37" class="annotation superscript" style="position: unset;">2</span> CFU/mL, p
                  = 0.055; 10% PI, 8.93 ± 6.64 × 10<span data-id="superscript_38" class="annotation superscript"
                    style="position: unset;">2</span> CFU/mL; p = 0.076), OxZr (PI + HP, 8.67 ± 4.65 × 10<span
                    data-id="superscript_39" class="annotation superscript" style="position: unset;">1</span> CFU/mL, p
                  = 0.0134; 10% PI, 8.73 ± 2.80 × 10<span data-id="superscript_40" class="annotation superscript"
                    style="position: unset;">2</span> CFU/mL; p = 0.249), and PMMA (PI + HP, 8.67 ± 0.54 × 10<span
                    data-id="superscript_41" class="annotation superscript" style="position: unset;">1</span> CFU/mL, p
                  = 0.0132; 10% PI, 1.03 ± 0.11 × 10<span data-id="superscript_42" class="annotation superscript"
                    style="position: unset;">3</span> CFU/mL; p = 0.247). dPI failed to achieve clinical efficacy on any
                  surface (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.01118f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=76d67681-a6e9-4e24-9c3f-02760cc5368f&type=jpeg&name=JBJS.23.01118f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;">CFU counts following treatment of 72-hour (mature)
                          <span data-id="emphasis_61" class="annotation emphasis" style="position: unset;">E.
                            coli</span> biofilm. Efficacy (dotted lines) was defined as existing if antiseptic solutions
                          produced a 3-log reduction in CFU counts compared with control. PI + HP = 1:1 ratio of 10%
                          povidone-iodine [PI] and 3% hydrogen peroxide [HP], dPI = 0.35% PI in sterile water, CHG =
                          0.05% chlorhexidine gluconate in sterile water, and SBF = ethanol, acetic acid, sodium
                          acetate, benzalkonium chloride, and water. The values are shown as the mean ± the standard
                          error of the mean. *p &lt; 0.05 compared with the control.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">PJI imposes serious risks of morbidity and mortality on
                  patients<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_43" class="annotation superscript" style="position: unset;">27</span></a> and
                  imposes increasing social and economic burdens on society<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_44" class="annotation superscript" style="position: unset;">28</span></a>.
                  The removal of viable bacteria within biofilm is paramount to the success of surgical treatment of
                  PJI, but success rates continue to be low<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_45" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_45" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_45" class="annotation superscript"
                      style="position: unset;">5</span></a>. Our group recently published a study comparing various
                  irrigation solutions on MSSA biofilm on common orthopaedic surfaces clinically used in total hip
                  arthroplasty, demonstrating that different solutions exhibited varying levels of clinical efficacy on
                  different surfaces<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_46" class="annotation superscript" style="position: unset;">9</span></a>. The
                  goal of this study was to expand the investigation to additional orthopaedic surfaces used in knee
                  arthroplasty and to include a gram-negative organism.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">In this current study, we observed that MSSA and <span
                    data-id="emphasis_64" class="annotation emphasis" style="position: unset;">E. coli</span> biofilms
                  grew at similar rates on all 3 tested surfaces. We also demonstrated that treatments with 10% PI and
                  10% PI + HP had the greatest effect on reducing bacterial counts in both immature and mature biofilms
                  after 3 minutes of exposure. These treatments completely eradicated bacteria from mature biofilm on CC
                  and OxZr, whereas they reached clinical efficacy on PMMA based on a previously defined threshold of a
                  3-log reduction in CFU counts<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_47" class="annotation superscript" style="position: unset;">9</span></a>. The
                  10% PI + HP consistently produced the largest reduction in CFU counts on all surfaces. In contrast,
                  the other antiseptic solutions were either completely ineffective (chlorhexidine and diluted PI) or
                  inconsistent (surfactant-based). The latter findings regarding these other solutions are especially
                  concerning when considering the substantially higher costs associated with chlorhexidine and
                  SBF-related solutions. Overall, our findings demonstrate that antiseptic efficacy varies considerably
                  against biofilm on different orthopaedic surfaces, confirming our initial hypothesis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The above results are consistent with our previous work,
                  which determined that 10% PI with or without HP produced the most consistent reduction of MSSA
                  bacterial counts on both immature and mature biofilms<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_48" class="annotation superscript" style="position: unset;">9</span></a>. The
                  strong bactericidal effect of 10% PI has also been reported elsewhere. Ernest et al. examined the
                  effect of irrigation solutions on MSSA biofilm grown for 48 hours on CC, titanium alloy, and stainless
                  steel<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_49" class="annotation superscript" style="position: unset;">29</span></a>,
                  demonstrating that 10% PI produced a 99% reduction in bacterial counts on all surfaces. Gocke et al.
                  found that a 2-minute treatment with 10% PI eradicated 95 clinical isolates of Staphylococcus
                  species<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_50" class="annotation superscript" style="position: unset;">30</span></a>. HP
                  has also been effective against <span data-id="emphasis_65" class="annotation emphasis"
                    style="position: unset;">S. epidermidis</span> biofilms on cardiac catheters, eradicating bacteria
                  through a mechanism different from that of PI, so it follows that combining these 2 solutions would
                  improve the eradication potential<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_51" class="annotation superscript" style="position: unset;">31</span></a>.
                  Interestingly, Oduwole et al. found that PI exposure to Staphylococcus and Streptococcus biofilms
                  grown on plastic<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_52" class="annotation superscript" style="position: unset;">32</span></a> led
                  to decreased genetic expression of polysaccharide intercellular adhesins, a major component of
                  bacterial surface adhesion, in the bacteria. Therefore, the literature supports use of 10% PI with or
                  without HP for treating orthopaedic biofilms encountered surgically. Efficacy against biofilm is the
                  critical feature that surgeons should consider when selecting which antiseptic solution to utilize.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Interestingly, Premkumar et al. demonstrated that 10% PI
                  with and without HP produced complete eradication of bacteria from immature biofilm on PMMA<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">9</span></a>. This contrasts with our findings that all irrigation
                  solutions demonstrated less effect on PMMA when compared with CC or OxZr, and no irrigation solution
                  was able to achieve complete eradication on PMMA. We concluded that the eradication profile on PMMA
                  may be reduced because of its rougher surface topography compared with CC and OxZr. Previous studies
                  have demonstrated that greater surface roughness and topography can increase bacterial adhesion and
                  biofilm production<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_54" class="annotation superscript" style="position: unset;">33</span></a>.
                  Similarly, Dantas et al. demonstrated that mechanical processing of PMMA to reduce surface roughness
                  reduced the amount of bacterial adhesion of <span data-id="emphasis_66" class="annotation emphasis"
                    style="position: unset;">Streptococcus sanguinis</span><a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_55" class="annotation superscript" style="position: unset;">34</span></a>.
                  Therefore, the rougher surface of the PMMA may have encouraged increased bacterial adhesion and,
                  ultimately, reduced the sensitivity of antiseptic treatments.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">There were several limitations in this study. First, the
                  in vitro nature of this study limited its translation to what occurs clinically. In vitro culture
                  techniques do not mimic the conditions of a human joint, consequently affecting how bacterial biofilm
                  forms. Biofilms formed in vivo have been found to be more resistant to antibiotics<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_56" class="annotation superscript"
                      style="position: unset;">35</span></a>, and it could be inferred that they would also be more
                  difficult to treat with antiseptic solutions. Second, tested irrigation solutions were not always
                  delivered with the same techniques as those that could be used clinically. Irrisept is packaged in a
                  bottle from which it can be squeezed and delivered at a certain pressure. Furthermore, Bactisure is
                  typically delivered through a high-pressure pulsatile lavage gun, contacting the implant at a
                  controlled velocity. As Premkumar et al. described, these delivery systems may improve efficacy<a
                    href="" data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_57" class="annotation superscript"
                      style="position: unset;">9</span></a>, but that was not captured in the present study. Finally,
                  the present study did not evaluate how these solutions affect osseointegration or tissue toxicity. PI
                  and especially chlorhexidine have been found to deleteriously affect chondrocyte and osteoblast
                  growth<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_58" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_58"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_58" class="annotation superscript"
                      style="position: unset;">37</span></a>. Future clinical investigations are needed to determine how
                  antiseptic solutions affect wound-healing and implant stability.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, antiseptic solutions differ considerably in
                  their effect against established biofilm on commonly used orthopaedic surfaces. Based on our current
                  results, clinicians should consider using 10% PI with or without HP when attempting to remove MSSA or
                  <span data-id="emphasis_67" class="annotation emphasis" style="position: unset;">E. coli</span>
                  biofilm in revision surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank Zachary Coles for laboratory support.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;">American
                Joint Replacement Registry (AJRR). 2022 <span data-id="strong_1" class="annotation strong"
                  style="position: unset;">Annual Report</span>. 2022. Accessed 2024 Aug 19. <a
                  href="https://connect.registryapps.net/2022-ajrr-annual-report" target="_blank" data-id="link_1"
                  class="link"
                  style="position: unset;">https://connect.registryapps.net/2022-ajrr-annual-report</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Annual%20Report&as_occt=title&as_sauthors=%20%22American%20Joint%20Replacement%20Registry%20(AJRR)%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Stoodley P</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Ehrlich GD</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Sedghizadeh PP</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Hall-Stoodley L</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Baratz ME</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Altman DT</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Sotereanos NG</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Costerton JW</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Demeo P</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Orthopaedic biofilm
                  infections</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Curr Orthop Pract.</span> 2011 Nov;22(6):558-63.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Orthopaedic%20biofilm%20infections&as_occt=title&as_sauthors=%20%22P%20Stoodley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Shaw T</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Winston M</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Rupp CJ</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Klapper I</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Stoodley P</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Commonality of elastic
                  relaxation times in biofilms</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Phys Rev Lett.</span> 2004 Aug 27;93(9):098102.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Commonality%20of%20elastic%20relaxation%20times%20in%20biofilms&as_occt=title&as_sauthors=%20%22T%20Shaw%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Osmon DR</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Berbari EF</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Berendt AR</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Lew D</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Zimmerli W</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Steckelberg JM</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Rao N</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Hanssen A</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Wilson WR</span>; Infectious
                Diseases Society of America. <span data-id="strong_4" class="annotation strong"
                  style="position: unset;">Diagnosis and management of prosthetic joint infection: clinical practice
                  guidelines by the Infectious Diseases Society of America</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Clin Infect Dis.</span> 2013
                Jan;56(1):e1-25.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagnosis%20and%20management%20of%20prosthetic%20joint%20infection%3A%20clinical%20practice%20guidelines%20by%20the%20Infectious%20Diseases%20Society%20of%20America&as_occt=title&as_sauthors=%20%22DR%20Osmon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Weinstein EJ</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Stephens-Shields AJ</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Newcomb CW</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Silibovsky R</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Nelson CL</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> O’Donnell JA</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Glaser LJ</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Hsieh E</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Hanberg JS</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Tate JP</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Akgün KM</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> King JT Jr</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Lo Re V 3rd</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Incidence, Microbiological
                  studies, and factors associated with prosthetic joint infection after total knee arthroplasty</span>.
                <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">JAMA Netw Open.</span>
                2023 Oct 2;6(10):e2340457.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%2C%20Microbiological%20studies%2C%20and%20factors%20associated%20with%20prosthetic%20joint%20infection%20after%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22EJ%20Weinstein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Kuiper JW</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Vos SJ</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Saouti R</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Vergroesen DA</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Graat HC</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Debets-Ossenkopp YJ</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Peters EJ</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Nolte PA</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Prosthetic joint-associated
                  infections treated with DAIR (debridement, antibiotics, irrigation, and retention): analysis of risk
                  factors and local antibiotic carriers in 91 patients</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Acta Orthop.</span> 2013
                Aug;84(4):380-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prosthetic%20joint-associated%20infections%20treated%20with%20DAIR%20(debridement%2C%20antibiotics%2C%20irrigation%2C%20and%20retention)%3A%20analysis%20of%20risk%20factors%20and%20local%20antibiotic%20carriers%20in%2091%20patients&as_occt=title&as_sauthors=%20%22JW%20Kuiper%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Worboys M</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Joseph Lister and the
                  performance of antiseptic surgery</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Notes Rec R Soc Lond.</span> 2013 Sep 20;67(3):199-209.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Joseph%20Lister%20and%20the%20performance%20of%20antiseptic%20surgery&as_occt=title&as_sauthors=%20%22M%20Worboys%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Caid M</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Valk J</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Danoff J</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Irrigation solutions in total
                  joint arthroplasty</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Spartan Med Res J.</span> 2022 Sep 6;7(2):37502.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Irrigation%20solutions%20in%20total%20joint%20arthroplasty&as_occt=title&as_sauthors=%20%22M%20Caid%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Premkumar A</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Nishtala SN</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Nguyen JT</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Bostrom MPG</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Carli AV</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">The AAHKS Best Podium
                  Presentation Research Award: Comparing the efficacy of irrigation solutions on Staphylococcal biofilm
                  formed on arthroplasty surfaces</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2021 Jul;36(7S):S26-32.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20AAHKS%20Best%20Podium%20Presentation%20Research%20Award%3A%20Comparing%20the%20efficacy%20of%20irrigation%20solutions%20on%20Staphylococcal%20biofilm%20formed%20on%20arthroplasty%20surfaces&as_occt=title&as_sauthors=%20%22A%20Premkumar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Lu M</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Hansen EN</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Hydrogen peroxide wound
                  irrigation in orthopaedic surgery</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">J Bone Jt Infect.</span> 2017 Jan 1;2(1):3-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hydrogen%20peroxide%20wound%20irrigation%20in%20orthopaedic%20surgery&as_occt=title&as_sauthors=%20%22M%20Lu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Zahar A</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Gehrke TA</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">One-stage revision for infected
                  total hip arthroplasty</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Orthop Clin North Am.</span> 2016 Jan;47(1):11-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=One-stage%20revision%20for%20infected%20total%20hip%20arthroplasty&as_occt=title&as_sauthors=%20%22A%20Zahar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Negus JJ</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Gifford PB</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Haddad FS</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Single-stage revision
                  arthroplasty for infection-an underutilized treatment strategy</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2017
                Jul;32(7):2051-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Single-stage%20revision%20arthroplasty%20for%20infection-an%20underutilized%20treatment%20strategy&as_occt=title&as_sauthors=%20%22JJ%20Negus%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Kruckenhauser EM</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Nogler M</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Coraça-Huber D</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Use of lavage fluids in
                  arthroplasty to prevent postoperative infections</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">Drug Res (Stuttg).</span> 2014
                Mar;64(3):166-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Use%20of%20lavage%20fluids%20in%20arthroplasty%20to%20prevent%20postoperative%20infections&as_occt=title&as_sauthors=%20%22EM%20Kruckenhauser%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Brown NM</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Cipriano CA</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Moric M</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Sporer SM</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Della Valle CJ</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Dilute Betadine lavage before
                  closure for the prevention of acute postoperative deep periprosthetic joint infection</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2012
                Jan;27(1):27-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dilute%20Betadine%20lavage%20before%20closure%20for%20the%20prevention%20of%20acute%20postoperative%20deep%20periprosthetic%20joint%20infection&as_occt=title&as_sauthors=%20%22NM%20Brown%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Frisch NB</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Kadri OM</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Tenbrunsel T</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Abdul-Hak A</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Qatu M</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Davis JJ</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Intraoperative chlorhexidine
                  irrigation to prevent infection in total hip and knee arthroplasty</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">Arthroplast Today.</span> 2017 May
                12;3(4):294-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intraoperative%20chlorhexidine%20irrigation%20to%20prevent%20infection%20in%20total%20hip%20and%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22NB%20Frisch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Dirschl DR</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Wilson FC</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Topical antibiotic irrigation
                  in the prophylaxis of operative wound infections in orthopedic surgery</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Orthop Clin North
                  Am.</span> 1991 Jul;22(3):419-26.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Topical%20antibiotic%20irrigation%20in%20the%20prophylaxis%20of%20operative%20wound%20infections%20in%20orthopedic%20surgery&as_occt=title&as_sauthors=%20%22DR%20Dirschl%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Williams RL</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Ayre WN</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Khan WS</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Mehta A</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Morgan-Jones R</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Acetic acid as part of a
                  debridement protocol during revision total knee arthroplasty</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2017
                Mar;32(3):953-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acetic%20acid%20as%20part%20of%20a%20debridement%20protocol%20during%20revision%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22RL%20Williams%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Whiteside LA</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Prophylactic peri-operative
                  local antibiotic irrigation</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2016 Jan;98-B(1)(Suppl A):23-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prophylactic%20peri-operative%20local%20antibiotic%20irrigation&as_occt=title&as_sauthors=%20%22LA%20Whiteside%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Ruder JA</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Springer BD</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Treatment of periprosthetic
                  joint infection using antimicrobials: dilute povidone-iodine lavage</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Bone Jt Infect.</span>
                2017 Jan 1;2(1):10-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treatment%20of%20periprosthetic%20joint%20infection%20using%20antimicrobials%3A%20dilute%20povidone-iodine%20lavage&as_occt=title&as_sauthors=%20%22JA%20Ruder%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Hart A</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Hernandez NM</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Abdel MP</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Mabry TM</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Hanssen AD</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Perry KI</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Povidone-iodine wound lavage to
                  prevent infection after revision total hip and knee arthroplasty: an analysis of 2,884 cases</span>.
                <span data-id="emphasis_19" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2019 Jul 3;101(13):1151-9.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=2087180" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Kherabi Y</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Zeller V</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Kerroumi Y</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Meyssonnier V</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Heym B</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Lidove O</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Marmor S</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Streptococcal and
                  Staphylococcus aureus prosthetic joint infections: are they really different?</span><span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">BMC Infect Dis.</span> 2022
                Jun 17;22(1):555.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Streptococcal%20and%20Staphylococcus%20aureus%20prosthetic%20joint%20infections%3A%20are%20they%20really%20different%3F&as_occt=title&as_sauthors=%20%22Y%20Kherabi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Carli AV</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Ross FP</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Bhimani SJ</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Nodzo SR</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Bostrom MP</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Developing a clinically
                  representative model of periprosthetic joint infection</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2016 Oct
                5;98(19):1666-76.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1262380"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Kalbian IL</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Goswami K</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Tan TL</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> John N</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Foltz C</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Parvizi J</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Arnold WV</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Treatment outcomes and
                  attrition in gram-negative periprosthetic joint infection</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2020
                Mar;35(3):849-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treatment%20outcomes%20and%20attrition%20in%20gram-negative%20periprosthetic%20joint%20infection&as_occt=title&as_sauthors=%20%22IL%20Kalbian%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Chen X</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Thomsen TR</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Winkler H</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Xu Y</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Influence of biofilm growth
                  age, media, antibiotic concentration and exposure time on Staphylococcus aureus and Pseudomonas
                  aeruginosa biofilm removal in vitro</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">BMC Microbiol.</span> 2020 Aug 24;20(1):264.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Influence%20of%20biofilm%20growth%20age%2C%20media%2C%20antibiotic%20concentration%20and%20exposure%20time%20on%20Staphylococcus%20aureus%20and%20Pseudomonas%20aeruginosa%20biofilm%20removal%20in%20vitro&as_occt=title&as_sauthors=%20%22X%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Hogan S</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Zapotoczna M</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Stevens NT</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Humphreys H</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> O’Gara JP</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> O’Neill E</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">In vitro approach for
                  identification of the most effective agents for antimicrobial lock therapy in the treatment of
                  intravascular catheter-related infections caused by Staphylococcus aureus</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Antimicrob Agents
                  Chemother.</span> 2016 Apr 22;60(5):2923-31.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=In%20vitro%20approach%20for%20identification%20of%20the%20most%20effective%20agents%20for%20antimicrobial%20lock%20therapy%20in%20the%20treatment%20of%20intravascular%20catheter-related%20infections%20caused%20by%20Staphylococcus%20aureus&as_occt=title&as_sauthors=%20%22S%20Hogan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Zapotoczna M</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> O’Neill E</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> O’Gara JP</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Untangling the diverse and
                  redundant mechanisms of Staphylococcus aureus biofilm formation</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">PLoS Pathog.</span> 2016 Jul
                21;12(7):e1005671.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Untangling%20the%20diverse%20and%20redundant%20mechanisms%20of%20Staphylococcus%20aureus%20biofilm%20formation&as_occt=title&as_sauthors=%20%22M%20Zapotoczna%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Premkumar A</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Kolin DA</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Farley KX</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Wilson JM</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> McLawhorn AS</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Cross MB</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Sculco PK</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Projected economic burden of
                  periprosthetic joint infection of the hip and knee in the United States</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2021
                May;36(5):1484-1489.e3.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Projected%20economic%20burden%20of%20periprosthetic%20joint%20infection%20of%20the%20hip%20and%20knee%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22A%20Premkumar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Blom A</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Cho J</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Fleischman A</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Goswami K</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Ketonis C</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Kunutsor SK</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Makar G</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Meeker DG</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Morgan-Jones R</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Ortega-Peña S</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Parvizi J</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Smeltzer M</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Stambough JB</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Urish K</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Ziliotto G</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">General assembly, prevention,
                  antiseptic irrigation solution: Proceedings of International Consensus on Orthopedic
                  Infections</span>. <span data-id="emphasis_27" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2019 Feb;34(2S):S131-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=General%20assembly%2C%20prevention%2C%20antiseptic%20irrigation%20solution%3A%20Proceedings%20of%20International%20Consensus%20on%20Orthopedic%20Infections&as_occt=title&as_sauthors=%20%22A%20Blom%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Ernest EP</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Machi AS</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Karolcik BA</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> LaSala PR</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Dietz MJ</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Topical adjuvants incompletely
                  remove adherent Staphylococcus aureus from implant materials</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">J Orthop Res.</span> 2018
                Jun;36(6):1599-604.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Topical%20adjuvants%20incompletely%20remove%20adherent%20Staphylococcus%20aureus%20from%20implant%20materials&as_occt=title&as_sauthors=%20%22EP%20Ernest%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Gocke DJ</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Ponticas S</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Pollack W</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">In vitro studies of the killing
                  of clinical isolates by povidone-iodine solutions</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">J Hosp Infect.</span> 1985Mar;6(Suppl
                A):59-66.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=In%20vitro%20studies%20of%20the%20killing%20of%20clinical%20isolates%20by%20povidone-iodine%20solutions&as_occt=title&as_sauthors=%20%22DJ%20Gocke%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Presterl E</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Suchomel M</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Eder M</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Reichmann S</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Lassnigg A</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Graninger W</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Rotter M</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Effects of alcohols,
                  povidone-iodine and hydrogen peroxide on biofilms of Staphylococcus epidermidis</span>. <span
                  data-id="emphasis_30" class="annotation emphasis" style="position: unset;">J Antimicrob
                  Chemother.</span> 2007 Aug;60(2):417-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20alcohols%2C%20povidone-iodine%20and%20hydrogen%20peroxide%20on%20biofilms%20of%20Staphylococcus%20epidermidis&as_occt=title&as_sauthors=%20%22E%20Presterl%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Oduwole KO</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Glynn AA</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Molony DC</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Murray D</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Rowe S</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Holland LM</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> McCormack DJ</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> O’Gara JP</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Anti-biofilm activity of
                  sub-inhibitory povidone-iodine concentrations against Staphylococcus epidermidis and Staphylococcus
                  aureus</span>. <span data-id="emphasis_31" class="annotation emphasis" style="position: unset;">J
                  Orthop Res.</span> 2010 Sep;28(9):1252-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anti-biofilm%20activity%20of%20sub-inhibitory%20povidone-iodine%20concentrations%20against%20Staphylococcus%20epidermidis%20and%20Staphylococcus%20aureus&as_occt=title&as_sauthors=%20%22KO%20Oduwole%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Zheng S</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Bawazir M</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Dhall A</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Kim HE</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> He L</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Heo J</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Hwang G</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Implication of surface
                  properties, bacterial motility, and hydrodynamic conditions on bacterial surface sensing and their
                  initial adhesion</span>. <span data-id="emphasis_32" class="annotation emphasis"
                  style="position: unset;">Front Bioeng Biotechnol.</span> 2021 Feb 12;9:643722.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Implication%20of%20surface%20properties%2C%20bacterial%20motility%2C%20and%20hydrodynamic%20conditions%20on%20bacterial%20surface%20sensing%20and%20their%20initial%20adhesion&as_occt=title&as_sauthors=%20%22S%20Zheng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Dantas LC</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> da Silva-Neto JP</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Dantas TS</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Naves LZ</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> das Neves FD</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> da Mota AS</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Bacterial adhesion and surface
                  roughness for different clinical techniques for acrylic polymethyl methacrylate</span>. <span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Int J Dent.</span>
                2016;2016:8685796.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bacterial%20adhesion%20and%20surface%20roughness%20for%20different%20clinical%20techniques%20for%20acrylic%20polymethyl%20methacrylate&as_occt=title&as_sauthors=%20%22LC%20Dantas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Okae Y</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Nishitani K</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Sakamoto A</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Kawai T</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Tomizawa T</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Saito M</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Kuroda Y</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Matsuda S</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Estimation of minimum biofilm
                  eradication concentration (MBEC) on <span data-id="emphasis_35" class="annotation emphasis"
                    style="position: unset;">in vivo</span> biofilm on orthopedic implants in a rodent femoral infection
                  model</span>. <span data-id="emphasis_36" class="annotation emphasis" style="position: unset;">Front
                  Cell Infect Microbiol.</span> 2022 Jul 1;12:896978.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Estimation%20of%20minimum%20biofilm%20eradication%20concentration%20(MBEC)%20on%20in%20vivo%20biofilm%20on%20orthopedic%20implants%20in%20a%20rodent%20femoral%20infection%20model&as_occt=title&as_sauthors=%20%22Y%20Okae%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Newton Ede MP</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Philp AM</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Philp A</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Richardson SM</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Mohammad S</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Jones SW</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Povidone-iodine has a profound
                  effect on in vitro osteoblast proliferation and metabolic function and inhibits their ability to
                  mineralize and form bone</span>. <span data-id="emphasis_37" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2016 May;41(9):729-34.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Povidone-iodine%20has%20a%20profound%20effect%20on%20in%20vitro%20osteoblast%20proliferation%20and%20metabolic%20function%20and%20inhibits%20their%20ability%20to%20mineralize%20and%20form%20bone&as_occt=title&as_sauthors=%20%22Ede%20MP%20Newton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Moslehyazdi M</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Bielajew B</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Schlechter JA</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Hu JC</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Athanasiou KA</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Wang D</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Detrimental effects of
                  chlorhexidine on articular cartilage viability, matrix, and mechanics</span>. <span
                  data-id="emphasis_38" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2024 Mar;52(4):1068-74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Detrimental%20effects%20of%20chlorhexidine%20on%20articular%20cartilage%20viability%2C%20matrix%2C%20and%20mechanics&as_occt=title&as_sauthors=%20%22M%20Moslehyazdi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D76d67681-a6e9-4e24-9c3f-02760cc5368f%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 15, 2025;
                      107 (2): 127</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01118</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 22, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_46"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the Hospital
                            for Special Surgery, New York, NY</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;76d67681-a6e9-4e24-9c3f-02760cc5368f&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=76d67681-a6e9-4e24-9c3f-02760cc5368f&type=pdf&name=JBJS.23.01118.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=76d67681-a6e9-4e24-9c3f-02760cc5368f&type=pdf&name=JBJS.23.01118.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_43"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The Zeiss Gemini 300
                          FE-SEM was provided through the National Institutes of Health S10 Shared Instrumentation
                          Program (grant 1S10OD026989-01). Cobalt-chromium and oxidized zirconium discs were donated by
                          Smith+Nephew. The <span data-id="strong_44" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I289"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I289</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;76d67681-a6e9-4e24-9c3f-02760cc5368f&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=76d67681-a6e9-4e24-9c3f-02760cc5368f&type=zip&name=JBJS.23.01118.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Christina A. Chao, MSc<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2060-5368" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2060-5368</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tyler K. Khilnani, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3378-6770" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3378-6770</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Suenghwan Jo, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3378-5954" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3378-5954</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Aarti Shenoy, PhD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1904-9253" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1904-9253</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mathias P.G. Bostrom, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0906-7554" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0906-7554</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alberto V. Carli, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:carlia@hss.edu" class="" style="position: unset;">carlia@hss.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3129-705X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3129-705X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Adult Reconstruction and Joint Replacement, Hospital for Special Surgery, New
                York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Weill Cornell Medical College, New York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Biomechanics, Hospital for Special Surgery, New York, NY</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 25441.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
