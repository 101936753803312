import type { Article } from "../../../../../types";

export const Article636: Article = {
	id: 636,
	rsuiteId: "27f719b7-446d-4613-8a88-3933e408577a",
	type: "the orthopaedic forum",
	title:
		"What's Important: Engaging Meaningfully with Sustainability Efforts as an Orthopaedic Surgeon",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=27f719b7-446d-4613-8a88-3933e408577a&type=jpeg&name=JBJS.24.00641f1",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">What’s Important: Engaging Meaningfully with
                Sustainability Efforts as an Orthopaedic Surgeon</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Laura L.
                      Bellaire, MD, FAAOS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">I think I have always been an environmentalist. The main
                  event at my sixth birthday party was a “rescue the whale” adaptation of pass-the-parcel: stuffed
                  whales had lovingly been tangled with black yarn by my mother, mimicking their entrapment in a fishing
                  net. My childhood friends and I then took turns unwrapping the yarn so the whales could be freed—all
                  to the tune of “Under the Sea.”</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">My intention in choosing a career in science—specifically
                  medicine—was to pursue this investment in, and care for, all members of our living world. I am in good
                  company in the field of orthopaedic surgery. Many of my peers display tremendous compassion and love
                  for their patients and families. I have come to find that many care deeply for our natural world and
                  its inhabitants as well, but few recognize opportunities for environmental stewardship in their
                  clinical work. Because this is a forum for discussing what’s important, I’d like to advocate for
                  expanding the dialogue around how we—orthopaedic surgeons—can better care for our patients <span
                    data-id="emphasis_12" class="annotation emphasis" style="position: unset;">and</span> our planet.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Interest in
                Sustainable Health-Care Delivery Is Growing</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">Health-care activities generate tremendous waste and
                  greenhouse gas emissions. The United States is the largest emitter, generating magnitudes (upward of
                  50 times) more emissions per capita than other countries<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>. The
                  burden of such damages is not shared fairly across the globe. Certain communities have been so
                  disproportionately harmed by air and water pollution and waste dumping practices that they have been
                  labeled as “human sacrifice zones.”<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a> These
                  same communities also tend to have the greatest challenges with food insecurity and access to health
                  care. Improving health access and equity begs the question: how might we prioritize individual health
                  outcomes while also minimizing the burden those treatments place on vulnerable communities and our
                  natural environment?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Currently, most sustainability efforts within health
                  systems are conducted by administrators in silos, without widespread feelings of investment or
                  relevance among physicians and other health-care workers<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>. Yet,
                  in reality, each of our choices as orthopaedic surgeons—how we plan for and communicate about upcoming
                  cases, which products we open and utilize in our clinics and operating rooms, whether we order
                  laboratory and imaging tests, and the behaviors we model for our students, residents, fellows, and
                  colleagues—has consequences. Recognition of and accountability for waste and emissions among
                  physicians is emerging, with opportunities to collaborate with other subspecialties and disciplines<a
                    href="" data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Engaging Our
                Future Workforce</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">Navigating sustainable transitions within our health
                  systems requires the input of diverse individuals and perspectives. Investing in, and uplifting
                  champions for, underserved and underrepresented populations will be critical to creating a sense of
                  shared purpose and values that span a variety of backgrounds. Generation Z has been dubbed “the
                  Sustainability Generation”; its members prioritize sustainability and have exhibited willingness to
                  pay a premium to support products and companies that perform well<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">5</span></a>. They are expected to be the “catalyst” in pushing
                  companies, services, and products toward sustainable practices through their investments and
                  purchasing behaviors, and they will soon comprise the majority of our health-care workforce and,
                  later, our patient population<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">6</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Many of our current trainees completed their training
                  during and after the COVID pandemic. Throughout the shutdown, these learners witnessed their peers and
                  mentors make tough decisions about which patients absolutely needed to be seen or treated, with a
                  marked drop in clinic visits, procedures, and surgeries. It would seem natural that—as a result—our
                  newly minted physicians, residents, fellows, and surgeons have more questions about how we might
                  balance great patient care with minimizing overconsumption and unnecessary waste of all forms:
                  material, financial, time, labor, and well-being.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Do
                Sustainability Efforts Belong in Orthopaedic Surgery?</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">I am concerned that a divide appears to be emerging
                  between those engaged in efforts to promote sustainability and those who feel that such efforts do not
                  belong in the sphere of orthopaedic surgery research and education. The latter have described climate
                  science as “controversial” and “insufficiently settled” for inclusion in orthopaedic journals or
                  medical school curricula<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">8</span></a>. These and other published and unpublished criticisms
                  diminish and discourage further inquiry and can lead to feelings of inefficacy and isolation among
                  those passionate about furthering this matter within orthopaedics<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">9</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">This divide is unnecessary. Promoting the health of our
                  patients individually and caring for patients and planet collectively are not mutually exclusive.
                  Sustainability efforts are often painted as being at odds with patient safety and outcomes, but data
                  to support this conflict are lacking. Continuing a productive, objective dialogue and generating data
                  through quality-improvement studies and research including waste audits and life-cycle assessments of
                  specific products and procedures will be critical to increasing attention to and engagement with this
                  topic. To this end, experienced surgeons can encourage conversations and innovation while modeling
                  curious, self-aware, efficient behaviors. Comfort is overrated, and there is room for each of us to
                  improve.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The Power in
                Concordance of Values</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">American physicians across specialties report sincere
                  frustration when certain patient groups and problems fall through the cracks of their health systems<a
                    href="" data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">10</span></a>. Feeling “at odds” or discordant with the organization or
                  industry within which one works can promote feelings of emotional exhaustion, depersonalization, and
                  inefficacy associated with burnout<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">11</span></a>.
                  Concordance, on the other hand, represents an alignment of core values and goals, and has been
                  demonstrated at length to promote feelings of fulfillment, satisfaction, and positive attitudes and
                  behaviors within one’s profession<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">13</span></a>. Pursuing sustainable health-care delivery provides an
                  avenue for employees to develop feelings of concordance with their health systems and colleagues and
                  can enhance organizational competitiveness<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">14</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The practice of medicine requires a commitment to lifelong
                  learning, and I invite others to join me in identifying opportunities to reduce the environmental
                  impact of orthopaedic practices (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). Other
                  industries are actively discussing and seeking creative large-scale solutions and inventions.
                  Orthopaedic surgeons are brilliant and resourceful—if we create space and support for diversity of
                  thought and innovation in this space, our specialty may emerge as a leader in the path toward a
                  healthier world for the next generation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00641f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=27f719b7-446d-4613-8a88-3933e408577a&type=jpeg&name=JBJS.24.00641f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_13" style="position: unset;">
                        <div class="content" style="position: unset;">Join conversations about sustainability as an
                          orthopaedic surgeon.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Karliner J</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Slotterback S</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Boyd R</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Ashby B</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Steele K</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Health Care’s Climate Footprint:
                  How the health sector contributes to the global climate crisis and opportunities for action</span>.
                2019. Accessed 2024 Aug 9. <a
                  href="https://global.noharm.org/sites/default/files/documents-files/5961/HealthCaresClimateFootprint_092319.pdf"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://global.noharm.org/sites/default/files/documents-files/5961/HealthCaresClimateFootprint_092319.pdf</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20Care%E2%80%99s%20Climate%20Footprint%3A%20How%20the%20health%20sector%20contributes%20to%20the%20global%20climate%20crisis%20and%20opportunities%20for%20action&as_occt=title&as_sauthors=%20%22J%20Karliner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Bullard RD</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Confronting environmental
                  racism: Voices from the grassroots.</span> South End Press; 1993.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Holmes S.</span><span
                  data-id="strong_2" class="annotation strong" style="position: unset;">We can progress sustainable
                  healthcare by breaking silos and changing behaviours</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">BMJ.</span> 2024 Apr 8;385:q813.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=We%20can%20progress%20sustainable%20healthcare%20by%20breaking%20silos%20and%20changing%20behaviours&as_occt=title&as_sauthors=%20%22S.%20Holmes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Barker NDJ</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Tukkers C</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Nelissen RGHH</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">What’s Important (Arts and
                  Humanities): Shouldn’t Our GOAL! Be to Find a Better Way?</span><span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2024 Apr
                3;106(7):639-42.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=0f21f37b-18c6-4c2a-abc3-9ff230e12bc3" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Petro G</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Gen Z Is Emerging As The
                  Sustainability Generation</span>. 2021. Accessed 2024 Aug 9. <a
                  href="https://www.forbes.com/sites/gregpetro/2021/04/30/gen-z-is-emerging-as-the-sustainability-generation/"
                  target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://www.forbes.com/sites/gregpetro/2021/04/30/gen-z-is-emerging-as-the-sustainability-generation/</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Gen%20Z%20Is%20Emerging%20As%20The%20Sustainability%20Generation&as_occt=title&as_sauthors=%20%22G%20Petro%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Versace C</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Abssy M</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">How Millennials and Gen Z Are
                  Driving Growth Behind ESG</span>. 2022. Accessed 2024 Aug 9. <a
                  href="https://www.nasdaq.com/articles/how-millennials-and-gen-z-are-driving-growth-behind-esg"
                  target="_blank" data-id="link_3" class="link"
                  style="position: unset;">https://www.nasdaq.com/articles/how-millennials-and-gen-z-are-driving-growth-behind-esg</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20Millennials%20and%20Gen%20Z%20Are%20Driving%20Growth%20Behind%20ESG&as_occt=title&as_sauthors=%20%22C%20Versace%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Bernstein J</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Not the Last Word: Climate
                  Change Comes to Orthopaedics</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2023 Oct 1;481(10):1878-85.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Not%20the%20Last%20Word%3A%20Climate%20Change%20Comes%20to%20Orthopaedics&as_occt=title&as_sauthors=%20%22J%20Bernstein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Matta JM</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Is there an
                  impending</span><span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">“Climate Disaster” that Orthopedic Surgery should be fighting?</span> 2023.
                Accessed 2024 Aug 9. <a
                  href="https://cdn-links.lww.com/permalink/jbjsel/a/jbjsel_105_1_2023_02_01_saleh_2200548-el01_sdc1.pdf"
                  target="_blank" data-id="link_4" class="link"
                  style="position: unset;">https://cdn-links.lww.com/permalink/jbjsel/a/jbjsel_105_1_2023_02_01_saleh_2200548-el01_sdc1.pdf</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20there%20an%20impending&as_occt=title&as_sauthors=%20%22JM%20Matta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Murrell AJ</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Blake-Beard S</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Porter DM Jr</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">The Importance of Peer
                  Mentoring, Identity Work and Holding Environments: A Study of African American Leadership
                  Development</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Int J Environ Res Public Health.</span> 2021 May 5;18(9):4920.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Importance%20of%20Peer%20Mentoring%2C%20Identity%20Work%20and%20Holding%20Environments%3A%20A%20Study%20of%20African%20American%20Leadership%20Development&as_occt=title&as_sauthors=%20%22AJ%20Murrell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Hoffer EP</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">America’s Health Care System Is
                  Broken: What Went Wrong and How We Can Fix It. Part 5: Malpractice, Fraud, Waste, and the EMR</span>.
                <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Am J Med.</span> 2019
                Oct;132(10):1129-32.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=America%E2%80%99s%20Health%20Care%20System%20Is%20Broken%3A%20What%20Went%20Wrong%20and%20How%20We%20Can%20Fix%20It.%20Part%205%3A%20Malpractice%2C%20Fraud%2C%20Waste%2C%20and%20the%20EMR&as_occt=title&as_sauthors=%20%22EP%20Hoffer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Del Carmen MG</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Herman J</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Rao S</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Hidrue MK</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Ting D</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Lehrhoff SR</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Lenz S</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Heffernan J</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Ferris TG</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Trends and factors associated
                  with physician burnout at a multispecialty academic faculty practice organization</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">JAMA Netw Open.</span> 2019
                Mar 1;2(3):e190554-190554.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trends%20and%20factors%20associated%20with%20physician%20burnout%20at%20a%20multispecialty%20academic%20faculty%20practice%20organization&as_occt=title&as_sauthors=%20%22MG%20Del%20Carmen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Wan P</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Wen T</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Gao H</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Wang J</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Goal Self-Concordance Model:
                  What Have We Learned and Where are We Going</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Int J Ment Health Promot.</span>
                2021;23(2):201-19.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Goal%20Self-Concordance%20Model%3A%20What%20Have%20We%20Learned%20and%20Where%20are%20We%20Going&as_occt=title&as_sauthors=%20%22P%20Wan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Pronk NP</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Kottke TE</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Lowry M</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Katz AS</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Gallagher JM</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Knudson SM</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Rauri SJ</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Tillema JO</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Concordance between life
                  satisfaction and six elements of well-being among respondents to a health assessment survey,
                  HealthPartners employees, Minnesota, 2011</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">Prev Chronic Dis.</span> 2016 Dec
                22;13:E173.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Concordance%20between%20life%20satisfaction%20and%20six%20elements%20of%20well-being%20among%20respondents%20to%20a%20health%20assessment%20survey%2C%20HealthPartners%20employees%2C%20Minnesota%2C%202011&as_occt=title&as_sauthors=%20%22NP%20Pronk%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Aanestad M</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Jensen TB</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Collective mindfulness in
                  post-implementation IS adaptation processes</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Inf Organ.</span> 2016;26(1-2):13-27.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Collective%20mindfulness%20in%20post-implementation%20IS%20adaptation%20processes&as_occt=title&as_sauthors=%20%22M%20Aanestad%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D27f719b7-446d-4613-8a88-3933e408577a%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node eletter" data-id="eletter_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="file" style="position: unset;"><span class="" style="position: unset;">eLetter 1</span><a
                  class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;27f719b7-446d-4613-8a88-3933e408577a&quot;,&quot;type&quot;:&quot;eletter&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=27f719b7-446d-4613-8a88-3933e408577a&type=eletter&name=1711"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 1, 2025;
                      107 (1): 93</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00641</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 11, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;27f719b7-446d-4613-8a88-3933e408577a&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=27f719b7-446d-4613-8a88-3933e408577a&type=pdf&name=JBJS.24.00641.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=27f719b7-446d-4613-8a88-3933e408577a&type=pdf&name=JBJS.24.00641.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_14"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_15" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> form is provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I187" target="_blank" data-id="link_5"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I187</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;27f719b7-446d-4613-8a88-3933e408577a&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=27f719b7-446d-4613-8a88-3933e408577a&type=zip&name=JBJS.24.00641.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Laura L. Bellaire, MD, FAAOS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:Laura.Bellaire@hsc.utah.edu" class=""
                    style="position: unset;">Laura.Bellaire@hsc.utah.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8478-0574" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8478-0574</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, University of Utah, Salt Lake City,
                Utah</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 8948.28px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
