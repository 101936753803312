import { useToast } from "@contexts/ToastContext";
import { useAppTheme } from "@hooks/useAppTheme";
import { useAuthContext } from "@memorang/applets";
import { Box } from "@memorang/ui";
import { useMutation } from "@tanstack/react-query";
import { ConfirmDialog } from "components/ConfirmDialog";
import { TextInput } from "react-native-paper";
import { useCertificate } from "../contexts/CertificateContext";

type Props = {
	visible: boolean;
	onDismiss: () => void;
	onConfirm: () => void;
};

export const CMENameConfirmationDialog = ({
	visible,
	onDismiss,
	onConfirm,
}: Props) => {
	const theme = useAppTheme();
	const { firstName, setFirstName, lastName, setLastName } = useCertificate();
	const { showToast } = useToast();
	const { setUserInfoNew } = useAuthContext();

	const { mutate: handleUpdateUserName, isPending: isUpdatingUserName } =
		useMutation({
			mutationFn: async () => {
				const result = await setUserInfoNew(firstName, lastName);
				return result;
			},
			onSuccess: () => {
				onConfirm();
			},
			onError: (error) => {
				showToast({
					message: "Failed to update name. Please try again.",
					duration: 3000,
				});
				console.error("Error updating name:", error);
			},
		});

	return (
		<ConfirmDialog
			visible={visible}
			icon="certificate-outline"
			dismissCta="Cancel"
			confirmCta="Confirm"
			dismissCallback={onDismiss}
			confirmCallback={handleUpdateUserName}
			confirmCtaLoading={isUpdatingUserName}
			title="Confirm your name"
			subtitle="This name will be printed on your CME Certificate."
			body={
				<Box gap={16}>
					<TextInput
						label="First name"
						value={firstName}
						autoFocus
						placeholder="Enter your first name"
						onChangeText={setFirstName}
						mode="outlined"
						disabled={isUpdatingUserName}
						style={{
							backgroundColor: theme.colors.surfaceVariant,
						}}
					/>
					<TextInput
						label="Last name"
						value={lastName}
						placeholder="Enter your last name"
						onChangeText={setLastName}
						mode="outlined"
						disabled={isUpdatingUserName}
						style={{
							backgroundColor: theme.colors.surfaceVariant,
						}}
					/>
				</Box>
			}
		/>
	);
};
