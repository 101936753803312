import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";
import PlanCard from "./PlanCard";
/* ----------------- Local Imports --------------- */
import PlanListItem from "./PlanListItem";
import type { SubscriptionListProps } from "./types";

/* ----------------- Third Party Imports --------------- */
import { Text } from "react-native-paper";

const SubscriptionList = ({
	title,
	plans,
	selectedPlanId,
	handleSelectPlan,
}: SubscriptionListProps) => {
	const theme = useAppTheme();
	const planViewChangeCount = 3;

	return (
		<Box alignItems="center" rowGap={8} width="100%">
			<Text
				variant="labelLarge"
				style={{ color: theme.colors.onSurfaceVariant, fontWeight: "600" }}
			>
				{title}
			</Text>

			{plans.length > planViewChangeCount ? (
				plans.map((plan) => (
					<PlanListItem
						key={plan.id}
						{...plan}
						selected={selectedPlanId === plan.id}
						offer={selectedPlanId === plan.id ? plan.offer : null}
						handleSelectPlan={handleSelectPlan}
					/>
				))
			) : (
				<Box flexDirection="row" gap={8} width="100%" flex={1}>
					{plans.map((plan) => (
						<PlanCard
							key={plan.id}
							{...plan}
							selected={selectedPlanId === plan.id}
							handleSelectPlan={handleSelectPlan}
						/>
					))}
				</Box>
			)}
		</Box>
	);
};

export default SubscriptionList;
