import { useAuthContext } from "@memorang/applets";
import { useCallback, useEffect } from "react";
import { Platform } from "react-native";
import Purchases from "react-native-purchases";
import type { default as RevenueCatUIType } from "react-native-purchases-ui";

const RevenueCatUI = Platform.select({
	native: () => require("react-native-purchases-ui").default,
	default: () => null,
})?.() as typeof RevenueCatUIType | null;

import { router } from "expo-router";
import { useAppStore } from "./useAppStore";
import { useGlobalStore } from "./useGlobalStore";
const useSubscriptionPurchase = () => {
	const keys = useAppStore((store) => store.app.keys.revenueCat);
	const setIsPremiumUser = useGlobalStore((store) => store.setIsPremiumUser);

	const isPremiumUser = useGlobalStore((store) => store.isPremiumUser);
	const tenantId = useAppStore((store) => store.tenant.id);
	const planId = useAppStore((store) => store.app.purchase?.planId);
	const bundleId = useAppStore((store) => store.app.purchase?.productId);

	const { viewerId } = useAuthContext();
	const isIos = Platform.OS === "ios";

	const apiKey = isIos ? keys.ios : keys.android;

	const checkSubscriptionStatus = useCallback(async () => {
		try {
			const customerInfo = await Purchases.getCustomerInfo();
			const isSubscribed = customerInfo.activeSubscriptions.length > 0;
			setIsPremiumUser(isSubscribed);
		} catch (error) {
			console.error("Failed to check subscription status:", error);
		}
	}, [setIsPremiumUser]);

	useEffect(() => {
		if (viewerId && apiKey) {
			Purchases.configure({
				apiKey,
				appUserID: viewerId,
			});
			if (planId && bundleId && tenantId) {
				const attrs = {
					tenantId,
					planId,
					productId: bundleId,
					userId: viewerId!,
				};
				Purchases.setAttributes(attrs);
			}
			if (!isPremiumUser) {
				checkSubscriptionStatus();
			}
		}
	}, [
		apiKey,
		viewerId,
		checkSubscriptionStatus,
		planId,
		bundleId,
		tenantId,
		isPremiumUser,
	]);

	const handleShowPaywall = async () => {
		try {
			if (!RevenueCatUI) {
				return false;
			}

			const paywallResult = await RevenueCatUI.presentPaywall();

			switch (paywallResult) {
				case "NOT_PRESENTED":
				case "ERROR":
				case "CANCELLED": {
					setIsPremiumUser(false);
					return false;
				}
				case "RESTORED":
				case "PURCHASED": {
					setIsPremiumUser(true);
					router.replace("/home");
					return true;
				}

				default:
					return false;
			}
		} catch (error) {
			console.info("error", error);
		}
	};

	return { handleShowPaywall, isPremiumUser };
};

export default useSubscriptionPurchase;
