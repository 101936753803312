"use dom";

import "./App.css";

import { isDOM } from "@helpers/platform";
import { customFonts } from "@memorang/media";
import { loadAsync } from "expo-font";
import { ArrowUp } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";

const isCitationCondition = "a[data-id^='citation_reference_']";
const isContributorCondition = "a[data-id^='contributor_reference_']";
const isFigureOrTableCondition = "a[data-id^='figure_reference_']";
const isExternalLinksCondition = "a[href^='http']";
const isEmailCondition = "a[href^='mailto']";

const getElementByDataId = (
	dataId: string,
	articleElement: HTMLElement,
): HTMLElement | null => {
	return articleElement.querySelector<HTMLElement>(`[data-id="${dataId}"]`);
};

const closestMatch = (
	element: HTMLOptionalElement,
	selector: string,
	container: HTMLOptionalElement,
): HTMLOptionalElement => {
	while (element && element !== container && !element.matches(selector)) {
		// biome-ignore lint/style/noParameterAssign: <explanation>
		element = element.parentElement;
	}
	return element?.matches(selector) ? element : null;
};

const createLink = (url: string, text: string) => {
	const contentDiv = document.createElement("div");
	contentDiv.className = "content-node heading level-3";

	const link = document.createElement("a");
	link.href = url;
	link.textContent = text;
	link.style.fontWeight = "bold";
	link.className = "content";

	contentDiv.appendChild(link);
	return contentDiv;
};

type HTMLOptionalElement = HTMLElement | null | undefined;

type Props = {
	articleHtml: string;
	openExternalLink: (url: string) => void;
	openEmail: (email: string) => void;
	primaryColor: string;
	fullArticleUrl: string;
	fontScale: number;
	showOnlyAbstract?: boolean;
};

const ArticleRenderer = ({
	articleHtml,
	openExternalLink,
	openEmail,
	primaryColor,
	fullArticleUrl,
	fontScale,
	showOnlyAbstract = false,
}: Props) => {
	const articleRef = useRef<HTMLElement>(null);
	const mainRef = useRef<HTMLElement>(null);
	const timeoutsRef = useRef<Map<HTMLElement, number>>(new Map());
	const [showScrollToTop, setShowScrollToTop] = useState(false);

	const scrollAndHighlight = useCallback((element: HTMLOptionalElement) => {
		if (element) {
			// Clear any existing timeout for this element
			const existingTimeout = timeoutsRef.current.get(element);
			if (existingTimeout) {
				clearTimeout(existingTimeout);
			}

			element.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});

			element.classList.add("highlight");

			const timeoutId = window.setTimeout(() => {
				element.classList.remove("highlight");
				timeoutsRef.current.delete(element);
			}, 1500);

			timeoutsRef.current.set(element, timeoutId);
		}
	}, []);

	const handleCitationClick = useCallback(
		(element: HTMLElement) => {
			const citationNumber = element.textContent?.trim();
			if (citationNumber && !Number.isNaN(Number(citationNumber))) {
				const citationTargetElement = getElementByDataId(
					`article_citation_${citationNumber}`,
					articleRef.current!,
				);
				scrollAndHighlight(citationTargetElement);
			}
		},
		[scrollAndHighlight],
	);

	const handleContributorClick = useCallback(
		(element: HTMLElement) => {
			const dataId = element.getAttribute("data-id");
			const contributorNumber = dataId?.match(/\d+$/)?.[0];
			if (!contributorNumber) {
				console.error("Unable to extract contributor number from data-id");
				return;
			}
			const contributorTargetElement = getElementByDataId(
				`contributor_${contributorNumber}`,
				articleRef.current!,
			);
			scrollAndHighlight(contributorTargetElement);
		},
		[scrollAndHighlight],
	);

	const handleFigureOrTableClick = useCallback(
		(element: HTMLElement) => {
			let text = element.textContent;
			const isTable = text?.includes("Table");
			text = text?.split(" ").at(-1) ?? null;

			if (isTable) {
				const tableLabelNodes = articleRef.current!.querySelectorAll(
					".content-node.html-table .table-caption-label",
				);

				for (const tableNode of tableLabelNodes) {
					const currentTableNumber = tableNode.textContent?.split(" ").at(-1);
					if (currentTableNumber === text) {
						scrollAndHighlight(tableNode as HTMLElement);
						return;
					}
				}
				console.error("Unable to find table with specified number");
				return;
			}

			const figureLabelNodes = articleRef.current!.querySelectorAll(
				".content-node.figure .label",
			);
			for (const figureNode of figureLabelNodes) {
				if (figureNode.textContent?.includes(text || "")) {
					scrollAndHighlight(figureNode as HTMLElement);
					return;
				}
			}
			console.error("Unable to find figure with specified number");
		},
		[scrollAndHighlight],
	);

	const handleClicks = useCallback(
		(e: Event) => {
			if (!articleRef.current) {
				return;
			}
			const target = e.target as HTMLElement;
			const articleElement = articleRef.current;

			const citationElement = closestMatch(
				target,
				isCitationCondition,
				articleElement,
			);
			if (citationElement) {
				e.preventDefault();
				handleCitationClick(citationElement);
				return;
			}

			const contributorElement = closestMatch(
				target,
				isContributorCondition,
				articleElement,
			);
			if (contributorElement) {
				e.preventDefault();
				handleContributorClick(contributorElement);
				return;
			}

			const figureOrTableElement = closestMatch(
				target,
				isFigureOrTableCondition,
				articleElement,
			);
			if (figureOrTableElement) {
				e.preventDefault();
				handleFigureOrTableClick(figureOrTableElement);
				return;
			}

			const externalLinkElement = closestMatch(
				target,
				isExternalLinksCondition,
				articleElement,
			);

			if (externalLinkElement) {
				e.preventDefault();
				openExternalLink((externalLinkElement as HTMLAnchorElement).href);
				return;
			}

			const emailElement = closestMatch(
				target,
				isEmailCondition,
				articleElement,
			);

			if (emailElement) {
				e.preventDefault();
				openEmail((emailElement as HTMLAnchorElement).href);
				return;
			}
		},
		[
			handleCitationClick,
			handleContributorClick,
			handleFigureOrTableClick,
			openExternalLink,
			openEmail,
		],
	);

	const handleScroll = useCallback(() => {
		const scrollY =
			!isDOM && mainRef.current
				? mainRef.current?.scrollTop
				: window.scrollY || document.documentElement.scrollTop;
		setShowScrollToTop(scrollY > window.innerHeight * 0.2); // Show button after 20% of screen height
	}, []);

	const scrollToTop = useCallback(() => {
		(isDOM ? window : mainRef.current)?.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	const handleArticleContent = useCallback(
		(articleElement: HTMLElement) => {
			if (!showOnlyAbstract) {
				return;
			}

			const nodesDiv = articleElement.querySelector(".nodes");
			if (!nodesDiv) {
				return;
			}

			const children = Array.from(nodesDiv.children);
			const contributorIndex = children.findIndex((child) =>
				child.classList.contains("contributor"),
			);
			const abstractElement = children.find((child) =>
				child.classList.contains("abstract"),
			);

			if (abstractElement) {
				const fullArticleLink = createLink(
					fullArticleUrl,
					"Access full article - subscription required",
				);
				abstractElement.insertAdjacentElement("afterend", fullArticleLink);
			}

			children.forEach((child, index) => {
				const shouldDisplay =
					child.classList.contains("cover") ||
					child.classList.contains("abstract") ||
					child.classList.contains("contributor") ||
					(contributorIndex !== -1 && index === contributorIndex - 1);

				if (!shouldDisplay) {
					(child as HTMLElement).style.display = "none";
				}
			});
		},
		[showOnlyAbstract, fullArticleUrl],
	);

	useEffect(() => {
		if (articleRef.current) {
			handleArticleContent(articleRef.current);
		}
	}, [handleArticleContent]);

	useEffect(() => {
		const articleElement = articleRef.current;
		if (articleElement) {
			articleElement.addEventListener("click", handleClicks);
		}

		return () => {
			if (articleElement) {
				articleElement.removeEventListener("click", handleClicks);
			}
			// Clear all timeouts
			for (const timeoutId of timeoutsRef.current.values()) {
				clearTimeout(timeoutId);
			}
			timeoutsRef.current.clear();
		};
	}, [handleClicks]);

	// Attach scroll event listener based on platform (DOM or web)
	useEffect(() => {
		const scrollElement = !isDOM && mainRef.current ? mainRef.current : window;
		scrollElement.addEventListener("scroll", handleScroll);

		return () => {
			scrollElement.removeEventListener("scroll", handleScroll);
		};
	}, [handleScroll]);

	// Load Open Sans font
	useEffect(() => {
		(async () => {
			await loadAsync(customFonts);
		})();
	}, []);

	// Set root font size for rem calculations
	useEffect(() => {
		document.documentElement.style.fontSize = `${16 * fontScale}px`;

		return () => {
			document.documentElement.style.fontSize = "16px"; // Reset on unmount
		};
	}, [fontScale]);

	return (
		<main
			ref={mainRef}
			style={{
				position: "relative",
				overflowY: "auto",
			}}
		>
			<article
				ref={articleRef}
				style={{
					height: "100vh",
					width: "100vw",
					position: "relative",
				}}
				// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
				dangerouslySetInnerHTML={{ __html: articleHtml }}
			/>
			{showScrollToTop && (
				<button
					onClick={scrollToTop}
					style={{
						position: "fixed",
						bottom: "100px",
						right: "20px",
						width: "40px",
						height: "40px",
						backgroundColor: "white",
						border: "none",
						borderRadius: "50%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
					}}
					aria-label="Scroll to top"
				>
					<ArrowUp name="arrow-up" size={24} color={primaryColor} />
				</button>
			)}
		</main>
	);
};

export default ArticleRenderer;
