import type { FlashList } from "@shopify/flash-list";
/* -----------------Globals--------------- */
import { useRef } from "react";

/* -----------------Types--------------- */
import type { SessionItem } from "@memorang/types";

import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import { isWeb } from "@helpers/platform";
import { useAppTheme } from "@hooks/useAppTheme";
import { useDevice } from "@hooks/useDevice";
/* -----------------Components--------------- */
import { Box } from "@memorang/ui";
import { ScrollView } from "react-native";
import StudySessionBottombarContainer from "../containers/StudySessionBottombarContainer";
/* -----------------Containers--------------- */
import StudySessionFeedbackContainer from "../containers/StudySessionFeedbackContainer";
import TryAgainSheetContainer from "../containers/TryAgainSheetContainer";
import SessionList from "./SessionList";
import { StudySessionAppBarContainer } from "./StudySessionAppBarContainer";

type Props = {
	sessionItems: SessionItem[];
	sessionId: string;
	isSummativeTest?: boolean;
	currentBlockIndex: number;
};
const StudySessionView = ({
	sessionItems,
	isSummativeTest,
	currentBlockIndex,
}: Props) => {
	const ref = useRef<FlashList<SessionItem>>(null);

	const { isMobile } = useDevice();

	const theme = useAppTheme();

	const finalSessionItems = isSummativeTest
		? sessionItems[currentBlockIndex].children
		: sessionItems;

	return (
		<Box flex={1} backgroundColor={theme.colors.background} height="100%">
			<Box flex={1} height="100%">
				<StudySessionAppBarContainer />
				<Box
					flex={1}
					backgroundColor={theme.colors.surface}
					paddingTop={isMobile ? 0 : 24}
					height="100%"
				>
					{isMobile ? (
						<SessionList ref={ref} items={finalSessionItems as SessionItem[]} />
					) : (
						<ScrollView
							contentContainerStyle={{
								paddingBottom: 200,
								alignItems: "center",
								gap: 16,
								flexGrow: 1,
							}}
						>
							<SessionList
								ref={ref}
								items={finalSessionItems as SessionItem[]}
							/>
							{isWeb && <StudySessionFeedbackContainer />}
						</ScrollView>
					)}
					{!isWeb && (
						<BottomSheetModalProvider>
							<StudySessionFeedbackContainer />
						</BottomSheetModalProvider>
					)}
				</Box>
			</Box>

			<StudySessionBottombarContainer flashlistRef={ref} />
			{!isWeb && (
				<BottomSheetModalProvider>
					<TryAgainSheetContainer />
				</BottomSheetModalProvider>
			)}
		</Box>
	);
};

export default StudySessionView;
