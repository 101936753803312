/* -----------------Globals--------------- */
import Image from "@tiptap/extension-image";

/* -----------------Child components--------------- */
import { ImageNodeViewWrapper } from "../tiptapwrappers/ImageNodeViewWrapper";

/* -----------------Helpers & Hooks--------------- */
import { ReactNodeViewRenderer } from "@tiptap/react";

export const CustomImageExtension = Image.extend({
	addNodeView() {
		return ReactNodeViewRenderer(ImageNodeViewWrapper);
	},
});
