import type { Article } from "../../../../../types";

export const Article587: Article = {
	id: 587,
	rsuiteId: "56410f65-daca-4dfa-b173-9a771c17d354",
	type: "guest editorial",
	title: "What's New in Musculoskeletal Basic Science",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/587.jpeg",
	subSpecialties: ["pediatrics"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">What’s New in Musculoskeletal Basic Science</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Zbigniew
                      Gugala, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">Musculoskeletal basic science provides a foundation for
                  clinical knowledge while fostering innovation in the management of orthopaedic conditions. In the past
                  year, several outstanding articles have been published that advance our understanding of neural system
                  function and its crosstalk with immunity in tissue healing and regulation of bone homeostasis,
                  revealing new roles of mechanotransduction in bone and critically assessing vitamin D supplementation
                  in schoolchildren (6 to 13 years of age). Novel research was generated on maintaining muscle strength
                  and function, establishing the role of synovium in chronic inflammatory joint pathology, and the
                  effects of aging on the musculoskeletal system. These publications provide new insight into the
                  physiologic and pathologic processes underlying orthopaedic conditions and critically assess routine
                  clinical practices with high-level evidence. All 20 musculoskeletal science articles chosen for this
                  review were published in extremely prestigious, high-impact-factor journals.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Somatic and
                Autonomic Innervation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">Physical activity requires precisely coordinated
                  participation of the somatic nervous system controlling skeletal muscles and the autonomic nervous
                  system enabling synchronous responses from the inner organs. Although somatic and autonomic
                  innervations have separate anatomic representations, the nature of their coordinated interactions
                  remains elusive. Zhang et al.<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>
                  discovered that spinal-projecting neurons in the rostral ventromedial medulla (rVMM) simultaneously
                  coregulate both somatic motor and autonomic sympathetic activity in different bodily functional
                  states. Using adeno-associated virus (AAV) retrograde tracing and optogenetic mapping,
                  spinal-projecting neurons located in the gigantocellular reticular nucleus alpha (GiA) and ventral
                  part (GiV) of the rVMM exhibited axon collaterals connecting the motor and sympathetic spinal neurons.
                  These spinal-projecting neurons exhibited excitatory or inhibitory regulation of skeletal muscle tone
                  concurrently with sympathetic tone (blood pressure, heart rate). Active states such as exercise
                  exhibited spinal-projecting neuron excitation causing high motor and sympathetic tones, whereas
                  quiescent states such as sleep showed spinal-projecting neuron inhibition yielding skeletal muscle
                  atonia and sympathetic hypoactivity. This study considerably advances our knowledge on neuronal
                  circuitry governing coordinated motor and sympathetic responses during different functional states.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Neuroimmune
                Modulation of Tissue Healing</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Nociceptive sensory neurons are important immunoregulators
                  exerting protective or damaging effects via neurogenic inflammation. The neuroimmune interactions
                  during tissue healing following an injury are poorly understood. Lu et al.<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a> demonstrated that ablation of the Nav1.8 voltage-gated
                  sodium channels in nociceptive neurons in the dorsal root ganglia (DRG) impaired healing of
                  full-thickness skin wounds and regeneration of volumetric muscle loss. Transgenic <span
                    data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Nav1.8</span><span
                    data-id="emphasis_23" class="annotation emphasis" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">cre</span></span><span data-id="superscript_3"
                    class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_24"
                    class="annotation emphasis" style="position: unset;">/Rosa26</span><span data-id="emphasis_25"
                    class="annotation emphasis" style="position: unset;"><span data-id="superscript_4"
                      class="annotation superscript" style="position: unset;">DTA</span></span><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;"></span> mice lacking
                  Nav1.8 exhibit impaired sensory axon ingrowth into granulation tissue, which coincides with local
                  downregulation of calcitonin gene-related peptide (CGRP). CGRP released by the Nav1.8<span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">+</span> neurons
                  enables skin and muscle healing by interacting with CD11b<span data-id="superscript_6"
                    class="annotation superscript" style="position: unset;">+</span> myeloid cells via receptor
                  activity-modifying protein 1 (RAMP1). CGRP upregulates thrombospondin-1 (TSP-1) in neutrophils and
                  monocytes/macrophages to inhibit their recruitment and enhance efferocytosis and M2 polarization. Mice
                  with diabetic neuropathy (<span data-id="emphasis_26" class="annotation emphasis"
                    style="position: unset;">Lepr</span><span data-id="emphasis_27" class="annotation emphasis"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">db/db</span></span><span data-id="superscript_7"
                    class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_28"
                    class="annotation emphasis" style="position: unset;">)</span>, similar to Nav1.8-deficient mice,
                  exhibit reduced CGRP expression in sensory neurons and impaired tissue-healing, which can be mitigated
                  with exogenous local CGRP delivery. The study demonstrated that tissue-healing involves complex
                  CGRP-mediated neuroimmune interactions and suggested potential new treatment options.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Regulation
                of Bone Homeostasis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">A demand for high calcium levels in breast milk during
                  lactation causes abrupt bone loss. In mechanistic studies, Babey et al.<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">3</span></a> identified cellular communication network factor 3 (CCN3) as
                  a potent osteoanabolic hormone released in the early postpartum period throughout lactation. CCN3 is
                  secreted from the kisspeptin-positive neurons of the arcuate nucleus (ARCKISS1<span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">+</span>) in the
                  brain. Parabiosis of female wild-type mice and female mice overexpressing CCN3 (<span
                    data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Esr1</span><span
                    data-id="emphasis_30" class="annotation emphasis" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">Nkx2-1-cre</span></span><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;"></span>) revealed the same high bone mass.
                  Further, human stem cells exposed to CCN3 showed enhanced osteogenic differentiation and matrix
                  mineralization, regardless of cell donor age and sex. Systemic CCN3 delivery augmented the bone
                  phenotype in ovariectomized mice and reversed the skeletal sequelae of a chronic high-fat diet known
                  to compromise ARCKISS1<span data-id="superscript_11" class="annotation superscript"
                    style="position: unset;">+</span> neurons. Finally, localized CCN3 delivery enhanced femoral
                  osteotomy healing in aged female and male mice. Knocking down <span data-id="emphasis_31"
                    class="annotation emphasis" style="position: unset;">Ccn3</span> in the arcuate nucleus leads to
                  excessive bone loss during lactation, further compounded by a low-calcium diet. The study identified
                  maternal CCN3 as a potent brain-derived, osteoprotective hormone released during lactation and
                  delineated its osteoanabolic properties.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Osteoclastogenesis controls bone turnover, although
                  several pathways regulating the process remain unclear. Colocalization of osteoclast precursors with
                  eosinophils in bone marrow suggests their interaction. Andreev et al.<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">4</span></a> discovered that eosinophils regulate osteoclast activity in
                  both normal and pathologic bone conditions. Eosinophil-deficient ΔdblGATA knockout mice exhibit a
                  marked reduction in bone mass due to increased osteoclastogenesis. Conversely, eosinophilia in
                  transgenic interleukin (IL)-5 mice strongly inhibits osteoclast differentiation and activity by
                  altering their transcriptional profile. Eosinophil peroxidase (EPX) was identified as a regulator of
                  osteoclast formation and function by downregulating mitochondrial reactive oxygen species (ROS) and
                  mitogen-activated protein kinases (MAPKs) in osteoclast precursors. EPX treatment ameliorated bone
                  loss induced by inflammatory arthritis and estrogen deprivation. In humans, the number and the
                  activity of eosinophils highly correlated with bone mass in healthy participants and patients with
                  rheumatoid arthritis. This study revealed a novel function of eosinophils to regulate bone homeostasis
                  and underscored the role of innate immunity in the process.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Mechanotransduction in Development, Health, and Disease</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Postnatally, mechanical signals dynamically regulate
                  skeletal homeostasis and its adaptive changes; however, the role of mechanical cues in embryonic
                  skeletal development is largely unknown. Collins et al.<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">5</span></a>
                  established the essential role of Hippo signaling and its downstream transcription factors YAP
                  (Yes-associated protein) and TAZ (transcriptional coactivator with PDZ-binding motif) in fetal murine
                  osteoblasts and their coordinated interactions with endothelial cells during embryonic endochondral
                  ossification to form bone. In transgenic mice, selective YAP/TAZ deletion in osteogenic osterix
                  (Osx)-expressing cells causes loss in mechanoresponsivness due to alteration of gene expression
                  required for cell cytoskeleton rearrangement to sense mechanical stimuli. YAP/TAZ spatially couple
                  Osx<span data-id="superscript_14" class="annotation superscript" style="position: unset;">+</span>
                  osteoblast precursors with endothelial cells of sprouting vessels to initiate cartilage hypertrophy
                  and remodeling at the endochondral ossification centers, thereby mechanoregulating bone formation in
                  murine developing limbs. Single-cell transcriptomics revealed a specific population of
                  vessel-associated osteoblast precursors, and YAP/TAZ deletion in these cells downregulated C-X-C motif
                  chemokine 12, which mediates vascular morphogenesis. Ex vivo bioreactor-based mechanical stimulation
                  of explanted embryonic (E15.5) mouse hindlimbs increased mineralization, and YAP/TAZ deletion
                  abrogated load-induced osteogenesis at the ossification sites. The study reveals a key role of YAP/TAZ
                  in mechanosignaling during embryonic bone development, with potential implications for skeletal
                  pathologies associated with fetal akinesia or hypokinesia and uterine crowding.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Daylight is the primary entrainer of circadian rhythms,
                  pacing and resetting the neuronal signals in the brain. Food intake, ambient temperature, and stress
                  can uncouple circadian rhythms at autonomous tissue and organ levels. In a murine mechanistic study,
                  Dudek et al.<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">6</span></a>
                  demonstrated that cyclic mechanical loading and resultant tissue osmolarity changes can reset the
                  circadian rhythm phase and amplitude in femoral head articular cartilage and intervertebral disc
                  devoid of innervation and vascularity. Using treadmill exercise in PER2::Luc reporter mice, they
                  established that hyperosmolarity alone can effectively shift and reset the internal clock in young and
                  aging skeletal phenotypes by modulating the expression of specific circadian rhythm genes.
                  Transcriptomic and biochemical analyses revealed the PLD2-mTORC2-AKT-GSK3β molecular pathway as a
                  convergent mechanism mediating both the loading-induced and hyperosmolarity-induced clock
                  entrainments. These results demonstrate a key role of cartilage mechanoresponsiveness in synchronizing
                  skeletal circadian rhythms and suggest cartilage osmolar intervention options.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Altered mechanoresponsiveness was implicated in bone
                  fragility in type-2 diabetes mellitus due to detrimental effects of a high-glucose and high-fat diet
                  on osteocytes. Shao et al.<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">7</span></a>
                  demonstrated that unilateral in vivo tibial cyclic loading (1,200 cycles per day for 2 weeks) fails to
                  improve bone mass, microarchitecture, and strength in genetically spontaneous (KK-Ay) and
                  high-fat-diet combined with streptozotocin (HFD/STZ)-induced mice with type-2 diabetes mellitus. The
                  lack of bone mechanoresponsiveness was directly associated with attenuated Ca<span
                    data-id="superscript_17" class="annotation superscript" style="position: unset;">2+</span>
                  oscillatory dynamics in osteocytes mediated by a reduced expression of the SERCA2 pump. This process
                  involves peroxisome proliferator-activated receptor α (PPARα), leading to fewer and weaker Ca<span
                    data-id="superscript_18" class="annotation superscript" style="position: unset;">2+</span> spikes
                  with prolonged Ca<span data-id="superscript_19" class="annotation superscript"
                    style="position: unset;">2+</span> uptake in osteocytes with the type-2 diabetes mellitus phenotype.
                  The loading-dependent Ca<span data-id="superscript_20" class="annotation superscript"
                    style="position: unset;">2+</span> oscillations cause immediate actin network contractions, thereby
                  modulating osteocyte secretory activities (RANKL, OPG, SOST). Conversely, treatment with istaroxime, a
                  SERCA2 agonist, improves bone mechanoresponsiveness by rescuing osteocyte Ca<span
                    data-id="superscript_21" class="annotation superscript" style="position: unset;">2+</span> dynamics
                  in both type-2 diabetes mellitus models. Similarly, conditional osteocyte-specific SERCA2
                  overexpression (SERCA2<span data-id="superscript_22" class="annotation superscript"
                    style="position: unset;">flox/flox</span>; DMP1-Cre) mitigated the type-2 diabetes mellitus-related
                  suppression of bone mechanoresponsiveness. The study provided a mechanistic insight into compromised
                  bone mechanoresponsiveness in type-2 diabetes mellitus and identified osteocyte Ca<span
                    data-id="superscript_23" class="annotation superscript" style="position: unset;">2+</span>
                  signatures as druggable targets.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Bone and Its
                Blood Supply</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Transcortical vessels connect the endosteum with
                  periosteum to permit molecule and cell trafficking between the inner medullary and external bone
                  compartments across the osteocyte-populated cortex. Liao et al.<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">8</span></a> revealed a unique role of osteocytes, which, via rich direct
                  dendritic connections with transcortical vessels, transfer mitochondria to the endothelial cells to
                  protect them from oxidative stress, and promote their proliferation and migration, thereby enhancing
                  transcortical vessel formation. The ablation of osteocytes in transgenic (<span data-id="emphasis_32"
                    class="annotation emphasis" style="position: unset;">Dmp1</span><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">Cre</span>-<span data-id="emphasis_33"
                    class="annotation emphasis" style="position: unset;">DTA</span><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">ki/wt</span>) mice exhibits substantial
                  reduction in transcortical vessel density and connectivity, resulting from significant downexpression
                  of angiogenic genes (<span data-id="emphasis_34" class="annotation emphasis"
                    style="position: unset;">Vegfc, Slit3, Notch3, Notch4</span>). The osteocyte-endothelium transfer of
                  Dendra2-labeled mitochondria was visualized in vitro and in vivo and was required for transcortical
                  vessel formation and functionality. The metabolic effects of the endothelial mitochondria uptake
                  involved induction of D-sphingosine catalyzed by sphingosine kinase 1 (SPHK1). In vivo delivery of
                  osteocyte-derived mitochondria or D-sphingosine (2 mg/kg for 7 days) enhanced intact femoral bone
                  mass, density, and microarchitecture, and augmented diaphyseal defect healing in mice. These findings
                  identify a unique mitochondria transfer regulating transcortical vessel formation and homeostasis.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The pathways orchestrating synchronous new vessel
                  formation and osteogenic stem cell recruitment and migration during bone-healing lack thorough
                  understanding. In a dynamic imaging study, Bixel et al.<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">9</span></a>
                  employed intravital multiphoton microscopy to longitudinally surveil vascularization in murine
                  calvarial defect healing and determined that initial microvessel (CD31<span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">+</span> Emcn<span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">+</span>) formation did not spatially or
                  temporally associate with osteoprogenitors (Osx<span data-id="superscript_30"
                    class="annotation superscript" style="position: unset;">+</span> Runx2<span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">+</span>) emerging from the periosteum.
                  Second-harmonic generation imaging permitted simultaneous visualization of angiogenesis and
                  osteogenesis in double-transgenic <span data-id="emphasis_35" class="annotation emphasis"
                    style="position: unset;">Flk1-GFP</span> and <span data-id="emphasis_36" class="annotation emphasis"
                    style="position: unset;">SP7-mCherry</span> reporter mice. The early-formed vessels did not connect
                  with the adjacent uninjured vessels, but rather with the outer periosteal and meningeal vasculature,
                  and the blood flow velocities were highly variable and independent of the vessel diameter. Compared
                  with femoral fracture-healing, the uncoupling of angiogenesis from osteogenesis was unique to
                  calvarial healing. In contrast to femoral fracture-healing, the angiogenesis-related and
                  hypoxia-related genes (<span data-id="emphasis_37" class="annotation emphasis"
                    style="position: unset;">Hif1a, Vegfa, Angpt2</span>) were only slightly expressed in calvarial
                  healing, and surprisingly, although they profoundly altered vascular ingrowth, endothelial Notch and
                  vascular endothelial growth factor (VEGF) signaling did affect calvarial healing. The study
                  represented a pioneering effort in sequential intravital imaging of the entire process of calvarial
                  bone repair at a multiscale level and delineated highly dynamic relationships between angiogenesis and
                  osteogenesis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Vitamin D
                Supplementation in Schoolchildren</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Vitamin D is a hormone promoting bone mineralization,
                  specifically in the growing skeleton. Severe and prolonged vitamin D deficiency causes rickets and
                  requires vitamin D therapy; however, routine vitamin D supplementation in children lacks evidence.
                  Recently, 2 sister subtrials were reported, both nested within 2 separate main Phase-3 randomized
                  clinical trials (RCTs). In the subtrial by Ganmaa et al.<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">10</span></a>
                  involving a secondary analysis of the outcomes of an RCT conducted in Mongolia, 8,851 schoolchildren
                  (6 to 13 years of age) were randomized to receive weekly oral 14,000-IU vitamin D3 (4,418
                  schoolchildren) or placebo (4,433 schoolchildren) for 3 years and fracture incidence was assessed. At
                  baseline, 31.9% of the schoolchildren were vitamin D-deficient (&lt;25 nmol/L), 63.5% were vitamin
                  D-insufficient (25 to 50 nmol/L), and only 4.4% were vitamin D-sufficient (&gt;50 nmol/L). At the
                  trial end point, schoolchildren in the vitamin D group had normalized vitamin D levels (mean, 72.1
                  nmol/L), whereas, in the placebo group, vitamin D status remained insufficient (26.1 nmol/L);
                  nevertheless, fracture incidence was similar in the vitamin D group (6.4%) compared with the placebo
                  group (6.1%). Alterations in blood parathyroid hormone (PTH), alkaline phosphatase (ALP), and minerals
                  were noted only in vitamin D-deficient children. The subtrial by Middelkoop et al.<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">11</span></a> was nested within the ViDiKids trial and assessed the
                  effect of weekly oral 10,000-IU vitamin D3 compared with placebo for 3 years on bone mineral content
                  (BMC) and serum calcium, vitamin D3, and PTH levels, bone remodeling markers (ALP, C-terminal
                  telopeptide of type I collagen [CTX], procollagen type I N-propeptide [P1NP]), and fracture incidence
                  in 450 Black African schoolchildren (6 to 11 years of age) from South Africa. At baseline, 5.8% of
                  participating schoolchildren were vitamin D-insufficient (&lt;50 nmol/L), and, at the trial end point,
                  serum vitamin D3 was higher in the vitamin D group compared with the placebo group (mean difference,
                  39.9 nmol/L), whereas serum PTH was lower (mean difference, 0.55 pmol/L). There were no differences in
                  BMC by dual x-ray absorptiometry (DXA) (whole body less the head; lumbar spine), serum calcium, or
                  bone turnover markers. Fracture incidence was very low in both groups (0.93% compared with 1.32%). The
                  null findings of these subtrials indicate that vitamin D supplementation alone does not reduce
                  fractures or improve BMC or serum remodeling markers in schoolchildren despite vitamin D deficiency or
                  insufficiency.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Maintaining
                Skeletal Muscle Strength and Function</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The health benefits of intentional weight loss can be
                  offset by lowering bone mineral density (BMD) and increasing bone turnover, which may be concerning in
                  older individuals. Jensen et al.<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">12</span></a>
                  reported a secondary analysis of an RCT to determine bone health at the hip, spine, and forearm after
                  an 8-week diet-induced weight loss followed by a 52-week weight loss maintenance intervention. The 195
                  eligible participants (124 female and 71 male, 18 to 65 years of age, with a body mass index of 32 to
                  43 kg/m<span data-id="superscript_35" class="annotation superscript"
                    style="position: unset;">2</span>, no diabetes, and at least 5% body mass reduction after diet) were
                  equally randomized to (1) a supervised moderate-to-vigorous-intensity exercise program; (2)
                  glucagon-like peptide-1 receptor agonist (liraglutide, 3.0 mg daily); (3) combined exercise and
                  liraglutide; or (4) placebo. Unlike placebo (+6.1 kg), all 3 intervention groups had sustained body
                  mass and body fat reductions, with the greatest effect in the combined exercise-liraglutide group
                  (−16.9 kg). The hip, spine, and forearm BMDs remined unchanged in the combined exercise-liraglutide
                  group, whereas liraglutide alone reduced hip and spine BMDs more than exercise alone, despite their
                  similar weight loss effects. Bone turnover markers (P-CTX, P-P1NP) initially increased during the
                  weight loss phase but later normalized. The study demonstrated that a combination of exercise and
                  liraglutide can achieve effective and sustained weight reduction without compromising bone health.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Patients with type-2 diabetes mellitus accumulate fat
                  subcutaneously, viscerally, and intramuscularly, causing insulin resistance and cardiometabolic risks.
                  Increased intramyocellular fat in the skeletal muscle also occurs in healthy endurance athletes who
                  are highly insulin-sensitive, known as the athlete’s paradox. Mezincescu et al.<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">13</span></a> conducted a parallel, nonrandomized clinical trial to
                  compare the myocyte lipid signature in the vastus lateralis of age-matched male patients with type-2
                  diabetes mellitus (n = 27; diet with or without metformin; sedentary lifestyle) and endurance athletes
                  (n = 29; cycling/running/triathlon; ≥5 years moderate-to-vigorous-intensity training ≥420 min/week)
                  before and after endurance training or deconditioning. <a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">1</span></a>H-magnetic resonance spectroscopy, stable isotope U-<span
                    data-id="superscript_38" class="annotation superscript" style="position: unset;">13</span>C tracing,
                  and muscle biopsy lipidomics revealed that participants with type-2 diabetes mellitus initially had
                  higher unsaturated intramyocellular fat and blunted palmitate and linoleate kinetics, whereas the
                  athletes exhibited higher intramyocellular fat saturation with very high palmitate kinetics. The
                  8-week endurance training for participants with type-2 diabetes mellitus rendered these values
                  comparable with those after 4-week deconditioning for the athletes, and improved insulin sensitivity,
                  serum cholesterol and triglycerides, glycemic control, physical performance, and metabolic sensing.
                  The study demonstrated that endurance training can be an effective type-2 diabetes mellitus-modifying
                  intervention to reverse maladapted intramyocellular lipid accumulation and mitigate insulin
                  resistance.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Loss of skeletal muscle mass and strength in the elderly
                  causes disability and frailty. Despite a high-protein diet, older adults may not receive notable
                  anabolic effects in skeletal muscles (anabolic resistance), which is poorly understood. Ni Lochlainn
                  et al.<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript" style="position: unset;">14</span></a>
                  conducted a double-blinded, placebo-controlled RCT (the PROMOTe trial) to investigate the effects of
                  the gut microbiome on muscle anabolic resistance and cognition in older adults. There were 72
                  participants (56 female and 16 male; 36 sets of twins ≥60 years of age [mean, 73 years (range, 63 to
                  83 years)]), who, within each pair, were randomized to receive prebiotics daily for 12 weeks or
                  placebo. Both groups additionally underwent resistance exercise and received branched-chain amino
                  acids. Although prebiotics changed gut microbiome taxa, no significant difference in muscle function
                  (5-repetition chair raise time; grip strength; Short Physical Performance Battery; International
                  Physical Activity Questionnaire [IPAQ] MET minutes) was noted. However, the prebiotics were associated
                  with an improvement in the cognitive first-factor score compared with placebo. The study results
                  support the gut-brain axis concept and suggest the use of gut microbiome interventions to improve
                  cognition in the elderly population.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The Role of
                the Synovium in Arthritis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The synovium is the primary site of inflammation in
                  osteoarthritis from its early stages to eventual joint destruction. Jiang et al.<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">15</span></a> integrated genomics with transcriptomics and chromatin
                  accessibility mapping to provide a comprehensive molecular profile of the human synovium, offering
                  insights into genetic regulatory mechanisms underlying osteoarthritis predisposition. The study
                  analyzed gene expression and genotype data from the human synovium and blood obtained from 245
                  patients with osteoarthritis (Chinese ethnicity; 77 male patients and 168 female patients; 46 to 84
                  years of age), who underwent knee replacement. It identified 4,765 independent primary and 616
                  secondary cis-expression quantitative trait loci (cis-eQTLs) and established that the eQTLs with
                  multiple independent signals had stronger effects and heritability than single independent eQTLs. By
                  integrating genome-wide association study (GWAS) with eQTLs, 84 osteoarthritis-related genes were
                  identified, 38 of which were novel. Epigenetic variants affecting gene expression in 1,517 regions
                  modulating chromatin accessibility were also detected. Among the top 10 eQTL variants enriched in
                  transcription-factor-binding sites, 7 (<span data-id="emphasis_38" class="annotation emphasis"
                    style="position: unset;">PAX5, TCF3, ELF1, REL, IRF4, YY1, NFKB2</span>) were implicated in the
                  immune pathways. The study offered the most extensive genetic resource for the human synovium in
                  osteoarthritis to date, paving the way for future research.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Chronic inflammation with infiltration of various immune
                  cells is the hallmark of synovial pathology in rheumatoid arthritis. Mast cells in the rheumatoid
                  arthritis synovium are characteristic; however, their specific roles in joint pathology in rheumatoid
                  arthritis remain elusive. Lei et al.<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_41" class="annotation superscript" style="position: unset;">16</span></a>
                  revealed that the synovial microenvironment in patients with rheumatoid arthritis causes mast cell
                  activation and degranulation mediated by MAS-related G protein-coupled receptor X2 (MRGPRX2) and
                  expression of major histocompatibility complex (MHC) class II (MHC II) as costimulatory molecules to
                  enhance the CD4<span data-id="superscript_42" class="annotation superscript"
                    style="position: unset;">+</span> T-cell response. In contrast to osteoarthritis, increased
                  frequency and aggregation of mast cells in the synovium of patients with osteoarthritis correlated
                  with clinical severity. Furthermore, adoptive transfer of mast cells promoted disease progression in
                  collagen-induced arthritis (CIA) mice, whereas treatment with a combination of cromolyn and
                  anti-IL-17A, potent mast cell membrane stabilizers, markedly reduced joint pathology. The study
                  indicated that synovium in rheumatoid arthritis involves mast cell recruitment and activation leading
                  to rheumatoid arthritis progression and suggested mast cell targeting as an intervention option.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Musculoskeletal System in Aging</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">In men with moderate hypogonadism due to aging,
                  testosterone supplementation can improve bone health; however, the effects of testosterone on reducing
                  fracture incidence are less clear. Snyder et al.<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_43" class="annotation superscript" style="position: unset;">17</span></a>
                  reported a subtrial nested in the TRAVERSE trial to determine whether testosterone treatment can
                  prevent fractures in men with mild hypogonadism. Men (n = 5,204; 45 to 80 years of age) with mild
                  hypogonadism (serum testosterone, 100 to 300 ng/dL) and prostate-specific antigen (PSA) &lt; 3.0 ng/mL
                  who received transdermal testosterone or placebo were analyzed at a median 3.19-year follow-up.
                  Osteoporosis was not a criterion for trial entry. Surprisingly, more fractures occurred in the
                  testosterone group (91 [3.5%] of 2,601) compared with placebo (64 [2.46%] of 2,603). There was no
                  difference in the incidence of typical osteoporotic fractures, but more ankle and rib fractures
                  (typically associated with trauma and/or more risky physical activities) led to an overall higher
                  fracture incidence in the testosterone group. Divergent behavioral trajectories between the groups,
                  with testosterone likely causing engagement in activities with greater fracture risk, likely accounted
                  for these findings. This subtrial identified a potential fracture risk associated with testosterone
                  supplementation in men with mild hypogonadism and substantiated assessing the behavioral effects of
                  testosterone in future studies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Aging involves accumulation of circulating free DNA
                  (cfDNA), with a profile consistent with inflammation and senescence. Because cfDNA increases in
                  rheumatoid arthritis and decreases in response to rheumatoid arthritis treatment, DNA fragmentation
                  can be implicated in rheumatoid arthritis pathogenesis. Luo et al.<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">18</span></a> assessed DNA fragmentation in healthy young subjects,
                  healthy elderly subjects, and elderly subjects with rheumatoid arthritis, and established that
                  rheumatoid arthritis increases cfDNA by the downregulation of TREX1 (a DNA clearance enzyme), which
                  reflects the rheumatoid arthritis severity (DAS28 activity score). TREX1 downregulation was associated
                  with a marked increase of cGAS (a DNA fragment sensor) in patients with rheumatoid arthritis compared
                  with patients with osteoarthritis. In adjuvant-induced arthritis and ultraviolet-induced DNA
                  fragmentation rat models, cGAS signaling initiated inflammation. Intravenous or intra-articular knee
                  injection with fragmented DNA potentiated arthritis, whereas TREX1 overexpression suppressed synovial
                  inflammation. Knocking out <span data-id="emphasis_39" class="annotation emphasis"
                    style="position: unset;">TREX1</span> (TREX1<span data-id="superscript_45"
                    class="annotation superscript" style="position: unset;">Cre</span>) in rats with adjuvant-induced
                  arthritis produced more cfDNA, activated CD8<span data-id="superscript_46"
                    class="annotation superscript" style="position: unset;">+</span> T-cells, and decreased Foxp3<span
                    data-id="superscript_47" class="annotation superscript" style="position: unset;">+</span>-cells,
                  leading to more severe arthritic symptoms. The downregulation of the E2F1 and activator protein (AP)-1
                  transcription factors regulated TREX1 expression and induced the senescence-associated secretory
                  phenotype (SASP). The study demonstrated that aging-related cellular senescence and TREX1
                  downregulation contribute to cfDNA and resultant autoimmune activation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Frailty involves increased vulnerability to stressors
                  typically associated with aging or a serious disease and involves deterioration in various domains,
                  such as unintentional weight loss, exhaustion, low physical activity, slowness, and weakness (Fried
                  frailty phenotype). Using the U.K. Biobank health data, Jiang et al.<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">19</span></a> identified subjects with a frailty diagnosis to undergo
                  neuroimaging and assessment with 325 health-related measures. Analysis of subject baseline data (n =
                  483,033; 38 to 73 years of age), behavioral data (n = 46,501), and neuroimaging data (n = 40,210) at a
                  median 9-year follow-up revealed that the severity of physical frailty was significantly associated
                  with decreased cognitive performance, an unhealthy lifestyle, poor fitness, poor mental health, and
                  adverse biochemical markers. These results were similar between the sexes and across all ages, but
                  mental health measures were mostly associated with frailty in middle age rather than in older
                  subjects. Frailty severity correlated with increased total white-matter hyperintensity and lower
                  gray-matter volume in subcortical brain regions. This large population-based study is a valuable
                  multimodal characterization of physical frailty and its associated mental health conditions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Falls are the leading cause of injury-related morbidity
                  and mortality in older adults in the United States. In response to health risks, economic burden, and
                  increasing incidence of falls, the U.S. Preventive Services Task Force released an updated evidence
                  report<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_49" class="annotation superscript" style="position: unset;">20</span></a>
                  comprising a systematic review and meta-analysis of fair-quality and good-quality RCTs to examine the
                  effectiveness of multifactorial interventions (28 RCTs; n = 27,784) compared with exercise
                  interventions (37 RCTs; n = 16,117) to prevent falls in community-dwelling older adults (≥65 years of
                  age). Multifactorial interventions (individualized and targeted based on the risk factors) were
                  associated with a lower risk of falls, but not with reduction in the individual risk of ≥1 falls,
                  injurious falls, fall-related fractures, individual risk of injurious falls, or individual risk of
                  fall-related fractures. Exercise interventions (supervised group setting with multiple components)
                  were associated with a significant reduction in falls, individual risk of ≥1 falls, and injurious
                  falls, but not in individual injurious falls or individual fall-related fractures. Harms associated
                  with both interventions were rare. The study concluded that multifactorial and exercise interventions
                  can reduce fall incidence, but exercise offers the most benefits across multiple fall-related
                  outcomes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Upcoming
                Meetings and Events Related to Orthopaedic Basic Science</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node list" data-id="list_1" style="position: unset;">
            <ul class="content" style="position: unset;">
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_24" style="position: unset;">
                      <div class="content" style="position: unset;">The 2025 Annual Meeting of the Orthopaedic Research
                        Society (ORS) will be held on February 7 to 11, 2025, in Phoenix, Arizona, United States.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_25" style="position: unset;">
                      <div class="content" style="position: unset;">The 2025 OARSI World Congress on Osteoarthritis by
                        the Osteoarthritis Research Society International will be held on April 24 to 27, 2025, in
                        Incheon, South Korea.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_26" style="position: unset;">
                      <div class="content" style="position: unset;">The Gordon Research Conference on Cartilage Biology
                        and Pathology: Genes, Molecules and Mechanics in Musculoskeletal Tissue Development and Disease
                        will be held on March 23 to 28, 2025, in Pomona, California, United States.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_27" style="position: unset;">
                      <div class="content" style="position: unset;">The International Society for Stem Cell Research
                        (ISSCR) 2025 Annual Meeting will be held on June 11 to 14, 2025, in Hong Kong.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_28" style="position: unset;">
                      <div class="content" style="position: unset;">The American Society for Bone and Mineral Research
                        (ASBMR) 2025 Annual Meeting will be held on September 4 to 8, 2025, in Seattle, Washington,
                        United States.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_29" style="position: unset;">
                      <div class="content" style="position: unset;">The 33rd Annual Meeting of the European Orthopaedic
                        Research Society will be held on June 16 to 19, 2025, in Davos, Switzerland.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
              <li style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_30" style="position: unset;">
                      <div class="content" style="position: unset;">The 18th International Cartilage Regeneration &
                        Joint Preservation Society (ICRS) World Congress will be held on October 11 to 14, 2025, in
                        Boston, Massachusetts, United States.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </li>
            </ul>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Zhang Z</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Su J</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Tang J</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Chung L</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Page JC</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Winter CC</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Liu Y</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Kegeles E</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Conti S</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Biundo J</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Chalif JI</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Hua CY</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Yang Z</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Yao X</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Yang Y</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Chen S</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Schwab JM</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Wang KH</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Chen C</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Prerau MJ</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> He Z</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Spinal projecting neurons in
                  rostral ventromedial medulla co-regulate motor and sympathetic tone</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Cell.</span> 2024 Jun
                20;187(13):3427-3444.e21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spinal%20projecting%20neurons%20in%20rostral%20ventromedial%20medulla%20co-regulate%20motor%20and%20sympathetic%20tone&as_occt=title&as_sauthors=%20%22Z%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Lu YZ</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Nayer B</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Singh SK</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Alshoubaki YK</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Yuan E</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Park AJ</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Maruyama K</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Akira S</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Martino MM</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">CGRP sensory neurons promote
                  tissue healing via neutrophils and macrophages</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Nature.</span> 2024
                Apr;628(8008):604-11.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=CGRP%20sensory%20neurons%20promote%20tissue%20healing%20via%20neutrophils%20and%20macrophages&as_occt=title&as_sauthors=%20%22YZ%20Lu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Babey ME</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Krause WC</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Chen K</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Herber CB</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Torok Z</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Nikkanen J</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Rodriguez R</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Zhang X</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Castro-Navarro F</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Wang Y</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Wheeler EE</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Villeda S</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Leach JK</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Lane NE</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Scheller EL</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Chan CKF</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Ambrosi TH</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Ingraham HA</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">A maternal brain hormone that
                  builds bone</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Nature.</span> 2024 Aug;632(8024):357-65.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20maternal%20brain%20hormone%20that%20builds%20bone&as_occt=title&as_sauthors=%20%22ME%20Babey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Andreev D</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Kachler K</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Liu M</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Chen Z</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Krishnacoumar B</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Ringer M</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Frey S</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Krönke G</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Voehringer D</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Schett G</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Bozec A</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Eosinophils preserve bone
                  homeostasis by inhibiting excessive osteoclast formation and activity via eosinophil
                  peroxidase</span>. <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Nat
                  Commun.</span> 2024 Feb 5;15(1):1067.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Eosinophils%20preserve%20bone%20homeostasis%20by%20inhibiting%20excessive%20osteoclast%20formation%20and%20activity%20via%20eosinophil%20peroxidase&as_occt=title&as_sauthors=%20%22D%20Andreev%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Collins JM</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Lang A</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Parisi C</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Moharrer Y</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Nijsure MP</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Thomas Kim JH</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Ahmed S</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Szeto GL</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Qin L</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Gottardi R</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Dyment NA</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Nowlan NC</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Boerckel JD</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">YAP and TAZ couple osteoblast
                  precursor mobilization to angiogenesis and mechanoregulation in murine bone development</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Dev Cell.</span> 2024 Jan
                22;59(2):211-227.e5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=YAP%20and%20TAZ%20couple%20osteoblast%20precursor%20mobilization%20to%20angiogenesis%20and%20mechanoregulation%20in%20murine%20bone%20development&as_occt=title&as_sauthors=%20%22JM%20Collins%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Dudek M</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Pathiranage DRJ</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Bano-Otalora B</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Paszek A</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Rogers N</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Gonçalves CF</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Lawless C</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Wang D</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Luo Z</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Yang L</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Guilak F</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Hoyland JA</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Meng QJ</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Mechanical loading and
                  hyperosmolarity as a daily resetting cue for skeletal circadian clocks</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Nat Commun.</span> 2023 Nov
                14;14(1):7237.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mechanical%20loading%20and%20hyperosmolarity%20as%20a%20daily%20resetting%20cue%20for%20skeletal%20circadian%20clocks&as_occt=title&as_sauthors=%20%22M%20Dudek%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Shao X</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Tian Y</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Liu J</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Yan Z</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Ding Y</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Hao X</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Wang D</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Shen L</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Luo E</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Guo XE</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Luo P</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Luo W</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Cai J</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Jing D</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Rescuing SERCA2 pump deficiency
                  improves bone mechano-responsiveness in type 2 diabetes by shaping osteocyte calcium dynamics</span>.
                <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Nat Commun.</span> 2024
                Jan 30;15(1):890.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Rescuing%20SERCA2%20pump%20deficiency%20improves%20bone%20mechano-responsiveness%20in%20type%202%20diabetes%20by%20shaping%20osteocyte%20calcium%20dynamics&as_occt=title&as_sauthors=%20%22X%20Shao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Liao P</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Chen L</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Zhou H</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Mei J</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Chen Z</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Wang B</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Feng JQ</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Li G</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Tong S</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Zhou J</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Zhu S</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Qian Y</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Zong Y</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Zou W</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Li H</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Zhang W</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Yao M</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Ma Y</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Ding P</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Pang Y</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Gao C</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Mei J</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Zhang S</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Zhang C</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Liu D</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Zheng M</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Gao J</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Osteocyte mitochondria regulate
                  angiogenesis of transcortical vessels</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">Nat Commun.</span> 2024 Mar 21;15(1):2529.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteocyte%20mitochondria%20regulate%20angiogenesis%20of%20transcortical%20vessels&as_occt=title&as_sauthors=%20%22P%20Liao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Bixel MG</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Sivaraj KK</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Timmen M</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Mohanakrishnan V</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Aravamudhan A</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Adams S</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Koh BI</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Jeong HW</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Kruse K</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Stange R</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Adams RH</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Angiogenesis is uncoupled from
                  osteogenesis during calvarial bone regeneration</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Nat Commun.</span> 2024 Jun
                4;15(1):4575.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Angiogenesis%20is%20uncoupled%20from%20osteogenesis%20during%20calvarial%20bone%20regeneration&as_occt=title&as_sauthors=%20%22MG%20Bixel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Ganmaa D</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Khudyakov P</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Buyanjargal U</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Tserenkhuu E</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Erdenenbaatar S</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Achtai CE</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Yansanjav N</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Delgererekh B</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Ankhbat M</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Tsendjav E</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Ochirbat B</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Jargalsaikhan B</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Enkhmaa D</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Martineau AR</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Vitamin D supplements for
                  fracture prevention in schoolchildren in Mongolia: analysis of secondary outcomes from a multicentre,
                  double-blind, randomised, placebo-controlled trial</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">Lancet Diabetes Endocrinol.</span> 2024
                Jan;12(1):29-38.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vitamin%20D%20supplements%20for%20fracture%20prevention%20in%20schoolchildren%20in%20Mongolia%3A%20analysis%20of%20secondary%20outcomes%20from%20a%20multicentre%2C%20double-blind%2C%20randomised%2C%20placebo-controlled%20trial&as_occt=title&as_sauthors=%20%22D%20Ganmaa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Middelkoop K</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Micklesfield LK</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Walker N</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Stewart J</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Delport C</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Jolliffe DA</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Mendham AE</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Coussens AK</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> van Graan A</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Nuttall J</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Tang JCY</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Fraser WD</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Cooper C</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Harvey NC</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Hooper RL</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Wilkinson RJ</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Bekker LG</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Martineau AR</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Influence of vitamin D
                  supplementation on bone mineral content, bone turnover markers, and fracture risk in South African
                  schoolchildren: multicenter double-blind randomized placebo-controlled trial (ViDiKids)</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J Bone Miner Res.</span>
                2024 Apr 19;39(3):211-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Influence%20of%20vitamin%20D%20supplementation%20on%20bone%20mineral%20content%2C%20bone%20turnover%20markers%2C%20and%20fracture%20risk%20in%20South%20African%20schoolchildren%3A%20multicenter%20double-blind%20randomized%20placebo-controlled%20trial%20(ViDiKids)&as_occt=title&as_sauthors=%20%22K%20Middelkoop%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Jensen SBK</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Sørensen V</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Sandsdal RM</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Lehmann EW</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Lundgren JR</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Juhl CR</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Janus C</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Ternhamar T</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Stallknecht BM</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Holst JJ</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Jørgensen NR</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Jensen JB</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Madsbad S</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Torekov SS</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Bone health after exercise
                  alone, GLP-1 receptor agonist treatment, or combination treatment: a secondary analysis of a
                  randomized clinical trial</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">JAMA Netw Open.</span> 2024 Jun 3;7(6):e2416775.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20health%20after%20exercise%20alone%2C%20GLP-1%20receptor%20agonist%20treatment%2C%20or%20combination%20treatment%3A%20a%20secondary%20analysis%20of%20a%20randomized%20clinical%20trial&as_occt=title&as_sauthors=%20%22SBK%20Jensen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Mezincescu AM</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Rudd A</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Cheyne L</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Horgan G</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Philip S</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Cameron D</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> van Loon L</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Whitfield P</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Gribbin R</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Hu MK</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Delibegovic M</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Fielding B</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Lobley G</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Thies F</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Newby DE</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Gray S</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Henning A</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Dawson D</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Comparison of intramyocellular
                  lipid metabolism in patients with diabetes and male athletes</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">Nat Commun.</span> 2024 May
                15;15(1):3690.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20intramyocellular%20lipid%20metabolism%20in%20patients%20with%20diabetes%20and%20male%20athletes&as_occt=title&as_sauthors=%20%22AM%20Mezincescu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Ni Lochlainn M</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Bowyer RCE</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Moll JM</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> García MP</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Wadge S</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Baleanu AF</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Nessa A</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Sheedy A</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Akdag G</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Hart D</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Raffaele G</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Seed PT</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Murphy C</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Harridge SDR</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Welch AA</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Greig C</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Whelan K</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Steves CJ</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Effect of gut microbiome
                  modulation on muscle function and cognition: the PROMOTe randomised controlled trial</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Nat Commun.</span> 2024 Feb
                29;15(1):1859.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20gut%20microbiome%20modulation%20on%20muscle%20function%20and%20cognition%3A%20the%20PROMOTe%20randomised%20controlled%20trial&as_occt=title&as_sauthors=%20%22Lochlainn%20M%20Ni%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Jiang F</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Hu SY</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Tian W</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Wang NN</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Yang N</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Dong SS</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Song HM</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Zhang DJ</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Gao HW</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Wang C</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Wu H</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> He CY</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Zhu DL</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Chen XF</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Guo Y</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Yang Z</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Yang TL</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">A landscape of gene expression
                  regulation for synovium in arthritis</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Nat Commun.</span> 2024 Feb 15;15(1):1409.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20landscape%20of%20gene%20expression%20regulation%20for%20synovium%20in%20arthritis&as_occt=title&as_sauthors=%20%22F%20Jiang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Lei Y</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Guo X</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Luo Y</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Niu X</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Xi Y</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Xiao L</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> He D</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Bian Y</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Wang L</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Peng X</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Wang Z</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Chen G</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Synovial
                  microenvironment-influenced mast cells promote the progression of rheumatoid arthritis</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Nat Commun.</span> 2024 Jan
                2;15(1):113.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Synovial%20microenvironment-influenced%20mast%20cells%20promote%20the%20progression%20of%20rheumatoid%20arthritis&as_occt=title&as_sauthors=%20%22Y%20Lei%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Snyder PJ</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Bauer DC</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Ellenberg SS</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Cauley JA</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Buhr KA</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Bhasin S</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Miller MG</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Khan NS</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Li X</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Nissen SE</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Testosterone treatment and
                  fractures in men with hypogonadism</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">N Engl J Med.</span> 2024 Jan 18;390(3):203-11.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Testosterone%20treatment%20and%20fractures%20in%20men%20with%20hypogonadism&as_occt=title&as_sauthors=%20%22PJ%20Snyder%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Luo WD</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Wang YP</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Lv J</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Liu Y</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Qu YQ</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Xu XF</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Yang LJ</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Lin ZC</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Wang LN</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Chen RH</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Yang JJ</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Zeng YL</span>, <span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Zhang RL</span>, <span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Huang BX</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Yun XY</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Wang XY</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Song LL</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Wu JH</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Wang XX</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Chen X</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Zhang W</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Wang HM</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Qu LQ</span>, <span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Liu MH</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Liu L</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Law BYK</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Wong VKW</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Age-related self-DNA
                  accumulation may accelerate arthritis in rats and in human rheumatoid arthritis</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">Nat Commun.</span> 2023 Jul
                20;14(1):4394.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Age-related%20self-DNA%20accumulation%20may%20accelerate%20arthritis%20in%20rats%20and%20in%20human%20rheumatoid%20arthritis&as_occt=title&as_sauthors=%20%22WD%20Luo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Jiang R</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Noble S</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Sui J</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Yoo K</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Rosenblatt M</span>, <span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Horien C</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Qi S</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Liang Q</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Sun H</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Calhoun VD</span>, <span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Scheinost D</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Associations of physical
                  frailty with health outcomes and brain structure in 483 033 middle-aged and older adults: a
                  population-based study from the UK Biobank</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Lancet Digit Health.</span> 2023
                Jun;5(6):e350-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Associations%20of%20physical%20frailty%20with%20health%20outcomes%20and%20brain%20structure%20in%20483%20033%20middle-aged%20and%20older%20adults%3A%20a%20population-based%20study%20from%20the%20UK%20Biobank&as_occt=title&as_sauthors=%20%22R%20Jiang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Guirguis-Blake JM</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Perdue LA</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Coppola EL</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Bean SI</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Interventions to prevent falls
                  in older adults: updated evidence report and systematic review for the US Preventive Services Task
                  Force</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2024 Jul 2;332(1):58-69.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Interventions%20to%20prevent%20falls%20in%20older%20adults%3A%20updated%20evidence%20report%20and%20systematic%20review%20for%20the%20US%20Preventive%20Services%20Task%20Force&as_occt=title&as_sauthors=%20%22JM%20Guirguis-Blake%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D56410f65-daca-4dfa-b173-9a771c17d354%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Guest Editorial</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 4, 2024;
                      106 (23): 2181</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.01086</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 17, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_21"
                            class="annotation emphasis" style="position: unset;">Investigation performed at The
                            University of Texas Medical Branch, Galveston, Texas</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;56410f65-daca-4dfa-b173-9a771c17d354&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=56410f65-daca-4dfa-b173-9a771c17d354&type=pdf&name=JBJS.24.01086.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=56410f65-daca-4dfa-b173-9a771c17d354&type=pdf&name=JBJS.24.01086.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_21"
                            class="annotation strong" style="position: unset;">Disclosure</span>: No external funding
                          was received for this work. The <span data-id="strong_22" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> form is
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I251"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I251</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;56410f65-daca-4dfa-b173-9a771c17d354&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=56410f65-daca-4dfa-b173-9a771c17d354&type=zip&name=JBJS.24.01086.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Zbigniew Gugala, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:zgugala@utmb.edu" class="" style="position: unset;">zgugala@utmb.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2331-7660" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2331-7660</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery and Rehabilitation, The University of Texas
                Medical Branch, Galveston, Texas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 25813.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
