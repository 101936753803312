import type { Article } from "../../../../../types";

export const Article626: Article = {
	id: 626,
	rsuiteId: "5073f84c-a119-4f63-bd0d-9b74b4fe0037",
	type: "scientific article",
	title: "Defining the Cost of Arthroscopic Rotator Cuff Repair",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/626.jpeg",
	subSpecialties: ["shoulder_elbow"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Defining the Cost of Arthroscopic Rotator Cuff Repair
              </div>
              <div class="text subtitle" style="position: unset;">A Multicenter, Time-Driven Activity-Based Costing and
                Cost Optimization Investigation</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Catherine J.
                      Fedorka, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ana Paula
                      Beck da Silva Etges, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 20 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_18" class="annotation strong"
                          style="position: unset;">Background:</span> Rotator cuff repair (RCR) is a frequently
                        performed outpatient orthopaedic surgery, with substantial financial implications for
                        health-care systems. Time-driven activity-based costing (TDABC) is a method for nuanced cost
                        analysis and is a valuable tool for strategic health-care decision-making. The aim of this study
                        was to apply the TDABC methodology to RCR procedures to identify specific avenues to optimize
                        cost-efficiency within the health-care system in 2 critical areas: (1) the reduction of
                        variability in the episode duration, and (2) the standardization of suture anchor acquisition
                        costs.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_19" class="annotation strong"
                          style="position: unset;">Methods:</span> Using a multicenter, retrospective design, this study
                        incorporates data from all patients who underwent an RCR surgical procedure at 1 of 4 academic
                        tertiary health systems across the United States. Data were extracted from Avant-Garde Health’s
                        Care Measurement platform and were analyzed utilizing TDABC methodology. Cost analysis was
                        performed using 2 primary metrics: the opportunity costs arising from a possible reduction in
                        episode duration variability, and the potential monetary savings achievable through the
                        standardization of suture anchor costs.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_20" class="annotation strong"
                          style="position: unset;">Results:</span> In this study, 921 RCR cases performed at 4
                        institutions had a mean episode duration cost of $4,094 ± $1,850. There was a significant
                        threefold cost variability between the 10th percentile ($2,282) and the 90th percentile ($6,833)
                        (p &lt; 0.01). The mean episode duration was registered at 7.1 hours. The largest variability in
                        the episode duration was time spent in the post-acute care unit and the ward after the surgical
                        procedure. By reducing the episode duration variability, it was estimated that up to 640
                        care-hours could be saved annually at a single hospital. Likewise, standardizing suture anchor
                        acquisition costs could generate direct savings totaling $217,440 across the hospitals.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_21" class="annotation strong"
                          style="position: unset;">Conclusions:</span> This multicenter study offers valuable insights
                        into RCR cost as a function of care pathways and suture anchor cost. It outlines avenues for
                        achieving cost-savings and operational efficiency. These findings can serve as a foundational
                        basis for developing health-economics models.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_6" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_22" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Economic and Decision Analysis <span
                          data-id="underline_1" class="annotation underline" style="position: unset;">Level III</span>.
                        See Instructions for Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">In the United States, nearly 5 million patients seek
                  treatment for rotator cuff pathology every year. In 2022, an estimated 1,119,734 primary rotator cuff
                  surgical procedures were performed per year<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>,
                  costing nearly $7 billion annually<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>.
                  Rotator cuff repair (RCR) is also among the most cost-variable surgical procedures<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a>, which inherently makes it an excellent target for both cost
                  analysis and cost-optimization strategies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Time-driven activity-based costing (TDABC) is a method of
                  microcosting that has been accepted as both more accurate and more transparent than traditional cost
                  accounting. In the field of orthopaedic surgery, TDABC has been utilized to study the cost of surgical
                  procedures<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a>,
                  clinic consultations<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">5</span></a>, and
                  care across different hospital settings. Principally, TDABC allows researchers to accurately identify
                  the cost drivers of an episode duration. However, TDABC can also be used to highlight optimal resource
                  utilization for increased efficiency or increased capacity<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">6</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Several studies using TDABC have been published on RCR to
                  date<a href="" data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">9</span></a>. Each of the aforementioned studies analyzed the data from a
                  single institution. Although each of the reported cost estimates for RCR is likely accurate for its
                  own institution, they lack external generalizability. Furthermore, a key limitation of reporting on
                  single-institution cost data is the lack of insight into resource efficiency and capacity. Identifying
                  efficiency and capacity opportunities in surgical services is an integral part of an approach to
                  achieving a more sustainable health-care system through cost reduction. Specifically, this can be
                  achieved by reducing the variability in resource consumption. In this regard, TDABC has been
                  considered as the gold-standard method for precisely measuring costs and identifying cost-saving
                  opportunities throughout the care processes<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">10</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">As such, the goal of this study was to utilize a
                  multicenter approach and TDABC methodology to accurately define the cost of RCR with more external
                  generalizability than previously reported, and demonstrate potential cost-optimization opportunities
                  by reducing variability in RCR time-derived costs and implant acquisition costs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">This was a multicenter, retrospective study that included
                  all patients undergoing an RCR at 4 U.S. academic tertiary care systems. Hospitals A, B, and C
                  collected data from January 2021 to December 2022, and hospital D collected data from January 2022 to
                  December 2022. Hospitals A, B, and C are located in New England, and hospital D is located in the
                  Mid-Atlantic region. Cases were identified using Current Procedural Technology (CPT) codes for RCR and
                  associated procedures. Patients were excluded if they did not have an RCR or had additional procedures
                  performed beyond those listed above. A complete list of CPT codes identified and included is available
                  in Appendix I.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Collection and Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The data for surgical supplies (including implants),
                  medications, time stamps, and personnel costs were extracted from Care Measurement (Avant-Garde
                  Health). Care Measurement provides patient-level electronic health data including demographic
                  characteristics, CPT codes, time stamps throughout the surgical pathway, professional involvement, and
                  supplies and medications utilized, including all acquisition costs. The patient-level data were
                  submitted for validation to look for anomalies, such as inconsistent time stamps and supply prices
                  that were outside of typical ranges. These cases were excluded.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The steps established for the TDABC study were strictly
                  followed and guided the data collection. The patient care pathway map was created and identified
                  resources utilized by patients along the care pathway, assessed the cost of each resource identified,
                  and estimated the capacity of each resource. The capacity cost rate for each resource was calculated.
                  Finally, the cost equations per patient were structured, multiplying the length of time that each
                  resource was utilized by its cost capacity rate, allowing the calculation of the unit costs<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">3</span></a>.
                  Appendix II contains a detailed description of how Care Measurement considers the TDABC method in its
                  codes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The episode duration was defined from patient arrival at
                  the hospital through hospital discharge. The cost analysis focused on direct personnel and direct
                  supply costs, including for medications, implants, and general materials. Indirect and hospital
                  structural costs, such as loss of productivity and hospital fixed costs (depreciation, energy), were
                  excluded. The capacity cost rate, the length of time spent per patient in each phase of the care
                  cycle, and the supply consumption were extracted from Care Measurement, using the real-world data from
                  the period in which each patient was treated. For supply costs, the hospital acquisition cost was
                  considered, excluding profit margins.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Three data analyses were performed to explore the granular
                  cost information achieved with TDABC. The first one described the cost composition in the sample of
                  patients included in the microcosting study. The second one compared the resource consumption between
                  the centers, measuring the variability in the surgical pathway. The third one identified potential
                  cost-saving opportunities achieved by the potential redesign of the care pathways. In all of them,
                  costs were organized in groups according to the resource category: supply, medications, and personnel
                  costs in each phase of the care cycle.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">We acknowledge that patient demographic characteristics,
                  surgical complexity, and anesthesia practices vary across centers, potentially influencing the episode
                  duration and cost metrics. To ensure the robustness of our findings, this variability is recognized as
                  a factor in our analysis, underscoring the necessity of contextual interpretation of the data.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Microcosting
                Descriptive Statistics for RCR Surgery</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Descriptive statistics were used to analyze the cost per
                  surgical procedure based on the sample of patients included in the study from each center, followed by
                  a cost composition analysis that allowed for quantification of the proportion of cost justified by
                  supplies and personnel costs in each center. The Mann-Whitney test, a nonparametric statistical test,
                  was used to evaluate differences in total costs between groups. Significance was set at p &lt; 0.05.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Care
                Capacity and Potential Supply Cost-Savings</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Care capacity was quantified by identifying the centers
                  where the mean episode duration between patient check-in and post-acute care unit (PACU) discharge was
                  longer than at other centers, and quantifying how many care-hours could be saved by reducing the
                  episode duration variability between the centers. First, the episode duration was broken into phases
                  based on the surgery process map: check-in to anesthesia start, anesthesia start to incision, incision
                  to surgical room check-out, duration in the PACU, and duration in the ward prior to discharge. The
                  mean time duration that patients spent in each phase was measured per hospital, and the mean episode
                  duration from check-in to PACU departure at the center with the shortest episode duration was set as a
                  reference to quantify the number of additional care-hours that could be delivered by the other centers
                  based on the potential decreases in episode duration.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Finally, the cost-saving opportunity associated with the
                  variability in implant costs between centers was estimated, focusing on the suture anchors. Suture
                  anchors were selected as the focus of our implant costs as previous studies have shown that implant
                  costs drive overall episode duration costs<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_10" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_10" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_10" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">12</span></a>. The suture anchor with the lowest cost utilized at each
                  institution was used to estimate the potential savings achieved, by reviewing implant purchase
                  agreements in the other centers. Potential cost savings were estimated by using the median number of
                  anchors per case utilized across the patient cohort. All of the cost information was collected and
                  presented in U.S. dollars in 2022.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The analyses in this study included 921 patients who
                  underwent RCR at 4 academic, tertiary-care centers. Most of the patients were male (61%), and the mean
                  patient age (and standard deviation) was 60 ± 10 years (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). The
                  mean cost per patient was $4,094 ± $1,850. The variability between the 90th percentile ($6,833) and
                  the 10th percentile ($2,282) was 3.0-fold (p &lt; 0.01).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Sample Description</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 414px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Hospital</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Cases</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_18" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Male
                          Sex</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">CMS<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Length of
                          Time<span data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_19" class="annotation emphasis"
                            style="position: unset;">(hr)</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">158</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.7 ±
                          4.8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">B</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">610</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60 ±
                          11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.7 ±
                          1.4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52 ±
                          11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.1 ±
                          5.2</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">D</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">101</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.7 ±
                          0.9</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">921</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60 ±
                          10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.1 ±
                          3.0</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">U.S. Centers for Medicare & Medicaid Services.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">The center with the highest volume demonstrated the lowest
                  mean cost (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>), and
                  most of the cost (56%) was accounted for by the supply cost and usage (number of individual supplies
                  used). The greatest variability between centers was in implant and personnel costs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Cost Composition per Patient and Center<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 459.605px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.707527);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Hospital</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Cases</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Personnel
                          Cost<span data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total
                          Implant Cost<span data-id="table_footnote_reference_5"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Medication
                          Cost<span data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Non-Implant and Non-Medication Cost<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total
                          Supply Cost<span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total
                          Cost<span data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">158</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$2,077 ±
                          $647</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$1,303 ±
                          $1,505</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$116 ±
                          $88</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$699 ±
                          $543</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$2,064 ±
                          $1,659</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$4,141 ±
                          2,101</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">B</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">610</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$1,684 ±
                          $346</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$1,564 ±
                          $1,395</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$43 ±
                          $24</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$564 ±
                          $345</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$2,171 ±
                          $1,469</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$3,855 ±
                          $1,702</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$2,050 ±
                          $494</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$1,466 ±
                          $1,743</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$41 ±
                          $49</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$620 ±
                          $207</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$2,127 ±
                          $1,828</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$4,178 ±
                          $2,102</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">D</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">101</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$2,998 ±
                          $535</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$1,809 ±
                          $1,400</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$62 ±
                          $30</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$551 ±
                          $179</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$2,423 ±
                          $1,459</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$5,421 ±
                          $1,605</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$1,916 ±
                          $604</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$1,541 ±
                          $1,440</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$58 ±
                          $52</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$589 ±
                          $371</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$2,178 ±
                          $1,525</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">$4,094 ±
                          $1,850</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The cost information is expressed on a case level.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Variability
                in Episode Duration and Implant Cost</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">The mean length of episode duration of the total surgical
                  cycle was 7.1 hours (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>). The
                  periods in the PACU and in the ward after the surgical procedure were identified as the ones with the
                  highest variability between the centers. Facility volume was not associated with a shorter episode
                  duration, as the hospital with the third-highest volume had the shortest episode duration (hospital D:
                  5.7 hours). <a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a> shows
                  the mean episode duration in hours per surgical phase in each center. Taking the center with the
                  shortest length of time in the PACU to discharge (excluding time in the ward), B, as a reference, it
                  would have been possible to save 43, 120, and 123 PACU care-hours annually in the other 3 centers (<a
                    href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>). If the time in the ward is included in the total episode
                  duration and hospital D (in which patients were discharged directly from the PACU) is set as the
                  reference center, it would have been possible to save 178 care-hours at hospital C, 471 care-hours at
                  hospital A, and 640 care-hours as hospital B (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Mean Episode Duration per Surgical Phase and
                          Hospital</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 289.626px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.629063);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Hospital</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Cases</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Check-in
                          to Anesthesia Start <span data-id="emphasis_20" class="annotation emphasis"
                            style="position: unset;">(hr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Anesthesia
                          Start to Incision <span data-id="emphasis_21" class="annotation emphasis"
                            style="position: unset;">(hr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Incision
                          to Surgical Room Checkout <span data-id="emphasis_22" class="annotation emphasis"
                            style="position: unset;">(hr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">PACU <span
                            data-id="emphasis_23" class="annotation emphasis"
                            style="position: unset;">(hr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total,
                          Check-in to PACU Departure <span data-id="emphasis_24" class="annotation emphasis"
                            style="position: unset;">(hr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Ward <span
                            data-id="emphasis_25" class="annotation emphasis"
                            style="position: unset;">(hr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total
                          Episode Duration <span data-id="emphasis_26" class="annotation emphasis"
                            style="position: unset;">(hr)</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">158</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.75<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.51</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.54</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.30</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.59<span
                            data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.69</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">B</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">610</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.35</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.46<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.43<span
                            data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.03<span
                            data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.27<span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.49</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.76</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.96</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.73<span
                            data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.94<span
                            data-id="table_footnote_reference_18" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.33<span
                            data-id="table_footnote_reference_19" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.97<span
                            data-id="table_footnote_reference_20" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.16</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.13</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">D</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">101</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.07<span
                            data-id="table_footnote_reference_21" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.68</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.71</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.25</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.71</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—<span
                            data-id="table_footnote_reference_22" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.71</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.25</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.50</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.33</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.58</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.48</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.06</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Maximum
                          variability</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.68</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.27</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.51</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.69</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.59</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.42</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Highest length of stay observed in the phase of care.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Lowest length of stay observed in the phase of care.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Potential Annual Care-Hours Saved by Reducing the
                          Variability in PACU Time</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 346px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Hospital</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Actual
                          PACU Time <span data-id="emphasis_27" class="annotation emphasis"
                            style="position: unset;">(hr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Cases</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Target
                          PACU Time <span data-id="emphasis_28" class="annotation emphasis"
                            style="position: unset;">(hr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Potential
                          Time Savings <span data-id="emphasis_29" class="annotation emphasis"
                            style="position: unset;">(hr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Potential
                          Care-Hours Saved <span data-id="emphasis_30" class="annotation emphasis"
                            style="position: unset;">(hr)</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference, B</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.03</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">610</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.03</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.30</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">158</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.03</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.27</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">42.66</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.33</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.03</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.30</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">119.60</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">D</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.25</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">101</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.03</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.22</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">123.22</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Potential Annual Care-Hours Saved by Reducing the
                          Variability in Total Episode Duration Including Time in the Ward</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 326.458px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.932011);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Hospital</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Actual
                          Total Episode Duration <span data-id="emphasis_31" class="annotation emphasis"
                            style="position: unset;">(hr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Cases</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Target
                          Episode Duration <span data-id="emphasis_32" class="annotation emphasis"
                            style="position: unset;">(hr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Potential
                          Time Savings <span data-id="emphasis_33" class="annotation emphasis"
                            style="position: unset;">(hr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Potential
                          Care-Hours Saved <span data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">(hr)</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.69</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">158</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.71</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.98</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">470.84</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">B</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.76</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">610</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.71</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.05</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">640.5</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.13</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.71</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.42</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">177.84</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference, D</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.71</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">101</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.71</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The implant cost per case was defined as the number of
                  suture anchors utilized multiplied by the cost per implant. Focusing only on the mean suture anchor
                  acquisition costs revealed a variability in price ranging from $578 at hospital D to $1,273 in
                  hospital C (with $768 in hospital A and $826 at hospital B). If all hospitals had been able to acquire
                  the suture anchors at the cost of $578, then it would have been possible to achieve a total savings of
                  $217,440 at the hospitals during the period of analysis, based on the number of cases performed in
                  each hospital and the median number of anchors used per case across the cohort (1) (<a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VI</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VI</span>Potential Annual Cost Savings by Reducing the
                          Variability in Suture Anchor Costs</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 337.905px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.964809);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Hospital</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Cases</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          Suture Anchor Cost per Case</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total
                          Suture Anchor Cost per Hospital</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Potential
                          Total Cost</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Estimated
                          Cost Savings</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">158</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$768</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$121,344</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$91,324</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$30,020</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">B</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">610</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$826</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$503,860</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$352,580</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$151,280</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$1,273</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$66,196</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$30,056</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$36,140</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">D</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">101</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$578</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$58,378</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">$58,378</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">In this multicenter, high-powered study, microcosting was
                  applied to accurately identify the cost of RCR and the potential cost savings achievable through
                  reducing variability. We discovered the mean cost for the episode duration for RCR to be $4,094, with
                  high cost variability: a threefold difference was observed between the lowest-cost and highest-cost
                  cases. Notably, the greatest variability was found in the time that patients spent in the PACU and the
                  ward after the surgical procedure, which ranged from a total of 2.25 to &gt;4 hours across the 4
                  centers. Although surgical time showed less variability (1.43 to 1.94 hours), our analysis highlights
                  the time in the PACU and overall episode duration as critical factors in operational efficiency.
                  Reducing nonoperative components of the episode duration, particularly in the PACU and episode
                  durations, not only reflects an opportunity to streamline patient care but also underscores an avenue
                  for increasing surgical volume. Optimizing these components can alleviate bottlenecks in the surgical
                  care pathway, thereby enhancing the capacity for additional surgical procedures. Despite the inherent
                  limitations posed by operating room availability and labor constraints, this approach to improving
                  operational efficiency presents a strategic pathway to augment surgical throughput. By focusing on
                  these areas for efficiency gains, health-care systems can potentially increase the number of surgical
                  procedures performed, thus leveraging our findings for broader operational improvements without
                  compromising the quality of patient care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Although our analysis identifies potential efficiencies
                  through episode duration reduction, it may suggest that such reductions are without associated costs.
                  It is important to clarify that our assessment primarily envisioned the potential for additional
                  revenue generation through increased surgical volume facilitated by episode duration reductions.
                  However, we have not delved into the detailed calculation of additional costs that these added
                  surgical procedures might incur—including, but not limited to, direct expenses such as surgical
                  supplies and personnel as well as indirect costs such as increased postoperative care. Additionally,
                  it is essential to state that achieving reductions in episode duration, particularly to levels
                  observed in the most efficient center, would likely incur costs related to process optimization,
                  training, and perhaps even infrastructure adjustments. These investments are critical to realize the
                  efficiency gains noted. They should be factored into any cost-benefit analysis of episode duration
                  reduction strategies, ensuring a comprehensive understanding of both the potential revenue
                  enhancements and the associated costs of operational changes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Similar to previous work on costs in RCR and other
                  orthopaedic procedures, our study has shown that implant costs are one of the main direct costs
                  driving the total cost for the episode duration<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_11" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_11" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_11" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">17</span></a> but also found that there is high market variability in
                  price between centers. There was more than a twofold difference between the center with the lowest
                  cost per anchor and the center with the highest cost per anchor. This discrepancy shows that
                  negotiating implant costs can also influence the total cost of the procedure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Previous studies utilizing TDABC for RCR analysis, such as
                  those by Bernstein et al.<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">3</span></a>,
                  Koolmees et al.<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">8</span></a>, and
                  Wise et al.<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">9</span></a>,
                  were confined to single institutions, limiting their ability to assess efficiency and cost driver
                  differences across various settings. Bernstein et al. discovered intraoperative costs to be the
                  predominant expense, accounting for 91% of total costs, primarily driven by the use of biologic
                  implants and surgeon preferences. Koolmees et al. reported a mean 90-day episode duration cost of
                  $10,569, with operative costs comprising 76% of this. Meanwhile, Wise et al. found surgical and
                  implant costs to be the major contributors to a mean RCR cost of $5,413 per case, noting a minimal
                  correlation between cost and patient-reported outcomes. Unlike these prior studies, our multicenter
                  analysis uniquely explores the variability in episode duration costs. It identifies postoperative
                  recovery time and suture anchor costs as areas with the highest variability across institutions. This
                  approach not only corroborates the previously noted cost variability but also advances our
                  understanding by pinpointing specific segments within the episode duration and supply chain that offer
                  opportunities for standardization and efficiency improvement.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">After recognizing the variability in the episode duration
                  across centers, it is crucial to consider the multifaceted reasons behind such differences. The center
                  exhibiting the shortest episode duration potentially benefitted from specific patient demographic
                  characteristics and surgical and anesthesia practices that are optimized for efficiency. This distinct
                  context raises important questions about the generalizability of these results to other settings with
                  different patient populations and operational protocols. The variability emphasizes the necessity for
                  a tailored approach in applying efficiency improvements, considering the unique characteristics of
                  each health-care facility. Further investigation into the factors influencing the episode duration,
                  such as patient health status, surgical complexity, and anesthesia type, will enhance our
                  understanding of how best to implement such efficiencies across diverse surgical settings.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">This study had limitations that warrant consideration. It
                  encompassed 4 academic medical centers, which may have limited the generalizability of our findings
                  across diverse health-care settings. A notable limitation was our inability to differentiate the types
                  of anesthesia used, which could have influenced recovery times. This aspect suggests a valuable
                  direction for future research aimed at elucidating factors that extend postoperative recovery, thereby
                  identifying opportunities for enhancing operational efficiency safely. Furthermore, our analysis did
                  not encompass the evaluation of tear size, patient comorbidities, or other patient-specific factors.
                  Despite these limitations, the breadth of patient data analyzed likely provided a representative
                  overview of the typical patient population undergoing RCR. Additionally, our study did not explore the
                  nuances of surgeon idiosyncrasy and intraoperative decision-making, nor could it directly assess tear
                  characteristics. The potential for cost variation across different care delivery networks remains
                  unexplored. Although our analysis included personnel costs, a detailed examination of labor
                  utilization across centers was beyond our study’s scope.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">This research offers insights into the variability and
                  potential for efficiency optimization in RCR procedures. However, it does not incorporate
                  postoperative data, including patient-reported outcomes, complications, or failure rates, leaving
                  unanswered questions regarding the real-world value of the proposed cost-saving strategies. Looking
                  forward, there is a critical need for prospective multicenter research that delves into how variables
                  such as anesthesia type, tear size, and labor utilization vary between institutions and their
                  consequent impact on the episode duration and overall episode duration costs. Such studies should also
                  extend to include patient outcomes, providing a comprehensive assessment of whether cost-minimization
                  efforts indeed translate into tangible benefits for patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, we utilized microcosting and the TDABC
                  methodology to identify RCR cost in the largest cohort to date. We showed that the episode duration,
                  including variability in postoperative recovery time, was a large driver of cost. The multicenter
                  approach highlights the variability in care delivery for RCR, showing that both optimization of the
                  PACU pathway as well as implant negotiations may significantly reduce episode-of-care costs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_2" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I287" target="_blank" data-id="link_3" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I287</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">NOTE: Members of the Avant-Garde Health and Codman
                  Shoulder Society Value-Based Care Group include: Catherine J. Fedorka, MD (Cooper Bone and Joint
                  Institute, Cooper University Hospital, Camden, New Jersey); Ana Paula Beck da Silva Etges, PhD
                  (Avant-Garde Health, Boston, Massachusetts); Matthew J. Best, MD (Department of Orthopaedic Surgery,
                  Johns Hopkins Hospital, Johns Hopkins University School of Medicine, Baltimore, Maryland); Harry H.
                  Liu, PhD (Avant-Garde Health, Boston, Massachusetts); Xiaoran Zhang, MA (Avant-Garde Health, Boston,
                  Massachusetts); Brett Sanders, MD (Center for Sports Medicine and Orthopaedics, Chattanooga,
                  Tennessee); Joseph A. Abboud, MD (Rothman Institute, Thomas Jefferson University Hospital,
                  Philadelphia, Pennsylvania); Mohamad Y. Fares, MD, MSc (Rothman Institute, Thomas Jefferson University
                  Hospital, Philadelphia, Pennsylvania); Jacob M. Kirsch, MD (Department of Orthopaedic Surgery, New
                  England Baptist Hospital, Tufts University School of Medicine, Boston, Massachusetts); Jason E. Simon,
                  MD, MBA (Department of Orthopaedic Surgery, Massachusetts General Hospital/Newton-Wellesley Hospital,
                  Boston, Massachusetts); Jarret Woodmass, MD, FRCSC (Pan Am Clinic, Winnipeg, Manitoba, Canada); Porter
                  Jones, MD, MBA (Avant-Garde Health, Boston, Massachusetts); Derek A. Haas, MBA (Avant-Garde Health,
                  Boston, Massachusetts); April D. Armstrong, MD (Department of Orthopaedics and Rehabilitation, Bone
                  and Joint Institute, Penn State Milton S. Hershey Medical Center, Hershey, Pennsylvania); Uma
                  Srikumaran, MD, MBA, MPH (Department of Orthopaedic Surgery, Johns Hopkins Hospital, Johns Hopkins
                  University School of Medicine, Baltimore, Maryland); Eric R. Wagner, MD (Department of Orthopaedic
                  Surgery, Emory University, Atlanta, Georgia); Michael B. Gottschalk, MD (Department of Orthopaedic
                  Surgery, Emory University, Atlanta, Georgia); Adam Z. Khan, MD (Department of Orthopedics, Northwest
                  Permanente PC, Portland, Oregon); John G. Costouros, MD (Institute for Joint Restoration and Research,
                  California Shoulder Center, Menlo Park, California); Jon J.P. Warner, MD (Department of Orthopaedic
                  Surgery, Harvard Medical School, Massachusetts General Hospital, Boston, Massachusetts); and Evan A.
                  O’Donnell, MD (Department of Orthopaedic Surgery, Harvard Medical School, Massachusetts General
                  Hospital, Boston, Massachusetts).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Young BL</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Bitzer A</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Odum S</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Hamid N</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Shiffern S</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Connor PM</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Healthcare costs of failed
                  rotator cuff repairs</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">JSES Rev Rep Tech.</span> 2023 Apr 25;3(3):318-23.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Healthcare%20costs%20of%20failed%20rotator%20cuff%20repairs&as_occt=title&as_sauthors=%20%22BL%20Young%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Colvin AC</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Egorova N</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Harrison AK</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Moskowitz A</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Flatow EL</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">National trends in rotator cuff
                  repair</span>. <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">J Bone
                  Joint Surg Am.</span> 2012 Feb 1;94(3):227-33.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1133495" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Bernstein DN</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Wright CL</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Lu A</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Kim C</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Warner JJP</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> O’Donnell EA</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Surgeon idiosyncrasy is a key
                  driver of cost in arthroscopic rotator cuff repair: a time-driven activity-based costing
                  analysis</span>. <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2023 Dec;32(12):e616-23.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgeon%20idiosyncrasy%20is%20a%20key%20driver%20of%20cost%20in%20arthroscopic%20rotator%20cuff%20repair%3A%20a%20time-driven%20activity-based%20costing%20analysis&as_occt=title&as_sauthors=%20%22DN%20Bernstein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Nixon RA</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Dang KH</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Haberli JE</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> O’Donnell EA</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Surgical time and outcomes of
                  stemmed versus stemless total shoulder arthroplasty</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2022
                Jun;31(6S):S83-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20time%20and%20outcomes%20of%20stemmed%20versus%20stemless%20total%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22RA%20Nixon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> O’Donnell EA</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Haberli JE</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Martinez AM</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Yagoda D</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Kaplan RS</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Warner JJP</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Telehealth visits after shoulder
                  surgery: higher patient satisfaction and lower costs</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg Glob Res Rev.</span> 2022
                Jul 6;6(7):e22.00119.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Telehealth%20visits%20after%20shoulder%20surgery%3A%20higher%20patient%20satisfaction%20and%20lower%20costs&as_occt=title&as_sauthors=%20%22EA%20O%E2%80%99Donnell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Mandigo M</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> O’Neill K</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Mistry B</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Mundy B</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Millien C</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Nazaire Y</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Damuse R</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Pierre C</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Mugunga JC</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Gillies R</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Lucien F</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Bertrand K</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Luo E</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Costas A</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Greenberg SL</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Meara JG</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Kaplan R</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">A time-driven activity-based
                  costing model to improve health-care resource use in Mirebalais, Haiti</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Lancet.</span> 2015 Apr
                27;385(Suppl 2):S22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20time-driven%20activity-based%20costing%20model%20to%20improve%20health-care%20resource%20use%20in%20Mirebalais%2C%20Haiti&as_occt=title&as_sauthors=%20%22M%20Mandigo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Narvy SJ</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Ahluwalia A</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Vangsness CTJ Jr</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Analysis of direct costs of
                  outpatient arthroscopic rotator cuff repair</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Am J Orthop (Belle Mead NJ).</span> 2016
                Jan;45(1):E7-11.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Analysis%20of%20direct%20costs%20of%20outpatient%20arthroscopic%20rotator%20cuff%20repair&as_occt=title&as_sauthors=%20%22SJ%20Narvy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Koolmees DS</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Ramkumar PN</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Solsrud K</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Yedulla NR</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Elhage KG</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Cross AG</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Makhni EC</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Time-driven activity-based
                  costing accurately determines bundle cost for rotator cuff repair</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2022
                Aug;38(8):2370-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Time-driven%20activity-based%20costing%20accurately%20determines%20bundle%20cost%20for%20rotator%20cuff%20repair&as_occt=title&as_sauthors=%20%22DS%20Koolmees%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Wise KL</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Parikh HR</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Okelana B</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Only AJ</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Reams M</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Harrison A</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Braman J</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Craig E</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Cunningham BP</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Measurement of value in rotator
                  cuff repair: patient-level value analysis for the 1-year episode of care</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2022 Jan;31(1):72-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Measurement%20of%20value%20in%20rotator%20cuff%20repair%3A%20patient-level%20value%20analysis%20for%20the%201-year%20episode%20of%20care&as_occt=title&as_sauthors=%20%22KL%20Wise%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Etges APBDS</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Ruschel KB</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Polanczyk CA</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Urman RD</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Advances in value-based
                  healthcare by the application of time-driven activity-based costing for inpatient management: a
                  systematic review</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Value Health.</span> 2020 Jun;23(6):812-23.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Advances%20in%20value-based%20healthcare%20by%20the%20application%20of%20time-driven%20activity-based%20costing%20for%20inpatient%20management%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22APBDS%20Etges%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Tashjian RZ</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Belisle J</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Baran S</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Granger EK</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Nelson RE</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Burks RT</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Greis PE</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Factors influencing direct
                  clinical costs of outpatient arthroscopic rotator cuff repair surgery</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2018 Feb;27(2):237-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20influencing%20direct%20clinical%20costs%20of%20outpatient%20arthroscopic%20rotator%20cuff%20repair%20surgery&as_occt=title&as_sauthors=%20%22RZ%20Tashjian%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Morris JH</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Malik AT</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Hatef S</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Neviaser AS</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Bishop JY</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Cvetanovich GL</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Cost of arthroscopic rotator
                  cuff repairs is primarily driven by procedure-level factors: a single-institution analysis of an
                  ambulatory surgery center</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2021 Apr;37(4):1075-83.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cost%20of%20arthroscopic%20rotator%20cuff%20repairs%20is%20primarily%20driven%20by%20procedure-level%20factors%3A%20a%20single-institution%20analysis%20of%20an%20ambulatory%20surgery%20center&as_occt=title&as_sauthors=%20%22JH%20Morris%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Carducci MP</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Gasbarro G</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Menendez ME</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Mahendraraj KA</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Mattingly DA</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Talmo C</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Jawa A</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Variation in the cost of care
                  for different types of joint arthroplasty</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2020 Mar
                4;102(5):404-9.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2232185"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Haas DA</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Kaplan RS</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Variation in the cost of care
                  for primary total knee arthroplasties</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">Arthroplast Today.</span> 2016 Sep 30;3(1):33-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Variation%20in%20the%20cost%20of%20care%20for%20primary%20total%20knee%20arthroplasties&as_occt=title&as_sauthors=%20%22DA%20Haas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Allen AE</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Sakheim ME</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Mahendraraj KA</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Nemec SM</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Nho SJ</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Mather RC 3rd</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Wuerz TH</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Time-driven activity-based
                  costing analysis identifies use of consumables and operating room time as factors associated with
                  increased cost of outpatient primary hip arthroscopic labral repair</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2024
                May;40(5):1517-26.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Time-driven%20activity-based%20costing%20analysis%20identifies%20use%20of%20consumables%20and%20operating%20room%20time%20as%20factors%20associated%20with%20increased%20cost%20of%20outpatient%20primary%20hip%20arthroscopic%20labral%20repair&as_occt=title&as_sauthors=%20%22AE%20Allen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Menendez ME</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Lawler SM</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Shaker J</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Bassoff NW</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Warner JJP</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Jawa A</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Time-driven activity-based
                  costing to identify patients incurring high inpatient cost for total shoulder arthroplasty</span>.
                <span data-id="emphasis_16" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2018 Dec 5;100(23):2050-6.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1877307" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Carducci MP</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Mahendraraj KA</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Menendez ME</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Rosen I</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Klein SM</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Namdari S</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Ramsey ML</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Jawa A</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Identifying surgeon and
                  institutional drivers of cost in total shoulder arthroplasty: a multicenter study</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2021 Jan;30(1):113-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Identifying%20surgeon%20and%20institutional%20drivers%20of%20cost%20in%20total%20shoulder%20arthroplasty%3A%20a%20multicenter%20study&as_occt=title&as_sauthors=%20%22MP%20Carducci%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="infographic_reference" class="annotation infographic_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node infographic" data-id="infographic" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Infographic</div><a class="link jbjs_tracking" jbjs_tracking_type="download"
                jbjs_tracking_data="{&quot;id&quot;:&quot;5073f84c-a119-4f63-bd0d-9b74b4fe0037&quot;,&quot;type&quot;:&quot;infographic&quot;,&quot;topics&quot;:[]}"
                href="/php/content/content_api.php?op=download&rsuite_id=5073f84c-a119-4f63-bd0d-9b74b4fe0037&type=infographic"
                jbjs_tracking_source="reader" style="position: unset;">Download</a>
              <div class="infographic-wrapper" style="position: unset;">
                <div class="viewer" style="position: unset;">
                  <div class="progress-bar" style="position: unset; width: 100%;"></div>
                  <div class="pages" style="position: unset;"><canvas class="page" width="337" height="189"></canvas>
                  </div>
                  <div class="toolbar" style="position: unset;"><button id="zoom-out" class=""
                      style="position: unset;">-</button><button id="zoom-in" class=""
                      style="position: unset;">+</button><button id="full-screen" class="" style="position: unset;">Full
                      screen ON</button></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;5073f84c-a119-4f63-bd0d-9b74b4fe0037&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=5073f84c-a119-4f63-bd0d-9b74b4fe0037&type=supplement&name=12526"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D5073f84c-a119-4f63-bd0d-9b74b4fe0037%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 1, 2025;
                      107 (1): 9</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01351</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 20, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;5073f84c-a119-4f63-bd0d-9b74b4fe0037&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=5073f84c-a119-4f63-bd0d-9b74b4fe0037&type=pdf&name=JBJS.23.01351.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=5073f84c-a119-4f63-bd0d-9b74b4fe0037&type=pdf&name=JBJS.23.01351.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_23"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_24" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I288"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I288</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;5073f84c-a119-4f63-bd0d-9b74b4fe0037&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=5073f84c-a119-4f63-bd0d-9b74b4fe0037&type=zip&name=JBJS.23.01351.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Catherine J. Fedorka, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:fedorka-catherine@cooperhealth.edu" class=""
                    style="position: unset;">fedorka-catherine@cooperhealth.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8566-912X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8566-912X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ana Paula Beck da Silva Etges, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6411-3480" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6411-3480</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Matthew J. Best, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4401-2834" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4401-2834</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Harry H. Liu, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0006-2637-4336" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0006-2637-4336</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Xiaoran Zhang, MA<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4180-6567" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4180-6567</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Brett Sanders, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Joseph A. Abboud, MD<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3845-7220" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3845-7220</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mohamad Y. Fares, MD, MSc<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8228-3953" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8228-3953</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jacob M. Kirsch, MD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1368-3512" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1368-3512</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jason E. Simon, MD, MBA<span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1229-9586" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1229-9586</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jarret Woodmass, MD, FRCSC<span
                  data-id="affiliation_reference_8" class="label annotation cross_reference">8</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7303-4365" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7303-4365</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Porter Jones, MD, MBA<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0004-5618-5750" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0004-5618-5750</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Derek A. Haas, MBA<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5395-7306" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5395-7306</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">April D. Armstrong, MD<span
                  data-id="affiliation_reference_9" class="label annotation cross_reference">9</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3729-9811" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3729-9811</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Uma Srikumaran, MD, MBA<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2314-8855" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2314-8855</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Eric R. Wagner, MD<span
                  data-id="affiliation_reference_10" class="label annotation cross_reference">10</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Michael B. Gottschalk, MD<span
                  data-id="affiliation_reference_10" class="label annotation cross_reference">10</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0487-201X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0487-201X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Adam Z. Khan, MD<span
                  data-id="affiliation_reference_11" class="label annotation cross_reference">11</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7251-6503" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7251-6503</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">John G. Costouros, MD<span
                  data-id="affiliation_reference_12" class="label annotation cross_reference">12</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4939-6192" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4939-6192</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jon J.P. Warner, MD<span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2563-6318" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2563-6318</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Evan A. O’Donnell, MD<span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7137-7655" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7137-7655</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">the Avant-Garde Health and Codman Shoulder Society
                Value-Based Care Group<span data-id="footnote_reference_1"
                  class="label annotation cross_reference">*</span></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Cooper University Hospital, Cooper Medical
                School of Rowan University, Camden, New Jersey</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Avant-garde Health, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Johns Hopkins Hospital, Johns Hopkins
                University School of Medicine, Baltimore, Maryland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Center for Sports Medicine and Orthopaedics, Chattanooga, Tennessee</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Rothman Institute, Thomas Jefferson University Hospital, Philadelphia,
                Pennsylvania</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, New England Baptist Hospital, Tufts
                University School of Medicine, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Harvard Medical School, Boston Shoulder
                Institute, Massachusetts General Hospital, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">8</span><span class="text"
                style="position: unset;">Pan Am Clinic, Winnipeg, MB, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">9</span><span class="text"
                style="position: unset;">Department of Orthopaedics and Rehabilitation, Bone and Joint Institute, Penn
                State Milton S. Hershey Medical Center, Hershey, Pennsylvania</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">10</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Emory University, Atlanta, Georgia</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">11</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Southern California Permanente Medical
                Group, Panorama City, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">12</span><span class="text"
                style="position: unset;">Institute for Joint Restoration and Research, California Shoulder Center, Menlo
                Park, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">A list of the Avant-Garde Health and Codman Shoulder
                        Society Value-Based Care Group members is provided in a Note at the end of the article.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 26283.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
