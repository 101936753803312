import type { Article } from "../../../../../types";

export const Article620: Article = {
	id: 620,
	rsuiteId: "2d1b5eb1-1eba-4644-8fb7-ef995f758498",
	type: "commentary and perspective",
	title: "Dual-Mobility Articulations in Revision Hip Arthroplasty",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/620.jpeg",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Dual-Mobility Articulations in Revision Hip Arthroplasty
              </div>
              <div class="text subtitle" style="position: unset;">Commentary on an article by Monti Khatod, MD, et al.:
                “Dual-Mobility Articulations in Revision Total Hip Arthroplasty. A Comparison with Metal or Ceramic on
                Highly Cross-Linked Polyethylene and Constrained Articulations”</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Paul J. King,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">Hip arthroplasty volume continues to increase
                  substantially, and accordingly there continues to be a predictable concomitant increase in revision
                  total hip arthroplasty (rTHA) volume<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  Patient outcomes after rTHA are reported to be inferior to those after primary THA<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>. Hip instability is the indication for as many as 22.5% of
                  rTHAs, and instability is also a leading cause of re-revision after rTHA<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>.
                  Instability is a common and difficult problem for both patients and surgeons. Dual-mobility
                  articulations (DMs) have emerged as a technique to presumably decrease the risk of instability in
                  primary THA and rTHA by allowing for a larger head size, increased jump distance, and greater range of
                  motion before impingement.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">Khatod et al. present a timely study on a series of 3,725
                  rTHAs performed by 345 surgeons at 64 hospitals in an integrated health system. In an adjusted
                  analysis, they show that a DM reduced the risk of dislocation in rTHA compared with a cross-linked
                  polyethylene liner (XLPE), regardless of the head diameter of the latter, but was not significantly
                  better than a constrained liner. In an adjusted analysis of their primary outcome of interest, DMs
                  importantly also reduced the risk of re-revision for aseptic loosening compared with XLPE and
                  constrained liners.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">DMs were used more commonly later in the series, for
                  obvious reasons, and coinciding temporal advances in prosthetic materials, surgical techniques, and
                  rehabilitation protocols are likely also responsible for improving the rTHA outcomes. However, the
                  authors controlled for this with a subgroup analysis of 5-year time frames showing that in the 1,425
                  hip revisions performed between 2018 and 2022, the DM hips likewise had a lower rate of re-revision
                  for aseptic loosening compared with constrained liners or XLPE, and a lower risk of instability
                  compared with XLPE regardless of head diameter, but not compared with constrained liners.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">This manuscript provides longitudinal data supporting the
                  emerging role of DMs in rTHA to achieve not only lower dislocation risk but also the lowest risk of
                  subsequent aseptic re-revision. Each revision has unique circumstances for the surgeon to consider,
                  including the indication for revision, patient-specific factors, and the status of and modular options
                  available for the existing implants. The surgeon must rely on the literature and the unique attributes
                  of each case to attempt to obtain the best possible outcome for the patient in these often-complex
                  scenarios, and this study provides valuable information for this process.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">Although this study is potentially limited in that it
                  evaluated a variety of primary and revision implants, including multiple designs of DM constructs,
                  this is reflective of the clinical realities that surgeons face when performing rTHA. The use of
                  constrained liners may have been more common in cases with very severe soft-tissue loss, abductor
                  insufficiency, or high-risk patient comorbidities, which could not be evaluated in this study, and
                  perhaps DM constructs would not perform as well in some of these difficult circumstances. Given the
                  potential high forces generated and reduced range of motion associated with constrained liners, and
                  the known risks, including the possibility of liner dissociation, their use to reduce dislocations
                  comes with an understood compromise<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Although DM articulations can have unique complications,
                  such as intraprosthetic dislocation, and DMs have a variety of similar but distinct prosthetic designs
                  and materials that may ultimately have different long-term outcomes, this well-done study provides
                  evidence of the value of considering the use of DMs where possible when performing rTHA—to reduce the
                  risk of both instability and, importantly, the risk of re-revision for aseptic reasons.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Kurtz S</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Ong K</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Lau E</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Mowat F</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Halpern M</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Projections of primary and
                  revision hip and knee arthroplasty in the United States from 2005 to 2030</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2007 Apr;89(4):780-1-2.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1007448"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Khatod M</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Cafri G</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Inacio MC</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Schepps AL</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Paxton EW</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Bini SA</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Revision total hip arthoplasty:
                  factors associated with re-revision surgery</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2015 Mar
                4;97(5):359-1-2.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1206087"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Bozic KJ</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Kurtz SM</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Lau E</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Ong K</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Vail TP</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Berry DJ</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">The epidemiology of revision
                  total hip arthroplasty in the United States</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2009
                Jan;91(1):128-1-2.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1033620"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Brown TS</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Tibbo ME</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Arsoy D</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Lewallen DG</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Hanssen AD</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Trousdale RT</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Abdel MP</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Long-Term Outcomes of
                  Constrained Liners Cemented into Retained, Well-Fixed Acetabular Components</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2019 Apr 3;101(7):620-1-2.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1996414" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D2d1b5eb1-1eba-4644-8fb7-ef995f758498%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Commentary and Perspective</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): e51</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00816</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">December 18, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=e9b8ef32-0c5a-4261-b4be-95407996fecf&native=1"
                              class="article-title" style="position: unset;">Dual-Mobility Articulations in Revision
                              Total Hip Arthroplasty<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">A Comparison with Metal or Ceramic on Highly Cross-Linked
                                Polyethylene and Constrained Articulations</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Khatod,
                              Monti; Chan, Priscilla H.; Prentice, Heather A.; Fasig, Brian H.; Paxton, Elizabeth W.;
                              Reddy, Nithin C.; Kelly, Matthew P.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(24):2313 | October 17, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;2d1b5eb1-1eba-4644-8fb7-ef995f758498&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=2d1b5eb1-1eba-4644-8fb7-ef995f758498&type=pdf&name=JBJS.24.00816.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=2d1b5eb1-1eba-4644-8fb7-ef995f758498&type=pdf&name=JBJS.24.00816.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_5" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_6"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I241" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I241</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;2d1b5eb1-1eba-4644-8fb7-ef995f758498&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=2d1b5eb1-1eba-4644-8fb7-ef995f758498&type=zip&name=JBJS.24.00816.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Paul J. King, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:pking@aahs.org" class="" style="position: unset;">pking@aahs.org</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4647-0078" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4647-0078</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Luminis Health Anne Arundel Medical Center, Annapolis, Maryland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 5065.5px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
