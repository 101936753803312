/**
 * @generated SignedSource<<48a66713e034778c76e2a07027ba7e20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Permission = "DELETE" | "EDIT" | "PARTIAL_VIEW" | "SHARE_TO_EDIT" | "SHARE_TO_PARTIAL_VIEW" | "SHARE_TO_VIEW" | "VIEW" | "%future added value";
export type FetchBundlesQuery$variables = {
  tenantId?: string | null;
};
export type FetchBundlesQuery$data = {
  readonly bundleList: ReadonlyArray<{
    readonly bundle: {
      readonly categories: ReadonlyArray<string | null> | null;
      readonly contents: {
        readonly folder: {
          readonly contents: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly content: {
                  readonly contents?: {
                    readonly edges: ReadonlyArray<{
                      readonly node: {
                        readonly content: {
                          readonly id: string;
                        };
                      } | null;
                    } | null> | null;
                  } | null;
                  readonly id: string;
                };
              } | null;
            } | null> | null;
          } | null;
          readonly contentsCount: {
            readonly numFolders: number;
            readonly numQuestionSets: number;
            readonly numStudySets: number;
          };
          readonly contentsItemsCount: {
            readonly numFacts: number;
            readonly numQuestions: number;
          };
        };
      };
      readonly contentsItemsCount: {
        readonly numFacts: number;
        readonly numQuestions: number;
      };
      readonly description: string | null;
      readonly features: ReadonlyArray<string | null> | null;
      readonly icon: {
        readonly url: any;
      } | null;
      readonly id: string;
      readonly isActive: boolean;
      readonly isMobileOnly: boolean | null;
      readonly name: string;
      readonly numTrialDays: number;
      readonly numericId: number;
      readonly plans: ReadonlyArray<{
        readonly amount: number;
        readonly currency: string;
        readonly duration: number;
        readonly id: string;
        readonly interval: string;
        readonly name: string;
      } | null> | null;
      readonly tagline: string;
    };
    readonly metadata: {
      readonly permissions: ReadonlyArray<Permission | null> | null;
      readonly subscription: {
        readonly endDate: any | null;
        readonly status: string;
      };
      readonly trial: {
        readonly endDate: any | null;
        readonly isOpted: boolean;
        readonly status: string;
      };
    };
  }>;
};
export type FetchBundlesQuery = {
  response: FetchBundlesQuery$data;
  variables: FetchBundlesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "tenantId",
    "variableName": "tenantId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "BundleMetaData",
  "kind": "LinkedField",
  "name": "metadata",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BundleSubscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BundleTrial",
      "kind": "LinkedField",
      "name": "trial",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOpted",
          "storageKey": null
        },
        (v3/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numTrialDays",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numericId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tagline",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMobileOnly",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categories",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "features",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numQuestions",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numFacts",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "BundleContentsItemsCount",
  "kind": "LinkedField",
  "name": "contentsItemsCount",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    (v16/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Plan",
  "kind": "LinkedField",
  "name": "plans",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "FolderContentsItemsCount",
  "kind": "LinkedField",
  "name": "contentsItemsCount",
  "plural": false,
  "selections": [
    (v16/*: any*/),
    (v15/*: any*/)
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "FolderContentsCount",
  "kind": "LinkedField",
  "name": "contentsCount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numFolders",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numStudySets",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numQuestionSets",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchBundlesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BundleDetails",
        "kind": "LinkedField",
        "name": "bundleList",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Bundle",
            "kind": "LinkedField",
            "name": "bundle",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FolderDetails",
                "kind": "LinkedField",
                "name": "contents",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Folder",
                    "kind": "LinkedField",
                    "name": "folder",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/),
                      (v20/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentDetailsConnection",
                        "kind": "LinkedField",
                        "name": "contents",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentDetailsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ContentDetails",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "content",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ContentDetailsConnection",
                                            "kind": "LinkedField",
                                            "name": "contents",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ContentDetailsEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ContentDetails",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": null,
                                                        "kind": "LinkedField",
                                                        "name": "content",
                                                        "plural": false,
                                                        "selections": [
                                                          (v5/*: any*/)
                                                        ],
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "Folder",
                                        "abstractKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "icon",
                "plural": false,
                "selections": [
                  (v21/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FetchBundlesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BundleDetails",
        "kind": "LinkedField",
        "name": "bundleList",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Bundle",
            "kind": "LinkedField",
            "name": "bundle",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FolderDetails",
                "kind": "LinkedField",
                "name": "contents",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Folder",
                    "kind": "LinkedField",
                    "name": "folder",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/),
                      (v20/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentDetailsConnection",
                        "kind": "LinkedField",
                        "name": "contents",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentDetailsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ContentDetails",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "content",
                                    "plural": false,
                                    "selections": [
                                      (v22/*: any*/),
                                      (v5/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ContentDetailsConnection",
                                            "kind": "LinkedField",
                                            "name": "contents",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ContentDetailsEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ContentDetails",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": null,
                                                        "kind": "LinkedField",
                                                        "name": "content",
                                                        "plural": false,
                                                        "selections": [
                                                          (v22/*: any*/),
                                                          (v5/*: any*/)
                                                        ],
                                                        "storageKey": null
                                                      },
                                                      (v5/*: any*/)
                                                    ],
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "Folder",
                                        "abstractKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "icon",
                "plural": false,
                "selections": [
                  (v21/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bea630e394fc19dcfd6a49e9ac1e12b9",
    "id": null,
    "metadata": {},
    "name": "FetchBundlesQuery",
    "operationKind": "query",
    "text": "query FetchBundlesQuery(\n  $tenantId: ID\n) {\n  bundleList(tenantId: $tenantId) {\n    metadata {\n      permissions\n      subscription {\n        status\n        endDate\n      }\n      trial {\n        isOpted\n        endDate\n        status\n      }\n    }\n    bundle {\n      id\n      name\n      numTrialDays\n      isActive\n      numericId\n      description\n      tagline\n      isMobileOnly\n      categories\n      features\n      contentsItemsCount {\n        numQuestions\n        numFacts\n      }\n      plans {\n        id\n        name\n        amount\n        currency\n        interval\n        duration\n      }\n      contents {\n        folder {\n          contentsItemsCount {\n            numFacts\n            numQuestions\n          }\n          contentsCount {\n            numFolders\n            numStudySets\n            numQuestionSets\n          }\n          contents {\n            edges {\n              node {\n                content {\n                  __typename\n                  id\n                  ... on Folder {\n                    contents {\n                      edges {\n                        node {\n                          content {\n                            __typename\n                            id\n                          }\n                          id\n                        }\n                      }\n                    }\n                  }\n                }\n                id\n              }\n            }\n          }\n          id\n        }\n        id\n      }\n      icon {\n        url\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "24d406e8787f2ff3358dbbbb29654303";

export default node;
