import type { Article } from "../../../../../types";

export const Article568: Article = {
	id: 568,
	rsuiteId: "757bf1ae-7e46-455e-9ce7-a1eadbbd6a34",
	type: "scientific article",
	title:
		"Association of Technology Usage and Decreased Revision TKA Rates for Low-Volume Surgeons Using an Optimal Prosthesis Combination",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=757bf1ae-7e46-455e-9ce7-a1eadbbd6a34&type=jpeg&name=JBJS.24.00539f1",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Association of Technology Usage and Decreased Revision
                TKA Rates for Low-Volume Surgeons Using an Optimal Prosthesis Combination</div>
              <div class="text subtitle" style="position: unset;">An Analysis of 53,264 Primary TKAs</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Michael
                      McAuliffe, MBBS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ibrahim
                      Darwish, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_38" class="annotation strong"
                          style="position: unset;">Background:</span> Technology (navigation and robotics) usage during
                        total knee arthroplasty (TKA) is often supported by literature involving high-volume surgeons
                        and hospitals, but the value of technology for lower-volume surgeons is uncertain. This study
                        aimed to determine if there was a relationship among surgeon volume, technology usage, and
                        revision rate when using an optimal prosthesis combination (OPC).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_39" class="annotation strong"
                          style="position: unset;">Methods:</span> Australian Orthopaedic Association National Joint
                        Replacement Registry (AOANJRR) data were obtained from January 1, 2008, to December 31, 2022,
                        for all primary TKA procedures performed for osteoarthritis using an OPC by a known surgeon ≥5
                        years after their first recorded procedure. The interaction between surgeon volume and
                        conventional-instrumentation (CV) versus technology-assisted (TA) TKA was assessed. The
                        cumulative percent revision (CPR) was determined by Kaplan-Meier estimates. Cox
                        proportional-hazards methods were used to compare rates of revision by surgeon volume and by the
                        interaction of volume and technology. Subanalyses were undertaken to examine major and minor
                        revisions separately, and to assess the influence of technology on revision rates relative to
                        those of a surgeon undertaking 100 TKA/year.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_40" class="annotation strong"
                          style="position: unset;">Results:</span> Of the 53,264 procedures that met the inclusion
                        criteria, 31,536 were TA-TKA and 21,728 were CV-TKA. Use of technology reduced the all-cause
                        revision rate for surgeons with a volume of &lt;50 TKA/year and the rate of minor revisions for
                        surgeons with a volume of &lt;40 TKA/year. No interaction between surgeon volume and the rate of
                        major revision surgery was found. With CV-TKA by a surgeon with a 100-TKA/year volume as the
                        comparator, all-cause and major revision rates were significantly elevated for surgeons
                        undertaking &lt;50 and &lt;100 TKA/year, respectively. In contrast, analysis of TA-TKA showed no
                        difference in rates of all-cause or major revisions for surgeons undertaking &lt;100 TKA/year
                        compared with 100 TKA/year.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_41" class="annotation strong"
                          style="position: unset;">Conclusions:</span> TA-TKA was associated with a decrease in the
                        revision rate for lower-volume surgeons but no significant alterations in revision rate for
                        higher-volume surgeons. Preferential use of TA-TKA by lower-volume surgeons should be
                        considered.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_42" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Technology-assisted total knee arthroplasty (TA-TKA)
                  includes the use of computer navigation (CN) and robotic-assisted (RA) platforms. There is a global
                  trend of increasing use of such adjunctive TKA technologies<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  Technology utilization is rising in the U.S.<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">4</span></a>, although conventional-instrumentation (CV)-TKA still
                  predominates<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">2</span></a>.
                  TA-TKA has also increased consistently in Germany<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">5</span></a>. The
                  Australian Orthopaedic Association National Joint Replacement Registry (AOANJRR) recently reported
                  that 65.8% of TKAs in Australia utilized adjunctive technology<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">6</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">This trend is largely premised on increased implant
                  positioning accuracy<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">10</span></a>;
                  however, it has increased TKA delivery costs<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">11</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">13</span></a>. A substantial body of published clinical evidence suggests
                  that there is no significant improvement in patient outcomes when comparing TA-TKA with CV-TKA<a
                    href="" data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">10</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">18</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">The American Academy of Orthopaedic Surgeons (AAOS)
                  clinical guidelines recommend with a “strong” level that there is no evidence of improved outcomes,
                  function, or pain, and no difference in complications, after CV-TKA compared with CN-TKA<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">19</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Many factors affect patient outcomes after TKA, including
                  surgeon volume<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">21</span></a>. TA-TKA performed in higher-volume surgical centers may
                  have improved cost-effectiveness compared with CV-TKA<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">12</span></a>. However, there is limited evidence regarding the effect of
                  TA-TKA on the clinical outcomes of TKAs performed by lower-volume surgeons. It is possible that this
                  technology is more effective at reducing the revision rate after procedures performed by
                  less-experienced, lower-volume surgeons.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">We hypothesized that TA-TKA (RA-TKA or CN-TKA) usage by
                  lower-volume surgeons would have a beneficial effect on implant survivorship.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The AOANJRR is approved by the Commonwealth of Australia
                  as a Federal Quality Assurance Activity (F2022L00986) under Part VC of the Health Insurance Act 1973
                  (HIA) and Part 10 of the Health Insurance Regulations 2018. All AOANJRR studies are conducted in
                  accordance with ethical principles of research (the Helsinki Declaration II).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The AOANJRR has well-documented data collection and
                  validation procedures that ensure high-quality data encompassing almost all arthroplasties performed
                  in Australia since mid-2002<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">6</span></a>.
                  Revisions involving the insertion, removal, and/or replacement of any components fixed to bone, except
                  for the patellar prosthesis, are defined as major. All other revisions are defined as minor<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_13" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">23</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Data were obtained from the AOANJRR for all primary TKAs
                  performed for osteoarthritis (OA) between January 1, 2008, and December 31, 2022. This period was
                  chosen because prior to 2008 provision of the operating surgeon’s code for a procedure was an opt-in
                  process, potentially underestimating procedure volumes for surgeons who were operating prior to 2008.
                  Furthermore, only surgeons whose first procedure was recorded since January 1, 2008, were included. Of
                  the 1,452 surgeons with TKA procedures recorded by the registry, 962 had their first procedure
                  recorded since January 1, 2008. TKA procedures in the first 5 years of a surgeon’s practice were also
                  removed from the study cohort to diminish the influence of learning curves and experience.
                  Additionally, only procedures that used an optimal prosthesis combination (OPC) were included in the
                  analysis, to limit confounding factors associated with the prosthetic construct. OPCs were defined as
                  those with a minimally stabilized or medial-pivot design, fixed mobility, a cross-linked polyethylene
                  (XLPE) insert, a cemented tibial component, and resurfacing of the patella. Only procedures in which
                  computer navigation, robotic assistance, or conventional instrumentation was used were included.
                  Procedures using image-derived instrumentation (IDI) were excluded, as they have a higher revision
                  rate in the AOANJRR. The TKA was classified as technology-assisted if either computer navigation or
                  robotic assistance was used. Of the 962 surgeons whose first procedure was performed since January 1,
                  2008, 677 had procedures eligible for inclusion in this analysis. After removing the procedures
                  performed in their first 5 years of practice, 457 surgeons contributed cases and 53,264 of the 886,536
                  primary TKA procedures performed since January 1, 2008, met the criteria for inclusion in the study
                  (<a href="" data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). These included 31,536 TA-TKAs and 21,728 CV-TKAs (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Since a given surgeon’s procedure volume could change over
                  time, each procedure was analyzed on the basis of the volume of the surgeon (the number of primary TKA
                  procedures for any diagnosis and using any prosthesis) in the year (365 days) before that particular
                  procedure was undertaken.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The primary outcome for this study was the time from
                  primary TKA to the first revision (major or minor) for any reason. Secondary outcomes were the time to
                  major revisions and minor revisions. For each outcome, Kaplan-Meier estimates of survivorship were
                  used to report the time to revision, with censoring at the time of death or closure of the data set at
                  the end of December 2022. The unadjusted cumulative percent revision (CPR) was calculated as the
                  complement, in probability, of the Kaplan-Meier estimates, and its 95% confidence interval (CI) was
                  calculated using unadjusted point-wise Greenwood estimates.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Rates of revision by technology usage and by surgeon
                  volume were compared using hazard ratios (HRs) estimated under Cox proportional-hazards models
                  adjusting for age and gender, as well as under a model including age, gender, technology usage (TA or
                  CV), and surgeon volume. For the latter model, age and surgeon volume were treated as continuous
                  variables, and potential nonlinear effects were modeled using restricted cubic splines. Additionally,
                  a model containing an interaction between technology usage and surgeon volume was examined for each
                  outcome. HRs comparing TA-TKA with CV-TKA as a function of surgeon volume and comparing higher and
                  lower surgeon volumes with a volume of 100 TKA/year for TA-TKA and CV-TKA procedures separately were
                  calculated to summarize the effect of each factor. The number of eligible procedures during the study
                  period determined the available sample size.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Further analysis adjusting for American Society of
                  Anesthesiologists Physical Status Classification (ASA) and body mass index (BMI) at the time of the
                  primary procedure was performed to assess potential confounding effects. The AOANJRR commenced
                  collection of ASA and BMI values in 2012 and 2015, respectively. For the purposes of this analysis,
                  missing ASA and BMI data were assumed to be missing completely at random, and the analysis was
                  restricted to procedures with complete data for these covariates.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The assumption of proportional hazards was checked
                  analytically for each model. If the interaction between the predictor and the log of time was
                  significant in the standard Cox model, then a time-varying model was developed. Time points were
                  selected on the basis of the greatest change in hazard, weighted by a function of events. Time points
                  were iteratively chosen until the assumption of proportionality was met, and HRs were then calculated
                  for each selected time period. In the current study, if no time period is specified, the stated HR was
                  calculated over the entire follow-up period. All tests were 2-tailed at the 5% level of significance.
                  Statistical analysis was performed using SAS (version 9.4; SAS Institute).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">When evaluating the all-cause revision rate and adjusting
                  for age and gender only, TA-TKA had a lower revision rate compared with CV-TKA procedures (HR = 0.86
                  [95% CI, 0.75 to 0.99], p = 0.031) (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>).
                  However, this reduction in revision rate with the use of TA-TKA was no longer significant when
                  analyzing the subset of 48,819 procedures with known ASA and BMI data (<a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). The revision rate also did not differ significantly between
                  TA-TKA and CV-TKA in this subset when adjusting for additional variables (age, gender, ASA, BMI,
                  surgeon volume) (HR = 0.89 [95% CI, 0.77 to 1.03], p = 0.128).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00539f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=757bf1ae-7e46-455e-9ce7-a1eadbbd6a34&type=jpeg&name=JBJS.24.00539f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Cumulative percent revision of primary TKA (for a
                          primary diagnosis of OA) with and without technology assistance. The shading represents the
                          95% CI.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00539f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=757bf1ae-7e46-455e-9ce7-a1eadbbd6a34&type=jpeg&name=JBJS.24.00539f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;">Cumulative percent revision of primary TKA (for a
                          primary diagnosis of OA) with and without technology assistance in patients with known ASA and
                          BMI. The shading represents the 95% CI.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">When the effect of technology usage was allowed to vary
                  with surgeon volume, through the inclusion of an interaction between these 2 factors, the relative
                  difference in revision rate between TA-TKA and CV-TKA was largest among procedures performed by
                  lower-volume surgeons (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>, <a
                    href="" data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>). Surgeons with a volume of &lt;50 TKA/year demonstrated a
                  lower revision rate with TA-TKA compared with CV-TKA. At higher surgeon volumes, there was no evidence
                  of a difference in the revision rate.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00539f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=757bf1ae-7e46-455e-9ce7-a1eadbbd6a34&type=jpeg&name=JBJS.24.00539f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;">Hazard ratio for all-cause revision of primary TKA
                          (for a primary diagnosis of OA) with, relative to without, technology assistance, graphed
                          according to surgeon volume.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 4</a> shows
                  all-cause revision as a function of surgeon volume, with a 100 TKA/year surgeon as the reference. For
                  TA-TKA, there was no significant difference between the revision rate of procedures performed by
                  surgeons with a volume of &lt;100 TKA/year and procedures performed by 100-TKA/year surgeons.
                  Conversely, when CV-TKA was utilized, there was a significantly higher rate of revision for surgeons
                  undertaking &lt;50 TKA/year compared with 100-TKA/year surgeons. The revision rate of TA-TKA was found
                  to steadily decrease, with variable significance, as surgeon volume increased above 100 TKA/year. For
                  CV-TKA procedures, however, there was no evidence of a difference in revision rate between surgeons
                  performing &gt;100 TKA/year and 100-TKA/year surgeons.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00539f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=757bf1ae-7e46-455e-9ce7-a1eadbbd6a34&type=jpeg&name=JBJS.24.00539f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;">Hazard ratio for all-cause revision of primary TKA
                          (for a primary diagnosis of OA), with and without technology assistance, versus a surgeon
                          volume of 100 TKA/year. The shading represents the 95% CI.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Taken together, the model allowing an interaction of
                  technology usage with surgeon volume and the comparison of lower with higher-volume surgeons suggest
                  that TA-TKA is associated with a decreased revision rate for lower-volume surgeons. However, it should
                  be noted that there was not strong evidence for this interaction, given the p value of 0.158 for the
                  total model, and the observed data were thus also compatible with the absence of an interaction
                  between technology assistance and surgeon volume.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Our analysis examining variation in the effect of TA-TKA
                  usage with surgeon volume demonstrated that TA-TKA decreased the rate of minor revision surgery for
                  surgeons performing &lt;40 TKA/year (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>). The
                  model also suggested a decrease in the minor revision rate with TA-TKA for some surgeons undertaking
                  &gt;100 TKA/year, but the estimated HR was relatively imprecise.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.24.00539f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=757bf1ae-7e46-455e-9ce7-a1eadbbd6a34&type=jpeg&name=JBJS.24.00539f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;">Hazard ratio for minor revision of primary TKA
                          (for a primary diagnosis of OA) with, relative to without, technology assistance, graphed
                          according to surgeon volume.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>
                  describes the HR for minor revision associated with specific surgeon volumes. As for all-cause
                  revision, there was no strong evidence to suggest an interaction between technology usage and surgeon
                  volume (p = 0.298).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 6</a>
                  demonstrates that, regardless of technology utilization, there was no significant difference in the
                  rate of minor revision surgery when higher and lower-volume surgeons were compared with a 100 TKA/year
                  surgeon.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.24.00539f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=757bf1ae-7e46-455e-9ce7-a1eadbbd6a34&type=jpeg&name=JBJS.24.00539f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;">Hazard ratio for minor revision of primary TKA
                          (for a primary diagnosis of OA), with and without technology assistance, versus a surgeon
                          volume of 100 TKA/year. The shading represents the 95% CI.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 7</a>
                  compares major revisions between TA-TKA and CI-TKA as a function of surgeon volume. No significant
                  effect of technology use on the major revision rate was found at any surgeon volume, as all CIs
                  contained an HR of 1.0.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 7</div>
                <div class="image-download" name="JBJS.24.00539f7" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=757bf1ae-7e46-455e-9ce7-a1eadbbd6a34&type=jpeg&name=JBJS.24.00539f7"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;">Hazard ratio for major revision of primary TKA
                          (for a primary diagnosis of OA) with, relative to without, technology assistance, graphed
                          according to surgeon volume.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 8</a> shows
                  the HRs of major revisions by surgeon volume, with a surgeon undertaking 100 TKA/year as the
                  reference. The findings are similar to those for all-cause revision. With TA-TKA, high-volume surgeons
                  tended to have a lower rate of major revision compared with surgeons with a volume of 100 TKA/year.
                  Without technology usage, a similar pattern was seen for some of the high-volume surgeons, although
                  with lower precision. With CV-TKA usage, lower-volume surgeons had a higher rate of major revision
                  than a 100-TKA/year surgeon. For TA-TKA, there was no significant difference in the rate of major
                  revision surgery for lower-volume surgeons compared with 100-TKA/year surgeons.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 8</div>
                <div class="image-download" name="JBJS.24.00539f8" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=757bf1ae-7e46-455e-9ce7-a1eadbbd6a34&type=jpeg&name=JBJS.24.00539f8"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;">Hazard ratio for major revision of primary TKA
                          (for a primary diagnosis of OA), with and without technology assistance, versus a surgeon
                          volume of 100 TKA/year. The shading represents the 95% CI.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">The key finding of this study is that TA-TKA was
                  associated with a lower revision rate than CV-TKA for lower-volume surgeons.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">TA-TKA with an OPC was associated with a lower rate of
                  revision in an unadjusted analysis of the whole study cohort. However, when results were adjusted for
                  age, gender, ASA, BMI, and surgeon volume in a large subset for whom such data were available, no
                  significant benefit of TA-TKA was demonstrated. This raises the question of whether the potential
                  benefits of technology assistance are more apparent in certain subgroups, rather than being spread
                  evenly across the cohort.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Our study results support our hypothesis that TA-TKA may
                  be most beneficial for lower-volume surgeons. We found a lower rate of all-cause revision with the use
                  of TA-TKA than CV-TKA for surgeons whose annual volume at the time of the TKA was &lt;50 TKA/year.
                  This was predominantly due to a lower rate of minor revisions for TA-TKA, compared with CV-TKA,
                  performed by surgeons with an annual case volume of &lt;40 TKA/year. No clear difference in the
                  revision rate was found between TA-TKAs and CV-TKAs performed by higher-volume surgeons. This suggests
                  that the maximum value of these technologies to the health-care system would result from its
                  preferential use by lower-volume surgeons. More commonly, it is high-volume surgeons at large teaching
                  centers who are heavily involved in technology usage, study, and promotion<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">25</span></a>. However, lower-volume surgeons are an important group
                  because they account for a considerable percentage of TKAs. A recent Canadian study investigating
                  volume-outcome relationships defined low-volume surgeons as those performing &lt;70 TKA/year, with
                  this cohort accounting for 33% of the caseload (56,265 TKAs) over a 15-year period<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">26</span></a>. Comparable studies have previously reported similar
                  findings<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">28</span></a>. The focused application of technology in this surgeon
                  group has the potential to produce considerable health-care system benefits. These findings should be
                  carefully considered by health-care administrators when considering technology usage.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">We utilized a comparator of 100 TKA/year to examine
                  whether TA-TKA allowed lower-volume surgeons to achieve results similar to those of their
                  higher-volume colleagues, and whether further benefit was gained with technology at higher volume
                  levels.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Surgeons performing &lt;50 CV-TKA/year had a significantly
                  higher rate of all-cause revision compared with the 100-CV-TKA/year surgeon, and those performing
                  &lt;100 CV-TKA/year had a significantly higher rate of major revision compared with the
                  100-CV-TKA/year surgeon. However, when performing TA-TKA, lower-volume surgeons had rates of all-cause
                  and major revision comparable with those of higher-volume surgeons, and the variation in revision
                  rates with volume decreased. Thus, technology made the results of lower-volume surgeons more
                  homogeneous and comparable with those of their higher-volume peers. These conclusions are consistent
                  with our hypothesis that lower-volume surgeons would attain value from utilizing TA-TKA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Furthermore, as surgeon volume increased above 100
                  TKA/year there was a reduction in all-cause and major revision with both CV-TKA and TA-TKA. The
                  significance of the difference in revision rate compared with the 100-TKA/year surgeon depended on the
                  TKA volume/year, and the significance was more consistent with TA-TKA than with CV-TKA. The
                  comparatively better revision rates at higher volumes are unsurprising, as surgeon volume has been
                  previously linked to improved outcomes<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">35</span></a>. TA-TKA also appeared to be associated with more
                  consistency in surgical outcomes for higher-volume surgeons.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">The more homogeneous revision outcomes across surgeon
                  volumes for TA-TKA compared with CV-TKA using the 100 TKA/year comparator would be consistent with the
                  fact that the technology platforms are based on standardized algorithms that should act to keep
                  surgical outcomes within a certain range.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">The potential benefits of TA-TKA may be due to multiple
                  associated factors. Although these factors may vary between platforms, they may include implant
                  alignment and sizing guidance, more precise resections, intraoperative feedback about resections,
                  detailed soft-tissue balance, and limb and local knee alignment information. As lower-volume surgeons
                  showed greater outcome variation than higher-volume surgeons with TA-TKA, they may be a good group in
                  which to undertake future studies aimed at determining which of the technology factors offer maximum
                  value in diminishing the revision rate. These factors could then be optimized in future technology
                  iterations.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">Our findings are consistent with prior literature in which
                  limited outcome differences are seen with TA-TKA compared with CV-TKA<a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_18" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">37</span></a>. Many of these studies are from high-volume surgeons and
                  centers, which is consistent with our finding that this surgeon group derives limited benefit from
                  TA-TKA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">Our results also suggest that the benefits of TA-TKA are
                  limited overall. TA-TKA carries substantial associated costs. If this technology is to be embraced
                  across all surgeon volumes, then it is important to demonstrate clear improvements in patient-reported
                  outcome measures and overall reductions in the revision rate that meet cost-effectiveness thresholds.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">Our study had several limitations. First, we combined CN
                  and RA in the TA-TKA cohort. Purely robotic technology may deliver consistent improvements in the
                  revision rate across wider surgeon volumes. However, the AOANJRR 2023 report notes no difference in
                  the early revision rate for RA-TKA compared with CV-TKA<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">6</span></a>.
                  Second, although our study has a large patient cohort, there was heterogeneity within the technology
                  systems and the OPCs which may have affected outcomes. However, we attempted to minimize the influence
                  of prosthetic variables by studying only OPCs, and the prosthetic variability was present in both the
                  CV-TKA and TA-TKA cohorts. Third, although the study cohorts were large, and are a pragmatic
                  representation of real-world practice, when considering the models in totality we were not able to
                  exclude the possibility that technology usage and surgeon volume do not interact. It thus also remains
                  plausible that any benefits associated with TA-TKA do not vary with surgeon volume. Fourth, hospital
                  volumes may represent unrecognized confounders between the groups. Fifth, ultimately registry-based
                  studies can only show association, not causation. It would be important to confirm these findings in
                  well-designed prospective trials that focus on lower-volume surgeons.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, we have demonstrated that TA-TKA shows the
                  potential to improve the revision rate after TKA surgery, particularly when utilized by surgeons whose
                  practice volumes are &lt;50 TKA/year.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_50" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_50" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Application of the Inclusion and Exclusion
                          Criteria<span data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 408px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Criterion</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No.
                          Included</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No.
                          Excluded</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Primary TKA</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">886,536</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Primary diagnosis
                          OA</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">867,113</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">19,423</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Used an OPC</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">154,383</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">712,730</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Performed by a known
                          surgeon</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">148,040</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6,343</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgeon performed first
                          procedure after 2007</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">83,785</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">64,255</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">IDI not used</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">76,366</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7,419</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Performed at least 5
                          years since the surgeon’s first procedure</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">53,264</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">23,102</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Each inclusion or exclusion criterion also applies to all subsequent
                        rows. OPC = optimal prosthesis combination, and IDI = image-derived instrumentation.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_51" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_51" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Characteristics of Primary TKAs (for a Primary
                          Diagnosis of OA) According to Technology Assistance<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 2238px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Variable</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Robotically Assisted or Computer-Navigated</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Not
                          Technology-Assisted</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Total</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Years since surgeon’s
                          first procedure</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mean ± SD</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.2 ±
                          2.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.3 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.2 ±
                          2.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Median (IQR)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.8 (6.9,
                          11.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9 (7,
                          11.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.9 (6.9,
                          11.4)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Minimum</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Maximum</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14.9</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgeon’s prior number of
                          cases</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mean ± SD</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">697.2 ±
                          482</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">657.6 ±
                          456.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">681 ±
                          472.1</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Median (IQR)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">565 (342,
                          924)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">542 (317,
                          894)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">557 (331,
                          910)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgeon volume</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mean ± SD</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98.2 ±
                          54.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">92 ±
                          44.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95.7 ±
                          50.8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Median (IQR)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87 (57,
                          131)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">91 (56,
                          119)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">89 (57,
                          125)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age group</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;55 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,845
                          (5.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,133
                          (5.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,978
                          (5.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 55-64 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8,314
                          (26.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,335
                          (24.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13,649
                          (25.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 65-74 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13,203
                          (41.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9,192
                          (42.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22,395
                          (42.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥75 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8,174
                          (25.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,068
                          (27.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14,242
                          (26.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12,666
                          (40.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9,094
                          (41.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21,760
                          (40.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18,870
                          (59.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12,634
                          (58.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31,504
                          (59.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ASA class<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ASA 1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,383
                          (4.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">984
                          (4.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,367
                          (4.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ASA 2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16,343
                          (52.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10,921
                          (50.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27,264
                          (51.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ASA 3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13,346
                          (42.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9,437
                          (43.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22,783
                          (43.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ASA 4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">349
                          (1.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">201
                          (0.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">550
                          (1.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ASA 5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI category in kg/m<span
                            data-id="superscript_20" class="annotation superscript"
                            style="position: unset;">2</span><span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Underweight
                          (&lt;18.50)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49
                          (0.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (0.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78
                          (0.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Normal
                          (18.50-24.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,028
                          (10.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,816
                          (9.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,844
                          (9.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pre-obese
                          (25.00-29.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8,856
                          (29.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,899
                          (30.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14,755
                          (30.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Obese Class 1
                          (30.00-34.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9,234
                          (31.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,895
                          (30.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15,129
                          (31.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Obese Class 2
                          (35.00-39.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,277
                          (17.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,447
                          (17.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8,724
                          (17.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Obese Class 3
                          (≥40.00)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,131
                          (10.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,195
                          (11.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,326
                          (10.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">31,536</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">21,728</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">53,264</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">SD = standard deviation, IQR = interquartile range, ASA = American
                        Society of Anesthesiologists, BMI = body mass index.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Excludes 298 procedures with unknown ASA class.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Excludes 4,408 procedures with unknown BMI category.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_52" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_52" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Hazard Ratios for All-Cause Revision with Versus
                          without Technology Assistance, According to Surgeon Volume</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 283px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Surgeon
                          Volume</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">HR (95%
                          CI)<span data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">50 cases</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.82
                          (0.66, 1.01)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.057</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">75 cases</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.98
                          (0.78, 1.23)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.854</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">100 cases</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.99
                          (0.81, 1.21)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.921</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">125 cases</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.93
                          (0.72, 1.21)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.609</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">150 cases</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.86
                          (0.66, 1.13)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.283</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">175 cases</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.79
                          (0.56, 1.12)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.189</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Revision of primary TKA for a primary diagnosis of OA. Adjusted for age
                        and gender.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_12" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_53" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_53" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Hazard Ratio for Minor Revision with Versus without
                          Technology Assistance, According to Surgeon Volume</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 283px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Surgeon Volume</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">HR (95%
                          CI)<span data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">50 cases</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.83
                          (0.62, 1.10)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.196</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">75 cases</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.99
                          (0.73, 1.36)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.971</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">100 cases</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.88
                          (0.68, 1.12)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.296</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">125 cases</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.76
                          (0.55, 1.04)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.089</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">150 cases</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.72
                          (0.52, 0.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.045</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">175 cases</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.72
                          (0.47, 1.10)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.128</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Revision of primary TKA for a primary diagnosis of OA. Adjusted for age
                        and gender.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Shah SM</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">After 25 years of
                  computer-navigated total knee arthroplasty, where do we stand today?</span><span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Arthroplasty.</span> 2021 Nov
                4;3(1):41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=After%2025%20years%20of%20computer-navigated%20total%20knee%20arthroplasty%2C%20where%20do%20we%20stand%20today%3F&as_occt=title&as_sauthors=%20%22SM%20Shah%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Bendich I</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Kapadia M</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Alpaugh K</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Diane A</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Vigdorchik J</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Westrich G</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Trends of Utilization and 90-Day
                  Complication Rates for Computer-Assisted Navigation and Robotic Assistance for Total Knee Arthroplasty
                  in the United States From 2010 to 2018</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Arthroplast Today.</span> 2021 Sep 9;11:134-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trends%20of%20Utilization%20and%2090-Day%20Complication%20Rates%20for%20Computer-Assisted%20Navigation%20and%20Robotic%20Assistance%20for%20Total%20Knee%20Arthroplasty%20in%20the%20United%20States%20From%202010%20to%202018&as_occt=title&as_sauthors=%20%22I%20Bendich%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Antonios JK</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Korber S</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Sivasundaram L</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Mayfield C</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Kang HP</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Oakes DA</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Heckmann ND</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Trends in computer navigation
                  and robotic assistance for total knee arthroplasty in the United States: an analysis of patient and
                  hospital factors</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Arthroplast Today.</span> 2019 Mar 12;5(1):88-95.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trends%20in%20computer%20navigation%20and%20robotic%20assistance%20for%20total%20knee%20arthroplasty%20in%20the%20United%20States%3A%20an%20analysis%20of%20patient%20and%20hospital%20factors&as_occt=title&as_sauthors=%20%22JK%20Antonios%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Wang JC</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Piple AS</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Hill WJ</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Chen MS</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Gettleman BS</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Richardson M</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Heckmann ND</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Christ AB</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Computer-Navigated and
                  Robotic-Assisted Total Knee Arthroplasty: Increasing in Popularity Without Increasing
                  Complications</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2022 Dec;37(12):2358-64.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Computer-Navigated%20and%20Robotic-Assisted%20Total%20Knee%20Arthroplasty%3A%20Increasing%20in%20Popularity%20Without%20Increasing%20Complications&as_occt=title&as_sauthors=%20%22JC%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Heinz T</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Eidmann A</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Anderson P</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Weißenberger M</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Jakuscheit A</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Rudert M</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Stratos I</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Trends in Computer-Assisted
                  Surgery for Total Knee Arthroplasty in Germany: An Analysis Based on the Operative Procedure
                  Classification System between 2010 to 2021</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">J Clin Med.</span> 2023 Jan
                9;12(2):549.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trends%20in%20Computer-Assisted%20Surgery%20for%20Total%20Knee%20Arthroplasty%20in%20Germany%3A%20An%20Analysis%20Based%20on%20the%20Operative%20Procedure%20Classification%20System%20between%202010%20to%202021&as_occt=title&as_sauthors=%20%22T%20Heinz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Smith PNGD</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> McAuliffe MJ</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> McDougall C</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Stoney JD</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Vertullo CJ</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Wall CJ</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Corfield S</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Page R</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Cuthbert AR</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Du P</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Harries D</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Holder C</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Lorimer MF</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Cashman K</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Lewis PL</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Hip, Knee and Shoulder
                  Arthroplasty</span>: <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">2023 Annual Report.</span> 2023. Accessed 2024 Aug 13. <a
                  href="https://aoanjrr.sahmri.com/en-US/annual-reports-2023" target="_blank" data-id="link_1"
                  class="link"
                  style="position: unset;">https://aoanjrr.sahmri.com/en-US/annual-reports-2023</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%2C%20Knee%20and%20Shoulder%20Arthroplasty&as_occt=title&as_sauthors=%20%22PNGD%20Smith%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Jones CW</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Jerabek SA</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Current Role of Computer
                  Navigation in Total Knee Arthroplasty</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2018 Jul;33(7):1989-93.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Current%20Role%20of%20Computer%20Navigation%20in%20Total%20Knee%20Arthroplasty&as_occt=title&as_sauthors=%20%22CW%20Jones%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Mahoney O</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Kinsey T</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Sodhi N</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Mont MA</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Chen AF</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Orozco F</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Hozack W</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Improved Component Placement
                  Accuracy with Robotic-Arm Assisted Total Knee Arthroplasty</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">J Knee Surg.</span> 2022
                Feb;35(3):337-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Improved%20Component%20Placement%20Accuracy%20with%20Robotic-Arm%20Assisted%20Total%20Knee%20Arthroplasty&as_occt=title&as_sauthors=%20%22O%20Mahoney%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Shen TS</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Uppstrom TJ</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Walker PJ</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Yu JS</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Cheng R</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Mayman DJ</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Jerabek SA</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Ast MP</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">High degree of alignment
                  precision associated with total knee arthroplasty performed using a surgical robot or handheld
                  navigation</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2023
                Nov;31(11):4735-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High%20degree%20of%20alignment%20precision%20associated%20with%20total%20knee%20arthroplasty%20performed%20using%20a%20surgical%20robot%20or%20handheld%20navigation&as_occt=title&as_sauthors=%20%22TS%20Shen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Barrack RL</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Ruh EL</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Williams BM</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Ford AD</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Foreman K</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Nunley RM</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Patient specific cutting blocks
                  are currently of no proven value</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 2012 Nov;94(11)(Suppl A):95-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20specific%20cutting%20blocks%20are%20currently%20of%20no%20proven%20value&as_occt=title&as_sauthors=%20%22RL%20Barrack%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Hickey MD</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Masri BA</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Hodgson AJ</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Can Technology Assistance be
                  Cost Effective in TKA? A Simulation-Based Analysis of a Risk-prioritized, Practice-specific
                  Framework</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2023 Jan 1;481(1):157-73.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Can%20Technology%20Assistance%20be%20Cost%20Effective%20in%20TKA%3F%20A%20Simulation-Based%20Analysis%20of%20a%20Risk-prioritized%2C%20Practice-specific%20Framework&as_occt=title&as_sauthors=%20%22MD%20Hickey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Rajan PV</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Khlopas A</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Klika A</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Molloy R</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Krebs V</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Piuzzi NS</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">The Cost-Effectiveness of
                  Robotic-Assisted Versus Manual Total Knee Arthroplasty: A Markov Model-Based Evaluation</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J Am Acad Orthop
                  Surg.</span> 2022 Feb 15;30(4):168-76.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Cost-Effectiveness%20of%20Robotic-Assisted%20Versus%20Manual%20Total%20Knee%20Arthroplasty%3A%20A%20Markov%20Model-Based%20Evaluation&as_occt=title&as_sauthors=%20%22PV%20Rajan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Steffens D</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Karunaratne S</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> McBride K</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Gupta S</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Horsley M</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Fritsch B</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Implementation of
                  robotic-assisted total knee arthroplasty in the public health system: a comparative cost
                  analysis</span>. <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Int
                  Orthop.</span> 2022 Mar;46(3):481-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Implementation%20of%20robotic-assisted%20total%20knee%20arthroplasty%20in%20the%20public%20health%20system%3A%20a%20comparative%20cost%20analysis&as_occt=title&as_sauthors=%20%22D%20Steffens%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Kim YH</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Park JW</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Kim JS</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">2017 Chitranjan S. Ranawat
                  Award: Does Computer Navigation in Knee Arthroplasty Improve Functional Outcomes in Young Patients? A
                  Randomized Study</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2018 Jan;476(1):6-15.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=2017%20Chitranjan%20S.%20Ranawat%20Award%3A%20Does%20Computer%20Navigation%20in%20Knee%20Arthroplasty%20Improve%20Functional%20Outcomes%20in%20Young%20Patients%3F%20A%20Randomized%20Study&as_occt=title&as_sauthors=%20%22YH%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Kim YH</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Yoon SH</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Park JW</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Does Robotic-assisted TKA
                  Result in Better Outcome Scores or Long-Term Survivorship Than Conventional TKA? A Randomized,
                  Controlled Trial</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2020 Feb;478(2):266-75.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20Robotic-assisted%20TKA%20Result%20in%20Better%20Outcome%20Scores%20or%20Long-Term%20Survivorship%20Than%20Conventional%20TKA%3F%20A%20Randomized%2C%20Controlled%20Trial&as_occt=title&as_sauthors=%20%22YH%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Ollivier M</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Parratte S</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Lino L</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Flecher X</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Pesenti S</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Argenson JN</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">No Benefit of Computer-assisted
                  TKA: 10-year Results of a Prospective Randomized Study</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2018
                Jan;476(1):126-34.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=No%20Benefit%20of%20Computer-assisted%20TKA%3A%2010-year%20Results%20of%20a%20Prospective%20Randomized%20Study&as_occt=title&as_sauthors=%20%22M%20Ollivier%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Schotanus MGM</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Boonen B</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> van der Weegen W</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Hoekstra H</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> van Drumpt R</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Borghans R</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Vos R</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> van Rhijn L</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Kort NP</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">No difference in mid-term
                  survival and clinical outcome between patient-specific and conventional instrumented total knee
                  arthroplasty: a randomized controlled trial</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2019
                May;27(5):1463-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=No%20difference%20in%20mid-term%20survival%20and%20clinical%20outcome%20between%20patient-specific%20and%20conventional%20instrumented%20total%20knee%20arthroplasty%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22MGM%20Schotanus%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Kayani B</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Konan S</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Ayuob A</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Onochie E</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Al-Jabri T</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Haddad FS</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Robotic technology in total
                  knee arthroplasty: a systematic review</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">EFORT Open Rev.</span> 2019 Oct 1;4(10):611-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Robotic%20technology%20in%20total%20knee%20arthroplasty%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22B%20Kayani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;">American
                Academy of Orthopaedic Surgeons. <span data-id="strong_19" class="annotation strong"
                  style="position: unset;">Surgical Management of Osteoarthritis of the Knee: Evidence-Based Clinical
                  Practice Guideline</span>. 2022 Dec 2. Accessed 2023 Nov 10. <a href="http://www.aaos.org/smoak2cpg"
                  target="_blank" data-id="link_2" class="link"
                  style="position: unset;">www.aaos.org/smoak2cpg</a></span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20Management%20of%20Osteoarthritis%20of%20the%20Knee%3A%20Evidence-Based%20Clinical%20Practice%20Guideline&as_occt=title&as_sauthors=%20%22American%20Academy%20of%20Orthopaedic%20Surgeons%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Wilson S</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Marx RG</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Pan TJ</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Lyman S</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Meaningful Thresholds for the
                  Volume-Outcome Relationship in Total Knee Arthroplasty</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2016 Oct
                19;98(20):1683-90.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1262709"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Wilson MD</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Dowsey MM</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Spelman T</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Choong PF</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Impact of surgical experience
                  on outcomes in total joint arthroplasties</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">ANZ J Surg.</span> 2016
                Dec;86(12):967-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impact%20of%20surgical%20experience%20on%20outcomes%20in%20total%20joint%20arthroplasties&as_occt=title&as_sauthors=%20%22MD%20Wilson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> de Steiger RN</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Graves SE</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Orthopaedic registries: the
                  Australian experience</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">EFORT Open Rev.</span> 2019 Jun 3;4(6):409-15.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Orthopaedic%20registries%3A%20the%20Australian%20experience&as_occt=title&as_sauthors=%20%22Steiger%20RN%20de%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Jorgensen NB</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> McAuliffe M</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Orschulok T</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Lorimer MF</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> de Steiger R</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Major Aseptic Revision
                  Following Total Knee Replacement: A Study of 478,081 Total Knee Replacements from the Australian
                  Orthopaedic Association National Joint Replacement Registry</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2019 Feb
                20;101(4):302-10.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1948099"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Korber S</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Antonios JK</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Sivasundaram L</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Mayfield CK</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Kang HP</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Chung BC</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Oakes DA</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Heckmann ND</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Utilization of
                  technology-assisted total hip arthroplasty in the United States from 2005 to 2018</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Arthroplast Today.</span>
                2021 Oct 29;12:36-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Utilization%20of%20technology-assisted%20total%20hip%20arthroplasty%20in%20the%20United%20States%20from%202005%20to%202018&as_occt=title&as_sauthors=%20%22S%20Korber%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_133" class="annotation" style="position: unset;"> O’Rourke RJ</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Milto AJ</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Kurcz BP</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Scaife SL</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Allan DG</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> El Bitar Y</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Decreased patient comorbidities
                  and post-operative complications in technology-assisted compared to conventional total knee
                  arthroplasty</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2023
                Mar;31(3):1168-75.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Decreased%20patient%20comorbidities%20and%20post-operative%20complications%20in%20technology-assisted%20compared%20to%20conventional%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22RJ%20O%E2%80%99Rourke%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Okoro T</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Tomescu S</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Paterson JM</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Ravi B</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Analysis of the relationship
                  between surgeon procedure volume and complications after total knee arthroplasty using a
                  propensity-matched cohort study</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">BMJ Surg Interv Health Technol.</span> 2021 Apr 2;3(1):e000072.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Analysis%20of%20the%20relationship%20between%20surgeon%20procedure%20volume%20and%20complications%20after%20total%20knee%20arthroplasty%20using%20a%20propensity-matched%20cohort%20study&as_occt=title&as_sauthors=%20%22T%20Okoro%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Lau RL</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Perruccio AV</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Gandhi R</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Mahomed NN</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">The role of surgeon volume on
                  patient outcome in total knee arthroplasty: a systematic review of the literature</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">BMC Musculoskelet
                  Disord.</span> 2012 Dec 14;13:250.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20role%20of%20surgeon%20volume%20on%20patient%20outcome%20in%20total%20knee%20arthroplasty%3A%20a%20systematic%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22RL%20Lau%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Kreder HJ</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Grosso P</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Williams JI</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Jaglal S</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Axcell T</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Wal EK</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Stephen DJ</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Provider volume and other
                  predictors of outcome after total knee arthroplasty: a population study in Ontario</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Can J Surg.</span> 2003
                Feb;46(1):15-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Provider%20volume%20and%20other%20predictors%20of%20outcome%20after%20total%20knee%20arthroplasty%3A%20a%20population%20study%20in%20Ontario&as_occt=title&as_sauthors=%20%22HJ%20Kreder%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Katz JN</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Barrett J</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Mahomed NN</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Baron JA</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Wright RJ</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Losina E</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Association between hospital
                  and surgeon procedure volume and the outcomes of total knee replacement</span>. <span
                  data-id="emphasis_28" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2004 Sep;86(9):1909-16.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=928414" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Liddle AD</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Pandit H</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Effect of Surgical Caseload on
                  Revision Rate Following Total and Unicompartmental Knee Replacement</span>. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2016 Jan 6;98(1):1-8.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1243012" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Yasunaga H</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Tsuchiya K</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Matsuyama Y</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Ohe K</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Analysis of factors affecting
                  operating time, postoperative complications, and length of stay for total knee arthroplasty:
                  nationwide web-based survey</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">J Orthop Sci.</span> 2009 Jan;14(1):10-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Analysis%20of%20factors%20affecting%20operating%20time%2C%20postoperative%20complications%2C%20and%20length%20of%20stay%20for%20total%20knee%20arthroplasty%3A%20nationwide%20web-based%20survey&as_occt=title&as_sauthors=%20%22H%20Yasunaga%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Baker P</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Jameson S</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Critchley R</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Reed M</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Gregg P</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Deehan D</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Center and surgeon volume
                  influence the revision rate following unicondylar knee replacement: an analysis of 23,400 medial
                  cemented unicondylar knee replacements</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2013 Apr 17;95(8):702-9.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1162579" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Kazarian GS</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Lawrie CM</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Barrack TN</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Donaldson MJ</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Miller GM</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Haddad FS</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Barrack RL</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">The Impact of Surgeon Volume
                  and Training Status on Implant Alignment in Total Knee Arthroplasty</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2019 Oct 2;101(19):1713-23.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=2186737" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Manley M</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Ong K</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Lau E</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Kurtz SM</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Total knee arthroplasty
                  survivorship in the United States Medicare population: effect of hospital and surgeon procedure
                  volume</span>. <span data-id="emphasis_33" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2009 Oct;24(7):1061-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20knee%20arthroplasty%20survivorship%20in%20the%20United%20States%20Medicare%20population%3A%20effect%20of%20hospital%20and%20surgeon%20procedure%20volume&as_occt=title&as_sauthors=%20%22M%20Manley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Fowler TJ</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Aquilina AL</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Blom AW</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Sayers A</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Whitehouse MR</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Association between surgeon
                  grade and implant survival following hip and knee replacement: a systematic review and
                  meta-analysis</span>. <span data-id="emphasis_34" class="annotation emphasis"
                  style="position: unset;">BMJ Open.</span> 2021 Nov 10;11(11):e047882.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20between%20surgeon%20grade%20and%20implant%20survival%20following%20hip%20and%20knee%20replacement%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22TJ%20Fowler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Singh V</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Fiedler B</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Simcox T</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Aggarwal VK</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Schwarzkopf R</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Meftah M</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Does the Use of Intraoperative
                  Technology Yield Superior Patient Outcomes Following Total Knee Arthroplasty?</span><span
                  data-id="emphasis_35" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2021
                Jul;36(7S):S227-S32.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20the%20Use%20of%20Intraoperative%20Technology%20Yield%20Superior%20Patient%20Outcomes%20Following%20Total%20Knee%20Arthroplasty%3F&as_occt=title&as_sauthors=%20%22V%20Singh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Harvie P</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Sloan K</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Beaver RJ</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Computer navigation vs
                  conventional total knee arthroplasty: five-year functional results of a prospective randomized
                  trial</span>. <span data-id="emphasis_36" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2012 May;27(5):667-72.e1.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Computer%20navigation%20vs%20conventional%20total%20knee%20arthroplasty%3A%20five-year%20functional%20results%20of%20a%20prospective%20randomized%20trial&as_occt=title&as_sauthors=%20%22P%20Harvie%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D757bf1ae-7e46-455e-9ce7-a1eadbbd6a34%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F22%2F2063%26topics%3Dkn"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2063</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00539</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 26, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;757bf1ae-7e46-455e-9ce7-a1eadbbd6a34&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;kn&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=757bf1ae-7e46-455e-9ce7-a1eadbbd6a34&type=pdf&name=JBJS.24.00539.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=757bf1ae-7e46-455e-9ce7-a1eadbbd6a34&type=pdf&name=JBJS.24.00539.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_43"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_44" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I215"
                            target="_blank" data-id="link_3" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I215</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;757bf1ae-7e46-455e-9ce7-a1eadbbd6a34&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;kn&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=757bf1ae-7e46-455e-9ce7-a1eadbbd6a34&type=zip&name=JBJS.24.00539.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Michael McAuliffe, MBBS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ibrahim Darwish, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:ibrahim.darwish093@gmail.com" class=""
                    style="position: unset;">ibrahim.darwish093@gmail.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0334-9406" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0334-9406</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jon Anderson, MBBS<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span><span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alex Nicholls, MBBS<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sophie Corfield, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Dylan Harries, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Christopher Vertullo, MBBS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Australian Orthopaedic Association National Joint Replacement Registry,
                Adelaide, South Australia, Australia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Queensland University of Technology, Brisbane, Queensland, Australia</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">CJM Centre, Ipswich, Queensland, Australia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Sydney Orthopaedic Research Institute, Sydney, New South Wales,
                Australia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Brisbane Orthopaedic and Sports Medicine Centre (BOSMC), Brisbane, Queensland,
                Australia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">School of Human Movement and Nutrition Sciences, University of Queensland, St
                Lucia, Queensland, Australia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 30997.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
