import { OnboardingContainer } from "@features/auth";
import DocumentTitle from "components/DocumentTitle";
import { Stack } from "expo-router";

const OnboardingRoute = () => {
	return (
		<>
			<Stack.Screen
				options={{
					headerShown: false,
				}}
			/>
			<DocumentTitle title="Onboarding" />
			<OnboardingContainer />
		</>
	);
};

export default OnboardingRoute;
