import type { Article } from "../../../../../types";

export const Article619: Article = {
	id: 619,
	rsuiteId: "e44684d2-9755-4140-9d16-dcdbf0a0dd94",
	type: "commentary and perspective",
	title:
		"Patients Presenting with Acute Myocardial Injury with Hip Fracture Have Greater Survival with Rapid Surgical Care",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/619.jpeg",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Patients Presenting with Acute Myocardial Injury with Hip
                Fracture Have Greater Survival with Rapid Surgical Care</div>
              <div class="text subtitle" style="position: unset;">Commentary on an article by Flavia K. Borges, MD, PhD,
                et al.: “Myocardial Injury in Patients with Hip Fracture. A HIP ATTACK Randomized Trial Substudy”</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Charles
                      Cornell, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">Current evidence and clinical experience strongly support
                  the principle that elderly patients with a hip fracture are best served by prompt surgical repair<a
                    href="" data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">1</span></a>. Although consulting physicians and assigned
                  anesthesiologists often believe that medical management and testing are needed preoperatively, the
                  resulting surgical delay typically worsens early survival without any beneficial effect. There are
                  ample published data confirming that a delay to reverse anticoagulation or to perform echocardiography
                  does not improve outcomes or help avoid complications<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a>. Although there is some controversy regarding the preferred
                  time to surgery, 48 hours appears to be the upper limit of acceptable delay and 24 hours may be the
                  ideal window<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">5</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">The HIP ATTACK trial was carried out to determine if even
                  more accelerated surgery—i.e., performed within 6 hours—provided additional benefit over a 24-hour
                  delay. The authors of that study found that accelerated surgery performed within 6 hours did not
                  significantly lower the risk of mortality or a composite of major complications compared with surgery
                  performed within 24 hours<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">6</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">Of the 1392 patients in the HIP ATTACK trial, 47% had a
                  preoperative troponin level recorded and 23% of the 1392 had an elevated level indicating preoperative
                  myocardial injury<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">6</span></a>.
                  Despite this evidence of myocardial injury, patients were randomized per the study protocol. In the
                  present substudy involving the patients with preoperative troponin measurement, surgery within 6 hours
                  reduced early mortality by approximately half in those with an elevated troponin level whereas, in the
                  group without evidence of myocardial injury, accelerated surgery provided no benefit compared with
                  surgery delayed for 24 hours. This result suggests that patients with a hip fracture and concomitant
                  myocardial injury may have better survival with fewer complications when the physiologic stress of the
                  fracture is relieved in as little time as possible. Conversely, if the stress is prolonged for even 24
                  hours, the mortality rate may be doubled. As pointed out by the authors, these results are similar to
                  those in previous cohort studies demonstrating that preoperative myocardial injury is common in
                  patients with a hip fracture (range, 15% to 30%) and carries a poor prognosis. Currently, there are no
                  clinical guidelines on how to manage those patients. Unfortunately, this substudy is probably
                  underpowered. If its findings can be corroborated, they should provoke a new approach to the care of
                  elderly patients with a hip fracture.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">As suggested by the authors, the observed myocardial
                  injuries may have been a consequence of the physiologic stress induced by the hip fracture and are a
                  marker of poor cardiac reserve. The stress is likely related to pain and inflammation from the
                  fracture, and ischemia may be compounded by dehydration, bleeding, and hypoperfusion. As suggested by
                  this study, early surgery may provide rehydration, cessation of bleeding, and pain relief, thereby
                  reducing the stress response and critically reducing cardiac workload and cardiac injury. The key
                  points are that the stress needs to be relieved as quickly as possible and that delay for even 24
                  hours increases mortality and morbidity in this frail population with little reserve. Because surgery
                  within 6 hours may be impractical in many hospital settings, alternative approaches to reduction of
                  this stress are needed. Can this be achieved with better clinical protocols that improve care in the
                  preoperative period? Future studies need to assess if pain relief with long-acting intra-articular and
                  peripheral nerve blocks might provide needed pain relief when given upon admission. Intravenous
                  hydration, appropriate transfusion, and anti-inflammatory medications might also help blunt the
                  stress/inflammatory response.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">The HIP ATTACK Investigators have clearly documented that
                  approximately 20% of patients presenting with hip fracture have concomitant cardiac injury that
                  demands immediate treatment. Their data support the idea that immediate surgery may play a role in
                  relieving the stress related to the fracture, thus providing a better chance of survival. They also
                  demonstrated that the preoperative period following hip fracture is a critical period that definitely
                  impacts morbidity and mortality. These revelations need to be acted upon. Surgical treatment of hip
                  fractures should be expedited as much as possible. The time to surgery after a hip fracture must
                  become a quality-of-care metric. Future studies are needed that corroborate the HIP ATTACK data and
                  provide a better understanding of the stress response that results from hip fracture. Hopefully, this
                  research will lead to new and improved protocols for preoperative management of these frail and
                  critically injured patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Simunovic N</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Devereaux PJ</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Sprague S</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Guyatt GH</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Schemitsch E</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Debeer J</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Bhandari M</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Effect of early surgery after
                  hip fracture on mortality and complications: systematic review and meta-analysis</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">CMAJ.</span> 2010 Oct
                19;182(15):1609-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20early%20surgery%20after%20hip%20fracture%20on%20mortality%20and%20complications%3A%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22N%20Simunovic%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Mattesi L</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Noailles T</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Rosencher N</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Rouvillain JL</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Discontinuation of Plavix<span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">®</span>
                  (clopidogrel) for hip fracture surgery. A systematic review of the literature</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Orthop Traumatol Surg
                  Res.</span> 2016 Dec;102(8):1097-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Discontinuation%20of%20Plavix%C2%AE%20(clopidogrel)%20for%20hip%20fracture%20surgery.%20A%20systematic%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22L%20Mattesi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Wang J</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Nilssen P</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Stone J</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Breda K</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Little M</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Moon C</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Lin C</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Expedited surgery does not
                  increase transfusion rates for patients with geriatric hip fracture taking factor Xa
                  inhibitors</span>. <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">OTA
                  Int.</span> 2023 Dec 22;6(5 Suppl):e292.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Expedited%20surgery%20does%20not%20increase%20transfusion%20rates%20for%20patients%20with%20geriatric%20hip%20fracture%20taking%20factor%20Xa%20inhibitors&as_occt=title&as_sauthors=%20%22J%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Memtsoudis SG</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Preoperative Echocardiography in
                  Hip Fracture Patients: A Waste of Time or Good Practice?</span><span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Anesth Analg.</span> 2019
                Feb;128(2):207-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preoperative%20Echocardiography%20in%20Hip%20Fracture%20Patients%3A%20A%20Waste%20of%20Time%20or%20Good%20Practice%3F&as_occt=title&as_sauthors=%20%22SG%20Memtsoudis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Pincus D</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Ravi B</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Wasserstein D</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Huang A</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Paterson JM</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Nathens AB</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Kreder HJ</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Jenkinson RJ</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Wodchis WP</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Association Between Wait Time
                  and 30-Day Mortality in Adults Undergoing Hip Fracture Surgery</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">JAMA.</span> 2017 Nov
                28;318(20):1994-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20Between%20Wait%20Time%20and%2030-Day%20Mortality%20in%20Adults%20Undergoing%20Hip%20Fracture%20Surgery&as_occt=title&as_sauthors=%20%22D%20Pincus%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;">HIP ATTACK
                Investigators. <span data-id="strong_6" class="annotation strong" style="position: unset;">Accelerated
                  surgery versus standard care in hip fracture (HIP ATTACK): an international, randomised, controlled
                  trial</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 2020 Feb 29;395(10225):698-1-2.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Accelerated%20surgery%20versus%20standard%20care%20in%20hip%20fracture%20(HIP%20ATTACK)%3A%20an%20international%2C%20randomised%2C%20controlled%20trial&as_occt=title&as_sauthors=%20%22HIP%20ATTACK%20Investigators%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3De44684d2-9755-4140-9d16-dcdbf0a0dd94%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Commentary and Perspective</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): e50</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00583</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">December 18, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=0614619d-b8ac-4c02-8b9c-317341d189b3&native=1"
                              class="article-title" style="position: unset;">Myocardial Injury in Patients with Hip
                              Fracture<span class="subtitle" style="position: unset;"><br style="position: unset;">A HIP
                                ATTACK Randomized Trial Substudy</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Borges,
                              Flavia K.; Guerra-Farfan, Ernesto; Bhandari, Mohit; Patel, Ameen; Slobogean, Gerard;
                              Feibel, Robert J.; Sancheti, Parag K.; Tiboni, Maria E.; Balaguer-Castro, Mariano; Tandon,
                              Vikas; Tomas-Hernandez, Jordi; Sigamani, Alben; Sigamani, Alen; Szczeklik, Wojciech;
                              McMahon, Stephen J.; Ślęczka, Pawel; Ramokgopa, Mmampapatla T.; Adinaryanan, S.; Umer,
                              Masood; Jenkinson, Richard J.; Lawendy, Abdel; Popova, Ekaterine; Nur, Aamer Nabi; Wang,
                              Chew Yin; Vizcaychipi, Marcela; Biccard, Bruce M.; Ofori, Sandra; Spence, Jessica;
                              Duceppe, Emmanuelle; Marcucci, Maura; Harvey, Valerie; Balasubramanian, Kumar; Vincent,
                              Jessica; Tonelli, Ana Claudia; Devereaux, P.J.; the HIP ATTACK Investigators :~ Veevaete,
                              Laurent; le Polain de Waroux, Bernard; Lavand'homme, Patricia; Cornu, Olivier; Tribak,
                              Karim; Yombi, Jean C; Touil, Nassim; Bhutia, Jigme T; Clinckaert, Carol; De Clippeleir,
                              Dirk; Reul, Maike; Patel, Ameen; Tandon, Vikas; Gauthier, Leslie P; Avram, Victoria RA;
                              Winemaker, Mitchell; de Beer, Justin; Simpson, Diane L; Worster, Andrew; Alvarado, Kim A;
                              Gregus, Krysten K; Lawrence, Kelly H; Leong, Darryl P; Joseph, Philip G; Magloire,
                              Patrick; Deheshi, Benjamin; Bisland, Stuart; Wood, Thomas J; Tushinski, Daniel M; Wilson,
                              David AJ; Haider, Shariq; Kearon, Clive; Bali, Kamal; Le Manach, Yannick; Adili, Anthony;
                              Tiboni, Maria E; Neary, John D; Cowan, David D; Khanna, Vickas; Zaki, Amna; Farrell, Janet
                              C; MacDonald, Anne Marie; Conen, David; Wong, Steven CW; Karbassi, Arsha; Wright, Douglas
                              S; Shanthanna, Harsha; Coughlin, Ryan; Khan, Moin; Wikkerink, Spencer; Quraishi, Faraaz A;
                              Lawendy, Abdel-Rahman; Kishta, Waleed; Schemitsch, Emil; Macleod, Mark D; Sanders, David
                              W; Vasarhelyi, Edward; Dresser, George K; Tieszer, Christina; Inculet, Clayton; Gagnier,
                              Joel; Schemitsch, Emil; Jenkinson, Richard J; Shadowitz, Steven; Lee, Jacques S; Choi,
                              Stephen; Kreder, Hans J; Nousiainen, Markku; Kunz, Monica R; Tuazon, Ravianne; Shrikumar,
                              Mopina; Ravi, Bheeshma; Wasserstein, David; Stephen, David JG; Nam, Diane; Henry, Patrick
                              DG; Wood, Gavin CA; Mann, Stephen M; Jaeger, Melanie T; Sivilotti, Marco LA; Smith,
                              Christopher A; Frank, Christopher C; Grant, Heather; Ploeg, Leone; Yach, Jeff D; Harrison,
                              Mark M; Campbell, Aaron R; Bicknell, Ryan T; Bardana, Davide D; Feibel, Robert J;
                              McIlquham, Katie; Gallant, Catherine; Halman, Samantha; Thiruganasambandamoorth,
                              Venkatesh; Ruggiero, Sara; Hadden, William J; Chen, Brian PJ; Coupal, Stephanie A;
                              McMahon, Stephen J; McLean, Lisa M; Shirali, Hemant R; Haider, Syed Y; Smith, Crystal A;
                              Watts, Evan; Santone, David J; Koo, Kevin; Yee, Allan J; Oyenubi, Ademilola N; Nauth,
                              Aaron; Schemitsch, Emil H; Daniels, Timothy R; Ward, Sarah E; Hall, Jeremy A; Ahn, Henry;
                              Whelan, Daniel B; Atrey, Amit; Khoshbin, Amir; Puskas, David; Droll, Kurt; Cullinan,
                              Claude; Payendeh, Jubin; Lefrancois, Tina; Mozzon, Lise; Marion, Travis; Jacka, Michael J;
                              Greene, James; Menon, Matthew; Stiegelmahr, Robert; Dillane, Derek; Irwin, Marleen;
                              Beaupre, Lauren; Coles, Chad P; Trask, Kelly; MacDonald, Shelley; Trenholm, JAI; Oxner,
                              William; Richardson, CG; Dehghan, Niloofar; Sadoughi, Mehdi; Sharma, Achal; White, Neil J;
                              Olivieri, Loretta; Hunt, Stephen B; Turgeon, Thomas R; Bohm, Eric R; Tran, Sarah; Giilck,
                              Stephen M; Hupel, Tom; Guy, Pierre; O'Brien, Peter J; Duncan, Andrew W; Crawford, Gordon
                              A; Zhou, Junlin; Zhao, Yanrui; Liu, Yang; Shan, Lei; Wu, Anshi; Muñoz, Juan M; Chaudier,
                              Philippe; Douplat, Marion; Fessy, Michel Henri; Piriou, Vincent; Louboutin, Lucie; David,
                              Jean Stephane; Friggeri, Arnaud; Beroud, Sebastien; Fayet, Jean Marie; Landais, Paul; Li
                              Leung, Frankie Ka; Fang, Christian Xinshuo; Hang Yee, Dennis King; Sancheti, Parag K;
                              Pradhan, Chetan V; Patil, Atul A; Puram, Chetan P; Borate, Madhav P; Kudrimoti, Kiran B;
                              Adhye, Bharati A; Dongre, Himanshu V; John, Bobby; Abraham, Valsamma; Pandey, Ritesh A;
                              Rajkumar, Arti; George, Preetha E; Sigamani, Alen; Stephen, Manesh; Chandran, Nitheesh;
                              Ashraf, Mohammed; Georgekutty, AM; Sulthan, Ahamad S; Adinarayanan, S; Sharma, Deep;
                              Barnawal, Satish P; Swaminathan, Srinivasan; Bidkar, Prasanna U; Mishra, Sandeep K; Menon,
                              Jagdish; M, Niranjan; ZK, Varghese; Hiremath, Santosh A; NC, Madhusudhan; Jawali, Abhijit;
                              Gnanadurai, Kingsly R; George, Carolin E; Maddipati, Tatarao; KP, Mary; Sharma, Vijay;
                              Farooque, Kamran; Malhotra, Rajesh; Mittal, Samarth; Sawhney, Chavi; Gupta, Babita;
                              Mathur, Purva; Gamangati, Shivanand; Tripathy, Vijaylaxmi; Menon, Prem H; Dhillon, Mandeep
                              S; Chouhan, Devendra K; Patil, Sharanu; Narayan, Ravi; Lal, Purushotham; Bilchod,
                              Prashanth N; Singh, Surya U; Gattu, Uttam V; Dashputra, Ravi P; Rahate, Prashant V;
                              Turiel, Maurizio; De Blasio, Giuseppe; Accetta, Riccardo; Perazzo, Paolo; Stella, Daniele;
                              Bonadies, Marika; Colombo, Chiara; Fozzato, Stefania; Pino, Fabio; Morelli, Ilaria;
                              Colnaghi, Eleonora; Salini, Vincenzo; Denaro, Giuseppe; Beretta, Luigi; Placella, Giacomo;
                              Giardina, Giuseppe; Binda, Mirko; Marcato, Anna; Guzzetti, Luca; Piccirillo, Fabio;
                              Cecconi, Maurizio; Khor, HM; Lai, Hou Yee; Kumar, CS; Chee, KH; Loh, PS; Tan, Kit Mun;
                              Singh, Simmrat; Foo, Li Lian; Prakasam, Komella; Chaw, Sook Hui; Lee, Meng-Li; Ngim,
                              Joanne HL; Boon, Huck Wee; Chin, Im Im; Kleinlugtenbelt, Ydo V; BM Landman, Ellie;
                              Flikweert, Elvira R; Roerdink, Herbert W; Brokelman, Roy BG; Elskamp-Meijerman, Hannie F;
                              Horst, Maarten R.; MG Cobben, Jan-Hein; Umer, Masood; Begum, Dilshad; Anjum, Anila;
                              Hashmi, Pervaiz M; Ahmed, Tashfeen; Rashid, Haroon U; Khattak, Mujahid J; Rashid, Rizwan
                              H; Lakdawala, Riaz H; Noordin, Shahryar; Juman, Naveed M; Khan, Robyna I; Riaz, Muhammad
                              M; Bokhari, Syedah S; Almas, Ayesha; Wahab, Hussain; Ali, Arif; Khan, Hammad N; Khan, Eraj
                              K; Nur, Aamer Nabi; Janjua, Kholood A; Orakzai, Sajjad H; Khan, Abdus S; Mustafa, Khawaja
                              J; Sohail, Mian A; Umar, Muhammad; Khan, Siddra A; Ashraf, Muhammad; Khan, Muhammad K;
                              Shiraz, Muhammad; Furgan, Ahmad; Ślęczka, Paweł; Dąbek, Piotr; Kumoń, Adam; Satora,
                              Wojciech; Ambroży, Wojciech; Święch, Mariusz; Rycombel, Jacek; Grzelak, Adrian; Kózka,
                              Ilona Nowak-; Gucwa, Jaroslaw; Machala, Waldemar; Ramokgopa, Mmampapatla T; Firth, Gregory
                              B; Karera, Mwalimu; Fourtounas, Maria; Singh, Virsen; Biscardi, Anna; Muhammad, ; </div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(24):2303 | July 25, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;e44684d2-9755-4140-9d16-dcdbf0a0dd94&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=e44684d2-9755-4140-9d16-dcdbf0a0dd94&type=pdf&name=JBJS.24.00583.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=e44684d2-9755-4140-9d16-dcdbf0a0dd94&type=pdf&name=JBJS.24.00583.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_7" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_8"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I132" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I132</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;e44684d2-9755-4140-9d16-dcdbf0a0dd94&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=e44684d2-9755-4140-9d16-dcdbf0a0dd94&type=zip&name=JBJS.24.00583.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Charles Cornell, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:cornellc@hss.edu" class="" style="position: unset;">cornellc@hss.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7076-8803" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7076-8803</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Hospital for Special Surgery, Weill Cornell College of Medicine, New York,
                NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 5963.43px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
