import type { AccessCodeData } from "@features/auth";
import { formatTimeString } from "@helpers/date";
import { isOlxExamWithinDateRange } from "@helpers/exams";
import { DateTime } from "luxon";

export const getRedeemCodeSuccessText = (accessCodeResult: AccessCodeData) => {
	const { bundleName, expiryDate, planLength } = accessCodeResult;
	const expiryText = DateTime.fromISO(expiryDate).toFormat("MMMM dd, yyyy");
	return `You now have **${planLength} access** to the **${bundleName}**! This will expire on **${expiryText}**`;
};

export const getOlxPostRedeemDialogConfig = (
	beginStartDate: string,
	endStartDate: string,
) => {
	const now = new Date().getTime();
	const beginTime = new Date(beginStartDate).getTime();
	const endTime = new Date(endStartDate).getTime();
	let title = "Access Unlocked";
	let body = "";
	let confirmCta = undefined;
	let cancelCta = "OK";

	if (now < beginTime) {
		const daysUntilStart = Math.ceil((beginTime - now) / (1000 * 60 * 60 * 24));
		body = `Your access to the OLX test has been unlocked! You can start your session in ${daysUntilStart} day(s). Please check back later to begin your session from the Practice Test tab.`;
	} else if (isOlxExamWithinDateRange(beginStartDate, endStartDate)) {
		body = `Your access to the OLX test is now active! You can start your session anytime before ${formatTimeString(
			endStartDate,
		)}. Would you like to begin your session now or start it later?`;
		confirmCta = "Start Now";
		cancelCta = "Start Later";
	} else if (now > endTime) {
		title = "Access Expired";
		body = `Your access to the OLX test has expired on ${formatTimeString(
			endStartDate,
		)}. Please contact your professor or school administrator for further assistance.`;
	}

	return {
		title,
		body,
		confirmCta,
		cancelCta,
	};
};
