import { useAppTheme } from "@hooks/useAppTheme";
import { type Features, useGlobalStore } from "@hooks/useGlobalStore";
import { Switch } from "react-native-paper";

const FeatureToggleSwitch = ({
	feature,
}: {
	feature: Features;
}) => {
	const theme = useAppTheme();
	const settingsEnabledFeatures = useGlobalStore(
		(state) => state.settingsEnabledFeatures,
	);
	const toggleFeature = useGlobalStore((state) => state.toggleFeature);

	return (
		<Switch
			value={settingsEnabledFeatures[feature]}
			onValueChange={() => toggleFeature(feature)}
			color={theme.colors.secondary}
		/>
	);
};

export default FeatureToggleSwitch;
