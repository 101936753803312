/* ----------------- Components --------------- */
import OutlinedCard from "components/OutlinedCard";

import { useAppTheme } from "@hooks/useAppTheme";
import { Box, useDevice } from "@memorang/ui";
import ContentLoader from "react-native-easy-content-loader";
/* ----------------- Types --------------- */
import { Card, Divider } from "react-native-paper";

const CardSkeleton = () => {
	const theme = useAppTheme();

	const { isMobile } = useDevice();

	const CardHeader = () => {
		return (
			<>
				<Box padding={16}>
					{/*@ts-ignore*/}
					<ContentLoader
						avatar
						titleStyles={{
							marginTop: 8,
							marginLeft: 8,
						}}
						aSize={40}
						paragraphStyles={{
							marginLeft: 8,
						}}
						avatarStyles={{
							marginLeft: -8,
						}}
						title
						pRows={1}
					/>
				</Box>
				<Divider
					style={{
						backgroundColor: theme.colors.surfaceVariant,
					}}
				/>
			</>
		);
	};

	const showCardHeader = !isMobile;
	return (
		<OutlinedCard>
			{showCardHeader && <CardHeader />}
			{[...Array(10).keys()].map((item) => {
				return (
					<Box key={item} padding={16}>
						{/*@ts-ignore*/}
						<ContentLoader
							key={item}
							avatar
							title
							pRows={1}
							aSize={40}
							titleStyles={{
								marginTop: 4,
								marginLeft: 8,
								height: 12,
							}}
							paragraphStyles={{
								marginLeft: 8,
								marginTop: -2,
							}}
							avatarStyles={{
								marginLeft: -8,
							}}
						/>
					</Box>
				);
			})}

			<Card.Actions>
				{/*@ts-ignore*/}
				<ContentLoader
					title={false}
					pRows={1}
					pHeight={34}
					paragraphStyles={{
						alignSelf: "flex-end",
						width: 100,
					}}
				/>
			</Card.Actions>
		</OutlinedCard>
	);
};

export default CardSkeleton;
