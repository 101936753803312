/* -----------------UI--------------- */
import { Button, type ButtonProps } from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Platform } from "react-native";
/* -----------------Child components--------------- */
import Box from "../Box";

interface Props extends ButtonProps {
	onPress: () => void;
	buttonText: string;
	children: React.ReactNode;
	maxWidth?: "xs" | "sm" | "md" | "lg";
	showButton?: boolean;
}

const StickyButtonContainer = ({
	onPress,
	buttonText,
	children,
	showButton = true,
	...buttonProps
}: Props) => {
	const insets = useSafeAreaInsets();
	return (
		<Box flex={1}>
			<Box style={{ flex: 1 }}>
				{children}
				{showButton && (
					<Box
						style={{
							paddingBottom: insets.bottom + (Platform.OS === "ios" ? 0 : 16),
							paddingHorizontal: 16,
						}}
					>
						<Button mode="contained" onPress={onPress} {...buttonProps}>
							{buttonText}
						</Button>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default StickyButtonContainer;
