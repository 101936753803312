/* -----------------Globals--------------- */
import { useLocalSearchParams, useRouter } from "expo-router";

/* -----------------UI--------------- */
import { Appbar } from "react-native-paper";

import { fetchBundleDetails } from "@features/store";
import { isWeb } from "@helpers/platform";
import { useDevice } from "@hooks/useDevice";
import type { BundleDetails } from "@memorang/types";
/* -----------------Child components--------------- */
import { Box, Loader, ScrollContainer } from "@memorang/ui";
import { AccessGrantedView } from "components/AccessGrantedView";
import DocumentTitle from "components/DocumentTitle";
import { CustomAppbar } from "components/index";
import { useEffect, useState } from "react";

const SuccessRoute = () => {
	const [bundleDetails, setBundleDetails] = useState<BundleDetails | null>(
		null,
	);

	const router = useRouter();

	const { isMobile } = useDevice();

	const { id: idFromParams } = useLocalSearchParams<{
		id: string;
		fromStore: string;
	}>();

	useEffect(() => {
		if (idFromParams) {
			const numericId = Number.parseInt(idFromParams);
			fetchBundleDetails(numericId).then((bundleDetails) => {
				setBundleDetails(bundleDetails);
			});
		}
	}, [idFromParams]);

	const headerTitle = "Thank You for Your Purchase!";

	if (!bundleDetails) {
		return (
			<Box flex={1} height="100%" justifyContent="center">
				<CustomAppbar
					options={{
						headerTitle,
						subtitle: isMobile ? "" : "Your Purchase is Successful.",
						headerLeft: () => (
							<Appbar.BackAction
								onPress={() => router.replace("/(protected)/(tabs)/home")}
							/>
						),
					}}
				/>
				<Loader />
			</Box>
		);
	}

	const {
		bundle: {
			name,
			bundleType,
			examName,
			id,
			icon: { url },
			features,
		},
	} = bundleDetails;

	const title = `Access to your ${name} has been successfully granted`;
	const isStudyPack = bundleType === "STUDY_PACK";
	const subtitle = isMobile ? "" : `Your ${name} Purchase is Successful.`;

	const products = [
		{
			id,
			title: name,
			description: isStudyPack ? "Study-Pack" : "Practice Test",
			icon: url,
			features,
			isCustomAccess: false,
		},
	];

	return (
		<>
			<CustomAppbar
				options={{
					headerTitle,
					subtitle,
					headerLeft: () => (
						<Appbar.BackAction
							onPress={() => {
								//Not sure but for some reason the router.replace is not working on mobile
								if (isWeb) {
									router.replace("/(protected)/(tabs)/home");
								} else {
									router.back();
								}
							}}
						/>
					),
				}}
			/>
			<DocumentTitle title="Purchase" />
			<ScrollContainer maxWidth="xs">
				<Box paddingTop={24} paddingBottom={44}>
					<AccessGrantedView
						products={products}
						examName={examName}
						title={title}
					/>
				</Box>
			</ScrollContainer>
		</>
	);
};

export default SuccessRoute;
