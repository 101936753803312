/**
 * @generated SignedSource<<9464e0d5b3eedee238a28761d8ef0a7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FetchAchievementsQuery$variables = {
  userId: string;
};
export type FetchAchievementsQuery$data = {
  readonly getAchievementList: ReadonlyArray<{
    readonly description: string | null;
    readonly events: number | null;
    readonly icon: {
      readonly backgroundColor: string | null;
      readonly url: any;
    } | null;
    readonly id: string | null;
    readonly level: number | null;
    readonly points: number | null;
    readonly title: string | null;
    readonly totalEvents: number | null;
    readonly usersEarned: number | null;
  } | null> | null;
};
export type FetchAchievementsQuery = {
  response: FetchAchievementsQuery$data;
  variables: FetchAchievementsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "points",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "level",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "events",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalEvents",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usersEarned",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "backgroundColor",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchAchievementsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AchievementLevelDetails",
        "kind": "LinkedField",
        "name": "getAchievementList",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "icon",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FetchAchievementsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AchievementLevelDetails",
        "kind": "LinkedField",
        "name": "getAchievementList",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "icon",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "832a1bd4d7d36bd5b05e73343f803bfc",
    "id": null,
    "metadata": {},
    "name": "FetchAchievementsQuery",
    "operationKind": "query",
    "text": "query FetchAchievementsQuery(\n  $userId: ID!\n) {\n  getAchievementList(userId: $userId) {\n    id\n    points\n    level\n    events\n    totalEvents\n    usersEarned\n    title\n    description\n    icon {\n      url\n      backgroundColor\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "454541d0a8936ad09bb9736fbfd2952b";

export default node;
