/* -----------------Globals--------------- */
import { useState } from "react";

/* -----------------Child components--------------- */
import { FilterView } from "@memorang/ui";

import { useExamContext, useExams } from "@features/exam";
import { CustomSnackbar } from "@memorang/ui";
/* -----------------Others--------------- */
import { useRouter } from "expo-router";
import SearchNoResult from "../../../../assets/no-search-results.svg";

type Props = {
	handleClose?: () => void;
	searchIcon?: string;
};
const ExamsFilterRouteView = ({ handleClose, searchIcon }: Props) => {
	const [snackbarMessage, setSnackbarMessage] = useState("");

	const { groupedExams, exams } = useExams();

	const {
		currentExamContext: { examId, examName },
		handleSwitchExamContext,
	} = useExamContext();

	const router = useRouter();

	const currentCategoryExamSelected = groupedExams?.find((item) => {
		return item.data.find(({ exam }) => exam.id === examId);
	});
	const sectionData = [...(groupedExams || [])]
		.sort(({ title }) => {
			return title === currentCategoryExamSelected?.title ? -1 : 1;
		})
		.map((group) => ({
			title: group.title,
			data: group.data.map(({ exam }) => ({
				name: exam.name,
				id: exam.id,
			})),
		}));

	const handleAction = (selectedItemId: string) => {
		const examDetails = exams.find(({ exam }) => exam.id === selectedItemId);
		const message = `Switched Exam from "${examName}" to "${examDetails?.exam.name}"`;
		setSnackbarMessage(message);
		handleSwitchExamContext(examDetails!);
	};
	return (
		<>
			<FilterView
				sectionData={sectionData}
				handleAction={handleAction}
				initSelectedItemId={examId}
				actionCta="Switch exam"
				searchIcon={searchIcon}
				emptySearchResultSvg={SearchNoResult}
				currentItemSelectedLabel="Current Exam"
			/>
			{snackbarMessage && (
				<CustomSnackbar
					message={snackbarMessage}
					visible={!!snackbarMessage}
					duration={500}
					handleDismiss={() => {
						setSnackbarMessage("");
						handleClose?.();
						router.navigate("/home");
					}}
				/>
			)}
		</>
	);
};

export default ExamsFilterRouteView;
