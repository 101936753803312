import type { EXAM_STATUS } from "types/tag";

export const taskCardBodyTemplate = ({
	isDiagonstic,
	isDailyProgress,
}: {
	isDiagonstic?: boolean;
	isDailyProgress?: boolean;
}): Record<EXAM_STATUS, string> => {
	if (isDailyProgress) {
		return {
			NOT_STARTED:
				"Revisit previous topics that you may have struggled with to keep your knowledge sharp",
			ACTIVE:
				"Continue to revisit previous topics that you may have struggled with to keep your knowledge sharp",
			COMPLETE: "",
			LEGACYREPORT: "",
			MIGRATEDREPORT: "",
		};
	}
	return {
		NOT_STARTED: isDiagonstic
			? "Take free diagnostic to become familiar with the exam format"
			: "Take full-length practice test to assess your exam readiness",
		ACTIVE: "Complete your in-progress assessment",
		COMPLETE: "Review your performance and answers",
		LEGACYREPORT: "",
		MIGRATEDREPORT: "",
	};
};
