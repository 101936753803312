import { TaskCardSkeleton, useDevice } from "@memorang/ui";
import { FlatList } from "react-native";

export const TasksSkeleton = () => {
	const { isMobile } = useDevice();
	return (
		<FlatList
			horizontal
			showsHorizontalScrollIndicator={false}
			contentContainerStyle={{
				paddingBottom: 8,
				paddingHorizontal: isMobile ? 16 : 0,
				gap: 16,
			}}
			data={[...Array(2).keys()]}
			renderItem={() => {
				return <TaskCardSkeleton />;
			}}
		/>
	);
};
