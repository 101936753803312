/* ----------------- Helpers --------------- */
import { getOLXExamEligibilityConfig } from "@helpers/exams";

/* ----------------- UI Components --------------- */
import { DialogWrapper } from "@memorang/ui";
import { Button, Dialog, Text } from "react-native-paper";

/* ----------------- Navigation --------------- */
import { router } from "expo-router";

export const OLXExamEligibilityDialog = ({
	visible,
	handleClose,
	endDateTime,
	startDateTime,
}: {
	visible: boolean;
	handleClose: () => void;
	endDateTime: string;
	startDateTime: string;
}) => {
	const { title, body: message } = getOLXExamEligibilityConfig(
		startDateTime,
		endDateTime,
	);
	return (
		<DialogWrapper visible={visible} handleClose={handleClose} maxWidth={"xs"}>
			<Dialog.Icon icon={"information-outline"} />
			<Dialog.Title style={{ textAlign: "center" }}>{title}</Dialog.Title>
			<Dialog.Content>
				<Text variant="bodyMedium">{message}</Text>
			</Dialog.Content>
			<Dialog.Actions>
				<Button
					mode="contained"
					contentStyle={{
						paddingHorizontal: 8,
					}}
					onPress={() => {
						router.push("/practice-tests");
						handleClose();
					}}
				>
					Go to Practice tests
				</Button>
			</Dialog.Actions>
		</DialogWrapper>
	);
};
