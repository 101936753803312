/* -----------------Constants--------------- */
import type { SessionChildItem, SessionItem } from "@memorang/types";

import PassageItem from "../components/itemtypes/passage/Item";
/* -----------------Components--------------- */
import QuestionItem from "../components/itemtypes/question/Item";

interface Props {
	item: SessionItem;
}
const StudySessionItemContainer = ({ item }: Props) => {
	const { itemType } = item;

	const lookup: Record<string, React.ReactNode> = {
		QUESTION: <QuestionItem item={item as SessionChildItem} />,
		PASSAGE: <PassageItem item={item} />,
	};

	const Component = lookup[itemType];

	return <>{Component}</>;
};

export default StudySessionItemContainer;
