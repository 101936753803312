/* -----------------Icons--------------- */
import { useAppTheme } from "@hooks/useAppTheme";
import { ConfirmDialog } from "components/ConfirmDialog";
import { Button } from "react-native-paper";
import {
	type DialogType,
	dialogSessionConfigs,
} from "../constants/dialog-configs";

type Props = {
	open: boolean;
	handleClose: () => void;
	type?: DialogType;
	handleClickButton?: (type: string) => void;
	customConfig?: {
		title: string;
		content: string;
		buttons?: {
			label: string;
			type: "cancel" | "confirm";
		}[];
		icon: string;
	};
	maxWidth?: unknown;
	showCloseButton?: boolean;
};
export default function DialogSession({
	open,
	handleClose,
	type = "pause",
	handleClickButton,
	customConfig,
	maxWidth,
	showCloseButton = true,
}: Props) {
	const theme = useAppTheme();
	const { title, content, buttons, icon } =
		customConfig || dialogSessionConfigs[type];
	const colorMap: Record<string, string> = {
		cancel: theme.colors.onSurfaceVariant,
		confirm: theme.colors.primary,
	};
	const renderActions = () => (
		<>
			{handleClickButton &&
				buttons &&
				buttons.map(({ label, type }) => (
					<Button
						key={label}
						onPress={() => {
							handleClickButton(type);
						}}
						textColor={colorMap[type]}
					>
						{label}
					</Button>
				))}
		</>
	);
	return (
		<ConfirmDialog
			visible={open}
			dismissCallback={handleClose}
			body={content}
			showCloseButton={showCloseButton}
			title={title}
			maxWidth={maxWidth}
			icon={icon}
			actions={renderActions()}
		/>
	);
}
