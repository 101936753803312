import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

/* -----------------Types--------------- */
import { zustandStorage } from "@helpers/storage";
import type { EXAM_STATUS } from "types/tag";

interface Progress {
	numCompleted: number;
	numGoal: number;
	status: EXAM_STATUS;
}

type ProgressByDate = {
	[date: string]: Progress;
};

export interface DailyProgressState {
	dailyProgressByData?: ProgressByDate;
	setDailyProgressByDate: (progress: ProgressByDate) => void;
}

export const useDailyProgressStore = create<DailyProgressState>()(
	devtools(
		persist(
			(set) => ({
				setDailyProgressByDate: (progress: ProgressByDate) => {
					set({ dailyProgressByData: progress });
				},
			}),
			{
				name: "quick-refresh-progress-storage",
				storage: createJSONStorage(() => zustandStorage),
			},
		),
	),
);
