/* ----------------- Local Imports --------------- */
import { useAppTheme } from "../../useAppTheme";
import type { PlanProps } from "./types";

/* ----------------- Third Party Imports --------------- */
import { Card, Text } from "react-native-paper";

const PlanCard = ({
	id,
	duration,
	price,
	expiry,
	selected,
	disabled,
	handleSelectPlan,
}: Omit<PlanProps, "offer">) => {
	const theme = useAppTheme();

	return (
		<Card
			mode="outlined"
			disabled={disabled || selected}
			onPress={() => handleSelectPlan(id)}
			style={{
				backgroundColor: selected
					? theme.colors.secondaryContainer
					: theme.colors.surface,
				flex: 1,
			}}
		>
			<Card.Content
				style={{
					alignItems: "center",
					gap: 4,
					paddingHorizontal: 8,
				}}
			>
				<Text
					variant="titleMedium"
					style={{
						fontWeight: "700",
					}}
				>
					{duration}
				</Text>
				<Text
					variant="titleMedium"
					style={{
						fontWeight: "700",
						fontStyle: "italic",
					}}
				>
					{price}
				</Text>
				<Text
					variant="labelMedium"
					numberOfLines={2}
					style={{
						fontWeight: selected ? "700" : "400",
						fontStyle: "italic",
						textAlign: "center",
					}}
				>
					{expiry}
				</Text>
			</Card.Content>
		</Card>
	);
};

export default PlanCard;
