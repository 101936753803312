import type { Article } from "../../../../../types";

export const Article501: Article = {
	id: 501,
	rsuiteId: "00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7",
	type: "scientific article",
	title:
		"The Wagner Cone Stem for Atypical Femoral Anatomy in Total Hip Arthroplasty",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7&type=jpeg&name=JBJS.23.00849f1a",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">The Wagner Cone Stem for Atypical Femoral Anatomy in
                Total Hip Arthroplasty</div>
              <div class="text subtitle" style="position: unset;">A Report of 320 Cases with Minimum 5-Year Follow-up
              </div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Babar Kayani,
                      MBBS, BSc, FRCS(Tr&Orth), PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Michael E.
                      Neufeld, MD, FRCSC, MSc</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 6 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Background:</span> The Wagner Cone Prosthesis was designed to address
                        complex femoral deformities during total hip arthroplasty (THA), but its mid-term component
                        survivorship and functional outcomes remain undetermined. The objectives of this study were to
                        determine the implant survivorship, patient satisfaction, functional outcomes, osseointegration
                        as seen radiographically, implant subsidence, and complications of THA using the Wagner Cone
                        Prosthesis stem at intermediate-term follow-up.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Methods:</span> This study involved 302 patients with proximal
                        femoral deformities, including developmental hip dysplasia and Legg-Calvé-Perthes disease, who
                        underwent a total of 320 primary THAs using the Wagner Cone Prosthesis. The average age at the
                        time of surgery was 49.4 ± 14.5 years (range, 18.8 to 85.6 years). Patient satisfaction was
                        recorded using a self-administered questionnaire assessing satisfaction in 4 domains. The
                        University of California at Los Angeles (UCLA) activity score, the Western Ontario and McMaster
                        Universities Osteoarthritis Index (WOMAC), the Oxford Hip Score (OHS), the Forgotten Joint Score
                        (FJS), radiographic outcomes, and complications were recorded. The mean follow-up time was 10.1
                        years (range, 5.2 to 15.5 years).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Results:</span> Survivorship of the Wagner Cone Prosthesis was 98.7%
                        (95% confidence interval [CI]: 97.2% to 100%) with stem revision as the end point and 95.8% (95%
                        CI: 93.5% to 98.2%) with reoperation for any reason as the end point at 10 years
                        postoperatively. In total, 3 stems were revised: 2 for infection and 1 for chronic hip
                        dislocation. The median patient satisfaction score was 95 (interquartile range [IQR], 80 to
                        100), median UCLA score was 6 (IQR, 6 to 7), median WOMAC score was 18 (IQR, 16 to 22), median
                        OHS was 40 (IQR, 36 to 47), and median FJS was 80 (IQR, 76 to 88) at the time of final
                        follow-up. All Wagner Cone stems that were not revised showed radiographic evidence of
                        osseointegration, with a mean stem subsidence of 0.9 ± 0.8 mm at the most recent follow-up.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The use of the Wagner Cone Prosthesis stem in
                        patients with complex femoral anatomy undergoing primary THA is associated with excellent
                        component survivorship, high levels of patient satisfaction, good functional outcomes, and
                        reliable osseointegration with minimal stem subsidence as seen on radiographs at
                        intermediate-term follow-up.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level IV</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Total hip arthroplasty (THA) is an effective and
                  cost-efficient surgical treatment for symptomatic end-stage hip arthritis<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. The procedure is commonly undertaken either using a
                  cementless flat-wedge taper and fit-and-fill-type stem or using a cemented stem, with well-established
                  success in terms of functional outcomes and component survivorship at long-term follow-up. However,
                  some patients undergoing THA have atypical proximal femoral morphology; their outcomes after THA are
                  less assured, and the atypical morphology is associated with an increased risk of complications<a
                    href="" data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">3</span></a>.
                  These morphological femoral abnormalities include poor metaphyseal bone quality, a narrow femoral
                  canal diameter, abnormal femoral neck version, excessive femoral neck varus/valgus angulation, and
                  reduced horizontal offset<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">7</span></a>. In these patients, morphological mismatch between the
                  native femoral anatomy and the femoral stem or the inability to achieve a satisfactory cement mantle
                  precludes the use of standard femoral implants<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">6</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The Wagner Cone Prosthesis femoral stem (Zimmer) is a
                  monoblock conical prosthesis composed of a titanium alloy with a corundum (a crystalline form of
                  aluminum oxide)-treated surface. It is a short diaphyseal-engaging stem with a 5° taper that achieves
                  taper-lock axial stability<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">8</span></a>. The
                  circular cross-section of the cone allows unrestricted rotation to dial in the preferred anteversion
                  and correct for any proximal torsional abnormalities<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">8</span></a>. The
                  longitudinal flutes around the circumference of the stem increase contact pressures between the stem
                  and endosteal bone, conferring rotational stability and promoting osseointegration. In addition, the
                  smallest stem’s outer diameter is 13 mm, as measured at 80 mm from the distal stem tip, to fit very
                  narrow canals without the need for cementation, while the straight stem design allows stem height
                  adjustment to facilitate limb-length equalization<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">9</span></a>. The
                  offset options include 135° and 125° femoral neck angles to help accommodate the reduced femoral
                  offset encountered in many congenital and acquired hip abnormalities<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">10</span></a>. Studies have shown that use of the Wagner Cone Prosthesis
                  stem in THA is associated with excellent component survival rates and a low risk of complications at
                  short-term follow-up<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">23</span></a>. However, the longer-term component survivorship and
                  clinical outcomes remain unknown.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The primary objective of this study was to establish the
                  mid-term survivorship of the Wagner Cone Prosthesis stem in patients with complex femoral anatomy
                  treated with THA. The secondary objectives were to determine patient satisfaction, functional
                  outcomes, radiographic evidence of osseointegration, component subsidence, and complications in these
                  patients at mid-term follow-up. The study hypotheses were that the Wagner Cone Prosthesis stem would
                  provide robust fixation with excellent implant survivorship, good functional outcomes, and a low risk
                  of complications at intermediate-term follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">This study included all patients who underwent primary THA
                  using the Wagner Cone Prosthesis for abnormal proximal femoral morphology at our tertiary referral
                  center between July 2007 and February 2018 (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). All
                  operative procedures were undertaken by 1 of 4 surgeons specializing in complex primary and revision
                  arthroplasty. Inclusion criteria for this study were an age between 18 and 90 years, primary THA, and
                  use of the Wagner Cone Prosthesis for abnormal proximal femoral anatomy (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 1-A</a> through <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">3-B</a>). The
                  decision to use the Wagner Cone stem was based on preoperative templating that suggested standard
                  proximally flared metaphyseal-loading stems or cemented stems were not suitable due to implant-bone
                  size mismatch, extreme femoral torsion with excessive anteversion or femoral neck angulation, reduced
                  femoral offset, or the inability to achieve a satisfactory cement mantle for a small cemented implant.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Baseline Demographics of the Study Population
                          Undergoing Total Hip Arthroplasty Using the Wagner Cone Stem (N = 320 THAs in 302
                          Patients)<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1057px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_31" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49.4 ±
                          14.5 (18.8 to 85.6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">179
                          (59.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">123
                          (40.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Laterality</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">147
                          (45.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">173
                          (54.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Body mass index <span
                            data-id="emphasis_32" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_33" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_10" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_10"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_34"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.1 ± 7.2
                          (16.4 to 63.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ASA score</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> I</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59
                          (19.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> II</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">190
                          (62.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53
                          (17.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> IV</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Underlying
                          diagnosis</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Developmental dysplasia
                          of the hip</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">207
                          (64.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Legg-Calvé-Perthes
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43
                          (13.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Neuromuscular condition
                          (cerebral palsy, Down syndrome, childhood brain injury, and poliomyelitis)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (6.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Childhood trauma
                          including septic arthritis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (7.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Multiple epiphyseal
                          dysplasia or spondyloepiphyseal dysplasia</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (1.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Revision of hip
                          arthrodesis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (2.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Juvenile rheumatoid
                          arthritis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (3.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Osteopetrosis</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.3%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">ASA = American Society of Anesthesiologists. Continuous data are given
                        as the mean (range) and standard deviation with the range in parentheses. Categorical data are
                        given as the count and percentage.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_38"
                            class="annotation strong" style="position: unset;">Figs. 1-A and 1-B</span> Preoperative
                          (<span data-id="strong_39" class="annotation strong" style="position: unset;">Fig. 1-A</span>)
                          and postoperative (<span data-id="strong_40" class="annotation strong"
                            style="position: unset;">Fig. 1-B</span>) anteroposterior radiographs of a patient with
                          bilateral developmental dysplasia of the hip treated with bilateral THA with Wagner Cone
                          stems.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-A</div>
                    <div class="image-download" name="JBJS.23.00849f1a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7&type=jpeg&name=JBJS.23.00849f1a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-B</div>
                    <div class="image-download" name="JBJS.23.00849f1b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7&type=jpeg&name=JBJS.23.00849f1b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_4" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_17" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_44"
                            class="annotation strong" style="position: unset;">Figs. 2-A and 2-B</span> Preoperative
                          (<span data-id="strong_45" class="annotation strong" style="position: unset;">Fig. 2-A</span>)
                          and postoperative (<span data-id="strong_46" class="annotation strong"
                            style="position: unset;">Fig. 2-B</span>) anteroposterior radiographs of a patient with
                          previous bilateral trochanteric rotational osteotomy for spondyloepiphyseal dysplasia who
                          subsequently underwent a left THA with the Wagner Cone stem.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-A</div>
                    <div class="image-download" name="JBJS.23.00849f2a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7&type=jpeg&name=JBJS.23.00849f2a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-B</div>
                    <div class="image-download" name="JBJS.23.00849f2b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7&type=jpeg&name=JBJS.23.00849f2b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_50"
                            class="annotation strong" style="position: unset;">Figs. 3-A and 3-B</span> Preoperative
                          (<span data-id="strong_51" class="annotation strong" style="position: unset;">Fig. 3-A</span>)
                          and postoperative (<span data-id="strong_52" class="annotation strong"
                            style="position: unset;">Fig. 3-B</span>) anteroposterior radiographs of a patient with a
                          previous right hip arthrodesis who subsequently underwent trochanteric osteotomy and THA with
                          the Wagner Cone stem.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-A</div>
                    <div class="image-download" name="JBJS.23.00849f3a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7&type=jpeg&name=JBJS.23.00849f3a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-B</div>
                    <div class="image-download" name="JBJS.23.00849f3b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7&type=jpeg&name=JBJS.23.00849f3b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_12" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">In total, 341 patients met the inclusion criteria for this
                  study. Of these, 28 died from unrelated conditions during the follow-up period and another 11 could
                  not be contacted. The outcomes of the remaining 302 patients (320 THAs) were recorded and included in
                  the analysis (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Tables I</a> and <a
                    href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">II</a>). The operative technique, including the surgical approach, bone
                  preparation, and implantation of the Wagner Cone stem, are described in Appendix 1. The study was
                  approved by the institutional review boards of the university and hospital. Informed consent was
                  obtained from all study patients. The mean clinical follow-up time (and standard deviation) following
                  the THA with the Wagner Cone stem was 10.1 ± 2.4 years (range, 5.2 to 15.5 years).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_14" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Implant Data for the Study Population Undergoing
                          Total Hip Arthroplasty Using the Wagner Cone Stem (N = 320)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1310px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No.
                          (%)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Wagner Cone stem
                          diameter</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 13 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (6.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 14 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (8.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 15 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (13.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 16 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62
                          (19.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 17 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52
                          (16.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 18 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43
                          (13.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 19 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (7.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 20 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (5.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 21 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (4.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 22 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (2.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 23 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (1.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 24 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (1.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Wagner Cone neck
                          angle</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 125°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">200
                          (62.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 135°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">120
                          (37.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Femoral head
                          diameter</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 28 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49
                          (15.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 32 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">216
                          (67.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 36 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51
                          (15.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 40 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (0.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 44 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Acetabular cup
                          diameter</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 44 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (3.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 46 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (2.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 48 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74
                          (23.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 50 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98
                          (30.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 52 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72
                          (22.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 54 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (6.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 56 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (5.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 58 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (4.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 60 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (0.6%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Data on the study patients were reviewed postoperatively
                  at 6 weeks, 1 year, and the time of final follow-up. At the most recent follow-up, which was done for
                  the purpose of this study, the following outcomes were recorded for all study patients: patient
                  satisfaction using a self-administered questionnaire assessing satisfaction in 4 domains, University
                  of California at Los Angeles (UCLA) activity score, Western Ontario and McMaster Universities
                  Osteoarthritis Index (WOMAC), Oxford Hip Score (OHS), and Forgotten Joint Score (FJS). Radiographic
                  assessment was performed by 2 independent observers (B.K. and M.B.), comparing radiographs immediately
                  after surgery and the most recent radiographs (see Appendix 2). The mean radiographic follow-up time
                  was 9.8 ± 2.1 years (range, 5.9 to 13.2 years). All radiographic outcomes were assessed for
                  interobserver agreement between the independent observers. Any complications and their associated
                  treatments were recorded during follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Kaplan-Meier survivorship was calculated using revision of
                  the Wagner Cone stem for any reason, revision of the stem due to aseptic loosening, and reoperation
                  for any reason as end points. Revision was defined as any operation for removal or replacement of the
                  Wagner Cone stem with the ongrowth surface, whereas reoperation was defined as any surgical procedure
                  affecting the THA prosthesis. To investigate the overall survivorship at the 10-year follow-up, an
                  unadjusted Cox regression model without competing events was used and a Kaplan-Meier curve was
                  generated. Based on the work by Lie et al., Cox regression and Kaplan-Meier curves without competing
                  events are preferable for reporting survivorship timelines in hip arthroplasty<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">24</span></a>. Normality of continuous variables was assessed by
                  observing the skewness, kurtosis, and boxplots as well as performing the Shapiro-Wilk and
                  Kolmogorov-Smirnov tests. If the distributional assumptions were violated, nonparametric tests were
                  employed. For comparisons among the 7 main diagnosis groups, analysis of variance (ANOVA) was used for
                  continuous variables with a normal distribution, the Kruskal-Wallis test was used for continuous
                  variables that did not have a normal distribution, and the Fisher exact test was used for categorical
                  variables. For comparisons between the 2 subsidence groups, the unpaired t test was used to compare
                  continuous variables that were normally distributed, the Mann-Whitney test was used for continuous
                  variables that were not normally distributed, and the Fisher exact test was used for categorical
                  variables. The paired t test was used to assess changes in leg-length discrepancy from before to after
                  surgery. All patient-reported outcome scores were presented as medians and interquartile ranges (IQRs)
                  for consistency. Interobserver agreement on radiographic outcomes was assessed with the interclass
                  correlation coefficient (ICC) for absolute agreement using the 2-way random effects model. The alpha
                  level for significance was set at 0.05. All analyses were conducted using SPSS version 25.0 (IBM).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Survivorship of the Wagner Cone Prosthesis was 98.7% (95%
                  confidence interval [CI]: 97.2% to 100%) with stem revision for any reason as the end point, 100% with
                  stem revision due to aseptic loosening as the end point (no stems were revised due to aseptic
                  loosening), and 95.8% (95% CI: 93.5% to 98.2%) with a reoperation for any reason as the end point at
                  10 years postoperatively (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 4</a> and <a
                    href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">5</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.00849f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7&type=jpeg&name=JBJS.23.00849f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_15" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;">Kaplan-Meier curve showing a survival rate of
                          98.7% (95% CI [shading]: 97.2% to 100%) with stem revision as the end point at 10 years
                          postoperatively.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.00849f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7&type=jpeg&name=JBJS.23.00849f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_16" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;">Kaplan-Meier curve showing a survival rate of
                          95.8% (95% CI [shading]: 93.5% to 98.2%) with a reoperation for any reason as the end point at
                          10 years postoperatively.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The Wagner Cone stem was revised in 3 patients (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>): 1 patient who underwent 2-stage revision for periprosthetic
                  joint infection at 3.6 years after the index procedure, 1 who developed extensive medical issues
                  including a chronically dislocated THA and underwent a Girdlestone procedure at 6.2 years after
                  surgery, and 1 who was actively abusing intravenous drugs, developed a chronic periprosthetic joint
                  infection with a draining sinus and extensive proximal femoral osteomyelitis, and underwent a
                  Girdlestone procedure at 8.6 years after surgery. In both patients with periprosthetic joint
                  infection, the Wagner Cone stem was grossly loose and was removed using a universal stem extractor
                  (Zimmer). In the patient with a chronic THA dislocation, the Wagner Cone stem was well-fixed.
                  Proximally introduced flexible osteotomes and a high-speed burr were used to disrupt the bone-implant
                  interface. The universal stem extractor was then used to remove the stem. None of these patients
                  required a femoral osteotomy to remove the stem. Another 9 patients required reoperations without
                  revision of the Wagner Cone stem (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_17" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Baseline and Outcome Data for All Reoperations (N =
                          12) After Total Hip Arthroplasty with the Wagner Cone Prosthesis<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 2097.16px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.765116);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Case</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Age at
                          Time of Surgery <span data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(yr)</span>, Sex</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Underlying
                          Diagnosis Group</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Complication</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Treatment</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Outcome</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">1</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">53, female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Revision of hip
                          arthrodesis</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Dislocation at 2 mo
                          postop.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reop. for insertion of
                          constrained polyethylene liner</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No additional
                          complications at 14.9-yr follow-up</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">46, female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Childhood trauma
                          including septic arthritis</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Dislocation at 2 mo
                          postop.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reop. to increase femoral
                          head offset</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No additional
                          complications at 12.5-yr follow-up</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">52, female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Developmental dysplasia
                          of the hip</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Pseudotumor formation at
                          4.9 yr postop.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reop. to change
                          metal-on-metal articulation to ceramic-on-polyethylene articulation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No additional
                          complications at 10.3-yr follow-up</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">4</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">58, female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Developmental dysplasia
                          of the hip</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic acetabular
                          loosening with periprosthetic acetabular fracture at 4 mo postop.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reop. to replace
                          acetabular component with Trabecular Metal (Zimmer Biomet) shell and segmental
                          allograft</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No additional
                          complications at 14.2-yr follow-up</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">5</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">45, male</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Developmental dysplasia
                          of the hip</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Periprosthetic joint
                          infection with extensive femoral osteomyelitis at 8.6 yr postop.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Girdlestone procedure (in
                          a patient with active IVDU, crack cocaine use, hepatitis B, liver cirrhosis, multiple lower
                          limb ulcers)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No additional
                          complications at 3.2-yr follow-up</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">62, female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Revision of hip
                          arthrodesis</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic acetabular
                          loosening with periprosthetic acetabular fracture at 3.2 yr postop.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reop. to replace
                          acetabular component with Trabecular Metal shell and segmental allograft</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No additional
                          complications at 10.3-yr follow-up</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">7</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">26, male</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Developmental dysplasia
                          of the hip</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Greater trochanter
                          abduction impingement at 1.9 yr postop.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reop. with trochanteric
                          osteotomy and advancement</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No additional
                          complications at 10.6-yr follow-up</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">62, female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Childhood trauma
                          including septic arthritis</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Periprosthetic joint
                          infection at 3.6 yr postop.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2-stage revision
                          procedure</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No additional
                          complications at 9.8-yr follow-up</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">9</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">58, female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Legg-Calvé Perthes
                          disease</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Chronic dislocation at
                          6.2 yr postop.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Girdlestone procedure (in
                          a patient with active IVDU, liver cirrhosis, severe peripheral vascular disease)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No additional
                          complications at 5.6-yr follow-up</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">10</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">48, male</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Childhood trauma
                          including septic arthritis</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Acute periprosthetic
                          joint infection at 1.2 yr postop.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Debridement, antibiotics.
                          and change of modular components</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No additional
                          complications at 9.2-yr follow-up</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">11</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">28, female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Developmental dysplasia
                          of the hip</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Dislocation at 3 mo
                          postop.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reop. to increase femoral
                          head offset</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No additional
                          complications at 9.1-yr follow-up</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">12</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">43, female</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Developmental dysplasia
                          of the hip</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Dislocation at 11 mo
                          postop.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reop. for insertion of
                          constrained polyethylene liner</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No additional
                          complications at 7.9-yr follow-up</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">IVDU = intravenous drug use.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">The median patient satisfaction score was 95 (IQR, 80 to
                  100; range, 60 to 100), median UCLA score was 6 (IQR, 6 to 7; range, 5 to 8), median WOMAC score was
                  18 (IQR, 16 to 22; range, 8 to 38), median OHS was 40 (IQR, 36 to 47; range, 20 to 48), and median FJS
                  was 80 (IQR, 76 to 88; range, 64 to 94) at the time of final follow-up. Comparison of functional
                  outcomes based on the underlying diagnosis revealed no difference among these groups in relation to
                  patient satisfaction (p = 0.33), UCLA score (p = 0.48), WOMAC (p = 0.42), OHS (p = 0.62), or FJS (p =
                  0.96) (<a href="" data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>). No patients with multiple epiphyseal dysplasia,
                  spondyloepiphyseal dysplasia or juvenile arthritis required revision surgery. Overall, there was no
                  significant difference in the need for revision surgery based on the underlying diagnosis (p = 0.06)
                  (<a href="" data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_18" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Comparison of Patient-Reported Outcome Scores
                          According to Underlying Diagnoses in Patients Treated with Total Hip Arthroplasty Using the
                          Wagner Cone Stem<span data-id="table_footnote_reference_9"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 523.646px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.52057);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Variable</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Developmental Hip Dysplasia (N = 207)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Legg-Calvé-Perthes Disease (N = 43)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Neuromuscular Condition (N = 22)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Childhood
                          Trauma Including Septic Arthritis (N = 24)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">MED or SED
                          (N = 6</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Revision
                          of Hip Arthrodesis (N = 7)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Juvenile
                          Rheumatoid Arthritis (N = 10)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">OHS</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45 [36,
                          47] (20-48)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46 [40,
                          48] (32-48)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45 [34,
                          48] (32-48)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45 [39,
                          46] (30-48)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48 [46,
                          48] (39-48)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39 [32,
                          41] (30-46)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36 [32,
                          45] (29-48)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.62<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">UCLA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6 [6, 7]
                          (5-8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6 [6, 6]
                          (5-8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6 [5, 7]
                          (5-8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6 [5, 6]
                          (5-7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7 [6, 7]
                          (5-7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6 [6, 7]
                          (5-7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6 [5, 7]
                          (5-8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.48<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">WOMAC</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18 [16,
                          22] (8-38)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20 [18,
                          22] (10-32)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20 [16,
                          25] (10-32)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20 [18,
                          28] (10-36)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17 [13,
                          18] (12-20)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18 [11,
                          25] (8-30)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20 [14,
                          22] (10-32)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.42<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">FJS</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82 [76,
                          88] (66-94)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80 [74,
                          86] (64-92)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80 [76,
                          88] (68-90)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80 [72,
                          88] (68-92)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81 [77,
                          84] (74-90)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82 [80,
                          84] (72-88)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80 [77,
                          82] (70-90)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.96<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Satisfaction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95 [88,
                          100] (60-100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100 [89,
                          100] (70-100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95 [90,
                          100] (70-100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96 [83,
                          100] (70-100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">91 [90,
                          100] (80-100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">85 [80,
                          90] (70-95)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">92 [80,
                          100] (65-100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.33<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Reoperation for any
                          reason</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (2.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (12.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (28.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.06<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the median [interquartile range] (range), or
                        number (percentage). MED or SED = multiple epiphyseal dysplasia or spondyloepiphyseal dysplasia.
                        OHS = Oxford Hip Score, UCLA = University of California at Los Angeles activity score, WOMAC =
                        Western Ontario and McMaster Universities Osteoarthritis Index, FJS = Forgotten Joint
                        Score.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Analysis using Kruskal-Wallis test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Analysis using ANOVA.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Analysis using Fisher exact test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">The mean ICC was 0.78 (range, 0.72 to 0.83) for the
                  radiographic analysis of all study patients. Nonprogressive radiolucent lines were visible in 12
                  patients in Gruen zones 1 and 2, 6 patients in Gruen zones 3 and 4, and 3 patients in Gruen zones 5,
                  6, and 7. These patients were asymptomatic and did not have any complications during the follow-up
                  period. All Wagner Cone stems that were not revised showed radiographic evidence of osseointegration.
                  The mean limb-length discrepancy improved from 11.6 ± 3.8 mm (range, 0.5 to 60.2 mm) preoperatively to
                  1.2 ± 0.8 mm (range, 0.1 mm to 12.1 mm) postoperatively (paired samples t test: p &lt; 0.001).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">The mean stem subsidence was 0.9 ± 0.8 mm (range, 0.0 to
                  6.7 mm) at the most recent follow-up. In total, 12 (3.8%) of the 320 THAs had stem subsidence of 5 to
                  10 mm, but none of these patients were symptomatic and the subsidence did not progress after the
                  initial 12 months following surgery. There were no significant differences in terms of patient
                  satisfaction (p = 0.20), UCLA score (p = 0.36), OHS (p = 0.86), or FJS (p = 0.50) in patients with
                  &lt;5 mm of stem subsidence compared with patients with 5 to 10 mm of stem subsidence (<a href=""
                    data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table V</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_19" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_40" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Comparison of Patient-Reported Outcome Scores Between
                          Stem-Subsidence Groups After Total Hip Arthroplasty Using the Wagner Cone Stem<span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 454px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Variable</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Subsidence
                          &lt;5 mm (N = 308)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Subsidence
                          5 -10 mm (N = 12)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">OHS</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40 [36,
                          47] (20-48)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40 [36,
                          48] (24-48)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.86<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">UCLA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6 [5, 7]
                          (5-8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6 [6, 7]
                          (5-8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.36<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">WOMAC</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18 [16,
                          23] (8-38)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18 [16,
                          22] (10-32)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.55<span
                            data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">FJS</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80 [78,
                          84] (64-94)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80 [76,
                          84] (68-90)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.50<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Satisfaction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95 [87,
                          100] (60-100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100 [90,
                          100] (70-100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.20<span
                            data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Reoperation for any
                          reason</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (3.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (8.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.35<span
                            data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the median [interquartile range] (range) or
                        number (percentage). OHS = Oxford Hip Score, UCLA = University of California at Los Angeles
                        activity score, WOMAC = Western Ontario and McMaster Universities Osteoarthritis Index, FJS =
                        Forgotten Joint Score.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Analysis using Mann-Whitney test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Analysis using unpaired t test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Analysis using Fisher exact test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">A nondisplaced intraoperative femoral fracture occurred
                  during bone preparation in 4 THAs (1.25%). All 4 patients had an underlying diagnosis of developmental
                  hip dysplasia. Three of these patients (0.94%) had required a 13-mm diameter stem and 1 (0.31%) had
                  required a 14-mm-diameter stem. The fractures were secured with cables, and these patients were
                  advised to walk with partial weight-bearing for 6 weeks following the surgery. There were no
                  additional complications in these patients and no other intraoperative complications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">This study found that use of the Wagner Cone Prothesis
                  stem for primary THA in patients with complex femoral anatomy was associated with excellent component
                  survivorship, high levels of patient satisfaction, good functional outcomes, and reliable
                  osseointegration with minimal stem subsidence seen on radiographs at intermediate-term follow-up. The
                  Wagner Cone Prothesis had a 10-year survivorship of almost 99% when stem revision was considered the
                  end point. These findings are comparable with the 95% to 100% survival rate reported in other studies
                  with smaller sample sizes over a wide range of follow-up<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_12" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">18</span></a>. Zhang et al. reviewed outcomes in 49 patients who
                  underwent THA with the Wagner Cone stem and reported that implant survivorship was 100% at a mean
                  4.0-year follow-up<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">15</span></a>.
                  Consistent with our study, they reported no evidence of aseptic loosening or clinically relevant
                  subsidence of the stem. Similarly, Gholson et al. reviewed outcomes in 302 patients with complex
                  femoral anatomy who underwent THA with the Wagner Cone stem and reported a component retention
                  survival rate of 98.7% and a reoperation rate of 4.2% at a mean follow-up of 3.2 years<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">18</span></a>. In combination with our findings, these studies suggest
                  that mid-term component survivorship of the Wagner Cone stem in primary THA is comparable with that of
                  primary THA done with the most commonly used metaphyseal-engaging flat-wedge taper stem designs<a
                    href="" data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">27</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">The use of the Wagner Cone stem for THA was associated
                  with high levels of patient satisfaction and good functional outcomes in our study, which are
                  consistent with previous studies using smaller patient groups and shorter-term follow-up<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">28</span></a>. Ors et al. reviewed outcomes in 91 patients with
                  developmental dysplasia of the hip treated with THA using the Wagner Cone stem combined with a
                  transverse subtrochanteric shortening osteotomy and augmentation of the osteotomy site with the
                  intercalary segment as a strut autograft<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">20</span></a>.
                  The authors reported that the Harris hip score improved from 33.7 ± 16.9 to 94.2 ± 6.9 points and the
                  WOMAC score improved from 63.4 ± 24 to 7.4 ± 12 points at a mean 8.4-year follow-up. The less
                  favorable WOMAC score in our study may be attributable to our patient group having a wide range of
                  underlying systemic skeletal and neuromuscular pathological conditions compared with studies that
                  recruited only patients with developmental dysplasia of the hip<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">22</span></a>. As a result, our study group may have had a greater
                  prevalence of patients with chronic abnormal muscle function and physiological deconditioning,
                  limiting their ability to achieve comparable overall WOMAC scores.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">Subsidence of the stem is a major concern when using any
                  conical diaphyseal-fitting implant. Specific concerns about the Wagner Cone Prosthesis include an
                  undersized stem leading to poor diaphyseal fixation, trial implants having fewer splines than the
                  definitive component, and the final stem often sitting slightly prouder than the trials. In our study,
                  the mean subsidence was 0.9 mm, with 3.8% of patients having subsidence of 5 to10 mm at the most
                  recent follow-up, but patient satisfaction scores and functional outcomes of these patients were
                  comparable with those in the overall study cohort. These findings are consistent with the
                  aforementioned study by Gholson et al., in which the mean subsidence was 1.1 mm, 5.6% of patients had
                  subsidence of &gt;5 mm, and all stems showed radiographic evidence of osseointegration<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">18</span></a>. Similar to our study, the authors reported that patients
                  with &gt;5 mm of subsidence had satisfaction rates and UCLA scores comparable to those in the overall
                  cohort.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">In combination with our study, these findings suggest that
                  the Wagner Cone stem has excellent osseous fixation at intermediate-term follow-up<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">18</span></a>. In particular, the conical stem may be forgiving to minor
                  subsidence by design, as this did not adversely impact functional outcomes or component survivorship.
                  Two other studies, including 1 trial assessing the initial 100 THAs performed with this prosthesis by
                  Wagner and Wagner, have also reported cases of minor stem subsidence with secondary stabilization,
                  which were clinically irrelevant and did not impact clinical outcomes or implant survivorship<a
                    href="" data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_21" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">29</span></a>.
                  This is an important difference from press-fit metaphyseal stems, the subsidence of which is
                  associated with stem loosening and an increased risk of revision surgery. Several other studies with
                  smaller sample sizes have reported excellent osseointegration and minimal subsidence seen on
                  radiographs at mid- and long-term follow-up<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">23</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">A main limitation of this study is that it is a
                  retrospective case series with no control group. Due to the simplicity of the surgical technique and
                  reliable outcomes achieved with the Wagner Cone stem, our institution does not routinely use modular
                  stems or patient-specific implants during THA for patients with abnormal proximal femoral anatomy, so
                  a comparative control group was not possible. Another limitation is that preoperative functional
                  measures (UCLA, WOMAC, and OHS) were not recorded for the study patients. Furthermore, subsidence was
                  assessed using radiographs referencing osseous landmarks, which are dependent on the position of the
                  limb and open to measurement errors. Assessing migration using serial radiographs with
                  radiostereometric analysis would provide more comprehensive and methodologically robust data on
                  subsidence over time or any early displacement with secondary osseointegration. Despite these
                  limitations, to our knowledge this is the largest case series to date on the use of Wagner Cone stems
                  for the treatment of abnormal femoral anatomy and provides longer-term data than the current body of
                  literature. The findings of this study provide important prognostic information that will assist with
                  component selection for THA in patients with abnormal proximal femoral anatomy and facilitate more
                  informed discussions with these patients about mid-term component survivorship, patient satisfaction,
                  functional outcomes, and complications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">The use of the Wagner Cone Prosthesis stem in primary THA
                  for patients with complex femoral anatomy is associated with excellent component survivorship, high
                  levels of patient satisfaction, good functional outcomes, and reliable osseointegration and minimal
                  stem subsidence as seen on radiographs at intermediate-term follow-up. The most common reasons for
                  revision surgery were instability requiring a change of the acetabular cup or to a more constrained
                  liner.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_2" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I50" target="_blank" data-id="link_3" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I50</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Learmonth ID</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Young C</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Rorabeck C</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">The operation of the century:
                  total hip replacement</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 2007 Oct 27;370(9597):1508-19.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20operation%20of%20the%20century%3A%20total%20hip%20replacement&as_occt=title&as_sauthors=%20%22ID%20Learmonth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Khanuja HS</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Vakil JJ</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Goddard MS</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Mont MA</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Cementless femoral fixation in
                  total hip arthroplasty</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2011 Mar 2;93(5):500-9.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1114677" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Salentiny Y</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Zwicky L</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Ochsner PE</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Clauss M</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Long-term survival of the
                  cemented Müller CDH stem: a minimum follow-up of 10 years</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Arch Orthop Trauma Surg.</span> 2018
                Oct;138(10):1471-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20survival%20of%20the%20cemented%20M%C3%BCller%20CDH%20stem%3A%20a%20minimum%20follow-up%20of%2010%20years&as_occt=title&as_sauthors=%20%22Y%20Salentiny%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Boisgard S</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Descamps S</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Bouillet B</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Complex primary total hip
                  arthroplasty</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Orthop Traumatol Surg Res.</span> 2013 Feb;99(1)(Suppl):S34-42.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Complex%20primary%20total%20hip%20arthroplasty&as_occt=title&as_sauthors=%20%22S%20Boisgard%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Engesæter LB</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Engesæter IØ</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Fenstad AM</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Havelin LI</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Kärrholm J</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Garellick G</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Pedersen AB</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Overgaard S</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Low revision rate after total
                  hip arthroplasty in patients with pediatric hip diseases</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Acta Orthop.</span> 2012
                Oct;83(5):436-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Low%20revision%20rate%20after%20total%20hip%20arthroplasty%20in%20patients%20with%20pediatric%20hip%20diseases&as_occt=title&as_sauthors=%20%22LB%20Enges%C3%A6ter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Rogers BA</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Garbedian S</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Kuchinad RA</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Backstein D</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Safir O</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Gross AE</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Total hip arthroplasty for adult
                  hip dysplasia</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2012 Oct 3;94(19):1809-21.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1155436" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Wang CK</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Cohen D</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Kay J</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Almasri M</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Simunovic N</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Cardenas-Nylander C</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Ranawat AS</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Ayeni OR</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">The Effect of Femoral and
                  Acetabular Version on Outcomes Following Hip Arthroscopy: A Systematic Review</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2022 Feb 2;104(3):271-83.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=3118234"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Wagner H</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Wagner M</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Cone prosthesis for the hip
                  joint</span>. <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Arch
                  Orthop Trauma Surg.</span> 2000;120(1-2):88-95.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cone%20prosthesis%20for%20the%20hip%20joint&as_occt=title&as_sauthors=%20%22H%20Wagner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Shahin M</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Massé V</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Belzile É</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Bédard L</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Angers M</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Vendittoli PA</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Midterm results of titanium
                  conical Wagner stem with challenging femoral anatomy: Survivorship and unique bone remodeling</span>.
                <span data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Orthop Traumatol Surg
                  Res.</span> 2023 Feb;109(1):103242.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Midterm%20results%20of%20titanium%20conical%20Wagner%20stem%20with%20challenging%20femoral%20anatomy%3A%20Survivorship%20and%20unique%20bone%20remodeling&as_occt=title&as_sauthors=%20%22M%20Shahin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Parry MC</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Vioreanu MH</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Garbuz DS</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Masri BA</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Duncan CP</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">The Wagner Cone Stem for the
                  Management of the Challenging Femur in Primary Hip Arthroplasty</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2016
                Aug;31(8):1767-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Wagner%20Cone%20Stem%20for%20the%20Management%20of%20the%20Challenging%20Femur%20in%20Primary%20Hip%20Arthroplasty&as_occt=title&as_sauthors=%20%22MC%20Parry%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Pak P</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> de Steiger R</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Cone femoral prosthesis for
                  osteoarthritis of the hip with femoral dysplasia</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">J Orthop Surg (Hong Kong).</span> 2008
                Aug;16(2):206-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cone%20femoral%20prosthesis%20for%20osteoarthritis%20of%20the%20hip%20with%20femoral%20dysplasia&as_occt=title&as_sauthors=%20%22P%20Pak%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Schuh A</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Schraml A</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Hohenberger G</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Long-term results of the Wagner
                  cone prosthesis</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Int Orthop.</span> 2009 Feb;33(1):53-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20results%20of%20the%20Wagner%20cone%20prosthesis&as_occt=title&as_sauthors=%20%22A%20Schuh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Faldini C</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Nanni M</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Leonetti D</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Miscione MT</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Acri F</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Giannini S</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Total hip arthroplasty in
                  developmental hip dysplasia using cementless tapered stem. Results after a minimum 10-year
                  follow-up</span>. <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Hip
                  Int.</span> 2011 Jul-Aug;21(4):415-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20hip%20arthroplasty%20in%20developmental%20hip%20dysplasia%20using%20cementless%20tapered%20stem.%20Results%20after%20a%20minimum%2010-year%20follow-up&as_occt=title&as_sauthors=%20%22C%20Faldini%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Claramunt RT</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Marqués F</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> León A</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Vilà G</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Mestre C</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Verdié LP</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Total hip replacement with an
                  uncemented Wagner cone stem for patients with congenital hip dysplasia</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2011
                Dec;35(12):1767-70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20hip%20replacement%20with%20an%20uncemented%20Wagner%20cone%20stem%20for%20patients%20with%20congenital%20hip%20dysplasia&as_occt=title&as_sauthors=%20%22RT%20Claramunt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Zhang Q</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Goodman SB</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Maloney WJ</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Huddleston JI 3rd</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Can a Conical Implant
                  Successfully Address Complex Anatomy in Primary THA? Radiographs and Hip Scores at Early
                  Followup</span>. <span data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 2016 Feb;474(2):459-64.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Can%20a%20Conical%20Implant%20Successfully%20Address%20Complex%20Anatomy%20in%20Primary%20THA%3F%20Radiographs%20and%20Hip%20Scores%20at%20Early%20Followup&as_occt=title&as_sauthors=%20%22Q%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Zhen P</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Liu J</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Lu H</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Chen H</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Li X</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Zhou S</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Developmental hip dysplasia
                  treated by total hip arthroplasty using a cementless Wagner cone stem in young adult patients with a
                  small physique</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">BMC Musculoskelet Disord.</span> 2017 May 15;18(1):192.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Developmental%20hip%20dysplasia%20treated%20by%20total%20hip%20arthroplasty%20using%20a%20cementless%20Wagner%20cone%20stem%20in%20young%20adult%20patients%20with%20a%20small%20physique&as_occt=title&as_sauthors=%20%22P%20Zhen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Grappiolo G</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Blaha JD</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Gruen TA</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Burastero G</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Spotorno L</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Primary total hip arthroplasty
                  using a grit-blasted, press-fit femoral prosthesis. Long-term results with survivorship
                  analysis</span>. <span data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Hip
                  Int.</span> 2002 Apr-Jun;12(2):55-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Primary%20total%20hip%20arthroplasty%20using%20a%20grit-blasted%2C%20press-fit%20femoral%20prosthesis.%20Long-term%20results%20with%20survivorship%20analysis&as_occt=title&as_sauthors=%20%22G%20Grappiolo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Gholson JJ</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Wallace SS</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Akram F</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Gonzalez A</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Kunze KN</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Levine BR</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Wagner Cone Midterm
                  Survivorship and Outcomes</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2020 Aug;35(8):2155-60.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Wagner%20Cone%20Midterm%20Survivorship%20and%20Outcomes&as_occt=title&as_sauthors=%20%22JJ%20Gholson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Park KS</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Jin SY</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Lim JH</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Yoon TR</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Long-term outcomes of
                  cementless femoral stem revision with the Wagner cone prosthesis</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">J Orthop Surg Res.</span> 2021 Jun
                11;16(1):375.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20outcomes%20of%20cementless%20femoral%20stem%20revision%20with%20the%20Wagner%20cone%20prosthesis&as_occt=title&as_sauthors=%20%22KS%20Park%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Ors C</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Caylak R</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Togrul E</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Total Hip Arthroplasty With the
                  Wagner Cone Femoral Stem in Patients With Crowe IV Developmental Dysplasia of the Hip: A Retrospective
                  Study</span>. <span data-id="emphasis_20" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2022 Jan;37(1):103-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20Hip%20Arthroplasty%20With%20the%20Wagner%20Cone%20Femoral%20Stem%20in%20Patients%20With%20Crowe%20IV%20Developmental%20Dysplasia%20of%20the%20Hip%3A%20A%20Retrospective%20Study&as_occt=title&as_sauthors=%20%22C%20Ors%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Solarino G</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Vicenti G</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Piazzolla A</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Maruccia F</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Notarnicola A</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Moretti B</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Total hip arthroplasty for
                  dysplastic coxarthrosis using a cementless Wagner Cone stem</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">J Orthop Traumatol.</span> 2021 Apr
                17;22(1):16.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20hip%20arthroplasty%20for%20dysplastic%20coxarthrosis%20using%20a%20cementless%20Wagner%20Cone%20stem&as_occt=title&as_sauthors=%20%22G%20Solarino%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Daud A</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Perlus R</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Anand A</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Safir OA</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Gross AE</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Kuzyk PR</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Mid-term outcomes of the Wagner
                  Cone Prosthesis™ stem for developmental dysplasia of the hip: minimum two year follow-up</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2022
                Aug;46(8):1733-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mid-term%20outcomes%20of%20the%20Wagner%20Cone%20Prosthesis%E2%84%A2%20stem%20for%20developmental%20dysplasia%20of%20the%20hip%3A%20minimum%20two%20year%20follow-up&as_occt=title&as_sauthors=%20%22A%20Daud%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> La Camera F</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Loppini M</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Della Rocca A</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> de Matteo V</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Grappiolo G</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Total Hip Arthroplasty With a
                  Monoblock Conical Stem in Dysplastic Hips: A 20-Year Follow-Up Study</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2020
                Nov;35(11):3242-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20Hip%20Arthroplasty%20With%20a%20Monoblock%20Conical%20Stem%20in%20Dysplastic%20Hips%3A%20A%2020-Year%20Follow-Up%20Study&as_occt=title&as_sauthors=%20%22F%20La%20Camera%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Lie SA</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Fenstad AM</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Lygre SHL</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Kroken G</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Dybvik E</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Gjertsen JE</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Hallan G</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Dale H</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Furnes O</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Kaplan-Meier and Cox Regression
                  Are Preferable for the Analysis of Time to Revision of Joint Arthroplasty: Thirty-One Years of
                  Follow-up for Cemented and Uncemented THAs Inserted From 1987 to 2000 in the Norwegian Arthroplasty
                  Register</span>. <span data-id="emphasis_24" class="annotation emphasis" style="position: unset;">JB
                  JS Open Access.</span> 2022 Feb 23;7(1):e21.00108.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Kaplan-Meier%20and%20Cox%20Regression%20Are%20Preferable%20for%20the%20Analysis%20of%20Time%20to%20Revision%20of%20Joint%20Arthroplasty%3A%20Thirty-One%20Years%20of%20Follow-up%20for%20Cemented%20and%20Uncemented%20THAs%20Inserted%20From%201987%20to%202000%20in%20the%20Norwegian%20Arthroplasty%20Register&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Casper DS</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Kim GK</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Restrepo C</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Parvizi J</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Rothman RH</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Primary total hip arthroplasty
                  with an uncemented femoral component five- to nine-year results</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2011
                Sep;26(6):838-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Primary%20total%20hip%20arthroplasty%20with%20an%20uncemented%20femoral%20component%20five-%20to%20nine-year%20results&as_occt=title&as_sauthors=%20%22DS%20Casper%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Hozack WJ</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Booth RE Jr</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Clinical and radiographic
                  results with the Trilock femoral component—a wedge-fit porous ingrowth stem design</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">Semin Arthroplasty.</span>
                1990 Jul;1(1):64-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20and%20radiographic%20results%20with%20the%20Trilock%20femoral%20component%E2%80%94a%20wedge-fit%20porous%20ingrowth%20stem%20design&as_occt=title&as_sauthors=%20%22WJ%20Hozack%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Purtill JJ</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Rothman RH</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Hozack WJ</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Sharkey PF</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Total hip arthroplasty using
                  two different cementless tapered stems</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2001 Dec;(393):121-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20hip%20arthroplasty%20using%20two%20different%20cementless%20tapered%20stems&as_occt=title&as_sauthors=%20%22JJ%20Purtill%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Pavone V</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Costarella L</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Privitera V</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Sessa G</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Bilateral total hip
                  arthroplasty in subjects with multiple epiphyseal dysplasia</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2009
                Sep;24(6):868-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bilateral%20total%20hip%20arthroplasty%20in%20subjects%20with%20multiple%20epiphyseal%20dysplasia&as_occt=title&as_sauthors=%20%22V%20Pavone%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Kim YY</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Kim BJ</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Ko HS</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Sung YB</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Kim SK</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Shim JC</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Total hip reconstruction in the
                  anatomically distorted hip. Cemented versus hybrid total hip arthroplasty</span>. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Arch Orthop Trauma
                  Surg.</span> 1998;117(1-2):8-14.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20hip%20reconstruction%20in%20the%20anatomically%20distorted%20hip.%20Cemented%20versus%20hybrid%20total%20hip%20arthroplasty&as_occt=title&as_sauthors=%20%22YY%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7&type=supplement&name=10449"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">August 21, 2024;
                      106 (16): 1461</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00849</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">May 30, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_30"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the Division
                            of Orthopaedic Surgery, University of British Columbia, Vancouver, British Columbia,
                            Canada</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_35"
                            class="annotation strong" style="position: unset;">A commentary by Cécile Batailler, MD,
                            PhD,</span> is linked to the online version of this article.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=df947fed-dbf5-44d6-b3f3-e8de4bf264a6&native=1"
                              class="article-title" style="position: unset;">Accurate Preoperative Digital Planning: The
                              First Mandatory Step to Success in Complex and Unusual Total Hip Arthroplasties<span
                                class="subtitle" style="position: unset;"><br style="position: unset;">Commentary on an
                                article by Babar Kayani, MBBS, BSc, FRCS(Tr&Orth), PhD, et al.: “The Wagner Cone
                                Stem for Atypical Femoral Anatomy in Total Hip Arthroplasty. A Report of 320 Cases with
                                Minimum 5-Year Follow-up”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Batailler,
                              Cécile</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(16):e34 | August 21, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7&type=pdf&name=JBJS.23.00849.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7&type=pdf&name=JBJS.23.00849.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_36"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this study. The <span data-id="strong_37" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I49"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I49</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=00e7d5f3-ced0-45a1-8bbe-2bb9b8910fd7&type=zip&name=JBJS.23.00849.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Babar Kayani, MBBS, BSc, FRCS(Tr&Orth),
                PhD<span data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:babar.kayani@gmail.com" class=""
                    style="position: unset;">babar.kayani@gmail.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6611-3989" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6611-3989</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Michael E. Neufeld, MD, FRCSC, MSc<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8609-6925" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8609-6925</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Maria Bautista, MD, MSc<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5225-0883" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5225-0883</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lisa C. Howard, MD, FRCSC, MHSc<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mohammed Abdelmalek, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4453-1714" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4453-1714</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nelson V. Greidanus, MD, FRCSC, MPH<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0008-9543-013X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0008-9543-013X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Bassam A. Masri, MD, FRCSC<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6362-2120" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6362-2120</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Donald S. Garbuz, MD, FRCSC, MHSc<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6257-6644" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6257-6644</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Division of Lower Limb Reconstruction & Oncology, Vancouver General
                Hospital, Vancouver, British Columbia, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Division of Orthopaedic Surgery, University of British Columbia, Vancouver,
                British Columbia, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 32573.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
