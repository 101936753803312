import type { Article } from "../../../../../types";

export const Article594: Article = {
	id: 594,
	rsuiteId: "3df78771-d6cb-4a61-8e6a-50d7eb632d63",
	type: "scientific article",
	title:
		"Corticosteroid Effects on Upper Esophageal Sphincter Function in Anterior Cervical Discectomy and Fusion",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=3df78771-d6cb-4a61-8e6a-50d7eb632d63&type=jpeg&name=JBJS.24.00084f1",
	subSpecialties: ["spine"],
	content: `< id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Corticosteroid Effects on Upper Esophageal Sphincter
                Function in Anterior Cervical Discectomy and Fusion</div>
              <div class="text subtitle" style="position: unset;">A Study Using High-Resolution Impedance Manometry
              </div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Chih-Jun Lai,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jo-Yu Chen,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 6 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Background:</span> The aim of our study was to explore the effect of
                        local corticosteroids on dysphagia in patients undergoing anterior cervical discectomy and
                        fusion (ACDF). To address a gap in the limited research on this topic, we utilized
                        high-resolution impedance manometry (HRIM) and the Eating Assessment Tool-10 (EAT-10)
                        questionnaire to assess the effects on key swallowing muscles, including the upper esophageal
                        sphincter (UES).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Methods:</span> We randomly assigned patients undergoing ACDF to
                        either the corticosteroid group or the saline solution group. Patients received 10 mg of local
                        triamcinolone or saline solution prevertebrally at the cervical spine level before wound
                        closure. Swallowing function preoperatively and at 1 month postoperatively were compared between
                        the groups with use of HRIM parameters (e.g., UES relaxation, UES opening extent, intrabolus
                        pressure, and pharyngeal contraction) and EAT-10 scores. Patients were also followed for
                        postoperative complications and mortality within 12 months after surgery.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_35" class="annotation strong"
                          style="position: unset;">Results:</span> Thirty patients completed the study. The median age
                        was 55 years in the corticosteroid group and 57 years in the saline group, and each group had 8
                        female patients. All participants were Taiwanese. We found no significant difference in median
                        preoperative UES relaxation pressure between the corticosteroid and saline solution groups (33.8
                        and 31.3 mm Hg, respectively; p = 0.54). Postoperatively, the corticosteroid group had
                        significantly lower median UES relaxation pressure than the saline solution group (24.5 versus
                        33.6 mm Hg; p = 0.01). Before and after surgery, all other HRIM parameters and EAT-10 scores
                        were similar between the groups, with the corticosteroid group demonstrating median pre- and
                        postoperative EAT-10 scores of 0 and 4, respectively, and the saline solution group
                        demonstrating scores of 2 and 3, respectively. There were no adverse events.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_36" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The findings suggest that local corticosteroids
                        may reduce UES relaxation pressure at 1 month after ACDF, potentially enhancing UES opening.
                        Further research is required to verify our findings.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_37" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level I</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Postoperative dysphagia is common after anterior cervical
                  discectomy and fusion (ACDF), with a prevalence ranging from 1.7% to 67%<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. Postoperative swallowing discomfort may result from upper
                  esophageal sphincter (UES) suspension, which allows access to the cervical spine but may cause
                  traction injury to the UES and adjacent swallowing muscles<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">4</span></a>. Under normal circumstances, the UES relaxes to open and to
                  allow food to enter the esophagus when needed<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">5</span></a>.
                  Typically, the UES relaxation pressure is &lt;15 mm Hg<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">6</span></a>.
                  However, it may increase if UES relaxation is impaired, potentially impeding the passage of food<a
                    href="" data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">7</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Several studies have evaluated the effect of
                  corticosteroids on reducing the severity of postoperative dysphagia<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">10</span></a>.
                  Research has shown that corticosteroids can effectively reduce the severity of postoperative dysphagia
                  without serious side effects, with evidence supporting their use during ACDF<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">11</span></a>.
                  However, these studies focused on the patients’ subjective experiences of dysphagia and considered
                  only patient-reported outcome measures<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">10</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">12</span></a>, which may have led to underestimation of dysphagia-related
                  dysmotility.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">High-resolution impedance manometry (HRIM) is a robust
                  tool for evaluating oropharyngeal swallowing, including UES relaxation pressure<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">14</span></a>. The
                  Eating Assessment Tool-10 (EAT-10) questionnaire is commonly utilized to assess subjective dysphagia
                  symptoms<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">15</span></a>. In
                  this study, we aimed to determine whether administering intraoperative local corticosteroids to
                  patients undergoing ACDF enhanced swallowing muscle recovery and lowered UES relaxation pressure. We
                  conducted HRIM and EAT-10 assessments preoperatively and at 1 month postoperatively to measure these
                  effects. We also monitored for occurrences of postoperative complications and mortality within 12
                  months after surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Objectives</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">We aimed to investigate whether patients receiving local
                  intraoperative corticosteroids experienced lower UES relaxation pressure 1 month after ACDF compared
                  with patients given normal saline solution.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Participants</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">This study was a prospective randomized trial. The
                  patients and analyst were blinded to the allocation process; additionally, the treating physician was
                  blinded to patient allocation during the assessment of swallowing. The study was conducted in
                  accordance with the ethical standards embodied in the 1964 Declaration of Helsinki, approved by our
                  institutional review board (202002052MIND), and registered at ClinicalTrials.gov (NCT04717713).
                  Written informed consent was obtained from all patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">All patients 20 to 80 years old who underwent ACDF were
                  considered for enrollment by the clinical trial manager (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>).
                  Patients were excluded from the study if they underwent revision procedures; were operated on via
                  anterior and posterior approaches; had trauma, infection, tumor, or any other medical
                  contraindications to local corticosteroid administration; or had coagulopathy.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00084f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=3df78771-d6cb-4a61-8e6a-50d7eb632d63&type=jpeg&name=JBJS.24.00084f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_17" style="position: unset;">
                        <div class="content" style="position: unset;">Flowchart of patient enrollment and analysis in
                          accordance with the Consolidated Standards Of Reporting Trials.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Technique</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">All ACDF procedures were performed on the right side of
                  the neck. The standard Smith-Robinson approach was utilized to access the anterior cervical spine. We
                  first made a 4-cm horizontal linear skin incision extending from the midline to the anterior border of
                  the right sternocleidomastoid muscle. We then dissected along the anterior border of the platysma
                  muscle and gently retracted the infrahyoid muscle and trachea medially. We did not transect or
                  sacrifice any muscle during the approach to the prevertebral space of the cervical spine. The medial
                  aspect of the longus colli muscle was dissected off the vertebral bodies bilaterally, and Koros
                  self-retaining retractors (Koros USA) were utilized to hold the tissues in place. We performed the
                  anulotomy, discectomy, and posterior osteophyte removal with use of a high-speed hand drill, curet,
                  and Kerrison rongeurs. The posterior longitudinal ligament was then resected in all cases.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">We used a PEEK (polyetheretherketone) interbody cage with
                  an artificial bone graft. Either local triamcinolone (10 mg/1 mL) or the equivalent volume of saline
                  solution was sprayed into the prevertebral space before wound closure. No other method was utilized,
                  nor was any other space-occupying material inserted into the prevertebral space. Intravenous
                  corticosteroids were not administered during the preoperative or perioperative periods. A surgical
                  drain was inserted anterior to the fusion site during surgery. Patients were discharged on the first
                  or second postoperative day.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Recruitment Process</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Participants were randomly assigned to the local
                  corticosteroid or saline solution groups after providing their informed consent. A computer
                  spreadsheet was utilized for the randomization process. The operating room nurse, who was informed of
                  the group allocations, prepared 10 mg of triamcinolone or the equivalent volume of saline solution
                  accordingly. This process was performed discreetly, keeping the anesthesiologist, trial manager, and
                  patients unaware of the treatment that was administered. Although the surgeon could identify the
                  substance administered on the basis of its color, this did not affect the procedure or delivery of
                  care because the injections occurred during wound closure. Patients underwent HRIM assessment
                  preoperatively and at 1 month postoperatively under the supervision of the clinical trial manager, who
                  was blinded to treatment allocation. All patients were followed for the occurrence of postoperative
                  complications, including any related complications and mortality, within 12 months after surgery in
                  order to ensure comprehensive postoperative care and data collection.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Equipment
                for HRIM</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">We performed the HRIM with use of a 10-Fr outer diameter
                  solid-state assembly with 36 circumferential pressure sensors spaced at 1-cm intervals and 12
                  impedance segments spaced at 2-cm intervals (Medical Measurement Systems). Patients fasted for at
                  least 6 hours before being intubated and undergoing the HRIM. During the HRIM, the catheter was
                  strategically positioned to span the entire pharyngoesophageal segment, ensuring precise and
                  comprehensive data collection (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>).
                  Patients sat upright while the Solar GI system (Medical Measurement Systems) recorded pressure and
                  impedance at 20 Hz.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00084f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=3df78771-d6cb-4a61-8e6a-50d7eb632d63&type=jpeg&name=JBJS.24.00084f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Images showing the high-resolution impedance
                          manometry (HRIM) catheter, plate, and fluid bolus in the body. “A” indicates the HRIM catheter
                          from the pharynx to the esophagus. “B” indicates the cervical plate in the retropharyngeal and
                          prevertebral spaces. “C” indicates the fluid bolus passing from the pharynx into the
                          esophagus.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">HRIM
                Protocol</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">After the HRIM catheter was inserted through the
                  pharyngoesophageal segment (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>), 5 mL of
                  normal saline solution bolus was administered at 20-second intervals with use of a syringe. Each
                  patient underwent 10 swallowing tests in the neutral head position.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Manometry
                Data Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The manometry data were analyzed as described in our
                  previous study<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">3</span></a>. The
                  data analysis was performed with use of MATLAB (The MathWorks). We compared 11 key HRIM metrics
                  between the groups: (1) UES 0.25-s integrated relaxation pressure (IRP; range, −4 to 15 mm Hg), (2)
                  UES basal pressure, (3) UES post-deglutitive peak pressure (UES PeakP), (4) UES open time, (5) UES
                  maximum admittance (UES Adm), (6) the velopharynx-to-tongue-base contractile index (VTI), (7)
                  hypopharyngeal mean peak pressure (hypopharyngeal PeakP), (8) hypopharyngeal intrabolus pressure 1 cm
                  above the UES (IBP), (9) hypopharyngeal distension contraction latency (DCL), (10) hypopharyngeal
                  bolus presence time (BPT), and (11) the swallow risk index (SRI), calculated with use of the following
                  formula: SRI = (IBP × BPT)/([DCL + 1] × hypopharyngeal PeakP) × 100<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_12" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">6</span></a>.
                  These parameters were essential for understanding the swallowing mechanics and comparing the groups
                  with respect to their physiological responses.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                Monitoring</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">We utilized lateral radiography to perform the
                  radiographic measurements. Patients were scheduled to undergo radiography preoperatively, on
                  postoperative day 1, and at postoperative months 1, 3, and 12. We utilized this lateral radiography to
                  capture measurements of the anterior and posterior diameters of the anterior soft tissue at the C3 and
                  C6 levels in order to assess prevertebral soft-tissue swelling<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">16</span></a>. Using a picture archiving and communication system (EBM
                  Technologies), we determined the horizontal distance from the midsection of the anterior cortex of
                  each vertebral body to the point where the airway shadow was visible. This procedure was performed by
                  2 technicians who were unaware of the research protocol and grouping. In addition, we utilized the
                  Cobb angle (between the end plates of C2 and C7) to assess regional alignment. We did not use computed
                  tomography for fusion evaluation, but we did measure and compare segmental height reduction between
                  the groups. When the height reduction exceeded 3 mm, it was classified as subsidence<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">17</span></a>. Plate prominence was also measured by 2 technicians<a
                    href="" data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">18</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Sample Size
                Calculation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">We hypothesized that the corticosteroid group would have a
                  lower IRP than the saline solution group, with expected 1-month postoperative mean IRP values (and
                  standard deviations) of 20 ± 9 and 30 ± 8 mm Hg, respectively. To achieve a statistical power of
                  &gt;0.8 with an alpha of 0.05 and a 1:1 group ratio, we needed 13 patients per group. Factoring in a
                  10% dropout rate, we recruited 15 patients per group, for a total of 30 patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">The data for the local corticosteroid and saline solution
                  groups were compared with use of an appropriate method for the type of variable. Categorical variables
                  are presented as counts and percentages and were analyzed with use of the Pearson chi-square or Fisher
                  exact test. Continuous variables are presented as medians and interquartile ranges (IQRs) and were
                  analyzed with use of the Mann-Whitney U test. Significance was set at p &lt; 0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_27" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">A total of 37 patients were assessed for eligibility;
                  however, 2 patients were excluded because they did not meet the inclusion criteria and 5 declined to
                  participate (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). Thus, a
                  total of 30 participants were enrolled. Preoperatively, the patients had no abnormalities of the jaw,
                  tongue, soft palate, pharynx, or esophagus; they had an intact oral mucosa and the ability to
                  self-feed without needing specially minced soft foods. None of the patients had a medical history of
                  stroke, other neurological diseases, or hip fracture. The patients were randomly assigned to either
                  the corticosteroid group or the saline solution (control) group (<a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). The number of surgical levels was similar between the
                  corticosteroid and saline solution groups (p = 0.90), with both groups having a median value of 2
                  (IQR: 1, 3). The baseline demographic characteristics of the patients were similar between the groups
                  (<a href="" data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). The median age of the corticosteroid group was 55 years,
                  whereas the median age of the saline group was 57 years. There were 8 female patients in each group.
                  All participants were Taiwanese, as identified by the clinical trial manager from the electronic
                  health records.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Patient Demographics, Surgical Procedures, and
                          Outcomes in the Corticosteroid and Saline Solution Groups<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1654px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Variable</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Corticosteroid (N = 15)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Saline
                          Solution (N = 15)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55 (40,
                          70)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57 (50,
                          65)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.65</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex <span
                            data-id="emphasis_35" class="annotation emphasis" style="position: unset;">(no. [%] of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.00</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (53.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (53.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (46.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (46.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Body weight <span
                            data-id="emphasis_36" class="annotation emphasis"
                            style="position: unset;">(kg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67 (54,
                          91)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62 (58,
                          66)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.84</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Body height <span
                            data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(cm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">159.0
                          (151.0, 168.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">157.5
                          (154.0, 167.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.46</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI <span
                            data-id="emphasis_38" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_39" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_16" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_16"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_40"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.9
                          (23.4, 32.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.9
                          (23.1, 26.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.16</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Smoking status <span
                            data-id="emphasis_41" class="annotation emphasis" style="position: unset;">(no. [%] of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.00</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Current smoker</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (6.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (6.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Non-smoker</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (93.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (93.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Diabetes <span
                            data-id="emphasis_42" class="annotation emphasis" style="position: unset;">(no. [%] of
                            patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (20.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (20.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.00</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hypertension <span
                            data-id="emphasis_43" class="annotation emphasis" style="position: unset;">(no. [%] of
                            patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (60.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (46.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.46</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ASA grade <span
                            data-id="emphasis_44" class="annotation emphasis" style="position: unset;">(no. [%] of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.75</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> I</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (20.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (13.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> II</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (46.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (60.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (33.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (26.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgical levels <span
                            data-id="emphasis_45" class="annotation emphasis" style="position: unset;">(no. [%] of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.87</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1 level</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (26.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (33.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2 levels</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (46.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (40.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3 levels</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (20.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (13.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 4 levels</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (6.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (13.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Anterior plate(s) <span
                            data-id="emphasis_46" class="annotation emphasis" style="position: unset;">(no. [%] of
                            patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (46.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (40.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.71</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgical duration <span
                            data-id="emphasis_47" class="annotation emphasis"
                            style="position: unset;">(min)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">163 (106,
                          179)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">142 (101,
                          215)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.90</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hospitalization <span
                            data-id="emphasis_48" class="annotation emphasis"
                            style="position: unset;">(days)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3 (3,
                          4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3 (3,
                          5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.87</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Estimated blood loss
                          <span data-id="emphasis_49" class="annotation emphasis" style="position: unset;">(no. [%] of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.00</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;50 mL</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (93.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 100 mL</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (6.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are presented as the median, with the interquartile range in
                        parentheses, or as the count, with the percentage in parentheses. BMI = body mass index, ASA =
                        American Society of Anesthesiologists.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">The median duration of hospitalization in both groups was
                  3 days (<a href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). No significant differences in total surgical duration or
                  estimated blood loss were found between the groups (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). All
                  drains were removed on postoperative day 1. There were no occurrences of readmission, reoperation,
                  postoperative intravenous corticosteroid administration, or morphine use. There were no wound-related
                  complications such as infections, delayed healing, hematomas, or seromas; implant loosening; or
                  mortality during the 12-month follow-up period postoperatively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">HRIM
                Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Preoperatively, no differences in UES parameters were
                  found between the groups (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).
                  However, at 1 month postoperatively, the median UES IRP in the corticosteroid group (24.5 mm Hg) was
                  notably reduced compared with the preoperative value and was significantly lower than that in the
                  saline solution group (33.6 mm Hg; p = 0.01; <a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                  There were no significant differences in the other UES-related metrics between the groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Preoperative Metrics of the Corticosteroid and
                          Saline Solution Groups<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1055px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Variable</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Corticosteroid (N = 15)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Saline
                          Solution (N = 15)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">UES parameter</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> UES IRP <span
                            data-id="emphasis_50" class="annotation emphasis" style="position: unset;">(mm
                            Hg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.8
                          (25.1, 39.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.3
                          (24.8, 37.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.54</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> UES basal pressure <span
                            data-id="emphasis_51" class="annotation emphasis" style="position: unset;">(mm
                            Hg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42.2
                          (38.5, 72.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">79.2
                          (47.8, 95.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.12</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> UES PeakP <span
                            data-id="emphasis_52" class="annotation emphasis" style="position: unset;">(mm
                            Hg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">215.8
                          (181.3, 296.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">228.4
                          (190.2, 329.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.51</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> UES open time <span
                            data-id="emphasis_53" class="annotation emphasis" style="position: unset;">(s)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5 (0.5,
                          0.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6 (0.5,
                          0.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.41</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> UES Adm <span
                            data-id="emphasis_54" class="annotation emphasis"
                            style="position: unset;">(ms)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.0 (3.6,
                          4.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.8 (3.6,
                          4.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.84</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pharyngeal
                          parameter</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> VTI <span
                            data-id="emphasis_55" class="annotation emphasis" style="position: unset;">(mm
                            Hg·s·cm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">333.4
                          (213.5, 522.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">365.9
                          (259.3, 508.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.46</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hypopharyngeal PeakP
                          <span data-id="emphasis_56" class="annotation emphasis" style="position: unset;">(mm
                            Hg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">109.4
                          (77.5, 137.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">129.8
                          (84.0, 252.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.33</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> IBP <span
                            data-id="emphasis_57" class="annotation emphasis" style="position: unset;">(mm
                            Hg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.9
                          (11.7, 36.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.5 (5.9,
                          23.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.19</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> DCL <span
                            data-id="emphasis_58" class="annotation emphasis"
                            style="position: unset;">(ms)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">393.5
                          (374.6, 437.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">398.6
                          (333.6, 495.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.87</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> BPT <span
                            data-id="emphasis_59" class="annotation emphasis" style="position: unset;">(s)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5 (0.45,
                          0.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5 (0.4,
                          0.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.23</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Global swallowing
                          function</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> SRI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.9 (2.6,
                          10.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9 (1.5,
                          5.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.16</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are presented as the median, with the interquartile range in
                        parentheses. UES = upper esophageal sphincter, UES IRP = UES 0.25-s integrated relaxation
                        pressure, UES PeakP = UES post-deglutitive peak pressure, UES Adm = UES maximum admittance, VTI
                        = velopharynx-to-tongue-base contractile index, hypopharyngeal PeakP = hypopharyngeal mean peak
                        pressure, IBP = hypopharyngeal intrabolus pressure at 1 cm above the UES, DCL = hypopharyngeal
                        distension contraction latency, BPT = hypopharyngeal bolus presence time, SRI = swallowing risk
                        index.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>One-Month Postoperative Metrics of the
                          Corticosteroid Group and Saline Solution Groups<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1026px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Variable</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Corticosteroid (N = 15)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Saline
                          Solution (N = 15)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">UES parameter</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> UES IRP <span
                            data-id="emphasis_60" class="annotation emphasis" style="position: unset;">(mm
                            Hg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.5
                          (15.1, 27.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.6
                          (25.1, 38.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> UES basal pressure <span
                            data-id="emphasis_61" class="annotation emphasis" style="position: unset;">(mm
                            Hg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47.8
                          (34.4, 78.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">85.7
                          (39.1, 110.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.09</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> UES PeakP <span
                            data-id="emphasis_62" class="annotation emphasis" style="position: unset;">(mm
                            Hg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">197.8
                          (151.9, 269.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">218.2
                          (185.5, 325.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.31</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> UES open time <span
                            data-id="emphasis_63" class="annotation emphasis" style="position: unset;">(s)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6 (0.5,
                          0.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6 (0.4,
                          0.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.51</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> UES Adm <span
                            data-id="emphasis_64" class="annotation emphasis"
                            style="position: unset;">(ms)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.1 (3.8,
                          4.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.1 (3.6,
                          4.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.97</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pharyngeal
                          parameter</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> VTI <span
                            data-id="emphasis_65" class="annotation emphasis" style="position: unset;">(mm
                            Hg·s·cm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">340.7
                          (238.9, 455.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">374.3
                          (250.6, 462.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.65</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hypopharyngeal PeakP
                          <span data-id="emphasis_66" class="annotation emphasis" style="position: unset;">(mm
                            Hg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">121.3
                          (98.3, 193.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">132.2
                          (95.1, 197.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.49</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> IBP <span
                            data-id="emphasis_67" class="annotation emphasis" style="position: unset;">(mm
                            Hg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.5 (0.2,
                          36.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.9 (8.5,
                          23.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.65</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> DCL <span
                            data-id="emphasis_68" class="annotation emphasis"
                            style="position: unset;">(ms)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">405.9
                          (365.6, 464.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">389.7
                          (34.3, 445.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.51</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> BPT <span
                            data-id="emphasis_69" class="annotation emphasis" style="position: unset;">(s)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5 (0.4,
                          0.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6 (0.4,
                          0.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.74</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Global swallowing
                          function</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> SRI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.82 (0.0,
                          10.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.8 (1.9,
                          7.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.97</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are presented as the median, with the interquartile range in
                        parentheses. UES = upper esophageal sphincter, UES IRP = UES 0.25-s integrated relaxation
                        pressure, UES PeakP = UES post-deglutitive peak pressure, UES Adm = UES maximum admittance, VTI
                        = velopharynx-to-tongue-base contractile index, hypopharyngeal PeakP = hypopharyngeal mean peak
                        pressure, IBP = hypopharyngeal intrabolus pressure at 1 cm above the UES, DCL = hypopharyngeal
                        distension contraction latency, BPT = hypopharyngeal bolus presence time, SRI = swallowing risk
                        index.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">No intergroup differences were observed in the VTI,
                  hypopharyngeal parameters (including the hypopharyngeal PeakP, DCL, and BPT), or SRI at any time point
                  (<a href="" data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Tables II</a> and <a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">III</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The diameter of the prevertebral soft tissue at C3 and
                  that at C6 were significantly smaller in the corticosteroid group than in the saline solution group at
                  1 and 3 months postoperatively (see Appendix I). The values of prevertebral soft-tissue swelling did
                  not significantly differ between the groups at the other time points. Correlations between
                  prevertebral soft-tissue swelling and UES IRP and between prevertebral soft-tissue swelling and the
                  EAT-10 are shown in Appendix II. Correlations between plate prominence and UES IRP and between plate
                  prominence and the EAT-10 are shown in Appendix III. The correlation values shown in Appendices II and
                  III were not significant.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">There were no significant differences between the groups
                  with respect to regional sagittal alignment measurement preoperatively and at 1 day and 1, 3, and 12
                  months postoperatively (see Appendix I). Similarly, no significant differences in the subsidence rates
                  at 1, 3, and 12 months postoperatively were found between the groups. No patients had ankylosing
                  spondylitis or diffuse idiopathic skeletal hyperostosis affecting anterior osteophyte formation<a
                    href="" data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">19</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">EAT-10
                Scores</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Preoperative and 1-month postoperative EAT-10 scores did
                  not significantly differ between the groups (<a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>EAT-10 Scores in the Corticosteroid and Saline
                          Solution Groups<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 267px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">EAT-10 Score</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Corticosteroid (N = 15)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Saline
                          Solution (N = 15)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Before surgery</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0 (0,
                          2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2 (1,
                          4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.08</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">1 month after
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4 (2,
                          9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3 (1,
                          6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.31</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are presented as the median, with the interquartile range in
                        parentheses. EAT-10 = Eating Assessment Tool-10.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_29" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">At 30 days after ACDF, the patients who received local
                  corticosteroids had significantly lower IRP values in the UES than those who received normal saline
                  solution. However, all other postoperative UES-related parameters, as well as the VTI,
                  hypopharynx-related parameters, SRI, and EAT-10 scores, did not significantly differ between the 2
                  groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Our study revealed that the local administration of
                  corticosteroids may be effective in reducing IRP in the UES. Several factors may contribute to such a
                  reduction. For example, reducing local edema and inhibiting the synthesis of intercellular collagen
                  and proteoglycan may reduce the local inflammatory response<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">20</span></a>.
                  Another potential factor is the local administration of corticosteroids to the cricopharyngeal muscle.
                  This muscle plays a crucial role in the high-pressure zone of the UES, so reducing the inflammation of
                  that muscle may also reduce the pressure profile within the UES<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">7</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Our findings align with previous meta-analyses
                  demonstrating that local or intravenous perioperative steroid administration reduced dysphagia after
                  ACDF<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_20" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">22</span></a>. However, relying on questionnaires, as was done in the
                  aforementioned studies, may underestimate dysphagia-related dysmotility. The present study enhances
                  understanding by precisely evaluating the effect of corticosteroids on UES dysmotility following ACDF.
                  Preoperatively, the UES IRP of each group exceeded the normal range of &lt;15 mm Hg<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">6</span></a>, suggesting that patients with cervical disorders may have
                  elevated UES IRP<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">3</span></a>.
                  However, preoperative UES IRP data for these conditions are scarce. In our previous study<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">3</span></a>, patients undergoing ACDF without local corticosteroid
                  administration demonstrated no significant changes in UES IRP from the preoperative time point to
                  postoperative day 7. Similarly, the control group in the present study showed no significant changes
                  in UES IRP from the preoperative time point to 1 month postoperatively (see Appendix IV). Because of
                  the discomfort associated with HRIM tests, patients were rarely willing to undergo multiple tests. To
                  adjust for this, we changed our protocol to assess swallowing function 1 month after surgery. Taking
                  the findings of the 2 studies together, our results indicate that, without local corticosteroid
                  administration, there was no significant change in UES IRP from before the surgery to 7 days or 1
                  month postoperatively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Some disparity was observed in the postoperative median
                  UES basal pressure between the corticosteroid and saline solution groups, but it did not reach
                  significance. The reasons for this finding may be the wide range of UES basal pressure that is
                  considered normal (29 to 125 mm Hg)<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_24" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">23</span></a> and random errors owing to the small sample size. We
                  further examined whether the change in UES basal pressure from the preoperative to postoperative time
                  points differed between the groups, but the difference was not significant (see Appendix V).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">We found no significant differences in preoperative or
                  1-month postoperative EAT-10 scores between the 2 groups. Subjective questionnaires may not fully
                  represent changes in swallowing function<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">24</span></a>. We
                  therefore propose that HRIM may be more promising than the EAT-10 for assessing postoperative changes
                  following ACDF, particularly for detecting substantial alterations in UES function.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">Excessive blood loss and the placement of an anterior
                  plate have been shown to be risk factors for dysphagia<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">26</span></a>. However, no significant intergroup differences were found
                  for these variables in the present study, probably because of the small sample size. We did not
                  explore the risk factors of omohyoid muscle destruction and tracheal intubation duration<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">29</span></a> because all of our patients had intact omohyoid muscles as
                  well as a short duration of tracheal intubation as a result of the short duration of the surgery.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">Of the 30 patients in this study, 13 had anterior plate
                  placements. In patients undergoing single-level ACDF with a stand-alone cage, plates were seldom
                  utilized because of the acceptable fusion rate. In instances of multilevel ACDF, plating was an
                  option. The screw and plate systems were covered by our national insurance, and the decision to use
                  plating was determined through shared decision-making.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">The strength of this study is that it is, to our
                  knowledge, the first randomized controlled trial to utilize HRIM, which assesses aspiration risk, to
                  investigate the effect of local corticosteroid application on UES function following ACDF. HRIM may
                  outperform videofluoroscopy, the utility of which is often hindered by poor UES visibility due to
                  shoulder shadowing. Nevertheless, our study does have some limitations. First, the follow-up period
                  was only 1 month. To enhance our understanding of long-term changes in the pressure-flow parameters of
                  patients following surgery, future studies could consider extending the follow-up duration to 6 months
                  or even 1 year. Second, our patients were enrolled solely from a single tertiary medical center, which
                  may restrict the generalizability of our findings. Third, we theorized that local corticosteroid
                  administration may affect prevertebral soft-tissue swelling, potentially leading to a reduction in UES
                  relaxation pressure; this outcome warrants further investigation. Additionally, research findings on
                  such correlations are variable, and UES IRP values vary widely among different patient groups<a
                    href="" data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_28" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">30</span></a><span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">31</span></a>. Furthermore, the extent to which a decrease in UES IRP
                  improves swallowing function remains uncertain<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_29" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">32</span></a>. More extensive studies with larger sample sizes are needed
                  to understand the correlation between UES IRP values and dysphagia, as well as that between UES IRP
                  and prevertebral soft-tissue swelling. Fourth, the force and duration of retraction during surgery may
                  affect postoperative swallowing function but was not directly measured in our study. However, it can
                  reasonably be assumed that the duration was consistent between the groups given the randomized
                  controlled study design. Additionally, the uniformity in surgical technique is reinforced by the fact
                  that all procedures were performed by the same surgeon.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_28" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">Local intraoperative corticosteroid injections can reduce
                  UES relaxation pressure at 1 month after ACDF, although this finding requires further investigation.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_30" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_4" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I246" target="_blank" data-id="link_5" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I246</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Epstein NE</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">A review of complication rates
                  for anterior cervical diskectomy and fusion (ACDF)</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Surg Neurol Int.</span> 2019 Jun
                7;10:100.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20review%20of%20complication%20rates%20for%20anterior%20cervical%20diskectomy%20and%20fusion%20(ACDF)&as_occt=title&as_sauthors=%20%22NE%20Epstein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Leonard R</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Belafsky P</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Dysphagia following cervical
                  spine surgery with anterior instrumentation: evidence from fluoroscopic swallow studies</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2011 Dec 1;36(25):2217-23.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dysphagia%20following%20cervical%20spine%20surgery%20with%20anterior%20instrumentation%3A%20evidence%20from%20fluoroscopic%20swallow%20studies&as_occt=title&as_sauthors=%20%22R%20Leonard%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Lai CJ</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Cheng YJ</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Lai DM</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Wu CY</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Chang WT</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Tsuang FY</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Applying High-Resolution
                  Impedance Manometry for Detecting Swallowing Change in Anterior Cervical Spine Surgery
                  Patients</span>. <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Front
                  Surg.</span> 2022 Mar 16;9:851126.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Applying%20High-Resolution%20Impedance%20Manometry%20for%20Detecting%20Swallowing%20Change%20in%20Anterior%20Cervical%20Spine%20Surgery%20Patients&as_occt=title&as_sauthors=%20%22CJ%20Lai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Winslow CP</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Winslow TJ</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Wax MK</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Dysphonia and dysphagia
                  following the anterior approach to the cervical spine</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Arch Otolaryngol Head Neck Surg.</span> 2001
                Jan;127(1):51-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dysphonia%20and%20dysphagia%20following%20the%20anterior%20approach%20to%20the%20cervical%20spine&as_occt=title&as_sauthors=%20%22CP%20Winslow%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Sivarao DV</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Goyal RK</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Functional anatomy and
                  physiology of the upper esophageal sphincter</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Am J Med.</span> 2000 Mar
                6;108(4):27S-37S.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Functional%20anatomy%20and%20physiology%20of%20the%20upper%20esophageal%20sphincter&as_occt=title&as_sauthors=%20%22DV%20Sivarao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Cock C</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Omari T</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Diagnosis of Swallowing
                  Disorders: How We Interpret Pharyngeal Manometry</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">Curr Gastroenterol Rep.</span> 2017
                Mar;19(3):11.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagnosis%20of%20Swallowing%20Disorders%3A%20How%20We%20Interpret%20Pharyngeal%20Manometry&as_occt=title&as_sauthors=%20%22C%20Cock%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Omari T</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Cock C</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Wu P</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Szczesniak MM</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Schar M</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Tack J</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Rommel N</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Using high resolution manometry
                  impedance to diagnose upper esophageal sphincter and pharyngeal motor disorders</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Neurogastroenterol
                  Motil.</span> 2023 Jan;35(1):e14461.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Using%20high%20resolution%20manometry%20impedance%20to%20diagnose%20upper%20esophageal%20sphincter%20and%20pharyngeal%20motor%20disorders&as_occt=title&as_sauthors=%20%22T%20Omari%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Zhang X</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Yang Y</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Shen YW</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Zhang KR</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Ma LT</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Liu H</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Effect of perioperative steroids
                  application on dysphagia, fusion rate, and visual analogue scale (VAS) following anterior cervical
                  spine surgery: A meta-analysis of 14 randomized controlled trials (RCTs)</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Front Surg.</span> 2022 Nov
                1;9:1040166.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20perioperative%20steroids%20application%20on%20dysphagia%2C%20fusion%20rate%2C%20and%20visual%20analogue%20scale%20(VAS)%20following%20anterior%20cervical%20spine%20surgery%3A%20A%20meta-analysis%20of%2014%20randomized%20controlled%20trials%20(RCTs)&as_occt=title&as_sauthors=%20%22X%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Zadegan SA</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Jazayeri SB</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Abedi A</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Bonaki HN</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Vaccaro AR</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Rahimi-Movaghar V</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Corticosteroid Administration to
                  Prevent Complications of Anterior Cervical Spine Fusion: A Systematic Review</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Global Spine J.</span> 2018
                May;8(3):286-302.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Corticosteroid%20Administration%20to%20Prevent%20Complications%20of%20Anterior%20Cervical%20Spine%20Fusion%3A%20A%20Systematic%20Review&as_occt=title&as_sauthors=%20%22SA%20Zadegan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Shen L</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Lu L</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Si C</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Yu D</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Zhen-Yong K</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Zhong-Liang D</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Zheng-Jian Y</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Impact of local steroid
                  application on dysphagia after anterior cervical spine surgery: a meta-analysis</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Arch Orthop Trauma
                  Surg.</span> 2023 Jun;143(6):3015-24.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impact%20of%20local%20steroid%20application%20on%20dysphagia%20after%20anterior%20cervical%20spine%20surgery%3A%20a%20meta-analysis&as_occt=title&as_sauthors=%20%22L%20Shen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Garcia S</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Schaffer NE</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Wallace N</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Butt BB</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Gagnier J</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Aleem IS</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Perioperative Corticosteroids
                  Reduce Dysphagia Severity Following Anterior Cervical Spinal Fusion: A Meta-Analysis of Randomized
                  Controlled Trials</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2021 May 5;103(9):821-8.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=2767603" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Jeyamohan SB</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Kenning TJ</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Petronis KA</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Feustel PJ</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Drazin D</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> DiRisio DJ</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Effect of steroid use in
                  anterior cervical discectomy and fusion: a randomized controlled trial</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J Neurosurg Spine.</span>
                2015 Aug;23(2):137-43.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20steroid%20use%20in%20anterior%20cervical%20discectomy%20and%20fusion%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22SB%20Jeyamohan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Ahuja NK</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Chan WW</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Assessing upper esophageal
                  sphincter function in clinical practice: a primer</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">Curr Gastroenterol Rep.</span> 2016
                Feb;18(2):7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Assessing%20upper%20esophageal%20sphincter%20function%20in%20clinical%20practice%3A%20a%20primer&as_occt=title&as_sauthors=%20%22NK%20Ahuja%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Wang YT</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Yazaki E</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Sifrim D</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">High-resolution Manometry:
                  Esophageal Disorders Not Addressed by the “Chicago Classification”</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">J Neurogastroenterol Motil.</span> 2012
                Oct;18(4):365-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High-resolution%20Manometry%3A%20Esophageal%20Disorders%20Not%20Addressed%20by%20the%20%E2%80%9CChicago%20Classification%E2%80%9D&as_occt=title&as_sauthors=%20%22YT%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Zhang PP</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Yuan Y</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Lu DZ</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Li TT</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Zhang H</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Wang HY</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Wang XW</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Diagnostic Accuracy of the
                  Eating Assessment Tool-10 (EAT-10) in Screening Dysphagia: A Systematic Review and
                  Meta-Analysis</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Dysphagia.</span> 2023 Feb;38(1):145-58.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagnostic%20Accuracy%20of%20the%20Eating%20Assessment%20Tool-10%20(EAT-10)%20in%20Screening%20Dysphagia%3A%20A%20Systematic%20Review%20and%20Meta-Analysis&as_occt=title&as_sauthors=%20%22PP%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Kim SW</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Jang C</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Yang MH</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Lee S</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Yoo JH</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Kwak YH</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Hwang JH</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">The natural course of
                  prevertebral soft tissue swelling after anterior cervical spine surgery: how long will it
                  last?</span><span data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Spine
                  J.</span> 2017 Sep;17(9):1297-309.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20natural%20course%20of%20prevertebral%20soft%20tissue%20swelling%20after%20anterior%20cervical%20spine%20surgery%3A%20how%20long%20will%20it%20last%3F&as_occt=title&as_sauthors=%20%22SW%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Obermueller T</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Wagner A</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Kogler L</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Joerger AK</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Lange N</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Lehmberg J</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Meyer B</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Shiban E</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Radiographic measurements of
                  cervical alignment, fusion and subsidence after ACDF surgery and their impact on clinical
                  outcome</span>. <span data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Acta
                  Neurochir (Wien).</span> 2020 Jan;162(1):89-99.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiographic%20measurements%20of%20cervical%20alignment%2C%20fusion%20and%20subsidence%20after%20ACDF%20surgery%20and%20their%20impact%20on%20clinical%20outcome&as_occt=title&as_sauthors=%20%22T%20Obermueller%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Chin KR</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Eiszner JR</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Adams SBJ Jr</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Role of plate thickness as a
                  cause of dysphagia after anterior cervical fusion</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2007 Nov
                1;32(23):2585-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Role%20of%20plate%20thickness%20as%20a%20cause%20of%20dysphagia%20after%20anterior%20cervical%20fusion&as_occt=title&as_sauthors=%20%22KR%20Chin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Smart RJ</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Ghali GE</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Dysphagia caused by diffuse
                  idiopathic skeletal hyperostosis</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">J Oral Maxillofac Surg.</span> 2016 Apr;74(4):764-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dysphagia%20caused%20by%20diffuse%20idiopathic%20skeletal%20hyperostosis&as_occt=title&as_sauthors=%20%22RJ%20Smart%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Lee SH</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Kim KT</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Suk KS</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Park KJ</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Oh KI</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Effect of retropharyngeal
                  steroid on prevertebral soft tissue swelling following anterior cervical discectomy and fusion: a
                  prospective, randomized study</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2011 Dec 15;36(26):2286-92.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20retropharyngeal%20steroid%20on%20prevertebral%20soft%20tissue%20swelling%20following%20anterior%20cervical%20discectomy%20and%20fusion%3A%20a%20prospective%2C%20randomized%20study&as_occt=title&as_sauthors=%20%22SH%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Yu H</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Dong H</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Ruan B</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Xu X</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Wang Y</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Intraoperative use of topical
                  retropharyngeal steroids for dysphagia after anterior cervical fusion: a systematic review and
                  meta-analysis</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Dis Markers.</span> 2021 Dec 31;2021:7115254.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intraoperative%20use%20of%20topical%20retropharyngeal%20steroids%20for%20dysphagia%20after%20anterior%20cervical%20fusion%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22H%20Yu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Song J</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Yi P</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Wang Y</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Gong L</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Sun Y</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Yang F</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Tang X</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Tan M</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">The retropharyngeal steroid use
                  during operation on the fusion rate and dysphagia after ACDF? A systematic review and
                  meta-analysis</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Eur Spine J.</span> 2022 Feb;31(2):288-300.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20retropharyngeal%20steroid%20use%20during%20operation%20on%20the%20fusion%20rate%20and%20dysphagia%20after%20ACDF%3F%20A%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22J%20Song%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Weijenborg PW</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Kessing BF</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Smout AJPM</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Bredenoord AJ</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Normal values for solid-state
                  esophageal high-resolution manometry in a European population; an overview of all current
                  metrics</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">Neurogastroenterol Motil.</span> 2014 May;26(5):654-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Normal%20values%20for%20solid-state%20esophageal%20high-resolution%20manometry%20in%20a%20European%20population%3B%20an%20overview%20of%20all%20current%20metrics&as_occt=title&as_sauthors=%20%22PW%20Weijenborg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Dewan K</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Clarke JO</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Kamal AN</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Nandwani M</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Starmer HM</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Patient Reported Outcomes and
                  Objective Swallowing Assessments in a Multidisciplinary Dysphagia Clinic</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Laryngoscope.</span> 2021
                May;131(5):1088-94.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20Reported%20Outcomes%20and%20Objective%20Swallowing%20Assessments%20in%20a%20Multidisciplinary%20Dysphagia%20Clinic&as_occt=title&as_sauthors=%20%22K%20Dewan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Palumbo MA</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Aidlen JP</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Daniels AH</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Thakur NA</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Caiati J</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Airway compromise due to wound
                  hematoma following anterior cervical spine surgery</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Open Orthop J.</span> 2012;6:108-13.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Airway%20compromise%20due%20to%20wound%20hematoma%20following%20anterior%20cervical%20spine%20surgery&as_occt=title&as_sauthors=%20%22MA%20Palumbo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Wang H</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Yu H</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Zhang N</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Xiang L</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Incidence, Risk Factors, and
                  Management of Postoperative Hematoma Following Anterior Cervical Decompression and Fusion for
                  Degenerative Cervical Diseases</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Neurospine.</span> 2023 Jun;20(2):525-35.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%2C%20Risk%20Factors%2C%20and%20Management%20of%20Postoperative%20Hematoma%20Following%20Anterior%20Cervical%20Decompression%20and%20Fusion%20for%20Degenerative%20Cervical%20Diseases&as_occt=title&as_sauthors=%20%22H%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Rassameehiran S</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Klomjit S</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Mankongpaisarnrung C</span>,
                <span data-id="annotation_126" class="annotation" style="position: unset;"> Rakvit A</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Postextubation
                  Dysphagia</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">Proc (Bayl Univ Med Cent).</span> 2015 Jan;28(1):18-20.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Postextubation%20Dysphagia&as_occt=title&as_sauthors=%20%22S%20Rassameehiran%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_127" class="annotation" style="position: unset;"> McIntyre M</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Chimunda T</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Koppa M</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Dalton N</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Reinders H</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Doeltgen S</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Risk Factors for Postextubation
                  Dysphagia: A Systematic Review and Meta-analysis</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">Laryngoscope.</span> 2022
                Feb;132(2):364-74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20Factors%20for%20Postextubation%20Dysphagia%3A%20A%20Systematic%20Review%20and%20Meta-analysis&as_occt=title&as_sauthors=%20%22M%20McIntyre%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_133" class="annotation" style="position: unset;"> van Esch BF</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Stegeman I</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Smit AL</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Comparison of laryngeal mask
                  airway vs tracheal intubation: a systematic review on airway complications</span>. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">J Clin Anesth.</span> 2017
                Feb;36:142-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20laryngeal%20mask%20airway%20vs%20tracheal%20intubation%3A%20a%20systematic%20review%20on%20airway%20complications&as_occt=title&as_sauthors=%20%22BF%20van%20Esch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Schar M</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Woods C</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Ooi EH</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Athanasiadis T</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Ferris L</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Szczesniak MM</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Cock C</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Omari T</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Pathophysiology of swallowing
                  following oropharyngeal surgery for obstructive sleep apnea syndrome</span>. <span
                  data-id="emphasis_30" class="annotation emphasis" style="position: unset;">Neurogastroenterol
                  Motil.</span> 2018 May;30(5):e13277.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pathophysiology%20of%20swallowing%20following%20oropharyngeal%20surgery%20for%20obstructive%20sleep%20apnea%20syndrome&as_occt=title&as_sauthors=%20%22M%20Schar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Omari TI</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Ciucci M</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Gozdzikowska K</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Hernández E</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Hutcheson K</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Jones C</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Maclean J</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Nativ-Zeltzer N</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Plowman E</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Rogus-Pulia N</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Rommel N</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> O’Rourke A</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">High-Resolution Pharyngeal
                  Manometry and Impedance: Protocols and Metrics-Recommendations of a High-Resolution Pharyngeal
                  Manometry International Working Group</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Dysphagia.</span> 2020 Apr;35(2):281-95.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High-Resolution%20Pharyngeal%20Manometry%20and%20Impedance%3A%20Protocols%20and%20Metrics-Recommendations%20of%20a%20High-Resolution%20Pharyngeal%20Manometry%20International%20Working%20Group&as_occt=title&as_sauthors=%20%22TI%20Omari%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Szczesniak MM</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Omari TI</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Lam TY</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Wong M</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Mok VCT</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Wu JCY</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Chiu PWY</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Yuen MTY</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Tsang RK</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Cock C</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Sung JJ</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Wu P</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Evaluation of oropharyngeal
                  deglutitive pressure dynamics in patients with Parkinson’s disease</span>. <span data-id="emphasis_32"
                  class="annotation emphasis" style="position: unset;">Am J Physiol Gastrointest Liver Physiol.</span>
                2022 Apr 1;322(4):G421-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20oropharyngeal%20deglutitive%20pressure%20dynamics%20in%20patients%20with%20Parkinson%E2%80%99s%20disease&as_occt=title&as_sauthors=%20%22MM%20Szczesniak%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;3df78771-d6cb-4a61-8e6a-50d7eb632d63&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=3df78771-d6cb-4a61-8e6a-50d7eb632d63&type=supplement&name=12499"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D3df78771-d6cb-4a61-8e6a-50d7eb632d63%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_31" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 4, 2024;
                      106 (23): 2241</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00084</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 23, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_33"
                            class="annotation emphasis" style="position: unset;">Investigation performed at National
                            Taiwan University Hospital, Taipei, Taiwan</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Author(s). Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the terms of the <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank"
                            data-id="link_1" class="link" style="position: unset;">Creative Commons Attribution-Non
                            Commercial-No Derivatives License 4.0</a> (CCBY-NC-ND), where it is permissible to download
                          and share the work provided it is properly cited. The work cannot be changed in any way or
                          used commercially without permission from the journal.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;3df78771-d6cb-4a61-8e6a-50d7eb632d63&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=3df78771-d6cb-4a61-8e6a-50d7eb632d63&type=pdf&name=JBJS.24.00084.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=3df78771-d6cb-4a61-8e6a-50d7eb632d63&type=pdf&name=JBJS.24.00084.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_38"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This study was funded
                          by National Taiwan University Hospital (110-32). The <span data-id="strong_39"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I247" target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I247</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;3df78771-d6cb-4a61-8e6a-50d7eb632d63&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=3df78771-d6cb-4a61-8e6a-50d7eb632d63&type=zip&name=JBJS.24.00084.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                        Sharing</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;">A <span data-id="strong_40"
                            class="annotation strong" style="position: unset;">data-sharing statement</span> is provided
                          with the online version of the article (<a href="http://links.lww.com/JBJS/I248"
                            target="_blank" data-id="link_3" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I248</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Sharing
                          PDF</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;3df78771-d6cb-4a61-8e6a-50d7eb632d63&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=3df78771-d6cb-4a61-8e6a-50d7eb632d63&type=pdf&name=JBJS.24.00084.dataavailability.pdf&subtype=dataavailability"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Chih-Jun Lai, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4975-7501" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4975-7501</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jo-Yu Chen, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7640-7737" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7640-7737</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jing-Rong Jhuang, MD, PhD<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0760-921X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0760-921X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ming-Yen Hsiao, MD, PhD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span><span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9062-1027" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9062-1027</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tyng-Guey Wang, MD, PhD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span><span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3298-4916" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3298-4916</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yu-Chang Yeh, MD, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_8" class="label annotation cross_reference">8</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Dar-Ming Lai, MD, PhD<span
                  data-id="affiliation_reference_9" class="label annotation cross_reference">9</span><span
                  data-id="affiliation_reference_10" class="label annotation cross_reference">10</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Fon-Yih Tsuang, MD, PhD<span
                  data-id="affiliation_reference_9" class="label annotation cross_reference">9</span><span
                  data-id="affiliation_reference_10" class="label annotation cross_reference">10</span><span
                  data-id="affiliation_reference_11" class="label annotation cross_reference">11</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:tsuangfy@gmail.com" class="" style="position: unset;">tsuangfy@gmail.com</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5797-1885" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5797-1885</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Institute of Epidemiology and Preventive Medicine, National Taiwan University,
                Taipei, Taiwan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Anesthesiology, National Taiwan University Hospital, Taipei,
                Taiwan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Medical Imaging, National Taiwan University Hospital, Taipei,
                Taiwan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Graduate Institute of Clinical Medicine, College of Medicine, National Taiwan
                University, Taipei, Taiwan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Institute of Statistical Science, Academia Sinica, Taipei, Taiwan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Department of Physical Medicine and Rehabilitation, College of Medicine,
                National Taiwan University, Taipei, Taiwan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Department of Physical Medicine and Rehabilitation, National Taiwan University
                Hospital, Taipei, Taiwan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">8</span><span class="text"
                style="position: unset;">Department of Anesthesiology, College of Medicine, National Taiwan University,
                Taipei, Taiwan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">9</span><span class="text"
                style="position: unset;">Department of Surgery, National Taiwan University Hospital, Taipei,
                Taiwan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">10</span><span class="text"
                style="position: unset;">Department of Surgery, College of Medicine, National Taiwan University, Taipei,
                Taiwan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">11</span><span class="text"
                style="position: unset;">Spine Tumor Center, National Taiwan University Hospital, Taipei, Taiwan</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 30246.1px; position: unset;"></div>
      </div>
    </div>
  </div>
  </div>`,
};
