import { useState } from "react";
/* ----------------- Globals --------------- */

/* ----------------- Hooks --------------- */
import { useMutation } from "@tanstack/react-query";

import { events } from "@constants/tracking";
import { mockedAvatars } from "@features/onboardings/constants";
import { trackCustomEvent } from "analytics";
import { router } from "expo-router";
import changeAvatar from "../mutations/ChangeAvatar";
/* ----------------- Queries & Mutations --------------- */
// import { fetchAvatars } from "../queries/FetchAvatars";

export const useAvatars = (
	handleClose?: () => void,
	callback?: (avatarUrl: string) => void,
) => {
	const avatars = mockedAvatars;

	const [selectedAvatarId, setSelectedAvatarId] = useState<string | undefined>(
		undefined,
	);

	// const { data: avatars, isLoading } = useQuery({
	// 	queryKey: [cacheKeys.avatars],
	// 	queryFn: async () => {
	// 		return await fetchAvatars();
	// 	},
	// 	staleTime: 1000 * 60 * 60 * 24 * 7,
	// });

	const { mutate: changeAvatarMutate, isPending: isUpdatingAvatar } =
		useMutation({
			// biome-ignore lint/suspicious/useAwait: <explanation>
			mutationFn: async (url: string) => {
				trackCustomEvent({
					eventName: events.avatarChanged,
				});
				changeAvatar(url);
				if (callback) {
					callback(url);
				} else if (handleClose) {
					handleClose();
				} else {
					router.back();
				}
			},
		});

	const handleUpdateAvatar = () => {
		// const avatar = avatars?.find((avatar) => avatar.id === selectedAvatarId);
		// if (avatar) {
		// 	changeAvatarMutate(avatar.url);
		// }
	};

	return {
		avatars,
		isLoading: false,
		isUpdatingAvatar,
		changeAvatarMutate,
		handleUpdateAvatar,
		selectedAvatarId,
		setSelectedAvatarId,
	};
};
