import { pageViewTypes } from "@constants/tracking";
import { emptyStates } from "@features/dashboard/constants";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import useAnalytics from "@hooks/useAnalytics";
// import OverallYourRankCard from "@memorang/ui/src/components/Cards/OverallYourRankCard";
/* -----------------Assets--------------- */
import { ProgressEmptySvg } from "@memorang/media";
/* ----------------- UI --------------- */
import { Box, EmptyState, Loader } from "@memorang/ui";
/* ----------------- Components --------------- */
import { ScrollContainer } from "@memorang/ui";
import AIInsightsBottomSheet from "@memorang/ui/src/components/AiInsightsBottomSheet";
import OverallYourStreakCard from "@memorang/ui/src/components/Cards/OverallStreakCard";
import OverallYourAchievementsCard from "@memorang/ui/src/components/Cards/OverallYourAchievementsCard";
import OverallYourGuessDistributionCard from "@memorang/ui/src/components/Cards/OverallYourGuessDistributionCard";
import OverallYourProgressCard from "@memorang/ui/src/components/Cards/OverallYourProgressCard";
import StrengthsAndWeaknessCard from "@memorang/ui/src/components/Cards/StrenghtsAndWeaknessCard";
import { router } from "expo-router";
import { useMemo, useState } from "react";
import { useInsights } from "../hooks/useInsights";

const ProgressView = () => {
	const eventsObject = useMemo(() => {
		return {
			type: pageViewTypes.progress,
		};
	}, []);
	useAnalytics(eventsObject);
	const {
		overallProgressData,
		// overallRankData,
		overallAchievementsData,
		overallGuessDistributionData,
		overallStreakData,
		strengthsAndWeaknessData,
		isLoading,
		showEmptyState,
	} = useInsights();

	const [showModal, setShowModal] = useState(false);

	const handleClickLearnMore = (_type: string) => {
		setShowModal(true);
	};

	if (showEmptyState) {
		return (
			<EmptyState
				{...emptyStates.insights}
				cta="START CHALLENGE"
				image={ProgressEmptySvg}
				handleAction={() => {
					router.push("/home");
				}}
			/>
		);
	}

	return (
		<>
			<ScrollContainer maxWidth="sm">
				{isLoading ? (
					<Box
						flexGrow={1}
						justifyContent="center"
						alignItems="center"
						height="100%"
					>
						<Loader />
					</Box>
				) : (
					<Box padding={16} gap={16}>
						<OverallYourProgressCard
							{...overallProgressData}
							handleClickLearnMore={handleClickLearnMore}
						/>
						{strengthsAndWeaknessData && (
							<StrengthsAndWeaknessCard {...strengthsAndWeaknessData} />
						)}
						{/* <OverallYourRankCard {...overallRankData} /> */}
						<OverallYourAchievementsCard {...overallAchievementsData} />
						<OverallYourGuessDistributionCard
							{...overallGuessDistributionData}
						/>
						<OverallYourStreakCard {...overallStreakData} />
					</Box>
				)}
			</ScrollContainer>
			{showModal && (
				<BottomSheetModalProvider>
					<AIInsightsBottomSheet
						showModal={showModal}
						toggleShowModal={() => setShowModal(false)}
					/>
				</BottomSheetModalProvider>
			)}
		</>
	);
};

export default ProgressView;
