/* ======== Globals ========= */

import { isWeb } from "@helpers/platform";
import { useAppStore } from "@hooks/useAppStore";
import { useDevice } from "@hooks/useDevice";
import { TooltipWrapper } from "@memorang/ui";
import { Image } from "@memorang/ui";
import type { ImageSource } from "expo-image";
import { useRouter } from "expo-router";
/* ======== UI ========= */
import { Pressable, View } from "react-native";
import { Appbar, type AppbarProps, Text, useTheme } from "react-native-paper";

type Props = {
	showLogo?: boolean;
	title?: string;
	onBackAction?: () => void;
	showProfileMenu?: boolean;
	disableBackAction?: boolean;
} & Omit<AppbarProps, "children">;
const OnboardingAppBar = ({
	showLogo,
	title,
	onBackAction,
	disableBackAction,
	...rest
}: Props) => {
	const theme = useTheme();

	const router = useRouter();

	const { isMobile } = useDevice();

	const {
		companyName,
		app: {
			logo: appLogo,
			links: { companyUrl },
		},
	} = useAppStore((store) => ({
		companyName: store.companyName,
		app: store.app,
	}));

	const getAppLogo = () => {
		const {
			default: { light, dark },
		} = appLogo;
		return theme.dark ? dark : light;
	};

	const navigateToPartnerSite = () => {
		window.open(companyUrl, "_blank");
	};
	const AppLogo = getAppLogo();
	const Logo = ({ image }: { image: ImageSource }) => {
		const handleLogoPress = () => {
			router.back();
		};

		return (
			<Pressable style={{ paddingLeft: 16 }} onPress={handleLogoPress}>
				<Image
					source={image}
					style={{
						width: 136,
						height: 38,
					}}
					contentFit="contain"
				/>
			</Pressable>
		);
	};
	// If back action exists, showBackAction = true, otherwise false
	const showBackAction = !!onBackAction;

	return (
		<Appbar.Header
			mode="center-aligned"
			{...rest}
			style={{
				position: "relative",
			}}
		>
			{showBackAction && (
				<Appbar.BackAction
					onPress={onBackAction}
					disabled={disableBackAction}
				/>
			)}
			{showLogo && <Logo image={AppLogo} />}
			<Appbar.Content title="" />

			<View
				style={{
					position: "absolute",
					width: "100%",
					height: "100%",
					justifyContent: "center",
					alignItems: "center",
					zIndex: -1,
				}}
			>
				<Text variant="titleLarge">{title}</Text>
			</View>
			{!isMobile && isWeb && (
				<TooltipWrapper title={`Go to ${companyName}.com`}>
					<Appbar.Action icon="open-in-new" onPress={navigateToPartnerSite} />
				</TooltipWrapper>
			)}
		</Appbar.Header>
	);
};

export default OnboardingAppBar;
