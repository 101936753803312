import { newEvents } from "@constants/tracking";
import {
	BottomSheetFooter,
	type BottomSheetFooterProps,
} from "@gorhom/bottom-sheet";
import { useAppTheme } from "@hooks/useAppTheme";
import { Box, MasteryListItem } from "@memorang/ui";
import { trackCustomEvent } from "analytics";
import CustomButton from "components/CustomButton";
import { useCallback, useEffect, useState } from "react";
import { Checkbox, List } from "react-native-paper";
import FilterBottomSheetWrapper from "./FilterBottomSheetWrapper";

type SpecialityFilter = {
	title: string;
	icon: string;
	description: string;
	isSelected: boolean;
};

type Props = {
	showModal: boolean;
	handleSheetClose: () => void;
	specialitiesFilter: SpecialityFilter[];
	applyFilters: (filters: SpecialityFilter[]) => void;
};

const FilterModalContainer = ({
	showModal,
	handleSheetClose,
	specialitiesFilter,
	applyFilters,
}: Props) => {
	useEffect(() => {
		if (showModal) {
			trackCustomEvent({
				eventName: newEvents.bottomSheetOpened,
				type: "filter",
				source: "challenges",
			});
		}
	}, [showModal]);
	const theme = useAppTheme();
	const [tempFilters, setTempFilters] =
		useState<SpecialityFilter[]>(specialitiesFilter);

	const handleToggleSpeciality = useCallback((speciality: string) => {
		setTempFilters((prev) =>
			prev.map((item) =>
				item.title === speciality
					? { ...item, isSelected: !item.isSelected }
					: item,
			),
		);
	}, []);

	const handleClearAll = useCallback(() => {
		setTempFilters((prev) =>
			prev.map((item) => ({ ...item, isSelected: false })),
		);
		handleSheetClose();
		applyFilters([]);
	}, [handleSheetClose, applyFilters]);

	const handleDone = useCallback(() => {
		applyFilters(tempFilters);
		handleSheetClose();
	}, [applyFilters, tempFilters, handleSheetClose]);

	const renderFooter = (p: BottomSheetFooterProps) => (
		<BottomSheetFooter
			{...p}
			style={{
				padding: 24,
				backgroundColor: theme.colors.surface,
			}}
		>
			<CustomButton mode="contained" onPress={handleDone}>
				Done
			</CustomButton>
		</BottomSheetFooter>
	);

	return (
		<FilterBottomSheetWrapper
			showModal={showModal}
			title="Filter by specialties"
			snapPoints={["50%", "80%"]}
			handleSheetClose={handleSheetClose}
			paddingBottom={124}
			renderFooter={renderFooter}
			handleClearAll={handleClearAll}
		>
			<Box justifyContent="space-between" flexGrow={1} height="100%">
				{tempFilters.map((item) => {
					return (
						<MasteryListItem
							key={item.title}
							title={item.title}
							iconUrl={item.icon}
							description={item.description}
							onPress={() => handleToggleSpeciality(item.title)}
							renderRight={(p) => {
								return (
									<List.Icon
										{...p}
										icon={() => {
											return (
												<Checkbox.Android
													status={item.isSelected ? "checked" : "unchecked"}
												/>
											);
										}}
									/>
								);
							}}
							distribution={{
								high: 0,
								medium: 0,
								low: 0,
								none: 0,
							}}
						/>
					);
				})}
			</Box>
		</FilterBottomSheetWrapper>
	);
};

export default FilterModalContainer;
