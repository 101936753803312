/* -----------------Globals--------------- */
import { useCallback } from "react";
import { Platform } from "react-native";

import { currentAppVersion } from "@helpers/expo-extras";
/* -----------------Helpers & Hooks--------------- */
import { trackCustomEvent } from "analytics";
import useSessionStore from "./useSessionStore";

/* -----------------Types--------------- */
import type { FeedbackParams } from "@memorang/features";

import { useUserProfilePrompt } from "@features/aila/useUserProfilePrompt";
import { useExamContext } from "@features/exam";
/* -----------------Contexts--------------- */
import { useAppStore } from "@hooks/useAppStore";
import useClerkUser from "@hooks/useClerkUser";
import useImageChallengeAppConfig from "@hooks/useImageChallengeAppConfig";
import { useAuthContext } from "@memorang/applets";

export const useQASearch = () => {
	const { newUserInfo } = useAuthContext();
	const { userPublicMetadataId: viewerId, email } = useClerkUser();
	const { collectionId } = useImageChallengeAppConfig();
	const {
		app: { name: appName },
		tenant: { name: tenantName },
	} = useAppStore((store) => ({
		app: store.app,
		tenant: store.tenant,
	}));
	const sessionId = useSessionStore((state) => state.id);
	const inReviewMode = useSessionStore((state) => state.inReviewMode);
	const isSummativeTest = useSessionStore((state) => state.isSummativeTest);
	const userProfilePrompt = useUserProfilePrompt();

	const {
		currentExamContext: {
			studyPackProductId,
			practiceTestProductId,
			examName,
			examId,
		},
	} = useExamContext();

	const handleTracking = useCallback(
		(eventName: string, data?: Record<string, string>) => {
			const viewForFormative = inReviewMode
				? "review_session_formative"
				: "session_formative";
			trackCustomEvent({
				eventName,
				...(data || {}),
				view: isSummativeTest ? "review_session_summative" : viewForFormative,
			});
		},
		[inReviewMode, isSummativeTest],
	);

	const type = practiceTestProductId ? "Practice Test" : "Study-Pack";
	const contextId = practiceTestProductId ?? studyPackProductId;

	const feedbackDialogParams: FeedbackParams = {
		userName: newUserInfo?.name!,
		email,
		tenantId: tenantName,
		appName,
		sessionId,
		platform: Platform.OS.toUpperCase(),
		currentAppVersion,
		view: isSummativeTest
			? "Summative Review Session"
			: "Formative Review Session",
		context: examName,
		product: `${examName} ${type}`,
		feature: "AI QA",
	};

	return {
		viewerId,
		examId: collectionId.toString() ?? examId,
		contextId,
		handleTracking,
		feedbackDialogParams,
		examName: examName || "JBJS Mobility",
		userProfilePrompt,
	};
};
