/* -----------------Globals--------------- */
import { useAppStore } from "@hooks/useAppStore";
import { createTheme } from "@mui/material/styles";

const muiTheme = (paletteType: boolean) => {
	const theme = useAppStore.getState().theme;
	const { light, dark } = theme.colors;
	return createTheme({
		typography: {
			fontFamily: ["Arial", "Times New Roman"].join(","),
		},
		palette: {
			mode: paletteType ? "dark" : "light",
			primary: {
				main: paletteType ? dark.primary : light.primary,
			},
			secondary: {
				main: paletteType ? dark.secondary : light.secondary,
			},
			background: {
				default: paletteType ? dark.background : light.background,
				paper: paletteType ? dark.surface : light.surface,
			},
		},
		components: {
			MuiDialog: {
				styleOverrides: {
					paper: {
						margin: 16,
						width: "calc(100% - 32px)",
					},
				},
			},
		},
	});
};

export default muiTheme;
