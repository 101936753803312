/* -----------------Globals--------------- */
import { useEffect } from "react";
import { StyleSheet } from "react-native";

/* -----------------UI--------------- */
import Animated, {
	useSharedValue,
	useAnimatedStyle,
	withRepeat,
	withTiming,
} from "react-native-reanimated";
import Box from "../Box";

/* -----------------Helpers & Hooks--------------- */
import { type AppTheme, useAppTheme } from "../../useAppTheme";

interface Props {
	size?: number;
}

const getStyles = (theme: AppTheme, size: number) =>
	StyleSheet.create({
		dot: {
			height: size,
			width: size,
			borderRadius: size / 2,
			backgroundColor: theme.colors.primary,
			margin: 5,
		},
	});

export const TypingIndicator = ({ size = 10 }: Props) => {
	const scale1 = useSharedValue(1);
	const scale2 = useSharedValue(1);
	const scale3 = useSharedValue(1);

	const theme = useAppTheme();
	const styles = getStyles(theme, size);

	const animatedStyle1 = useAnimatedStyle(() => {
		return {
			transform: [{ scale: scale1.value }],
		};
	});

	const animatedStyle2 = useAnimatedStyle(() => {
		return {
			transform: [{ scale: scale2.value }],
		};
	});

	const animatedStyle3 = useAnimatedStyle(() => {
		return {
			transform: [{ scale: scale3.value }],
		};
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	useEffect(() => {
		[scale1, scale2, scale3].forEach((scale, index) => {
			setTimeout(() => {
				scale.value = withRepeat(withTiming(0.5, { duration: 300 }), -1, true);
			}, 200 * index);
		});
	}, []);

	return (
		<Box flexDirection="row" justifyContent="center">
			<Animated.View style={[styles.dot, animatedStyle1]} />
			<Animated.View style={[styles.dot, animatedStyle2]} />
			<Animated.View style={[styles.dot, animatedStyle3]} />
		</Box>
	);
};
