/* ----------------- Globals --------------- */
import { SectionList } from "react-native";
import { List } from "react-native-paper";

/* ----------------- Components --------------- */
import { CustomListSubheader } from "@memorang/ui";

/* ----------------- Hooks --------------- */
import { useAppTheme } from "@hooks/useAppTheme";
import { ScrollContainer } from "@memorang/ui";
import { useRouter } from "expo-router";
import { useSubjectsStore } from "../hooks/useNotesStore";
import type { StudyGuideItem } from "../types";

type Section = {
	title: string;
	data: StudyGuideItem[];
	isMainCategory?: boolean;
};

type Props = {
	sections: Section[];
	isMainCategory?: boolean;
};
export const NotesCategorySectionList = ({
	sections,
	isMainCategory,
}: Props) => {
	const theme = useAppTheme();
	const router = useRouter();
	const { setNote } = useSubjectsStore();
	return (
		<ScrollContainer>
			<SectionList
				sections={sections}
				contentContainerStyle={{
					paddingTop: 16,
				}}
				renderSectionHeader={({ section }) => (
					<CustomListSubheader
						style={{
							backgroundColor: theme.colors.background,
						}}
						enableGutters={true}
					>
						{section.title}
					</CustomListSubheader>
				)}
				renderItem={(itemData) => {
					const {
						item,
						section: { title },
					} = itemData;
					return (
						<List.Item
							title={item.title}
							left={(props) => (
								<List.Icon
									{...props}
									icon={
										(item.childTags && item.childTags.length > 0) ||
										isMainCategory
											? "file-tree-outline"
											: "note-outline"
									}
								/>
							)}
							onPress={() => {
								if (item.items && item.items.length > 0) {
									setNote(item);
									router.push({
										pathname:
											"/(protected)/subject/[subjectId]/[title]/study-guide",
										params: {
											subjectId: item.id,
											title: title,
										},
									});
								} else {
									router.push({
										pathname: "/(protected)/subject/[subjectId]/[title]",
										params: {
											subjectId: item.id,
											title: item.title,
										},
									});
								}
							}}
						/>
					);
				}}
			/>
		</ScrollContainer>
	);
};
