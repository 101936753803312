import type { Article } from "../../../../../types";

export const Article622: Article = {
	id: 622,
	rsuiteId: "c2757a6c-bb29-4861-979f-178b054b8182",
	type: "commentary and perspective",
	title: "Value Lessons from Bottom-Up Cost Accounting",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/622.jpeg",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Value Lessons from Bottom-Up Cost Accounting</div>
              <div class="text subtitle" style="position: unset;">Commentary on an article by Stephen A. Doxey, DO, et
                al “Patient-Level Value Analysis in Total Hip Arthroplasty: Optimizing the Value of Care Delivery”</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Robert S.
                      Sterling, MD, FAOA, FAAOS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">The article by Doxey et al. provides readers with an
                  insightful report on measuring value in total hip arthroplasty (THA). The major finding that
                  patient-reported outcomes (PROs) did not correlate with costs presents the reader with challenges in,
                  and opportunities for, understanding and improving value in THA. The article provides meaningful
                  information and perspective for all hip replacement surgeons, especially as the value of care
                  continues to fall under the closer scrutiny of surgeons, administrators, and payers. However, the
                  findings must be understood in the context of the study design and limitations.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">Time-driven activity-based costing (TDABC) was first
                  described by Kaplan and Anderson as an alternative to traditional activity-based costing<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;">1</a>. TDABC has been found to more accurately reflect the actual cost of
                  an episode of care for numerous orthopaedic procedures. Akhavan et al. utilized TDABC to study primary
                  THA and total knee arthroplasty and concluded that “TDABC … can be used to identify
                  high-cost/high-variability processes that can be targeted for process/quality improvement.”<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;">2</a> Doxey et al. attempt to do just this by calculating the value
                  quotient—that is, the ratio of the 1-year change in the Hip disability and Osteoarthritis Outcome
                  Score, Joint Replacement (HOOS-JR) to the 1-year cost of an episode of care using TDABC—and comparing
                  it across 480 patients to identify the variables that have the largest impact on quality. Their
                  findings are largely consistent with prior studies; specifically, they reported that surgery at an
                  ambulatory surgery center (ASC) with discharge to home was associated with higher value than surgery
                  at a hospital with discharge to a specialized nursing facility and that implant costs were the single
                  largest driver of costs. The only surgical factor resulting in higher value was the use of
                  fixed-bearing components.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">Although the article provides an outstanding overview of
                  TDABC and value, scrutiny of the study reveals some of the gaps that still need to be filled in order
                  to fully understand value in THA. Patient-related factors such as sex and age were found to be
                  predictive of costs and changes in PRO scores; however, body mass index and smoking status were the
                  only alterable patient factors evaluated. A more comprehensive group of patient factors (e.g.,
                  preoperative anemia, renal insufficiency, preoperative anticoagulation therapy) should be studied for
                  a more complete understanding. Additionally, the exclusion of patients requiring readmission or
                  revision within 1 year provides an opportunity for study expansion and is critically important to
                  recognize when contextualizing the main findings. The true value of factors such as implant choice or
                  surgical approach cannot truly be understood with these exclusions because readmission and revision
                  costs are ultimately part of the true measure of the value of THA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">As we continue to focus more on providing high-value care
                  and are presented with both mandated and voluntary bundled payment programs, our understanding of
                  factors that can be leveraged to improve patient outcomes and to lower costs will improve. Ultimately,
                  the optimization of value for each individual patient will be determined by a unique set of fixed and
                  variable factors. As surgeons, we must continue to study and recognize those factors that can be
                  optimized, reduce variability in care, and provide the right care at the right time in the right place
                  for each patient in order to improve value. The study by Doxey et al. educates us and advances our
                  pursuit of value while leaving many questions still open for investigation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Kaplan RS</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Anderson SR</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Time-driven activity-based
                  costing</span>. <span data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Harv
                  Bus Rev.</span> 2004 Nov;82(11):131-8-1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Time-driven%20activity-based%20costing&as_occt=title&as_sauthors=%20%22RS%20Kaplan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Akhavan S</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Ward L</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Bozic KJ</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Time-driven Activity-based
                  Costing More Accurately Reflects Costs in Arthroplasty Surgery</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2016
                Jan;474(1):8-1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Time-driven%20Activity-based%20Costing%20More%20Accurately%20Reflects%20Costs%20in%20Arthroplasty%20Surgery&as_occt=title&as_sauthors=%20%22S%20Akhavan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dc2757a6c-bb29-4861-979f-178b054b8182%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Commentary and Perspective</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): e53</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00977</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">December 18, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=fa63ab2a-9883-431e-9242-a2dcc04d6825&native=1"
                              class="article-title" style="position: unset;">Patient-Level Value Analysis in Total Hip
                              Arthroplasty: Optimizing the Value of Care Delivery</a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Doxey,
                              Stephen A.; Huyke-Hernández, Fernando A.; Davidson, Samuel; Kleinsmith, Rebekah M.; Qian,
                              Lily J.; Krohn, Eric J.; Horst, Patrick K.; Bingham, Joshua S.; Cunningham, Brian P.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(24):2338 | October 15, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;c2757a6c-bb29-4861-979f-178b054b8182&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=c2757a6c-bb29-4861-979f-178b054b8182&type=pdf&name=JBJS.24.00977.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=c2757a6c-bb29-4861-979f-178b054b8182&type=pdf&name=JBJS.24.00977.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_3" class="annotation strong"
                            style="position: unset;">Disclosure:</span> No external funding was received for this work.
                          The <span data-id="strong_4" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> form is provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I239" target="_blank" data-id="link_1"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I239</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;c2757a6c-bb29-4861-979f-178b054b8182&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=c2757a6c-bb29-4861-979f-178b054b8182&type=zip&name=JBJS.24.00977.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Robert S. Sterling, MD, FAOA, FAAOS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:rsterling@mfa.gwu.edu" class=""
                    style="position: unset;">rsterling@mfa.gwu.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2963-3162" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2963-3162</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, George Washington University School of
                Medicine and Health Sciences, Washington, DC</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 4383.58px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
