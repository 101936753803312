/* ----------------- Library Imports --------------- */
import { TabScreen, Tabs, TabsProvider } from "react-native-paper-tabs";

/* ----------------- Custom Hook Imports --------------- */
import { useAppTheme } from "@hooks/useAppTheme";

interface TabData {
	label: string;
	content: React.ReactNode;
	badge?: string | number | boolean;
	icon?: string;
}
interface Props {
	tabs: TabData[];
	mode?: "scrollable" | "fixed";
	onChangeIndex?: (index: number) => void;
	backgroundColor?: string;
}
const CustomTabs = ({
	tabs,
	mode = "fixed",
	onChangeIndex,
	backgroundColor,
}: Props) => {
	const theme = useAppTheme();

	return (
		<TabsProvider onChangeIndex={onChangeIndex}>
			<Tabs
				style={{
					backgroundColor: backgroundColor ?? theme.colors.elevation.level3,
				}}
				mode={mode}
			>
				{tabs.map((item) => {
					return (
						<TabScreen
							badge={item.badge}
							key={item.label}
							label={item.label}
							icon={item.icon}
						>
							{item.content}
						</TabScreen>
					);
				})}
			</Tabs>
		</TabsProvider>
	);
};

export default CustomTabs;
