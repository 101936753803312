import { useThemeContext } from "@contexts/ThemeContext";
import { useAppTheme } from "@hooks/useAppTheme";
import { Switch } from "react-native-paper";

const DarkModeSwitch = () => {
	const theme = useAppTheme();
	const { isDarkMode, toggleDarkMode } = useThemeContext();

	return (
		<Switch
			value={isDarkMode}
			onValueChange={toggleDarkMode}
			color={theme.colors.secondary}
		/>
	);
};

export default DarkModeSwitch;
