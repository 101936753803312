import useSessionStore from "../hooks/useSessionStore";

import type { SessionChildItem } from "@memorang/types";
import { useLocalSearchParams } from "expo-router";

import { newEvents } from "@constants/tracking";
import { useGlobalStore } from "@hooks/useGlobalStore";
import { trackCustomEvent } from "analytics";
import { useCallback, useEffect } from "react";
import TryAgainBottomSheet from "../components/postanswer/TryAgainBottomSheet";
import { useHint } from "../hooks/useHint";

const TryAgainSheetContainer = () => {
	const { index } = useLocalSearchParams<{
		index: string;
	}>();

	const currentItemIndex = index ? Number.parseInt(index) - 1 : 0;

	const isSummativeTest = useSessionStore((state) => state.isSummativeTest);
	const currentBlockIndex = useSessionStore((state) => state.currentBlockIndex);
	const sessionItems = useSessionStore((state) => state.sessionItems);

	const currentSessionItems = isSummativeTest
		? sessionItems[currentBlockIndex].children
		: sessionItems;
	const currentItem = currentSessionItems[currentItemIndex] as SessionChildItem;
	const updateAttempts = useSessionStore((state) => state.updateItemAttempts);

	const hintEnabled = useGlobalStore(
		(state) => state.settingsEnabledFeatures.hint,
	);

	const attempts = useSessionStore((state) => state.attempts);
	const numTotalAttempts = currentItem.children?.length || 0;

	const currentAttempt = attempts.get(currentItem.id);

	const choiceAttempts = currentAttempt?.choiceAttempts;

	const keys = Object.keys(choiceAttempts || {});
	const numAttempts = keys.length;

	const lastAttemptChoiceId = keys[keys.length - 1];

	const numResponses = currentItem?.children?.find(
		(choice) => choice.id === lastAttemptChoiceId,
	)?.numAttempts;

	const lastAttemptChoiceCorrect =
		choiceAttempts?.[lastAttemptChoiceId]?.correct;

	const lastAttemptChoiceItem = currentItem.children?.find(
		(choice) => choice.id === lastAttemptChoiceId,
	);

	const { hint, loading, getHint } = useHint({
		item: currentItem,
		type: "choice" as const,
		selectedChoice: lastAttemptChoiceItem?.stem,
		selectedChoiceId: lastAttemptChoiceId,
	});

	useEffect(() => {
		if (
			lastAttemptChoiceItem &&
			lastAttemptChoiceCorrect === false &&
			hintEnabled
		) {
			getHint();
		}
	}, [lastAttemptChoiceItem, lastAttemptChoiceCorrect, getHint, hintEnabled]);

	const showTryAgain = currentAttempt?.showTryAgain;

	const handleToggleTryAgain = useCallback(() => {
		trackCustomEvent({
			eventName: newEvents.sessionItemTryAgainClicked,
			item_id: currentItem.id,
		});
		updateAttempts({
			itemId: currentItem.id,
			showTryAgain: false,
		});
	}, [currentItem.id, updateAttempts]);

	return (
		<TryAgainBottomSheet
			currentItemIndex={currentItemIndex}
			currentQuestionAnswered={Boolean(showTryAgain)}
			handleClickTryAgain={handleToggleTryAgain}
			numAttempts={numAttempts}
			numTotalAttempts={numTotalAttempts}
			hint={hint}
			loading={hintEnabled ? loading : false}
			hintEnabled={hintEnabled}
			numResponses={numResponses}
		/>
	);
};

export default TryAgainSheetContainer;
