import { useOnboardingStore } from "@features/onboardings/hooks/useOnboardingStore";

export const useUserProfilePrompt = () => {
	const { role, speciality, subRole, subSpecialities } = useOnboardingStore();

	if (!(role || speciality || subRole || subSpecialities)) {
		return "";
	}

	const sections: string[] = ["**About the User**"];

	if (role) {
		sections.push(`role: ${role}`);
	}

	if (speciality) {
		sections.push(`specialty: ${speciality}`);
	}

	if (subRole) {
		sections.push(`sub_role: ${subRole}`);
	}

	if (subSpecialities?.length) {
		sections.push(`sub_specialties: ${subSpecialities.join(", ")}`);
	}

	return sections.join("\n");
};
