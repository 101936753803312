/* ----------------- Third parties --------------- */
import { Dialog } from "react-native-paper";

/* ----------------- UI --------------- */
import { DialogWrapper } from "@memorang/ui";

import ToastProvider from "@contexts/ToastContext";
import { useAppTheme } from "@hooks/useAppTheme";
/* ----------------- Components --------------- */
import DeleteForm from "../DeleteForm";

type Props = {
	visible: boolean;
	handleDismiss: () => void;
	handleSendFeedback: () => void;
};
const DeleteConfirmationDialog = ({
	visible,
	handleDismiss,
	handleSendFeedback,
}: Props) => {
	const theme = useAppTheme();
	return (
		<DialogWrapper
			visible={visible}
			maxWidth={"xs"}
			handleClose={handleDismiss}
		>
			<Dialog.Icon color={theme.colors.error} icon="alert-outline" />
			<Dialog.Title
				style={{
					textAlign: "center",
				}}
			>
				Confirm delete account
			</Dialog.Title>
			<Dialog.Content>
				<ToastProvider>
					<DeleteForm
						handleDismiss={handleDismiss}
						handleSendFeedback={handleSendFeedback}
					/>
				</ToastProvider>
			</Dialog.Content>
		</DialogWrapper>
	);
};

export default DeleteConfirmationDialog;
