import type { Article } from "../../../../../types";

export const Article544: Article = {
	id: 544,
	rsuiteId: "dc6f4b6f-a9df-401b-8b3f-eb1fa03b4e40",
	type: "commentary and perspective",
	title:
		"The Microbial Road Trip: Are We Nearing the Golden Era of Orthopaedics?",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/544.png",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">The Microbial Road Trip: Are We Nearing the Golden Era of
                Orthopaedics?</div>
              <div class="text subtitle" style="position: unset;">Commentary on an article by Sunqi Nian, MM, et al.:
                “Landscape of the Lumbar Cartilaginous End Plate Microbiota and Metabolites in Patients with Modic
                Changes”</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jari Dahmen,
                      MD, BSc</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Gino M.M.J.
                      Kerkhoffs, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">Modic changes in the lumbar spine are magnetic resonance
                  imaging (MRI) signal changes in vertebral body end plates that were first described in 1988 by Modic
                  et al.<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  These changes are (inconsistently) associated with disabling episodes of lower back pain<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>. The recognition that the intervertebral disc may host
                  microbiota in both typical and pathological circumstances is relatively recent<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a>. Nian et al. aimed to build on these emerging concepts by
                  analyzing both the microbiota and metabolic profiles of discs obtained during lumbar spinal fusion,
                  and identifying differences between patients with and without preoperative MRI findings indicating the
                  presence of Modic changes. We salute the authors for their well-executed study providing novel
                  insights regarding how the compositions of the microbiome and metabolome are linked to Modic changes
                  by showing that both the microbiota and metabolites were significantly different between the groups
                  with and without such changes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">In addition to identifying 26 metabolites that were
                  significantly different between the 2 groups, Nian et al. noted that the unsaturated fatty acid
                  pathway was the main pathway that these metabolites were involved in. Furthermore, they found that
                  Mycobacterium, Clostridium, Blautia, and Bifidobacterium were the genera in the microbiota that
                  differed the most between the groups, and that the abundances of these organisms correlated with the
                  levels of the metabolites in a way that further supported the hypothesis that dysregulation of fatty
                  acid metabolism is involved in the pathogenesis of Modic changes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">However, it should be stated that the (future) clinical
                  relevance of the study remains unanswered in this study. Although the mechanistic and
                  pathophysiological insights are clearly described and can be considered valuable and relevant, the
                  translation of the findings of the study into the daily practice of clinicians seeing patients with
                  lower back pain associated with Modic changes warrants further study: How might the identification of
                  specific microbial signatures and metabolite alterations influence clinical decision-making in terms
                  of treatment selection, prognostication, and patient selection? How can we improve clinical outcomes
                  through the findings of this study? How can we put the important finding of the identification of
                  unsaturated fatty acid metabolism as a pivotal pathway that appears to underpin Modic changes into
                  perspective and into practice?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Building on such questions regarding clinical relevance,
                  future studies in this domain must aim to bridge the grand gap between bench and bedside. Future
                  studies could, for example, focus on redefining the diagnostic criteria for patients with Modic
                  change-related conditions or work toward developing a therapeutic antimicrobial strategy that is
                  individualized to each patient, based on genomic DNA samples obtained in the diagnostic stage.
                  Alternatively, future studies that could perhaps be as relevant and clinically important might focus
                  on extrapolating or expanding the findings and concepts behind this study to areas beyond the lower
                  back<a href="" data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a>. The highly interesting finding that dysregulation of fatty
                  acid metabolism contributes to the pathogenesis of Modic changes may serve as a very relevant and
                  compelling example that can potentially be extended to other joints having cartilage-related
                  disorders.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">Overall, this well-executed study may mark a paradigm
                  shift in our current understanding of Modic changes, shedding light on the landscape of the lumbar
                  cartilaginous end plate’s microbiota and metabolites. By unraveling an unexplored association between
                  the presence of Modic changes and the microbiota and metabolites of the lumbar cartilaginous end
                  plate, the study gives us novel usable information on the pathogenesis of Modic changes and can pave
                  the way for specifically targeted therapeutic interventions. Successful early preventive measures
                  could bring us clinicians better and cheaper options to treat our patients with lower back pain, and
                  potentially also our patients with other (potentially inflammation-related) orthopaedic diseases. The
                  golden orthopaedic area is yet to come but, with a better understanding of Modic changes, we are a
                  step further in the right direction.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Modic MT</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Steinberg PM</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Ross JS</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Masaryk TJ</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Carter JR</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Degenerative disk disease:
                  assessment of changes in vertebral body marrow with MR imaging</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Radiology.</span> 1988 Jan;166(1 Pt
                1):193-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Degenerative%20disk%20disease%3A%20assessment%20of%20changes%20in%20vertebral%20body%20marrow%20with%20MR%20imaging&as_occt=title&as_sauthors=%20%22MT%20Modic%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Herlin C</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Kjaer P</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Espeland A</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Skouen JS</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Leboeuf-Yde C</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Karppinen J</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Niinimäki J</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Sørensen JS</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Storheim K</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Jensen TS</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Modic changes-Their associations
                  with low back pain and activity limitation: A systematic literature review and meta-analysis</span>.
                <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">PLoS One.</span> 2018
                Aug 1;13(8):e0200677.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Modic%20changes-Their%20associations%20with%20low%20back%20pain%20and%20activity%20limitation%3A%20A%20systematic%20literature%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22C%20Herlin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Viswanathan VK</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Shetty AP</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Rajasekaran S</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Modic changes - An
                  evidence-based, narrative review on its patho-physiology, clinical significance and role in chronic
                  low back pain</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">J Clin Orthop Trauma.</span> 2020 Sep-Oct;11(5):761-1-2.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Modic%20changes%20-%20An%20evidence-based%2C%20narrative%20review%20on%20its%20patho-physiology%2C%20clinical%20significance%20and%20role%20in%20chronic%20low%20back%20pain&as_occt=title&as_sauthors=%20%22VK%20Viswanathan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Leite CBG</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Merkely G</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Charles JF</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Lattermann C</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">From Inflammation to Resolution:
                  Specialized Pro-resolving Mediators in Posttraumatic Osteoarthritis</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Curr Osteoporos Rep.</span> 2023
                Dec;21(6):758-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=From%20Inflammation%20to%20Resolution%3A%20Specialized%20Pro-resolving%20Mediators%20in%20Posttraumatic%20Osteoarthritis&as_occt=title&as_sauthors=%20%22CBG%20Leite%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Ddc6f4b6f-a9df-401b-8b3f-eb1fa03b4e40%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F20%2Fe43%26topics%3Dsp"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Commentary and Perspective</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">October 16, 2024;
                      106 (20): e43</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00576</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 16, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=81ba9ba0-6708-465a-a5b7-84520d20a16c&native=1"
                              class="article-title" style="position: unset;">Landscape of the Lumbar Cartilaginous End
                              Plate Microbiota and Metabolites in Patients with Modic Changes</a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Nian,
                              Sunqi; Tang, Shaohua; Shen, Shiqian; Yue, Wenqiang; Zhao, Caiwang; Zou, Tiannan; Li,
                              Weichao; Li, Na; Lu, Sheng; Chen, Jiayu</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(20):1866 | August 19, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;dc6f4b6f-a9df-401b-8b3f-eb1fa03b4e40&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;sp&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=dc6f4b6f-a9df-401b-8b3f-eb1fa03b4e40&type=pdf&name=JBJS.24.00576.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=dc6f4b6f-a9df-401b-8b3f-eb1fa03b4e40&type=pdf&name=JBJS.24.00576.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_5" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_6"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I104" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I104</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;dc6f4b6f-a9df-401b-8b3f-eb1fa03b4e40&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;sp&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=dc6f4b6f-a9df-401b-8b3f-eb1fa03b4e40&type=zip&name=JBJS.24.00576.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jari Dahmen, MD, BSc<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:j.dahmen@amsterdamumc.nl" class=""
                    style="position: unset;">j.dahmen@amsterdamumc.nl</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6849-1008" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6849-1008</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Gino M.M.J. Kerkhoffs, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery and Sports Medicine, Amsterdam Movement
                Sciences, Amsterdam University Medical Center, University of Amsterdam, Amsterdam, The
                Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Academic Center for Evidence Based Sports Medicine (ACES), Amsterdam University
                Medical Center, University of Amsterdam, Amsterdam, The Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Amsterdam Collaboration for Health and Safety in Sports (ACHSS), International
                Olympic Committee (IOC) Research Center, Amsterdam University Medical Center, Amsterdam, The
                Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 5688.45px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
