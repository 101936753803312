/* -----------------Globals--------------- */
import { relayEnv } from "@memorang/configs";
import { fetchData } from "@memorang/helpers";
import type { LeaderboardRecord } from "@memorang/types";
import { graphql } from "react-relay";

type Response = {
	leaderboard: LeaderboardRecord[];
};

const query = graphql`
  query FetchLeaderboardQuery($tenantId: String!, $scope: LeaderboardType) {
    leaderboard(tenantId: $tenantId, scope: $scope) {
      xp
      username
      avatar
    }
  }
`;

const fetchLeaderboard = async (
	tenantId: string,
	scope: string,
): Promise<Response> => {
	const variables = {
		tenantId,
		scope,
	};

	const { leaderboard } = (await fetchData(
		relayEnv,
		query,
		variables,
	)) as Response;

	return {
		leaderboard,
	};
};

export default fetchLeaderboard;
