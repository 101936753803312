/* ----------------- Type Imports --------------- */
import { SectionListCards } from "@memorang/ui";
import type {
	RecommendedChallenge,
	SectionListCardsType,
} from "types/image-challenge";

interface Props {
	recommendedChallenge?: RecommendedChallenge;
	imageChallengeHomeSectionList: SectionListCardsType[];
	handleClickCard?: (id: number, type: string, isFeatured?: boolean) => void;
	headerComponent?: React.ReactNode;
	isPremiumUser?: boolean;
}
const ImageChallengeHomeView = ({
	recommendedChallenge,
	imageChallengeHomeSectionList,
	handleClickCard,
	headerComponent,
	isPremiumUser,
}: Props) => {
	return (
		<SectionListCards
			featuredChallenge={recommendedChallenge}
			sectionListCardsData={imageChallengeHomeSectionList}
			handleClickCard={handleClickCard}
			headerComponent={headerComponent}
			isPremiumUser={isPremiumUser}
		/>
	);
};

export default ImageChallengeHomeView;
