import { Image } from "@memorang/ui";
import { ScrollView } from "react-native";

/* -----------------Child components--------------- */
import { AccountListItem } from "components";

import { useAppTheme } from "@hooks/useAppTheme";
import { Box, CustomListSubheader } from "@memorang/ui";
import { useImageDimensions } from "@react-native-community/hooks";
/* -----------------Types--------------- */
import type { Section } from "../types";

type Props = {
	sections: Section[];
};

const PoweredBy = () => {
	const theme = useAppTheme();

	const footerLogo = theme.dark
		? require("../../../../assets/core/logo_poweredby_dark.png")
		: require("../../../../assets/core/logo_poweredby_light.png");

	const { dimensions } = useImageDimensions(footerLogo);
	return (
		<Box flexDirection="column" alignItems="center" padding={16}>
			<Image
				source={footerLogo}
				style={{
					height: 72,
					width: dimensions?.width,
					marginBottom: 16,
				}}
				contentFit="contain"
			/>
		</Box>
	);
};
const AccountView = ({ sections }: Props) => {
	return (
		<ScrollView>
			{sections.map(({ sectionTitle, items }) => (
				<Box key={sectionTitle}>
					<CustomListSubheader
						style={{
							paddingLeft: 16,
						}}
					>
						{sectionTitle}
					</CustomListSubheader>
					{items.map((view) => (
						<AccountListItem key={view.title} {...view} />
					))}
				</Box>
			))}
			<PoweredBy />
		</ScrollView>
	);
};

export default AccountView;
