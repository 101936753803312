/* ======== Globals ========= */
import { create } from "zustand";

import { customConstants } from "../configs/custom-app-config";
import type { CustomConfigType } from "../configs/custom-app-config-schema";
import { buildType } from "../helpers/expo-extras";
/* ======== Types ========= */
import type { Apps } from "../types/apps";

/* ======== Store Creation ========= */
interface AppState extends CustomConfigType {
	setAppConfig: (appConfig: CustomConfigType) => void;
}

export const useAppStore = create<AppState>((set) => ({
	...customConstants[buildType as Apps],
	setAppConfig: (appConfig) => set((state) => ({ ...state, ...appConfig })),
}));
