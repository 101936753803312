import type { Article } from "../../../../../types";

export const Article603: Article = {
	id: 603,
	rsuiteId: "98cc85fd-6bd9-400e-bbd1-88a472c0f295",
	type: "guest editorial",
	title: "What's New in Musculoskeletal Tumor Surgery",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/603.jpeg",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">What’s New in Musculoskeletal Tumor Surgery</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Aaron
                      Gazendam, MD, FRCSC</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Michelle
                      Ghert, MD, FRCSC</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">This past year, the musculoskeletal tumor surgery
                  literature kept up with the rapidly increasing publication volume in the medical literature. Among the
                  thousands of publications in our field, we highlight a chosen few in this Guest Editorial. We are
                  excited to present remarkable collaborations and exciting innovations. Although the majority of
                  research continues to be retrospective, unique approaches are being developed and international
                  cohesion is apparent by the worldwide collaborations and sharing of prospective data sets. Molecular
                  approaches to diagnosis and treatment have made incremental steps forward, and the use of machine
                  learning in prognosis and diagnosis continues to make strides. Finally, surgical approaches to bone
                  and soft-tissue tumors are becoming better understood through large case series.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Soft-Tissue
                Sarcoma</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Prognostic
                Prediction Models</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">Recent research has emphasized the crucial role of
                  advanced diagnostic tools and methodologies in improving the prognosis and management of soft-tissue
                  sarcomas. Marques et al. explored how magnetic resonance imaging (MRI) can predict the histological
                  grade of soft-tissue sarcomas, suggesting that MRI features can provide valuable preoperative
                  insights, potentially guiding treatment strategies<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  Similarly, Kim et al. explored DNA methylation profiling as a means to classify histologic subtypes
                  and grades in soft-tissue sarcomas<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>.
                  Their findings indicate that this molecular approach can complement traditional histopathological
                  evaluation, enhancing diagnostic accuracy and personalized treatment planning.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Survival prediction models are also gaining attention.
                  Yeramosu et al. developed a model to predict 5-year mortality in patients with soft-tissue sarcoma,
                  emphasizing factors such as tumor size, patient age, and histological grade<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a>. Kamalapathy et al. introduced a machine learning algorithm
                  specifically for predicting 5-year survival in patients with soft-tissue leiomyosarcoma, highlighting
                  the potential of artificial intelligence in clinical decision-making<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a>. Additionally, Stauss et al. examined the impact of surgical
                  resection margins on outcomes such as local recurrence, distant metastasis, and overall survival,
                  underscoring the importance of achieving clear margins during the surgical procedure to improve
                  patient prognosis<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">5</span></a>.
                  Although the risk factors identified in these prediction model studies are not new, big data and the
                  machine learning approach are important advancements.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Specific
                Soft-Tissue Sarcoma Subtypes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">An increasing number of studies are approaching
                  soft-tissue sarcoma subtypes as unique entities. In a Phase-2, international, open-label trial
                  (SPEARHEAD-1), D’Angelo et al. investigated the efficacy of afamitresgene autoleucel in patients with
                  advanced synovial sarcoma and myxoid round cell liposarcoma<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">6</span></a>. The
                  study demonstrated promising results, with a notable percentage of patients experiencing tumor
                  reduction and manageable safety profiles. This innovative therapy, which involves engineered T-cells
                  targeting specific cancer antigens, showed potential in treating these aggressive sarcomas, offering
                  hope for patients with limited treatment options, although improved survival was not proven.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Atezolizumab, an immune checkpoint inhibitor, was
                  evaluated for its effectiveness in treating advanced alveolar soft-part sarcoma in a study published
                  in <span data-id="emphasis_66" class="annotation emphasis" style="position: unset;">The New England
                    Journal of Medicine</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">7</span></a>. The
                  findings indicated that atezolizumab provided clinical benefits, including tumor shrinkage and
                  prolonged progression-free survival for some patients. This study highlights the potential of
                  immunotherapy in managing rare and difficult-to-treat sarcomas, underlining the importance of further
                  research to optimize treatment protocols and patient selection.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Additional studies have focused on other unique sarcoma
                  subtypes and their management. Makris et al. provided insights into the natural history of
                  undifferentiated pleomorphic sarcoma through the U.S. Sarcoma Collaborative, identifying factors
                  influencing disease progression and outcomes<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">8</span></a>.
                  Fujiwara et al. identified key survival factors in patients with clear cell sarcoma, emphasizing the
                  importance of early detection and tailored treatment strategies<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">9</span></a>. Zhang et al. explored the optimal timing for reexcision in
                  patients with synovial sarcoma, finding that immediate intervention postoperatively may improve
                  outcomes compared with waiting for local recurrence<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">10</span></a>.
                  Furthermore, Wahlig et al. assessed the clinical importance of bone marrow aspiration and biopsy in
                  the initial staging of extraskeletal Ewing sarcoma, concluding that its routine use may not be
                  necessary, thus potentially reducing patient burden without compromising diagnostic accuracy<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">11</span></a>. It is clear that a subtype-specific approach to the study
                  and treatment of soft-tissue sarcoma will become increasingly more central to patient care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Wound
                Management</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Soft-tissue sarcoma surgery often results in wound
                  complications. Therefore, ongoing research has focused on preventing and/or minimizing these
                  complications. Mericli et al. introduced the Anderson SArcoma Risk of Complications (A-SARC) score, a
                  novel tool designed to guide reconstructive decision-making in patients with extremity soft-tissue
                  sarcoma<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">12</span></a>.
                  This scoring system helps clinicians to determine when to opt for more complex reconstructive
                  techniques over traditional methods by assessing the risk of complications. By considering various
                  patient-specific factors, the A-SARC score emphasizes personalized treatment planning, ensuring that
                  patients receive the most appropriate reconstructive strategy based on their individual risk profile.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">In another study, Gonzalez et al. analyzed the
                  cost-effectiveness of wound vacuum-assisted closure temporization following tumor resection in
                  patients with soft-tissue sarcoma<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">13</span></a>. By
                  comparing propensity-score-matched patients, they demonstrated that vacuum-assisted closure
                  temporization could be a cost-effective strategy, potentially reducing health-care expenses associated
                  with prolonged hospital stays and additional surgical interventions, although vacuum-assisted closure
                  temporization will not be necessary in all patients. Miwa et al. developed and validated a nomogram to
                  predict the risk of surgical site infections after soft-tissue sarcoma resection<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">14</span></a>. This predictive tool allows clinicians to identify
                  high-risk patients and implement targeted preventive measures, thereby minimizing the incidence of
                  surgical site infections and improving postoperative outcomes. As further research clarifies the risks
                  of wound complications and best practices for preventing wound complications, surgeons will be able to
                  optimize surgical care and resource utilization in the management of soft-tissue sarcomas.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Exploratory
                and Cutting-Edge Approaches</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Immunotherapy continues to be explored as a potential
                  treatment approach for soft-tissue sarcoma. In a Phase-1 trial, Seo et al. investigated the
                  combination of toll-like receptor 4 (TLR4) agonist injections with concurrent radiation therapy in
                  patients with metastatic soft-tissue sarcoma<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">15</span></a>.
                  The study demonstrated that this combination could be safe and potentially enhance the immune response
                  against tumors. Meanwhile, Xu et al. conducted the ALTER-S006 trial, assessing anlotinib as a
                  maintenance treatment for advanced soft-tissue sarcoma after chemotherapy<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">16</span></a>. The trial found that anlotinib could potentially prolong
                  progression-free survival, offering a new therapeutic option for patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Finally, a Phase-3 factorial trial of post-treatment
                  surveillance for soft-tissue sarcoma, the Surveillance After Extremity Tumor Surgery (SAFETY)
                  randomized controlled trial, completed the pilot study of 100 patients followed for 2 years with more
                  or less intense clinical follow-up and thoracic imaging schedules<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">17</span></a>. The pilot phase confirmed the feasibility of enrollment,
                  protocol adherence, and data quality. Full study enrollment was expected to be completed by the end of
                  2024. In support of the rationale for this trial, Priester et al. examined the utility of
                  contrast-enhanced chest computed tomographic (CT) scans in the staging and surveillance of extremity
                  sarcomas, finding that, although these scans can aid in detecting metastases, their routine use may
                  not significantly impact patient outcomes<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">18</span></a>.
                  The study suggested that a more selective approach to using contrast chest CT scans could optimize
                  patient care and resource allocation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Tenosynovial
                Giant Cell Tumor (TGCT)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Colony-stimulating factor 1 (CSF-1) inhibitors are a class
                  of drugs designed to block the activity of CSF-1, a cytokine that regulates the production,
                  differentiation, and function of macrophages. CSF-1 interacts with its receptor, CSF-1R (also known as
                  CD115), which is expressed on the surface of macrophages and other cells of the mononuclear phagocyte
                  system. By inhibiting the CSF-1/CSF-1R pathway, these drugs can reduce the number and activity of
                  macrophages, which play a crucial role in various pathological processes. CSF-1 inhibitors have shown
                  promise in the context of TGCTs. These inhibitors can decrease the recruitment and survival of
                  tumor-associated macrophages (TAMs), which often promote tumor growth and suppress anti-tumor immune
                  responses.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Examples of CSF-1 inhibitors include pexidartinib and
                  vimseltinib. The MOTION trial, conducted by Gelderblom et al., evaluated the efficacy of vimseltinib
                  compared with placebo in patients with TGCTs through a multicenter, randomized, double-blinded,
                  placebo-controlled Phase-3 study<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">19</span></a>.
                  The trial demonstrated that vimseltinib significantly improved objective response rates in patients
                  with TGCTs compared with placebo. Complementing this, van der Heijden et al. discussed the multimodal
                  management of TGCTs, highlighting the integration of new druggable targets into treatment strategies<a
                    href="" data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">20</span></a>. These studies highlighted the evolving landscape of TGCT
                  management, emphasizing the potential of targeted therapies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Prevention
                and Management of Thromboembolic Complications</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The patient population with musculoskeletal cancer is at
                  high risk for thromboembolic and bleeding complications. Therefore, research into prevention and
                  management is ongoing. LiBrizzi et al. used data from the PearlDiver database to evaluate the efficacy
                  and safety of direct oral anticoagulants compared with low-molecular-weight heparin for venous
                  thromboembolism prophylaxis in patients undergoing surgical resection of primary lower-extremity bone
                  or soft-tissue sarcoma<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">21</span></a>.
                  Their study concluded that direct oral anticoagulants are as effective as low-molecular-weight heparin
                  in preventing venous thromboembolism in this patient population, with a similar safety profile with
                  regard to bleeding complications. These findings suggested that direct oral anticoagulants could be a
                  viable alternative to low-molecular-weight heparin for postoperative venous thromboembolism
                  prophylaxis, potentially offering patients a more convenient oral administration route.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">In related research, Foster et al.<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">22</span></a> performed a large, retrospective, single-center review that
                  found the use of intravenous tranexamic acid during sarcoma resection to be associated with an
                  increased risk of venous thromboembolism and pulmonary embolism. Despite the limitations of the
                  retrospective study design, these findings nevertheless highlighted a critical consideration regarding
                  the intraoperative management of patients with sarcoma—suggesting that, although tranexamic acid is
                  effective in reducing blood loss, its potential risk of pulmonary embolism necessitates careful
                  patient selection and monitoring. Additionally, in a secondary analysis of the Prophylactic Antibiotic
                  Regimens in Tumor Surgery (PARITY) randomized controlled trial<a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">23</span></a>, Sabharwal et al. investigated thromboembolism incidence
                  and risk factors after endoprosthetic reconstruction in patients with musculoskeletal cancer<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">24</span></a>. Although relatively rare in the PARITY cohort (1.8%),
                  thromboembolic events were more likely to occur in older patients and those receiving long-term
                  prophylactic antibiotics. In contrast to the finding by Foster et al.<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">22</span></a>, intraoperative tranexamic acid and postoperative chemical
                  thromboprophylaxis were not associated with a greater incidence of venous thromboembolism.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">International Collaboration, Consensus Work, and Data-Sharing</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The orthopaedic oncology community is coming together more
                  to address and answer critical research questions in the field. An example of extraordinary
                  collaboration is the attempt at international consensus on the diagnosis and management of
                  chondrosarcoma and the prevention and management of surgical site infections in bone tumor
                  reconstruction. The Birmingham Orthopaedic Oncology Consensus Meeting, organized by Jeys et al.,
                  explored the associated controversies in orthopaedic oncology<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">25</span></a>. The Birmingham Orthopaedic Oncology Consensus Meeting was
                  held in January 2024 and aimed to establish global consensus on these issues by gathering &gt;300
                  delegates from &gt;50 countries. This meeting highlighted the ongoing debates and sought to generate
                  consensus statements based on evidence and expert opinions from various regions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Finally, data-sharing by the PARITY trial team allowed for
                  a greater understanding of the use of opioids in the orthopaedic oncology population. Gazendam et al.
                  explored opioid consumption patterns and predictors of chronic opioid use in patients with
                  musculoskeletal tumors undergoing limb-salvage surgery and endoprosthetic reconstruction<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">26</span></a>. Analyzing data from the PARITY trial revealed that 33.6%
                  of patients consumed opioids preoperatively, dropping to 6.6% at 1 year postoperatively. Chronic
                  opioid use was significantly associated only with surgical procedures for metastatic bone disease.
                  Other factors such as preoperative opioid use, age, sex, operative time, reoperation rates, and
                  country of origin were not predictive of chronic use. These findings highlight the need for specific
                  opioid-prescribing guidelines for this patient group, as addiction and chronic use were uncommon.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Bone
                Sarcoma</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Chondrosarcoma</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Chondrosarcoma continues to be a challenging entity, and
                  the optimal diagnosis and management is an area of controversy. As noted above, the first-ever
                  Birmingham Orthopaedic Oncology Consensus Meeting highlighted the most pressing issues, including the
                  management of dedifferentiated and pelvic chondrosarcoma, the diagnostic utility of imaging
                  modalities, and the management of atypical cartilaginous tumors<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">25</span></a>. This meeting of &gt;300 specialists from &gt;50 countries
                  represented an important step forward in the understanding of this challenging disease.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Recent literature on differentiating among atypical
                  cartilaginous tumors has focused on utilized imaging modalities to create predictive models. Gitto et
                  al. created a radiomics-based machine learning model utilizing cross-sectional imaging and radiographs
                  to accurately differentiate between atypical cartilaginous tumors and high-grade chondrosarcoma of the
                  extremities<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">27</span></a>.
                  Gundavda et al. developed a 9-parameter radiographic aggressiveness score utilizing radiographs and
                  MRI to predict the grade of primary chondrosarcomas in the long bones<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">28</span></a> The management of atypical cartilaginous tumors has also
                  been controversial, with some groups advocating for wide resection, others for intralesional
                  treatments, and still others for radiographic surveillance. The Leiden group reported on their
                  experience with active radiographic surveillance for atypical cartilaginous tumors and demonstrated
                  its safety in both the short and intermediate terms, with no malignant progression in 117 patients<a
                    href="" data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">29</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">There has been an interest in the presence and
                  implications of isocitrate dehydrogenase (IDH) mutations in patients with cartilaginous lesions.
                  Trovarelli et al. found that the presence of IDH mutations was a negative prognostic factor in
                  patients with chondrosarcoma<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">30</span></a>.
                  Rey et al. examined the impact of IDH mutations in the preclinical setting and reported that, in cell
                  lines with IDH2 mutations, enasidenib demonstrated profound antitumor effects<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">31</span></a>, although translation to clinical practice is far from
                  ensured.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Extremity
                Reconstruction</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">There has been a renewed interest in the use of biologic
                  reconstruction following extremity bone sarcoma resection. Bozzo et al. presented a prospective cohort
                  analysis on the surgical, functional, and oncologic outcomes of distraction osteogenesis following
                  bone sarcoma resection. They demonstrated that all patients achieved full bone-healing and independent
                  weight-bearing at a median of 12 months postoperatively<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">32</span></a>.
                  Bruschi et al. reported on the outcomes of an allograft-prosthetic composite (APC) reconstruction for
                  short proximal femoral segments. They found that APC reconstruction was associated with higher
                  functional outcomes, with no differences in complication rates<a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">33</span></a>. Errani et al.<a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript" style="position: unset;">34</span></a>
                  evaluated the efficacy of resurfaced APCs for pediatric patients with proximal humeral sarcoma. They
                  demonstrated a 25% reconstruction failure rate and recommended utilizing the technique for very young
                  patients or older pediatric patients with preserved axillary nerve function.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Fibular autografts continue to be a popular choice for
                  long-bone reconstructions. Campanacci et al.<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript" style="position: unset;">35</span></a>
                  reported on their experience utilizing vascularized fibular autograft for reconstruction following
                  tibial en bloc resection. They concluded that it is a viable reconstruction option but comes with an
                  almost 50% risk of postoperative complications including infection, fracture, and nonunion. Shahzad et
                  al. examined the utility of combining a vascularized fibular autograft reconstruction in combination
                  with an intercalary prosthesis in patients with a femoral diaphyseal bone tumor<a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">36</span></a>. The use of a “bioprosthetic” intercalary femoral
                  reconstruction allowed for earlier weight-bearing and quicker osseous union compared with
                  reconstruction with an allograft and vascularized fibular autograft. Finally, Shahzad et al. reported
                  on their experience of utilizing vascularized fibular autograft for upper-extremity reconstruction and
                  demonstrated excellent functional outcomes and relatively low complication rates<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">37</span></a>. However, it is important to note that these studies were
                  retrospective in nature and subject to inherent biases.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Endoprosthetic reconstruction continues to be a mainstay
                  in limb-salvage surgery, particularly after periarticular resection. Kendal et al. utilized the PARITY
                  data set to evaluate the rates and causes of early reoperation following lower-extremity
                  endoprosthetic reconstruction. They demonstrated a reoperation rate of &gt;25%, with the most common
                  causes of reoperation being infection and/or wound complications<a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">38</span></a>. Mori et al. reported on the Japanese Musculoskeletal
                  Oncology Group’s experience with complications following total femoral replacement. They demonstrated
                  that the most common serious complications were dislocations at 14% and infection rates at 15%<a
                    href="" data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">39</span></a>. Giglio et al. utilized the PARITY trial data to compare
                  cemented and cementless fixation in lower-extremity endoprosthetic reconstruction. They demonstrated
                  that there was no difference in complication or revision rates between the 2 groups, suggesting that
                  both are viable options in the appropriate patient populations<a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">40</span></a>. Labott et al. performed a single-center review of the
                  utility of humeral intercalary endoprosthetic reconstruction and found that it reliably restored
                  function but was associated with mechanical failure in 28% of patients<a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_43" class="annotation superscript"
                      style="position: unset;">41</span></a>. Finally, Tran et al. compared functional outcomes of
                  pediatric and adult patients undergoing lower-extremity endoprosthetic reconstruction and concluded
                  that pediatric patients had better functional outcomes at nearly all postoperative time points,
                  although both groups showed similar improvements from baseline to 1 year postoperatively<a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">42</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Pelvic
                Sarcomas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Pelvic sarcomas remain one of the most challenging
                  clinical entities, with high complication rates and poor postoperative function. Evenhuis et al.
                  provided intermediate-term results of the LUMiC acetabular prosthesis from a multicenter study<a
                    href="" data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_45" class="annotation superscript"
                      style="position: unset;">43</span></a>. They evaluated 166 patients who underwent periacetabular
                  tumor resection and LUMiC reconstruction and demonstrated a reoperation rate of nearly 50%. However,
                  most patients retained their primary implant, and the authors concluded that this remains a viable
                  reconstruction option. Zhang et al. presented an alternative reconstruction following periacetabular
                  tumor resection, utilizing a hip transposition with stabilization using polyethylene tape, mesh, or
                  iliotibial graft. They found that 76% of patients were able to walk with crutches by 2 months
                  postoperatively<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_46" class="annotation superscript" style="position: unset;">44</span></a>.
                  Wang et al. demonstrated that, regardless of the type of resection or reconstruction, obesity was an
                  independent risk factor for major wound complications following pelvic sarcoma resection<a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">45</span></a>. Vucicevic et al. demonstrated the steep learning curve
                  associated with pelvic sarcoma surgery. They demonstrated a shift toward the utilization of computer
                  navigation or 3D-printed cutting guides and multidisciplinary team care, resulting in lower rates of
                  major blood loss and local recurrence<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_48" class="annotation superscript" style="position: unset;">46</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">The role of reconstruction following iliosacral sarcoma
                  resection continues to be an area of controversy. Broida et al. evaluated the role of reconstruction
                  following iliosacral resection at 2 tertiary centers<a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_49" class="annotation superscript" style="position: unset;">47</span></a>.
                  They demonstrated that sacroiliac joint reconstruction was associated with longer operating room
                  times, higher blood transfusion requirements, and more infections, with no improvement in function,
                  when compared with patients who did not undergo a reconstruction.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Infection</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Infection continues to be the leading cause of
                  postoperative complications in patients undergoing a surgical procedure for a malignant bone tumor and
                  was a focus of the recent Birmingham Orthopaedic Oncology Consensus Meeting<a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                      style="position: unset;">25</span></a>. This meeting highlighted the need for uniform definitions
                  with respect to the surgical treatment options for periprosthetic joint infection (PJI) and the need
                  to better define the effectiveness of antibiotic treatment regimens used to treat the unique organisms
                  found in patients with oncological diagnoses.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">A number of PARITY trial secondary analyses provided
                  valuable insights into the predictors and impact of surgical site infections following lower-extremity
                  endoprosthetic reconstruction<a href="" data-id="citation_reference_51"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_51" class="annotation superscript" style="position: unset;">48</span></a>.
                  Zeitlinger et al. demonstrated that longer operative time was an independent risk factor for infection
                  and that a prolonged course of postoperative antibiotics did not mitigate the risk of infection in
                  this high-risk cohort<a href="" data-id="citation_reference_52"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_52" class="annotation superscript" style="position: unset;">49</span></a>.
                  LiBrizzi et al. demonstrated that prolonged drain duration (&gt;14 days) was associated with infection
                  following endoprosthetic reconstruction, whereas the use of negative-pressure wound therapy dressings
                  did not impact infection rates<a href="" data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_53" class="annotation superscript" style="position: unset;">50</span></a>.
                  Ray et al. highlighted the challenges of eradicating infections following endoprosthetic
                  reconstruction, with only 28% of patients diagnosed with a surgical site infection having successful
                  eradication of the infection<a href="" data-id="citation_reference_54"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_54" class="annotation superscript" style="position: unset;">51</span></a>.
                  Finally, Jackson et al. challenged the notion that postoperative infection may confer a survival
                  benefit in patients with osteosarcoma, finding that infection was associated with increased disease
                  progression in the subset of patients presenting with metastases at baseline<a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_55" class="annotation superscript"
                      style="position: unset;">52</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Diagnosis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Achieving an accurate diagnosis in patients with bone
                  sarcoma is paramount, as it ensures that patients receive effective, appropriate, and timely care.
                  Burke et al. analyzed international biopsy principles and their impact on surgical outcomes in
                  patients with lower-extremity bone sarcoma and demonstrated that core needle biopsies are more common
                  globally, whereas open biopsies are more frequent in the United States and Canada<a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_56" class="annotation superscript"
                      style="position: unset;">53</span></a>. Despite longer operative times and more tissue excision
                  with open biopsies, no differences in infection rates or oncologic outcomes were found. Low-grade
                  central osteosarcoma often has radiographic and pathological features that mimic other bone tumors,
                  complicating its diagnosis and treatment. Khal et al. retrospectively analyzed 49 patients with
                  low-grade central osteosarcoma, revealing that accurate diagnosis was more common with open biopsies
                  and that wide-margin surgical excision led to favorable outcomes, although local recurrence and
                  distant metastasis were associated with poorer prognoses<a href="" data-id="citation_reference_57"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_57" class="annotation superscript" style="position: unset;">54</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Extra-articular resection is commonly used to treat bone
                  tumors with intra-articular extension around the knee, although it often results in poor functional
                  outcomes. Kurisunkal et al. evaluated the diagnostic accuracy of MRI in predicting intra-articular
                  involvement in 63 high-grade sarcoma cases, finding that, although MRI can sometimes overestimate
                  joint involvement, combining imaging findings such as knee effusion with clinical signs such as
                  disease in the Hoffa fat pad or pathological fracture improves diagnostic accuracy<a href=""
                    data-id="citation_reference_58" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_58" class="annotation superscript"
                      style="position: unset;">55</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Metastatic
                Bone Disease</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Recent research in metastatic bone disease is advancing
                  our understanding and management of this complex condition, addressing challenges in the diagnosis,
                  treatment, and outcome predictions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Management</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">The Musculoskeletal Tumor Society (MSTS), with the
                  assistance of the American Academy of Orthopaedic Surgeons (AAOS), developed a Clinical Practice
                  Guideline (CPG) for managing metastatic humeral disease<a href="" data-id="citation_reference_59"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_59" class="annotation superscript" style="position: unset;">56</span></a>.
                  There was evidence that the addition of cement may provide short-term improvements in pain relief and
                  functional mobility. In patients requiring arthroplasty to reconstruct the proximal humerus, reverse
                  total shoulder arthroplasty was recommended in order to decrease shoulder instability and improve
                  range of motion. These findings were corroborated by Sullivan et al., who performed a retrospective
                  review and found that reverse total shoulder arthroplasty reconstruction in the setting of metastatic
                  bone disease allowed for better range of motion and less instability<a href=""
                    data-id="citation_reference_60" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_60" class="annotation superscript"
                      style="position: unset;">57</span></a>. However, the findings of these retrospective studies
                  remain preliminary, and shoulder reconstruction should be approached on a case-by-case basis in
                  patients with metastatic bone disease.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Two recent studies examined the optimal surgical
                  management of skeletal metastases in the appendicular skeleton, comparing outcomes for solitary bone
                  metastasis compared with multiple bone metastases. Ladegaard et al. conducted a retrospective study on
                  459 patients treated surgically between 2014 and 2019<a href="" data-id="citation_reference_61"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_61" class="annotation superscript" style="position: unset;">58</span></a>.
                  They found that patients with a solitary osseous metastasis had a 1-year survival rate of 47%,
                  significantly higher than the 34% for those with multiple metastases. Notably, aggressive surgical
                  resection (R0) of solitary metastases led to a higher 1-year survival rate of 75%, compared with 42%
                  for less complete resections. Kafchinski reviewed literature indicating that metastasectomy and
                  reconstruction can improve survival, especially for patients with renal cell carcinoma with a solitary
                  bone metastasis<a href="" data-id="citation_reference_62"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_62" class="annotation superscript" style="position: unset;">59</span></a>.
                  However, the benefits of such aggressive surgical approaches for other cancer types, such as breast
                  and thyroid cancer metastases, remain under debate. Both studies suggested that aggressive surgical
                  treatment of a solitary osseous metastasis can enhance patient survival, although the evidence varies
                  by cancer type.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Predictive
                Models</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Recent research in predictive models for extremity
                  metastatic bone disease has made notable strides, as evidenced by recent studies. One study conducted
                  in South Korea compared the performance of 5 survival prediction models among Asian patients
                  undergoing surgery for bone metastases of the extremities. The study found that the Skeletal Oncology
                  Research Group (SORG) model had the highest discriminatory accuracy, indicating its superior ability
                  to predict survival postoperatively<a href="" data-id="citation_reference_63"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_63" class="annotation superscript" style="position: unset;">60</span></a>.
                  Vankara et al. evaluated the effectiveness of the Pathologic Fracture Mortality Index (PFMI) in
                  predicting 30-day postoperative morbidity following fixation of pathologic fractures in patients with
                  cancer<a href="" data-id="citation_reference_64"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_64" class="annotation superscript" style="position: unset;">61</span></a>.
                  The PFMI surpassed other indices in predicting all-cause morbidity, underscoring its value in risk
                  stratification for surgical outcomes in this patient population. Furthermore, an international
                  analysis of life expectancy trends following treatment for metastatic bone disease revealed
                  improvements in median survival over time across various cancer types. This highlights the evolving
                  landscape of treatment outcomes, driven by advancements in both surgical techniques and medical
                  therapies<a href="" data-id="citation_reference_65"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_65" class="annotation superscript" style="position: unset;">62</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Within the spinal metastasis literature, recent work has
                  focused on enhancing predictive models, particularly the SORG machine-learning algorithm (SORG-MLA).
                  Su et al. validated its ability to predict 6-week survival in Taiwanese patients, demonstrating good
                  discrimination and accuracy despite slight underestimations of survival rates of &lt;50%<a href=""
                    data-id="citation_reference_66" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_66" class="annotation superscript"
                      style="position: unset;">63</span></a>. Another study investigated the algorithm’s performance
                  with missing data by intentionally removing surgical information from a larger cohort and found that,
                  although the SORG-MLA generally performed well with up to 3 missing items, it faltered without crucial
                  factors such as serum albumin and lymphocyte count<a href="" data-id="citation_reference_67"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_67" class="annotation superscript" style="position: unset;">64</span></a>. An
                  internet-based tool was developed to mitigate these challenges, enabling real-time data imputation to
                  support more informed clinical decisions for patients with spinal metastasis, especially when timely
                  surgical interventions are crucial.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Marques TMDM</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Cerqueira WS</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Neto JLF</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Kupper BEC</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Takahashi RM</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Bezerra TS</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Stevanato Filho PR</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Nakagawa WT</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Lopes A</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Aguiar S Jr</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Role of magnetic resonance
                  imaging in the prediction of histological grade in soft tissue sarcomas</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Surg Oncol.</span> 2024
                Apr 29. [Epub ahead of print].</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Role%20of%20magnetic%20resonance%20imaging%20in%20the%20prediction%20of%20histological%20grade%20in%20soft%20tissue%20sarcomas&as_occt=title&as_sauthors=%20%22TMDM%20Marques%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Kim H</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Joo MW</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Yoon J</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Park HS</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Kim JH</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Lee JH</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Kim SH</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Lee SK</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Chung YG</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Cho YJ</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Can DNA methylation profiling
                  classify histologic subtypes and grades in soft tissue sarcoma?</span><span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2024 Mar
                22;482(6):1028-37.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Can%20DNA%20methylation%20profiling%20classify%20histologic%20subtypes%20and%20grades%20in%20soft%20tissue%20sarcoma%3F&as_occt=title&as_sauthors=%20%22H%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Yeramosu T</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Ahmad W</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Bashir A</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Wait J</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Bassett J</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Domson G</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Predicting five-year mortality
                  in soft-tissue sarcoma patients</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2023 Jun 1;105-B(6):702-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predicting%20five-year%20mortality%20in%20soft-tissue%20sarcoma%20patients&as_occt=title&as_sauthors=%20%22T%20Yeramosu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Kamalapathy PN</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Gonzalez MR</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> de Groot TM</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Ramkumar D</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Raskin KA</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Ashkani-Esfahani S</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Lozano-Calderón SA</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Prediction of 5-year survival in
                  soft tissue leiomyosarcoma using a machine learning model algorithm</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">J Surg Oncol.</span> 2024
                Mar;129(3):531-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prediction%20of%205-year%20survival%20in%20soft%20tissue%20leiomyosarcoma%20using%20a%20machine%20learning%20model%20algorithm&as_occt=title&as_sauthors=%20%22PN%20Kamalapathy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Stauss R</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Aigner A</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Richter A</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Suero E</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Altemeier A</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Savov P</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Ettinger M</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Omar M</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">The prognostic significance of
                  surgical resection margins for local recurrence, distant metastasis, and overall survival in
                  sarcoma</span>. <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">J Surg
                  Oncol.</span> 2023 Dec;128(7):1160-70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20prognostic%20significance%20of%20surgical%20resection%20margins%20for%20local%20recurrence%2C%20distant%20metastasis%2C%20and%20overall%20survival%20in%20sarcoma&as_occt=title&as_sauthors=%20%22R%20Stauss%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> D’Angelo SP</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Araujo DM</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Abdul Razak AR</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Agulnik M</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Attia S</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Blay JY</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Carrasco Garcia I</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Charlson JA</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Choy E</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Demetri GD</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Druta M</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Forcade E</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Ganjoo KN</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Glod J</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Keedy VL</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Le Cesne A</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Liebner DA</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Moreno V</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Pollack SM</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Schuetze SM</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Schwartz GK</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Strauss SJ</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Tap WD</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Thistlethwaite F</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Valverde Morales CM</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Wagner MJ</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Wilky BA</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> McAlpine C</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Hudson L</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Navenot JM</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Wang T</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Bai J</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Rafail S</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Wang R</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Sun A</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Fernandes L</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Van Winkle E</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Elefant E</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Lunt C</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Norry E</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Williams D</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Biswas S</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Van Tine BA</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Afamitresgene autoleucel for
                  advanced synovial sarcoma and myxoid round cell liposarcoma (SPEARHEAD-1): an international,
                  open-label, phase 2 trial</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 2024 Apr 13;403(10435):1460-71.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Afamitresgene%20autoleucel%20for%20advanced%20synovial%20sarcoma%20and%20myxoid%20round%20cell%20liposarcoma%20(SPEARHEAD-1)%3A%20an%20international%2C%20open-label%2C%20phase%202%20trial&as_occt=title&as_sauthors=%20%22SP%20D%E2%80%99Angelo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Chen AP</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Sharon E</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> O’Sullivan-Coyne G</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Moore N</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Foster JC</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Hu JS</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Van Tine BA</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Conley AP</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Read WL</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Riedel RF</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Burgess MA</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Glod J</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Davis EJ</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Merriam P</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Naqash AR</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Fino KK</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Miller BL</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Wilsker DF</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Begum A</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Ferry-Galow KV</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Deshpande HA</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Schwartz GK</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Ladle BH</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Okuno SH</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Beck JC</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Chen JL</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Takebe N</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Fogli LK</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Rosenberger CL</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Parchment RE</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Doroshow JH</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Atezolizumab for advanced
                  alveolar soft part sarcoma</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">N Engl J Med.</span> 2023 Sep 7;389(10):911-21.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Atezolizumab%20for%20advanced%20alveolar%20soft%20part%20sarcoma&as_occt=title&as_sauthors=%20%22AP%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Makris EA</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Tran TB</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Delitto DJ</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Lee B</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Ethun CG</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Grignol V</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Harrison Howard J</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Bedi M</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Clark Gamblin T</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Tseng J</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Roggin KK</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Chouliaras K</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Votanopoulos K</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Cullinan D</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Fields RC</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Cardona K</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Poultsides G</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Kirane A</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Natural history of
                  undifferentiated pleomorphic sarcoma: experience from the US Sarcoma Collaborative</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">J Surg Oncol.</span> 2024
                Jun;129(7):1354-63.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Natural%20history%20of%20undifferentiated%20pleomorphic%20sarcoma%3A%20experience%20from%20the%20US%20Sarcoma%20Collaborative&as_occt=title&as_sauthors=%20%22EA%20Makris%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Fujiwara T</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Kunisada T</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Nakata E</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Mitsuhashi T</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Ozaki T</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Kawai A</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Factors associated with survival
                  in patients with clear cell sarcoma</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2023 Nov 1;105-B(11):1216-25.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20associated%20with%20survival%20in%20patients%20with%20clear%20cell%20sarcoma&as_occt=title&as_sauthors=%20%22T%20Fujiwara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Zhang H</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Sun W</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Huang W</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Yan W</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Wang H</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Qu G</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Wang K</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Qu X</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Wang C</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Chen Y</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Optimal timing of re-excision
                  in synovial sarcoma patients: immediate intervention versus waiting for local recurrence</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">J Surg Oncol.</span> 2023
                Dec;128(8):1394-406.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Optimal%20timing%20of%20re-excision%20in%20synovial%20sarcoma%20patients%3A%20immediate%20intervention%20versus%20waiting%20for%20local%20recurrence&as_occt=title&as_sauthors=%20%22H%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Wahlig BD</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Broida SE</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Rose PS</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Robinson SI</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Siontis BL</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Houdek MT</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Is bone marrow aspiration and
                  biopsy of clinical importance in the initial staging of extraskeletal Ewing sarcoma?</span><span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2023 Nov 1;481(11):2177-82.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20bone%20marrow%20aspiration%20and%20biopsy%20of%20clinical%20importance%20in%20the%20initial%20staging%20of%20extraskeletal%20Ewing%20sarcoma%3F&as_occt=title&as_sauthors=%20%22BD%20Wahlig%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Mericli AF</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Elmorsi R</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Camacho L</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Hassan A</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Krijgh DD</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Tilney G</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Lyu H</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Traweek RS</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Witt RG</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Roubaud MS</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Roland CL</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">When to ditch the ladder and
                  take the elevator: the Anderson SArcoma Risk of Complications (A-SARC) score to guide reconstructive
                  decision-making in extremity soft tissue sarcoma patients</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">J Surg Oncol.</span> 2024
                Jun;129(8):1456-65.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=When%20to%20ditch%20the%20ladder%20and%20take%20the%20elevator%3A%20the%20Anderson%20SArcoma%20Risk%20of%20Complications%20(A-SARC)%20score%20to%20guide%20reconstructive%20decision-making%20in%20extremity%20soft%20tissue%20sarcoma%20patients&as_occt=title&as_sauthors=%20%22AF%20Mericli%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Gonzalez MR</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> de Groot TM</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Werenski JO</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Fourman MS</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Morse AS</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Lozano-Calderon SA</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Wound vacuum-assisted closure
                  temporization after tumor resection of soft tissue sarcomas-a cost analysis in a propensity-score
                  matched population</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">J Surg Oncol.</span> 2024 Aug;130(2):301-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Wound%20vacuum-assisted%20closure%20temporization%20after%20tumor%20resection%20of%20soft%20tissue%20sarcomas-a%20cost%20analysis%20in%20a%20propensity-score%20matched%20population&as_occt=title&as_sauthors=%20%22MR%20Gonzalez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Miwa S</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Yamamoto N</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Hayashi K</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Takeuchi A</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Igarashi K</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Tada K</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Taniguchi Y</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Morinaga S</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Asano Y</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Tsuchiya H</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Development and validation of a
                  nomogram to predict surgical site infection after soft-tissue sarcoma resection</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2024
                May 1;106-B(5):492-500.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20and%20validation%20of%20a%20nomogram%20to%20predict%20surgical%20site%20infection%20after%20soft-tissue%20sarcoma%20resection&as_occt=title&as_sauthors=%20%22S%20Miwa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Seo YD</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Lu H</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Black G</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Smythe K</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Yu Y</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Hsu C</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Ng J</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Hermida de Viveiros P</span>,
                <span data-id="annotation_191" class="annotation" style="position: unset;"> Warren EH</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Schroeder BA</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> O’Malley RB</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Cranmer LD</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Loggers ET</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Wagner MJ</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Bonham L</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Pillarisetty VG</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Kane G</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Berglund P</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Hsu FJ</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Mi X</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Alexiev BA</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Pierce RH</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Riddell SR</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Jones RL</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Ter Meulen J</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Kim EY</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Pollack SM</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Toll-like receptor 4 agonist
                  injection with concurrent radiotherapy in patients with metastatic soft tissue sarcoma: a phase 1
                  nonrandomized controlled trial</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">JAMA Oncol.</span> 2023 Dec 1;9(12):1660-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Toll-like%20receptor%204%20agonist%20injection%20with%20concurrent%20radiotherapy%20in%20patients%20with%20metastatic%20soft%20tissue%20sarcoma%3A%20a%20phase%201%20nonrandomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22YD%20Seo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Xu B</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Pan Q</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Pan H</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Li H</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Li X</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Chen J</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Pang D</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Zhang B</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Weng D</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Peng R</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Fang M</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Zhang X</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Anlotinib as a maintenance
                  treatment for advanced soft tissue sarcoma after first-line chemotherapy (ALTER-S006): a multicentre,
                  open-label, single-arm, phase 2 trial</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">EClinicalMedicine.</span> 2023 Sep 21;64:102240.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anlotinib%20as%20a%20maintenance%20treatment%20for%20advanced%20soft%20tissue%20sarcoma%20after%20first-line%20chemotherapy%20(ALTER-S006)%3A%20a%20multicentre%2C%20open-label%2C%20single-arm%2C%20phase%202%20trial&as_occt=title&as_sauthors=%20%22B%20Xu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_222" class="annotation" style="position: unset;"> The Safety Investigators</span>.
                <span data-id="strong_17" class="annotation strong" style="position: unset;">The Surveillance After
                  Extremity Tumor Surgery (SAFETY) trial: protocol for a pilot study to determine the feasibility of a
                  multi-centre randomised controlled trial</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">BMJ Open.</span> 2024 Sep
                18;9(9):e029054.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Surveillance%20After%20Extremity%20Tumor%20Surgery%20(SAFETY)%20trial%3A%20protocol%20for%20a%20pilot%20study%20to%20determine%20the%20feasibility%20of%20a%20multi-centre%20randomised%20controlled%20trial&as_occt=title&as_sauthors=%20%22Safety%20Investigators%20The%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Priester JI</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Simister SK</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Sario M</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Choi J</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Pina D</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Theriault R</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Bateni C</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Ghasemiesfe A</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Carr-Ascher J</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Monjazeb AM</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Canter RJ</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Randall RL</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Thorpe SW</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Scrutinizing the use of
                  contrasted chest CTs in extremity sarcoma staging and surveillance</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">J Surg Oncol.</span> 2024
                Mar;129(3):523-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Scrutinizing%20the%20use%20of%20contrasted%20chest%20CTs%20in%20extremity%20sarcoma%20staging%20and%20surveillance&as_occt=title&as_sauthors=%20%22JI%20Priester%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Gelderblom H</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Bhadri V</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Stacchiotti S</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Bauer S</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Wagner AJ</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> van de Sande M</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Bernthal NM</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> López Pousa A</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Razak AA</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Italiano A</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Ahmed M</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Le Cesne A</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Tinoco G</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Boye K</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Martín-Broto J</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Palmerini E</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Tafuto S</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Pratap S</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Powers BC</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Reichardt P</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Casado Herráez A</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Rutkowski P</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Tait C</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Zarins F</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Harrow B</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Sharma MG</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Ruiz-Soto R</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Sherman ML</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Blay JY</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Tap WD</span>; <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">MOTION investigators.
                  Vimseltinib versus placebo for tenosynovial giant cell tumour (MOTION): a multicentre, randomised,
                  double-blind, placebo-controlled, phase 3 trial</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Lancet.</span> 2024 Jun
                22;403(10445):2709-19.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=MOTION%20investigators.%20Vimseltinib%20versus%20placebo%20for%20tenosynovial%20giant%20cell%20tumour%20(MOTION)%3A%20a%20multicentre%2C%20randomised%2C%20double-blind%2C%20placebo-controlled%2C%20phase%203%20trial&as_occt=title&as_sauthors=%20%22H%20Gelderblom%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_266" class="annotation" style="position: unset;"> van der Heijden L</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Spierenburg G</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Kendal JK</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Bernthal NM</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> van de Sande MAJ</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Multimodal management of
                  tenosynovial giant cell tumors (TGCT) in the landscape of new druggable targets</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">J Surg Oncol.</span> 2023
                Sep;128(3):478-88.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Multimodal%20management%20of%20tenosynovial%20giant%20cell%20tumors%20(TGCT)%20in%20the%20landscape%20of%20new%20druggable%20targets&as_occt=title&as_sauthors=%20%22L%20van%20der%20Heijden%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_271" class="annotation" style="position: unset;"> LiBrizzi CL</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Agarwal AR</span>, <span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Chiu AK</span>, <span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Morris CD</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Thakkar SC</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Levin AS</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">The efficacy and safety of
                  direct oral anticoagulants compared with low-molecular-weight heparin for venous thromboembolism
                  prophylaxis after surgical resection of primary lower extremity bone or soft-tissue sarcoma</span>.
                <span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">J Surg Oncol.</span>
                2024 Mar;129(3):537-43.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20efficacy%20and%20safety%20of%20direct%20oral%20anticoagulants%20compared%20with%20low-molecular-weight%20heparin%20for%20venous%20thromboembolism%20prophylaxis%20after%20surgical%20resection%20of%20primary%20lower%20extremity%20bone%20or%20soft-tissue%20sarcoma&as_occt=title&as_sauthors=%20%22CL%20LiBrizzi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Foster D</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Sebro R</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Garner H</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Stanborough R</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Spaulding AC</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Goulding K</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Houdek M</span>, <span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Wilke B</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Intravenous tranexamic acid is
                  associated with an increased risk of pulmonary embolism following sarcoma resection</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">J Surg Oncol.</span> 2023
                Oct;128(5):869-76.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intravenous%20tranexamic%20acid%20is%20associated%20with%20an%20increased%20risk%20of%20pulmonary%20embolism%20following%20sarcoma%20resection&as_occt=title&as_sauthors=%20%22D%20Foster%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Ghert M</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Schneider P</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Guyatt G</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Thabane L</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Vélez R</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> O’Shea T</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Randall RL</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Turcotte R</span>, <span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Wilson D</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Wunder JS</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Baptista AM</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Cheng EY</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Doung YC</span>, <span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Ferguson PC</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Giglio V</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Hayden J</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Heels-Ansdell D</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Khan SA</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Sampath Kumar V</span>, <span
                  data-id="annotation_304" class="annotation" style="position: unset;"> McKay P</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Miller B</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> van de Sande M</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Zumárraga JP</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Bhandari M</span>; <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Prophylactic Antibiotic
                  Regimens in Tumor Surgery (PARITY) Investigators. Comparison of prophylactic intravenous antibiotic
                  regimens after endoprosthetic reconstruction for lower extremity bone tumors: a randomized clinical
                  trial</span>. <span data-id="emphasis_23" class="annotation emphasis" style="position: unset;">JAMA
                  Oncol.</span> 2022 Mar 1;8(3):345-53.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prophylactic%20Antibiotic%20Regimens%20in%20Tumor%20Surgery%20(PARITY)%20Investigators.%20Comparison%20of%20prophylactic%20intravenous%20antibiotic%20regimens%20after%20endoprosthetic%20reconstruction%20for%20lower%20extremity%20bone%20tumors%3A%20a%20randomized%20clinical%20trial&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Sabharwal S</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> LiBrizzi CL</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Forsberg JA</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Morris CD</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Levin AS</span>; <span
                  data-id="annotation_314" class="annotation" style="position: unset;"> PARITY Investigators</span>.
                <span data-id="strong_24" class="annotation strong" style="position: unset;">Incidence of and risk
                  factors for thromboembolism after endoprosthetic reconstruction in musculoskeletal oncology
                  patients</span>. <span data-id="emphasis_24" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2023 Jul 19;105(Suppl 1):29-33.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%20of%20and%20risk%20factors%20for%20thromboembolism%20after%20endoprosthetic%20reconstruction%20in%20musculoskeletal%20oncology%20patients&as_occt=title&as_sauthors=%20%22S%20Sabharwal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Jeys LM</span>, <span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Thorkildsen J</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Kurisunkal V</span>, <span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Puri A</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Ruggieri P</span>, <span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Houdek MT</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Boyle RA</span>, <span
                  data-id="annotation_322" class="annotation" style="position: unset;"> Ebeid W</span>, <span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Botello E</span>, <span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Morris GV</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Laitinen MK</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Abudu A</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Ae K</span>, <span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Agarwal M</span>, <span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Ajit Singh V</span>, <span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Akiyama T</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Albergo JI</span>, <span
                  data-id="annotation_332" class="annotation" style="position: unset;"> Alexander J</span>, <span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Alpan B</span>, <span
                  data-id="annotation_334" class="annotation" style="position: unset;"> Aoude A</span>, <span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Asavamongkolkul A</span>, <span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Aston W</span>, <span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Baad-Hansen T</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> Balach T</span>, <span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Benevenia J</span>, <span
                  data-id="annotation_340" class="annotation" style="position: unset;"> Bergh P</span>, <span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Bernthal N</span>, <span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Binitie O</span>, <span
                  data-id="annotation_343" class="annotation" style="position: unset;"> Boffano M</span>, <span
                  data-id="annotation_344" class="annotation" style="position: unset;"> Bramer J</span>, <span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Branford White H</span>, <span
                  data-id="annotation_346" class="annotation" style="position: unset;"> Brennan B</span>, <span
                  data-id="annotation_347" class="annotation" style="position: unset;"> Cabrolier J</span>, <span
                  data-id="annotation_348" class="annotation" style="position: unset;"> Calvo Haro JA</span>, <span
                  data-id="annotation_349" class="annotation" style="position: unset;"> Campanacci DA</span>, <span
                  data-id="annotation_350" class="annotation" style="position: unset;"> Cardoso R</span>, <span
                  data-id="annotation_351" class="annotation" style="position: unset;"> Carey Smith R</span>, <span
                  data-id="annotation_352" class="annotation" style="position: unset;"> Casales Fresnga N</span>, <span
                  data-id="annotation_353" class="annotation" style="position: unset;"> Casanova JM</span>, <span
                  data-id="annotation_354" class="annotation" style="position: unset;"> Ceballos O</span>, <span
                  data-id="annotation_355" class="annotation" style="position: unset;"> Chan CM</span>, <span
                  data-id="annotation_356" class="annotation" style="position: unset;"> Chung YG</span>, <span
                  data-id="annotation_357" class="annotation" style="position: unset;"> Clara-Altamirano MA</span>,
                <span data-id="annotation_358" class="annotation" style="position: unset;"> Cribb G</span>, <span
                  data-id="annotation_359" class="annotation" style="position: unset;"> Dadia S</span>, <span
                  data-id="annotation_360" class="annotation" style="position: unset;"> Dammerer D</span>, <span
                  data-id="annotation_361" class="annotation" style="position: unset;"> de Vaal M</span>, <span
                  data-id="annotation_362" class="annotation" style="position: unset;"> Delgado Obando J</span>, <span
                  data-id="annotation_363" class="annotation" style="position: unset;"> Deo S</span>, <span
                  data-id="annotation_364" class="annotation" style="position: unset;"> Di Bella C</span>, <span
                  data-id="annotation_365" class="annotation" style="position: unset;"> Donati DM</span>, <span
                  data-id="annotation_366" class="annotation" style="position: unset;"> Endo M</span>, <span
                  data-id="annotation_367" class="annotation" style="position: unset;"> Eralp L</span>, <span
                  data-id="annotation_368" class="annotation" style="position: unset;"> Erol B</span>, <span
                  data-id="annotation_369" class="annotation" style="position: unset;"> Evans S</span>, <span
                  data-id="annotation_370" class="annotation" style="position: unset;"> Eward W</span>, <span
                  data-id="annotation_371" class="annotation" style="position: unset;"> Fiorenza F</span>, <span
                  data-id="annotation_372" class="annotation" style="position: unset;"> Freitas J</span>, <span
                  data-id="annotation_373" class="annotation" style="position: unset;"> Funovics PT</span>, <span
                  data-id="annotation_374" class="annotation" style="position: unset;"> Galli Serra M</span>, <span
                  data-id="annotation_375" class="annotation" style="position: unset;"> Ghert M</span>, <span
                  data-id="annotation_376" class="annotation" style="position: unset;"> Ghosh K</span>, <span
                  data-id="annotation_377" class="annotation" style="position: unset;"> Gomez Mier LC</span>, <span
                  data-id="annotation_378" class="annotation" style="position: unset;"> Gomez Vallejo J</span>, <span
                  data-id="annotation_379" class="annotation" style="position: unset;"> Griffin A</span>, <span
                  data-id="annotation_380" class="annotation" style="position: unset;"> Gulia A</span>, <span
                  data-id="annotation_381" class="annotation" style="position: unset;"> Guzman M</span>, <span
                  data-id="annotation_382" class="annotation" style="position: unset;"> Hardes J</span>, <span
                  data-id="annotation_383" class="annotation" style="position: unset;"> Healey J</span>, <span
                  data-id="annotation_384" class="annotation" style="position: unset;"> Hernandez A</span>, <span
                  data-id="annotation_385" class="annotation" style="position: unset;"> Hesla A</span>, <span
                  data-id="annotation_386" class="annotation" style="position: unset;"> Hongsaprabhas C</span>, <span
                  data-id="annotation_387" class="annotation" style="position: unset;"> Hornicek F</span>, <span
                  data-id="annotation_388" class="annotation" style="position: unset;"> Hosking K</span>, <span
                  data-id="annotation_389" class="annotation" style="position: unset;"> Iwata S</span>, <span
                  data-id="annotation_390" class="annotation" style="position: unset;"> Jagiello J</span>, <span
                  data-id="annotation_391" class="annotation" style="position: unset;"> Johnson L</span>, <span
                  data-id="annotation_392" class="annotation" style="position: unset;"> Johnston A</span>, <span
                  data-id="annotation_393" class="annotation" style="position: unset;"> Joo MW</span>, <span
                  data-id="annotation_394" class="annotation" style="position: unset;"> Jutte P</span>, <span
                  data-id="annotation_395" class="annotation" style="position: unset;"> Kapanci B</span>, <span
                  data-id="annotation_396" class="annotation" style="position: unset;"> Khan Z</span>, <span
                  data-id="annotation_397" class="annotation" style="position: unset;"> Kobayashi H</span>, <span
                  data-id="annotation_398" class="annotation" style="position: unset;"> Kollender Y</span>, <span
                  data-id="annotation_399" class="annotation" style="position: unset;"> Koob S</span>, <span
                  data-id="annotation_400" class="annotation" style="position: unset;"> Kotrych D</span>, <span
                  data-id="annotation_401" class="annotation" style="position: unset;"> Le Nail LR</span>, <span
                  data-id="annotation_402" class="annotation" style="position: unset;"> Legosz P</span>, <span
                  data-id="annotation_403" class="annotation" style="position: unset;"> Lehner B</span>, <span
                  data-id="annotation_404" class="annotation" style="position: unset;"> Leithner A</span>, <span
                  data-id="annotation_405" class="annotation" style="position: unset;"> Lewis V</span>, <span
                  data-id="annotation_406" class="annotation" style="position: unset;"> Lin P</span>, <span
                  data-id="annotation_407" class="annotation" style="position: unset;"> Linares F</span>, <span
                  data-id="annotation_408" class="annotation" style="position: unset;"> Lozano Calderon S</span>, <span
                  data-id="annotation_409" class="annotation" style="position: unset;"> Mahendra A</span>, <span
                  data-id="annotation_410" class="annotation" style="position: unset;"> Mahyudin F</span>, <span
                  data-id="annotation_411" class="annotation" style="position: unset;"> Mascard E</span>, <span
                  data-id="annotation_412" class="annotation" style="position: unset;"> Mattei JC</span>, <span
                  data-id="annotation_413" class="annotation" style="position: unset;"> McCullough L</span>, <span
                  data-id="annotation_414" class="annotation" style="position: unset;"> Medellin Rincon MR</span>, <span
                  data-id="annotation_415" class="annotation" style="position: unset;"> Morgan-Jones R</span>, <span
                  data-id="annotation_416" class="annotation" style="position: unset;"> Moriel Garcesco DJ</span>, <span
                  data-id="annotation_417" class="annotation" style="position: unset;"> Mottard S</span>, <span
                  data-id="annotation_418" class="annotation" style="position: unset;"> Nakayama R</span>, <span
                  data-id="annotation_419" class="annotation" style="position: unset;"> Narhari P</span>, <span
                  data-id="annotation_420" class="annotation" style="position: unset;"> O’Toole G</span>, <span
                  data-id="annotation_421" class="annotation" style="position: unset;"> Vania O</span>, <span
                  data-id="annotation_422" class="annotation" style="position: unset;"> Olivier A</span>, <span
                  data-id="annotation_423" class="annotation" style="position: unset;"> Omar M</span>, <span
                  data-id="annotation_424" class="annotation" style="position: unset;"> Ortiz-Cruz E</span>, <span
                  data-id="annotation_425" class="annotation" style="position: unset;"> Ozger H</span>, <span
                  data-id="annotation_426" class="annotation" style="position: unset;"> Ozkan K</span>, <span
                  data-id="annotation_427" class="annotation" style="position: unset;"> Palmerini E</span>, <span
                  data-id="annotation_428" class="annotation" style="position: unset;"> Papagelopoulos P</span>, <span
                  data-id="annotation_429" class="annotation" style="position: unset;"> Parry M</span>, <span
                  data-id="annotation_430" class="annotation" style="position: unset;"> Patton S</span>, <span
                  data-id="annotation_431" class="annotation" style="position: unset;"> Petersen MM</span>, <span
                  data-id="annotation_432" class="annotation" style="position: unset;"> Powell G</span>, <span
                  data-id="annotation_433" class="annotation" style="position: unset;"> Puhaindran M</span>, <span
                  data-id="annotation_434" class="annotation" style="position: unset;"> Raja A</span>, <span
                  data-id="annotation_435" class="annotation" style="position: unset;"> Rajasekaran RB</span>, <span
                  data-id="annotation_436" class="annotation" style="position: unset;"> Repsa L</span>, <span
                  data-id="annotation_437" class="annotation" style="position: unset;"> Ropars M</span>, <span
                  data-id="annotation_438" class="annotation" style="position: unset;"> Sambri A</span>, <span
                  data-id="annotation_439" class="annotation" style="position: unset;"> Schubert T</span>, <span
                  data-id="annotation_440" class="annotation" style="position: unset;"> Shehadeh A</span>, <span
                  data-id="annotation_441" class="annotation" style="position: unset;"> Siegel G</span>, <span
                  data-id="annotation_442" class="annotation" style="position: unset;"> Sommerville S</span>, <span
                  data-id="annotation_443" class="annotation" style="position: unset;"> Spiguel A</span>, <span
                  data-id="annotation_444" class="annotation" style="position: unset;"> Stevenson J</span>, <span
                  data-id="annotation_445" class="annotation" style="position: unset;"> Sys G</span>, <span
                  data-id="annotation_446" class="annotation" style="position: unset;"> Temple T</span>, <span
                  data-id="annotation_447" class="annotation" style="position: unset;"> Traub F</span>, <span
                  data-id="annotation_448" class="annotation" style="position: unset;"> Tsuchiya H</span>, <span
                  data-id="annotation_449" class="annotation" style="position: unset;"> Valencia J</span>, <span
                  data-id="annotation_450" class="annotation" style="position: unset;"> Van de Sande M</span>, <span
                  data-id="annotation_451" class="annotation" style="position: unset;"> Vaz G</span>, <span
                  data-id="annotation_452" class="annotation" style="position: unset;"> Velez Villa R</span>, <span
                  data-id="annotation_453" class="annotation" style="position: unset;"> Vyrva O</span>, <span
                  data-id="annotation_454" class="annotation" style="position: unset;"> Wafa H</span>, <span
                  data-id="annotation_455" class="annotation" style="position: unset;"> Wan Faisham Numan WI</span>,
                <span data-id="annotation_456" class="annotation" style="position: unset;"> Wang E</span>, <span
                  data-id="annotation_457" class="annotation" style="position: unset;"> Warnock D</span>, <span
                  data-id="annotation_458" class="annotation" style="position: unset;"> Werier J</span>, <span
                  data-id="annotation_459" class="annotation" style="position: unset;"> Wong KC</span>, <span
                  data-id="annotation_460" class="annotation" style="position: unset;"> Norio Y</span>, <span
                  data-id="annotation_461" class="annotation" style="position: unset;"> Zhaoming Y</span>, <span
                  data-id="annotation_462" class="annotation" style="position: unset;"> Zainul Abidin S</span>, <span
                  data-id="annotation_463" class="annotation" style="position: unset;"> Zamora T</span>, <span
                  data-id="annotation_464" class="annotation" style="position: unset;"> Zumarraga JP</span>, <span
                  data-id="annotation_465" class="annotation" style="position: unset;"> Abou-Nouar G</span>, <span
                  data-id="annotation_466" class="annotation" style="position: unset;"> Gebert C</span>, <span
                  data-id="annotation_467" class="annotation" style="position: unset;"> Randall RL</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Controversies in orthopaedic
                  oncology</span>. <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Bone
                  Joint J.</span> 2024 May 1;106-B(5):425-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Controversies%20in%20orthopaedic%20oncology&as_occt=title&as_sauthors=%20%22LM%20Jeys%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_468" class="annotation" style="position: unset;"> Gazendam AM</span>, <span
                  data-id="annotation_469" class="annotation" style="position: unset;"> Ghert M</span>, <span
                  data-id="annotation_470" class="annotation" style="position: unset;"> Gundle KR</span>, <span
                  data-id="annotation_471" class="annotation" style="position: unset;"> Hayden JB</span>, <span
                  data-id="annotation_472" class="annotation" style="position: unset;"> Doung YC</span>; <span
                  data-id="annotation_473" class="annotation" style="position: unset;"> PARITY Investigators</span>.
                <span data-id="strong_26" class="annotation strong" style="position: unset;">Opioid use in surgical
                  management in musculoskeletal oncology</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2023 Jul 19;105(Suppl 1):10-14.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Opioid%20use%20in%20surgical%20management%20in%20musculoskeletal%20oncology&as_occt=title&as_sauthors=%20%22AM%20Gazendam%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_474" class="annotation" style="position: unset;"> Gitto S</span>, <span
                  data-id="annotation_475" class="annotation" style="position: unset;"> Annovazzi A</span>, <span
                  data-id="annotation_476" class="annotation" style="position: unset;"> Nulle K</span>, <span
                  data-id="annotation_477" class="annotation" style="position: unset;"> Interlenghi M</span>, <span
                  data-id="annotation_478" class="annotation" style="position: unset;"> Salvatore C</span>, <span
                  data-id="annotation_479" class="annotation" style="position: unset;"> Anelli V</span>, <span
                  data-id="annotation_480" class="annotation" style="position: unset;"> Baldi J</span>, <span
                  data-id="annotation_481" class="annotation" style="position: unset;"> Messina C</span>, <span
                  data-id="annotation_482" class="annotation" style="position: unset;"> Albano D</span>, <span
                  data-id="annotation_483" class="annotation" style="position: unset;"> Di Luca F</span>, <span
                  data-id="annotation_484" class="annotation" style="position: unset;"> Armiraglio E</span>, <span
                  data-id="annotation_485" class="annotation" style="position: unset;"> Parafioriti A</span>, <span
                  data-id="annotation_486" class="annotation" style="position: unset;"> Luzzati A</span>, <span
                  data-id="annotation_487" class="annotation" style="position: unset;"> Biagini R</span>, <span
                  data-id="annotation_488" class="annotation" style="position: unset;"> Castiglioni I</span>, <span
                  data-id="annotation_489" class="annotation" style="position: unset;"> Sconfienza LM</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">X-rays radiomics-based machine
                  learning classification of atypical cartilaginous tumour and high-grade chondrosarcoma of long
                  bones</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">EBioMedicine.</span> 2024 Mar;101:105018.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=X-rays%20radiomics-based%20machine%20learning%20classification%20of%20atypical%20cartilaginous%20tumour%20and%20high-grade%20chondrosarcoma%20of%20long%20bones&as_occt=title&as_sauthors=%20%22S%20Gitto%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_490" class="annotation" style="position: unset;"> Gundavda MK</span>, <span
                  data-id="annotation_491" class="annotation" style="position: unset;"> Lazarides AL</span>, <span
                  data-id="annotation_492" class="annotation" style="position: unset;"> Burke ZDC</span>, <span
                  data-id="annotation_493" class="annotation" style="position: unset;"> Tsoi K</span>, <span
                  data-id="annotation_494" class="annotation" style="position: unset;"> Ferguson PC</span>, <span
                  data-id="annotation_495" class="annotation" style="position: unset;"> Wunder JS</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Reconstructive allograft
                  preparation for long bone intercalary segments after tumor resections: Toronto Sarcoma
                  Protocol</span>. <span data-id="emphasis_28" class="annotation emphasis" style="position: unset;">JBJS
                  Essent Surg Tech.</span> 2023 May 24;13(2):e22.00011.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reconstructive%20allograft%20preparation%20for%20long%20bone%20intercalary%20segments%20after%20tumor%20resections%3A%20Toronto%20Sarcoma%20Protocol&as_occt=title&as_sauthors=%20%22MK%20Gundavda%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_496" class="annotation" style="position: unset;"> Scholte CHJ</span>, <span
                  data-id="annotation_497" class="annotation" style="position: unset;"> Dorleijn DMJ</span>, <span
                  data-id="annotation_498" class="annotation" style="position: unset;"> Krijvenaar DT</span>, <span
                  data-id="annotation_499" class="annotation" style="position: unset;"> van de Sande MAJ</span>, <span
                  data-id="annotation_500" class="annotation" style="position: unset;"> van Langevelde K</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Wait-and-scan: an alternative
                  for curettage in atypical cartilaginous tumours of the long bones</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2024 Jan
                1;106-B(1):86-92.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Wait-and-scan%3A%20an%20alternative%20for%20curettage%20in%20atypical%20cartilaginous%20tumours%20of%20the%20long%20bones&as_occt=title&as_sauthors=%20%22CHJ%20Scholte%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_501" class="annotation" style="position: unset;"> Trovarelli G</span>, <span
                  data-id="annotation_502" class="annotation" style="position: unset;"> Sbaraglia M</span>, <span
                  data-id="annotation_503" class="annotation" style="position: unset;"> Angelini A</span>, <span
                  data-id="annotation_504" class="annotation" style="position: unset;"> Bellan E</span>, <span
                  data-id="annotation_505" class="annotation" style="position: unset;"> Pala E</span>, <span
                  data-id="annotation_506" class="annotation" style="position: unset;"> Belluzzi E</span>, <span
                  data-id="annotation_507" class="annotation" style="position: unset;"> Pozzuoli A</span>, <span
                  data-id="annotation_508" class="annotation" style="position: unset;"> Borga C</span>, <span
                  data-id="annotation_509" class="annotation" style="position: unset;"> Dei Tos AP</span>, <span
                  data-id="annotation_510" class="annotation" style="position: unset;"> Ruggieri P</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Are IDH1 R132 mutations
                  associated with poor prognosis in patients with chondrosarcoma of the bone?</span><span
                  data-id="emphasis_30" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2024 Jan 3;482(6):947-56.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Are%20IDH1%20R132%20mutations%20associated%20with%20poor%20prognosis%20in%20patients%20with%20chondrosarcoma%20of%20the%20bone%3F&as_occt=title&as_sauthors=%20%22G%20Trovarelli%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_511" class="annotation" style="position: unset;"> Rey V</span>, <span
                  data-id="annotation_512" class="annotation" style="position: unset;"> Tornín J</span>, <span
                  data-id="annotation_513" class="annotation" style="position: unset;"> Alba-Linares JJ</span>, <span
                  data-id="annotation_514" class="annotation" style="position: unset;"> Robledo C</span>, <span
                  data-id="annotation_515" class="annotation" style="position: unset;"> Murillo D</span>, <span
                  data-id="annotation_516" class="annotation" style="position: unset;"> Rodríguez A</span>, <span
                  data-id="annotation_517" class="annotation" style="position: unset;"> Gallego B</span>, <span
                  data-id="annotation_518" class="annotation" style="position: unset;"> Huergo C</span>, <span
                  data-id="annotation_519" class="annotation" style="position: unset;"> Viera C</span>, <span
                  data-id="annotation_520" class="annotation" style="position: unset;"> Braña A</span>, <span
                  data-id="annotation_521" class="annotation" style="position: unset;"> Astudillo A</span>, <span
                  data-id="annotation_522" class="annotation" style="position: unset;"> Heymann D</span>, <span
                  data-id="annotation_523" class="annotation" style="position: unset;"> Szuhai K</span>, <span
                  data-id="annotation_524" class="annotation" style="position: unset;"> Bovée JVMG</span>, <span
                  data-id="annotation_525" class="annotation" style="position: unset;"> Fernández AF</span>, <span
                  data-id="annotation_526" class="annotation" style="position: unset;"> Fraga MF</span>, <span
                  data-id="annotation_527" class="annotation" style="position: unset;"> Alonso J</span>, <span
                  data-id="annotation_528" class="annotation" style="position: unset;"> Rodríguez R</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">A personalized medicine
                  approach identifies enasidenib as an efficient treatment for IDH2 mutant chondrosarcoma</span>. <span
                  data-id="emphasis_31" class="annotation emphasis" style="position: unset;">EBioMedicine.</span> 2024
                Apr;102:105090.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20personalized%20medicine%20approach%20identifies%20enasidenib%20as%20an%20efficient%20treatment%20for%20IDH2%20mutant%20chondrosarcoma&as_occt=title&as_sauthors=%20%22V%20Rey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_529" class="annotation" style="position: unset;"> Bozzo A</span>, <span
                  data-id="annotation_530" class="annotation" style="position: unset;"> Aysola V</span>, <span
                  data-id="annotation_531" class="annotation" style="position: unset;"> Yeung CM</span>, <span
                  data-id="annotation_532" class="annotation" style="position: unset;"> Healey JH</span>, <span
                  data-id="annotation_533" class="annotation" style="position: unset;"> Prince DE</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Distraction osteogenesis
                  reconstruction following resection of bone sarcomas: surgical, functional, and oncological outcomes
                  from a prospective trial analysis</span>. <span data-id="emphasis_32" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2024 Jul;106(13):1205.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=57382ccf-961f-4c61-a6b3-39b14e1d6fa5" target="_new"
                  class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_534" class="annotation" style="position: unset;"> Bruschi A</span>, <span
                  data-id="annotation_535" class="annotation" style="position: unset;"> Cevolani L</span>, <span
                  data-id="annotation_536" class="annotation" style="position: unset;"> Minerba A</span>, <span
                  data-id="annotation_537" class="annotation" style="position: unset;"> Conversano D</span>, <span
                  data-id="annotation_538" class="annotation" style="position: unset;"> Falzetti L</span>, <span
                  data-id="annotation_539" class="annotation" style="position: unset;"> Pasini S</span>, <span
                  data-id="annotation_540" class="annotation" style="position: unset;"> Donati DM</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Extended distal femur
                  resection: megaprosthesis with telescopic bone allograft augmentation versus total femur
                  prosthesis</span>. <span data-id="emphasis_33" class="annotation emphasis" style="position: unset;">J
                  Surg Oncol.</span> 2024 Jun;129(8):1568-76.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Extended%20distal%20femur%20resection%3A%20megaprosthesis%20with%20telescopic%20bone%20allograft%20augmentation%20versus%20total%20femur%20prosthesis&as_occt=title&as_sauthors=%20%22A%20Bruschi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_541" class="annotation" style="position: unset;"> Errani C</span>, <span
                  data-id="annotation_542" class="annotation" style="position: unset;"> Aiba H</span>, <span
                  data-id="annotation_543" class="annotation" style="position: unset;"> Atherley A</span>, <span
                  data-id="annotation_544" class="annotation" style="position: unset;"> Palmas M</span>, <span
                  data-id="annotation_545" class="annotation" style="position: unset;"> Kimura H</span>, <span
                  data-id="annotation_546" class="annotation" style="position: unset;"> Donati DM</span>, <span
                  data-id="annotation_547" class="annotation" style="position: unset;"> Manfrini M</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">What is the revision-free
                  survival of resurfaced allograft-prosthesis composites for proximal humerus reconstruction in children
                  with bone tumors?</span><span data-id="emphasis_34" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2024 Jan 23;482(6):979-90.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20is%20the%20revision-free%20survival%20of%20resurfaced%20allograft-prosthesis%20composites%20for%20proximal%20humerus%20reconstruction%20in%20children%20with%20bone%20tumors%3F&as_occt=title&as_sauthors=%20%22C%20Errani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_548" class="annotation" style="position: unset;"> Campanacci DA</span>, <span
                  data-id="annotation_549" class="annotation" style="position: unset;"> Scanferla R</span>, <span
                  data-id="annotation_550" class="annotation" style="position: unset;"> Marsico M</span>, <span
                  data-id="annotation_551" class="annotation" style="position: unset;"> Scolari F</span>, <span
                  data-id="annotation_552" class="annotation" style="position: unset;"> Scoccianti G</span>, <span
                  data-id="annotation_553" class="annotation" style="position: unset;"> Beltrami G</span>, <span
                  data-id="annotation_554" class="annotation" style="position: unset;"> Delcroix L</span>, <span
                  data-id="annotation_555" class="annotation" style="position: unset;"> Innocenti M</span>, <span
                  data-id="annotation_556" class="annotation" style="position: unset;"> Capanna R</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Intercalary resection of the
                  tibia for primary bone tumors: are vascularized fibula autografts with or without allografts a durable
                  reconstruction?</span><span data-id="emphasis_35" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2024 Mar 21;482(6):960-75.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intercalary%20resection%20of%20the%20tibia%20for%20primary%20bone%20tumors%3A%20are%20vascularized%20fibula%20autografts%20with%20or%20without%20allografts%20a%20durable%20reconstruction%3F&as_occt=title&as_sauthors=%20%22DA%20Campanacci%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_557" class="annotation" style="position: unset;"> Shahzad F</span>, <span
                  data-id="annotation_558" class="annotation" style="position: unset;"> Christ AB</span>, <span
                  data-id="annotation_559" class="annotation" style="position: unset;"> Kim L</span>, <span
                  data-id="annotation_560" class="annotation" style="position: unset;"> Levy AS</span>, <span
                  data-id="annotation_561" class="annotation" style="position: unset;"> Teven CM</span>, <span
                  data-id="annotation_562" class="annotation" style="position: unset;"> Fabbri N</span>, <span
                  data-id="annotation_563" class="annotation" style="position: unset;"> Nelson JA</span>, <span
                  data-id="annotation_564" class="annotation" style="position: unset;"> Healey JH</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Tandem reconstruction of the
                  femoral diaphysis using an intercalary prosthesis and a fibular free flap</span>. <span
                  data-id="emphasis_36" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2024 Mar 6;106(5):425-34.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=0b24beae-b7f0-4e4a-801b-2d57b4d8a4d3" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_565" class="annotation" style="position: unset;"> Shahzad F</span>, <span
                  data-id="annotation_566" class="annotation" style="position: unset;"> Fazzalari A</span>, <span
                  data-id="annotation_567" class="annotation" style="position: unset;"> Zoghbi Y</span>, <span
                  data-id="annotation_568" class="annotation" style="position: unset;"> Coriddi MR</span>, <span
                  data-id="annotation_569" class="annotation" style="position: unset;"> Chapman TR</span>, <span
                  data-id="annotation_570" class="annotation" style="position: unset;"> Mehrara BJ</span>, <span
                  data-id="annotation_571" class="annotation" style="position: unset;"> Disa JJ</span>, <span
                  data-id="annotation_572" class="annotation" style="position: unset;"> Cordeiro PG</span>, <span
                  data-id="annotation_573" class="annotation" style="position: unset;"> Healey J</span>, <span
                  data-id="annotation_574" class="annotation" style="position: unset;"> Athanasian E</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Reconstruction of oncologic
                  upper extremity defects with fibula free flaps has high union rates and excellent functional
                  outcomes</span>. <span data-id="emphasis_37" class="annotation emphasis" style="position: unset;">J
                  Surg Oncol.</span> 2023 Dec;128(8):1416-27.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reconstruction%20of%20oncologic%20upper%20extremity%20defects%20with%20fibula%20free%20flaps%20has%20high%20union%20rates%20and%20excellent%20functional%20outcomes&as_occt=title&as_sauthors=%20%22F%20Shahzad%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_575" class="annotation" style="position: unset;"> Kendal JK</span>, <span
                  data-id="annotation_576" class="annotation" style="position: unset;"> Slawaska-Eng D</span>, <span
                  data-id="annotation_577" class="annotation" style="position: unset;"> Gazendam A</span>, <span
                  data-id="annotation_578" class="annotation" style="position: unset;"> Schneider P</span>, <span
                  data-id="annotation_579" class="annotation" style="position: unset;"> Wessel LE</span>, <span
                  data-id="annotation_580" class="annotation" style="position: unset;"> Ghert M</span>, <span
                  data-id="annotation_581" class="annotation" style="position: unset;"> Bernthal NM</span>; <span
                  data-id="annotation_582" class="annotation" style="position: unset;"> PARITY Investigators</span>.
                <span data-id="strong_38" class="annotation strong" style="position: unset;">Risk factors for all-cause
                  early reoperation following tumor resection and endoprosthetic reconstruction: a secondary analysis
                  from the PARITY trial</span>. <span data-id="emphasis_38" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2023 Jul 19;105(Suppl 1):4-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20for%20all-cause%20early%20reoperation%20following%20tumor%20resection%20and%20endoprosthetic%20reconstruction%3A%20a%20secondary%20analysis%20from%20the%20PARITY%20trial&as_occt=title&as_sauthors=%20%22JK%20Kendal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_583" class="annotation" style="position: unset;"> Mori T</span>, <span
                  data-id="annotation_584" class="annotation" style="position: unset;"> Kobayashi E</span>, <span
                  data-id="annotation_585" class="annotation" style="position: unset;"> Sato Y</span>, <span
                  data-id="annotation_586" class="annotation" style="position: unset;"> Takenaka S</span>, <span
                  data-id="annotation_587" class="annotation" style="position: unset;"> Endo M</span>, <span
                  data-id="annotation_588" class="annotation" style="position: unset;"> Nakamura T</span>, <span
                  data-id="annotation_589" class="annotation" style="position: unset;"> Morii T</span>, <span
                  data-id="annotation_590" class="annotation" style="position: unset;"> Yoshida Y</span>, <span
                  data-id="annotation_591" class="annotation" style="position: unset;"> Ueda T</span>, <span
                  data-id="annotation_592" class="annotation" style="position: unset;"> Kawano H</span>, <span
                  data-id="annotation_593" class="annotation" style="position: unset;"> Kawai A</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">What are the complication rates
                  and factors associated with total femur replacement after tumor resection? Findings from the Japanese
                  Musculoskeletal Oncology Group</span>. <span data-id="emphasis_39" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2024 Apr 1;482(4):702-12.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20are%20the%20complication%20rates%20and%20factors%20associated%20with%20total%20femur%20replacement%20after%20tumor%20resection%3F%20Findings%20from%20the%20Japanese%20Musculoskeletal%20Oncology%20Group&as_occt=title&as_sauthors=%20%22T%20Mori%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_594" class="annotation" style="position: unset;"> Giglio V</span>, <span
                  data-id="annotation_595" class="annotation" style="position: unset;"> Gazendam A</span>, <span
                  data-id="annotation_596" class="annotation" style="position: unset;"> Schneider P</span>, <span
                  data-id="annotation_597" class="annotation" style="position: unset;"> Ghert M</span>, <span
                  data-id="annotation_598" class="annotation" style="position: unset;"> Wilson D</span>; <span
                  data-id="annotation_599" class="annotation" style="position: unset;"> PARITY Investigators</span>.
                <span data-id="strong_40" class="annotation strong" style="position: unset;">The impact of implant
                  fixation type on reoperation rates following endoprosthetic reconstruction surgery</span>. <span
                  data-id="emphasis_40" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2023 Jul 19;105(Suppl 1):15-21.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20impact%20of%20implant%20fixation%20type%20on%20reoperation%20rates%20following%20endoprosthetic%20reconstruction%20surgery&as_occt=title&as_sauthors=%20%22V%20Giglio%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_600" class="annotation" style="position: unset;"> Labott JR</span>, <span
                  data-id="annotation_601" class="annotation" style="position: unset;"> Heidenreich MJ</span>, <span
                  data-id="annotation_602" class="annotation" style="position: unset;"> Broida SE</span>, <span
                  data-id="annotation_603" class="annotation" style="position: unset;"> Mills GL</span>, <span
                  data-id="annotation_604" class="annotation" style="position: unset;"> Rose PS</span>, <span
                  data-id="annotation_605" class="annotation" style="position: unset;"> Houdek MT</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Durability of intercalary
                  endoprosthesis for humeral reconstruction</span>. <span data-id="emphasis_41"
                  class="annotation emphasis" style="position: unset;">J Surg Oncol.</span> 2024
                Feb;129(2):410-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Durability%20of%20intercalary%20endoprosthesis%20for%20humeral%20reconstruction&as_occt=title&as_sauthors=%20%22JR%20Labott%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_606" class="annotation" style="position: unset;"> Tran TH</span>, <span
                  data-id="annotation_607" class="annotation" style="position: unset;"> Hayden JB</span>, <span
                  data-id="annotation_608" class="annotation" style="position: unset;"> Gazendam AM</span>, <span
                  data-id="annotation_609" class="annotation" style="position: unset;"> Ghert M</span>, <span
                  data-id="annotation_610" class="annotation" style="position: unset;"> Gundle KR</span>, <span
                  data-id="annotation_611" class="annotation" style="position: unset;"> Doung YC</span>; <span
                  data-id="annotation_612" class="annotation" style="position: unset;"> PARITY Investigators</span>.
                <span data-id="strong_42" class="annotation strong" style="position: unset;">Pediatric and adult
                  patients have similar functional improvement after endoprosthetic reconstruction of lower-extremity
                  tumors</span>. <span data-id="emphasis_42" class="annotation emphasis" style="position: unset;">J Bone
                  Joint Surg Am.</span> 2023 Jul 19;105(Suppl 1):22-28.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pediatric%20and%20adult%20patients%20have%20similar%20functional%20improvement%20after%20endoprosthetic%20reconstruction%20of%20lower-extremity%20tumors&as_occt=title&as_sauthors=%20%22TH%20Tran%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_613" class="annotation" style="position: unset;"> Evenhuis RE</span>, <span
                  data-id="annotation_614" class="annotation" style="position: unset;"> van de Sande MAJ</span>, <span
                  data-id="annotation_615" class="annotation" style="position: unset;"> Fiocco M</span>, <span
                  data-id="annotation_616" class="annotation" style="position: unset;"> Dierselhuis EF</span>, <span
                  data-id="annotation_617" class="annotation" style="position: unset;"> Broekhuis D</span>, <span
                  data-id="annotation_618" class="annotation" style="position: unset;"> Bus MPA</span>; and the LUMiC®
                Study Group. <span data-id="strong_43" class="annotation strong" style="position: unset;">LUMiC
                  endoprosthetic reconstruction of periacetabular tumor defects: a multicenter follow-up study</span>.
                <span data-id="emphasis_43" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2024 Jul 17;106(14):1309-16.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=c618c09e-6cb8-435b-b63a-241402d5321e" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_619" class="annotation" style="position: unset;"> Zhang L</span>, <span
                  data-id="annotation_620" class="annotation" style="position: unset;"> Iwata S</span>, <span
                  data-id="annotation_621" class="annotation" style="position: unset;"> Saito M</span>, <span
                  data-id="annotation_622" class="annotation" style="position: unset;"> Nakagawa M</span>, <span
                  data-id="annotation_623" class="annotation" style="position: unset;"> Tsukushi S</span>, <span
                  data-id="annotation_624" class="annotation" style="position: unset;"> Yoshida S</span>, <span
                  data-id="annotation_625" class="annotation" style="position: unset;"> Gokita T</span>, <span
                  data-id="annotation_626" class="annotation" style="position: unset;"> Ae K</span>, <span
                  data-id="annotation_627" class="annotation" style="position: unset;"> Nakashima S</span>, <span
                  data-id="annotation_628" class="annotation" style="position: unset;"> Watanuki M</span>, <span
                  data-id="annotation_629" class="annotation" style="position: unset;"> Akiyama T</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Hip transposition can provide
                  early walking function after periacetabular tumor resection: a multicenter study</span>. <span
                  data-id="emphasis_44" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2023 Dec 1;481(12):2406-16.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20transposition%20can%20provide%20early%20walking%20function%20after%20periacetabular%20tumor%20resection%3A%20a%20multicenter%20study&as_occt=title&as_sauthors=%20%22L%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_630" class="annotation" style="position: unset;"> Wang PQ</span>, <span
                  data-id="annotation_631" class="annotation" style="position: unset;"> Gazendam A</span>, <span
                  data-id="annotation_632" class="annotation" style="position: unset;"> Ibe I</span>, <span
                  data-id="annotation_633" class="annotation" style="position: unset;"> Kim N</span>, <span
                  data-id="annotation_634" class="annotation" style="position: unset;"> Alfaraidy M</span>, <span
                  data-id="annotation_635" class="annotation" style="position: unset;"> Eastley N</span>, <span
                  data-id="annotation_636" class="annotation" style="position: unset;"> Griffin A</span>, <span
                  data-id="annotation_637" class="annotation" style="position: unset;"> Wunder J</span>, <span
                  data-id="annotation_638" class="annotation" style="position: unset;"> Ferguson P</span>, <span
                  data-id="annotation_639" class="annotation" style="position: unset;"> Tsoi K</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">Obesity increases the risk of
                  major wound complications following pelvic resection for bone sarcoma</span>. <span
                  data-id="emphasis_45" class="annotation emphasis" style="position: unset;">J Surg Oncol.</span> 2024
                Aug;130(2):293-300.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Obesity%20increases%20the%20risk%20of%20major%20wound%20complications%20following%20pelvic%20resection%20for%20bone%20sarcoma&as_occt=title&as_sauthors=%20%22PQ%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_640" class="annotation" style="position: unset;"> Vucicevic RS</span>, <span
                  data-id="annotation_641" class="annotation" style="position: unset;"> Zavras AG</span>, <span
                  data-id="annotation_642" class="annotation" style="position: unset;"> Fice MP</span>, <span
                  data-id="annotation_643" class="annotation" style="position: unset;"> Khan ZA</span>, <span
                  data-id="annotation_644" class="annotation" style="position: unset;"> Gusho C</span>, <span
                  data-id="annotation_645" class="annotation" style="position: unset;"> Gasparro M</span>, <span
                  data-id="annotation_646" class="annotation" style="position: unset;"> Gitelis S</span>, <span
                  data-id="annotation_647" class="annotation" style="position: unset;"> Blank A</span>, <span
                  data-id="annotation_648" class="annotation" style="position: unset;"> Colman MW</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Internal hemipelvectomy: a
                  single institution’s learning curve and longitudinal experience</span>. <span data-id="emphasis_46"
                  class="annotation emphasis" style="position: unset;">J Surg Oncol.</span> 2024
                Apr;129(5):981-94.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Internal%20hemipelvectomy%3A%20a%20single%20institution%E2%80%99s%20learning%20curve%20and%20longitudinal%20experience&as_occt=title&as_sauthors=%20%22RS%20Vucicevic%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_649" class="annotation" style="position: unset;"> Broida SE</span>, <span
                  data-id="annotation_650" class="annotation" style="position: unset;"> Tsoi KM</span>, <span
                  data-id="annotation_651" class="annotation" style="position: unset;"> Rose PS</span>, <span
                  data-id="annotation_652" class="annotation" style="position: unset;"> Ferguson PC</span>, <span
                  data-id="annotation_653" class="annotation" style="position: unset;"> Griffin AM</span>, <span
                  data-id="annotation_654" class="annotation" style="position: unset;"> Wunder JS</span>, <span
                  data-id="annotation_655" class="annotation" style="position: unset;"> Houdek MT</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Reconstruction following
                  oncological iliosacral resection</span>. <span data-id="emphasis_47" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2024 Jan 1;106-B(1):93-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reconstruction%20following%20oncological%20iliosacral%20resection&as_occt=title&as_sauthors=%20%22SE%20Broida%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_656" class="annotation" style="position: unset;"> Slawaska-Eng D</span>, <span
                  data-id="annotation_657" class="annotation" style="position: unset;"> Gazendam AM</span>, <span
                  data-id="annotation_658" class="annotation" style="position: unset;"> Kendal J</span>, <span
                  data-id="annotation_659" class="annotation" style="position: unset;"> Schneider P</span>, <span
                  data-id="annotation_660" class="annotation" style="position: unset;"> Becker RG</span>, <span
                  data-id="annotation_661" class="annotation" style="position: unset;"> Freitas JP</span>, <span
                  data-id="annotation_662" class="annotation" style="position: unset;"> Bernthal N</span>, <span
                  data-id="annotation_663" class="annotation" style="position: unset;"> Ghert M</span>; <span
                  data-id="annotation_664" class="annotation" style="position: unset;"> PARITY Investigators</span>.
                <span data-id="strong_48" class="annotation strong" style="position: unset;">Patient and surgical risk
                  factors for surgical site infection in lower-extremity oncological endoprosthetic reconstruction: a
                  secondary analysis of the PARITY trial data</span>. <span data-id="emphasis_48"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2023 Jul
                19;105(Suppl 1):41-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20and%20surgical%20risk%20factors%20for%20surgical%20site%20infection%20in%20lower-extremity%20oncological%20endoprosthetic%20reconstruction%3A%20a%20secondary%20analysis%20of%20the%20PARITY%20trial%20data&as_occt=title&as_sauthors=%20%22D%20Slawaska-Eng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_665" class="annotation" style="position: unset;"> Zeitlinger L</span>, <span
                  data-id="annotation_666" class="annotation" style="position: unset;"> Wilson M</span>, <span
                  data-id="annotation_667" class="annotation" style="position: unset;"> Randall RL</span>, <span
                  data-id="annotation_668" class="annotation" style="position: unset;"> Thorpe S</span>; on behalf of
                the PARITY Investigators. <span data-id="strong_49" class="annotation strong"
                  style="position: unset;">Surgical duration is independently associated with an increased risk of
                  surgical site infection and may not be mitigated by prolonged antibiotics: a secondary analysis of the
                  PARITY trial of infection after lower-extremity endoprosthetic reconstruction for bone tumors</span>.
                <span data-id="emphasis_49" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2023 Jul 19;105(Suppl 1):79-86.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20duration%20is%20independently%20associated%20with%20an%20increased%20risk%20of%20surgical%20site%20infection%20and%20may%20not%20be%20mitigated%20by%20prolonged%20antibiotics%3A%20a%20secondary%20analysis%20of%20the%20PARITY%20trial%20of%20infection%20after%20lower-extremity%20endoprosthetic%20reconstruction%20for%20bone%20tumors&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_669" class="annotation" style="position: unset;"> LiBrizzi CL</span>, <span
                  data-id="annotation_670" class="annotation" style="position: unset;"> Sabharwal S</span>, <span
                  data-id="annotation_671" class="annotation" style="position: unset;"> Forsberg JA</span>, <span
                  data-id="annotation_672" class="annotation" style="position: unset;"> Leddy L</span>, <span
                  data-id="annotation_673" class="annotation" style="position: unset;"> Doung YC</span>, <span
                  data-id="annotation_674" class="annotation" style="position: unset;"> Morris CD</span>, <span
                  data-id="annotation_675" class="annotation" style="position: unset;"> Levin AS</span>; <span
                  data-id="annotation_676" class="annotation" style="position: unset;"> PARITY Investigators</span>.
                <span data-id="strong_50" class="annotation strong" style="position: unset;">Does the use of negative
                  pressure wound therapy and postoperative drains impact the development of surgical site infections? A
                  PARITY trial secondary analysis</span>. <span data-id="emphasis_50" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2023 Jul 19;105(Suppl 1):34-40.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20the%20use%20of%20negative%20pressure%20wound%20therapy%20and%20postoperative%20drains%20impact%20the%20development%20of%20surgical%20site%20infections%3F%20A%20PARITY%20trial%20secondary%20analysis&as_occt=title&as_sauthors=%20%22CL%20LiBrizzi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_677" class="annotation" style="position: unset;"> Ray GS</span>, <span
                  data-id="annotation_678" class="annotation" style="position: unset;"> Werth P</span>, <span
                  data-id="annotation_679" class="annotation" style="position: unset;"> Alexander JH</span>, <span
                  data-id="annotation_680" class="annotation" style="position: unset;"> Eward WC</span>, <span
                  data-id="annotation_681" class="annotation" style="position: unset;"> Bernthal NM</span>, <span
                  data-id="annotation_682" class="annotation" style="position: unset;"> Jeys LM</span>, <span
                  data-id="annotation_683" class="annotation" style="position: unset;"> Funovics P</span>, <span
                  data-id="annotation_684" class="annotation" style="position: unset;"> Windhager R</span>, <span
                  data-id="annotation_685" class="annotation" style="position: unset;"> Temple HT</span>, <span
                  data-id="annotation_686" class="annotation" style="position: unset;"> Lozano-Calderon S</span>, <span
                  data-id="annotation_687" class="annotation" style="position: unset;"> Avedian RS</span>, <span
                  data-id="annotation_688" class="annotation" style="position: unset;"> Jutte PC</span>, <span
                  data-id="annotation_689" class="annotation" style="position: unset;"> Ghert M</span>, <span
                  data-id="annotation_690" class="annotation" style="position: unset;"> Ruggieri P</span>, <span
                  data-id="annotation_691" class="annotation" style="position: unset;"> Henderson ER</span>; <span
                  data-id="annotation_692" class="annotation" style="position: unset;"> PARITY Investigators</span>.
                <span data-id="strong_51" class="annotation strong" style="position: unset;">Surgical site infection in
                  patients managed with an endoprosthesis for the treatment of cancer: evaluation of patient, disease,
                  and index surgical factors</span>. <span data-id="emphasis_51" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2023 Jul 19;105(Suppl 1):87-96.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20site%20infection%20in%20patients%20managed%20with%20an%20endoprosthesis%20for%20the%20treatment%20of%20cancer%3A%20evaluation%20of%20patient%2C%20disease%2C%20and%20index%20surgical%20factors&as_occt=title&as_sauthors=%20%22GS%20Ray%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_693" class="annotation" style="position: unset;"> Jackson KJ</span>, <span
                  data-id="annotation_694" class="annotation" style="position: unset;"> Sullivan CD</span>, <span
                  data-id="annotation_695" class="annotation" style="position: unset;"> Zimel MN</span>, <span
                  data-id="annotation_696" class="annotation" style="position: unset;"> Wustrack RL</span>; <span
                  data-id="annotation_697" class="annotation" style="position: unset;"> PARITY Investigators</span>.
                <span data-id="strong_52" class="annotation strong" style="position: unset;">Surgical site infection is
                  not associated with 1-year progression-free survival after endoprosthetic reconstruction for
                  lower-extremity osteosarcoma: a secondary analysis of PARITY study data</span>. <span
                  data-id="emphasis_52" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2023 Jul 19;105(Suppl 1):49-56.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20site%20infection%20is%20not%20associated%20with%201-year%20progression-free%20survival%20after%20endoprosthetic%20reconstruction%20for%20lower-extremity%20osteosarcoma%3A%20a%20secondary%20analysis%20of%20PARITY%20study%20data&as_occt=title&as_sauthors=%20%22KJ%20Jackson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_698" class="annotation" style="position: unset;"> Burke ZDC</span>, <span
                  data-id="annotation_699" class="annotation" style="position: unset;"> Lazarides AL</span>, <span
                  data-id="annotation_700" class="annotation" style="position: unset;"> Gundavda MK</span>, <span
                  data-id="annotation_701" class="annotation" style="position: unset;"> Griffin AM</span>, <span
                  data-id="annotation_702" class="annotation" style="position: unset;"> Tsoi KM</span>, <span
                  data-id="annotation_703" class="annotation" style="position: unset;"> Ferguson PC</span>, <span
                  data-id="annotation_704" class="annotation" style="position: unset;"> Wunder JS</span>; <span
                  data-id="annotation_705" class="annotation" style="position: unset;"> PARITY Investigators</span>.
                <span data-id="strong_53" class="annotation strong" style="position: unset;">Open versus core needle
                  biopsy in lower-extremity sarcoma: current practice patterns and patient outcomes</span>. <span
                  data-id="emphasis_53" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2023 Jul 19;105(Suppl 1):57-64.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Open%20versus%20core%20needle%20biopsy%20in%20lower-extremity%20sarcoma%3A%20current%20practice%20patterns%20and%20patient%20outcomes&as_occt=title&as_sauthors=%20%22ZDC%20Burke%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_54" style="position: unset;">
            <div class="content" style="position: unset;">54&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_706" class="annotation" style="position: unset;"> Khal AA</span>, <span
                  data-id="annotation_707" class="annotation" style="position: unset;"> Aiba H</span>, <span
                  data-id="annotation_708" class="annotation" style="position: unset;"> Righi A</span>, <span
                  data-id="annotation_709" class="annotation" style="position: unset;"> Gambarotti M</span>, <span
                  data-id="annotation_710" class="annotation" style="position: unset;"> Atherley O</span>’<span
                  data-id="annotation_711" class="annotation" style="position: unset;"> Meally AO</span>, <span
                  data-id="annotation_712" class="annotation" style="position: unset;"> Manfrini M</span>, <span
                  data-id="annotation_713" class="annotation" style="position: unset;"> Donati DM</span>, <span
                  data-id="annotation_714" class="annotation" style="position: unset;"> Errani C</span>. <span
                  data-id="strong_54" class="annotation strong" style="position: unset;">Diagnostic challenges in
                  low-grade central osteosarcoma</span>. <span data-id="emphasis_54" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2024 Jan 1;106-B(1):99-106.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagnostic%20challenges%20in%20low-grade%20central%20osteosarcoma&as_occt=title&as_sauthors=%20%22AA%20Khal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_55" style="position: unset;">
            <div class="content" style="position: unset;">55&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_715" class="annotation" style="position: unset;"> Kurisunkal V</span>, <span
                  data-id="annotation_716" class="annotation" style="position: unset;"> Morris G</span>, <span
                  data-id="annotation_717" class="annotation" style="position: unset;"> Kaneuchi Y</span>, <span
                  data-id="annotation_718" class="annotation" style="position: unset;"> Bleibleh S</span>, <span
                  data-id="annotation_719" class="annotation" style="position: unset;"> James S</span>, <span
                  data-id="annotation_720" class="annotation" style="position: unset;"> Botchu R</span>, <span
                  data-id="annotation_721" class="annotation" style="position: unset;"> Jeys L</span>, <span
                  data-id="annotation_722" class="annotation" style="position: unset;"> Parry MC</span>. <span
                  data-id="strong_55" class="annotation strong" style="position: unset;">Accuracy of MRI scans in
                  predicting intra-articular joint involvement in high-grade sarcomas around the knee</span>. <span
                  data-id="emphasis_55" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2023
                Jun 1;105-B(6):696-701.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Accuracy%20of%20MRI%20scans%20in%20predicting%20intra-articular%20joint%20involvement%20in%20high-grade%20sarcomas%20around%20the%20knee&as_occt=title&as_sauthors=%20%22V%20Kurisunkal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_56" style="position: unset;">
            <div class="content" style="position: unset;">56&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_723" class="annotation" style="position: unset;"> Tedesco NS</span>, <span
                  data-id="annotation_724" class="annotation" style="position: unset;"> Mesko N</span>, <span
                  data-id="annotation_725" class="annotation" style="position: unset;"> Wodajo F</span>; Management of
                Metastatic Humeral Disease Work Group; Staff of the American Academy of Orthopaedic Surgeons and the
                Musculoskeletal Tumor Society; <span data-id="strong_56" class="annotation strong"
                  style="position: unset;">Management of Metastatic Humeral Disease Work Group and Staff of the American
                  Academy of Orthopaedic Surgeons and the Musculoskeletal Tumor Society. The Musculoskeletal Tumor
                  Society Clinical Practice Guideline on the Management of Metastatic Humeral Disease</span>. <span
                  data-id="emphasis_56" class="annotation emphasis" style="position: unset;">J Am Acad Orthop
                  Surg.</span> 2024 May 15;32(10):e482-e488.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20Metastatic%20Humeral%20Disease%20Work%20Group%20and%20Staff%20of%20the%20American%20Academy%20of%20Orthopaedic%20Surgeons%20and%20the%20Musculoskeletal%20Tumor%20Society.%20The%20Musculoskeletal%20Tumor%20Society%20Clinical%20Practice%20Guideline%20on%20the%20Management%20of%20Metastatic%20Humeral%20Disease&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_57" style="position: unset;">
            <div class="content" style="position: unset;">57&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_726" class="annotation" style="position: unset;"> Sullivan MH</span>, <span
                  data-id="annotation_727" class="annotation" style="position: unset;"> Arguello AM</span>, <span
                  data-id="annotation_728" class="annotation" style="position: unset;"> Barlow JD</span>, <span
                  data-id="annotation_729" class="annotation" style="position: unset;"> Morrey ME</span>, <span
                  data-id="annotation_730" class="annotation" style="position: unset;"> Rose PS</span>, <span
                  data-id="annotation_731" class="annotation" style="position: unset;"> Sanchez-Sotelo J</span>, <span
                  data-id="annotation_732" class="annotation" style="position: unset;"> Houdek MT</span>. <span
                  data-id="strong_57" class="annotation strong" style="position: unset;">Comparison of reconstructive
                  techniques for nonprimary malignancies in the proximal humerus</span>. <span data-id="emphasis_57"
                  class="annotation emphasis" style="position: unset;">J Surg Oncol.</span> 2024
                Jul;130(1):64-71.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20reconstructive%20techniques%20for%20nonprimary%20malignancies%20in%20the%20proximal%20humerus&as_occt=title&as_sauthors=%20%22MH%20Sullivan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_58" style="position: unset;">
            <div class="content" style="position: unset;">58&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_733" class="annotation" style="position: unset;"> Ladegaard TH</span>, <span
                  data-id="annotation_734" class="annotation" style="position: unset;"> Sørensen MS</span>, <span
                  data-id="annotation_735" class="annotation" style="position: unset;"> Petersen MM</span>. <span
                  data-id="strong_58" class="annotation strong" style="position: unset;">Solitary versus multiple bone
                  metastases in the appendicular skeleton</span>. <span data-id="emphasis_58"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2023 Nov
                1;105-B(11):1206-15.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Solitary%20versus%20multiple%20bone%20metastases%20in%20the%20appendicular%20skeleton&as_occt=title&as_sauthors=%20%22TH%20Ladegaard%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_59" style="position: unset;">
            <div class="content" style="position: unset;">59&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_736" class="annotation" style="position: unset;"> Kafchinski LA</span>. <span
                  data-id="strong_59" class="annotation strong" style="position: unset;">Metastasectomy for
                  oligometastatic bone disease of the appendicular skeleton: a concise review</span>. <span
                  data-id="emphasis_59" class="annotation emphasis" style="position: unset;">J Surg Oncol.</span> 2023
                Sep;128(3):438-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Metastasectomy%20for%20oligometastatic%20bone%20disease%20of%20the%20appendicular%20skeleton%3A%20a%20concise%20review&as_occt=title&as_sauthors=%20%22LA%20Kafchinski%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_60" style="position: unset;">
            <div class="content" style="position: unset;">60&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_737" class="annotation" style="position: unset;"> Park JH</span>, <span
                  data-id="annotation_738" class="annotation" style="position: unset;"> Won J</span>, <span
                  data-id="annotation_739" class="annotation" style="position: unset;"> Kim HS</span>, <span
                  data-id="annotation_740" class="annotation" style="position: unset;"> Kim Y</span>, <span
                  data-id="annotation_741" class="annotation" style="position: unset;"> Kim S</span>, <span
                  data-id="annotation_742" class="annotation" style="position: unset;"> Han I</span>. <span
                  data-id="strong_60" class="annotation strong" style="position: unset;">Comparison of survival
                  prediction models for bone metastases of the extremities following surgery</span>. <span
                  data-id="emphasis_60" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2024
                Feb 1;106-B(2):203-11.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20survival%20prediction%20models%20for%20bone%20metastases%20of%20the%20extremities%20following%20surgery&as_occt=title&as_sauthors=%20%22JH%20Park%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_61" style="position: unset;">
            <div class="content" style="position: unset;">61&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_743" class="annotation" style="position: unset;"> Vankara A</span>, <span
                  data-id="annotation_744" class="annotation" style="position: unset;"> Leland CR</span>, <span
                  data-id="annotation_745" class="annotation" style="position: unset;"> Maxson R</span>, <span
                  data-id="annotation_746" class="annotation" style="position: unset;"> Raad M</span>, <span
                  data-id="annotation_747" class="annotation" style="position: unset;"> Sabharwal S</span>, <span
                  data-id="annotation_748" class="annotation" style="position: unset;"> Morris CD</span>, <span
                  data-id="annotation_749" class="annotation" style="position: unset;"> Levin AS</span>. <span
                  data-id="strong_61" class="annotation strong" style="position: unset;">Predicting risk of 30-day
                  postoperative morbidity using the Pathologic Fracture Mortality Index</span>. <span
                  data-id="emphasis_61" class="annotation emphasis" style="position: unset;">J Am Acad Orthop
                  Surg.</span> 2024 Feb 1;32(3):e146-55.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predicting%20risk%20of%2030-day%20postoperative%20morbidity%20using%20the%20Pathologic%20Fracture%20Mortality%20Index&as_occt=title&as_sauthors=%20%22A%20Vankara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_62" style="position: unset;">
            <div class="content" style="position: unset;">62&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_750" class="annotation" style="position: unset;"> Rogers DL</span>, <span
                  data-id="annotation_751" class="annotation" style="position: unset;"> Raad M</span>, <span
                  data-id="annotation_752" class="annotation" style="position: unset;"> Rivera JA</span>, <span
                  data-id="annotation_753" class="annotation" style="position: unset;"> Wedin R</span>, <span
                  data-id="annotation_754" class="annotation" style="position: unset;"> Laitinen M</span>, <span
                  data-id="annotation_755" class="annotation" style="position: unset;"> Sørensen MS</span>, <span
                  data-id="annotation_756" class="annotation" style="position: unset;"> Petersen MM</span>, <span
                  data-id="annotation_757" class="annotation" style="position: unset;"> Hilton T</span>, <span
                  data-id="annotation_758" class="annotation" style="position: unset;"> Morris CD</span>, <span
                  data-id="annotation_759" class="annotation" style="position: unset;"> Levin AS</span>, <span
                  data-id="annotation_760" class="annotation" style="position: unset;"> Forsberg JA</span>. <span
                  data-id="strong_62" class="annotation strong" style="position: unset;">Life expectancy after treatment
                  of metastatic bone disease: an international trend analysis</span>. <span data-id="emphasis_62"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2024 Mar
                15;32(6):e293-301.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Life%20expectancy%20after%20treatment%20of%20metastatic%20bone%20disease%3A%20an%20international%20trend%20analysis&as_occt=title&as_sauthors=%20%22DL%20Rogers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_63" style="position: unset;">
            <div class="content" style="position: unset;">63&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_761" class="annotation" style="position: unset;"> Su CC</span>, <span
                  data-id="annotation_762" class="annotation" style="position: unset;"> Lin YP</span>, <span
                  data-id="annotation_763" class="annotation" style="position: unset;"> Yen HK</span>, <span
                  data-id="annotation_764" class="annotation" style="position: unset;"> Pan YT</span>, <span
                  data-id="annotation_765" class="annotation" style="position: unset;"> Zijlstra H</span>, <span
                  data-id="annotation_766" class="annotation" style="position: unset;"> Verlaan JJ</span>, <span
                  data-id="annotation_767" class="annotation" style="position: unset;"> Schwab JH</span>, <span
                  data-id="annotation_768" class="annotation" style="position: unset;"> Lai CY</span>, <span
                  data-id="annotation_769" class="annotation" style="position: unset;"> Hu MH</span>, <span
                  data-id="annotation_770" class="annotation" style="position: unset;"> Yang SH</span>, <span
                  data-id="annotation_771" class="annotation" style="position: unset;"> Groot OQ</span>. <span
                  data-id="strong_63" class="annotation strong" style="position: unset;">A machine learning algorithm
                  for predicting 6-week survival in spinal metastasis: an external validation study using 2,768
                  Taiwanese patients</span>. <span data-id="emphasis_63" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2023 Sep 1;31(17):e645-56.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20machine%20learning%20algorithm%20for%20predicting%206-week%20survival%20in%20spinal%20metastasis%3A%20an%20external%20validation%20study%20using%202%2C768%20Taiwanese%20patients&as_occt=title&as_sauthors=%20%22CC%20Su%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_64" style="position: unset;">
            <div class="content" style="position: unset;">64&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_772" class="annotation" style="position: unset;"> Huang CC</span>, <span
                  data-id="annotation_773" class="annotation" style="position: unset;"> Peng KP</span>, <span
                  data-id="annotation_774" class="annotation" style="position: unset;"> Hsieh HC</span>, <span
                  data-id="annotation_775" class="annotation" style="position: unset;"> Groot OQ</span>, <span
                  data-id="annotation_776" class="annotation" style="position: unset;"> Yen HK</span>, <span
                  data-id="annotation_777" class="annotation" style="position: unset;"> Tsai CC</span>, <span
                  data-id="annotation_778" class="annotation" style="position: unset;"> Karhade AV</span>, <span
                  data-id="annotation_779" class="annotation" style="position: unset;"> Lin YP</span>, <span
                  data-id="annotation_780" class="annotation" style="position: unset;"> Kao YT</span>, <span
                  data-id="annotation_781" class="annotation" style="position: unset;"> Yang JJ</span>, <span
                  data-id="annotation_782" class="annotation" style="position: unset;"> Dai SH</span>, <span
                  data-id="annotation_783" class="annotation" style="position: unset;"> Huang CC</span>, <span
                  data-id="annotation_784" class="annotation" style="position: unset;"> Chen CW</span>, <span
                  data-id="annotation_785" class="annotation" style="position: unset;"> Yen MH</span>, <span
                  data-id="annotation_786" class="annotation" style="position: unset;"> Xiao FR</span>, <span
                  data-id="annotation_787" class="annotation" style="position: unset;"> Lin WH</span>, <span
                  data-id="annotation_788" class="annotation" style="position: unset;"> Verlaan JJ</span>, <span
                  data-id="annotation_789" class="annotation" style="position: unset;"> Schwab JH</span>, <span
                  data-id="annotation_790" class="annotation" style="position: unset;"> Hsu FM</span>, <span
                  data-id="annotation_791" class="annotation" style="position: unset;"> Wong T</span>, <span
                  data-id="annotation_792" class="annotation" style="position: unset;"> Yang RS</span>, <span
                  data-id="annotation_793" class="annotation" style="position: unset;"> Yang SH</span>, <span
                  data-id="annotation_794" class="annotation" style="position: unset;"> Hu MH</span>. <span
                  data-id="strong_64" class="annotation strong" style="position: unset;">Does the presence of missing
                  data affect the performance of the SORG machine-learning algorithm for patients with spinal
                  metastasis? Development of an internet application algorithm</span>. <span data-id="emphasis_64"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2024 Jan
                1;482(1):143-57.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20the%20presence%20of%20missing%20data%20affect%20the%20performance%20of%20the%20SORG%20machine-learning%20algorithm%20for%20patients%20with%20spinal%20metastasis%3F%20Development%20of%20an%20internet%20application%20algorithm&as_occt=title&as_sauthors=%20%22CC%20Huang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D98cc85fd-6bd9-400e-bbd1-88a472c0f295%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Guest Editorial</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): 2295</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00945</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 16, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_65"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            University of Maryland, Baltimore, Maryland</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;98cc85fd-6bd9-400e-bbd1-88a472c0f295&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=98cc85fd-6bd9-400e-bbd1-88a472c0f295&type=pdf&name=JBJS.24.00945.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=98cc85fd-6bd9-400e-bbd1-88a472c0f295&type=pdf&name=JBJS.24.00945.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_65"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_66" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I238" target="_blank" data-id="link_1"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I238</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;98cc85fd-6bd9-400e-bbd1-88a472c0f295&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=98cc85fd-6bd9-400e-bbd1-88a472c0f295&type=zip&name=JBJS.24.00945.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Aaron Gazendam, MD, FRCSC<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2615-4919" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2615-4919</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Michelle Ghert, MD, FRCSC<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:mghert@som.umaryland.edu" class=""
                    style="position: unset;">mghert@som.umaryland.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4778-4522" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4778-4522</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Division of Orthopaedic Surgery, Department of Surgery, McMaster University,
                Hamilton, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedics, University of Maryland, Baltimore, Maryland</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 36385.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
