/* -----------------Globals--------------- */
import { useEffect, useState } from "react";

/* -----------------Helpers & Hooks--------------- */
import { useExamContext } from "@features/exam";

/* -----------------Types--------------- */
import type { BundleDetails } from "@memorang/types";

import { ProductListItem, fetchBundleDetails } from "@features/store";
/* -----------------Child components--------------- */
import { Box, CardContainer, Loader } from "@memorang/ui";

import { useDevice } from "@hooks/useDevice";
/* -----------------Globals--------------- */
import { useRouter } from "expo-router";
import { IconButton } from "react-native-paper";

type Props = {
	renderJustProducts?: boolean;
	hasPracticeTestAccess?: boolean;
	hasStudyPackAccess?: boolean;
	showStudyPack?: boolean;
};
const UpSaleCard = ({
	renderJustProducts,
	hasPracticeTestAccess,
	hasStudyPackAccess,
	showStudyPack,
}: Props) => {
	const {
		currentExamContext: {
			studyPackProductNumericId,
			practiceTestProductNumericId,
			examName,
		},
	} = useExamContext();
	const router = useRouter();

	const { isMobile } = useDevice();
	const [productDetails, setProductDetails] = useState<BundleDetails[] | null>(
		null,
	);

	useEffect(() => {
		const promises = [
			...(studyPackProductNumericId && !hasStudyPackAccess
				? [fetchBundleDetails(studyPackProductNumericId)]
				: []),
			...(practiceTestProductNumericId && !isMobile && !hasPracticeTestAccess
				? [fetchBundleDetails(practiceTestProductNumericId)]
				: []),
		];

		Promise.all(promises).then((values) => {
			setProductDetails(values);
		});
	}, [
		hasPracticeTestAccess,
		hasStudyPackAccess,
		isMobile,
		practiceTestProductNumericId,
		studyPackProductNumericId,
	]);

	if (hasPracticeTestAccess && (hasStudyPackAccess || !showStudyPack)) {
		return null;
	}
	const handleNavigation = () => {
		router.push("/store");
	};

	const CardBody = () => {
		if (!productDetails) {
			return (
				<Box paddingVertical={16}>
					<Loader />
				</Box>
			);
		}

		return (
			<>
				{productDetails.map((item) => (
					<ProductListItem
						bundle={item.bundle}
						key={item.bundle.id}
						size={44}
						showRightView
						enableBoxShadow={false}
						rightComp={<IconButton icon={"chevron-right"} />}
						handleOpenProductModal={() => handleNavigation()}
					/>
				))}
			</>
		);
	};
	if (renderJustProducts) {
		return <CardBody />;
	}

	const subtitle = `Boost your chance of passing the ${examName} exam with premium content and features`;
	return (
		<CardContainer
			icon="cart-variant"
			title="Premium Test Prep"
			subtitle={subtitle}
		>
			<CardBody />
		</CardContainer>
	);
};

export default UpSaleCard;
