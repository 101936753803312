/**
 * @generated SignedSource<<bd8a7ad61ba2036335da0ba540c152a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */

// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FetchExamDateQuery$variables = {
  id?: string | null;
  numericId?: number | null;
  tenantId?: string | null;
};
export type FetchExamDateQuery$data = {
  readonly examDetails: {
    readonly id: string;
    readonly metadata: {
      readonly examDate: string | null;
    };
  };
};
export type FetchExamDateQuery = {
  response: FetchExamDateQuery$data;
  variables: FetchExamDateQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "numericId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "numericId",
        "variableName": "numericId"
      },
      {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
      }
    ],
    "concreteType": "ExamDetails",
    "kind": "LinkedField",
    "name": "examDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExamMetaData",
        "kind": "LinkedField",
        "name": "metadata",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "examDate",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchExamDateQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FetchExamDateQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f90cb9181f976897db5e848b46fa9ac3",
    "id": null,
    "metadata": {},
    "name": "FetchExamDateQuery",
    "operationKind": "query",
    "text": "query FetchExamDateQuery(\n  $id: ID\n  $numericId: Int\n  $tenantId: ID\n) {\n  examDetails(id: $id, tenantId: $tenantId, numericId: $numericId) {\n    metadata {\n      examDate\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5c8ab51f75940519cd9eb4deddcc60e0";

export default node;
