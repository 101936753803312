import { defaultAiAssistantInfo } from "@memorang/helpers/src/constants";
import type { Apps } from "types/apps";
import { z } from "zod";

const tenantSchema = z.object({
	id: z.string(),
	name: z.string(),
});

const masteryLabelsSchema = z.object({
	low: z.string(),
	medium: z.string(),
	high: z.string(),
	none: z.string(),
});

const scoreRangeSchema = z.object({
	rangeArray: z.array(z.number()),
	range: z.string(),
	color: z.string(),
	icon: z.string(),
});

const fontSizesSchema = z.object({
	stem: z.number(),
	answer: z.number(),
});

const purchaseSchema = z.object({
	isSubscriptionEnabled: z.boolean().optional(),
	subheading: z.string().nullable().optional(),
	productId: z.string().optional(),
	planId: z.string().optional(),
});

const logoSchema = z.object({
	stacked: z.object({
		// todo: might be more complex schema for image
		light: z.object({
			uri: z.string(),
		}),
		dark: z.object({
			uri: z.string(),
		}),
	}),
	default: z.object({
		light: z.object({
			uri: z.string(),
		}),
		dark: z.object({
			uri: z.string(),
		}),
	}),
	company: z
		.object({
			light: z.object({
				uri: z.string(),
			}),
			dark: z.object({
				uri: z.string(),
			}),
		})
		.optional(),
});

const linksSchema = z.object({
	baseUrl: z.string().optional(),
	privacy: z.string(),
	tos: z.string(),
	ios: z.string().optional(),
	android: z.string().optional(),
	redeemSupportUrl: z.string().optional(),
	companyUrl: z.string().optional(),
});

const revenueCatSchema = z.object({
	ios: z.string(),
	android: z.string(),
});

const appAnalyticsSchema = z.object({
	mixpanel: z.object({
		id: z.string(),
	}),
});

const clerkSchema = z.object({
	publishableKeyProd: z.string(),
	publishableKeyDev: z.string(),
});

const appKeysSchema = z.object({
	revenueCat: revenueCatSchema,
	oneSignalAppId: z.string(),
	clerk: clerkSchema.optional().nullable(),
	airtableBase: z.string().optional(),
});

const sessionViewConfigSchema = z.object({
	cta: z.string().optional(),
	maxItems: z
		.object({
			flashcards: z.number(),
			questions: z.number(),
		})
		.optional(),
	postAnswerStats: z
		.object({
			showXpEarned: z.boolean().optional(),
			showNumAttempts: z.boolean().optional(),
		})
		.optional(),
	timer: z.boolean().optional(),
	showCrossOut: z.boolean().optional().default(true),
	selectionStrategy: z
		.object({
			default: z.string(),
			optimized: z.boolean(),
			random: z.boolean(),
			shuffled: z.boolean(),
		})
		.optional(),
	suggestCorrection: z
		.object({
			description: z.string(),
		})
		.optional(),
});

const reportViewConfigSchema = z.object({
	practiceTest: z.object({
		reviewEnabled: z.boolean(),
	}),
});

const onboardingDataSchema = z.array(
	z.object({
		title: z.string(),
		image: z.string(),
		type: z.string(),
		id: z.string().optional(),
	}),
);

const baseItemSchema = z.object({
	label: z.string(),
	value: z.string(),
	icon: z.string().optional(),
	defaultSelectedIds: z.array(z.string()).optional(),
});

const itemsOnboardingSchema = z.array(
	z.object({
		...baseItemSchema.shape,
		items: z.array(baseItemSchema).optional(),
	}),
);

const onboardingStepSchema = z.object({
	step: z.string(),
	enabled: z.boolean(),
	title: z.string(),
	numMaxSelect: z.number().optional(),
	suffix: z.string().optional(),
	data: onboardingDataSchema.optional().nullable(),
	itemsData: z
		.object({
			defaultSelectedIds: z.array(z.string()).optional(),
			items: itemsOnboardingSchema,
		})
		.optional(),
});

export type OnboardingStep = z.infer<typeof onboardingStepSchema>;

const navigationRouteSchema = z.object({
	title: z.string(),
	name: z.string(),
	icon: z.object({
		default: z.string(),
		selected: z.string(),
	}),
	enabled: z.boolean(),
	custom: z.boolean().optional(),
});

const navigationSchema = z.object({
	routes: z.object({
		main: z.array(navigationRouteSchema),
	}),
});

const oldPortalSchema = z.object({
	title: z.string(),
	description: z.string(),
	link: z.string(),
});

const aiAssistantSchema = z.object({
	title: z.string().optional().default(defaultAiAssistantInfo.title),
	alias: z.string().optional().default(defaultAiAssistantInfo.alias),
	icon: z.string(),
	fabIcon: z.string().optional(),
});

const landingSchema = z.object({
	title: z.string().optional(),
	subtitle: z.string().optional(),
});

const appConfigSchema = z.object({
	id: z.string(),
	name: z.string(),
	purchase: purchaseSchema.optional(),
	masteryLabels: masteryLabelsSchema.optional(),
	scoreRanges: z.array(scoreRangeSchema).optional(),
	fontSizes: fontSizesSchema.optional(),
	analytics: appAnalyticsSchema,
	logo: logoSchema,
	links: linksSchema,
	keys: appKeysSchema,
	font: z
		.enum(["opensans", "beausite", "greetnarrow", "greetstandard"])
		.default("opensans")
		.optional(),
	supportUrl: z.string().optional(),
	oldPortal: oldPortalSchema.optional(),
	aiAssistant: aiAssistantSchema.optional().default(defaultAiAssistantInfo),
	isImageChallengeApp: z.boolean().optional().default(false),
	landing: landingSchema.optional(),
	showCountrySelection: z.boolean().optional().default(false),
	showChallengeCountdown: z.boolean().optional().default(false),
	showDisclaimer: z.boolean().optional().default(false),
	cmeEnabled: z.boolean().optional().default(false),
	avatarType: z.enum(["initials", "identicon"]).optional(),
});

const themeSchema = z.record(z.string(), z.any());

export const customConfigTypeSchema = z.object({
	tenant: tenantSchema,
	supportEmail: z.string(),
	companyName: z.string(),
	app: appConfigSchema,
	groupByCategory: z.boolean().optional().nullable(),
	exam: z.object({
		enabled: z.boolean(),
		quizEnabled: z.boolean().optional(),
		allowChangeExam: z.boolean().optional(),
	}),
	store: z.object({
		enabled: z.boolean(),
	}),
	homeView: z
		.object({
			showRecommendedChallenge: z.boolean(),
			showArticles: z.boolean(),
			showChallengeOfTheWeek: z.boolean(),
		})
		.optional()
		.nullable(),
	showStudyPackAsHome: z.boolean().optional().nullable(),
	reportView: reportViewConfigSchema.optional().nullable(),
	onboarding: z.array(onboardingStepSchema).optional().nullable(),
	navigation: navigationSchema,
	sessionView: sessionViewConfigSchema.optional().nullable(),
	theme: themeSchema,
});

export type CustomConfigType = z.infer<typeof customConfigTypeSchema>;

export type ConfigMap = Record<Apps, CustomConfigType>;
