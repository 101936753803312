import { useTheme } from "react-native-paper";
import Container, { type Props } from "../Container";

const AuthContainer = (props: Props) => {
	const theme = useTheme();
	return (
		<Container
			maxWidth="xs"
			containerStyle={{
				paddingVertical: 24,
				paddingHorizontal: 16,
				flex: 1,
			}}
			style={{
				backgroundColor: theme.colors.surface,
			}}
			{...props}
		/>
	);
};

export default AuthContainer;
