import type { Article } from "../../../../../types";

export const Article605: Article = {
	id: 605,
	rsuiteId: "e9b8ef32-0c5a-4261-b4be-95407996fecf",
	type: "scientific article",
	title: "Dual-Mobility Articulations in Revision Total Hip Arthroplasty",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e9b8ef32-0c5a-4261-b4be-95407996fecf&type=jpeg&name=JBJS.24.00168f1",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Dual-Mobility Articulations in Revision Total Hip
                Arthroplasty</div>
              <div class="text subtitle" style="position: unset;">A Comparison with Metal or Ceramic on Highly
                Cross-Linked Polyethylene and Constrained Articulations</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Monti Khatod,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Priscilla H.
                      Chan, MS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_26" class="annotation strong"
                          style="position: unset;">Background:</span> The increased availability of dual-mobility
                        acetabular constructs (DMCs) provides surgeons with a newer option to increase the effective
                        femoral head size in revision total hip arthroplasty (rTHA). We sought to evaluate risks of
                        re-revision and prosthetic dislocation following rTHA involving a DMC compared with other
                        articulations.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_27" class="annotation strong"
                          style="position: unset;">Methods:</span> A cohort study was conducted using data from a U.S.
                        integrated health-care system’s Total Joint Replacement Registry. Adult patients who underwent
                        primary THA and went on to undergo an aseptic rTHA in 2002 to 2022 were identified. Patients who
                        received a DMC, a constrained liner, or a metal or ceramic unipolar femoral head on highly
                        cross-linked polyethylene (XLPE) at the time of rTHA were the treatment groups. Subsequent
                        aseptic re-revision and dislocation were the outcomes of interest. Multivariable Cox
                        proportional-hazards regression was used to evaluate the risks of the outcomes, with adjustment
                        for patient, operative, and surgeon confounders.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_28" class="annotation strong"
                          style="position: unset;">Results:</span> The analyzed rTHAs comprised 375 with a DMC, 268 with
                        a constrained liner, 995 with a &lt;36-mm head on XLPE, and 2,087 with a ≥36-mm head on XLPE.
                        DMC utilization increased from 1.0% of rTHAs in 2011 to 21.6% in 2022. In adjusted analyses, a
                        higher re-revision risk was observed for the constrained liner (hazard ratio [HR] = 2.43, 95%
                        confidence interval [CI] = 1.29 to 4.59), &lt;36 mm on XLPE (HR = 2.05, 95% CI = 1.13 to 3.75),
                        and ≥36 mm on XLPE (HR = 2.03, 95% CI = 1.19 to 3.48) groups compared with the DMC group. A
                        higher dislocation risk was observed in both XLPE groups (&lt;36 mm: HR = 2.04, 95% CI = 1.33 to
                        3.14; ≥36 mm: HR = 2.46, 95% CI = 1.69 to 3.57) compared with the DMC group; a nonsignificant
                        trend toward a higher dislocation rate in the group with a constrained liner than in the DMC
                        group was also observed.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Conclusions:</span> In a large U.S.-based cohort, rTHAs using DMCs
                        had the lowest re-revision risk and dislocation risk. Both outcomes were significantly lower
                        than those using a unipolar femoral head on XLPE, re-revision risk was significantly lower than
                        using a constrained liner, and dislocation risk trended toward a lower risk than using a
                        constrained liner.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Total hip arthroplasty (THA) utilization has been
                  increasing annually in the United States, largely due to its popularity and success in treating
                  end-stage osteoarthritis (OA)<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">2</span></a>. This has led to a predictable increase in the number of
                  revision THA (rTHA) procedures<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">3</span></a>. Revision THA is fraught with increased challenges for the
                  patient and surgeon, including impaired outcomes in terms of both function and durability as well as
                  an increased risk of complications<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">2</span></a>. A
                  leading complication in rTHA is recurrent instability, which leads to re-revision in &gt;20% of
                  rTHAs<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Surgeons and patients are eager for strategies to reduce
                  this common complication, including the utilization of a dual-mobility acetabular construct (DMC). The
                  fundamental design of the DMC allows for a larger effective femoral head size even in the face of
                  smaller acetabular components. Advantages include an increased impingement-free range of motion and an
                  increased jump distance. Constrained liners have also been utilized in an attempt to reduce
                  instability. However, the ideal construct remains controversial. Both DMCs and constructs with a
                  constrained liner were found to be superior to modular unipolar fixed-bearing prostheses in rTHA in a
                  study using data from the Danish Arthroplasty Register<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">5</span></a>. No
                  difference between constructs with a DMC and with a constrained liner was discovered in a consecutive
                  series<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">6</span></a>. Most
                  recently, a meta-analysis reported constrained liners to yield higher dislocation and revision rates
                  compared with DMCs<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">7</span></a>.
                  DMCs, however, come with increased concerns for polyethylene wear leading to a potential higher risk
                  of aseptic loosening, as well as unique risks of intraprosthetic dislocation<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">8</span></a> and malseating resulting in fretting corrosion<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">9</span></a>, which have limited their utilization. Despite these risks,
                  utilization of modern DMC implants has increased<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">10</span></a>,
                  especially in rTHA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The purpose of this study was to determine whether the
                  utilization of a DMC was associated with a difference in the risk of aseptic re-revision compared with
                  other articulations, including a constrained liner, in rTHA. The risk of prosthetic dislocation
                  following rTHA was evaluated as a secondary outcome.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study Design
                and Data Source</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">We conducted a retrospective cohort study involving
                  patients from a U.S. integrated health-care system that covers &gt;12 million people in 8 geographic
                  regions<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">11</span></a>.
                  Its membership is demographically representative of the regions served by the health-care system<a
                    href="" data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">12</span></a>. This study was approved by the Kaiser Permanente
                  institutional review board (#5488).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The health-care system’s Total Joint Replacement Registry
                  (TJRR) was the data source. A detailed summary of data collection procedures, coverage, and
                  participation rates for this surveillance tool for all THAs performed in the health-care system has
                  been published previously<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">14</span></a>. Once procedures are included in the TJRR, outcomes are
                  prospectively monitored using electronic screening algorithms and validated by trained research
                  associates. Implant information recorded in the electronic health record at the time of the procedure
                  is extracted to the registry. This information is then used to classify attributes for the implants.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Sample</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Patients ≥18 years of age who underwent primary THA within
                  the health-care system and then went on to have a revision for aseptic reasons between 2002 and 2022
                  were identified (n = 3,947 rTHAs). rTHAs with acetabular components that were cemented (n = 55) or
                  with a missing femoral head size (n = 1) were excluded. rTHAs with unipolar implants and had a bearing
                  surface other than ceramic on highly cross-linked polyethylene (XLPE), oxidized zirconium on XLPE, or
                  metal on XLPE were also excluded (ceramic on ceramic, n = 7; ceramic on metal, n = 7; ceramic on
                  non-XLPE, n = 3; metal on metal, n = 42; metal on non-XLPE, n = 45; oxidized zirconium on non-XLPE, n
                  = 1; missing, n = 61).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The final study sample comprised 3,725 rTHAs in 4
                  treatment groups: 375 with a DMC, 268 with a constrained liner, 995 unipolar with a &lt;36-mm femoral
                  head on XLPE (&lt;36 mm on XLPE group), and 2,087 unipolar with a ≥36-mm femoral head on XLPE (≥36 mm
                  on XLPE group). Procedures were performed by 345 surgeons at 64 hospitals, utilizing implants from
                  multiple manufacturers. DMC utilization increased from 1.0% in 2011 to 21.6% in 2022 (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). The mean cohort age was 68 years and a majority were women
                  (63.8%), had a body mass index (BMI) of ≤35 kg/m<span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">2</span> (85.6%), and had an American
                  Society of Anesthesiologists (ASA) classification of &lt;3 (53.2%). The posterior approach was the
                  most commonly performed (69.2%). Most primary THAs were for primary OA (83.7%), whereas the most
                  common reason for rTHA was instability (37.2%). <a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a> presents
                  characteristics according to the articulation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00168f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e9b8ef32-0c5a-4261-b4be-95407996fecf&type=jpeg&name=JBJS.24.00168f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;">Surgical trends in aseptic revision total hip
                          arthroplasty from 2002 to 2022. The proportions of dual-mobility cups, constrained liners,
                          &lt;36-mm unipolar femoral heads on highly cross-linked polyethylene (XLPE), and ≥36-mm (36+)
                          unipolar femoral heads on XLPE are shown by operative year. An imbalance in operative year was
                          observed across the study groups, with a standardized mean difference of 0.892.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Characteristics of 3,725 Patients Who Underwent
                          Initial Aseptic THA Revision (rTHA), by Implant Type<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 2026.13px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.896458);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Characteristic</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Dual-Mobility</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Constrained Liner</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">&lt;36 mm
                          on XLPE</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">≥36 mm on
                          XLPE</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">SMD<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of rTHAs</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">375</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">268</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">995</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2,087</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_26" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67.8
                          (31-88)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73.7
                          (45-95)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67.4
                          (22-100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67.0
                          (19-96)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.323</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male sex</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">124
                          (33.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78
                          (29.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">172
                          (17.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">973
                          (46.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.341</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI <span
                            data-id="emphasis_27" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_28" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_15" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_15"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_29"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.4
                          (17.3-58.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.2
                          (16.2-43.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.4
                          (14.3-57.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.4
                          (16.8-56.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.233</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &gt;35 kg/m<span
                            data-id="superscript_16" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65
                          (17.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (7.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">130
                          (13.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">311
                          (15.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ASA ≥ 3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">201
                          (55.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">153
                          (61.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">379
                          (43.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">866
                          (44.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.221</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Neurological
                          disorder</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45
                          (12.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56
                          (22.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84
                          (9.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">163
                          (8.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.213</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Indication for primary
                          THA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.103</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Primary
                          osteoarthritis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">319
                          (85.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">228
                          (85.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">801
                          (80.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,770
                          (84.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Secondary
                          osteoarthritis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (7.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (5.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">79
                          (7.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">139
                          (6.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Not
                          osteoarthritis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (7.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (9.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">115
                          (11.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">178
                          (8.5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Indication for
                          rTHA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.920</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Aseptic loosening</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41
                          (10.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (1.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">158
                          (15.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">374
                          (17.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Instability</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">182
                          (48.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">244
                          (91.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">305
                          (30.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">654
                          (31.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Metallosis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56
                          (14.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62
                          (6.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">271
                          (13.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Periprosthetic
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (11.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (3.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">282
                          (28.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">426
                          (20.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54
                          (14.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (3.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">188
                          (18.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">362
                          (17.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgical approach for
                          rTHA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.229</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Direct anterior</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (7.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (2.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76
                          (7.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">155
                          (7.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Posterior</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">281
                          (74.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">206
                          (76.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">646
                          (64.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,443
                          (69.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (4.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (3.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57
                          (5.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">122
                          (5.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Acetabular cup for
                          rTHA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.451</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Not revised</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">244
                          (65.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">232
                          (86.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">869
                          (87.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,425
                          (68.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Revised, cup outer
                          diameter ≤ 54 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57
                          (15.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (4.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97
                          (9.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">193
                          (9.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Revised to cup outer
                          diameter &gt; 54 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74
                          (19.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (9.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (2.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">469
                          (22.5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stem revision for
                          THA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87
                          (23.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (6.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">448
                          (45.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">761
                          (36.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.534</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cemented femur for
                          rTHA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (0.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (2.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (2.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40
                          (1.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.069</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">rTHA operative time <span
                            data-id="emphasis_30" class="annotation emphasis"
                            style="position: unset;">(min)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">128.5
                          (33-472)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">101.0
                          (27-352)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">133.6
                          (18-540)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">137.7
                          (12-432)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.337</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Patients were treated in an integrated health-care system from 2002 to
                        2022. Values are given as the number, with the column percentage in parentheses, or as the mean,
                        with the range in parentheses. THA = total hip arthroplasty, SMD = standardized mean difference,
                        BMI = body mass index, ASA = American Society of Anesthesiologists classification, rTHA =
                        revision total hip arthroplasty, XLPE = highly cross-linked polyethylene. Missing data: BMI, 67
                        (1.8%); ASA, 309 (8.3%); neurological disorder, 354 (9.5%); surgical approach, 679 (18.2%);
                        operative time, 714 (19.2%).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">An SMD of &gt;0.2 indicates imbalance in the distribution of the
                        covariate across study groups.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Outcomes of
                Interest</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The primary outcome was subsequent aseptic re-revision,
                  defined as a procedure following the initial rTHA in which an implant was removed and replaced for
                  reasons other than infection. Revision reasons were collected from the surgeon by the registry, and
                  then validated by trained clinical research associates. rTHAs with re-revision for deep infection, and
                  those having wound drainage or a hematoma or seroma only without another reported reason for
                  re-revision, were considered to have the competing event of infection. All patients included in the
                  TJRR are longitudinally monitored for revision surgeries until either health-care plan membership
                  termination or death. The completeness of follow-up for the cohort was 91.4%.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Dislocation during follow-up was the secondary outcome and
                  was identified using previously specified ICD-9-CM and ICD-10-CM (International Classification of
                  Diseases, Ninth Revision and Tenth Revision, Clinical Modification) codes<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">15</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Covariates</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Age (as a continuous variable), sex (male versus female),
                  BMI (≤35 versus &gt;35 kg/m<span data-id="superscript_18" class="annotation superscript"
                    style="position: unset;">2</span>), ASA classification (1 to 2 versus ≥3), neurological disorder
                  (yes versus no), indication for the primary THA (primary or secondary OA versus non-OA), and reason
                  for the rTHA (aseptic loosening, instability, metallosis, periprosthetic fracture, other reasons) were
                  included as covariates. Operative covariates at the time of the rTHA were surgical approach
                  (posterior, direct anterior, other approaches), new cup outer diameter (≤54 mm, &gt;54 mm, cup not
                  revised [liner exchange only]), revision of the stem, cementation of the femur, operative time, and
                  operative year.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Subset
                Analyses</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The goal for many surgeons when faced with instability as
                  a cause for rTHA is to obtain maximal stability. In this subset of patients, a DMC may offer a greater
                  advantage than it does in patients with other reasons for rTHA. Therefore, we repeated the above
                  analyses for the subset in which instability was the reason for rTHA (n = 1,385).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">A subset of rTHAs in which only ball and/or liner exchange
                  was performed (i.e., the femoral head component and/or liner were revised without revising the
                  acetabular cup or femoral stem, n = 1,448) was also analyzed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Subgroup
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">A secondary analysis evaluated the risks of the outcomes
                  during follow-up in the study groups within 5-year intervals (2008 to 2012, 2013 to 2017, 2018 to
                  2022). Operative years 2002 to 2007 were not considered as too few DMCs were utilized. As DMC
                  utilization increased in more recent study years, stratifying by 5-year intervals sought to create
                  more homogenous subgroups, as there may have been other advances in prosthetic materials, operative
                  techniques, or postoperative rehabilitation protocols.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Means, ranges, frequencies, and proportions were used to
                  describe the study sample. The standardized mean difference (SMD) was calculated for each covariate;
                  an SMD of &gt;0.1 suggested covariate imbalance across study groups. Time-to-event analysis techniques
                  were used to evaluate re-revisions and dislocations; for each of these outcomes, the follow-up time
                  was defined as the difference between the rTHA date and date for that outcome. Follow-up time for
                  patients who did not experience the outcome was defined as the difference between the rTHA date and
                  the date of membership termination, date of death, or study end date (December 31, 2022), whichever
                  came first. Patients without the outcome of interest were censored at the last surveillance date. For
                  the dislocation outcome, if a dislocation happened after a re-revision, the event was censored at the
                  time of re-revision. The mean completeness of follow-up without health-care membership termination for
                  the study cohort was calculated as the sum of the observed follow-up times divided by the sum of the
                  potential follow-up times<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">16</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">The adjusted cumulative incidences of aseptic re-revision
                  and dislocation were calculated using the Aalen-Johansen estimator. Cause-specific multivariable Cox
                  proportional-hazards regression was used to evaluate the risks of the outcomes. Incidence curves and
                  regression models included the abovementioned covariates to adjust for potential confounders.
                  Furthermore, a cluster term was included to account for correlation among rTHAs performed by the same
                  surgeon. A separate missing level was created for procedures with missing information regarding the
                  surgical approach (n = 679, 18.2%), while the missing values for other covariates (BMI, n = 67 [1.8%];
                  ASA, n = 309 [8.3%]; neurological disorder, n = 354 [9.5%]; operative time, n = 714 [19.2%]) were
                  imputed as the mean for the indicator function. Scaled Schoenfeld residuals were plotted against the
                  logarithms of the unique failure times to test the proportional-hazards assumption. The
                  proportional-hazards assumption was met for the re-revision model but not for the dislocation model.
                  This implied that the effect estimates for dislocation risk were an average of the true estimate over
                  time. DMC was the reference group in all regression models. P &lt; 0.05 was the significance
                  threshold; all tests were 2-sided. The R language (version 3.6.2; R Foundation for Statistical
                  Computing) was used to perform analyses.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">The 10-year unadjusted aseptic re-revision probability was
                  8.6%, 24.6%, 13.1%, and 11.9% for rTHAs in the DMC, constrained liner, &lt;36-mm on XLPE, and ≥36-mm
                  on XLPE groups, respectively. In the adjusted analysis, a significantly higher aseptic re-revision
                  risk was observed in the constrained liner (hazard ratio [HR] = 2.43, 95% confidence interval [CI] =
                  1.29 to 4.59), &lt;36-mm on XLPE (HR = 2.05, 95% CI = 1.13 to 3.75), and ≥36-mm on XLPE (HR = 2.03,
                  95% CI = 1.19 to 3.48) groups compared with the DMC group (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>, <a
                    href="" data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). Appendix Table I presents all reported reasons for aseptic
                  re-revision by articulation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Crude Incidences and Adjusted Risks of Aseptic
                          Re-Revision and Dislocation Following Initial THA Revision<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 726px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Crude
                          10-Year Incidence<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Adjusted<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span> HR (95%
                          CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic
                          re-revision</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dual-mobility</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (8.6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Constrained liner</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43
                          (24.6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2.43 (1.29-4.59)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_34" class="annotation strong" style="position: unset;">0.006</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;36 mm on XLPE</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100
                          (13.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2.05 (1.13-3.75)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_35" class="annotation strong" style="position: unset;">0.019</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥36 mm on XLPE</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">196
                          (11.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2.03 (1.19-3.48)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_36" class="annotation strong" style="position: unset;">0.010</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dislocation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dual-mobility</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (11.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Constrained liner</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51
                          (30.0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1.53 (0.98-2.38)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.062</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;36 mm on XLPE</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">121
                          (15.6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2.04 (1.33-3.14)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_37" class="annotation strong" style="position: unset;">0.001</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥36 mm on XLPE</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">301
                          (17.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2.46 (1.69-3.57)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_38" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">THA = total hip arthroplasty, HR = hazard ratio, CI = confidence
                        interval, XLPE = highly cross-linked polyethylene, BMI = body mass index, ASA = American Society
                        of Anesthesiologists classification.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the number, with the percentage calculated as
                        the Aalen-Johansen estimator in parentheses, at 10 years of follow-up.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Adjusted for age, sex, BMI, ASA classification, neurological disorder,
                        indication for the primary arthroplasty, reason for the rTHA (revision THA), surgical approach,
                        cup outer diameter, stem revision, cemented femur, operative time, operative year, and operating
                        surgeon.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Bold indicates significance (p &lt; 0.05).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00168f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e9b8ef32-0c5a-4261-b4be-95407996fecf&type=jpeg&name=JBJS.24.00168f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;">Cumulative aseptic re-revision probability
                          following aseptic revision total hip arthroplasty using dual-mobility cups, constrained
                          liners, &lt;36-mm unipolar femoral heads on highly cross-linked polyethylene (XLPE), and
                          ≥36-mm (36+) unipolar femoral heads on XLPE. Curves were adjusted for age, sex, BMI, ASA
                          classification, neurological disorder, indication for the primary arthroplasty, reason for the
                          rTHA, surgical approach, cup outer diameter, stem revision, operative time, operative year,
                          and operating surgeon. Shaded areas represent 95% CIs. The table below the x axis presents the
                          number at risk at 0, 2, 4, 6, 8, and 10 years of follow-up.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">The unadjusted cumulative dislocation probability at 10
                  years of follow-up was 11.1%, 30.0%, 15.6%, and 17.9% for rTHAs in the DMC, constrained liner,
                  &lt;36-mm on XLPE, and ≥36-mm on XLPE groups, respectively. After covariate adjustment, a
                  significantly higher dislocation risk was observed in the &lt;36 mm on XLPE (HR = 2.04, 95% CI = 1.33
                  to 3.14) and ≥36 mm on XLPE groups (HR = 2.46, 95% CI = 1.69 to 3.57) compared with the DMC group (<a
                    href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>, <a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>). There
                  was a nonsignificant trend toward a higher dislocation risk for constrained liners (HR = 1.53, 95% CI
                  = 0.98 to 2.38).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00168f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e9b8ef32-0c5a-4261-b4be-95407996fecf&type=jpeg&name=JBJS.24.00168f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;">Cumulative dislocation probability following
                          aseptic revision total hip arthroplasty using dual-mobility cups, constrained liners,
                          &lt;36-mm unipolar femoral heads on highly cross-linked polyethylene (XLPE), and ≥36-mm
                          unipolar femoral heads on XLPE. Curves were adjusted for age, sex, BMI, ASA classification,
                          neurological disorder, indication for the primary arthroplasty, reason for the rTHA, surgical
                          approach, cup outer diameter, stem revision, operative time, operative year, and operating
                          surgeon. Shaded areas represent 95% CIs.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Subset
                Analyses</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">In the subset of rTHAs performed for the diagnosis of
                  instability (n = 1,385), a significantly higher aseptic re-revision risk was observed in the
                  constrained liner (HR = 3.62, 95% CI = 1.61 to 8.14), &lt;36 mm on XLPE (HR = 3.43, 95% CI = 1.52 to
                  7.78), and ≥36 mm on XLPE (HR = 3.34, 95% CI = 1.56 to 7.13) groups compared with the DMC group.
                  Furthermore, the ≥36 mm on XLPE group had a significantly higher dislocation risk (HR = 2.12, 95% CI =
                  1.34 to 3.37) compared with the DMC group (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                  There was a nonsignificant trend toward a higher dislocation risk for the &lt;36 mm on XLPE group (HR
                  = 1.68, 95% CI = 1.00 to 2.83).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Crude Incidences and Adjusted Risks of Aseptic
                          Re-Revision and Dislocation Following Initial Aseptic THA Revision in Subsets with Initial
                          Revision for Instability and with Revision of Only the Femoral Head and/or Liner<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1520px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Initial Aseptic
                          Revision</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Outcome by
                          Group</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Crude
                          10-Year Incidence<span data-id="table_footnote_reference_7"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Adjusted<span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span> HR (95%
                          CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td rowspan="10" style="position: unset;"><span class="text" style="position: unset;">Revision for
                          instability (n = 1,385)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic
                          re-revision</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dual-mobility</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (9.7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Constrained liner</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38
                          (23.9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3.62 (1.61-8.14)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_39" class="annotation strong" style="position: unset;">0.002</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;36 mm on XLPE</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (18.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3.43 (1.52-7.78)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_40" class="annotation strong" style="position: unset;">0.003</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥36 mm on XLPE</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">92
                          (17.5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3.34 (1.56-7.13)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_41" class="annotation strong" style="position: unset;">0.002</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dislocation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dual-mobility</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (18.8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Constrained liner</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47
                          (30.5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1.33 (0.81-2.18)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.263</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;36 mm on XLPE</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61
                          (26.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1.68 (1.00-2.83)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.050</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥36 mm on XLPE</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">169
                          (32.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2.12 (1.34-3.37)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_42" class="annotation strong" style="position: unset;">0.001</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="10" style="position: unset;"><span class="text" style="position: unset;">Revision of
                          femoral head and/or liner only<span data-id="table_footnote_reference_10"
                            class="annotation table_footnote_reference" style="position: unset;">#</span> (n =
                          1,448)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic
                          re-revision</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dual-mobility</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (49.8%<span data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">**</span>)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Constrained liner</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (27.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1.73 (0.78-3.82)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.174</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;36 mm on XLPE</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55
                          (16.1%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2.24 (0.82-6.15)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.118</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥36 mm on XLPE</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81
                          (15.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2.14 (0.81-5.65)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.124</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dislocation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dual-mobility</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (54.9%<span data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">**</span>)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Constrained liner</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (30.3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1.70 (0.77-3.74)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.191</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;36 mm on XLPE</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75
                          (22.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2.33 (1.12-4.87)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_43" class="annotation strong" style="position: unset;">0.024</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥36 mm on XLPE</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">161
                          (30.2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2.94 (1.42-6.09)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_44" class="annotation strong" style="position: unset;">0.004</span></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">THA = total hip arthroplasty, HR = hazard ratio, CI = confidence
                        interval, XLPE = highly cross-linked polyethylene, BMI = body mass index, ASA = American Society
                        of Anesthesiologists classification.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the number, with the percentage calculated as
                        the Aalen-Johansen estimator in parentheses, at 10 years of follow-up unless otherwise
                        noted.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Adjusted for age, sex, BMI, ASA classification, neurological disorder,
                        indication for the primary arthroplasty, reason for the rTHA (revision THA), surgical approach,
                        cup outer diameter, stem revision, cemented femur, operative time, operative year, and operating
                        surgeon.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Bold indicates significance (p &lt; 0.05).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">Exchange of ball and/or liner only, without revision of the acetabular
                        cup or femoral stem.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_12" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">**</b> <span class="text"
                        style="position: unset;">The percentage was calculated at 8 years of follow-up.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">In the subset of rTHAs in which only the femoral head
                  and/or liner were revised (n = 1,448), we observed no significant difference in the risk of
                  re-revision in the other groups compared with the DMC group (constrained liner: HR = 1.73, 95% CI =
                  0.78 to 3.82; &lt;36 mm on XLPE: HR = 2.24, 95% CI = 0.82 to 6.15; ≥36 mm on XLPE: HR = 2.14, 95% CI =
                  0.81 to 5.65). A significantly higher dislocation risk was observed in the &lt;36 mm on XLPE (HR =
                  2.33, 95% CI = 1.12 to 4.87) and ≥36 mm on XLPE groups (HR = 2.94, 95% CI = 1.42 to 6.09) groups
                  compared with the DMC group (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Subgroup
                Analyses</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>
                  presents adjusted results within 5-year intervals. The significantly higher risk of aseptic
                  re-revision that had been observed in the overall constrained liner, &lt;36 mm on XLPE, and ≥36 mm on
                  XLPE groups compared with the DMC group was seen only in the more recent years of 2018 to 2022
                  (constrained liner: HR = 3.14, 95% CI = 1.21 to 8.14; &lt;36 mm on XLPE: HR = 3.54, 95% CI = 1.72 to
                  7.29; ≥36 mm on XLPE: HR = 3.37, 95% CI = 1.78 to 6.39).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Adjusted Risks of Aseptic Re-Revision and
                          Dislocation Following Initial Revision THA, Stratified by Operative Years<span
                            data-id="table_footnote_reference_20" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 696.216px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.783333);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Outcome by
                          Group</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">2008-2012 (N = 692)</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">2013-2017 (N = 1,362)</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">2018-2022 (N = 1,425)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Adjusted<span data-id="table_footnote_reference_14"
                            class="annotation table_footnote_reference" style="position: unset;">†</span> HR (95%
                          CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Adjusted<span data-id="table_footnote_reference_16"
                            class="annotation table_footnote_reference" style="position: unset;">†</span> HR (95%
                          CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Adjusted<span data-id="table_footnote_reference_18"
                            class="annotation table_footnote_reference" style="position: unset;">†</span> HR (95%
                          CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_19" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic
                          re-revision</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dual-mobility</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Constrained
                          liners</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.75 (0.15-3.86)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.734</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.72 (0.28-1.81)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.481</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3.14 (1.21-8.14)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_45" class="annotation strong" style="position: unset;">0.019</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;36 mm on XLPE</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.78 (0.15-3.94)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.762</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.73 (0.32-1.69)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.462</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3.54 (1.72-7.29)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_46" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥36 mm on XLPE</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.46 (0.10-2.11)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.320</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.91 (0.41-2.01)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.822</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3.37 (1.78-6.39)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_47" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dislocation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dual-mobility</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Constrained liner</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1.69 (0.38-7.45)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.491</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.91 (0.39-2.13)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.832</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1.21 (0.61-2.40)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.579</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;36 mm on XLPE</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2.09 (0.49-8.94)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.320</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1.32 (0.53-3.26)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.554</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2.41 (1.38-4.19)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_48" class="annotation strong" style="position: unset;">0.002</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥36 mm on XLPE</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1.53 (0.38-6.23)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.553</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1.66 (0.75-3.69)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.212</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2.79 (1.77-4.38)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_49" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_13" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Operative years 2002 to 2007 were not considered because there were too
                        few dual-mobility THAs during these years. CI = confidence interval, HR = hazard ratio, XLPE =
                        highly cross-linked polyethylene, BMI = body mass index, ASA = American Society of
                        Anesthesiologists classification.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_14" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Adjusted for age, sex, BMI, ASA classification, neurological disorder,
                        indication for the primary arthroplasty, reason for the rTHA (revision THA), surgical approach,
                        cup outer diameter, stem revision, cemented femur, operative time, operative year, and operating
                        surgeon.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_15" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Bold indicates significance (p &lt; 0.05).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">rTHA presents a complex set of challenges for surgeons
                  that affect patient outcomes. Patient, surgeon, and implant factors must all be considered to optimize
                  outcomes of rTHA. Our findings support the utilization of a DMC in rTHA to reduce the risk of aseptic
                  re-revision surgery. Furthermore, the DMC group had the lowest dislocation risk, significantly lower
                  than that in the XLPE groups but not significantly lower than that in the constrained liner group.
                  This is especially clinically relevant as 37.2% of the rTHA cases were performed for the diagnosis of
                  instability. Consistent findings were also seen in analyses restricted to the most recent study years
                  (2018 to 2022).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Other studies have revealed instability as a leading cause
                  of rTHA in the U.S.<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_20" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">4</span></a>. Australia’s National Joint Replacement Registry was used to
                  evaluate large femoral heads compared with DMCs in rTHA and found similar outcomes for aseptic
                  re-revision THA across implants<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">17</span></a>.
                  They also observed increasing DMC utilization in rTHA annually, similar to the observations made in
                  the present cohort. Their study was restricted to ≥36-mm femoral heads<a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">17</span></a>, which differs from our cohort. Not all acetabular
                  components in rTHA will allow the surgeon to choose a large femoral head. It is possible that a DMC is
                  most beneficial for smaller acetabular components. Hoskins et al.<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">18</span></a> further investigated primary THA using data from the
                  Australian registry and found that DMC implants reduced the risk of aseptic revision compared with
                  large femoral heads (≥36 mm) in acetabular implants of &lt;58 mm, indicating some relationship between
                  acetabular cup size and risk of aseptic revision. In a single-surgeon series of 301 consecutive rTHAs,
                  no difference in revision risk was found when comparing ≥40-mm femoral heads with DMCs having an outer
                  articulation diameter of ≥40 mm<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">19</span></a>.
                  However, that study again found a higher risk of revision for smaller acetabular components.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">In a study focused on rTHA for the diagnosis of
                  instability, no difference in re-revision risk was discovered when comparing constrained with
                  nonconstrained liners<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">20</span></a>;
                  only patient factors, including obesity, spine disease, and abductor deficiency, predicted failure. A
                  meta-analysis focused on dislocation risk after rTHA revealed that smaller, ≤28-mm femoral heads and a
                  history of instability were associated with a higher risk of instability after rTHA<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">21</span></a>. In our cohort, we found that utilization of a DMC in rTHA
                  reduced the risk of dislocation compared with conventional articulations of all head sizes but not
                  compared with constrained liners. In our subset of patients revised for instability, we found a trend
                  toward less instability with the utilization of a DMC compared with a smaller femoral head, but the
                  difference did not reach significance. Since a high proportion of rTHAs are performed for instability,
                  optimizing as many factors as possible to promote stability is in the interest of the surgeon and the
                  patient. Implant factors can be restricted by the acetabular component size, and maximizing the
                  relative femoral head size through the use of DMC can therefore be an advantage; we did not observe a
                  higher risk for osteolysis or loosening in our cohort.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Although we observed increasing DMC utilization in rTHA,
                  this increased DMC utilization must be tempered with concerns regarding polyethylene wear. We did not
                  find a higher rate of aseptic loosening following utilization of a DMC relative to a unipolar
                  articulation on XLPE. However, further study with longer follow-up after rTHA may reveal differences
                  in aseptic loosening depending on the articulation. The lack of a higher risk of aseptic loosening
                  with DMC utilization is in concordance with other reports. Fessy et al.<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">22</span></a> showed 100% cup survivorship and 99.2% stem survivorship
                  for uncemented DMCs in primary THA at 10 years in France. Hoskins et al.<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">18</span></a> also found no difference in aseptic loosening between large
                  femoral heads and DMCs in primary THAs in Australia. In a study of XLPE in rTHA, Wilson et al.<a
                    href="" data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">23</span></a> found excellent survivorship of modern porous-coated
                  acetabular revision implants with and without the utilization of thinner polyethylene, including a
                  DMC. A smaller study of rTHAs utilizing a DMC found 98% survivorship free of aseptic loosening at 38
                  months<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">24</span></a>.
                  Other studies have identified unique causes for aseptic re-revision after THA with a DMC, such as
                  malseating leading to corrosion and intraprosthetic dislocation of the liner, that are not concerns in
                  modular unipolar constructs<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_31" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">25</span></a>. However, we did not specifically detect either of these
                  causes for re-revision in our DMC group (see Appendix Table I).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Strengths
                and Limitations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Study strengths include the large number of rTHAs. With
                  the dearth of literature on the outcomes of rTHA, this study adds information for the surgeon and
                  patient. If a surgeon must revise a THA with a smaller acetabular component and is unable to utilize a
                  large femoral head, then the utilization of a DMC implant may offer an advantage. Furthermore, the
                  cohort comprised rTHAs performed by 345 surgeons at 64 hospitals within a representative health-care
                  system<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">12</span></a>,
                  increasing generalizability.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Limitations include the observational nature of the study
                  design. Although our design and analysis attempted to address confounding, we were unable to adjust
                  for confounders not collected in the registry that served as our data source. Only observed
                  associations are reported; we cannot determine causality. Acetabular liners with and without elevated
                  lips or face-changing geometry (e.g., asymmetric liners) were not evaluated in the analysis. Abductor
                  function was also not evaluated, as this is not collected by the registry. Furthermore, additional
                  outcomes of clinical relevance, such as hip function, were not evaluated. As codes were used to
                  identify dislocations, there is potential for underreporting. However, any missing dislocations would
                  likely be evenly distributed among the investigated articulations, as underreporting would not be
                  dependent on the articulation. Finally, there is potential for patients to seek care outside of the
                  health-care system, which would not be captured by our registry. However, we anticipate that to be
                  infrequent because outside care would not be covered by the system’s insurance plan. Our analysis
                  accounted for individuals who terminated their health-care plan membership during follow-up without
                  experiencing an outcome of interest; these patients were included in the effect estimation as long as
                  they were members and were censored at the termination date.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">rTHA presents a myriad of challenges to the surgeon and
                  patient. Within our integrated health-care system, DMC utilization for rTHA has risen annually. When
                  evaluating articulation options, a DMC appeared to reduce the risks of aseptic re-revision and
                  dislocation compared with other available options.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_3" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I249" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I249</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors acknowledge all of the Kaiser Permanente surgeons
                  who contribute to the Total Joint Replacement Registry and the Medical Device & Surveillance
                  Assessment Department, which coordinates database operations.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Kurtz S</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Ong K</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Lau E</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Mowat F</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Halpern M</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Projections of primary and
                  revision hip and knee arthroplasty in the United States from 2005 to 2030</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2007 Apr;89(4):780-5.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1007448"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Bozic KJ</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Kurtz SM</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Lau E</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Ong K</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Vail TP</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Berry DJ</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The epidemiology of revision
                  total hip arthroplasty in the United States</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2009
                Jan;91(1):128-33.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1033620"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Schwartz AM</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Farley KX</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Guild GN</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Bradbury TL Jr</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Projections and Epidemiology of
                  Revision Hip and Knee Arthroplasty in the United States to 2030</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2020
                Jun;35(6S):S79-85.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Projections%20and%20Epidemiology%20of%20Revision%20Hip%20and%20Knee%20Arthroplasty%20in%20the%20United%20States%20to%202030&as_occt=title&as_sauthors=%20%22AM%20Schwartz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Khatod M</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Cafri G</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Inacio MC</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Schepps AL</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Paxton EW</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Bini SA</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Revision total hip arthoplasty:
                  factors associated with re-revision surgery</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2015 Mar
                4;97(5):359-66.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1206087"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Hermansen LL</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Viberg B</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Overgaard S</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Risk Factors for Dislocation and
                  Re-revision After First-Time Revision Total Hip Arthroplasty due to Recurrent Dislocation - A Study
                  From the Danish Hip Arthroplasty Register</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2021
                Apr;36(4):1407-12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20Factors%20for%20Dislocation%20and%20Re-revision%20After%20First-Time%20Revision%20Total%20Hip%20Arthroplasty%20due%20to%20Recurrent%20Dislocation%20-%20A%20Study%20From%20the%20Danish%20Hip%20Arthroplasty%20Register&as_occt=title&as_sauthors=%20%22LL%20Hermansen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Chisari E</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Ashley B</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Sutton R</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Largoza G</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Di Spagna M</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Goyal N</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Courtney PM</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Parvizi J</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Dual-Mobility Implants and
                  Constrained Liners in Revision Total Hip Arthroplasty</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">Arthroplast Today.</span> 2021 Dec
                6;13:8-12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dual-Mobility%20Implants%20and%20Constrained%20Liners%20in%20Revision%20Total%20Hip%20Arthroplasty&as_occt=title&as_sauthors=%20%22E%20Chisari%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Donovan RL</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Johnson H</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Fernando S</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Foxall-Smith M</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Whitehouse MR</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Blom AW</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Kunutsor SK</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">A Meta-Analysis of the Incidence
                  and Temporal Trends of Postoperative Dislocation in Revision Total Hip Arthroplasty Utilizing
                  Constrained Acetabular Components or Dual Mobility Implants</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2023
                May;38(5):957-969.e1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Meta-Analysis%20of%20the%20Incidence%20and%20Temporal%20Trends%20of%20Postoperative%20Dislocation%20in%20Revision%20Total%20Hip%20Arthroplasty%20Utilizing%20Constrained%20Acetabular%20Components%20or%20Dual%20Mobility%20Implants&as_occt=title&as_sauthors=%20%22RL%20Donovan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Philippot R</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Boyer B</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Farizon F</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Intraprosthetic dislocation: a
                  specific complication of the dual-mobility system</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2013
                Mar;471(3):965-70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intraprosthetic%20dislocation%3A%20a%20specific%20complication%20of%20the%20dual-mobility%20system&as_occt=title&as_sauthors=%20%22R%20Philippot%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Romero J</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Wach A</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Silberberg S</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Chiu YF</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Westrich G</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Wright TM</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Padgett DE</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">2020 Otto Aufranc Award:
                  Malseating of modular dual mobility liners</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2020
                Jul;102-B(7_Supple_B):20-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=2020%20Otto%20Aufranc%20Award%3A%20Malseating%20of%20modular%20dual%20mobility%20liners&as_occt=title&as_sauthors=%20%22J%20Romero%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Heckmann N</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Weitzman DS</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Jaffri H</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Berry DJ</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Springer BD</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Lieberman JR</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Trends in the use of dual
                  mobility bearings in hip arthroplasty</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2020 Jul;102-B(7_Suppl_B):27-32.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trends%20in%20the%20use%20of%20dual%20mobility%20bearings%20in%20hip%20arthroplasty&as_occt=title&as_sauthors=%20%22N%20Heckmann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Kaiser Permanente</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Fast Facts. 2023</span>.
                Accessed 2023 Jul 11. <a href="https://about.kaiserpermanente.org/who-we-are/fast-facts" target="_blank"
                  data-id="link_1" class="link"
                  style="position: unset;">https://about.kaiserpermanente.org/who-we-are/fast-facts</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fast%20Facts.%202023&as_occt=title&as_sauthors=%20%22Permanente%20Kaiser%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Davis AC</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Voelkel JL</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Remmers CL</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Adams JL</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> McGlynn EA</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Comparing Kaiser Permanente
                  Members to the General Population: Implications for Generalizability of Research</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Perm J.</span> 2023 Jun
                15;27(2):87-98.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparing%20Kaiser%20Permanente%20Members%20to%20the%20General%20Population%3A%20Implications%20for%20Generalizability%20of%20Research&as_occt=title&as_sauthors=%20%22AC%20Davis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Paxton EW</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Inacio MC</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Khatod M</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Yue EJ</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Namba RS</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Kaiser Permanente National
                  Total Joint Replacement Registry: aligning operations with information technology</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2010 Oct;468(10):2646-63.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Kaiser%20Permanente%20National%20Total%20Joint%20Replacement%20Registry%3A%20aligning%20operations%20with%20information%20technology&as_occt=title&as_sauthors=%20%22EW%20Paxton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Paxton EW</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Kiley ML</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Love R</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Barber TC</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Funahashi TT</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Inacio MC</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Kaiser Permanente implant
                  registries benefit patient safety, quality improvement, cost-effectiveness</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Jt Comm J Qual Patient
                  Saf.</span> 2013 Jun;39(6):246-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Kaiser%20Permanente%20implant%20registries%20benefit%20patient%20safety%2C%20quality%20improvement%2C%20cost-effectiveness&as_occt=title&as_sauthors=%20%22EW%20Paxton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Khatod M</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Chan PH</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Prentice HA</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Fasig BH</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Paxton EW</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Reddy NC</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Kelly MP</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Can Dual Mobility Cups Reduce
                  Revision and Dislocation Risks? An Analysis of 107,528 Primary Total Hip Arthroplasties in the United
                  States</span>. <span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2024 May;39(5):1279-1284.e1.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Can%20Dual%20Mobility%20Cups%20Reduce%20Revision%20and%20Dislocation%20Risks%3F%20An%20Analysis%20of%20107%2C528%20Primary%20Total%20Hip%20Arthroplasties%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22M%20Khatod%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Clark TG</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Altman DG</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> De Stavola BL</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Quantification of the
                  completeness of follow-up</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 2002 Apr 13;359(9314):1309-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Quantification%20of%20the%20completeness%20of%20follow-up&as_occt=title&as_sauthors=%20%22TG%20Clark%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Hoskins W</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Bingham R</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Hatton A</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> de Steiger RN</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Standard, Large-Head,
                  Dual-Mobility, or Constrained-Liner Revision Total Hip Arthroplasty for a Diagnosis of Dislocation: An
                  Analysis of 1,275 Revision Total Hip Replacements</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2020 Dec
                2;102(23):2060-7.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2609125"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Hoskins W</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Bingham R</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Dyer C</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Rainbird S</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Graves SE</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">A Comparison of Revision Rates
                  for Dislocation and Aseptic Causes Between Dual Mobility and Large Femoral Head Bearings in Primary
                  Total Hip Arthroplasty With Subanalysis by Acetabular Component Size: An Analysis of 106,163 Primary
                  Total Hip Arthroplasties</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2021 Sep;36(9):3233-40.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Comparison%20of%20Revision%20Rates%20for%20Dislocation%20and%20Aseptic%20Causes%20Between%20Dual%20Mobility%20and%20Large%20Femoral%20Head%20Bearings%20in%20Primary%20Total%20Hip%20Arthroplasty%20With%20Subanalysis%20by%20Acetabular%20Component%20Size%3A%20An%20Analysis%20of%20106%2C163%20Primary%20Total%20Hip%20Arthroplasties&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Sonn KA</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Deckard ER</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Meneghini RM</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">No Difference in Dislocation
                  Rates Comparing Large Diameter Jumbo Femoral Heads and Dual-Mobility Bearings in Revision Total Hip
                  Arthroplasty</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2021 Nov;36(11):3716-21.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=No%20Difference%20in%20Dislocation%20Rates%20Comparing%20Large%20Diameter%20Jumbo%20Femoral%20Heads%20and%20Dual-Mobility%20Bearings%20in%20Revision%20Total%20Hip%20Arthroplasty&as_occt=title&as_sauthors=%20%22KA%20Sonn%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Klemt C</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Chen W</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Bounajem G</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Tirumala V</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Xiong L</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Kwon YM</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Outcome and risk factors of
                  failures associated with revision total hip arthroplasty for recurrent dislocation</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">Arch Orthop Trauma
                  Surg.</span> 2022 Aug;142(8):1801-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcome%20and%20risk%20factors%20of%20failures%20associated%20with%20revision%20total%20hip%20arthroplasty%20for%20recurrent%20dislocation&as_occt=title&as_sauthors=%20%22C%20Klemt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Guo L</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Yang Y</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> An B</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Yang Y</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Shi L</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Han X</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Gao S</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Risk factors for dislocation
                  after revision total hip arthroplasty: A systematic review and meta-analysis</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Int J Surg.</span> 2017
                Feb;38:123-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20for%20dislocation%20after%20revision%20total%20hip%20arthroplasty%3A%20A%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22L%20Guo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Fessy MH</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Jacquot L</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Rollier JC</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Chouteau J</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Ait-Si-Selmi T</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Bothorel H</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Chatelet JC</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Midterm Clinical and
                  Radiographic Outcomes of a Contemporary Monoblock Dual-Mobility Cup in Uncemented Total Hip
                  Arthroplasty</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2019 Dec;34(12):2983-91.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Midterm%20Clinical%20and%20Radiographic%20Outcomes%20of%20a%20Contemporary%20Monoblock%20Dual-Mobility%20Cup%20in%20Uncemented%20Total%20Hip%20Arthroplasty&as_occt=title&as_sauthors=%20%22MH%20Fessy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Wilson JM</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Maradit-Kremers H</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Abdel MP</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Berry DJ</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Mabry TM</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Pagnano MW</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Perry KI</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Sierra RJ</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Taunton MJ</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Trousdale RT</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Lewallen DG</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Comparative Survival of
                  Contemporary Cementless Acetabular Components Following Revision Total Hip Arthroplasty</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2023
                Jul;38(7S):S194-200.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparative%20Survival%20of%20Contemporary%20Cementless%20Acetabular%20Components%20Following%20Revision%20Total%20Hip%20Arthroplasty&as_occt=title&as_sauthors=%20%22JM%20Wilson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Sutter EG</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> McClellan TR</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Attarian DE</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Bolognesi MP</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Lachiewicz PF</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Wellman SS</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Outcomes of Modular Dual
                  Mobility Acetabular Components in Revision Total Hip Arthroplasty</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2017
                Sep;32(9S):S220-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20Modular%20Dual%20Mobility%20Acetabular%20Components%20in%20Revision%20Total%20Hip%20Arthroplasty&as_occt=title&as_sauthors=%20%22EG%20Sutter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Hemmerling KJ</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Weitzler L</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Bauer TW</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Padgett DE</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Wright TM</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Fretting and corrosion of metal
                  liners from modular dual mobility constructs: a retrieval analysis</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2021
                Jul;103-B(7):1238-46.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fretting%20and%20corrosion%20of%20metal%20liners%20from%20modular%20dual%20mobility%20constructs%3A%20a%20retrieval%20analysis&as_occt=title&as_sauthors=%20%22KJ%20Hemmerling%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;e9b8ef32-0c5a-4261-b4be-95407996fecf&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=e9b8ef32-0c5a-4261-b4be-95407996fecf&type=supplement&name=12508"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3De9b8ef32-0c5a-4261-b4be-95407996fecf%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="authorinsights_reference"
            class="annotation authorinsights_reference resource-reference" style="position: unset;"></a>
          <div class="content-node authorinsights" data-id="authorinsights" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Author Insights</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6364049749112&quot;,&quot;type&quot;:&quot;author_insights&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;e9b8ef32-0c5a-4261-b4be-95407996fecf&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_3" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6364049749112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-0 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="6364049749112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6364049749112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6364049749112" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_3_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/dbb5186a-aea0-41e9-b961-f6611dc9a2db/436573b9-1c1b-4d4e-b208-efe89b64e4bf/1280x720/match/image.jpg"
                        src="blob:https://www.jbjs.org/7b2c4bcc-1ab9-4d19-bd47-a8cc44d8a1b2">
                        <track kind="metadata" label="segment-metadata">
                        <track kind="subtitles" label="English" srclang="en" default="" id="English">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/dbb5186a-aea0-41e9-b961-f6611dc9a2db/436573b9-1c1b-4d4e-b208-efe89b64e4bf/1280x720/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">2:41</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 2:41">
                            <div class="vjs-load-progress"><span class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">0%</span></span></div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">2:41</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_3_component_293">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_3_component_293"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden vjs-disabled">
                          <button
                            class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button vjs-disabled"
                            type="button" aria-disabled="true" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false" disabled="disabled"><span class="vjs-icon-placeholder"
                              aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu vjs-hidden">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content" role="menu">
                              <li class="vjs-menu-item" tabindex="-1" role="menuitemradio" aria-disabled="false"
                                aria-checked="false"><span class="vjs-menu-item-text">captions off</span><span
                                  class="vjs-control-text" aria-live="polite"></span></li>
                              <li class="vjs-menu-item vjs-subtitles-menu-item vjs-selected" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">English</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button vjs-disabled"
                          type="button" title="Picture-in-Picture" aria-disabled="true" disabled="disabled"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_511_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_511_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_3_component_517_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_517_description">Beginning of dialog window. Escape will cancel and
                          close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_3_component_517">Text</legend><label
                                id="captions-foreground-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517">
                                <option id="captions-foreground-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517">
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_3_component_517">Background</legend><label
                                id="captions-background-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517">
                                <option id="captions-background-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517">
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_3_component_517">Window</legend><label
                                id="captions-window-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517">
                                <option id="captions-window-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517">
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Transparent" value="0"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_3_component_517" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_3_component_517">
                                <option id="captions-font-size-vjs_video_3_component_517-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_3_component_517" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_3_component_517">
                                <option id="vjs_video_3_component_517-None" value="none"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-None">None
                                </option>
                                <option id="vjs_video_3_component_517-Raised" value="raised"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Raised">Raised
                                </option>
                                <option id="vjs_video_3_component_517-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_3_component_517-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Uniform">Uniform
                                </option>
                                <option id="vjs_video_3_component_517-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_3_component_517" class="">Font Family</legend>
                              <select aria-labelledby="captions-font-family-vjs_video_3_component_517">
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_3_component_517-SmallCaps" value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_705_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_705_description">This is a modal window. This modal can be closed by
                          pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): 2313</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00168</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 17, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_25"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Medical
                            Device Surveillance & Assessment, Kaiser Permanente, San Diego, California</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_31"
                            class="annotation strong" style="position: unset;">A commentary by Paul J. King, MD,</span>
                          is linked to the online version of this article.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=2d1b5eb1-1eba-4644-8fb7-ef995f758498&native=1"
                              class="article-title" style="position: unset;">Dual-Mobility Articulations in Revision Hip
                              Arthroplasty<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">Commentary on an article by Monti Khatod, MD, et al.:
                                “Dual-Mobility Articulations in Revision Total Hip Arthroplasty. A Comparison with Metal
                                or Ceramic on Highly Cross-Linked Polyethylene and Constrained Articulations”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">King, Paul
                              J.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(24):e51 | December 18, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;e9b8ef32-0c5a-4261-b4be-95407996fecf&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=e9b8ef32-0c5a-4261-b4be-95407996fecf&type=pdf&name=JBJS.24.00168.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=e9b8ef32-0c5a-4261-b4be-95407996fecf&type=pdf&name=JBJS.24.00168.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_32"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_33" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I250"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I250</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;e9b8ef32-0c5a-4261-b4be-95407996fecf&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=e9b8ef32-0c5a-4261-b4be-95407996fecf&type=zip&name=JBJS.24.00168.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Monti Khatod, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1641-9132" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1641-9132</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Priscilla H. Chan, MS<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8998-0110" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8998-0110</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Heather A. Prentice, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:Heather.Prentice@kp.org" class=""
                    style="position: unset;">Heather.Prentice@kp.org</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2200-6564" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2200-6564</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Brian H. Fasig, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0000-0991-4317" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0000-0991-4317</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Elizabeth W. Paxton, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1941-8186" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1941-8186</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nithin C. Reddy, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6966-5068" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6966-5068</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Matthew P. Kelly, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9687-8616" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9687-8616</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Southern California Permanente Medical
                Group, West Los Angeles, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Medical Device Surveillance & Assessment, Kaiser Permanente, San Diego,
                California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Southern California Permanente Medical
                Group, San Diego, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Southern California Permanente Medical
                Group, Harbor City, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 31993.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
