/* -----------------Types--------------- */
import type { Stat } from "components";

import { events } from "@constants/tracking";
import { ChangeAvatarDialog } from "@features/profile/";
import useAnalytics from "@hooks/useAnalytics";
import { useCustomNavigation } from "@hooks/useCustomNavigation";
import { Box, Loader } from "@memorang/ui";
import { useState } from "react";
/* -----------------Child components--------------- */
import ProfileHeader from "./ProfileHeader";
import ProfileStatsView from "./ProfileStatsView";

type Section = {
	title: string;
	data: {
		title: string;
		subtitle?: string;
		icon?: string;
		type?: string;
		rightText?: string;
	}[];
};
type Props = {
	headerStats?: Stat[];
	avatar: string;
	name: string;
	statsTabData?: Section[];
};

const ProfileView = ({ headerStats, statsTabData, avatar, name }: Props) => {
	useAnalytics({
		eventName: events.profileViewed,
		name,
	});

	const [showChangeAvatarDialog, setShowChangeAvatarDialog] = useState(false);
	const { handleChangeAvatarNavigation } = useCustomNavigation();

	const toggleChangeAvatarDialog = () => {
		setShowChangeAvatarDialog(!showChangeAvatarDialog);
	};

	if (!statsTabData?.length) {
		return (
			<Box
				flexGrow={1}
				height={"100%"}
				padding={24}
				style={{
					minHeight: 200,
				}}
			>
				<Loader />
			</Box>
		);
	}

	const handleAvatarClick = () => {
		handleChangeAvatarNavigation(toggleChangeAvatarDialog);
	};
	return (
		<>
			<ProfileHeader
				stats={headerStats!}
				iconUrl={avatar}
				handleAvatarClick={handleAvatarClick}
			/>
			<ProfileStatsView sections={statsTabData} />
			{showChangeAvatarDialog && (
				<ChangeAvatarDialog
					visible={showChangeAvatarDialog}
					handleClose={toggleChangeAvatarDialog}
				/>
			)}
		</>
	);
};

export default ProfileView;
