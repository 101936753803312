// import { Calendar } from "react-native-calendars";
import AiInsightsSuggestion from "../../AiInsightsSuggestion";
import Box from "../../Box";
import CardContainer from "../../CardContainer";
import RowStats, { type Stat } from "../../RowStats";

type Props = {
	title: string;
	tooltip: string;
	subtitle: string;
	stats: Stat[];
	insights: string;
};
const OverallStreakCard = ({
	title,
	tooltip,
	subtitle,
	stats,
	insights,
}: Props) => {
	return (
		<CardContainer
			title={title}
			tooltip={tooltip}
			subtitle={subtitle}
			icon="fire"
		>
			<Box padding={16} gap={24}>
				<RowStats stats={stats} stacked={false} itemWidth="15%" />
				{/* <Box>
					<Calendar
						markedDates={markedDates}
						theme={{
							backgroundColor: "transparent",
							calendarBackground: "transparent",
							selectedDayBackgroundColor: theme.colors.primary,
							todayTextColor: theme.colors.primary,
							arrowColor: theme.colors.primary,
						}}
					/>
				</Box> */}
			</Box>
			<AiInsightsSuggestion text={insights} />
		</CardContainer>
	);
};

export default OverallStreakCard;
