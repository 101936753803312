/* ----------------- Layouts --------------- */
import OnboardingLayout from "../../../layouts/OnboardingLayout";
import AvatarList, { type Avatar } from "../../AvatarList";

interface Props {
	selectedAvatarId?: string;
	handleClickContinue: () => void;
	title: string;
	avatars: Avatar[];
	handleSelectAvatar: (avatarId: string) => void;
	showBackButton?: boolean;
	handleBack: () => void;
}
const SelectAvatarOnboardingView = ({
	selectedAvatarId,
	handleClickContinue,
	handleSelectAvatar,
	title,
	avatars,
	showBackButton,
	handleBack,
}: Props) => {
	return (
		<OnboardingLayout
			showBackButton={showBackButton}
			title={title}
			disableContinueButton={!selectedAvatarId}
			handleClickContinue={handleClickContinue}
			cta={selectedAvatarId ? "Continue with selected avatar" : "Choose avatar"}
			showChangeLater
			handleBack={handleBack}
		>
			<AvatarList
				data={avatars}
				handleSelectAvatar={handleSelectAvatar}
				selectedAvatarId={selectedAvatarId}
			/>
		</OnboardingLayout>
	);
};

export default SelectAvatarOnboardingView;
