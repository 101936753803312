import theme from "@configs/theme";
import type { LeaderboardItemType } from "@features/dashboard";
import { getRandomAvatar } from "@helpers/mocked-helpers";
import { formatNumber } from "@memorang/helpers";
import type { IconType } from "components/index";

// home
export const mockedHomePageHeaderStats = [
	{
		icon: "brain",
		value: "90%",
		label: "Mastery",
		color: theme().palette.purple.main,
	},

	{
		icon: "check-circle-outline",
		value: "200 items",
		label: "Answered",
		color: theme().colors.secondary,
	},
	{
		icon: "bullseye-arrow",
		value: "80%",
		label: "Accuracy",
		color: theme().colors.error,
	},
];
// product home page
const mockedProgressHomeViewStats = [
	{
		label: "Mastered",
		icon: "brain" as const,
		value: "90%",
		color: theme().palette.purple.main,
	},
	{
		label: "Answered",
		icon: "check-decagram-outline" as const,
		value: 100,
		color: theme().colors.secondary,
	},
	{
		label: "XP earned",
		icon: "medal-outline" as const,
		value: "1,200",
		color: theme().colors.error,
	},
];

// progress

/// /////////////////////////////////////////////////////
// TODO: CHANGE THESE VALUES WHEN UPDATING SCREENSHOTS //
/// /////////////////////////////////////////////////////
const numItems = 20;
const numTopics = 8;

export const mockedPracticeTestSummary = {
	title: "Summary",
	subtitle: "Based on 210 items",
	tooltip: "Based on 210 items",
	data: [
		{
			name: "Mastery",
			list: [
				{
					icon: "speedometer",
					title: "Mastered",
					description: "180 items",
					chipLabel: "85%",
					key: "high",
				},
				{
					icon: "speedometer-medium",
					title: "Almost there",
					description: "4 items",
					chipLabel: "2%",
					key: "medium",
				},
				{
					icon: "speedometer-slow",
					title: "Incorrect",
					description: "26 items",
					chipLabel: "13%",
					key: "low",
				},
			],
		},
		{
			name: "Quick stats",
			list: [
				{
					icon: "checkbox-multiple-outline",
					title: "Completion",
					description: "210 / 210",
					chipLabel: "100%",
				},
				{
					icon: "radar",
					title: "Accuracy",
					description: "210 responses",
					chipLabel: "85%",
				},
				{
					icon: "timer-outline",
					title: "Speed",
					description: "210 responses",
					chipLabel: "5 seconds",
				},
			],
		},
	],
};
const masteryData = [
	{ x: new Date("2021-09"), y: 1 },
	{ x: new Date("2021-10"), y: 2 },
	{ x: new Date("2021-11"), y: 1 },
	{ x: new Date("2021-12"), y: 2 },
];

const mockedMasteryChartProgressData = {
	subtitle: "Based on 1,000 items",
	title: "Items Mastered",
	stats: [
		{
			title: "Total Mastered",
			rightText: "500",
			icon: "check-outline",
		},
	],
	chartData: {
		data: masteryData,
		name: "mastery-area",
		fillColor: "#66bb68",
		strokeColor: "#66bb68",
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		xTickValues: masteryData.map<any>((item) => item.x),
	},
};

const mockedMasteryTagProgressCardTitle = "Topics";
const mockedMasteryTagProgressCardSubtitle = `Based on ${formatNumber(
	numItems,
)} items across ${numTopics} topics`;

// leaderboard
const mockedAvatarUrls: string[] = [
	"https://memorang-prod-icon.s3.us-east-1.amazonaws.com/avatars/nurse-asian-male.svg",
	"https://memorang-prod-icon.s3.us-east-1.amazonaws.com/avatars/burglar-asian-female.svg",
	"https://memorang-prod-icon.s3.us-east-1.amazonaws.com/avatars/superhero-spiderman-comics.svg",
	"https://memorang-prod-icon.s3.us-east-1.amazonaws.com/avatars/muslim-attire-black-male.svg",
	"https://memorang-prod-icon.s3.us-east-1.amazonaws.com/avatars/robot-3.svg",
	"https://memorang-prod-icon.s3.us-east-1.amazonaws.com/avatars/cowhand-asian-female.svg",
	"https://memorang-prod-icon.s3.us-east-1.amazonaws.com/avatars/avatar-steampunk-woman-female-african.svg",
	"https://memorang-prod-icon.s3.us-east-1.amazonaws.com/avatars/pharmacist-black-male.svg",
	"https://memorang-prod-icon.s3.us-east-1.amazonaws.com/avatars/accountant-black-female.svg",
	"https://memorang-prod-icon.s3.us-east-1.amazonaws.com/avatars/ems-worker-asian-female.svg",
];
const mockedUserLeaderboard = {
	username: "whitehorse0912",
	xp: 25245,
	avatar: getRandomAvatar(mockedAvatarUrls),
	rank: 1,
};

const mockedLeaderboard: Record<string, LeaderboardItemType[]> = {
	all_time: [
		{
			username: "whitehorse0912",
			xp: 25245,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "apick4064",
			xp: 18333,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "sdille7",
			xp: 14091,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "meetdb69",
			xp: 13395,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "fmendoza1963",
			xp: 12678,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "royelshad",
			xp: 11688,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "samanthajo95",
			xp: 10767,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "kerissa26",
			xp: 10303,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "lenachen",
			xp: 10071,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "blynsa14",
			xp: 8907,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
	],
	week: [
		{
			username: "whitehorse0912",
			xp: 25245,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "apick4064",
			xp: 18333,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "sdille7",
			xp: 14091,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "meetdb69",
			xp: 13395,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "fmendoza1963",
			xp: 12678,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "royelshad",
			xp: 11688,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "samanthajo95",
			xp: 10767,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "kerissa26",
			xp: 10303,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "lenachen",
			xp: 10071,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "blynsa14",
			xp: 8907,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
	],
	month: [
		{
			username: "whitehorse0912",
			xp: 25245,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "apick4064",
			xp: 18333,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "sdille7",
			xp: 14091,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "meetdb69",
			xp: 13395,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "fmendoza1963",
			xp: 12678,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "royelshad",
			xp: 11688,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "samanthajo95",
			xp: 10767,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "kerissa26",
			xp: 10303,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "lenachen",
			xp: 10071,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
		{
			username: "blynsa14",
			xp: 8907,
			avatar: getRandomAvatar(mockedAvatarUrls),
		},
	],
};

// profile
const mockedProfileDetails = {
	headerStats: [
		{
			icon: "shield-star-outline",
			value: "Level 100",
			label: "Rank",
			color: "#1AA251",
		},
		{
			icon: "check-circle-outline",
			value: "1,237 items",
			label: "Answered",
			color: "rgb(16, 96, 164)",
		},
		{
			icon: "medal-outline",
			value: "1,445",
			label: "XP Earned",
		},
	],
	statsTabData: [
		// {
		//   'title': 'Current Exam',
		//   'data': [
		//     {
		//       'icon': 'https://icon.memorang.com/icon/icon_bundle_63.png',
		//       'title': 'National Real Estate Salesperson',
		//       'type': 'exam',
		//     },
		//   ],
		// },
		// {
		// 	title: "Study Progress",
		// 	data: [
		// 		{
		// 			title: "November 24, 2023",
		// 			rightText: "22 Days until exam",
		// 			description: "Exam date",
		// 			icon: "calendar",
		// 		},
		// 	],
		// },
		{
			title: "Leaderboard",
			data: [
				{
					title: "Level 2,151",
					description: "Rank",
					icon: "medal-outline",
					rightText: "2,151",
				},
				{
					title: "9,966 XP",
					rightText: "2,151",
					description: "all-time",
					icon: "star-four-points-outline",
				},
				{
					title: "360 XP",
					rightText: "23,366",
					description: "this month",
					icon: "calendar-month-outline",
				},
				{
					title: "57 XP",
					rightText: "19,643",
					description: "this week",
					icon: "calendar-week",
				},
			],
		},
	],
};
// report

const mockedSummaryCardData = {
	title: "Challenges Complete!",
	score: 90,
	numItems: 20,
	stats: [
		{
			overlineText: "Mode",
			icon: "arm-flex-outline",
			value: "Challenge",
			tooltip: "How many questions you've answered",
		},
		{
			overlineText: "Accuracy",
			icon: "radar",
			value: "90%",
			tooltip: "How many questions you've answered correctly",
		},
		{
			overlineText: "Completed",
			icon: "check-circle-outline",
			value: "30 challenges",
			tooltip: "How long you took to complete the test",
		},
		{
			overlineText: "AVG SPEED",
			icon: "timer-outline",
			value: "1.2 secs",
			description: "How long you took to answer each question",
		},
	],
};

const mockedMasteryCardData = {
	title: "Session Mastery",
	subtitle: "Based on 20 items",
	sections: [
		{
			sectionTitle: "Correct",
			items: [
				{
					title: "Mastered",
					count: 12,
					icon: "speedometer" as IconType,
					type: "correct" as const,
					color: "#66bb68",
				},
				{
					title: "Almost There",
					count: 4,
					type: "almostcorrect" as const,
					icon: "speedometer-medium" as IconType,
					color: "#dda53a",
				},
			],
		},
		{
			sectionTitle: "Incorrect",
			items: [
				{
					title: "Needs Work",
					type: "incorrect" as const,
					count: 4,
					icon: "speedometer-slow" as IconType,
					color: "#d66565",
				},
			],
		},
	],
};
const mockedTagProgressData = [
	{
		title: "Topics",
		subtitle: "Based on 20 items across 4 topics",
		items: [
			{
				title: "Knee",
				distribution: {
					low: 13,
					none: 15,
					high: 9,
					medium: 15,
				},
				rightText: "10 items",
			},
			{
				title: "Shoulder",
				distribution: {
					low: 10,
					none: 12,
					high: 8,
					medium: 14,
				},
				rightText: "10 items",
			},
		],
	},
];

const mockedProgress = 1;
export {
	mockedProgressHomeViewStats,
	mockedMasteryChartProgressData,
	mockedMasteryTagProgressCardTitle,
	mockedMasteryTagProgressCardSubtitle,
	mockedAvatarUrls,
	mockedUserLeaderboard,
	mockedLeaderboard,
	mockedProfileDetails,
	mockedSummaryCardData,
	mockedMasteryCardData,
	mockedTagProgressData,
	mockedProgress,
};
