/* -----------------Globals--------------- */
import { useEffect, useState } from "react";

/* -----------------UI--------------- */
import { Loader } from "@memorang/ui";

import { fetchExams, useExamContext, useExams } from "@features/exam";
/* -----------------Helpers & Hooks--------------- */
import { useAppStore } from "@hooks/useAppStore";

/* -----------------Types--------------- */
import type { ExamDetails } from "@features/exam";

/* -----------------Child components--------------- */
import ProductsView from "../components/Store/ProductsView";

import { events } from "@constants/tracking";
import useAnalytics from "@hooks/useAnalytics";
/* -----------------Others--------------- */
import { useRouter } from "expo-router";

const StoreContainer = () => {
	const {
		handleSwitchExamContext,
		currentExamContext: { examId },
	} = useExamContext();
	const {
		app: { id: appId },
		tenant: { id: tenantId },
	} = useAppStore((store) => ({
		app: store.app,
		tenant: store.tenant,
	}));

	const router = useRouter();
	const { groupedExams, setExams } = useExams();

	const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

	const [searchQuery, setSearchQuery] = useState("");

	const currentCategoryExamSelected = groupedExams?.find((item) => {
		return item.data.find(({ exam }) => exam.id === examId);
	});
	const handleChangeSearch = (query: string) => setSearchQuery(query);

	const filteredProducts = groupedExams
		.map((section) => ({
			...section,
			data: section.data.filter((examDetails) =>
				examDetails.exam.name.toLowerCase().includes(searchQuery.toLowerCase()),
			),
		}))
		.filter(
			(section) =>
				(selectedFilters.length > 0
					? selectedFilters.includes(section.title)
					: true) &&
				(searchQuery
					? section.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
						section.data.length > 0
					: true),
		);

	const finalGroupedExams = (
		searchQuery || selectedFilters.length ? filteredProducts : groupedExams
	).map((section) => ({
		...section,
		data: section.data.sort((a, b) =>
			a.exam.id === examId ? -1 : b.exam.id === examId ? 1 : 0,
		),
	}));
	useAnalytics({
		eventName: events.storeViewed,
	});
	useEffect(() => {
		if (!groupedExams.length) {
			fetchExams(tenantId, appId).then((exams) => {
				setExams(exams);
			});
		}
	}, [tenantId, appId, setExams, groupedExams.length]);

	if (!groupedExams.length) {
		return <Loader />;
	}

	const interceptHandleSwitchExamContext = (examDetails: ExamDetails) => {
		handleSwitchExamContext(examDetails);
		router.push("/home");
	};

	const filteredGroupedExams = [...finalGroupedExams]
		.sort(({ title }) => {
			return title === currentCategoryExamSelected?.title ? -1 : 1;
		})
		.filter((item) => {
			return item.data.length > 0;
		});

	const handleSelectFilter = (filter: string) => {
		if (selectedFilters.includes(filter)) {
			setSelectedFilters(selectedFilters.filter((f) => f !== filter));
		} else {
			setSelectedFilters([...selectedFilters, filter]);
		}
	};

	const filters = groupedExams.map((section) => section.title);

	return (
		<ProductsView
			products={filteredGroupedExams}
			searchQuery={searchQuery}
			selectedFilters={selectedFilters}
			handleSelectFilter={handleSelectFilter}
			filters={filters}
			handleChangeSearch={handleChangeSearch}
			handleSwitchExamContext={interceptHandleSwitchExamContext}
		/>
	);
};

export default StoreContainer;
