/* ----------------- React Imports --------------- */
import { useMemo } from "react";
import { useWindowDimensions } from "react-native";

import ContentLoader from "react-native-easy-content-loader";
/* ----------------- Third Party Imports --------------- */
import { Card } from "react-native-paper";

/* ----------------- Helper Imports --------------- */
import { calculateCardsToShow } from "@memorang/helpers";
import { useAppTheme } from "../../../useAppTheme";
import { useDevice } from "../../../useDevice";
import Box from "../../Box";

const TaskCardSkeleton = () => {
	const { isMobile } = useDevice();
	const { width } = useWindowDimensions();
	const theme = useAppTheme();
	const cardWidth = useMemo(() => {
		const cardsToShow = calculateCardsToShow(width);
		return (width + (isMobile ? 64 : 2) * (cardsToShow + 1)) / cardsToShow;
	}, [width, isMobile]);

	const Content = () => {
		if (!isMobile) {
			return (
				//@ts-ignore
				<ContentLoader
					active
					title={false}
					pHeight={12}
					containerStyles={{
						marginTop: 8,
						marginLeft: -8,
					}}
					pRows={2}
				/>
			);
		}
		return null;
	};

	return (
		<Card
			style={{
				width: cardWidth,
				borderColor: theme.colors.surfaceVariant,
				borderRadius: 12,
				maxWidth: 400,
			}}
			mode="outlined"
		>
			<Box padding={16} gap={8}>
				{/*@ts-ignore */}
				<ContentLoader
					active
					avatar
					title
					aSize={40}
					pRows={1}
					pHeight={8}
					paragraphStyles={{
						marginLeft: 12,
					}}
					containerStyles={{
						marginLeft: -12,
					}}
					titleStyles={{
						height: 8,
						marginTop: 4,
						marginLeft: 12,
					}}
				/>
				<Content />
				{/*@ts-ignore */}
				<ContentLoader
					pHeight={40}
					pWidth={100}
					pRows={1}
					paragraphStyles={{
						borderRadius: 22,
						marginLeft: -8,
						marginTop: isMobile ? 12 : -2,
						marginBottom: -2,
					}}
					title={false}
				/>
			</Box>
		</Card>
	);
};

export default TaskCardSkeleton;
