/* ======== Hooks ========= */
import useProfile from "../hooks/useProfile";

import { useGlobalStore } from "@hooks/useGlobalStore";
import { useAuthContext } from "@memorang/applets";
import { Box, useDevice } from "@memorang/ui";
import { ScrollContainer } from "@memorang/ui";
import SnackBarCustom from "components/CustomSnackbar/CustomSnackbar";
import { Card, Portal } from "react-native-paper";
/* ======== Components ========= */
import ProfileView from "../components/Profile/ProfileView";

const ProfileContainer = () => {
	const { profileDetails } = useProfile();

	const { isMobile } = useDevice();

	const { newUserInfo } = useAuthContext();

	const { headerStats, statsTabData } = profileDetails || {};

	const setShowAvatarUpdatedMessage = useGlobalStore(
		(state) => state.setShowAvatarUpdatedMessage,
	);

	const showAvatarUpdatedMessage = useGlobalStore(
		(state) => state.showAvatarUpdatedMessage,
	);

	const Wrapper = isMobile ? Box : Card;
	return (
		<ScrollContainer
			contentContainerStyle={{
				flexGrow: 1,
				paddingBottom: 40,
			}}
			maxWidth="sm"
		>
			<Wrapper
				style={{
					marginTop: 16,
				}}
				flexGrow={1}
			>
				<ProfileView
					avatar={""}
					name={newUserInfo?.name!}
					statsTabData={statsTabData}
					headerStats={headerStats}
				/>
			</Wrapper>
			{showAvatarUpdatedMessage && (
				<Portal>
					<SnackBarCustom
						message="Avatar updated successfully"
						duration={2000}
						visible
						handleDismiss={() => {
							setShowAvatarUpdatedMessage(false);
						}}
					/>
				</Portal>
			)}
		</ScrollContainer>
	);
};

export default ProfileContainer;
