import type { Article } from "../../../../../types";

export const Article623: Article = {
	id: 623,
	rsuiteId: "142365e1-e37a-4a23-85a2-8a2854735ab5",
	type: "commentary and perspective",
	title: "Robot-Assisted Arthroplasty Research Focuses on the Wrong Outcomes",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/623.jpeg",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Robot-Assisted Arthroplasty Research Focuses on the Wrong
                Outcomes</div>
              <div class="text subtitle" style="position: unset;">Commentary on an article by Seper Ekhtiari, MD, MSc,
                FRCSC, et al.: “Evidence Versus Frenzy in Robotic Total Knee Arthroplasty. A Systematic Review Comparing
                News Media Claims to Randomized Controlled Trial Evidence”</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Kim Madden,
                      PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Anthony
                      Adili, MD, PEng, FRCSC</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">Robots have been used in arthroplasty for decades, but the
                  technology is now substantially growing in popularity, making the article by Ekhtiari et al. a timely
                  review that we read with interest. Their well-conducted review rightly points out that media reports
                  of robot-assisted arthroplasty are more likely than scientific reports to report positive outcomes.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">Studies evaluating the precision of implant placement
                  using robotic technology have been overwhelmingly positive, but studies assessing more
                  patient-important outcomes such as time to recovery and postoperative range of motion, function, and
                  pain reduction have been mixed. For example, Liow et al.<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a> found
                  that patients who underwent robot-assisted total knee arthroplasty (TKA) had better function scores at
                  2 years postoperatively compared with those who underwent manual TKA, whereas Clement et al.<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a> found better pain scores but no difference in function. It
                  may be the case that commonly used patient-reported outcome measures (PROMs) in arthroplasty do not
                  adequately measure important improvements in function, and therefore functional differences between
                  groups are not detected (e.g., due to ceiling effects, failure to measure what modern patients value,
                  or inability to discriminate small differences)<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">4</span></a>. We also believe that these studies comparing PROMs between
                  robot-assisted and manual TKA are not designed to assess a key rationale for the use of robots in
                  arthroplasty: that robotic technology is an enabling technology that allows the surgeon to have access
                  to more information and to personalize knee arthritis treatment with arthroplasty in a way that has
                  never been possible before. These outcomes are difficult to evaluate with randomized controlled trials
                  (RCTs), which may also account for the bias in media reporting toward more positive outcomes than in
                  the scientific studies to date.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">The rich intraoperative data collected by modern robotic
                  systems can be used in research to determine variables that have an impact on patient outcomes. For
                  example, the raw intraoperative data from robotic systems, in conjunction with motion capture systems
                  for gait analysis, can enable us to better understand biomechanics after arthroplasty in order to
                  inform patient-specific personalized treatment plans and future research. Additionally, the robotic
                  technology can be used as a novel teaching tool for trainees, as the extensive intraoperative
                  evaluation and data available with this new technology aid in visualizing and understanding the
                  variables that are under the direct control of the surgeon during the arthroplasty. The robotic
                  technology allows for modeling various scenarios preoperatively and intraoperatively before committing
                  to a final implant plan. Such capabilities, which are not available with manual techniques, allow
                  surgeons and trainees to visualize and better understand how these parameters interact with each
                  other, and their degree of codependency, in real time.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Perhaps most importantly, the robotic technology has
                  allowed surgeons to change their perspective on how TKA is performed. Surgeons can perform “à la
                  carte” surgery in which they preoperatively plan for and model multiple scenarios, then
                  intraoperatively choose the one that is the most appropriate based on the patient’s anatomy,
                  visualization of the preoperative plans using the robotic software, and surgical judgment. The
                  arthroplasty robot can be used to shift away from treatment of all grades of knee arthritis with TKA
                  and toward utilizing unicompartmental and even bicompartmental knee arthroplasties whenever possible,
                  with the patient’s anatomy and disease stage dictating the type of replacement employed. There is some
                  evidence that surgeons who use robots in arthroplasty are more likely to consider individualized
                  operative plans while surgeons who perform manual arthroplasty tend to choose a more standardized
                  approach<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">5</span></a>,
                  which could indicate that robotic technology opens new avenues for innovation in arthroplasty.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">In summary, Ekhtiari et al. conducted a high-quality
                  systematic review on media portrayals versus available evidence in robot-assisted TKA, which they
                  point out are mismatched. Although this is true, and caution is always required with emerging
                  interventions, we need more primary studies that go beyond evaluating implant placement or whether
                  surgeons can perform a better TKA with a robot. That is too simplistic and ignores the educational
                  benefits, availability of rich data, and innovation benefits such as changing surgeon paradigms about
                  how arthroplasty is performed. Robots are enabling tools that allow surgeons to hit their target
                  precisely; now, the question we have to ask ourselves is “what target are we aiming for?”</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Liow MHL</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Goh GSH</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Wong MK</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Chin PL</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Tay DKJ</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Yeo SJ</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Robotic-assisted total knee
                  arthroplasty may lead to improvement in quality-of-life measures: a 2-year follow-up of a prospective
                  randomized trial</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2017
                Sep;25(9):2942-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Robotic-assisted%20total%20knee%20arthroplasty%20may%20lead%20to%20improvement%20in%20quality-of-life%20measures%3A%20a%202-year%20follow-up%20of%20a%20prospective%20randomized%20trial&as_occt=title&as_sauthors=%20%22MHL%20Liow%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Clement ND</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Galloway S</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Baron J</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Smith K</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Weir DJ</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Deehan DJ</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Patients undergoing robotic
                  arm-assisted total knee arthroplasty have a greater improvement in knee-specific pain but not in
                  function</span>. <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Bone
                  Joint J.</span> 2024 May 1;106-B(5):450-1-2.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patients%20undergoing%20robotic%20arm-assisted%20total%20knee%20arthroplasty%20have%20a%20greater%20improvement%20in%20knee-specific%20pain%20but%20not%20in%20function&as_occt=title&as_sauthors=%20%22ND%20Clement%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Madden K</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">CORR Insights®: How Much
                  Perioperative Pain and Dysfunction Underlie the HOOS JR and KOOS JR?</span><span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2023 Sep
                1;481(9):1811-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=CORR%20Insights%C2%AE%3A%20How%20Much%20Perioperative%20Pain%20and%20Dysfunction%20Underlie%20the%20HOOS%20JR%20and%20KOOS%20JR%3F&as_occt=title&as_sauthors=%20%22K%20Madden%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Eckhard L</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Munir S</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Wood D</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Talbot S</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Brighton R</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Walter B</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Baré J</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">The ceiling effects of patient
                  reported outcome measures for total knee arthroplasty</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Orthop Traumatol Surg Res.</span> 2021
                May;107(3):102758.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20ceiling%20effects%20of%20patient%20reported%20outcome%20measures%20for%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22L%20Eckhard%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Luyckx T</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Monk AP</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Müller JH</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Saffarini M</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Lustig S</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Catani F</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Bonnin MP</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">What are the perceived benefits
                  and barriers to the use of robot-assisted total knee arthroplasty? A survey of members of the European
                  Knee Society</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Int Orthop.</span> 2023 Feb;47(2):405-1-2.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20are%20the%20perceived%20benefits%20and%20barriers%20to%20the%20use%20of%20robot-assisted%20total%20knee%20arthroplasty%3F%20A%20survey%20of%20members%20of%20the%20European%20Knee%20Society&as_occt=title&as_sauthors=%20%22T%20Luyckx%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D142365e1-e37a-4a23-85a2-8a2854735ab5%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Commentary and Perspective</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): e54</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.01079</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">December 18, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=66158ebb-9cdd-4502-bfe2-5cf477bb1a82&native=1"
                              class="article-title" style="position: unset;">Evidence Versus Frenzy in Robotic Total
                              Knee Arthroplasty<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">A Systematic Review Comparing News Media Claims to Randomized
                                Controlled Trial Evidence</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Ekhtiari,
                              Seper; Sun, Bryan; Sidhu, Rabbea; Ade-Conde, Ayomide Michael; Chaudhry, Harman; Tomescu,
                              Sebastian; Ravi, Bheeshma; Mundi, Raman</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(24):2384 | September 19, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;142365e1-e37a-4a23-85a2-8a2854735ab5&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=142365e1-e37a-4a23-85a2-8a2854735ab5&type=pdf&name=JBJS.24.01079.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=142365e1-e37a-4a23-85a2-8a2854735ab5&type=pdf&name=JBJS.24.01079.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_6" class="annotation strong"
                            style="position: unset;">Disclosure:</span> No external funding was received for this work.
                          The <span data-id="strong_7" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I236" target="_blank" data-id="link_1"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I236</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;142365e1-e37a-4a23-85a2-8a2854735ab5&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=142365e1-e37a-4a23-85a2-8a2854735ab5&type=zip&name=JBJS.24.01079.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kim Madden, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:maddenk@mcmaster.ca" class="" style="position: unset;">maddenk@mcmaster.ca</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2861-9636" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2861-9636</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Anthony Adili, MD, PEng, FRCSC<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4692-819X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4692-819X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Research Institute of St. Joseph’s Hamilton, Hamilton, Ontario, Canada</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Surgery, McMaster University, Hamilton, Ontario, Canada</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Health Research Methods, McMaster University, Hamilton, Ontario,
                Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">School of Biomedical Engineering, McMaster University, Hamilton, Ontario,
                Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 6173.41px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
