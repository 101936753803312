import type { Article } from "../../../../../types";

export const Article562: Article = {
	id: 562,
	rsuiteId: "67b3521f-6b66-4f8d-b6a9-5bc8e7d1bcb7",
	type: "the orthopaedic forum",
	title: "What’s Important: Health Literacy in Orthopaedics",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/562.png",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">What’s Important: Health Literacy in Orthopaedics</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Zachary C.
                      Lum, DO</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Courtney R.
                      Lyles, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">Orthopaedic surgeons have traditionally been considered
                  poor communicators, a paradigm that is quickly changing with the shift to the patient-centric model<a
                    href="" data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. Effective communication with patients can directly result
                  in better patient satisfaction, adherence, and outcomes, as well as lower malpractice litigation rates
                  for the surgeon. In the same vein, a patient’s health literacy plays an equally important role in the
                  ecosystem of health care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">The definition of health literacy has evolved over time,
                  cumulating in multiple governmental organizations (e.g., the Centers for Disease Control and
                  Prevention [CDC], the U.S. Department of Health and Human Services [HHS], and the Office of Disease
                  Prevention and Health Promotion [ODPHP]) agreeing on the term: the degree to which individuals have or
                  organizations equitably enable “the ability to find, understand, and use information and services to
                  inform health-related decisions and actions for themselves and others<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>.” This ability is based on several domains comprised of
                  individual aspects of health literacy, including reading and/or listening comprehension, numeracy
                  (calculating measurements or dosages), digital health literacy, self-advocacy, oral communication,
                  health-care navigation, health-care forms and/or documentation, medications, and critical thinking and
                  decision-making<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>. Low
                  health literacy in medicine and surgery has been directly linked to increased health-care costs,
                  higher surgical complication rates, higher mortality, and worse patient-reported outcomes<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">8</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">Health literacy in orthopaedics is not well studied, but
                  the studies that have been performed have directly correlated it with positive patient outcomes in
                  aspects such as less pain and better function, better treatment adherence, and greater satisfaction<a
                    href="" data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">12</span></a>. Roh
                  et al. reported on health literacy assessed using the Newest Vital Sign (NVS) screening tool in
                  patients with acute mallet finger injuries; they found that lower health literacy correlated with poor
                  rehabilitation adherence, resultant extensor lag, and decreased patient satisfaction<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">11</span></a>. Cosic et al. performed a randomized controlled trial in
                  orthopaedic trauma patients with lower-limb fractures. Their intervention provided patients with an
                  additional discussion with illustrations about their fracture and its subsequent management prior to
                  discharge, which resulted in a higher satisfaction score in patients who received the additional
                  information (88.5% versus 69.7%). These results suggested that even small steps in addressing and
                  improving health literacy in orthopaedic surgery can have substantial impacts on patients<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">12</span></a>. Outside of the orthopaedic realm, many studies have linked
                  health literacy with reduced health-care costs from readmissions, hospitalization time, and
                  complications<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">13</span></a>.
                  Furthermore, according to the U.S. Census Bureau, the number of individuals with limited English
                  proficiency (LEP) is increasing, necessitating increased communication and health literacy standards<a
                    href="" data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">14</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Health literacy has strong associations with the social
                  determinants of health (SDOH), specifically income, education, race and ethnicity, and even geographic
                  location<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">17</span></a>. Given that health literacy may be modifiable and/or
                  addressed directly with interventions, there is a strong argument that focusing on health literacy
                  will improve equity and impact patient outcomes. A focus on more immediate health literacy needs will
                  not solve all of the core inequities for patients and communities, but it will be critical on the
                  pathway to achieving equity within our health-care systems.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">There are numerous general instruments to measure health
                  literacy, including the Test of Functional Health Literacy in Adults (TOFHLA), the Rapid Estimate of
                  Adult Literacy in Medicine (REALM), the Short Assessment of Health Literacy (SAHL), the NVS, and
                  several brief health literacy screening tools. All of these instruments test several of the components
                  of health literacy that were mentioned earlier; however, some are too brief and none are fully
                  comprehensive, which contributes to the drawbacks of limited depth and survey fatigue, respectively.
                  More importantly, criticisms of health literacy include the broadness and vagueness of the
                  measurements and the generalizability; critics also believe that, at times, interventions to improve
                  health literacy may not produce the expected positive outcomes<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">18</span></a>. These surprising results may be due to the challenges that
                  the medical field faces in general, the chronicity of certain illnesses, or the expectations and
                  values that patients associate with their own health<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">19</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">A notable advantage in the field of orthopaedic surgery is
                  the specificity of our goals, which typically are centered around a patient’s pain or functional
                  capacity<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">21</span></a>. These objectives are quantifiable and follow a treatment
                  trajectory with distinct phases, including a beginning, a middle, and an end. In contrast, chronic
                  diseases often entail more complex and enduring treatment journeys, potentially leaving patients
                  disheartened by the continuous burden of treatment without a clear end point<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">22</span></a>. In orthopaedic surgery, achieving pain relief and
                  functional milestones serve as tangible end points. Unlike the more entrenched SDOH, such as education
                  level, the Area Deprivation Index, or income, health literacy can be modified through relatively small
                  interventions, resulting in important and meaningful improvements in patient outcomes, all while
                  reducing health-care costs<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">25</span></a>. A common example of this is a preoperative joint
                  replacement class, where patients receive the opportunity to ask questions and receive feedback, which
                  may enhance adherence to perioperative protocols. Likewise, modest interventions can yield substantial
                  improvements in musculoskeletal health literacy. Therefore, we as orthopaedic surgeons have the
                  opportunity to lead the movement in health literacy and serve as the blueprint for other specialties.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Call to
                Action</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Given the powerful influence of health literacy on patient
                  outcomes, orthopaedic surgeons can play a pivotal role in addressing disparities through actionable
                  solutions in order to make a difference in outcomes<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">26</span></a>.
                  The teach-back method, also known as the “show me” or “closing-the-loop” technique, is a communication
                  strategy that is designed to increase patient understanding of medical information and instructions.
                  It is a valuable tool that orthopaedic surgeons can employ to ensure that patients comprehend the
                  information they receive; it has led to greater patient knowledge, health literacy, and satisfaction,
                  as well as better outcomes and lower complication rates<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">28</span></a>. Notably, it has garnered recommendations from reputable
                  health-care institutions such as the Agency for Healthcare Research and Quality (AHRQ), the Institute
                  for Healthcare Improvement, The Joint Commission, and others. Furthermore, health-care team members
                  other than orthopaedic surgeons can play a role in implementing the teach-back method<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">30</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">A practice of health literacy is to simplify communication
                  with patients in order to ensure that medical information is understandable and thereby accessible to
                  all patients. By using plain language and presenting information in clear, digestible chunks,
                  orthopaedic surgeons can foster better patient comprehension, which directly improves treatment
                  adherence and patient outcome<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">31</span></a>.
                  Additionally, visual aids can serve as invaluable tools to improve patients’ understanding of their
                  condition and enable them to make more informed decisions regarding complex treatment options<a
                    href="" data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">32</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Digital health tools offer approaches to improve health
                  literacy in orthopaedic patients, and they have several notable advantages when compared with
                  traditional, outdated pamphlets. These tools allow for self-paced learning and can be interactive,
                  providing a more personalized and effective educational experience. Examples include a comprehension
                  check regarding informed consent, video-based education and/or virtual training, and online patient
                  engagement platforms<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">37</span></a>. However, it is essential to acknowledge that these digital
                  health tools may require a baseline level of digital literacy for the patient to be able to access and
                  utilize them effectively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Artificial intelligence is another emerging field within
                  the health literacy space that shows promising results. Recent studies have reported enhanced patient
                  education and informed consent with use of large language models, and new applications are continually
                  emerging<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">38</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">39</span></a>. However, a healthy amount of caution should be reserved
                  because artificial intelligence can craft convincingly incorrect statements that perpetuate
                  misinformation to the unknowing individual.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The evolving landscape of orthopaedic surgery emphasizes
                  the important role of effective communication and health literacy in patient-centered care. We
                  recognize the impact of health literacy on patient outcomes, satisfaction, and health-care costs. The
                  strong associations between health literacy and established SDOH such as income and education also
                  cannot be ignored. Studies in orthopaedics have demonstrated that even small educational efforts to
                  address and improve health literacy can lead to substantial improvements in patient outcomes,
                  particularly in terms of pain, function, and satisfaction. This underscores the need for a
                  comprehensive musculoskeletal health literacy assessment tool that is tailored to the specific goals
                  of orthopaedic care. As we navigate the challenges and opportunities surrounding health literacy in
                  orthopaedics, it becomes clear that health literacy is a vital component of the strategy to enhance
                  access, quality, and equity in musculoskeletal medicine.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Burney DW III</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Surgeon-Patient Communication:
                  The Key to Patient Satisfaction, Patient-Centered Care, and Shared Decision Making</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Instr Course Lect.</span>
                2017 Feb 15;66:659-65.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgeon-Patient%20Communication%3A%20The%20Key%20to%20Patient%20Satisfaction%2C%20Patient-Centered%20Care%2C%20and%20Shared%20Decision%20Making&as_occt=title&as_sauthors=%20%22DW%20Burney%2C%20III%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;">Healthy
                People 2030. <span data-id="strong_2" class="annotation strong" style="position: unset;">Health Literacy
                  in Healthy People 2030</span>. Accessed 2024 Jun 2. <a
                  href="https://health.gov/healthypeople/priority-areas/health-literacy-healthy-people-2030"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://health.gov/healthypeople/priority-areas/health-literacy-healthy-people-2030</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20Literacy%20in%20Healthy%20People%202030&as_occt=title&as_sauthors=%20%22Healthy%20People%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Hersh L</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Salzman B</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Snyderman D</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Health Literacy in Primary Care
                  Practice</span>. <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Am
                  Fam Physician.</span> 2015 Jul 15;92(2):118-24.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20Literacy%20in%20Primary%20Care%20Practice&as_occt=title&as_sauthors=%20%22L%20Hersh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Wright JP</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Edwards GC</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Goggins K</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Tiwari V</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Maiga A</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Moses K</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Kripalani S</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Idrees K</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Association of Health Literacy
                  With Postoperative Outcomes in Patients Undergoing Major Abdominal Surgery</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">JAMA Surg.</span> 2018 Feb
                1;153(2):137-42.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20of%20Health%20Literacy%20With%20Postoperative%20Outcomes%20in%20Patients%20Undergoing%20Major%20Abdominal%20Surgery&as_occt=title&as_sauthors=%20%22JP%20Wright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Shahid R</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Shoker M</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Chu LM</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Frehlick R</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Ward H</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Pahwa P</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Impact of low health literacy on
                  patients’ health outcomes: a multicenter cohort study</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">BMC Health Serv Res.</span> 2022 Sep
                12;22(1):1148.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impact%20of%20low%20health%20literacy%20on%20patients%E2%80%99%20health%20outcomes%3A%20a%20multicenter%20cohort%20study&as_occt=title&as_sauthors=%20%22R%20Shahid%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Theiss LM</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Wood T</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> McLeod MC</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Shao C</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Santos Marques ID</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Bajpai S</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Lopez E</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Duong AM</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Hollis R</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Morris MS</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Chu DI</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">The association of health
                  literacy and postoperative complications after colorectal surgery: A cohort study</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Am J Surg.</span> 2022
                Jun;223(6):1047-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20association%20of%20health%20literacy%20and%20postoperative%20complications%20after%20colorectal%20surgery%3A%20A%20cohort%20study&as_occt=title&as_sauthors=%20%22LM%20Theiss%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Peterson PN</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Shetterly SM</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Clarke CL</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Bekelman DB</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Chan PS</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Allen LA</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Matlock DD</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Magid DJ</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Masoudi FA</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Health literacy and outcomes
                  among patients with heart failure</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2011 Apr 27;305(16):1695-701.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20literacy%20and%20outcomes%20among%20patients%20with%20heart%20failure&as_occt=title&as_sauthors=%20%22PN%20Peterson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Hälleberg Nyman M</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Nilsson U</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Dahlberg K</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Jaensson M</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Association Between Functional
                  Health Literacy and Postoperative Recovery, Health Care Contacts, and Health-Related Quality of Life
                  Among Patients Undergoing Day Surgery: Secondary Analysis of a Randomized Clinical Trial</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">JAMA Surg.</span> 2018 Aug
                1;153(8):738-45.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20Between%20Functional%20Health%20Literacy%20and%20Postoperative%20Recovery%2C%20Health%20Care%20Contacts%2C%20and%20Health-Related%20Quality%20of%20Life%20Among%20Patients%20Undergoing%20Day%20Surgery%3A%20Secondary%20Analysis%20of%20a%20Randomized%20Clinical%20Trial&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Narayanan AS</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Stoll KE</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Pratson LF</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Lin FC</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Olcott CW</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Del Gaizo DJ</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Musculoskeletal Health Literacy
                  is Associated With Outcome and Satisfaction of Total Knee Arthroplasty</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2021
                Jul;36(7S):S192-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Musculoskeletal%20Health%20Literacy%20is%20Associated%20With%20Outcome%20and%20Satisfaction%20of%20Total%20Knee%20Arthroplasty&as_occt=title&as_sauthors=%20%22AS%20Narayanan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Lacey RJ</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Campbell P</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Lewis M</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Protheroe J</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">The Impact of Inadequate Health
                  Literacy in a Population with Musculoskeletal Pain</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Health Lit Res Pract.</span> 2018 Dec
                6;2(4):e215-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Impact%20of%20Inadequate%20Health%20Literacy%20in%20a%20Population%20with%20Musculoskeletal%20Pain&as_occt=title&as_sauthors=%20%22RJ%20Lacey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Roh YH</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Lee BK</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Park MH</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Noh JH</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Gong HS</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Baek GH</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Effects of health literacy on
                  treatment outcome and satisfaction in patients with mallet finger injury</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">J Hand Ther.</span> 2016
                Oct-Dec;29(4):459-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20health%20literacy%20on%20treatment%20outcome%20and%20satisfaction%20in%20patients%20with%20mallet%20finger%20injury&as_occt=title&as_sauthors=%20%22YH%20Roh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Cosic F</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Kimmel L</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Edwards E</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Health Literacy in Orthopaedic
                  Trauma Patients</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2017 Mar;31(3):e90-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20Literacy%20in%20Orthopaedic%20Trauma%20Patients&as_occt=title&as_sauthors=%20%22F%20Cosic%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Bailey SC</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Fang G</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Annis IE</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> O’Conor R</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Paasche-Orlow MK</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Wolf MS</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Health literacy and 30-day
                  hospital readmission after acute myocardial infarction</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">BMJ Open.</span> 2015 Jun
                11;5(6):e006975.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20literacy%20and%2030-day%20hospital%20readmission%20after%20acute%20myocardial%20infarction&as_occt=title&as_sauthors=%20%22SC%20Bailey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;">Limited
                English Proficiency. <span data-id="strong_14" class="annotation strong" style="position: unset;">Data
                  and Language Maps</span>. 2023. Accessed 2024 Mar 18. <a href="https://www.lep.gov/maps"
                  target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://www.lep.gov/maps</a></span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Data%20and%20Language%20Maps&as_occt=title&as_sauthors=%20%22Limited%20English%20Proficiency%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Garcia-Codina O</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Juvinyà-Canal D</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Amil-Bujan P</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Bertran-Noguer C</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> González-Mestre MA</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Masachs-Fatjo E</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Santaeugènia SJ</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Magrinyà-Rull P</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Saltó-Cerezuela E</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Determinants of health literacy
                  in the general population: results of the Catalan health survey</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">BMC Public Health.</span> 2019 Aug
                16;19(1):1122.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Determinants%20of%20health%20literacy%20in%20the%20general%20population%3A%20results%20of%20the%20Catalan%20health%20survey&as_occt=title&as_sauthors=%20%22O%20Garcia-Codina%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Bennett IM</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Chen J</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Soroui JS</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> White S</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">The contribution of health
                  literacy to disparities in self-rated health status and preventive health behaviors in older
                  adults</span>. <span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Ann
                  Fam Med.</span> 2009 May-Jun;7(3):204-11.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20contribution%20of%20health%20literacy%20to%20disparities%20in%20self-rated%20health%20status%20and%20preventive%20health%20behaviors%20in%20older%20adults&as_occt=title&as_sauthors=%20%22IM%20Bennett%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Livingood WC</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Bautista MAB</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Smotherman C</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Azueta D</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Coleman J</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Grewal R</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Stewart E</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Orlando LA</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Scuderi C</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Comparative study of different
                  SES neighborhood clinics for health literacy and internet access</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">Digit Health.</span> 2022 Sep
                4;8:20552076221123715.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparative%20study%20of%20different%20SES%20neighborhood%20clinics%20for%20health%20literacy%20and%20internet%20access&as_occt=title&as_sauthors=%20%22WC%20Livingood%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Persell SD</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Karmali KN</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Lazar D</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Friesema EM</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Lee JY</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Rademaker A</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Kaiser D</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Eder M</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> French DD</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Brown T</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Wolf MS</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Effect of Electronic Health
                  Record-Based Medication Support and Nurse-Led Medication Therapy Management on Hypertension and
                  Medication Self-management: A Randomized Clinical Trial</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">JAMA Intern Med.</span> 2018 Aug
                1;178(8):1069-77.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20Electronic%20Health%20Record-Based%20Medication%20Support%20and%20Nurse-Led%20Medication%20Therapy%20Management%20on%20Hypertension%20and%20Medication%20Self-management%3A%20A%20Randomized%20Clinical%20Trial&as_occt=title&as_sauthors=%20%22SD%20Persell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Salimy MS</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Humphrey TJ</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Katakam A</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Melnic CM</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Heng M</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Bedair HS</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Which Factors Are Considered by
                  Patients When Considering Total Joint Arthroplasty? A Discrete-choice Experiment</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2023 Mar 1;481(3):427-37.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Which%20Factors%20Are%20Considered%20by%20Patients%20When%20Considering%20Total%20Joint%20Arthroplasty%3F%20A%20Discrete-choice%20Experiment&as_occt=title&as_sauthors=%20%22MS%20Salimy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Adie S</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Harris IA</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Naylor JM</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Mittal R</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Are outcomes reported in
                  surgical randomized trials patient-important? A systematic review and meta-analysis</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">Can J Surg.</span> 2017
                Apr;60(2):86-93.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Are%20outcomes%20reported%20in%20surgical%20randomized%20trials%20patient-important%3F%20A%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22S%20Adie%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> O’Hara NN</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Kringos DS</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Slobogean GP</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Degani Y</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Klazinga NS</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Patients Place More of an
                  Emphasis on Physical Recovery Than Return to Work or Financial Recovery</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2021 Jun 1;479(6):1333-43.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patients%20Place%20More%20of%20an%20Emphasis%20on%20Physical%20Recovery%20Than%20Return%20to%20Work%20or%20Financial%20Recovery&as_occt=title&as_sauthors=%20%22NN%20O%E2%80%99Hara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Gold SM</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Köhler-Forsberg O</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Moss-Morris R</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Mehnert A</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Miranda JJ</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Bullinger M</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Steptoe A</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Whooley MA</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Otte C</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Comorbid depression in medical
                  diseases</span>. <span data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Nat
                  Rev Dis Primers.</span> 2020 Aug 20;6(1):69.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comorbid%20depression%20in%20medical%20diseases&as_occt=title&as_sauthors=%20%22SM%20Gold%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Jones ED</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Davidson LJ</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Cline TW</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">The Effect of Preoperative
                  Education Prior to Hip or Knee Arthroplasty on Immediate Postoperative Outcomes</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">Orthop Nurs.</span> 2022
                Jan-Feb 01;41(1):4-12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Effect%20of%20Preoperative%20Education%20Prior%20to%20Hip%20or%20Knee%20Arthroplasty%20on%20Immediate%20Postoperative%20Outcomes&as_occt=title&as_sauthors=%20%22ED%20Jones%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Soeters R</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> White PB</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Murray-Weir M</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Koltsov JCB</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Alexiades MM</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Ranawat AS</span>; <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Hip and Knee Surgeons Writing
                  Committee. Preoperative Physical Therapy Education Reduces Time to Meet Functional Milestones After
                  Total Joint Arthroplasty</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2018 Jan;476(1):40-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20and%20Knee%20Surgeons%20Writing%20Committee.%20Preoperative%20Physical%20Therapy%20Education%20Reduces%20Time%20to%20Meet%20Functional%20Milestones%20After%20Total%20Joint%20Arthroplasty&as_occt=title&as_sauthors=%20%22R%20Soeters%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Kelmer GC</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Turcotte JJ</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Dolle SS</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Angeles JD</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> MacDonald JH</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> King PJ</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Preoperative Education for
                  Total Joint Arthroplasty: Does Reimbursement Reduction Threaten Improved Outcomes?</span><span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2021
                Aug;36(8):2651-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preoperative%20Education%20for%20Total%20Joint%20Arthroplasty%3A%20Does%20Reimbursement%20Reduction%20Threaten%20Improved%20Outcomes%3F&as_occt=title&as_sauthors=%20%22GC%20Kelmer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Lans A</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Schwab JH</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Health Literacy in
                  Orthopaedics</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2023 Apr 15;31(8):382-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20Literacy%20in%20Orthopaedics&as_occt=title&as_sauthors=%20%22A%20Lans%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Wang J</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Hu H</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Sun J</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Zhang Q</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Chen Z</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Wang Q</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Zhu M</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Yao J</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Yuan H</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Zhang X</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">The effectiveness of health
                  education based on the 5Ts for teach-back on oral nutritional supplements compliance of post-discharge
                  patients after surgery for gastric cancer: a randomized controlled trial</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Support Care Cancer.</span>
                2023 Feb 11;31(3):157.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effectiveness%20of%20health%20education%20based%20on%20the%205Ts%20for%20teach-back%20on%20oral%20nutritional%20supplements%20compliance%20of%20post-discharge%20patients%20after%20surgery%20for%20gastric%20cancer%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22J%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Cheng GZ</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Chen A</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Xin Y</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Ni QQ</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Using the teach-back method to
                  improve postpartum maternal-infant health among women with limited maternal health literacy: a
                  randomized controlled study</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">BMC Pregnancy Childbirth.</span> 2023 Jan 9;23(1):13.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Using%20the%20teach-back%20method%20to%20improve%20postpartum%20maternal-infant%20health%20among%20women%20with%20limited%20maternal%20health%20literacy%3A%20a%20randomized%20controlled%20study&as_occt=title&as_sauthors=%20%22GZ%20Cheng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Anderson KM</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Leister S</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> De Rego R</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">The 5Ts for Teach Back: An
                  Operational Definition for Teach-Back Training</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">Health Lit Res Pract.</span> 2020 Apr
                9;4(2):e94-103.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%205Ts%20for%20Teach%20Back%3A%20An%20Operational%20Definition%20for%20Teach-Back%20Training&as_occt=title&as_sauthors=%20%22KM%20Anderson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Caplin M</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Saunders T</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Utilizing Teach-Back to
                  Reinforce Patient Education: A Step-by-Step Approach</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">Orthop Nurs.</span> 2015 Nov-Dec;34(6):365-8,
                quiz :369-70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Utilizing%20Teach-Back%20to%20Reinforce%20Patient%20Education%3A%20A%20Step-by-Step%20Approach&as_occt=title&as_sauthors=%20%22M%20Caplin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Tavakoly Sany SB</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Behzhad F</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Ferns G</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Peyman N</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Communication skills training
                  for physicians improves health literacy and medical outcomes among patients with hypertension: a
                  randomized controlled trial</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">BMC Health Serv Res.</span> 2020 Jan 23;20(1):60.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Communication%20skills%20training%20for%20physicians%20improves%20health%20literacy%20and%20medical%20outcomes%20among%20patients%20with%20hypertension%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22SB%20Tavakoly%20Sany%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Sepucha K</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Atlas SJ</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Chang Y</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Dorrwachter J</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Freiberg A</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Mangla M</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Rubash HE</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Simmons LH</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Cha T</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Patient Decision Aids Improve
                  Decision Quality and Patient Experience and Reduce Surgical Rates in Routine Orthopaedic Care: A
                  Prospective Cohort Study</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2017 Aug 2;99(15):1253-60.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1396064" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Cheng JW</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Christakis DA</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Kieran K</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Niessen BA</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Fernandez N</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Merguerian PA</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Chang EK</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Shnorhavorian M</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Association between YouTube®
                  videos and health literacy with postoperative healthcare utilization following pediatric urologic
                  surgery: A randomized trial</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">J Pediatr Urol.</span> 2024 Apr;20(2):226.e1-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20between%20YouTube%C2%AE%20videos%20and%20health%20literacy%20with%20postoperative%20healthcare%20utilization%20following%20pediatric%20urologic%20surgery%3A%20A%20randomized%20trial&as_occt=title&as_sauthors=%20%22JW%20Cheng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Smith SK</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Trevena L</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Simpson JM</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Barratt A</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Nutbeam D</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> McCaffery KJ</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">A decision aid to support
                  informed choices about bowel cancer screening among adults with low education: randomised controlled
                  trial</span>. <span data-id="emphasis_32" class="annotation emphasis"
                  style="position: unset;">BMJ.</span> 2010 Oct 26;341:c5370.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20decision%20aid%20to%20support%20informed%20choices%20about%20bowel%20cancer%20screening%20among%20adults%20with%20low%20education%3A%20randomised%20controlled%20trial&as_occt=title&as_sauthors=%20%22SK%20Smith%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Press VG</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Arora VM</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Kelly CA</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Carey KA</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> White SR</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Wan W</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Effectiveness of Virtual vs
                  In-Person Inhaler Education for Hospitalized Patients With Obstructive Lung Disease: A Randomized
                  Clinical Trial</span>. <span data-id="emphasis_33" class="annotation emphasis"
                  style="position: unset;">JAMA Netw Open.</span> 2020 Jan 3;3(1):e1918205.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effectiveness%20of%20Virtual%20vs%20In-Person%20Inhaler%20Education%20for%20Hospitalized%20Patients%20With%20Obstructive%20Lung%20Disease%3A%20A%20Randomized%20Clinical%20Trial&as_occt=title&as_sauthors=%20%22VG%20Press%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Knapp PW</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Keller RA</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Mabee KA</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Pillai R</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Frisch NB</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Quantifying Patient Engagement
                  in Total Joint Arthroplasty Using Digital Application-Based Technology</span>. <span
                  data-id="emphasis_34" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2021
                Sep;36(9):3108-17.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Quantifying%20Patient%20Engagement%20in%20Total%20Joint%20Arthroplasty%20Using%20Digital%20Application-Based%20Technology&as_occt=title&as_sauthors=%20%22PW%20Knapp%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Holte AJ</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Molloy IB</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Werth PM</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Jevsevar DS</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Do Patient Engagement Platforms
                  in Total Joint Arthroplasty Improve Patient-Reported Outcomes?</span><span data-id="emphasis_35"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2021
                Dec;36(12):3850-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Do%20Patient%20Engagement%20Platforms%20in%20Total%20Joint%20Arthroplasty%20Improve%20Patient-Reported%20Outcomes%3F&as_occt=title&as_sauthors=%20%22AJ%20Holte%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Mika AP</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Martin JR</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Engstrom SM</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Polkowski GG</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Wilson JM</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Assessing ChatGPT Responses to
                  Common Patient Questions Regarding Total Hip Arthroplasty</span>. <span data-id="emphasis_36"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2023 Oct
                4;105(19):1519-26.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=ec43972f-c59d-49fe-ac19-c46efafe8cc1" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Decker H</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Trang K</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Ramirez J</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Colley A</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Pierce L</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Coleman M</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Bongiovanni T</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Melton GB</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Wick E</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Large Language Model-Based
                  Chatbot vs Surgeon-Generated Informed Consent Documentation for Common Procedures</span>. <span
                  data-id="emphasis_37" class="annotation emphasis" style="position: unset;">JAMA Netw Open.</span> 2023
                Oct 2;6(10):e2336997.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Large%20Language%20Model-Based%20Chatbot%20vs%20Surgeon-Generated%20Informed%20Consent%20Documentation%20for%20Common%20Procedures&as_occt=title&as_sauthors=%20%22H%20Decker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D67b3521f-6b66-4f8d-b6a9-5bc8e7d1bcb7%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F21%2F2042%26topics%3Doe"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 6, 2024;
                      106 (21): 2042</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00367</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">June 19, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;67b3521f-6b66-4f8d-b6a9-5bc8e7d1bcb7&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;oe&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=67b3521f-6b66-4f8d-b6a9-5bc8e7d1bcb7&type=pdf&name=JBJS.24.00367.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=67b3521f-6b66-4f8d-b6a9-5bc8e7d1bcb7&type=pdf&name=JBJS.24.00367.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_40"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_41" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I71" target="_blank" data-id="link_3" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I71</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;67b3521f-6b66-4f8d-b6a9-5bc8e7d1bcb7&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;oe&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=67b3521f-6b66-4f8d-b6a9-5bc8e7d1bcb7&type=zip&name=JBJS.24.00367.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Zachary C. Lum, DO<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:zacharylum@gmail.com" class="" style="position: unset;">zacharylum@gmail.com</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5871-8539" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5871-8539</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Courtney R. Lyles, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1111-8595" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1111-8595</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, UC Davis Medical Center, School of Medicine,
                University of California, Davis, Sacramento, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Public Health Sciences & Center for Healthcare Policy and
                Research, University of California, Davis, Sacramento, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 14864.2px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
