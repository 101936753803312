/* -----------------Globals--------------- */
import { Text } from "react-native-paper";

/* -----------------Icons--------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { Image } from "@memorang/ui";
import type { DimensionValue } from "react-native";
import type { IconType } from "../../types";
/* -----------------Child components--------------- */
import { useAppTheme } from "../../useAppTheme";
import { useDevice } from "../../useDevice";
import Box, { type BoxProps } from "../Box";
import DonutChart from "../DonutChart";

export type Stat = {
	label: string;
	icon: string;
	color?: string;
	textColor?: string;
	value: string | number;
	score?: number;
};
type Props = {
	stats: Stat[];
	stacked?: boolean;
	showBorder?: boolean;
	flexGrow?: number;
	itemWidth?: string;
} & BoxProps;
const RowStats = ({
	stats,
	stacked = true,
	showBorder,
	flexGrow = 1,
	itemWidth,
	...rest
}: Props) => {
	const theme = useAppTheme();
	const { isMobile } = useDevice();
	return (
		<Box
			flexDirection="row"
			gap={showBorder ? 8 : 24}
			alignItems="center"
			flexGrow={flexGrow}
			justifyContent={"space-around"}
			alignSelf="center"
			maxWidth={isMobile ? "100%" : 350}
			flexWrap="wrap"
			{...rest}
		>
			{stats.map(({ label, icon, value, color, textColor, score }) => {
				const StatView = () => {
					return (
						<>
							{score != null ? (
								<DonutChart
									distribution={[score, 100 - score]}
									innerRadius={22}
									radius={28}
									variant={"progress"}
									score={score}
								>
									{icon?.startsWith("http") ? (
										<Image
											source={{ uri: icon }}
											style={{ width: 24, height: 24 }}
										/>
									) : (
										<Icon
											name={icon as IconType}
											color={color || theme.colors.onSurfaceVariant}
											size={24}
										/>
									)}
								</DonutChart>
							) : (
								<Icon
									name={icon as IconType}
									color={color || theme.colors.onSurfaceVariant}
									size={24}
								/>
							)}
							<Box>
								<Text
									variant="bodyMedium"
									style={{
										textAlign: stacked ? "center" : "left",
									}}
								>
									{value}
								</Text>
								<Text
									variant="labelSmall"
									style={{
										color: textColor || theme.colors.onSurfaceVariant,
										textAlign: stacked ? "center" : "left",
									}}
								>
									{label}
								</Text>
							</Box>
						</>
					);
				};
				return (
					<Box
						flexDirection={stacked ? "column" : "row"}
						gap={4}
						key={label}
						alignItems="center"
						borderWidth={showBorder ? 1 : 0}
						borderColor={theme.colors.outlineVariant}
						borderRadius={12}
						padding={showBorder ? 8 : 0}
						flexGrow={1}
						width={itemWidth as DimensionValue}
						justifyContent="center"
					>
						<StatView />
					</Box>
				);
			})}
		</Box>
	);
};

export default RowStats;
