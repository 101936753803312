import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import { Platform } from "react-native";

export const saveFileToFileSystem = async ({
	uri,
	base64,
	fileName,
	mimeType,
}: {
	uri: string;
	base64: string;
	fileName: string;
	mimeType: string;
}) => {
	if (Platform.OS !== "android") {
		return Sharing.shareAsync(uri);
	}

	const permissions =
		await FileSystem.StorageAccessFramework.requestDirectoryPermissionsAsync();

	if (!permissions.granted) {
		return Sharing.shareAsync(uri);
	}

	try {
		const fileUri = await FileSystem.StorageAccessFramework.createFileAsync(
			permissions.directoryUri,
			fileName,
			mimeType,
		);
		await FileSystem.writeAsStringAsync(fileUri, base64, {
			encoding: FileSystem.EncodingType.Base64,
		});
	} catch (error) {
		console.error("Error saving file:", error);
		return Sharing.shareAsync(uri);
	}
};
