/* -----------------Globals--------------- */
import { relayEnv } from "@memorang/configs";
import { fetchData } from "@memorang/helpers";
import type { LeaderboardRecord } from "@memorang/types";
import { graphql } from "react-relay";
/* -----------------Type declarations--------------- */

type Response = {
	leaderboardUserRecord: LeaderboardRecord;
};

const query = graphql`
  query FetchUserLeaderboardQuery($tenantId: String!, $scope: LeaderboardType) {
    leaderboardUserRecord(tenantId: $tenantId, scope: $scope) {
      xp
      rank
      username
      avatar
      level
    }
  }
`;

const fetchUserLeaderboard = async (
	tenantId: string,
	scope: string,
): Promise<Response> => {
	const variables = {
		tenantId,
		scope,
	};

	const { leaderboardUserRecord } = (await fetchData(
		relayEnv,
		query,
		variables,
	)) as Response;

	return {
		leaderboardUserRecord,
	};
};

export default fetchUserLeaderboard;
