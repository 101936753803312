import type { Article } from "../../../../../types";

export const Article508: Article = {
	id: 508,
	rsuiteId: "6333fbb7-8252-4a10-8cd1-2d349100bb5d",
	type: "evidence-based orthopaedics",
	title:
		"In Patients with Bilateral Knee Osteoarthritis, Cementless and Cemented Total Knee Arthroplasties Did Not Differ for Functional Outcomes at 2 Years",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/508.png",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">In Patients with Bilateral Knee Osteoarthritis,
                Cementless and Cemented Total Knee Arthroplasties Did Not Differ for Functional Outcomes at 2 Years
              </div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jason A.
                      Bryman, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Douglas A.
                      Dennis, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">Tanariyakul Y, Kanitnate S, Tammachote N. Cementless and
                  cemented total knee arthroplasties have similar outcomes but cementless patellar component migration
                  was observed in a paired randomized control trial. J Arthroplasty. 2024 May;39(5):1266-1272. Epub 2023
                  Nov 2.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_6" class="annotation strong"
                    style="position: unset;">Question:</span> In patients with knee osteoarthritis, how do cementless
                  and cemented total knee arthroplasties (TKAs) compare for clinical and radiographic outcomes?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_7" class="annotation strong"
                    style="position: unset;">Design:</span> Randomized (unclear allocation concealment), blinded
                  (patients and data collector), controlled, noninferiority trial with follow-up ≥2 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_8" class="annotation strong"
                    style="position: unset;">Setting:</span> Thammasat University Hospital, Pathum Thani, Thailand.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_9" class="annotation strong"
                    style="position: unset;">Patients:</span> 40 patients aged 50 to 80 years (mean age, 65 years; 88%
                  women) who had osteoarthritis in both knees and American Society of Anesthesiologists classification I
                  or II, and were having sequential, bilateral TKA with patellar resurfacing under a single anesthesia.
                  Exclusion criteria were inflammatory arthritis, osteoporosis, metabolic bone disease, body mass index
                  &gt;40 kg/m<span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">2</span>, or previous knee surgery. 100% of patients had ≥2 years of
                  follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_10" class="annotation strong"
                    style="position: unset;">Intervention:</span> Patients had 1 knee allocated to receive cementless
                  TKA (n = 40) and the other knee to receive cemented components (n = 40) using cruciate-retaining
                  implants (Stryker). The bilateral TKA was done by a single surgeon, with the most severe knee
                  completed first.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_11" class="annotation strong"
                    style="position: unset;">Main outcome measures:</span> The primary outcome was the Forgotten Joint
                  Score (FJS). Secondary outcomes included the modified Western Ontario and McMaster Universities
                  Osteoarthritis Index (WOMAC) score, knee range of motion, pain, radiographic outcomes, and
                  complications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_12" class="annotation strong"
                    style="position: unset;">Main results:</span> Cementless and cemented groups did not differ for FJS,
                  modified WOMAC score, or knee range of motion at any follow-up (2-year outcomes shown in <a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>), or for postoperative pain at rest or during motion up to 12
                  weeks. In the cementless group, 4 patients had superior migration of the patellar component on
                  radiography (mean migration distance, 3.5 mm; mean time to migration, 4 weeks), 1 patient had
                  intraoperative conversion to a cemented component, and 1 patient needed revision surgery for
                  periprosthetic tibial fracture at 2 months. No patients in either group had aseptic loosening,
                  periprosthetic joint infection, wound complications, or deep vein thrombosis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_11" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Cementless versus cemented TKA in patients with
                          bilateral knee osteoarthritis (intention-to-treat analysis)<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 364px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Outcomes</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Mean value at 2 years</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Mean difference (95% CI) at 2 years</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Cementless</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Cemented</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">FJS<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1 (−1.92
                          to 1.00)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Modified WOMAC score<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0 (−0.99
                          to 1.04)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Knee flexion angle <span
                            data-id="emphasis_4" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">134</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">134</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0 (−0.13
                          to 0.73)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">TKA = total knee arthroplasty, CI = confidence interval, FJS =
                        Forgotten Joint Score, WOMAC = Western Ontario and McMaster Universities Osteoarthritis
                        Index.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Higher score = better outcome; minimal important change = 14
                        points.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Lower score = better outcome.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_13" class="annotation strong"
                    style="position: unset;">Conclusion:</span> In patients with bilateral knee osteoarthritis,
                  cementless and cemented TKAs did not differ for functional outcomes at 2 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Source of funding: No information provided.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="emphasis_5" class="annotation emphasis"
                    style="position: unset;">For correspondence: Dr. Nattapol Tammachote, Thammasat University, Khlong
                    Luang, Pathum Thani, Thailand</span>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Cementation in TKA provides immediate fixation and has
                  excellent long-term survivorship, whereas a cementless design allows for biologic fixation and offers
                  the possibility of increased longevity of implant fixation. The randomized controlled trial by
                  Tanariyakul and colleagues adds to evidence showing functional outcomes in TKA are similar regardless
                  of implant fixation type<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">1</span></a>. It
                  evaluated bilateral TKA done in the same surgical setting, which minimizes between-group confounding
                  and allows each patient to serve as their own control. Interestingly, 10% of cementless patellar
                  components showed superior migration without evidence of loosening in the short term. The authors
                  speculated that component migration was limited by superior peg engagement with the superior cortex of
                  the patella.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Expanding on the short-term results of Tanariyakul and
                  colleagues, recent studies showed excellent mid-term survivorship of modern-design cementless,
                  metal-backed patellar components in primary TKA<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">2</span></a>. This
                  contrasts with a previous report documenting high failure rates of earlier generations of metal-backed
                  patellar components<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">3</span></a>.
                  Although design improvements, such as better bonding of polyethylene to the underlying metal backing
                  and improved polyethylene quality, have been incorporated into current metal-backed components, risk
                  of premature failure still exists. Polyethylene is often thinner at the metal-backing periphery, where
                  most contact occurs with the femoral trochlea. Higher bending moments are present at the
                  polyethylene-metal backing junction. These factors increase risk of polyethylene wear-through at the
                  metal-backing periphery. Longer follow-up is needed to see whether failure mechanisms in earlier
                  designs occur over longer periods. One could theorize that early component migration could lead to
                  lack of osseous integration and failure due to aseptic loosening, especially in patients with other
                  established risk factors, including high body mass index and osteopenia.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Weaknesses of the trial by Tanariyakul and colleagues
                  include a small sample size and short-term follow-up. Additionally, most patients were older women.
                  Results may not be applicable to younger, higher-demand men. The mean body mass index of 27 kg/m<span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">2</span>, not
                  atypical for this patient population, may have been protective against early aseptic loosening of the
                  metal-backed patellar component. Finally, due to differences in implant design of metal-backed
                  patellae, results are only applicable to the specific design used in the trial.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Nam D</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Lawrie CM</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Salih R</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Nahhas CR</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Barrack RL</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Nunley RM</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Cemented versus cementless total
                  knee arthroplasty of the same modern design: a prospective, randomized trial</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2019 Jul 3;101(13):1185-92.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=2087377" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Baker JF</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Nadar AC</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Jouflas AC</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Smith LS</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Sachdeva S</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Yakkanti MR</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Malkani AL</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Cementless metal-backed patellar
                  components in primary total knee arthroplasty using an implant of modern design</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2023
                Dec 1;105-B(12):1279-85.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cementless%20metal-backed%20patellar%20components%20in%20primary%20total%20knee%20arthroplasty%20using%20an%20implant%20of%20modern%20design&as_occt=title&as_sauthors=%20%22JF%20Baker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Bayley JC</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Scott RD</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Ewald FC</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Holmes GB Jr</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Failure of the metal-backed
                  patellar component after total knee replacement</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 1988
                Jun;70(5):668-74.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=528620"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D6333fbb7-8252-4a10-8cd1-2d349100bb5d%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Evidence-Based Orthopaedics</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">August 21, 2024;
                      106 (16): 1505</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00567</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">June 13, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;6333fbb7-8252-4a10-8cd1-2d349100bb5d&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=6333fbb7-8252-4a10-8cd1-2d349100bb5d&type=pdf&name=JBJS.24.00567.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=6333fbb7-8252-4a10-8cd1-2d349100bb5d&type=pdf&name=JBJS.24.00567.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_4" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_5"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I57" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I57</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;6333fbb7-8252-4a10-8cd1-2d349100bb5d&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=6333fbb7-8252-4a10-8cd1-2d349100bb5d&type=zip&name=JBJS.24.00567.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jason A. Bryman, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Douglas A. Dennis, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Colorado Joint Replacement, Denver, Colorado</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 6382.4px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
