import { PlayerSdk } from "@api.video/player-sdk";
import { useEffect, useRef } from "react";

export const VideoPlayer = ({
	videoUrl,
	maxWidth = 480,
	height = 200,
}: {
	videoUrl: string;
	maxWidth?: number;
	height?: number;
}) => {
	const playerRef = useRef(null);

	useEffect(() => {
		if (playerRef.current) {
			new PlayerSdk(playerRef.current, {
				id: videoUrl,
				hideTitle: true,
			});
		}
	}, [videoUrl]);

	return (
		<div
			ref={playerRef}
			style={{
				maxWidth,
				height,
			}}
		/>
	);
};
