import type { ContentType, Distribution } from "./content";

type Content = {
	id: string;
	type: ContentType;
	distribution: Distribution;
	totalCount: number;
};

export type DisplayType = ["Filter" | "Progress" | "Display"];
export type Item = {
	id: string;
	type: "flashcard" | "question";
	distribution: keyof Distribution;
};
interface MixedItemTag {
	id: string;
	title: string;
	type: string;
	label?: string;
	displayType?: DisplayType;
	items: Item[];
	distribution: Distribution;
	locked?: boolean;
	childTags?: MixedItemTag[];
	weight?: number;
}

type LegacyReport = {
	id: string;
	numItems: number;
	numericId: number;
	score: number;
	sessionDate: string;
};

export type ExamStatus =
	| "COMPLETE"
	| "ACTIVE"
	| "NOT_STARTED"
	| "LEGACYREPORT"
	| "MIGRATEDREPORT";
export type PracticeTestExam = {
	id: string;
	title: string;
	numItems: number;
	numCompleted: number;
	status?: ExamStatus;
	totalTime: number;
	numericId?: number;
	dateCompleted: string;
	timeElapsed: number;
	distribution: Distribution;
	attempted: Distribution;
	hasAccess?: boolean;
	type: "PRACTICE_TEST" | "QUIZ";
	legacyReport?: LegacyReport;
	migratedSessionReport?: LegacyReport;
	beginStartDate?: string;
	endStartDate?: string;
};
type MixedItemDist = {
	iconUrl: string;
	title: string;
	contents: Content[];
	freeTagIds: string[];
	tags: MixedItemTag[];
	exams?: PracticeTestExam[];
	hasAccess?: boolean;
};

type MixedItemDistResponse = {
	getMixedItemDistribution: MixedItemDist;
};

type TagsByType = Record<string, MixedItemTag[]>;
export type { MixedItemDist, MixedItemDistResponse, MixedItemTag, TagsByType };
