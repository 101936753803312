/* ----------------- External Imports --------------- */
import { isWeb } from "@helpers/platform";

/* ----------------- Internal Imports --------------- */
import { CustomAppbar } from "components/index";

import { newEvents } from "@constants/tracking";
import { useArticles } from "@features/articles/hooks/useArticles";
import { useSession } from "@features/session";
import { getDisplayName } from "@helpers/user";
import useAnalytics from "@hooks/useAnalytics";
import useSubscriptionPurchase from "@hooks/useSubscriptionPurchase";
import { useAuthContext } from "@memorang/applets";
import { titleCase } from "@memorang/helpers";
import { Box, Loader, ScrollContainer, useDevice } from "@memorang/ui";
/* ----------------- Local Imports --------------- */
import { useImageChallengeHome } from "../hooks/useImageChallengeHome";
import ImageChallengeHomeView from "./ImageChallengeHomeView";

const ImageChallengeHomeContainer = () => {
	const {
		featuredChallenge,
		imageChallengeHomeSectionList,
		isLoading,
		flattenItems,
	} = useImageChallengeHome();

	const { newUserInfo } = useAuthContext();

	const { explicitCallTrackCustomEvent } = useAnalytics();

	const { isPremiumUser } = useSubscriptionPurchase();

	const { isMobile } = useDevice();

	const { handleCreateFormativeSession } = useSession();

	// const showChallengeCountdown = useAppStore(
	// 	(state) => state.app.showChallengeCountdown,
	// );
	const { handleNavigateToArticle } = useArticles();
	const handleClickCard = (id: number, type: string, isFeatured?: boolean) => {
		switch (type) {
			case "article": {
				explicitCallTrackCustomEvent({
					eventName: newEvents.articleClicked,
					articleId: id,
					source: "home",
				});
				handleNavigateToArticle(id);
				break;
			}
			case "challenge": {
				const eventName = isFeatured
					? newEvents.featuredChallengeClicked
					: newEvents.challengeClicked;
				explicitCallTrackCustomEvent({
					eventName,
					challengeId: id,
					source: "home",
				});
				const challengeItem = flattenItems?.find(
					(item) => item.id === Number(id),
				);
				handleCreateFormativeSession({
					challengeItem,
					isFeatured,
				});
				break;
			}
			default:
				break;
		}
	};

	const getGreeting = () => {
		const currentHour = new Date().getHours();
		if (currentHour < 12) {
			return "☀️ Good morning";
		}
		if (currentHour < 18) {
			return "🌇️ Good afternoon";
		}
		return "🌙 Good evening";
	};

	const greeting = getGreeting();

	const displayName = getDisplayName(
		newUserInfo?.profession,
		newUserInfo?.firstName,
		newUserInfo?.lastName,
	);
	const appbarTitle = displayName
		? `${greeting}, ${titleCase(displayName)}`
		: `${greeting}!`;

	return (
		<>
			<CustomAppbar
				mode="medium"
				align="flex-start"
				showCommandBar={isWeb}
				showRightAction={false}
				showHambugerMenu
				options={{
					headerTitle: appbarTitle,
					subtitle: isMobile
						? undefined
						: "Welcome to your personalized dashboard",
				}}
			/>
			<ScrollContainer maxWidth="sm">
				{isLoading ? (
					<Box
						alignItems="center"
						justifyContent="center"
						flexGrow={1}
						height="100%"
					>
						<Loader />
					</Box>
				) : (
					<Box paddingTop={8} paddingBottom={8}>
						<ImageChallengeHomeView
							recommendedChallenge={featuredChallenge}
							isPremiumUser={isPremiumUser}
							imageChallengeHomeSectionList={imageChallengeHomeSectionList}
							handleClickCard={handleClickCard}
							// headerComponent={
							// 	showChallengeCountdown &&
							// 	nextChallenge &&
							// 	nextChallenge.publishedAt ? (
							// 		<ChallengeCountdownContainer
							// 			imageUrl={nextChallenge.imageUri!}
							// 			challengeNumber={nextChallenge.challengeNumber}
							// 			numResponses={nextChallenge.numResponses}
							// 			title={nextChallenge.title}
							// 			publishedAt={nextChallenge.publishedAt}
							// 		/>
							// 	) : null
							// }
						/>
					</Box>
				)}
			</ScrollContainer>
		</>
	);
};

export default ImageChallengeHomeContainer;
