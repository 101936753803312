import type { Article } from "../../../../../types";

export const Article639: Article = {
	id: 639,
	rsuiteId: "566081f9-f9f8-4907-aa0c-459cf2e605da",
	type: "commentary and perspective",
	title:
		"Facet Joint Block: A Temporary Fix for an Iatrogenic Problem After Oblique Lumbar Interbody Fusion?",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/639.jpeg",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Facet Joint Block: A Temporary Fix for an Iatrogenic
                Problem After Oblique Lumbar Interbody Fusion?</div>
              <div class="text subtitle" style="position: unset;">Commentary on an article by Sung Hyeon Noh, MD, PhD,
                et al.: “Intraoperative Facet Joint Block Reduces Pain After Oblique Lumbar Interbody Fusion. A
                Double-Blinded, Randomized, Placebo-Controlled Clinical Trial”</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Mary L.
                      Forte, PhD, DC</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">The study by Noh et al. is a small (n = 61) randomized
                  clinical trial (RCT) comparing the effectiveness of an intraoperative facet joint block versus a
                  saline placebo block in reducing postoperative pain due to iatrogenic facet joint widening (FJW)
                  following inpatient, minimally invasive oblique lumbar interbody fusion (OLIF) for low-grade
                  degenerative spondylolisthesis in older adults. On average, the study participants were in their
                  mid-60s and had normal body mass index; 80% of the participants had a grade-1 slip, and 20% had
                  osteoporosis. Both groups utilized a fentanyl patient-controlled analgesia (PCA) system for 48 hours
                  postoperatively. Mean scores for back pain on a 0-to-10 visual analog scale (VAS) showed a 1 to
                  2-point benefit in the block group relative to the placebo group in the early postoperative period,
                  with the 24 and 48-hour scores meeting the minimum clinically important difference<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. Using an online conversion chart<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>, the total fentanyl consumption over 48 hours reported in
                  Table II differed by 80.97 oral morphine milligram equivalents (OME) between the groups (mean [and
                  standard deviation], 249.96 ± 45.6 OME in the active block group vs. 330.93 ± 59.55 OME in the placebo
                  group). The mean hospital length of stay (LOS) was over a week in both groups but was 1.3 days shorter
                  in the active block group compared with the placebo group. Twenty-eight percent of patients had cage
                  subsidence at 6 months postoperatively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">As a health services researcher, it is concerning to me
                  that a surgery to fix a chronic spinal problem requires another fix for a complication that was likely
                  caused by that choice of surgery or surgical technique. In my decade of reviewing for <span
                    data-id="emphasis_8" class="annotation emphasis" style="position: unset;">JBJS,</span> I have never
                  seen such a study. Even more perplexing is that the literature is virtually silent on facet joint
                  status following OLIF, with no information on painful FJW as an adverse effect that is sufficiently
                  problematic and common enough for these authors to undertake an RCT to investigate an intervention for
                  reducing FJW-related postoperative pain.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">The bulk of the literature on surgical pain focuses on
                  blocks (e.g., peripheral nerve and neuraxial blocks) to reduce early postoperative pain and to prevent
                  chronic pain and prolonged opioid use after surgery for a specific problem. In contrast, facet joint
                  blocks in this study provide short-term relief for a new, iatrogenic FJW-related problem that may be
                  either temporary or chronic.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">The introductory section of the study provides no
                  literature basis for this trial. The authors fail to explain why FJW is a problem that is apparently
                  experienced by many OLIF recipients. In that section, the authors provided 1 reference to a narrative
                  review on OLIF that merely shows a single magnetic resonance image marked as having a widened facet
                  joint space<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>.
                  Therefore, it is unclear whether FJW is inevitable with OLIF; whether it is a complication that is
                  related to surgical technique, including decisions regarding cage height; or whether it is desired to
                  some modest degree. There is no information or literature cited regarding the prevalence of painful
                  FJW after OLIF, the magnitude of FJW with OLIF (e.g., as shown on a CT [computed tomography] scan),
                  the facet joint distance that seems to be associated with painful FJW, or the duration of pain (acute
                  and/or chronic) from FJW postoperatively. Additionally, the authors provided no literature information
                  on patient, spine, or technique-related factors that are associated with painful FJW after OLIF.
                  Instead, the authors present FJW as a factual problem that needs to be addressed separately from the
                  problem that required surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">A quick scan of the literature found 9 systematic reviews
                  from 2019 to 2024 on OLIF for degenerative lumbar conditions, including spondylolisthesis. The studies
                  included in the most recent systematic reviews were almost exclusively observational, retrospective,
                  and conducted in Asian countries, and did not mention FJW as a complication or problem<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">7</span></a>. Most
                  reviews did not include the word “facet” except in descriptions of techniques.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">The generalizability of the study results by Noh et al. is
                  limited, likely to Asian countries with extended LOS for OLIF<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">8</span></a>.
                  Moreover, extended use of a PCA system is likely inconsistent with practices in other countries,
                  especially those moving to ambulatory OLIF.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">This RCT is novel in that it identified a
                  procedure-related problem following OLIF that the spine surgery community may not have been aware of.
                  It does not prove the source(s) of postoperative pain. The magnitude and duration of the negative
                  impact of FJW on patients after undergoing OLIF bears further high-quality investigations. Clear
                  definitions of FJW, informed by imaging, and a transparent discussion of the risk factors and
                  longer-term outcomes are essential for making evidence-based surgical decisions that can improve
                  patient care and outcomes following lumbar spinal fusion for degenerative conditions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Copay AG</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Glassman SD</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Subach BR</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Berven S</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Schuler TC</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Carreon LY</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Minimum clinically important
                  difference in lumbar spine surgery patients: a choice of methods using the Oswestry Disability Index,
                  Medical Outcomes Study questionnaire Short Form 36, and pain scales</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Spine J.</span> 2008
                Nov-Dec;8(6):968-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Minimum%20clinically%20important%20difference%20in%20lumbar%20spine%20surgery%20patients%3A%20a%20choice%20of%20methods%20using%20the%20Oswestry%20Disability%20Index%2C%20Medical%20Outcomes%20Study%20questionnaire%20Short%20Form%2036%2C%20and%20pain%20scales&as_occt=title&as_sauthors=%20%22AG%20Copay%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;">University
                of California San Francisco. <span data-id="strong_2" class="annotation strong"
                  style="position: unset;">Calculation of Oral Morphine Equivalents (OME)</span>. Accessed 2024 Jul 20.
                <a href="https://pain.ucsf.edu/opioid-analgesics/calculation-oral-morphine-equivalents-ome"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://pain.ucsf.edu/opioid-analgesics/calculation-oral-morphine-equivalents-ome</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Calculation%20of%20Oral%20Morphine%20Equivalents%20(OME)&as_occt=title&as_sauthors=%20%22University%20of%20California%20San%20Francisco%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Kim H</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Chang BS</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Chang SY</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Pearls and Pitfalls of Oblique
                  Lateral Interbody Fusion: A Comprehensive Narrative Review</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Neurospine.</span> 2022
                Mar;19(1):163-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pearls%20and%20Pitfalls%20of%20Oblique%20Lateral%20Interbody%20Fusion%3A%20A%20Comprehensive%20Narrative%20Review&as_occt=title&as_sauthors=%20%22H%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> An B</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Ren B</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Han Z</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Mao K</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Liu J</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Comparison between oblique
                  lumbar interbody fusion and posterior lumbar interbody fusion for the treatment of lumbar degenerative
                  diseases: a systematic review and meta-analysis</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Orthop Surg Res.</span> 2023 Nov
                10;18(1):856.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20between%20oblique%20lumbar%20interbody%20fusion%20and%20posterior%20lumbar%20interbody%20fusion%20for%20the%20treatment%20of%20lumbar%20degenerative%20diseases%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22B%20An%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Li XY</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Wang YL</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Yang S</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Liao CS</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Li SF</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Han PY</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Han PF</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Efficacy of oblique lumbar
                  interbody fusion versus transforaminal lumbar interbody fusion in the treatment of lumbar degenerative
                  diseases: a systematic review and meta-analysis</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Arch Orthop Trauma Surg.</span> 2023
                Sep;143(9):5657-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20of%20oblique%20lumbar%20interbody%20fusion%20versus%20transforaminal%20lumbar%20interbody%20fusion%20in%20the%20treatment%20of%20lumbar%20degenerative%20diseases%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22XY%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Xiao X</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Duan H</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Pan X</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Zhao H</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Fusion rate and complications of
                  oblique lumbar interbody fusion and transforaminal lumbar interbody fusion in the treatment of lumbar
                  degenerative diseases: a meta-analysis</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Front Surg.</span> 2024 Apr 30;11:1374134.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fusion%20rate%20and%20complications%20of%20oblique%20lumbar%20interbody%20fusion%20and%20transforaminal%20lumbar%20interbody%20fusion%20in%20the%20treatment%20of%20lumbar%20degenerative%20diseases%3A%20a%20meta-analysis&as_occt=title&as_sauthors=%20%22X%20Xiao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Ma H</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Zhang F</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Ying Q</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Pan B</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Li Y</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Ge H</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Cao Y</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Jiang T</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Dai M</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Ji C</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Long-Term Clinical and Imaging
                  Results of Oblique Lateral Interbody Fusion for Degenerative Lumbar Spondylolisthesis: A Systematic
                  Review and Meta-Analysis</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Orthop Surg.</span> 2023 Feb;15(2):400-1-2.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-Term%20Clinical%20and%20Imaging%20Results%20of%20Oblique%20Lateral%20Interbody%20Fusion%20for%20Degenerative%20Lumbar%20Spondylolisthesis%3A%20A%20Systematic%20Review%20and%20Meta-Analysis&as_occt=title&as_sauthors=%20%22H%20Ma%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Miscusi M</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Trungu S</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Ricciardi L</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Forcato S</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Piazza A</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Ramieri A</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Raco A</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Stand-Alone Oblique Lumbar
                  Interbody Fusion (OLIF) for the Treatment of Adjacent Segment Disease (ASD) after Previous Posterior
                  Lumbar Fusion: Clinical and Radiological Outcomes and Comparison with Posterior Revision
                  Surgery</span>. <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">J Clin
                  Med.</span> 2023 Apr 20;12(8):2985.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Stand-Alone%20Oblique%20Lumbar%20Interbody%20Fusion%20(OLIF)%20for%20the%20Treatment%20of%20Adjacent%20Segment%20Disease%20(ASD)%20after%20Previous%20Posterior%20Lumbar%20Fusion%3A%20Clinical%20and%20Radiological%20Outcomes%20and%20Comparison%20with%20Posterior%20Revision%20Surgery&as_occt=title&as_sauthors=%20%22M%20Miscusi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D566081f9-f9f8-4907-aa0c-459cf2e605da%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Commentary and Perspective</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 1, 2025;
                      107 (1): e2</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00887</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">January 1, 2025</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=b3029aef-3521-49b3-9723-efc1ec840798&native=1"
                              class="article-title" style="position: unset;">Intraoperative Facet Joint Block Reduces
                              Pain After Oblique Lumbar Interbody Fusion<span class="subtitle"
                                style="position: unset;"><br style="position: unset;">A Double-Blinded, Randomized,
                                Placebo-Controlled Clinical Trial</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Noh, Sung
                              Hyeon; Lee, Sang-Woo; Hwang, Jong-moon; Jung, JinWoo; Lee, Eunyoung; Cho, Dae-Chul; Kim,
                              Chi Heon; Kim, Kyoung-Tae</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 107(1):16 | November 20, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2025 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;566081f9-f9f8-4907-aa0c-459cf2e605da&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=566081f9-f9f8-4907-aa0c-459cf2e605da&type=pdf&name=JBJS.24.00887.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=566081f9-f9f8-4907-aa0c-459cf2e605da&type=pdf&name=JBJS.24.00887.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_9" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_10"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I279" target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I279</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;566081f9-f9f8-4907-aa0c-459cf2e605da&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=566081f9-f9f8-4907-aa0c-459cf2e605da&type=zip&name=JBJS.24.00887.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mary L. Forte, PhD, DC<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:fort0023@umn.edu" class="" style="position: unset;">fort0023@umn.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4566-2529" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4566-2529</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Minnesota Evidence-Based Practice Center, Division of Health Policy and
                Management, School of Public Health, University of Minnesota, Minneapolis, Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 6853.37px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
