/* -----------------Globals--------------- */
import type { AccessCodeResult } from "@features/auth";
import { type ExamDetails, fetchExamDetails } from "@features/exam";
import { useRouter } from "expo-router";

/* -----------------UI--------------- */
import { Box, Loader, ScrollContainer } from "@memorang/ui";

import { FreeQuizSessionDialog } from "@features/auth/";
/* -----------------Helpers & Hooks--------------- */
import { useAppStore } from "@hooks/useAppStore";
import { useDevice } from "@hooks/useDevice";
import { AccessGrantedView } from "components/AccessGrantedView";
import { useEffect, useState } from "react";

type Props = {
	accessCodeResult: AccessCodeResult;
};
const RedeemCodeSuccess = (props: Props) => {
	const { isMobile } = useDevice();
	const tenantId = useAppStore((store) => store.tenant.id);

	const router = useRouter();

	const [examDetails, setExamDetails] = useState<ExamDetails | null>(null);
	const { accessCodeResult } = props;

	const [showFreeQuizDialog, setShowFreeQuizDialog] = useState(false);

	const handleNavigateToProduct = () => {
		if (examDetails) {
			const hasPracticeTests = accessCodeResult.productData?.find(
				(item) => item.bundleType === "PRACTICE_TEST",
			);
			const url =
				!hasPracticeTests || isMobile
					? "/(protected)/study-pack"
					: "/(protected)/(tabs)/practice-tests";
			router.push(url);
		}
	};
	const handleCloseFreeQuizDialog = (navigate?: boolean) => {
		setShowFreeQuizDialog(false);
		if (navigate) {
			handleNavigateToProduct();
		}
	};

	const examId = accessCodeResult.data.examId;

	useEffect(() => {
		if (examId) {
			fetchExamDetails({
				examId,
				tenantId,
			}).then((response) => setExamDetails(response));
		}
	}, [examId, tenantId]);

	if (!accessCodeResult) {
		return (
			<Box height="100%">
				<Loader />
			</Box>
		);
	}

	const title = "Access Granted";

	const products =
		accessCodeResult.productData && accessCodeResult.productData.length > 1
			? accessCodeResult.productData.map((product) => {
					return {
						icon: product.icon,
						title: product.bundleName,
						id: product.bundleId,
						description:
							product.bundleType === "PRACTICE_TEST"
								? "Practice Test"
								: "Study-Pack",
						features: product.features,
						isCustomAccess: product.isCustomAccess,
						examId: product.examId,
						bundleType: product.bundleType,
						beginStartDate: product.beginStartDate,
						endStartDate: product.endStartDate,
					};
				})
			: [
					{
						icon: accessCodeResult.data.icon,
						title: accessCodeResult.data.bundleName,
						id: accessCodeResult.data.bundleId,
						description:
							accessCodeResult.data.bundleType === "STUDY_PACK"
								? "Study-Pack"
								: "Practice Test",
						features: accessCodeResult.data.features,
						isCustomAccess: accessCodeResult.data.isCustomAccess,
						examId: accessCodeResult.data.examId,
						bundleType: accessCodeResult.data.bundleType,
						beginStartDate: accessCodeResult.data.beginStartDate,
						endStartDate: accessCodeResult.data.endStartDate,
					},
				];

	return (
		<>
			<ScrollContainer maxWidth="xs">
				<Box paddingVertical={16} gap={16}>
					<AccessGrantedView
						products={products}
						examName={accessCodeResult.data.examName}
						title={title}
						externalPurchase
					/>
				</Box>
			</ScrollContainer>
			{showFreeQuizDialog && (
				<FreeQuizSessionDialog
					visible={showFreeQuizDialog}
					onClose={handleCloseFreeQuizDialog}
				/>
			)}
		</>
	);
};

export default RedeemCodeSuccess;
