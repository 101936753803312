import type { Article } from "../../../../../types";

export const Article598: Article = {
	id: 598,
	rsuiteId: "c75feed3-ebc5-4157-835e-9d0cda7b800e",
	type: "current concepts review",
	title: "Shoulder Periprosthetic Joint Infection",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=c75feed3-ebc5-4157-835e-9d0cda7b800e&type=jpeg&name=JBJS.23.01073f1",
	subSpecialties: ["shoulder_elbow"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Shoulder Periprosthetic Joint Infection</div>
              <div class="text subtitle" style="position: unset;">Principles of Prevention, Diagnosis, and Treatment
              </div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ehab M.
                      Nazzal, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Zachary J.
                      Herman, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 6 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node list" data-id="list_1" style="position: unset;">
                  <ul class="simple content" style="position: unset;">
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_1" style="position: unset;">
                            <div class="content" style="position: unset;">Shoulder periprosthetic joint infection (PJI)
                              is a potentially devastating complication after arthroplasty and is projected to rise with
                              increasing numbers of performed arthroplasties, particularly reverse shoulder
                              arthroplasties.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_2" style="position: unset;">
                            <div class="content" style="position: unset;">Important considerations for the diagnosis and
                              treatment of shoulder PJI include age, sex, implant type, primary compared with revision
                              shoulder surgery, comorbidities, and medications (i.e., corticosteroids and
                              disease-modifying antirheumatic drugs).</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_3" style="position: unset;">
                            <div class="content" style="position: unset;">Diagnosis and management are unique compared
                              with lower-extremity PJI due to the role of lower-virulence organisms in shoulder PJI,
                              specifically <span data-id="emphasis_113" class="annotation emphasis"
                                style="position: unset;">Cutibacterium acnes.</span></div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_4" style="position: unset;">
                            <div class="content" style="position: unset;">Treatment pathways depend on chronicity of
                              infection, culture data, and implant type, and exist on a spectrum from irrigation and
                              debridement to multistage revision with temporary antibiotic spacer placement followed by
                              definitive revision arthroplasty.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Periprosthetic joint infection (PJI) is a devastating
                  complication after shoulder arthroplasty. In a 2023 article, an annual increase of shoulder PJIs in
                  the United States by 0.8% to 1.4% was reported between 2011 and 2018, with a projected 176% increase
                  from 2023 to 2030<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  Furthermore, shoulder PJI poses a substantial economic burden, with annual charges increasing &gt;300%
                  from 2011 to 2018 and charges expected to reach $500 million annually by 2030<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">The lack of diagnostic reliability makes identifying
                  shoulder PJI more challenging than PJI after total hip arthroplasty (THA) and total knee arthroplasty
                  (TKA)<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>. The
                  purpose of this review was to highlight the current diagnostic criteria, review shoulder PJI
                  classification systems, and provide an overview of treatment strategies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Risk
                Factors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Patient-Specific Factors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Sex</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Male sex may confer an increased risk of shoulder PJI<a
                    href="" data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">6</span></a>. A
                  systematic review by Kunutsor et al. demonstrated a pooled risk of PJI that was nearly twofold higher
                  in male compared with female patients<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">6</span></a>.
                  Padegimas et al. analyzed &gt;82,000 primary shoulder arthroplasties and found 2 times higher odds of
                  shoulder PJI among male patients<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">2</span></a>.
                  Moeini et al. analyzed &gt;17,000 primary shoulder arthroplasties and found that the revision rate for
                  PJI after reverse shoulder arthroplasty (RSA) was &gt;3 times higher in male patients (11.9%) compared
                  with female patients (3.1%)<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">7</span></a>. The
                  reasons for this difference in sex may be due in part to the significantly increased positive culture
                  rate of <span data-id="emphasis_115" class="annotation emphasis"
                    style="position: unset;">Cutibacterium acnes</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">8</span></a> in
                  male patients and the positive correlation of higher testosterone levels with <span
                    data-id="emphasis_116" class="annotation emphasis" style="position: unset;">C. acnes</span> skin
                  load<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">9</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Age</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">There is a lack of consensus with regard to the role of
                  age in the risk of shoulder PJI. Singh et al. evaluated 2,200 patients and found that older age was
                  associated with a lower risk of shoulder PJI<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">10</span></a>.
                  Various studies have used an age of &lt;65 years as a risk factor for shoulder PJI, especially after
                  RSA<a href="" data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_11" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_11" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">12</span></a>. However, 2023 data from the Australian registry showed
                  that revision for shoulder PJI occurred at mean age of 68.2 years, refuting those previous findings<a
                    href="" data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">13</span></a>. The true contribution of younger age remains unknown and
                  further research is warranted to determine what role, if any, younger age has in shoulder PJI.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Disease-Modifying Antirheumatic Drugs (DMARDs) and Miscellaneous
                Medications</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The overall increased risk of PJI in patients with
                  autoimmune diseases is likely due to both the patient’s inherent immune dysregulation and a byproduct
                  of the immunosuppressive effect of their treatment. Although there are no specific guidelines for
                  DMARDs and antirheumatic drug use prior to shoulder arthroplasty, the hip and knee arthroplasty
                  literature is robust, with a 2022 guideline update illustrated in <a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a><a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">14</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_13" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Dosing Recommendations for Various Anti-Rheumatic
                          Medications<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span><span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 3676px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Dosing
                          Interval</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Recommended Timing of Surgery Since Last Medication Dose</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medications to continue
                          through surgery</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> DMARDs: continue these
                          medications through surgery (all patients)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Methotrexate</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Weekly</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Anytime</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Sulfasalazine</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Once or twice
                          daily</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Anytime</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text"
                          style="position: unset;">  Hydroxychloroquine</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Once or twice
                          daily</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Anytime</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Leflunomide
                          (Arava)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Daily</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Anytime</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Doxycycline</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Daily</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Anytime</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Apremilast
                          (Otezla)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Twice daily</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Anytime</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Severe SLE-specific
                          medications: continue these medications in the perioperative period in consultation with the
                          treating rheumatologist</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Mycophenolate
                          mofetil</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Twice daily</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Anytime</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Azathioprine</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Daily or twice
                          daily</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Anytime</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Cyclosporine</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Twice daily</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Anytime</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Tacrolimus</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Twice daily (IV and
                          PO)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Anytime</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Rituximab
                          (Rituxan)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IV every 4 to 6
                          months</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Month 4 to 6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Belimumab SC
                          (Benlysta))</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Weekly</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Anytime</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Belimumab IV
                          (Benlysta)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Monthly</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Week 4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Anifrolumab
                          (Saphnelo)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">IV every 4 weeks</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Week 4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Voclosporin
                          (Lupkynis)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Twice daily</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Continue</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medications to withhold
                          prior to surgery</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Biologics: withhold
                          through surgery</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Infliximab
                          (Remicade)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Every 4, 6, or 8
                          weeks</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Week 5, 7, or 9</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Adalimumab
                          (Humira)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Every 2 weeks</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Week 3</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Etanercept
                          (Enbrel)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Every week</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Week 2</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Golimumab
                          (Simponi)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Every 4 weeks SC or every
                          8 weeks IV</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Week 5 (SC); Week 9
                          (IV)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Abatacept
                          (Orencia)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Monthly IV or weekly
                          SC</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Week 5 (IV); Week 2
                          (SC)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Certolizumab
                          (Cimzia)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Every 2 or 4 weeks</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Week 3 or 5</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Rituximab
                          (Rituxan)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2 doses 2 weeks apart
                          every 4 to 6 months</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Month 7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Tocilizumab
                          (Actemra)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Every week (SC) or every
                          4 weeks (IV)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Week 2 (SC); Week 5
                          (IV)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Anakinra
                          (Kineret)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Daily</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Day 2</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  IL-17 secukinumab
                          (Cosentyx)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Every 4 weeks</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Week 5</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Ustekinumab
                          (Stelara)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Every 12 weeks</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Week 13</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Ixekizumab
                          (Taltz)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Every 4 weeks</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Week 5</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  IL-23 guselkumab
                          (Tremfya)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Every 8 weeks</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Week 9</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> JAK inhibitors: withhold
                          this medication 3 days prior to surgery</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Tofacitinib
                          (Xeljanz)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Daily or twice
                          daily</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Day 4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Baricitinib
                          (Olumiant)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Daily</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Day 4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Upadacitinib
                          (Rinvoq)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Daily</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Day 4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Not severe SLE: withhold
                          these medications 1 week prior to surgery</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Mycophenolate
                          mofetil</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Twice daily</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 week after last
                          dose</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Azathioprine</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Daily or twice
                          daily</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 week after last
                          dose</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Cyclosporine</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Twice daily</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 week after last
                          dose</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Tacrolimus</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Twice daily (IV and
                          PO)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 week after last
                          dose</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Rituximab
                          (Rituxan)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Every 4 to 6
                          months</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Month 7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Belimumab IV
                          (Benlysta)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Monthly</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Week 5</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Belimumab SC
                          (Benlysta)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Weekly</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Week 2</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Reproduced, with modification, from: Goodman SM, Springer BD, Chen AF,
                        Davis M, Fernandez DR, Figgie M, Finlayson H, George MD, Giles JT, Gilliland J, Klatt B,
                        MacKenzie R, Michaud K, Miller A, Russell L, Sah A, Abdel MP, Johnson B, Mandl LA, Sculco P,
                        Turgunbaev M, Turner AS, Yates A Jr, Singh JA. 2022 American College of Rheumatology/American
                        Association of Hip and Knee Surgeons Guideline for the Perioperative Management of Antirheumatic
                        Medication in Patients with Rheumatic Diseases Undergoing Elective Total Hip or Total Knee
                        Arthroplasty. Arthritis Care Res (Hoboken). 2022 Sep;74(9):1399-408. With permission from
                        American College of Rheumatology, © 2022. An earlier version of this table appeared in: Goodman
                        SM, Springer B, Guyatt G, Abdel MP, Dasa V, George M, Gewurz-Singer O, Giles JT, Johnson B, Lee
                        S, Mandl LA, Mont MA, Sculco P, Sporer S, Stryker L, Turgunbaev M, Brause B, Chen AF, Gilliland
                        J, Goodman M, Hurley-Rosenblatt A, Kirou K, Losina E, MacKenzie R, Michaud K, Mikuls T, Russell
                        L, Sah A, Singh JA, Yates A. 2017 American College of Rheumatology/American Association of Hip
                        and Knee Surgeons Guideline for the Perioperative Management of Antirheumatic Medication in
                        Patients with Rheumatic Diseases Undergoing Elective Total Hip or Total Knee Arthroplasty. J
                        Arthroplasty. 2017 Sep;32(9):2628-38<a href="" data-id="citation_reference_19"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_14" class="annotation superscript"
                            style="position: unset;">108</span></a><span data-id="superscript_14"
                          class="annotation superscript" style="position: unset;"></span>.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">SLE = systemic lupus erythematosus, IV = intravenous, SC =
                        subcutaneous, PO = by mouth, and JAK = Janus kinase.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Corticosteroids</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Multiple studies have suggested an association of
                  preoperative intra-articular corticosteroid injections with an increased risk of PJI, including in the
                  shoulder<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">20</span></a>. Baksh et al. found that intra-articular corticosteroid
                  injection &lt;4 weeks prior to the RSA was associated with increased PJI risk at 90 days and 1 year
                  postoperatively<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">21</span></a>.
                  The effect of oral corticosteroid use is ill defined; however, Piple et al. evaluated the role of oral
                  prednisone on PJI and found that patients receiving prednisone following TKA had 3.2 times higher odds
                  of PJI<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">22</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Surgery-Specific Factors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Implant
                Type</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Generally, RSA is considered to have a higher infection
                  rate compared with anatomic total shoulder arthroplasty (TSA)<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_18" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">24</span></a>. Moeini et al. found that the 10-year cumulative revision
                  rate for infection after RSA (3.1%) was more than double that after TSA (1.4%)<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">7</span></a>. The possible reasons for this finding include older age,
                  more comorbidities, and higher rates of previous surgical procedures within RSA cohorts<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">25</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Primary
                Compared with Revision Shoulder Surgery</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Revision shoulder arthroplasty confers a higher risk of
                  shoulder PJI<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">28</span></a>. However, even prior non-arthroplasty revision surgery is a
                  risk factor for shoulder PJI<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">29</span></a>. Jensen et al. evaluated the Danish Shoulder Arthroplasty
                  Registry and found that a history of a rotator cuff repair (open or arthroscopic) increased the risk
                  of PJI<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">29</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Prevention</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Comorbidities</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Diabetes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">In a database study of nearly 19,000 patients, Cancienne
                  et al. found that patients undergoing shoulder arthroplasty with hemoglobin A1c levels of &gt;8.0
                  mg/dL had higher risks of wound complications and deep postoperative infections<a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">30</span></a>. More recently, Duey et al. evaluated &gt;113,000 patients
                  who underwent shoulder arthroplasty and found that diabetes was associated with an increased risk of
                  both 30-day and 90-day surgical-site and deep joint infections<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">31</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Tobacco</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Tobacco use, just as in lower-extremity PJI, is an
                  important risk factor for the development of shoulder PJI. Hatta et al. evaluated patients who
                  underwent RSA or TSA at a single institution and found that tobacco users had &gt;7 times higher risk
                  of PJI compared with nonsmokers<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">32</span></a>.
                  Even when tobacco cessation occurred at least 1 month preoperatively, there was still a 4.5-fold
                  higher risk of PJI compared with nonsmokers<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">32</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Perioperative Considerations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Skin
                Preparation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Perioperative considerations regarding shoulder PJI risk
                  must take the unique skin microbiome of the shoulder into account. Recent literature has implicated
                  <span data-id="emphasis_117" class="annotation emphasis" style="position: unset;">C. acnes</span> as
                  the primary pathogen in approximately 64% to 86% of cases of shoulder PJI and approximately 24% of
                  cases due to coagulase-negative staphylococci<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">34</span></a>. <span data-id="emphasis_118" class="annotation emphasis"
                    style="position: unset;">C. acnes</span> is a facultative anaerobe that thrives in areas with a high
                  density of sebaceous glands, such as the face, chest, and back, and on the anterior and lateral
                  surfaces of the shoulder<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">35</span></a>. In
                  the axilla, which is a wet environment, <span data-id="emphasis_119" class="annotation emphasis"
                    style="position: unset;">C. acnes</span> exists on the surface but not in the dermal sebaceous
                  glands as would be seen over the surface of the shoulder<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">35</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Mixed results exist with regard to the efficacy of topical
                  antiseptic solutions for decreasing <span data-id="emphasis_120" class="annotation emphasis"
                    style="position: unset;">C. acnes</span> loads in shoulder arthroplasty. Hsu et al. showed that,
                  following application of topical chlorhexidine, <span data-id="emphasis_121"
                    class="annotation emphasis" style="position: unset;">C. acnes</span> repopulated within 1 hour,
                  suggesting that penetration of sebaceous glands by topically applied chlorhexidine is unlikely to
                  adequately reduce the bacterial load<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">36</span></a>.
                  Meanwhile, Symonds et al. showed that topical benzoyl peroxide and benzoyl peroxide with clindamycin
                  reduce the bacterial load of <span data-id="emphasis_122" class="annotation emphasis"
                    style="position: unset;">C. acnes</span> on skin<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">37</span></a>.
                  Additionally, topical benzoyl peroxide has shown effectiveness in reducing <span
                    data-id="emphasis_123" class="annotation emphasis" style="position: unset;">C. acnes</span> positive
                  cultures in the shoulder when applied for 3 days prior to the surgical procedure<a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">38</span></a><span data-id="superscript_33"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_46" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">39</span></a>. Hydrogen peroxide has shown mixed results. Grewal et al.
                  reported that dermal application of hydrogen peroxide following the initial surgical incision did not
                  significantly alter the rate of positive <span data-id="emphasis_124" class="annotation emphasis"
                    style="position: unset;">C. acnes</span> cultures during shoulder arthroplasty<a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">40</span></a>. However, Chalmers et al. concluded that adding hydrogen
                  peroxide to a standard skin preparation may represent a low-cost method of reducing shoulder PJI
                  rates<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">41</span></a>.
                  The current 2018 International Consensus Meeting (ICM) recommendations<a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">42</span></a> suggested 2% chlorhexidine showers or cleansing wipes and
                  surgical preparation solutions composed of chlorhexidine and 70% isopropyl alcohol just prior to
                  incision<a href="" data-id="citation_reference_50"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_37" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">43</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Perioperative Antibiotics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Proper perioperative antibiotic selection is important for
                  targeting <span data-id="emphasis_125" class="annotation emphasis" style="position: unset;">C.
                    acnes,</span> yet consensus has been lacking. Marigi et al. performed an analysis of primary
                  shoulder arthroplasties over a 20-year period and found that cefazolin administration was associated
                  with a lower rate of PJI compared with non-cefazolin alternatives, including vancomycin and
                  clindamycin<a href="" data-id="citation_reference_52"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript" style="position: unset;">44</span></a>.
                  However, in a separate retrospective study, Koh et al. found that perioperative cefazolin
                  administration was ineffective in eliminating <span data-id="emphasis_126" class="annotation emphasis"
                    style="position: unset;">C. acnes</span> colonization<a href="" data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript" style="position: unset;">45</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Combined prophylaxis with cefazolin and clindamycin is
                  increasingly popular, as clindamycin may better target patients with a known history of
                  methicillin-resistant <span data-id="emphasis_127" class="annotation emphasis"
                    style="position: unset;">Staphylococcus aureus</span> (MRSA); however, this combination carries a
                  risk of postoperative renal damage and Clostridium infection, limiting its clinical utility<a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">46</span></a><span data-id="superscript_40"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">47</span></a>. The only contraindication for cefazolin prophylaxis is a
                  documented patient history of anaphylactic reaction to cephalosporins. In these cases, vancomycin is
                  an appropriate alternative, demonstrating a lower risk of shoulder PJI with monotherapy compared with
                  other agents such as clindamycin with an added benefit of limiting impact to the shoulder capsule<a
                    href="" data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">44</span></a><span data-id="superscript_41"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_57" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">48</span></a><span data-id="superscript_41"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_58" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">49</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Consideration of antibiotic resistance is important. One
                  2016 study found that <span data-id="emphasis_128" class="annotation emphasis"
                    style="position: unset;">C. acnes</span> antibiotic resistance was low for 5 different antibiotics
                  used, with clindamycin being the highest but only 8.5% of all strains being resistant<a href=""
                    data-id="citation_reference_59" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">50</span></a>. Boyle et al. found that hemolytic strains of <span
                    data-id="emphasis_129" class="annotation emphasis" style="position: unset;">C</span>. <span
                    data-id="emphasis_130" class="annotation emphasis" style="position: unset;">acnes</span> display
                  higher pathogenicity, with 31% of these strains displaying clindamycin resistance<a href=""
                    data-id="citation_reference_60" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_43" class="annotation superscript"
                      style="position: unset;">51</span></a>. These results suggest that, although the risk of
                  resistance is important to consider, it is likely not the driving factor in the difficulty of managing
                  shoulder PJI.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Tranexamic
                Acid</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Tranexamic acid (TXA) has revolutionized total joint
                  arthroplasty by decreasing postoperative blood loss and the risk of PJI<a href=""
                    data-id="citation_reference_61" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">52</span></a>. In their randomized controlled trial, Cunningham et al.<a
                    href="" data-id="citation_reference_62" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_45" class="annotation superscript"
                      style="position: unset;">53</span></a> showed that a single dose of TXA reduced blood loss,
                  hematoma formation, and drain output after shoulder arthroplasty, which may otherwise serve as a nidus
                  for tissue contamination and deep infection<a href="" data-id="citation_reference_63"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_46" class="annotation superscript" style="position: unset;">54</span></a>.
                  Further, a recent study by Pearson et al. explored the cost-effectiveness of TXA for infection
                  prevention after shoulder arthroplasty, showing that TXA would be cost-effective if it reduced the
                  infection rate by just 0.009%<a href="" data-id="citation_reference_64"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_47" class="annotation superscript" style="position: unset;">55</span></a>,
                  further supporting its use.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Classification and Diagnosis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Shoulder PJI is a diagnostic challenge, as validated
                  algorithms for hip and knee PJI do not display similar reliability for shoulder implants<a href=""
                    data-id="citation_reference_65" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_48"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_66" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">56</span></a><span data-id="superscript_48"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_67" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">58</span></a>. Radiographic findings such as humeral osteolysis,
                  peri-implant lucency, joint effusion, periosteal reaction, and endosteal scalloping are nondiagnostic
                  of infection<a href="" data-id="citation_reference_68"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_49" class="annotation superscript" style="position: unset;">59</span></a> (<a
                    href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). Furthermore, shoulder PJI often does not present with
                  pathognomonic signs of infection (draining sinuses, fevers, and chills)<a href=""
                    data-id="citation_reference_69" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_50"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_70" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                      style="position: unset;">60</span></a><span data-id="superscript_50"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_71" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                      style="position: unset;">62</span></a> (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>).
                  Additionally, serologic testing has a significantly lower sensitivity and negative predictive value
                  for shoulder PJI<a href="" data-id="citation_reference_72"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_51" class="annotation superscript" style="position: unset;">63</span></a>,
                  and the poor sensitivity of other biomarkers including serum interleukin (IL)-6 limits their clinical
                  utility<a href="" data-id="citation_reference_73"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_52" class="annotation superscript" style="position: unset;">64</span></a>.
                  The diagnosis of shoulder PJI requires a combination of identifying both a host response (i.e.,
                  positive serologic or synovial markers) and bacterial inoculation. For the latter, the official ICM
                  recommendation includes tissue sampling as a diagnostic method and often requires 5 deep-tissue
                  samples to assess the presence of bacteria<a href="" data-id="citation_reference_74"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_53" class="annotation superscript" style="position: unset;">42</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01073f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=c75feed3-ebc5-4157-835e-9d0cda7b800e&type=jpeg&name=JBJS.23.01073f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Anteroposterior radiograph of the left shoulder
                          after anatomic TSA in a patient who presented with pain around the shoulder replacement 10
                          years after the index procedure. The radiographic findings of humeral-sided osteolysis and
                          endosteal scalloping raised concern for shoulder PJI.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01073f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=c75feed3-ebc5-4157-835e-9d0cda7b800e&type=jpeg&name=JBJS.23.01073f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;">Clinical photograph of a patient presenting with
                          shoulder PJI, found to be from <span data-id="emphasis_131" class="annotation emphasis"
                            style="position: unset;">C</span>. <span data-id="emphasis_132" class="annotation emphasis"
                            style="position: unset;">acnes</span>. (Reproduced from: Sagkrioti M, Glass S, Arealis G.
                          Evaluation of the effectiveness of skin preparation methods for the reduction of Cutibacterium
                          acnes (formerly Propionibacterium acnes) in shoulder surgery: a systematic review. Shoulder
                          Elbow. 2022 Dec;14[6]:583-97. With permission from SAGE Publications, © 2021.)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Arthrocentesis may not be reliable in shoulder PJI because
                  of a reported 20% to 43% rate of dry taps from the glenohumeral joint as well as difficulty culturing
                  causative organisms<a href="" data-id="citation_reference_75"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_54" class="annotation superscript"
                      style="position: unset;">65</span></a><span data-id="superscript_54"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_76" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_54" class="annotation superscript"
                      style="position: unset;">67</span></a>. In cases in which synovial fluid is obtained, the
                  slow-growing nature of <span data-id="emphasis_133" class="annotation emphasis"
                    style="position: unset;">C. acnes</span> requires a long incubation period for conventional
                  bacterial culture (14 to 21 days for adequate detection)<a href="" data-id="citation_reference_77"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_55" class="annotation superscript" style="position: unset;">68</span></a>,
                  potentially delaying appropriate treatment. Additionally, there is limited evidence supporting the use
                  of inflammatory markers, including synovial IL-6 and alpha-defensin testing. In 1 study, synovial
                  fluid IL-6 demonstrated high sensitivity of 87% and specificity of 90% for shoulder PJI<a href=""
                    data-id="citation_reference_78" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_56" class="annotation superscript"
                      style="position: unset;">69</span></a>. Frangiamore et al. found that synovial alpha-defensin had
                  good diagnostic accuracy for shoulder PJI, as a positive alpha-defensin test increased the pre-test
                  odds of infection by a factor of 12.1 and carried a specificity of 95%<a href=""
                    data-id="citation_reference_79" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_57" class="annotation superscript"
                      style="position: unset;">70</span></a>. In another study, a positive synovial alpha-defensin test
                  had a sensitivity of 60% and a specificity of 83% for shoulder PJI<a href=""
                    data-id="citation_reference_80" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_58" class="annotation superscript"
                      style="position: unset;">71</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Recent studies have also supported the utility of
                  arthroscopic tissue biopsy rather than open sampling<a href="" data-id="citation_reference_81"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_59" class="annotation superscript"
                      style="position: unset;">62</span></a><span data-id="superscript_59"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_82" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_59" class="annotation superscript"
                      style="position: unset;">72</span></a>. When low-virulence organisms are suspected, synovial
                  biopsy may aid in diagnosis, with 1 study demonstrating 67% sensitivity and 95% specificity<a href=""
                    data-id="citation_reference_83" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_60" class="annotation superscript"
                      style="position: unset;">73</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">With regard to intraoperative frozen biopsy samples, the
                  ICM deemed that frozen sections or synovial biopsy, when reviewed by an experienced pathologist, may
                  be useful to evaluate for PJI. However, there has been limited evidence to support this
                  recommendation, with 2 studies demonstrating modest sensitivity but high specificity of frozen
                  sections, particularly in patients with culture-positive <span data-id="emphasis_134"
                    class="annotation emphasis" style="position: unset;">C. acnes</span>. These studies recommend a
                  lower diagnostic threshold for less-virulent organisms, defined as ≥10 polymorphonuclear leukocytes in
                  5 high-power fields<a href="" data-id="citation_reference_84"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_61" class="annotation superscript"
                      style="position: unset;">57</span></a><span data-id="superscript_61"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_85" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_61" class="annotation superscript"
                      style="position: unset;">74</span></a>. In aggregate, frozen sections and synovial biopsy may
                  assist the surgeon when the diagnosis is uncertain at the time of the surgical procedure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">In cases of uncertainty, we recommend performing joint
                  aspiration and assessing the preoperative erythrocyte sedimentation rate (ESR) and C-reactive protein
                  (CRP) level. In cases with a positive aspirate culture and elevated ESR and CRP, surgeons may proceed
                  with 1-stage or 2-stage revision. In cases with a negative aspiration and normal ESR and CRP, surgeons
                  may proceed with revision, at which time frozen sections may help to determine whether a definitive
                  implant may be placed or whether a second-stage revision is required. In cases of a negative
                  aspiration but elevated ESR and CRP, an arthroscopic biopsy may be performed to acquire tissue
                  samples. If cultures are positive, 1-stage or 2-stage revision may be performed. If cultures are
                  negative at the time of arthroscopic biopsy, other etiologies should be considered, with the
                  possibility of reaspiration if patients do not show clinical improvement.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The 2018 ICM provided the first iteration of a
                  shoulder-specific definition of PJI similar to the classic Musculoskeletal Infection Society (MSIS)
                  criteria<a href="" data-id="citation_reference_86"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_62" class="annotation superscript" style="position: unset;">42</span></a>,
                  stratifying shoulder PJI risk into the following categories: (1) definite, (2) probable, (3) possible,
                  and (4) unlikely. Criteria for “definite” shoulder PJI include (1) presence of a sinus tract from the
                  skin surface to the prosthesis, (2) gross intra-articular pus, and (3) 2 positive tissue cultures with
                  phenotypically identical virulent organisms<a href="" data-id="citation_reference_87"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_63" class="annotation superscript" style="position: unset;">42</span></a>. If
                  none of the criteria for definite shoulder PJI are met, the consensus meeting established a scoring
                  system to evaluate the likelihood of shoulder PJI based on the summation of the minor criteria in <a
                    href="" data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Major and Minor Criteria Used to Evaluate for
                          Shoulder PJI When Criteria for Definite Shoulder PJI Are Not Met<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 912px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Major Criteria</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Minor
                          Criteria and Weighted Value (in Parentheses)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">≥2 positive cultures of
                          aspirated joint fluid and/or synovial tissue samples<br data-id="break_1"
                            style="position: unset;">Fistula communicating with the prosthesis</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Cloudy fluid (2)<br
                            data-id="break_2" style="position: unset;">Elevated synovial α-defensin level (2)<br
                            data-id="break_3" style="position: unset;">Elevated CRP level<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span> (&gt;10 mg/L) (2)<br data-id="break_4"
                            style="position: unset;">Elevated ESR<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">†</span> (&gt;30 mm/hr)
                          (2)<br data-id="break_5" style="position: unset;">Elevated synovial WBC count<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">†</span> (&gt;3,000 cells/μL) (2)<br data-id="break_6"
                            style="position: unset;">Elevated synovial neutrophil percentage<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">†</span> (&gt;80%) (2)<br data-id="break_7"
                            style="position: unset;">Positive preoperative aspirate culture (low or high virulence)
                          (3)<br data-id="break_8" style="position: unset;">Positive frozen section (5 PMNs in ≥5
                          high-power fields) (3)<br data-id="break_9" style="position: unset;">Humeral loosening (3)<br
                            data-id="break_10" style="position: unset;">Single positive tissue culture with
                          low-virulence organism (1)<br data-id="break_11" style="position: unset;">Second positive
                          tissue culture (same low-virulence organism) (3)<br data-id="break_12"
                            style="position: unset;">Single positive tissue culture with virulent organism (3)<br
                            data-id="break_13" style="position: unset;">Unexpected wound drainage (4)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Based on diagnostic criteria originally proposed in Garrigues et al.<a
                          href="" data-id="citation_reference_88"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_64" class="annotation superscript"
                            style="position: unset;">42</span></a><span data-id="superscript_64"
                          class="annotation superscript" style="position: unset;"></span> and Fink and Sevelda<a href=""
                          data-id="citation_reference_89" class="annotation citation_reference resource-reference"
                          style="position: unset;"><span data-id="superscript_65" class="annotation superscript"
                            style="position: unset;">68</span></a><span data-id="superscript_65"
                          class="annotation superscript" style="position: unset;"></span>. PMN = polymorphonuclear
                        leukocytes and WBC = white blood cell.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">After 6 weeks postoperatively.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">A score of ≥6 with an identified organism indicates
                  probable shoulder PJI, whereas a score of ≥6 without an identified organism indicates possible
                  shoulder PJI. If the score is &lt;6, a single positive culture with a virulent organism or 2 positive
                  cultures with a low-virulence organism also indicates possible shoulder PJI. Lastly, a score of &lt;6
                  with negative cultures or a single positive culture with a low-virulence organism indicates that
                  shoulder PJI is unlikely.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Another way to evaluate shoulder PJI is by chronicity.
                  Infections have been classified on the basis of the time of symptom onset after implantation, with
                  early infections defined as manifesting within 1 to 3 months, delayed infections manifesting at &gt;3
                  to 12 months, and late infections manifesting at &gt;12 months<a href=""
                    data-id="citation_reference_90" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_66" class="annotation superscript"
                      style="position: unset;">75</span></a><span data-id="superscript_66"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_91" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_66" class="annotation superscript"
                      style="position: unset;">76</span></a>. Finally, the duration of infection, measured as the time
                  from the initial surgical procedure, can be classified as acute or chronic, delineated by days (acute)
                  or months to years (chronic) of infectious signs and symptoms.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_27" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Treatment
                and Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">The treatment of shoulder PJI is dictated largely by its
                  classification and can range from revision to maintenance of an implant with long-term antibiotic
                  suppression in patients who are too ill for a surgical procedure or who simply do not desire a
                  revision surgical procedure. Surgical treatment can employ either single-stage management with
                  resection and reimplantation or 2-stage management with intermediate antibiotic spacers. In patients
                  who are poor surgical candidates, debridement, antibiotics, and implant retention (DAIR), long-term
                  suppressive antibiotic treatment<a href="" data-id="citation_reference_92"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_67" class="annotation superscript" style="position: unset;">42</span></a>, or
                  resection and permanent antibiotic spacer placement are options<a href=""
                    data-id="citation_reference_93" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_68" class="annotation superscript"
                      style="position: unset;">77</span></a><span data-id="superscript_68"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_94" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_68" class="annotation superscript"
                      style="position: unset;">79</span></a>. Recently, a 3-stage revision has been described, which
                  utilizes an intermediate open biopsy prior to placing final components in order to gauge the success
                  of the antibiotic therapy<a href="" data-id="citation_reference_95"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_69" class="annotation superscript" style="position: unset;">80</span></a>.
                  Lastly, resection arthroplasty without subsequent implantation may be a last-resort option for certain
                  populations including elderly, chronically ill patients unable to tolerate multiple procedures<a
                    href="" data-id="citation_reference_96" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_70" class="annotation superscript"
                      style="position: unset;">81</span></a> (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Key Articles Highlighting Treatment Options for
                          Shoulder PJI</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1736px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Studies</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Treatment
                          Options</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Indications</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Outcomes</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Jacquot<a href=""
                            data-id="citation_reference_97" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_71" class="annotation superscript"
                              style="position: unset;">88</span></a><span data-id="superscript_71"
                            class="annotation superscript" style="position: unset;"></span> (2015)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">DAIR</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Early acute setting of
                          infection</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Infection-free survival
                          rate of up to 71%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Garrigues<a href=""
                            data-id="citation_reference_98" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_72" class="annotation superscript"
                              style="position: unset;">42</span></a><span data-id="superscript_72"
                            class="annotation superscript" style="position: unset;"></span> (2019)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Suppressive
                          antibiotics</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Chronic late infections
                          without surgical intervention</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Infection-free survival
                          rate ranges from 58% to 100%, depending on the antibiotic combination used (based on hip and
                          knee arthroplasty literature)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pellegrini<a href=""
                            data-id="citation_reference_99" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_73" class="annotation superscript"
                              style="position: unset;">78</span></a><span data-id="superscript_73"
                            class="annotation superscript" style="position: unset;"></span> (2018)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Antibiotic spacers</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Chronic late infections
                          without plans for implant removal due to comorbidities</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Infection-free survival
                          rate of between 66% and 100% in combination with the removal of the infected prosthesis</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Kunutsor<a href=""
                            data-id="citation_reference_100" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_74" class="annotation superscript"
                              style="position: unset;">6</span></a><span data-id="superscript_74"
                            class="annotation superscript" style="position: unset;"></span> (2020), Belay<a href=""
                            data-id="citation_reference_101" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_75" class="annotation superscript"
                              style="position: unset;">98</span></a><span data-id="superscript_75"
                            class="annotation superscript" style="position: unset;"></span> (2020), Fiore<a href=""
                            data-id="citation_reference_102" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_76" class="annotation superscript"
                              style="position: unset;">99</span></a><span data-id="superscript_76"
                            class="annotation superscript" style="position: unset;"></span> (2023)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1-stage revision or
                          replacement</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Lower-virulence organisms
                          (e.g., <span data-id="emphasis_135" class="annotation emphasis" style="position: unset;">C.
                            acnes</span>)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Infection-free survival
                          rate of between 50% and 100% and complication rate of 12% to 14%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Strickland<a href=""
                            data-id="citation_reference_103" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_77" class="annotation superscript"
                              style="position: unset;">96</span></a><span data-id="superscript_77"
                            class="annotation superscript" style="position: unset;"></span> (2008), Kunutsor<a href=""
                            data-id="citation_reference_104" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_78" class="annotation superscript"
                              style="position: unset;">6</span></a><span data-id="superscript_78"
                            class="annotation superscript" style="position: unset;"></span> (2020), Belay<a href=""
                            data-id="citation_reference_105" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_79" class="annotation superscript"
                              style="position: unset;">98</span></a><span data-id="superscript_79"
                            class="annotation superscript" style="position: unset;"></span> (2020), Fiore<a href=""
                            data-id="citation_reference_106" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_80" class="annotation superscript"
                              style="position: unset;">99</span></a><span data-id="superscript_80"
                            class="annotation superscript" style="position: unset;"></span> (2023)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2-stage revision or
                          replacement</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Gold standard; chronic or
                          late infections with virulent, drug-resistant organism (e.g., MRSA)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Infection-free survival
                          rate of between 63% and 100% and complication rate of 19% to 38%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Zhang<a href=""
                            data-id="citation_reference_107" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_81" class="annotation superscript"
                              style="position: unset;">81</span></a><span data-id="superscript_81"
                            class="annotation superscript" style="position: unset;"></span> (2015), Tseng<a href=""
                            data-id="citation_reference_108" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_82" class="annotation superscript"
                              style="position: unset;">80</span></a><span data-id="superscript_82"
                            class="annotation superscript" style="position: unset;"></span> (2019)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3-stage revision or
                          reimplantation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Confirmation of
                          eradication of infection with an intermediate-stage open biopsy is needed before
                          reimplantation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Infection-free survival
                          rate of up to 100%</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Antibiotic therapy, often a mainstay of treatment, comes
                  with drawbacks, including organ toxicity and development of antibiotic resistance that may pose
                  difficulties with maintenance and prophylactic treatment. There is no definitive answer regarding when
                  to start antibiotics. The results of cultures, often the litmus test for “infected” compared with
                  “non-infected,” are not known until days to weeks after collection. It is helpful to consider
                  risk-benefit profiles along with the available literature. Hsu et al. developed a nomenclature in
                  which infection is either “obvious” (elevated ESR or CRP, wound drainage and/or erythema) or “stealth”
                  (absent clinical indicators with positive cultures)<a href="" data-id="citation_reference_109"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_83" class="annotation superscript" style="position: unset;">82</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">To cover both presentations, all patients undergoing
                  revision shoulder arthroplasty are placed on antibiotic prophylaxis until culture results are known
                  (approximately 2 weeks), given the favorable risk-benefit ratio. With regard to intravenous
                  antibiotics compared with oral antibiotics, the 2018 ICM ruled that there was no overt benefit of
                  intravenous antibiotics over oral antibiotic therapy, and no agreement on the type or duration of
                  therapy<a href="" data-id="citation_reference_110"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_84" class="annotation superscript" style="position: unset;">42</span></a>.
                  Therefore, we prefer oral antibiotic therapy as a method of suppression and treatment while avoiding
                  iatrogenic risk to patients resulting from intravenous antibiotic therapy. Notably, the fear of
                  limiting subsequent culture data should not delay initiation prior to the start of the surgical
                  procedure, as newer studies have suggested that preoperative antibiotic administration does not
                  significantly influence culture yield, in agreement with the 2018 ICM<a href=""
                    data-id="citation_reference_111" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_85" class="annotation superscript"
                      style="position: unset;">42</span></a><span data-id="superscript_85"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_112" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_85" class="annotation superscript"
                      style="position: unset;">83</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">DAIR Partial
                Single-Stage Exchange</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Early postoperative infections and those occurring acutely
                  via hematogenous spread may be amenable to DAIR. This procedure is usually performed via an open
                  approach (as arthroscopic approaches are generally ineffective at eradicating biofilm<a href=""
                    data-id="citation_reference_113" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_86" class="annotation superscript"
                      style="position: unset;">84</span></a>). Empiric broad-spectrum antibiotics are initiated and then
                  are transitioned to an adapted, targeted therapy. There are little confirmatory data on the
                  appropriate duration of antibiotic therapy in these instances<a href=""
                    data-id="citation_reference_114" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_87" class="annotation superscript"
                      style="position: unset;">68</span></a>. When inflammatory markers are elevated, antibiotic therapy
                  should be continued until they have normalized<a href="" data-id="citation_reference_115"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_88" class="annotation superscript" style="position: unset;">85</span></a>.
                  The literature has suggested that many patients may require additional chronic antibiotic
                  suppression<a href="" data-id="citation_reference_116"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_89" class="annotation superscript"
                      style="position: unset;">86</span></a><span data-id="superscript_89"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_117" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_89" class="annotation superscript"
                      style="position: unset;">87</span></a>. Overall, varied success rates for DAIR have been reported:
                  Jacquot et al. reported successful infection control in 54% of cases<a href=""
                    data-id="citation_reference_118" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_90" class="annotation superscript"
                      style="position: unset;">88</span></a>, whereas Ortmaier et al. reported a success rate of only
                  29% in acute cases<a href="" data-id="citation_reference_119"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_91" class="annotation superscript" style="position: unset;">89</span></a> and
                  Zavala et al. reported a 60% success rate in the acute setting and 33% in the subacute or chronic
                  setting<a href="" data-id="citation_reference_120"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_92" class="annotation superscript" style="position: unset;">90</span></a>.
                  More recently, a 2024 study by Kew et al. found that patients with shoulder PJI treated with DAIR had
                  equivalent rates of reinfection and complications compared with patients who underwent single-stage
                  and 2-stage revision procedures<a href="" data-id="citation_reference_121"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_93" class="annotation superscript" style="position: unset;">91</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Partial single-stage revision, involving a modular
                  component exchange, is another option. In a 2017 study, Stone et al. found that partial single-stage
                  revision yielded a 63% eradication rate, although it yielded a substantially higher reoperation rate
                  compared with single-stage revision shoulder arthroplasty: 27% compared with 4%<a href=""
                    data-id="citation_reference_122" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_94" class="annotation superscript"
                      style="position: unset;">92</span></a>. As outlined by the 2018 ICM, partial single-stage revision
                  does potentially have a role in the management of shoulder PJI, especially when well-fixed humeral
                  implants (and patients who may not tolerate complete reimplantation or multistage procedures) are
                  involved<a href="" data-id="citation_reference_123"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_95" class="annotation superscript" style="position: unset;">42</span></a>.
                  However, it must be made clear that utilizing this technique carries a possible risk of persistent
                  infection.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Single-Stage
                Revision</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Single-stage management of shoulder PJI is emerging as a
                  viable treatment option<a href="" data-id="citation_reference_124"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_96" class="annotation superscript" style="position: unset;">93</span></a>.
                  Single-stage revision has the benefits of lower cost, lower morbidity, and less compromise of the
                  soft-tissue envelope than 2-stage revision. In addition, complications such as spacer fracture or bone
                  resorption can be avoided. Antibiotic-impregnated cement is often utilized during reimplantation, as
                  the cement elutes antibiotics into the tissue bed and elevates local concentrations beyond those
                  achievable via systemic administration<a href="" data-id="citation_reference_125"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_97" class="annotation superscript"
                      style="position: unset;">94</span></a><span data-id="superscript_97"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_126" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_97" class="annotation superscript"
                      style="position: unset;">97</span></a>. Belay et al. reported a lower reinfection rate of 6.3% for
                  single-stage revision compared with 10.1% for 2-stage revision, as well as a lower complication rate
                  (11.4% compared with 22.5%)<a href="" data-id="citation_reference_127"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_98" class="annotation superscript" style="position: unset;">98</span></a>.
                  Fiore et al. also compared single-stage and 2-stage revision and found significantly lower reinfection
                  and complication rates and a greater PJI eradication rate in the single-stage group (95.6%) than in
                  the 2-stage revision group (85.7%). Additionally, the single-stage revision group had a lower rate of
                  non-infection-related complications (13.8%) and revision rate (8%) than the 2-stage revision group
                  (37.6% and 18.9%, respectively)<a href="" data-id="citation_reference_128"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_99" class="annotation superscript" style="position: unset;">99</span></a>. It
                  is important to note that these results should be interpreted with caution as they may be subject to
                  selection bias.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">The antibiotic regimen after single-stage revision is an
                  important consideration. The 2018 ICM reported that there is no definitive evidence with regard to the
                  antibiotic regimen following single-stage revision, or whether there is a role for intravenous
                  compared with oral antibiotic administration while awaiting final culture data<a href=""
                    data-id="citation_reference_129" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_100" class="annotation superscript"
                      style="position: unset;">42</span></a>. Additionally, although trending certain values such as
                  serum white blood-cell (WBC) count, ESR, or CRP may be helpful, there is no evidence that supports
                  doing so.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Two-Stage
                Revision</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">Two-stage management usually includes complete implant
                  removal and placement of an antibiotic cement spacer along with a period of intravenous antibiotics
                  that depends on the patient’s response and the responsible pathologic organism<a href=""
                    data-id="citation_reference_130" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_101" class="annotation superscript"
                      style="position: unset;">42</span></a><span data-id="superscript_101"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_131" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_101" class="annotation superscript"
                      style="position: unset;">100</span></a>. Alternatively, abridged 2-stage revisions are gaining
                  popularity, whereby implant removal and debridement are followed by revision during the same hospital
                  encounter. Although this technique is promising, its efficacy remains to be shown in the literature.
                  Reported techniques vary widely in terms of types of cement, amount of antibiotics, and use of
                  intraoperatively handmade implants rather than commercially designed spacers, although little data on
                  these cementation strategies exist<a href="" data-id="citation_reference_132"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_102" class="annotation superscript"
                      style="position: unset;">101</span></a><span data-id="superscript_102"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_133" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_102" class="annotation superscript"
                      style="position: unset;">102</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">Two-stage revision may be regarded as the gold-standard
                  management, especially in cases of definite shoulder PJI; however, in other cases, single-stage
                  revision remains a viable treatment option. The 2018 ICM determined that single-stage revision was
                  appropriate for both acute and chronic or subacute shoulder PJI, and that 2-stage revision carried a
                  1.72 times higher risk of intraoperative or postoperative complications<a href=""
                    data-id="citation_reference_134" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_103" class="annotation superscript"
                      style="position: unset;">42</span></a>. Notably, comparisons between single-stage and 2-stage
                  revision are heavily influenced by selection bias, especially as more virulent pathogens yield
                  aggressive clinical presentations and are likely preferentially treated with 2-stage revision.
                  Therefore, until further investigations controlling for these confounding factors are developed, our
                  preference is to utilize 2-stage revision when possible.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Permanent
                Antibiotic Spacer</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">Antibiotic spacers may offer a permanent solution when a
                  future surgical procedure is not recommended. Permanent antibiotic spacers for shoulder PJI achieve
                  reproducibly low levels of reinfection while retaining joint function<a href=""
                    data-id="citation_reference_135" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_104" class="annotation superscript"
                      style="position: unset;">103</span></a><span data-id="superscript_104"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_136" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_104" class="annotation superscript"
                      style="position: unset;">104</span></a>. However, antibiotic concentrations eventually decrease,
                  and the cement spacer can become a nonbiologic nidus for infection. Furthermore, the spacer can
                  dislocate, break, or compress vessels or nerves. Finally, excessive elution of antibiotics may cause
                  complications including renal damage (with rates ranging from 4.8% to 20% in the lower-extremity
                  arthroplasty literature), hypersensitivity reactions, and antibiotic resistance<a href=""
                    data-id="citation_reference_137" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_105" class="annotation superscript"
                      style="position: unset;">105</span></a><span data-id="superscript_105"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_138" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_105" class="annotation superscript"
                      style="position: unset;">107</span></a>. Although these drawbacks of drug elution from spacers
                  exist, they are also important to consider for the initiation of standard intravenous antibiotic use.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">Based on the above discussion and available literature, we
                  have provided graded recommendations (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>). A
                  flow diagram of treatment options and indications is shown in <a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 3</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_48" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Grades of Recommendations</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1097px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Recommendation</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Grade<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Optimizing patient
                          comorbidities is essential to prevent shoulder PJI. The current evidence demonstrates an
                          association between certain patient comorbidities and risk of infection.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">C</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Patients undergoing RSA
                          are at a higher risk for PJI, necessitating greater attention to infection prevention. The
                          current literature shows increased risk of PJI following revision shoulder arthroplasty
                          compared with primary shoulder arthroplasty.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">C</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">The use of definitive
                          shoulder PJI criteria is essential for assessing the possibility of shoulder PJI initially.
                          The diagnostic criteria have received support from a consensus meeting conducted in
                          2018.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">C</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minor criteria can be
                          used to evaluate for shoulder PJI when criteria for definite shoulder PJI are not met. The
                          diagnostic criteria are supported by a consensus meeting that was held in 2018.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">C</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">An antibiotic spacer is a
                          good option for achieving a permanent solution with low levels of reinfection if further
                          surgery is not an option. A recently published systematic review<a href=""
                            data-id="citation_reference_139" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_106" class="annotation superscript"
                              style="position: unset;">103</span></a><span data-id="superscript_106"
                            class="annotation superscript" style="position: unset;"></span> demonstrates high rates of
                          infection resolution following antibiotic spacer.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">C</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Both operative and
                          nonoperative treatment options are generally good; however, the treatment choice should be
                          based on the patient’s individual characteristics. Meta-analyses and cohort and case-control
                          studies have demonstrated overall low reinfection rates following each treatment
                          option.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">C</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Both 1-stage and 2-stage
                          surgical revisions of shoulder PJI are effective treatment options for controlling infection
                          and maintaining joint function. Recent meta-analyses and systematic reviews have demonstrated
                          good results following both surgical interventions.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">C</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">According to Wright<a href="" data-id="citation_reference_140"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_107" class="annotation superscript"
                            style="position: unset;">109</span></a><span data-id="superscript_107"
                          class="annotation superscript" style="position: unset;"></span>, grade A indicates good
                        evidence (Level-I studies with consistent findings) for or against recommending intervention;
                        grade B, fair evidence (Level-II or III studies with consistent findings) for or against
                        recommending intervention; grade C, poor-quality evidence (Level-IV or V studies with consistent
                        findings) for or against recommending intervention; and grade I, insufficient or conflicting
                        evidence not allowing a recommendation for or against intervention.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01073f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=c75feed3-ebc5-4157-835e-9d0cda7b800e&type=jpeg&name=JBJS.23.01073f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_49" style="position: unset;">
                        <div class="content" style="position: unset;">Flowchart summarizing the treatment algorithm for
                          shoulder PJI. Labs = laboratory values, and ROM = range of motion.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_28" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Summary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_50" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_50" style="position: unset;">
                <div class="content" style="position: unset;">The diagnosis of shoulder PJI remains challenging.
                  Strategies are largely based on the THA and TKA literature, yet the shoulder-specific diagnostic
                  algorithm is rapidly evolving. Treatment relies on patient-specific factors as well as chronicity of
                  infection and exists on a spectrum from irrigation and debridement to multistage revision. As shoulder
                  PJI increases in incidence, future work will continue to develop treatment algorithms derived from
                  larger, prospective shoulder PJI-specific data.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Schick S</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Elphingstone J</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Murali S</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Carter K</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Davis W</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> McGwin G</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Evely T</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Ponce B</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Momaya A</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Brabston E</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">The incidence of shoulder
                  arthroplasty infection presents a substantial economic burden in the United States: a predictive
                  model</span>. <span data-id="emphasis_1" class="annotation emphasis" style="position: unset;">JSES
                  Int.</span> 2023 Apr 11;7(4):636-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20incidence%20of%20shoulder%20arthroplasty%20infection%20presents%20a%20substantial%20economic%20burden%20in%20the%20United%20States%3A%20a%20predictive%20model&as_occt=title&as_sauthors=%20%22S%20Schick%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Padegimas EM</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Maltenfort M</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Ramsey ML</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Williams GR</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Parvizi J</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Namdari S</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Periprosthetic shoulder
                  infection in the United States: incidence and economic burden</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2015
                May;24(5):741-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Periprosthetic%20shoulder%20infection%20in%20the%20United%20States%3A%20incidence%20and%20economic%20burden&as_occt=title&as_sauthors=%20%22EM%20Padegimas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Jauregui JJ</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Tran A</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Kaveeshwar S</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Nadarajah V</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Chaudhri MW</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Henn RF 3rd</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Gilotra MN</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Hasan SA</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Diagnosing a periprosthetic
                  shoulder infection: a systematic review</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">J Orthop.</span> 2021 Jul 13;26:58-66.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagnosing%20a%20periprosthetic%20shoulder%20infection%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22JJ%20Jauregui%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Richards J</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Inacio MC</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Beckett M</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Navarro RA</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Singh A</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Dillon MT</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Sodl JF</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Yian EH</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Patient and procedure-specific
                  risk factors for deep infection after primary shoulder arthroplasty</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2014
                Sep;472(9):2809-15.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20and%20procedure-specific%20risk%20factors%20for%20deep%20infection%20after%20primary%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22J%20Richards%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Markes AR</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Bigham J</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Ma CB</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Iyengar JJ</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Feeley BT</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Preventing and treating
                  infection in reverse total shoulder arthroplasty</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Curr Rev Musculoskelet Med.</span> 2023
                Aug;16(8):371-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preventing%20and%20treating%20infection%20in%20reverse%20total%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22AR%20Markes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Kunutsor SK</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Barrett MC</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Whitehouse MR</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Craig RS</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Lenguerrand E</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Beswick AD</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Blom AW</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Incidence, temporal trends and
                  potential risk factors for prosthetic joint infection after primary total shoulder and elbow
                  replacement: systematic review and meta-analysis</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">J Infect.</span> 2020
                Apr;80(4):426-36.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%2C%20temporal%20trends%20and%20potential%20risk%20factors%20for%20prosthetic%20joint%20infection%20after%20primary%20total%20shoulder%20and%20elbow%20replacement%3A%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22SK%20Kunutsor%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Moeini S</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Rasmussen JV</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Salomonsson B</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Domeij-Arverud E</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Fenstad AM</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Hole R</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Jensen SL</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Brorson S</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Reverse shoulder arthroplasty
                  has a higher risk of revision due to infection than anatomical shoulder arthroplasty: 17 730 primary
                  shoulder arthroplasties from the Nordic Arthroplasty Register Association</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2019
                Jun;101-B(6):702-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reverse%20shoulder%20arthroplasty%20has%20a%20higher%20risk%20of%20revision%20due%20to%20infection%20than%20anatomical%20shoulder%20arthroplasty%3A%2017%20730%20primary%20shoulder%20arthroplasties%20from%20the%20Nordic%20Arthroplasty%20Register%20Association&as_occt=title&as_sauthors=%20%22S%20Moeini%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Chuang MJ</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Jancosko JJ</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Mendoza V</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Nottage WM</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">The incidence of
                  Propionibacterium acnes in shoulder arthroscopy</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2015
                Sep;31(9):1702-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20incidence%20of%20Propionibacterium%20acnes%20in%20shoulder%20arthroscopy&as_occt=title&as_sauthors=%20%22MJ%20Chuang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Schiffman CJ</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Hsu JE</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Khoo KJ</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Whitson A</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Yao JJ</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Wu JC</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Matsen FA 3rd</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Association between serum
                  testosterone levels and Cutibacterium skin load in patients undergoing elective shoulder arthroplasty:
                  a cohort study</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">JB JS Open Access.</span> 2021 Dec 8;6(4):e21.00030.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20between%20serum%20testosterone%20levels%20and%20Cutibacterium%20skin%20load%20in%20patients%20undergoing%20elective%20shoulder%20arthroplasty%3A%20a%20cohort%20study&as_occt=title&as_sauthors=%20%22CJ%20Schiffman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Singh JA</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Sperling JW</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Schleck C</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Harmsen WS</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Cofield RH</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Periprosthetic infections after
                  total shoulder arthroplasty:s a 33-year perspective</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2012
                Nov;21(11):1534-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Periprosthetic%20infections%20after%20total%20shoulder%20arthroplasty%3As%20a%2033-year%20perspective&as_occt=title&as_sauthors=%20%22JA%20Singh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Morris BJ</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> O’Connor DP</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Torres D</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Elkousy HA</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Gartsman GM</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Edwards TB</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Risk factors for periprosthetic
                  infection after reverse shoulder arthroplasty</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2015
                Feb;24(2):161-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20for%20periprosthetic%20infection%20after%20reverse%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22BJ%20Morris%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Pottinger P</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Butler-Wu S</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Neradilek MB</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Merritt A</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Bertelsen A</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Jette JL</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Warme WJ</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Matsen FA 3rd</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Prognostic factors for
                  bacterial cultures positive for Propionibacterium acnes and other organisms in a large series of
                  revision shoulder arthroplasties performed for stiffness, pain, or loosening</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2012 Nov 21;94(22):2075-83.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1153134" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;">Australian
                Orthopaedic Association National Joint Replacement Registry. <span data-id="strong_13"
                  class="annotation strong" style="position: unset;">Hip, Knee and Shoulder Arthroplasty: 2023 Annual
                  Report</span>. 2023. Accessed 2024 Jul 18. <a
                  href="https://aoanjrr.sahmri.com/documents/10180/1579982/AOA_NJRR_AR23.pdf/c3bcc83b-5590-e034-4ad8-802e4ad8bf5b?t=1695887126627"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://aoanjrr.sahmri.com/documents/10180/1579982/AOA_NJRR_AR23.pdf/c3bcc83b-5590-e034-4ad8-802e4ad8bf5b?t=1695887126627</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%2C%20Knee%20and%20Shoulder%20Arthroplasty%3A%202023%20Annual%20Report&as_occt=title&as_sauthors=%20%22Australian%20Orthopaedic%20Association%20National%20Joint%20Replacement%20Registry%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Goodman SM</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Springer BD</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Chen AF</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Davis M</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Fernandez DR</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Figgie M</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Finlayson H</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> George MD</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Giles JT</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Gilliland J</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Klatt B</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> MacKenzie R</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Michaud K</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Miller A</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Russell L</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Sah A</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Abdel MP</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Johnson B</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Mandl LA</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Sculco P</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Turgunbaev M</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Turner AS</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Yates A Jr</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Singh JA</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">2022 American College of
                  Rheumatology/American Association of Hip and Knee Surgeons Guideline for the Perioperative Management
                  of Antirheumatic Medication in Patients with Rheumatic Diseases Undergoing Elective Total Hip or Total
                  Knee Arthroplasty</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Arthritis Care Res (Hoboken).</span> 2022 Sep;74(9):1399-408.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=2022%20American%20College%20of%20Rheumatology%2FAmerican%20Association%20of%20Hip%20and%20Knee%20Surgeons%20Guideline%20for%20the%20Perioperative%20Management%20of%20Antirheumatic%20Medication%20in%20Patients%20with%20Rheumatic%20Diseases%20Undergoing%20Elective%20Total%20Hip%20or%20Total%20Knee%20Arthroplasty&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Stadecker M</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Gu A</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Ramamurti P</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Fassihi SC</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Wei C</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Agarwal AR</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Bovonratwet P</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Srikumaran U</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Risk of revision based on
                  timing of corticosteroid injection prior to shoulder arthroplasty</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2022
                May;104-B(5):620-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20of%20revision%20based%20on%20timing%20of%20corticosteroid%20injection%20prior%20to%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22M%20Stadecker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Baksh N</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Etcheson JI</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Liu S</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Ikwuazom CP</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Chen Z</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Dubin JA</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Hameed D</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Naziri Q</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Pre-operative corticosteroid
                  injection within 1 month of total shoulder arthroplasty is associated with increased risk of
                  periprosthetic joint infection</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Arch Orthop Trauma Surg.</span> 2023 Sep;143(9):5609-14.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pre-operative%20corticosteroid%20injection%20within%201%20month%20of%20total%20shoulder%20arthroplasty%20is%20associated%20with%20increased%20risk%20of%20periprosthetic%20joint%20infection&as_occt=title&as_sauthors=%20%22N%20Baksh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Ricchetti ET</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Frangiamore SJ</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Grosso MJ</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Alolabi B</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Saleh A</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Bauer TW</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Iannotti JP</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Diagnosis of periprosthetic
                  infection after shoulder arthroplasty: a critical analysis review</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">JBJS Rev.</span> 2013 Nov 19;1(1):e3.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=824961" target="_new" class=""
                  style="position: unset;">JBJS Reviews</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Cancienne JM</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Werner BC</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">The risk of early infection
                  following intra-articular corticosteroid injection following shoulder arthroplasty</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Shoulder Elbow.</span> 2021
                Oct;13(6):605-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20risk%20of%20early%20infection%20following%20intra-articular%20corticosteroid%20injection%20following%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22JM%20Cancienne%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Bhattacharjee S</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Lee W</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Lee MJ</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Shi LL</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Preoperative corticosteroid
                  joint injections within 2 weeks of shoulder arthroscopies increase postoperative infection
                  risk</span>. <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2019 Nov;28(11):2098-102.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preoperative%20corticosteroid%20joint%20injections%20within%202%20weeks%20of%20shoulder%20arthroscopies%20increase%20postoperative%20infection%20risk&as_occt=title&as_sauthors=%20%22S%20Bhattacharjee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Forsythe B</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Agarwalla A</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Puzzitiello RN</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Sumner S</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Romeo AA</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Mascarenhas R</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">The timing of injections prior
                  to arthroscopic rotator cuff repair impacts the risk of surgical site infection</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2019 Apr 17;101(8):682-7.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=2006099" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Baksh N</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Nadarajah V</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Connors KM</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Bains SS</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Chen Z</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Dubin JA</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Naziri Q</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Does preoperative
                  corticosteroid injection increase the risk of periprosthetic joint infection after reverse shoulder
                  arthroplasty?</span><span data-id="emphasis_20" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2023 Jul;32(7):1459-64.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20preoperative%20corticosteroid%20injection%20increase%20the%20risk%20of%20periprosthetic%20joint%20infection%20after%20reverse%20shoulder%20arthroplasty%3F&as_occt=title&as_sauthors=%20%22N%20Baksh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Piple AS</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Wang JC</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Kebaish KJ</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Mills ES</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Oakes DA</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Lieberman JR</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Christ AB</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Heckmann ND</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Does prednisone dose affect
                  rates of periprosthetic joint infection following primary total hip and total knee
                  arthroplasty?</span><span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2023 Jun;38(6):1024-31.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20prednisone%20dose%20affect%20rates%20of%20periprosthetic%20joint%20infection%20following%20primary%20total%20hip%20and%20total%20knee%20arthroplasty%3F&as_occt=title&as_sauthors=%20%22AS%20Piple%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Contreras ES</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Frantz TL</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Bishop JY</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Cvetanovich GL</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Periprosthetic infection after
                  reverse shoulder arthroplasty: a review</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">Curr Rev Musculoskelet Med.</span> 2020
                Dec;13(6):757-68.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Periprosthetic%20infection%20after%20reverse%20shoulder%20arthroplasty%3A%20a%20review&as_occt=title&as_sauthors=%20%22ES%20Contreras%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Villacis D</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Sivasundaram L</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Pannell WC</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Heckmann N</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Omid R</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Hatch GF 3rd</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Complication rate and implant
                  survival for reverse shoulder arthroplasty versus total shoulder arthroplasty: results during the
                  initial 2 years</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2016 Jun;25(6):927-35.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Complication%20rate%20and%20implant%20survival%20for%20reverse%20shoulder%20arthroplasty%20versus%20total%20shoulder%20arthroplasty%3A%20results%20during%20the%20initial%202%20years&as_occt=title&as_sauthors=%20%22D%20Villacis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Florschütz AV</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Lane PD</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Crosby LA</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Infection after primary
                  anatomic versus primary reverse total shoulder arthroplasty</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2015
                Aug;24(8):1296-301.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Infection%20after%20primary%20anatomic%20versus%20primary%20reverse%20total%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22AV%20Florsch%C3%BCtz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Seok HG</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Park JJ</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Park SG</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Risk factors for periprosthetic
                  joint infection after shoulder arthroplasty: systematic review and meta-analysis</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">J Clin Med.</span> 2022 Jul
                21;11(14):4245.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20for%20periprosthetic%20joint%20infection%20after%20shoulder%20arthroplasty%3A%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22HG%20Seok%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Nezwek TA</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Dutcher L</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Mascarenhas L</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Woltemath A</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Thirumavalavan J</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Lund J</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Lo EY</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Krishnan SG</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Prior shoulder surgery and
                  rheumatoid arthritis increase early risk of infection after primary reverse total shoulder
                  arthroplasty</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">JSES Int.</span> 2021 Aug 6;5(6):1062-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prior%20shoulder%20surgery%20and%20rheumatoid%20arthritis%20increase%20early%20risk%20of%20infection%20after%20primary%20reverse%20total%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22TA%20Nezwek%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Everhart JS</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Bishop JY</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Barlow JD</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Medical comorbidities and
                  perioperative allogeneic red blood cell transfusion are risk factors for surgical site infection after
                  shoulder arthroplasty</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2017 Nov;26(11):1922-30.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Medical%20comorbidities%20and%20perioperative%20allogeneic%20red%20blood%20cell%20transfusion%20are%20risk%20factors%20for%20surgical%20site%20infection%20after%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22JS%20Everhart%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Jensen ML</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Jensen SL</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Bolder M</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Hanisch KWJ</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Sørensen AKB</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Olsen BS</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Falstie-Jensen T</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Rasmussen JV</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Previous rotator cuff repair
                  increases the risk of revision surgery for periprosthetic joint infection after reverse shoulder
                  arthroplasty</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2023 Jan;32(1):111-20.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Previous%20rotator%20cuff%20repair%20increases%20the%20risk%20of%20revision%20surgery%20for%20periprosthetic%20joint%20infection%20after%20reverse%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22ML%20Jensen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Cancienne JM</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Brockmeier SF</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Carr JC 2nd</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Werner BC</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Implant removal and spacer
                  placement for infected shoulder arthroplasty: risk factors for repeat procedures, spacer retention,
                  and mortality</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">HSS J.</span> 2018 Oct;14(3):228-32.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Implant%20removal%20and%20spacer%20placement%20for%20infected%20shoulder%20arthroplasty%3A%20risk%20factors%20for%20repeat%20procedures%2C%20spacer%20retention%2C%20and%20mortality&as_occt=title&as_sauthors=%20%22JM%20Cancienne%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Duey AH</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> White CA</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Levy KH</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Li T</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Tang JE</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Patel AV</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Kim JS</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Cho SK</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Cagle PJ</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Diabetes increases risk for
                  readmission and infection after shoulder arthroplasty: a national readmissions study of 113,713
                  patients</span>. <span data-id="emphasis_30" class="annotation emphasis" style="position: unset;">J
                  Orthop.</span> 2023 Mar 4;38:25-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diabetes%20increases%20risk%20for%20readmission%20and%20infection%20after%20shoulder%20arthroplasty%3A%20a%20national%20readmissions%20study%20of%20113%2C713%20patients&as_occt=title&as_sauthors=%20%22AH%20Duey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Hatta T</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Werthel JD</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Wagner ER</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Itoi E</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Steinmann SP</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Cofield RH</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Sperling JW</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Effect of smoking on
                  complications following primary shoulder arthroplasty</span>. <span data-id="emphasis_31"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2017
                Jan;26(1):1-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20smoking%20on%20complications%20following%20primary%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22T%20Hatta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Austin DC</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Townsley SH</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Rogers TH</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Barlow JD</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Morrey ME</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Sperling JW</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Sanchez-Sotelo J</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Shoulder periprosthetic joint
                  infection and all-cause mortality: a worrisome association</span>. <span data-id="emphasis_32"
                  class="annotation emphasis" style="position: unset;">JB JS Open Access.</span> 2022 Feb
                23;7(1):e21.00118.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Shoulder%20periprosthetic%20joint%20infection%20and%20all-cause%20mortality%3A%20a%20worrisome%20association&as_occt=title&as_sauthors=%20%22DC%20Austin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Moore NFE</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Batten TJ</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Hutton CEJ</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> White WJ</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Smith CD</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">The management of the shoulder
                  skin microbiome (<span data-id="emphasis_34" class="annotation emphasis"
                    style="position: unset;">Cutibacterium acnes</span>) in the context of shoulder surgery: a review of
                  the current literature</span>. <span data-id="emphasis_35" class="annotation emphasis"
                  style="position: unset;">Shoulder Elbow.</span> 2021 Oct;13(6):592-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20management%20of%20the%20shoulder%20skin%20microbiome%20(Cutibacterium%20acnes)%20in%20the%20context%20of%20shoulder%20surgery%3A%20a%20review%20of%20the%20current%20literature&as_occt=title&as_sauthors=%20%22NFE%20Moore%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Grice EA</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Segre JA</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">The skin microbiome</span>.
                <span data-id="emphasis_36" class="annotation emphasis" style="position: unset;">Nat Rev
                  Microbiol.</span> 2011 Apr;9(4):244-53.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20skin%20microbiome&as_occt=title&as_sauthors=%20%22EA%20Grice%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Hsu JE</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Whitson AJ</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Van Dyke R</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Wu JC</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Matsen FA 3rd</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Long DR</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Dynamics of Cutibacterium
                  repopulation onto the skin surface of the shoulder after chlorhexidine application</span>. <span
                  data-id="emphasis_37" class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2023
                Jun;47(6):1511-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dynamics%20of%20Cutibacterium%20repopulation%20onto%20the%20skin%20surface%20of%20the%20shoulder%20after%20chlorhexidine%20application&as_occt=title&as_sauthors=%20%22JE%20Hsu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Symonds T</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Grant A</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Doma K</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Hinton D</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Wilkinson M</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Morse L</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">The efficacy of topical
                  preparations in reducing the incidence of Cutibacterium acnes at the start and conclusion of total
                  shoulder arthroplasty: a randomized controlled trial</span>. <span data-id="emphasis_38"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2022
                Jun;31(6):1115-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20efficacy%20of%20topical%20preparations%20in%20reducing%20the%20incidence%20of%20Cutibacterium%20acnes%20at%20the%20start%20and%20conclusion%20of%20total%20shoulder%20arthroplasty%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22T%20Symonds%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Kolakowski L</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Lai JK</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Duvall GT</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Jauregui JJ</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Dubina AG</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Jones DL</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Williams KM</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Hasan SA</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Henn RF 3rd</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Gilotra MN</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Neer Award 2018: Benzoyl
                  peroxide effectively decreases preoperative Cutibacterium acnes shoulder burden: a prospective
                  randomized controlled trial</span>. <span data-id="emphasis_39" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2018 Sep;27(9):1539-44.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Neer%20Award%202018%3A%20Benzoyl%20peroxide%20effectively%20decreases%20preoperative%20Cutibacterium%20acnes%20shoulder%20burden%3A%20a%20prospective%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22L%20Kolakowski%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Scheer VM</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Bergman Jungeström M</span>,
                <span data-id="annotation_250" class="annotation" style="position: unset;"> Lerm M</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Serrander L</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Kalén A</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Topical benzoyl peroxide
                  application on the shoulder reduces Propionibacterium acnes: a randomized study</span>. <span
                  data-id="emphasis_40" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2018 Jun;27(6):957-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Topical%20benzoyl%20peroxide%20application%20on%20the%20shoulder%20reduces%20Propionibacterium%20acnes%3A%20a%20randomized%20study&as_occt=title&as_sauthors=%20%22VM%20Scheer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Grewal G</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Polisetty T</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Boltuch A</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Colley R</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Tapia R</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Levy JC</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Does application of hydrogen
                  peroxide to the dermis reduce incidence of Cutibacterium acnes during shoulder arthroplasty: a
                  randomized controlled trial</span>. <span data-id="emphasis_41" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2021 Aug;30(8):1827-33.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20application%20of%20hydrogen%20peroxide%20to%20the%20dermis%20reduce%20incidence%20of%20Cutibacterium%20acnes%20during%20shoulder%20arthroplasty%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22G%20Grewal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Chalmers PN</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Beck L</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Stertz I</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Tashjian RZ</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Hydrogen peroxide skin
                  preparation reduces Cutibacterium acnes in shoulder arthroplasty: a prospective, blinded, controlled
                  trial</span>. <span data-id="emphasis_42" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2019 Aug;28(8):1554-61.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hydrogen%20peroxide%20skin%20preparation%20reduces%20Cutibacterium%20acnes%20in%20shoulder%20arthroplasty%3A%20a%20prospective%2C%20blinded%2C%20controlled%20trial&as_occt=title&as_sauthors=%20%22PN%20Chalmers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Garrigues GE</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Zmistowski B</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Cooper AM</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Green A</span>; ICM Shoulder
                Group. <span data-id="strong_42" class="annotation strong" style="position: unset;">Proceedings from the
                  2018 International Consensus Meeting on Orthopedic Infections: Management of periprosthetic shoulder
                  infection</span>. <span data-id="emphasis_43" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2019 Jun;28(6S):S67-99.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Proceedings%20from%20the%202018%20International%20Consensus%20Meeting%20on%20Orthopedic%20Infections%3A%20Management%20of%20periprosthetic%20shoulder%20infection&as_occt=title&as_sauthors=%20%22GE%20Garrigues%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Saltzman MD</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Nuber GW</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Gryzlo SM</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Marecek GS</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Koh JL</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Efficacy of surgical
                  preparation solutions in shoulder surgery</span>. <span data-id="emphasis_44"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2009
                Aug;91(8):1949-53.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1062586"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Marigi EM</span>, <span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Bartels DW</span>, <span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Yoon JH</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Sperling JW</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Sanchez-Sotelo J</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Antibiotic prophylaxis with
                  cefazolin is associated with lower shoulder periprosthetic joint infection rates than non-cefazolin
                  alternatives</span>. <span data-id="emphasis_45" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2022 May 18;104(10):872-80.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=3213086" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Koh CK</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Marsh JP</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Drinković D</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Walker CG</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Poon PC</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">Propionibacterium acnes in
                  primary shoulder arthroplasty: rates of colonization, patient risk factors, and efficacy of
                  perioperative prophylaxis</span>. <span data-id="emphasis_46" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2016 May;25(5):846-52.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Propionibacterium%20acnes%20in%20primary%20shoulder%20arthroplasty%3A%20rates%20of%20colonization%2C%20patient%20risk%20factors%2C%20and%20efficacy%20of%20perioperative%20prophylaxis&as_occt=title&as_sauthors=%20%22CK%20Koh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Longo UG</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Candela V</span>, <span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Facchinetti G</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Marchetti A</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Dsoke S</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Mazzella C</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Risi Ambrogioni L</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> De Marinis MG</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Denaro V</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Antibiotic prophylaxis in
                  primary and revision shoulder replacement: a systematic review</span>. <span data-id="emphasis_47"
                  class="annotation emphasis" style="position: unset;">BMC Musculoskelet Disord.</span> 2020 May
                11;21(1):292.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Antibiotic%20prophylaxis%20in%20primary%20and%20revision%20shoulder%20replacement%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22UG%20Longo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Branch-Elliman W</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Ripollone JE</span>, <span
                  data-id="annotation_293" class="annotation" style="position: unset;"> O’Brien WJ</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Itani KMF</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Schweizer ML</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Perencevich E</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Strymish J</span>, <span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Gupta K</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Risk of surgical site
                  infection, acute kidney injury, and Clostridium difficile infection following antibiotic prophylaxis
                  with vancomycin plus a beta-lactam versus either drug alone: a national propensity-score-adjusted
                  retrospective cohort study</span>. <span data-id="emphasis_48" class="annotation emphasis"
                  style="position: unset;">PLoS Med.</span> 2017 Jul 10;14(7):e1002340.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20of%20surgical%20site%20infection%2C%20acute%20kidney%20injury%2C%20and%20Clostridium%20difficile%20infection%20following%20antibiotic%20prophylaxis%20with%20vancomycin%20plus%20a%20beta-lactam%20versus%20either%20drug%20alone%3A%20a%20national%20propensity-score-adjusted%20retrospective%20cohort%20study&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Yian EH</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Chan PH</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Burfeind W</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Navarro RA</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Singh A</span>, <span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Dillon MT</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">Perioperative clindamycin use
                  in penicillin allergic patients is associated with a higher risk of infection after shoulder
                  arthroplasty</span>. <span data-id="emphasis_49" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2020 Mar 15;28(6):e270-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Perioperative%20clindamycin%20use%20in%20penicillin%20allergic%20patients%20is%20associated%20with%20a%20higher%20risk%20of%20infection%20after%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22EH%20Yian%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Huang TB</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Pena Diaz AM</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Faber KJ</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Athwal GS</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Woolman M</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Nygard K</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Keenliside L</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> O’Gorman DB</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">Development of a
                  three-dimensional bioartificial shoulder joint implant mimetic of periprosthetic joint
                  infection</span>. <span data-id="emphasis_50" class="annotation emphasis"
                  style="position: unset;">Tissue Eng Part A.</span> 2022 Feb;28(3-4):175-83.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20of%20a%20three-dimensional%20bioartificial%20shoulder%20joint%20implant%20mimetic%20of%20periprosthetic%20joint%20infection&as_occt=title&as_sauthors=%20%22TB%20Huang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Wright TE</span>, <span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Boyle KK</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Duquin TR</span>, <span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Crane JK</span>. <span
                  data-id="emphasis_52" class="annotation emphasis" style="position: unset;"><span data-id="strong_50"
                    class="annotation strong" style="position: unset;">Propionibacterium acnes</span></span><span
                  data-id="strong_50" class="annotation strong" style="position: unset;"> susceptibility and correlation
                  with hemolytic phenotype</span>. <span data-id="emphasis_53" class="annotation emphasis"
                  style="position: unset;">Infect Dis (Auckl).</span> 2016 Oct 9;9:39-44.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Propionibacterium%20acnes%20susceptibility%20and%20correlation%20with%20hemolytic%20phenotype&as_occt=title&as_sauthors=%20%22TE%20Wright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Boyle KK</span>, <span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Nodzo SR</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Wright TE</span>, <span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Crane JK</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Duquin TR</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">Hemolysis is a diagnostic
                  adjuvant for Propionibacterium acnes orthopaedic shoulder infections</span>. <span
                  data-id="emphasis_54" class="annotation emphasis" style="position: unset;">J Am Acad Orthop
                  Surg.</span> 2019 Feb 15;27(4):136-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hemolysis%20is%20a%20diagnostic%20adjuvant%20for%20Propionibacterium%20acnes%20orthopaedic%20shoulder%20infections&as_occt=title&as_sauthors=%20%22KK%20Boyle%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_322" class="annotation" style="position: unset;"> Yazdi H</span>, <span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Klement MR</span>, <span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Hammad M</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Inoue D</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Xu C</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Goswami K</span>, <span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Parvizi J</span>. <span
                  data-id="strong_52" class="annotation strong" style="position: unset;">Tranexamic acid is associated
                  with reduced periprosthetic joint infection after primary total joint arthroplasty</span>. <span
                  data-id="emphasis_55" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2020
                Mar;35(3):840-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tranexamic%20acid%20is%20associated%20with%20reduced%20periprosthetic%20joint%20infection%20after%20primary%20total%20joint%20arthroplasty&as_occt=title&as_sauthors=%20%22H%20Yazdi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Cunningham G</span>, <span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Hughes J</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Borner B</span>, <span
                  data-id="annotation_332" class="annotation" style="position: unset;"> Mattern O</span>, <span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Taha ME</span>, <span
                  data-id="annotation_334" class="annotation" style="position: unset;"> Smith MM</span>, <span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Young AA</span>, <span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Cass B</span>. <span
                  data-id="strong_53" class="annotation strong" style="position: unset;">A single dose of tranexamic
                  acid reduces blood loss after reverse and anatomic shoulder arthroplasty: a randomized controlled
                  trial</span>. <span data-id="emphasis_56" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2021 Jul;30(7):1553-60.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20single%20dose%20of%20tranexamic%20acid%20reduces%20blood%20loss%20after%20reverse%20and%20anatomic%20shoulder%20arthroplasty%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22G%20Cunningham%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_54" style="position: unset;">
            <div class="content" style="position: unset;">54&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Cheung EV</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> Sperling JW</span>, <span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Cofield RH</span>. <span
                  data-id="strong_54" class="annotation strong" style="position: unset;">Infection associated with
                  hematoma formation after shoulder arthroplasty</span>. <span data-id="emphasis_57"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2008
                Jun;466(6):1363-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Infection%20associated%20with%20hematoma%20formation%20after%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22EV%20Cheung%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_55" style="position: unset;">
            <div class="content" style="position: unset;">55&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_340" class="annotation" style="position: unset;"> Pearson ZC</span>, <span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Agarwal AR</span>, <span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Elnemer W</span>, <span
                  data-id="annotation_343" class="annotation" style="position: unset;"> Thomas J</span>, <span
                  data-id="annotation_344" class="annotation" style="position: unset;"> Fox H</span>, <span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Ahiarakwe U</span>, <span
                  data-id="annotation_346" class="annotation" style="position: unset;"> Best MJ</span>, <span
                  data-id="annotation_347" class="annotation" style="position: unset;"> Srikumaran U</span>. <span
                  data-id="strong_55" class="annotation strong" style="position: unset;">The cost effectiveness of
                  tranexamic acid for preventing periprosthetic joint infection following total shoulder arthroplasty: a
                  break-even analysis</span>. <span data-id="emphasis_58" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2023 Jun;32(6):1159-64.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20cost%20effectiveness%20of%20tranexamic%20acid%20for%20preventing%20periprosthetic%20joint%20infection%20following%20total%20shoulder%20arthroplasty%3A%20a%20break-even%20analysis&as_occt=title&as_sauthors=%20%22ZC%20Pearson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_56" style="position: unset;">
            <div class="content" style="position: unset;">56&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_348" class="annotation" style="position: unset;"> Parvizi J</span>, <span
                  data-id="annotation_349" class="annotation" style="position: unset;"> Adeli B</span>, <span
                  data-id="annotation_350" class="annotation" style="position: unset;"> Zmistowski B</span>, <span
                  data-id="annotation_351" class="annotation" style="position: unset;"> Restrepo C</span>, <span
                  data-id="annotation_352" class="annotation" style="position: unset;"> Greenwald AS</span>. <span
                  data-id="strong_56" class="annotation strong" style="position: unset;">Management of periprosthetic
                  joint infection: the current knowledge: AAOS exhibit selection</span>. <span data-id="emphasis_59"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2012 Jul
                18;94(14):e104.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1150018"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_57" style="position: unset;">
            <div class="content" style="position: unset;">57&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_353" class="annotation" style="position: unset;"> Topolski MS</span>, <span
                  data-id="annotation_354" class="annotation" style="position: unset;"> Chin PY</span>, <span
                  data-id="annotation_355" class="annotation" style="position: unset;"> Sperling JW</span>, <span
                  data-id="annotation_356" class="annotation" style="position: unset;"> Cofield RH</span>. <span
                  data-id="strong_57" class="annotation strong" style="position: unset;">Revision shoulder arthroplasty
                  with positive intraoperative cultures: the value of preoperative studies and intraoperative
                  histology</span>. <span data-id="emphasis_60" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2006 Jul-Aug;15(4):402-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Revision%20shoulder%20arthroplasty%20with%20positive%20intraoperative%20cultures%3A%20the%20value%20of%20preoperative%20studies%20and%20intraoperative%20histology&as_occt=title&as_sauthors=%20%22MS%20Topolski%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_58" style="position: unset;">
            <div class="content" style="position: unset;">58&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_357" class="annotation" style="position: unset;"> Foruria AM</span>, <span
                  data-id="annotation_358" class="annotation" style="position: unset;"> Fox TJ</span>, <span
                  data-id="annotation_359" class="annotation" style="position: unset;"> Sperling JW</span>, <span
                  data-id="annotation_360" class="annotation" style="position: unset;"> Cofield RH</span>. <span
                  data-id="strong_58" class="annotation strong" style="position: unset;">Clinical meaning of unexpected
                  positive cultures (UPC) in revision shoulder arthroplasty</span>. <span data-id="emphasis_61"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2013
                May;22(5):620-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20meaning%20of%20unexpected%20positive%20cultures%20(UPC)%20in%20revision%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22AM%20Foruria%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_59" style="position: unset;">
            <div class="content" style="position: unset;">59&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_361" class="annotation" style="position: unset;"> Ko J-WK</span>, <span
                  data-id="annotation_362" class="annotation" style="position: unset;"> Namdari S</span>. <span
                  data-id="strong_59" class="annotation strong" style="position: unset;">The diagnosis and management of
                  periprosthetic joint infections of the shoulder</span>. <span data-id="emphasis_62"
                  class="annotation emphasis" style="position: unset;">Oper Tech Orthop.</span>
                2016;26(1):53-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20diagnosis%20and%20management%20of%20periprosthetic%20joint%20infections%20of%20the%20shoulder&as_occt=title&as_sauthors=%20%22J-WK%20Ko%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_60" style="position: unset;">
            <div class="content" style="position: unset;">60&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_363" class="annotation" style="position: unset;"> Mook WR</span>, <span
                  data-id="annotation_364" class="annotation" style="position: unset;"> Garrigues GE</span>. <span
                  data-id="strong_60" class="annotation strong" style="position: unset;">Diagnosis and management of
                  periprosthetic shoulder infections</span>. <span data-id="emphasis_63" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2014 Jun 4;96(11):956-65.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1194722" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_61" style="position: unset;">
            <div class="content" style="position: unset;">61&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_365" class="annotation" style="position: unset;"> Kadler BK</span>, <span
                  data-id="annotation_366" class="annotation" style="position: unset;"> Mehta SS</span>, <span
                  data-id="annotation_367" class="annotation" style="position: unset;"> Funk L</span>. <span
                  data-id="strong_61" class="annotation strong" style="position: unset;">Propionibacterium acnes
                  infection after shoulder surgery</span>. <span data-id="emphasis_64" class="annotation emphasis"
                  style="position: unset;">Int J Shoulder Surg.</span> 2015 Oct-Dec;9(4):139-44.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Propionibacterium%20acnes%20infection%20after%20shoulder%20surgery&as_occt=title&as_sauthors=%20%22BK%20Kadler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_62" style="position: unset;">
            <div class="content" style="position: unset;">62&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_368" class="annotation" style="position: unset;"> Karimi A</span>, <span
                  data-id="annotation_369" class="annotation" style="position: unset;"> Jalalpour P</span>, <span
                  data-id="annotation_370" class="annotation" style="position: unset;"> Reddy RP</span>, <span
                  data-id="annotation_371" class="annotation" style="position: unset;"> Sabzevari S</span>, <span
                  data-id="annotation_372" class="annotation" style="position: unset;"> Keeling LE</span>, <span
                  data-id="annotation_373" class="annotation" style="position: unset;"> Lin A</span>. <span
                  data-id="strong_62" class="annotation strong" style="position: unset;">Diagnostic arthroscopy for
                  periprosthetic shoulder arthroplasty infections: a systematic review and meta-analysis</span>. <span
                  data-id="emphasis_65" class="annotation emphasis" style="position: unset;">JSES Int.</span> 2023 May
                26;7(5):835-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagnostic%20arthroscopy%20for%20periprosthetic%20shoulder%20arthroplasty%20infections%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22A%20Karimi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_63" style="position: unset;">
            <div class="content" style="position: unset;">63&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_374" class="annotation" style="position: unset;"> Paxton ES</span>, <span
                  data-id="annotation_375" class="annotation" style="position: unset;"> Green A</span>, <span
                  data-id="annotation_376" class="annotation" style="position: unset;"> Krueger VS</span>. <span
                  data-id="strong_63" class="annotation strong" style="position: unset;">Periprosthetic infections of
                  the shoulder: diagnosis and management</span>. <span data-id="emphasis_66" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2019 Nov 1;27(21):e935-44.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Periprosthetic%20infections%20of%20the%20shoulder%3A%20diagnosis%20and%20management&as_occt=title&as_sauthors=%20%22ES%20Paxton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_64" style="position: unset;">
            <div class="content" style="position: unset;">64&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_377" class="annotation" style="position: unset;"> Villacis D</span>, <span
                  data-id="annotation_378" class="annotation" style="position: unset;"> Merriman JA</span>, <span
                  data-id="annotation_379" class="annotation" style="position: unset;"> Yalamanchili R</span>, <span
                  data-id="annotation_380" class="annotation" style="position: unset;"> Omid R</span>, <span
                  data-id="annotation_381" class="annotation" style="position: unset;"> Itamura J</span>, <span
                  data-id="annotation_382" class="annotation" style="position: unset;"> Rick Hatch GF 3rd</span>. <span
                  data-id="strong_64" class="annotation strong" style="position: unset;">Serum interleukin-6 as a marker
                  of periprosthetic shoulder infection</span>. <span data-id="emphasis_67" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2014 Jan 1;96(1):41-5.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1180056" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_65" style="position: unset;">
            <div class="content" style="position: unset;">65&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_383" class="annotation" style="position: unset;"> Dilisio MF</span>, <span
                  data-id="annotation_384" class="annotation" style="position: unset;"> Miller LR</span>, <span
                  data-id="annotation_385" class="annotation" style="position: unset;"> Warner JJ</span>, <span
                  data-id="annotation_386" class="annotation" style="position: unset;"> Higgins LD</span>. <span
                  data-id="strong_65" class="annotation strong" style="position: unset;">Arthroscopic tissue culture for
                  the evaluation of periprosthetic shoulder infection</span>. <span data-id="emphasis_68"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2014 Dec
                3;96(23):1952-8.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1200762"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_66" style="position: unset;">
            <div class="content" style="position: unset;">66&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_387" class="annotation" style="position: unset;"> Frangiamore SJ</span>, <span
                  data-id="annotation_388" class="annotation" style="position: unset;"> Gajewski N</span>, <span
                  data-id="annotation_389" class="annotation" style="position: unset;"> Iannotti JP</span>, <span
                  data-id="annotation_390" class="annotation" style="position: unset;"> Ricchetti ET</span>. <span
                  data-id="strong_66" class="annotation strong" style="position: unset;">Efficacy of preoperative
                  aspirates in periprosthetic joint infections of the shoulder</span>. <span data-id="emphasis_69"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span>
                2017;26(5):e144.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20of%20preoperative%20aspirates%20in%20periprosthetic%20joint%20infections%20of%20the%20shoulder&as_occt=title&as_sauthors=%20%22SJ%20Frangiamore%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_67" style="position: unset;">
            <div class="content" style="position: unset;">67&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_391" class="annotation" style="position: unset;"> Hecker A</span>, <span
                  data-id="annotation_392" class="annotation" style="position: unset;"> Jungwirth-Weinberger A</span>,
                <span data-id="annotation_393" class="annotation" style="position: unset;"> Bauer MR</span>, <span
                  data-id="annotation_394" class="annotation" style="position: unset;"> Tondelli T</span>, <span
                  data-id="annotation_395" class="annotation" style="position: unset;"> Uçkay I</span>, <span
                  data-id="annotation_396" class="annotation" style="position: unset;"> Wieser K</span>. <span
                  data-id="strong_67" class="annotation strong" style="position: unset;">The accuracy of joint
                  aspiration for the diagnosis of shoulder infections</span>. <span data-id="emphasis_70"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2020
                Mar;29(3):516-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20accuracy%20of%20joint%20aspiration%20for%20the%20diagnosis%20of%20shoulder%20infections&as_occt=title&as_sauthors=%20%22A%20Hecker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_68" style="position: unset;">
            <div class="content" style="position: unset;">68&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_397" class="annotation" style="position: unset;"> Fink B</span>, <span
                  data-id="annotation_398" class="annotation" style="position: unset;"> Sevelda F</span>. <span
                  data-id="strong_68" class="annotation strong" style="position: unset;">Periprosthetic joint infection
                  of shoulder arthroplasties: diagnostic and treatment options</span>. <span data-id="emphasis_71"
                  class="annotation emphasis" style="position: unset;">Biomed Res Int.</span>
                2017;2017:4582756.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Periprosthetic%20joint%20infection%20of%20shoulder%20arthroplasties%3A%20diagnostic%20and%20treatment%20options&as_occt=title&as_sauthors=%20%22B%20Fink%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_69" style="position: unset;">
            <div class="content" style="position: unset;">69&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_399" class="annotation" style="position: unset;"> Frangiamore SJ</span>, <span
                  data-id="annotation_400" class="annotation" style="position: unset;"> Saleh A</span>, <span
                  data-id="annotation_401" class="annotation" style="position: unset;"> Kovac MF</span>, <span
                  data-id="annotation_402" class="annotation" style="position: unset;"> Grosso MJ</span>, <span
                  data-id="annotation_403" class="annotation" style="position: unset;"> Zhang X</span>, <span
                  data-id="annotation_404" class="annotation" style="position: unset;"> Bauer TW</span>, <span
                  data-id="annotation_405" class="annotation" style="position: unset;"> Daly TM</span>, <span
                  data-id="annotation_406" class="annotation" style="position: unset;"> Ricchetti ET</span>, <span
                  data-id="annotation_407" class="annotation" style="position: unset;"> Iannotti JP</span>. <span
                  data-id="strong_69" class="annotation strong" style="position: unset;">Synovial fluid interleukin-6 as
                  a predictor of periprosthetic shoulder infection</span>. <span data-id="emphasis_72"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2015 Jan
                7;97(1):63-70.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1203593"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_70" style="position: unset;">
            <div class="content" style="position: unset;">70&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_408" class="annotation" style="position: unset;"> Frangiamore SJ</span>, <span
                  data-id="annotation_409" class="annotation" style="position: unset;"> Saleh A</span>, <span
                  data-id="annotation_410" class="annotation" style="position: unset;"> Grosso MJ</span>, <span
                  data-id="annotation_411" class="annotation" style="position: unset;"> Kovac MF</span>, <span
                  data-id="annotation_412" class="annotation" style="position: unset;"> Higuera CA</span>, <span
                  data-id="annotation_413" class="annotation" style="position: unset;"> Iannotti JP</span>, <span
                  data-id="annotation_414" class="annotation" style="position: unset;"> Ricchetti ET</span>. <span
                  data-id="strong_70" class="annotation strong" style="position: unset;">α-Defensin as a predictor of
                  periprosthetic shoulder infection</span>. <span data-id="emphasis_73" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2015 Jul;24(7):1021-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%CE%B1-Defensin%20as%20a%20predictor%20of%20periprosthetic%20shoulder%20infection&as_occt=title&as_sauthors=%20%22SJ%20Frangiamore%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_71" style="position: unset;">
            <div class="content" style="position: unset;">71&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_415" class="annotation" style="position: unset;"> Weigelt L</span>, <span
                  data-id="annotation_416" class="annotation" style="position: unset;"> Plate A</span>, <span
                  data-id="annotation_417" class="annotation" style="position: unset;"> Stadler L</span>, <span
                  data-id="annotation_418" class="annotation" style="position: unset;"> Sutter R</span>, <span
                  data-id="annotation_419" class="annotation" style="position: unset;"> Frustaci D</span>, <span
                  data-id="annotation_420" class="annotation" style="position: unset;"> Zbinden R</span>, <span
                  data-id="annotation_421" class="annotation" style="position: unset;"> Zingg PO</span>, <span
                  data-id="annotation_422" class="annotation" style="position: unset;"> Gerber C</span>, <span
                  data-id="annotation_423" class="annotation" style="position: unset;"> Achermann Y</span>. <span
                  data-id="strong_71" class="annotation strong" style="position: unset;">Alpha-defensin lateral flow
                  test does not appear to be useful in predicting shoulder periprosthetic joint infections</span>. <span
                  data-id="emphasis_74" class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2020
                Jun;44(6):1023-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Alpha-defensin%20lateral%20flow%20test%20does%20not%20appear%20to%20be%20useful%20in%20predicting%20shoulder%20periprosthetic%20joint%20infections&as_occt=title&as_sauthors=%20%22L%20Weigelt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_72" style="position: unset;">
            <div class="content" style="position: unset;">72&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_424" class="annotation" style="position: unset;"> Tat J</span>, <span
                  data-id="annotation_425" class="annotation" style="position: unset;"> Tat J</span>, <span
                  data-id="annotation_426" class="annotation" style="position: unset;"> Faber K</span>. <span
                  data-id="strong_72" class="annotation strong" style="position: unset;">Arthroscopic tissue biopsy as a
                  preoperative diagnostic test for periprosthetic shoulder arthroplasty infections: a systematic review
                  and meta-analysis</span>. <span data-id="emphasis_75" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2023 Jul;32(7):1545-54.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthroscopic%20tissue%20biopsy%20as%20a%20preoperative%20diagnostic%20test%20for%20periprosthetic%20shoulder%20arthroplasty%20infections%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22J%20Tat%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_73" style="position: unset;">
            <div class="content" style="position: unset;">73&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_427" class="annotation" style="position: unset;"> Mederake M</span>, <span
                  data-id="annotation_428" class="annotation" style="position: unset;"> Hofmann UK</span>, <span
                  data-id="annotation_429" class="annotation" style="position: unset;"> Fink B</span>. <span
                  data-id="strong_73" class="annotation strong" style="position: unset;">The significance of synovial
                  biopsy in the diagnostic workup of the low-grade periprosthetic joint infection of shoulder
                  arthroplasty</span>. <span data-id="emphasis_76" class="annotation emphasis"
                  style="position: unset;">Arch Orthop Trauma Surg.</span> 2022 Nov;142(11):3157-64.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20significance%20of%20synovial%20biopsy%20in%20the%20diagnostic%20workup%20of%20the%20low-grade%20periprosthetic%20joint%20infection%20of%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22M%20Mederake%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_74" style="position: unset;">
            <div class="content" style="position: unset;">74&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_430" class="annotation" style="position: unset;"> Grosso MJ</span>, <span
                  data-id="annotation_431" class="annotation" style="position: unset;"> Frangiamore SJ</span>, <span
                  data-id="annotation_432" class="annotation" style="position: unset;"> Ricchetti ET</span>, <span
                  data-id="annotation_433" class="annotation" style="position: unset;"> Bauer TW</span>, <span
                  data-id="annotation_434" class="annotation" style="position: unset;"> Iannotti JP</span>. <span
                  data-id="strong_74" class="annotation strong" style="position: unset;">Sensitivity of frozen section
                  histology for identifying Propionibacterium acnes infections in revision shoulder arthroplasty</span>.
                <span data-id="emphasis_77" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2014 Mar 19;96(6):442-7.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1186115" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_75" style="position: unset;">
            <div class="content" style="position: unset;">75&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_435" class="annotation" style="position: unset;"> Franceschini V</span>, <span
                  data-id="annotation_436" class="annotation" style="position: unset;"> Chillemi C</span>. <span
                  data-id="strong_75" class="annotation strong" style="position: unset;">Periprosthetic shoulder
                  infection</span>. <span data-id="emphasis_78" class="annotation emphasis"
                  style="position: unset;">Open Orthop J.</span> 2013 Jun 28;7:243-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Periprosthetic%20shoulder%20infection&as_occt=title&as_sauthors=%20%22V%20Franceschini%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_76" style="position: unset;">
            <div class="content" style="position: unset;">76&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_437" class="annotation" style="position: unset;"> Jerosch J</span>, <span
                  data-id="annotation_438" class="annotation" style="position: unset;"> Schneppenheim M</span>. <span
                  data-id="strong_76" class="annotation strong" style="position: unset;">Management of infected shoulder
                  replacement</span>. <span data-id="emphasis_79" class="annotation emphasis"
                  style="position: unset;">Arch Orthop Trauma Surg.</span> 2003 Jun;123(5):209-14.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20infected%20shoulder%20replacement&as_occt=title&as_sauthors=%20%22J%20Jerosch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_77" style="position: unset;">
            <div class="content" style="position: unset;">77&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_439" class="annotation" style="position: unset;"> McFarland EG</span>, <span
                  data-id="annotation_440" class="annotation" style="position: unset;"> Rojas J</span>, <span
                  data-id="annotation_441" class="annotation" style="position: unset;"> Smalley J</span>, <span
                  data-id="annotation_442" class="annotation" style="position: unset;"> Borade AU</span>, <span
                  data-id="annotation_443" class="annotation" style="position: unset;"> Joseph J</span>. <span
                  data-id="strong_77" class="annotation strong" style="position: unset;">Complications of antibiotic
                  cement spacers used for shoulder infections</span>. <span data-id="emphasis_80"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2018
                Nov;27(11):1996-2005.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Complications%20of%20antibiotic%20cement%20spacers%20used%20for%20shoulder%20infections&as_occt=title&as_sauthors=%20%22EG%20McFarland%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_78" style="position: unset;">
            <div class="content" style="position: unset;">78&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_444" class="annotation" style="position: unset;"> Pellegrini A</span>, <span
                  data-id="annotation_445" class="annotation" style="position: unset;"> Legnani C</span>, <span
                  data-id="annotation_446" class="annotation" style="position: unset;"> Macchi V</span>, <span
                  data-id="annotation_447" class="annotation" style="position: unset;"> Meani E</span>. <span
                  data-id="strong_78" class="annotation strong" style="position: unset;">Management of periprosthetic
                  shoulder infections with the use of a permanent articulating antibiotic spacer</span>. <span
                  data-id="emphasis_81" class="annotation emphasis" style="position: unset;">Arch Orthop Trauma
                  Surg.</span> 2018 May;138(5):605-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20periprosthetic%20shoulder%20infections%20with%20the%20use%20of%20a%20permanent%20articulating%20antibiotic%20spacer&as_occt=title&as_sauthors=%20%22A%20Pellegrini%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_79" style="position: unset;">
            <div class="content" style="position: unset;">79&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_448" class="annotation" style="position: unset;"> Jawa A</span>, <span
                  data-id="annotation_449" class="annotation" style="position: unset;"> Shi L</span>, <span
                  data-id="annotation_450" class="annotation" style="position: unset;"> O’Brien T</span>, <span
                  data-id="annotation_451" class="annotation" style="position: unset;"> Wells J</span>, <span
                  data-id="annotation_452" class="annotation" style="position: unset;"> Higgins L</span>, <span
                  data-id="annotation_453" class="annotation" style="position: unset;"> Macy J</span>, <span
                  data-id="annotation_454" class="annotation" style="position: unset;"> Warner JJ</span>. <span
                  data-id="strong_79" class="annotation strong" style="position: unset;">Prosthesis of antibiotic-loaded
                  acrylic cement (PROSTALAC) use for the treatment of infection after shoulder arthroplasty</span>.
                <span data-id="emphasis_82" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2011 Nov 2;93(21):2001-9.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1132387" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_80" style="position: unset;">
            <div class="content" style="position: unset;">80&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_455" class="annotation" style="position: unset;"> Tseng WJ</span>, <span
                  data-id="annotation_456" class="annotation" style="position: unset;"> Lansdown DA</span>, <span
                  data-id="annotation_457" class="annotation" style="position: unset;"> Grace T</span>, <span
                  data-id="annotation_458" class="annotation" style="position: unset;"> Zhang AL</span>, <span
                  data-id="annotation_459" class="annotation" style="position: unset;"> Feeley BT</span>, <span
                  data-id="annotation_460" class="annotation" style="position: unset;"> Hung LW</span>, <span
                  data-id="annotation_461" class="annotation" style="position: unset;"> Ma CB</span>. <span
                  data-id="strong_80" class="annotation strong" style="position: unset;">Outcomes of revision
                  arthroplasty for shoulder periprosthetic joint infection: a three-stage revision protocol</span>.
                <span data-id="emphasis_83" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2019 Feb;28(2):268-75.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20revision%20arthroplasty%20for%20shoulder%20periprosthetic%20joint%20infection%3A%20a%20three-stage%20revision%20protocol&as_occt=title&as_sauthors=%20%22WJ%20Tseng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_81" style="position: unset;">
            <div class="content" style="position: unset;">81&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_462" class="annotation" style="position: unset;"> Zhang AL</span>, <span
                  data-id="annotation_463" class="annotation" style="position: unset;"> Feeley BT</span>, <span
                  data-id="annotation_464" class="annotation" style="position: unset;"> Schwartz BS</span>, <span
                  data-id="annotation_465" class="annotation" style="position: unset;"> Chung TT</span>, <span
                  data-id="annotation_466" class="annotation" style="position: unset;"> Ma CB</span>. <span
                  data-id="strong_81" class="annotation strong" style="position: unset;">Management of deep
                  postoperative shoulder infections: is there a role for open biopsy during staged
                  treatment?</span><span data-id="emphasis_84" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2015 Jan;24(1):e15-20.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20deep%20postoperative%20shoulder%20infections%3A%20is%20there%20a%20role%20for%20open%20biopsy%20during%20staged%20treatment%3F&as_occt=title&as_sauthors=%20%22AL%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_82" style="position: unset;">
            <div class="content" style="position: unset;">82&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_467" class="annotation" style="position: unset;"> Hsu JE</span>, <span
                  data-id="annotation_468" class="annotation" style="position: unset;"> Somerson JS</span>, <span
                  data-id="annotation_469" class="annotation" style="position: unset;"> Vo KV</span>, <span
                  data-id="annotation_470" class="annotation" style="position: unset;"> Matsen FA 3rd</span>. <span
                  data-id="strong_82" class="annotation strong" style="position: unset;">What is a “periprosthetic
                  shoulder infection”? A systematic review of two decades of publications</span>. <span
                  data-id="emphasis_85" class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2017
                Apr;41(4):813-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20is%20a%20%E2%80%9Cperiprosthetic%20shoulder%20infection%E2%80%9D%3F%20A%20systematic%20review%20of%20two%20decades%20of%20publications&as_occt=title&as_sauthors=%20%22JE%20Hsu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_83" style="position: unset;">
            <div class="content" style="position: unset;">83&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_471" class="annotation" style="position: unset;"> Stoll K</span>, <span
                  data-id="annotation_472" class="annotation" style="position: unset;"> Alfonsi S 3rd</span>, <span
                  data-id="annotation_473" class="annotation" style="position: unset;"> Khan AZ</span>, <span
                  data-id="annotation_474" class="annotation" style="position: unset;"> Vaughan A</span>, <span
                  data-id="annotation_475" class="annotation" style="position: unset;"> Namdari S</span>. <span
                  data-id="strong_83" class="annotation strong" style="position: unset;">Preoperative prophylactic
                  antibiotics administration does not influence culture yield in revision shoulder arthroplasty</span>.
                <span data-id="emphasis_86" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2023 Nov;32(11):2366-70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preoperative%20prophylactic%20antibiotics%20administration%20does%20not%20influence%20culture%20yield%20in%20revision%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22K%20Stoll%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_84" style="position: unset;">
            <div class="content" style="position: unset;">84&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_476" class="annotation" style="position: unset;"> Bartsch A</span>, <span
                  data-id="annotation_477" class="annotation" style="position: unset;"> Krenn P</span>, <span
                  data-id="annotation_478" class="annotation" style="position: unset;"> Lubberts B</span>, <span
                  data-id="annotation_479" class="annotation" style="position: unset;"> Morgenstern M</span>, <span
                  data-id="annotation_480" class="annotation" style="position: unset;"> Pagenstert G</span>, <span
                  data-id="annotation_481" class="annotation" style="position: unset;"> Clauss M</span>. <span
                  data-id="strong_84" class="annotation strong" style="position: unset;">Management of acute
                  periprosthetic knee infection: a comparison of arthroscopic and open debridement</span>. <span
                  data-id="emphasis_87" class="annotation emphasis" style="position: unset;">Arch Orthop Trauma
                  Surg.</span> 2023 Jul;143(7):4309-16.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20acute%20periprosthetic%20knee%20infection%3A%20a%20comparison%20of%20arthroscopic%20and%20open%20debridement&as_occt=title&as_sauthors=%20%22A%20Bartsch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_85" style="position: unset;">
            <div class="content" style="position: unset;">85&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_482" class="annotation" style="position: unset;"> Le Vavasseur B</span>, <span
                  data-id="annotation_483" class="annotation" style="position: unset;"> Zeller V</span>. <span
                  data-id="strong_85" class="annotation strong" style="position: unset;">Antibiotic therapy for
                  prosthetic joint infections: an overview</span>. <span data-id="emphasis_88"
                  class="annotation emphasis" style="position: unset;">Antibiotics (Basel).</span> 2022 Apr
                5;11(4):486.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Antibiotic%20therapy%20for%20prosthetic%20joint%20infections%3A%20an%20overview&as_occt=title&as_sauthors=%20%22B%20Le%20Vavasseur%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_86" style="position: unset;">
            <div class="content" style="position: unset;">86&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_484" class="annotation" style="position: unset;"> Dennison T</span>, <span
                  data-id="annotation_485" class="annotation" style="position: unset;"> Alentorn-Geli E</span>, <span
                  data-id="annotation_486" class="annotation" style="position: unset;"> Assenmacher AT</span>, <span
                  data-id="annotation_487" class="annotation" style="position: unset;"> Sperling JW</span>, <span
                  data-id="annotation_488" class="annotation" style="position: unset;"> Sánchez-Sotelo J</span>, <span
                  data-id="annotation_489" class="annotation" style="position: unset;"> Cofield RH</span>. <span
                  data-id="strong_86" class="annotation strong" style="position: unset;">Management of acute or late
                  hematogenous infection after shoulder arthroplasty with irrigation, débridement, and component
                  retention</span>. <span data-id="emphasis_89" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2017 Jan;26(1):73-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20acute%20or%20late%20hematogenous%20infection%20after%20shoulder%20arthroplasty%20with%20irrigation%2C%20d%C3%A9bridement%2C%20and%20component%20retention&as_occt=title&as_sauthors=%20%22T%20Dennison%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_87" style="position: unset;">
            <div class="content" style="position: unset;">87&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_490" class="annotation" style="position: unset;"> Byren I</span>, <span
                  data-id="annotation_491" class="annotation" style="position: unset;"> Bejon P</span>, <span
                  data-id="annotation_492" class="annotation" style="position: unset;"> Atkins BL</span>, <span
                  data-id="annotation_493" class="annotation" style="position: unset;"> Angus B</span>, <span
                  data-id="annotation_494" class="annotation" style="position: unset;"> Masters S</span>, <span
                  data-id="annotation_495" class="annotation" style="position: unset;"> McLardy-Smith P</span>, <span
                  data-id="strong_87" class="annotation strong" style="position: unset;">One hundred and twelve infected
                  arthroplasties treated with 'DAIR' (debridement, antibiotics and implant retention): antibiotic
                  duration and outcome</span>. <span data-id="emphasis_90" class="annotation emphasis"
                  style="position: unset;">J Antimicrob Chemother.</span> 2009 Jun;63(6):1264-71.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=One%20hundred%20and%20twelve%20infected%20arthroplasties%20treated%20with%20'DAIR'%20(debridement%2C%20antibiotics%20and%20implant%20retention)%3A%20antibiotic%20duration%20and%20outcome&as_occt=title&as_sauthors=%20%22I%20Byren%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_88" style="position: unset;">
            <div class="content" style="position: unset;">88&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_496" class="annotation" style="position: unset;"> Jacquot A</span>, <span
                  data-id="annotation_497" class="annotation" style="position: unset;"> Sirveaux F</span>, <span
                  data-id="annotation_498" class="annotation" style="position: unset;"> Roche O</span>, <span
                  data-id="annotation_499" class="annotation" style="position: unset;"> Favard L</span>, <span
                  data-id="annotation_500" class="annotation" style="position: unset;"> Clavert P</span>, <span
                  data-id="annotation_501" class="annotation" style="position: unset;"> Molé D</span>. <span
                  data-id="strong_88" class="annotation strong" style="position: unset;">Surgical management of the
                  infected reversed shoulder arthroplasty: a French multicenter study of reoperation in 32
                  patients</span>. <span data-id="emphasis_91" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2015 Nov;24(11):1713-22.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20management%20of%20the%20infected%20reversed%20shoulder%20arthroplasty%3A%20a%20French%20multicenter%20study%20of%20reoperation%20in%2032%20patients&as_occt=title&as_sauthors=%20%22A%20Jacquot%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_89" style="position: unset;">
            <div class="content" style="position: unset;">89&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_502" class="annotation" style="position: unset;"> Ortmaier R</span>, <span
                  data-id="annotation_503" class="annotation" style="position: unset;"> Resch H</span>, <span
                  data-id="annotation_504" class="annotation" style="position: unset;"> Hitzl W</span>, <span
                  data-id="annotation_505" class="annotation" style="position: unset;"> Mayer M</span>, <span
                  data-id="annotation_506" class="annotation" style="position: unset;"> Stundner O</span>, <span
                  data-id="annotation_507" class="annotation" style="position: unset;"> Tauber M</span>. <span
                  data-id="strong_89" class="annotation strong" style="position: unset;">Treatment strategies for
                  infection after reverse shoulder arthroplasty</span>. <span data-id="emphasis_92"
                  class="annotation emphasis" style="position: unset;">Eur J Orthop Surg Traumatol.</span> 2014
                Jul;24(5):723-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treatment%20strategies%20for%20infection%20after%20reverse%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22R%20Ortmaier%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_90" style="position: unset;">
            <div class="content" style="position: unset;">90&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_508" class="annotation" style="position: unset;"> Zavala JA</span>, <span
                  data-id="annotation_509" class="annotation" style="position: unset;"> Clark JC</span>, <span
                  data-id="annotation_510" class="annotation" style="position: unset;"> Kissenberth MJ</span>, <span
                  data-id="annotation_511" class="annotation" style="position: unset;"> Tolan SJ</span>, <span
                  data-id="annotation_512" class="annotation" style="position: unset;"> Hawkins RJ</span>. <span
                  data-id="strong_90" class="annotation strong" style="position: unset;">Management of deep infection
                  after reverse total shoulder arthroplasty: a case series</span>. <span data-id="emphasis_93"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2012
                Oct;21(10):1310-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20deep%20infection%20after%20reverse%20total%20shoulder%20arthroplasty%3A%20a%20case%20series&as_occt=title&as_sauthors=%20%22JA%20Zavala%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_91" style="position: unset;">
            <div class="content" style="position: unset;">91&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_513" class="annotation" style="position: unset;"> Kew ME</span>, <span
                  data-id="annotation_514" class="annotation" style="position: unset;"> Mathew JI</span>, <span
                  data-id="annotation_515" class="annotation" style="position: unset;"> Wimberly AC</span>, <span
                  data-id="annotation_516" class="annotation" style="position: unset;"> Fu MC</span>, <span
                  data-id="annotation_517" class="annotation" style="position: unset;"> Taylor SA</span>, <span
                  data-id="annotation_518" class="annotation" style="position: unset;"> Blaine TA</span>, <span
                  data-id="annotation_519" class="annotation" style="position: unset;"> Carli AV</span>, <span
                  data-id="annotation_520" class="annotation" style="position: unset;"> Dines JS</span>, <span
                  data-id="annotation_521" class="annotation" style="position: unset;"> Dines DM</span>, <span
                  data-id="annotation_522" class="annotation" style="position: unset;"> Gulotta LV</span>. <span
                  data-id="strong_91" class="annotation strong" style="position: unset;">Outcomes after débridement,
                  antibiotics, and implant retention for prosthetic joint infection in shoulder arthroplasty</span>.
                <span data-id="emphasis_94" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2024 Feb;33(2):e68-78.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20after%20d%C3%A9bridement%2C%20antibiotics%2C%20and%20implant%20retention%20for%20prosthetic%20joint%20infection%20in%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22ME%20Kew%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_92" style="position: unset;">
            <div class="content" style="position: unset;">92&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_523" class="annotation" style="position: unset;"> Stone GP</span>, <span
                  data-id="annotation_524" class="annotation" style="position: unset;"> Clark RE</span>, <span
                  data-id="annotation_525" class="annotation" style="position: unset;"> O’Brien KC</span>, <span
                  data-id="annotation_526" class="annotation" style="position: unset;"> Vaccaro L</span>, <span
                  data-id="annotation_527" class="annotation" style="position: unset;"> Simon P</span>, <span
                  data-id="annotation_528" class="annotation" style="position: unset;"> Lorenzetti AJ</span>, <span
                  data-id="annotation_529" class="annotation" style="position: unset;"> Stephens BC</span>, <span
                  data-id="annotation_530" class="annotation" style="position: unset;"> Frankle MA</span>. <span
                  data-id="strong_92" class="annotation strong" style="position: unset;">Surgical management of
                  periprosthetic shoulder infections</span>. <span data-id="emphasis_95" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2017 Jul;26(7):1222-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20management%20of%20periprosthetic%20shoulder%20infections&as_occt=title&as_sauthors=%20%22GP%20Stone%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_93" style="position: unset;">
            <div class="content" style="position: unset;">93&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_531" class="annotation" style="position: unset;"> Sabesan VJ</span>, <span
                  data-id="annotation_532" class="annotation" style="position: unset;"> Ho JC</span>, <span
                  data-id="annotation_533" class="annotation" style="position: unset;"> Kovacevic D</span>, <span
                  data-id="annotation_534" class="annotation" style="position: unset;"> Iannotti JP</span>. <span
                  data-id="strong_93" class="annotation strong" style="position: unset;">Two-stage reimplantation for
                  treating prosthetic shoulder infections</span>. <span data-id="emphasis_96"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2011
                Sep;469(9):2538-43.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Two-stage%20reimplantation%20for%20treating%20prosthetic%20shoulder%20infections&as_occt=title&as_sauthors=%20%22VJ%20Sabesan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_94" style="position: unset;">
            <div class="content" style="position: unset;">94&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_535" class="annotation" style="position: unset;"> Grubhofer F</span>, <span
                  data-id="annotation_536" class="annotation" style="position: unset;"> Imam MA</span>, <span
                  data-id="annotation_537" class="annotation" style="position: unset;"> Wieser K</span>, <span
                  data-id="annotation_538" class="annotation" style="position: unset;"> Achermann Y</span>, <span
                  data-id="annotation_539" class="annotation" style="position: unset;"> Meyer DC</span>, <span
                  data-id="annotation_540" class="annotation" style="position: unset;"> Gerber C</span>. <span
                  data-id="strong_94" class="annotation strong" style="position: unset;">Staged revision with antibiotic
                  spacers for shoulder prosthetic joint infections yields high infection control</span>. <span
                  data-id="emphasis_97" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2018 Jan;476(1):146-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Staged%20revision%20with%20antibiotic%20spacers%20for%20shoulder%20prosthetic%20joint%20infections%20yields%20high%20infection%20control&as_occt=title&as_sauthors=%20%22F%20Grubhofer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_95" style="position: unset;">
            <div class="content" style="position: unset;">95&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_541" class="annotation" style="position: unset;"> Levy JC</span>, <span
                  data-id="annotation_542" class="annotation" style="position: unset;"> Triplet J</span>, <span
                  data-id="annotation_543" class="annotation" style="position: unset;"> Everding N</span>. <span
                  data-id="strong_95" class="annotation strong" style="position: unset;">Use of a functional antibiotic
                  spacer in treating infected shoulder arthroplasty</span>. <span data-id="emphasis_98"
                  class="annotation emphasis" style="position: unset;">Orthopedics.</span> 2015
                Jun;38(6):e512-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Use%20of%20a%20functional%20antibiotic%20spacer%20in%20treating%20infected%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22JC%20Levy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_96" style="position: unset;">
            <div class="content" style="position: unset;">96&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_544" class="annotation" style="position: unset;"> Strickland JP</span>, <span
                  data-id="annotation_545" class="annotation" style="position: unset;"> Sperling JW</span>, <span
                  data-id="annotation_546" class="annotation" style="position: unset;"> Cofield RH</span>. <span
                  data-id="strong_96" class="annotation strong" style="position: unset;">The results of two-stage
                  re-implantation for infected shoulder replacement</span>. <span data-id="emphasis_99"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 2008
                Apr;90(4):460-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20results%20of%20two-stage%20re-implantation%20for%20infected%20shoulder%20replacement&as_occt=title&as_sauthors=%20%22JP%20Strickland%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_97" style="position: unset;">
            <div class="content" style="position: unset;">97&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_547" class="annotation" style="position: unset;"> George DA</span>, <span
                  data-id="annotation_548" class="annotation" style="position: unset;"> Volpin A</span>, <span
                  data-id="annotation_549" class="annotation" style="position: unset;"> Scarponi S</span>, <span
                  data-id="annotation_550" class="annotation" style="position: unset;"> Haddad FS</span>, <span
                  data-id="annotation_551" class="annotation" style="position: unset;"> Romanò CL</span>. <span
                  data-id="strong_97" class="annotation strong" style="position: unset;">Does exchange arthroplasty of
                  an infected shoulder prosthesis provide better eradication rate and better functional outcome,
                  compared to a permanent spacer or resection arthroplasty? a systematic review</span>. <span
                  data-id="emphasis_100" class="annotation emphasis" style="position: unset;">BMC Musculoskelet
                  Disord.</span> 2016 Feb 1;17:52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20exchange%20arthroplasty%20of%20an%20infected%20shoulder%20prosthesis%20provide%20better%20eradication%20rate%20and%20better%20functional%20outcome%2C%20compared%20to%20a%20permanent%20spacer%20or%20resection%20arthroplasty%3F%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22DA%20George%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_98" style="position: unset;">
            <div class="content" style="position: unset;">98&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_552" class="annotation" style="position: unset;"> Belay ES</span>, <span
                  data-id="annotation_553" class="annotation" style="position: unset;"> Danilkowicz R</span>, <span
                  data-id="annotation_554" class="annotation" style="position: unset;"> Bullock G</span>, <span
                  data-id="annotation_555" class="annotation" style="position: unset;"> Wall K</span>, <span
                  data-id="annotation_556" class="annotation" style="position: unset;"> Garrigues GE</span>. <span
                  data-id="strong_98" class="annotation strong" style="position: unset;">Single-stage versus two-stage
                  revision for shoulder periprosthetic joint infection: a systematic review and meta-analysis</span>.
                <span data-id="emphasis_101" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2020 Dec;29(12):2476-86.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Single-stage%20versus%20two-stage%20revision%20for%20shoulder%20periprosthetic%20joint%20infection%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22ES%20Belay%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_99" style="position: unset;">
            <div class="content" style="position: unset;">99&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_557" class="annotation" style="position: unset;"> Fiore M</span>, <span
                  data-id="annotation_558" class="annotation" style="position: unset;"> Ferra L</span>, <span
                  data-id="annotation_559" class="annotation" style="position: unset;"> Giannini C</span>, <span
                  data-id="annotation_560" class="annotation" style="position: unset;"> Sambri A</span>, <span
                  data-id="annotation_561" class="annotation" style="position: unset;"> Filippini M</span>, <span
                  data-id="annotation_562" class="annotation" style="position: unset;"> Tedeschi S</span>, <span
                  data-id="annotation_563" class="annotation" style="position: unset;"> Zamparini E</span>, <span
                  data-id="annotation_564" class="annotation" style="position: unset;"> Viale P</span>, <span
                  data-id="annotation_565" class="annotation" style="position: unset;"> De Paolis M</span>, <span
                  data-id="annotation_566" class="annotation" style="position: unset;"> Guerra E</span>. <span
                  data-id="strong_99" class="annotation strong" style="position: unset;">Management of periprosthetic
                  joint infection of shoulder arthroplasty: single-stage versus two-stage protocols. A systematic review
                  and meta-analysis of the literature</span>. <span data-id="emphasis_102" class="annotation emphasis"
                  style="position: unset;">Shoulder Elbow.</span> 2023 Sep;15(1):25-40.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20periprosthetic%20joint%20infection%20of%20shoulder%20arthroplasty%3A%20single-stage%20versus%20two-stage%20protocols.%20A%20systematic%20review%20and%20meta-analysis%20of%20the%20literature&as_occt=title&as_sauthors=%20%22M%20Fiore%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_100" style="position: unset;">
            <div class="content" style="position: unset;">100&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_567" class="annotation" style="position: unset;"> Markes AR</span>, <span
                  data-id="annotation_568" class="annotation" style="position: unset;"> Cheung E</span>, <span
                  data-id="annotation_569" class="annotation" style="position: unset;"> Ma CB</span>. <span
                  data-id="strong_100" class="annotation strong" style="position: unset;">Failed reverse shoulder
                  arthroplasty and recommendations for revision</span>. <span data-id="emphasis_103"
                  class="annotation emphasis" style="position: unset;">Curr Rev Musculoskelet Med.</span> 2020
                Feb;13(1):1-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Failed%20reverse%20shoulder%20arthroplasty%20and%20recommendations%20for%20revision&as_occt=title&as_sauthors=%20%22AR%20Markes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_101" style="position: unset;">
            <div class="content" style="position: unset;">101&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_570" class="annotation" style="position: unset;"> Markes AR</span>, <span
                  data-id="annotation_571" class="annotation" style="position: unset;"> Bigham J</span>, <span
                  data-id="annotation_572" class="annotation" style="position: unset;"> Ma CB</span>, <span
                  data-id="annotation_573" class="annotation" style="position: unset;"> Iyengar JJ</span>, <span
                  data-id="annotation_574" class="annotation" style="position: unset;"> Feeley BT</span>. <span
                  data-id="strong_101" class="annotation strong" style="position: unset;">Preventing and treating
                  infection in reverse total shoulder arthroplasty</span>. <span data-id="emphasis_104"
                  class="annotation emphasis" style="position: unset;">Curr Rev Musculoskelet Med.</span> 2023
                Aug;16(8):371-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preventing%20and%20treating%20infection%20in%20reverse%20total%20shoulder%20arthroplasty&as_occt=title&as_sauthors=%20%22AR%20Markes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_102" style="position: unset;">
            <div class="content" style="position: unset;">102&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_575" class="annotation" style="position: unset;"> Namdari S</span>, <span
                  data-id="annotation_576" class="annotation" style="position: unset;"> Sudah SY</span>, <span
                  data-id="annotation_577" class="annotation" style="position: unset;"> Menendez ME</span>, <span
                  data-id="annotation_578" class="annotation" style="position: unset;"> Denard PJ</span>. <span
                  data-id="strong_102" class="annotation strong" style="position: unset;">Antibiotic spacers for
                  shoulder periprosthetic joint infection: a review</span>. <span data-id="emphasis_105"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2022 Oct
                1;30(19):917-24.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Antibiotic%20spacers%20for%20shoulder%20periprosthetic%20joint%20infection%3A%20a%20review&as_occt=title&as_sauthors=%20%22S%20Namdari%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_103" style="position: unset;">
            <div class="content" style="position: unset;">103&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_579" class="annotation" style="position: unset;"> Alessio-Mazzola M</span>, <span
                  data-id="annotation_580" class="annotation" style="position: unset;"> Repetto I</span>, <span
                  data-id="annotation_581" class="annotation" style="position: unset;"> Russo A</span>, <span
                  data-id="annotation_582" class="annotation" style="position: unset;"> Clemente A</span>, <span
                  data-id="annotation_583" class="annotation" style="position: unset;"> Ventura N</span>, <span
                  data-id="annotation_584" class="annotation" style="position: unset;"> Formica M</span>, <span
                  data-id="annotation_585" class="annotation" style="position: unset;"> Burastero G</span>, <span
                  data-id="annotation_586" class="annotation" style="position: unset;"> Felli L</span>. <span
                  data-id="strong_103" class="annotation strong" style="position: unset;">Permanent spacers are a
                  reliable solution for peri-prosthetic shoulder infection: a systematic review</span>. <span
                  data-id="emphasis_106" class="annotation emphasis" style="position: unset;">HSS J.</span> 2020
                Oct;16(3):272-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Permanent%20spacers%20are%20a%20reliable%20solution%20for%20peri-prosthetic%20shoulder%20infection%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22M%20Alessio-Mazzola%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_104" style="position: unset;">
            <div class="content" style="position: unset;">104&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_587" class="annotation" style="position: unset;"> Mahure SA</span>, <span
                  data-id="annotation_588" class="annotation" style="position: unset;"> Mollon B</span>, <span
                  data-id="annotation_589" class="annotation" style="position: unset;"> Yu S</span>, <span
                  data-id="annotation_590" class="annotation" style="position: unset;"> Kwon YW</span>, <span
                  data-id="annotation_591" class="annotation" style="position: unset;"> Zuckerman JD</span>. <span
                  data-id="strong_104" class="annotation strong" style="position: unset;">Definitive treatment of
                  infected shoulder arthroplasty with a cement spacer</span>. <span data-id="emphasis_107"
                  class="annotation emphasis" style="position: unset;">Orthopedics.</span> 2016 Sep
                1;39(5):e924-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Definitive%20treatment%20of%20infected%20shoulder%20arthroplasty%20with%20a%20cement%20spacer&as_occt=title&as_sauthors=%20%22SA%20Mahure%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_105" style="position: unset;">
            <div class="content" style="position: unset;">105&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_592" class="annotation" style="position: unset;"> Samelis PV</span>, <span
                  data-id="annotation_593" class="annotation" style="position: unset;"> Papagrigorakis E</span>, <span
                  data-id="annotation_594" class="annotation" style="position: unset;"> Sameli E</span>, <span
                  data-id="annotation_595" class="annotation" style="position: unset;"> Mavrogenis A</span>, <span
                  data-id="annotation_596" class="annotation" style="position: unset;"> Savvidou O</span>, <span
                  data-id="annotation_597" class="annotation" style="position: unset;"> Koulouvaris P</span>. <span
                  data-id="strong_105" class="annotation strong" style="position: unset;">Current concepts on the
                  application, pharmacokinetics and complications of antibiotic-loaded cement spacers in the treatment
                  of prosthetic joint infections</span>. <span data-id="emphasis_108" class="annotation emphasis"
                  style="position: unset;">Cureus.</span> 2022 Jan 5;14(1):e20968.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Current%20concepts%20on%20the%20application%2C%20pharmacokinetics%20and%20complications%20of%20antibiotic-loaded%20cement%20spacers%20in%20the%20treatment%20of%20prosthetic%20joint%20infections&as_occt=title&as_sauthors=%20%22PV%20Samelis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_106" style="position: unset;">
            <div class="content" style="position: unset;">106&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_598" class="annotation" style="position: unset;"> Shahpari O</span>, <span
                  data-id="annotation_599" class="annotation" style="position: unset;"> Mousavian A</span>, <span
                  data-id="annotation_600" class="annotation" style="position: unset;"> Elahpour N</span>, <span
                  data-id="annotation_601" class="annotation" style="position: unset;"> Malahias MA</span>, <span
                  data-id="annotation_602" class="annotation" style="position: unset;"> Ebrahimzadeh MH</span>, <span
                  data-id="annotation_603" class="annotation" style="position: unset;"> Moradi A</span>. <span
                  data-id="strong_106" class="annotation strong" style="position: unset;">The use of antibiotic
                  impregnated cement spacers in the treatment of infected total joint replacement: challenges and
                  achievements</span>. <span data-id="emphasis_109" class="annotation emphasis"
                  style="position: unset;">Arch Bone Joint Surg.</span> 2020 Jan;8(1):11-20.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20use%20of%20antibiotic%20impregnated%20cement%20spacers%20in%20the%20treatment%20of%20infected%20total%20joint%20replacement%3A%20challenges%20and%20achievements&as_occt=title&as_sauthors=%20%22O%20Shahpari%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_107" style="position: unset;">
            <div class="content" style="position: unset;">107&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_604" class="annotation" style="position: unset;"> Runner RP</span>, <span
                  data-id="annotation_605" class="annotation" style="position: unset;"> Mener A</span>, <span
                  data-id="annotation_606" class="annotation" style="position: unset;"> Bradbury TL</span>. <span
                  data-id="strong_107" class="annotation strong" style="position: unset;">Renal failure after placement
                  of an articulating, antibiotic impregnated polymethylmethacrlyate hip spacer</span>. <span
                  data-id="emphasis_110" class="annotation emphasis" style="position: unset;">Arthroplast Today.</span>
                2017 Mar 29;4(1):51-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Renal%20failure%20after%20placement%20of%20an%20articulating%2C%20antibiotic%20impregnated%20polymethylmethacrlyate%20hip%20spacer&as_occt=title&as_sauthors=%20%22RP%20Runner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_108" style="position: unset;">
            <div class="content" style="position: unset;">108&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_607" class="annotation" style="position: unset;"> Goodman SM</span>, <span
                  data-id="annotation_608" class="annotation" style="position: unset;"> Springer B</span>, <span
                  data-id="annotation_609" class="annotation" style="position: unset;"> Guyatt G</span>, <span
                  data-id="annotation_610" class="annotation" style="position: unset;"> Abdel MP</span>, <span
                  data-id="annotation_611" class="annotation" style="position: unset;"> Dasa V</span>, <span
                  data-id="annotation_612" class="annotation" style="position: unset;"> George M</span>, <span
                  data-id="annotation_613" class="annotation" style="position: unset;"> Gewurz-Singer O</span>, <span
                  data-id="annotation_614" class="annotation" style="position: unset;"> Giles JT</span>, <span
                  data-id="annotation_615" class="annotation" style="position: unset;"> Johnson B</span>, <span
                  data-id="annotation_616" class="annotation" style="position: unset;"> Lee S</span>, <span
                  data-id="annotation_617" class="annotation" style="position: unset;"> Mandl LA</span>, <span
                  data-id="annotation_618" class="annotation" style="position: unset;"> Mont MA</span>, <span
                  data-id="annotation_619" class="annotation" style="position: unset;"> Sculco P</span>, <span
                  data-id="annotation_620" class="annotation" style="position: unset;"> Sporer S</span>, <span
                  data-id="annotation_621" class="annotation" style="position: unset;"> Stryker L</span>, <span
                  data-id="annotation_622" class="annotation" style="position: unset;"> Turgunbaev M</span>, <span
                  data-id="annotation_623" class="annotation" style="position: unset;"> Brause B</span>, <span
                  data-id="annotation_624" class="annotation" style="position: unset;"> Chen AF</span>, <span
                  data-id="annotation_625" class="annotation" style="position: unset;"> Gilliland J</span>, <span
                  data-id="annotation_626" class="annotation" style="position: unset;"> Goodman M</span>, <span
                  data-id="annotation_627" class="annotation" style="position: unset;"> Hurley-Rosenblatt A</span>,
                <span data-id="annotation_628" class="annotation" style="position: unset;"> Kirou K</span>, <span
                  data-id="annotation_629" class="annotation" style="position: unset;"> Losina E</span>, <span
                  data-id="annotation_630" class="annotation" style="position: unset;"> MacKenzie R</span>, <span
                  data-id="annotation_631" class="annotation" style="position: unset;"> Michaud K</span>, <span
                  data-id="annotation_632" class="annotation" style="position: unset;"> Mikuls T</span>, <span
                  data-id="annotation_633" class="annotation" style="position: unset;"> Russell L</span>, <span
                  data-id="annotation_634" class="annotation" style="position: unset;"> Sah A</span>, <span
                  data-id="annotation_635" class="annotation" style="position: unset;"> Singh JA</span>, <span
                  data-id="annotation_636" class="annotation" style="position: unset;"> Yates A</span>. <span
                  data-id="strong_108" class="annotation strong" style="position: unset;">2017 American College of
                  Rheumatology/American Association of Hip and Knee Surgeons Guideline for the Perioperative Management
                  of Antirheumatic Medication in Patients with Rheumatic Diseases Undergoing Elective Total Hip or Total
                  Knee Arthroplasty</span>. <span data-id="emphasis_111" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2017 Sep;32(9):2628-38.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=2017%20American%20College%20of%20Rheumatology%2FAmerican%20Association%20of%20Hip%20and%20Knee%20Surgeons%20Guideline%20for%20the%20Perioperative%20Management%20of%20Antirheumatic%20Medication%20in%20Patients%20with%20Rheumatic%20Diseases%20Undergoing%20Elective%20Total%20Hip%20or%20Total%20Knee%20Arthroplasty&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_109" style="position: unset;">
            <div class="content" style="position: unset;">109&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_637" class="annotation" style="position: unset;"> Wright JG</span>. <span
                  data-id="strong_109" class="annotation strong" style="position: unset;">Revised grades of
                  recommendation for summaries or reviews of orthopaedic surgical studies</span>. <span
                  data-id="emphasis_112" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2006 May;88(5):1161-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Revised%20grades%20of%20recommendation%20for%20summaries%20or%20reviews%20of%20orthopaedic%20surgical%20studies&as_occt=title&as_sauthors=%20%22JG%20Wright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dc75feed3-ebc5-4157-835e-9d0cda7b800e%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_29" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Current Concepts Review</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 4, 2024;
                      106 (23): 2265</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01073</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 30, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_5" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_114"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            University of Pittsburgh Medical Center, Pittsburgh, Pennsylvania</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;c75feed3-ebc5-4157-835e-9d0cda7b800e&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=c75feed3-ebc5-4157-835e-9d0cda7b800e&type=pdf&name=JBJS.23.01073.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=c75feed3-ebc5-4157-835e-9d0cda7b800e&type=pdf&name=JBJS.23.01073.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_4" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_110"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_111" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I242" target="_blank" data-id="link_2"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I242</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;c75feed3-ebc5-4157-835e-9d0cda7b800e&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=c75feed3-ebc5-4157-835e-9d0cda7b800e&type=zip&name=JBJS.23.01073.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ehab M. Nazzal, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9852-8975" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9852-8975</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Zachary J. Herman, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9962-294X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9962-294X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Matthew Como, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1232-725X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1232-725X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Janina Kaarre, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2559-8283" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2559-8283</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Rajiv P. Reddy, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8862-1458" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8862-1458</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Eric R. Wagner, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9241-5702" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9241-5702</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Brian A. Klatt, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Albert Lin, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:Lina2@upmc.edu" class="" style="position: unset;">Lina2@upmc.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4204-3482" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4204-3482</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, University of Pittsburgh Medical Center,
                Pittsburgh, Pennsylvania</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Emory University, Atlanta, Georgia</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 50929px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
