export type SessionMeta = Record<
	string,
	{
		lastTime: number;
		numSuccessAttempts: number;
		numTotalAttempts: number;
	}
>;

export type Score = {
	value: number;
};
export * from "./question";
export * from "./common";
export * from "./report";
