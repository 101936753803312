import type { Article } from "../../../../../types";

export const Article541: Article = {
	id: 541,
	rsuiteId: "264b406d-a3d0-4fd3-95de-fa2f26d70db8",
	type: "the orthopaedic forum",
	title:
		"Overcoming the Odds: “Making It”—Personally and Professionally—in Orthopaedic Surgery Residency as an International Medical Graduate",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/541.png",
	subSpecialties: ["trauma"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Overcoming the Odds: “Making It”—Personally and
                Professionally—in Orthopaedic Surgery Residency as an International Medical Graduate</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Gergo
                      Merkely, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">As an international medical graduate (IMG), I feel deeply
                  fortunate to now be an orthopaedic surgery resident in the U.S., as the successful match rate for IMGs
                  is typically &lt;1%<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  Although I am now “living my dream” as a resident physician in the Harvard Combined Orthopaedic
                  Residency Program (HCORP), my journey has not been linear and I would like to share some of the “ups
                  and downs” that I have experienced as an IMG.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">My journey began in Hungary, where it was no surprise to
                  anyone that I, who had a physician for a grandfather and father and a dentist for a mother, decided to
                  pursue a career in health care. I had always been interested in orthopaedic surgery because I was
                  amazed by biology and mechanics and wanted to help patients using my hands.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Upon graduation from medical school at Semmelweis
                  University in Budapest, I started my orthopaedic residency in Budapest on a dedicated research
                  physician path, seeking to marry my love of research with high-quality patient care. However, after 2
                  years, my wife—a cardiology resident at the time—and I decided to take a personal and professional
                  risk and move to the U.S. to pursue our PhDs. Initially, our shared goal was to experience a new
                  culture and advance in our respective fields before returning to clinical practice in Hungary.
                  However, during our research experiences, we were deeply impressed by the quality of U.S. medical
                  education and the level of patient care. With remarkable mentorship and support throughout 5 years as
                  a clinical and basic-science researcher, I was thrilled to match as an orthopaedic surgery resident
                  physician in the HCORP in 2022. I am currently cheering on my wife as she interviews this year.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">Still, although I am profoundly grateful that I achieved
                  the “match” that is so rare for IMGs, and is denied to even 20% to 30% of U.S. medical school
                  graduates, the challenges facing an IMG are real and, without identifying them, it is hard to prepare
                  yourself or grow.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">First, there are the administrative challenges, with visa
                  issues being the most prominent. The J-1 (non-immigrant exchange visitor) visa allows IMGs to remain
                  in the U.S. for up to 7 years while they complete their training. However, it comes with a 2-year home
                  residency requirement, meaning that IMGs must return to their home country for at least 2 years after
                  it has expired before coming back to the U.S. or seeking another visa type, including a green card.
                  The H1-B visa, which I presently hold, is for temporary workers with professional-level degrees (e.g.,
                  MDs); it has no 2-year home residency requirement and allows up to a 6-year stay in the U.S. However,
                  because H-1B visas are costly for health-care systems, a limited number of residencies offer them.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Both J-1 and H-1B visas must be renewed annually,
                  conferring a substantial administrative burden on the IMG, faculty, and support staff. Also, to travel
                  or to obtain a U.S. ID, I must acquire a new visa stamp every year, which is possible only in my home
                  country after obtaining a visa appointment. Not only are visa appointments difficult to schedule, but
                  the entire process typically takes up to 2 weeks, which is longer than an average resident vacation.
                  Thus, I must request an emergency visa appointment, with no guarantee that it will be granted.
                  Additionally, throughout residency, IMGs who seek to stay in the U.S. are advised to apply for a green
                  card, which involves enormous paperwork and costs applicants up to $15,000—a large amount on a
                  resident salary.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">On a more personal level, living in the U.S. as an IMG, I
                  am sometimes deeply homesick. It is hard to describe, but in addition to missing your home county,
                  family, and friends, you also “miss” yourself—the person you are in your own language. I am Gergő in
                  Hungarian, and Gergo in English. People try their best to call me Gergő here, but with the
                  difficulties of the Hungarian language for non-native speakers, it is rarely accurate. Still, having 2
                  homes can be a positive. It is good to appreciate what you have now and what you used to have at the
                  same time. I’m proud to be both Gergő and Gergo.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Another challenge for an IMG is “imposter syndrome.” This
                  affects all residents, who often experience self-doubt and insecurity when transitioning from the
                  observational role of a medical student to the active one of an intern. However, these feelings are
                  amplified for IMGs, who have often spent months or years away from seeing patients while they pursued
                  research opportunities to increase their chances of matching. Also, what may be vast differences in
                  the culture and medical system of an IMG’s home country can increase the sense of being an “imposter”
                  impersonating an American doctor. Just imagine presenting at rounds and speaking to patients in your
                  non-native language with an accent. Yet this difficulty can also remind you to not take yourself too
                  seriously and to have some fun along the way. I’ll never forget the time that, completely exhausted
                  after a long stretch of overnight shifts, I had a whole conversation with my senior in Hungarian and
                  how hard we laughed when he admitted that, having not understood a word, he thought I was having a
                  stroke!</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">At my worst moments I can wonder whether I belong and fear
                  that someone smarter than me will realize that, as an immigrant from a small central European country
                  training in the U.S., I am in way over my head. Luckily, this fear is usually dispelled by close
                  co-residents and mentors who assure me that I do, in fact, belong and that others feel as I do—I am
                  not alone. I also remind myself that I deserve what I have achieved and I should not compare myself to
                  others. Orthopaedic surgery is a team sport, and we all bring different strengths and weaknesses.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">I have been fortunate to have wonderful mentors to guide
                  me in my journey from IMG to U.S. researcher to American orthopaedic resident. During my research
                  year, these mentors pushed me to not only build my investigative skills in the laboratory and clinic,
                  but also believe that more can be done to care for patients. Then, before I even started my residency,
                  I was thrilled to be assigned a mentor to guide me in the transition from researcher to clinician. I
                  have also greatly benefited from the personal and professional support from the countless attending
                  surgeons at the multiple academic medical centers included in my large program. Nothing brought home
                  the importance of my mentors and colleagues as much as the time when I unexpectedly had a serious
                  cardiac concern that required insertion of a stent into one of my coronary arteries. Immediately, my
                  program rallied around me, with my program director driving to my apartment on the day I received the
                  news and one of my chairs following up several times over the phone to make sure I was OK. Since then,
                  they have provided me with special support and attention, for which I will be eternally grateful.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">In closing, I want to state unequivocally that the
                  challenges that I have experienced as an IMG in the U.S. have been more than worth it. My wife and I
                  purposely chose this complex, often overwhelming path to be the best version of ourselves—personally
                  and professionally—and to work toward our ultimate goal of providing the highest-level patient care.
                  Each day, we try to embrace the challenge and be grateful for the adversity itself. There is something
                  beautiful in trying, working extremely hard, failing or succeeding, learning, and starting all over
                  again.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Reference</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;">The Match:
                National Resident Matching Program. <span data-id="strong_1" class="annotation strong"
                  style="position: unset;">Main Residency Match Data and Reports</span>. Accessed 2023 Mar 25. <a
                  href="http://www.nrmp.org/main-residency-match-data/" target="_blank" data-id="link_1" class="link"
                  style="position: unset;">http://www.nrmp.org/main-residency-match-data/</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Main%20Residency%20Match%20Data%20and%20Reports&as_occt=title&as_sauthors=%20%22The%20Match%3A%20National%20Resident%20Matching%20Program%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fhub.jbjs.org%2Freader.php%3Frsuite_id%3D264b406d-a3d0-4fd3-95de-fa2f26d70db8%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F20%2F1922%26topics%3Doe"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">October 16, 2024;
                      106 (20): 1922</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00147</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">May 2, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_1"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopaedic Surgery, Massachusetts General Hospital, Harvard Combined
                            Orthopaedic Residency Program, Boston</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;264b406d-a3d0-4fd3-95de-fa2f26d70db8&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;oe&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=264b406d-a3d0-4fd3-95de-fa2f26d70db8&type=pdf&name=JBJS.24.00147.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=264b406d-a3d0-4fd3-95de-fa2f26d70db8&type=pdf&name=JBJS.24.00147.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_2" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_3"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/H993" target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/H993</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;264b406d-a3d0-4fd3-95de-fa2f26d70db8&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;oe&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=264b406d-a3d0-4fd3-95de-fa2f26d70db8&type=zip&name=JBJS.24.00147.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Gergo Merkely, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:gmerkely@bwh.harvard.edu" class=""
                    style="position: unset;">gmerkely@bwh.harvard.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6660-6994" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6660-6994</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Massachusetts General Hospital, Harvard
                Combined Orthopaedic Residency Program, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 6717.38px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
