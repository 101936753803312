import type { Article } from "../../../../../types";

export const Article572: Article = {
	id: 572,
	rsuiteId: "bcc80f40-d5c4-4adc-bdf0-8f0f32f0e886",
	type: "scientific article",
	title:
		"Feasibility of the Non-Window-Type 3D-Printed Porous Titanium Cage in Posterior Lumbar Interbody Fusion",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=bcc80f40-d5c4-4adc-bdf0-8f0f32f0e886&type=jpeg&name=JBJS.23.01245f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Feasibility of the Non-Window-Type 3D-Printed Porous
                Titanium Cage in Posterior Lumbar Interbody Fusion</div>
              <div class="text subtitle" style="position: unset;">A Randomized Controlled Multicenter Trial</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Dae-Woong
                      Ham, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Sang-Min
                      Park, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_44" class="annotation strong"
                          style="position: unset;">Background:</span> Three-dimensionally printed titanium (3D-Ti) cages
                        can be divided into 2 types: window-type cages, which have a void for bone graft, and
                        non-window-type cages without a void. Few studies have investigated the necessity of a void for
                        bone graft in fusion surgery. Therefore, the present study assessed the clinical and
                        radiographic outcomes of window and non-window-type 3D-Ti cages in single-level posterior lumbar
                        interbody fusion.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_45" class="annotation strong"
                          style="position: unset;">Methods:</span> A total of 70 patients were randomly assigned to
                        receive either a window or non-window cage; 61 patients (87%) completed final follow-up (32 from
                        the window cage group, 29 from the non-window cage group). Radiographic outcomes, including
                        fusion rates, subsidence, and intra-cage osseointegration patterns, were assessed. Intra-cage
                        osseointegration was measured using the intra-cage bridging bone score for the window cage group
                        and the surface osseointegration ratio score for the non-window cage group. Additionally, we
                        looked for the presence of the trabecular bone remodeling (TBR) sign on computed tomography (CT)
                        images.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_46" class="annotation strong"
                          style="position: unset;">Results:</span> Of the 61 patients, 58 achieved interbody fusion,
                        resulting in a 95.1% fusion rate. The fusion rate in the non-window cage group was comparable
                        to, and not significantly different from, that in the window cage group (96.6% and 93.8%, p &gt;
                        0.99). The subsidence rate showed no significant difference between the window and non-window
                        cage groups (15.6% and 3.4%, respectively; p = 0.262). The intra-cage osseointegration scores
                        showed a significant difference between the groups (p = 0.007), with the non-window cage group
                        having a higher proportion of cases with a score of 4 compared with the window cage group. The
                        TBR sign was observed in 87.9% of patients who achieved interbody fusion, with a higher rate in
                        the non-window cage group across the entire cohort although the difference was not significant
                        (89.7% versus 78.1%, p = 0.385).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_47" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Non-window-type 3D-Ti cages showed equivalent
                        clinical outcomes compared with window-type cages and comparable interbody fusion rates. These
                        results suggest that the potential advantages of 3D-Ti cages could be optimized in the absence
                        of a void for bone graft by providing a larger contact surface for osseointegration.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_48" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level II</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Posterior lumbar interbody fusion (PLIF) is a widely used
                  surgical technique that has shown favorable outcomes in the treatment of various spinal disorders,
                  including degenerative disc disease, spondylolisthesis, and spinal stenosis<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">3</span></a>. The
                  introduction of interbody cages has provided surgeons with a means to improve fusion rates and achieve
                  desirable outcomes<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">7</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">The evolution of cage material began with solid titanium
                  cages in the 1980s<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">9</span></a>, offering high fusion rates but causing subsidence due to
                  their high elastic modulus<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">10</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">11</span></a>. Polyetheretherketone (PEEK) cages were introduced to
                  mitigate this issue because their elastic modulus is similar to that of cortical bone<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">14</span></a>.
                  However, PEEK’s biopassive nature hinders apatite formation, which is essential for osteoblastic
                  differentiation<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">15</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">16</span></a>. To address the issue of fibrous interface development at
                  the vertebral end plate, which potentially causes implant micromotion<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">17</span></a>, a window-type cage with a void for bone graft was designed
                  to enhance fusion rates<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">12</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">18</span></a>. Subsequently, ceramic cages and titanium-coated PEEK cages
                  were introduced to overcome issues with poor cage-bone osseointegration<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">21</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The recent advancement of 3D printing technology has
                  facilitated the creation of 3D-printed porous titanium (3D-Ti) cages, improving their osteoinductive
                  properties while reducing the elastic modulus, leading to favorable outcomes<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_10" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">22</span></a>. As
                  most 3D-Ti cages designed before the introduction of PEEK cages are of the window type with a void for
                  bone graft<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">18</span></a>, an
                  outstanding research question is whether a non-window-type cage provides a broader contact surface for
                  improved osseointegration. There have been few comprehensive studies comparing clinical and
                  radiographic outcomes between window and non-window-type 3D-Ti cages<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">24</span></a>. Thus, a well-structured randomized controlled trial was
                  needed to fill this knowledge gap.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">We hypothesized that non-window-type 3D-Ti cages would
                  yield clinical and radiographic outcomes equivalent to those achieved with window-type 3D-Ti cages in
                  single-level PLIF. Therefore, the objective of the present study was to evaluate the feasibility of
                  the non-window-type cage without a void for bone graft by comparing its clinical and radiographic
                  outcomes to those of the window-type cage.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study Design
                and Participants</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">This study was designed as a multicenter, single-blinded,
                  actively controlled randomized clinical trial with 2 parallel groups. Conducted from September 2020 to
                  December 2022, the trial involved 4 experienced orthopaedic surgeons from 4 different academic centers
                  who had a minimum of 10 years of expertise in the spinal surgery field. The trial was approved by the
                  institutional review board of each center and registered with the Clinical Research Information
                  Service (CRIS, KCT0005793, <a href="https://cris.nih.go.kr" target="_blank" data-id="link_3"
                    class="link" style="position: unset;">https://cris.nih.go.kr</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The study enrolled patients aged 18 to 80 years undergoing
                  single-level PLIF for degenerative disc disease, spinal stenosis, or spondylolisthesis. Exclusion
                  criteria included prior spinal surgery at the same level, spinal infection or malignancy, medication
                  affecting bone metabolism, or contraindications to spinal fusion. Written informed consent was
                  obtained from all participants before enrollment. Collected demographic data included age, sex,
                  height, weight, smoking history, diagnosis, femoral neck bone mineral density (BMD), lowest T-score,
                  osteoporosis treatment history, surgical level, and cage type.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">A total of 70 consecutive patients who met the inclusion
                  criteria were enrolled and randomized; 37 patients were assigned to the window-type cage group and 33
                  patients were assigned to the non-window-type cage group. Five patients in the window group and 4 in
                  the non-window group were lost to follow-up, a drop-out rate of 13%; there were no dropouts related to
                  adverse events. The study included the remaining 61 patients who completed the 12-month follow-up (<a
                    href="" data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01245f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=bcc80f40-d5c4-4adc-bdf0-8f0f32f0e886&type=jpeg&name=JBJS.23.01245f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;">Consolidated Standards of Reporting Trials
                          (CONSORT) diagram of study enrollment.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Randomization and Blinding</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Patients were randomly assigned in a 1:1 ratio to either
                  the window or the non-window group using computer-generated sequences employing the block
                  randomization method (block size of 4). The randomization, coded in R version 4.0.0 (R Development
                  Core Team), was managed by a designated investigator with exclusive access. The allocations to the
                  window and non-window groups were revealed to each institution’s principal investigator, while the
                  patients and data analysts remained blinded. The surgeons and outcome assessors were not blinded due
                  to the intervention’s nature.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Interventions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">All patients underwent single-level PLIF surgery performed
                  by experienced spine surgeons following standard procedures. The only difference between the groups
                  was the type of cage used: window or non-window type. After adequate decompression, the intervertebral
                  disc was removed, and end-plate preparation was carefully performed to facilitate fusion.
                  Additionally, 6 mL of extra-cage bone graft, comprising a mixture of locally sourced autologous bone
                  and cancellous allograft chips, was filled into the anterior intervertebral space on each side. The
                  window-type cages were packed with morselized autologous bone graft. To minimize bias, use of
                  fusion-promoting materials such as bone morphogenetic protein-2 (BMP-2) or demineralized bone matrix
                  was strictly prohibited.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Implants</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The window and non-window-type cages (Genoss) used in the
                  present study were fabricated using 3D printing technology with a porosity of 88%, diamond-shaped
                  pores, a pore size of 1,100 μm, and an elastic modulus of 1.2 GPa (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). The implants had a standardized 11-mm width, 26-mm length, and
                  4° lordotic angle, whereas the height ranged from 8 to 12 mm according to the patient’s needs. The
                  window-type cage, featuring a void, resulted in a bone contact area of 166.6 mm<span
                    data-id="superscript_13" class="annotation superscript" style="position: unset;">2</span>, compared
                  with 203.4 mm<span data-id="superscript_14" class="annotation superscript"
                    style="position: unset;">2</span> for the non-window-type cage.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01245f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=bcc80f40-d5c4-4adc-bdf0-8f0f32f0e886&type=jpeg&name=JBJS.23.01245f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;">Two types of 3D-printed porous titanium cages were
                          employed: the window type (<span data-id="strong_52" class="annotation strong"
                            style="position: unset;">Fig. 2-A</span>) and non-window type (<span data-id="strong_53"
                            class="annotation strong" style="position: unset;">Fig. 2-B</span>). Both cages have a
                          porosity of 88%, diamond-shaped pores, a pore size of 1,100 μm, and an elastic modulus of 1.2
                          GPa (<span data-id="strong_54" class="annotation strong" style="position: unset;">Fig.
                            2-C</span>). The standardized dimensions of the cages include a width of 11 mm, a length of
                          26 mm, and a lordotic angle of 4°. The contact areas are 166.6 mm<span
                            data-id="superscript_15" class="annotation superscript" style="position: unset;">2</span>
                          for the window type and 203.4 mm<span data-id="superscript_16" class="annotation superscript"
                            style="position: unset;">2</span> for the non-window type. Reproduced with permission from
                          Genoss.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Outcome
                Measures</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The primary outcome measure was the Oswestry Disability
                  Index (ODI), which was assessed 12 months postoperatively. The secondary outcomes included interbody
                  fusion rate, subsidence rate, the EuroQol-5 Dimensions (EQ-5D), and complications including surgical
                  site infections, dural tears, neurologic deterioration, and medical complications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                Evaluation and Determinants of Fusion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Interbody fusion was determined with both stress
                  radiographs and multiaxial computed tomography (CT) scans 12 months postoperatively. Fusion assessment
                  was conducted by 2 independent orthopaedic spine surgeons with 4 and 5 years of experience who were
                  not involved in the study’s design or intervention. Fusion was determined on flexion-extension (F-E)
                  stress radiographs by a difference of &lt;3° in the segmental angle (F-E angle). All centers used a
                  standardized stress radiograph protocol for consistent stress application. In this protocol, flexion
                  involved bending forward from the lower back to the maximal point possible while extension required
                  leaning back maximally. Subsidence was defined as &gt;3 mm of cage subsidence from the end plate as
                  seen on radiographs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The assessment of interbody fusion on multiaxial CT scans
                  was conducted using the following scoring systems<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">26</span></a>. New bone formation in the extra-cage space was evaluated
                  using the extra-cage bridging bone (ExCBB) score (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 3-A</a> and <a
                    href="" data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">3-B</a>). The ExCBB score assesses new bone formation outside cages
                  (anterior, posterior, intermediate, right, and left) based on bone bridging, with a score of 0 for no
                  bridging, 1 for incomplete bridging, and 2 for complete bridging<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">26</span></a>. The maximum total ExCBB score for a patient is 10 (2 each
                  for anterior, posterior, intermediate, right, and left). Fusion was deemed to have been achieved when
                  grade-2 bridging was seen in any section.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01245f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=bcc80f40-d5c4-4adc-bdf0-8f0f32f0e886&type=jpeg&name=JBJS.23.01245f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">CT-based scoring systems for interbody fusion
                          assessment. <span data-id="strong_55" class="annotation strong" style="position: unset;">Fig.
                            3-A</span> Intra-cage bridging bone (InCBB) and extra-cage bridging bone (ExCBB) scores were
                          used for the window-type cage group. For both scores, 0 indicates no bridging, 1 (white
                          arrows) represents incomplete or edge-only bridging with a clear radiolucent line, and 2
                          (black arrows) signifies complete bridging. <span data-id="strong_56"
                            class="annotation strong" style="position: unset;">Fig. 3-B</span> The surface
                          osseointegration ratio (SOR) score was used to assess intra-cage osseointegration in the
                          non-window-type cage group. An SOR score of 0 (white arrows) indicates a radiolucent line
                          along &gt;50% of the end plate, while a score of 1 (black arrows) signifies end-plate
                          integration (white dotted lines) along &gt;50% of the end plate without a radiolucent line.
                          The ExCBB score was also used to evaluate extra-cage new bone formation in the non-window cage
                          group.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">The intra-cage osseointegration pattern was assessed
                  differently for each group. The window-type group was rated using the intra-cage bridging bone (InCBB)
                  score (<a href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3-A</a>), rating new bone within the void for bone graft as 0, 1, or
                  2, on the basis of the same criteria used for the ExCBB score. For the non-window group, the surface
                  osseointegration ratio (SOR) score<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">25</span></a> (<a
                    href="" data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3-B</a>), which evaluated osseointegration between the cage and the
                  end plates on sagittal CT images, was used. The score was either 0 (radiolucent line along &gt;50% of
                  the end plate) or 1 (integration [no radiolucent line] along &gt;50% of the end plate). The scores for
                  the upper and lower end plates were combined, with a maximum of score of 2, which indicated interbody
                  fusion. To quantitatively compare osseointegration between groups on a numeric scale, the InCBB or SOR
                  scores for the 2 cages in each patient were summed for comparative analysis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">We also looked for the trabecular bone remodeling (TBR)
                  sign, indicating trabecular reaction between the cage-end plate interface and the vertebral body, on
                  coronal CT images (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>)<a
                    href="" data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">27</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01245f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=bcc80f40-d5c4-4adc-bdf0-8f0f32f0e886&type=jpeg&name=JBJS.23.01245f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;">The trabecular bone remodeling (TBR) sign is shown
                          on CT coronal images both vertically and obliquely, extending from the contact between the
                          cage and vertebral end plate toward the pedicle screw (white arrows), in the window (<span
                            data-id="strong_57" class="annotation strong" style="position: unset;">Fig. 4-A</span>) and
                          non-window (<span data-id="strong_58" class="annotation strong" style="position: unset;">Figs.
                            4-B and 4-C</span>) cage groups. The TBR sign indicates the trabecular reaction between the
                          cage-end plate interface and the vertebral body.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Sample Size
                Calculation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">In the calculation of sample size, the primary outcome
                  measure was the ODI 12 months postoperatively. A successful treatment response was defined as a
                  minimum reduction in the ODI score of 12.8 points, representing the minimal clinically important
                  difference. The sample size calculation was based on separate data from a previous pilot study, not
                  included in the present analysis, setting the standard deviation at 14.3, the power to 90%, the
                  significance level (alpha) to 0.05 for the null hypothesis, and the equivalence margin to 10%. The
                  calculation suggested a minimum of 56 patients for this 2-treatment parallel design study. To account
                  for a potential dropout rate of 20%, 35 participants were enrolled in each group, resulting in a total
                  sample size of 70 participants.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Baseline characteristics and outcomes were compared
                  between groups using independent t tests for continuous variables and chi-square tests for categorical
                  ones; a 2-sided p value of 0.05 was considered significant. A linear mixed-effects model (LMEM) with
                  time points and groups as fixed factors and patients as random effects was used for the primary
                  outcome analysis. Missing data were addressed using an intention-to-treat approach, employing a last
                  observation carried forward method for serial data. Fusion evaluation was based on a per-protocol
                  analysis, limited to patients completing the final follow-up. All analyses were conducted using R
                  version 4.2.2.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Demographics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">No significant differences were observed between the
                  groups in terms of age, sex, body mass index (BMI), BMD, lowest T-score, smoking status, or
                  osteoporosis medication (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).
                  Additionally, the distribution of operative levels and cage heights showed no significant differences
                  between the groups (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Demographic Data</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1939px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Window
                          Group (N = 32)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Non-Window
                          Group (N = 29)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total (N =
                          61)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_44" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71.3 ±
                          6.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70.4 ±
                          6.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70.9 ±
                          6.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.631</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Female <span
                            data-id="emphasis_45" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (46.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (69.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (57.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.138</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_46" class="annotation emphasis"
                            style="position: unset;">(kg/m</span><span data-id="emphasis_47" class="annotation emphasis"
                            style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_21"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_48"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.0 ±
                          3.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.2 ±
                          3.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.1 ±
                          3.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.799</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMD<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_49" class="annotation emphasis"
                            style="position: unset;">(g/cm</span><span data-id="emphasis_50" class="annotation emphasis"
                            style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_22"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_51"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.721 ±
                          0.163</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.739 ±
                          0.149</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.730 ±
                          0.156</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.667</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">T-score<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1.3 ±
                          1.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1.3 ±
                          1.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1.3 ±
                          1.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.965</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Smoking <span
                            data-id="emphasis_52" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&gt;0.99</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative osteoporosis
                          medication <span data-id="emphasis_53" class="annotation emphasis"
                            style="position: unset;">(no. [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.175</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> None</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32
                          (100)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (89.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58
                          (95.1)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Denosumab</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.6)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Parathyroid
                          hormone</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (6.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (3.3)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postoperative
                          osteoporosis medication <span data-id="emphasis_54" class="annotation emphasis"
                            style="position: unset;">(no. [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.878</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> None</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (87.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (89.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54
                          (88.5)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Denosumab</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (6.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (4.9)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Parathyroid
                          hormone</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (6.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (6.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (6.6)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Diagnosis <span
                            data-id="emphasis_55" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.423</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Stenosis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (40.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (27.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (34.4)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Spondylolisthesis</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (59.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (72.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40
                          (65.6)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Operative level <span
                            data-id="emphasis_56" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.981</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> L3-L4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (12.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (13.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (13.1)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> L4-L5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (68.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (69.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (68.9)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> L5-S1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (18.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (17.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (18.0)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cage height <span
                            data-id="emphasis_57" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.616</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 8 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (12.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (8.2)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 9 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (43.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (55.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (49.2)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 10 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (34.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (31.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (32.8)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 11 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (6.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (4.9)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 12 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (6.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (4.9)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Clinical
                Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The ODI and EQ-5D score showed significant improvements 12
                  months postoperatively in both groups (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>) and did
                  not differ significantly between the groups at any time point (<a href=""
                    data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 5</a>). One dural tear occurred in the window cage group, and 1
                  superficial infection was reported in the non-window cage group. Neither group experienced any
                  neurologic deterioration or medical complications (<a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.01245f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=bcc80f40-d5c4-4adc-bdf0-8f0f32f0e886&type=jpeg&name=JBJS.23.01245f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;">Linear mixed-effects model analysis indicated no
                          significant differences in the ODI and EQ-5D clinical scores (shown as the mean and standard
                          deviation) between the 2 groups at any time point. Both scores showed significant improvements
                          at 12 months post-surgery in both the window and the non-window group.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Clinical and Radiographic Outcomes</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1340px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Window
                          Group (N = 32)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Non-Window
                          Group (N = 29)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total (N =
                          61)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> EQ-5D</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.514 ±
                          0.172</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.490 ±
                          0.147</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.503 ±
                          0.160</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.554</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ODI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.449 ±
                          0.179</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.498 ±
                          0.127</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.472 ±
                          0.157</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.219</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">12-month
                          postoperative<span data-id="table_footnote_reference_6"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> EQ-5D</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.804 ±
                          0.133</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.772 ±
                          0.158</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.789 ±
                          0.145</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.400</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ODI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.191 ±
                          0.136</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.251 ±
                          0.164</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.220 ±
                          0.152</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.133</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgical site infection
                          <span data-id="emphasis_58" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.960</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dural tear <span
                            data-id="emphasis_59" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.960</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Neurologic deterioration
                          <span data-id="emphasis_60" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&gt;0.99</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medical complications
                          <span data-id="emphasis_61" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&gt;0.99</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Successful fusion <span
                            data-id="emphasis_62" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (93.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (96.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58
                          (95.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&gt;0.99</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Segmental angular
                          difference in F-E<span data-id="table_footnote_reference_7"
                            class="annotation table_footnote_reference" style="position: unset;">*</span><span
                            data-id="emphasis_63" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.53 ±
                          1.13</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.59 ±
                          0.89</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.56 ±
                          0.102</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.813</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Subsidence <span
                            data-id="emphasis_64" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (15.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (9.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.262</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Trabecular bone reaction
                          sign <span data-id="emphasis_65" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (78.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (89.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51
                          (83.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.385</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Fusion was achieved in 58 (95.1%) of the 61 patients, and
                  the fusion rate did not differ significantly between the non-window and window cage groups (96.6% and
                  93.8%, respectively; p &gt; 0.99) (<a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). The
                  interobserver reliability of the fusion rating demonstrated a kappa value of 0.792. There was a
                  discrepancy in the classifications of 1 of the 3 nonunions, with the patient eventually classified as
                  having a nonunion following discussion between the assessors. Comparison of the F-E angle between the
                  2 groups revealed no significant difference (<a href="" data-id="figure_reference_14"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). The
                  subsidence rate also showed no significant difference between the groups, despite being numerically
                  higher in the window cage group (15.6% versus 3.6%, p = 0.262; <a href=""
                    data-id="figure_reference_15" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">A significant difference was noted in the intra-cage
                  osseointegration scores (p = 0.007), with more patients having a score of 4 in the non-window group
                  (<a href="" data-id="figure_reference_16" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 6</a>, <a href="" data-id="figure_reference_17"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>). A
                  TBR was observed in 51 (87.9%) of the 58 patients achieving interbody fusion. The TBR-positive
                  proportion was numerically higher in the non-window cage group across the entire cohort but it was not
                  significantly higher (89.7% versus 78.1%, p = 0.385; <a href="" data-id="figure_reference_18"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.23.01245f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=bcc80f40-d5c4-4adc-bdf0-8f0f32f0e886&type=jpeg&name=JBJS.23.01245f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;">The percentages of patients with each intra-cage
                          osseointegration score in each group. The non-window group contained a significantly higher
                          proportion of cases with a score of 4 compared with the window cage group (p = 0.035 per post
                          hoc analysis).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Intra- and Extra-Cage Osseointegration Scores</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 550px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Window
                          Group (N = 32)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Non-Window
                          Group (N = 29)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total (N =
                          61)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ExCBB score<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.7 ±
                          1.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.6 ±
                          1.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.7 ±
                          1.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.801</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Intra-cage
                          osseointegration score<span data-id="table_footnote_reference_9"
                            class="annotation table_footnote_reference" style="position: unset;">†</span> (<span
                            data-id="emphasis_66" class="annotation emphasis" style="position: unset;">no.
                            [%])</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_59" class="annotation strong" style="position: unset;">0.007</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.6)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (12.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (6.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (9.8)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (62.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (27.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (45.9)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (25.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (62.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (42.6)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The new-bone formation within the cage was assessed using the InCBB
                        scoring system for window-type cages and the SOR scoring system for non-window-type
                        cages.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">The present study demonstrates that both window and
                  non-window types of cages can achieve satisfactory interbody fusion rates that do not differ
                  significantly from one another (93.8% and 96.6%, respectively). Furthermore, at a 12-month follow-up,
                  significant improvement in clinical outcomes was observed in both groups, with no significant
                  differences found between the groups at any time point.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">The overall fusion rate of 95% in the present study was in
                  line with previously reported fusion rates of 83% to 100% following PLIF using titanium cages<a
                    href="" data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">29</span></a>. Interestingly, while the finding was not significant, the
                  fusion rate in the non-window cage group was numerically higher than that in the window cage group.
                  Additionally, intra-cage osseointegration scores differed significantly between the groups (p =
                  0.007), with the non-window group having more cases with a score of 4 than the window cage group (<a
                    href="" data-id="figure_reference_19" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 6</a>, <a href="" data-id="figure_reference_20"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>). This
                  may be due to the non-window cage having a 22% larger contact surface area, enhancing the effect of
                  surface roughness on reducing early postoperative micromotion at the implant-bone interface and
                  providing a stable interface<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">30</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">31</span></a>, which minimizes the risk of early implant loosening and
                  failure<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">11</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">The wide contact surface can also provide an extensive
                  area for osseointegration between the porous structure and the end plate<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">32</span></a>. This allows optimization of the unique advantage of the
                  porous-structured titanium cage in promoting osseointegration<a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">35</span></a>. Segi et al.<a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">27</span></a>
                  analyzed CT images and described TBR signs associated with titanium cages as a reactive change to the
                  vertebral body during the osseointegration process. They reported higher fusion rates in patients with
                  early postoperative TBR signs. In the present study, the TBR sign was observed in 51 (87.9%) of 58
                  patients who achieved interbody fusion. Despite the lack of statistical significance, the TBR-positive
                  rate was numerically higher in the non-window cage group across the entire cohort than in the window
                  group (89.7% and 78.1%, respectively; <a href="" data-id="figure_reference_21"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).
                  Furthermore, TBR occurred on the porous surface in the window cages, not where the void for bone graft
                  was present (<a href="" data-id="figure_reference_22"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4-A</a>),
                  whereas TBR was observed to be distributed across the surface of the non-window cages (<a href=""
                    data-id="figure_reference_23" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 4-B</a> and <a href="" data-id="figure_reference_24"
                    class="annotation figure_reference resource-reference" style="position: unset;">4-C</a>). Thus, the
                  broad contact surface would have contributed to a favorable radiographic outcome by providing a wider
                  area for osseointegration and thus greater mechanical stability through osseointegration on the
                  cage-end plate interface<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">37</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Interestingly, the subsidence rate was numerically higher
                  in the window cage group than in the non-window cage group, although not significantly so (15.6%
                  versus 3.4%, p = 0.262). This might be due to the reduced elastic modulus resulting from the porous
                  structure in the titanium cage, affecting the overall modulus but not the end-plate contact
                  properties<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">38</span></a><span data-id="superscript_30"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">40</span></a>. A narrow contact surface can concentrate stress, possibly
                  increasing subsidence regardless of the cage’s overall modulus. Previous studies<a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">41</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">42</span></a> have indicated a lower subsidence rate with larger
                  end-plate contact areas, suggesting that the larger contact surface of the non-window cage may offer
                  better support through more uniform stress distribution across the end plate.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">In addition to cage design, meticulous end-plate
                  preparation is crucial for preventing subsidence. Overly aggressive end-plate preparation that leads
                  to violation of the cortical bone can increase the risk of subsidence. Therefore, surgeons should aim
                  for careful end-plate preparation, removing the cartilaginous layer while preserving the subchondral
                  bone. Furthermore, understanding the patient’s bone quality and tailoring the surgical technique
                  accordingly can reduce the risk of subsidence<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">43</span></a>. In
                  this context, the use of a non-window-type cage with a larger contact surface can provide additional
                  support, particularly in patients with compromised bone quality.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">This study has limitations that should be acknowledged. A
                  major limitation is related to the study design, particularly in the selection of the primary outcome.
                  The postoperative 12-month ODI, which was the primary outcome of the study, may not provide sufficient
                  power to adequately compare the radiographic outcomes, including the fusion rate. However, using the
                  fusion rate as the primary outcome would have required an impractically large sample size to achieve
                  the desired statistical power. Therefore, we opted for a clinical outcome score for equivalence
                  assessment, combined with radiographic outcome analysis to emphasize the potential advantages of the
                  non-window-type cage. The study could also be limited by the follow-up period of 12 months, which may
                  be insufficient to evaluate the long-term outcomes of the 2 cage types. However, the overall fusion
                  rate of 95.1% is favorable, and we do not believe that the 3 nonunions identified would have
                  substantially impacted the study’s main conclusion (equivalency of ODIs) even with a 24-month
                  follow-up. Future studies with larger sample sizes and extended follow-up periods are warranted to
                  expand our findings. It should also be noted that the bone graft type and quantity used in this study
                  might differ from those in other clinical settings. Finally, the impact of fusion-promoting materials
                  such as BMP-2 cannot be ascertained from our study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, the results of this study demonstrate that
                  non-window-type 3D-Ti cages without a void for bone graft can achieve clinical outcomes equivalent to
                  those of window-type 3D-Ti cages and comparable interbody fusion rates. These results suggest that the
                  potential advantages of 3D-Ti cages could be optimized in the absence of a void for bone graft by
                  providing a larger contact surface for osseointegration.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Lee JH</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Kong CB</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Yang JJ</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Shim HJ</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Koo KH</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Kim J</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Lee CK</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Chang BS</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Comparison of fusion rate and
                  clinical results between CaO-SiO<span data-id="subscript_6" class="annotation subscript"
                    style="position: unset;">2</span>-P<span data-id="subscript_7" class="annotation subscript"
                    style="position: unset;">2</span>O<span data-id="subscript_8" class="annotation subscript"
                    style="position: unset;">5</span>-B<span data-id="subscript_9" class="annotation subscript"
                    style="position: unset;">2</span>O<span data-id="subscript_10" class="annotation subscript"
                    style="position: unset;">3</span> bioactive glass ceramics spacer with titanium cages in posterior
                  lumbar interbody fusion</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Spine J.</span> 2016 Nov;16(11):1367-76.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20fusion%20rate%20and%20clinical%20results%20between%20CaO-SiO2-P2O5-B2O3%20bioactive%20glass%20ceramics%20spacer%20with%20titanium%20cages%20in%20posterior%20lumbar%20interbody%20fusion&as_occt=title&as_sauthors=%20%22JH%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Norton RP</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Bianco K</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Klifto C</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Errico TJ</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Bendo JA</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Degenerative Spondylolisthesis:
                  An Analysis of the Nationwide Inpatient Sample Database</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2015 Aug
                1;40(15):1219-27.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Degenerative%20Spondylolisthesis%3A%20An%20Analysis%20of%20the%20Nationwide%20Inpatient%20Sample%20Database&as_occt=title&as_sauthors=%20%22RP%20Norton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Rao PJ</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Pelletier MH</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Walsh WR</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Mobbs RJ</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Spine interbody implants:
                  material selection and modification, functionalization and bioactivation of surfaces to improve
                  osseointegration</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Orthop Surg.</span> 2014 May;6(2):81-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spine%20interbody%20implants%3A%20material%20selection%20and%20modification%2C%20functionalization%20and%20bioactivation%20of%20surfaces%20to%20improve%20osseointegration&as_occt=title&as_sauthors=%20%22PJ%20Rao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Mobbs RJ</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Phan K</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Malham G</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Seex K</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Rao PJ</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Lumbar interbody fusion:
                  techniques, indications and comparison of interbody fusion options including PLIF, TLIF, MI-TLIF,
                  OLIF/ATP, LLIF and ALIF</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">J Spine Surg.</span> 2015 Dec;1(1):2-18.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lumbar%20interbody%20fusion%3A%20techniques%2C%20indications%20and%20comparison%20of%20interbody%20fusion%20options%20including%20PLIF%2C%20TLIF%2C%20MI-TLIF%2C%20OLIF%2FATP%2C%20LLIF%20and%20ALIF&as_occt=title&as_sauthors=%20%22RJ%20Mobbs%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Cole CD</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> McCall TD</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Schmidt MH</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Dailey AT</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Comparison of low back fusion
                  techniques: transforaminal lumbar interbody fusion (TLIF) or posterior lumbar interbody fusion (PLIF)
                  approaches</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Curr Rev Musculoskelet Med.</span> 2009 Jun;2(2):118-26.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20low%20back%20fusion%20techniques%3A%20transforaminal%20lumbar%20interbody%20fusion%20(TLIF)%20or%20posterior%20lumbar%20interbody%20fusion%20(PLIF)%20approaches&as_occt=title&as_sauthors=%20%22CD%20Cole%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> DiPaola CP</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Molinari RW</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Posterior lumbar interbody
                  fusion</span>. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J Am
                  Acad Orthop Surg.</span> 2008 Mar;16(3):130-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Posterior%20lumbar%20interbody%20fusion&as_occt=title&as_sauthors=%20%22CP%20DiPaola%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Kim YH</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Ha KY</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Kim YS</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Kim KW</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Rhyu KW</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Park JB</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Shin JH</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Kim YY</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Lee JS</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Park HY</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Ko J</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Kim SI</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Lumbar Interbody Fusion and
                  Osteobiologics for Lumbar Fusion</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Asian Spine J.</span> 2022 Dec;16(6):1022-33.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lumbar%20Interbody%20Fusion%20and%20Osteobiologics%20for%20Lumbar%20Fusion&as_occt=title&as_sauthors=%20%22YH%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Seaman S</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Kerezoudis P</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Bydon M</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Torner JC</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Hitchon PW</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Titanium vs.
                  polyetheretherketone (PEEK) interbody fusion: Meta-analysis and review of the literature</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">J Clin Neurosci.</span> 2017
                Oct;44:23-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Titanium%20vs.%20polyetheretherketone%20(PEEK)%20interbody%20fusion%3A%20Meta-analysis%20and%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22S%20Seaman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Kuslich SD</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Ulstrom CL</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Griffith SL</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Ahern JW</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Dowdle JD</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">The Bagby and Kuslich method of
                  lumbar interbody fusion. History, techniques, and 2-year follow-up results of a United States
                  prospective, multicenter trial</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 1998 Jun 1;23(11):1267-78, discussion
                1279.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Bagby%20and%20Kuslich%20method%20of%20lumbar%20interbody%20fusion.%20History%2C%20techniques%2C%20and%202-year%20follow-up%20results%20of%20a%20United%20States%20prospective%2C%20multicenter%20trial&as_occt=title&as_sauthors=%20%22SD%20Kuslich%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Igarashi H</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Hoshino M</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Omori K</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Matsuzaki H</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Nemoto Y</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Tsuruta T</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Yamasaki K</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Factors influencing interbody
                  cage subsidence following anterior cervical discectomy and fusion</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">Clin Spine Surg.</span> 2019
                Aug;32(7):297-302.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20influencing%20interbody%20cage%20subsidence%20following%20anterior%20cervical%20discectomy%20and%20fusion&as_occt=title&as_sauthors=%20%22H%20Igarashi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Sundfeldt M</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Carlsson LV</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Johansson CB</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Thomsen P</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Gretzer C</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Aseptic loosening, not only a
                  question of wear: a review of different theories</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Acta Orthop.</span> 2006
                Apr;77(2):177-97.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Aseptic%20loosening%2C%20not%20only%20a%20question%20of%20wear%3A%20a%20review%20of%20different%20theories&as_occt=title&as_sauthors=%20%22M%20Sundfeldt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Pechlivanis I</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Thuring T</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Brenke C</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Seiz M</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Thome C</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Barth M</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Harders A</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Schmieder K</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Non-fusion rates in anterior
                  cervical discectomy and implantation of empty polyetheretherketone cages</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2011 Jan 1;36(1):15-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Non-fusion%20rates%20in%20anterior%20cervical%20discectomy%20and%20implantation%20of%20empty%20polyetheretherketone%20cages&as_occt=title&as_sauthors=%20%22I%20Pechlivanis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Panayotov IV</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Orti V</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Cuisinier F</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Yachouh J</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Polyetheretherketone (PEEK) for
                  medical applications</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">J Mater Sci Mater Med.</span> 2016 Jul;27(7):118.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Polyetheretherketone%20(PEEK)%20for%20medical%20applications&as_occt=title&as_sauthors=%20%22IV%20Panayotov%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Brantigan JW</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Steffee AD</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Geiger JM</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">A carbon fiber implant to aid
                  interbody lumbar fusion. Mechanical testing</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 1991
                Jun;16(6)(Suppl):S277-82.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20carbon%20fiber%20implant%20to%20aid%20interbody%20lumbar%20fusion.%20Mechanical%20testing&as_occt=title&as_sauthors=%20%22JW%20Brantigan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Briem D</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Strametz S</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Schröder K</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Meenen NM</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Lehmann W</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Linhart W</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Ohl A</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Rueger JM</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Response of primary fibroblasts
                  and osteoblasts to plasma treated polyetheretherketone (PEEK) surfaces</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">J Mater Sci Mater
                  Med.</span> 2005 Jul;16(7):671-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Response%20of%20primary%20fibroblasts%20and%20osteoblasts%20to%20plasma%20treated%20polyetheretherketone%20(PEEK)%20surfaces&as_occt=title&as_sauthors=%20%22D%20Briem%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Torstrick FB</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Lin ASP</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Safranski DL</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Potter D</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Sulchek T</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Lee CSD</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Gall K</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Guldberg RE</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Effects of surface topography
                  and chemistry on polyether-ether-ketone (PEEK) and titanium osseointegration</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2020 Apr 15;45(8):E417-24.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20surface%20topography%20and%20chemistry%20on%20polyether-ether-ketone%20(PEEK)%20and%20titanium%20osseointegration&as_occt=title&as_sauthors=%20%22FB%20Torstrick%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Olivares-Navarrete R</span>,
                <span data-id="annotation_95" class="annotation" style="position: unset;"> Gittens RA</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Schneider JM</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Hyzy SL</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Haithcock DA</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Ullrich PF</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Schwartz Z</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Boyan BD</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Osteoblasts exhibit a more
                  differentiated phenotype and increased bone morphogenetic protein production on titanium alloy
                  substrates than on poly-ether-ether-ketone</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Spine J.</span> 2012
                Mar;12(3):265-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteoblasts%20exhibit%20a%20more%20differentiated%20phenotype%20and%20increased%20bone%20morphogenetic%20protein%20production%20on%20titanium%20alloy%20substrates%20than%20on%20poly-ether-ether-ketone&as_occt=title&as_sauthors=%20%22R%20Olivares-Navarrete%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Muthiah N</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Yolcu YU</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Alan N</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Agarwal N</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Hamilton DK</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Ozpinar A</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Evolution of
                  polyetheretherketone (PEEK) and titanium interbody devices for spinal procedures: a comprehensive
                  review of the literature</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Eur Spine J.</span> 2022 Oct;31(10):2547-56.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evolution%20of%20polyetheretherketone%20(PEEK)%20and%20titanium%20interbody%20devices%20for%20spinal%20procedures%3A%20a%20comprehensive%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22N%20Muthiah%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Ducheyne P</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Qiu Q</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Bioactive ceramics: the effect
                  of surface reactivity on bone formation and bone cell function</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Biomaterials.</span> 1999
                Dec;20(23-24):2287-303.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bioactive%20ceramics%3A%20the%20effect%20of%20surface%20reactivity%20on%20bone%20formation%20and%20bone%20cell%20function&as_occt=title&as_sauthors=%20%22P%20Ducheyne%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Fujibayashi S</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Neo M</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Kim HM</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Kokubo T</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Nakamura T</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Osteoinduction of porous
                  bioactive titanium metal</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">Biomaterials.</span> 2004 Feb;25(3):443-50.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteoinduction%20of%20porous%20bioactive%20titanium%20metal&as_occt=title&as_sauthors=%20%22S%20Fujibayashi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Hasegawa T</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Ushirozako H</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Shigeto E</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Ohba T</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Oba H</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Mukaiyama K</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Shimizu S</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Yamato Y</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Ide K</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Shibata Y</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Ojima T</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Takahashi J</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Haro H</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Matsuyama Y</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">The titanium-coated PEEK cage
                  maintains better bone fusion with the endplate than the PEEK cage 6 months after PLIF surgery: a
                  multicenter, prospective, randomized study</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2020 Aug
                1;45(15):E892-902.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20titanium-coated%20PEEK%20cage%20maintains%20better%20bone%20fusion%20with%20the%20endplate%20than%20the%20PEEK%20cage%206%20months%20after%20PLIF%20surgery%3A%20a%20multicenter%2C%20prospective%2C%20randomized%20study&as_occt=title&as_sauthors=%20%22T%20Hasegawa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_129" class="annotation" style="position: unset;"> McGilvray KC</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Easley J</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Seim HB</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Regan D</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Berven SH</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Hsu WK</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Mroz TE</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Puttlitz CM</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Bony ingrowth potential of
                  3D-printed porous titanium alloy: a direct comparison of interbody cage materials in an in vivo ovine
                  lumbar fusion model</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Spine J.</span> 2018 Jul;18(7):1250-60.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bony%20ingrowth%20potential%20of%203D-printed%20porous%20titanium%20alloy%3A%20a%20direct%20comparison%20of%20interbody%20cage%20materials%20in%20an%20in%20vivo%20ovine%20lumbar%20fusion%20model&as_occt=title&as_sauthors=%20%22KC%20McGilvray%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Laratta JL</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Vivace BJ</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> López-Peña M</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Guzón FM</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Gonzalez-Cantalpeidra A</span>,
                <span data-id="annotation_142" class="annotation" style="position: unset;"> Jorge-Mora A</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Villar-Liste RM</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Pino-Lopez L</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Lukyanchuk A</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Taghizadeh EA</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Pino-Minguez J</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">3D-printed titanium cages
                  without bone graft outperform PEEK cages with autograft in an animal model</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Spine J.</span> 2022
                Jun;22(6):1016-27.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=3D-printed%20titanium%20cages%20without%20bone%20graft%20outperform%20PEEK%20cages%20with%20autograft%20in%20an%20animal%20model&as_occt=title&as_sauthors=%20%22JL%20Laratta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Fujibayashi S</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Takemoto M</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Ishii K</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Funao H</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Isogai N</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Otsuki B</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Shimizu T</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Nakamura T</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Matsuda S</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Multicenter Prospective Study
                  of Lateral Lumbar Interbody Fusions Using Bioactive Porous Titanium Spacers without Bone
                  Grafts</span>. <span data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Asian
                  Spine J.</span> 2022 Dec;16(6):890-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Multicenter%20Prospective%20Study%20of%20Lateral%20Lumbar%20Interbody%20Fusions%20Using%20Bioactive%20Porous%20Titanium%20Spacers%20without%20Bone%20Grafts&as_occt=title&as_sauthors=%20%22S%20Fujibayashi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Ham DW</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Jung CW</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Chang DG</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Yang JJ</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Song KS</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Feasibility of non-window
                  three-dimensional-printed porous titanium cage in posterior lumbar interbody fusion: a pilot
                  trial</span>. <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Surg.</span> 2023 Dec;15(6):960-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Feasibility%20of%20non-window%20three-dimensional-printed%20porous%20titanium%20cage%20in%20posterior%20lumbar%20interbody%20fusion%3A%20a%20pilot%20trial&as_occt=title&as_sauthors=%20%22DW%20Ham%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Lee J</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Lee DH</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Jung CW</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Song KS</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">The significance of extra-cage
                  bridging bone via radiographic lumbar interbody fusion criterion</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">Global Spine J.</span> 2023
                Jan;13(1):113-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20significance%20of%20extra-cage%20bridging%20bone%20via%20radiographic%20lumbar%20interbody%20fusion%20criterion&as_occt=title&as_sauthors=%20%22J%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Segi N</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Nakashima H</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Shinjo R</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Kagami Y</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Ando K</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Machino M</span>, <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Trabecular bone remodeling as a
                  new indicator of osteointegration after posterior lumbar interbody fusion</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Global Spine J.</span> 2024
                Jan;14(1):25-32.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trabecular%20bone%20remodeling%20as%20a%20new%20indicator%20of%20osteointegration%20after%20posterior%20lumbar%20interbody%20fusion&as_occt=title&as_sauthors=%20%22N%20Segi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Tanida S</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Fujibayashi S</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Otsuki B</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Masamoto K</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Takahashi Y</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Nakayama T</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Matsuda S</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Vertebral endplate cyst as a
                  predictor of nonunion after lumbar interbody fusion: comparison of titanium and polyetheretherketone
                  cages</span>. <span data-id="emphasis_28" class="annotation emphasis" style="position: unset;">Spine
                  (Phila Pa 1976).</span> 2016 Oct 15;41(20):E1216-22.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vertebral%20endplate%20cyst%20as%20a%20predictor%20of%20nonunion%20after%20lumbar%20interbody%20fusion%3A%20comparison%20of%20titanium%20and%20polyetheretherketone%20cages&as_occt=title&as_sauthors=%20%22S%20Tanida%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Nemoto O</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Asazuma T</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Yato Y</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Imabayashi H</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Yasuoka H</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Fujikawa A</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Comparison of fusion rates
                  following transforaminal lumbar interbody fusion using polyetheretherketone cages or titanium cages
                  with transpedicular instrumentation</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">Eur Spine J.</span> 2014 Oct;23(10):2150-5.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20fusion%20rates%20following%20transforaminal%20lumbar%20interbody%20fusion%20using%20polyetheretherketone%20cages%20or%20titanium%20cages%20with%20transpedicular%20instrumentation&as_occt=title&as_sauthors=%20%22O%20Nemoto%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Stadelmann VA</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Terrier A</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Pioletti DP</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Microstimulation at the
                  bone-implant interface upregulates osteoclast activation pathways</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">Bone.</span> 2008 Feb;42(2):358-64.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Microstimulation%20at%20the%20bone-implant%20interface%20upregulates%20osteoclast%20activation%20pathways&as_occt=title&as_sauthors=%20%22VA%20Stadelmann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Lewis G.</span><span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Properties of open-cell porous
                  metals and alloys for orthopaedic applications</span>. <span data-id="emphasis_31"
                  class="annotation emphasis" style="position: unset;">J Mater Sci Mater Med.</span> 2013
                Oct;24(10):2293-325.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Properties%20of%20open-cell%20porous%20metals%20and%20alloys%20for%20orthopaedic%20applications&as_occt=title&as_sauthors=%20%22G.%20Lewis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Gittens RA</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Olivares-Navarrete R</span>,
                <span data-id="annotation_191" class="annotation" style="position: unset;"> Schwartz Z</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Boyan BD</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Implant osseointegration and
                  the role of microroughness and nanostructures: lessons for spine implants</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Acta Biomater.</span> 2014
                Aug;10(8):3363-71.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Implant%20osseointegration%20and%20the%20role%20of%20microroughness%20and%20nanostructures%3A%20lessons%20for%20spine%20implants&as_occt=title&as_sauthors=%20%22RA%20Gittens%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Chang B</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Song W</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Han T</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Yan J</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Li F</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Zhao L</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Kou H</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Zhang Y</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Influence of pore size of
                  porous titanium fabricated by vacuum diffusion bonding of titanium meshes on cell penetration and bone
                  ingrowth</span>. <span data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Acta
                  Biomater.</span> 2016 Mar;33:311-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Influence%20of%20pore%20size%20of%20porous%20titanium%20fabricated%20by%20vacuum%20diffusion%20bonding%20of%20titanium%20meshes%20on%20cell%20penetration%20and%20bone%20ingrowth&as_occt=title&as_sauthors=%20%22B%20Chang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Swaminathan PD</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Uddin MN</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Wooley P</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Asmatulu R</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Fabrication and biological
                  analysis of highly porous PEEK bionanocomposites incorporated with carbon and hydroxyapatite
                  nanoparticles for biological applications</span>. <span data-id="emphasis_34"
                  class="annotation emphasis" style="position: unset;">Molecules.</span> 2020 Aug
                6;25(16):3572.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fabrication%20and%20biological%20analysis%20of%20highly%20porous%20PEEK%20bionanocomposites%20incorporated%20with%20carbon%20and%20hydroxyapatite%20nanoparticles%20for%20biological%20applications&as_occt=title&as_sauthors=%20%22PD%20Swaminathan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Torstrick FB</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Lin ASP</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Potter D</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Safranski DL</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Sulchek TA</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Gall K</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Guldberg RE</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Porous PEEK improves the
                  bone-implant interface compared to plasma-sprayed titanium coating on PEEK</span>. <span
                  data-id="emphasis_35" class="annotation emphasis" style="position: unset;">Biomaterials.</span> 2018
                Dec;185:106-16.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Porous%20PEEK%20improves%20the%20bone-implant%20interface%20compared%20to%20plasma-sprayed%20titanium%20coating%20on%20PEEK&as_occt=title&as_sauthors=%20%22FB%20Torstrick%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Makino T</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Kaito T</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Sakai Y</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Takenaka S</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Yoshikawa H</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Computed tomography color
                  mapping for evaluation of bone ongrowth on the surface of a titanium-coated polyetheretherketone cage
                  in vivo: A pilot study</span>. <span data-id="emphasis_36" class="annotation emphasis"
                  style="position: unset;">Medicine (Baltimore).</span> 2018 Sep;97(37):e12379.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Computed%20tomography%20color%20mapping%20for%20evaluation%20of%20bone%20ongrowth%20on%20the%20surface%20of%20a%20titanium-coated%20polyetheretherketone%20cage%20in%20vivo%3A%20A%20pilot%20study&as_occt=title&as_sauthors=%20%22T%20Makino%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Guyer RD</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Abitbol JJ</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Ohnmeiss DD</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Yao C</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Evaluating osseointegration
                  into a deeply porous titanium scaffold: a biomechanical comparison with PEEK and allograft</span>.
                <span data-id="emphasis_37" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2016 Oct 1;41(19):E1146-50.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluating%20osseointegration%20into%20a%20deeply%20porous%20titanium%20scaffold%3A%20a%20biomechanical%20comparison%20with%20PEEK%20and%20allograft&as_occt=title&as_sauthors=%20%22RD%20Guyer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Li F</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Li J</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Kou H</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Huang T</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Zhou L</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Compressive mechanical
                  compatibility of anisotropic porous Ti6Al4V alloys in the range of physiological strain rate for
                  cortical bone implant applications</span>. <span data-id="emphasis_38" class="annotation emphasis"
                  style="position: unset;">J Mater Sci Mater Med.</span> 2015 Sep;26(9):233.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Compressive%20mechanical%20compatibility%20of%20anisotropic%20porous%20Ti6Al4V%20alloys%20in%20the%20range%20of%20physiological%20strain%20rate%20for%20cortical%20bone%20implant%20applications&as_occt=title&as_sauthors=%20%22F%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Zhang Z</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Li H</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Fogel GR</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Xiang D</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Liao Z</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Liu W</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Finite element model predicts
                  the biomechanical performance of transforaminal lumbar interbody fusion with various porous additive
                  manufactured cages</span>. <span data-id="emphasis_39" class="annotation emphasis"
                  style="position: unset;">Comput Biol Med.</span> 2018 Apr 1;95:167-74.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Finite%20element%20model%20predicts%20the%20biomechanical%20performance%20of%20transforaminal%20lumbar%20interbody%20fusion%20with%20various%20porous%20additive%20manufactured%20cages&as_occt=title&as_sauthors=%20%22Z%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Li F</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Li J</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Xu G</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Liu G</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Kou H</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Zhou L</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Fabrication, pore structure and
                  compressive behavior of anisotropic porous titanium for human trabecular bone implant
                  applications</span>. <span data-id="emphasis_40" class="annotation emphasis"
                  style="position: unset;">J Mech Behav Biomed Mater.</span> 2015 Jun;46:104-14.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fabrication%2C%20pore%20structure%20and%20compressive%20behavior%20of%20anisotropic%20porous%20titanium%20for%20human%20trabecular%20bone%20implant%20applications&as_occt=title&as_sauthors=%20%22F%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Marchi L</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Abdala N</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Oliveira L</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Amaral R</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Coutinho E</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Pimenta L</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Radiographic and clinical
                  evaluation of cage subsidence after stand-alone lateral interbody fusion</span>. <span
                  data-id="emphasis_41" class="annotation emphasis" style="position: unset;">J Neurosurg Spine.</span>
                2013 Jul;19(1):110-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiographic%20and%20clinical%20evaluation%20of%20cage%20subsidence%20after%20stand-alone%20lateral%20interbody%20fusion&as_occt=title&as_sauthors=%20%22L%20Marchi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Agarwal N</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> White MD</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Zhang X</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Alan N</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Ozpinar A</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Salvetti DJ</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Tempel ZJ</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Okonkwo DO</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Kanter AS</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Hamilton DK</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Impact of endplate-implant area
                  mismatch on rates and grades of subsidence following stand-alone lateral lumbar interbody fusion: an
                  analysis of 623 levels</span>. <span data-id="emphasis_42" class="annotation emphasis"
                  style="position: unset;">J Neurosurg Spine.</span> 2020 Mar 6;33(1):12-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impact%20of%20endplate-implant%20area%20mismatch%20on%20rates%20and%20grades%20of%20subsidence%20following%20stand-alone%20lateral%20lumbar%20interbody%20fusion%3A%20an%20analysis%20of%20623%20levels&as_occt=title&as_sauthors=%20%22N%20Agarwal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Polikeit A</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Ferguson SJ</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Nolte LP</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Orr TE</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">The importance of the endplate
                  for interbody cages in the lumbar spine</span>. <span data-id="emphasis_43"
                  class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2003
                Dec;12(6):556-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20importance%20of%20the%20endplate%20for%20interbody%20cages%20in%20the%20lumbar%20spine&as_occt=title&as_sauthors=%20%22A%20Polikeit%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dbcc80f40-d5c4-4adc-bdf0-8f0f32f0e886%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2102</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01245</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 11, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;bcc80f40-d5c4-4adc-bdf0-8f0f32f0e886&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=bcc80f40-d5c4-4adc-bdf0-8f0f32f0e886&type=pdf&name=JBJS.23.01245.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=bcc80f40-d5c4-4adc-bdf0-8f0f32f0e886&type=pdf&name=JBJS.23.01245.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_49"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this study. The <span data-id="strong_50" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I182"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I182</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;bcc80f40-d5c4-4adc-bdf0-8f0f32f0e886&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=bcc80f40-d5c4-4adc-bdf0-8f0f32f0e886&type=zip&name=JBJS.23.01245.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                        Sharing</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">A <span data-id="strong_51"
                            class="annotation strong" style="position: unset;">data-sharing statement</span> is provided
                          with the online version of the article (<a href="http://links.lww.com/JBJS/I183"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I183</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Sharing
                          PDF</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;bcc80f40-d5c4-4adc-bdf0-8f0f32f0e886&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=bcc80f40-d5c4-4adc-bdf0-8f0f32f0e886&type=pdf&name=JBJS.23.01245.dataavailability.pdf&subtype=dataavailability"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Dae-Woong Ham, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4278-2701" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4278-2701</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sang-Min Park, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6171-3256" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6171-3256</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Youngbae B. Kim, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3966-0949" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3966-0949</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Dong-Gune Chang, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6731-1063" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6731-1063</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jae Jun Yang, MD<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Byung-Taek Kwon, MD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4276-6162" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4276-6162</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kwang-Sup Song, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:ksong70@cau.ac.kr" class="" style="position: unset;">ksong70@cau.ac.kr</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9238-8908" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9238-8908</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Chung-Ang University Hospital, College of
                Medicine, Chung-Ang University, Seoul, Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Spine Center and Department of Orthopaedic Surgery, Seoul National University
                Bundang Hospital, Seoul National University College of Medicine, Seongnamsi, Republic of Korea</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, VHS Medical Center, Seoul, Republic of
                Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Spine Center and Department of Orthopedic Surgery, Inje University Sanggye Paik
                Hospital, College of Medicine, Inje University, Seoul, Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Dongguk University Ilsan Hospital, Goyangsi,
                Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Chung-Ang University Gwang Myeong Hospital,
                Gwangmyeongsi, Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 32517.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
