import type { Article } from "../../../../../types";

export const Article652: Article = {
	id: 652,
	rsuiteId: "47c92ab7-56d9-4978-85ed-4f4c3065fd02",
	type: "current concepts review",
	title: "Advanced Care Planning for the Orthopaedic Patient",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/652.jpeg",
	subSpecialties: ["hip"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Advanced Care Planning for the Orthopaedic Patient</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Kathryn R.
                      Segal, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Lauren E.
                      Piana, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node list" data-id="list_1" style="position: unset;">
                  <ul class="simple content" style="position: unset;">
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_1" style="position: unset;">
                            <div class="content" style="position: unset;">Advanced care planning most commonly refers to
                              the act of planning and preparing for decisions with regard to end-of-life care and/or
                              serious illness based on a patient’s personal values, life goals, and preferences.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_2" style="position: unset;">
                            <div class="content" style="position: unset;">Over time, advanced care planning and its
                              formalization through advanced directives have demonstrated substantial benefits to
                              patients, their families and caregivers, and the larger health-care system.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_3" style="position: unset;">
                            <div class="content" style="position: unset;">Despite these benefits, advanced care planning
                              and advanced directives remain underutilized.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_4" style="position: unset;">
                            <div class="content" style="position: unset;">Orthopaedic surgeons interact with patients
                              during sentinel events, such as fragility hip fractures, that indicate a decline in the
                              overall health trajectory.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_5" style="position: unset;">
                            <div class="content" style="position: unset;">Orthopaedic surgeons must familiarize
                              themselves with the concepts and medicolegal aspects of advanced care planning so that
                              care can be optimized for patients during sentinel health events.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Advanced care planning (ACP) describes a “process that
                  supports adults at any age or stage of health in understanding and sharing their personal values, life
                  goals, and preferences regarding future medical care.” The goal is to help to ensure that people’s
                  treatment at the end of life or near serious illness best reflects their values, goals, and
                  preferences<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>. In
                  modern health care, life-preserving interventions are often the default mode of care unless otherwise
                  specified by the patient or an identified health-care proxy. This can ultimately lead to futile,
                  invasive, and expensive care that may not be concordant with a patient’s wishes. With ACP, patients
                  are better able to preserve autonomy and indicate to the health-care system whether the default
                  life-preserving care model should be delivered.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">ACP has been associated with substantial benefits to the
                  individual patient, family and caregivers, physicians and other providers, and the health-care system.
                  These benefits include more goal-concordant care, less moral distress and decision burden on families
                  and providers, and overall cost savings to the health-care system. However, despite these benefits,
                  ACP and its formalized counterpart, the advanced directive, remain underutilized, with rates of use as
                  low as only one-third in American adults as a whole<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">6</span></a>. During the COVID-19 pandemic, there were modest gains in
                  the uptake of ACP<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">7</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Orthopaedic surgeons often interact with patients during
                  sentinel events, including fragility hip fractures, that reflect a decline in the overall health
                  trajectory<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">8</span></a>.
                  Between 20% and 30% of patients who sustain hip fractures ultimately die within 1 year of the injury<a
                    href="" data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">12</span></a>.
                  This is not usually because of the fracture or a surgical procedure alone, but rather what this type
                  of injury reveals about a patient’s underlying health status and frailty. Furthermore, there is
                  extensive morbidity associated with these injuries, as well as other indicators of frailty, when
                  pursuing future orthopaedic care<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">13</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">18</span></a>. It is critical that orthopaedists familiarize themselves
                  with the bioethical principles of ACP and actively take part in facilitating this aspect of care for
                  patients during these sentinel events in parallel with the usual orthopaedic care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Terms and
                Definitions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Advanced directives are the avenues through which the
                  wishes and goals developed in ACP are formally declared. Following the Patient Self-Determination Act
                  of 1990, health-care systems across the United States were mandated to inform patients of their right
                  to involvement in their medical care and to formally document decisions with regard to care that they
                  do or do not want<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">19</span></a>.
                  Over time, advanced directives have emerged as the means by which individuals make these wishes known.
                  Examples include a living will, a designated health-care power of attorney or health-care proxy, and
                  forms known as the Medical Orders for Life-Sustaining Treatment (MOLST) or Physician Orders for
                  Life-Sustaining Treatment (POLST). A summary of the types of advanced directives and the differences
                  in their use can be found in <a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_13" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Types of Advanced Directives<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1269px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Living
                          Will</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Health-Care POA or Proxy</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">MOLST or
                          POLST</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Document type</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Legal</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Legal</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Medical</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">What is
                          communicated?</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">General wishes about
                          end-of-life treatment; may be as detailed as a patient wishes, but typically communicates
                          overarching values and goals of care</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Designation of an
                          individual (or individuals) who would make medical decisions on the patient’s behalf</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Specific medical orders,
                          including resuscitation, intubation, transfer to higher levels of care, artificial nutrition
                          (e.g., feeding tube), artificial hydration</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Who is needed to
                          complete?</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The patient and 2
                          uninvolved witnesses; can be completed with or without the assistance of a lawyer</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The patient, the POA or
                          proxy, and 2 uninvolved witnesses; can be completed with or without the assistance of a
                          lawyer</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The patient and a
                          health-care provider</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Can a surrogate
                          decision-maker complete on the behalf of an incapacitated patient?</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes, in several
                          states</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Will emergency personnel
                          (e.g., EMTs, paramedics) follow?</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">POA = power of attorney, MOLST = Medical Orders for Life-Sustaining
                        Treatment, POLST = Physician Orders for Life-Sustaining Treatment, and EMT = emergency medical
                        technician.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">In living wills, patients describe the care that they want
                  to receive when they are no longer able to make decisions for themselves. Several tools have emerged
                  to assist patients in formulating these decisions<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">20</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">22</span></a>. The living will provides present and future clinicians
                  with a sense of a patient’s goals and preferences when it comes to end-of-life or serious illness
                  care. Although it is not feasible to cover every possible clinical scenario within this text, the
                  living will provides guidance for both clinicians and caregivers to use when deciding on care for an
                  incapacitated person. Further, detailed discussions between patients and their caregivers about end of
                  life can make difficult decisions less burdensome for the family and caregivers.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Meanwhile, a health-care power of attorney or health-care
                  proxy is someone designated by patients to carry out medical decisions on their behalf when they are
                  no longer able to do so. When a patient is incapacitated and has not legally designated a health-care
                  power of attorney or health-care proxy, some states delineate an order of surrogate decision-makers,
                  which typically follows a next-of-kin ordering. Both living wills and health-care power of attorney
                  and proxy designations can only be completed by a capacitated patient. An attorney is not necessary to
                  legally document a patient’s wishes, but some people elect to complete these documents with the
                  assistance of one<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">23</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">By comparison, a MOLST or POLST form is a medical document
                  completed by patients with their physician or provider. It is for patients who either are terminally
                  ill or have a serious health condition, and it describes the type of medical care that they would want
                  to receive if incapacitated. MOLST and POLST forms include decisions with regard to cardiopulmonary
                  resuscitation (CPR), either attempt CPR or do not resuscitate (DNR); the level of respiratory support,
                  such as invasive or noninvasive ventilation, including the option of do not intubate (DNI); transfer
                  to a hospital or not; and artificial and intravenous nutrition<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">24</span></a>. Hickman et al. showed that these are effective forms of
                  communicating end-of-life wishes to medical personnel, likely due to their ease of understanding and
                  inclusion of treatment preferences beyond the traditional DNR or DNI order<a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">25</span></a>. It is also important for patients to understand that if
                  they would like a DNR or DNI code status upheld when traveling via ambulance (such as from a hospital
                  to a rehabilitation facility), a MOLST or POLST form must usually be completed, as emergency medical
                  services are limited in the types of advanced directives that they may follow. Although some states
                  historically have utilized a specialized out-of-hospital DNR form, the MOLST or POLST form represents
                  a newer standardized document that non-physician health-care personnel should follow. It is prudent
                  for patients and their families to keep copies of these forms readily available. Some patients keep
                  copies folded in their purse or wallet or hanging on their refrigerators. In many states, these forms
                  may also be completed by surrogate decision-makers on the behalf of incapacitated patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">It is important to remind patients that advanced
                  directives can be modified and rewritten should their values or preferences change. Furthermore, some
                  states have specific requirements for how often a MOLST or POLST form needs to be renewed. In New York
                  State, these must be reviewed by a physician every 3 months<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">26</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Benefits of
                ACP</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Patients who have participated in ACP are more likely to
                  receive care consistent with their described wishes, such as less invasive interventions, fewer
                  presentations to the emergency department or intensive care unit, and more time spent in hospice
                  care<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">35</span></a>. However, discrepancies between the care documented in
                  patients’ advanced directives and the actual care that they receive at the end of life continue to
                  occur. For example, Lee et al. found that, even among patients with treatment-limiting MOLST or POLST
                  forms, over one-third still ended up with intensive care treatment at the end of life<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">32</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">ACP also benefits stakeholders involved in the end-of-life
                  care of patients, including their families, caregivers, and medical teams<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">34</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">37</span></a>. Having these discussions early on and encouraging patients
                  to complete advanced directives might mitigate the moral distress and decision burden that caregivers
                  and providers experience when making consequential decisions on someone else’s behalf<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">38</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">39</span></a>. One randomized controlled trial among patients receiving
                  dialysis and their caregivers found that an ACP intervention instilled greater confidence in making
                  decisions among the surrogates, as well as lessened anxiety, depression, and posttraumatic distress
                  following the patient’s death<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">37</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Lastly, studies have demonstrated that ACP interventions
                  have led to considerable cost savings through the avoidance of futile intensive care interventions<a
                    href="" data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">40</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">46</span></a>. It is well-described that a substantial proportion of
                  total Medicare funding is spent during the last year of the beneficiaries’ lives. Duncan et al.
                  estimated that these payments make up about 20% of yearly Medicare expenditures<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">47</span></a>. Accordingly, it is conceivable that, through these efforts
                  aimed at restructuring end-of-life care, notable savings can be achieved. In a systematic review,
                  Klingler et al. discovered that between $1,041 and $64,827 in U.S. dollars per patient were realized
                  in cost savings through various ACP interventions<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">48</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Challenges
                to ACP</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Despite the wide-reaching benefits associated with ACP,
                  there still are several barriers to its adoption and widespread use. With regard to its low overall
                  prevalence, Phung et al. identified several key reasons that patients are resistant to participating
                  in the ACP process. These include discomfort thinking about the topic, the desire to leave end-of-life
                  care to a higher power, the assumption that providers know what patients would want, or skepticism
                  that providers would correctly deliver the care that they would want<a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">49</span></a>. Meanwhile, provider-perceived challenges to these
                  discussions include the patient or family’s inability to accept a poor prognosis or difficulty
                  understanding limitations and complications of life-sustaining interventions, and disagreement among
                  family members on ACP choices<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">50</span></a>.
                  There have also been concerns raised by members of the public that ACP could lead to restricting care
                  for vulnerable populations, commonly known as the “death panel myth.”<a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">51</span></a> Furthermore, disparities associated with race, ethnicity,
                  and socioeconomic class exist in the prevalence of ACP, mirroring several other trends in health
                  care<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_24" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">52</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">56</span></a>. Several patient-centered and provider-centered
                  interventions have been developed to confront these often-misconceived notions, including among
                  marginalized communities<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">57</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">60</span></a>. The PREPARE trial developed an easy-to-read online
                  resource for patients that helps to guide them through ACP and has been validated for English-speaking
                  and Spanish-speaking patients alike<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">60</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Another scenario in which ACP is poorly executed by
                  medical professionals involves patients who had completed advanced directives, but ultimately receive
                  care discordant with their expressed wishes. This, in part, is due to fear of litigation. The majority
                  of providers feel that there is less risk in keeping patients alive against their documented wishes or
                  in following a family member’s wishes instead of what is delineated in the advanced directive<a
                    href="" data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">61</span></a>. However, it is important to note that providers are
                  generally granted legal immunity if they follow patients’ advanced directives<a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">23</span></a>. When stark disagreements occur between the incapacitated
                  patients’ advanced directives and the current wishes of their health-care proxies, an ethics
                  consultation can be of great value when deciding how to proceed with care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The lack of interoperability of electronic health records
                  (EHRs) also poses a challenge to proper use. Although a patient may have completed a MOLST or POLST
                  form at 1 institution, providers at another institution may not have access to it. If a caregiver is
                  not present to correct the providers, the providers will default to full measures in the absence of
                  knowing the patient’s wishes. This is why it is valuable for patients to keep advanced directives,
                  such as the MOLST or POLST form, readily available on their person. States such as Oregon and New York
                  have adopted statewide registries for MOLST and POLST forms so that they are accessible across care
                  settings and readily available in emergencies<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">62</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">64</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Policy
                Responses to ACP</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">In 2016, in recognition of the seemingly overwhelming
                  benefits of ACP, the U.S. Centers for Medicare & Medicaid Services began reimbursing health-care
                  providers for having and documenting these conversations<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">65</span></a>.
                  Private health insurers have adopted similar billing practices. However, since the implementation of
                  these policies, documented advanced care discussions have only modestly increased<a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_31" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_50"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">66</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">69</span></a>. Gotanda et al. demonstrated that the proportion of
                  beneficiaries with these claims increased from 9.7% to 21.9% from 2017 to 2019 and that they were
                  occurring earlier in life on average<a href="" data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">5</span></a>.
                  However, Tsugawa et al. pointed out that the low rates of billing for ACP seen in the early years
                  following implementation likely reflect administrative barriers and low reimbursement rates ($80 to
                  $86 for the first 30 minutes, $75 per each additional 30 minutes) rather than an accurate indication
                  of a low number of physicians partaking in these discussions with their patients<a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">70</span></a>. Several alternative payment models have incorporated ACP
                  for their beneficiaries into their quality metrics in an attempt to increase their prevalence.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">ACP in
                Orthopaedics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Orthopaedists have unique access to patients for whom ACP
                  can be decidedly beneficial<a href="" data-id="citation_reference_55"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_34" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">18</span></a>. As the population ages and sustains frailty-related
                  orthopaedic injuries, this will only create a greater need for ACP in patient encounters<a href=""
                    data-id="citation_reference_57" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">71</span></a>. This opportunity has been demonstrated in the orthopaedic
                  literature, as evidenced by low rates of pre-injury and perioperative advanced directives<a href=""
                    data-id="citation_reference_58" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">55</span></a><span data-id="superscript_36"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_59" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">56</span></a><span data-id="superscript_36"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_60" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">72</span></a> and high rates of end-of-life care needs following events
                  such as hip fractures<a href="" data-id="citation_reference_61"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_37" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_62" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">73</span></a>. Kata et al. found that one-third of patients who sustain
                  hip fractures ultimately need surrogate decision-making prior to death<a href=""
                    data-id="citation_reference_63" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">73</span></a>. Although several retrospective reviews have demonstrated
                  the substantial need for ACP for orthogeriatric patients, no studies have yet described interventions
                  aimed at increasing the prevalence in this high-need population.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Furthermore, several studies have demonstrated that ACP
                  discussions prior to elective surgical procedures can be beneficial<a href=""
                    data-id="citation_reference_64" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">74</span></a><span data-id="superscript_39"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_65" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">77</span></a>. Kata et al. described a multidisciplinary initiative that
                  utilized the preoperative planning period as an opportunity to engage patients in ACP, which
                  significantly increased the completion of advanced directives<a href=""
                    data-id="citation_reference_66" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">74</span></a>. Although this opportunity for intervention is not unique
                  to orthopaedics, these findings suggest that orthopaedic surgeons can and should participate in ACP
                  even beyond the patient with a traditional hip fracture or with spinal trauma, as doing so may have a
                  large impact.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Advanced
                Care Conversations for the Orthopaedic Surgeon</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">It is important to examine how these concepts can be
                  translated into everyday skills for the orthopaedic surgeon. Three familiar clinical scenarios are
                  described below, with examples of how ACP and its principles can assist in optimizing overall patient
                  care (<a href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Summary of Clinical Scenarios Demonstrating ACP in
                          Orthopaedics<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1464px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Patient
                          A</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Patient
                          B</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Patient
                          C</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Key features of this
                          case</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• 78-year-old woman<br
                            data-id="break_1" style="position: unset;">• Fragility hip fracture<br data-id="break_2"
                            style="position: unset;">• Lives independently<br data-id="break_3"
                            style="position: unset;">• Community ambulator without assistance<br data-id="break_4"
                            style="position: unset;">• No ADs</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• 86-year-old man<br
                            data-id="break_5" style="position: unset;">• Fragility hip fracture<br data-id="break_6"
                            style="position: unset;">• Lives in a memory facility<br data-id="break_7"
                            style="position: unset;">• Home ambulator with assistive devices<br data-id="break_8"
                            style="position: unset;">• 2 adult children who share in medical decisions<br
                            data-id="break_9" style="position: unset;">• History of DNR and DNI, although no ADs</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• 91-year-old woman<br
                            data-id="break_10" style="position: unset;">• Acute cervical spinal trauma<br
                            data-id="break_11" style="position: unset;">• Intubated upon arrival to the hospital<br
                            data-id="break_12" style="position: unset;">• Low chance of meaningful recovery<br
                            data-id="break_13" style="position: unset;">• Lives with family<br data-id="break_14"
                            style="position: unset;">• Family members requesting full measures be taken<br
                            data-id="break_15" style="position: unset;">• No ADs</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ACP principles to use in
                          optimizing care</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• Introduce the topic of
                          ACP<br data-id="break_16" style="position: unset;">• Inquire about who she would trust to make
                          decisions on her behalf<br data-id="break_17" style="position: unset;">• Resources on
                          different types of ADs<br data-id="break_18" style="position: unset;">• Perioperative
                          management of code status<br data-id="break_19" style="position: unset;">• Completion of MOLST
                          or POLST, if interested</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• Selection of a single
                          point person in the family for medical updates<br data-id="break_20"
                            style="position: unset;">• Perioperative management of code status<br data-id="break_21"
                            style="position: unset;">• Completion of MOLST or POLST during hospitalization</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• Selection of a single
                          point person in the family for medical updates<br data-id="break_22"
                            style="position: unset;">• Engage in conversations with family to better understand
                          patient’s values and goals and describe what life would look like under various scenarios<br
                            data-id="break_23" style="position: unset;">• Draw on past examples of patient’s selection
                          of less intensive treatment<br data-id="break_24" style="position: unset;">• Palliative or
                          bioethics consultation, if necessary</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">AD = advanced directive, DNR = Do Not Resuscitate, DNI = Do Not
                        Intubate, ACP = advanced care planning, MOLST = Medical Orders for Life-Sustaining Treatment,
                        and POLST = Physician Orders for Life-Sustaining Treatment.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                A</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Patient A is a 78-year-old woman who is an independent
                  community ambulator and sustains a hip fracture after a fall at home. She lives alone and performs
                  most of her daily activities on her own, with some help from an adult son. She makes her own medical
                  decisions and does not have any documented advanced directives.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Patient A is deemed to have capacity and chooses to
                  proceed with operative management of the hip fracture. While discussing her injury, it is critical to
                  set expectations for her postoperative course, including a potential stay in a rehabilitation facility
                  and the need for a cane or walker at least while she rehabilitates<a href=""
                    data-id="citation_reference_67" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">78</span></a>. This would also be an opportune time to broach the topic
                  of ACP with the patient. Insightful questions include who she would trust to make medical decisions
                  for her should she be unable to make them on her own and the types of interventions she would want if
                  her heart and/or lungs were to stop working. It is likely that, even though the patient has been
                  functionally independent up until this time, she has begun thinking about these matters on her own.
                  This conversation, in conjunction with this sentinel health event, can provide the necessary
                  encouragement to begin the process of ACP. This may also be an advantageous time to ask the patient to
                  complete a MOLST or POLST form.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                B</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Patient B is an 86-year-old man who is a dependent
                  facility ambulator and sustains a hip fracture after an unwitnessed fall at his memory care unit. He
                  is oriented to self, but not to place or time. He has 2 adult children who share in making decisions
                  with regard to his health and financial affairs. According to his children, his code status is DNR and
                  DNI based on wishes that he had expressed to them while he still had capacity, but he does not have
                  any documented advanced directives.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">In contrast to Patient A, conversations with regard to
                  Patient B’s clinical status will be held with his surrogate decision-makers. Despite having not been
                  formally named his health-care powers of attorney, his children will be the surrogate decision-makers
                  in the absence of a living spouse. For the purpose of the current hospitalization, it will be helpful
                  to decide which of the 2 children should be the primary point person for disseminating information and
                  updates between the care teams and the family. Because the patient’s code status is DNR and DNI,
                  conversations with his family should include specific interventions that would be deemed within the
                  goals of care during this time, such as intubation and the administration of standard medications to
                  maintain hemodynamic stability while a patient is under anesthesia. Although there is a widely held
                  belief that a DNR and DNI code status must be rescinded perioperatively, this is not true. For
                  example, if a cardiac arrest were to occur in the operating room, CPR may not be pursued if that is
                  decided during the family discussion regarding the perioperative management of the patient’s code
                  status<a href="" data-id="citation_reference_68"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">79</span></a><span data-id="superscript_42"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_69" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">80</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Because the patient does not have any advanced directives,
                  it is critical to take this opportunity to complete a MOLST or POLST form with his family,
                  particularly because of the patient’s existing DNR and DNI order.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                C</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Patient C is a 91-year-old woman who is a community
                  ambulator with a walker and a history of dementia who sustained an ASIA (American Spinal Injury
                  Association) B high-cervical spinal cord injury. She is intubated upon arrival at the emergency
                  department and has no advanced directive documented on file. Her family, consisting of adult children
                  with whom she lives and who are her medical decision-makers, is requesting that all measures be
                  undertaken.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">For Patient C, like patient B, conversations will be held
                  with surrogate decision-makers due to the patient’s incapacity. After educating the patient’s family
                  about the severity of her injury and what this would mean for the patient moving forward, the family
                  relays that they would still like full measures taken despite the likely futility of such measures. It
                  is important to engage with the family to gain a better sense of what the patient’s values and
                  preferences might have been if she were actively taking part in the care discussions. Drawing on past
                  medical experiences when the patient opted for a less aggressive therapy or prioritized pain control
                  may be useful. It is also critical to inquire about the family’s rationale and goals when making these
                  requests for care. It is possible they have not yet fully conceptualized the postoperative course or
                  the potential harms associated with operative management.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Families</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Families often struggle in the immediate time period to
                  understand what life will look like for an older adult going forward after serious injury occurs and
                  functional status rapidly declines. Thus, these conversations must go beyond simply the risks and
                  benefits of an operative intervention. If disagreement between the providers and family remains after
                  an in-depth and open dialogue, it would be beneficial to consult palliative care and/or bioethics
                  services to facilitate additional discussions before moving forward.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">It is key to acknowledge the varying time sensitivities of
                  different orthopaedic injuries. Orthopaedic emergencies pose unique challenges as it can be difficult
                  to fully elicit patients or families’ goals and wishes in a short period of time. In these cases,
                  having a strong foundation regarding the principles of ACP and a standardized approach to
                  goals-of-care conversations can facilitate more expedient and proficient discussions<a href=""
                    data-id="citation_reference_70" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_43" class="annotation superscript"
                      style="position: unset;">81</span></a><span data-id="superscript_43"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_71" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_43" class="annotation superscript"
                      style="position: unset;">82</span></a>. Furthermore, it is highly valuable to discuss topics such
                  as prolonged intubations and alternative nutrition (parenteral or enteral via nasogastric or
                  gastrostomy tubing) that may arise if patients require intensive care postoperatively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Summary and
                Future Directions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">ACP is critical to the future of health care. ACP benefits
                  the individual patients; their caregivers, physicians, and providers participating in end-of-life
                  care; and the larger health-care system. However, despite the advantages of ACP, there is still a low
                  prevalence of advanced directive utilization, including among orthopaedic patients. The orthopaedist’s
                  opportunity and obligation to participate in this aspect of patient care will only become more
                  critical as the population ages.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Several interventions have been described to promote the
                  use of ACP (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>). This
                  includes the use of machine-learning algorithms to identify high-risk individuals who have a greater
                  need for ACP<a href="" data-id="citation_reference_72"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">83</span></a><span data-id="superscript_44"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_73" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">84</span></a>; behavioral nudges in the electronic health record for ACP
                  discussions<a href="" data-id="citation_reference_74"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_45" class="annotation superscript" style="position: unset;">84</span></a>;
                  and the use of interdisciplinary care teams to create feasible, efficient workflows to better
                  integrate this work<a href="" data-id="citation_reference_75"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_46" class="annotation superscript" style="position: unset;">85</span></a>.
                  Orthopaedic surgeons have the opportunity to actively participate in ACP with patients in both the
                  inpatient and outpatient settings in parallel with their usual orthopaedic care. Orthogeriatric
                  comanagement services serve as valuable tools to help accomplish this work in the inpatient setting.
                  However, to expand this care, more education and awareness among physicians and providers are urgently
                  needed. One model that has been proposed is the “Best Case, Worst Case” framework, which utilizes a
                  framework that provides patients and families with a dynamic, or day-to-day, assessment of the
                  different outcomes available to patients based on their current clinical status: a “best case,” a
                  “worst case,” and, importantly, the most likely case based on the clinician’s evaluation<a href=""
                    data-id="citation_reference_76" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">86</span></a>. Orthopaedists can, with practice, integrate this framework
                  into their standard workflow to expand conversations beyond traditional informed consent. Furthermore,
                  targeted and multidisciplinary interventions are needed to best accomplish these patient-centered care
                  goals.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_40" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Grades of Recommendation<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1004px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Recommendation</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Grade<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Fragility hip fractures
                          are associated with high rates of morbidity and mortality within 1 year of the injury<a
                            href="" data-id="citation_reference_77"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_48" class="annotation superscript"
                              style="position: unset;">8</span></a><span data-id="superscript_48"
                            class="annotation superscript" style="position: unset;">-<a href=""
                              data-id="citation_reference_78" class="annotation citation_reference resource-reference"
                              style="position: unset;">12</a></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">A</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">There are several
                          orthopaedic conditions that are associated with patient frailty<a href=""
                            data-id="citation_reference_79" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                              style="position: unset;">13</span></a><span data-id="superscript_49"
                            class="annotation superscript" style="position: unset;">-<a href=""
                              data-id="citation_reference_80" class="annotation citation_reference resource-reference"
                              style="position: unset;">18</a></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">B</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Patients who participate
                          in ACP are more likely to receive goal-concordant and less invasive care<a href=""
                            data-id="citation_reference_81" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                              style="position: unset;">25</span></a><span data-id="superscript_50"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_82" class="annotation citation_reference resource-reference"
                              style="position: unset;">27</a>-<a href="" data-id="citation_reference_83"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">35</a></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">B</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Forms such as MOLST or
                          POLST are effective at communicating patients’ goals of care to medical personnel<a href=""
                            data-id="citation_reference_84" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_51" class="annotation superscript"
                              style="position: unset;">25</span></a><span data-id="superscript_51"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">B</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Caregivers and providers
                          of patients who have participated in ACP demonstrate lower rates of moral distress and higher
                          confidence in decision-making<a href="" data-id="citation_reference_85"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_52" class="annotation superscript"
                              style="position: unset;">28</span></a><span data-id="superscript_52"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_86" class="annotation citation_reference resource-reference"
                              style="position: unset;">34</a>,<a href="" data-id="citation_reference_87"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">36</a>-<a href="" data-id="citation_reference_88"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">39</a></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">B</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ACP is associated with
                          considerable cost savings through the avoidance of futile intensive care interventions<a
                            href="" data-id="citation_reference_89"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_53" class="annotation superscript"
                              style="position: unset;">40</span></a><span data-id="superscript_53"
                            class="annotation superscript" style="position: unset;">-<a href=""
                              data-id="citation_reference_90" class="annotation citation_reference resource-reference"
                              style="position: unset;">48</a></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">B</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Disparities associated
                          with patient race, ethnicity, and socioeconomic status exist in ACP<a href=""
                            data-id="citation_reference_91" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_54" class="annotation superscript"
                              style="position: unset;">2</span></a><span data-id="superscript_54"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_92" class="annotation citation_reference resource-reference"
                              style="position: unset;">52</a>-<a href="" data-id="citation_reference_93"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">56</a></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">B</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">The integration of ACP
                          into Medicare billing codes has demonstrated only modest increases in rates of billing for
                          these services<a href="" data-id="citation_reference_94"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_55" class="annotation superscript"
                              style="position: unset;">5</span></a><span data-id="superscript_55"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_95" class="annotation citation_reference resource-reference"
                              style="position: unset;">33</a>,<a href="" data-id="citation_reference_96"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">65</a>-<a href="" data-id="citation_reference_97"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">70</a></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">B</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Orthogeriatric patients
                          have demonstrated low rates of advanced directives<a href="" data-id="citation_reference_98"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_56" class="annotation superscript"
                              style="position: unset;">55</span></a><span data-id="superscript_56"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_99" class="annotation citation_reference resource-reference"
                              style="position: unset;">56</a>,<a href="" data-id="citation_reference_100"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">72</a></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">B</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">The “best case, worst
                          case” framework is a reliable method of initiating end-of-life conversations with surgical
                          patients and their caretakers<a href="" data-id="citation_reference_101"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_57" class="annotation superscript"
                              style="position: unset;">86</span></a><span data-id="superscript_57"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">B</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">MOLST = Medical Orders for Life-Sustaining Treatment, and POLST =
                        Physician Orders for Life-Sustaining Treatment.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">According to Wright<a href="" data-id="citation_reference_102"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_58" class="annotation superscript"
                            style="position: unset;">87</span></a><span data-id="superscript_58"
                          class="annotation superscript" style="position: unset;"></span>, grade A indicates good
                        evidence (Level-I studies with consistent findings) for or against recommending intervention;
                        grade B, fair evidence (Level-II or III studies with consistent findings) for or against
                        recommending intervention; grade C, poor-quality evidence (Level-IV or V studies with consistent
                        findings) for or against recommending intervention; and grade I, insufficient or conflicting
                        evidence not allowing a recommendation for or against intervention.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Sudore RL</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Lum HD</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> You JJ</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Hanson LC</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Meier DE</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Pantilat SZ</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Matlock DD</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Rietjens JAC</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Korfage IJ</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Ritchie CS</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Kutner JS</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Teno JM</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Thomas J</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> McMahan RD</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Heyland DK</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Defining advance care planning
                  for adults: a consensus definition from a multidisciplinary Delphi panel</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Pain Symptom
                  Manage.</span> 2017 May;53(5):821-32.e1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Defining%20advance%20care%20planning%20for%20adults%3A%20a%20consensus%20definition%20from%20a%20multidisciplinary%20Delphi%20panel&as_occt=title&as_sauthors=%20%22RL%20Sudore%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Rao JK</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Anderson LA</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Lin FC</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Laux JP</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Completion of advance directives
                  among U.S. consumers</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Am J Prev Med.</span> 2014 Jan;46(1):65-70.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Completion%20of%20advance%20directives%20among%20U.S.%20consumers&as_occt=title&as_sauthors=%20%22JK%20Rao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Yadav KN</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Gabler NB</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Cooney E</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Kent S</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Kim J</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Herbst N</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Mante A</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Halpern SD</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Courtright KR</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Approximately one in three US
                  adults completes any type of advance directive for end-of-life care</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Health Aff (Millwood).</span> 2017 Jul
                1;36(7):1244-51.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Approximately%20one%20in%20three%20US%20adults%20completes%20any%20type%20of%20advance%20directive%20for%20end-of-life%20care&as_occt=title&as_sauthors=%20%22KN%20Yadav%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Knight T</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Malyon A</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Fritz Z</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Subbe C</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Cooksley T</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Holland M</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Lasserson D</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Advance care planning in
                  patients referred to hospital for acute medical care: results of a national day of care survey</span>.
                <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">EClinicalMedicine.</span> 2020 Jan 18;19:100235.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Advance%20care%20planning%20in%20patients%20referred%20to%20hospital%20for%20acute%20medical%20care%3A%20results%20of%20a%20national%20day%20of%20care%20survey&as_occt=title&as_sauthors=%20%22T%20Knight%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Gotanda H</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Walling AM</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Zhang JJ</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Xu H</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Tsugawa Y</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Timing and setting of billed
                  advance care planning among Medicare decedents in 2017-2019</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">J Am Geriatr Soc.</span> 2023
                Oct;71(10):3237-43.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Timing%20and%20setting%20of%20billed%20advance%20care%20planning%20among%20Medicare%20decedents%20in%202017-2019&as_occt=title&as_sauthors=%20%22H%20Gotanda%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Asiaban JN</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Patel S</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Ormseth CH</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Donohue KC</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Wallin D</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Wang RC</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Raven MC</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Advance care planning among
                  patients with advanced illness presenting to the emergency department</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J Emerg Med.</span> 2023
                Apr;64(4):476-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Advance%20care%20planning%20among%20patients%20with%20advanced%20illness%20presenting%20to%20the%20emergency%20department&as_occt=title&as_sauthors=%20%22JN%20Asiaban%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Auriemma CL</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Halpern SD</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Asch JM</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Van Der Tuyn M</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Asch DA</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Completion of advance directives
                  and documented care preferences during the coronavirus disease 2019 (COVID-19) pandemic</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">JAMA Netw Open.</span> 2020
                Jul 1;3(7):e2015762.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Completion%20of%20advance%20directives%20and%20documented%20care%20preferences%20during%20the%20coronavirus%20disease%202019%20(COVID-19)%20pandemic&as_occt=title&as_sauthors=%20%22CL%20Auriemma%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Koso RE</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Sheets C</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Richardson WJ</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Galanos AN</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Hip fracture in the elderly
                  patients: a sentinel event</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">Am J Hosp Palliat Care.</span> 2018 Apr;35(4):612-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20fracture%20in%20the%20elderly%20patients%3A%20a%20sentinel%20event&as_occt=title&as_sauthors=%20%22RE%20Koso%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Roche JJW</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Wenn RT</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Sahota O</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Moran CG</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Effect of comorbidities and
                  postoperative complications on mortality after hip fracture in elderly people: prospective
                  observational cohort study</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">BMJ.</span> 2005 Dec 10;331(7529):1374.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20comorbidities%20and%20postoperative%20complications%20on%20mortality%20after%20hip%20fracture%20in%20elderly%20people%3A%20prospective%20observational%20cohort%20study&as_occt=title&as_sauthors=%20%22JJW%20Roche%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Brauer CA</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Coca-Perraillon M</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Cutler DM</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Rosen AB</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Incidence and mortality of hip
                  fractures in the United States</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2009 Oct 14;302(14):1573-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%20and%20mortality%20of%20hip%20fractures%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22CA%20Brauer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Bentler SE</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Liu L</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Obrizan M</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Cook EA</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Wright KB</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Geweke JF</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Chrischilles EA</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Pavlik CE</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Wallace RB</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Ohsfeldt RL</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Jones MP</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Rosenthal GE</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Wolinsky FD</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">The aftermath of hip fracture:
                  discharge placement, functional status change, and mortality</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Am J Epidemiol.</span> 2009 Nov
                15;170(10):1290-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20aftermath%20of%20hip%20fracture%3A%20discharge%20placement%2C%20functional%20status%20change%2C%20and%20mortality&as_occt=title&as_sauthors=%20%22SE%20Bentler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Schnell S</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Friedman SM</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Mendelson DA</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Bingham KW</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Kates SL</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">The 1-year mortality of
                  patients treated in a hip fracture program for elders</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">Geriatr Orthop Surg Rehabil.</span> 2010
                Sep;1(1):6-14.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%201-year%20mortality%20of%20patients%20treated%20in%20a%20hip%20fracture%20program%20for%20elders&as_occt=title&as_sauthors=%20%22S%20Schnell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Testa EJ</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Albright JA</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Lemme NJ</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Molla V</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> McCrae B</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Daniels AH</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Paxton ES</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Increased risk of
                  periprosthetic fractures and revision arthroplasty in patients undergoing shoulder arthroplasty with a
                  history of prior fragility fractures: a matched cohort analysis</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2023 May
                1;31(9):e473-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Increased%20risk%20of%20periprosthetic%20fractures%20and%20revision%20arthroplasty%20in%20patients%20undergoing%20shoulder%20arthroplasty%20with%20a%20history%20of%20prior%20fragility%20fractures%3A%20a%20matched%20cohort%20analysis&as_occt=title&as_sauthors=%20%22EJ%20Testa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Albright JA</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Testa EJ</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Meghani O</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Chang K</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Daniels AH</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Barrett TJ</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Increased risk of hospital
                  readmissions and implant-related complications in patients who had a recent history of fragility
                  fracture: a matched cohort analysis</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2023 Feb;38(2):266-73.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Increased%20risk%20of%20hospital%20readmissions%20and%20implant-related%20complications%20in%20patients%20who%20had%20a%20recent%20history%20of%20fragility%20fracture%3A%20a%20matched%20cohort%20analysis&as_occt=title&as_sauthors=%20%22JA%20Albright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Ross AJ</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Ross BJ</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Lee OC</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Guild GN 3rd</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Sherman WF</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">The impact of prior fragility
                  fractures on complications after total hip arthroplasty: a propensity score-matched cohort
                  study</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Arthroplast Today.</span> 2021 Aug 19;11:41-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20impact%20of%20prior%20fragility%20fractures%20on%20complications%20after%20total%20hip%20arthroplasty%3A%20a%20propensity%20score-matched%20cohort%20study&as_occt=title&as_sauthors=%20%22AJ%20Ross%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Chang K</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Albright JA</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Testa EJ</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Balboni AB</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Daniels AH</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Cohen E</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Sarcopenia is associated with
                  an increased risk of postoperative complications following total hip arthroplasty for
                  osteoarthritis</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Biology (Basel).</span> 2023 Feb 13;12(2):295.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sarcopenia%20is%20associated%20with%20an%20increased%20risk%20of%20postoperative%20complications%20following%20total%20hip%20arthroplasty%20for%20osteoarthritis&as_occt=title&as_sauthors=%20%22K%20Chang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Testa EJ</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Yang D</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Steflik MJ</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Owens BD</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Parada SA</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Daniels AH</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> DeFroda S</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Reverse total shoulder
                  arthroplasty in patients 80 years and older: a national database analysis of complications and
                  mortality</span>. <span data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2022 Jun;31(6S):S71-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reverse%20total%20shoulder%20arthroplasty%20in%20patients%2080%20years%20and%20older%3A%20a%20national%20database%20analysis%20of%20complications%20and%20mortality&as_occt=title&as_sauthors=%20%22EJ%20Testa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> McDonald CL</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Berreta RS</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Alsoof D</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Anderson G</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Kutschke MJ</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Diebo BG</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Kuris EO</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Daniels AH</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Three-column osteotomy for
                  frail versus nonfrail patients with adult spinal deformity: assessment of medical and surgical
                  complications, revision surgery rates, and cost</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">World Neurosurg.</span> 2023
                Mar;171:e714-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Three-column%20osteotomy%20for%20frail%20versus%20nonfrail%20patients%20with%20adult%20spinal%20deformity%3A%20assessment%20of%20medical%20and%20surgical%20complications%2C%20revision%20surgery%20rates%2C%20and%20cost&as_occt=title&as_sauthors=%20%22CL%20McDonald%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Patient Self-Determination Act
                  of 1990</span>. Accessed 2024 Oct 9. <a
                  href="https://www.congress.gov/bill/101st-congress/house-bill/4449" target="_blank" data-id="link_1"
                  class="link"
                  style="position: unset;">https://www.congress.gov/bill/101st-congress/house-bill/4449</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Freytag J</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Street RL Jr</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Barnes DE</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Shi Y</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Volow AM</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Shim JK</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Alexander SC</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Sudore RL</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Empowering older adults to
                  discuss advance care planning during clinical visits: the PREPARE randomized trial</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">J Am Geriatr Soc.</span>
                2020 Jun;68(6):1210-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Empowering%20older%20adults%20to%20discuss%20advance%20care%20planning%20during%20clinical%20visits%3A%20the%20PREPARE%20randomized%20trial&as_occt=title&as_sauthors=%20%22J%20Freytag%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Sudore RL</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Boscardin J</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Feuz MA</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> McMahan RD</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Katen MT</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Barnes DE</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Effect of the PREPARE website
                  vs an easy-to-read advance directive on advance care planning documentation and engagement among
                  veterans: a randomized clinical trial</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">JAMA Intern Med.</span> 2017 Aug 1;177(8):1102-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20the%20PREPARE%20website%20vs%20an%20easy-to-read%20advance%20directive%20on%20advance%20care%20planning%20documentation%20and%20engagement%20among%20veterans%3A%20a%20randomized%20clinical%20trial&as_occt=title&as_sauthors=%20%22RL%20Sudore%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Volandes AE</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Zupanc SN</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Lakin JR</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Cabral HJ</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Burns EA</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Carney MT</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Lopez S</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Itty J</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Emmert K</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Martin NJ</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Cole T</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Dobie A</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Cucinotta T</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Joel M</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Caruso LB</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Henault L</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Dugas JN</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Astone K</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Winter M</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Wang N</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Davis AD</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Garde C</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Rodriguez PM</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> El-Jawahri A</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Moseley ET</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Das S</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Sciacca K</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Ramirez AM</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Gromova V</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Lambert S</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Sanghani S</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Lindvall C</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Paasche-Orlow MK</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Video intervention and
                  goals-of-care documentation in hospitalized older adults: the VIDEO-PCE randomized clinical
                  trial</span>. <span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">JAMA
                  Netw Open.</span> 2023 Sep 5;6(9):e2332556.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Video%20intervention%20and%20goals-of-care%20documentation%20in%20hospitalized%20older%20adults%3A%20the%20VIDEO-PCE%20randomized%20clinical%20trial&as_occt=title&as_sauthors=%20%22AE%20Volandes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Hooper S</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Sabatino CP</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Sudore RL</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Improving medical-legal advance
                  care planning</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">J Pain Symptom Manage.</span> 2020 Aug;60(2):487-94.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Improving%20medical-legal%20advance%20care%20planning&as_occt=title&as_sauthors=%20%22S%20Hooper%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;">State of
                Rhode Island Department of Health. <span data-id="strong_24" class="annotation strong"
                  style="position: unset;">Medical Orders for Life Sustaining Treatment (MOLST)</span>. 2023. Accessed
                2024 Feb 7. <a href="https://health.ri.gov/forms/medical/OrdersForLifeSustainingTreatment.pdf"
                  target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://health.ri.gov/forms/medical/OrdersForLifeSustainingTreatment.pdf</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Medical%20Orders%20for%20Life%20Sustaining%20Treatment%20(MOLST)&as_occt=title&as_sauthors=%20%22State%20of%20Rhode%20Island%20Department%20of%20Health%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Hickman SE</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Nelson CA</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Perrin NA</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Moss AH</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Hammes BJ</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Tolle SW</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">A comparison of methods to
                  communicate treatment preferences in nursing facilities: traditional practices versus the physician
                  orders for life-sustaining treatment program</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">J Am Geriatr Soc.</span> 2010
                Jul;58(7):1241-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20comparison%20of%20methods%20to%20communicate%20treatment%20preferences%20in%20nursing%20facilities%3A%20traditional%20practices%20versus%20the%20physician%20orders%20for%20life-sustaining%20treatment%20program&as_occt=title&as_sauthors=%20%22SE%20Hickman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;">New York
                State Senate. <span data-id="strong_26" class="annotation strong" style="position: unset;">Chapter 45
                  Public Health, Section 2994-DD</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Managing a Nonhospital Order Not to Resuscitate.</span> 2020. Accessed 2024
                Oct 10. <a href="https://www.nysenate.gov/legislation/laws/PBH/2994-DD" target="_blank" data-id="link_3"
                  class="link"
                  style="position: unset;">https://www.nysenate.gov/legislation/laws/PBH/2994-DD</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Chapter%2045%20Public%20Health%2C%20Section%202994-DD&as_occt=title&as_sauthors=%20%22New%20York%20State%20Senate%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Silveira MJ</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Kim SYH</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Langa KM</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Advance directives and outcomes
                  of surrogate decision making before death</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">N Engl J Med.</span> 2010 Apr
                1;362(13):1211-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Advance%20directives%20and%20outcomes%20of%20surrogate%20decision%20making%20before%20death&as_occt=title&as_sauthors=%20%22MJ%20Silveira%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Detering KM</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Hancock AD</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Reade MC</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Silvester W</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">The impact of advance care
                  planning on end of life care in elderly patients: randomised controlled trial</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">BMJ.</span> 2010 Mar
                23;340:c1345.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20impact%20of%20advance%20care%20planning%20on%20end%20of%20life%20care%20in%20elderly%20patients%3A%20randomised%20controlled%20trial&as_occt=title&as_sauthors=%20%22KM%20Detering%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Brinkman-Stoppelenburg A</span>,
                <span data-id="annotation_186" class="annotation" style="position: unset;"> Rietjens JA</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> van der Heide A</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">The effects of advance care
                  planning on end-of-life care: a systematic review</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">Palliat Med.</span> 2014
                Sep;28(8):1000-25.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effects%20of%20advance%20care%20planning%20on%20end-of-life%20care%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22A%20Brinkman-Stoppelenburg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Ache K</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Harrold J</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Harris P</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Dougherty M</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Casarett D</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Are advance directives
                  associated with better hospice care?</span><span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">J Am Geriatr Soc.</span> 2014 Jun;62(6):1091-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Are%20advance%20directives%20associated%20with%20better%20hospice%20care%3F&as_occt=title&as_sauthors=%20%22K%20Ache%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Ashana DC</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Chen X</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Agiro A</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Sridhar G</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Nguyen A</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Barron J</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Haynes K</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Fisch M</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Debono D</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Halpern SD</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Harhay MO</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Advance care planning claims
                  and health care utilization among seriously ill patients near the end of life</span>. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">JAMA Netw Open.</span> 2019
                Nov 1;2(11):e1914471.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Advance%20care%20planning%20claims%20and%20health%20care%20utilization%20among%20seriously%20ill%20patients%20near%20the%20end%20of%20life&as_occt=title&as_sauthors=%20%22DC%20Ashana%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Lee RY</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Brumback LC</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Sathitratanacheewin S</span>,
                <span data-id="annotation_207" class="annotation" style="position: unset;"> Lober WB</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Modes ME</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Lynch YT</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Ambrose CI</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Sibley J</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Vranas KC</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Sullivan DR</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Engelberg RA</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Curtis JR</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Kross EK</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Association of physician orders
                  for life-sustaining treatment with ICU admission among patients hospitalized near the end of
                  life</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2020 Mar 10;323(10):950-60.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20of%20physician%20orders%20for%20life-sustaining%20treatment%20with%20ICU%20admission%20among%20patients%20hospitalized%20near%20the%20end%20of%20life&as_occt=title&as_sauthors=%20%22RY%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Gupta A</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Jin G</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Reich A</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Prigerson HG</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Ladin K</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Kim D</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Ashana DC</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Cooper Z</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Halpern SD</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Weissman JS</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Association of billed advance
                  care planning with end-of-life care intensity for 2017 Medicare decedents</span>. <span
                  data-id="emphasis_31" class="annotation emphasis" style="position: unset;">J Am Geriatr Soc.</span>
                2020 Sep;68(9):1947-53.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20of%20billed%20advance%20care%20planning%20with%20end-of-life%20care%20intensity%20for%202017%20Medicare%20decedents&as_occt=title&as_sauthors=%20%22A%20Gupta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_227" class="annotation" style="position: unset;"> McMahan RD</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Tellez I</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Sudore RL</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Deconstructing the complexities
                  of advance care planning outcomes: what do we know and where do we go? A scoping review</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">J Am Geriatr Soc.</span>
                2021 Jan;69(1):234-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Deconstructing%20the%20complexities%20of%20advance%20care%20planning%20outcomes%3A%20what%20do%20we%20know%20and%20where%20do%20we%20go%3F%20A%20scoping%20review&as_occt=title&as_sauthors=%20%22RD%20McMahan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Weissman JS</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Reich AJ</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Prigerson HG</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Gazarian P</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Tjia J</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Kim D</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Rodgers P</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Manful A</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Association of advance care
                  planning visits with intensity of health care for Medicare beneficiaries with serious illness at the
                  end of life</span>. <span data-id="emphasis_33" class="annotation emphasis"
                  style="position: unset;">JAMA Health Forum.</span> 2021 Jul 30;2(7):e211829.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20of%20advance%20care%20planning%20visits%20with%20intensity%20of%20health%20care%20for%20Medicare%20beneficiaries%20with%20serious%20illness%20at%20the%20end%20of%20life&as_occt=title&as_sauthors=%20%22JS%20Weissman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Torke AM</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Siegler M</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Abalos A</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Moloney RM</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Alexander GC</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Physicians’ experience with
                  surrogate decision making for hospitalized adults</span>. <span data-id="emphasis_34"
                  class="annotation emphasis" style="position: unset;">J Gen Intern Med.</span> 2009
                Sep;24(9):1023-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Physicians%E2%80%99%20experience%20with%20surrogate%20decision%20making%20for%20hospitalized%20adults&as_occt=title&as_sauthors=%20%22AM%20Torke%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Song MK</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Ward SE</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Fine JP</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Hanson LC</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Lin FC</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Hladik GA</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Hamilton JB</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Bridgman JC</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Advance care planning and
                  end-of-life decision making in dialysis: a randomized controlled trial targeting patients and their
                  surrogates</span>. <span data-id="emphasis_35" class="annotation emphasis" style="position: unset;">Am
                  J Kidney Dis.</span> 2015 Nov;66(5):813-22.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Advance%20care%20planning%20and%20end-of-life%20decision%20making%20in%20dialysis%3A%20a%20randomized%20controlled%20trial%20targeting%20patients%20and%20their%20surrogates&as_occt=title&as_sauthors=%20%22MK%20Song%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Elpern EH</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Covert B</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Kleinpell R</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Moral distress of staff nurses
                  in a medical intensive care unit</span>. <span data-id="emphasis_36" class="annotation emphasis"
                  style="position: unset;">Am J Crit Care.</span> 2005 Nov;14(6):523-30.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Moral%20distress%20of%20staff%20nurses%20in%20a%20medical%20intensive%20care%20unit&as_occt=title&as_sauthors=%20%22EH%20Elpern%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Wendler D</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Rid A</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Systematic review: the effect
                  on surrogates of making treatment decisions for others</span>. <span data-id="emphasis_37"
                  class="annotation emphasis" style="position: unset;">Ann Intern Med.</span> 2011 Mar
                1;154(5):336-46.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Systematic%20review%3A%20the%20effect%20on%20surrogates%20of%20making%20treatment%20decisions%20for%20others&as_occt=title&as_sauthors=%20%22D%20Wendler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Chambers CV</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Diamond JJ</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Perkel RL</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Lasch LA</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Relationship of advance
                  directives to hospital charges in a Medicare population</span>. <span data-id="emphasis_38"
                  class="annotation emphasis" style="position: unset;">Arch Intern Med.</span> 1994 Mar
                14;154(5):541-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Relationship%20of%20advance%20directives%20to%20hospital%20charges%20in%20a%20Medicare%20population&as_occt=title&as_sauthors=%20%22CV%20Chambers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Molloy DW</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Guyatt GH</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Russo R</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Goeree R</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> O’Brien BJ</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Bédard M</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Willan A</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Watson J</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Patterson C</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Harrison C</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Standish T</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Strang D</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Darzins PJ</span>, <span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Smith S</span>, <span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Dubois S</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Systematic implementation of an
                  advance directive program in nursing homes: a randomized controlled trial</span>. <span
                  data-id="emphasis_39" class="annotation emphasis" style="position: unset;">JAMA.</span> 2000 Mar
                15;283(11):1437-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Systematic%20implementation%20of%20an%20advance%20directive%20program%20in%20nursing%20homes%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22DW%20Molloy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Edes TE</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Lindbloom EJ</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Deal JL</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Madsen RW</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Improving care at lower cost
                  for end-stage heart and lung disease: integrating end of life planning with home care</span>. <span
                  data-id="emphasis_40" class="annotation emphasis" style="position: unset;">Mo Med.</span> 2006
                Mar-Apr;103(2):146-51.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Improving%20care%20at%20lower%20cost%20for%20end-stage%20heart%20and%20lung%20disease%3A%20integrating%20end%20of%20life%20planning%20with%20home%20care&as_occt=title&as_sauthors=%20%22TE%20Edes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Engelhardt JB</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> McClive-Reed KP</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Toseland RW</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Smith TL</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Larson DG</span>, <span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Tobin DR</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Effects of a program for
                  coordinated care of advanced illness on patients, surrogates, and healthcare costs: a randomized
                  trial</span>. <span data-id="emphasis_41" class="annotation emphasis" style="position: unset;">Am J
                  Manag Care.</span> 2006 Feb;12(2):93-100.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20a%20program%20for%20coordinated%20care%20of%20advanced%20illness%20on%20patients%2C%20surrogates%2C%20and%20healthcare%20costs%3A%20a%20randomized%20trial&as_occt=title&as_sauthors=%20%22JB%20Engelhardt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Zhang B</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Wright AA</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Huskamp HA</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Nilsson ME</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Maciejewski ML</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Earle CC</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Block SD</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Maciejewski PK</span>, <span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Prigerson HG</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Health care costs in the last
                  week of life: associations with end-of-life conversations</span>. <span data-id="emphasis_42"
                  class="annotation emphasis" style="position: unset;">Arch Intern Med.</span> 2009 Mar
                9;169(5):480-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20care%20costs%20in%20the%20last%20week%20of%20life%3A%20associations%20with%20end-of-life%20conversations&as_occt=title&as_sauthors=%20%22B%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Hamlet KS</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Hobgood A</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Hamar GB</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Dobbs AC</span>, <span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Rula EY</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Pope JE</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">Impact of predictive
                  model-directed end-of-life counseling for Medicare beneficiaries</span>. <span data-id="emphasis_43"
                  class="annotation emphasis" style="position: unset;">Am J Manag Care.</span> 2010
                May;16(5):379-84.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impact%20of%20predictive%20model-directed%20end-of-life%20counseling%20for%20Medicare%20beneficiaries&as_occt=title&as_sauthors=%20%22KS%20Hamlet%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Bond WF</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Kim M</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Franciskovich CM</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Weinberg JE</span>, <span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Svendsen JD</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Fehr LS</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Funk A</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Sawicki R</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Asche CV</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Advance care planning in an
                  accountable care organization is associated with increased advanced directive documentation and
                  decreased costs</span>. <span data-id="emphasis_44" class="annotation emphasis"
                  style="position: unset;">J Palliat Med.</span> 2018 Apr;21(4):489-502.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Advance%20care%20planning%20in%20an%20accountable%20care%20organization%20is%20associated%20with%20increased%20advanced%20directive%20documentation%20and%20decreased%20costs&as_occt=title&as_sauthors=%20%22WF%20Bond%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Duncan I</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Ahmed T</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Dove H</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Maxwell TL</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Medicare cost at end of
                  life</span>. <span data-id="emphasis_45" class="annotation emphasis" style="position: unset;">Am J
                  Hosp Palliat Care.</span> 2019 Aug;36(8):705-10.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Medicare%20cost%20at%20end%20of%20life&as_occt=title&as_sauthors=%20%22I%20Duncan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Klingler C</span>, in <span
                  data-id="annotation_314" class="annotation" style="position: unset;"> der Schmitten J</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Marckmann G</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">Does facilitated advance care
                  planning reduce the costs of care near the end of life? Systematic review and ethical
                  considerations</span>. <span data-id="emphasis_46" class="annotation emphasis"
                  style="position: unset;">Palliat Med.</span> 2016 May;30(5):423-33.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20facilitated%20advance%20care%20planning%20reduce%20the%20costs%20of%20care%20near%20the%20end%20of%20life%3F%20Systematic%20review%20and%20ethical%20considerations&as_occt=title&as_sauthors=%20%22C%20Klingler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Phung LH</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Barnes DE</span>, <span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Volow AM</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Li BH</span>, <span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Shirsat NR</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Sudore RL</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">English and Spanish-speaking
                  vulnerable older adults report many barriers to advance care planning</span>. <span
                  data-id="emphasis_47" class="annotation emphasis" style="position: unset;">J Am Geriatr Soc.</span>
                2021 Aug;69(8):2110-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=English%20and%20Spanish-speaking%20vulnerable%20older%20adults%20report%20many%20barriers%20to%20advance%20care%20planning&as_occt=title&as_sauthors=%20%22LH%20Phung%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_322" class="annotation" style="position: unset;"> You JJ</span>, <span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Downar J</span>, <span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Fowler RA</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Lamontagne F</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Ma IW</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Jayaraman D</span>, <span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Kryworuchko J</span>, <span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Strachan PH</span>, <span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Ilan R</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Nijjar AP</span>, <span
                  data-id="annotation_332" class="annotation" style="position: unset;"> Neary J</span>, <span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Shik J</span>, <span
                  data-id="annotation_334" class="annotation" style="position: unset;"> Brazil K</span>, <span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Patel A</span>, <span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Wiebe K</span>, <span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Albert M</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> Palepu A</span>, <span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Nouvet E</span>, <span
                  data-id="annotation_340" class="annotation" style="position: unset;"> des Ordons AR</span>, <span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Sharma N</span>, <span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Abdul-Razzak A</span>, <span
                  data-id="annotation_343" class="annotation" style="position: unset;"> Jiang X</span>, <span
                  data-id="annotation_344" class="annotation" style="position: unset;"> Day A</span>, <span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Heyland DK</span>; <span
                  data-id="strong_50" class="annotation strong" style="position: unset;">Canadian Researchers at the End
                  of Life Network. Barriers to goals of care discussions with seriously ill hospitalized patients and
                  their families: a multicenter survey of clinicians</span>. <span data-id="emphasis_48"
                  class="annotation emphasis" style="position: unset;">JAMA Intern Med.</span> 2015
                Apr;175(4):549-56.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Canadian%20Researchers%20at%20the%20End%20of%20Life%20Network.%20Barriers%20to%20goals%20of%20care%20discussions%20with%20seriously%20ill%20hospitalized%20patients%20and%20their%20families%3A%20a%20multicenter%20survey%20of%20clinicians&as_occt=title&as_sauthors=%20%22JJ%20You%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_346" class="annotation" style="position: unset;"> Billings JA</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">The need for safeguards in
                  advance care planning</span>. <span data-id="emphasis_49" class="annotation emphasis"
                  style="position: unset;">J Gen Intern Med.</span> 2012 May;27(5):595-600.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20need%20for%20safeguards%20in%20advance%20care%20planning&as_occt=title&as_sauthors=%20%22JA%20Billings%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_347" class="annotation" style="position: unset;"> Harrison KL</span>, <span
                  data-id="annotation_348" class="annotation" style="position: unset;"> Adrion ER</span>, <span
                  data-id="annotation_349" class="annotation" style="position: unset;"> Ritchie CS</span>, <span
                  data-id="annotation_350" class="annotation" style="position: unset;"> Sudore RL</span>, <span
                  data-id="annotation_351" class="annotation" style="position: unset;"> Smith AK</span>. <span
                  data-id="strong_52" class="annotation strong" style="position: unset;">Low completion and disparities
                  in advance care planning activities among older Medicare beneficiaries</span>. <span
                  data-id="emphasis_50" class="annotation emphasis" style="position: unset;">JAMA Intern Med.</span>
                2016 Dec 1;176(12):1872-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Low%20completion%20and%20disparities%20in%20advance%20care%20planning%20activities%20among%20older%20Medicare%20beneficiaries&as_occt=title&as_sauthors=%20%22KL%20Harrison%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_352" class="annotation" style="position: unset;"> Nouri S</span>, <span
                  data-id="annotation_353" class="annotation" style="position: unset;"> Lyles CR</span>, <span
                  data-id="annotation_354" class="annotation" style="position: unset;"> Rubinsky AD</span>, <span
                  data-id="annotation_355" class="annotation" style="position: unset;"> Patel K</span>, <span
                  data-id="annotation_356" class="annotation" style="position: unset;"> Desai R</span>, <span
                  data-id="annotation_357" class="annotation" style="position: unset;"> Fields J</span>, <span
                  data-id="annotation_358" class="annotation" style="position: unset;"> DeRouen MC</span>, <span
                  data-id="annotation_359" class="annotation" style="position: unset;"> Volow A</span>, <span
                  data-id="annotation_360" class="annotation" style="position: unset;"> Bibbins-Domingo K</span>, <span
                  data-id="annotation_361" class="annotation" style="position: unset;"> Sudore RL</span>. <span
                  data-id="strong_53" class="annotation strong" style="position: unset;">Evaluation of neighborhood
                  socioeconomic characteristics and advance care planning among older adults</span>. <span
                  data-id="emphasis_51" class="annotation emphasis" style="position: unset;">JAMA Netw Open.</span> 2020
                Dec 1;3(12):e2029063.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20neighborhood%20socioeconomic%20characteristics%20and%20advance%20care%20planning%20among%20older%20adults&as_occt=title&as_sauthors=%20%22S%20Nouri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_54" style="position: unset;">
            <div class="content" style="position: unset;">54&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_362" class="annotation" style="position: unset;"> Choi S</span>, <span
                  data-id="annotation_363" class="annotation" style="position: unset;"> McDonough IM</span>, <span
                  data-id="annotation_364" class="annotation" style="position: unset;"> Kim M</span>, <span
                  data-id="annotation_365" class="annotation" style="position: unset;"> Kim G</span>. <span
                  data-id="strong_54" class="annotation strong" style="position: unset;">The association between the
                  number of chronic health conditions and advance care planning varies by race/ethnicity</span>. <span
                  data-id="emphasis_52" class="annotation emphasis" style="position: unset;">Aging Ment Health.</span>
                2020 Mar;24(3):453-63.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20association%20between%20the%20number%20of%20chronic%20health%20conditions%20and%20advance%20care%20planning%20varies%20by%20race%2Fethnicity&as_occt=title&as_sauthors=%20%22S%20Choi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_55" style="position: unset;">
            <div class="content" style="position: unset;">55&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_366" class="annotation" style="position: unset;"> Khan IA</span>, <span
                  data-id="annotation_367" class="annotation" style="position: unset;"> Magnuson JA</span>, <span
                  data-id="annotation_368" class="annotation" style="position: unset;"> Ciesielka KA</span>, <span
                  data-id="annotation_369" class="annotation" style="position: unset;"> Levicoff EA</span>, <span
                  data-id="annotation_370" class="annotation" style="position: unset;"> Cohen-Rosenblum A</span>, <span
                  data-id="annotation_371" class="annotation" style="position: unset;"> Krueger CA</span>, <span
                  data-id="annotation_372" class="annotation" style="position: unset;"> Fillingham YA</span>. <span
                  data-id="strong_55" class="annotation strong" style="position: unset;">Patients from distressed
                  communities who undergo surgery for hip fragility fractures are less likely to have advanced care
                  planning documents in their electronic medical record</span>. <span data-id="emphasis_53"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2023 Feb
                1;481(2):312-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patients%20from%20distressed%20communities%20who%20undergo%20surgery%20for%20hip%20fragility%20fractures%20are%20less%20likely%20to%20have%20advanced%20care%20planning%20documents%20in%20their%20electronic%20medical%20record&as_occt=title&as_sauthors=%20%22IA%20Khan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_56" style="position: unset;">
            <div class="content" style="position: unset;">56&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_373" class="annotation" style="position: unset;"> Issa TZ</span>, <span
                  data-id="annotation_374" class="annotation" style="position: unset;"> McCurdy MA</span>, <span
                  data-id="annotation_375" class="annotation" style="position: unset;"> Lee Y</span>, <span
                  data-id="annotation_376" class="annotation" style="position: unset;"> Lambrechts MJ</span>, <span
                  data-id="annotation_377" class="annotation" style="position: unset;"> Sherman MB</span>, <span
                  data-id="annotation_378" class="annotation" style="position: unset;"> Kalra A</span>, <span
                  data-id="annotation_379" class="annotation" style="position: unset;"> Goodman P</span>, <span
                  data-id="annotation_380" class="annotation" style="position: unset;"> Canseco JA</span>, <span
                  data-id="annotation_381" class="annotation" style="position: unset;"> Hilibrand AS</span>, <span
                  data-id="annotation_382" class="annotation" style="position: unset;"> Vaccaro AR</span>, <span
                  data-id="annotation_383" class="annotation" style="position: unset;"> Schroeder GD</span>, <span
                  data-id="annotation_384" class="annotation" style="position: unset;"> Kepler CK</span>. <span
                  data-id="strong_56" class="annotation strong" style="position: unset;">The impact of socioeconomic
                  status on the presence of advance care planning documents in patients with acute cervical spinal cord
                  injury</span>. <span data-id="emphasis_54" class="annotation emphasis" style="position: unset;">J Am
                  Acad Orthop Surg.</span> 2024 Apr 15;32(8):354-61.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20impact%20of%20socioeconomic%20status%20on%20the%20presence%20of%20advance%20care%20planning%20documents%20in%20patients%20with%20acute%20cervical%20spinal%20cord%20injury&as_occt=title&as_sauthors=%20%22TZ%20Issa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_57" style="position: unset;">
            <div class="content" style="position: unset;">57&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_385" class="annotation" style="position: unset;"> Curtis JR</span>, <span
                  data-id="annotation_386" class="annotation" style="position: unset;"> Lee RY</span>, <span
                  data-id="annotation_387" class="annotation" style="position: unset;"> Brumback LC</span>, <span
                  data-id="annotation_388" class="annotation" style="position: unset;"> Kross EK</span>, <span
                  data-id="annotation_389" class="annotation" style="position: unset;"> Downey L</span>, <span
                  data-id="annotation_390" class="annotation" style="position: unset;"> Torrence J</span>, <span
                  data-id="annotation_391" class="annotation" style="position: unset;"> LeDuc N</span>, <span
                  data-id="annotation_392" class="annotation" style="position: unset;"> Mallon Andrews K</span>, <span
                  data-id="annotation_393" class="annotation" style="position: unset;"> Im J</span>, <span
                  data-id="annotation_394" class="annotation" style="position: unset;"> Heywood J</span>, <span
                  data-id="annotation_395" class="annotation" style="position: unset;"> Brown CE</span>, <span
                  data-id="annotation_396" class="annotation" style="position: unset;"> Sibley J</span>, <span
                  data-id="annotation_397" class="annotation" style="position: unset;"> Lober WB</span>, <span
                  data-id="annotation_398" class="annotation" style="position: unset;"> Cohen T</span>, <span
                  data-id="annotation_399" class="annotation" style="position: unset;"> Weiner BJ</span>, <span
                  data-id="annotation_400" class="annotation" style="position: unset;"> Khandelwal N</span>, <span
                  data-id="annotation_401" class="annotation" style="position: unset;"> Abedini NC</span>, <span
                  data-id="annotation_402" class="annotation" style="position: unset;"> Engelberg RA</span>. <span
                  data-id="strong_57" class="annotation strong" style="position: unset;">Intervention to promote
                  communication about goals of care for hospitalized patients with serious illness: a randomized
                  clinical trial</span>. <span data-id="emphasis_55" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2023 Jun 20;329(23):2028-37.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intervention%20to%20promote%20communication%20about%20goals%20of%20care%20for%20hospitalized%20patients%20with%20serious%20illness%3A%20a%20randomized%20clinical%20trial&as_occt=title&as_sauthors=%20%22JR%20Curtis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_58" style="position: unset;">
            <div class="content" style="position: unset;">58&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_403" class="annotation" style="position: unset;"> Houben CHM</span>, <span
                  data-id="annotation_404" class="annotation" style="position: unset;"> Spruit MA</span>, <span
                  data-id="annotation_405" class="annotation" style="position: unset;"> Groenen MTJ</span>, <span
                  data-id="annotation_406" class="annotation" style="position: unset;"> Wouters EFM</span>, <span
                  data-id="annotation_407" class="annotation" style="position: unset;"> Janssen DJA</span>. <span
                  data-id="strong_58" class="annotation strong" style="position: unset;">Efficacy of advance care
                  planning: a systematic review and meta-analysis</span>. <span data-id="emphasis_56"
                  class="annotation emphasis" style="position: unset;">J Am Med Dir Assoc.</span> 2014
                Jul;15(7):477-89.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20of%20advance%20care%20planning%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22CHM%20Houben%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_59" style="position: unset;">
            <div class="content" style="position: unset;">59&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_408" class="annotation" style="position: unset;"> Lakin JR</span>, <span
                  data-id="annotation_409" class="annotation" style="position: unset;"> Robinson MG</span>, <span
                  data-id="annotation_410" class="annotation" style="position: unset;"> Obermeyer Z</span>, <span
                  data-id="annotation_411" class="annotation" style="position: unset;"> Powers BW</span>, <span
                  data-id="annotation_412" class="annotation" style="position: unset;"> Block SD</span>, <span
                  data-id="annotation_413" class="annotation" style="position: unset;"> Cunningham R</span>, <span
                  data-id="annotation_414" class="annotation" style="position: unset;"> Tumblin JM</span>, <span
                  data-id="annotation_415" class="annotation" style="position: unset;"> Vogeli C</span>, <span
                  data-id="annotation_416" class="annotation" style="position: unset;"> Bernacki RE</span>. <span
                  data-id="strong_59" class="annotation strong" style="position: unset;">Prioritizing primary care
                  patients for a communication intervention using the “surprise question”: a prospective cohort
                  study</span>. <span data-id="emphasis_57" class="annotation emphasis" style="position: unset;">J Gen
                  Intern Med.</span> 2019 Aug;34(8):1467-74.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prioritizing%20primary%20care%20patients%20for%20a%20communication%20intervention%20using%20the%20%E2%80%9Csurprise%20question%E2%80%9D%3A%20a%20prospective%20cohort%20study&as_occt=title&as_sauthors=%20%22JR%20Lakin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_60" style="position: unset;">
            <div class="content" style="position: unset;">60&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_417" class="annotation" style="position: unset;"> Sudore RL</span>, <span
                  data-id="annotation_418" class="annotation" style="position: unset;"> Schillinger D</span>, <span
                  data-id="annotation_419" class="annotation" style="position: unset;"> Katen MT</span>, <span
                  data-id="annotation_420" class="annotation" style="position: unset;"> Shi Y</span>, <span
                  data-id="annotation_421" class="annotation" style="position: unset;"> Boscardin WJ</span>, <span
                  data-id="annotation_422" class="annotation" style="position: unset;"> Osua S</span>, <span
                  data-id="annotation_423" class="annotation" style="position: unset;"> Barnes DE</span>. <span
                  data-id="strong_60" class="annotation strong" style="position: unset;">Engaging diverse English- and
                  Spanish-speaking older adults in advance care planning: the PREPARE randomized clinical trial</span>.
                <span data-id="emphasis_58" class="annotation emphasis" style="position: unset;">JAMA Intern Med.</span>
                2018 Dec 1;178(12):1616-25.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Engaging%20diverse%20English-%20and%20Spanish-speaking%20older%20adults%20in%20advance%20care%20planning%3A%20the%20PREPARE%20randomized%20clinical%20trial&as_occt=title&as_sauthors=%20%22RL%20Sudore%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_61" style="position: unset;">
            <div class="content" style="position: unset;">61&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_424" class="annotation" style="position: unset;"> Burkle CM</span>, <span
                  data-id="annotation_425" class="annotation" style="position: unset;"> Mueller PS</span>, <span
                  data-id="annotation_426" class="annotation" style="position: unset;"> Swetz KM</span>, <span
                  data-id="annotation_427" class="annotation" style="position: unset;"> Hook CC</span>, <span
                  data-id="annotation_428" class="annotation" style="position: unset;"> Keegan MT</span>. <span
                  data-id="strong_61" class="annotation strong" style="position: unset;">Physician perspectives and
                  compliance with patient advance directives: the role external factors play on physician decision
                  making</span>. <span data-id="emphasis_59" class="annotation emphasis" style="position: unset;">BMC
                  Med Ethics.</span> 2012 Nov 21;13(1):31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Physician%20perspectives%20and%20compliance%20with%20patient%20advance%20directives%3A%20the%20role%20external%20factors%20play%20on%20physician%20decision%20making&as_occt=title&as_sauthors=%20%22CM%20Burkle%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_62" style="position: unset;">
            <div class="content" style="position: unset;">62&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_429" class="annotation" style="position: unset;"> Schmidt TA</span>, <span
                  data-id="annotation_430" class="annotation" style="position: unset;"> Olszewski EA</span>, <span
                  data-id="annotation_431" class="annotation" style="position: unset;"> Zive D</span>, <span
                  data-id="annotation_432" class="annotation" style="position: unset;"> Fromme EK</span>, <span
                  data-id="annotation_433" class="annotation" style="position: unset;"> Tolle SW</span>. <span
                  data-id="strong_62" class="annotation strong" style="position: unset;">The Oregon physician orders for
                  life-sustaining treatment registry: a preliminary study of emergency medical services
                  utilization</span>. <span data-id="emphasis_60" class="annotation emphasis" style="position: unset;">J
                  Emerg Med.</span> 2013 Apr;44(4):796-805.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Oregon%20physician%20orders%20for%20life-sustaining%20treatment%20registry%3A%20a%20preliminary%20study%20of%20emergency%20medical%20services%20utilization&as_occt=title&as_sauthors=%20%22TA%20Schmidt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_63" style="position: unset;">
            <div class="content" style="position: unset;">63&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_434" class="annotation" style="position: unset;"> Richardson DK</span>, <span
                  data-id="annotation_435" class="annotation" style="position: unset;"> Fromme E</span>, <span
                  data-id="annotation_436" class="annotation" style="position: unset;"> Zive D</span>, <span
                  data-id="annotation_437" class="annotation" style="position: unset;"> Fu R</span>, <span
                  data-id="annotation_438" class="annotation" style="position: unset;"> Newgard CD</span>. <span
                  data-id="strong_63" class="annotation strong" style="position: unset;">Concordance of out-of-hospital
                  and emergency department cardiac arrest resuscitation with documented end-of-life choices in
                  Oregon</span>. <span data-id="emphasis_61" class="annotation emphasis" style="position: unset;">Ann
                  Emerg Med.</span> 2014 Apr;63(4):375-83.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Concordance%20of%20out-of-hospital%20and%20emergency%20department%20cardiac%20arrest%20resuscitation%20with%20documented%20end-of-life%20choices%20in%20Oregon&as_occt=title&as_sauthors=%20%22DK%20Richardson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_64" style="position: unset;">
            <div class="content" style="position: unset;">64&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_439" class="annotation" style="position: unset;"> Bomba PA</span>, <span
                  data-id="annotation_440" class="annotation" style="position: unset;"> Orem K</span>. <span
                  data-id="strong_64" class="annotation strong" style="position: unset;">Lessons learned from New York’s
                  community approach to advance care planning and MOLAT</span>. <span data-id="emphasis_62"
                  class="annotation emphasis" style="position: unset;">Ann Palliat Med.</span> 2015
                Jan;4(1):10-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lessons%20learned%20from%20New%20York%E2%80%99s%20community%20approach%20to%20advance%20care%20planning%20and%20MOLAT&as_occt=title&as_sauthors=%20%22PA%20Bomba%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_65" style="position: unset;">
            <div class="content" style="position: unset;">65&nbsp;<span class="text" style="position: unset;">U.S.
                Centers for Medicare & Medicaid Services. <span data-id="strong_65" class="annotation strong"
                  style="position: unset;">ICN 909289. 2023</span>. Accessed 2024 Oct 10. <a
                  href="https://www.cms.gov/outreach-and-education/medicare-learning-network-mln/mlnproducts/mln-publications-items/icn909289"
                  target="_blank" data-id="link_4" class="link"
                  style="position: unset;">https://www.cms.gov/outreach-and-education/medicare-learning-network-mln/mlnproducts/mln-publications-items/icn909289</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=ICN%20909289.%202023&as_occt=title&as_sauthors=%20%22U.S.%20Centers%20for%20Medicare%20%26%20Medicaid%20Services%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_66" style="position: unset;">
            <div class="content" style="position: unset;">66&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_441" class="annotation" style="position: unset;"> Luth EA</span>, <span
                  data-id="annotation_442" class="annotation" style="position: unset;"> Manful A</span>, <span
                  data-id="annotation_443" class="annotation" style="position: unset;"> Weissman JS</span>, <span
                  data-id="annotation_444" class="annotation" style="position: unset;"> Reich A</span>, <span
                  data-id="annotation_445" class="annotation" style="position: unset;"> Ladin K</span>, <span
                  data-id="annotation_446" class="annotation" style="position: unset;"> Semco R</span>, <span
                  data-id="annotation_447" class="annotation" style="position: unset;"> Ganguli I</span>. <span
                  data-id="strong_66" class="annotation strong" style="position: unset;">Practice billing for Medicare
                  advance care planning across the USA</span>. <span data-id="emphasis_63" class="annotation emphasis"
                  style="position: unset;">J Gen Intern Med.</span> 2022 Nov;37(15):3869-76.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Practice%20billing%20for%20Medicare%20advance%20care%20planning%20across%20the%20USA&as_occt=title&as_sauthors=%20%22EA%20Luth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_67" style="position: unset;">
            <div class="content" style="position: unset;">67&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_448" class="annotation" style="position: unset;"> Palmer MK</span>, <span
                  data-id="annotation_449" class="annotation" style="position: unset;"> Jacobson M</span>, <span
                  data-id="annotation_450" class="annotation" style="position: unset;"> Enguidanos S</span>. <span
                  data-id="strong_67" class="annotation strong" style="position: unset;">Advance care planning for
                  Medicare beneficiaries increased substantially, but prevalence remained low</span>. <span
                  data-id="emphasis_64" class="annotation emphasis" style="position: unset;">Health Aff
                  (Millwood).</span> 2021 Apr;40(4):613-21.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Advance%20care%20planning%20for%20Medicare%20beneficiaries%20increased%20substantially%2C%20but%20prevalence%20remained%20low&as_occt=title&as_sauthors=%20%22MK%20Palmer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_68" style="position: unset;">
            <div class="content" style="position: unset;">68&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_451" class="annotation" style="position: unset;"> Pelland K</span>, <span
                  data-id="annotation_452" class="annotation" style="position: unset;"> Morphis B</span>, <span
                  data-id="annotation_453" class="annotation" style="position: unset;"> Harris D</span>, <span
                  data-id="annotation_454" class="annotation" style="position: unset;"> Gardner R</span>. <span
                  data-id="strong_68" class="annotation strong" style="position: unset;">Assessment of first-year use of
                  Medicare’s advance care planning billing codes</span>. <span data-id="emphasis_65"
                  class="annotation emphasis" style="position: unset;">JAMA Intern Med.</span> 2019 Jun
                1;179(6):827-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Assessment%20of%20first-year%20use%20of%20Medicare%E2%80%99s%20advance%20care%20planning%20billing%20codes&as_occt=title&as_sauthors=%20%22K%20Pelland%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_69" style="position: unset;">
            <div class="content" style="position: unset;">69&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_455" class="annotation" style="position: unset;"> Belanger E</span>, <span
                  data-id="annotation_456" class="annotation" style="position: unset;"> Loomer L</span>, <span
                  data-id="annotation_457" class="annotation" style="position: unset;"> Teno JM</span>, <span
                  data-id="annotation_458" class="annotation" style="position: unset;"> Mitchell SL</span>, <span
                  data-id="annotation_459" class="annotation" style="position: unset;"> Adhikari D</span>, <span
                  data-id="annotation_460" class="annotation" style="position: unset;"> Gozalo PL</span>. <span
                  data-id="strong_69" class="annotation strong" style="position: unset;">Early utilization patterns of
                  the new Medicare procedure codes for advance care planning</span>. <span data-id="emphasis_66"
                  class="annotation emphasis" style="position: unset;">JAMA Intern Med.</span> 2019 Jun
                1;179(6):829-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Early%20utilization%20patterns%20of%20the%20new%20Medicare%20procedure%20codes%20for%20advance%20care%20planning&as_occt=title&as_sauthors=%20%22E%20Belanger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_70" style="position: unset;">
            <div class="content" style="position: unset;">70&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_461" class="annotation" style="position: unset;"> Tsugawa Y</span>, <span
                  data-id="annotation_462" class="annotation" style="position: unset;"> Kato H</span>, <span
                  data-id="annotation_463" class="annotation" style="position: unset;"> Jha AK</span>, <span
                  data-id="annotation_464" class="annotation" style="position: unset;"> Wenger NS</span>, <span
                  data-id="annotation_465" class="annotation" style="position: unset;"> Zingmond DS</span>, <span
                  data-id="annotation_466" class="annotation" style="position: unset;"> Gross N</span>, <span
                  data-id="annotation_467" class="annotation" style="position: unset;"> Jena AB</span>. <span
                  data-id="strong_70" class="annotation strong" style="position: unset;">Characteristics of physicians
                  who adopted Medicare’s new advance care planning codes in the first year</span>. <span
                  data-id="emphasis_67" class="annotation emphasis" style="position: unset;">J Gen Intern Med.</span>
                2020 Jun;35(6):1914-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Characteristics%20of%20physicians%20who%20adopted%20Medicare%E2%80%99s%20new%20advance%20care%20planning%20codes%20in%20the%20first%20year&as_occt=title&as_sauthors=%20%22Y%20Tsugawa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_71" style="position: unset;">
            <div class="content" style="position: unset;">71&nbsp;<span class="text" style="position: unset;">GBD 2019
                Fracture Collaborators. <span data-id="strong_71" class="annotation strong"
                  style="position: unset;">Global, regional, and national burden of bone fractures in 204 countries and
                  territories, 1990-2019: a systematic analysis from the Global Burden of Disease Study 2019</span>.
                <span data-id="emphasis_68" class="annotation emphasis" style="position: unset;">Lancet Healthy
                  Longev.</span> 2021 Sep;2(9):e580-92.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Global%2C%20regional%2C%20and%20national%20burden%20of%20bone%20fractures%20in%20204%20countries%20and%20territories%2C%201990-2019%3A%20a%20systematic%20analysis%20from%20the%20Global%20Burden%20of%20Disease%20Study%202019&as_occt=title&as_sauthors=%20%22GBD%202019%20Fracture%20Collaborators%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_72" style="position: unset;">
            <div class="content" style="position: unset;">72&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_468" class="annotation" style="position: unset;"> Dunn RH</span>, <span
                  data-id="annotation_469" class="annotation" style="position: unset;"> Ahn J</span>, <span
                  data-id="annotation_470" class="annotation" style="position: unset;"> Bernstein J</span>. <span
                  data-id="strong_72" class="annotation strong" style="position: unset;">End-of-life care planning and
                  fragility fractures of the hip: are we missing a valuable opportunity?</span><span
                  data-id="emphasis_69" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2016 Jul;474(7):1736-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=End-of-life%20care%20planning%20and%20fragility%20fractures%20of%20the%20hip%3A%20are%20we%20missing%20a%20valuable%20opportunity%3F&as_occt=title&as_sauthors=%20%22RH%20Dunn%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_73" style="position: unset;">
            <div class="content" style="position: unset;">73&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_471" class="annotation" style="position: unset;"> Kata A</span>, <span
                  data-id="annotation_472" class="annotation" style="position: unset;"> Cenzer I</span>, <span
                  data-id="annotation_473" class="annotation" style="position: unset;"> Sudore RL</span>, <span
                  data-id="annotation_474" class="annotation" style="position: unset;"> Covinsky KE</span>, <span
                  data-id="annotation_475" class="annotation" style="position: unset;"> Tang VL</span>. <span
                  data-id="strong_73" class="annotation strong" style="position: unset;">Advance care planning prior to
                  death in older adults with hip fracture</span>. <span data-id="emphasis_70"
                  class="annotation emphasis" style="position: unset;">J Gen Intern Med.</span> 2020
                Jul;35(7):1946-53.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Advance%20care%20planning%20prior%20to%20death%20in%20older%20adults%20with%20hip%20fracture&as_occt=title&as_sauthors=%20%22A%20Kata%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_74" style="position: unset;">
            <div class="content" style="position: unset;">74&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_476" class="annotation" style="position: unset;"> Kata A</span>, <span
                  data-id="annotation_477" class="annotation" style="position: unset;"> Sudore R</span>, <span
                  data-id="annotation_478" class="annotation" style="position: unset;"> Finlayson E</span>, <span
                  data-id="annotation_479" class="annotation" style="position: unset;"> Broering JM</span>, <span
                  data-id="annotation_480" class="annotation" style="position: unset;"> Ngo S</span>, <span
                  data-id="annotation_481" class="annotation" style="position: unset;"> Tang VL</span>. <span
                  data-id="strong_74" class="annotation strong" style="position: unset;">Increasing advance care
                  planning using a surgical optimization program for older adults</span>. <span data-id="emphasis_71"
                  class="annotation emphasis" style="position: unset;">J Am Geriatr Soc.</span> 2018
                Oct;66(10):2017-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Increasing%20advance%20care%20planning%20using%20a%20surgical%20optimization%20program%20for%20older%20adults&as_occt=title&as_sauthors=%20%22A%20Kata%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_75" style="position: unset;">
            <div class="content" style="position: unset;">75&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_482" class="annotation" style="position: unset;"> Marks S</span>, <span
                  data-id="annotation_483" class="annotation" style="position: unset;"> Wanner JP</span>, <span
                  data-id="annotation_484" class="annotation" style="position: unset;"> Cobb AS</span>, <span
                  data-id="annotation_485" class="annotation" style="position: unset;"> Swetz KM</span>, <span
                  data-id="annotation_486" class="annotation" style="position: unset;"> Lange GM</span>. <span
                  data-id="strong_75" class="annotation strong" style="position: unset;">Surgery without a surrogate:
                  the low prevalence of healthcare power of attorney documents among preoperative patients</span>. <span
                  data-id="emphasis_72" class="annotation emphasis" style="position: unset;">Hosp Pract(</span> (1995).
                2019 Feb;47(1):28-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgery%20without%20a%20surrogate%3A%20the%20low%20prevalence%20of%20healthcare%20power%20of%20attorney%20documents%20among%20preoperative%20patients&as_occt=title&as_sauthors=%20%22S%20Marks%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_76" style="position: unset;">
            <div class="content" style="position: unset;">76&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_487" class="annotation" style="position: unset;"> Shah SK</span>, <span
                  data-id="annotation_488" class="annotation" style="position: unset;"> Manful A</span>, <span
                  data-id="annotation_489" class="annotation" style="position: unset;"> Reich AJ</span>, <span
                  data-id="annotation_490" class="annotation" style="position: unset;"> Semco RS</span>, <span
                  data-id="annotation_491" class="annotation" style="position: unset;"> Tjia J</span>, <span
                  data-id="annotation_492" class="annotation" style="position: unset;"> Ladin K</span>, <span
                  data-id="annotation_493" class="annotation" style="position: unset;"> Weissman JS</span>. <span
                  data-id="strong_76" class="annotation strong" style="position: unset;">Advance care planning among
                  Medicare beneficiaries with dementia undergoing surgery</span>. <span data-id="emphasis_73"
                  class="annotation emphasis" style="position: unset;">J Am Geriatr Soc.</span> 2021
                Aug;69(8):2273-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Advance%20care%20planning%20among%20Medicare%20beneficiaries%20with%20dementia%20undergoing%20surgery&as_occt=title&as_sauthors=%20%22SK%20Shah%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_77" style="position: unset;">
            <div class="content" style="position: unset;">77&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_494" class="annotation" style="position: unset;"> Colley A</span>, <span
                  data-id="annotation_495" class="annotation" style="position: unset;"> Lin JA</span>, <span
                  data-id="annotation_496" class="annotation" style="position: unset;"> Pierce L</span>, <span
                  data-id="annotation_497" class="annotation" style="position: unset;"> Finlayson E</span>, <span
                  data-id="annotation_498" class="annotation" style="position: unset;"> Sudore RL</span>, <span
                  data-id="annotation_499" class="annotation" style="position: unset;"> Wick E</span>. <span
                  data-id="strong_77" class="annotation strong" style="position: unset;">Missed opportunities and health
                  disparities for advance care planning before elective surgery in older adults</span>. <span
                  data-id="emphasis_74" class="annotation emphasis" style="position: unset;">JAMA Surg.</span> 2022 Oct
                1;157(10):e223687.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Missed%20opportunities%20and%20health%20disparities%20for%20advance%20care%20planning%20before%20elective%20surgery%20in%20older%20adults&as_occt=title&as_sauthors=%20%22A%20Colley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_78" style="position: unset;">
            <div class="content" style="position: unset;">78&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_500" class="annotation" style="position: unset;"> Mariconda M</span>, <span
                  data-id="annotation_501" class="annotation" style="position: unset;"> Costa GG</span>, <span
                  data-id="annotation_502" class="annotation" style="position: unset;"> Cerbasi S</span>, <span
                  data-id="annotation_503" class="annotation" style="position: unset;"> Recano P</span>, <span
                  data-id="annotation_504" class="annotation" style="position: unset;"> Orabona G</span>, <span
                  data-id="annotation_505" class="annotation" style="position: unset;"> Gambacorta M</span>, <span
                  data-id="annotation_506" class="annotation" style="position: unset;"> Misasi M</span>. <span
                  data-id="strong_78" class="annotation strong" style="position: unset;">Factors predicting mobility and
                  the change in activities of daily living after hip fracture: a 1-year prospective cohort study</span>.
                <span data-id="emphasis_75" class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span>
                2016 Feb;30(2):71-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20predicting%20mobility%20and%20the%20change%20in%20activities%20of%20daily%20living%20after%20hip%20fracture%3A%20a%201-year%20prospective%20cohort%20study&as_occt=title&as_sauthors=%20%22M%20Mariconda%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_79" style="position: unset;">
            <div class="content" style="position: unset;">79&nbsp;<span class="text" style="position: unset;">American
                College of Surgeons. <span data-id="strong_79" class="annotation strong"
                  style="position: unset;">Statement on advance directives by patients: “do not resuscitate” in the
                  operating room</span>. <span data-id="emphasis_76" class="annotation emphasis"
                  style="position: unset;">Bull Am Coll Surg.</span> 2014 Jan;99(1):42-3.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Statement%20on%20advance%20directives%20by%20patients%3A%20%E2%80%9Cdo%20not%20resuscitate%E2%80%9D%20in%20the%20operating%20room&as_occt=title&as_sauthors=%20%22American%20College%20of%20Surgeons%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_80" style="position: unset;">
            <div class="content" style="position: unset;">80&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_507" class="annotation" style="position: unset;"> Mannino R</span>, <span
                  data-id="annotation_508" class="annotation" style="position: unset;"> Zuelzer W</span>, <span
                  data-id="annotation_509" class="annotation" style="position: unset;"> McDaniel C</span>, <span
                  data-id="annotation_510" class="annotation" style="position: unset;"> Lyckholm L</span>. <span
                  data-id="strong_80" class="annotation strong" style="position: unset;">Advance directives and
                  resuscitation issues in the care of patients in orthopaedic surgery</span>. <span
                  data-id="emphasis_77" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2008 Sep;90(9):2037-42.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1057597" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_81" style="position: unset;">
            <div class="content" style="position: unset;">81&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_511" class="annotation" style="position: unset;"> Cooper Z</span>, <span
                  data-id="annotation_512" class="annotation" style="position: unset;"> Courtwright A</span>, <span
                  data-id="annotation_513" class="annotation" style="position: unset;"> Karlage A</span>, <span
                  data-id="annotation_514" class="annotation" style="position: unset;"> Gawande A</span>, <span
                  data-id="annotation_515" class="annotation" style="position: unset;"> Block S</span>. <span
                  data-id="strong_81" class="annotation strong" style="position: unset;">Pitfalls in communication that
                  lead to nonbeneficial emergency surgery in elderly patients with serious illness: description of the
                  problem and elements of a solution</span>. <span data-id="emphasis_78" class="annotation emphasis"
                  style="position: unset;">Ann Surg.</span> 2014 Dec;260(6):949-57.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pitfalls%20in%20communication%20that%20lead%20to%20nonbeneficial%20emergency%20surgery%20in%20elderly%20patients%20with%20serious%20illness%3A%20description%20of%20the%20problem%20and%20elements%20of%20a%20solution&as_occt=title&as_sauthors=%20%22Z%20Cooper%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_82" style="position: unset;">
            <div class="content" style="position: unset;">82&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_516" class="annotation" style="position: unset;"> Cauley CE</span>, <span
                  data-id="annotation_517" class="annotation" style="position: unset;"> Block SD</span>, <span
                  data-id="annotation_518" class="annotation" style="position: unset;"> Koritsanszky LA</span>, <span
                  data-id="annotation_519" class="annotation" style="position: unset;"> Gass JD</span>, <span
                  data-id="annotation_520" class="annotation" style="position: unset;"> Frydman JL</span>, <span
                  data-id="annotation_521" class="annotation" style="position: unset;"> Nurudeen SM</span>, <span
                  data-id="annotation_522" class="annotation" style="position: unset;"> Bernacki RE</span>, <span
                  data-id="annotation_523" class="annotation" style="position: unset;"> Cooper Z</span>. <span
                  data-id="strong_82" class="annotation strong" style="position: unset;">Surgeons’ perspectives on
                  avoiding nonbeneficial treatments in seriously ill older patients with surgical emergencies: a
                  qualitative study</span>. <span data-id="emphasis_79" class="annotation emphasis"
                  style="position: unset;">J Palliat Med.</span> 2016 May;19(5):529-37.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgeons%E2%80%99%20perspectives%20on%20avoiding%20nonbeneficial%20treatments%20in%20seriously%20ill%20older%20patients%20with%20surgical%20emergencies%3A%20a%20qualitative%20study&as_occt=title&as_sauthors=%20%22CE%20Cauley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_83" style="position: unset;">
            <div class="content" style="position: unset;">83&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_524" class="annotation" style="position: unset;"> Chi S</span>, <span
                  data-id="annotation_525" class="annotation" style="position: unset;"> Kim S</span>, <span
                  data-id="annotation_526" class="annotation" style="position: unset;"> Reuter M</span>, <span
                  data-id="annotation_527" class="annotation" style="position: unset;"> Ponzillo K</span>, <span
                  data-id="annotation_528" class="annotation" style="position: unset;"> Oliver DP</span>, <span
                  data-id="annotation_529" class="annotation" style="position: unset;"> Foraker R</span>, <span
                  data-id="annotation_530" class="annotation" style="position: unset;"> Heard K</span>, <span
                  data-id="annotation_531" class="annotation" style="position: unset;"> Liu J</span>, <span
                  data-id="annotation_532" class="annotation" style="position: unset;"> Pitzer K</span>, <span
                  data-id="annotation_533" class="annotation" style="position: unset;"> White P</span>, <span
                  data-id="annotation_534" class="annotation" style="position: unset;"> Moore N</span>. <span
                  data-id="strong_83" class="annotation strong" style="position: unset;">Advanced care planning for
                  hospitalized patients following clinician notification of patient mortality by a machine learning
                  algorithm</span>. <span data-id="emphasis_80" class="annotation emphasis"
                  style="position: unset;">JAMA Netw Open.</span> 2023 Apr 3;6(4):e238795.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Advanced%20care%20planning%20for%20hospitalized%20patients%20following%20clinician%20notification%20of%20patient%20mortality%20by%20a%20machine%20learning%20algorithm&as_occt=title&as_sauthors=%20%22S%20Chi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_84" style="position: unset;">
            <div class="content" style="position: unset;">84&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_535" class="annotation" style="position: unset;"> Manz CR</span>, <span
                  data-id="annotation_536" class="annotation" style="position: unset;"> Parikh RB</span>, <span
                  data-id="annotation_537" class="annotation" style="position: unset;"> Small DS</span>, <span
                  data-id="annotation_538" class="annotation" style="position: unset;"> Evans CN</span>, <span
                  data-id="annotation_539" class="annotation" style="position: unset;"> Chivers C</span>, <span
                  data-id="annotation_540" class="annotation" style="position: unset;"> Regli SH</span>, <span
                  data-id="annotation_541" class="annotation" style="position: unset;"> Hanson CW</span>, <span
                  data-id="annotation_542" class="annotation" style="position: unset;"> Bekelman JE</span>, <span
                  data-id="annotation_543" class="annotation" style="position: unset;"> Rareshide CAL</span>, <span
                  data-id="annotation_544" class="annotation" style="position: unset;"> O’Connor N</span>, <span
                  data-id="annotation_545" class="annotation" style="position: unset;"> Schuchter LM</span>, <span
                  data-id="annotation_546" class="annotation" style="position: unset;"> Shulman LN</span>, <span
                  data-id="annotation_547" class="annotation" style="position: unset;"> Patel MS</span>. <span
                  data-id="strong_84" class="annotation strong" style="position: unset;">Effect of integrating machine
                  learning mortality estimates with behavioral nudges to clinicians on serious illness conversations
                  among patients with cancer: a stepped-wedge cluster randomized clinical trial</span>. <span
                  data-id="emphasis_81" class="annotation emphasis" style="position: unset;">JAMA Oncol.</span> 2020 Dec
                1;6(12):e204759.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20integrating%20machine%20learning%20mortality%20estimates%20with%20behavioral%20nudges%20to%20clinicians%20on%20serious%20illness%20conversations%20among%20patients%20with%20cancer%3A%20a%20stepped-wedge%20cluster%20randomized%20clinical%20trial&as_occt=title&as_sauthors=%20%22CR%20Manz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_85" style="position: unset;">
            <div class="content" style="position: unset;">85&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_548" class="annotation" style="position: unset;"> Gabbard J</span>, <span
                  data-id="annotation_549" class="annotation" style="position: unset;"> Pajewski NM</span>, <span
                  data-id="annotation_550" class="annotation" style="position: unset;"> Callahan KE</span>, <span
                  data-id="annotation_551" class="annotation" style="position: unset;"> Dharod A</span>, <span
                  data-id="annotation_552" class="annotation" style="position: unset;"> Foley KL</span>, <span
                  data-id="annotation_553" class="annotation" style="position: unset;"> Ferris K</span>, <span
                  data-id="annotation_554" class="annotation" style="position: unset;"> Moses A</span>, <span
                  data-id="annotation_555" class="annotation" style="position: unset;"> Willard J</span>, <span
                  data-id="annotation_556" class="annotation" style="position: unset;"> Williamson JD</span>. <span
                  data-id="strong_85" class="annotation strong" style="position: unset;">Effectiveness of a nurse-led
                  multidisciplinary intervention vs usual care on advance care planning for vulnerable older adults in
                  an accountable care organization: a randomized clinical trial</span>. <span data-id="emphasis_82"
                  class="annotation emphasis" style="position: unset;">JAMA Intern Med.</span> 2021 Mar
                1;181(3):361-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effectiveness%20of%20a%20nurse-led%20multidisciplinary%20intervention%20vs%20usual%20care%20on%20advance%20care%20planning%20for%20vulnerable%20older%20adults%20in%20an%20accountable%20care%20organization%3A%20a%20randomized%20clinical%20trial&as_occt=title&as_sauthors=%20%22J%20Gabbard%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_86" style="position: unset;">
            <div class="content" style="position: unset;">86&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_557" class="annotation" style="position: unset;"> Taylor LJ</span>, <span
                  data-id="annotation_558" class="annotation" style="position: unset;"> Nabozny MJ</span>, <span
                  data-id="annotation_559" class="annotation" style="position: unset;"> Steffens NM</span>, <span
                  data-id="annotation_560" class="annotation" style="position: unset;"> Tucholka JL</span>, <span
                  data-id="annotation_561" class="annotation" style="position: unset;"> Brasel KJ</span>, <span
                  data-id="annotation_562" class="annotation" style="position: unset;"> Johnson SK</span>, <span
                  data-id="annotation_563" class="annotation" style="position: unset;"> Zelenski A</span>, <span
                  data-id="annotation_564" class="annotation" style="position: unset;"> Rathouz PJ</span>, <span
                  data-id="annotation_565" class="annotation" style="position: unset;"> Zhao Q</span>, <span
                  data-id="annotation_566" class="annotation" style="position: unset;"> Kwekkeboom KL</span>, <span
                  data-id="annotation_567" class="annotation" style="position: unset;"> Campbell TC</span>, <span
                  data-id="annotation_568" class="annotation" style="position: unset;"> Schwarze ML</span>. <span
                  data-id="strong_86" class="annotation strong" style="position: unset;">A framework to improve surgeon
                  communication in high-stakes surgical decisions: best case/worst case</span>. <span
                  data-id="emphasis_83" class="annotation emphasis" style="position: unset;">JAMA Surg.</span> 2017 Jun
                1;152(6):531-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20framework%20to%20improve%20surgeon%20communication%20in%20high-stakes%20surgical%20decisions%3A%20best%20case%2Fworst%20case&as_occt=title&as_sauthors=%20%22LJ%20Taylor%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_87" style="position: unset;">
            <div class="content" style="position: unset;">87&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_569" class="annotation" style="position: unset;"> Wright JG</span>. <span
                  data-id="strong_87" class="annotation strong" style="position: unset;">Revised grades of
                  recommendation for summaries or reviews of orthopaedic surgical studies</span>. <span
                  data-id="emphasis_84" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2006 May;88(5):1161-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Revised%20grades%20of%20recommendation%20for%20summaries%20or%20reviews%20of%20orthopaedic%20surgical%20studies&as_occt=title&as_sauthors=%20%22JG%20Wright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D47c92ab7-56d9-4978-85ed-4f4c3065fd02%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Current Concepts Review</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 15, 2025;
                      107 (2): 209</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00357</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 21, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_85"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Warren
                            Alpert Medical School, Brown University, Providence, Rhode Island</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;47c92ab7-56d9-4978-85ed-4f4c3065fd02&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=47c92ab7-56d9-4978-85ed-4f4c3065fd02&type=pdf&name=JBJS.24.00357.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=47c92ab7-56d9-4978-85ed-4f4c3065fd02&type=pdf&name=JBJS.24.00357.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_4" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_88"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_89" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I305" target="_blank" data-id="link_5"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I305</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;47c92ab7-56d9-4978-85ed-4f4c3065fd02&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=47c92ab7-56d9-4978-85ed-4f4c3065fd02&type=zip&name=JBJS.24.00357.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kathryn R. Segal, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9306-799X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9306-799X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lauren E. Piana, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-3407-2065" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-3407-2065</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nadia Mujahid, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2991-0924" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2991-0924</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Brian Mikolasko, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9841-6789" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9841-6789</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Eren O. Kuris, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2303-7610" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2303-7610</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alan H. Daniels, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9597-4139" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9597-4139</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Julia A. Katarincic, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:julie_katarincic@brown.edu" class=""
                    style="position: unset;">julie_katarincic@brown.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1509-6813" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1509-6813</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Warren Alpert Medical School, Brown
                University, Providence, Rhode Island</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Division of Geriatric Medicine, Department of Medicine, Warren Alpert Medical
                School, Brown University, Providence, Rhode Island</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Division of Palliative Medicine, Department of Medicine, Warren Alpert Medical
                School, Brown University, Providence, Rhode Island</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 38057.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
