{
	"name": "memorangicons",
	"css_prefix_text": "icon-",
	"css_use_suffix": false,
	"hinting": true,
	"units_per_em": 1000,
	"ascent": 850,
	"glyphs": [
		{
			"uid": "d9b4a9517d4ac96b1a4da0b3c6c3e259",
			"css": "study-pack-outline",
			"code": 59394,
			"src": "custom_icons",
			"selected": true,
			"svg": {
				"path": "M911.8 777.9L527.7 991.3C519.9 997.1 510.2 1000 500 1000 489.8 1000 480.1 997.1 472.3 991.3L88.2 777.9C72.4 769.7 62.5 753.4 62.5 735.6L62.5 303C62.5 284.7 72.7 268.9 88.2 260.7L257.5 166.7C257.1 172 257 177.4 257 182.8 257 212.5 262.4 240.8 272.3 267L210.3 303 500 464 816 303 731 257.8C738.8 234.2 743 209 743 182.8 743 177.4 742.9 172 742.5 166.7L911.8 260.7C927.3 268.9 937.5 284.7 937.5 303L937.5 735.6C937.5 753.9 927.3 769.8 911.8 777.9ZM451.4 869.7L451.4 547.2 159.7 385.2 159.7 707.3ZM840.3 707.3L840.3 385.2 548.6 547.2 548.6 869.7ZM500 248.5L448.5 281.3 462.1 219.6 416.7 178.1 476.6 172.8 500 114.6 523.4 172.8 583.3 178.1 537.9 219.6 551.5 281.3M687.5 142.9L552.7 130.9 500 0 447.3 130.9 312.5 142.9 414.7 236.2 384.1 375 500 301.4 615.9 375 585.1 236.2Z",
				"width": 1000
			},
			"search": ["study-pack-outline"]
		},
		{
			"uid": "8a6f45d1266916680c0d80091d7eb821",
			"css": "study-pack",
			"code": 59393,
			"src": "custom_icons",
			"selected": true,
			"svg": {
				"path": "M911.8 777.9L527.7 991.3C519.9 997.1 510.2 1000 500 1000 489.8 1000 480.1 997.1 472.3 991.3L88.2 777.9C72.4 769.7 62.5 753.4 62.5 735.6L62.5 303C62.5 284.7 72.7 268.9 88.2 260.7L257.5 166.7C257.1 172 257 177.4 257 182.8 257 212.5 262.4 240.8 272.3 267L210.3 303 500 464 816 303 731 257.8C738.8 234.2 743 209 743 182.8 743 177.4 742.9 172 742.5 166.7L911.8 260.7C927.3 268.9 937.5 284.7 937.5 303L937.5 735.6C937.5 753.9 927.3 769.8 911.8 777.9ZM451.4 869.7L451.4 547.2 159.7 385.2 159.7 707.3ZM500 301.4L615.9 375 585.1 236.2 687.5 142.9 552.7 130.7 500 0 447.3 130.7 312.5 142.9 414.7 236.2 384.1 375Z",
				"width": 1000
			},
			"search": ["study-pack"]
		},
		{
			"uid": "af869ef6134e4466977ab3f30ae447ec",
			"css": "question-set",
			"code": 59392,
			"src": "custom_icons",
			"selected": true,
			"svg": {
				"path": "M791.7 129.2L617.5 129.2C600 78.4 554.2 41.7 500 41.7 445.8 41.7 400 78.4 382.5 129.2L208.3 129.2C162.5 129.2 125 168.5 125 216.7L125 829.2C125 877.3 162.5 916.7 208.3 916.7L791.7 916.7C837.5 916.7 875 877.3 875 829.2L875 216.7C875 168.5 837.5 129.2 791.7 129.2M500 129.2C522.9 129.2 541.7 148.8 541.7 172.9 541.7 197 522.9 216.7 500 216.7 477.1 216.7 458.3 197 458.3 172.9 458.3 148.8 477.1 129.2 500 129.2M291.7 270.8L708.3 270.8 708.3 216.7 791.7 216.7 791.7 829.2 208.3 829.2 208.3 216.7 291.7 216.7M526 391L734.4 391 734.4 453.5 526 453.5 526 391M526 632.6L734.4 632.6 734.4 695.1 526 695.1 526 632.6M352.8 495.1L276 416.4 307 384.7 352.8 431.5 453.5 328.5 484.4 360.2ZM311 587.9L283.3 615.6 338.9 671.2 283.3 726.9 311 754.6 366.6 699 422.2 754.6 449.9 726.9 394.3 671.2 449.9 615.6 422.2 587.9 366.6 643.5 311 587.9",
				"width": 1000
			},
			"search": ["question-set"]
		},
		{
			"uid": "cdfe2be954e90af817de344f7fe08fb4",
			"css": "study-set",
			"code": 59395,
			"src": "custom_icons",
			"selected": true,
			"svg": {
				"path": "M791.7 838L333.3 838 333.3 287 791.7 287M791.7 208.3L333.3 208.3C287.3 208.3 250 243.6 250 287L250 838C250 881.4 287.3 916.7 333.3 916.7L791.7 916.7C837.7 916.7 875 881.4 875 838L875 287C875 243.6 837.7 208.3 791.7 208.3M747.5 446.8L703.3 405.1 500 597.1 411.7 513.7 367.5 555.4 500 680.5ZM625 162L166.7 162 166.7 713 250 713 250 791.7 166.7 791.7C120.6 791.7 83.3 756.4 83.3 713L83.3 162C83.3 118.6 120.6 83.3 166.7 83.3L625 83.3C671 83.3 708.3 118.6 708.3 162L708.3 201.4 625 201.4Z",
				"width": 1000
			},
			"search": ["study-set"]
		},
		{
			"uid": "scdfe2be954e90af817de344f7fe08fb4",
			"css": "question",
			"code": 59388,
			"src": "custom_icons",
			"selected": true,
			"svg": {
				"path": "M2 4H0V18C0 19.1 0.9 20 2 20H16V18H2V4ZM18 0H6C4.9 0 4 0.9 4 2V14C4 15.1 4.9 16 6 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 14H6V2H18V14ZM11.51 8.16C11.92 7.43 12.69 7 13.14 6.36C13.62 5.68 13.35 4.42 12 4.42C11.12 4.42 10.68 5.09 10.5 5.65L9.13 5.08C9.51 3.96 10.52 3 11.99 3C13.22 3 14.07 3.56 14.5 4.26C14.87 4.86 15.08 5.99 14.51 6.83C13.88 7.76 13.28 8.04 12.95 8.64C12.82 8.88 12.77 9.04 12.77 9.82H11.25C11.26 9.41 11.19 8.74 11.51 8.16ZM10.95 11.95C10.95 11.36 11.42 10.91 12 10.91C12.59 10.91 13.04 11.36 13.04 11.95C13.04 12.53 12.6 13 12 13C11.42 13 10.95 12.53 10.95 11.95Z",
				"width": 24
			},
			"search": ["question"]
		}
	]
}
