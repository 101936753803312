const term = {
	inline: true,
	config: {
		html: false,
		xhtmlOut: false,
		breaks: false,
		linkify: false,
	},
	disabled: [
		"blockquote",
		"fence",
		"hr",
		"reference",
		"autolink",
		"image",
		"link",
		"newline",
	],
};

const fact = {
	inline: true,
	config: {
		html: false,
		xhtmlOut: false,
		breaks: false,
		linkify: false,
	},
	disabled: [
		"blockquote",
		"fence",
		"heading",
		"hr",
		"reference",
		"autolink",
		"image",
		"link",
		"newline",
	],
};

const description = {
	inline: false,
	config: {
		html: false,
		xhtmlOut: false,
		breaks: true,
		linkify: false,
	},
	disabled: ["autolink", "image", "link"],
};

const stem = {
	inline: false,
	config: {
		html: true,
		xhtmlOut: true,
		breaks: true,
		linkify: false,
	},
	disabled: ["autolink", "link"],
};

const answer = {
	inline: true,
	config: {
		html: true,
		xhtmlOut: true,
		breaks: false,
		linkify: false,
	},
	disabled: ["blockquote", "fence", "hr", "list", "reference"],
};

const explanation = {
	inline: false,
	config: {
		html: true,
		xhtmlOut: true,
		breaks: true,
		linkify: true,
	},
	disabled: [],
};

const rules = {
	term,
	fact,
	description,
	stem,
	answer,
	explanation,
};

export default rules;
