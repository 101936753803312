import type { LeaderboardItemType } from "./types";

const chipTypes = {
	WEEK: "week",
	ALLTIME: "all_time",
	MONTH: "month",
} as const;
const filterChips = [
	{
		label: "All time",
		type: chipTypes.ALLTIME,
		daysToReset: 0,
	},
	{
		label: "This month",
		type: chipTypes.MONTH,
		daysToReset: 30,
	},
	{
		label: "This week",
		type: chipTypes.WEEK,
		daysToReset: 5,
	},
];
const leaderboards: LeaderboardItemType[] = [
	{
		username: "John Doe",
		xp: 700,
		avatar:
			"https://fastly.picsum.photos/id/453/200/200.jpg?hmac=IO3u3eOcKSOUCe8J1IlvctdxPKLTh5wFXvBT4O3BNs4",
		rank: 1,
	},
	{
		username: "John Doe Two",
		xp: 600,
		avatar:
			"https://fastly.picsum.photos/id/453/200/200.jpg?hmac=IO3u3eOcKSOUCe8J1IlvctdxPKLTh5wFXvBT4O3BNs4",
		rank: 1,
	},
	{
		username: "John Doe Three",
		xp: 500,
		avatar:
			"https://fastly.picsum.photos/id/453/200/200.jpg?hmac=IO3u3eOcKSOUCe8J1IlvctdxPKLTh5wFXvBT4O3BNs4",
		rank: 1,
	},
	{
		username: "John Doe Four",
		xp: 400,
		avatar:
			"https://fastly.picsum.photos/id/453/200/200.jpg?hmac=IO3u3eOcKSOUCe8J1IlvctdxPKLTh5wFXvBT4O3BNs4",
		rank: 1,
	},
	{
		username: "John Doe Five",
		xp: 300,
		avatar:
			"https://fastly.picsum.photos/id/453/200/200.jpg?hmac=IO3u3eOcKSOUCe8J1IlvctdxPKLTh5wFXvBT4O3BNs4",
		rank: 1,
	},
	{
		username: "John Doe Six",
		xp: 200,
		avatar:
			"https://fastly.picsum.photos/id/453/200/200.jpg?hmac=IO3u3eOcKSOUCe8J1IlvctdxPKLTh5wFXvBT4O3BNs4",
		rank: 1,
	},
	{
		username: "John Doe Seven",
		xp: 100,
		avatar:
			"https://fastly.picsum.photos/id/453/200/200.jpg?hmac=IO3u3eOcKSOUCe8J1IlvctdxPKLTh5wFXvBT4O3BNs4",
		rank: 1,
	},
];
export { chipTypes, filterChips, leaderboards };
