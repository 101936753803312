/* ----------------- React --------------- */
import { useCallback, useState } from "react";

/* ----------------- Types --------------- */
import type {
	CreateSessionType,
	Distribution,
	EndSessionTypes,
	Mode,
} from "@memorang/types";

import { useExamContext } from "@features/exam";
/* ----------------- Hooks --------------- */
import useSessionStore from "./useSessionStore";

/* ----------------- Analytics --------------- */
import { trackCustomEvent } from "analytics";

/* ----------------- Constants --------------- */
import { newEvents } from "@constants/tracking";

import type { ItemData } from "schema/item";

import useSubscriptionPurchase from "@hooks/useSubscriptionPurchase";
import { useAuthContext } from "@memorang/applets";
import { useMutation, useQueryClient } from "@tanstack/react-query";
/* ----------------- Router --------------- */
import { useRouter } from "expo-router";
import { endSession } from "../relay/EndSession";

export enum LearningModes {
	flashcards = "flashcards",
	questions = "question",
	quiz = "quiz",
}
const _sessionTypeMap: Record<LearningModes, CreateSessionType> = {
	question: "PRACTICE",
	flashcards: "FLASHCARD",
	quiz: "QUIZ",
};

const _sessionModeMap: Record<LearningModes, Mode> = {
	question: "QUESTION",
	flashcards: "FLIP",
	quiz: "QUIZ",
};
export const useSession = () => {
	const {
		currentExamContext: { studyPackProductId },
	} = useExamContext();
	const router = useRouter();

	const { viewerId: userId } = useAuthContext();

	const { handleShowPaywall, isPremiumUser } = useSubscriptionPurchase();

	const [creatingSession, setCreatingSession] = useState(false);
	const [showErrorDialog, setShowErrorDialog] = useState(false);
	const resetSessionStore = useSessionStore((state) => state.resetSessionStore);
	const updateItemAttempts = useSessionStore(
		(state) => state.updateItemAttempts,
	);
	const updateAnswers = useSessionStore((state) => state.updateAnswers);
	const updateSession = useSessionStore((state) => state.updateSession);

	const queryClient = useQueryClient();
	const updateEndingSessionInProgress = useSessionStore(
		(state) => state.updateEndingSessionInProgress,
	);

	const toggleShowErrorDialog = () => {
		setShowErrorDialog((prev) => !prev);
	};
	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	const handleCreateFormativeSession = useCallback(
		async ({
			dist = {
				low: 0,
				medium: 0,
				high: 0,
				none: 0,
			},
			learningMode,
			tags,
			handleClose,
			challengeItem,
			navigate = true,
			replace = false,
			isFeatured = false,
		}: {
			dist?: Distribution;
			learningMode?: LearningModes;
			tags?: string[];
			handleClose?: () => void;
			challengeItem?: ItemData;
			navigate?: boolean;
			replace?: boolean;
			isFeatured?: boolean;
		}) => {
			resetSessionStore();
			try {
				setCreatingSession(true);
				const numItemsRequested = Object.values(dist).reduce(
					(acc, curr) => acc + curr,
					0,
				);

				if (challengeItem) {
					const isCompleted = challengeItem?.completedAt != null;
					const isPremium = challengeItem?.accessType === "PREMIUM";

					if (isPremium && !isPremiumUser && !isFeatured && !isCompleted) {
						handleShowPaywall();
						return;
					}

					const mappedItem = {
						id: challengeItem.id,
						stem: challengeItem.data.stem,
						itemType: challengeItem.type,
						contentId: challengeItem.id,
						children: challengeItem.data.children,
						tags: challengeItem.tags,
						media: challengeItem.media,
						shuffle: challengeItem.shuffle,
						selectedChoiceIds: challengeItem.selectedChoiceIds,
						completedAt: challengeItem.completedAt,
					};

					trackCustomEvent({
						eventName: isCompleted
							? newEvents.completedChallengeClicked
							: newEvents.sessionStarted,
						learningMode,
						numItems: numItemsRequested,
						dist: JSON.stringify(dist),
						tags: JSON.stringify(tags || []),
						contentId: studyPackProductId,
						type: "FORMATIVE_SESSION",
					});
					const selectedChoiceIds = challengeItem.selectedChoiceIds;
					if (selectedChoiceIds?.length) {
						for (const item of selectedChoiceIds) {
							const isLastAttempt =
								item === selectedChoiceIds[selectedChoiceIds.length - 1];
							const currentAnswer = {
								timeTaken: 0,
								confidence: 50,
								correct: isLastAttempt,
								score: 0,
								selectedChoiceIds,
							};
							updateItemAttempts({
								//@ts-ignore
								itemId: challengeItem.id,
								answered: true,
								showTryAgain: false,
								//@ts-ignore
								selectedChoiceId: item,
								attempted: true,
								correct: isLastAttempt,
							});
							if (isLastAttempt) {
								//@ts-ignore
								updateAnswers(challengeItem.id, currentAnswer);
							}
						}
					}
					updateSession({
						//@ts-ignore
						sessionResponse: {
							id: challengeItem.id.toString(),
							reportSessionType: "STUDYPACK",
							//@ts-ignore
							sessionItems: [mappedItem],
						},
						mode: "QUESTION",
					});

					if (navigate) {
						if (handleClose) {
							handleClose();
							router.push({
								pathname: "/(protected)/item/[id]",
								params: {
									id: challengeItem.id,
								},
							});
						} else if (replace) {
							router.replace({
								pathname: "/(protected)/item/[id]",
								params: {
									id: challengeItem.id,
								},
							});
						} else {
							router.push({
								pathname: "/(protected)/item/[id]",
								params: {
									id: challengeItem.id,
								},
							});
						}
					}
				}
			} catch (_error) {
				toggleShowErrorDialog();
			} finally {
				setCreatingSession(false);
			}
		},
		[resetSessionStore, router, studyPackProductId, updateSession],
	);

	const { mutate: handleEndSession } = useMutation({
		mutationFn: async ({
			id,
			sessionType,
		}: {
			id: string;
			sessionType: EndSessionTypes;
		}) => {
			updateEndingSessionInProgress(true);
			await endSession({
				sessionId: id!,
				userId: userId!,
				sessionType,
			});
		},
		onSuccess: () => {
			updateEndingSessionInProgress(false);
			queryClient.invalidateQueries();
		},
	});
	return {
		creatingSession,
		handleCreateFormativeSession,
		showErrorDialog,
		toggleShowErrorDialog,
		handleEndSession,
	};
};
