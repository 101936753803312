/* -----------------Globals--------------- */
import { pageViewTypes } from "@constants/tracking";

/* -----------------Child components--------------- */
import { SessionContainer } from "@features/session";

/* -----------------Helpers & Hooks--------------- */
import useAnalytics from "@hooks/useAnalytics";
import DocumentTitle from "components/DocumentTitle";

/* -----------------UI--------------- */
import { Stack } from "expo-router";

const ReviewRoute = () => {
	useAnalytics({
		type: pageViewTypes.review,
	});

	return (
		<>
			<Stack.Screen
				options={{
					headerShown: false,
				}}
			/>
			<DocumentTitle title={"Review"} />
			<SessionContainer />
		</>
	);
};

export default ReviewRoute;
