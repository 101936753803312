import type { Article } from "../../../../../types";

export const Article621: Article = {
	id: 621,
	rsuiteId: "cabf7cd7-1aad-4f85-92b6-f957277cf6f0",
	type: "commentary and perspective",
	title: "Can We Predict Poor Outcomes for Arthroscopic Partial Meniscectomy?",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/621.jpeg",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Can We Predict Poor Outcomes for Arthroscopic Partial
                Meniscectomy?</div>
              <div class="text subtitle" style="position: unset;">Commentary on an article by Michael R. Moore, BA, et
                al.: “Levels of Synovial Fluid Inflammatory Biomarkers on Day of Arthroscopic Partial Meniscectomy
                Predict Long-Term Outcomes and Conversion to TKA. A 10-Year Mean Follow-up Study”</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Charles J.
                      Gatt, Jr., MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">Arthroscopic partial meniscectomy (APM) continues to be
                  one of the most commonly performed procedures in orthopaedic surgery. This continues to be the case
                  despite several randomized prospective studies calling into question the utility of APM compared with
                  nonoperative management<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  There is no question that there are patients who have degenerative meniscal tears and present with
                  primarily mechanical symptoms who do benefit from APM. Unfortunately, there are also patients who have
                  poor outcomes after APM. The challenge for orthopaedic surgeons is to determine which patients will
                  have poor outcomes and advise them that APM surgery is probably not the proper course of treatment of
                  them. Often, clinicians rely on factors such as age, body mass index, and arthritis grade to
                  differentiate between the 2 groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">The study by Moore et al. reports interesting correlations
                  between synovial fluid markers obtained at the time of APM and long-term outcomes, namely conversion
                  to total knee arthroplasty (TKA) and validated patient-reported outcomes (PROs). I also found the use
                  of an unsupervised machine learning algorithm in the study particularly interesting. The algorithm
                  created 2 cohorts based on cytokine concentrations, those with a “pro-inflammatory phenotype” and an
                  “anti-inflammatory phenotype.” The authors concluded that these phenotypes can predict PROs and
                  conversion to TKA at long-term follow-up after APM.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">The shortcoming of the data presented in this study is
                  that the synovial fluid was collected at the time of surgery. The next logical step is to utilize the
                  2 phenotypes identified in this study as part of the clinical decision-making process for patients
                  being considered for APM. Performing this analysis in the clinic is certainly feasible, as evidenced
                  by the work of Cole et al.<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>. In
                  that study, the authors collected synovial fluid samples at the time of hyaluronic acid and
                  platelet-rich plasma injection therapy and afterward. They concluded that there was a trend toward
                  decreases over time in the levels of 2 pro-inflammatory cytokines. Injection therapy is often part of
                  the management for patients with symptomatic meniscal tears prior to surgery. The phenotypes
                  determined by Moore et al. have the potential to determine which patients may be better served by
                  continued nonoperative management.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Investigators continue to look for ways to alter the
                  degenerative processes that lead to end-stage arthritis of the knee. Of the 2 phenotypes identified by
                  Moore et al. on the basis of an analysis of synovial fluid cytokines, one progressed toward end-stage
                  arthritis more quickly than the other phenotype. Ultimately, that and other data may be used to
                  develop pharmacologic strategies to slow the steady progression of degenerative arthritis in the
                  millions of patients affected by this condition.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Wijn SRW</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Hannink G</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Østerås H</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Risberg MA</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Roos EM</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Hare KB</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> van de Graaf VA</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Poolman RW</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Ahn HW</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Seon JK</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Englund M</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Rovers MM</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Arthroscopic partial
                  meniscectomy vs non-surgical or sham treatment in patients with MRI-confirmed degenerative meniscus
                  tears: a systematic review and meta-analysis with individual participant data from 605 randomised
                  patients</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Osteoarthritis Cartilage.</span> 2023 May;31(5):557-1.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthroscopic%20partial%20meniscectomy%20vs%20non-surgical%20or%20sham%20treatment%20in%20patients%20with%20MRI-confirmed%20degenerative%20meniscus%20tears%3A%20a%20systematic%20review%20and%20meta-analysis%20with%20individual%20participant%20data%20from%20605%20randomised%20patients&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Cole BJ</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Karas V</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Hussey K</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Pilz K</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Fortier LA</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Hyaluronic Acid Versus
                  Platelet-Rich Plasma: A Prospective, Double-Blind Randomized Controlled Trial Comparing Clinical
                  Outcomes and Effects on Intra-articular Biology for the Treatment of Knee Osteoarthritis</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2017
                Feb;45(2):339-1. [Erratum in: Am J Sports Med. 2017 Apr;45(5):NP10.]</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hyaluronic%20Acid%20Versus%20Platelet-Rich%20Plasma%3A%20A%20Prospective%2C%20Double-Blind%20Randomized%20Controlled%20Trial%20Comparing%20Clinical%20Outcomes%20and%20Effects%20on%20Intra-articular%20Biology%20for%20the%20Treatment%20of%20Knee%20Osteoarthritis&as_occt=title&as_sauthors=%20%22BJ%20Cole%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dcabf7cd7-1aad-4f85-92b6-f957277cf6f0%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Commentary and Perspective</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): e52</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.01009</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">December 18, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=e4ea1710-bc67-449c-acef-c2c72f6f88ea&native=1"
                              class="article-title" style="position: unset;">Levels of Synovial Fluid Inflammatory
                              Biomarkers on Day of Arthroscopic Partial Meniscectomy Predict Long-Term Outcomes and
                              Conversion to TKA<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">A 10-Year Mean Follow-up Study</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Moore,
                              Michael R.; DeClouette, Brittany; Wolfe, Isabel; Kingery, Matthew T.; Sandoval-Hernandez,
                              Carlos; Isber, Ryan; Kirsch, Thorsten; Strauss, Eric J.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(24):2330 | September 12, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;cabf7cd7-1aad-4f85-92b6-f957277cf6f0&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=cabf7cd7-1aad-4f85-92b6-f957277cf6f0&type=pdf&name=JBJS.24.01009.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=cabf7cd7-1aad-4f85-92b6-f957277cf6f0&type=pdf&name=JBJS.24.01009.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_3" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_4"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I252" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I252</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;cabf7cd7-1aad-4f85-92b6-f957277cf6f0&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=cabf7cd7-1aad-4f85-92b6-f957277cf6f0&type=zip&name=JBJS.24.01009.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Charles J. Gatt, Jr., MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:cjgatt85@gmail.com" class="" style="position: unset;">cjgatt85@gmail.com</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6565-138X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6565-138X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Robert Wood Johnson University Hospital,
                Rutgers University, New Brunswick, New Jersey</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 4371.59px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
