enum LearningModes {
	flashcards = "flashcards",
	questions = "question",
	quiz = "quiz",
}
enum LearningGoals {
	linear = "LINEAR",
	optimized = "OPTIMIZED",
	shuffled = "SHUFFLED",
}

export { LearningModes, LearningGoals };
