import type { Article } from "../../../../../types";

export const Article673: Article = {
	id: 673,
	rsuiteId: "4696d323-023f-4663-9fd1-cc40fecd470b",
	type: "commentary and perspective",
	title: "Prospective Vertebral Body Tethering Data",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/673.jpeg",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Prospective Vertebral Body Tethering Data</div>
              <div class="text subtitle" style="position: unset;">Commentary on an article by A. Noelle Larson, MD, et
                al.: “Vertebral Body Tethering in Skeletally Immature Patients. Results of a Prospective U.S. FDA
                Investigational Device Exemption Study”</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Peter O.
                      Newton, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">I congratulate Larson et al. on their well-presented
                  Investigational Device Exemption study that was initiated by the investigators rather than the
                  company. This was no small undertaking, and I commend the authors for taking this path to prospective
                  investigation of a technique. At the initiation of the study in 2015, there was no device cleared by
                  the U.S. Food and Drug Administration (FDA) for this indication (the FDA Humanitarian Device Exemption
                  occurred in 2019). Thus, much of the literature on vertebral body tethering (VBT) is retrospective.
                  The cohort studied was a fairly uniform population with adolescent idiopathic scoliosis (AIS): most
                  had a single thoracic tether, all curves were between 40° and 70°, and patients were enrolled and
                  followed under a strict protocol.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">The amount of skeletal growth remaining is thought to be
                  an important variable in determining the extent of growth modulation based on additional curve
                  reduction following VBT. The range of remaining growth in the cohort, as assessed by the Sanders
                  Maturity Score, was quite narrow. Two-thirds of the cohort were at Sanders 4 and another quarter were
                  at Sanders 3. Thus, the results are most helpful in assessing the outcomes in this specific patient
                  age range—beyond peak height velocity, and with modest growth remaining. The average T1-S1 spine
                  height increased 20 mm over the first 2 years postoperatively. Standing height gained during follow-up
                  was not presented.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">One of the challenges in reporting the outcomes of VBT is
                  achieving consistent follow-up. The authors were able to follow a consecutive series of patients for a
                  minimum of 2 years. Although 2 years is the minimum standard that we apply for publication, the cohort
                  was undergoing growth and had a device that is known to fatigue and break, so we must interpret these
                  results (like many that have been reported before) with an eye on what the additional follow-up times
                  points will yield. Even with the minimum 2-year and average 3.8-year results presented, Larson et al.
                  demonstrate clinical “success” that was declining over time: 85% at 1 year, 75% at 2 years, and 62% at
                  3.8 years. The sustainability of the correction may be related to the degree of growth modulation
                  achieved in the years following implantation of the tether. If the growth and thus the modulation of
                  vertebral shape are limited, the correction may be lost as the tether fails. More growth after
                  implantation (selecting patients younger than Sanders 4) may yield growth modulation that leads to
                  greater correction (or even overcorrection), and thus more potential for permanent vertebral shape
                  change in the process, potentially resulting in a more sustainable long-term correction. The authors
                  did not find the rate of suspected tether breakage to be as high as in some prior reports. Measuring
                  every radiograph made at 6-month intervals may yield a higher rate of detecting the &gt;5° change in
                  angulation between screws at adjacent segments that is required to declare a suspected failure,
                  compared with the annual measurements made in this study. The authors, like others, conclude that cord
                  breakage does not necessarily result in clinical failure. This is an important point, and I absolutely
                  agree. However, I also would suggest that the best chance for a lasting correction, given the
                  knowledge that cord failure is coming eventually, likely requires greater growth potential than the
                  majority of the patients in the present study had. The fact that the clinical success rate declined
                  with each year of follow-up remains a concern that we must confront. How do we achieve an 85% success
                  rate that lasts well beyond skeletal maturity?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">This paper is an important addition to the growing body of
                  literature regarding the outcomes of VBT in the treatment of AIS. Nevertheless, the precise
                  indications with regard to curve location and magnitude and remaining skeletal growth that will yield
                  a durable long-term scoliosis correction continue to be defined and clarified.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D4696d323-023f-4663-9fd1-cc40fecd470b%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Commentary and Perspective</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">February 5, 2025;
                      107 (3): e9</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.01045</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">February 5, 2025</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=b5d99738-9e74-4552-8369-a53ce562188c&native=1"
                              class="article-title" style="position: unset;">Vertebral Body Tethering in Skeletally
                              Immature Patients<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">Results of a Prospective U.S. FDA Investigational Device
                                Exemption Study</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Larson, A.
                              Noelle; Todderud, Julia E.; Mathew, Smitha E.; Nassr, Ahmad; Sebastian, Arjun S.; Potter,
                              D. Dean; Milbrandt, Todd A.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 107(3):249 | December 24, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2025 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;4696d323-023f-4663-9fd1-cc40fecd470b&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=4696d323-023f-4663-9fd1-cc40fecd470b&type=pdf&name=JBJS.24.01045.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=4696d323-023f-4663-9fd1-cc40fecd470b&type=pdf&name=JBJS.24.01045.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_1" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_2"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I338" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I338</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;4696d323-023f-4663-9fd1-cc40fecd470b&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=4696d323-023f-4663-9fd1-cc40fecd470b&type=zip&name=JBJS.24.01045.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Peter O. Newton, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:pnewton@rchsd.org" class="" style="position: unset;">pnewton@rchsd.org</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9437-7973" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9437-7973</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Rady Children’s Medical Practice Foundation, Rady Children’s Hospital San
                Diego, San Diego, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, University of California San Diego, San
                Diego, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 4383.58px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
