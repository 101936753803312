import type { OriginalQuestionData } from "../types/question";

export const extractTopicsFromUserPrompt = (userPrompt?: string) => {
	if (!userPrompt) {
		return undefined;
	}
	const regex = /\[([^)]+)\]/;
	const matches = userPrompt.match(regex);
	return matches ? matches[1] : undefined;
};

export const getInitialQuestionMarkdown = ({
	stem,
	choices,
}: OriginalQuestionData) => {
	const choicesText = choices
		.map((choice, index) => {
			const choiceStem = choice.text;
			if (choice.isCorrect) {
				return `${index + 1}. ✅ **${choiceStem}**`;
			}
			return `${index + 1}. ${choiceStem}`;
		})
		.join("\n");

	return `${stem}\n\n${choicesText}`;
};
