import type { Article } from "../../../../../types";

export const Article502: Article = {
	id: 502,
	rsuiteId: "4b083972-6b42-425d-b925-c2f62cd2c93e",
	type: "scientific article",
	title:
		"Aseptic Revision Total Hip Arthroplasty Using Modular Fluted Tapered Stems",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4b083972-6b42-425d-b925-c2f62cd2c93e&type=jpeg&name=JBJS.23.00921f1",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Aseptic Revision Total Hip Arthroplasty Using Modular
                Fluted Tapered Stems</div>
              <div class="text subtitle" style="position: unset;">Long-Term Follow-up of 515 Cases</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Christopher
                      N. Carender, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Dirk R.
                      Larson, MS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Background:</span> Modular fluted tapered (MFT) femoral components
                        are frequently utilized in aseptic revision total hip arthroplasties (THAs). However, long-term
                        follow-up has been limited. The purpose of this study was to update our prior series at
                        long-term follow-up, with specific emphasis on implant survivorship, radiographic results, and
                        complications in a large cohort of aseptic revision THAs using MFT stems.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Methods:</span> We retrospectively identified 515 aseptic femoral
                        revisions performed with 2 MFT stem designs in the total joint registry of a single tertiary
                        care academic institution from 1999 to 2013. Serial radiographs were reviewed for subsidence of
                        &gt;5 mm. The mean follow-up (and standard deviation) was 10 ± 5 years (range, 2 to 21 years). A
                        competing risk model accounting for death was utilized.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Results:</span> The 15-year cumulative incidence of any revision was
                        12%. There were 57 revisions, 27 of which involved revision of the fluted tapered component
                        (FTC). Dislocation (n = 19), periprosthetic joint infection (n = 15), and aseptic loosening of
                        the FTC (n = 11) were the most common reasons for revision. The 15-year cumulative incidence of
                        any reoperation was 16%. The 15-year cumulative incidences were 6% for any FTC revision and 2%
                        for FTC revision for aseptic loosening. Stem subsidence of &gt;5 mm occurred in 2% of unrevised
                        cases, and all but 1 stem was stable at the most recent follow-up.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Conclusions:</span> This large series of MFT stems used in aseptic
                        revision THAs had a 2% incidence of subsequent revision of the FTC for aseptic loosening at 15
                        years. Dislocation and infection were the most common reasons for any revision.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The survival of contemporary aseptic revision total hip
                  arthroplasties (THAs) is much improved relative to historical cohorts<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>; however, aseptic loosening of the femoral component remains
                  the second-most common reason for subsequent revision THA<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">1</span></a>. As
                  the revision THA volume increases, the survivorship and clinical performance of commonly utilized
                  femoral components need to be better understood.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Modular fluted tapered (MFT) stems have received increased
                  attention in revision THA over the past 2 decades<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">5</span></a>. MFT stems are designed to provide immediate axial and
                  rotation stability<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">6</span></a> and
                  uncoupling of diaphyseal fixation from modulation of length, offset, and version. To date, clinical
                  series evaluating MFT stems in aseptic revision THA have been promising, with a 10-year survivorship
                  free of any femoral component revision of 96%<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">7</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Despite the popularity of MFT stems, existing series
                  examining MFT stems in aseptic revision THA have been limited by modest cohort sizes and mid-term
                  follow-up<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">11</span></a>. In
                  the largest series of MFT stems to date, we previously examined 519 aseptic revision THAs with MFT
                  stems at a mean follow-up of 4.5 years, demonstrating a 10-year survivorship free of stem revision for
                  aseptic loosening of 98%<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">2</span></a>. The
                  purpose of this study was to update our prior series<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">2</span></a> at a
                  mean follow-up of 10 years, with specific emphasis on implant survivorship, radiographic results, and
                  complications in this large cohort of aseptic revision THAs using MFT stems.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">We retrospectively identified 5,643 aseptic revision THAs
                  performed by 16 surgeons at a single tertiary care academic institution from 1999 to 2013. Patients
                  were identified via our institutional total joint registry (TJR), which has prospectively captured
                  data on all patients who have undergone arthroplasty at our institution since 1969<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">2</span></a>. We included 519 aseptic revision THAs that utilized 1 of 2
                  MFT stem designs: the Waldemar LINK MP stem (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>) or the
                  Stryker Restoration stem (including the historical Restoration T3 stem and contemporary Restoration
                  Modular stem) (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>).
                  Patients undergoing revision THA for septic indications and periprosthetic fracture were excluded.
                  Since the previous study<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">2</span></a>, 3
                  patients (4 hips) opted out of research participation and were excluded, leaving 515 aseptic revision
                  THA cases for the present study. Clinical and radiographic follow-up was routinely offered at 6 weeks,
                  3 months, 1 year, 2 years, 5 years, and every 5 years thereafter. Patients who were unable to return
                  for follow-up were mailed a questionnaire and were asked to return radiographs for institutional
                  review. Prior to 2 years of follow-up, 23 patients underwent revision (and were included in all
                  analyses) and 12 patients died; an additional 20 patients had &lt;2 years of follow-up and were
                  considered lost to follow-up. Among the remaining 460 patients who were alive and had not undergone a
                  revision, the mean follow-up (and standard deviation) was 10 ± 5 years (range, 2 to 21 years).
                  Institutional review board approval was obtained prior to study initiation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00921f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4b083972-6b42-425d-b925-c2f62cd2c93e&type=jpeg&name=JBJS.23.00921f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;">Anteroposterior radiographs demonstrating a loose
                          uncemented femoral component prior to revision (<span data-id="strong_36"
                            class="annotation strong" style="position: unset;">Fig. 1-A</span>) and 5 years after
                          revision THA with an MFT stem (<span data-id="strong_37" class="annotation strong"
                            style="position: unset;">Fig. 1-B</span>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.00921f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4b083972-6b42-425d-b925-c2f62cd2c93e&type=jpeg&name=JBJS.23.00921f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;">Anteroposterior radiographs demonstrating loose
                          cemented femoral and acetabular components prior to revision (<span data-id="strong_38"
                            class="annotation strong" style="position: unset;">Fig. 2-A</span>) and 2 years after
                          revision THA with an MFT stem and multihole acetabular component (<span data-id="strong_39"
                            class="annotation strong" style="position: unset;">Fig. 2-B</span>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">At revision THA, the mean age was 70 years (range, 32 to
                  98 years), the mean body mass index (BMI) was 29 kg/m<span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">2</span> (range, 18 to 54 kg/m<span
                    data-id="superscript_12" class="annotation superscript" style="position: unset;">2</span>), and 46%
                  of patients were female (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). The
                  indications for index revision THA, operative approach, operative time, components revised at the
                  index revision THA, classifications of femoral bone loss<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">12</span></a>,
                  and femoral-head sizes and bearing surfaces are shown in <a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Demographic and Operative Factors</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 2431px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of hips</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">515</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at revision surgery
                          <span data-id="emphasis_30" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mean<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70 ±
                          12</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Range</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32 to
                          98</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">278
                          (54%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">237
                          (46%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI <span
                            data-id="emphasis_31" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_32" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_14" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_14"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_33"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mean<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29 ±
                          6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Range</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18 to
                          54</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Indication for index
                          revision THA<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Aseptic loosening of
                          femoral component</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">284
                          (55%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Aseptic loosening of
                          femoral and acetabular components</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">147
                          (29%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Osteolysis or
                          polyethylene wear</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46
                          (9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mechanical failure or
                          fracture of the femoral component</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dislocation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Operative approach<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Anterolateral</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">132
                          (26%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Posterior</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65
                          (13%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Extended trochanteric
                          osteotomy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">318
                          (62%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Components revised at
                          index revision THA<span data-id="table_footnote_reference_6"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Femoral and acetabular
                          component revision</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">238
                          (46%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Femoral component
                          revision with bearing surface exchange</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">186
                          (36%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Femoral component
                          revision without bearing surface exchange</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">91
                          (18%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Paprosky bone loss
                          classification<span data-id="table_footnote_reference_7"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> I or II</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74
                          (17%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> IIIA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">191
                          (43%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> IIIB</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86
                          (19%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> IV</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94
                          (21%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stem design<span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Stryker
                          Restoration</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">144
                          (28%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Link MP</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">371
                          (72%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stem diameter <span
                            data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Median</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Interquartile
                          range</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17 to
                          20</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Range</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12 to
                          28</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stem length <span
                            data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Median</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">210</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Interquartile
                          range</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">180 to
                          250</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Range</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">115 to
                          330</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Bearing type<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dual-mobility
                          construct</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37
                          (7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Constrained liner</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Femoral head
                          diameter<span data-id="table_footnote_reference_11"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥36 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">279
                          (54%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;36 mm</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">186
                          (36%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Operative time <span
                            data-id="emphasis_36" class="annotation emphasis"
                            style="position: unset;">(min)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mean<span
                            data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">237 ±
                          95</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Range</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71 to
                          699</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the number of cases, with the percentage of
                        cases with data in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Data were available for 445 of 515 cases.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The Stryker Restoration T3 modular stem was used in 39
                  cases (8%) and the contemporary Stryker Restoration Modular stem was used in 105 cases (20%). In the
                  contemporary Restoration Modular stem, the modular taper junction is treated with a shot-peening
                  process to improve fatigue strength; stem geometry is otherwise the same. The median stem diameter was
                  19 mm (range, 12 to 28 mm), and the median stem length was 210 mm (range, 115 to 330 mm). There were
                  several notable differences in stem utilization: the Link MP stem was used more frequently in cases of
                  more severe bone loss (p &lt; 0.001), and the median length of the Link MP stems used were longer (210
                  mm) than the Stryker Restoration modular stems (195 mm) (p &lt; 0.001). There was no difference in
                  patient age, BMI, or stem diameter between the 2 stem designs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Radiographs made prior to revision THA, immediately
                  following revision, and at follow-up intervals were reviewed by 1 of the authors who was not among the
                  treating surgeons. All cases had radiographs available for review. The mean radiographic follow-up was
                  9 ± 4 years (range, 0 to 20 years). Radiographs were evaluated for stem subsidence, failure, and
                  loosening. Magnification correction using a spherical object of a known diameter (e.g., sizing ball,
                  femoral head) was performed on all radiographs. Subsidence was measured by referencing a specific
                  point on the prosthesis to a fixed landmark on the femur<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_15" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">4</span></a>. Subsidence of &gt;5 mm was recorded. An unrevised stem was
                  considered loose if it demonstrated progressive subsidence (axial translation) on serial radiographs
                  or if radiolucent lines &gt;1 mm wide were present around the fluted tapered component (FTC)<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_16" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">4</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Technique</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Stem selection was at the discretion of the surgeon. A
                  plan for an appropriate length and diameter of the FTC and proximal body was established on the basis
                  of preoperative radiographs. Prior to reaming the femur, a prophylactic cable or wire was placed
                  around the femoral diaphysis in most cases. The femoral diaphysis was then reamed with either hand or
                  power sequential reamers to form a supportive cone of bone with endosteal contact; this was assessed
                  with tactile feedback from the reamer, listening for contact between the reamer and endosteal bone,
                  and the amount of endosteal bone on the reamer once removed from the femoral canal. In cases of severe
                  bone loss (Paprosky IIIB or IV), hand reaming was preferred to allow for maximum tactile feedback to
                  the surgeon. Following reaming, trial components were implanted, and axial and rotational stability
                  was assessed. If axial or rotational stability was not achieved, the femoral canal was re-reamed with
                  a larger-diameter reamer and/or to a greater depth of reaming. After achieving stability with the
                  trial FTC, the proximal body trial was then added, and canal fit, leg lengths, offset, and hip
                  stability were assessed via intraoperative radiographs and manual testing. Once the final implants
                  were selected, the femoral canal was irrigated, and the FTC of the stem was impacted until axial and
                  rotational stability was achieved. The final proximal body was then secured to the FTC, and the hip
                  was reduced. The osteotomy (if used) was then reduced and fixed with cerclage cables and/or wires.
                  Patients were typically limited to toe-touch weight-bearing with a gait aid for 8 to 12 weeks,
                  followed by progressive weight-bearing.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Data were summarized using means and standard deviations
                  for continuous variables and counts and percentages for categorical variables. The time-to-event
                  outcomes of any revision, any revision of the FTC, revision of the FTC for aseptic loosening, and any
                  reoperation were evaluated with cumulative incidence analyses accounting for death as a competing
                  risk. Revision was defined as any removal or exchange of the FTC or proximal body of the stem, femoral
                  head, acetabular component, or acetabular liner. Reoperation was defined as any return to the
                  operating room. Intraoperative and postoperative complications were extracted from the medical record.
                  Postoperative complications were defined as adverse outcomes related to the index revision THA that
                  occurred at any time during the follow-up period. Only postoperative complications that were treated
                  nonoperatively were counted as complications; complications treated with revision were captured as
                  revisions and complications treated with reoperation were captured as reoperations. Cox proportional
                  hazards regression analyses were performed to assess the association of demographic and operative
                  factors with time-to-event outcomes. The Fisher exact test was used to evaluate associations between
                  the demographic and operative factors and the risk of stem subsidence. All statistical tests were
                  2-sided, with significance set at p &lt; 0.05. All analyses were conducted using SAS version 9.4 (SAS
                  Institute) and R version 3.4.2 (The R Foundation).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Cumulative
                Incidence of Revision and Reoperation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The 15-year cumulative incidence of any revision was 12%
                  (<a href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>). A total of 57 revisions were performed; the etiologies of
                  subsequent revisions are shown in <a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>.
                  Twenty-seven revisions involved revision or removal of the FTC, resulting in a 15-year cumulative
                  incidence of any FTC revision of 6% (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>, <a
                    href="" data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). The 15-year cumulative incidence of revision of the FTC for
                  aseptic loosening was 2%. Aseptic loosening occurred in 3 cases with type-IIIA bone loss, 2 cases with
                  type-IIIB bone loss, and 6 cases with type-IV bone loss. No demographic factors were associated with
                  the risk of any revision (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>), any
                  revision of the FTC, or revision of the FTC for aseptic loosening (<a href=""
                    data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.00921f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4b083972-6b42-425d-b925-c2f62cd2c93e&type=jpeg&name=JBJS.23.00921f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">The 15-year cumulative incidence of any revision,
                          with death as a competing risk, was 12%. The whiskers indicate the 95% confidence intervals.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Etiologies of Subsequent Revisions, Reoperations,
                          and Postoperative Complications</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 2132px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Etiology</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Value<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Revision</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dislocation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (33%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Periprosthetic joint
                          infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (26%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Aseptic loosening of
                          femoral component</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (19%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Aseptic loosening of
                          acetabular component</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Periprosthetic femoral
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Fracture of FTC at the
                          modular junction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Trunnionosis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Metallosis secondary to
                          cerclage wires abrading stem</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Revision or removal of
                          FTC</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Aseptic loosening of
                          femoral component</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (41%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Periprosthetic joint
                          infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (30%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dislocation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (11%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Periprosthetic femoral
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (11%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Fracture of FTC at the
                          modular junction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Reoperation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dislocation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (25%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Periprosthetic joint
                          infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (20%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Aseptic loosening of
                          femoral component</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (15%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Periprosthetic femoral
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (14%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Aseptic loosening of
                          acetabular component</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hematoma
                          evacuation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Removal of symptomatic
                          implant</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bone grafting of an
                          extended trochanteric osteotomy nonunion</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Fracture of FTC at the
                          modular junction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cortical strut grafting
                          of femoral stress reaction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Trunnionosis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Metallosis secondary to
                          cerclage wires abrading stem</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Iliopsoas
                          impingement</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postoperative
                          complications without subsequent revision or reoperation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dislocation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (36%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Delayed
                          wound-healing</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (24%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Vancouver A<span
                            data-id="subscript_1" class="annotation subscript" style="position: unset;">G</span>
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (12%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Symptomatic heterotopic
                          ossification</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (10%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nonunion of extended
                          trochanteric osteotomy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Transient peroneal nerve
                          palsy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Deep vein
                          thrombosis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Periprosthetic joint
                          infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the number of cases, with or without the
                        percentage of revisions, reoperations, or other postoperative complications in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.00921f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4b083972-6b42-425d-b925-c2f62cd2c93e&type=jpeg&name=JBJS.23.00921f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">The 15-year cumulative incidence of any revision
                          of the FTC, with death as a competing risk, was 6%. The whiskers indicate the 95% confidence
                          intervals.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Univariate Cox Regression Analysis of Any Revision
                          and Any Reoperation</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 850.502px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.691176);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Any Revision</span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Any Reoperation</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Cases</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Events</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Hazard
                          Ratio<span data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Cases</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Events</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Hazard
                          Ratio<span data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at
                          reimplantation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥65 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">364</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8 (0.5
                          to 1.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.52</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">364</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9 (0.6
                          to 1.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.69</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;65 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">151</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">151</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">278</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6 (0.4
                          to 1.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">278</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6 (0.4
                          to 1.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">237</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">237</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI, per 1-kg/m<span
                            data-id="superscript_17" class="annotation superscript" style="position: unset;">2</span>
                          increase</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">515</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0 (1.0
                          to 1.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.22</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">515</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0 (1.0
                          to 1.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.07</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Paprosky bone loss</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> IIIA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">191</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0 (0.4
                          to 2.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.98</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">191</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.3 (0.6
                          to 2.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.51</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> IIIB</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.1 (0.9
                          to 4.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.09</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.2 (1.0
                          to 4.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> IV</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0 (0.4
                          to 2.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.94</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.3 (0.6
                          to 3.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.54</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> I or II</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stem design</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Stryker
                          Restoration</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">144</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.1 (1.3
                          to 3.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.006</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">144</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.8 (1.2
                          to 3.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Link MP</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">371</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">371</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stem diameter, per 1-mm
                          increase</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">515</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0 (0.9
                          to 1.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.93</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">515</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0 (0.9
                          to 1.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.95</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stem length, per 1-cm
                          increase</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">515</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0 (1.0
                          to 1.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.49</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">515</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0 (1.0
                          to 1.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.24</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the hazard ratio, with the 95% confidence
                        interval in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Univariate Cox Regression Analysis of Any Revision
                          of the FTC and Revision of the FTC for Aseptic Loosening</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 870.019px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.691176);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Any Revision of FTC</span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Revision of FTC for Aseptic Loosening</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Cases</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Events</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Hazard
                          Ratio<span data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Cases</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Events</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Hazard
                          Ratio<span data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at
                          reimplantation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥65 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">364</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8 (0.4
                          to 1.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.63</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">364</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.3 (0.4
                          to 4.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.70</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;65 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">151</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">151</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">278</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0 (0.5
                          to 2.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.94</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">278</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.4 (0.4
                          to 4.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.56</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">237</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">237</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI, per 1-kg/m<span
                            data-id="superscript_18" class="annotation superscript" style="position: unset;">2</span>
                          increase</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">515</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0 (0.9
                          to 1.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.71</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">515</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9 (0.8
                          to 1.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.14</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Paprosky bone loss</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> IIIA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">191</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.2 (0.5
                          to 9.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.32</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">191</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.2 (0.1
                          to 10.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.89</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> IIIB</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.6 (0.5
                          to 12.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.25</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.7 (0.2
                          to 18.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.67</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> IV</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.9 (0.6
                          to 13.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.18</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.6 (0.4
                          to 30.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.24</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> I or II</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stem design</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Stryker
                          Restoration</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">144</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.8 (1.3
                          to 5.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.008</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">144</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.2 (1.5
                          to 18.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Link MP</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">371</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">371</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stem diameter, per 1-mm
                          increase</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">515</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9 (0.8
                          to 1.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.54</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">515</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.1 (0.8
                          to 1.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.14</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stem length, per 1-cm
                          increase</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">515</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.1 (1.0
                          to 1.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.22</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">515</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.2 (1.0
                          to 1.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.09</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the hazard ratio, with the 95% confidence
                        interval in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">A total of 75 reoperations were performed, including the
                  57 revisions noted above; etiologies for the reoperations are shown in <a href=""
                    data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>. The 15-year cumulative incidence of any reoperation was 16%
                  (<a href="" data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 5</a>). No demographic factors were associated with the risk of any
                  reoperation (<a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.00921f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=4b083972-6b42-425d-b925-c2f62cd2c93e&type=jpeg&name=JBJS.23.00921f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;">The 15-year cumulative incidence of any
                          reoperation, with death as a competing risk, was 16%. The whiskers indicate the 95% confidence
                          intervals.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Stem subsidence of &gt;5 mm occurred in 11 unrevised cases
                  (2%). At a mean clinical and radiographic follow-up of 10 ± 3 years (range, 5 to 14 years), 10 of the
                  11 stems that subsided were radiographically stable (no further subsidence on serial radiographs) and
                  had no radiographic signs of loosening. One stem continued to subside and had progressive radiolucent
                  lines &gt;1 mm wide circumferentially around the stem; it was deemed loose. The stem was not revised
                  secondary to medical comorbidities of the patient. Among stems that subsided, the mean amount of
                  subsidence was 16 mm (range, 6 to 29 mm). Of the stems that did not undergo revision (354 Link MP
                  stems and 129 Stryker Restoration modular stems), subsidence occurred in 8 (2%) of Link MP stems and 3
                  (2%) of Stryker Restoration modular stems. Age at the time of the surgical procedure, patient sex,
                  femoral bone loss, and stem design were not associated with the risk of stem subsidence.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Complications</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">An intraoperative femoral fracture occurred in 68 cases
                  (13%). Sixty-three fractures involved the femoral diaphysis, and 5 fractures involved the greater
                  trochanter. Twenty-two of the diaphyseal femoral fractures required no additional fixation other than
                  fixation of the osteotomy site with cerclage cables and/or wires; additional cerclage cables and/or
                  wires were used to fix the fracture in 29 cases. Nine diaphyseal femoral fractures were fixed with
                  cortical strut allografts and cables, 1 was treated with a lateral plate, 1 was treated with a longer
                  stem, and 1 was treated with bone grafting alone. The 5 fractures involving the greater trochanter
                  were treated with tension-band wiring. Two cases in which an intraoperative diaphyseal femoral
                  fracture occurred required subsequent revision of the FTC: 1 for aseptic loosening of the femoral
                  component at 3 years after the index revision THA, and 1 for a periprosthetic femoral fracture at 2
                  years after the index revision THA. One case in which an intraoperative diaphyseal femoral fracture
                  occurred exhibited stem subsidence of 15 mm; this stem was radiographically stable at 10 years after
                  the index revision THA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Nonoperatively treated postoperative complications
                  occurred in 84 cases (16%); the etiologies of the postoperative complications are shown in <a href=""
                    data-id="figure_reference_14" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The use of MFT stems has been described throughout the
                  spectrum of revision THAs, including aseptic revision THA<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_19" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">7</span></a>, revisions for periprosthetic fractures<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_20" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">14</span></a>, oncologic revisions<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">3</span></a>, and revision THAs complicated by high-grade femoral bone
                  loss<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_22" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">15</span></a>. However, existing series examining clinical and
                  radiographic outcomes of MFT stems in revision THA have been limited by modest cohort sizes and
                  follow-up<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_23" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_23" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">11</span></a>. In
                  this large series, we found a 2% cumulative incidence of subsequent revision of the FTC for aseptic
                  loosening at 15 years, even in the setting of high-grade femoral bone loss. Fracture of the modular
                  implant was rare (0.4%) and occurred only in the historical version of 1 of the 2 implant designs
                  studied in this series; the contemporary version of that stem with a redesigned taper had no
                  fractures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">At 15 years, the cumulative incidence of any revision of
                  the FTC was 6%. Subsidence of &gt;5 mm occurred in 2% of unrevised cases, and all but 1 stem that
                  subsided was stable at the final follow-up. We found no association between the degree of femoral bone
                  loss and the risk of any revision, any revision of the FTC, and revision of the FTC for aseptic
                  loosening. Similarly, we found no association between the degree of femoral bone loss and the risk of
                  stem subsidence. These findings are similar to previous reports of MFT stems in the literature<a
                    href="" data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_24" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_24" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">21</span></a> (<a href="" data-id="figure_reference_15"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a>). Otero
                  et al.<a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">14</span></a>
                  reviewed 129 cases of MFT stem use with type-III or IV bone loss or Vancouver B<span
                    data-id="subscript_2" class="annotation subscript" style="position: unset;">2</span> and B<span
                    data-id="subscript_3" class="annotation subscript" style="position: unset;">3</span> periprosthetic
                  fractures. Survivorship was 95% at a mean follow-up of 4 years. In a similar cohort, Amanatullah et
                  al.<a href="" data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">15</span></a> demonstrated stem survivorship of 97% and a subsidence rate
                  of 7% at a mean follow-up of 6 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Published Results of MFT Stems in Contemporary
                          Revision THA<span data-id="table_footnote_reference_21"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1808.69px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.7669);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Study</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Cases</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Indications</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Follow-up<span data-id="table_footnote_reference_18"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Stem
                          Survivorship<span data-id="table_footnote_reference_19"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Subsidence
                          Rate<span data-id="table_footnote_reference_20" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stimac<a href=""
                            data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                              style="position: unset;">21</span></a><span data-id="superscript_27"
                            class="annotation superscript" style="position: unset;"></span> (2014)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">125</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Femoral revisions</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Fink<a href=""
                            data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                              style="position: unset;">10</span></a><span data-id="superscript_28"
                            class="annotation superscript" style="position: unset;"></span> (2014)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">121</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic loosening,
                          fracture, infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Rodriguez<a href=""
                            data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                              style="position: unset;">7</span></a><span data-id="superscript_29"
                            class="annotation superscript" style="position: unset;"></span> (2014)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic loosening,
                          fracture, infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Wirtz<a href=""
                            data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                              style="position: unset;">17</span></a><span data-id="superscript_30"
                            class="annotation superscript" style="position: unset;"></span> (2014)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">163</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic loosening,
                          infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Brown<a href=""
                            data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                              style="position: unset;">8</span></a><span data-id="superscript_31"
                            class="annotation superscript" style="position: unset;"></span> (2015)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic loosening,
                          fracture, infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Amanatullah<a href=""
                            data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                              style="position: unset;">15</span></a><span data-id="superscript_32"
                            class="annotation superscript" style="position: unset;"></span> (2015)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">92</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic loosening,
                          fracture, infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Houdek<a href=""
                            data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                              style="position: unset;">5</span></a><span data-id="superscript_33"
                            class="annotation superscript" style="position: unset;"></span> (2015)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Huddleston<a href=""
                            data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                              style="position: unset;">16</span></a><span data-id="superscript_34"
                            class="annotation superscript" style="position: unset;"></span> (2016)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">150</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic loosening,
                          fracture, infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">91%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NR</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Huang<a href=""
                            data-id="citation_reference_46" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                              style="position: unset;">28</span></a><span data-id="superscript_35"
                            class="annotation superscript" style="position: unset;"></span> (2017)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">103</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic loosening,
                          fracture, infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Abdel<a href=""
                            data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                              style="position: unset;">2</span></a><span data-id="superscript_36"
                            class="annotation superscript" style="position: unset;"></span> (2017)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">519</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic etiologies</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Clair<a href=""
                            data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                              style="position: unset;">25</span></a><span data-id="superscript_37"
                            class="annotation superscript" style="position: unset;"></span> (2019)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">103</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic loosening,
                          fracture, infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NR</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Diaz-Dilernia<a href=""
                            data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                              style="position: unset;">18</span></a><span data-id="superscript_38"
                            class="annotation superscript" style="position: unset;"></span> (2019)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Vancouver B<span
                            data-id="subscript_4" class="annotation subscript" style="position: unset;">3</span>
                          fractures</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cohn<a href=""
                            data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                              style="position: unset;">9</span></a><span data-id="superscript_39"
                            class="annotation superscript" style="position: unset;"></span> (2020)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic loosening,
                          fracture, infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Munegato<a href=""
                            data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                              style="position: unset;">19</span></a><span data-id="superscript_40"
                            class="annotation superscript" style="position: unset;"></span> (2020)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Vancouver B<span
                            data-id="subscript_5" class="annotation subscript" style="position: unset;">2</span> and
                          B<span data-id="subscript_6" class="annotation subscript" style="position: unset;">3</span>
                          fractures</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">100%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Feng<a href=""
                            data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                              style="position: unset;">26</span></a><span data-id="superscript_41"
                            class="annotation superscript" style="position: unset;"></span> (2020)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">108</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic loosening,
                          fracture, dislocation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Bingham<a href=""
                            data-id="citation_reference_53" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                              style="position: unset;">3</span></a><span data-id="superscript_42"
                            class="annotation superscript" style="position: unset;"></span> (2020)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Oncologic
                          revisions</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">100%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Otero<a href=""
                            data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_43" class="annotation superscript"
                              style="position: unset;">14</span></a><span data-id="superscript_43"
                            class="annotation superscript" style="position: unset;"></span> (2020)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">129</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">High-grade bone loss,
                          Vancouver B<span data-id="subscript_7" class="annotation subscript"
                            style="position: unset;">2</span> and B<span data-id="subscript_8"
                            class="annotation subscript" style="position: unset;">3</span> fractures</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NR</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">van Laarhoven<a href=""
                            data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                              style="position: unset;">13</span></a><span data-id="superscript_44"
                            class="annotation superscript" style="position: unset;"></span> (2021)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Vancouver B<span
                            data-id="subscript_9" class="annotation subscript" style="position: unset;">2</span> and
                          B<span data-id="subscript_10" class="annotation subscript" style="position: unset;">3</span>
                          fractures</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">100%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Perticarini<a href=""
                            data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_45" class="annotation superscript"
                              style="position: unset;">20</span></a><span data-id="superscript_45"
                            class="annotation superscript" style="position: unset;"></span> (2021)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic loosening,
                          fracture, infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">100%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hannon<a href=""
                            data-id="citation_reference_57" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_46" class="annotation superscript"
                              style="position: unset;">4</span></a><span data-id="superscript_46"
                            class="annotation superscript" style="position: unset;"></span> (2022)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">171</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Vancouver B<span
                            data-id="subscript_11" class="annotation subscript" style="position: unset;">2</span> and
                          B<span data-id="subscript_12" class="annotation subscript" style="position: unset;">3</span>
                          fractures</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Present study</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">515</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Aseptic etiologies</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2%</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">NR = not reported.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the mean.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Survivorship was based on Kaplan-Meier estimates of survivorship free
                        of revision or removal of the FTC or on the cumulative incidence of revision or removal of the
                        FTC; the follow-up time for survivorship outcomes varied between studies.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Subsidence was defined as &gt;5 mm.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Dislocation was the most common reason for subsequent
                  revision in the present series, which is consistent with prior studies evaluating revision THA<a
                    href="" data-id="citation_reference_58" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_47" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_59"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_47" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_47" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_60" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_47"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_61" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">15</span></a>. Dual-mobility constructs, which were used in only 7% of
                  cases in this series, may improve hip stability in the setting of revision THA<a href=""
                    data-id="citation_reference_62" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">22</span></a>. Modularity of MFT stems allows for changes in length,
                  offset, and version independent of fixation within the femoral diaphysis. Theoretically, this might
                  confer a hip stability advantage to MFT stems relative to monoblock stems; however, retrospective
                  comparisons of monoblock with modular titanium fluted stems have not demonstrated a significant
                  difference in dislocation rates<a href="" data-id="citation_reference_63"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_49" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_49" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_64" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_49"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_65" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_49"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_66" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">26</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">The use of a monoblock stem avoids the risk of stem
                  fracture at the modular junction, which had been a concern with historical MFT stem designs<a href=""
                    data-id="citation_reference_67" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_50"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_68" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                      style="position: unset;">27</span></a>. Studies evaluating contemporary MFT designs have noted a
                  rate of stem fracture at the modular junction of &lt;1%<a href="" data-id="citation_reference_69"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_51" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_51"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_70" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_51" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_51"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_71" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_51" class="annotation superscript"
                      style="position: unset;">28</span></a>. In the present series, there were 2 stem fractures at the
                  modular junction; both stem fractures occurred in historical Restoration T3 modular stems. There were
                  no implant fractures of the contemporary Restoration Modular stems or LINK MP stems.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">This study had limitations. First, it was retrospective in
                  nature and lacked a control group. Second, MFT stems were not the only stems used for femoral
                  reconstruction in revision THA during the study period. Monoblock fluted tapered stems, extensively
                  porous-coated cylindrical stems, and cemented stems were also used by our surgeons during the study
                  period, and, as such, selection bias was present. Finally, the low incidence of aseptic loosening and
                  of any revision of the FTC in the present study introduced the possibility that the study may have
                  been underpowered to detect associations between time-to-event outcomes and demographic and operative
                  factors.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, MFT stems offer durable fixation in aseptic
                  revision THA at long-term follow-up, with a very low incidence of aseptic loosening (2%), stem
                  fracture (0.4%), and stem subsidence (2%) at 15 years. Dislocation and infection were the most common
                  reasons for subsequent revision.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank Kristin M. Fruth, MS, for their statistical
                  expertise. Additionally, they thank Youlonda A. Loechler and the members of the Mayo Clinic Total
                  Joint Registry for their contributions to this study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Goldman AH</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Sierra RJ</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Trousdale RT</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Lewallen DG</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Berry DJ</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Abdel MP</span>. <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> The Lawrence D</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Dorr Surgical Techniques &
                  Technologies Award: Why are contemporary revision total hip arthroplasties failing? An analysis of
                  2500 cases</span>. <span data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2019 Jul;34(7S):S11-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dorr%20Surgical%20Techniques%20%26%20Technologies%20Award%3A%20Why%20are%20contemporary%20revision%20total%20hip%20arthroplasties%20failing%3F%20An%20analysis%20of%202500%20cases&as_occt=title&as_sauthors=%20%22AH%20Goldman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Abdel MP</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Cottino U</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Larson DR</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Hanssen AD</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Lewallen DG</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Berry DJ</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Modular fluted tapered stems in
                  aseptic revision total hip arthroplasty</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2017 May 17;99(10):873-81.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1285469" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Bingham JS</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Tibbo ME</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Beauchamp CP</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Lewallen DG</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Berry DJ</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Abdel MP</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Modular fluted tapered stems in
                  aseptic oncologic revision total hip arthroplasty: a game changer?</span><span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2020
                Dec;35(12):3692-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Modular%20fluted%20tapered%20stems%20in%20aseptic%20oncologic%20revision%20total%20hip%20arthroplasty%3A%20a%20game%20changer%3F&as_occt=title&as_sauthors=%20%22JS%20Bingham%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Hannon CP</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Sheehan KP</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Duong SQ</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Yuan BJ</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Lewallen DG</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Berry DJ</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Abdel MP</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Modular fluted tapered stems for
                  periprosthetic femoral fractures: excellent results in 171 cases</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2022 Jul
                6;104(13):1188-96.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=3313187"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Houdek MT</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Perry KI</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Wyles CC</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Berry DJ</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Sierra RJ</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Trousdale RT</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Use of a modular tapered fluted
                  femoral component in revision total hip arthroplasty following resection of a previously infected
                  total hip: minimum 5-year follow-up</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2015 Mar;30(3):435-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Use%20of%20a%20modular%20tapered%20fluted%20femoral%20component%20in%20revision%20total%20hip%20arthroplasty%20following%20resection%20of%20a%20previously%20infected%20total%20hip%3A%20minimum%205-year%20follow-up&as_occt=title&as_sauthors=%20%22MT%20Houdek%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Wagner H</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Wagner M</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Cone prosthesis for the hip
                  joint</span>. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Arch
                  Orthop Trauma Surg.</span> 2000;120(1-2):88-95.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cone%20prosthesis%20for%20the%20hip%20joint&as_occt=title&as_sauthors=%20%22H%20Wagner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Rodriguez JA</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Deshmukh AJ</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Robinson J</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Cornell CN</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Rasquinha VJ</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Ranawat AS</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Ranawat CS</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Reproducible fixation with a
                  tapered, fluted, modular, titanium stem in revision hip arthroplasty at 8-15 years follow-up</span>.
                <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span>
                2014 Sep;29(9):214-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reproducible%20fixation%20with%20a%20tapered%2C%20fluted%2C%20modular%2C%20titanium%20stem%20in%20revision%20hip%20arthroplasty%20at%208-15%20years%20follow-up&as_occt=title&as_sauthors=%20%22JA%20Rodriguez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Brown NM</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Tetreault M</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Cipriano CA</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Della Valle CJ</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Paprosky W</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Sporer S</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Modular tapered implants for
                  severe femoral bone loss in THA: reliable osseointegration but frequent complications</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2015 Feb;473(2):555-60.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Modular%20tapered%20implants%20for%20severe%20femoral%20bone%20loss%20in%20THA%3A%20reliable%20osseointegration%20but%20frequent%20complications&as_occt=title&as_sauthors=%20%22NM%20Brown%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Cohn MR</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Tetreault MW</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Li J</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Kunze KN</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Nahhas CR</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Michalski JF</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Levine BR</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Nam D</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Is there a benefit to modularity
                  for femoral revisions when using a splined, tapered titanium stem?</span><span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2020
                Jun;35(6S):S278-83.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20there%20a%20benefit%20to%20modularity%20for%20femoral%20revisions%20when%20using%20a%20splined%2C%20tapered%20titanium%20stem%3F&as_occt=title&as_sauthors=%20%22MR%20Cohn%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Fink B</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Urbansky K</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Schuster P</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Mid term results with the
                  curved modular tapered, fluted titanium Revitan stem in revision hip replacement</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2014
                Jul;96-B(7):889-95.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mid%20term%20results%20with%20the%20curved%20modular%20tapered%2C%20fluted%20titanium%20Revitan%20stem%20in%20revision%20hip%20replacement&as_occt=title&as_sauthors=%20%22B%20Fink%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Van Houwelingen AP</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Duncan CP</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Masri BA</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Greidanus NV</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Garbuz DS</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">High survival of modular
                  tapered stems for proximal femoral bone defects at 5 to 10 years followup</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2013 Feb;471(2):454-62.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High%20survival%20of%20modular%20tapered%20stems%20for%20proximal%20femoral%20bone%20defects%20at%205%20to%2010%20years%20followup&as_occt=title&as_sauthors=%20%22AP%20Van%20Houwelingen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Paprosky WG</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Burnett RS</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Assessment and classification
                  of bone stock deficiency in revision total hip arthroplasty</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">Am J Orthop (Belle Mead NJ).</span> 2002
                Aug;31(8):459-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Assessment%20and%20classification%20of%20bone%20stock%20deficiency%20in%20revision%20total%20hip%20arthroplasty&as_occt=title&as_sauthors=%20%22WG%20Paprosky%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_66" class="annotation" style="position: unset;"> van Laarhoven SN</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Vles GF</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> van Haaren EH</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Schotanus MGM</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> van Hemert WLW</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Tapered, fluted, modular,
                  titanium stems in Vancouver B periprosthetic femoral fractures: an analysis of 87 consecutive
                  revisions</span>. <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Hip
                  Int.</span> 2021 Jul;31(4):555-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tapered%2C%20fluted%2C%20modular%2C%20titanium%20stems%20in%20Vancouver%20B%20periprosthetic%20femoral%20fractures%3A%20an%20analysis%20of%2087%20consecutive%20revisions&as_occt=title&as_sauthors=%20%22SN%20van%20Laarhoven%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Otero JE</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Martin JR</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Rowe TM</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Odum SM</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Mason JB</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Radiographic and clinical
                  outcomes of modular tapered fluted stems for femoral revision for Paprosky III and IV femoral defects
                  or Vancouver B2 and B3 femoral fractures</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2020
                Apr;35(4):1069-73.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiographic%20and%20clinical%20outcomes%20of%20modular%20tapered%20fluted%20stems%20for%20femoral%20revision%20for%20Paprosky%20III%20and%20IV%20femoral%20defects%20or%20Vancouver%20B2%20and%20B3%20femoral%20fractures&as_occt=title&as_sauthors=%20%22JE%20Otero%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Amanatullah DF</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Howard JL</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Siman H</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Trousdale RT</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Mabry TM</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Berry DJ</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Revision total hip arthroplasty
                  in patients with extensive proximal femoral bone loss using a fluted tapered modular femoral
                  component</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2015 Mar;97-B(3):312-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Revision%20total%20hip%20arthroplasty%20in%20patients%20with%20extensive%20proximal%20femoral%20bone%20loss%20using%20a%20fluted%20tapered%20modular%20femoral%20component&as_occt=title&as_sauthors=%20%22DF%20Amanatullah%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Huddleston JI</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Tetreault MW</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Yu M</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Bedair H</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Hansen VJ</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Choi HR</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Goodman SB</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Sporer SM</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Della Valle CJ</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Is there a benefit to
                  modularity in ‘simpler’ femoral revisions?</span><span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2016
                Feb;474(2):415-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20there%20a%20benefit%20to%20modularity%20in%20%E2%80%98simpler%E2%80%99%20femoral%20revisions%3F&as_occt=title&as_sauthors=%20%22JI%20Huddleston%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Wirtz DC</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Gravius S</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Ascherl R</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Thorweihe M</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Forst R</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Noeth U</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Maus UM</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Wimmer MD</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Zeiler G</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Deml MC</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Uncemented femoral revision
                  arthroplasty using a modular tapered, fluted titanium stem: 5- to 16-year results of 163 cases</span>.
                <span data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Acta Orthop.</span>
                2014 Dec;85(6):562-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Uncemented%20femoral%20revision%20arthroplasty%20using%20a%20modular%20tapered%2C%20fluted%20titanium%20stem%3A%205-%20to%2016-year%20results%20of%20163%20cases&as_occt=title&as_sauthors=%20%22DC%20Wirtz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Diaz-Dilernia F</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Slullitel PA</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Oñativia JI</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Comba FM</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Piccaluga F</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Buttaro MA</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Impaction bone grafting or
                  uncemented modular stems for the treatment of type B3 periprosthetic femoral fractures? A complication
                  rate analysis</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2019 Sep;34(9):2051-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impaction%20bone%20grafting%20or%20uncemented%20modular%20stems%20for%20the%20treatment%20of%20type%20B3%20periprosthetic%20femoral%20fractures%3F%20A%20complication%20rate%20analysis&as_occt=title&as_sauthors=%20%22F%20Diaz-Dilernia%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Munegato D</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Caminita AD</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Sotiri R</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Rossi A</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Bigoni M</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Zatti G</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Femoral revision in
                  periprosthetic fractures using a titanium modular fluted tapered stem: mortality and clinical and
                  radiological outcomes</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">Hip Int.</span> 2020 Dec;30(2_suppl):101-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Femoral%20revision%20in%20periprosthetic%20fractures%20using%20a%20titanium%20modular%20fluted%20tapered%20stem%3A%20mortality%20and%20clinical%20and%20radiological%20outcomes&as_occt=title&as_sauthors=%20%22D%20Munegato%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Perticarini L</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Rossi SMP</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Fioruzzi A</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Jannelli E</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Mosconi M</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Benazzo F</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Modular tapered conical
                  revision stem in hip revision surgery: mid- term results</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">BMC Musculoskelet Disord.</span> 2021 Jan
                6;22(1):29.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Modular%20tapered%20conical%20revision%20stem%20in%20hip%20revision%20surgery%3A%20mid-%20term%20results&as_occt=title&as_sauthors=%20%22L%20Perticarini%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Stimac JD</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Boles J</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Parkes N</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Gonzalez Della Valle A</span>,
                <span data-id="annotation_123" class="annotation" style="position: unset;"> Boettner F</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Westrich GH</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Revision total hip arthroplasty
                  with modular femoral stems</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2014 Nov;29(11):2167-70.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Revision%20total%20hip%20arthroplasty%20with%20modular%20femoral%20stems&as_occt=title&as_sauthors=%20%22JD%20Stimac%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Hartzler MA</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Abdel MP</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Sculco PK</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Taunton MJ</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Pagnano MW</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Hanssen AD</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Otto Aufranc Award:
                  Dual-mobility constructs in revision THA reduced dislocation, rerevision, and reoperation compared
                  with large femoral heads</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2018 Feb;476(2):293-301.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Otto%20Aufranc%20Award%3A%20Dual-mobility%20constructs%20in%20revision%20THA%20reduced%20dislocation%2C%20rerevision%2C%20and%20reoperation%20compared%20with%20large%20femoral%20heads&as_occt=title&as_sauthors=%20%22MA%20Hartzler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Yacovelli S</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Ottaway J</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Banerjee S</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Courtney PM</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Modern revision femoral stem
                  designs have no difference in rates of subsidence</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2021
                Jan;36(1):268-73.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Modern%20revision%20femoral%20stem%20designs%20have%20no%20difference%20in%20rates%20of%20subsidence&as_occt=title&as_sauthors=%20%22S%20Yacovelli%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;">Revision
                Total Hip Arthroplasty Study Group. <span data-id="strong_24" class="annotation strong"
                  style="position: unset;">A comparison of modular tapered versus modular cylindrical stems for complex
                  femoral revisions</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2013 Sep;28(8 Suppl):71-3.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20comparison%20of%20modular%20tapered%20versus%20modular%20cylindrical%20stems%20for%20complex%20femoral%20revisions&as_occt=title&as_sauthors=%20%22Revision%20Total%20Hip%20Arthroplasty%20Study%20Group%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Clair AJ</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Cizmic Z</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Vigdorchik JM</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Poultsides LA</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Schwarzkopf R</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Rathod PA</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Deshmukh AJ</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Nonmodular stems are a viable
                  alternative to modular stems in revision total hip arthroplasty</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2019
                Jul;34(7S):S292-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Nonmodular%20stems%20are%20a%20viable%20alternative%20to%20modular%20stems%20in%20revision%20total%20hip%20arthroplasty&as_occt=title&as_sauthors=%20%22AJ%20Clair%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Feng S</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Bao YH</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Yang Z</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Zha GC</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Chen XY</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Comparison of modular and
                  nonmodular tapered fluted titanium stems in femoral revision hip arthroplasty: a minimum 6-year
                  follow-up study</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Sci Rep.</span> 2020 Aug 13;10(1):13692.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20modular%20and%20nonmodular%20tapered%20fluted%20titanium%20stems%20in%20femoral%20revision%20hip%20arthroplasty%3A%20a%20minimum%206-year%20follow-up%20study&as_occt=title&as_sauthors=%20%22S%20Feng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Bischel OE</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Suda AJ</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Böhm PM</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Bormann T</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Jäger S</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Seeger JB</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Breakage of tapered junctions
                  of modular stems in revision total hip arthroplasty-high incidence in a consecutive series of a single
                  institution</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">Bioengineering (Basel).</span> 2023 Mar 8;10(3):341.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Breakage%20of%20tapered%20junctions%20of%20modular%20stems%20in%20revision%20total%20hip%20arthroplasty-high%20incidence%20in%20a%20consecutive%20series%20of%20a%20single%20institution&as_occt=title&as_sauthors=%20%22OE%20Bischel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Huang Y</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Zhou Y</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Shao H</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Gu J</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Tang H</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Tang Q</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">What is the difference between
                  modular and nonmodular tapered fluted titanium stems in revision total hip arthroplasty</span>. <span
                  data-id="emphasis_28" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2017
                Oct;32(10):3108-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20is%20the%20difference%20between%20modular%20and%20nonmodular%20tapered%20fluted%20titanium%20stems%20in%20revision%20total%20hip%20arthroplasty&as_occt=title&as_sauthors=%20%22Y%20Huang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D4b083972-6b42-425d-b925-c2f62cd2c93e%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">August 21, 2024;
                      106 (16): 1470</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00921</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">June 28, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_29"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopedic Surgery, Mayo Clinic, Rochester, Minnesota</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Disclaimer: The content is solely the
                          responsibility of the authors and does not necessarily represent the official views of the
                          National Institutes of Health.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;4b083972-6b42-425d-b925-c2f62cd2c93e&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=4b083972-6b42-425d-b925-c2f62cd2c93e&type=pdf&name=JBJS.23.00921.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=4b083972-6b42-425d-b925-c2f62cd2c93e&type=pdf&name=JBJS.23.00921.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_34"
                            class="annotation strong" style="position: unset;">Disclosure:</span> Dr. Daniel J. Berry
                          was funded by grants from the National Institutes of Health (R01AR73147, R01HL147155) and
                          NIAMS (P30AR76312). The Andrew A. and Mary S. Sugg Professorship in Orthopedic Research made
                          the research possible through its philanthropic support of Dr. Matthew P. Abdel. The <span
                            data-id="strong_35" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I81" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I81</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;4b083972-6b42-425d-b925-c2f62cd2c93e&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=4b083972-6b42-425d-b925-c2f62cd2c93e&type=zip&name=JBJS.23.00921.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Christopher N. Carender, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3535-5584" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3535-5584</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Dirk R. Larson, MS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2970-2124" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2970-2124</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Robert T. Trousdale, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4572-7540" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4572-7540</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">David G. Lewallen, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3101-9129" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3101-9129</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Daniel J. Berry, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1422-0306" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1422-0306</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Matthew P. Abdel, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:abdel.matthew@mayo.edu" class=""
                    style="position: unset;">abdel.matthew@mayo.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2398-1724" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2398-1724</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Mayo Clinic, Rochester, Minnesota</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 30741.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
