export const subjectCategoryMap: Record<string, string[]> = {
	"Part A (Pre-clinical subjects)": ["Anatomy", "Physiology", "Biochemistry"],
	"Part B (Para-clinical subjects)": [
		"Pathology",
		"Pharmacology",
		"Microbiology",
		"Forensic Medicine",
	],
	"Part C (Clinical subjects)": [
		"Pediatrics",
		"ENT",
		"Ophthalmology",
		"Medicine",
		"Surgery",
		"Gynaecology & Obstetrics",
		"Orthopedics",
		"Anesthesia",
		"Radiology",
		"Dermatology",
		"Venerology",
		"Psychiatry",
		"Emergency Medicine",
		"PSM",
	],
};
