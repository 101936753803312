import { pageViewTypes } from "@constants/tracking";
import { LatestIssuesCollectionRouteContainer } from "@features/articles";
import { issues } from "@features/articles/constants/issues";
import useAnalytics from "@hooks/useAnalytics";
import { useLocalSearchParams } from "expo-router";

const IssueList = () => {
	const { id } = useLocalSearchParams<{ id: string }>();

	const issue = issues.find((issue) => issue.id === Number.parseInt(id));

	useAnalytics({
		type: pageViewTypes.detailIssue,
		id,
	});

	return <LatestIssuesCollectionRouteContainer issue={issue} />;
};

export default IssueList;
