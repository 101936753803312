import type { Item, Section, SortOrder, SortType } from "../types";

const TIME_GROUPS = {
	THIS_WEEK: "Completed This Week",
	THIS_MONTH: "Completed This Month",
	LAST_3_MONTHS: "Completed Last 3 Months",
	THIS_YEAR: "Completed This Year",
	OLDER: "Completed Over a Year Ago",
} as const;

const PUBLISHED_GROUPS = {
	THIS_WEEK: "Published This Week",
	THIS_MONTH: "Published This Month",
	LAST_3_MONTHS: "Published Last 3 Months",
	THIS_YEAR: "Published This Year",
	OLDER: "Published Over a Year Ago",
} as const;

const DIFFICULTY_GROUPS = {
	EASY: "Beginner Friendly",
	MEDIUM: "Intermediate",
	HARD: "Advanced",
} as const;

const POPULARITY_GROUPS = {
	MOST_POPULAR: "Most Popular",
	TRENDING: "Trending",
	POPULAR: "Popular",
	LESS_POPULAR: "Less Popular",
} as const;

const getDifficultyGroup = (level: number | undefined): string => {
	if (level === undefined || level <= 3) {
		return DIFFICULTY_GROUPS.EASY;
	}
	if (level <= 6) {
		return DIFFICULTY_GROUPS.MEDIUM;
	}
	return DIFFICULTY_GROUPS.HARD;
};

const getPopularityGroup = (score: number | undefined): string => {
	if (!score) {
		return POPULARITY_GROUPS.LESS_POPULAR;
	}

	if (score >= 80) {
		return POPULARITY_GROUPS.MOST_POPULAR;
	}
	if (score >= 50) {
		return POPULARITY_GROUPS.TRENDING;
	}
	if (score >= 20) {
		return POPULARITY_GROUPS.POPULAR;
	}
	return POPULARITY_GROUPS.LESS_POPULAR;
};

const getUniqueAllItems = (sections: Section[]): Item[] => {
	const allItems = sections.flatMap((section) => section.data);
	return allItems.filter(
		(item, index, self) => index === self.findIndex((t) => t.id === item.id),
	);
};

const compareFunction = (a: number, b: number, sortOrder: SortOrder): number =>
	sortOrder === "asc" ? a - b : b - a;

const sortByDifficulty = (
	sections: Section[],
	sortOrder: SortOrder,
): Section[] => {
	const allItems = getUniqueAllItems(sections);
	const groupedItems = allItems.reduce<Record<string, Item[]>>((acc, item) => {
		const group = getDifficultyGroup(item.difficultyLevel);
		if (!acc[group]) {
			acc[group] = [];
		}
		acc[group].push(item);
		return acc;
	}, {});

	for (const items of Object.values(groupedItems)) {
		items.sort((a: Item, b: Item) => {
			const levelA = a.difficultyLevel || 0;
			const levelB = b.difficultyLevel || 0;
			return compareFunction(levelA, levelB, sortOrder);
		});
	}

	const difficultyGroups = [
		DIFFICULTY_GROUPS.EASY,
		DIFFICULTY_GROUPS.MEDIUM,
		DIFFICULTY_GROUPS.HARD,
	];

	if (sortOrder === "desc") {
		difficultyGroups.reverse();
	}

	return difficultyGroups
		.filter((group) => groupedItems[group]?.length > 0)
		.map((group) => ({
			title: `${group} • ${groupedItems[group].length} challenges`,
			data: groupedItems[group],
		}));
};

const getPublishedTimeGroup = (date: string): string => {
	const now = new Date();
	const itemDate = new Date(date);
	const diffDays = Math.floor(
		(now.getTime() - itemDate.getTime()) / (1000 * 60 * 60 * 24),
	);

	if (diffDays < 7) {
		return PUBLISHED_GROUPS.THIS_WEEK;
	}
	if (diffDays < 30) {
		return PUBLISHED_GROUPS.THIS_MONTH;
	}
	if (diffDays < 90) {
		return PUBLISHED_GROUPS.LAST_3_MONTHS;
	}
	if (diffDays < 365) {
		return PUBLISHED_GROUPS.THIS_YEAR;
	}
	return PUBLISHED_GROUPS.OLDER;
};

const sortByRecent = (sections: Section[], sortOrder: SortOrder): Section[] => {
	const allItems = getUniqueAllItems(sections);
	const groupedItems = allItems.reduce<Record<string, Item[]>>((acc, item) => {
		const group = getPublishedTimeGroup(
			item.updatedAt || new Date().toISOString(),
		);
		if (!acc[group]) {
			acc[group] = [];
		}
		acc[group].push(item);
		return acc;
	}, {});

	for (const items of Object.values(groupedItems)) {
		items.sort((a: Item, b: Item) => {
			const dateA = new Date(a.updatedAt || 0).getTime();
			const dateB = new Date(b.updatedAt || 0).getTime();
			return compareFunction(dateA, dateB, sortOrder);
		});
	}

	const publishedGroups = [
		PUBLISHED_GROUPS.THIS_WEEK,
		PUBLISHED_GROUPS.THIS_MONTH,
		PUBLISHED_GROUPS.LAST_3_MONTHS,
		PUBLISHED_GROUPS.THIS_YEAR,
		PUBLISHED_GROUPS.OLDER,
	];

	if (sortOrder === "desc") {
		publishedGroups.reverse();
	}

	return publishedGroups
		.filter((group) => groupedItems[group]?.length > 0)
		.map((group) => ({
			title: `${group} • ${groupedItems[group].length} challenges`,
			data: groupedItems[group],
		}));
};

const getCompletionTimeGroup = (
	completedAt: string | null | undefined,
): string => {
	if (!completedAt) {
		return "Not Completed";
	}

	const now = new Date();
	const completionDate = new Date(completedAt);
	const diffDays = Math.floor(
		(now.getTime() - completionDate.getTime()) / (1000 * 60 * 60 * 24),
	);

	if (diffDays < 7) {
		return TIME_GROUPS.THIS_WEEK;
	}
	if (diffDays < 30) {
		return TIME_GROUPS.THIS_MONTH;
	}
	if (diffDays < 90) {
		return TIME_GROUPS.LAST_3_MONTHS;
	}
	if (diffDays < 365) {
		return TIME_GROUPS.THIS_YEAR;
	}
	return TIME_GROUPS.OLDER;
};

const sortByCompletion = (
	sections: Section[],
	sortOrder: SortOrder,
): Section[] => {
	const allItems = getUniqueAllItems(sections);
	const groupedItems = allItems.reduce<Record<string, Item[]>>((acc, item) => {
		const group = getCompletionTimeGroup(item.completedAt);
		if (!acc[group]) {
			acc[group] = [];
		}
		acc[group].push(item);
		return acc;
	}, {});

	for (const items of Object.values(groupedItems)) {
		items.sort((a: Item, b: Item) => {
			const dateA = new Date(a.completedAt || 0).getTime();
			const dateB = new Date(b.completedAt || 0).getTime();
			return compareFunction(dateA, dateB, sortOrder);
		});
	}

	const timeGroups = [
		TIME_GROUPS.THIS_WEEK,
		TIME_GROUPS.THIS_MONTH,
		TIME_GROUPS.LAST_3_MONTHS,
		TIME_GROUPS.THIS_YEAR,
		TIME_GROUPS.OLDER,
		"Not Completed",
	];

	if (sortOrder === "desc") {
		timeGroups.reverse();
		timeGroups.unshift(timeGroups.pop()!);
	}

	return timeGroups
		.filter((group) => groupedItems[group]?.length > 0)
		.map((group) => ({
			title: `${group} • ${groupedItems[group].length} challenges`,
			data: groupedItems[group],
		}));
};

const sortByPopularity = (
	sections: Section[],
	sortOrder: SortOrder,
): Section[] => {
	const allItems = getUniqueAllItems(sections);
	const groupedItems = allItems.reduce<Record<string, Item[]>>((acc, item) => {
		const group = getPopularityGroup(item.popularityScore);
		if (!acc[group]) {
			acc[group] = [];
		}
		acc[group].push(item);
		return acc;
	}, {});

	for (const items of Object.values(groupedItems)) {
		items.sort((a: Item, b: Item) => {
			const scoreA = a.popularityScore || 0;
			const scoreB = b.popularityScore || 0;
			return compareFunction(scoreA, scoreB, sortOrder);
		});
	}

	const popularityGroups = [
		POPULARITY_GROUPS.MOST_POPULAR,
		POPULARITY_GROUPS.TRENDING,
		POPULARITY_GROUPS.POPULAR,
		POPULARITY_GROUPS.LESS_POPULAR,
	];

	if (sortOrder === "desc") {
		popularityGroups.reverse();
	}

	return popularityGroups
		.filter((group) => groupedItems[group]?.length > 0)
		.map((group) => ({
			title: `${group} • ${groupedItems[group].length} challenges`,
			data: groupedItems[group],
		}));
};

const sortBySpeciality = (
	sections: Section[],
	sortOrder: SortOrder,
): Section[] => {
	const uniqueItems = getUniqueAllItems(sections);

	const deduplicatedSections = sections.map((section) => ({
		...section,
		data: uniqueItems.filter((item) =>
			section.data.some((sectionItem) => sectionItem.id === item.id),
		),
	}));

	deduplicatedSections.sort((a, b) =>
		sortOrder === "asc"
			? a.title.localeCompare(b.title)
			: b.title.localeCompare(a.title),
	);

	return deduplicatedSections.filter((section) => section.data.length > 0);
};

export const sortSections = (
	sections: Section[],
	sortType: SortType,
	sortOrder: SortOrder,
): Section[] => {
	switch (sortType) {
		case "difficulty":
			return sortByDifficulty(sections, sortOrder);
		case "recent":
			return sortByRecent(sections, sortOrder);
		case "popular":
			return sortByPopularity(sections, sortOrder);
		case "speciality":
			return sortBySpeciality(sections, sortOrder);
		case "completed":
			return sortByCompletion(sections, sortOrder);
		default:
			return sections;
	}
};

export const filterSections = (
	sections: Section[],
	searchQuery: string,
	selectedFilters: string[],
	isPremiumSelected?: boolean,
	isCompletedSelected?: boolean,
): Section[] => {
	if (!(sections && Array.isArray(sections))) {
		return [];
	}

	let filteredItems = [...sections];

	if (searchQuery.trim() !== "") {
		const lowercaseQuery = searchQuery.toLowerCase().trim();
		filteredItems = filteredItems
			.map((section) => ({
				...section,
				data:
					section.data?.filter(
						(item: Item) =>
							item.title.toLowerCase().includes(lowercaseQuery) ||
							item.description.toLowerCase().includes(lowercaseQuery) ||
							item.tags?.some((tag) =>
								tag.label?.toLowerCase().includes(lowercaseQuery),
							),
					) || [],
			}))
			.filter((section) => section.data.length > 0);
	}

	if (selectedFilters?.length > 0) {
		filteredItems = filteredItems
			.map((section) => ({
				...section,
				data:
					section.data?.filter((item) =>
						item.tags?.some((tag) => selectedFilters.includes(tag.label!)),
					) || [],
			}))
			.filter((section) => section.data.length > 0);
	}

	if (isPremiumSelected) {
		filteredItems = filteredItems
			.map((section) => ({
				...section,
				data:
					section.data?.filter((item) => item.accessType === "PREMIUM") || [],
			}))
			.filter((section) => section.data.length > 0);
	}

	if (isCompletedSelected) {
		filteredItems = filteredItems
			.map((section) => ({
				...section,
				data: section.data?.filter((item) => item.completed) || [],
			}))
			.filter((section) => section.data.length > 0);
	}

	return filteredItems.map((section) => {
		const baseTitle = section.title.split("•")[0].trim();
		return {
			...section,
			title: `${baseTitle} • ${section.data?.length || 0} challenges`,
		};
	});
};
