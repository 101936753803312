/* -----------------Globals--------------- */
import { relayEnv } from "@memorang/configs";
import { fetchData } from "@memorang/helpers";
import type { UserInfo } from "@memorang/types";
import { graphql } from "react-relay";

type Response = {
	getUserInfo: UserInfo;
};
const query = graphql`
  query GetUserInfoQuery($email: AWSEmail!, $appId: String!) {
    getUserInfo(email: $email, appId: $appId) {
      id
      email
      school
      gradYear
      examDate
      name
      firstName
      lastName
      isOnboarded
      profession
      specialities
      country
      alerts {
        name
        optInStatus
      }
    }
  }
`;

const fetchUserInfo = async (email: string, appId: string) => {
	const variables = {
		email,
		appId,
	};
	await new Promise((resolve) => setTimeout(resolve, 8000));

	const { getUserInfo } = (await fetchData(
		relayEnv,
		query,
		variables,
	)) as Response;

	return getUserInfo;
};

export default fetchUserInfo;
