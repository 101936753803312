export type OriginalQuestionData = {
	stem: string;
	explanation?: string;
	choices: Choice[];
	topics: string[];
};

type Choice = {
	text?: string;
	isCorrect?: boolean;
};
