/* ----------------- UI Imports --------------- */
import { Box, ScrollContainer } from "@memorang/ui";

/* ----------------- Component Imports --------------- */
import BrowseArticlesView from "./BrowseArticlesView";

import AssistantFab from "components/AssistantFab";
/* ----------------- Types Imports --------------- */
import type { QuickActionsChipType } from "components/QuickActionChips/types";
import type { SectionListCardsType } from "types/image-challenge";

interface Props {
	quickActionsChips: QuickActionsChipType[];
	handleQuickActionPress: (action?: string) => void;
	issuesCollectionSectionListData: SectionListCardsType[];
	handleNavigateToAssistant: () => void;
}
const LatestIssuesCollectionView = ({
	issuesCollectionSectionListData,
	handleNavigateToAssistant,
}: Props) => {
	return (
		<>
			<ScrollContainer>
				<Box paddingTop={8} paddingBottom={8}>
					<BrowseArticlesView
						sectionListCardsData={issuesCollectionSectionListData}
					/>
				</Box>
			</ScrollContainer>
			<AssistantFab
				handleAssistantFabPress={handleNavigateToAssistant}
				customBottomPosition={16}
			/>
		</>
	);
};
export default LatestIssuesCollectionView;
