/* ----------------- Globals --------------- */
import { FilterView } from "@memorang/ui";

/* ----------------- Hooks --------------- */
import { useCountryOnboarding } from "../hooks/useCountryOnboarding";

const SelectCountryRouteContainer = () => {
	const { handleClickContinue, sectionedCountryListData, initSelectedItemId } =
		useCountryOnboarding();

	return (
		<FilterView
			sectionData={sectionedCountryListData}
			handleAction={handleClickContinue}
			type="country"
			shouldShowFilters={false}
			currentItemSelectedLabel="Current country"
			initSelectedItemId={initSelectedItemId}
		/>
	);
};

export default SelectCountryRouteContainer;
