/* -----------------Globals--------------- */
import { useNavigation, usePathname, useRouter } from "expo-router";

/* -----------------UI--------------- */
import { Appbar, type AppbarHeaderProps, Text } from "react-native-paper";

import { FeedbackDialog, defaultFeedbackSubtitle } from "@features/feedback";
import { isWeb } from "@helpers/platform";
import { useAppStore } from "@hooks/useAppStore";
/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "@hooks/useAppTheme";
import { useCustomNavigation } from "@hooks/useCustomNavigation";
import { useDevice } from "@hooks/useDevice";
import { useAuthContext } from "@memorang/applets";
import {
	Box,
	type BreadcrumbType,
	CustomBreadcrumbWeb,
	TooltipWrapper,
} from "@memorang/ui";
import { Image } from "@memorang/ui";
import AvatarGenerator from "@memorang/ui/src/components/NameCard/AvatarGenerator";
import { useState } from "react";
import RowStats, { type Stat } from "../RowStats";
import { CommandBar } from "./CommandBar";

const TitleContent = ({
	align = "flex-start",
	headerTitle,
	subtitle,
	stats,
	breadcrumbs,
	renderSearchbar,
	logo,
}: {
	align?: "center" | "flex-start";
	headerTitle?: string;
	subtitle?: string | React.ReactNode;
	stats?: Stat[];
	breadcrumbs?: BreadcrumbType[];
	renderSearchbar?: () => React.ReactNode;
	logo?: string;
}) => {
	const { isMobile, isMediumWindowSize } = useDevice();

	return (
		<Box
			alignItems={align}
			gap={isMobile || isMediumWindowSize ? 8 : 16}
			width={"100%"}
			position="relative"
		>
			{renderSearchbar?.()}
			{logo && (
				<Image
					source={{ uri: logo }}
					style={{
						width: 80,
						height: 40,
						position: "absolute",
						left: 0,
						top: "-140%",
					}}
					contentFit="contain"
				/>
			)}
			<Box gap={4} paddingTop={isMobile ? 0 : 16}>
				{breadcrumbs?.length && !isMobile && isWeb ? (
					<CustomBreadcrumbWeb breadcrumbs={breadcrumbs} />
				) : null}
				<Box alignItems={align}>
					{headerTitle && (
						<Text
							numberOfLines={1}
							style={{ maxWidth: isMobile ? 400 : "100%" }}
							variant={isMobile ? "headlineSmall" : "headlineMedium"}
						>
							{headerTitle as string}
						</Text>
					)}
					{subtitle && <Text variant="bodyMedium">{subtitle}</Text>}
				</Box>
			</Box>
			{stats?.length && !isMobile ? (
				<RowStats stacked={false} stats={stats} />
			) : null}
		</Box>
	);
};
const AppBarOpenPartnerSiteAction = () => {
	const {
		companyName,
		app: {
			links: { companyUrl },
		},
	} = useAppStore((store) => ({
		companyName: store.companyName,
		app: store.app,
	}));
	const navigateToPartnerSite = () => {
		window.open(companyUrl, "_blank");
	};
	return (
		<TooltipWrapper title={`Go to ${companyName} exams`}>
			<Appbar.Action icon="open-in-new" onPress={navigateToPartnerSite} />
		</TooltipWrapper>
	);
};

const CustomNavigationBar = (
	props: {
		back?: boolean;
		stats?: Stat[];
		breadcrumbs?: BreadcrumbType[];
		showRightAction?: boolean;
		showHambugerMenu?: boolean;
		showCommandBar?: boolean;
		renderSearchbar?: () => React.ReactNode;
		align?: "center" | "flex-start";
		showSettings?: boolean;
		showUserMenu?: boolean;
		handleClickInfoIcon?: () => void;
		options: {
			headerTitle?: string;
			subtitle?: string | React.ReactNode;
			headerRight?: () => React.ReactNode;
			headerLeft?: () => React.ReactNode;
		};
	} & Omit<AppbarHeaderProps, "children">,
) => {
	const {
		back,
		stats,
		options: { headerTitle, subtitle, headerRight, headerLeft },
		showRightAction = true,
		breadcrumbs,
		renderSearchbar,
		showHambugerMenu,
		align,
		showCommandBar,
		showSettings = true,
		showUserMenu = true,
		handleClickInfoIcon,
		...rest
	} = props;
	const theme = useAppTheme();

	const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
	const pathname = usePathname();

	const isHomeRoute = pathname === "/home";

	const { newUserInfo } = useAuthContext();

	const firstName = newUserInfo?.firstName;
	const lastName = newUserInfo?.lastName;

	const avatarType = useAppStore((state) => state.app.avatarType);

	const { handleFeedbackNavigation } = useCustomNavigation();

	const {
		app: { logo: appLogo },
	} = useAppStore((store) => ({
		companyName: store.companyName,
		app: store.app,
	}));
	const getAppLogo = () => {
		const {
			default: { light, dark },
		} = appLogo;
		return theme.dark ? dark : light;
	};
	const image = getAppLogo();

	const showFeedbackOnMobile = true;
	const toggleShowFeedbackDialog = () => {
		setShowFeedbackDialog((prev) => !prev);
	};

	const { isMobile, isMediumWindowSize } = useDevice();

	// FOR SOME REASON, the medium prop medium does not work well on tablet
	const mode =
		isMobile && !isWeb
			? "center-aligned"
			: isMediumWindowSize
				? "large"
				: "medium";
	const finalAlign =
		align || (isMobile ? ("center" as const) : ("flex-start" as const));

	const renderHeaderRight = () => {
		if (headerRight) {
			return headerRight();
		}
		if (isWeb && !isMobile && showRightAction) {
			return <AppBarOpenPartnerSiteAction />;
		}

		return (
			<Box flexDirection="row">
				<>
					{isWeb ? (
						<TooltipWrapper title="We value your feedback. Click here to share your thoughts.">
							<Appbar.Action
								icon="message-alert-outline"
								onPress={() =>
									handleFeedbackNavigation(toggleShowFeedbackDialog)
								}
							/>
						</TooltipWrapper>
					) : showFeedbackOnMobile ? (
						<Appbar.Action
							icon="message-alert-outline"
							onPress={() => handleFeedbackNavigation(toggleShowFeedbackDialog)}
						/>
					) : null}
				</>
				{showSettings && (
					<Appbar.Action
						icon="cog-outline"
						onPress={() => router.push("/(protected)/account/settings")}
					/>
				)}
				{showUserMenu &&
					(avatarType ? (
						<Appbar.Action
							icon={() => (
								<AvatarGenerator
									avatarType={avatarType}
									size={24}
									firstName={firstName}
									lastName={lastName}
									customStyle={{
										position: "relative",
										top: undefined,
									}}
								/>
							)}
						/>
					) : (
						<Appbar.Action icon="account-outline" />
					))}
				{handleClickInfoIcon && (
					<Appbar.Action
						icon="information-outline"
						onPress={handleClickInfoIcon}
					/>
				)}
			</Box>
		);
	};

	const router = useRouter();
	const navigation = useNavigation<{
		toggleDrawer: () => void;
	}>();
	return (
		//@ts-ignore
		<Appbar.Header
			theme={theme}
			mode={mode}
			{...rest}
			{...(isWeb
				? {
						style: {
							height: "fit-content",
						},
					}
				: {})}
		>
			{isWeb && (
				<Box
					position="absolute"
					alignItems="center"
					left={0}
					flexDirection="row"
				>
					{isMobile && showHambugerMenu && (
						<Appbar.Action
							icon="menu"
							onPress={() => navigation.toggleDrawer()}
						/>
					)}
					{showCommandBar && !isMobile && (
						<Box paddingLeft={12}>
							<CommandBar />
						</Box>
					)}
				</Box>
			)}

			{headerLeft?.()}
			{back && !headerLeft && (
				<Appbar.BackAction
					onPress={() => {
						if (router.canGoBack()) {
							router.back();
						} else {
							router.push("/(protected)/(tabs)/home");
						}
					}}
				/>
			)}
			<Appbar.Content
				title={
					<TitleContent
						align={finalAlign}
						headerTitle={headerTitle}
						subtitle={subtitle}
						stats={stats}
						logo={isHomeRoute ? image.uri : undefined}
						breadcrumbs={breadcrumbs}
						renderSearchbar={renderSearchbar}
					/>
				}
			/>
			{renderHeaderRight()}
			{isWeb && (
				<FeedbackDialog
					visible={showFeedbackDialog}
					view={pathname}
					feedbackSubtitle={defaultFeedbackSubtitle}
					handleDismiss={toggleShowFeedbackDialog}
				/>
			)}
		</Appbar.Header>
	);
};

export default CustomNavigationBar;
