//@ts-ignore
import Identicon from "identicon.js";
import { useEffect, useMemo, useState } from "react";
import type { StyleProp, ViewStyle } from "react-native";
import { Avatar } from "react-native-paper";
import { useAppTheme } from "../../useAppTheme";
import Image from "../Image";
import { getInitials } from "./helpers";

type Props = {
	firstName?: string;
	lastName?: string;
	avatarType?: "initials" | "identicon" | null;
	size?: number;
	customStyle?: StyleProp<ViewStyle>;
};
const getColorFromInitials = (initials: string) => {
	const colors = [
		"#FF6B6B",
		"#4ECDC4",
		"#45B7D1",
		"#FFA07A",
		"#98D8C8",
		"#F06292",
		"#AED581",
		"#FFD54F",
		"#4DB6AC",
		"#7986CB",
	];
	const index =
		initials.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0) %
		colors.length;
	return colors[index];
};

const AvatarGenerator = ({
	firstName,
	lastName,
	avatarType,
	size = 116,
	customStyle,
}: Props) => {
	const [identicon, setIdenticon] = useState("");

	const theme = useAppTheme();

	const initials = useMemo(() => getInitials(firstName), [firstName]);
	const avatarColor = useMemo(
		() => getColorFromInitials(initials || ""),
		[initials],
	);

	useEffect(() => {
		if (
			firstName &&
			lastName &&
			firstName.length > 0 &&
			lastName.length > 0 &&
			avatarType === "identicon"
		) {
			generateIdenticon(`${firstName} ${lastName}`);
		} else {
			setIdenticon("");
		}
	}, [firstName, lastName, avatarType]);

	const generateIdenticon = (input: string) => {
		let hash = "";
		while (hash.length < 15) {
			hash += input.split("").reduce((acc, char) => {
				return acc + char.charCodeAt(0).toString(16);
			}, "");
		}
		hash = hash.slice(0, 15);

		const options = {
			size: size * 2,
			format: "png",
		};

		const data = new Identicon(hash, options).toString();
		setIdenticon(`data:image/png;base64,${data}`);
	};

	if (!(initials || identicon)) {
		return (
			<Avatar.Icon
				size={size}
				icon="account-outline"
				style={{
					position: "absolute",
					top: "36%",
					backgroundColor: avatarColor,
					...(customStyle as object),
				}}
			/>
		);
	}

	if (avatarType === "initials" && initials) {
		return (
			<Avatar.Text
				size={size}
				label={initials}
				color={theme.colors.onPrimary}
				style={{
					position: "absolute",
					top: "36%",
					backgroundColor: avatarColor,
					...(customStyle as object),
				}}
			/>
		);
	}

	return (
		<>
			{identicon ? (
				<Image
					source={{ uri: identicon }}
					style={{
						position: "absolute",
						top: "36%",
						width: size,
						height: size,
						borderRadius: size / 2,
						borderColor: avatarColor,
						borderWidth: 2,
						...(customStyle as object),
					}}
				/>
			) : (
				<Avatar.Icon
					size={size}
					icon="account-outline"
					style={{
						position: "absolute",
						top: "36%",
						backgroundColor: avatarColor,
						...(customStyle as object),
					}}
				/>
			)}
		</>
	);
};

export default AvatarGenerator;
