import type { Article } from "../../../../../types";

export const Article551: Article = {
	id: 551,
	rsuiteId: "46049e4d-a449-4991-a627-c97bdb0bb888",
	type: "scientific article",
	title:
		"Liposomal Bupivacaine Does Not Decrease Postoperative Pain in Patients with Intracapsular Femoral Neck Fracture Treated with Hemiarthroplasty",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=46049e4d-a449-4991-a627-c97bdb0bb888&type=jpeg&name=JBJS.23.01344f1",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Liposomal Bupivacaine Does Not Decrease Postoperative
                Pain in Patients with Intracapsular Femoral Neck Fracture Treated with Hemiarthroplasty</div>
              <div class="text subtitle" style="position: unset;">HEAT—A Randomized, Controlled Trial</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Kevin K.
                      Kang, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Lucas
                      Voyvodic, BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_24" class="annotation strong"
                          style="position: unset;">Background:</span> Liposomal bupivacaine (LB) is a long-lasting local
                        anesthetic agent that was developed for use in the surgical setting to help manage postoperative
                        pain. The objective of this study was to evaluate the effect of LB on postoperative pain,
                        function, and overall hospital course in patients with intracapsular hip fractures who were
                        treated with hip hemiarthroplasty.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_25" class="annotation strong"
                          style="position: unset;">Methods:</span> This was a single-center, randomized prospective
                        double-blinded study of 50 patients with an isolated intracapsular femoral neck fracture who
                        were treated with hip hemiarthroplasty from 2018 to 2022. The study group consisted of 25
                        patients who were treated with intraoperative LB and bupivacaine hydrochloride injections, while
                        the control group consisted of 25 patients who were treated with intraoperative bupivacaine
                        hydrochloride injections only. Primary outcomes were a visual analog scale (VAS) score for pain,
                        total morphine milligram equivalents (MME), delirium, and time to ambulation with physical
                        therapy.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_26" class="annotation strong"
                          style="position: unset;">Results:</span> No significant differences between the study and
                        control groups were found in any of the outcomes measured. Most notably, there were no
                        differences in the average postoperative pain score (VAS, 2.26 versus 2.7; p = 0.34), total MME
                        used postoperatively (11.73 versus 9.98 MME; p = 0.71), and postoperative day of discharge (4.00
                        versus 3.88 days; p = 0.82).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_27" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The results of our study suggest that use of LB
                        is not associated with substantially improved postoperative pain or function or with a shorter
                        hospital course following hip hemiarthroplasty for a femoral neck fracture. Given the higher
                        cost of LB compared with standard postoperative pain modalities, it is worth questioning its use
                        in the setting of geriatric hip fractures.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_28" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level I</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Annually, &gt;300,000 people sustain a hip fracture in the
                  U.S., most of whom are ≥65 years of age<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  There is substantial morbidity and mortality associated with hip fractures, with multiple studies
                  estimating a 25% to 30% 1-year mortality rate<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>.
                  Thus, the majority of hip fractures are managed with surgery to decrease pain and improve functional
                  outcomes, although many patients remain unable to walk independently, have difficulties with daily
                  activities of living, and/or are transferred to a nursing home postoperatively<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a>. Postoperative delirium is common in the geriatric
                  population, affecting an estimated 19% to 50% of patients, and is an additional risk factor for
                  mortality and poor functional outcomes<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Historically, opioids have been used liberally to manage
                  postoperative pain, especially among orthopaedic surgeons, who accounted for the writing of 7.7% of
                  all opioid prescriptions in the U.S. in 2009, more than any other surgical specialty<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">5</span></a>. Ensuring appropriate postoperative analgesia is an
                  important factor to enhance functional outcomes and patient comfort, but it is essential to balance
                  achieving appropriate pain control against the risks of opioid use, which can cause oversedation and
                  respiratory depression and is an independent risk factor for delirium<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">6</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Liposomal bupivacaine (LB) is a long-lasting local
                  anesthetic agent that was developed for use in the surgical setting to potentially decrease
                  postoperative pain. It is designed to be administered as a single dose into the surgical site<a
                    href="" data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">7</span></a>. It involves encapsulation of bupivacaine into
                  multivesicular liposomes that are closely packed and released in an extended fashion, allowing drug
                  diffusion for up to 72 hours following intraoperative administration<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">8</span></a>. There is evidence that LB decreases postoperative pain
                  relative to placebo and/or control groups in various surgical procedures (e.g., hemorrhoidectomy<a
                    href="" data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">9</span></a>, colectomy<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">10</span></a>,
                  and bunionectomy<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">11</span></a>),
                  with recent studies supporting its efficacy in total joint arthroplasty (total knee arthroplasty [TKA]
                  and total hip arthroplasty [THA])<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">14</span></a>. However, little is known about its effectiveness in
                  patients with hip fractures; to our knowledge, there are no Level-I studies evaluating the effect of
                  LB on this population to date.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The aim of this study, the Hip Fracture Exparel
                  Administration (HEAT) trial, was to evaluate the effect of LB on postoperative pain, function, and
                  overall hospital course in patients with an intracapsular femoral neck fracture who were treated with
                  hip hemiarthroplasty. Given the prior studies that supported its use based on its extended-release
                  mechanism of drug diffusion, we hypothesized that LB would decrease postoperative pain scores and
                  analgesic requirements, and lead to earlier times to ambulation with physical therapy and discharge to
                  home.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Subjects</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">A total of 50 patients were enrolled in this prospective
                  cohort study, which was registered on ClinicalTrials.gov (NCT03502018)<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">15</span></a>. Inclusion criteria included patients who were ≥65 years of
                  age (with the ability to consent to the study) with an isolated intracapsular hip fracture undergoing
                  hemiarthroplasty via a posterior approach from March 2018 to April 2022. Patients were excluded if
                  they had an extracapsular hip fracture, they had any form of cognitive compromise that left them
                  unable to consent (e.g., dementia), or the treatment consisted of any surgical modality other than hip
                  hemiarthroplasty.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Via a random number generator used by the research fellow,
                  patients were randomized 1:1 into either the treatment group (injection with LB) or the control group
                  (injection with bupivacaine hydrochloride [HCl] and normal saline solution); there were 25 patients in
                  each group (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). Of
                  note, the research fellow worked with the nursing staff to draw up the appropriate injection materials
                  in order to ensure blinding from the rest of the surgical team. One patient from the control group was
                  excluded due to postoperative death. Demographic information, such as age, sex, and race of each
                  patient, was recorded.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01344f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=46049e4d-a449-4991-a627-c97bdb0bb888&type=jpeg&name=JBJS.23.01344f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;">Flowchart demonstrating the randomization of
                          patients into either the study or control group.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Technique</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Once the implants were in place and confirmed to be
                  appropriately positioned, the wound was copiously irrigated, and final hemostasis was obtained. The 2
                  groups of interest received either bupivacaine mixed with LB (study group of interest) or bupivacaine
                  HCl with saline solution (control group). Specifically, the control group received 40 mL of 0.25%
                  bupivacaine HCl (Marcaine) with 20 mL of normal saline solution that was injected in a systematic
                  fashion, with self-retaining retractors exposing the surgical field. Multiple 20-mL syringes with 22-G
                  spinal needles were used. Starting medially and posteriorly, the capsule was injected
                  circumferentially in 10 to 12 locations with 2 to 3 mL in each location, while watching for the
                  capsule to swell slightly. The posterior capsule was injected around the neck of the implant. Care was
                  taken to ensure that the needle only penetrated the capsule to a depth of 1 to 2 cm to prevent sciatic
                  or femoral nerve injury. The remaining bupivacaine was then used to inject the abductors and tensor
                  fasciae latae. The study group received 40 mL of 0.25% bupivacaine HCl with 20 mL of LB (Exparel;
                  Pacira Pharmaceuticals), for a total volume of 60 mL that was injected in the same locations and
                  manner as the control group. If any of the solution was remaining, the subcutaneous layer was
                  injected. The wound was then closed in the standard fashion. Of note, the intention-to-treat and
                  as-treated groups in our study were identical, with no crossover or dropout; patients consented to
                  both procedures preoperatively and were randomly assigned and administered either to LB and
                  bupivacaine HCl or the control group (bupivacaine HCl only) in a blinded fashion. To ensure
                  appropriate blinding, the outcome assessors for the visual analog scale (VAS) pain scores were
                  orthopaedic surgery residents on the trauma service (who were blinded to assignment status), and
                  manual chart review was conducted by research fellows to quantify the total morphine milligram
                  equivalents (MME) used, time to ambulation with physical therapy, and subsequent postoperative
                  complications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Institutional Pain Protocol</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Pain was managed postoperatively according to the standard
                  institutional pain protocol. Patients received general or spinal anesthesia, depending on the
                  indications, by the attending anesthesiologist; in general, spinal anesthesia was administered unless
                  otherwise contraindicated (e.g., patients who were being treated with anticoagulation, had substantial
                  aortic stenosis, etc.). The protocol consisted of acetaminophen (975 mg every 8 hours) for mild pain
                  (VAS, 1 to 3), oxycodone (5 mg every 4 hours as needed [PRN]) for moderate pain (VAS, 4 to 6), and
                  oxycodone (10 mg every 4 hours PRN) for severe pain (VAS, 7 to 10). Patients with breakthrough pain
                  received intravenous morphine dosed at 0.1 µg/kg/hr every 4 hours PRN.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Outcomes
                Measured</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The following primary outcomes were recorded for each
                  patient: total MME at 12, 24, and 48 hours postoperatively; VAS score for subjective pain at 4, 8, 12,
                  24, and 48 hours postoperatively; and time to ambulation with physical therapy. MME units were
                  calculated using the equianalgesic ratios for different opioid medications that have been described in
                  the literature. The following secondary outcomes were also recorded for each patient from the day of
                  surgery until discharge and/or readmission: length of stay, discharge disposition (home, skilled
                  nursing facility, or inpatient rehabilitation facility), 30-day readmission, delirium, and any adverse
                  events or complications. Of note, delirium was diagnosed by changes in baseline mental status; in
                  general, at our institution, postoperative patients in whom there is concern for delirium were seen by
                  an internal medicine comanagement team for an official diagnosis and further workup and/or management
                  (e.g., evaluation to rule out stroke or treat the potential etiology of the delirium).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">A power analysis was performed to ensure the study’s
                  ability to detect clinically relevant differences in outcomes between the cohorts. Our study was
                  powered with assumptions based on prior studies, including 1 that demonstrated that 20 patients were
                  needed to detect a 1 to 2-point difference in the VAS numerical pain score (0 to 10) and a pill count
                  difference of 1 pill/day<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">16</span></a>.
                  Given the prior literature, we determined that 36 patients were required to detect a significant
                  difference in the VAS at the 5% level with 80% power, a sampling ratio of 1:1, a standard deviation of
                  3, and an effect size of 2. Assuming a 10% study attrition rate, a total of at least 40 patients were
                  required; we chose to enroll 50 patients. In addition, a prior study evaluated regional blocks in
                  patients with a hip fracture and used a morphine sulfate equivalent of 3.5 ± 0.5 mg/day in the first 3
                  postoperative days<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">17</span></a>.
                  That study also identified a 33% reduction in MME (3.5 to 2.1 mg/day). Given a similar effect size,
                  our study would have required only 8 patients to have 80% power at a significance level of 0.05.
                  Because we included 50 patients, our study was powered to detect differences as low as 0.42 mg/day
                  (12% effect size). Of note, our study was initiated in large part in response to a 2017 proposal by
                  the Orthopaedic Trauma Association (OTA) entitled “Hip Fracture Patients Treated with Exparel Versus
                  the Standard of Care Will Have Better Outcomes,” which recommended that approximately 25 patients be
                  recruited to each arm of the study. All of the outcomes in our study were compared between cohorts
                  using the Welch t test. Statistical analysis was performed with SPSS (IBM); a p value of &lt;0.05 was
                  considered significant.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Demographics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">There was no significant difference in the mean age of
                  patients in the LB group relative to the control group (81.5 versus 84.3 years; p = 0.272) (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). The LB cohort included 20 women and 5 men, while the control
                  group included 17 women and 7 men. Therefore, there was no significant difference in the sex of the
                  patients between the groups (p = 0.46).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Patient-Reported Pain Score Outcomes Following Hip
                          Hemiarthroplasty in the LB and Control Cohorts<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 362px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Control</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">LB</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of patients</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_26" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">84.28</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">81.54</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.27</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pain at 4 hr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.16</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.75</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.57</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pain at 8 hr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.80</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.13</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.20</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pain at 12 hr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.32</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.17</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.83</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pain at 24 hr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.48</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.08</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.61</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pain at 48 hr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.60</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.96</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.44</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Average pain</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.70</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.26</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.34</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Hip hemiarthroplasty for intracapsular femoral neck fracture. LB =
                        liposomal bupivacaine.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">VAS Pain
                Scores</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The average postoperative VAS pain score for the LB group
                  was not found to be significantly different from the control group (2.3 versus 2.7; p = 0.34) (<a
                    href="" data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). Furthermore, pain scores at 4 hours (2.8 versus 3.2; p =
                  0.57), 8 hours (2.1 versus 2.8; p = 0.2), 12 hours (2.2 versus 2.3; p = 0.83), 24 hours (2.1 versus
                  2.5; p = 0.61), and 48 hours (2.0 versus 2.6; p = 0.44) were not found to be significantly different
                  between the LB and control cohorts (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01344f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=46049e4d-a449-4991-a627-c97bdb0bb888&type=jpeg&name=JBJS.23.01344f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">Postoperative VAS pain scores.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Delirium</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">One patient in the LB group developed delirium at 24
                  hours, which was resolved at 48 hours (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). No
                  patients in the control group developed delirium. The difference between the groups at 24 hours was
                  not found to be significant (p = 0.32).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Other Outcomes Following Hip Arthroplasty in the LB
                          and Control Cohorts<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 401px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Control</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">LB</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of patients</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MME at 12 hours</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.20</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.65</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.36</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MME at 24 hr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.58</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.59</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.00</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MME at 48 hr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.20</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.65</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.83</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total MME</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">9.98</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.73</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.71</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Delirium at 24 hr</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.32</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Delirium at 48 hr</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">POD of first ambulation
                          with PT</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.44</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.08</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.07</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">POD of discharge</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.88</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.00</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.82</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Analysis of morphine equivalent usage, time to ambulation with physical
                        therapy, and discharge after hip hemiarthroplasty following intracapsular femoral neck fracture
                        in Exparel (liposomal bupivacaine) versus control cohorts. LB = liposomal bupivacaine, MME =
                        morphine milligram equivalents, POD = postoperative day, and PT = physical therapy.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Opioid
                Medication Use</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">The total MME used in the LB group were not found to be
                  significantly different from those in the control group (11.73 versus 9.98 MME; p = 0.71) (<a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). Additionally, no significant difference was observed in MME
                  use at 12 hours (4.65 versus 3.2 MME; p = 0.36), 24 hours (3.6 versus 3.6 MME; p = 1.00), or 48 hours
                  (3.7 versus 3.2 MME; p = 0.83) between the 2 cohorts (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01344f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=46049e4d-a449-4991-a627-c97bdb0bb888&type=jpeg&name=JBJS.23.01344f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;">Postoperative opioid use.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Day of
                Ambulation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">The mean postoperative day of ambulation was 1.1 day in
                  the LB group and 1.4 day in the control group (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). This
                  was not found to be significantly different between the groups (p = 0.07) (<a href=""
                    data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01344f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=46049e4d-a449-4991-a627-c97bdb0bb888&type=jpeg&name=JBJS.23.01344f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;">Postoperative day (POD) of ambulation and
                          discharge.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Day of
                Discharge</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">The mean postoperative day of discharge was 4 days in the
                  LB group versus 3.9 days in the control group (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). This
                  was not found to be significantly different between the groups (p = 0.82) (<a href=""
                    data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The purpose of this study was to determine if LB decreases
                  postoperative pain and improves function after hip hemiarthroplasty for femoral neck fracture. Our
                  HEAT trial, which to our knowledge is the only Level-I study of hip fractures treated with LB,
                  demonstrated that it does not significantly decrease postoperative pain, opioid use, time to
                  postoperative ambulation, or time to discharge.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Although this study was a randomized controlled trial
                  (RCT) that included Level-I evidence, it does have limitations. First, given that our study found no
                  significant difference in the effect of LB for treating postoperative pain after a hip fracture, one
                  must wonder if the study was adequately powered. Of note, the study was powered with assumptions based
                  on prior studies, including 1 that demonstrated that 20 patients were required for detection of a 1 to
                  2-point difference in the numerical pain score (0 to 10) and a pill count difference of 1 pill/day<a
                    href="" data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">16</span></a>. However, a type-II error is always a possibility, and
                  larger-scale studies may be necessary. Second, the external validity of this study must be put into
                  context, as it was performed at a single center with unique institutional pain protocols limited to
                  geriatric patients with a hip fracture without dementia who underwent hemiarthroplasty. In addition,
                  our study design included only 48 hours of follow-up for the pain outcome, although health-care
                  utilization outcomes (i.e., time to ambulation with physical therapy and time to discharge) were
                  followed for longer. This is in line with previous studies, especially given the relatively short
                  efficacy of LB at 48 to 72 hours after administration<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">18</span></a>. It is also important to consider injection technique:
                  while our institution’s surgical protocol uses intracapsular injection of the anesthetic, it is
                  possible that LB only has efficacious effects in certain injection sites from which the drug can
                  diffuse over time. In addition, while the company that manufactures LB recommends the use of a 25-G
                  needle to maintain liposomal integrity and the injection of 1 to 2 mL at a time, our protocol included
                  the use of 22-G spinal needles because of the volume used (60 mL) as well as the desire to decrease
                  operative time in geriatric patients with a hip fracture. Finally, while our study included both
                  subjective (VAS pain scores) and objective markers of pain (MME usage, time to ambulation with
                  physical therapy, and time to discharge), it did not include other patient-reported outcome measures
                  (e.g., Harris hip score). Overall, to our knowledge, our study was the first to examine the use of LB
                  for postoperative pain management after hemiarthroplasty in geriatric patients with a hip fracture.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The current literature on the efficacy of LB in
                  orthopaedic surgery shows mixed results. A retrospective study of 586 patients undergoing THA
                  demonstrated decreased total narcotic use up to postoperative day 2 (p = 0.016), increased physical
                  therapy milestones (p &lt; 0.001), and decreased length of stay by 0.31 days (p &lt; 0.001), with no
                  difference in operative time or hospital cost<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">19</span></a>. Of
                  note, that study incorporated bupivacaine HCl into the superficial injections, but the periarticular
                  injection included LB compared with no control or other group, which resulted in a potentially less
                  well-controlled design than our study. A meta-analysis of 6 studies (2 RCTs and 4 non-RCTs with
                  variable controls) with 6,009 patients undergoing THA concluded that LB demonstrates better pain
                  control and reduces length of hospital stay after THA<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">20</span></a>. A
                  meta-analysis of 16 trials, with variable control groups, involving patients undergoing primary TKA
                  who received LB demonstrated no significant superiority of LB with respect to opioid consumption or
                  range of motion but did demonstrate a shorter length of stay by 0.17 days, which was attributed to
                  potential publication bias (Egger test, x intercept = 2.42, p &lt; 0.001)<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">13</span></a>. An additional meta-analysis of 11 studies comparing LB via
                  periarticular injection for patients undergoing TKA with variable controls found no advantage with
                  respect to the VAS pain score (p = 0.46), total opioid consumption (p = 0.25), length of stay (p =
                  0.53), and ambulation distance (p = 0.07), and therefore recommended conventional periarticular
                  injections<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">21</span></a>.
                  Considering the results of these studies, there is some evidence that LB may be effective in
                  decreasing postoperative pain in patients undergoing total joint arthroplasty<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">14</span></a>. However, controls for the trials were often variable or
                  lacking (i.e., LB was compared with no injection rather than with bupivacaine HCL or another kind of
                  injection).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">There are few studies to date that have examined the
                  intraoperative use of LB in orthopaedic surgery in patients with fractures. A systematic review of 8
                  studies involving surgical fracture treatment with use of LB demonstrated mixed results and
                  variability in comparison groups and study designs, resulting in a lack of clear evidence<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">8</span></a>. An RCT of 41 patients who underwent open reduction and
                  internal fixation (ORIF) of the distal radius found that LB decreased pain levels (3.9 versus 5.8) and
                  opioid consumption (1.2 versus 2.0 pills) on the day of surgery but not on any subsequent days<a
                    href="" data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">16</span></a>. Another prospective single-blinded RCT of 76 patients who
                  underwent ORIF for an acute ankle fracture found that the 37 patients who received LB had lower pain
                  scores for up to 2 weeks after surgery and decreased Percocet (oxycodone and acetaminophen combined)
                  ingestion at 4 hours (p = 0.004), with no differences in satisfaction with pain control (p = 0.93)<a
                    href="" data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">22</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, our study is the first RCT that examined
                  the potential utility of LB in geriatric patients with a hip fracture. In the context of hip
                  fractures, a retrospective study of 100 patients with a periarticular femoral neck fracture who were
                  treated with hemiarthroplasty found no difference in pain scores from postoperative days 1 to 4,
                  narcotic use, or MME used, but noted a decreased length of stay (4.8 versus 5.7 days), supporting its
                  use as a part of a multimodal analgesic program<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">23</span></a>. It
                  is challenging to reconcile a decreased length of stay with no changes in pain scores or narcotic use;
                  our study found no difference in pain scores, narcotic use, or length of stay. A more recent
                  single-center retrospective review of 109 hip fractures in patients with a mean age of 81.2 years
                  found that 82 patients who received LB had decreased intravenous opioid requirements (day 1, p &lt;
                  0.001; day 2, p = 0.002; day 3, p = 0.030), with a nonsignificant decrease in delirium rates (23.9%
                  versus 32.8%, p = 0.272)<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">24</span></a>. Of
                  note, that study included both intracapsular and extracapsular fractures, did not standardize the
                  injection amount and/or locations, and, most importantly, as a retrospective observational study, did
                  not randomize the patients. Furthermore, while the VAS pain scores were roughly similar to ours
                  (ranging between 2 and 3 of 10 up to postoperative day 3), their lengths of stay among both control
                  and intervention groups (6.60 versus 8.47 days, respectively) were longer than those in our study
                  (3.88 versus 4.00 days). This hints at the substantial variability in the multimodal pain regimen and
                  the disposition protocols among geriatric patients with a hip fracture among different institutions.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Overall, our study found that use of LB does not lead to
                  significantly improved postoperative pain by subjective (VAS scores) or objective (narcotic usage)
                  measures, function (time to ambulation), or hospital course following hip hemiarthroplasty for a
                  femoral neck fracture. Given the greater cost of LB versus bupivacaine ($334 versus $3)<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">23</span></a> or other postoperative pain modalities, it is worth
                  examining its use and application in the setting of geriatric hip fractures. It is also prudent to
                  examine the injection technique and to standardize preparation and use for future studies. In a recent
                  systematic review of 8 studies involving surgical fracture repair with use of LB as part of
                  postoperative pain management, 7 reported sponsorship by or a conflict of interest involving Pacira
                  Pharmaceuticals<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">8</span></a>. To
                  this end, there is a continued need for prospective RCTs that are not sponsored by pharmaceutical
                  companies and use of more consistent, standardized controls.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">The aim of this study was to determine if LB decreases
                  postoperative pain and improves function after hip hemiarthroplasty for a femoral neck fracture. Our
                  HEAT trial, which to our knowledge is the only Level-I study of hip fractures treated with LB,
                  demonstrated that use of LB did not significantly decrease postoperative pain, opioid use, time to
                  postoperative ambulation, or time to discharge. Given the increased costs associated with LB, it is
                  worth questioning its use in the setting of geriatric patients with a hip fracture undergoing
                  hemiarthroplasty.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Brauer CA</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Coca-Perraillon M</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Cutler DM</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Rosen AB</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Incidence and mortality of hip
                  fractures in the United States</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2009 Oct 14;302(14):1573-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%20and%20mortality%20of%20hip%20fractures%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22CA%20Brauer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Cenzer IS</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Tang V</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Boscardin WJ</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Smith AK</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Ritchie C</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Wallhagen MI</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Espaldon R</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Covinsky KE</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">One-Year Mortality After Hip
                  Fracture: Development and Validation of a Prognostic Index</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">J Am Geriatr Soc.</span> 2016
                Sep;64(9):1863-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=One-Year%20Mortality%20After%20Hip%20Fracture%3A%20Development%20and%20Validation%20of%20a%20Prognostic%20Index&as_occt=title&as_sauthors=%20%22IS%20Cenzer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Neuman MD</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Silber JH</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Magaziner JS</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Passarella MA</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Mehta S</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Werner RM</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Survival and functional outcomes
                  after hip fracture among nursing home residents</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">JAMA Intern Med.</span> 2014
                Aug;174(8):1273-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Survival%20and%20functional%20outcomes%20after%20hip%20fracture%20among%20nursing%20home%20residents&as_occt=title&as_sauthors=%20%22MD%20Neuman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Kyziridis TC</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Post-operative delirium after
                  hip fracture treatment - a review of the current literature</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Psychosoc Med.</span> 2006 Feb
                8;3:Doc01.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Post-operative%20delirium%20after%20hip%20fracture%20treatment%20-%20a%20review%20of%20the%20current%20literature&as_occt=title&as_sauthors=%20%22TC%20Kyziridis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Boddapati V</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Padaki AS</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Lehman RA</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Levine WN</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Riew KD</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Opioid Prescriptions by
                  Orthopaedic Surgeons in a Medicare Population: Recent Trends, Potential Complications, and
                  Characteristics of High Prescribers</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2021 Mar 1;29(5):e232-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Opioid%20Prescriptions%20by%20Orthopaedic%20Surgeons%20in%20a%20Medicare%20Population%3A%20Recent%20Trends%2C%20Potential%20Complications%2C%20and%20Characteristics%20of%20High%20Prescribers&as_occt=title&as_sauthors=%20%22V%20Boddapati%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Smith TO</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Cooper A</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Peryer G</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Griffiths R</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Fox C</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Cross J</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Factors predicting incidence of
                  post-operative delirium in older people following hip fracture surgery: a systematic review and
                  meta-analysis</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Int J Geriatr Psychiatry.</span> 2017 Apr;32(4):386-96.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20predicting%20incidence%20of%20post-operative%20delirium%20in%20older%20people%20following%20hip%20fracture%20surgery%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22TO%20Smith%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Tong YCI</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Kaye AD</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Urman RD</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Liposomal bupivacaine and
                  clinical outcomes</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Best Pract Res Clin Anaesthesiol.</span> 2014 Mar;28(1):15-27.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Liposomal%20bupivacaine%20and%20clinical%20outcomes&as_occt=title&as_sauthors=%20%22YCI%20Tong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Gailey AD</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Ostrum RF</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">The use of liposomal bupivacaine
                  in fracture surgery: a review</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">J Orthop Surg Res.</span> 2023 Apr 1;18(1):267.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20use%20of%20liposomal%20bupivacaine%20in%20fracture%20surgery%3A%20a%20review&as_occt=title&as_sauthors=%20%22AD%20Gailey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Gorfine SR</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Onel E</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Patou G</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Krivokapic ZV</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Bupivacaine extended-release
                  liposome injection for prolonged postsurgical analgesia in patients undergoing hemorrhoidectomy: a
                  multicenter, randomized, double-blind, placebo-controlled trial</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Dis Colon Rectum.</span> 2011
                Dec;54(12):1552-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bupivacaine%20extended-release%20liposome%20injection%20for%20prolonged%20postsurgical%20analgesia%20in%20patients%20undergoing%20hemorrhoidectomy%3A%20a%20multicenter%2C%20randomized%2C%20double-blind%2C%20placebo-controlled%20trial&as_occt=title&as_sauthors=%20%22SR%20Gorfine%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Cohen SM</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Extended pain relief trial
                  utilizing infiltration of Exparel(®), a long-acting multivesicular liposome formulation of
                  bupivacaine: a Phase IV health economic trial in adult patients undergoing open colectomy</span>.
                <span data-id="emphasis_10" class="annotation emphasis" style="position: unset;">J Pain Res.</span>
                2012;5:567-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Extended%20pain%20relief%20trial%20utilizing%20infiltration%20of%20Exparel(%C2%AE)%2C%20a%20long-acting%20multivesicular%20liposome%20formulation%20of%20bupivacaine%3A%20a%20Phase%20IV%20health%20economic%20trial%20in%20adult%20patients%20undergoing%20open%20colectomy&as_occt=title&as_sauthors=%20%22SM%20Cohen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Golf M</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Daniels SE</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Onel E</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">A phase 3, randomized,
                  placebo-controlled trial of DepoFoam® bupivacaine (extended-release bupivacaine local analgesic) in
                  bunionectomy</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">Adv Ther.</span> 2011 Sep;28(9):776-88.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20phase%203%2C%20randomized%2C%20placebo-controlled%20trial%20of%20DepoFoam%C2%AE%20bupivacaine%20(extended-release%20bupivacaine%20local%20analgesic)%20in%20bunionectomy&as_occt=title&as_sauthors=%20%22M%20Golf%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Domb BG</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Gupta A</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Hammarstedt JE</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Stake CE</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Sharp K</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Redmond JM</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">The effect of liposomal
                  bupivacaine injection during total hip arthroplasty: a controlled cohort study</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">BMC Musculoskelet
                  Disord.</span> 2014 Sep 24;15(1):310.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20liposomal%20bupivacaine%20injection%20during%20total%20hip%20arthroplasty%3A%20a%20controlled%20cohort%20study&as_occt=title&as_sauthors=%20%22BG%20Domb%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Singh PM</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Borle A</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Trikha A</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Michos L</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Sinha A</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Goudra B</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Role of Periarticular Liposomal
                  Bupivacaine Infiltration in Patients Undergoing Total Knee Arthroplasty-A Meta-analysis of Comparative
                  Trials</span>. <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2017 Feb;32(2):675-688.e1.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Role%20of%20Periarticular%20Liposomal%20Bupivacaine%20Infiltration%20in%20Patients%20Undergoing%20Total%20Knee%20Arthroplasty-A%20Meta-analysis%20of%20Comparative%20Trials&as_occt=title&as_sauthors=%20%22PM%20Singh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Barrington JW</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Olugbode O</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Lovald S</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Ong K</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Watson H</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Emerson RH Jr</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Liposomal Bupivacaine: A
                  Comparative Study of More Than 1000 Total Joint Arthroplasty Cases</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">Orthop Clin North Am.</span> 2015
                Oct;46(4):469-77.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Liposomal%20Bupivacaine%3A%20A%20Comparative%20Study%20of%20More%20Than%201000%20Total%20Joint%20Arthroplasty%20Cases&as_occt=title&as_sauthors=%20%22JW%20Barrington%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Kang KK</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Hip Fracture Exparel
                  Administration Trial Capsule During Hemiarthroplasty.</span> 2018. Accessed 2023 Jun 3. <a
                  href="https://clinicaltrials.gov/ct2/show/NCT03502018" target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://clinicaltrials.gov/ct2/show/NCT03502018</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Alter TH</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Liss FE</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Ilyas AM</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">A Prospective Randomized Study
                  Comparing Bupivacaine Hydrochloride Versus Bupivacaine Liposome for Pain Management After Distal
                  Radius Fracture Repair Surgery</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">J Hand Surg Am.</span> 2017 Dec;42(12):1003-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Prospective%20Randomized%20Study%20Comparing%20Bupivacaine%20Hydrochloride%20Versus%20Bupivacaine%20Liposome%20for%20Pain%20Management%20After%20Distal%20Radius%20Fracture%20Repair%20Surgery&as_occt=title&as_sauthors=%20%22TH%20Alter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Morrison RS</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Dickman E</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Hwang U</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Akhtar S</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Ferguson T</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Huang J</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Jeng CL</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Nelson BP</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Rosenblatt MA</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Silverstein JH</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Strayer RJ</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Torrillo TM</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Todd KH</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Regional Nerve Blocks Improve
                  Pain and Functional Outcomes in Hip Fracture: A Randomized Controlled Trial</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J Am Geriatr Soc.</span>
                2016 Dec;64(12):2433-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Regional%20Nerve%20Blocks%20Improve%20Pain%20and%20Functional%20Outcomes%20in%20Hip%20Fracture%3A%20A%20Randomized%20Controlled%20Trial&as_occt=title&as_sauthors=%20%22RS%20Morrison%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Bagsby DT</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Ireland PH</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Meneghini RM</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Liposomal bupivacaine versus
                  traditional periarticular injection for pain control after total knee arthroplasty</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2014
                Aug;29(8):1687-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Liposomal%20bupivacaine%20versus%20traditional%20periarticular%20injection%20for%20pain%20control%20after%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22DT%20Bagsby%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Yu SW</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Szulc AL</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Walton SL</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Davidovitch RI</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Bosco JA</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Iorio R</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Liposomal Bupivacaine as an
                  Adjunct to Postoperative Pain Control in Total Hip Arthroplasty</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2016
                Jul;31(7):1510-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Liposomal%20Bupivacaine%20as%20an%20Adjunct%20to%20Postoperative%20Pain%20Control%20in%20Total%20Hip%20Arthroplasty&as_occt=title&as_sauthors=%20%22SW%20Yu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Ma TT</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Wang YH</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Jiang YF</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Peng CB</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Yan C</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Liu ZG</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Xu WX</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Liposomal bupivacaine versus
                  traditional bupivacaine for pain control after total hip arthroplasty: A meta-analysis</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Medicine
                  (Baltimore).</span> 2017 Jun;96(25):e7190.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Liposomal%20bupivacaine%20versus%20traditional%20bupivacaine%20for%20pain%20control%20after%20total%20hip%20arthroplasty%3A%20A%20meta-analysis&as_occt=title&as_sauthors=%20%22TT%20Ma%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Kuang MJ</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Du Y</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Ma JX</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> He W</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Fu L</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Ma XL</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">The Efficacy of Liposomal
                  Bupivacaine Using Periarticular Injection in Total Knee Arthroplasty: A Systematic Review and
                  Meta-Analysis</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2017 Apr;32(4):1395-402.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Efficacy%20of%20Liposomal%20Bupivacaine%20Using%20Periarticular%20Injection%20in%20Total%20Knee%20Arthroplasty%3A%20A%20Systematic%20Review%20and%20Meta-Analysis&as_occt=title&as_sauthors=%20%22MJ%20Kuang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Davidovitch R</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Goch A</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Driesman A</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Konda S</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Pean C</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Egol K</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">The Use of Liposomal
                  Bupivacaine Administered With Standard Bupivacaine in Ankle Fractures Requiring Open Reduction
                  Internal Fixation: A Single-Blinded Randomized Controlled Trial</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2017
                Aug;31(8):434-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Use%20of%20Liposomal%20Bupivacaine%20Administered%20With%20Standard%20Bupivacaine%20in%20Ankle%20Fractures%20Requiring%20Open%20Reduction%20Internal%20Fixation%3A%20A%20Single-Blinded%20Randomized%20Controlled%20Trial&as_occt=title&as_sauthors=%20%22R%20Davidovitch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Hutchinson HL</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Jaekel DJ</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Lovald ST</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Watson HN</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Ong KL</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Multimodal Pain Management of
                  Femoral Neck Fractures Treated With Hemiarthroplasty</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">J Surg Orthop Adv.</span> 2019
                Spring;28(1):58-62.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Multimodal%20Pain%20Management%20of%20Femoral%20Neck%20Fractures%20Treated%20With%20Hemiarthroplasty&as_occt=title&as_sauthors=%20%22HL%20Hutchinson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Stevenson KL</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Stein MK</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Fryhofer GW</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Looby S</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Donegan D</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Mehta S</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Liposomal bupivacaine decreases
                  intravenous opioid use in patients with hip fracture: a modification to a novel pain protocol</span>.
                <span data-id="emphasis_24" class="annotation emphasis" style="position: unset;">J Am Acad Orthop
                  Surg.</span> 2023 Mar 1;31(5):e246-55.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Liposomal%20bupivacaine%20decreases%20intravenous%20opioid%20use%20in%20patients%20with%20hip%20fracture%3A%20a%20modification%20to%20a%20novel%20pain%20protocol&as_occt=title&as_sauthors=%20%22KL%20Stevenson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="infographic_reference" class="annotation infographic_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node infographic" data-id="infographic" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Infographic</div><a class="link jbjs_tracking" jbjs_tracking_type="download"
                jbjs_tracking_data="{&quot;id&quot;:&quot;46049e4d-a449-4991-a627-c97bdb0bb888&quot;,&quot;type&quot;:&quot;infographic&quot;,&quot;topics&quot;:[&quot;ta hp&quot;]}"
                href="/php/content/content_api.php?op=download&rsuite_id=46049e4d-a449-4991-a627-c97bdb0bb888&type=infographic"
                jbjs_tracking_source="reader" style="position: unset;">Download</a>
              <div class="infographic-wrapper" style="position: unset;">
                <div class="viewer" style="position: unset;">
                  <div class="progress-bar" style="position: unset; width: 4.95401%;"></div>
                  <div class="pages" style="position: unset;"></div>
                  <div class="toolbar" style="position: unset;"><button id="zoom-out" class=""
                      style="position: unset;">-</button><button id="zoom-in" class=""
                      style="position: unset;">+</button><button id="full-screen" class="" style="position: unset;">Full
                      screen ON</button></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D46049e4d-a449-4991-a627-c97bdb0bb888%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F21%2F1957%26topics%3Dta%2Bhp"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node eletter" data-id="eletter_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="file" style="position: unset;"><span class="" style="position: unset;">eLetter 1</span><a
                  class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;46049e4d-a449-4991-a627-c97bdb0bb888&quot;,&quot;type&quot;:&quot;eletter&quot;,&quot;topics&quot;:[&quot;ta hp&quot;]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=46049e4d-a449-4991-a627-c97bdb0bb888&type=eletter&name=1451"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 6, 2024;
                      106 (21): 1957</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01344</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 18, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_25"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopaedic Surgery, Maimonides Medical Center, Brooklyn, New York</span>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;46049e4d-a449-4991-a627-c97bdb0bb888&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;ta hp&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=46049e4d-a449-4991-a627-c97bdb0bb888&type=pdf&name=JBJS.23.01344.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=46049e4d-a449-4991-a627-c97bdb0bb888&type=pdf&name=JBJS.23.01344.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_29"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_30" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I173"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I173</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;46049e4d-a449-4991-a627-c97bdb0bb888&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;ta hp&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=46049e4d-a449-4991-a627-c97bdb0bb888&type=zip&name=JBJS.23.01344.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                        Sharing</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">A <span data-id="strong_31"
                            class="annotation strong" style="position: unset;">data-sharing statement</span> is provided
                          with the online version of the article (<a href="http://links.lww.com/JBJS/I174"
                            target="_blank" data-id="link_3" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I174</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Sharing
                          PDF</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;46049e4d-a449-4991-a627-c97bdb0bb888&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;ta hp&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=46049e4d-a449-4991-a627-c97bdb0bb888&type=pdf&name=JBJS.23.01344.dataavailability.pdf&subtype=dataavailability"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kevin K. Kang, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0551-0734" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0551-0734</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lucas Voyvodic, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0005-2671-8453" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0005-2671-8453</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Daniel Komlos, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0003-4588-8074" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0003-4588-8074</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Samuel Swiggett, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8551-4884" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8551-4884</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mitchell K. Ng, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:mitchng77@gmail.com" class="" style="position: unset;">mitchng77@gmail.com</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5831-055X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5831-055X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Maimonides Medical Center, Brooklyn, New
                York</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 25022.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
