import type { Article } from "../../../../../types";

export const Article648: Article = {
	id: 648,
	rsuiteId: "52dcbf9b-090a-42e2-a10e-a20a55ef5cb0",
	type: "scientific article",
	title:
		"Association Between Nail Type and Aseptic Revision Risk After Cephalomedullary Nailing for Hip Fracture",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=52dcbf9b-090a-42e2-a10e-a20a55ef5cb0&type=jpeg&name=JBJS.24.00390f1",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Association Between Nail Type and Aseptic Revision Risk
                After Cephalomedullary Nailing for Hip Fracture</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Kanu Okike,
                      MD, MPH</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Richard N.
                      Chang, MPH</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 7 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_44" class="annotation strong"
                          style="position: unset;">Background:</span> Although the majority of intertrochanteric femoral
                        fractures in the United States are now treated with cephalomedullary nailing, it remains
                        uncertain whether differences in clinical performance by nail type exist. The purpose of this
                        study was to compare the aseptic revision rates associated with the 3 most commonly utilized
                        cephalomedullary nails in the United States today: the Gamma nail (Stryker), the INTERTAN
                        (Smith+Nephew), and the Trochanteric Fixation Nail/Trochanteric Fixation Nail Advanced
                        (TFN/TFNA; DePuy Synthes).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_45" class="annotation strong"
                          style="position: unset;">Methods:</span> Using an integrated health-care system’s hip fracture
                        registry, patients ≥60 years of age who were treated with 1 of these 3 commonly used
                        cephalomedullary nail devices were identified. Potential confounders were identified and
                        controlled for, including age, gender, race or ethnicity, body mass index, smoking status,
                        American Society of Anesthesiologists classification, anesthesia type, Elixhauser comorbidities,
                        and the operating surgeon. Multivariable Cox proportional-hazards regression was used to
                        evaluate the risk of aseptic revision (the primary outcome measure) by cephalomedullary nail
                        type, with mortality and revisions unrelated to the index fracture considered as competing
                        events.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_46" class="annotation strong"
                          style="position: unset;">Results:</span> There were 19,215 patients included in the study
                        sample (71.4% female, 77.0% White), including 4,421 in the Gamma nail group, 2,350 in the
                        INTERTAN nail group, and 12,444 in the TFN/TFNA nail group. In the multivariable analysis
                        involving nails of all lengths, the INTERTAN group was found to have a higher risk of aseptic
                        revision compared with the TFN/TFNA group (8-year crude revision rate, 2.9% compared with 1.8%;
                        hazard ratio [HR], 1.62 [95% confidence interval (CI), 1.15 to 2.27]; p = 0.006). The increased
                        risk associated with the INTERTAN nail was primarily seen among the long nails (HR, 1.83 [95%
                        CI, 1.16 to 2.87]; p = 0.009) rather than the short nails (HR, 1.36 [95% CI, 0.87 to 2.11]; p =
                        0.18). There were no differences in aseptic revision observed between the Gamma group and the
                        TFN/TFNA group.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_47" class="annotation strong"
                          style="position: unset;">Conclusions:</span> In this study of 19,215 patients with a hip
                        fracture treated with cephalomedullary nailing, the INTERTAN nail was found to have a
                        significantly higher risk of aseptic revision. Further research is required to determine whether
                        these results could be related to the unique design of this implant.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_48" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Of the &gt;250,000 hip fractures that occur in the United
                  States each year<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>,
                  approximately 40% to 45% are in the intertrochanteric region<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>.
                  Although sliding hip screw and cephalomedullary nail devices both represent viable treatment options
                  for stable fractures<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>, the
                  majority of intertrochanteric femoral fractures in the United States are currently treated with
                  cephalomedullary devices<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">5</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Reoperation rates of 4% to 5% have been reported following
                  the cephalomedullary nail treatment of intertrochanteric femoral fractures<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">6</span></a>. Given the often frail nature of elderly patients who
                  sustain a hip fracture, complications such as these can greatly contribute to morbidity and mortality.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Although many reoperations following the cephalomedullary
                  nail treatment of intertrochanteric femoral fractures may be due to technical factors, it is possible
                  that implant characteristics could also play a role. For example, prior research has suggested that
                  certain cephalomedullary nail devices could be superior to others<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">19</span></a>.
                  However, other studies have not found any such differences<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">20</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">32</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, no prior study has sought to compare the
                  outcomes associated with the 3 most common cephalomedullary nail implants in use in the United States
                  today. The purpose of this study was to compare the aseptic revision rates associated with the Gamma
                  nail (Stryker), the INTERTAN nail (Smith+Nephew), and the Trochanteric Fixation Nail/Trochanteric
                  Fixation Nail Advanced (TFN/TFNA; DePuy Synthes) in the treatment of hip fractures in elderly
                  individuals.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study Design
                and Data Source</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">We conducted a retrospective cohort study using data from
                  our integrated health-care system’s hip fracture registry. This large health-care system covers &gt;12
                  million members in 8 geographical regions of the United States<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">33</span></a>. Details on data collection, participation, and other
                  details have been published previously<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">34</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">36</span></a>. Briefly, patients with surgically treated hip fractures
                  are identified and information related to their demographic characteristics, medical comorbidities,
                  intraoperative details, implant information, and outcomes is collected into the registry using the
                  health-care system’s integrated electronic health record, administrative databases, and other
                  institutional databases. All patients in the hip fracture registry are longitudinally monitored for
                  adverse outcomes using electronic screening algorithms until either health-care membership termination
                  or death. Identified outcomes are manually validated through electronic health record review by
                  trained research associates. This study was approved by the Kaiser Permanente institutional review
                  board prior to its commencement.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Inclusion
                and Exclusion Criteria</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The study cohort included patients ≥60 years of age with a
                  primary hip fracture treated with cephalomedullary nail fixation from 2009 to 2020. Only patients
                  treated with Gamma, INTERTAN, or TFN/TFNA nails were included in this study. Patients with multiple
                  fractures, those who had undergone bilateral procedures, those who had metastatic cancer, and those
                  who had prior hip surgery were excluded (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00390f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=52dcbf9b-090a-42e2-a10e-a20a55ef5cb0&type=jpeg&name=JBJS.24.00390f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;">Inclusion and exclusion criteria.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Exposure of
                Interest</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The cephalomedullary nail implant design was the exposure
                  of interest and was classified as Gamma, INTERTAN, or TFN/TFNA. A subanalysis stratified nails by
                  length as either short (170 to 180 mm) or long (&gt;180 mm). Nail length was missing for 8 cases,
                  which were excluded from this subanalysis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Outcome of
                Interest</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The outcome of interest in this study was aseptic
                  revision. Revision was defined as any operation following the index procedure in which a component was
                  replaced. All patients were monitored for revision following the index surgical procedure until death
                  or loss to follow-up through health-care membership termination.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">For each aseptic revision identified through the registry,
                  a chart review was conducted by 1 of 4 clinical study investigators (K.O., C.S., J.M.J., and A.S.F.).
                  Using the electronic health record and imaging studies, each revision was categorized as being related
                  to the primary fracture or unrelated to it (e.g., total hip arthroplasty performed for degenerative
                  arthritis). In addition, the reason for revision was classified as fixation failure, screw or blade
                  cut-through or cutout, nonunion, peri-implant fracture, lateral migration of the blade or screw
                  resulting in symptomatic prominence, implant breakage, or other. Patients could have more than 1
                  reason for revision (e.g., nonunion and blade or screw cutout).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Covariates</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Patient characteristics included age category (60 to 69,
                  70 to 79, 80 to 89 or ≥90 years), gender (male or female), race or ethnicity (Asian, Black, Hispanic,
                  White, or other, as self-reported by patients in the electronic health record), body mass index (BMI)
                  (categorized as &lt;22, 22 to 24.9, 25 to 29.9, ≥30 in kg/m<span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">2</span>), smoking status (never, former,
                  current), American Society of Anesthesiologists (ASA) classification (1 to 2, ≥3), anesthetic type
                  (general, regional, conversion from regional to general), and patient medical comorbidities identified
                  using the Elixhauser algorithm<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">37</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The characteristics of the study cohort were described
                  using frequencies and proportions or medians and interquartile ranges (IQRs). Revision was modeled as
                  the time to the event using survival analysis, in which the follow-up was defined as the difference
                  between the date of the index hip fracture surgery and the subsequent revision. Patients who did not
                  experience a revision were censored at the last surveillance date: the study end date (December 31,
                  2020), death, or health-care membership termination, whichever came first. There were 951 patients
                  (4.9%) lost to follow-up because of membership termination. The cumulative revision probability was
                  calculated as 1 minus the Kaplan-Meier estimator.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Competing-risk Cox proportional-hazards regression was
                  used to evaluate the risk of revision by nail type. Revisions unrelated to the index fracture and
                  mortality were modeled as competing events, and membership termination and the study end were
                  censoring events. The model included all covariates listed above, as well as a surgeon-level,
                  cluster-robust variance estimator to account for the correlation of procedures performed by the same
                  operating surgeon. Hazard ratios (HRs), 95% confidence intervals (CIs), and p values are reported,
                  with TFN/TFNA as the reference group. The proportional-hazards assumption for the exposure variable
                  was checked and met, implying that the hazards were constant over time. Uncategorized missing values
                  were not included in the multivariable model. The significance cutoff used for this study was p &lt;
                  0.05, and all tests were 2-sided. Analyses were performed using SAS Enterprise Guide (version 8.3; SAS
                  Institute).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The study sample included 19,215 primary hip fracture
                  procedures performed by 535 surgeons at 35 facilities. The median patient age was 83 years (IQR, 76 to
                  89 years), and most patients had an ASA classification of ≥3 (71.2%). Most patients were female
                  (71.4%) and White (77.0%). Patient characteristics by nail type are summarized in <a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a> and the Appendix Supplemental Table. The median follow-up was
                  2.2 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Demographic and Surgical Characteristics of Study
                          Patients by Cephalomedullary Nail Type</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 2458px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Characteristic</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Gamma (N =
                          4,421)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">INTERTAN
                          (N = 2,350)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">TFN/TFNA
                          (N = 12,444)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Patient
                          characteristics</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Age<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_44" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83 (76 to
                          89)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83 (75 to
                          88)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83 (75 to
                          89)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  60 to 69 years<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">488
                          (11.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">290
                          (12.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,527
                          (12.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  70 to 79 years<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,068
                          (24.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">592
                          (25.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,070
                          (24.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  80 to 89 years<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,936
                          (43.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,008
                          (42.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,193
                          (41.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  ≥90 years<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">929
                          (21.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">460
                          (19.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,654
                          (21.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male gender<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,184
                          (26.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">698
                          (29.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,613
                          (29.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> BMI<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span><span
                            data-id="emphasis_45" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_46" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_12" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_12"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_47"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.8 (20.7
                          to 27.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.1 (21.1
                          to 27.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.9 (20.9
                          to 27.5)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  &lt;22 kg/m<span
                            data-id="superscript_13" class="annotation superscript"
                            style="position: unset;">2</span><span data-id="table_footnote_reference_9"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,523
                          (34.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">746
                          (32.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,130
                          (33.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  22 to 24.9 kg/m<span
                            data-id="superscript_14" class="annotation superscript"
                            style="position: unset;">2</span><span data-id="table_footnote_reference_10"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,068
                          (24.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">569
                          (24.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,120
                          (25.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  25 to 29.9 kg/m<span
                            data-id="superscript_15" class="annotation superscript"
                            style="position: unset;">2</span><span data-id="table_footnote_reference_11"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,196
                          (27.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">675
                          (28.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,299
                          (26.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  ≥30 kg/m<span
                            data-id="superscript_16" class="annotation superscript"
                            style="position: unset;">2</span><span data-id="table_footnote_reference_12"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">603
                          (13.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">345
                          (14.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,796
                          (14.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ASA category<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_14"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  1 to 2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,042
                          (23.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">567
                          (24.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,897
                          (23.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  ≥3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,886
                          (65.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,653
                          (70.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9,137
                          (73.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Missing</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">493
                          (11.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">130
                          (5.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">410
                          (3.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Race or ethnicity<span
                            data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_16"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Asian</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">385
                          (8.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">121
                          (5.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">956
                          (7.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Black</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">126
                          (2.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54
                          (2.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">469
                          (3.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Hispanic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">656
                          (14.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">183
                          (7.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,277
                          (10.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  White</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,199
                          (72.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,970
                          (84.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9,610
                          (77.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46
                          (1.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (0.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">118
                          (1.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Smoking category<span
                            data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_18"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Never</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,388
                          (55.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,206
                          (51.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,366
                          (52.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Former</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,636
                          (37.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">914
                          (38.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,951
                          (40.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Current</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">296
                          (6.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">195
                          (8.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">874
                          (7.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Implant and surgical
                          characteristics</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Anesthetic type<span
                            data-id="table_footnote_reference_19" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_20"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  General</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,164
                          (71.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,492
                          (63.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,587
                          (52.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Regional</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,124
                          (25.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">746
                          (31.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,391
                          (43.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Conversion</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">130
                          (2.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">112
                          (4.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">463
                          (3.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nail length<span
                            data-id="table_footnote_reference_21" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_22"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Short</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,649
                          (37.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,072
                          (45.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,429
                          (43.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Long</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,769
                          (62.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,277
                          (54.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7,011
                          (56.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Length of stay<span
                            data-id="table_footnote_reference_23" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_48" class="annotation emphasis"
                            style="position: unset;">(days)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4 (3 to
                          6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4 (3 to
                          5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4 (3 to
                          5)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Operative time<span
                            data-id="table_footnote_reference_24" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="table_footnote_reference_25"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span><span
                            data-id="emphasis_49" class="annotation emphasis"
                            style="position: unset;">(min)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50 (36 to
                          71)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55 (41 to
                          74)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51 (38 to
                          72)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Annual surgeon
                          volume<span data-id="table_footnote_reference_26" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21 (12 to
                          43)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22 (15 to
                          49)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17 (13 to
                          24)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the median, with the IQR in parentheses.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, with the percentage in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The following categories had missing values: BMI (145 [0.8%]), ASA
                        classification (1,033 [5.4%]), race or ethnicity (32 [0.2%]), anesthetic type (6 [&lt;0.1%]),
                        smoking status (389 [2.0%]), nail length (8 [&lt;0.1%]), and operative time (107 [0.6%]).</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">With regard to nail type, there were 4,421 Gamma nails,
                  2,350 INTERTAN nails, and 12,444 TFN/TFNA nails. At the 8-year follow-up, the crude aseptic revision
                  rate was 1.6% in the Gamma group, 2.9% in the INTERTAN group, and 1.8% in the TFN/TFNA group (<a
                    href="" data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). In the multivariable analysis controlling for confounders
                  among all nails, the INTERTAN group was found to have a significantly higher risk of aseptic revision
                  compared with the TFN/TFNA group (HR, 1.62 [95% CI, 1.15 to 2.27]; p = 0.006). With regard to the
                  revision reason, we observed higher rates of fixation failure and screw or blade cut-through or cutout
                  (<a href="" data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 3-A</a> through <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">3-F</a>) in the
                  INTERTAN group compared with the TFN/TFNA group (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). No
                  differences in aseptic revision were observed between the Gamma and TFN/TFNA (HR, 0.90 [95% CI, 0.63
                  to 1.28]; p = 0.54) (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00390f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=52dcbf9b-090a-42e2-a10e-a20a55ef5cb0&type=jpeg&name=JBJS.24.00390f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Cumulative probability of aseptic revision
                          following cephalomedullary nailing by nail type in the entire cohort. The shaded areas around
                          the solid lines represent the 95% CIs. The bottom table presents the number of patients still
                          at risk, with the number of cumulative revision events in parentheses, at each year of
                          postoperative follow-up.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_51"
                            class="annotation strong" style="position: unset;">Figs. 3-A through 3-F</span> Case
                          example. An 88-year-old woman sustained a right intertrochanteric femoral fracture in a fall
                          from a chair (<span data-id="strong_52" class="annotation strong"
                            style="position: unset;">Fig. 3-A</span>) and underwent closed reduction with surgical
                          fixation using the INTERTAN device (<span data-id="strong_53" class="annotation strong"
                            style="position: unset;">Figs. 3-B and 3-C</span>). Radiographs were made at the 6-week
                          follow-up visit, which, in hindsight, demonstrated some early varus collapse (<span
                            data-id="strong_54" class="annotation strong" style="position: unset;">Fig. 3-D</span>). At
                          10 weeks postoperatively, the patient presented to the emergency department and was found to
                          have cutout of the integrated dual lag screw (<span data-id="strong_55"
                            class="annotation strong" style="position: unset;">Fig. 3-E</span>), which was treated with
                          implant removal and conversion to calcar-replacing hemiarthroplasty with a cemented implant
                          (<span data-id="strong_56" class="annotation strong" style="position: unset;">Fig.
                            3-F</span>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-A</div>
                    <div class="image-download" name="JBJS.24.00390f3a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=52dcbf9b-090a-42e2-a10e-a20a55ef5cb0&type=jpeg&name=JBJS.24.00390f3a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-B</div>
                    <div class="image-download" name="JBJS.24.00390f3b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=52dcbf9b-090a-42e2-a10e-a20a55ef5cb0&type=jpeg&name=JBJS.24.00390f3b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-C</div>
                    <div class="image-download" name="JBJS.24.00390f3c" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=52dcbf9b-090a-42e2-a10e-a20a55ef5cb0&type=jpeg&name=JBJS.24.00390f3c"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-D</div>
                    <div class="image-download" name="JBJS.24.00390f3d" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=52dcbf9b-090a-42e2-a10e-a20a55ef5cb0&type=jpeg&name=JBJS.24.00390f3d"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-E</div>
                    <div class="image-download" name="JBJS.24.00390f3e" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=52dcbf9b-090a-42e2-a10e-a20a55ef5cb0&type=jpeg&name=JBJS.24.00390f3e"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-F</div>
                    <div class="image-download" name="JBJS.24.00390f3f" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=52dcbf9b-090a-42e2-a10e-a20a55ef5cb0&type=jpeg&name=JBJS.24.00390f3f"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_12" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Crude Incidence and Adjusted Risk of Revision After
                          Surgical Repair of Hip Fracture in the Entire Cohort by Cephalomedullary Nail Type<span
                            data-id="table_footnote_reference_35" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 859.048px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.744344);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Revision
                          Reason</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Gamma<span data-id="table_footnote_reference_27"
                            class="annotation table_footnote_reference" style="position: unset;">†</span> (N =
                          4,421)</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">INTERTAN<span data-id="table_footnote_reference_28"
                            class="annotation table_footnote_reference" style="position: unset;">†</span> (N =
                          2,350)</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">TFN/TFNA<span data-id="table_footnote_reference_29"
                            class="annotation table_footnote_reference" style="position: unset;">†</span> (N =
                          12,444)</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Gamma vs. TFN/TFNA</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">INTERTAN vs. TFN/TFNA</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">HR<span
                            data-id="table_footnote_reference_30" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_31" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">HR<span
                            data-id="table_footnote_reference_32" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_33" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Any cause related to
                          index fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64
                          (1.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62
                          (2.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">208
                          (1.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.90 (0.63
                          to 1.28)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.54</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.62 (1.15
                          to 2.27)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_63" class="annotation strong" style="position: unset;">0.006</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Specific reasons for
                          revision<span data-id="table_footnote_reference_34"
                            class="annotation table_footnote_reference" style="position: unset;">#</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Fixation failure</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (0.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (2.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">111
                          (1.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.74 (0.47
                          to 1.14)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.17</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.11 (1.43
                          to 3.13)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_64" class="annotation strong" style="position: unset;">0.0002</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cut-through or
                          cutout</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (0.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34
                          (2.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">124
                          (1.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.58 (0.36
                          to 0.92)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_65" class="annotation strong" style="position: unset;">0.020</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.52 (1.02
                          to 2.25)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_66" class="annotation strong" style="position: unset;">0.038</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nonunion</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (0.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (1.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59
                          (0.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.13 (0.58
                          to 2.20)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.72</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.41 (0.74
                          to 2.69)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.30</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Peri-implant
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (0.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (0.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (0.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Implant breakage</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (0.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (0.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lateral migration of
                          screw or blade</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (0.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (0.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (0.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The model was adjusted for age, race or ethnicity, gender, BMI, ASA
                        classification, anesthetic type, smoking status, Elixhauser comorbidities, and operating
                        surgeon.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients with 8-year cumulative
                        incidence, calculated using 1 minus the Kaplan-Meier estimator, in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the HR, with the 95% CI in parentheses.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Bold indicates significant p values.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">Patients could have &gt;1 reason specified.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">With regard to nail length, the study sample included
                  8,150 short nails and 11,057 long nails. Among the short nails, the incidence of aseptic revision was
                  2.8% for the INTERTAN group compared with 2.0% for the TFN/TFNA group (HR, 1.36 [95% CI, 0.87 to
                  2.11]; p = 0.18). For the Gamma group, the aseptic revision rate was 1.8% (HR, 0.82 [95% CI, 0.50 to
                  1.35]; p = 0.44) compared with the TFN/TFNA group (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>, <a
                    href="" data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00390f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=52dcbf9b-090a-42e2-a10e-a20a55ef5cb0&type=jpeg&name=JBJS.24.00390f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_13" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;">Cumulative probability of aseptic revision of
                          short nails following cephalomedullary nailing by nail type. The shaded areas around the solid
                          lines represent the 95% CIs. The bottom table presents the number of patients still at risk,
                          with the number of cumulative revision events in parentheses, at each year of postoperative
                          follow-up.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_14" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Crude Incidence and Adjusted Risk of Revision After
                          Surgical Repair of Hip Fracture with a Short Nail by Cephalomedullary Nail Type<span
                            data-id="table_footnote_reference_42" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 639.002px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.7669);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Revision
                          Reason</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Gamma<span data-id="table_footnote_reference_36"
                            class="annotation table_footnote_reference" style="position: unset;">†</span> (N =
                          1,649)</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">INTERTAN<span data-id="table_footnote_reference_37"
                            class="annotation table_footnote_reference" style="position: unset;">†</span> (N =
                          1,072)</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">TFN/TFNA<span data-id="table_footnote_reference_38"
                            class="annotation table_footnote_reference" style="position: unset;">†</span> (N =
                          5,429)</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Gamma vs. TFN/TFNA</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">INTERTAN vs. TFN/TFNA</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">HR<span
                            data-id="table_footnote_reference_39" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">HR<span
                            data-id="table_footnote_reference_40" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Any cause related to
                          index fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (1.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (2.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">103
                          (2.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.82 (0.50
                          to 1.35)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.44</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.36 (0.87
                          to 2.11)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.18</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Specific reasons for
                          revision<span data-id="table_footnote_reference_41"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Fixation failure</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (1.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (2.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64
                          (1.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.67 (0.36
                          to 1.22)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.19</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.63 (0.99
                          to 2.67)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cut-through or
                          cutout</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (1.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (1.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73
                          (1.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.65 (0.37
                          to 1.15)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.14</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.09 (0.64
                          to 1.85)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.76</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nonunion</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (0.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (0.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (0.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.10 (0.51
                          to 2.39)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.80</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.36 (0.52
                          to 3.58)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.53</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The model was adjusted for age, race or ethnicity, gender, BMI, ASA
                        classification, anesthetic type, smoking status, Elixhauser comorbidities, and operating
                        surgeon.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients with the 8-year
                        cumulative incidence in parentheses; the 8-year cumulative incidence was calculated using 1
                        minus the Kaplan-Meier estimator.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the HR, with the 95% CI in parentheses.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_12" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Patients could have &gt;1 reason specified.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Among the long nails, aseptic revision incidence at 8
                  years was 1.5% for the Gamma group, 3.0% for the INTERTAN group, and 1.6% for the TFN/TFNA group (<a
                    href="" data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 5</a>). In the multivariable analysis, the INTERTAN group was found to
                  have a significantly higher risk of all-cause aseptic revision compared with the TFN/TFNA group (HR,
                  1.83 [95% CI, 1.16 to 2.87]; p = 0.009). With regard to specific reasons for revision, the INTERTAN
                  group was found to have a higher risk of fixation failure (HR, 2.73 [95% CI, 1.56 to 4.80]; p =
                  0.0005) as well as screw or blade cut-through or cutout (HR, 2.06 [95% CI, 1.16 to 3.63]; p = 0.013)
                  compared with the TFN/TFNA group. No significant differences were observed between the Gamma group and
                  the TFN/TFNA group among the long nails (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.24.00390f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=52dcbf9b-090a-42e2-a10e-a20a55ef5cb0&type=jpeg&name=JBJS.24.00390f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_15" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_40" style="position: unset;">
                        <div class="content" style="position: unset;">Cumulative probability of aseptic revision of long
                          nails following cephalomedullary nailing by nail type. The shaded areas around the solid lines
                          represent the 95% CIs. The bottom table presents the number of patients still at risk, with
                          the number of cumulative revision events in parentheses, at each year of postoperative
                          follow-up.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_16" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_41" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Crude Incidence and Adjusted Risk of Revision After
                          Surgical Repair of Hip Fracture with a Long Nail by Cephalomedullary Nail Type<span
                            data-id="table_footnote_reference_50" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 628.729px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.754587);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Revision
                          Reason</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Gamma<span data-id="table_footnote_reference_43"
                            class="annotation table_footnote_reference" style="position: unset;">†</span> (N =
                          2,769)</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">INTERTAN<span data-id="table_footnote_reference_44"
                            class="annotation table_footnote_reference" style="position: unset;">†</span> (N =
                          1,277)</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">TFN/TFNA<span data-id="table_footnote_reference_45"
                            class="annotation table_footnote_reference" style="position: unset;">†</span> (N =
                          7,011)</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Gamma vs. TFN/TFNA</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">INTERTAN vs. TFN/TFNA</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">HR<span
                            data-id="table_footnote_reference_46" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">HR<span
                            data-id="table_footnote_reference_47" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_48" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Any cause related to
                          index fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38
                          (1.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34
                          (3.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">105
                          (1.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.98 (0.63
                          to 1.53)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.94</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.83 (1.16
                          to 2.87)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_67" class="annotation strong" style="position: unset;">0.009</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Specific reasons for
                          revision<span data-id="table_footnote_reference_49"
                            class="annotation table_footnote_reference" style="position: unset;">#</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Fixation failure</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (0.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (2.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47
                          (0.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.86 (0.43
                          to 1.71)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.67</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.73 (1.56
                          to 4.80)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_68" class="annotation strong" style="position: unset;">0.0005</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cut-through or
                          cutout</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (2.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51
                          (0.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.53 (0.26
                          to 1.06)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.07</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.06 (1.16
                          to 3.63)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_69" class="annotation strong" style="position: unset;">0.013</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nonunion</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (0.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (1.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (0.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.18 (0.52
                          to 2.69)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.69</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.45 (0.65
                          to 3.23)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.69</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_13" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The model was adjusted for age, race or ethnicity, gender, BMI, ASA
                        classification, anesthetic type, smoking status, Elixhauser comorbidities, and operating
                        surgeon.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_14" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients with the 8-year
                        cumulative incidence in parentheses; the 8-year cumulative incidence was calculated using 1
                        minus the Kaplan-Meier estimator.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_15" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the HR, with the 95% CI in parentheses.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_16" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Bold indicates significant p values.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_17" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">Patients could have &gt;1 reason specified.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">In this study of &gt;19,000 patients treated with 1 of 3
                  commonly used cephalomedullary nail types, we observed a higher rate of aseptic revision among
                  patients treated with the INTERTAN device. When stratifying by nail length, we found that the higher
                  revision rate associated with INTERTAN was primarily observed among the long cephalomedullary nails.
                  The Gamma and TFN/TFNA devices were generally found to perform similarly to each other in our study.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Perhaps because of its unique dual-lag screw design, the
                  INTERTAN has historically fared well in biomechanical studies<a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_17" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_17" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">13</span></a>. In the clinical realm, early research generally found the
                  INTERTAN to perform similarly to<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">30</span></a>, or better than<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_19" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">28</span></a>, the comparison implant. For example, Gavaskar et al.
                  conducted a matched-pair analysis of 100 patients in India who received either the INTERTAN or
                  Proximal Femoral Nail Antirotation-II (PFNA-II; DePuy Synthes) and documented better patient-reported
                  outcomes and fewer reoperations in the INTERTAN group<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">17</span></a>.
                  Zhang et al. performed a retrospective analysis of 283 patients in the Republic of China who received
                  either the INTERTAN or PFNA and reported a lower complication rate in the INTERTAN group, including
                  less frequent screw cutout, femoral shaft fracture, and varus head collapse<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">15</span></a>. Summarizing the available data, Ma et al. conducted a
                  meta-analysis of 9 studies comparing the INTERTAN, Gamma, and PFNA and concluded that the INTERTAN had
                  a lower rate of cutout and femoral fracture compared with the other 2 devices<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">16</span></a>. However, these prior studies tended to have small sample
                  sizes and also compared the INTERTAN with devices not routinely utilized in the United States (e.g.,
                  the PFNA).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">However, recent clinical research has raised concerns
                  about the performance of the INTERTAN device. Specifically, Grønhaug et al. used the Norwegian Hip
                  Fracture Register to evaluate 13,232 hip fractures treated with a variety of intramedullary devices<a
                    href="" data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">19</span></a>. In their analysis of short nails, which included 3
                  cephalomedullary devices (Gamma 3, PFNA, and INTERTAN), these authors found the INTERTAN to have a
                  significantly higher rate of reoperation at 3 years (HR, 1.31 [95% CI, 1.07 to 1.62]; p = 0.011). In
                  their analysis of long nails, which included the same 3 cephalomedullary devices (as well as 2 types
                  of reconstruction nails), there was a trend toward a higher rate of reoperation for the INTERTAN nail,
                  although it did not reach significance (HR, 1.23 [95% CI, 0.96 to 1.58]; p = 0.099). In our study, we
                  observed a higher risk of reoperation for the INTERTAN among patients treated with long nails.
                  Although we did not observe a significant difference in the performance of the INTERTAN among patients
                  treated with short nails in our study, it is interesting to note that the HR that we observed for the
                  short INTERTAN nails in our study (HR, 1.36) was similar to that observed among short nails in the
                  Norwegian analysis (HR, 1.31).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">More recently, Gonzalez et al. reported on a retrospective
                  study of 2,130 patients at 13 Level-I trauma centers in the United States who were treated with either
                  a single-lag screw cephalomedullary nail or an integrated dual-lag screw cephalomedullary nail (i.e.,
                  INTERTAN)<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">38</span></a>. In
                  the multivariable regression analysis, these authors found that patients treated with an integrated
                  dual-lag screw cephalomedullary nail had a significantly higher risk of reoperation (odds ratio, 4.95
                  [95% CI, 2.29 to 10.69]; p &lt; 0.001). Further research is required to determine whether the higher
                  revision rate associated with INTERTAN could be due to the unique design features of the device (i.e.,
                  integrated dual lag screw), difficulty in proper implant placement on the part of surgeons, or some
                  other factor.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">In comparing the Gamma nail with the TFN/TFNA nail,
                  performance was found to be similar for nearly all of the outcomes examined. Specifically, aseptic
                  revision rates were similar in the sample as a whole, as well as in the short and long nail groups
                  separately. The only difference observed between the 2 devices was revision specifically for
                  cut-through or cutout in the study sample as a whole, which was observed to occur less frequently in
                  the Gamma group compared with the TFN/TFNA group (HR, 0.58 [95% CI, 0.36 to 0.92]; p = 0.020). Given
                  that proximal fixation in the Gamma group utilized a lag screw, whereas proximal fixation in the
                  TFN/TFNA group utilized a combination of lag screws and helical blades, this finding is interesting.
                  Although some prior research has found lag screws to be associated with a lower risk of cutout<a
                    href="" data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">39</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">41</span></a>, other studies have not detected any differences between
                  these 2 modes of proximal fixation<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">42</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">43</span></a>. Further research is clearly required on this topic.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">Our findings should be interpreted in light of our study
                  design. Our study benefits from the large number of patients (&gt;19,000), surgeons (535), and centers
                  (35), which could heighten generalizability. Nevertheless, our investigation was not a randomized
                  trial but a retrospective analysis, which raises the possibility of confounding. Specifically,
                  although our study controlled for a wide range of covariates, there does remain the possibility of
                  residual confounding by unmeasured variables, such as fracture severity. Although we could not account
                  for bone quality or osteoporosis, we did find patient characteristics to be similar across the 3
                  groups (see the Appendix Supplemental Table). Similarly, although we were unable to control
                  specifically for the quality of fracture reduction, the quality of implant placement, or surgeon
                  skill, we did find the annual surgeon hip fracture volume to be 22 for the INTERTAN group compared
                  with 17 for the TFN/TFNA group (<a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).
                  Finally, given the retrospective nature of our study, the findings should be taken to represent
                  association and not necessarily causation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">In summary, our study documents an increased risk of
                  revision for the INTERTAN in the cephalomedullary nailing of geriatric hip fractures, especially for
                  long nails. Further research is required to determine the specific reasons for this finding.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_3" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I291" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I291</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors gratefully acknowledge the Kaiser Permanente
                  surgeons who contribute to the Kaiser Permanente Hip Fracture Registry, as well as the Surgical
                  Outcomes and Analysis Department staff who coordinate registry operations.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Swenning T</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Leighton J</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Nentwig M</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Dart B</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Hip fracture care and national
                  systems: the United States and Canada</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">OTA Int.</span> 2020 Mar 23;3(1):e073.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20fracture%20care%20and%20national%20systems%3A%20the%20United%20States%20and%20Canada&as_occt=title&as_sauthors=%20%22T%20Swenning%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Adeyemi A</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Delhougne G</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Incidence and economic burden of
                  intertrochanteric fracture: a Medicare Claims Database analysis</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">JB JS Open Access.</span> 2019 Feb
                27;4(1):e0045.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%20and%20economic%20burden%20of%20intertrochanteric%20fracture%3A%20a%20Medicare%20Claims%20Database%20analysis&as_occt=title&as_sauthors=%20%22A%20Adeyemi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Kaplan K</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Miyamoto R</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Levine BR</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Egol KA</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Zuckerman JD</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Surgical management of hip
                  fractures: an evidence-based review of the literature. II: Intertrochanteric fractures</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">J Am Acad Orthop
                  Surg.</span> 2008 Nov;16(11):665-73.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20management%20of%20hip%20fractures%3A%20an%20evidence-based%20review%20of%20the%20literature.%20II%3A%20Intertrochanteric%20fractures&as_occt=title&as_sauthors=%20%22K%20Kaplan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Anglen JO</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Weinstein JN</span>; American
                Board of Orthopaedic Surgery Research Committee. <span data-id="strong_4" class="annotation strong"
                  style="position: unset;">Nail or plate fixation of intertrochanteric hip fractures: changing pattern
                  of practice. A review of the American Board of Orthopaedic Surgery Database</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2008 Apr;90(4):700-7.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=971780"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Niu E</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Yang A</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Harris AH</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Bishop J</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Which fixation device is
                  preferred for surgical treatment of intertrochanteric hip fractures in the United States? A survey of
                  orthopaedic surgeons</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2015 Nov;473(11):3647-55.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Which%20fixation%20device%20is%20preferred%20for%20surgical%20treatment%20of%20intertrochanteric%20hip%20fractures%20in%20the%20United%20States%3F%20A%20survey%20of%20orthopaedic%20surgeons&as_occt=title&as_sauthors=%20%22E%20Niu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Dunn J</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Kusnezov N</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Bader J</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Waterman BR</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Orr J</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Belmont PJ</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Long versus short
                  cephalomedullary nail for trochanteric femur fractures (OTA 31-A1, A2 and A3): a systematic
                  review</span>. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J
                  Orthop Traumatol.</span> 2016 Dec;17(4):361-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long%20versus%20short%20cephalomedullary%20nail%20for%20trochanteric%20femur%20fractures%20(OTA%2031-A1%2C%20A2%20and%20A3)%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22J%20Dunn%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Huang Y</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Zhang C</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Luo Y</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">A comparative biomechanical
                  study of proximal femoral nail (InterTAN) and proximal femoral nail antirotation for intertrochanteric
                  fractures</span>. <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Int
                  Orthop.</span> 2013 Dec;37(12):2465-73.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20comparative%20biomechanical%20study%20of%20proximal%20femoral%20nail%20(InterTAN)%20and%20proximal%20femoral%20nail%20antirotation%20for%20intertrochanteric%20fractures&as_occt=title&as_sauthors=%20%22Y%20Huang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Nüchtern JV</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Ruecker AH</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Sellenschloh K</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Rupprecht M</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Püschel K</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Rueger JM</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Morlock MM</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Lehmann W</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Malpositioning of the lag screws
                  by 1- or 2-screw nailing systems for pertrochanteric femoral fractures: a biomechanical comparison of
                  Gamma 3 and Intertan</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2014 May;28(5):276-82.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Malpositioning%20of%20the%20lag%20screws%20by%201-%20or%202-screw%20nailing%20systems%20for%20pertrochanteric%20femoral%20fractures%3A%20a%20biomechanical%20comparison%20of%20Gamma%203%20and%20Intertan&as_occt=title&as_sauthors=%20%22JV%20N%C3%BCchtern%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Wu D</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Ren G</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Peng C</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Zheng X</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Mao F</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Zhang Y</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">InterTan nail versus Gamma3 nail
                  for intramedullary nailing of unstable trochanteric fractures</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Diagn Pathol.</span> 2014 Oct
                1;9:191.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=InterTan%20nail%20versus%20Gamma3%20nail%20for%20intramedullary%20nailing%20of%20unstable%20trochanteric%20fractures&as_occt=title&as_sauthors=%20%22D%20Wu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Chinzei N</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Hiranaka T</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Niikura T</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Tsuji M</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Kuroda R</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Doita M</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Kurosaka M</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Comparison of the sliding and
                  femoral head rotation among three different femoral head fixation devices for trochanteric
                  fractures</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Surg.</span> 2015 Sep;7(3):291-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20the%20sliding%20and%20femoral%20head%20rotation%20among%20three%20different%20femoral%20head%20fixation%20devices%20for%20trochanteric%20fractures&as_occt=title&as_sauthors=%20%22N%20Chinzei%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Seyhan M</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Turkmen I</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Unay K</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Ozkut AT</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Do PFNA devices and Intertan
                  nails both have the same effects in the treatment of trochanteric fractures? A prospective clinical
                  study</span>. <span data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J
                  Orthop Sci.</span> 2015 Nov;20(6):1053-61.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Do%20PFNA%20devices%20and%20Intertan%20nails%20both%20have%20the%20same%20effects%20in%20the%20treatment%20of%20trochanteric%20fractures%3F%20A%20prospective%20clinical%20study&as_occt=title&as_sauthors=%20%22M%20Seyhan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Yu W</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Zhang X</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Zhu X</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Hu J</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Liu Y</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">A retrospective analysis of the
                  InterTan nail and Proximal Femoral Nail Anti-Rotation-Asia in the treatment of unstable
                  intertrochanteric femur fractures in the elderly</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">J Orthop Surg Res.</span> 2016 Jan
                15;11:10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20retrospective%20analysis%20of%20the%20InterTan%20nail%20and%20Proximal%20Femoral%20Nail%20Anti-Rotation-Asia%20in%20the%20treatment%20of%20unstable%20intertrochanteric%20femur%20fractures%20in%20the%20elderly&as_occt=title&as_sauthors=%20%22W%20Yu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Santoni BG</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Nayak AN</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Cooper SA</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Smithson IR</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Cox JL</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Marberry ST</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Sanders RW</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Comparison of femoral head
                  rotation and varus collapse between a single lag screw and integrated dual screw intertrochanteric hip
                  fracture fixation device using a cadaveric hemi-pelvis biomechanical model</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span>
                2016 Apr;30(4):164-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20femoral%20head%20rotation%20and%20varus%20collapse%20between%20a%20single%20lag%20screw%20and%20integrated%20dual%20screw%20intertrochanteric%20hip%20fracture%20fixation%20device%20using%20a%20cadaveric%20hemi-pelvis%20biomechanical%20model&as_occt=title&as_sauthors=%20%22BG%20Santoni%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Serrano R</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Blair JA</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Watson DT</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Infante AF Jr</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Shah AR</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Mir HR</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Maxson BJ</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Downes KL</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Sanders RW</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Cephalomedullary nail fixation
                  of intertrochanteric femur fractures: are two proximal screws better than one?</span><span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span>
                2017 Nov;31(11):577-82.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cephalomedullary%20nail%20fixation%20of%20intertrochanteric%20femur%20fractures%3A%20are%20two%20proximal%20screws%20better%20than%20one%3F&as_occt=title&as_sauthors=%20%22R%20Serrano%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Zhang H</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Zhu X</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Pei G</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Zeng X</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Zhang N</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Xu P</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Chen D</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Yu W</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Zhang X</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">A retrospective analysis of the
                  InterTan nail and Proximal Femoral Nail Anti-Rotation in the treatment of intertrochanteric fractures
                  in elderly patients with osteoporosis: a minimum follow-up of 3 years</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">J Orthop Surg Res.</span>
                2017 Oct 10;12(1):147.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20retrospective%20analysis%20of%20the%20InterTan%20nail%20and%20Proximal%20Femoral%20Nail%20Anti-Rotation%20in%20the%20treatment%20of%20intertrochanteric%20fractures%20in%20elderly%20patients%20with%20osteoporosis%3A%20a%20minimum%20follow-up%20of%203%20years&as_occt=title&as_sauthors=%20%22H%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Ma JX</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Kuang MJ</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Fan ZR</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Xing F</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Zhao YL</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Zhang LK</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Chen HT</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Han C</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Ma XL</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Comparison of clinical outcomes
                  with InterTan vs Gamma nail or PFNA in the treatment of intertrochanteric fractures: a
                  meta-analysis</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Sci Rep.</span> 2017 Nov 21;7(1):15962.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20clinical%20outcomes%20with%20InterTan%20vs%20Gamma%20nail%20or%20PFNA%20in%20the%20treatment%20of%20intertrochanteric%20fractures%3A%20a%20meta-analysis&as_occt=title&as_sauthors=%20%22JX%20Ma%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Gavaskar AS</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Tummala NC</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Srinivasan P</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Gopalan H</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Karthik B</span>, S S. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Helical blade or the integrated
                  lag screws: a matched pair analysis of 100 patients with unstable trochanteric fractures</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span>
                2018 Jun;32(6):274-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Helical%20blade%20or%20the%20integrated%20lag%20screws%3A%20a%20matched%20pair%20analysis%20of%20100%20patients%20with%20unstable%20trochanteric%20fractures&as_occt=title&as_sauthors=%20%22AS%20Gavaskar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Nherera L</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Trueman P</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Horner A</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Watson T</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Johnstone AJ</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Comparison of a twin
                  interlocking derotation and compression screw cephalomedullary nail (InterTAN) with a single screw
                  derotation cephalomedullary nail (Proximal Femoral Nail Antirotation): a systematic review and
                  meta-analysis for intertrochanteric fractures</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">J Orthop Surg Res.</span> 2018 Mar
                2;13(1):46.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20a%20twin%20interlocking%20derotation%20and%20compression%20screw%20cephalomedullary%20nail%20(InterTAN)%20with%20a%20single%20screw%20derotation%20cephalomedullary%20nail%20(Proximal%20Femoral%20Nail%20Antirotation)%3A%20a%20systematic%20review%20and%20meta-analysis%20for%20intertrochanteric%20fractures&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Grønhaug KML</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Dybvik E</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Matre K</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Östman B</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Gjertsen JE</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Comparison of intramedullary
                  nails in the treatment of trochanteric and subtrochanteric fractures: an observational study of 13,232
                  fractures in the Norwegian Hip Fracture Register</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2023 Aug
                16;105(16):1227-36.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=d418374e-ec6b-46d3-aad5-422bdde06d90" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Herrera A</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Domingo LJ</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Calvo A</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Martínez A</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Cuenca J</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">A comparative study of
                  trochanteric fractures treated with the Gamma nail or the Proximal Femoral Nail</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Int Orthop.</span>
                2002;26(6):365-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20comparative%20study%20of%20trochanteric%20fractures%20treated%20with%20the%20Gamma%20nail%20or%20the%20Proximal%20Femoral%20Nail&as_occt=title&as_sauthors=%20%22A%20Herrera%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Schipper IB</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Steyerberg EW</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Castelein RM</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> van der Heijden FH</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> den Hoed PT</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Kerver AJ</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> van Vugt AB</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Treatment of unstable
                  trochanteric fractures. Randomised comparison of the Gamma nail and the Proximal Femoral Nail</span>.
                <span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Br.</span> 2004 Jan;86(1):86-94.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treatment%20of%20unstable%20trochanteric%20fractures.%20Randomised%20comparison%20of%20the%20Gamma%20nail%20and%20the%20Proximal%20Femoral%20Nail&as_occt=title&as_sauthors=%20%22IB%20Schipper%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Wagner R</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Blattert TR</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Weckbach A</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">[Solution to the problem of
                  extra-articular, femoral hip fracture by the “sliding screw-nail principle”. Results of 2 different
                  systems (classical nail and Gamma nail)]</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">Unfallchirurg.</span> 1998 Dec;101(12):894-900.
                German.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BSolution%20to%20the%20problem%20of%20extra-articular%2C%20femoral%20hip%20fracture%20by%20the%20%E2%80%9Csliding%20screw-nail%20principle%E2%80%9D.%20Results%20of%202%20different%20systems%20(classical%20nail%20and%20Gamma%20nail)%5D&as_occt=title&as_sauthors=%20%22R%20Wagner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Xu Y</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Geng D</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Yang H</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Wang X</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Zhu G</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Treatment of unstable proximal
                  femoral fractures: comparison of the Proximal Femoral Nail Antirotation and Gamma Nail 3</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Orthopedics.</span> 2010
                Jul 13;33(7):473.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treatment%20of%20unstable%20proximal%20femoral%20fractures%3A%20comparison%20of%20the%20Proximal%20Femoral%20Nail%20Antirotation%20and%20Gamma%20Nail%203&as_occt=title&as_sauthors=%20%22Y%20Xu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Yaozeng X</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Dechun G</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Huilin Y</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Guangming Z</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Xianbin W</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Comparative study of
                  trochanteric fracture treated with the Proximal Femoral Nail Anti-Rotation and the third generation of
                  Gamma nail</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Injury.</span> 2010 Dec;41(12):1234-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparative%20study%20of%20trochanteric%20fracture%20treated%20with%20the%20Proximal%20Femoral%20Nail%20Anti-Rotation%20and%20the%20third%20generation%20of%20Gamma%20nail&as_occt=title&as_sauthors=%20%22X%20Yaozeng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Zhang S</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Zhang K</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Jia Y</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Yu B</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Feng W</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">InterTan nail versus Proximal
                  Femoral Nail Antirotation-Asia in the treatment of unstable trochanteric fractures</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Orthopedics.</span> 2013
                Mar;36(3):e288-94.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=InterTan%20nail%20versus%20Proximal%20Femoral%20Nail%20Antirotation-Asia%20in%20the%20treatment%20of%20unstable%20trochanteric%20fractures&as_occt=title&as_sauthors=%20%22S%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Queally JM</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Harris E</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Handoll HH</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Parker MJ</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Intramedullary nails for
                  extracapsular hip fractures in adults</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Cochrane Database Syst Rev.</span> 2014 Sep 12;2014(9):CD004961.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intramedullary%20nails%20for%20extracapsular%20hip%20fractures%20in%20adults&as_occt=title&as_sauthors=%20%22JM%20Queally%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Zehir S</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Şahin E</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Zehir R</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Comparison of clinical outcomes
                  with three different intramedullary nailing devices in the treatment of unstable trochanteric
                  fractures</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">Ulus Travma Acil Cerrahi Derg.</span> 2015 Dec;21(6):469-76.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20clinical%20outcomes%20with%20three%20different%20intramedullary%20nailing%20devices%20in%20the%20treatment%20of%20unstable%20trochanteric%20fractures&as_occt=title&as_sauthors=%20%22S%20Zehir%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Berger-Groch J</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Rupprecht M</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Schoepper S</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Schroeder M</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Rueger JM</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Hoffmann M</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Five-year outcome analysis of
                  intertrochanteric femur fractures: a prospective randomized trial comparing a 2-screw and a
                  single-screw cephalomedullary nail</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2016 Sep;30(9):483-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Five-year%20outcome%20analysis%20of%20intertrochanteric%20femur%20fractures%3A%20a%20prospective%20randomized%20trial%20comparing%20a%202-screw%20and%20a%20single-screw%20cephalomedullary%20nail&as_occt=title&as_sauthors=%20%22J%20Berger-Groch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Hopp S</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Wirbel R</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Ojodu I</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Pizanis A</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Pohlemann T</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Fleischer J</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Does the implant make the
                  difference? Prospective comparison of two different proximal femur nails</span>. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Acta Orthop Belg.</span>
                2016 Aug;82(2):319-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20the%20implant%20make%20the%20difference%3F%20Prospective%20comparison%20of%20two%20different%20proximal%20femur%20nails&as_occt=title&as_sauthors=%20%22S%20Hopp%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Zhang H</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Zeng X</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Zhang N</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Zeng D</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Xu P</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Zhang L</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Chen D</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Yu W</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Zhang X</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">INTERTAN nail versus Proximal
                  Femoral Nail Antirotation-Asia for intertrochanteric femur fractures in elderly patients with primary
                  osteoporosis</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">J Int Med Res.</span> 2017 Aug;45(4):1297-309.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=INTERTAN%20nail%20versus%20Proximal%20Femoral%20Nail%20Antirotation-Asia%20for%20intertrochanteric%20femur%20fractures%20in%20elderly%20patients%20with%20primary%20osteoporosis&as_occt=title&as_sauthors=%20%22H%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Persiani P</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Ranaldi FM</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Gurzì M</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Formica A</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Graci J</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> De Cristo C</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Grasso R</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Villani C</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Choice of three different
                  intramedullary nails in the treatment of trochanteric fractures: outcome, analysis and consideration
                  in midterm</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Injury.</span> 2019 Aug;50(Suppl 4):S6-10.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Choice%20of%20three%20different%20intramedullary%20nails%20in%20the%20treatment%20of%20trochanteric%20fractures%3A%20outcome%2C%20analysis%20and%20consideration%20in%20midterm&as_occt=title&as_sauthors=%20%22P%20Persiani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Bonnaire F</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Lein T</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Fülling T</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Bula P</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Reduced complication rates for
                  unstable trochanteric fractures managed with third-generation nails: Gamma 3 nail versus PFNA</span>.
                <span data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Eur J Trauma Emerg
                  Surg.</span> 2020 Oct;46(5):955-62.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reduced%20complication%20rates%20for%20unstable%20trochanteric%20fractures%20managed%20with%20third-generation%20nails%3A%20Gamma%203%20nail%20versus%20PFNA&as_occt=title&as_sauthors=%20%22F%20Bonnaire%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Kaiser Permanente</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Fast Facts: Kaiser
                  Permanente</span>. Accessed 2024 Feb 28. <a
                  href="https://about.kaiserpermanente.org/who-we-are/fast-facts" target="_blank" data-id="link_1"
                  class="link"
                  style="position: unset;">https://about.kaiserpermanente.org/who-we-are/fast-facts</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fast%20Facts%3A%20Kaiser%20Permanente&as_occt=title&as_sauthors=%20%22Permanente%20Kaiser%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Inacio MC</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Weiss JM</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Miric A</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Hunt JJ</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Zohman GL</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Paxton EW</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">A community-based hip fracture
                  registry: population, methods, and outcomes</span>. <span data-id="emphasis_33"
                  class="annotation emphasis" style="position: unset;">Perm J. 2015
                  Summer</span>;19(3):29-36.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20community-based%20hip%20fracture%20registry%3A%20population%2C%20methods%2C%20and%20outcomes&as_occt=title&as_sauthors=%20%22MC%20Inacio%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Okike K</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Chan PH</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Paxton EW</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Effect of surgeon and hospital
                  volume on morbidity and mortality after hip fracture</span>. <span data-id="emphasis_34"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2017 Sep
                20;99(18):1547-53.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1420273"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Goodnough LH</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Chang RN</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Fasig BH</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Prentice HA</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Paxton EW</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Diekmann GR</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Jackman JM</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Okike K</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Fang AS</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Risk of revision after hip
                  fracture fixation using DePuy Synthes Trochanteric Fixation Nail or Trochanteric Fixation Nail
                  Advanced: a cohort study of 7,979 patients</span>. <span data-id="emphasis_35"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2022 Jun
                15;104(12):1090-7.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=3250301"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Elixhauser A</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Steiner C</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Harris DR</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Coffey RM</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Comorbidity measures for use
                  with administrative data</span>. <span data-id="emphasis_36" class="annotation emphasis"
                  style="position: unset;">Med Care.</span> 1998 Jan;36(1):8-27.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comorbidity%20measures%20for%20use%20with%20administrative%20data&as_occt=title&as_sauthors=%20%22A%20Elixhauser%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Gonzalez CA</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Van Rysselberghe NL</span>,
                <span data-id="annotation_201" class="annotation" style="position: unset;"> Whittaker MJ</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Ngo D</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Michaud JB</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Gardner MJ</span>; the Hip
                Fracture Nail Study Group. <span data-id="strong_38" class="annotation strong"
                  style="position: unset;">Integrated dual lag screws have higher reoperation rates for fixation failure
                  than single lag component cephalomedullary nails: a retrospective study of 2,130 patients with
                  intertrochanteric femoral fractures</span>. <span data-id="emphasis_37" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2024 Sep 18;106(18):1673-9.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=e72fae04-34d8-4558-8dff-3f8128336cbb"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Stern LC</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Gorczyca JT</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Kates S</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Ketz J</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Soles G</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Humphrey CA</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Radiographic review of helical
                  blade versus lag screw fixation for cephalomedullary nailing of low-energy peritrochanteric femur
                  fractures: there is a difference in cutout</span>. <span data-id="emphasis_38"
                  class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2017
                Jun;31(6):305-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiographic%20review%20of%20helical%20blade%20versus%20lag%20screw%20fixation%20for%20cephalomedullary%20nailing%20of%20low-energy%20peritrochanteric%20femur%20fractures%3A%20there%20is%20a%20difference%20in%20cutout&as_occt=title&as_sauthors=%20%22LC%20Stern%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Kim CH</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Kim HS</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Kim YC</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Moon DH</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Does the helical blade lead to
                  higher rates of fixation failure as compared to lag screw in the cephalomedullary nailing treatment of
                  hip fractures? A systematic review and meta-analysis</span>. <span data-id="emphasis_39"
                  class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2021 Aug
                1;35(8):401-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20the%20helical%20blade%20lead%20to%20higher%20rates%20of%20fixation%20failure%20as%20compared%20to%20lag%20screw%20in%20the%20cephalomedullary%20nailing%20treatment%20of%20hip%20fractures%3F%20A%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22CH%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Chapman T</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Zmistowski B</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Krieg J</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Stake S</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Jones CM</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Levicoff E</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Helical blade versus screw
                  fixation in the treatment of hip fractures with cephalomedullary devices: incidence of failure and
                  atypical “medial cutout”</span>. <span data-id="emphasis_40" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2018 Aug;32(8):397-402.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Helical%20blade%20versus%20screw%20fixation%20in%20the%20treatment%20of%20hip%20fractures%20with%20cephalomedullary%20devices%3A%20incidence%20of%20failure%20and%20atypical%20%E2%80%9Cmedial%20cutout%E2%80%9D&as_occt=title&as_sauthors=%20%22T%20Chapman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Hancıoğlu S</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Gem K</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Tosyali HK</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Okçu G</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Clinical and radiological
                  outcomes of trochanteric AO/OTA 31A2 fractures: comparison between helical blade and lag screw - a
                  retrospective cohort study</span>. <span data-id="emphasis_41" class="annotation emphasis"
                  style="position: unset;">Z Orthop Unfall.</span> 2022 Jun;160(3):278-86.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20and%20radiological%20outcomes%20of%20trochanteric%20AO%2FOTA%2031A2%20fractures%3A%20comparison%20between%20helical%20blade%20and%20lag%20screw%20-%20a%20retrospective%20cohort%20study&as_occt=title&as_sauthors=%20%22S%20Hanc%C4%B1o%C4%9Flu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Ng M</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Shah NS</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Golub I</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Ciminero M</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Zhai K</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Kang KK</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Emara AK</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Piuzzi NS</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">No difference between lag screw
                  and helical blade for cephalomedullary nail cut-out a systematic review and meta-analysis</span>.
                <span data-id="emphasis_42" class="annotation emphasis" style="position: unset;">Eur J Orthop Surg
                  Traumatol.</span> 2022 Dec;32(8):1617-25.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=No%20difference%20between%20lag%20screw%20and%20helical%20blade%20for%20cephalomedullary%20nail%20cut-out%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22M%20Ng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;52dcbf9b-090a-42e2-a10e-a20a55ef5cb0&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=52dcbf9b-090a-42e2-a10e-a20a55ef5cb0&type=supplement&name=12536"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D52dcbf9b-090a-42e2-a10e-a20a55ef5cb0%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 15, 2025;
                      107 (2): 174</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00390</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 21, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_43"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the Surgical
                            Outcomes and Analysis Department, Kaiser Permanente, San Diego, California, and the Hawaii
                            Permanente Medical Group, Honolulu, Hawaii</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;52dcbf9b-090a-42e2-a10e-a20a55ef5cb0&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=52dcbf9b-090a-42e2-a10e-a20a55ef5cb0&type=pdf&name=JBJS.24.00390.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=52dcbf9b-090a-42e2-a10e-a20a55ef5cb0&type=pdf&name=JBJS.24.00390.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_49"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_50" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I290"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I290</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;52dcbf9b-090a-42e2-a10e-a20a55ef5cb0&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=52dcbf9b-090a-42e2-a10e-a20a55ef5cb0&type=zip&name=JBJS.24.00390.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kanu Okike, MD, MPH<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:okike@post.harvard.edu" class=""
                    style="position: unset;">okike@post.harvard.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9310-9969" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9310-9969</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Richard N. Chang, MPH<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2877-8562" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2877-8562</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kathryn E. Royse, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6828-1449" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6828-1449</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Brian H. Fasig, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0000-0991-4317" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0000-0991-4317</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Cameron Sadeghi, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0006-6429-242X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0006-6429-242X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">James M. Jackman, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6344-2071" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6344-2071</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ronald A. Navarro, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Andrew S. Fang, MD<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Elizabeth W. Paxton, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1941-8186" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1941-8186</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Hawaii Permanente Medical Group, Kaiser Permanente, Honolulu, Hawaii</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Surgical Outcomes and Analysis Department, Kaiser Permanente, San Diego,
                California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Southern California Permanente Medical Group, Pasadena, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Northwest Permanente Medical Group, Portland, Oregon</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">The Permanente Medical Group, Oakland, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 32164.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
