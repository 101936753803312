import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";
import ScrollContainer from "../ScrollContainer";
import FeatureTable from "./FeatureTable";
import Footer from "./Footer";
/* ----------------- Local Imports --------------- */
import Header from "./Header";
import ProductList from "./ProductList";
import SubscriptionList from "./SubscriptionList";
import type { PaywallUIProps } from "./types";

/* ----------------- Third Party Imports --------------- */
import { Button, Text } from "react-native-paper";

const PaywallUI = ({
	title,
	subtitle,
	products,
	featureTable,
	subscriptions,
	recommendation,
	disclaimer,
	purchaseButtonText,
	alreadyPurchasedButtonText,
	handlePurchase,
	handleAlreadyPurchased,
}: PaywallUIProps) => {
	const theme = useAppTheme();

	return (
		<Box flex={1}>
			<ScrollContainer>
				<Box alignItems="center" gap={16} paddingHorizontal={16}>
					<Header title={title} subtitle={subtitle} />

					<ProductList {...products} />
					<FeatureTable {...featureTable} />
					<SubscriptionList {...subscriptions} />

					<Footer recommendation={recommendation} disclaimer={disclaimer}>
						<Button onPress={handleAlreadyPurchased}>
							<Text
								variant="labelLarge"
								style={{ color: theme.colors.secondary }}
							>
								{alreadyPurchasedButtonText}
							</Text>
						</Button>
					</Footer>
				</Box>
			</ScrollContainer>

			<Box
				alignItems="center"
				padding={16}
				paddingBottom={40}
				style={{
					shadowColor: theme.colors.onSurface,
					shadowOffset: {
						width: 0,
						height: -1,
					},
					shadowOpacity: 0.1,
					shadowRadius: 4,
				}}
			>
				<Button
					buttonColor={theme.colors.primary}
					onPress={handlePurchase}
					style={{ width: "100%", maxWidth: 500 }}
				>
					<Text variant="labelLarge" style={{ color: theme.colors.onPrimary }}>
						{purchaseButtonText}
					</Text>
				</Button>
			</Box>
		</Box>
	);
};

export default PaywallUI;
