/* -----------------Globals--------------- */
import { memo, useEffect } from "react";
import isEqual from "react-fast-compare";

/* -----------------Child components--------------- */
import { QAChips } from "@memorang/features";

/* -----------------Helpers & Hooks--------------- */
import { useQASearch } from "@features/session";
import { isWeb } from "@helpers/platform";
import { useAppStore } from "@hooks/useAppStore";
import { useRouter } from "expo-router";
import { useAilaStore } from "../../hooks/useAilaStore";

/* -----------------Types--------------- */
import type { OriginalQuestionData } from "@memorang/features";

/* -----------------Constants--------------- */
import { EXCLUDED_EXAM_IDS } from "./constants";

type Props = {
	itemId: number;
	userPrompt: string;
	shouldRender?: boolean;
	originalQuestionData?: OriginalQuestionData;
};

const QAChipsWrapper = ({
	itemId,
	userPrompt,
	shouldRender = true,
	originalQuestionData,
}: Props) => {
	const router = useRouter();
	const updateItemId = useAilaStore((state) => state.updateItemId);
	const updateSelectedQuery = useAilaStore(
		(state) => state.updateSelectedQuery,
	);
	const updateUserPrompt = useAilaStore((state) => state.updateUserPrompt);
	const updateIsItemAnswered = useAilaStore(
		(state) => state.updateIsItemAnswered,
	);

	const showDrawer = useAilaStore((state) => state.showDrawer);
	const updateShowDrawer = useAilaStore((state) => state.updateShowDrawer);
	const updateOriginalQuestionData = useAilaStore(
		(state) => state.updateOriginalQuestionData,
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	useEffect(() => {
		updateItemId(typeof itemId === "string" ? itemId : String(itemId));
		updateUserPrompt(userPrompt);
	}, [itemId, userPrompt]);

	useEffect(() => {
		updateIsItemAnswered(shouldRender);
	}, [shouldRender, updateIsItemAnswered]);

	useEffect(() => {
		updateOriginalQuestionData(originalQuestionData);
	}, [originalQuestionData, updateOriginalQuestionData]);

	const { viewerId, examId, contextId } = useQASearch();
	const { aiAssistant } = useAppStore((store) => ({
		aiAssistant: store.app.aiAssistant,
	}));

	if (EXCLUDED_EXAM_IDS.includes(examId) || !itemId || !userPrompt) {
		return null;
	}

	const handleChipPress = (query: string) => {
		router.push({
			pathname: "/aila/[itemId]",
			params: {
				itemId,
				query,
			},
		});
	};

	return (
		<QAChips
			visible={shouldRender}
			userPrompt={userPrompt}
			vectorStoreParams={{
				examId,
				itemId: String(itemId),
				contextId,
			}}
			userId={String(viewerId)}
			handleChipPress={isWeb ? undefined : handleChipPress}
			showDialog={showDrawer}
			setShowDialog={updateShowDrawer}
			setSelectedQuery={updateSelectedQuery}
			showKeyboardShortcuts={false}
			assistantTitle={aiAssistant.title}
		/>
	);
};

export default memo(QAChipsWrapper, isEqual);
