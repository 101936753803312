import { pageViewTypes } from "@constants/tracking";
import {
	HomeRouteContainer,
	ImageChallengeHomeContainer,
	StudyPackContainer,
} from "@features/dashboard/";
import useAnalytics from "@hooks/useAnalytics";
/* -----------------Globals--------------- */
import { useAppStore } from "@hooks/useAppStore";
import { useGlobalStore } from "@hooks/useGlobalStore";
import DocumentTitle from "components/DocumentTitle";
import { useEffect } from "react";

/* -----------------Child components--------------- */

const StudyPackRouteContainer = () => {
	return (
		<>
			<DocumentTitle title="Study-Pack" />
			<StudyPackContainer showBackButton={false} />
		</>
	);
};
const HomeRoute = () => {
	const showStudyPackAsHome = useAppStore((store) => store.showStudyPackAsHome);
	const homeView = useAppStore((store) => store.homeView);
	const setShowOnboarding = useGlobalStore((state) => state.setShowOnboarding);

	const showRecommendedChallenge = homeView?.showRecommendedChallenge;

	useEffect(() => {
		setShowOnboarding(false);
	}, [setShowOnboarding]);

	useAnalytics({
		type: pageViewTypes.home,
	});

	if (showRecommendedChallenge) {
		return <ImageChallengeHomeContainer />;
	}

	if (showStudyPackAsHome) {
		return <StudyPackRouteContainer />;
	}
	return (
		<>
			<DocumentTitle title="Home" />
			<HomeRouteContainer />
		</>
	);
};

export default HomeRoute;
