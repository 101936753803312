import type { Article } from "../../../../../types";

export const Article654: Article = {
	id: 654,
	rsuiteId: "396ffdbf-c086-4e64-a5f0-c67c40c3919b",
	type: "the orthopaedic forum",
	title: "What's Important: Moving Orthopaedic Patient Care Forward",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=396ffdbf-c086-4e64-a5f0-c67c40c3919b&type=jpeg&name=JBJS.24.00698f1",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">What’s Important: Moving Orthopaedic Patient Care Forward
              </div>
              <div class="text subtitle" style="position: unset;">Tips for Successful Systems Improvement</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">David N.
                      Bernstein, MD, PhD, MBA, MEI</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Robert Kaspar
                      Wagner, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">Delivering consistently high-quality musculoskeletal
                  patient care can be challenging, especially in tertiary and quaternary referral hospitals within
                  integrated health-care delivery systems with seemingly countless stakeholders. Time and resources
                  (e.g., financial and human capital) are limited, yet the demand for orthopaedic and complex medical
                  care continues to grow. This creates added strain on hospitals and health-care systems, but most
                  importantly leads to moral distress and professional frustration among caretakers (physicians, nurses,
                  and others) who are trying their very best to focus on the patients they serve in suboptimal
                  circumstances.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">While all stakeholders would love “quick fixes” that
                  simultaneously address all of the issues, the reality is that incremental progress that focuses on 1
                  or 2 agreed upon “pain points” is likely the best approach. However, simply identifying such
                  opportunities does not guarantee real change. One must have a structured process that is supported by
                  leadership, management principles, and personal experience in order to drive the necessary changes
                  collaboratively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">This article highlights 8 tips that the senior author
                  (T.V.L.) has learned throughout his career for successful systems improvement that can progress
                  orthopaedic care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Tip 1:
                Surround Yourself with Game-Changers</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">Whether in the operating room or elsewhere, trying to
                  change how care is delivered requires a team effort. The likelihood of success increases when the
                  identified stakeholders work collectively and are dedicated to common goals. Thus, even if you try to
                  make a simple change independently, the likelihood of making it sustainable is quite limited.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">While the most successful teams are diverse in every sense
                  of the word, each member of successful teams has an individual will to drive positive change; however,
                  this is not sufficient. As Casciaro and Lobo noted in the <span data-id="emphasis_3"
                    class="annotation emphasis" style="position: unset;">Harvard Business Review,</span> the individuals
                  you should seek are the “lovable stars,” defined as those who not only have high competence but also
                  have high likability<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>. You
                  want these people not only on your team but also in your community to push everyone to be better each
                  day. However, caution is warranted because it is critical to make sure that these people put the “we”
                  ahead of the “I” when needed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Not all individuals are game-changers or people who find a
                  way to routinely make progress on challenges. In fact, there is a wide range of individuals in any
                  work setting, including health care. We propose a simple green-yellow-red framework, where individuals
                  are either game-changers (i.e., green), demonstrate substantial potential but are not game-changers
                  yet (i.e., yellow), or are toxic to progress (i.e., red) (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). The
                  goal is to build teams with as many game-changers as possible, while mentoring those with potential
                  and ensuring that those who are toxic do not spread poor habits to their colleagues.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00698f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=396ffdbf-c086-4e64-a5f0-c67c40c3919b&type=jpeg&name=JBJS.24.00698f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;">An illustration of the “rule of thirds” related to
                          Tip 1 (i.e., “Surround yourself with game-changers”).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Tip 2:
                Ensure Overarching Vision Alignment Across Stakeholders</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">While it is critical to clearly articulate what you want
                  to accomplish, there will be endless hurdles if your division, department, and/or institution do not
                  understand the “need for change” and support the endeavor. However, it is important to note that
                  support should not be an “all or nothing” expectation. In many cases, the overarching philosophy may
                  be agreed upon, but how to design and implement the change will take negotiation and compromise in
                  order to make a real, lasting difference.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">For example, time to surgery is an important modifiable
                  factor to improve outcomes for geriatric patients who sustain a hip fracture. However, decreasing time
                  to surgery is challenging; it requires algorithmic protocols and, most importantly, a well-aligned,
                  multidisciplinary effort and overarching vision shared among orthopaedic surgeons, anesthesiologists,
                  geriatricians, nurses, and physical therapists, among others. Therefore, we initiated a geriatric hip
                  fracture working group with all of the stakeholders, who agreed on the broad vision to improve
                  outcomes for our patients by decreasing the time to surgery. As a result, time to surgery improved
                  dramatically, decreasing from 36 to 17 hours over a 2-year period (2021 to 2023); in-hospital length
                  of stay also decreased (from 6.5 to 5.0 days). Importantly, if our team members had disagreed that
                  time to surgery was an issue, it would have been much harder, if not impossible, to make the changes
                  we desired.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Tip 3: Pick
                Your Battles</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">As part of routine patient care responsibilities, we
                  witness the good and bad aspects of the systems in which we work. While there are an overwhelming
                  number of positive aspects of what we accomplish each day, the frustrating shortcomings and
                  disappointments become areas of focus, especially as part of systems improvement work. However, we
                  must acknowledge that our time, as well as the time of our colleagues, and available financial
                  resources are limited; thus, addressing all of the challenges is simply not possible. Further, there
                  may be issues that some stakeholders feel are critical but others feel are less important. In fact,
                  there may be issues that we appreciate but others simply do not feel are worthwhile to address or even
                  recognize as problems. It is critical to remember that in such situations, we must compromise (as long
                  as it does not harm our patients). We should never “burn bridges” regarding a singular frustration
                  unless we are confident that it is absolutely pivotal for the safety of our patients or colleagues.
                  Sometimes, the best battles are the ones you choose not to fight.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Tip 4: If
                You’re Not Willing to Volunteer, Don’t Complain</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">It is extremely easy to complain and identify areas that
                  need improvement. It takes minimal effort, and while it may be cathartic, the positive feelings often
                  evaporate quickly and are replaced by frustration, especially if an issue recurs frequently. Simply
                  complaining does not fix the underlying issue and can often lead to animosity among care team members
                  who may shift to blaming each other without searching for a solution to further enhance patient care.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">If you are not willing to volunteer to help fix a problem,
                  you should not complain. For example, be the first to join the standing committee that is tasked with
                  solving operating room issues or patient length-of-stay challenges. Additionally, if you are a
                  decision-maker, when a major policy that may impact multiple individuals is shifted, make sure that it
                  impacts you as well, if possible. Indeed, it is crucial to lead by example. However, it is important
                  to note that this may not always be the case since you may be in a position to lead change that only
                  impacts a subset of individuals. In such circumstances, it is important to be empathetic and an active
                  listener, ensuring that you understand how a policy shift may affect key stakeholders and alleviating
                  their concerns whenever possible. This may require changing proposed solutions and admitting your own
                  fault or misunderstandings, which are key elements of leadership and should never get in the way of
                  progress.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Tip 5: Look
                in the Mirror Before Blaming Others</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Care teams are all busy, trying to accomplish many things
                  simultaneously to move patient care forward. When issues arise, it is simple to say, “It’s not me,
                  it’s them.” However, we must remember to be grounded and remain accountable for our actions, including
                  the role we play in creating or worsening an issue. For example, it may be the orthopaedic surgery
                  team, not the anesthesia or nursing teams as we often assume, that delays the start of a first case
                  because of an incorrect consent or failure to mark a patient’s surgical site.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">When you are unsure of what is driving a problem, request
                  the relevant data, and know that you likely are responsible for at least part of the issue. Do not
                  come up with excuses when the data show that you are part of the problem; the data speak for
                  themselves. Instead, embrace your role in the problem and seek to find a solution with the entire
                  team.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Tip 6: Think
                Twice Before You “CC” Someone’s Boss</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Building trust, good rapport, and mutual respect among
                  team members who are working together to solve an issue is critical to success. However, this does not
                  occur overnight. It takes time and transparency, as well as an understanding that disagreements and
                  conflict are likely to occur but can be overcome.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">When disagreements occur between 2 team members, it is
                  easy to quickly escalate an issue to another’s superior with the hope that they will side with you.
                  Sometimes, such an approach even feels like the “right” thing to do. However, we strongly recommend
                  against this approach. Instead, working collaboratively to address issues as well as possible should
                  be the primary approach. A superior should be included in discussions only if you have determined that
                  this is a “battle worth fighting” and after an extended time frame of trying to compromise and failing
                  to do so. Importantly, informing a superior should not be done as a blind “CC” (i.e., “BCC”); you
                  should alert the other party that you will be doing so and make it visible to all involved parties.
                  This shows respect and does not “burn bridges” for future interactions and challenges that you are
                  likely to face together.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Tip 7: Focus
                on Change Locally Before Chasing Change Globally</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">As orthopaedic surgeons, when we see an issue, we often
                  want to fix it and then “move on.” When it comes to systems improvement, however, we must be willing
                  to take a step back and be comfortable making and accepting incremental or local progress instead of
                  broader or global change, at least to start.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Our sphere of influence may be centered in a division, not
                  a full department, hospital, or health-care system setting. By initially focusing more locally,
                  achieved successes can then be leveraged to make broader changes instead of wasting time and limited
                  resources trying to engage with those with whom you have no working or personal relationship.
                  Ultimately, through dedicated education of different stakeholders regarding the issues at hand and
                  their possible solutions, local change can occur; broader change may require research or data to
                  support a shift in policy. For example, using the insights gained from our work with geriatric
                  patients with hip fracture to reduce length of stay, work is now underway to improve this metric with
                  other patients on a department-wide basis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Tip 8: Build
                and Foster Mentor-Mentee Relationships</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Mentorship matters. Success in health care, whether it be
                  patient care, research, or business-related, is rarely accomplished by an individual but is rather a
                  team effort. Throughout one’s career, there will always be “ups and downs,” and having a mentor in
                  your corner for feedback and guidance can be invaluable, especially with systems improvement
                  initiatives.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The very best mentors are honest and provide frank advice
                  and guidance that you may not want to hear but need to if you ultimately are to succeed. At times,
                  these honest insights may come across as hurtful to the mentee, yet they are critical for continued
                  educational, personal, and professional development. It is always important to remember that true
                  mentors have a mentee’s best interest in mind. Further, mentors often see your “blind spots” and alert
                  you to them. They cheer your successes and offer invaluable reflections on your shortcomings. They
                  want you to succeed in a more expedited fashion than they did, often sharing their own experiences
                  throughout the process.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">It is essential that mentors and mentees pick each other,
                  as it is a 2-way relationship that requires continual maintenance<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>. You will likely be a mentor to some and a mentee to others.
                  This dynamic leads to constant learning and an opportunity to grow personally and professionally since
                  mentorship, especially in academic circles, often leads to sponsorship and professional advancement
                  that otherwise may be tougher to accomplish.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Final
                Thoughts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">As orthopaedic surgeons, we work in complex clinical
                  settings with countless stakeholders. However, we must always remember that the patient is the primary
                  stakeholder and the core reason that we should strive for systems improvement. While many care
                  delivery processes work well, most have room for improvement. However, change is hard, even when it is
                  known that a system is failing or falling short of its optimal outcome. By utilizing the 8 tips that
                  the senior author has learned over his career, we believe that systems improvement initiatives can be
                  more successful and enjoyable as diverse, collaborative teams are built and strong relationships are
                  formed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Casciaro T</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Lobo MS</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Competent Jerks, Lovable Fools,
                  and the Formation of Social Networks</span>. 2005. Accessed 2024 May 10. <a
                  href="https://hbr.org/2005/06/competent-jerks-lovable-fools-and-the-formation-of-social-networks"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://hbr.org/2005/06/competent-jerks-lovable-fools-and-the-formation-of-social-networks</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Competent%20Jerks%2C%20Lovable%20Fools%2C%20and%20the%20Formation%20of%20Social%20Networks&as_occt=title&as_sauthors=%20%22T%20Casciaro%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Moed BR</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Mentoring: the role of a mentor
                  and finding one</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2012 Sep;26(Suppl 1):S23-4.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mentoring%3A%20the%20role%20of%20a%20mentor%20and%20finding%20one&as_occt=title&as_sauthors=%20%22BR%20Moed%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D396ffdbf-c086-4e64-a5f0-c67c40c3919b%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 15, 2025;
                      107 (2): 219</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00698</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 27, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_2"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopaedic Surgery, Massachusetts General Hospital, Boston,
                            Massachusetts</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;396ffdbf-c086-4e64-a5f0-c67c40c3919b&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=396ffdbf-c086-4e64-a5f0-c67c40c3919b&type=pdf&name=JBJS.24.00698.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=396ffdbf-c086-4e64-a5f0-c67c40c3919b&type=pdf&name=JBJS.24.00698.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_3" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_4"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I214" target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I214</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;396ffdbf-c086-4e64-a5f0-c67c40c3919b&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=396ffdbf-c086-4e64-a5f0-c67c40c3919b&type=zip&name=JBJS.24.00698.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">David N. Bernstein, MD, PhD, MBA, MEI<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:dbernstein4@mgh.harvard.edu" class=""
                    style="position: unset;">dbernstein4@mgh.harvard.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1784-3288" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1784-3288</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Robert Kaspar Wagner, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jacob S. Borgida, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mitchel B. Harris, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Thuan V. Ly, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Massachusetts General Hospital, Boston,
                Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 11220.2px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
