/* ======== UI ========= */
import { configureFonts } from "react-native-paper";

import { buildType } from "@helpers/expo-extras";
import { useAppStore } from "@hooks/useAppStore";
/* ======== Helpers ========= */
import { typography } from "@memorang/helpers";
import type { Apps } from "types/apps";
import { breakpoints, componentOverrides, fontConfig } from "./constants";
import { getCustomPalette, getMergedCustomTheme, textColors } from "./helpers";

const buildTheme = (appName: Apps, darkMode: boolean) => {
	const appFont = useAppStore.getState().app.font;
	const customTextColors = textColors(darkMode);
	const customPalette = getCustomPalette(darkMode);
	const mergedCustomTheme = getMergedCustomTheme({
		appName,
		darkMode,
	});
	const customTypography = typography(darkMode, appFont ?? "opensans");
	const fonts = configureFonts({ config: fontConfig });
	const colors = {
		...mergedCustomTheme.colors,
		text: darkMode ? "#fff" : "#000",
		white: "#fff",
		black: "#000",
		...customPalette,
	};

	return {
		...mergedCustomTheme,
		dark: darkMode,
		colors,
		palette: {
			text: {
				...customTextColors,
			},
			error: {
				main: "#EB0014",
				light: "rgba(248, 128, 120, 1)",
				dark: "#C70011",
				info: "rgba(255, 220, 220, 1)",
				infoDark: "rgba(255, 180, 180, 1)",
			},
			info: {
				main: "rgba(123, 97, 255, 1)",
			},
			purple: {
				main: "#800080",
				light: "#E0B0FF",
				dark: "#4B0082",
			},
			warning: {
				main: "rgba(237, 108, 2, 1)",
				light: "rgba(255, 181, 71, 1)",
				dark: "rgba(199, 119, 0, 1)",
			},
			success: {
				main: "#008000",
				light: "rgba(123, 198, 126, 1)",
				dark: "#006400",
				info: "rgba(187, 240, 181, 1)",
				infoDark: "rgba(102, 187, 104, 1)",
				infoLight: "rgba(0, 33, 5, 0.6)",
			},
			answer: {
				selected: {
					border: "#6da3eb",
					background: "rgba(109, 163, 235, 0.15)",
				},
			},
		},
		breakpoints,
		typography: customTypography,
		fonts,
		componentOverrides,
	};
};

const theme = (darkMode?: boolean) => buildTheme(buildType, darkMode!);
export default theme;
