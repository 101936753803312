import type { Article } from "../../../../../types";

export const Article550: Article = {
	id: 550,
	rsuiteId: "1e30254d-1373-480c-a87c-953d5824e911",
	type: "scientific article",
	title:
		"Reverse Shoulder Arthroplasty Is Superior to Plate Fixation for Displaced Proximal Humeral Fractures in the Elderly",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=jpeg&name=JBJS.23.01431f1a",
	subSpecialties: ["shoulder_elbow"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Reverse Shoulder Arthroplasty Is Superior to Plate
                Fixation for Displaced Proximal Humeral Fractures in the Elderly</div>
              <div class="text subtitle" style="position: unset;">Five-Year Follow-up of the DelPhi Randomized
                Controlled Trial<span data-id="footnote_reference_1"
                  class="label annotation cross_reference subtitle">*</span></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Alexander
                      Nilsskog Fraser, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Tone Mehlum
                      Wagle, PT</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 10 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">The treatment of proximal humeral fractures is
                        controversial, and operative treatment is increasing despite sparse evidence to support any
                        surgical method over another. We present the 5-year results of the DelPhi (Delta-PHILOS) trial,
                        in which the hypothesis was that reverse total shoulder arthroplasty (RTSA) yields better
                        clinical results compared with open reduction and internal fixation (ORIF). The DelPhi trial was
                        a multicenter single-blinded randomized controlled trial comparing RTSA versus ORIF for the
                        treatment of displaced AO/OTA type-B2 and C2 proximal humeral fractures in patients 65 to 85
                        years of age. One hundred and twenty-four patients were allocated to RTSA (64 patients) or ORIF
                        (60 patients). The primary outcome was the Constant score, and secondary outcomes included the
                        Oxford Shoulder Score, radiographic measurements, and quality of life. The results were reported
                        as the mean difference between the groups, with the 95% confidence interval (CI). The mean age
                        was 75 years, and 90% of the patients were female. Overall, 65 of the 124 patients were
                        available at a minimum of 5 years. The mean Constant score was 71.7 (95% CI, 67.1 to 76.3) for
                        the RTSA group, compared with 58.3 (95% CI, 50.6 to 65.9) for the ORIF group, representing a
                        significant difference of 13.4 (95% CI, 5.2 to 21.7) in favor of RTSA (p = 0.002). Among
                        patients with type-C2 fractures, the mean Constant score was 73.3 (95% CI, 67.5 to 79.2) for the
                        RTSA group and 56.0 (95% CI, 47.4 to 64.7) for the ORIF group, representing a significant
                        difference of 17.3 (95% CI, 7.5 to 27.0) in favor of RTSA (p = 0.001). Patients with type-B2
                        fractures demonstrated a nonsignificant difference of 8.1 (95% CI, −7.3 to 23.3) in favor of
                        RTSA (p = 0.29). Patients 65 to 74 years of age showed a significant mean difference of 15.7
                        (95% CI, 4.9 to 26.7) in favor of RTSA (p = 0.006), whereas patients 75 to 85 years of age
                        showed a nonsignificant difference of 10.8 (95% CI, −3.0 to 24.6) in favor of RTSA. In the
                        present study, RTSA was superior to plate fixation for displaced AO/OTA type-B2 and C2 fractures
                        in elderly patients at 5 years. Patients 65 to 74 years of age and patients with type-C2
                        fractures benefited the most.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_42" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level I</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Although most proximal humeral fractures in elderly
                  patients are treated nonoperatively, approximately 20% to 30% may be considered for operative
                  treatment<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">3</span></a>. Locking plates represented a change in fracture care,
                  including the treatment of proximal humeral fractures, and produced more consistent operative results,
                  leading to increased optimism regarding the prognosis<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">5</span></a>. Later studies on the use of locking plates for the
                  treatment of proximal humeral fractures have shown variable clinical results, however, with adverse
                  events and secondary surgical procedures in up to 30% to 40% of patients<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">9</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Since the 1950s, hemiarthroplasty has been a surgical
                  option for the treatment of severely displaced proximal humeral fractures that were considered too
                  difficult to reconstruct or that were associated with high rates of osteonecrosis<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">11</span></a>. For
                  lack of a better alternative, hemiarthroplasty remained an accepted practice until recently, despite
                  documented limited functional gain in elderly patients<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">12</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">14</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Promising results have led to a substantial increase in
                  the use of reverse total shoulder arthroplasty (RTSA) for the operative treatment of displaced
                  proximal humeral fractures in the elderly<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">15</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">16</span></a>, and RTSA has gradually replaced hemiarthroplasty as the
                  prosthetic surgical procedure of choice<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">17</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">20</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Level-IV studies have indicated promising short-term
                  results for RTSA in fracture treatment<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">21</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">23</span></a>, and these results have been confirmed by high-level
                  studies, including the 2-year follow-up of the DelPhi (Delta-PHILOS) trial (ClinicalTrials.gov
                  NCT01737060)<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">24</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">25</span></a>. To bridge the gap in knowledge regarding the durability of
                  RTSA in fracture treatment, we conducted a 5-year follow-up of the DelPhi trial. The purpose of the
                  DelPhi trial was to conduct a high-level investigation of operative treatment of proximal humeral
                  fractures in the elderly, and the hypothesis was that RTSA would result in a better clinical outcome
                  compared with open reduction and internal fixation (ORIF).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The DelPhi trial was a multicenter, single-blinded
                  randomized controlled trial (RCT) in which RTSA was compared with ORIF for the operative treatment of
                  severely displaced OTA/AO (2007)<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">40</span></a>
                  type-B2 (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 1-A</a>, <a
                    href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">1-B</a>, and <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">1-C</a>) and type-C2
                  (<a href="" data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 2-A</a> and <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">2-B</a>) proximal
                  humeral fractures in patients 65 to 85 years of age. The trial originated at Oslo University Hospital
                  and included the participation of 6 other Norwegian hospitals. Block randomization was conducted with
                  use of a secured web solution, and patients were allocated to RTSA (Delta Xtend; DePuy Synthes [52
                  patients] or Promos; Smith and Nephew [12 patients]) or ORIF with a locking plate (PHILOS; DePuy
                  Synthes [60 patients]). Operations were conducted according to standardized procedures. The primary
                  outcome was the Constant score, and the minimal clinically important difference (MCID) was set to 10
                  points<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">27</span></a>. The secondary outcome measures were the Constant score in
                  subgroups, the Oxford Shoulder Score<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">29</span></a>, radiographic measurements, and the HRQoL-15D
                  (health-related quality-of life-15D) score<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">30</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_13" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_45"
                            class="annotation strong" style="position: unset;">Figs. 1-A, 1-B, and 1-C</span> True
                          anteroposterior radiograph (<span data-id="strong_46" class="annotation strong"
                            style="position: unset;">Fig. 1-A</span>), scapular Y radiograph (<span data-id="strong_47"
                            class="annotation strong" style="position: unset;">Fig. 1-B</span>), and posterior 3D
                          computed tomography (CT) scan (<span data-id="strong_48" class="annotation strong"
                            style="position: unset;">Fig. 1-C</span>) showing a 3-part varus displaced AO/OTA type-B2
                          proximal humeral fracture. The 3D CT shows that the posterior anatomical neck is intact.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-A</div>
                    <div class="image-download" name="JBJS.23.01431f1a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=jpeg&name=JBJS.23.01431f1a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-B</div>
                    <div class="image-download" name="JBJS.23.01431f1b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=jpeg&name=JBJS.23.01431f1b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-C</div>
                    <div class="image-download" name="JBJS.23.01431f1c" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=jpeg&name=JBJS.23.01431f1c"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_4" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_53"
                            class="annotation strong" style="position: unset;">Figs. 2-A and 2-B</span> True
                          anteroposterior radiograph (<span data-id="strong_54" class="annotation strong"
                            style="position: unset;">Fig. 2-A</span>) and scapular Y radiograph (<span
                            data-id="strong_55" class="annotation strong" style="position: unset;">Fig. 2-B</span>)
                          showing a 4-part valgus impacted AO/OTA type-C2 proximal humeral fracture. The radiographs
                          confirm that the fracture line runs along the anatomical neck.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-A</div>
                    <div class="image-download" name="JBJS.23.01431f2a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=jpeg&name=JBJS.23.01431f2a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-B</div>
                    <div class="image-download" name="JBJS.23.01431f2b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=jpeg&name=JBJS.23.01431f2b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Data collection was performed at baseline, 3 months, 6
                  months, 1 year, 2 years, and 5 years. Patient testing was conducted by physiotherapists who were
                  blinded to the allocated treatment. Radiographic images were examined by a dedicated radiologist
                  (A.C.K.) and the first author (A.N.F.). There was a considerable loss to follow-up because of the
                  declining health condition of some of the included patients, and telephone interviews with collection
                  of Oxford Shoulder Score data were conducted for 6 patients<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">31</span></a> (5
                  in the RTSA group and 1 in the ORIF group).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Notching and implant loosening in the RTSA group and
                  osteonecrosis in the ORIF group were of particular interest at the time of the radiographic evaluation
                  at 5 years. We used the Nerot-Sirveaux score to grade notching<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">32</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">41</span></a>. Implant loosening was defined as implant migration and
                  radiolucency around the implant. Osteonecrosis was graded as no changes, changes involving &lt;50% of
                  the humeral head surface, or partial collapse of the humeral head surface and/or structural changes
                  involving ≥50% of the humeral head surface<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">33</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Statistical analysis was performed with IBM SPSS (version
                  25; IBM) for descriptive analyses and Stata (version 18; StataCorp) for linear mixed model analyses.
                  The mean outcome and subgroup analyses of the allocation groups were compared with use of the
                  independent-samples t test with a 5% level of significance. Linear mixed model analyses for repeated
                  measurements with use of a random intercept for each patient were used as a supplementary sensitivity
                  analysis. The results were reported as mean differences with 95% confidence intervals (CIs) between
                  the 2 allocated treatment groups. The intention-to-treat principle was applied for crossover patients.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Eligible patients received oral and written information
                  about the trial before giving written consent. The trial was approved by the Regional Ethics Committee
                  of Research, South-East Health Authority, Oslo, Norway on November 6, 2012 (2012/1606). The published
                  protocol and DelPhi 2-year article may be consulted for further information on the methodology of the
                  trial<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">34</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">One hundred and twenty-four patients were included in the
                  trial: 64 who had been allocated to the RTSA group and 60 who had been allocated to the ORIF group (<a
                    href="" data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>). The mean age (and standard deviation) was 75.2 ± 6.3 years
                  (range, 64.8 to 85.8 years), 90% of the patients were female, and 56% had a type-C2 fracture (see
                  Appendix Table 1). At the time of the 5-year follow-up, 65 patients (39 in the RTSA group and 26 in
                  the ORIF group) were available for clinical testing. An additional 6 patients (5 in the RTSA group and
                  1 in the ORIF group) answered questions regarding patient-reported outcome measures by means of a
                  telephone interview. Fourteen patients in the RTSA group dropped out of the study (3 had cognitive
                  impairment and 11 gave no reason). Twenty-four patients in the ORIF group dropped out of the study (4
                  had cognitive impairment and 20 gave no reason).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01431f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=jpeg&name=JBJS.23.01431f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;">Flowchart showing the 5-year follow-up for
                          patients in the DelPhi trial. A total of 270 patients with AO type-B2 or C2 fractures between
                          65 and 85 years of age were assessed for eligibility, 146 patients were excluded, and 124
                          patients were included in the study and were allocated to either reverse total shoulder
                          arthroplasty (RTSA) or open reduction and internal fixation (ORIF). Missing data due to
                          withdrawal from the study or death are cumulative. Patients who were not available for
                          follow-up at certain previous time points were included in the analysis if they were available
                          for testing at 5 years. PROMS = patient-reported outcome measures, OSS = Oxford Shoulder
                          Score.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Primary
                Outcome</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">At 5 years, the mean Constant score was 71.7 (95% CI, 67.1
                  to 76.3) in the RTSA group, compared with 58.3 (95% CI, 50.6 to 65.9) in the ORIF group, representing
                  a significant mean difference of 13.4 (95% CI, 5.2 to 21.7) (p &lt; 0.002) in favor of RTSA (<a
                    href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4</a>). Constant subscores showed that the patients in the RTSA group
                  had significantly better scores than those in the ORIF group with regard to activities of daily living
                  in the categories of sleep (reported sleep disturbance due to shoulder pain), movement (the height to
                  which the patient could comfortably use their arm), and range of motion of the shoulder in abduction,
                  flexion, and external rotation (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). The
                  main findings are summarized in <a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01431f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=jpeg&name=JBJS.23.01431f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;">Graph showing the overall mean Constant score over
                          5 years for the RTSA group (red) and the ORIF group (blue). Error bars indicate 95% CIs.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Secondary
                Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Constant
                Score According to Fracture Type</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Among patients with type-B2 fractures, the mean Constant
                  score was 68.9 (95% CI, 60.6 to 77.1) in the RTSA group, compared with 60.8 (95% CI, 46.1 to 75.5) in
                  the ORIF group (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>),
                  representing a nonsignificant Constant score difference of 8.1 (95% CI, −7.3 to 23.3) in favor of RTSA
                  (p = 0.29). Among patients with type-C2 fractures, the mean Constant score was 73.3 (95% CI, 67.5 to
                  79.2) for the RTSA group, compared with 56.0 (95% CI, 47.4 to 64.7) for the ORIF group, representing a
                  significant mean difference of 17.3 (95% CI, 7.5 to 27.0) in favor of RTSA (p = 0.001).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.01431f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=jpeg&name=JBJS.23.01431f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_12" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;">Graph showing the mean Constant score for the RTSA
                          and ORIF groups, stratified by the AO/OTA fracture classification. Error bars indicate 95%
                          CIs.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Constant
                Score According to Age</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Among patients 65 to 74 years of age, the mean Constant
                  score was 74.0 (95% CI, 67.8 to 80.5) for the RTSA group, compared with 58.3 (95% CI, 48.6 to 60.1)
                  for the ORIF group, representing a significant mean difference of 15.7 (95% CI, 4.9 to 26.7) in favor
                  of RTSA (p = 0.006) (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 6</a>). Among
                  patients 75 to 85 years of age, the mean Constant score was 68.9 (95% CI, 61.7 to 76.0) for the RTSA
                  group, compared with 58.1 (95% CI, 42.5 to 73.7) for the ORIF group, representing a nonsignificant
                  difference of 10.8 (95% CI, −3.0 to 24.6) in favor of RTSA (p = 0.12).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.23.01431f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=jpeg&name=JBJS.23.01431f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_13" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;">Graph showing the mean Constant score for the RTSA
                          and ORIF groups, stratified by age groups. Error bars indicate 95% CIs.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Oxford
                Shoulder Score</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The Oxford Shoulder Score results supported the primary
                  outcome findings (<a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 7</a>). The
                  mean Oxford Shoulder Score was 43.0 (95% CI, 41.3 to 44.8) for the RTSA group, compared with 38.0 (95%
                  CI, 34.8 to 41.3) for the ORIF group, representing a significant mean difference of 5.0 (95% CI, 1.7
                  to 8.3) in favor of RTSA (p = 0.003).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 7</div>
                <div class="image-download" name="JBJS.23.01431f7" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=jpeg&name=JBJS.23.01431f7"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_14" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;">Graph showing the overall mean Oxford Shoulder
                          Scores for the RTSA and ORIF groups over 5 years. Error bars indicate 95% CIs. The Oxford
                          Shoulder Score consists of 12 questions regarding shoulder pain, shoulder function, and
                          activities of daily living and ranges from 0 points (worst) to 48 points (best).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Quality of
                Life</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">There were no differences between the groups in terms of
                  HRQoL-15D scores at 2<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">35</span></a> or
                  5 years. At 5 years, the mean score was 0.84 (95% CI, 0.81 to 0.87) for the RTSA group, compared with
                  0.84 (95% CI, 0.80 to 0.88) for the ORIF group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                Measurements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Preoperative radiographic measurements are shown in
                  Appendix Table 2. In the RTSA group, two-thirds (41 of 62) achieved an inferior overhang of the
                  glenosphere of ≥5 mm, which is desired for the Delta Xtend prosthesis design (see Appendix Table 3).
                  At 5 years, 11 of 42 patients showed radiographic signs of scapular notching (classified as grade 1 in
                  3 patients and as grade 2 in 8 patients<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">32</span></a>),
                  which was practically equivalent to the 2-year findings. Twenty-two patients had periarticular bone
                  formation at 5 years, compared with 28 patients at 2 years. The greater tuberosity was not healed in 5
                  patients, of whom 2 had a Constant score of &gt;90 and excellent external rotation, 1 had a Constant
                  score of 42 and no external rotation, and 2 were lost to follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">In the ORIF group, 3 of 28 patients had 11° to 30° varus,
                  8 had screw penetration into the glenohumeral joint, and 1 patient had a nonunion (see Appendix Table
                  4). Fifteen of 49 patients had had osteonecrosis at 2 years, compared with 13 of 28 patients at 5
                  years, representing a relative increase from 31% to 46%. The number of instances of screw penetration
                  and osteonecrosis found on radiographic assessment (see Appendix Table 4) is somewhat higher than the
                  number recorded as adverse events (<a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>)
                  because only clinically important findings were reported as adverse events.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Adverse
                Events</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">At 2 years, there were 7 adverse events in the RTSA group:
                  2 transient axillary nerve palsies, 2 deep wound infections and 2 periprosthetic fractures. One
                  patient sustained a perioperative glenoid fracture during primary RTSA surgery and therefore received
                  treatment with a hemiprosthesis instead of RTSA. When the glenoid fracture had healed, the patient
                  underwent a subsequent reoperation with RTSA (<a href="" data-id="figure_reference_14"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                  There were 4 secondary surgical procedures: 2 revisions with changing of the plastic liner, 1 plating
                  of a periprosthetic fracture, and 1 revision of a hemiarthroplasty with conversion to RTSA. Between 2
                  and 5 years, there were 6 adverse events and 2 secondary surgical procedures in the RTSA group. The
                  adverse events included 4 periprosthetic fractures (<a href="" data-id="figure_reference_15"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 8-A</a> and <a
                    href="" data-id="figure_reference_16" class="annotation figure_reference resource-reference"
                    style="position: unset;">8-B</a>; see also Appendix Fig. 1) and 1 glenohumeral dislocation; all were
                  caused by a fall or minor trauma. There was 1 case of clinically apparent and radiographically
                  verified implant loosening, where the patient declined revision surgery. In 2 other cases,
                  radiographic findings were suggestive of implant loosening but clinical findings were inconclusive or
                  did not loosening. One periprosthetic fracture was operatively treated by ORIF with a plate, and the
                  other 3 were treated nonoperatively; all 4 periprosthetic fractures healed. The glenohumeral
                  dislocation was treated with closed reduction, and 1 implant was revised with changing of the plastic
                  liner because of pain without findings suggestive of infection.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_15" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_40" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_59"
                            class="annotation strong" style="position: unset;">Figs. 8-A and 8-B</span> A patient
                          sustained a periprosthetic fracture due to a fall at 15 months after undergoing operative
                          treatment with reverse shoulder arthroplasty. The fracture was treated nonoperatively with an
                          orthosis. At 3 years after the primary surgery, the anteroposterior radiograph (<span
                            data-id="strong_60" class="annotation strong" style="position: unset;">Fig. 8-A</span>) and
                          the scapular Y radiograph (<span data-id="strong_61" class="annotation strong"
                            style="position: unset;">Fig. 8-B</span>) showed clear signs of healing.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 8-A</div>
                    <div class="image-download" name="JBJS.23.01431f8a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=jpeg&name=JBJS.23.01431f8a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_41" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_12" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 8-B</div>
                    <div class="image-download" name="JBJS.23.01431f8b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=jpeg&name=JBJS.23.01431f8b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_17" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_42" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">In the ORIF group, there were 12 adverse events at 2
                  years. Nine patients had screw penetrations, of whom 6 also had concomitant osteonecrosis. As screw
                  penetrations and osteonecrosis frequently occurred together, they have not been treated as separate
                  adverse events in Appendix Table 3. One patient had a nonunion, 1 patient had a peri-implant fracture
                  of the humeral shaft, and 1 patient had a rotator cuff tear. There were 8 secondary surgical
                  procedures in the ORIF group at 2 years: 4 conversions from a plate to RTSA, and 4 procedures for
                  implant removal or refixation. Between 2 and 5 years, there were 5 adverse events and 3 secondary
                  surgical procedures. All 5 patients with adverse events showed screw penetration in combination with
                  osteonecrosis (<a href="" data-id="figure_reference_17"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 9-A</a> and <a
                    href="" data-id="figure_reference_18" class="annotation figure_reference resource-reference"
                    style="position: unset;">9-B</a>; see also Appendix Fig. 2). One patient was revised to RTSA between
                  2 and 5 years, and 2 patients had implant removal.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_19" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_45" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_65"
                            class="annotation strong" style="position: unset;">Figs. 9-A and 9-B</span> Anteroposterior
                          radiograph (<span data-id="strong_66" class="annotation strong" style="position: unset;">Fig.
                            9-A</span>) and scapular Y radiograph (<span data-id="strong_67" class="annotation strong"
                            style="position: unset;">Fig. 9-B</span>), made 5 years after open reduction and internal
                          fixation with a locking plate, showing severe osteonecrosis of the humeral head and
                          penetration of the glenohumeral joint by several screws.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_13" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 9-A</div>
                    <div class="image-download" name="JBJS.23.01431f9a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=jpeg&name=JBJS.23.01431f9a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_46" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_14" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 9-B</div>
                    <div class="image-download" name="JBJS.23.01431f9b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=jpeg&name=JBJS.23.01431f9b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_47" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">In summary, a periprosthetic fracture caused by a
                  subsequent fall was the most frequent adverse event in the RTSA group (6 cases), whereas screw
                  penetration with or without osteonecrosis was most frequent in the ORIF group (14 cases). The rate of
                  adverse events and secondary procedures was approximately twice as high in the ORIF group compared
                  with the RTSA group during the first 2 years of follow-up, whereas the 2 groups were more even in this
                  regard between 2 and 5 years. The cumulative numbers of adverse events over 5 years were 13 for the
                  RTSA group and 17 for the ORIF group, leading to 6 secondary procedures in the RTSA group and 11
                  secondary procedures in the ORIF group. All 5 conversions from a plate to RTSA were performed in
                  patients who had sustained a type-C2 fracture.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_50" style="position: unset;">
                <div class="content" style="position: unset;">At 5 years, RTSA was superior to ORIF for the treatment of
                  proximal humeral fractures in the elderly, with significantly higher Constant scores (p = 0.002) and
                  Oxford Shoulder Scores (p = 0.003). Stratified results significantly favored RTSA for the treatment of
                  type-C2 fractures (p = 0.001) and for patients in the 65 to 74-year age group (p = 0.006). The trend
                  was also in favor of RTSA for patients with type-B2 fractures and patients in the 75 to 85-year age
                  group; however, these results were nonsignificant. The rate of withdrawal was higher in the ORIF group
                  compared with the RTSA group (24 versus 14 patients), and the number of adverse events was higher in
                  the ORIF group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_51" style="position: unset;">
                <div class="content" style="position: unset;">We are not aware of any other RCT comparing RTSA with
                  ORIF; few RCTs have involved RTSA as treatment of proximal humeral fractures, and none of those
                  studies have assessed 5-year results<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_20" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">19</span></a>. The 2-year results for the RTSA group in those previous
                  trials showed mean Constant scores in the upper 50s, which is were lower than the mean Constant score
                  for RTSA group in the present study. This discrepancy may be due to differences between the study
                  cohorts in terms of comorbidities, preoperative shoulder function, operative technique, test methods,
                  or postoperative physiotherapy. Also, very few high-level RCTs on proximal humeral fractures have
                  involved ORIF, and we found none with 5 years of follow-up<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_21" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">37</span></a>. The functional results at 2 years have varied somewhat in
                  previous studies, but the reported complication rates for ORIF seem to be equal to or higher than
                  those in the present study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_52" style="position: unset;">
                <div class="content" style="position: unset;">Overall, the 5-year results in the present study confirm
                  our previously published 2-year results<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">24</span></a>.
                  The 5-year follow-up results are of particular importance with respect to fracture treatment with
                  prosthetic implants, where longevity is of particular concern. The subgroup analyses at 5 years showed
                  that the primary outcome favoring RTSA was mainly driven by the significant positive effect of RTSA in
                  patients with the most severe (i.e., type-C2) fractures (<a href="" data-id="figure_reference_19"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>). Among
                  patients with type-B2 fractures, there was a nonsignificant 8-point difference in the Constant score
                  in favor of RTSA. Furthermore, the younger age group benefited somewhat more from RTSA compared with
                  the older group, particularly during the first 2 years, and, to a lesser degree, from 2 to 5 years (<a
                    href="" data-id="figure_reference_20" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 6</a>). The primary results (Constant score) were supported by the
                  secondary results (Oxford Shoulder Score).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_53" style="position: unset;">
                <div class="content" style="position: unset;">To measure quality of life, we used the HRQoL-15D<a
                    href="" data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">30</span></a>, rather than the more commonly used HRQoL-5D<a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">38</span></a>, as the former is more sensitive to minor changes. Even so,
                  we found no difference in quality of life between the 2 groups at 5 years. This finding prompts the
                  question: Why is there a significant difference in favor of RTSA when using shoulder-specific outcomes
                  (Constant score and Oxford Shoulder Score) but still no difference in quality of life (15D)? The
                  primary outcome findings showed a significantly increased painless range of motion for elevation and
                  external rotation of the shoulder and a significantly increased height as to which the patients could
                  painlessly use the operatively treated arm (Appendix Table 1). The 15D is a generic instrument that
                  measures somatic, mental, and social factors, and the questions that concern physical ability do not
                  particularly focus on upper-extremity function. Furthermore, there is a somewhat lower demand with
                  respect to activity among elderly patients, and pain-provoking activity may be avoided without loss of
                  quality of life.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_54" style="position: unset;">
                <div class="content" style="position: unset;">The limitations and strengths of study design and
                  follow-through were discussed in the 2-year DelPhi article<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">24</span></a>. At
                  5 years, the main limitation was the loss to follow-up, which can introduce bias, reduce statistical
                  power, and thereby reduce the precision and generalizability of the study. To prevent this, we made
                  reasonable attempts to contact patients who had been lost to follow-up, and telephone interviews were
                  conducted with 6 suitable patients. A sensitivity analysis of the primary outcome using a linear mixed
                  model showed comparable results. Also, the consistency of the current results with the 2-year results,
                  even at the subgroup level, speaks to the validity of the current results.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_55" style="position: unset;">
                <div class="content" style="position: unset;">At 5 years, the rate of loss to follow-up was distinctly
                  higher in the ORIF group (33 of 60) than in the RTSA group (20 of 64). This difference could reflect a
                  higher baseline comorbidity in the ORIF group, but this is not the case when we look at the random
                  baseline differences of the 2 treatment groups. The RTSA group included more patients with diabetes
                  than the ORIF group (8 in the RTSA group, compared with 1 in the ORIF group), the ORIF group included
                  more smokers (2 in the RTSA group, compared with 4 in the ORIF group), the RTSA group had a greater
                  proportion of patients with type-C2 fractures (60% in the RTSA group, compared with 52% in the ORIF
                  group), and the ORIF group had a greater proportion of patients who sustained a fall outdoors (40% in
                  the RTSA group, compared with 60% in the ORIF group). Besides the significant differences in primary
                  outcome results, the higher rate of adverse events, higher mortality rate, and higher loss to
                  follow-up further suggest that the ORIF group fared worse than the RTSA group. The higher loss to
                  follow-up in the ORIF group may have been due to a less-functional arm, loss of self-reliance, or
                  institutionalization.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_56" style="position: unset;">
                <div class="content" style="position: unset;">The presented 5-year results of the DelPhi trial are
                  generally consistent with our previously published 2-year results. The patients in the RTSA group
                  continued to score consistently better than those in the ORIF group, and the major difference in favor
                  of RTSA was observed among patients with intra-articular type-C2 proximal humeral fractures. The most
                  frequently occurring adverse events in the RTSA group between 2 and 5 years were periprosthetic
                  fractures due to a subsequent fall, suggesting that these patients may profit from a multidisciplinary
                  orthogeriatric approach to address frailty issues<a href="" data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">39</span></a>.
                  The overall rate of adverse events and secondary surgical procedures over 5 years was higher in the
                  ORIF group compared with the RTSA group. Elderly patients with displaced proximal humeral fractures
                  considered for operative treatment benefited more from RTSA than from ORIF with a locking plate, and
                  patients with the most severe fractures showed the most benefit from treatment with RTSA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_57" style="position: unset;">
                <div class="content" style="position: unset;">Reverse shoulder arthroplasty was superior to plate
                  fixation for the treatment of displaced AO/OTA type-B2 and C2 fractures in elderly patients at 5
                  years. Patients in the RTSA group had better Constant scores and Oxford Shoulder Scores and had fewer
                  adverse events. Patients 65 to 74 years of age and patients with type-C2 fractures benefited the most
                  from RTSA treatment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_58" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_4" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I189" target="_blank" data-id="link_5" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I189</a>)</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">Note: The authors thank Jonas Bjørdal, MD (Kalnes
                  Hospital), Odd Arve Lien MD (Bærum Hospital), and Konrad Mader, MD, PhD (Førde Hospital, University
                  Medical Center Hamburg-Eppendorf), for their participation in the trial. They thank Marianne Lund
                  Eriksen, MD (Diakonhjemmet Hospital) and Kirsten Maria Grønhaug, MD, PhD (Kalnes Hospital) for their
                  contribution to the trial. They thank physiotherapists Merethe Giske Fahs, Gitte Fredriksen, Hege
                  Hansen, Øygunn Juklestad, Tone Kalheim, Anne-Gunn Kallum, Inger Kaltenborn, Silje Karlsen, Maria
                  Liljeholm, Kjetil Loen, Hanne Marie Mølmen, Kirsten F. Nielsen, Liv Marie Odland, Haakon Skuggedal,
                  Ingvild Solen, Vibeke Bergsvik Stavang, Inger Storrønning, Yvonne Vallner, Anette Ruud Øverby, Marte
                  Iversen Aaser, and Jørund Myhrberg. The Oxford Shoulder Score was used by arrangement with Oxford
                  University Innovation, Limited (Project 3240).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_23" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_50" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_59" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Constant Scores</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 2319px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Category</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">RTSA
                          Group</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">ORIF
                          Group</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          Difference (95% CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pain (15 points)</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 12 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.3 (−1.1,
                          1.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 24 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0 (−0.3,
                          2.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.12</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 60 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.7
                          (−0.07, 3.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Activities of daily
                          living</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Work (4 points)</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  12 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.1 (−0.3,
                          0.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.73</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  24 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.3 (−0.1,
                          0.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.14</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  60 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.3 (−0.1,
                          0.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.13</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Recreation (4
                          points)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  12 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.2 (−0.2,
                          0.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.44</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  24 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.2 (−0.2,
                          0.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.32</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  60 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.4
                          (−0.02, 0.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Sleep (2 points)</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  12 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.0 (−0.1,
                          0.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.69</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  24 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.1
                          (−0.002, 0.27)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.053</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  60 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.3 (0.04,
                          0.48)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Movement (10
                          points)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  12 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.4 (0.4,
                          2.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.004</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  24 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0 (1.1,
                          2.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  60 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.1 (1.1,
                          3.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Range of motion</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Flexion (10
                          points)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  12 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,0 (0.9,
                          2.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  24 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.8 (0.9,
                          2.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  60 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.4 (1.3,
                          3.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Abduction (10
                          points)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  12 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.8 (0.8,
                          2.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  24 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0 (1.1,
                          3.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  60 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0 (1.0,
                          3.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Internal rotation (10
                          points)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  12 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.8
                          (−1.8, 0.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.17</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  24 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.2 (−1.1,
                          1.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.85</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  60 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6 (−1.0,
                          2.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.45</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> External rotation (10
                          points)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  12 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0 (0.8,
                          3.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.002</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  24 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.6 (1.3,
                          3.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  60 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.1 (1.5,
                          4.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Strength (25
                          points)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 12 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.9 (0.1,
                          5.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.045</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 24 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0 (0.05,
                          5.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.046</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 60 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.4 (−2.2,
                          4.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.45</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total score</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 12 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">62.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">54.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.5 (0.5,
                          16.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.037</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 24 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">68.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">54.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.4 (6.2,
                          20.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 60 mo</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">71.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">58.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.4 (5.2,
                          21.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.002</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_24" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_51" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_60" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Constant and Oxford Shoulder Scores at 5 Years<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 559.784px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.911357);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="3" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="5" style="position: unset;"><span class="text"
                          style="position: unset;">Constant Score</span></th>
                      <th align="center" rowspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Oxford Shoulder Score</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Total</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Fracture Type</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Age</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Type-B2
                          Fracture</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Type-C2
                          Fracture</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">65-74
                          Years of Age</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">75-85
                          Years of Age</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RTSA group</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71.7
                          (67.1, 76.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68.85
                          (60.6, 77.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73.3
                          (67.5, 79.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74.0
                          (67.8, 80.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68.9
                          (61.7, 76.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43.0
                          (41.3, 44.8)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ORIF group</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58.3
                          (50.6, 65.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60.8
                          (46.1, 75.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56.0
                          (47.4, 64.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58.3
                          (48.6, 60.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58.1
                          (42.5, 73.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.0
                          (34.8, 41.3)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean difference</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.5 (5.2,
                          21.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.0 (−7.3,
                          23.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.3 (7.5,
                          27.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.8 (4.9,
                          26.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.8
                          (−3.0, 24.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0 (1.7,
                          8.3)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">P value<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.002</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.29</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.006</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.12</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.003</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">P value<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.031</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.108</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean, with the 95% CI in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Independent-samples t test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Linear mixed model.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_25" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_52" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_61" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Adverse Events</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1581.34px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.942693);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">RTSA Group</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">ORIF Group</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">0-2 Yr (N
                          = 64)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">2-5 Yr (N
                          = 39)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">0-5 Yr
                          Cumulative</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">0-2 Yr (N
                          = 60)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">2-5 Yr (N
                          = 26)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">0-5 Yr
                          Cumulative</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of patients with
                          adverse events</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of adverse
                          events</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Type of adverse
                          event</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nerve injury
                          (transient)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Screw penetration with
                          or without osteonecrosis</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Deep wound
                          infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Periprosthetic fracture
                          or fracture distal to plate</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nonunion</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Glenohumeral
                          dislocation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Implant loosening</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Revision surgery</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Changing of plastic
                          insert</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Conversion from plate to
                          RTSA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hardware removal or
                          refixation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other revision
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Court-Brown CM</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Garg A</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> McQueen MM</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">The epidemiology of proximal
                  humeral fractures</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Acta Orthop Scand.</span> 2001 Aug;72(4):365-71.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20epidemiology%20of%20proximal%20humeral%20fractures&as_occt=title&as_sauthors=%20%22CM%20Court-Brown%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Launonen AP</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Lepola V</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Saranko A</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Flinkkilä T</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Laitinen M</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Mattila VM</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Epidemiology of proximal humerus
                  fractures</span>. <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Arch
                  Osteoporos.</span> 2015;10:209.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Epidemiology%20of%20proximal%20humerus%20fractures&as_occt=title&as_sauthors=%20%22AP%20Launonen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Brorson S</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Viberg B</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Gundtoft P</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Jalal B</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Ohrt-Nissen S</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Epidemiology and trends in
                  management of acute proximal humeral fractures in adults: an observational study of 137,436 cases from
                  the Danish National Patient Register, 1996-2018</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Acta Orthop.</span> 2022 Sep
                20;93:750-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Epidemiology%20and%20trends%20in%20management%20of%20acute%20proximal%20humeral%20fractures%20in%20adults%3A%20an%20observational%20study%20of%20137%2C436%20cases%20from%20the%20Danish%20National%20Patient%20Register%2C%201996-2018&as_occt=title&as_sauthors=%20%22S%20Brorson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Koukakis A</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Apostolou CD</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Taneja T</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Korres DS</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Amini A</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Fixation of proximal humerus
                  fractures using the PHILOS plate: early experience</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2006
                Jan;442(442):115-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fixation%20of%20proximal%20humerus%20fractures%20using%20the%20PHILOS%20plate%3A%20early%20experience&as_occt=title&as_sauthors=%20%22A%20Koukakis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Björkenheim JM</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Pajarinen J</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Savolainen V</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Internal fixation of proximal
                  humeral fractures with a locking compression plate: a retrospective evaluation of 72 patients followed
                  for a minimum of 1 year</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Acta Orthop Scand.</span> 2004 Dec;75(6):741-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Internal%20fixation%20of%20proximal%20humeral%20fractures%20with%20a%20locking%20compression%20plate%3A%20a%20retrospective%20evaluation%20of%2072%20patients%20followed%20for%20a%20minimum%20of%201%20year&as_occt=title&as_sauthors=%20%22JM%20Bj%C3%B6rkenheim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Brorson S</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Locking plate osteosynthesis in
                  geriatric shoulder fractures: why do we continue to perform a low-value procedure?</span><span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Acta Orthop.</span> 2022 Feb
                24;93:355-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Locking%20plate%20osteosynthesis%20in%20geriatric%20shoulder%20fractures%3A%20why%20do%20we%20continue%20to%20perform%20a%20low-value%20procedure%3F&as_occt=title&as_sauthors=%20%22S%20Brorson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Launonen AP</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Sumrein BO</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Reito A</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Lepola V</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Paloneva J</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Jonsson KB</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Wolf O</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Ström P</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Berg HE</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Felländer-Tsai L</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Jansson KÅ</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Fell D</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Mechlenburg I</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Døssing K</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Østergaard H</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Märtson A</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Laitinen MK</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Mattila VM</span>; as the NITEP
                group. <span data-id="strong_7" class="annotation strong" style="position: unset;">Operative versus
                  non-operative treatment for 2-part proximal humerus fracture: A multicenter randomized controlled
                  trial</span>. <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">PLoS
                  Med.</span> 2019 Jul 18;16(7):e1002855.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Operative%20versus%20non-operative%20treatment%20for%202-part%20proximal%20humerus%20fracture%3A%20A%20multicenter%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22AP%20Launonen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Barlow JD</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Logli AL</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Steinmann SP</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Sems SA</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Cross WW</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Yuan BJ</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Torchia ME</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Sanchez-Sotelo J</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Locking plate fixation of
                  proximal humerus fractures in patients older than 60 years continues to be associated with a high
                  complication rate</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2020 Aug;29(8):1689-94.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Locking%20plate%20fixation%20of%20proximal%20humerus%20fractures%20in%20patients%20older%20than%2060%20years%20continues%20to%20be%20associated%20with%20a%20high%20complication%20rate&as_occt=title&as_sauthors=%20%22JD%20Barlow%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Handoll HH</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Elliott J</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Thillemann TM</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Aluko P</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Brorson S</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Interventions for treating
                  proximal humeral fractures in adults</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Cochrane Database Syst Rev.</span> 2022 Jun 21;6(6):CD000434.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Interventions%20for%20treating%20proximal%20humeral%20fractures%20in%20adults&as_occt=title&as_sauthors=%20%22HH%20Handoll%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Neer CS</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Indications for replacement of
                  the proximal humeral articulation</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Am J Surg.</span> 1955 Apr;89(4):901-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Indications%20for%20replacement%20of%20the%20proximal%20humeral%20articulation&as_occt=title&as_sauthors=%20%22CS%20Neer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Neer CS</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Displaced proximal humeral
                  fractures. II. Treatment of three-part and four-part displacement</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 1970
                Sep;52(6):1090-103.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=378564"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Olerud P</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Ahrengart L</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Ponzer S</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Saving J</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Tidermark J</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Hemiarthroplasty versus
                  nonoperative treatment of displaced 4-part proximal humeral fractures in elderly patients: a
                  randomized controlled trial</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2011 Oct;20(7):1025-33.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hemiarthroplasty%20versus%20nonoperative%20treatment%20of%20displaced%204-part%20proximal%20humeral%20fractures%20in%20elderly%20patients%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22P%20Olerud%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Spross C</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Platz A</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Erschbamer M</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Lattmann T</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Dietrich M</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Surgical treatment of Neer
                  Group VI proximal humeral fractures: retrospective comparison of PHILOS® and hemiarthroplasty</span>.
                <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2012 Jul;470(7):2035-42.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20treatment%20of%20Neer%20Group%20VI%20proximal%20humeral%20fractures%3A%20retrospective%20comparison%20of%20PHILOS%C2%AE%20and%20hemiarthroplasty&as_occt=title&as_sauthors=%20%22C%20Spross%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Boons HW</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Goosen JH</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> van Grinsven S</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> van Susante JL</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> van Loon CJ</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Hemiarthroplasty for humeral
                  four-part fractures for patients 65 years and older: a randomized controlled trial</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2012 Dec;470(12):3483-91.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hemiarthroplasty%20for%20humeral%20four-part%20fractures%20for%20patients%2065%20years%20and%20older%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22HW%20Boons%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;">European
                Health Information Portal. <span data-id="strong_15" class="annotation strong"
                  style="position: unset;">Norwegian Arthroplasty Register</span>. Accessed 2024 Jun 27. <a
                  href="https://helsedata.no/en/forvaltere/bergen-hospital-trust/norwegian-arthroplasty-register/"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://helsedata.no/en/forvaltere/bergen-hospital-trust/norwegian-arthroplasty-register/</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Norwegian%20Arthroplasty%20Register&as_occt=title&as_sauthors=%20%22European%20Health%20Information%20Portal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;">Australian
                Orthopaedic Association National Joint Replacement Registry. <span data-id="strong_16"
                  class="annotation strong" style="position: unset;">Welcome to AOANJRR</span>. Accessed 2024 Jun 27. <a
                  href="https://aoanjrr.sahmri.com" target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://aoanjrr.sahmri.com</a>.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Welcome%20to%20AOANJRR&as_occt=title&as_sauthors=%20%22Australian%20Orthopaedic%20Association%20National%20Joint%20Replacement%20Registry%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Jonsson EO</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Ekholm C</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Salomonsson B</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Demir Y</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Olerud P</span>; Collaborators in
                the SAPF Study Group. <span data-id="strong_17" class="annotation strong"
                  style="position: unset;">Reverse total shoulder arthroplasty provides better shoulder function than
                  hemiarthroplasty for displaced 3- and 4-part proximal humeral fractures in patients aged 70 years or
                  older: a multicenter randomized controlled trial</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2021
                May;30(5):994-1006.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reverse%20total%20shoulder%20arthroplasty%20provides%20better%20shoulder%20function%20than%20hemiarthroplasty%20for%20displaced%203-%20and%204-part%20proximal%20humeral%20fractures%20in%20patients%20aged%2070%20years%20or%20older%3A%20a%20multicenter%20randomized%20controlled%20trial&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Critchley O</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> McLean A</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Page R</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Taylor F</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Graves S</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Lorimer M</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Peng Y</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Hatton A</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Bain G</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Reverse total shoulder
                  arthroplasty compared to stemmed hemiarthroplasty for proximal humeral fractures: a registry analysis
                  of 5946 patients</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2020 Dec;29(12):2538-47.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reverse%20total%20shoulder%20arthroplasty%20compared%20to%20stemmed%20hemiarthroplasty%20for%20proximal%20humeral%20fractures%3A%20a%20registry%20analysis%20of%205946%20patients&as_occt=title&as_sauthors=%20%22O%20Critchley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Sebastiá-Forcada E</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Cebrián-Gómez R</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Lizaur-Utrilla A</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Gil-Guillén V</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Reverse shoulder arthroplasty
                  versus hemiarthroplasty for acute proximal humeral fractures. A blinded, randomized, controlled,
                  prospective study</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2014 Oct;23(10):1419-26.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reverse%20shoulder%20arthroplasty%20versus%20hemiarthroplasty%20for%20acute%20proximal%20humeral%20fractures.%20A%20blinded%2C%20randomized%2C%20controlled%2C%20prospective%20study&as_occt=title&as_sauthors=%20%22E%20Sebasti%C3%A1-Forcada%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Cuff DJ</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Pupello DR</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Comparison of hemiarthroplasty
                  and reverse shoulder arthroplasty for the treatment of proximal humeral fractures in elderly
                  patients</span>. <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2013 Nov 20;95(22):2050-5.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1180016" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Ross M</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Hope B</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Stokes A</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Peters SE</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> McLeod I</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Duke PF</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Reverse shoulder arthroplasty
                  for the treatment of three-part and four-part proximal humeral fractures in the elderly</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2015 Feb;24(2):215-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reverse%20shoulder%20arthroplasty%20for%20the%20treatment%20of%20three-part%20and%20four-part%20proximal%20humeral%20fractures%20in%20the%20elderly&as_occt=title&as_sauthors=%20%22M%20Ross%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Chalmers PN</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Slikker W</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Mall NA</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Gupta AK</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Rahman Z</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Enriquez D</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Nicholson GP</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Reverse total shoulder
                  arthroplasty for acute proximal humeral fracture: comparison to open reduction-internal fixation and
                  hemiarthroplasty</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2014 Feb;23(2):197-204.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reverse%20total%20shoulder%20arthroplasty%20for%20acute%20proximal%20humeral%20fracture%3A%20comparison%20to%20open%20reduction-internal%20fixation%20and%20hemiarthroplasty&as_occt=title&as_sauthors=%20%22PN%20Chalmers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Cazeneuve JF</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Cristofari DJ</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Grammont reversed prosthesis
                  for acute complex fracture of the proximal humerus in an elderly population with 5 to 12 years
                  follow-up</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Orthop Traumatol Surg Res.</span> 2014 Feb;100(1):93-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Grammont%20reversed%20prosthesis%20for%20acute%20complex%20fracture%20of%20the%20proximal%20humerus%20in%20an%20elderly%20population%20with%205%20to%2012%20years%20follow-up&as_occt=title&as_sauthors=%20%22JF%20Cazeneuve%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Fraser AN</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Bjørdal J</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Wagle TM</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Karlberg AC</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Lien OA</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Eilertsen L</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Mader K</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Apold H</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Larsen LB</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Madsen JE</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Fjalestad T</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Reverse Shoulder Arthroplasty
                  Is Superior to Plate Fixation at 2 Years for Displaced Proximal Humeral Fractures in the Elderly: A
                  Multicenter Randomized Controlled Trial</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2020 Mar
                18;102(6):477-85.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2312011"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Nguyen MP</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">CORR Insights(R): Reverse Total
                  Shoulder Arthroplasty Demonstrates Better Outcomes Than Angular Stable Plate in the Treatment of
                  Three-part and Four-part Proximal Humerus Fractures in Patients Older Than 70 Years</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2022;481(4):748-750.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=CORR%20Insights(R)%3A%20Reverse%20Total%20Shoulder%20Arthroplasty%20Demonstrates%20Better%20Outcomes%20Than%20Angular%20Stable%20Plate%20in%20the%20Treatment%20of%20Three-part%20and%20Four-part%20Proximal%20Humerus%20Fractures%20in%20Patients%20Older%20Than%2070%20Years&as_occt=title&as_sauthors=%20%22MP%20Nguyen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Constant CR</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Murley AH</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">A clinical method of functional
                  assessment of the shoulder</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 1987 Jan;(214):160-4.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20clinical%20method%20of%20functional%20assessment%20of%20the%20shoulder&as_occt=title&as_sauthors=%20%22CR%20Constant%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Constant CR</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Gerber C</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Emery RJ</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Søjbjerg JO</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Gohlke F</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Boileau P</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">A review of the Constant score:
                  modifications and guidelines for its use</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2008
                Mar-Apr;17(2):355-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20review%20of%20the%20Constant%20score%3A%20modifications%20and%20guidelines%20for%20its%20use&as_occt=title&as_sauthors=%20%22CR%20Constant%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Dawson J</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Fitzpatrick R</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Carr A</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Questionnaire on the
                  perceptions of patients about shoulder surgery</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 1996
                Jul;78(4):593-600.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Questionnaire%20on%20the%20perceptions%20of%20patients%20about%20shoulder%20surgery&as_occt=title&as_sauthors=%20%22J%20Dawson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Dawson J</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Rogers K</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Fitzpatrick R</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Carr A</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">The Oxford shoulder score
                  revisited</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">Arch Orthop Trauma Surg.</span> 2009 Jan;129(1):119-23.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Oxford%20shoulder%20score%20revisited&as_occt=title&as_sauthors=%20%22J%20Dawson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Sintonen H</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">The 15D instrument of
                  health-related quality of life: properties and applications</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">Ann Med.</span> 2001
                Jul;33(5):328-36.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%2015D%20instrument%20of%20health-related%20quality%20of%20life%3A%20properties%20and%20applications&as_occt=title&as_sauthors=%20%22H%20Sintonen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Ziegler P</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Stierand K</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Bahrs C</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Ahrend MD</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Mid-term results after proximal
                  humeral fractures following angular stable plate fixation in elderly patients-which scores can be
                  evaluated by a telephone-based assessment?</span><span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">J Orthop Surg Res.</span> 2020 Jan
                6;15(1):6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mid-term%20results%20after%20proximal%20humeral%20fractures%20following%20angular%20stable%20plate%20fixation%20in%20elderly%20patients-which%20scores%20can%20be%20evaluated%20by%20a%20telephone-based%20assessment%3F&as_occt=title&as_sauthors=%20%22P%20Ziegler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Sirveaux F</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Favard L</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Oudet D</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Huquet D</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Walch G</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Molé D</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Grammont inverted total
                  shoulder arthroplasty in the treatment of glenohumeral osteoarthritis with massive rupture of the
                  cuff. Results of a multicentre study of 80 shoulders</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 2004
                Apr;86(3):388-95.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Grammont%20inverted%20total%20shoulder%20arthroplasty%20in%20the%20treatment%20of%20glenohumeral%20osteoarthritis%20with%20massive%20rupture%20of%20the%20cuff.%20Results%20of%20a%20multicentre%20study%20of%2080%20shoulders&as_occt=title&as_sauthors=%20%22F%20Sirveaux%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Fjalestad T</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Hole MO</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Displaced proximal humeral
                  fractures: operative versus non-operative treatment—a 2-year extension of a randomized controlled
                  trial</span>. <span data-id="emphasis_31" class="annotation emphasis" style="position: unset;">Eur J
                  Orthop Surg Traumatol.</span> 2014 Oct;24(7):1067-73.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Displaced%20proximal%20humeral%20fractures%3A%20operative%20versus%20non-operative%20treatment%E2%80%94a%202-year%20extension%20of%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22T%20Fjalestad%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Fjalestad T</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Iversen P</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Hole MO</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Smedsrud M</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Madsen JE</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Clinical investigation for
                  displaced proximal humeral fractures in the elderly: a randomized study of two surgical treatments:
                  reverse total prosthetic replacement versus angular stable plate Philos (The DELPHI-trial)</span>.
                <span data-id="emphasis_32" class="annotation emphasis" style="position: unset;">BMC Musculoskelet
                  Disord.</span> 2014 Sep 28;15:323.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20investigation%20for%20displaced%20proximal%20humeral%20fractures%20in%20the%20elderly%3A%20a%20randomized%20study%20of%20two%20surgical%20treatments%3A%20reverse%20total%20prosthetic%20replacement%20versus%20angular%20stable%20plate%20Philos%20(The%20DELPHI-trial)&as_occt=title&as_sauthors=%20%22T%20Fjalestad%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Bjørdal J</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Fraser AN</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Wagle TM</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Kleven L</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Lien OA</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Eilertsen L</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Mader K</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Apold H</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Larsen LB</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Madsen JE</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Fjalestad T</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">A cost-effectiveness analysis
                  of reverse total shoulder arthroplasty compared with locking plates in the management of displaced
                  proximal humerus fractures in the elderly: the DelPhi trial</span>. <span data-id="emphasis_33"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2022
                Oct;31(10):2187-95.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20cost-effectiveness%20analysis%20of%20reverse%20total%20shoulder%20arthroplasty%20compared%20with%20locking%20plates%20in%20the%20management%20of%20displaced%20proximal%20humerus%20fractures%20in%20the%20elderly%3A%20the%20DelPhi%20trial&as_occt=title&as_sauthors=%20%22J%20Bj%C3%B8rdal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Fjalestad T</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Hole MO</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Hovden IA</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Blücher J</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Strømsøe K</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Surgical treatment with an
                  angular stable plate for complex displaced proximal humeral fractures in elderly patients: a
                  randomized controlled trial</span>. <span data-id="emphasis_34" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2012 Feb;26(2):98-106.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20treatment%20with%20an%20angular%20stable%20plate%20for%20complex%20displaced%20proximal%20humeral%20fractures%20in%20elderly%20patients%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22T%20Fjalestad%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Olerud P</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Ahrengart L</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Ponzer S</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Saving J</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Tidermark J</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Internal fixation versus
                  nonoperative treatment of displaced 3-part proximal humeral fractures in elderly patients: a
                  randomized controlled trial</span>. <span data-id="emphasis_35" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2011 Jul;20(5):747-55.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Internal%20fixation%20versus%20nonoperative%20treatment%20of%20displaced%203-part%20proximal%20humeral%20fractures%20in%20elderly%20patients%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22P%20Olerud%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_173" class="annotation" style="position: unset;"> EuroQol G</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">EuroQol–a new facility for the
                  measurement of health-related quality of life</span>. <span data-id="emphasis_36"
                  class="annotation emphasis" style="position: unset;">Health Policy.</span>
                1990;16(3):199-208.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=EuroQol%E2%80%93a%20new%20facility%20for%20the%20measurement%20of%20health-related%20quality%20of%20life&as_occt=title&as_sauthors=%20%22G%20EuroQol%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Sumrein BO</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Berg HE</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Launonen AP</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Landell P</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Laitinen MK</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Felländer-Tsai L</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Mattila VM</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Huttunen TT</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Mortality following proximal
                  humerus fracture-a nationwide register study of 147,692 fracture patients in Sweden</span>. <span
                  data-id="emphasis_37" class="annotation emphasis" style="position: unset;">Osteoporos Int.</span> 2023
                Feb;34(2):349-56.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mortality%20following%20proximal%20humerus%20fracture-a%20nationwide%20register%20study%20of%20147%2C692%20fracture%20patients%20in%20Sweden&as_occt=title&as_sauthors=%20%22BO%20Sumrein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Marsh JL</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Slongo TF</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Agel J</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Broderick JS</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Creevey W</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> DeCoster TA</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Prokuski L</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Sirkin MS</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Ziran B</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Henley B</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Audige ́ L</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Fracture and dislocation
                  classification compendium - 2007: Orthopaedic Trauma Association classification, database and outcomes
                  committee</span>. <span data-id="emphasis_38" class="annotation emphasis" style="position: unset;">J
                  Orthop Trauma.</span> 2007 Nov-Dec;21(10):S1-133.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fracture%20and%20dislocation%20classification%20compendium%20-%202007%3A%20Orthopaedic%20Trauma%20Association%20classification%2C%20database%20and%20outcomes%20committee&as_occt=title&as_sauthors=%20%22JL%20Marsh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Valenti PH</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Boutens D</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Nérot C</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Delta 3 reversed prosthesis for
                  osteoarthritis with massive rotator cuff tear: long term results</span>. In: <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Walch G</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Boileau P</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Molé D</span>, eds. <span
                  data-id="emphasis_39" class="annotation emphasis" style="position: unset;">2000 Shoulder Prostheses.
                  Two to Ten Years Follow-up.</span> Paris, France: Sauramps Médical; 2001:253-259.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Delta%203%20reversed%20prosthesis%20for%20osteoarthritis%20with%20massive%20rotator%20cuff%20tear%3A%20long%20term%20results&as_occt=title&as_sauthors=%20%22PH%20Valenti%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="infographic_reference" class="annotation infographic_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node infographic" data-id="infographic" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Infographic</div><a class="link jbjs_tracking" jbjs_tracking_type="download"
                jbjs_tracking_data="{&quot;id&quot;:&quot;1e30254d-1373-480c-a87c-953d5824e911&quot;,&quot;type&quot;:&quot;infographic&quot;,&quot;topics&quot;:[&quot;sh eb ta&quot;]}"
                href="/php/content/content_api.php?op=download&rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=infographic"
                jbjs_tracking_source="reader" style="position: unset;">Download</a>
              <div class="infographic-wrapper" style="position: unset;">
                <div class="viewer" style="position: unset;">
                  <div class="progress-bar" style="position: unset; width: 100%; display: none;"></div>
                  <div class="pages" style="position: unset;"><canvas class="page" width="337" height="189"></canvas>
                  </div>
                  <div class="toolbar" style="position: unset;"><button id="zoom-out" class=""
                      style="position: unset;">-</button><button id="zoom-in" class=""
                      style="position: unset;">+</button><button id="full-screen" class="" style="position: unset;">Full
                      screen ON</button></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;1e30254d-1373-480c-a87c-953d5824e911&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[&quot;sh eb ta&quot;]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=supplement&name=10537"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D1e30254d-1373-480c-a87c-953d5824e911%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F21%2F1945%26topics%3Dsh%2Beb%2Bta"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 6, 2024;
                      106 (21): 1945</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01431</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 20, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node footnote article-note" data-id="fn_1" style="position: unset;">
                    <div class="content article-note" style="position: unset;"><span class="label">*</span><span
                        class="note" style="position: unset;">
                        <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                          <div class="content" style="position: unset;">
                            <div class="content-node text" data-id="text_3" style="position: unset;">
                              <div class="content" style="position: unset;"><span data-id="emphasis_40"
                                  class="annotation emphasis" style="position: unset;">Original Publication</span></div>
                              <div class="focus-handle" style="position: unset;"></div>
                            </div>
                          </div>
                          <div class="focus-handle" style="position: unset;"></div>
                        </div>
                      </span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_4" style="position: unset;">
                        <div class="content" style="position: unset;">Fraser AN, Bjørdal J, Wagle TM, Karlberg AC, Lien
                          OA, Eilertsen L, Mader K, Apold H, Larsen LB, Madsen JE, Fjalestad T. Reverse Shoulder
                          Arthroplasty Is Superior to Plate Fixation at 2 Years for Displaced Proximal Humeral Fractures
                          in the Elderly. A Multicenter Randomized Controlled Trial. J Bone Joint Surg Am. 2020 Mar
                          18;102(6):477-85.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_4" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_5" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;1e30254d-1373-480c-a87c-953d5824e911&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;sh eb ta&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=pdf&name=JBJS.23.01431.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=pdf&name=JBJS.23.01431.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_5" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_43"
                            class="annotation strong" style="position: unset;">Disclosure:</span> Funding for this study
                          was received from Sophies Minde Ortopedi AS, The South-East Health Authority Norway, and The
                          Research Council of Norway. The funding sources did not play a role in the investigation. The
                          <span data-id="strong_44" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I188" target="_blank" data-id="link_3"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I188</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;1e30254d-1373-480c-a87c-953d5824e911&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;sh eb ta&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=1e30254d-1373-480c-a87c-953d5824e911&type=zip&name=JBJS.23.01431.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alexander Nilsskog Fraser, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:a.n.fraser@medisin.uio.no" class=""
                    style="position: unset;">a.n.fraser@medisin.uio.no</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3258-3365" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3258-3365</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tone Mehlum Wagle, PT<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2575-6819" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2575-6819</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Anna Cecilia Karlberg, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2227-0362" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2227-0362</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jan Erik Madsen, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9002-1213" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9002-1213</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Megan Mellberg, MD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tom Lian, MD<span data-id="affiliation_reference_7"
                  class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0087-5598" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0087-5598</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Simone Mader, MD<span
                  data-id="affiliation_reference_8" class="label annotation cross_reference">8</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0006-2569-1831" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0006-2569-1831</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lars Eilertsen, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3022-7858" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3022-7858</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hilde Apold, MD, PhD<span
                  data-id="affiliation_reference_9" class="label annotation cross_reference">9</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9227-2959" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9227-2959</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Leif Børge Larsen, MD<span
                  data-id="affiliation_reference_10" class="label annotation cross_reference">10</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3853-5791" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3853-5791</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Are Hugo Pripp, PhD<span
                  data-id="affiliation_reference_11" class="label annotation cross_reference">11</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1678-7309" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1678-7309</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tore Fjalestad, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4995-9891" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4995-9891</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Division of Orthopaedic Surgery, Oslo University Hospital, Oslo, Norway</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Diakonhjemmet Hospital, Oslo, Norway</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Center for Treatment of Rheumatic and Musculoskeletal Diseases (REMEDY),
                Diakonhjemmet Hospital, Oslo, Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Division of Radiology and Nuclear Medicine, Department of Musculoskeletal
                Radiology, Oslo University Hospital, Oslo, Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Institute of Clinical Medicine, University of Oslo, Oslo, Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Østfold Hospital Trust, Grålum,
                Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Vestre Viken Hospital Trust, Bærum,
                Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">8</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Førde Hospital Trust, Førde, Norway</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">9</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Telemark Hospital Trust, Skien,
                Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">10</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Vestfold Hospital Trust, Tønsberg,
                Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">11</span><span class="text"
                style="position: unset;">Oslo Centre for Biostatistics & Epidemiology, Oslo University Hospital,
                Oslo Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 41474.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
