import type { Article } from "../../../../../types";

export const Article529: Article = {
	id: 529,
	rsuiteId: "0bb297e3-3c34-458b-9ec2-dd7148219f5d",
	type: "scientific article",
	title:
		"Association Between Periprosthetic Joint Infection and Mortality Following Primary Total Hip Arthroplasty",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0bb297e3-3c34-458b-9ec2-dd7148219f5d&type=jpeg&name=JBJS.23.01160f1",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Association Between Periprosthetic Joint Infection and
                Mortality Following Primary Total Hip Arthroplasty</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Raman Mundi,
                      MD, MSc, FRCSC</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Daniel
                      Pincus, MD, PhD, FRCSC</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 7 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_40" class="annotation strong"
                          style="position: unset;">Background:</span> Periprosthetic joint infection (PJI) remains a
                        dreaded and unpredictable complication after total hip arthroplasty (THA). In addition to
                        causing substantial morbidity, PJI may contribute to long-term mortality risk. Our objective was
                        to determine the long-term mortality risk associated with PJI following THA.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_41" class="annotation strong"
                          style="position: unset;">Methods:</span> This population-based, retrospective cohort study
                        included adult patients (≥18 years old) in Ontario, Canada, who underwent their first primary
                        elective THA for arthritis between April 1, 2002, and March 31, 2021. The primary outcome was
                        death within 10 years after the index THA. Mortality was compared between
                        propensity-score-matched groups (PJI within 1 year after surgery versus no PJI within 1 year
                        after surgery) with use of survival analyses. Patients who died within 1 year after surgery were
                        excluded to avoid immortal time bias.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_42" class="annotation strong"
                          style="position: unset;">Results:</span> A total of 175,432 patients (95,883 [54.7%] women)
                        with a mean age (and standard deviation) of 67 ± 11.4 years underwent primary THA during the
                        study period. Of these, 868 patients (0.49%) underwent surgery for a PJI of the replaced joint
                        within 1 year after the index procedure. After matching, patients with a PJI within the first
                        year had a significantly higher 10-year mortality rate than their counterparts (11.4% [94 of 827
                        patients] versus 2.2% [18 of 827 patients]; absolute risk difference, 9.19% [95% confidence
                        interval (CI), 6.81% to 11.6%]; hazard ratio, 5.49 [95% CI, 3.32 to 9.09]).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_43" class="annotation strong"
                          style="position: unset;">Conclusions:</span> PJI within 1 year after surgery is associated
                        with over a fivefold increased risk of mortality within 10 years. The findings of this study
                        underscore the importance of prioritizing efforts related to the prevention, diagnosis, and
                        treatment of PJIs.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_44" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Over 1 million total hip arthroplasty (THA) and total knee
                  arthroplasty (TKA) procedures are performed annually in the United States<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">3</span></a>.
                  Joint replacement procedures have a long track record of success in alleviating pain and improving the
                  quality of life for patients with end-stage arthritis<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">6</span></a>. THA specifically has been heralded as the “operation of the
                  [20th] century.”<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">7</span></a> Given
                  the growing burden of osteoarthritis and an aging population, the number of THAs in the U.S. alone is
                  expected to surpass 600,000 annually by the year 2030<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">9</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Despite the success of joint replacement procedures,
                  periprosthetic joint infection (PJI) remains the most dreaded and unpredictable complication
                  associated with arthroplasty. The rate of PJI following THA has been estimated to be 1% to 2%
                  worldwide<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">10</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">13</span></a>. PJI management requires extensive revision procedures,
                  prolonged antibiotic courses, extended hospital admissions, and challenging rehabilitation regimens<a
                    href="" data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">14</span></a>.
                  Even with such aggressive management, cure rates are generally only 65% to 80%<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">16</span></a>. For
                  patients in whom PJI is cured, some degree of chronic morbidity often persists, and these patients
                  rarely demonstrate functional outcomes that are equivalent to those of patients without PJI<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">18</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Given the failure to eradicate PJI in many cases and the
                  likelihood of morbidity even if the infection is cured, we hypothesized that PJI would be associated
                  with a potentially significant impact on long-term survival following THA. This association has been
                  suggested in prior studies; however, these studies were limited by small sample sizes and poor control
                  of confounders such as comorbidities that have been linked to both PJI and mortality<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">19</span></a>. The
                  objective of the present study was to delineate the long-term (10-year) mortality risk of PJI
                  following THA, after controlling for relevant confounders. We hypothesized that PJI would be
                  associated with a significantly increased risk of mortality.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data Sources
                and Setting</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">This population-based, propensity-score-matched,
                  retrospective cohort study included patients who underwent their first primary elective THA for
                  arthritis in Ontario, the most populous province of Canada. Ontario has solely single-payer health
                  care, and the only avenue for the provision of arthroplasty and the management of associated
                  complications is through the public health-care system. We utilized administrative health-care data
                  from ICES, an independent, not-for-profit health services research institute funded by the Ontario
                  Ministry of Health. Information regarding the physician, hospital care, and demographic
                  characteristics of Ontario residents is routinely recorded in a series of linked databases, which are
                  maintained for the purposes of operating the Ontario Health Insurance Plan (OHIP). We used validated
                  algorithms that were previously utilized to identify patients undergoing THA, covariates, and outcomes
                  in the database<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">20</span></a>.
                  Use of the data in the present study was authorized under section 45 of the Personal Health
                  Information Protection Act of Ontario and did not require review by a research ethics board.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Patients</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The cohort comprised all consecutive adult patients (≥18
                  years old) in Ontario who underwent their first primary elective THA for arthritis between April 1,
                  2002, and March 31, 2021. These patients were identified in the Canadian Institute for Health
                  Information Discharge Abstract Database or Same Day Surgery database with use of the code “1VA53” and
                  linked with a physician billing code (R440 or R553) for the same patient in the OHIP Registered
                  Persons database. To avoid immortal time bias, patients were excluded if they died within 1 year after
                  surgery<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">21</span></a>.
                  All patients were followed until death or the end of the study period.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Main
                Exposure</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The main exposure was the occurrence of a PJI that
                  required surgery (i.e., irrigation and debridement with a liner exchange, single or 2-stage revision,
                  and subsequent use of a peripherally inserted central catheter) within 365 days after the index THA.
                  This case definition has a sensitivity of 92% and a positive predictive value of 78%<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">22</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Covariates</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Comorbidities listed on hospital discharge abstracts
                  within 3 years prior to patient admission for the index THA were categorized according to an
                  adaptation of the Charlson Comorbidity Index<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">23</span></a>.
                  Class-III obesity (a body mass index of &gt;40 kg/m<span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">2</span>) was identified with use of the
                  physician billing code (E676) recorded at the time of surgery. The Johns Hopkins ACG System (version
                  10; The Johns Hopkins University, The Johns Hopkins Hospital, and Johns Hopkins Health System) was
                  utilized to classify patients as “frail.”<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">24</span></a> The
                  Statistics Canada census neighborhood income quintile was utilized as a surrogate for socioeconomic
                  status, with the lowest quintile representing the least affluent<a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">25</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Outcome</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The primary study outcome was all-cause mortality within
                  10 years after the index THA as recorded in the OHIP Registered Persons Database<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">26</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Patients in the cohort were classified according to
                  whether they had a PJI within 1 year after the index THA. Baseline cohort characteristics at the time
                  of the index THA are reported as proportions, as means (with standard deviations [SDs]), or as medians
                  (with interquartile ranges [IQRs]) if the data were skewed. The potential trend in the incidence of
                  PJI over the study period was determined with use of the Cochran-Armitage test for trend. Patients who
                  had a PJI within 1 year after their joint replacement were matched to those who did not have a PJI
                  within 1 year in a 1:1 ratio via greedy matching on the basis of age (±1 year), biological sex
                  (male/female), Class-III obesity, the year of the index surgery, and the logit of a propensity score
                  for the occurrence of a PJI with a caliper of 0.2 times the SD<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">27</span></a>. Variables thought to be potential confounders (i.e.,
                  predictors of both infection and mortality) were entered into the propensity score and included
                  sociodemographic characteristics (age, sex, and income quintile) and health status (comorbidities,
                  Charlson score, frailty, and Class-III obesity). Standardized differences for all covariates were
                  calculated before and after matching, and a difference of ≥10% (≥0.1) was considered indicative of an
                  imbalance<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">28</span></a>.
                  Ten-year mortality was compared between the 2 groups after matching.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The cumulative incidence of mortality events was
                  calculated with use of the Kaplan-Meier method, and the log-rank test was utilized to calculate the p
                  value. Hazard ratios (HRs) for 10-year mortality were calculated with use of a Cox proportional
                  hazards model with robust variance estimation that accounted for pair-matching<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">29</span></a>. The proportionality assumption was tested by graphical
                  methods and was met (see Appendix). All analyses were performed at ICES with use of SAS (version 9.3;
                  SAS Institute). The 2-sided type-I error probability was set to 0.05. Missing data, which accounted
                  for &lt;1% of data for all variables considered, were excluded from the regression models.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Sensitivity
                Analyses</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">We performed 3 additional analyses that were defined a
                  priori. First, we repeated the primary analysis but also matched patients by primary surgeon in
                  addition to age (±1 year), sex (male/female), Class-III obesity, year of surgery, and the logit of a
                  propensity score for the occurrence of PJI with a caliper of 0.2 times the SD. This analysis was
                  performed to capture the intuition that a surgeon may utilize to determine whether a patient is a poor
                  candidate for surgery on the basis of the comorbidities of the patient, which may not be adequately
                  captured retrospectively. This also controlled for differences between surgeons in terms of their
                  thresholds for recommending revision surgery for patients with suspected infections. Second, we
                  repeated the main analysis after excluding all patients &gt;60 years old. Although younger patients
                  may still have multiple comorbidities, one would not expect such patients to die within 10 years if
                  they were appropriate candidates for the elective surgery. Third, we determined whether there was a
                  relationship between the occurrence of infection at another site, specifically pneumonia within 30
                  days after surgery, and mortality within 10 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Characteristics Before Matching (<a href="" data-id="figure_reference_1"
                  class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>)</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">Table I</span>Characteristics of THA Groups by Postoperative PJI
                          Status, Before and After Matching<span data-id="table_footnote_reference_6"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1769.23px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.72949);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Before Matching</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">After Matching</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Infection</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No
                          Infection</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">SMD<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Infection</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No
                          Infection</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">SMD<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of patients</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">868</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">174,564</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">827</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">827</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Demographics</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Age<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_43" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66.70 ±
                          11.31</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66.84 ±
                          11.43</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67.23 ±
                          10.80</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67.23 ±
                          10.78</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Men</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">409
                          (47.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">79,140
                          (45.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.04</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">390
                          (47.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">390
                          (47.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Women</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">459
                          (52.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95,424
                          (54.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.04</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">437
                          (52.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">437
                          (52.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Income quintile<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  1, least
                          affluent</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">176
                          (20.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27,511
                          (15.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.12</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">166
                          (20.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">183
                          (22.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">184
                          (21.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32,987
                          (18.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">170
                          (20.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">172
                          (20.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">161
                          (18.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34,363
                          (19.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.03</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">155
                          (18.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">157
                          (19.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">177
                          (20.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36,332
                          (20.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">172
                          (20.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">165
                          (20.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  5, most affluent</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">167
                          (19.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42,921
                          (24.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.13</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">164
                          (19.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">150
                          (18.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.04</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Comorbidities</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cerebrovascular
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (1.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,254
                          (1.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.04</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (1.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (1.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> COPD</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47
                          (5.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7,211
                          (4.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45
                          (5.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32
                          (3.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.07</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Congestive heart
                          failure</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (2.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,900
                          (1.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.08</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (2.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (1.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.08</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Connective tissue
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (2.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,101
                          (1.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (2.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (1.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diabetes with
                          complications</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58
                          (6.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,628
                          (3.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.13</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56
                          (6.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58
                          (7.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diabetes without
                          complications</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">109
                          (12.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14,036
                          (8.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.15</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97
                          (11.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">104
                          (12.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.03</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Metastatic cancer</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (1.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">797
                          (0.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.08</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.03</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mild liver
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (1.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">763
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.08</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.09</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Moderate or severe liver
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.07</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Myocardial
                          infarction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (2.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,084
                          (2.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.03</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (2.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (2.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Peptic ulcer
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (1.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,994
                          (1.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (1.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (0.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Peripheral vascular
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (1.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,824
                          (1.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (1.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (1.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.04</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Primary cancer</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (4.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,868
                          (3.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.04</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (4.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (3.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Renal disease</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (1.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,937
                          (1.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (1.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (1.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> BMI &gt;40 kg/m<span
                            data-id="superscript_21" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">154
                          (17.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8,923
                          (5.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.41</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">130
                          (15.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">130
                          (15.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Frail</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">105
                          (12.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13,980
                          (8.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.14</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">93
                          (11.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88
                          (10.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Charlson score<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">562
                          (64.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">131,365
                          (75.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.23</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">550
                          (66.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">569
                          (68.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">175
                          (20.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25,009
                          (14.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.15</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">161
                          (19.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">142
                          (17.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69
                          (7.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11,607
                          (6.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.05</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64
                          (7.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70
                          (8.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.03</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  ≥3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62
                          (7.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,583
                          (3.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.15</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52
                          (6.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46
                          (5.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.03</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">BMI = body mass index, SMD = standardized mean difference, COPD =
                        chronic obstructive pulmonary disease, NA = not applicable. Values are given as the number of
                        patients, with the percentage in parentheses, except as noted. Greedy matching was performed in
                        a 1:1 ratio on the basis of age (±1 year), sex (male/female), Class III-obesity at the time of
                        surgery, year of surgery, and the logit of a propensity score for occurrence of a PJI within 1
                        year after surgery with a caliper of 0.2 times the SD.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">SMDs of ≥0.1 represent meaningful differences in covariates between
                        groups before and after matching.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Values are given as the mean ± SD.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Data were missing in the Before Matching group for this
                        variable.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">The Charlson score is a measure of patient comorbidity (prior to the
                        joint replacement) and ranges from 0 (lowest complication risk) to ≥3 (highest complication
                        risk).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">During the study period, 175,432 patients underwent
                  primary THA. The mean age (and SD) was 67 ± 11.4 years, and 95,883 patients (54.7%) were women. A
                  total of 868 patients (0.49%) underwent surgery for a PJI of the replaced joint within 1 year after
                  the index procedure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Compared with patients who did not have a PJI within 1
                  year postoperatively, those who did have a PJI within 1 year postoperatively had significantly higher
                  rates of obesity (5.1% and 17.7%, respectively; standardized difference, 0.41), frailty (8.0% and
                  12.1%, respectively; standardized difference, 0.14), and several other comorbidities. In the PJI
                  group, the median time to revision surgery was 33 days (IQR, 19 to 97 days). The annual rate of PJI
                  remained relatively static across the study period, with a rate of 0.50% in 2002 (30 of 5,912
                  patients) and 2020 (53 of 10,606 patients; p = 0.46).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Outcomes
                After Matching</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">A total of 827 (95.3%) of the 868 patients who had a PJI
                  within 1 year after surgery were matched to patients who did not, with standardized differences of
                  &lt;10% for all covariates (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). After
                  matching, patients with a PJI within the first year had a significantly higher 10-year mortality rate
                  than their counterparts (11.4% [94 of 827 patients] versus 2.2% [18 of 827 patients]; absolute risk
                  difference, 9.19% [95% confidence interval (CI), 6.81% to 11.6%]; HR, 5.49 [95% CI, 3.32 to 9.09]).
                  The cumulative incidence of mortality over 10 years in each group, calculated with use of the
                  Kaplan-Meier method, is shown in <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 1</a> (p &lt;
                  0.0001).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01160f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0bb297e3-3c34-458b-9ec2-dd7148219f5d&type=jpeg&name=JBJS.23.01160f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Cumulative incidence of mortality following THA in
                          each matched group.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Sensitivity
                Analysis: Matching by Primary Surgeon</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">A total of 166 patients with a PJI within 1 year after
                  surgery were matched to patients without a PJI within 1 year after surgery who had the same primary
                  surgeon for their index procedure. After matching, patients in the PJI group had a significantly
                  higher 10-year mortality rate than their counterparts (HR, 3.28 [95% CI, 1.20 to 8.98]).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Sensitivity
                Analysis: Excluding Patients Over 60 Years Old</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">A total of 215 patients ≤60 years old who had a PJI within
                  1 year after surgery were matched to patients ≤60 years old who did not. After matching, patients in
                  the PJI group had a significantly higher 10-year mortality rate than their counterparts (HR, 10.18
                  [95% CI, 1.30 to 79.61]).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Sensitivity
                Analysis: Impact of Postoperative Pneumonia on 10-Year Mortality</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">A total of 442 patients who had postoperative pneumonia
                  were matched to patients who did not. After matching, we found no significant association between
                  postoperative pneumonia and 10-year mortality (HR, 0.22 [95% CI, 0.05 to 1.02]).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_27" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">In this population-based cohort study that included
                  patients who underwent primary elective THA, a PJI within 1 year after surgery was associated with a
                  5.5-fold increased risk of mortality within 10 years. This association between PJI and increased
                  10-year mortality risk was consistent across multiple sensitivity analyses and distinct from pneumonia
                  infections.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">The main results of this study are relevant to the
                  increasing number of patients undergoing THA and to the multidisciplinary teams of surgeons and
                  infectious disease specialists involved in managing PJIs. Because of the recognized impact of each
                  PJI, arthroplasty surgeons and infectious disease experts have long focused on measures that can
                  mitigate the risk of PJI. Despite this universal interest, there is a lack of evidence on PJI
                  prevention, including on commonly utilized prevention solutions such as antiseptic irrigation
                  solutions, locally administered antibiotic powders, and extended antibiotic courses. Patients deemed
                  to be at a high risk for infection are sometimes denied surgery altogether<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">30</span></a>. The findings of the present study underscore the continued
                  importance of identifying effective strategies for infection prevention and ensuring that operative
                  teams adhere to best-practice guidelines. These findings also reinforce the need to delineate the best
                  approaches to managing PJI, with strategies that not only focus on curing the infection but also
                  consider patient morbidity and mortality.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">There are several reasons to believe that the observed
                  association between PJI and mortality may be causal rather than a result of unmeasured or residual
                  confounding. First, the association was strong in our primary analysis, in which we controlled for
                  several important prognostic variables, and remained strong in our sensitivity analyses. In patients
                  under 60 years old, we would anticipate mortality to be driven by the major comorbidities accounted
                  for in the matched analysis rather than by unmeasurable markers of frailty. Furthermore, surgeons have
                  varying thresholds of risk tolerance when determining patient candidacy for joint replacement surgery,
                  and yet the results remained consistent in the sensitivity analysis that controlled for the primary
                  surgeon.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Second, there is a well-established precedent that major
                  orthopaedic events can directly increase the risk of mortality. Namely, hip fractures carry an
                  estimated 1-year mortality risk of approximately 30%<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">31</span></a>.
                  Research has demonstrated several causal factors in mortality associated with hip fracture, including
                  inflammatory cascades, perioperative complications, and treatment variables such as the time to
                  surgery<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">32</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">36</span></a>. Similarly, PJI might accelerate mortality via multiple
                  mechanisms. There may be biological harm caused by chronic infection, which can persist for several
                  months. Moreover, mobility challenges associated with a chronically painful and poorly functioning
                  joint may limit the capacity to lead an active and healthy lifestyle. In addition, recent reports have
                  indicated that morbidity following a 2-stage surgical revision, which is the gold standard for the
                  management of PJI, may be associated with increased mortality risk compared with that following a
                  single-stage revision<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">37</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">38</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Finally, our findings agree with and build on evidence
                  from previous reports. In a systematic review, Natsuhara et al. calculated a weighted mean mortality
                  rate of 5.4% among patients with a PJI after THA. The risk of mortality was significantly increased
                  relative to the national age-adjusted risk, and there was no trend in improvement over time<a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">39</span></a>. In a study of data from the Swedish Hip Arthroplasty
                  Registry, Wildeman et al. identified a 10-year mortality rate of 45% among 442 patients with a PJI of
                  the hip following THA and found a significantly increased risk of mortality compared with patients
                  without a PJI following THA (odds ratio, 1.4; 95% CI, 1.2 to 1.6)<a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">17</span></a>. In a similar study of 445 patients from the Danish Hip
                  Arthroplasty Register, the relative risk of mortality among patients who underwent a revision for PJI
                  was 2 times greater than that among patients who did not undergo a revision for any cause (odds ratio,
                  2.18; 95% CI, 1.54 to 3.08). That study did not control for surgical indication and thus may have
                  included patients with a hip fracture who had a higher baseline risk of mortality<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">19</span></a>. Increased mortality risk has also been demonstrated when
                  comparing revisions for PJI with revisions for aseptic indications<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">40</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, the present study constitutes the
                  largest cohort of patients with a PJI after THA (n &gt; 800) with long-term follow-up. Furthermore, we
                  matched patients according to several important prognostic variables that were missing in previous
                  population-based studies, and we subsequently performed sensitivity analyses to test for potentially
                  underlying spurious findings. The results of this study build on previous evidence to help delineate
                  the causal association between PJI and increased mortality risk. These findings should help to bolster
                  efforts related to the prevention, diagnosis, and treatment of PJIs, which may be considered among the
                  most important priorities for future musculoskeletal research.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Limitations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">This study has several limitations. First, no information
                  was available regarding the microbiology of the PJIs, so we could not explore any associations between
                  specific organisms and mortality. Given the limitations of the available data, we were not able to
                  match cohorts on the basis of certain prognostic factors, including treatment factors (1-stage
                  revision versus 2-stage revision, antibiotic duration) and patient factors (race, ethnicity).
                  Furthermore, we relied on diagnostic and fee codes rather than culture data to identify the patients
                  with a PJI. The algorithm utilized for identifying PJIs had a sensitivity of 92% and a positive
                  predictive value of 78%<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">22</span></a>.
                  The misclassification of a PJI diagnosis is expected to be nondifferential, and thus the observed
                  associations between PJI and mortality were perhaps even underestimated<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">41</span></a>. Given the long follow-up period, some patients may have
                  moved out of the region, causing some deaths to not be captured. In theory, those with no
                  complications following THA and less need for continued hospital follow-up may have had a higher
                  likelihood of moving, and thus their deaths could have been underreported. Finally, given that the
                  study period spanned nearly 2 decades, there may have been unrecognized changes over time that could
                  have influenced the mortality rates, including in characteristics related to infection, diagnosis, and
                  treatment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">PJI within 1 year after THA is associated with a more than
                  fivefold increased risk of mortality within 10 years. The findings of this study underscore the
                  importance of prioritizing efforts related to the prevention, diagnosis, and treatment of PJIs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_28" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_4" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/H999" target="_blank" data-id="link_5" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/H999</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: Dr. Pincus and Dr. Ravi had full access to all of the data in
                  the study and take responsibility for the integrity of the data and the accuracy of the data analysis.
                  Parts of this material are based on data and information compiled and provided by the Ontario Ministry
                  of Health and the Canadian Institute for Health Information. This document also utilized data adapted
                  from the Statistics Canada Postal Code Conversion File, which is based on data licensed from Canada
                  Post and/or data adapted from the Ontario Ministry of Health Postal Code Conversion File, which
                  contains data copied under license from Canada Post and Statistics Canada.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Maradit Kremers H</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Larson DR</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Crowson CS</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Kremers WK</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Washington RE</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Steiner CA</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Jiranek WA</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Berry DJ</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Prevalence of Total Hip and Knee
                  Replacement in the United States</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2015 Sep 2;97(17):1386-97.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1215985" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Williams SN</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Wolford ML</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Bercovitz A</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Hospitalization for Total Knee
                  Replacement Among Inpatients Aged 45 and Over: United States, 2000-2010</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">NCHS Data Brief.</span> 2015
                Aug;(210):1-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hospitalization%20for%20Total%20Knee%20Replacement%20Among%20Inpatients%20Aged%2045%20and%20Over%3A%20United%20States%2C%202000-2010&as_occt=title&as_sauthors=%20%22SN%20Williams%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Wolford ML</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Palso K</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Bercovitz A</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Hospitalization for total hip
                  replacement among inpatients aged 45 and over: United States, 2000-2010</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">NCHS Data Brief.</span> 2015
                Feb;(186):1-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hospitalization%20for%20total%20hip%20replacement%20among%20inpatients%20aged%2045%20and%20over%3A%20United%20States%2C%202000-2010&as_occt=title&as_sauthors=%20%22ML%20Wolford%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Ferguson RJ</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Palmer AJ</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Taylor A</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Porter ML</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Malchau H</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Glyn-Jones S</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Hip replacement</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Lancet.</span> 2018 Nov
                3;392(10158):1662-71.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20replacement&as_occt=title&as_sauthors=%20%22RJ%20Ferguson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Pivec R</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Johnson AJ</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Mears SC</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Mont MA</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Hip arthroplasty</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Lancet.</span> 2012 Nov
                17;380(9855):1768-77.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20arthroplasty&as_occt=title&as_sauthors=%20%22R%20Pivec%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Price AJ</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Alvand A</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Troelsen A</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Katz JN</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Hooper G</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Gray A</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Carr A</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Beard D</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Knee replacement</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Lancet.</span> 2018 Nov
                3;392(10158):1672-82.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Knee%20replacement&as_occt=title&as_sauthors=%20%22AJ%20Price%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Learmonth ID</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Young C</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Rorabeck C</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">The operation of the century:
                  total hip replacement</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 2007 Oct 27;370(9597):1508-19.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20operation%20of%20the%20century%3A%20total%20hip%20replacement&as_occt=title&as_sauthors=%20%22ID%20Learmonth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Hunter DJ</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Bierma-Zeinstra S</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Osteoarthritis</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Lancet.</span> 2019 Apr
                27;393(10182):1745-59.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteoarthritis&as_occt=title&as_sauthors=%20%22DJ%20Hunter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Sloan M</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Premkumar A</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Sheth NP</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Projected Volume of Primary
                  Total Joint Arthroplasty in the U.S., 2014 to 2030</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2018 Sep
                5;100(17):1455-60.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1781130"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Kurtz SM</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Lau EC</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Son MS</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Chang ET</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Zimmerli W</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Parvizi J</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Are We Winning or Losing the
                  Battle With Periprosthetic Joint Infection: Trends in Periprosthetic Joint Infection and Mortality
                  Risk for the Medicare Population</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2018 Oct;33(10):3238-45.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Are%20We%20Winning%20or%20Losing%20the%20Battle%20With%20Periprosthetic%20Joint%20Infection%3A%20Trends%20in%20Periprosthetic%20Joint%20Infection%20and%20Mortality%20Risk%20for%20the%20Medicare%20Population&as_occt=title&as_sauthors=%20%22SM%20Kurtz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Jin X</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Gallego Luxan B</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Hanly M</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Pratt NL</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Harris I</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> de Steiger R</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Graves SE</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Jorm L</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Estimating incidence rates of
                  periprosthetic joint infection after hip and knee arthroplasty for osteoarthritis using linked
                  registry and administrative health data</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2022
                Sep;104-B(9):1060-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Estimating%20incidence%20rates%20of%20periprosthetic%20joint%20infection%20after%20hip%20and%20knee%20arthroplasty%20for%20osteoarthritis%20using%20linked%20registry%20and%20administrative%20health%20data&as_occt=title&as_sauthors=%20%22X%20Jin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Kapadia BH</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Berg RA</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Daley JA</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Fritz J</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Bhave A</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Mont MA</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Periprosthetic joint
                  infection</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 2016 Jan 23;387(10016):386-94.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Periprosthetic%20joint%20infection&as_occt=title&as_sauthors=%20%22BH%20Kapadia%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Ong KL</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Kurtz SM</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Lau E</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Bozic KJ</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Berry DJ</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Parvizi J</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Prosthetic joint infection risk
                  after total hip arthroplasty in the Medicare population</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2009
                Sep;24(6)(Suppl):105-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prosthetic%20joint%20infection%20risk%20after%20total%20hip%20arthroplasty%20in%20the%20Medicare%20population&as_occt=title&as_sauthors=%20%22KL%20Ong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Patel R</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Periprosthetic Joint
                  Infection</span>. <span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">N
                  Engl J Med.</span> 2023 Jan 19;388(3):251-62.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Periprosthetic%20Joint%20Infection&as_occt=title&as_sauthors=%20%22R%20Patel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Wang Q</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Goswami K</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Kuo FC</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Xu C</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Tan TL</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Parvizi J</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Two-Stage Exchange Arthroplasty
                  for Periprosthetic Joint Infection: The Rate and Reason for the Attrition After the First
                  Stage</span>. <span data-id="emphasis_15" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2019 Nov;34(11):2749-56.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Two-Stage%20Exchange%20Arthroplasty%20for%20Periprosthetic%20Joint%20Infection%3A%20The%20Rate%20and%20Reason%20for%20the%20Attrition%20After%20the%20First%20Stage&as_occt=title&as_sauthors=%20%22Q%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Slullitel PA</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Oñativia JI</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Cima I</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Zanotti G</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Comba F</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Piccaluga F</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Buttaro MA</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Patients with no recurrence of
                  infection five years after two-stage revision hip arthroplasty may be classified as periprosthetic
                  infection ‘in remission’</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2021 Jan;103-B(1):79-86.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patients%20with%20no%20recurrence%20of%20infection%20five%20years%20after%20two-stage%20revision%20hip%20arthroplasty%20may%20be%20classified%20as%20periprosthetic%20infection%20%E2%80%98in%20remission%E2%80%99&as_occt=title&as_sauthors=%20%22PA%20Slullitel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Wildeman P</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Rolfson O</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Söderquist B</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Wretenberg P</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Lindgren V</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">What Are the Long-term Outcomes
                  of Mortality, Quality of Life, and Hip Function after Prosthetic Joint Infection of the Hip? A 10-year
                  Follow-up from Sweden</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2021 Oct 1;479(10):2203-13.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20Are%20the%20Long-term%20Outcomes%20of%20Mortality%2C%20Quality%20of%20Life%2C%20and%20Hip%20Function%20after%20Prosthetic%20Joint%20Infection%20of%20the%20Hip%3F%20A%2010-year%20Follow-up%20from%20Sweden&as_occt=title&as_sauthors=%20%22P%20Wildeman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Rietbergen L</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Kuiper JW</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Walgrave S</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Hak L</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Colen S</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Quality of life after staged
                  revision for infected total hip arthroplasty: a systematic review</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">Hip Int.</span> 2016 Jul
                25;26(4):311-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Quality%20of%20life%20after%20staged%20revision%20for%20infected%20total%20hip%20arthroplasty%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22L%20Rietbergen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Gundtoft PH</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Pedersen AB</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Varnum C</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Overgaard S</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Increased Mortality After
                  Prosthetic Joint Infection in Primary THA</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2017
                Nov;475(11):2623-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Increased%20Mortality%20After%20Prosthetic%20Joint%20Infection%20in%20Primary%20THA&as_occt=title&as_sauthors=%20%22PH%20Gundtoft%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Ravi B</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Jenkinson R</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Austin PC</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Croxford R</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Wasserstein D</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Escott B</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Paterson JM</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Kreder H</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Hawker GA</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Relation between surgeon volume
                  and risk of complications after total hip arthroplasty: propensity score matched cohort study</span>.
                <span data-id="emphasis_20" class="annotation emphasis" style="position: unset;">BMJ.</span> 2014 May
                23;348:g3284.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Relation%20between%20surgeon%20volume%20and%20risk%20of%20complications%20after%20total%20hip%20arthroplasty%3A%20propensity%20score%20matched%20cohort%20study&as_occt=title&as_sauthors=%20%22B%20Ravi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Yadav K</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Lewis RJ</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Immortal Time Bias in
                  Observational Studies</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2021 Feb 16;325(7):686-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Immortal%20Time%20Bias%20in%20Observational%20Studies&as_occt=title&as_sauthors=%20%22K%20Yadav%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Kandel CE</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Jenkinson R</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Widdifield J</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Hansen BE</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Davey JR</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Muller MP</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Daneman N</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> McGeer A</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Identification of prosthetic
                  hip and knee joint infections using administrative databases-A validation study</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Infect Control Hosp
                  Epidemiol.</span> 2021 Mar;42(3):325-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Identification%20of%20prosthetic%20hip%20and%20knee%20joint%20infections%20using%20administrative%20databases-A%20validation%20study&as_occt=title&as_sauthors=%20%22CE%20Kandel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Deyo RA</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Cherkin DC</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Ciol MA</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Adapting a clinical comorbidity
                  index for use with ICD-9-CM administrative databases</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">J Clin Epidemiol.</span> 1992
                Jun;45(6):613-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Adapting%20a%20clinical%20comorbidity%20index%20for%20use%20with%20ICD-9-CM%20administrative%20databases&as_occt=title&as_sauthors=%20%22RA%20Deyo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;">Johns
                Hopkins Medicine. <span data-id="emphasis_24" class="annotation emphasis" style="position: unset;">ACG
                  System version 10.0 Technical Reference Guide.</span> 2011. Accessed 2024 Mar 20. <a
                  href="https://www.hopkinsacg.org/document/acg-system-version-10-0-technical-reference-guide/"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.hopkinsacg.org/document/acg-system-version-10-0-technical-reference-guide/</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Glazier RH</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Badley EM</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Gilbert JE</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Rothman L</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">The nature of increased
                  hospital use in poor neighbourhoods: findings from a Canadian inner city</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Can J Public Health.</span>
                2000 Jul-Aug;91(4):268-73.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20nature%20of%20increased%20hospital%20use%20in%20poor%20neighbourhoods%3A%20findings%20from%20a%20Canadian%20inner%20city&as_occt=title&as_sauthors=%20%22RH%20Glazier%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;">Ontario
                Data Catalogue. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Registered Persons Database (RPDB).</span> Accessed 2024 Mar 20. <a
                  href="https://data.ontario.ca/dataset/registered-persons-database-rpdb" target="_blank"
                  data-id="link_2" class="link"
                  style="position: unset;">https://data.ontario.ca/dataset/registered-persons-database-rpdb</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Austin PC</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">An Introduction to Propensity
                  Score Methods for Reducing the Effects of Confounding in Observational Studies</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Multivariate Behav
                  Res.</span> 2011 May;46(3):399-424.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=An%20Introduction%20to%20Propensity%20Score%20Methods%20for%20Reducing%20the%20Effects%20of%20Confounding%20in%20Observational%20Studies&as_occt=title&as_sauthors=%20%22PC%20Austin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Austin PC</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Balance diagnostics for
                  comparing the distribution of baseline covariates between treatment groups in propensity-score matched
                  samples</span>. <span data-id="emphasis_28" class="annotation emphasis" style="position: unset;">Stat
                  Med.</span> 2009 Nov 10;28(25):3083-107.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Balance%20diagnostics%20for%20comparing%20the%20distribution%20of%20baseline%20covariates%20between%20treatment%20groups%20in%20propensity-score%20matched%20samples&as_occt=title&as_sauthors=%20%22PC%20Austin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Barlow WE</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Robust variance estimation for
                  the case-cohort design</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">Biometrics.</span> 1994 Dec;50(4):1064-72.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Robust%20variance%20estimation%20for%20the%20case-cohort%20design&as_occt=title&as_sauthors=%20%22WE%20Barlow%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_124" class="annotation" style="position: unset;"> McLaughlin J</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Kipping R</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Owen-Smith A</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> McLeod H</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Hawley S</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Wilkinson JM</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Judge A</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">What effect have NHS
                  commissioners’ policies for body mass index had on access to knee replacement surgery in England?: An
                  interrupted time series analysis from the National Joint Registry</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">PLoS One.</span> 2022 Jun
                29;17(6):e0270274.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20effect%20have%20NHS%20commissioners%E2%80%99%20policies%20for%20body%20mass%20index%20had%20on%20access%20to%20knee%20replacement%20surgery%20in%20England%3F%3A%20An%20interrupted%20time%20series%20analysis%20from%20the%20National%20Joint%20Registry&as_occt=title&as_sauthors=%20%22J%20McLaughlin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Chang JS</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Ravi B</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Jenkinson RJ</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Paterson JM</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Huang A</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Pincus D</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Impact of preoperative
                  echocardiography on surgical delays and outcomes among adults with hip fracture</span>. <span
                  data-id="emphasis_31" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2021
                Feb;103-B(2):271-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impact%20of%20preoperative%20echocardiography%20on%20surgical%20delays%20and%20outcomes%20among%20adults%20with%20hip%20fracture&as_occt=title&as_sauthors=%20%22JS%20Chang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Rohold CK</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Lauritzen JB</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Jørgensen HL</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Causes of death among 93.637
                  hip fracture patients- data based on the Danish National Registry of causes of death</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Eur J Trauma Emerg
                  Surg.</span> 2022 Jun;48(3):1861-70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Causes%20of%20death%20among%2093.637%20hip%20fracture%20patients-%20data%20based%20on%20the%20Danish%20National%20Registry%20of%20causes%20of%20death&as_occt=title&as_sauthors=%20%22CK%20Rohold%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_140" class="annotation" style="position: unset;"> von Friesendorff M</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> McGuigan FE</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Wizert A</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Rogmark C</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Holmberg AH</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Woolf AD</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Akesson K</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Hip fracture, mortality risk,
                  and cause of death over two decades</span>. <span data-id="emphasis_33" class="annotation emphasis"
                  style="position: unset;">Osteoporos Int.</span> 2016 Oct;27(10):2945-53.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20fracture%2C%20mortality%20risk%2C%20and%20cause%20of%20death%20over%20two%20decades&as_occt=title&as_sauthors=%20%22M%20von%20Friesendorff%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Chen YH</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Chou CH</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Su HH</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Tsai YT</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Chiang MH</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Kuo YJ</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Chen YP</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Correlation between
                  neutrophil-to-lymphocyte ratio and postoperative mortality in elderly patients with hip fracture: a
                  meta-analysis</span>. <span data-id="emphasis_34" class="annotation emphasis"
                  style="position: unset;">J Orthop Surg Res.</span> 2021 Nov 18;16(1):681.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Correlation%20between%20neutrophil-to-lymphocyte%20ratio%20and%20postoperative%20mortality%20in%20elderly%20patients%20with%20hip%20fracture%3A%20a%20meta-analysis&as_occt=title&as_sauthors=%20%22YH%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Sheehan KJ</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Sobolev B</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Chudyk A</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Stephens T</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Guy P</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Patient and system factors of
                  mortality after hip fracture: a scoping review</span>. <span data-id="emphasis_35"
                  class="annotation emphasis" style="position: unset;">BMC Musculoskelet Disord.</span> 2016 Apr
                14;17:166.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20and%20system%20factors%20of%20mortality%20after%20hip%20fracture%3A%20a%20scoping%20review&as_occt=title&as_sauthors=%20%22KJ%20Sheehan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Pincus D</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Ravi B</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Wasserstein D</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Huang A</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Paterson JM</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Nathens AB</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Kreder HJ</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Jenkinson RJ</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Wodchis WP</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Association Between Wait Time
                  and 30-Day Mortality in Adults Undergoing Hip Fracture Surgery</span>. <span data-id="emphasis_36"
                  class="annotation emphasis" style="position: unset;">JAMA.</span> 2017 Nov
                28;318(20):1994-2003.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20Between%20Wait%20Time%20and%2030-Day%20Mortality%20in%20Adults%20Undergoing%20Hip%20Fracture%20Surgery&as_occt=title&as_sauthors=%20%22D%20Pincus%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Wolf CF</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Gu NY</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Doctor JN</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Manner PA</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Leopold SS</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Comparison of one and two-stage
                  revision of total hip arthroplasty complicated by infection: a Markov expected-utility decision
                  analysis</span>. <span data-id="emphasis_37" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2011 Apr 6;93(7):631-9.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1114475" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Lenguerrand E</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Whitehouse MR</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Kunutsor SK</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Beswick AD</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Baker RP</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Rolfson O</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Reed MR</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Blom AW</span>; National Joint
                Registry for England, Wales, Northern Ireland and the Isle of Man. <span data-id="strong_36"
                  class="annotation strong" style="position: unset;">Mortality and re-revision following single-stage
                  and two-stage revision surgery for the management of infected primary knee arthroplasty in England and
                  Wales: evidence from the National Joint Registry</span>. <span data-id="emphasis_38"
                  class="annotation emphasis" style="position: unset;">Bone Joint Res.</span> 2022
                Oct;11(10):690-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mortality%20and%20re-revision%20following%20single-stage%20and%20two-stage%20revision%20surgery%20for%20the%20management%20of%20infected%20primary%20knee%20arthroplasty%20in%20England%20and%20Wales%3A%20evidence%20from%20the%20National%20Joint%20Registry&as_occt=title&as_sauthors=%20%22E%20Lenguerrand%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Natsuhara KM</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Shelton TJ</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Meehan JP</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Lum ZC</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Mortality During Total Hip
                  Periprosthetic Joint Infection</span>. <span data-id="emphasis_39" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2019 Jul;34(7S):S337-42.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mortality%20During%20Total%20Hip%20Periprosthetic%20Joint%20Infection&as_occt=title&as_sauthors=%20%22KM%20Natsuhara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Zmistowski B</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Karam JA</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Durinka JB</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Casper DS</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Parvizi J</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Periprosthetic joint infection
                  increases the risk of one-year mortality</span>. <span data-id="emphasis_40"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2013 Dec
                18;95(24):2177-84.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1177336"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Pham A</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Cummings M</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Lindeman C</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Drummond N</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Williamson T</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Recognizing misclassification
                  bias in research and medical practice</span>. <span data-id="emphasis_41" class="annotation emphasis"
                  style="position: unset;">Fam Pract.</span> 2019 Nov 18;36(6):804-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Recognizing%20misclassification%20bias%20in%20research%20and%20medical%20practice&as_occt=title&as_sauthors=%20%22A%20Pham%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="videosummary_reference" class="annotation videosummary_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node videosummary" data-id="videoabstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Video Abstract</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6361448847112&quot;,&quot;type&quot;:&quot;video&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;0bb297e3-3c34-458b-9ec2-dd7148219f5d&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_3" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6361448847112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-0 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="6361448847112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6361448847112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6361448847112" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_3_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/jit/2324982687001/125d4be5-f723-4185-9b81-cff772b62476/main/1280x720/1m21s514ms/match/image.jpg"
                        src="blob:https://www.jbjs.org/b8ccf3df-f725-46c5-9e1f-da357b9ac852">
                        <track kind="metadata" label="segment-metadata">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/jit/2324982687001/125d4be5-f723-4185-9b81-cff772b62476/main/1280x720/1m21s514ms/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">2:42</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 2:42">
                            <div class="vjs-load-progress" style="width: 6.09%;"><span
                                class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">6.09%</span></span>
                              <div data-start="0" data-end="9.919999" style="left: 0%; width: 100%;"></div>
                            </div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">2:42</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_3_component_293">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_3_component_293"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">captions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button" type="button"
                          title="Picture-in-Picture" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                        <div class="vjs-overlay vjs-overlay-bottom-right vjs-overlay-no-background vjs-hidden"><img
                            src="//players.brightcove.net/2324982687001/studio_overlays/HyP0JSARx-0efbccee-026f-4138-bed4-07fddabe7725.png"
                            style="max-width: 100%;"></div>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_511_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_511_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_3_component_517_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_517_description">Beginning of dialog window. Escape will cancel and
                          close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_3_component_517">Text</legend><label
                                id="captions-foreground-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517">
                                <option id="captions-foreground-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517">
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_3_component_517">Background</legend><label
                                id="captions-background-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517">
                                <option id="captions-background-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517">
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_3_component_517">Window</legend><label
                                id="captions-window-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517">
                                <option id="captions-window-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517">
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Transparent" value="0"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_3_component_517" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_3_component_517">
                                <option id="captions-font-size-vjs_video_3_component_517-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_3_component_517" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_3_component_517">
                                <option id="vjs_video_3_component_517-None" value="none"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-None">None
                                </option>
                                <option id="vjs_video_3_component_517-Raised" value="raised"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Raised">Raised
                                </option>
                                <option id="vjs_video_3_component_517-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_3_component_517-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Uniform">Uniform
                                </option>
                                <option id="vjs_video_3_component_517-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_3_component_517" class="">Font Family</legend>
                              <select aria-labelledby="captions-font-family-vjs_video_3_component_517">
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_3_component_517-SmallCaps" value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_712_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_712_description">This is a modal window. This modal can be closed by
                          pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;0bb297e3-3c34-458b-9ec2-dd7148219f5d&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=0bb297e3-3c34-458b-9ec2-dd7148219f5d&type=supplement&name=10470"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D0bb297e3-3c34-458b-9ec2-dd7148219f5d%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_29" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">September 4, 2024;
                      106 (17): 1546</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01160</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">May 9, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_42"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Sunnybrook
                            Research Institute, Toronto, Ontario, Canada</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Disclaimer: The opinions, results, and conclusions
                          reported are those of the authors. No endorsement by ICES, its funders, or the data providers
                          is intended or should be inferred.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;0bb297e3-3c34-458b-9ec2-dd7148219f5d&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=0bb297e3-3c34-458b-9ec2-dd7148219f5d&type=pdf&name=JBJS.23.01160.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=0bb297e3-3c34-458b-9ec2-dd7148219f5d&type=pdf&name=JBJS.23.01160.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_45"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This study was
                          supported by a strategic operating grant (21-0000000176) from the Arthritis Society and by
                          ICES, an independent research institute funded by the Ontario Ministry of Health and Long-Term
                          Care. The funders and data providers had no role in the design and conduct of the study;
                          collection, management, analysis, and interpretation of the data; preparation, review, and
                          approval of the manuscript; or the decision to submit the manuscript for publication. The
                          <span data-id="strong_46" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/H998" target="_blank" data-id="link_3"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/H998</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;0bb297e3-3c34-458b-9ec2-dd7148219f5d&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=0bb297e3-3c34-458b-9ec2-dd7148219f5d&type=zip&name=JBJS.23.01160.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Raman Mundi, MD, MSc, FRCSC<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:raman.mundi@sunnybrook.ca" class=""
                    style="position: unset;">raman.mundi@sunnybrook.ca</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5115-5227" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5115-5227</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Daniel Pincus, MD, PhD, FRCSC<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3783-8205" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3783-8205</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Emil Schemitsch, MD, FRCSC<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6435-9069" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6435-9069</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Seper Ekhtiari, MD, MSc, FRCSC<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4571-9655" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4571-9655</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">J. Michael Paterson, MSc<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5995-1714" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5995-1714</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Harman Chaudhry, MD, MSc, FRCSC<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3584-8418" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3584-8418</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jerome A. Leis, MD, MSc, FRCPC<span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2250-4894" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2250-4894</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Donald A. Redelmeier, MD, MSc, FRCPC<span
                  data-id="affiliation_reference_8" class="label annotation cross_reference">8</span><span
                  data-id="affiliation_reference_9" class="label annotation cross_reference">9</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4147-3544" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4147-3544</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Bheeshma Ravi, MD, PhD, FRCSC<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1829-6885" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1829-6885</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Division of Orthopaedic Surgery, Department of Surgery, University of Toronto,
                Toronto, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Division of Orthopaedic Surgery, Sunnybrook Health Sciences Centre, Toronto,
                Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">ICES, Sunnybrook Health Sciences Centre, Toronto, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Surgery, University of Western Ontario, London, Ontario,
                Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Division of Trauma and Orthopaedic Surgery, Addenbrooke’s Hospital, Cambridge
                University Hospitals, Cambridge, United Kingdom</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Institute of Health Policy, Management & Evaluation, University of Toronto,
                Toronto, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Division of Infectious Diseases, Department of Medicine, Sunnybrook Health
                Sciences Centre, and Department of Medicine and Centre for Quality Improvement and Patient Safety,
                University of Toronto, Toronto, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">8</span><span class="text"
                style="position: unset;">Department of Medicine, University of Toronto, Toronto, Ontario, Canada</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">9</span><span class="text"
                style="position: unset;">Evaluative Clinical Sciences Platform, Sunnybrook Research Institute, Toronto,
                Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 27344.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
