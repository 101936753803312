import useBreakpoints from "./useBreakpoints";

export const useDevice = () => {
	const { smDown, smUp, mdDown, lgUp, xxsDown } = useBreakpoints();

	const isSmallScreen = xxsDown;
	const isMobile = smDown;
	const isMediumWindowSize = smUp && mdDown;
	const isLargeWindowSize = lgUp;

	return {
		isSmallScreen,
		isMobile,
		isMediumWindowSize,
		isLargeWindowSize,
	};
};
